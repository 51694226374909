import { FormattedContent } from '@jotta/ui/FormattedContent'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import { Trans } from '@lingui/macro'
import { usePhotoAppsRouteLoaderData } from './appsRouteLoader'

export function AppsRoute() {
  const url = usePhotoAppsRouteLoaderData()
  return (
    <LoadingOverlay
      open
      text={
        <FormattedContent>
          <Trans>
            Redirecting to <a href={url}>{url}</a>...
          </Trans>
        </FormattedContent>
      }
    />
  )
}
