/* eslint-disable tailwindcss/no-custom-classname */
import type { LabelHTMLAttributes, ReactNode } from 'react'
import { forwardRef } from 'react'
import type { AllVariantPaths } from '../../types/VariantTypes'
import { BrandIcon } from '../../Icons/BrandIcon/BrandIcon'
import clsx from 'clsx'

export function FormFieldErrorMessage({
  errorMessage,
  className,
}: {
  errorMessage: ReactNode
  className?: string
}) {
  return (
    <div
      className={clsx(
        'body-sm-ln flex items-center gap-2 py-2 text-danger',
        className,
      )}
    >
      <BrandIcon
        icon="SvgFillWarningCircle"
        className="size-body-sm-ln shrink-0 self-start"
      />
      {errorMessage}
    </div>
  )
}

export interface LabelProps {
  label?: string
  errorMessage?: ReactNode
  hidden?: boolean
  variant?: AllVariantPaths
  className?: string
}
export const Label = forwardRef<
  HTMLLabelElement,
  LabelHTMLAttributes<HTMLLabelElement> & LabelProps
>(function Label(
  {
    label,
    hidden = false,
    variant = 'forms.label',
    errorMessage,
    children,
    ...props
  },
  ref,
) {
  return (
    <label
      aria-hidden={hidden}
      ref={ref}
      sx={{
        cursor: 'pointer',
        variant,
      }}
      {...props}
    >
      {label && !hidden && <span className="label-md pb-2">{label}</span>}
      {children}
      {errorMessage && !hidden && (
        <FormFieldErrorMessage errorMessage={errorMessage} />
      )}
    </label>
  )
})
