import { useRef, useCallback } from 'react'

export function useObjectKeys() {
  const nextId = useRef(1)
  const map = useRef<WeakMap<WeakKey, number>>(new WeakMap())

  return useCallback((key: WeakKey) => {
    if (!map.current.has(key)) {
      map.current.set(key, nextId.current++)
    }

    return map.current.get(key)
  }, [])
}
