import { captureException } from '@sentry/react'
import type { CaptureContext } from '@sentry/types'
import Debug from 'debug'
import { useEffect } from 'react'
import { isRouteErrorResponse, useRouteError } from 'react-router-dom'
import { ErrorPage } from './ErrorPage'
const debug = Debug('jotta:ui:ErrorElement')

export function ErrorElement({
  captureContext,
  hideFooter,
}: {
  captureContext?: CaptureContext
  hideFooter?: boolean
}) {
  const error = useRouteError()

  useEffect(() => {
    if (isRouteErrorResponse(error)) {
      debug('isRouteErrorResponse', error)
      captureException(error, captureContext)
    } else {
      debug('Unknown error', error)
      captureException(error, captureContext)
    }
  }, [error, captureContext])

  return <ErrorPage error={error} hideFooter={hideFooter} />
}
