// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AlbumsSectionHeader_header__z\\+Vlg{margin-top:0}body.mediamarkt .AlbumsSectionHeader_header__z\\+Vlg{font-size:20px}body.elkjop .AlbumsSectionHeader_header__z\\+Vlg{font-size:20px;font-weight:500}body.saturn .AlbumsSectionHeader_header__z\\+Vlg{color:var(--color-gray-bg);font-size:20px;font-weight:500}body.comhem .AlbumsSectionHeader_header__z\\+Vlg{font-size:20px}body.telia .AlbumsSectionHeader_header__z\\+Vlg{font-size:var(--fz4);text-transform:uppercase}.AlbumsSectionHeader_count__sVYnc{color:var(--color-background-text);font-weight:500;opacity:.5}body.saturn .AlbumsSectionHeader_count__sVYnc{font-weight:400;opacity:.8}body.comhem .AlbumsSectionHeader_count__sVYnc{color:var(--color-purple-hue-gray);font-size:15px;font-weight:400;opacity:.8}body.telia .AlbumsSectionHeader_count__sVYnc{font-size:var(--fz2);text-transform:none}`, "",{"version":3,"sources":["webpack://./../../libraries/photos/src/components/Album/AlbumsSection/AlbumsSectionHeader.module.scss"],"names":[],"mappings":"AACA,oCACE,YAAA,CAEA,oDACE,cAAA,CAGF,gDACE,cAAA,CACA,eAAA,CAGF,gDAGE,0BAAA,CAFA,cAAA,CACA,eACA,CAGF,gDACE,cAAA,CAEF,+CACE,oBAAA,CACA,wBAAA,CAIJ,kCAEE,kCAAA,CADA,eAAA,CAEA,UAAA,CAEA,8CACE,eAAA,CACA,UAAA,CAGF,8CAEE,kCAAA,CADA,cAAA,CAEA,eAAA,CACA,UAAA,CAEF,6CACE,oBAAA,CACA,mBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `AlbumsSectionHeader_header__z+Vlg`,
	"count": `AlbumsSectionHeader_count__sVYnc`
};
export default ___CSS_LOADER_EXPORT___;
