import type { PartialMessage } from '@bufbuild/protobuf'
import { authTransport } from '../transport'
import { createPromiseClient } from '@connectrpc/connect'
import { CustomerService } from '@jotta/grpc-connect-openapi/customerService'
import type {
  PatchCustomerRequest,
  PatchCustomerResponse,
} from '@jotta/grpc-connect-openapi/customer'
import moize from 'moize'

export const getCustomerClient = moize(() =>
  createPromiseClient(CustomerService, authTransport),
)

export function patchCustomer(
  args: PartialMessage<PatchCustomerRequest>,
): Promise<PatchCustomerResponse> {
  return getCustomerClient().patchCustomer(args)
}
