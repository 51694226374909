import { Language } from '@jotta/grpc-web/no/jotta/openapi/language_pb'

export type LanguageKey = keyof typeof Language
const keys: LanguageKey[] = Object.keys(Language) as LanguageKey[]
export function getLanguageEnumName(language: Language) {
  return keys[language]
}

// Use this to mark text that should be translated in the far future, but not in the near future instead of the lingui macro :)
export function tt(string: TemplateStringsArray) {
  return string.toString()
}
