import type { Simplify } from 'type-fest'
import type { AlertVariants } from '@jotta/ui/types/VariantTypes'
import type { BrandThemeIcon } from '@jotta/types/Brand'
import { nextXid } from '@jotta/utils/xid'
import Debug from 'debug'
import { action, autorun, makeAutoObservable } from 'mobx'
import type { ReactNode } from 'react'
import { useMemo } from 'react'
import type { KeyMap } from '@jotta/types/TypeUtils'

const debug = Debug('jotta:ui:alert:store')

export type AlertMessageFunction = (
  message: ReactNode,
  options?: Partial<Omit<AlertMessage, 'message' | 'severity'>>,
) => void

export interface AlertMessageAction {
  text: string
  testId?: string
  action: string | (() => void)
  replace?: boolean
  keepOpen?: boolean
  icon?: BrandThemeIcon
}

export interface AlertMessage {
  /** Type of message */
  severity: AlertVariants
  /** Message to display */
  message: ReactNode
  /** Automatically dismiss alert after MS number of milliseconds */
  autoDismissAfterMsElapsed: number
  open: boolean

  /** Make it possible to dismiss the alert */
  dismissable: boolean
  /** Persist the dismiss state in localstorage using the value as a cache key */
  persistKey: string

  onClose?: () => void
  onRemove?: () => void
  actions?: AlertMessageAction[]
  testid?: string
}

export type AlertMessageParam = Simplify<
  Pick<AlertMessage, 'message'> & Partial<Omit<AlertMessage, 'message'>>
>

export const defaultAlertTimeouts: KeyMap<AlertVariants, number> = {
  error: 0,
  info: 6000,
  success: 6000,
  warning: 0,
  photoUploadComplete: 10000,
  photoUploadError: 10000,
  followSharedAlbum: 0,
  loginToAddPhotosAlert: 10000,
  addPhotosToAlbumAlert: 10000,
}

export class AlertStore {
  message: AlertMessage
  safeToRemove = false
  id = nextXid()
  dismissTimeout?: number

  constructor({
    severity = 'info',
    autoDismissAfterMsElapsed = defaultAlertTimeouts[severity],
    message,
    open = true,
    dismissable = true,
    persistKey = '',
    actions = [],
    onClose = () => {},
    onRemove = () => {},
    testid,
  }: AlertMessageParam) {
    makeAutoObservable(this)
    this.message = {
      autoDismissAfterMsElapsed,
      severity,
      message,
      open,
      dismissable,
      persistKey,
      actions,
      onClose,
      onRemove,
      testid,
    }

    this.setTimeout(autoDismissAfterMsElapsed)
  }

  clearTimeout() {
    if (this.dismissTimeout) {
      clearTimeout(this.dismissTimeout)
      this.dismissTimeout = 0
    }
  }

  setTimeout(ms: number) {
    this.clearTimeout()

    if (ms > 0) {
      this.dismissTimeout = window.setTimeout(
        action(() => {
          this.safeToRemove = true
        }),
        ms,
      )
    }
  }

  reOpen() {
    if (!this.message.open) {
      this.message.open = true
    }
  }

  get open() {
    debug('GET open', this.message.open)
    if (this.message.persistKey) {
      return Boolean(
        localStorage.getItem(this.message.persistKey) ?? this.message.open,
      )
    }
    return this.message.open && !this.safeToRemove
  }
  close = () => {
    if (this.message.dismissable) {
      if (this.message.persistKey) {
        localStorage.setItem(this.message.persistKey, String(Date.now()))
      }
      debug('CLOSE')
      this.message.open = false
      if (this.message.onClose) {
        this.message.onClose()
      }
    }
  }

  remove = () => {
    if (this.message.onRemove) {
      this.message.onRemove()
    }
    this.message.open = false
    this.safeToRemove = true
  }
}

export class AlertListStore {
  private alertSet: Set<AlertStore> = new Set()

  constructor() {
    makeAutoObservable(this)
  }

  showAlert = (msg: AlertMessageParam | AlertStore) => {
    const alertStore = msg instanceof AlertStore ? msg : new AlertStore(msg)
    this.alertSet.add(alertStore)
    autorun(() => {
      if (alertStore.safeToRemove) {
        debug('Removing alert with id %s', alertStore.id)
        this.alertSet.delete(alertStore)
      }
    })
    return alertStore
  }

  clearAll() {
    this.alertSet.clear()
  }
  remove = (alert: AlertStore) => {
    if (this.alertSet.has(alert)) {
      this.alertSet.delete(alert)
    }
  }

  info: AlertMessageFunction = (message, options = {}) => {
    this.showAlert({ message, ...options, severity: 'info' })
  }
  warning: AlertMessageFunction = (message, options = {}) => {
    this.showAlert({ message, ...options, severity: 'warning' })
  }
  error: AlertMessageFunction = (message, options = {}) => {
    this.showAlert({ message, ...options, severity: 'error' })
  }
  success: AlertMessageFunction = (message, options = {}) => {
    this.showAlert({ message, ...options, severity: 'success' })
  }

  photoUploadComplete: AlertMessageFunction = (message, options = {}) => {
    this.showAlert({ message, ...options, severity: 'photoUploadComplete' })
  }

  photoUploadError: AlertMessageFunction = (message, options = {}) => {
    this.showAlert({ message, ...options, severity: 'photoUploadError' })
  }

  photoUploadErrorWithActions: AlertMessageFunction = (
    message,
    options = {},
  ) => {
    this.showAlert({
      message,
      ...options,
      severity: 'photoUploadError',
    })
  }

  followSharedAlbum: AlertMessageFunction = (message, options = {}) => {
    this.showAlert({ message, ...options, severity: 'followSharedAlbum' })
  }

  loginToAddPhotosAlert: AlertMessageFunction = (message, options = {}) => {
    this.showAlert({ message, ...options, severity: 'loginToAddPhotosAlert' })
  }

  addPhotosToAlbumAlert: AlertMessageFunction = (message, options = {}) => {
    this.showAlert({ message, ...options, severity: 'addPhotosToAlbumAlert' })
  }

  get alerts() {
    const alerts: ReadonlyArray<AlertStore> = Array.from(this.alertSet.values())
    return alerts
  }
  get openAlerts() {
    const alerts: ReadonlyArray<AlertStore> = Array.from(this.alertSet.values())
    return alerts.filter(alert => alert.open)
  }

  get hasAlerts() {
    return Boolean(this.alertSet.size)
  }
}

let alertStore: AlertListStore

export function getAlertListStore() {
  if (!alertStore) {
    alertStore = new AlertListStore()
  }
  return alertStore
}

export function useAlertListStore(alertListStore = getAlertListStore()) {
  const alert = useMemo(() => getAlertListStore(), [])
  return { alert }
}
