import { Box } from '@jotta/ui/Box'
import { CopyInputFragment } from '@jotta/ui/CopyInput'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import { RadixDialog } from '@jotta/ui/RadixDialog'
import { RadixDialogToast } from '@jotta/ui/RadixDialogToast'
import type { RadixModalProps } from '@jotta/ui/RadixModal'
import { RadixModal } from '@jotta/ui/RadixModal'
import { ToggleButton } from '@jotta/ui/ToggleButton'
import { createUrl } from '@jotta/utils/url'
import { Trans, t } from '@lingui/macro'

import { useState } from 'react'
export interface ShareAlbumDialogProps extends RadixModalProps {
  isLoading?: boolean
  onShareAlbum: (shared: boolean) => void
  onToggleAccess: (hasWriteAccess: boolean) => void
  shareId?: string
  hasWriteAccess?: boolean
}

export const ShareAlbumDialog: React.FC<ShareAlbumDialogProps> = ({
  open = false,
  hasWriteAccess = false,
  isLoading = false,
  onClose,
  onShareAlbum,
  onToggleAccess,
  shareId,
  ...props
}) => {
  const [toastOpen, setToastOpen] = useState(false)
  const isShared = Boolean(shareId)
  const shareLink = shareId ? createUrl('share', shareId) : ''
  return (
    <RadixModal open={open} onClose={onClose} {...props}>
      <RadixDialog
        title={t`Share link`}
        onClose={onClose}
        buttons={[
          {
            action: 'close',
            label: t`Close`,
            variant: 'buttons.secondary',
          },
        ]}
      >
        <LoadingOverlay position="absolute" open={isLoading} />
        <Box variant="dialogs.shareDialogWrapper">
          <label id="toggleAlbumSharingLabel" htmlFor="toggleAlbumSharing">
            <Trans>Turn on album sharing</Trans>
          </label>

          <ToggleButton
            id="toggleAlbumSharing"
            aria-labelledby="toggleAlbumSharingLabel"
            isChecked={isShared}
            onToggle={onShareAlbum}
          />
          {isShared && (
            <>
              <label
                id="toggleAlbumAccessToAddPhotosLabel"
                htmlFor="toggleAlbumAccessToAddPhotos"
              >
                <Trans>Anyone can add photos</Trans>
              </label>
              <ToggleButton
                id="toggleAlbumAccessToAddPhotos"
                aria-labelledby="toggleAlbumAccessToAddPhotosLabel"
                isChecked={hasWriteAccess}
                onToggle={onToggleAccess}
              />
            </>
          )}
          {shareLink && (
            <CopyInputFragment
              value={shareLink}
              onCopy={() => setToastOpen(true)}
              buttonVariant="buttons.copyInputButton"
            />
          )}
        </Box>

        <RadixDialogToast
          severity="sharePhotos"
          open={open && toastOpen}
          onOpenChange={setToastOpen}
          title={t`Copied to clipboard`}
          data-testid="CopySharedAlbumSuccess"
        />
      </RadixDialog>
    </RadixModal>
  )
}
