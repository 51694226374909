import { toUrl } from '@jotta/grpc-js-client/ref'
import type { Importance } from '@jotta/ui/BannerMessage'
import { BannerMessage } from '@jotta/ui/BannerMessage'
import { observer } from 'mobx-react-lite'
import { useGetCustomer } from '@jotta/grpc-connect-client/customer'
import { Message_Importance } from '@jotta/grpc-connect-openapi/esm/openapi/customer/customer.v2_pb'

function mapImportance(importance: Message_Importance): Importance | undefined {
  switch (importance) {
    case Message_Importance.UNKNOWN_IMPORTANCE:
      return undefined
    case Message_Importance.CRITICAL:
      return 'critical'
    case Message_Importance.WARNING:
      return 'warning'
    case Message_Importance.INFO:
      return 'info'
  }
}

export const WebappBanner = observer(function WebappBanner() {
  const message = useGetCustomer().data?.customer?.messages[0]

  if (!message) {
    return null
  }
  const importance = mapImportance(message.importance)
  if (!importance) {
    return null
  }

  return (
    <BannerMessage
      importance={importance}
      message={message.localizedMessage}
      title={message.localizedTitle}
      link={
        message.encodedContentRef ? toUrl(message.encodedContentRef) : undefined
      }
      linkText={message.localizedButtonText}
      fullWidth
    />
  )
})
