import {
  addToAlbum,
  createAlbum,
  deleteAlbum,
  deletePhotos,
  downloadPhotos,
  hidePhotos,
  joinAlbum,
  leaveAlbum,
  removeFromAlbum,
  setAlbumAccess,
  shareAlbum,
  sharePhotos,
  unhidePhotos,
  unShareAlbum,
  updateAlbumTitle,
  upload,
} from '../../api/photosApi'
import type { PhotoActionType } from './PhotoActions.types'

function createPhotoActionsApiFunctionMap<
  T extends Record<PhotoActionType, (...args: any) => any>,
>(data: T) {
  return data
}
export const photoActionsApiFunctions = createPhotoActionsApiFunctionMap({
  ADD_COMMENT: (...args: void[]) => {
    // Start select photos mode
  },
  ADD_PHOTOS: (...args: void[]) => {
    // Start select photos mode
  },
  ADD_PHOTOS_ANONYMOUS: (...args: void[]) => {
    // No implementation
  },
  ADD_PHOTOS_TO_ALBUM: addToAlbum,
  COPY_SHARED_ALBUM_LINK: (...args: void[]) => {
    // No implementation
  },
  CREATE_ALBUM: createAlbum,
  DELETE_ALBUM: deleteAlbum,
  DELETE_PHOTOS: deletePhotos,
  DELETE_SHARED_PHOTO_LINK: unShareAlbum,
  DOWNLOAD_PHOTO: (...args: void[]) => {
    // Download single photo
  },
  DOWNLOAD_PHOTOS: downloadPhotos,
  FIND_SIMILAR: (...args: void[]) => {
    // void
  },
  FOLLOW_ALBUM: joinAlbum,
  FOLLOW_ALBUM_ANONYMOUS: (albumId: string) => {
    // No implementation
  },
  HIDE_PHOTO: hidePhotos,
  LEAVE_ALBUM: leaveAlbum,
  PLAY_ALBUM_SLIDESHOW: (albumId: string) => {
    // No implementation
  },
  REMOVE_PHOTOS_FROM_ALBUM: removeFromAlbum,
  RENAME_ALBUM: updateAlbumTitle,
  SET_ALBUM_ACCESS: setAlbumAccess,
  SHARE_ALBUM: shareAlbum,
  SHARE_PHOTO: sharePhotos,
  SHOW_INFO: (...args: void[]) => {
    // Toggle info in Media carousel
  },
  UNHIDE_PHOTO: unhidePhotos,
  UNSHARE_ALBUM: unShareAlbum,
  UPLOAD_PHOTO: upload,
} as const)
export type PhotoActionApiFunctions = typeof photoActionsApiFunctions
