import type { PartialMessage } from '@bufbuild/protobuf'
import { useSuspenseQuery } from '@connectrpc/connect-query'
import { getCustomer } from '@jotta/grpc-connect-openapi/customerQuery'
import type { GetCustomerRequest } from '@jotta/grpc-connect-openapi/customer'

export function useSuspenseCustomer(
  args: PartialMessage<GetCustomerRequest> = {},
) {
  const query = useSuspenseQuery(getCustomer, args)
  return query
}
