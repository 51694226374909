import type { AppError } from '@jotta/types/AppError'
import { t, Trans } from '@lingui/macro'
import { BaseErrorPage } from './BaseErrorPage'
import { useBrandTheme } from '@jotta/ui/useBrandTheme'
import { DefinitionListFromObject } from '@jotta/ui/DefinitionList'
import { Button } from '@jotta/ui/Button'
import { copyToClipboard } from '@jotta/utils/copyToClipboard'
import { useMutation } from '@tanstack/react-query'
import { MutationStatusAlert } from '../Alert/Alert'

export function ErrorPageError({
  error,
  resetError,
}: {
  error: AppError
  resetError?: () => void
}) {
  const { brandName } = useBrandTheme()
  const errorJson = error.toJSON()
  const copyToClipboardMutation = useMutation({
    mutationFn: copyToClipboard,
    throwOnError: false,
  })
  return (
    <BaseErrorPage>
      <p>
        <Trans id="Please try the wanted action again, if the error persists please <0>contact {0} support</0>">
          Please try the wanted action again, if the error persists please{' '}
          <a href="/support">contact {brandName} support</a>
        </Trans>
      </p>
      <details className="mb-16">
        <summary
          sx={{
            pl: 0,
            pb: 2,
            ml: 4,
            listStylePosition: 'outside',
          }}
        >
          <Trans>Technical details</Trans>
        </summary>
        <DefinitionListFromObject value={errorJson} />
        <div className="my-4 flex flex-col items-start gap-2">
          <button
            className="btn btn-primary"
            onClick={event => {
              event.preventDefault()
              copyToClipboardMutation.mutate(JSON.stringify(errorJson))
            }}
          >
            <Trans>Copy to clipboard</Trans>
          </button>
          <MutationStatusAlert
            mutation={copyToClipboardMutation}
            successText={t`Copied to clipboard`}
            errorText={t`Could not copy to clipboard`}
          />
        </div>
      </details>

      {resetError && (
        <p>
          <button className="btn" onClick={resetError}>
            <Trans>Reload</Trans>
          </button>
        </p>
      )}
      <p>
        <a href="/">
          <Trans>Click here to go back to the homepage.</Trans>
        </a>
      </p>
    </BaseErrorPage>
  )
}
