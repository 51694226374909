import { Button } from '@jotta/ui/Button'
import { observer } from 'mobx-react-lite'
import type { PhotoActionHandlerActionProps } from './PhotoActionHandlers'
import { useDispatchPhotoActionMutation } from './useDispatchPhotoActionMutation'

export const ActionDeleteSharedGroupOfPhotos = observer<
  PhotoActionHandlerActionProps<'DELETE_SHARED_PHOTO_LINK'>
>(function ActionDeleteSharedGroupOfPhotos({
  action,
  dispatch,
  buttonVariant = 'buttons.albumActionButton',
  disabled = false,
}) {
  const mutation = useDispatchPhotoActionMutation(
    'DELETE_SHARED_PHOTO_LINK',
    undefined,
    dispatch,
  )

  return (
    <Button
      aria-label={action.label}
      data-testid={action.testid}
      loading={mutation.isPending}
      disabled={disabled}
      key={action.actionType}
      icon={action.icon}
      variant={buttonVariant}
    >
      {action.label}
    </Button>
  )
})
