import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { DialogTrigger } from '@radix-ui/react-dialog'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { CreateAlbumDialog } from '../Album/Dialogs/CreateAlbumDialog'
import type { PhotoActionHandlerActionProps } from './PhotoActionHandlers'
import { useDispatchPhotoActionMutation } from './useDispatchPhotoActionMutation'

export const ActionCreateAlbum = observer<
  PhotoActionHandlerActionProps<'CREATE_ALBUM'>
>(function ActionCreateAlbum({
  action,
  photoIds = [],
  dispatch,
  buttonVariant = 'buttons.albumActionButton',
  onActionDialogClose,
}) {
  const [open, setOpen] = useState(false)
  const mutation = useDispatchPhotoActionMutation(
    'CREATE_ALBUM',
    {
      onSettled: () => setOpen(false),
    },
    dispatch,
  )
  return (
    <CreateAlbumDialog
      open={open}
      onClose={open => {
        setOpen(open)
        if (!open && onActionDialogClose) {
          onActionDialogClose(action.actionType)
        }
      }}
      createAlbumFn={async (ids, title = '') => {
        const res = await mutation.mutateAsync([[...photoIds], title])
        return res
      }}
      isLoading={mutation.isPending}
      triggerElement={
        <DialogTrigger
          aria-label={action.label}
          data-testid={action.testid}
          aria-busy={mutation.isPending}
          key={action.actionType}
          sx={{
            variant: buttonVariant,
          }}
        >
          <PlainBrandIcon icon={action.icon} />
          <span>{action.label}</span>
        </DialogTrigger>
      }
    />
  )
})
