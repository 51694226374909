// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RadixDialogToast_viewport__EAqRa{padding:0 var(--s3);transform:translateY(var(--s3))}.RadixDialogToast_viewport__EAqRa,.RadixDialogToast_viewportBottom__Dfjl9{height:0;margin-bottom:0;margin-top:0;position:relative;width:100;z-index:10}.RadixDialogToast_viewportBottom__Dfjl9{padding:0 var(--s4);transform:translateY(-50px)}.RadixDialogToast_toast__fJaS-{align-items:center;background-color:var(--toast-bg,#fff);border:1px solid var(--toast-border,#0000);border-radius:var(--radius1);color:var(--toast-color,#000);display:flex;flex-flow:row nowrap;font-size:var(--fz2);gap:var(--s2);margin-bottom:var(--s1);padding:var(--s2,1rem) var(--s4,1rem)}.RadixDialogToast_toast__fJaS- .RadixDialogToast_title__3ivQX{flex:1 1 auto}.RadixDialogToast_toast__fJaS- button{align-items:center;display:flex}.RadixDialogToast_toast__fJaS- svg{height:24px;width:24px}.RadixDialogToast_toast__fJaS->svg:first-child{color:var(--toast-border,#0000)}.RadixDialogToast_toast__fJaS-.RadixDialogToast_toastOutline__MdUI5{background-color:var(--toast-bg,#fff);border-left:6px solid var(--toast-border,#0000);border-radius:var(--radius1);box-shadow:2px 2px 5px 0 #0003;color:var(--toast-color,#000);font-size:var(--fz2);padding:var(--s2,1rem) var(--s3,1rem)}.RadixDialogToast_toast__fJaS-:last-of-type{margin-bottom:0}`, "",{"version":3,"sources":["webpack://./../../libraries/ui/src/Dialogs/RadixDialogToast/RadixDialogToast.module.scss"],"names":[],"mappings":"AACA,kCAIE,mBAAA,CACA,+BAEA,CAGF,0EAPE,QAAA,CAIA,eAAA,CAAA,YAAA,CANA,iBAAA,CACA,SAAA,CAIA,UAWA,CAPF,wCAIE,mBAAA,CACA,2BAEA,CAGF,+BAGE,kBAAA,CAKA,qCAAA,CAFA,0CAAA,CACA,4BAAA,CAEA,6BAAA,CARA,YAAA,CACA,oBAAA,CAQA,oBAAA,CANA,aAAA,CA+BA,uBAAA,CA9BA,qCA8BA,CAxBA,8DACE,aAAA,CAEF,sCAEE,kBAAA,CADA,YACA,CAEF,mCAEE,WAAA,CADA,UACA,CAEF,+CACE,+BAAA,CAEF,oEAKE,qCAAA,CAHA,+CAAA,CAEA,4BAAA,CADA,8BAAA,CAGA,6BAAA,CACA,oBAAA,CANA,qCAMA,CAIF,4CACE,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"viewport": `RadixDialogToast_viewport__EAqRa`,
	"viewportBottom": `RadixDialogToast_viewportBottom__Dfjl9`,
	"toast": `RadixDialogToast_toast__fJaS-`,
	"title": `RadixDialogToast_title__3ivQX`,
	"toastOutline": `RadixDialogToast_toastOutline__MdUI5`
};
export default ___CSS_LOADER_EXPORT___;
