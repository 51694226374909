/* eslint-disable tailwindcss/no-custom-classname */
import { Box } from '@jotta/ui/Box'
import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { useBrandStore } from '@jotta/ui/useBrandTheme'
import {
  useAnyElementSizeRef,
  useDebounce,
  useDocumentEventListener,
  useElementSizeRef,
  useThrottle,
} from '@jotta/hooks'
import { AppLayoutPortal } from '@jotta/ui/AppLayoutPortal'
import { bisectLeft } from '@jotta/utils/bisect'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useRef, useState } from 'react'
import { getTimelineScrubberStore } from '../../store/TimelineScrubberStore'
import styles from './TimelineScrubber.module.scss'

const LABEL_TIMEOUT = 3000

export const TimelineScrubber = observer(function TimelineScrubber() {
  const [isDragging, setIsDragging] = useState(false)
  const [isScrolling, setIsScrolling] = useState(false)
  const [mouseOver, setMouseOver] = useState(false)
  const store = getTimelineScrubberStore()
  const refContainer = useRef<HTMLDivElement>(null)
  const refCursor = useRef<HTMLDivElement>(null)
  const refScrollIndicator = useRef<HTMLDivElement>(null)
  const labelBoundary = useRef({ top: Infinity, bottom: -Infinity, offset: 0 })
  const [isTouchDragging, setTouchDragging] = useState(false)
  const { labels } = store
  const { scrollingParent } = store
  const setContainerHeight = useThrottle(
    useCallback(
      height => runInAction(() => (store.scrubberHeight = height)),
      [store],
    ),
    500,
  )

  const containerSize = useElementSizeRef(refContainer, ({ height }) => {
    setContainerHeight(height)
  })
  const docSize = useAnyElementSizeRef(scrollingParent)

  const getScrollPercent = useCallback(() => {
    const element = docSize.scrollingElement
    if (!element) {
      return 0
    }

    return (
      (element.scrollTop /
        (element.scrollHeight - docSize.rect.current.height)) *
      100
    )
  }, [docSize])

  const translateToScrollPosition = useCallback(
    (height: number, offset: number) => {
      const element = docSize.scrollingElement
      if (!element || !height) {
        return 0
      }

      return Math.round(
        ((element.scrollHeight - docSize.rect.current.height) / height) *
          offset,
      )
    },
    [docSize],
  )

  const updateLabelContent = useCallback(
    (offsetPercent?: number) => {
      if (!refCursor.current || !labels.length) {
        return
      }

      // Boundary check
      if (
        typeof offsetPercent !== 'undefined' &&
        offsetPercent >= labelBoundary.current.top &&
        offsetPercent < labelBoundary.current.bottom
      ) {
        return
      }

      const offsetP =
        typeof offsetPercent !== 'undefined'
          ? offsetPercent
          : labelBoundary.current.offset
      labelBoundary.current.offset = offsetP

      // Look up label
      let i = bisectLeft(labels, pos => pos.offset - offsetP)
      i = Math.min(i, labels.length - 1)

      if (refCursor.current.querySelector('.cursor-label')) {
        const label = refCursor.current.querySelector(
          '.cursor-label',
        ) as HTMLDivElement
        const date = dayjs.utc(labels[i].date).format('MMMM YYYY')
        label.innerText = date

        // Update boundaries
        labelBoundary.current.top = !i ? 0 : labels[i - 1].offset
        labelBoundary.current.bottom = labels[i].offset
      }
    },
    [labels],
  )

  const updateLabel = useCallback(
    (event: MouseEvent) => {
      if (!refCursor.current || !refContainer.current || !labels.length) {
        return
      }

      if (window.getComputedStyle(refCursor.current).display === 'none') {
        return
      }

      // Get offset
      let offset = 0
      const { height, top } = containerSize.current
      offset = event.clientY - top
      offset = Math.min(Math.max(offset, 0), height)

      if (refCursor.current.offsetTop === offset) {
        return
      }

      const offsetPercent = offset / height

      // Update position
      refCursor.current.style.top = `${Math.round(offset)}px`

      // Clear label
      setIsScrolling(false) // Lingers for 3 seconds, need to clear if mouse moved

      updateLabelContent(offsetPercent)
    },
    [labels, containerSize, updateLabelContent],
  )

  const handleMouseEnter: React.MouseEventHandler = e => {
    setMouseOver(true)
    updateLabel(e.nativeEvent)
  }

  const handleMouseLeave = () => {
    setMouseOver(false)
  }

  const updateScrollIndicator = useCallback(
    (forceCursor = false) => {
      if (!refContainer.current || !refScrollIndicator.current) {
        return
      }

      const offset = getScrollPercent()
      refScrollIndicator.current.style.top = `${offset}%`

      if (refCursor.current && (forceCursor || (!mouseOver && isScrolling))) {
        refCursor.current.style.top = `${offset}%`
        updateLabelContent(offset / 100)
      }
    },
    [getScrollPercent, mouseOver, updateLabelContent, isScrolling],
  )

  const timeoutScrolling = useDebounce(
    useCallback(() => {
      setIsScrolling(false)
    }, []),
    LABEL_TIMEOUT,
  )

  const handleScroll = useCallback(() => {
    setIsScrolling(true)
    updateScrollIndicator()
    timeoutScrolling()
  }, [updateScrollIndicator, timeoutScrolling])

  useEffect(() => {
    const el = scrollingParent

    if (!el) {
      return
    }

    el.addEventListener('scroll', handleScroll)

    return () => {
      el.removeEventListener('scroll', handleScroll)
    }
  }, [scrollingParent, store, handleScroll])

  useEffect(() => {
    updateScrollIndicator(true)
    updateLabelContent()
  }, [
    store.rows.length,
    updateLabelContent,
    updateScrollIndicator,
    containerSize,
  ])

  const onMouseUp = useCallback((e: MouseEvent) => {
    if (e.button !== 0) {
      return
    }
    setIsDragging(false)
  }, [])

  const updateScroll = useCallback(
    (e: MouseEvent | TouchEvent) => {
      const element = docSize.scrollingElement

      if (!refContainer.current || !element) {
        return
      }

      const { height, top } = containerSize.current
      let offset = 0

      if (e instanceof MouseEvent) {
        offset = e.clientY - top
        offset = Math.min(Math.max(offset, 0), height)
      } else if (e instanceof TouchEvent) {
        offset = e.touches[0].clientY - top
        offset = Math.min(Math.max(offset, 0), height)
      }
      element.scrollTop = translateToScrollPosition(height, offset)

      if (refScrollIndicator.current) {
        refScrollIndicator.current.style.top = `${offset}px`
      }
    },
    [containerSize, translateToScrollPosition, docSize.scrollingElement],
  )

  const handleMouseDown: React.MouseEventHandler = useCallback(
    e => {
      if (!refContainer.current || e.button !== 0) {
        return
      }
      updateScroll(e.nativeEvent)
      setIsDragging(true)
    },
    [updateScroll],
  )

  useDocumentEventListener('mouseup', onMouseUp, { enabled: isDragging })
  useDocumentEventListener('mousemove', updateScroll, { enabled: isDragging })

  const cursorThumb = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const el = cursorThumb.current
    if (!el) {
      return
    }

    const touchStart = (e: TouchEvent) => {
      e.preventDefault()
      e.stopPropagation()
      setTouchDragging(true)
    }
    const touchEnd = (e: TouchEvent) => {
      e.preventDefault()
      e.stopPropagation()
      setTouchDragging(false)
    }
    const touchMove = (e: TouchEvent) => {
      e.preventDefault()
      e.stopPropagation()
      updateScroll(e)
    }

    el.addEventListener('touchstart', touchStart, { passive: false })
    el.addEventListener('touchend', touchEnd, { passive: false })
    el.addEventListener('touchmove', touchMove, { passive: false })

    return () => {
      el.removeEventListener('touchstart', touchStart)
      el.removeEventListener('touchend', touchEnd)
      el.removeEventListener('touchmove', touchMove)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateScroll, cursorThumb.current])

  const branding = useBrandStore()

  return (
    <AppLayoutPortal slot="timelineScrubber">
      <Box
        ref={refContainer}
        className={clsx(
          branding.isMobileTimeline ? 'mobile' : 'desktop',
          styles.scrubber,
        )}
        onMouseEnter={
          branding.currentBreakpoint > 1 ? handleMouseEnter : undefined
        }
        onMouseLeave={handleMouseLeave}
        onMouseDown={handleMouseDown}
        onMouseMove={
          branding.currentBreakpoint > 1 ? handleMouseEnter : undefined
        }
      >
        <div
          className={styles.yearDot}
          style={{ top: 0, transform: 'translateY(-50%)' }}
        />

        {store.visibleLabels.map(({ date, type, percentage }, index) => (
          <div
            key={index}
            style={{
              height: `${percentage}%`,
              position: 'relative',
            }}
          >
            <div
              className={
                index < store.visibleLabels.length - 1 && type === 'month'
                  ? styles.monthDot
                  : styles.yearDot
              }
            />
            {type === 'year' && (
              <div className={styles.yearLabel}>
                {type === 'year' && dayjs.utc(date).year()}
              </div>
            )}
          </div>
        ))}

        <div
          className={clsx({
            [styles.cursor]: styles.cursor,
            'mouse-over': mouseOver,
            'is-scrolling': isScrolling,
            'is-dragging': isDragging,
            'is-touch-dragging': isTouchDragging,
          })}
          ref={refCursor}
        >
          <div className={styles.cursorLine}>
            <div className={[styles.cursorLabel, 'cursor-label'].join(' ')} />
          </div>
          <div
            ref={cursorThumb}
            className={styles.cursorThumb}
            key="cursor-thumb"
          >
            <PlainBrandIcon
              icon="SvgSmallArrowDown"
              className={styles.thumbArrowUp}
            />
            <PlainBrandIcon
              icon="SvgSmallArrowDown"
              className={styles.thumbArrowDown}
            />
          </div>
        </div>

        <div
          ref={refScrollIndicator}
          className={styles.scrollIndicator}
          style={{
            ...((mouseOver || isDragging) && {
              boxShadow: '0 0 5px black',
              opacity: 0.9,
            }),
          }}
        />
      </Box>
    </AppLayoutPortal>
  )
})
