import { GetPaymentInfoResponse } from '@jotta/grpc-web/no/jotta/openapi/subscription/payment.v2_pb'
import { FormattedContent } from '@jotta/ui/FormattedContent'
import { Trans } from '@lingui/macro'
import dayjs from 'dayjs'

/* eslint-disable jsx-a11y/anchor-has-content */

export interface SubscriptionRenewalInfoProps {
  subscription: GetPaymentInfoResponse.AsObject
  upgradeOptionCase?: GetPaymentInfoResponse.ChangeSubscriptionOptionCase
  brandName?: string
}

export const SubscriptionExpirationInfo: React.FC<
  SubscriptionRenewalInfoProps
> = ({
  subscription: { expirationDateMillis, ...subscription },
  upgradeOptionCase,
  brandName,
}) => {
  if (expirationDateMillis) {
    const expirationDate = dayjs(expirationDateMillis.value).format('L')

    switch (upgradeOptionCase) {
      case GetPaymentInfoResponse.ChangeSubscriptionOptionCase.NORMAL_UPGRADE:
        return (
          <FormattedContent>
            <p>
              <Trans id="subscription_expires_can_convert">
                {expirationDate}
                <a href={'/web/expire'} />
              </Trans>
            </p>
          </FormattedContent>
        )

      case GetPaymentInfoResponse.ChangeSubscriptionOptionCase.SA_SUBSCRIPTION:
        return (
          <FormattedContent>
            <p>
              <Trans id="subscription_expires_sa_subscription">
                {brandName}
                {expirationDate}
                <a href="/web/renew" />
              </Trans>
            </p>
          </FormattedContent>
        )
      default:
        return (
          <FormattedContent>
            <p>
              <Trans id="subscription_expires">{expirationDate}</Trans>
            </p>
          </FormattedContent>
        )
    }
  } else {
    return null
  }
}
