import { allocate } from '@jotta/grpc-js-client/fileService'
import { ErrorType } from '@jotta/grpc-web/no/jotta/openapi/error_pb'
import {
  AllocateConflictHandler,
  FileState,
} from '@jotta/grpc-web/no/jotta/openapi/file/file.v2_pb'
import { AbortError } from '@jotta/types/AbortError'
import { calculateFileMd5 } from '@jotta/utils/crypto'
import { fileUploadXhr } from '@jotta/utils/fileUploadXhr'
import { createPath } from '@jotta/utils/pathUtils'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import type {
  ConflictResponse,
  FileConflictQueue,
} from '../FileConflictDialog/FileConflictStore'
import { BaseAction } from './BaseAction'
import { getOrRefreshAccessToken } from '@jotta/auth-client/useAuthStatus'
export type UploadStatus =
  | 'idle'
  | 'md5'
  | 'allocate'
  | 'uploading'
  | 'success'
  | 'skipped'

export class FileUploadAction extends BaseAction<any> {
  private _file: File
  protected conflicts: FileConflictQueue | null = null

  public get isPreparing() {
    return (
      (this.status === 'pending' && !this.completed) || this.status === 'idle'
    )
  }

  public get isUploading() {
    return this.status === 'pending' && this.completed > 0
  }

  public get id() {
    return `${this.path}/${this.name}`
  }

  private path: string

  public get name() {
    return this._file.name.split('/').slice(-1)[0]
  }

  public get total() {
    return this._file.size
  }

  private _md5: string | null = null
  private _md5Completed = 0
  private _md5Total = 0

  public get md5() {
    return this._md5
  }

  public get md5Completed() {
    return this._md5Completed
  }

  public get md5Total() {
    return this._md5Total
  }

  private async allocate(
    md5: string,
    conflictResponse?: ConflictResponse,
  ): Promise<ReturnType<typeof allocate> | void> {
    if (conflictResponse && conflictResponse.action === 'skip') {
      throw new AbortError('skipped')
    }

    if (conflictResponse?.action === 'cancel') {
      this.abort()
      this.abortCheck()
    }

    const conflictHandler =
      conflictResponse?.action === 'overwrite'
        ? AllocateConflictHandler.CREATE_NEW_REVISION
        : conflictResponse?.action === 'rename'
          ? AllocateConflictHandler.CREATE_NEW_FILE
          : AllocateConflictHandler.REJECT_CONFLICTS

    const allocator = await allocate({
      file: this._file,
      md5,
      path: createPath(this.path, this._file.name),
      conflictHandler,
    })

    if (!allocator.isOk()) {
      if (
        !conflictResponse &&
        this.conflicts &&
        (allocator.error === 'FILE_ONTO_FILE' ||
          allocator.error === 'FILE_ONTO_FOLDER' ||
          allocator.error === 'FOLDER_ONTO_FILE')
      ) {
        return this.allocate(
          md5,
          await this.conflicts.requestConflictAction(
            this._file.name,
            allocator.error,
            'UPLOAD',
          ),
        )
      }

      allocator.mapErr(e => {
        throw e
      })
    }

    return allocator
  }

  private abortCheck() {
    if (this.signal.aborted) {
      throw new AbortError('aborted')
    }
  }

  protected async run(): Promise<any> {
    const md5 = await calculateFileMd5({
      file: this._file,
      signal: this.signal,
      progressCallback: completed => {
        runInAction(() => {
          this.abortCheck()
          this._md5Completed = completed
        })
      },
    })

    runInAction(() => {
      this._md5 = md5
    })

    this.abortCheck()

    const allocator = await this.allocate(md5)

    if (!allocator) {
      return
    }

    this.abortCheck()
    const token = await getOrRefreshAccessToken()
    const { uploadUrl, state: fileState } = allocator._unsafeUnwrap()

    if (fileState === FileState.COMPLETED) {
      return
    }

    await fileUploadXhr(
      this._file,
      uploadUrl,
      token,
      ev => {
        if (ev.lengthComputable) {
          this.completed = ev.loaded
        }
      },
      this.signal,
    )
  }

  public get isInsufficientCapacityError() {
    if (!this.error) {
      return false
    }

    if (
      this.error.errors.some(e => e.code === ErrorType.INSUFFICIENT_CAPACITY)
    ) {
      return true
    }

    return false
  }

  constructor(
    file: File,
    path: string,
    conflicts: FileConflictQueue | null = null,
    abortController?: AbortController,
  ) {
    super(abortController)
    this.conflicts = conflicts
    this._file = file
    this.path = path
    this._md5Total = file.size
    makeObservable<
      FileUploadAction,
      'run' | '_md5' | '_md5Completed' | '_md5Total' | 'abortCheck'
    >(this, {
      abortCheck: true,
      id: computed,
      run: action.bound,
      _md5: observable,
      _md5Completed: observable,
      _md5Total: observable,
      md5: computed,
      md5Completed: computed,
      md5Total: computed,
      total: computed,
      name: computed,
      isPreparing: computed,
      isUploading: computed,
    })

    this.schedule()
  }
}
