import type { SortDir } from '@jotta/types/Files'
import type { PhotosApi } from '@jotta/types/PhotosApi'
import { excludesFalse } from '@jotta/types/TypeUtils'
// import * as Sentry from '@sentry/react'
import Debug from 'debug'
import { makeAutoObservable, observable } from 'mobx'
import { computedFn } from 'mobx-utils'
import { AlbumInfoQueryStore } from './AlbumInfoQueryStore'
import { AlbumInfoStore } from './AlbumInfoStore'
import { AlbumListQueryStore } from './AlbumListQueryStore'
import { AlbumSharesQueryStore } from './AlbumSharesQueryStore'
import type { PhotoStore } from './PhotoStore'
const debug = Debug('jotta:photos:album:store')

export function getAlbumInfoStoreSorter(
  sortBy: PhotosApi.Album.NumberColumn,
  sortDir: SortDir,
) {
  return (a: AlbumInfoStore, b: AlbumInfoStore) =>
    sortDir === 'ASC'
      ? a.data[sortBy] - b.data[sortBy]
      : b.data[sortBy] - a.data[sortBy]
}
export function getAlbumSorter(
  sortBy: PhotosApi.Album.NumberColumn,
  sortDir: SortDir,
) {
  return (a: PhotosApi.AlbumInfo, b: PhotosApi.AlbumInfo) =>
    sortDir === 'ASC' ? a[sortBy] - b[sortBy] : b[sortBy] - a[sortBy]
}

export interface AlbumListResponse {
  isLoading: boolean
  albums: AlbumInfoStore[]
  data: PhotosApi.AlbumInfo[]
}
/**
 * Collection of all albums
 */
export class AlbumRootStore {
  /**
   * Stores individual albums by ID
   * Other stores reference albums by ID and get them from this store
   */
  albums = observable.map<string, AlbumInfoStore>(undefined, {})

  /**
   * Data fetching for albums by type
   * Fetches album list on observed and updated the albums map with the result
   */
  private albumsByType = {
    normal: new AlbumListQueryStore(this, 'NORMAL_ALBUM'),
    location: new AlbumListQueryStore(this, 'LOCATION_ALBUM'),
    sharedAlbums: new AlbumListQueryStore(this, 'META_SHARED_ALBUM_TYPES'),
    sharedPhotos: new AlbumListQueryStore(this, 'SHARED_PHOTOS'),
  }

  public shares

  constructor(public root: PhotoStore) {
    this.shares = new AlbumSharesQueryStore(this.root)
    makeAutoObservable<typeof this, 'log'>(
      this,
      {
        log: false,
      },
      {
        autoBind: true,
      },
    )
  }
  private log = Debug('jotta:photos:AlbumCollection:Store')

  getAlbumsByIdsUntracked(
    ids: readonly string[],
    sortBy: PhotosApi.Album.NumberColumn,
    sortDir: SortDir = 'ASC',
  ) {
    const uniqueIds = [...new Set(ids)]
    const photos = uniqueIds
      .map(id => this.getAlbum(id))
      .filter(excludesFalse)
      .sort(getAlbumInfoStoreSorter(sortBy, sortDir))
    debug('getAlbumsByIds %d ids %d photos found', ids.length, photos.length)
    return photos
  }
  getAlbumsByIds = computedFn(
    (
      ids: readonly string[],
      sortBy: PhotosApi.Album.NumberColumn,
      sortDir: SortDir = 'ASC',
    ) => {
      debug('computed: get %d photos by ids', ids.length)
      return [...new Set(ids)]
        .map(this.getAlbum)
        .filter(excludesFalse)
        .sort(getAlbumInfoStoreSorter(sortBy, sortDir))
    },
  )
  getAlbumInfoQueryStore = computedFn((id: string) => {
    this.log('getAlbumInfoQueryStore', id.substring(0, 8))
    return new AlbumInfoQueryStore(id, this.root)
  })
  /**
   * Update an album with new data or create a new one if the ID doesn't exist
   *
   */
  setAlbum(id: string, data: PhotosApi.AlbumInfo) {
    const album = this.albums.get(id)
    if (album) {
      album.updateAlbumInfo(data)
      return album
    }

    const newAlbum = new AlbumInfoStore(data, this.root)
    this.albums.set(id, newAlbum)
    return newAlbum
  }
  /**
   * Update an album with new data or create a new one if the ID doesn't exist
   * Ignore album children
   */
  setAlbumInfo(
    id: string,
    data: PhotosApi.AlbumInfo,
    ignoreChildren?: boolean,
  ) {
    const album = this.albums.get(id)
    if (album) {
      if (ignoreChildren) {
        album.updateAlbumInfoOnly(data)
        return album
      }
      album.updateAlbumInfo(data)
      return album
    }
    const newAlbum = new AlbumInfoStore(data, this.root)
    this.albums.set(id, newAlbum)
    return newAlbum
  }
  setAlbums(albums: PhotosApi.AlbumInfo[], ignoreChildren?: boolean) {
    return albums.map(data => {
      const { id } = data
      const album = this.albums.get(id)
      if (album) {
        if (ignoreChildren) {
          album.updateAlbumInfoOnly(data)
          return id
        }
        album.updateAlbumInfo(data)
        return id
      }
      const newAlbum = new AlbumInfoStore(data, this.root)
      this.albums.set(id, newAlbum)
      return id
    })
  }

  getAlbum(id: string) {
    return this.albums.get(id)
  }

  get isAuthenticated() {
    return this.root.isAuthenticated
  }

  get normalAlbums(): AlbumListResponse {
    const albums = this.getAlbumsByIds(
      this.albumsByType.normal.albumIds,
      'lastModified',
      'DESC',
    )
    return {
      isLoading: this.isAuthenticated
        ? this.albumsByType.normal.isLoading
        : false,
      albums,
      data: albums.map(album => album.data),
    }
  }
  get sharedAlbums(): AlbumListResponse {
    const albums = this.getAlbumsByIds(
      this.albumsByType.sharedAlbums.albumIds,
      'lastModified',
      'DESC',
    )

    return {
      isLoading: this.albumsByType.sharedAlbums.isLoading,
      albums,
      data: albums.map(album => album.data),
    }
  }

  get locationAlbums(): AlbumListResponse {
    const albums = this.getAlbumsByIds(
      this.albumsByType.location.albumIds,
      'maxCapturedDate',
      'DESC',
    )
    return {
      isLoading: this.albumsByType.location.isLoading,
      albums,
      data: albums.map(album => album.data),
    }
  }
  get editableAlbums(): AlbumListResponse {
    const albums = this.isAuthenticated
      ? this.getAlbumsByIds(
          [
            ...this.albumsByType.normal.albumIds,
            ...this.albumsByType.sharedAlbums.albumIds,
          ],
          'lastModified',
          'DESC',
        ).filter(album => album.data.isEditable)
      : []
    return {
      isLoading: this.isAuthenticated
        ? this.normalAlbums.isLoading || this.sharedAlbums.isLoading
        : false,
      albums: albums,
      data: albums.map(album => album.data),
    }
  }
}
