import { capitalize } from '@jotta/utils/text'
import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

export function ShortTime({ timestamp }: { timestamp: number }) {
  const { i18n } = useLingui()

  const [value, setValue] = useState(shortTime(timestamp, i18n.locale))

  useEffect(() => {
    const id = setInterval(() => {
      setValue(shortTime(timestamp, i18n.locale))
    }, 60 * 1000)
    return () => clearInterval(id)
  }, [value, timestamp, i18n.locale])

  return <>{value}</>
}

const second = 1000
const minute = 60 * second
const hour = 60 * minute
const day = 24 * hour

const spans = [
  { name: 's', div: 1000, limit: 59 },
  { name: 'm', div: 60, limit: 59 },
  { name: 'h', div: 60, limit: 23 },
  { name: 'd', div: 24, limit: 6 },
]

export function shortTime(timestamp: number, locale: string) {
  return Date.now() - timestamp < 60 * 1000
    ? t`Just now`
    : short(timestamp, locale)
}

function short(date: number | Date, locale: string) {
  date = new Date(date)
  let span = Math.abs(date.getTime() - Date.now())
  if (span >= 7 * day) {
    if (span < 8 * day) {
      return '1w'
    }
    const sameYear = date.getFullYear() === new Date().getFullYear()
    return capitalize(
      dayjs(date)
        .format(sameYear ? 'MMM D' : 'MMM D YYYY')
        .replace('.', ''),
    )
  }
  let curr = -1
  do {
    curr += 1
    span = Math.floor(span / spans[curr].div)
  } while (span > spans[curr].limit)

  return `${span}${spans[curr].name}`
}
