import { Box } from '@jotta/ui/Box'
import { Button } from '@jotta/ui/Button'
import { Stack } from '@jotta/ui/Stack'
import Debug from 'debug'
import { AnimatePresence, motion } from 'framer-motion'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import type { PhotoUploadCollection } from '../../api/PhotoUploadCollection'
import { ActionAddPhotosToAlbum } from '../PhotoActions/ActionAddPhotosToAlbum'
import { ActionSharePhotos } from '../PhotoActions/ActionSharePhotos'
import { photoActionsApi } from '../PhotoActions/PhotoActions.config'
import { UploadPhotoQueueStatusUploadComplete } from './UploadPhotoQueueStatusUploadComplete'
import { UploadPhotoQueueStatusUploadCompletedWithErrors } from './UploadPhotoQueueStatusUploadCompletedWithErrors'
import { UploadPhotoQueueStatusUploading } from './UploadPhotoQueueStatusUploading'
import { useUsername } from '@jotta/grpc-connect-client/customer'

const debug = Debug('jotta:photos:UploadPhotoQueueStatus')

export interface UploadPhotoQueueStatusProps {
  status: PhotoUploadCollection['status']
  onCancel?: () => void
  onClose?: () => void
}
export const UploadPhotoQueueStatus = observer<UploadPhotoQueueStatusProps>(
  function UploadPhotoQueueStatus(props) {
    const { status, onClose } = props
    const open = status.state !== 'idle'
    const [shareLink, setShareLink] = useState('')
    let content: JSX.Element = <div>{status.state}</div>
    const username = useUsername()

    switch (status.state) {
      case 'idle':
        content = <></>
        break
      case 'uploading':
        content = <UploadPhotoQueueStatusUploading {...props} />
        break
      case 'uploadsCompleted':
        content = <UploadPhotoQueueStatusUploadComplete {...props} />
        break
      case 'error':
      case 'uploadsCompletedWithErrors':
        content = <UploadPhotoQueueStatusUploadCompletedWithErrors {...props} />
        break
    }
    return (
      <AnimatePresence>
        {open && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            sx={{ pointerEvents: 'all' }}
          >
            <Box
              variant={
                status.state === 'uploadsCompleted'
                  ? 'cards.photoUploadSuccess'
                  : 'cards.photoUpload'
              }
              p={0}
              sx={{
                position: 'relative',
              }}
            >
              {onClose && (
                <Button
                  onClick={onClose}
                  icon="SvgClose"
                  sx={{
                    position: 'absolute',
                    top: 3,
                    right: 3,
                  }}
                />
              )}
              <Stack p={4} gap={2}>
                {content}
              </Stack>
              {!status.isAlbumUpload &&
                status.uploadsCompleted &&
                Boolean(status.resolved) && (
                  <Stack
                    horizontal
                    sx={{
                      borderTop: '1px solid',
                      borderTopColor: 'var(--border-color)',
                      '& > :not(:last-child)': {
                        borderRight: '1px solid',
                        borderRightColor: 'var(--border-color)',
                      },
                      'button svg': {
                        ml: 'auto',
                      },
                      'button svg + span': {
                        mr: 'auto',
                        flex: '0 0 auto',
                      },
                    }}
                  >
                    <ActionSharePhotos
                      action={photoActionsApi['SHARE_PHOTO']}
                      shareLink={shareLink}
                      isOwnerOfAllPhotos
                      username={username}
                      mutationOptions={{
                        onSettled: async data => {
                          const album = await data
                          debug('onSettled', album)
                          if (album) {
                            const shareId =
                              album.shareInfo && album.shareInfo.uri
                            if (shareId) {
                              const url = new URL(
                                `/share/${shareId}`,
                                window.location.origin,
                              )
                              setShareLink(url.href)
                              debug('shareLink', url.href)
                            }
                          }
                        },
                      }}
                      photoIds={status.ids}
                      onActionDialogClose={actionType => {
                        if (actionType === 'SHARE_PHOTO') {
                          setShareLink('')
                          if (onClose) {
                            onClose()
                          }
                        }
                      }}
                    />
                    <ActionAddPhotosToAlbum
                      action={photoActionsApi['ADD_PHOTOS_TO_ALBUM']}
                      onActionDialogClose={actionType => {
                        if (actionType === 'ADD_PHOTOS_TO_ALBUM' && onClose) {
                          onClose()
                        }
                      }}
                      photoIds={status.ids}
                      username={username}
                    />
                  </Stack>
                )}
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
    )
  },
)
