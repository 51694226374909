import type { ApiErrorResponse } from '@jotta/grpc-web/no/jotta/openapi/api_error'
import { GrpcApiError, mapErrorCode } from '@jotta/types/GrpcApiError'
import { nextXid } from '@jotta/utils/xid'
import Debug from 'debug'
import type { Metadata } from 'grpc-web'
import { RpcError } from 'grpc-web'
import {
  getOrRefreshAccessToken,
  getAccessToken,
} from '@jotta/auth-client/useAuthStatus'
import { enrichError } from '@jotta/utils/error'
import { AppError } from '@jotta/types/AppError'

const debug = Debug('jotta:grpc')

export function getMeta(
  meta: Metadata = {},
  token?: string,
  deadlineSeconds: number = 10,
): Metadata {
  const deadline = new Date()
  deadline.setSeconds(deadline.getSeconds() + deadlineSeconds)

  const newMeta: Metadata = {
    'X-ID': nextXid(),
    deadline: deadline.getTime().toString(), // used for grpc request timeout ref: https://github.com/grpc/grpc-web#setting-deadline
    ...meta,
  }
  if (!token) {
    token = getAccessToken()
  }
  if (token && !newMeta.Authorization) {
    newMeta.Authorization = `Bearer ${token}`
  }
  return newMeta
}

export async function getAuthMeta(token?: string): Promise<Metadata> {
  if (!token) {
    token = await getOrRefreshAccessToken()
  }

  return getMeta({}, token)
}

function gprcMiddleware(
  namespace: string,
  callName: string,
  originalFunction: Function,
) {
  return function (request: any, meta: Metadata) {
    const logger = debug.extend(`${namespace}:${callName}`)
    const metadata = meta || getMeta(meta)
    const xid = metadata['X-ID']

    const requestObject = request.toObject()
    logger('request', xid, requestObject)

    // @ts-ignore
    const promise: Promise<any> = originalFunction.call(this, request, metadata)

    if (promise.then) {
      return promise.then(
        response => {
          logger('response', xid, response.toObject())
          return response
        },
        error => {
          logger('error.message', error.message)
          logger('raw error', error)

          if (error instanceof Error) {
            let apiErrorResponse: ApiErrorResponse
            try {
              apiErrorResponse = JSON.parse(error.message) as ApiErrorResponse
            } catch (err) {
              logger('failed to parse ApiErrorResponse', err)
              if (error instanceof RpcError) {
                throw new AppError({
                  message: error.message,
                  GrpcStatus: mapErrorCode(error.code),
                  xid: xid,
                  cause: error,
                })
              } else {
                throw enrichError(error, xid, callName)
              }
            }

            const apiError = new GrpcApiError(apiErrorResponse, {
              callName,
              namespace,
              cause: error,
            })
            // debugger
            logger(
              'Throwing ApiError',
              error,
              apiError.code,
              apiError.isNotAuthenticated,
            )
            throw apiError
          }
          logger('Not instance of error, throwing original error', error)
          throw enrichError(error, xid, callName)
        },
      )
    }
    return promise
  }
}

export function withGrpcClientMiddleware<T>(namespace: string, client: T): T {
  if (client) {
    const prototypeOf = Object.getPrototypeOf(client)
    Object.entries(prototypeOf).forEach(([functionName, func]) => {
      if (functionName !== 'constructor') {
        // @ts-ignore
        client[functionName] = gprcMiddleware(namespace, functionName, func)
      }
    })
  }
  return client
}
