import {
  useMutation,
  useQuery as useConnectQuery,
} from '@connectrpc/connect-query'
import {
  useMutation as useReactMutation,
  useQuery as useReactQuery,
  useQueryClient,
} from '@tanstack/react-query'
import {
  getDeviceToken,
  postDeviceToken,
} from '@jotta/grpc-connect-openapi/esm/openapi/device_token/v2/device_token.v2-DeviceTokenService_connectquery'
import {
  PostDeviceTokenRequest_DeviceType,
  PostDeviceTokenRequest_Platform,
} from '@jotta/grpc-connect-openapi/deviceToken'
import { useLocalStorage } from './useLocalStorage'
import { exhaustiveGuard } from '@jotta/utils/exhaustive'

const tokenRef = `ELS:key`
const algorithm = 'AES-GCM'
const exportFormat = 'raw'

const textEncoder = new TextEncoder()
const textDecoder = new TextDecoder()

export enum DecryptionFailureStrategy {
  DELETE_RECORD,
  THROW_ERROR,
}

function generateKey() {
  return crypto.subtle.generateKey(
    {
      name: algorithm,
      length: 256,
    },
    true,
    ['encrypt', 'decrypt'],
  )
}

function importKey(key: string) {
  return crypto.subtle.importKey(
    exportFormat,
    base64ToBytes(key),
    algorithm,
    true,
    ['encrypt', 'decrypt'],
  )
}

async function exportKey(key: CryptoKey) {
  return bytesToBase64(
    new Uint8Array(await crypto.subtle.exportKey(exportFormat, key)),
  )
}

function base64ToBytes(base64: string): Uint8Array {
  return Uint8Array.from(atob(base64), m => m.codePointAt(0) || 0)
}

function bytesToBase64(bytes: Uint8Array) {
  return btoa(Array.from(bytes, byte => String.fromCodePoint(byte)).join(''))
}

async function encryptMessage(key: CryptoKey, value: string): Promise<string> {
  const iv = crypto.getRandomValues(new Uint8Array(12))
  const cipher = await crypto.subtle.encrypt(
    { name: algorithm, iv: iv },
    key,
    textEncoder.encode(value),
  )
  return `${bytesToBase64(iv)}:${bytesToBase64(new Uint8Array(cipher))}`
}

async function decryptMessage(key: CryptoKey, value: string): Promise<string> {
  const [ivEncoded, cipherEncoded] = value.split(':')
  const iv = base64ToBytes(ivEncoded)
  const cipher = base64ToBytes(cipherEncoded)
  const plaintext = await crypto.subtle.decrypt(
    { name: algorithm, iv: iv },
    key,
    cipher,
  )
  return textDecoder.decode(plaintext)
}

export function useCryptoKey(tokenRef: string) {
  const { mutateAsync: postDeviceTokenAsync } = useMutation(postDeviceToken)
  const { data: getDeviceTokenResponse } = useConnectQuery(getDeviceToken, {
    tokenRef: tokenRef,
  })
  return useReactQuery({
    queryKey: [tokenRef],
    enabled: Boolean(getDeviceTokenResponse),
    queryFn: async () => {
      const tokenKey = getDeviceTokenResponse?.tokenKey
      if (tokenKey) {
        return await importKey(tokenKey)
      } else {
        const cryptoKey = await generateKey()
        await postDeviceTokenAsync({
          tokenKey: await exportKey(cryptoKey),
          tokenRef: tokenRef,
          platform: PostDeviceTokenRequest_Platform.UNKNOWN_PLATFORM,
          deviceType: PostDeviceTokenRequest_DeviceType.WEB,
        })
        return cryptoKey
      }
    },
  })
}

export function useEncryptedLocalStorageState<S>(
  key: string,
  initialValue: S,
  decryptionFailureStrategy: DecryptionFailureStrategy,
): [S | undefined, (value: S) => void] {
  const localStorage = useLocalStorage()

  const { data: cryptoKey } = useCryptoKey(tokenRef)

  const queryKey = [cryptoKey, localStorage, key]
  const { data } = useReactQuery({
    queryKey: queryKey,
    queryFn: async (): Promise<S> => {
      if (cryptoKey && localStorage) {
        const item = localStorage.getItem(key)
        if (item) {
          try {
            const plaintext = await decryptMessage(cryptoKey, item)
            return JSON.parse(plaintext)
          } catch (e) {
            switch (decryptionFailureStrategy) {
              case DecryptionFailureStrategy.DELETE_RECORD:
                localStorage.removeItem(key)
                break
              case DecryptionFailureStrategy.THROW_ERROR:
                throw new Error('decryption failed', {
                  cause: e,
                })
              default:
                exhaustiveGuard(decryptionFailureStrategy)
            }
          }
        }
      }
      return initialValue
    },
  })

  const queryClient = useQueryClient()
  const { mutate } = useReactMutation({
    mutationFn: async (value: S) => {
      if (cryptoKey && localStorage) {
        localStorage.setItem(
          key,
          await encryptMessage(cryptoKey, JSON.stringify(value)),
        )
        queryClient.invalidateQueries({
          queryKey: queryKey,
        })
      }
    },
  })

  return [data, mutate]
}
