import type { Avatar } from '@jotta/types/Avatar'
import type { Avatar as AvatarPB } from '@jotta/grpc-web/no/jotta/openapi/avatar_pb'

export function avatarFromProtobuf(avatar?: AvatarPB.AsObject): Avatar {
  const initials = avatar?.initials || ''
  const backgroundColor = avatar?.backgroundColor

  return {
    initials: initials,
    backgroundColor: backgroundColor
      ? `rgb(${backgroundColor.r}, ${backgroundColor.g}, ${backgroundColor.b})`
      : '',
    profilePhotoUrl: avatar?.profilePhotoUrl,
  }
}
