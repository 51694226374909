import { ensureAuthStatus } from '@jotta/auth-client/useAuthStatus'
import { queryClient } from '@jotta/query'
import { redirectToLogin } from '@jotta/utils/auth'
import { createUrl } from '@jotta/utils/url'
import Debug from 'debug'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { redirect } from 'react-router-dom'
import {
  getAlbumInfoQueryOptions,
  isAlbumUser,
} from '../components/Album/AlbumInfo/AlbumInfo.loaders'
import { joinAlbum } from './photosApi'
const debug = Debug('jotta:photos:loader:addphotos')

export async function addPhotosLoader({ params }: LoaderFunctionArgs) {
  const log = debug.extend('addphotosloader')
  const { shareId } = params
  if (!shareId) {
    log('Share ID missing')
    return redirect('/share')
  }
  const { authenticated, username } = await ensureAuthStatus()
  if (!authenticated) {
    const redirectUri = createUrl('share', shareId, 'addphotos')
    log('Not authenticated, redirecting to', redirectUri)
    redirectToLogin({
      redirectUri,
    })
    return null
  }
  log('Fetching album')
  const album = await queryClient.fetchQuery(
    getAlbumInfoQueryOptions({
      albumId: shareId,
      isPublic: true,
    }),
  )
  if (!isAlbumUser(album, username || '')) {
    log('Not subscriber, joining album')
    await joinAlbum(album.id)
  }
  const joinedAlbum = await queryClient.fetchQuery({
    ...getAlbumInfoQueryOptions({
      albumId: shareId,
      isPublic: false,
    }),
    staleTime: 0,
  })
  const redirectUri = `/photo?add-photos=${joinedAlbum.id}`
  log('Redirect to', redirectUri)
  return redirect(redirectUri)
}
