import { useFileKindIcon } from '@jotta/file-utils'
import type { ItemKind } from '@jotta/grpc-js-client/fileService'
import type { FC } from 'react'

export interface FileIconProps {
  kind: ItemKind
  size?: number | string
  shared?: boolean
  className?: string
}

export const FileIcon: FC<FileIconProps> = ({
  kind,
  size = '1em',
  shared = false,
  ...props
}) => {
  const Icon = useFileKindIcon(kind)

  return (
    <Icon
      width={size}
      height={size}
      sx={{
        variant: 'styles.fileIcon',
        width: '100%',
        height: '100%',
        fontSize: '100%',
      }}
      {...props}
    />
  )
}
