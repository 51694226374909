import type { InputHTMLAttributes } from 'react'
import { forwardRef } from 'react'
import { textInputVariants } from './TextInput.variants'
import { useThemeContext } from '../../Brand/ThemeProvider/ThemeProvider'

export type TextInputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'type' | 'checked'
>

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  function Input(props, ref) {
    const { theme } = useThemeContext()
    return (
      <input
        {...props}
        type="text"
        className={textInputVariants({
          className: props.className,
          theme: theme,
        })}
        ref={ref}
      />
    )
  },
)
