import { Button } from '@jotta/ui/Button'
import { observer } from 'mobx-react-lite'
import type { PhotoActionHandlerActionProps } from './PhotoActionHandlers'
import { useDispatchPhotoActionMutation } from './useDispatchPhotoActionMutation'

export const ActionPlayAlbumSlideshow = observer<
  PhotoActionHandlerActionProps<'PLAY_ALBUM_SLIDESHOW'>
>(function ActionPlayAlbumSlideshow({
  action,
  albumId = '',
  dispatch,
  buttonVariant = 'buttons.albumActionButton',
  disabled = false,
}) {
  const mutation = useDispatchPhotoActionMutation(
    'PLAY_ALBUM_SLIDESHOW',
    undefined,
    dispatch,
  )

  return (
    <Button
      variant={buttonVariant}
      aria-label={action.label}
      data-testid={action.testid}
      aria-busy={mutation.isPending}
      disabled={disabled}
      key={action.actionType}
      icon={action.icon}
      onClick={() => mutation.mutate([albumId])}
    >
      {action.label}
    </Button>
  )
})
