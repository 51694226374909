import { CodeInput } from '@jotta/ui/CodeInput'
import { Label } from '@jotta/ui/Label'
import { Button } from '@jotta/ui/Button'
import { useEffect, useRef, useState } from 'react'
import { Form } from '@jotta/form/Form'
import { Headline3 } from '@jotta/ui/Headline'
import { t, Trans } from '@lingui/macro'
import { Card } from '@jotta/ui/Card'
import appleTv from './appletv.png'
import { useMutation } from '@connectrpc/connect-query'
import { approveLogin as approveLoginDescriptor } from '@jotta/grpc-connect-openapi/esm/openapi/customer/customer.v2-CustomerService_connectquery'
import { authTransport } from '@jotta/grpc-connect-client/transport'
import { useResetAfterTimeout } from '@jotta/grpc-connect-client/useResetAfterTimeout'

export function DeviceLogin({ code }: { code?: string }) {
  const [loginCode, setLoginCode] = useState('')
  const submitButtonRef = useRef<HTMLButtonElement>(null)

  const approveLoginMutation = useMutation(approveLoginDescriptor, {
    throwOnError: false,
    transport: authTransport,
    onSuccess: () => {
      setLoginCode('')
    },
  })

  useResetAfterTimeout({
    mutation: approveLoginMutation,
    timeout: 3000,
    errorTimeout: 0,
  })

  const {
    mutate: approveLogin,
    isSuccess,
    isPending,
    isError,
    reset,
  } = approveLoginMutation

  useEffect(() => {
    if (code) {
      setLoginCode(code)
    }
  }, [code])

  return (
    <div className="flex flex-col items-center gap-16 sm:p-16">
      <img className="h-8 lg:h-32" src={appleTv} />
      <Card variant="cards.primary" className="bg-white">
        <Form
          className="flex flex-col items-end gap-4"
          onSubmit={() => {
            approveLogin({
              loginCode,
            })
          }}
        >
          <Label>
            <Headline3>{t`To sign in, enter your code below`}</Headline3>
            <CodeInput
              className="p-6"
              autofocus
              codeLength={6}
              code={loginCode}
              onCodeChange={code => {
                setLoginCode(code)
                reset()
              }}
              onCodeComplete={() => submitButtonRef.current?.focus()}
            />
          </Label>
          <Button
            ref={submitButtonRef}
            variant="buttons.primary"
            type="submit"
            loading={isPending}
          >
            <Trans>Send code</Trans>
          </Button>

          <Card
            hidden={!isSuccess}
            variant="cards.successOutline"
            className="w-full"
          >
            <Trans>Success</Trans>
          </Card>

          <Card hidden={!isError} variant="cards.danger" className="w-full">
            <Trans>Incorrect code. Type in the code again!</Trans>
          </Card>
        </Form>
      </Card>
    </div>
  )
}
