export type MaybeArray<T> = T | T[]
/**
 * Get the ID of a value
 * If T is a string or number the value itself is the ID, otherwise use on of its keys
 */
export type IdKey<T> = T extends string | number ? T : keyof T
export type AnyFunction = (...args: any[]) => any
export type Expand<T> = T extends infer O
  ? {
      [K in keyof O]: O[K]
    }
  : never
// expands object types recursively

export type ExpandRecursively<T> = T extends object
  ? T extends infer O
    ? {
        [K in keyof O]: ExpandRecursively<O[K]>
      }
    : never
  : T

/**
 * Partial two levels deep
 */
export type PartialDeep2<T> = {
  [K in keyof T]?: Partial<T[K]>
}
/**
 * Merge two types T and U
 * Keys are added from U when not already present in T
 */
export type Assign<T, U> = {
  [P in keyof (T & U)]: P extends keyof T
    ? T[P]
    : P extends keyof U
      ? U[P]
      : never
}
export type KeyMap<M extends string, T> = {
  [key in M]: T
}

/** Get all values of an object */
export type ValuesOf<T> = T[keyof T]

/** Get all second level keys as a union type with dot notation */
export type NestedKeys<T extends {}> = ValuesOf<{
  [K in keyof T]: K extends string
    ? keyof {
        [KK in keyof T[K] as KK extends string ? `${K}.${KK}` : never]: null
      }
    : never
}>
export const objectKeys = Object.keys as unknown as <
  const T extends Record<string, unknown>,
  K extends keyof T,
>(
  obj: T,
) => K[]

export function enumKeys<T extends {}, K extends string & keyof T>(
  enumValue: T,
): K[] {
  return Object.keys(enumValue) as K[]
}

export type NamedProperties<Name, T extends {}> = Name extends string
  ? {
      [K in keyof T as K extends 'Name'
        ? Name
        : K extends `Names` /**Pluralized */
          ? `${Uncapitalize<Name>}s`
          : K extends `Name${infer Suffix}`
            ? `${Uncapitalize<Name>}${Capitalize<Suffix>}`
            : K extends `${infer Prefix}Name`
              ? `${Uncapitalize<Prefix>}${Capitalize<Name>}`
              : K]: T[K]
    }
  : never

export type MapFn<In, Out> = (item: In, index: number, items: In[]) => Out
export type ExcludesFalse = <T>(x: T | false) => x is T
export function excludesFalse<T>(
  value: T | false | null | undefined,
): value is T {
  return Boolean(value)
}

export function typeGuard<T>(value: T) {}

export function unreachable(
  value: never,
  message = `No such case in exhaustive switch: ${value}`,
): never {
  throw new Error(message)
}

/** Index array of types by key */
export type Objectify<
  T extends any[] | readonly any[],
  IndexBy extends string & keyof T[number],
> = {
  [O in T[number] as `${O[IndexBy]}`]: O
}

/**
 * https://www.calebpitan.com/blog/dot-notation-type-accessor-in-typescript
 */
type ExcludeArrayKeys<T> =
  T extends ArrayLike<any> ? Exclude<keyof T, keyof any[]> : keyof T

export type IsAny<T> = unknown extends T
  ? [keyof T] extends [never]
    ? false
    : true
  : false
type PathImpl<T, Key extends keyof T> = Key extends string
  ? IsAny<T[Key]> extends true
    ? never
    : T[Key] extends Record<string, any>
      ?
          | `${Key}.${PathImpl<T[Key], ExcludeArrayKeys<T[Key]>> & string}`
          | `${Key}.${ExcludeArrayKeys<T[Key]> & string}`
      : never
  : never

type PathImpl2<T> = PathImpl<T, keyof T> | keyof T

export type Path<T> = keyof T extends string
  ? PathImpl2<T> extends infer P
    ? P extends string | keyof T
      ? P
      : keyof T
    : keyof T
  : never

export type Choose<
  T extends Record<string | number, any>,
  K extends Path<T>,
> = K extends `${infer U}.${infer Rest}`
  ? Rest extends Path<T[U]>
    ? Choose<T[U], Rest>
    : never
  : T[K]
