// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AlbumList_container__HNfVl{--content-opacity:1;position:relative;width:var(--content-width-safe)}.AlbumList_container__HNfVl.AlbumList_loading__SMI3I{--content-opacity:0}.AlbumList_container__HNfVl .AlbumList_content__BMy52{--ph:var(--s2);opacity:var(--content-opacity);overflow:hidden;padding:var(--s4) var(--ph) 60px;transition:opacity .3s;width:100%}body.br-1-up .AlbumList_container__HNfVl .AlbumList_content__BMy52{--ph:var(--s3)}body.br-2-up .AlbumList_container__HNfVl .AlbumList_content__BMy52{--ph:var(--s4)}`, "",{"version":3,"sources":["webpack://./../../libraries/photos/src/components/Album/AlbumList/AlbumList.module.scss","webpack://./../../libraries/ui/sass/mixins.scss"],"names":[],"mappings":"AACA,4BACE,mBAAA,CAEA,iBAAA,CADA,+BACA,CAEA,qDACE,mBAAA,CAEF,sDCgGQ,cAAA,CD1FN,8BAAA,CAFA,eAAA,CAFA,gCAAA,CAGA,sBAAA,CAFA,UAGA,CC+CA,mEA2CM,cAAA,CA3CN,mEA2CM,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AlbumList_container__HNfVl`,
	"loading": `AlbumList_loading__SMI3I`,
	"content": `AlbumList_content__BMy52`
};
export default ___CSS_LOADER_EXPORT___;
