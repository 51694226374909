import { UpdatesServicePromiseClient } from '@jotta/grpc-web/no/jotta/openapi/updates/updates.v1_grpc_web_pb'
import type {
  GetUpdatesResponse,
  Update,
} from '@jotta/grpc-web/no/jotta/openapi/updates/updates.v1_pb'
import {
  GetUpdatesRequest,
  UpdateLastSeenRequest,
} from '@jotta/grpc-web/no/jotta/openapi/updates/updates.v1_pb'
import Debug from 'debug'
import { withGrpcClientMiddleware } from './grpcutils'
import { env } from '@jotta/utils/env'

export {
  GetUpdatesResponse,
  Update,
} from '@jotta/grpc-web/no/jotta/openapi/updates/updates.v1_pb'

const debug = Debug('jotta:grpc:updates')

let updatesServiceClient: UpdatesServicePromiseClient
function getUpdatesServiceClient() {
  if (!updatesServiceClient) {
    updatesServiceClient = withGrpcClientMiddleware(
      'update',
      new UpdatesServicePromiseClient(env.grpcApi),
    )
  }
  return updatesServiceClient
}

export interface MarkAsReadRequest {
  update: Update.AsObject
}

export async function markAsRead(request: MarkAsReadRequest): Promise<void> {
  const req = new UpdateLastSeenRequest()
  req.setLastSeenId(request.update.id)

  const res = await getUpdatesServiceClient().updateLastSeen(req)

  debug('res', res.toObject())
}

export interface ListUpdatesRequest {
  cursor?: string
}

export async function listUpdates(
  request: ListUpdatesRequest,
): Promise<GetUpdatesResponse.AsObject> {
  const req = new GetUpdatesRequest()
  req.setLimit(20)
  req.setSort(GetUpdatesRequest.Sort.DESC)
  if (request.cursor) {
    req.setCursor(request.cursor)
  }

  const res = await getUpdatesServiceClient().getUpdates(req)

  return res.toObject()
}
