// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConfirmDialog_dialog__G\\+zGm{display:grid;gap:var(--s4);grid-template-rows:max-content 1fr max-content;height:100%;padding:var(--s4)}.ConfirmDialog_dialog__G\\+zGm h2,.ConfirmDialog_dialog__G\\+zGm p{margin-bottom:0;margin-top:0}.ConfirmDialog_dialog__G\\+zGm h2{font-size:var(--fz4);font-weight:var(--fw-body)}.ConfirmDialog_dialog__G\\+zGm .ConfirmDialog_content__riT1s p{margin-bottom:.5em}.ConfirmDialog_dialog__G\\+zGm .ConfirmDialog_buttons__IALU9{display:flex;flex-direction:column;flex-wrap:nowrap;gap:var(--s2)}.ConfirmDialog_dialog__G\\+zGm .ConfirmDialog_buttons__IALU9 button{flex:1 1 auto}body.br-1-up .ConfirmDialog_dialog__G\\+zGm .ConfirmDialog_buttons__IALU9{flex-direction:row;justify-content:flex-end}body.br-1-up .ConfirmDialog_dialog__G\\+zGm .ConfirmDialog_buttons__IALU9 button{flex:0 0 auto}`, "",{"version":3,"sources":["webpack://./../../libraries/ui/src/Dialogs/ConfirmDialog/ConfirmDialog.module.scss"],"names":[],"mappings":"AACA,8BACE,YAAA,CAGA,aAAA,CAFA,8CAAA,CAGA,WAAA,CAFA,iBAEA,CACA,iEAGE,eAAA,CADA,YACA,CAEF,iCACE,oBAAA,CACA,0BAAA,CAGA,8DACE,kBAAA,CAGJ,4DACE,YAAA,CACA,qBAAA,CACA,gBAAA,CACA,aAAA,CACA,mEACE,aAAA,CAEF,yEAIE,kBAAA,CACA,wBAAA,CAJA,gFACE,aAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": `ConfirmDialog_dialog__G+zGm`,
	"content": `ConfirmDialog_content__riT1s`,
	"buttons": `ConfirmDialog_buttons__IALU9`
};
export default ___CSS_LOADER_EXPORT___;
