import { Box } from '@jotta/ui/Box'
import { Stack } from '@jotta/ui/Stack'
import { Avatar } from '@jotta/ui/Avatar'
import { Plural, Trans } from '@lingui/macro'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import { useUsername } from '@jotta/grpc-connect-client/customer'
import { useNavigate } from 'react-router-dom'
import type { AlbumInfoStore } from '../../../store/AlbumInfoStore'
import { PhotoActionHandlers } from '../../PhotoActions/PhotoActionHandlers'
import styles from './AlbumInfo.module.scss'
import { AlbumMembers } from './AlbumMembers'
import { AlbumTitle } from './AlbumTitle'

const debug = Debug('jotta:photos:Album')

export const AlbumInfo = observer<{
  store: AlbumInfoStore
  publicView?: boolean
}>(function AlbumInfo({ store, publicView }) {
  const navigate = useNavigate()
  const album = store.model
  const photoCount = store.total
  const username = useUsername()

  return (
    <>
      <Box
        data-testid={publicView ? 'PublicAlbumView' : 'AlbumView'}
        sx={{
          width: 'var(--content-width-safe)',
          maxWidth: '100%',
        }}
      >
        <Box p="4">
          {album.isSharedPhotos && album.owner ? (
            <Stack
              horizontal
              gap={3}
              sx={{
                gridTemplateColumns: 'max-content 1fr',
                alignItems: 'center',
              }}
            >
              {album.owner && album.ownerAvatar && (
                <>
                  <Avatar size={30} avatar={album.ownerAvatar} />
                  <h1 className={styles.publicShareTitle}>{album.owner}</h1>
                </>
              )}
            </Stack>
          ) : (
            <Stack
              horizontal
              gap={2}
              sx={{
                width: '100%',
                gridTemplateColumns: '1fr max-content',
              }}
            >
              {!album.isSharedPhotos && <AlbumTitle store={store} />}
              <AlbumMembers store={store} />
            </Stack>
          )}
          <Stack
            horizontal
            justifyContent="start"
            gap={2}
            variant="styles.albumInfo"
          >
            {store.captureDate && <span>{store.captureDate}</span>}
            {photoCount ? (
              <span>
                <Plural
                  value={photoCount || 0}
                  one="# photo"
                  other="# photos"
                />
              </span>
            ) : (
              <span>
                <Trans id="AlbumContainer.emptyAlbum" />
              </span>
            )}
            {album.hasMorePhotos && <span>+</span>}
          </Stack>
        </Box>
        <Stack
          horizontal
          justifyContent="start"
          pb={5}
          pl="calc(var(--s4) / 2)"
          sx={{
            '--action-button-padding': 'var(--s2) calc(var(--s4) / 2)',
            width: '100%',
            overflow: 'hidden',
          }}
          data-testid="AlbumActions"
        >
          <PhotoActionHandlers
            actionContext={store.viewContexts.album}
            album={album}
            albumId={album.id}
            buttonVariant="buttons.responsiveActionButton"
            getDownloadInfo={store.getDownloadInfo}
            isAlbumSubscriber={store.isSubscriber}
            isAlbumUser={store.isAlbumUser}
            photoIds={store.selectedPhotoIds}
            readOnly={!album.canAddPhotos}
            shareId={album.shareInfo?.uri}
            shareLink={store.shareLink}
            username={username}
            onDispatch={(actionType, ...args) => {
              switch (actionType) {
                case 'FOLLOW_ALBUM':
                  return false
              }
            }}
            onDispatchComplete={(actionType, ...args) => {
              switch (actionType) {
                case 'LEAVE_ALBUM':
                  navigate('../')
                  break

                default:
                  break
              }
            }}
          />
        </Stack>
      </Box>
    </>
  )
})
