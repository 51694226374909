import type { LoaderFunctionArgs } from 'react-router-dom'
import { getWebAppStore } from '../store/WebAppStore'
import Debug from 'debug'

export async function rootLoader({ request }: LoaderFunctionArgs) {
  const log = Debug('jotta:webapp:rootLoader')
  const url = new URL(request.url)

  getWebAppStore().path = url.pathname
  const encodedContentRef = url.searchParams.get('encodedContentRef')
  const username = url.searchParams.get('username') || ''
  const token = url.searchParams.get('token') || ''
  const redirectToAutoLogin = Boolean(!encodedContentRef && username && token)
  log(username, token, encodedContentRef, redirectToAutoLogin)
  if (redirectToAutoLogin) {
    const redirectUri = new URL(url.href)
    redirectUri.searchParams.delete('username')
    redirectUri.searchParams.delete('token')
    const autoLoginUrl = new URL('/api/autologin', url.origin)
    autoLoginUrl.searchParams.set('username', username)
    autoLoginUrl.searchParams.set('token', token)
    autoLoginUrl.searchParams.set(
      'redirect_uri',
      redirectUri.pathname + redirectUri.search,
    )
    log(autoLoginUrl.href)
    window.location.href = autoLoginUrl.href
  }
  // await delay(5000)
  return { redirectToAutoLogin }
}
