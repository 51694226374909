import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import type { ReactNode } from 'react'
import { useCallback } from 'react'
import { EmptyFolder } from '../EmptyFolder/EmptyFolder'
import type { FileListRenderItem } from '../FileList/FileList'
import { FileList } from '../FileList/FileList'
import type { FolderStore } from './FolderStore'
import { FolderVirtualListItem } from './FolderVirtualListItem'
const debug = Debug('jotta:files:FolderVirtualList')

export interface FolderVirtualListProps {
  store: FolderStore
  emptyContent?: ReactNode
  showEmptyFolderComponent?: boolean
  loading?: boolean
}

export const FolderVirtualList = observer<FolderVirtualListProps>(
  function FolderVirtualList({
    store,
    loading = false,
    emptyContent = (
      <EmptyFolder
        context={store.root.route.params.context}
        pathItem={store.item}
        actions={store.actions}
      />
    ),
    showEmptyFolderComponent = false,
  }) {
    const items = store.virtualListItems
    const itemCount = items.length
    const renderItem = useCallback<FileListRenderItem>(
      (index, key, viewMode) => (
        <FolderVirtualListItem key={key} index={index} store={store} />
      ),
      [store],
    )
    const disabled =
      store.root.route.isTrash && Boolean(store.actions.emptyTrashPending)

    const onRenderItemCount = useCallback(
      (count: number) => {
        if (count === itemCount) {
          store.hasRendered = true
        }
      },
      [store, itemCount],
    )

    return (
      <FileList
        emptyContent={emptyContent}
        showEmptyContentWhenEmpty={showEmptyFolderComponent}
        itemCount={itemCount}
        renderItem={renderItem}
        viewMode={store.route.viewMode}
        disabled={disabled}
        loading={loading}
        onRenderItemCount={onRenderItemCount}
      />
    )
  },
)
