import { BrandIcon } from '@jotta/ui/BrandIcon'
import { getThumbLink } from '@jotta/file-utils'
import { ItemKind } from '@jotta/grpc-js-client/fileService'
import type { FileThumbSize, PathItemObject } from '@jotta/types/Files'
import { px } from '@jotta/utils/css'
import Debug from 'debug'
import type { FC } from 'react'
import { useCallback, useState } from 'react'
import { FileIcon } from '../FileIcon/FileIcon'
import styles from './FileItemTypeIcon.module.scss'
const debug = Debug('jotta:file:icons')

export interface FileItemTypeIconProps {
  item: Partial<PathItemObject>
  size?: number | string
  iconSize?: number | string
  thumbSize?: FileThumbSize
  shared?: boolean
  className?: string
}

export const FileItemTypeIcon: FC<FileItemTypeIconProps> = ({
  item,
  size = 32,
  iconSize = size,
  thumbSize = 'WS',
  shared,
  ...props
}) => {
  const isShared =
    typeof shared !== 'undefined' ? shared : Boolean(item.publicLinkId)
  const [loadError, setLoadError] = useState(false)
  const onLoadError = useCallback(() => {
    setLoadError(true)
  }, [])

  const thumbLink = getThumbLink(item, thumbSize)
  const showImage = thumbLink && !loadError
  const itemKind = item.kind || ItemKind.UNKNOWN_ITEM_KIND
  const isVideo = item.kind === ItemKind.VIDEO

  return (
    <div
      data-shared={isShared}
      data-has-thumbnail={showImage}
      className={styles.wrapper}
      sx={{
        '--thumb-size': px(size),
      }}
      {...props}
    >
      <div
        sx={{
          position: 'relative',
          width: showImage ? '100%' : iconSize,
          height: showImage ? '100%' : iconSize,
        }}
      >
        {showImage ? (
          <img
            className={styles.thumb}
            src={thumbLink}
            alt=""
            onError={onLoadError}
          />
        ) : (
          <FileIcon
            kind={itemKind}
            sx={{
              // width: iconSize,
              // height: iconSize,
              width: '100%',
              height: '100%',
              fontSize: '100%',
            }}
          />
        )}
        {showImage && isVideo && (
          <BrandIcon icon="SvgPlayCircleButton" className={styles.playIcon} />
        )}
        {isShared && (
          <BrandIcon icon="SvgShared" className={styles.shareIcon} />
        )}
      </div>
    </div>
  )
}
