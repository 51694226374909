import type {
  DisableTFARequest,
  GenerateLoginTokenRequest,
} from '@jotta/grpc-web/no/jotta/openapi/auth/v2/auth.v2_pb'
import type { Challenge } from '@jotta/grpc-web/no/jotta/openapi/credentials_pb'
import type {
  DowngradeAccountRequest,
  PatchCustomerRequest,
  TerminateAccountRequest,
} from '@jotta/grpc-web/no/jotta/openapi/customer/customer.v2_pb'
import type { VerifyOtpDialogProps } from '@jotta/ui/VerifyOtpDialog'
import type { VerifyPasswordDialogProps } from '@jotta/ui/VerifyPasswordDialog'
import type { VerifyPhoneDialogProps } from '@jotta/ui/VerifyPhoneDialog'

export enum ChallengeStates {
  PENDING,
  ANSWERED,
}

export enum ResolveState {
  CHALLENGED,
  SUCCESS,
  CANCELLED_BY_USER,
}

export type ChallengeableRequests =
  | PatchCustomerRequest
  | TerminateAccountRequest
  | DisableTFARequest
  | GenerateLoginTokenRequest
  | DowngradeAccountRequest

export interface ChallengeableRequestParams {
  request?: ChallengeableRequests
  onResolve?: (state: ResolveState) => void
  onFail?: (error: unknown) => void
  additionalProps?: {
    [key in Challenge]?: key extends Challenge.PASSWORD
      ? Partial<VerifyPasswordDialogProps>
      : key extends Challenge.SMS_OTP
        ? Partial<VerifyPhoneDialogProps>
        : key extends Challenge.TOTP
          ? Partial<VerifyOtpDialogProps>
          : undefined
  }
}

export interface ChallengeResolver extends ChallengeableRequestParams {
  type: Challenge
  state: ChallengeStates
  request: ChallengeableRequests
  errorMessage?: string
}
