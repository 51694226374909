import { Button } from '@jotta/ui/Button'
import { Grid } from '@jotta/ui/Grid'
import { Label } from '@jotta/ui/Label'
import { Stack } from '@jotta/ui/Stack'
import { RadioThemeUI, TextThemeUI } from '@jotta/ui/themeui'
import { Trans, t } from '@lingui/macro'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
} from '@stripe/react-stripe-js'
import type {
  StripeCardNumberElement,
  StripeElementChangeEvent,
  StripeElementType,
} from '@stripe/stripe-js'
import type { Dispatch, FC, FormEvent, SetStateAction } from 'react'

import { useState } from 'react'
import { FormattedContent } from '../../Elements/FormattedContent/FormattedContent'
import { VippsButton } from '../../Forms/VippsButton/VippsButton'
import type { DialogProps } from '../Dialog/Dialog'
import { Dialog } from '../Dialog/Dialog'

export type StripeValidationError = {
  [key in StripeElementType]?:
    | undefined
    | {
        type: 'validation_error'
        code: string
        message: string
      }
}

type PaymentMethods = 'vipps' | 'stripe'

export interface StripeFormProps {
  /** Verification handler  */
  onVerify: (cardElement: StripeCardNumberElement | null) => void
  errors: StripeValidationError
  setErrors: Dispatch<SetStateAction<StripeValidationError>>
}

export const StripeForm: FC<StripeFormProps> = ({
  onVerify,
  errors,
  setErrors,
}) => {
  const elements = useElements()

  const handleSubmit = (event?: FormEvent) => {
    event?.preventDefault()
    if (elements) {
      onVerify(elements.getElement(CardNumberElement))
    }
  }

  const errorHandler = ({ elementType, error }: StripeElementChangeEvent) =>
    setErrors(prevState => ({ ...prevState, ...{ [elementType]: error } }))

  const errorMessage = (field: StripeElementType) => errors[field]?.message

  return (
    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
      <div className="grid grid-cols-2 gap-3">
        <div className="form-control col-span-full">
          <Label
            label={t({ id: 'Card number' })}
            errorMessage={errorMessage('cardNumber')}
          >
            <CardNumberElement
              id="card-number"
              onChange={errorHandler}
              options={{
                placeholder: '0000 0000 0000 0000',
                classes: {
                  base: 'input input-stripe',
                  invalid: 'input-error',
                  focus: 'input-focus',
                },
              }}
            />
          </Label>
        </div>
        <div className="form-control">
          <Label
            label={t({ id: 'Expiry date' })}
            errorMessage={errorMessage('cardExpiry')}
          >
            <CardExpiryElement
              className="input input-stripe"
              id="exp-date"
              onChange={errorHandler}
              options={{
                classes: {
                  base: 'input input-stripe',
                  invalid: 'input-error',
                  focus: 'input-focus',
                },
              }}
            />
          </Label>
        </div>
        <div className="form-control">
          <Label
            label={t({ id: 'CVC' })}
            errorMessage={errorMessage('cardCvc')}
          >
            <CardCvcElement
              id="cvc"
              className="input input-stripe"
              onChange={errorHandler}
              options={{
                placeholder: '000',
                classes: {
                  base: 'input input-stripe',
                  invalid: 'input-error',
                  focus: 'input-focus',
                },
              }}
            />
          </Label>
        </div>
      </div>
    </form>
  )
}

export interface ChangePaymentMethodDialogProps extends DialogProps {
  /** Modal small title */
  isLoading?: boolean
  setErrorMessage: (msg: string) => void
  enableVipps?: boolean
  initialPaymentMethod?: PaymentMethods
  /** Verification handler */
  onVerify: (cardElement: StripeCardNumberElement | null) => void
  /** Cancel handler  */
  onCancel: () => void
  onSetupVipps: () => void
}

export const PaymentMethodSelector: FC<{
  paymentMethod: PaymentMethods
  selectPaymentMethod: (next: PaymentMethods) => void
  enableVipps: boolean | undefined
  className?: string
}> = ({ paymentMethod, selectPaymentMethod, enableVipps, ...props }) => {
  if (!enableVipps) {
    return null
  }
  return (
    <Stack gap="2" {...props}>
      <Label variant="forms.labelHorizontal">
        <RadioThemeUI
          name={'paymentmethod'}
          value={'stripe'}
          onChange={() => selectPaymentMethod('stripe')}
          checked={paymentMethod === 'stripe'}
        />
        <Trans id={'Card'} />
      </Label>
      <Label variant="forms.labelHorizontal">
        <RadioThemeUI
          name={'paymentmethod'}
          value={'vipps'}
          onChange={() => selectPaymentMethod('vipps')}
          checked={paymentMethod === 'vipps'}
        />
        <TextThemeUI>{'Vipps'}</TextThemeUI>
      </Label>
    </Stack>
  )
}

interface ChangePaymentMethodDialogInnerProps
  extends ChangePaymentMethodDialogProps {
  paymentMethod: PaymentMethods
  selectPaymentMethod: (next: PaymentMethods) => void
}

export const ChangePaymentMethodDialogInner: FC<
  ChangePaymentMethodDialogInnerProps
> = props => {
  return (
    <Dialog
      title={t({ id: 'Update payment details' })}
      errorMessage={props.errorMessage}
      onClose={props.onCancel}
      buttons={props.buttons}
      customButtons={props.customButtons}
      sx={{
        minHeight: ['100%', '400px'],
      }}
    >
      <PaymentMethodSelector
        paymentMethod={props.paymentMethod}
        selectPaymentMethod={props.selectPaymentMethod}
        enableVipps={props.enableVipps}
        sx={{
          pb: 4,
        }}
      />
      {props.children}
    </Dialog>
  )
}

const VippsDialog: FC<ChangePaymentMethodDialogInnerProps> = props => {
  return (
    <ChangePaymentMethodDialogInner
      {...props}
      customButtons={
        <Grid
          sx={{
            gap: 2,
            gridTemplateColumns: ['1fr', 'max-content max-content'],
            mt: 'auto',
            px: 4,
            justifyContent: ['stretch', 'end'],
          }}
        >
          <Button
            onClick={() => props.onCancel()}
            variant={'buttons.secondary'}
          >
            {t({ id: 'Cancel' })}
          </Button>
          <VippsButton
            loading={props.isLoading}
            onClick={ev => props.onSetupVipps()}
            type={'button'}
          />
        </Grid>
      }
    >
      <FormattedContent>
        <ul>
          <li>
            <Trans id={'Pay subscription with Vipps'} />
          </li>
          <li>
            <Trans id={'Approve subscription in the Vipps app'} />
          </li>
        </ul>
      </FormattedContent>
    </ChangePaymentMethodDialogInner>
  )
}

const StripeFormDialog: FC<ChangePaymentMethodDialogInnerProps> = props => {
  const elements = useElements()
  const [errors, setErrors] = useState<StripeValidationError>({})
  const isValid = Boolean(Object.keys(errors).length)

  const handleSubmit = (event?: FormEvent) => {
    event?.preventDefault()
    if (elements) {
      props.onVerify(elements.getElement(CardNumberElement))
    }
  }

  return (
    <ChangePaymentMethodDialogInner
      {...props}
      buttons={[
        {
          action: () => props.onCancel(),
          label: t({ id: 'Cancel' }),
        },
        {
          label: t({ id: 'Register new card' }),
          loading: props.isLoading,
          action: handleSubmit,
          disabled: !isValid,
        },
      ]}
    >
      <StripeForm
        onVerify={props.onVerify}
        errors={errors}
        setErrors={setErrors}
      />
    </ChangePaymentMethodDialogInner>
  )
}

export const ChangePaymentMethodDialog: FC<ChangePaymentMethodDialogProps> = ({
  initialPaymentMethod = 'stripe',
  ...props
}) => {
  const [paymentMethod, setPaymentMethod] =
    useState<PaymentMethods>(initialPaymentMethod)

  switch (paymentMethod) {
    case 'vipps':
      return (
        <VippsDialog
          {...props}
          paymentMethod={paymentMethod}
          selectPaymentMethod={next => {
            if (next !== paymentMethod) {
              props.setErrorMessage('')
            }
            setPaymentMethod(next)
          }}
        />
      )
    case 'stripe':
    default:
      return (
        <StripeFormDialog
          {...props}
          paymentMethod={paymentMethod}
          selectPaymentMethod={next => {
            if (next !== paymentMethod) {
              props.setErrorMessage('')
            }
            setPaymentMethod(next)
          }}
        />
      )
  }
}
