import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import type { BrandColorCssVar } from '@jotta/ui/types/ColorTypes'
import type { BrandThemeIcon } from '@jotta/types/Brand'
import * as Toast from '@radix-ui/react-toast'
import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'
import type { CSSProperties } from 'react'
import { useCallback, useLayoutEffect, useState } from 'react'
import styles from './RadixDialogToast.module.scss'
export const toastSeverity = [
  'info',
  'success',
  'warn',
  'error',
  'infoOutline',
  'successOutline',
  'warnOutline',
  'errorOutline',
  'sharePhotos',
] as const
type ToastSeverity = (typeof toastSeverity)[number]
const toastIcons: Record<ToastSeverity, BrandThemeIcon> = {
  info: 'SvgInfo',
  success: 'SvgCheckmark',
  warn: 'SvgBellAlert',
  error: 'SvgStop',
  infoOutline: 'SvgInfo',
  successOutline: 'SvgCheckmark',
  warnOutline: 'SvgBellAlert',
  errorOutline: 'SvgStop',
  sharePhotos: 'SvgInfo',
}
export interface RadixDialogToastProps extends Toast.ToastProps {
  title: string
  severity?: ToastSeverity
  disableAnimations?: boolean
  direction?: 'fromTop' | 'fromBottom'
}
const severityColors: Record<
  ToastSeverity,
  {
    bg: BrandColorCssVar
    text: BrandColorCssVar
    border?: BrandColorCssVar
  }
> = {
  info: { bg: '--color-light-gray', text: '--color-text' },
  error: { bg: '--color-danger', text: '--color-danger-text' },
  success: { bg: '--color-accent', text: '--color-background' },
  warn: { bg: '--color-warning', text: '--color-warning-text' },
  infoOutline: {
    bg: '--color-background',
    border: '--color-light-gray',
    text: '--color-text',
  },
  errorOutline: {
    bg: '--color-background',
    border: '--color-danger',
    text: '--color-text',
  },
  successOutline: {
    bg: '--color-background',
    border: '--color-accent',
    text: '--color-text',
  },
  warnOutline: {
    bg: '--color-background',
    border: '--color-warning',
    text: '--color-text',
  },
  sharePhotos: { bg: '--color-light-gray', text: '--color-text' },
}

export function useInternalOpenState(
  open: boolean,
  onOpenChange?: (open: boolean) => void,
) {
  const [internalOpen, setInternalOpen] = useState(open)

  useLayoutEffect(() => {
    if (onOpenChange) {
      return
    }

    if (open) {
      setInternalOpen(true)
    }
  }, [open, onOpenChange])

  const change = useCallback((open: boolean) => {
    setInternalOpen(open)
  }, [])

  return onOpenChange
    ? ([open, onOpenChange] as const)
    : ([internalOpen, change] as const)
}

export function RadixDialogToast({
  title,
  severity = 'info',
  disableAnimations = false,
  direction = 'fromTop',
  open: propOpen = false,
  onOpenChange,
  ...props
}: RadixDialogToastProps) {
  const hideCloseButton = Boolean(severity === 'sharePhotos')
  const isOutline = severity.endsWith('Outline')
  const className = clsx({
    [styles.toast]: true,
    [styles.toastOutline]: isOutline,
  })
  const style = {
    '--toast-border': `var(${
      severityColors[severity].border || 'transparent'
    })`,
    '--toast-color': `var(${severityColors[severity].text})`,
    '--toast-bg': `var(${severityColors[severity].bg})`,
  } as CSSProperties

  const [open, setOpen] = useInternalOpenState(propOpen, onOpenChange)

  if (disableAnimations) {
    return (
      <Toast.Toast
        {...props}
        open={open}
        onOpenChange={setOpen}
        className={className}
        style={style}
      >
        {isOutline && <PlainBrandIcon icon={toastIcons[severity]} />}
        <Toast.Title className={styles.title}>{title}</Toast.Title>
        {!hideCloseButton && (
          <Toast.Close>
            <PlainBrandIcon icon="SvgClose" />
          </Toast.Close>
        )}
      </Toast.Toast>
    )
  }
  const initialYPosition = direction === 'fromTop' ? '-500%' : '200%'

  return (
    <AnimatePresence>
      {open && (
        <Toast.Toast
          {...props}
          open={open}
          onOpenChange={setOpen}
          asChild
          forceMount
        >
          <motion.li
            initial={{
              y: initialYPosition,
              opacity: 0,
            }}
            animate={{
              y: 0,
              opacity: 1,
            }}
            exit={{
              y: initialYPosition,
              opacity: 0,
            }}
            className={className}
            style={style}
          >
            {isOutline && <PlainBrandIcon icon={toastIcons[severity]} />}
            <Toast.Title className={styles.title}>{title}</Toast.Title>{' '}
            {!hideCloseButton && (
              <Toast.Close>
                <PlainBrandIcon icon="SvgClose" />
              </Toast.Close>
            )}
          </motion.li>
        </Toast.Toast>
      )}
    </AnimatePresence>
  )
}
