import { useGetCustomer } from '@jotta/grpc-connect-client/customer'
import { useGetConfig } from '@jotta/grpc-connect-client/config'

export function useIntercomConfig() {
  const configAppId = useGetConfig()?.data?.brandingInfo?.intercomAppId
  const customer = useGetCustomer().data?.customer
  const intercom = customer?.intercom
  const appId = intercom?.appId
  const webHash = intercom?.webHash

  if (customer && webHash && appId) {
    return {
      appId,
      userData: {
        username: customer.username,
        userHash: webHash,
        fullName: customer.fullName,
        email: customer.email,
      },
    }
  } else if (configAppId) {
    return {
      appId: configAppId,
    }
  }
}
