import type { PropsWithChildren } from 'react'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { cn } from '@jotta/utils/css'

interface TabContext {
  insertTab: (tab: string) => () => void
  selected: string
}

const TabContext = createContext<TabContext>({
  insertTab: () => () => {},
  selected: '',
})

export function TabContent({
  name,
  children,
}: PropsWithChildren<{ name: string }>) {
  const { selected, insertTab } = useContext(TabContext)

  useEffect(() => {
    const remove = insertTab(name)

    return remove
  }, [name, insertTab])

  if (selected === name) {
    return <>{children}</>
  }

  return null
}

export function UpgradeTabSwitch({
  onChange = () => {},
  value = '',
  children,
}: PropsWithChildren<{ onChange?: (tab: string) => void; value?: string }>) {
  const [tabs, setTabs] = useState<string[]>([])
  const [selected, setSelected] = useState(value)

  const insertTab = useCallback((tab: string) => {
    setTabs(tabs => {
      if (tabs.includes(tab)) {
        // throw new Error(`Duplicate tab "${tab}"`)
        console.warn(`Duplicate tab "${tab}"`)
        return [...tabs]
      }
      return [...tabs, tab]
    })

    return () => {
      setTabs(tabs => {
        const i = tabs.indexOf(tab)
        if (i < 0) {
          return tabs
        }

        return tabs.slice().splice(i, 1)
      })
    }
  }, [])

  useEffect(() => {
    if (tabs.length && !selected) {
      setSelected(tabs[0])
    }
  }, [tabs, selected])

  const onClick = useCallback(
    (tab: string) => {
      onChange(tab)
    },
    [onChange],
  )

  const ctx = useMemo(() => ({ insertTab, selected }), [insertTab, selected])

  return (
    <TabContext.Provider value={ctx}>
      <div className="w-full">
        <div className="relative w-full">
          <div className="grid w-full auto-cols-fr grid-flow-col border-b-2 border-light text-grey3">
            {tabs.map(tab => (
              <button
                className={`row-start-1 flex h-12 items-start justify-center transition-all duration-150 ${selected === tab ? 'label-lg' : 'body-lg-sh'}`}
                onClick={() => setSelected(tab)}
                key={tab}
              >
                {tab}
              </button>
            ))}
            <div
              className="absolute bottom-[-1px] row-start-2 h-[3px] bg-[#744FF6] transition-all duration-150"
              style={{
                left: `${(100 / tabs.length) * tabs.indexOf(selected)}%`,
                width: `${100 / tabs.length}%`,
              }}
            />
          </div>
        </div>
        {children}
      </div>
    </TabContext.Provider>
  )
}
