import { baseGetPath } from '@jotta/grpc-js-client/files/getPath'
import { getAuthMeta } from '@jotta/grpc-js-client/grpcutils'
import {
  getAddressBook,
  getShareInfo,
  getSharingServiceClient,
} from '@jotta/grpc-js-client/sharingService'
import type { Avatar } from '@jotta/grpc-web/no/jotta/openapi/avatar_pb'
import type {
  Address,
  ShareInfo,
} from '@jotta/grpc-web/no/jotta/openapi/sharing/v2/sharing.v2_pb'
import {
  FolderShareInviteStatus,
  GetShareInfoRequest,
  ShareMode,
} from '@jotta/grpc-web/no/jotta/openapi/sharing/v2/sharing.v2_pb'
import { AppError } from '@jotta/types/AppError'
import type { ShareInfoObject } from '@jotta/types/Files'
import Debug from 'debug'
import { createQuery } from 'react-query-kit'
const debug = Debug('jotta:files:FileActionShareApi')

export type GetShareInfoStateObject = Awaited<
  ReturnType<typeof getShareInfoState>
>

export interface GetShareInfoStateParams {
  path: string
  token?: string
}
export const useShareInfo = createQuery<
  ShareInfoObject,
  {
    path: string
  },
  unknown
>({
  queryKey: ['shareInfo'],
  fetcher: async params => getShareInfo(params),
})

export async function getShareInfoState({
  path,
  token,
}: GetShareInfoStateParams) {
  const getShareInfoRequest = new GetShareInfoRequest()
  getShareInfoRequest.setPath(path)

  debug('getShareInfoRequest', getShareInfoRequest.toObject())

  const meta = await getAuthMeta(token)

  const response = await getSharingServiceClient().getShareInfo(
    getShareInfoRequest,
    meta,
  )
  const shareInfo = response.getShareInfo()?.toObject()

  if (!shareInfo) {
    throw new AppError({
      message: 'getShareInfo: shareInfo missing from response',
    })
  }
  const result = await parseShareInfo({
    path,
    token,
    shareInfo: shareInfo,
  })
  return result
}
export type ShareInfoUserStatus =
  | 'address'
  | 'not_sent'
  | 'sent'
  | 'declined'
  | 'member'
const inviteStatusMap: Record<FolderShareInviteStatus, ShareInfoUserStatus> = {
  [FolderShareInviteStatus.DECLINED]: 'declined',
  [FolderShareInviteStatus.SENT]: 'sent',
  [FolderShareInviteStatus.NOT_SENT]: 'not_sent',
  [FolderShareInviteStatus.UNKNOWN]: 'address',
}
export interface ShareInfoUser {
  status: ShareInfoUserStatus
  username: string
  name: string
  email: string
  avatar?: Avatar.AsObject
}
export async function parseShareInfo({
  path,
  shareInfo,
  token,
}: GetShareInfoStateParams & {
  shareInfo: ShareInfo.AsObject
}) {
  const addressesList: Address.AsObject[] = shareInfo.canShareCollaboratively
    ? await getAddressBook()
    : []
  const pathItem =
    shareInfo.pathItem ||
    (
      await baseGetPath({
        path,
        includeChildren: false,
        includeDeleted: false,
        token,
        limit: 1,
      })
    ).pathItem.toObject()
  const publicLinkId = pathItem.publicLinkId
  const shareMode: ShareMode = !publicLinkId
    ? ShareMode.NOT_SET
    : shareInfo.publicShareInfo?.shareMode || ShareMode.NOT_SET
  const { invitesList, membersList, canShareCollaboratively } = shareInfo

  const users: Map<string, ShareInfoUser> = new Map()

  for (const user of addressesList) {
    users.set(user.email, {
      status: 'address',
      email: user.email,
      name: '',
      username: user.username,
      avatar: user.avatar,
    })
  }
  for (const user of membersList) {
    users.set(user.email, {
      status: 'member',
      email: user.email,
      name: user.name,
      username: user.username,
      avatar: user.avatar,
    })
  }
  for (const user of invitesList) {
    const currentUser = users.get(user.email)
    if (currentUser) {
      users.set(currentUser.email, {
        ...currentUser,
        status: inviteStatusMap[user.status],
      })
    }
    users.set(user.email, {
      status: inviteStatusMap[user.status],
      email: user.email,
      name: '',
      username: '',
      avatar: undefined,
    })
  }
  return {
    title: pathItem.name,
    path,
    shareMode,
    publicLinkId,
    folderShareId: pathItem.folderShareId,
    users: Array.from(users.values()),
    canShareCollaboratively,
  }
}
