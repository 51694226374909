import { createBrowserRouter, createMemoryRouter } from 'react-router-dom'
import { RouterStore } from './RouterStore'
import type { Router, RouterNavigateFn, RouterNavigateParams } from './Types'

let router: Router
let store: RouterStore
export function createRouter(
  createBrowserRouterFn = createBrowserRouter,
  ...args: Parameters<typeof createBrowserRouter>
) {
  if (router) {
    throw new Error('router can only be created once')
  }
  router = createBrowserRouterFn(...args)
  store = new RouterStore(router)
  return router
}
export function createStorybookRouter(
  ...args: Parameters<typeof createMemoryRouter>
) {
  router = createMemoryRouter(...args)
  store = new RouterStore(router)
  return router
}

export function getRouter(): Router {
  if (!router) {
    throw new Error('Tried to get router before it was created')
  }
  return router
}
export function getRouterStore(): RouterStore {
  if (!store) {
    throw new Error('Tried to get RouterStore before route was created')
  }
  return store
}

export function navigate(...args: RouterNavigateParams) {
  if (!router) {
    throw new Error('router.navigate used before router was created')
  }
  // Override incorrect typings
  const navigate = router.navigate as any as RouterNavigateFn
  navigate(...args)
}
if (process.env.NODE_ENV === 'development') {
  ;(globalThis as any).navigate = navigate
  ;(globalThis as any).routerStore = getRouterStore
}
