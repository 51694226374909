/**
 * THIS FILE IS AUTOGENERATED
 * Do not edit directly as it will be overwritten by the task that generated it
 */

export type BrandName =
  | 'ELKO Cloud'
  | 'Elgiganten Cloud'
  | 'Elkjøp Cloud'
  | 'Gigantti Cloud'
  | 'Halebop Cloud'
  | 'Jottacloud'
  | 'MediaMarkt Cloud'
  | 'Onlime'
  | 'Saturn Cloud'
  | 'Styrerommet'
  | 'T-Sky'
  | 'Tele2 Cloud'
  | 'Telia Cloud'
  | 'Telia Sky'
  | 'TestCloud'

export const brandNames: readonly BrandName[] = [
  'ELKO Cloud',
  'Elgiganten Cloud',
  'Elkjøp Cloud',
  'Gigantti Cloud',
  'Halebop Cloud',
  'Jottacloud',
  'MediaMarkt Cloud',
  'Onlime',
  'Saturn Cloud',
  'Styrerommet',
  'T-Sky',
  'Tele2 Cloud',
  'Telia Cloud',
  'Telia Sky',
  'TestCloud',
] as const

export type BrandKey =
  | 'COMHEM'
  | 'ELGIGANTEN'
  | 'ELKJOP'
  | 'ELKO'
  | 'GET'
  | 'GIGANTTI'
  | 'HALEBOP'
  | 'JOTTACLOUD'
  | 'MEDIAMARKT'
  | 'OBOS'
  | 'ONLIME_WL'
  | 'SATURN'
  | 'TDC_NO'
  | 'TELIA_CLOUD'
  | 'TEST'

export const brandKeys: readonly BrandKey[] = [
  'COMHEM',
  'ELGIGANTEN',
  'ELKJOP',
  'ELKO',
  'GET',
  'GIGANTTI',
  'HALEBOP',
  'JOTTACLOUD',
  'MEDIAMARKT',
  'OBOS',
  'ONLIME_WL',
  'SATURN',
  'TDC_NO',
  'TELIA_CLOUD',
  'TEST',
] as const

export type BrandAlias =
  | 'comhem'
  | 'elgiganten'
  | 'elkjop'
  | 'elko'
  | 'gigantti'
  | 'halebop'
  | 'jottacloud'
  | 'mediamarkt'
  | 'obos'
  | 'onlime'
  | 'saturn'
  | 'teliacloud'
  | 'teliasky'
  | 'test'
  | 'tsky'

export const brandAliases: readonly BrandAlias[] = [
  'comhem',
  'elgiganten',
  'elkjop',
  'elko',
  'gigantti',
  'halebop',
  'jottacloud',
  'mediamarkt',
  'obos',
  'onlime',
  'saturn',
  'teliacloud',
  'teliasky',
  'test',
  'tsky',
] as const

export const brands = {
  ELKO: { name: 'ELKO Cloud', code: 'ELKO', alias: 'elko' },
  ELGIGANTEN: {
    name: 'Elgiganten Cloud',
    code: 'ELGIGANTEN',
    alias: 'elgiganten',
  },
  ELKJOP: { name: 'Elkjøp Cloud', code: 'ELKJOP', alias: 'elkjop' },
  GIGANTTI: { name: 'Gigantti Cloud', code: 'GIGANTTI', alias: 'gigantti' },
  HALEBOP: { name: 'Halebop Cloud', code: 'HALEBOP', alias: 'halebop' },
  JOTTACLOUD: { name: 'Jottacloud', code: 'JOTTACLOUD', alias: 'jottacloud' },
  MEDIAMARKT: {
    name: 'MediaMarkt Cloud',
    code: 'MEDIAMARKT',
    alias: 'mediamarkt',
  },
  ONLIME_WL: { name: 'Onlime', code: 'ONLIME_WL', alias: 'onlime' },
  SATURN: { name: 'Saturn Cloud', code: 'SATURN', alias: 'saturn' },
  OBOS: { name: 'Styrerommet', code: 'OBOS', alias: 'obos' },
  TDC_NO: { name: 'T-Sky', code: 'TDC_NO', alias: 'tsky' },
  COMHEM: { name: 'Tele2 Cloud', code: 'COMHEM', alias: 'comhem' },
  TELIA_CLOUD: {
    name: 'Telia Cloud',
    code: 'TELIA_CLOUD',
    alias: 'teliacloud',
  },
  GET: { name: 'Telia Sky', code: 'GET', alias: 'teliasky' },
  TEST: { name: 'TestCloud', code: 'TEST', alias: 'test' },
} as const satisfies Record<
  BrandKey,
  {
    code: BrandKey
    alias: BrandAlias
    name: BrandName
  }
>

export const brandsByName: Record<
  BrandName,
  {
    code: BrandKey
    alias: BrandAlias
    name: BrandName
  }
> = {
  'ELKO Cloud': { name: 'ELKO Cloud', code: 'ELKO', alias: 'elko' },
  'Elgiganten Cloud': {
    name: 'Elgiganten Cloud',
    code: 'ELGIGANTEN',
    alias: 'elgiganten',
  },
  'Elkjøp Cloud': { name: 'Elkjøp Cloud', code: 'ELKJOP', alias: 'elkjop' },
  'Gigantti Cloud': {
    name: 'Gigantti Cloud',
    code: 'GIGANTTI',
    alias: 'gigantti',
  },
  'Halebop Cloud': { name: 'Halebop Cloud', code: 'HALEBOP', alias: 'halebop' },
  Jottacloud: { name: 'Jottacloud', code: 'JOTTACLOUD', alias: 'jottacloud' },
  'MediaMarkt Cloud': {
    name: 'MediaMarkt Cloud',
    code: 'MEDIAMARKT',
    alias: 'mediamarkt',
  },
  Onlime: { name: 'Onlime', code: 'ONLIME_WL', alias: 'onlime' },
  'Saturn Cloud': { name: 'Saturn Cloud', code: 'SATURN', alias: 'saturn' },
  Styrerommet: { name: 'Styrerommet', code: 'OBOS', alias: 'obos' },
  'T-Sky': { name: 'T-Sky', code: 'TDC_NO', alias: 'tsky' },
  'Tele2 Cloud': { name: 'Tele2 Cloud', code: 'COMHEM', alias: 'comhem' },
  'Telia Cloud': {
    name: 'Telia Cloud',
    code: 'TELIA_CLOUD',
    alias: 'teliacloud',
  },
  'Telia Sky': { name: 'Telia Sky', code: 'GET', alias: 'teliasky' },
  TestCloud: { name: 'TestCloud', code: 'TEST', alias: 'test' },
}

export const brandsByAlias: Record<
  BrandAlias,
  {
    code: BrandKey
    alias: BrandAlias
    name: BrandName
  }
> = {
  elko: { name: 'ELKO Cloud', code: 'ELKO', alias: 'elko' },
  elgiganten: {
    name: 'Elgiganten Cloud',
    code: 'ELGIGANTEN',
    alias: 'elgiganten',
  },
  elkjop: { name: 'Elkjøp Cloud', code: 'ELKJOP', alias: 'elkjop' },
  gigantti: { name: 'Gigantti Cloud', code: 'GIGANTTI', alias: 'gigantti' },
  halebop: { name: 'Halebop Cloud', code: 'HALEBOP', alias: 'halebop' },
  jottacloud: { name: 'Jottacloud', code: 'JOTTACLOUD', alias: 'jottacloud' },
  mediamarkt: {
    name: 'MediaMarkt Cloud',
    code: 'MEDIAMARKT',
    alias: 'mediamarkt',
  },
  onlime: { name: 'Onlime', code: 'ONLIME_WL', alias: 'onlime' },
  saturn: { name: 'Saturn Cloud', code: 'SATURN', alias: 'saturn' },
  obos: { name: 'Styrerommet', code: 'OBOS', alias: 'obos' },
  tsky: { name: 'T-Sky', code: 'TDC_NO', alias: 'tsky' },
  comhem: { name: 'Tele2 Cloud', code: 'COMHEM', alias: 'comhem' },
  teliacloud: { name: 'Telia Cloud', code: 'TELIA_CLOUD', alias: 'teliacloud' },
  teliasky: { name: 'Telia Sky', code: 'GET', alias: 'teliasky' },
  test: { name: 'TestCloud', code: 'TEST', alias: 'test' },
}

export const brandKeyToAliasMap = {
  ELKO: 'elko',
  ELGIGANTEN: 'elgiganten',
  ELKJOP: 'elkjop',
  GIGANTTI: 'gigantti',
  HALEBOP: 'halebop',
  JOTTACLOUD: 'jottacloud',
  MEDIAMARKT: 'mediamarkt',
  ONLIME_WL: 'onlime',
  SATURN: 'saturn',
  OBOS: 'obos',
  TDC_NO: 'tsky',
  COMHEM: 'comhem',
  TELIA_CLOUD: 'teliacloud',
  GET: 'teliasky',
  TEST: 'test',
} as const satisfies Record<BrandKey, BrandAlias>
