import { createObservableRef } from '@jotta/hooks'
import { getFilenameFromPath } from '@jotta/utils/pathUtils'
import type { Debugger } from 'debug'
import Debug from 'debug'
import { action, makeAutoObservable, toJS, values } from 'mobx'
import type { ChangeEventHandler } from 'react'
import type { FolderStore } from './FolderStore'
import { ObservableCaseInsensitiveSet } from '@jotta/collection/ObservableCaseInsensitiveSet'

const debug = Debug(`jotta:files:selection`)

export class FolderSelectionStore {
  _selection = new ObservableCaseInsensitiveSet()
  private _log: Debugger
  private _folder: FolderStore
  private _shiftPressed = false
  private _lastSelection = {
    selected: false,
    id: '',
  }
  constructor(folder: FolderStore) {
    this._folder = folder
    this._log = folder.log.extend('selection')
    this._log('Create')
    makeAutoObservable<typeof this, '_log'>(
      this,
      {
        _log: false,
      },
      {
        autoBind: true,
      },
    )
  }
  headerInputRef = createObservableRef<HTMLInputElement>()
  onKeyboardShiftPress(e: KeyboardEvent) {
    // this._log('key', e)
    if (e.key !== 'Shift') {
      return
    }
    switch (e.type) {
      case 'keydown':
        {
          this._log('Shift pressed')
          this._shiftPressed = true
        }
        break
      case 'keyup':
        {
          this._log('Shift released')
          this._shiftPressed = false
        }
        break

      default:
        break
    }
  }
  onSelectCheckboxChange = action<ChangeEventHandler<HTMLInputElement>>(
    'onSelectChange',
    e => {
      const id = e.target.value
      const selected = e.target.checked
      const lastId = this._lastSelection.id
      const lastSelected = this._lastSelection.selected
      this._log('onSelectChange', {
        id,
        selected,
      })
      this._lastSelection = {
        id,
        selected,
      }
      // this._log(e.target.value, [...this.ids])
      if (this._shiftPressed && lastId) {
        this.selectRangeById(id, lastId, lastSelected)
        return
      }
      if (selected) {
        this._log('onSelectChange', 'select')
        this.select(id)
        return
      }

      this._log('onSelectChange', 'unselect')
      this.unselect(id)
    },
  )

  select(id: string) {
    // this._log('Select', id)
    this._selection.add(id)
  }
  unselect(id: string) {
    // this._log('Unselect', id)
    this._selection.delete(id)
  }
  setIndeterminate(isIndeterminate: boolean) {
    const el = this.headerInputRef.current
    if (el) {
      el.indeterminate = isIndeterminate
    }
  }
  toggle(id: string) {
    if (this._selection.has(id)) {
      this.unselect(id)
    } else {
      this.select(id)
    }
  }
  selectAll() {
    this._selection.replace(toJS(this._folder.ids))
  }
  selectRangeById(id1: string, id2: string, selected: boolean) {
    const [i1, i2] = [
      this._folder.idsArray.indexOf(id1),
      this._folder.idsArray.indexOf(id2),
    ].sort()
    if (i1 >= 0 && i2 >= 0) {
      const ids = this._folder.idsArray.slice(i1, i2 + 1)
      // this._log('selectRangeById', id1, id2, i1, i2, selected, ids)
      if (selected) {
        for (const id of ids) {
          this._selection.add(id)
        }
      } else {
        for (const id of ids) {
          this._selection.delete(id)
        }
      }
    }
  }
  selectNone() {
    this._selection.clear()
  }
  toggleSelectAll() {
    if (!this.allSelected) {
      this._log('Toggle select: all')
      this.selectAll()
    } else {
      this._log('Toggle select: none')
      this.selectNone()
    }
  }
  clear() {
    this._selection.clear()
  }
  get ids() {
    return toJS(this._folder.ids)
  }
  get selectedIds() {
    return values(this._selection).filter(this.ids.has)
  }
  get selectedNames() {
    return this.selectedIds.map(getFilenameFromPath)
  }
  get total() {
    return this._selection.size
  }

  get hasSelection() {
    return Boolean(this._selection.size)
  }
  get oneSelected() {
    return this._selection.size === 1
  }
  get allSelected() {
    return this._selection.size === this._folder.ids.size
  }
  get someSelected() {
    return Boolean(!this.allSelected && this._selection.size)
  }
}
