import { useMemo, useEffect } from 'react'
import debounce from 'lodash/debounce'
import type { DebounceSettings } from 'lodash'

export function useDebounce<T extends (...args: any[]) => any>(
  callback: T,
  delay: number,
  { leading, trailing, maxWait }: DebounceSettings = {},
) {
  const options = useMemo(
    () => ({
      ...(typeof leading === 'boolean' && { leading }),
      ...(typeof trailing === 'boolean' && { trailing }),
      ...(typeof maxWait === 'number' && { maxWait }),
    }),
    [leading, trailing, maxWait],
  )

  const debounced = useMemo(
    () => debounce(callback, delay, options),
    [delay, options, callback],
  )

  useEffect(
    () => () => {
      debounced.cancel()
    },
    [debounced],
  )

  return debounced
}
