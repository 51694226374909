import { ErrorPage } from '@jotta/ui/ErrorPage'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import { ErrorBoundary } from '@sentry/react'
import { Suspense } from 'react'
import { AppLayoutSignup } from '@jotta/ui/AppLayoutSignup'
import { DeviceLogin } from './DeviceLogin'
import { useAndConsumeSearchParam } from '@jotta/router'
import { WithAuth } from '@jotta/auth-client/WithAuth'
import { AuthTransportProvider } from '@jotta/grpc-connect-client/provider'

export function DeviceLoginRoute() {
  const code = useAndConsumeSearchParam('code')
  // TODO should ErrorBoundary+Suspense be included in AppLayoutSignup?
  return (
    <WithAuth>
      <AuthTransportProvider>
        <AppLayoutSignup>
          <ErrorBoundary
            fallback={({ error, resetError }) => (
              <ErrorPage error={error} resetError={resetError} />
            )}
          >
            <Suspense fallback={<LoadingOverlay open />}>
              <DeviceLogin code={code} />
            </Suspense>
          </ErrorBoundary>
        </AppLayoutSignup>
      </AuthTransportProvider>
    </WithAuth>
  )
}
