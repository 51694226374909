import { isAuthenticated } from '@jotta/auth-client/useAuthStatus'
import { getAuthMeta } from '@jotta/grpc-js-client/grpcutils'
import { getSharingServiceClient } from '@jotta/grpc-js-client/sharingService'
import type { PendingInvite } from '@jotta/grpc-web/no/jotta/openapi/sharing/v2/sharing.v2_pb'
import { ListPendingInvitesRequest } from '@jotta/grpc-web/no/jotta/openapi/sharing/v2/sharing.v2_pb'
import { queryClient } from '@jotta/query'
import Debug from 'debug'
import { runInAction } from 'mobx'
import { useEffect } from 'react'
import { createQuery } from 'react-query-kit'
import { useFileStore } from '../../FileStore/FileContext'
const debug = Debug('jotta:files:usePathItem')

export const usePendingInvitesQuery = createQuery<
  PendingInvite.AsObject[],
  void,
  unknown
>({
  queryKey: ['PendingInvites'],
  async fetcher() {
    const client = getSharingServiceClient()
    const meta = await getAuthMeta()
    const req = new ListPendingInvitesRequest()
    const res = await client.listPendingInvites(req, meta)
    const { invitesList } = res.toObject()
    return invitesList
  },
})

export async function fetchPendingInvites(): Promise<PendingInvite.AsObject[]> {
  if (await isAuthenticated()) {
    const invites = await queryClient.fetchQuery(
      usePendingInvitesQuery.getFetchOptions(),
    )
    return invites
  }
  return []
}
export function usePendingInvites(enabled = false) {
  const query = usePendingInvitesQuery({
    enabled,
  })
  const store = useFileStore()
  const invites = query.data
  useEffect(() => {
    if (invites) {
      runInAction(() => {
        store.pendingInvites = invites
      })
    }
  }, [invites, store])
}
