import { autorun } from 'mobx'
import { useEffect, useState } from 'react'
import { getCurrentBreakpoint } from '../utils/setCSSCustomProperty'
import { useBrandStore } from './useBrandTheme'

export function useBreakpointIndex() {
  const [br, setBr] = useState(getCurrentBreakpoint())
  const branding = useBrandStore()
  useEffect(
    () => autorun(() => setBr(branding.currentBreakpointIndex)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
  return br
}
