import { isBrandKey } from '@jotta/types/Brand'
import type { BrandKey } from '@jotta/types/generated/brands'
import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export function getBrandFromLocalStorage() {
  const brand = localStorage.getItem('brand')
  if (isBrandKey(brand)) {
    return brand
  }
  if (brand) {
    localStorage.removeItem('brand')
  }
}
export function storeBrandInLocalStorage(brand?: BrandKey) {
  if (!brand) {
    localStorage.removeItem('brand')
    return
  }
  localStorage.setItem('brand', brand)
}
export function useBrandParam() {
  const [brand, setBrand] = useState<BrandKey | undefined>(
    getBrandFromLocalStorage(),
  )

  const [search, setSearch] = useSearchParams()
  const updateBrand = useCallback(
    (nextBrand?: BrandKey) => {
      if (nextBrand && nextBrand !== brand) {
        setSearch({
          nextBrand,
        })
        storeBrandInLocalStorage(nextBrand)
        setBrand(nextBrand)
        window.location.reload()
      }
    },
    [brand, setSearch],
  )
  useEffect(() => {
    const brandParam =
      search.get('brand') || search.get('bC') || getBrandFromLocalStorage()
    if (isBrandKey(brandParam)) {
      updateBrand(brandParam)
    }
  }, [search, updateBrand])

  return [brand, updateBrand] as const
}
