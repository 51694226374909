import { getFamilyInvite } from '@jotta/grpc-connect-openapi/esm/openapi/invite/v1/invite.v1-InviteService_connectquery'
import { createConnectQuery } from '../createConnectQuery'

export const useFamilyInviteQuery = createConnectQuery(getFamilyInvite)

export function useFamilyInvite(familyInviteId: string) {
  return useFamilyInviteQuery({
    variables: { familyInviteId },
    enabled: Boolean(familyInviteId),
  })
}
