import { Button } from '@jotta/ui/Button'
import { observer } from 'mobx-react-lite'

export interface WaitlistButtonProps {
  handleJoinWaitlist: () => void
}

export const WaitlistButton: React.FC<WaitlistButtonProps> = observer(
  ({ handleJoinWaitlist }) => {
    return (
      <Button
        onClick={handleJoinWaitlist}
        type="submit"
        variant="buttons.download"
        sx={{
          color: '#fff',
          backgroundColor: 'primary',
          backgroundImage: 'linear-gradient(-180deg,#9c61e8,#fd7efc)',
          border: 'none',
          '&:not(:disabled):hover': {
            border: 'none',
            boxShadow: '0 0.5em 0.5em -0.4em rgba(156,97,232)',
          },
        }}
      >
        Join Smart Photo Search Beta Waitlist
      </Button>
    )
  },
)
