import type { Interpolation } from '@emotion/react'
import type { Assign } from '@jotta/types/TypeUtils'
import type { ComponentPropsWithRef, ElementType } from 'react'
import type { BoxOwnProps as UiBoxOwnProps } from 'theme-ui'
import { Box as UiBox } from 'theme-ui'
import type { BrandTheme } from '../../types/BrandTheme'
import type { BrandColorOrString } from '../../types/ColorTypes'
import type { SelectVariant, VariantGroupOrAll } from '../../types/VariantTypes'

/**
 * Override the default theme-ui Box component props with these
 */

export type BoxOwnProps<V extends VariantGroupOrAll = 'all'> = {
  as?: React.ElementType
  bg?: BrandColorOrString
  backgroundColor?: BrandColorOrString
  variant?: SelectVariant<V>
  css?: Interpolation<BrandTheme>
  className?: string
} & UiBoxOwnProps

export type BoxPropsAs<
  T extends ElementType = 'div',
  P extends {} = {},
  V extends VariantGroupOrAll = 'all',
> = P & BoxOwnProps<V> & ComponentPropsWithRef<T>

export type BoxProps<
  T extends ElementType = 'div',
  P = BoxOwnProps,
> = React.ForwardRefExoticComponent<
  Omit<Assign<React.ComponentPropsWithRef<T>, P>, 'ref'> &
    React.RefAttributes<any>
>

export const Box = UiBox as BoxProps
Box.displayName = 'Box'
export const SvgBox = UiBox as BoxProps<'svg', BoxOwnProps<'all'>>
export const AnchorBox = UiBox as BoxProps<'a', BoxOwnProps<'all'>>
export type ContainerProps = BoxProps<'span', BoxOwnProps<'all'>>

export const Container = UiBox as ContainerProps
