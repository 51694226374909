import { observer } from 'mobx-react-lite'
import { crush, isArray, isObject, listify } from 'radash'
import type { PropsWithChildren } from 'react'
import { Fragment } from 'react'
import { mergeAll } from 'remeda'
import { Box } from '../Box/Box'
export function DefinitionList({
  children,
  ...props
}: PropsWithChildren<{
  className?: string
}>) {
  return (
    <Box as="dl" variant="styles.dl" {...props}>
      {children}
    </Box>
  )
}

export interface DefinitionListFromObjectProps {
  value?: unknown
  className?: string
}
export const DefinitionListFromObject = observer<DefinitionListFromObjectProps>(
  function DefinitionListFromObject({ value, ...props }) {
    if (!value) {
      return null
    }
    const entries = (
      isArray(value)
        ? listify(crush(mergeAll(value)), (k, v) => [k, String(v)])
        : isObject(value)
          ? listify(crush(value), (k, v) => [k, String(v)])
          : [['Value', String(value)]]
    ).sort(([a], [b]) => a.localeCompare(b))
    return (
      <DefinitionList {...props}>
        {entries.map(([k, v]) => (
          <Fragment key={k}>
            <dt>{k}</dt>
            <dd>{String(v)}</dd>
          </Fragment>
        ))}
      </DefinitionList>
    )
  },
)
