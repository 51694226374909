// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PdfView_embed__m69k5{all:unset;bottom:0;height:100%;left:0;position:absolute;right:0;top:0;width:100%}`, "",{"version":3,"sources":["webpack://./../../libraries/files/src/FileView/PdfView/PdfView.module.scss"],"names":[],"mappings":"AACA,sBACE,SAAA,CAOA,QAAA,CALA,WAAA,CAGA,MAAA,CAFA,iBAAA,CAGA,OAAA,CAFA,KAAA,CAHA,UAMA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"embed": `PdfView_embed__m69k5`
};
export default ___CSS_LOADER_EXPORT___;
