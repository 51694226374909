// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NoSharedPhotos_container__UJaak{align-items:center;display:flex;flex-direction:column;height:100%;justify-content:center;text-align:center;width:100%}.NoSharedPhotos_defaultSvg__hQ9oc{background-position:50%;height:192px;width:270px}.NoSharedPhotos_title__0kVT5{color:var(--color-purple-hue-gray);font-size:var(--fz4);font-weight:var(--fw-heading);margin:14px}`, "",{"version":3,"sources":["webpack://./../../libraries/photos/src/components/NoSharedPhotos/NoSharedPhotos.module.scss"],"names":[],"mappings":"AACA,iCAGE,kBAAA,CAFA,YAAA,CAGA,qBAAA,CACA,WAAA,CAEA,sBAAA,CALA,iBAAA,CAIA,UACA,CAGF,kCAGE,uBAAA,CADA,YAAA,CADA,WAEA,CAGF,6BACE,kCAAA,CAEA,oBAAA,CADA,6BAAA,CAEA,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `NoSharedPhotos_container__UJaak`,
	"defaultSvg": `NoSharedPhotos_defaultSvg__hQ9oc`,
	"title": `NoSharedPhotos_title__0kVT5`
};
export default ___CSS_LOADER_EXPORT___;
