// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VirtualGrid_list__U6eku{width:100%;--height:calc(var(--rows)*var(--row-height));height:var(--height);position:relative;--width:calc(100%/var(--columns, 2));--grid-line-width:var(--virtuallist-placeholder-line-width,2px);--grid-line-color:var(--virtuallist-placeholder-line-color,#fff);--inner-line-width:calc(var(--grid-line-width)/2);--bg-offset:calc(0px - var(--grid-line-width)/2)}.VirtualGrid_list__U6eku:before{background:linear-gradient(var(--grid-line-color) var(--grid-line-width),#0000 var(--grid-line-width)),linear-gradient(90deg,var(--grid-line-color) var(--grid-line-width),#0000 var(--grid-line-width)),rgba(0,0,0,var(--grid-opacity,.1));background-position:var(--bg-offset) var(--bg-offset);background-size:var(--width) var(--row-height);content:" ";height:calc(100% - var(--row-height));width:100%}.VirtualGrid_list__U6eku:before,.VirtualGrid_row__Rxvrt{left:0;position:absolute;top:0}.VirtualGrid_row__Rxvrt{aspect-ratio:1;display:flex;height:var(--row-height);overflow:hidden;width:var(--width)}`, "",{"version":3,"sources":["webpack://./../../libraries/virtualgrid/src/VirtualGrid.module.scss"],"names":[],"mappings":"AACA,yBACE,UAAA,CACA,4CAAA,CACA,oBAAA,CACA,iBAAA,CACA,oCAAA,CACA,+DAAA,CACA,gEAAA,CACA,iDAAA,CACA,gDAAA,CACA,gCAOE,2OAEE,CAWF,qDAAA,CACA,8CAAA,CApBA,WAAA,CAKA,qCAAA,CADA,UAgBA,CAGJ,wDApBI,MAAA,CAFA,iBAAA,CACA,KA6BF,CARF,wBAGE,cAAA,CACA,YAAA,CAFA,wBAAA,CAMA,eAAA,CAPA,kBAOA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `VirtualGrid_list__U6eku`,
	"row": `VirtualGrid_row__Rxvrt`
};
export default ___CSS_LOADER_EXPORT___;
