import { BrandLogo } from '@jotta/ui/BrandLogo'
import { useBrandTheme } from '@jotta/ui/useBrandTheme'
import type { ReactNode } from 'react'
import { Outlet } from 'react-router-dom'
import { LanguageSelector } from '../LanguageSelector/LanguageSelector'
import { PublicFooterLinks } from '../PublicFooter/PublicFooterLinks'
import { PublicFooterText } from '../PublicFooter/PublicFooterText'
import styles from './AppLayoutSignup.module.scss'

export function AppLayoutSignup({ children }: { children?: ReactNode }) {
  const { theme } = useBrandTheme()

  return (
    <div className={styles.container}>
      <header
        className="flex justify-between"
        sx={{ variant: 'layout.appHeader' }}
      >
        {theme.branding.header.showBrandLogo && (
          <a href="/">
            <BrandLogo icon="SvgLogoHeader" />
          </a>
        )}
        <div
          sx={{
            variant: 'layout.appHeaderContent',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <LanguageSelector />
        </div>
      </header>
      <main>{children || <Outlet />}</main>
      <footer>
        <div className={styles.footerContent}>
          <PublicFooterLinks />
          <PublicFooterText />
        </div>
      </footer>
    </div>
  )
}
export function AppLayoutSignupContent({
  title,
  children,
}: {
  title: ReactNode
  children?: ReactNode
}) {
  return (
    <>
      <h1>{title}</h1>
      <div className={styles.content}>{children}</div>
    </>
  )
}
