import path from 'path'

export const photosApiBaseUrl =
  process.env.NODE_ENV === 'test'
    ? 'https://private-anon-c1e44cdabf-jpapiv1.apiary-mock.com/photos/v1'
    : process.env.PHOTOS_API
      ? process.env.PHOTOS_API
      : 'https://api.jottacloud.com/photos/v1'
export const getPhotosApiUrl = <P extends {}>(
  pathStr: string = '/',
  params?: P,
): URL => {
  const p = path.normalize(path.join('/photos/v1/', pathStr, '/'))
  const apiUrl = new URL(
    `${path.dirname(p)}/${path.basename(p)}`,
    photosApiBaseUrl,
  )
  if (params && typeof params === 'object') {
    Object.entries(params).forEach(([key, val]) => {
      apiUrl.searchParams.append(key, String(val))
    })
  }
  return apiUrl
}
