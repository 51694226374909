import { Box } from '@jotta/ui/Box'
import { Headline2 } from '@jotta/ui/Headline'
import { Paragraph } from '@jotta/ui/Text'
import { observer } from 'mobx-react-lite'

import { WaitlistButton } from '../WaitlistButton/WaitlistButton'

export interface InactiveSectionProps {
  brandName: string
  handleJoinWaitlist: () => void
}

export const InactiveSection: React.FC<InactiveSectionProps> = observer(
  ({ brandName, handleJoinWaitlist }) => {
    return (
      <>
        <Headline2
          sx={{
            variant: 'styles.headerText',
          }}
        >
          Use text to smart search your photos for faster results, the latest AI
          innovation from {brandName}.
        </Headline2>

        <Paragraph
          sx={{
            variant: 'styles.subHeaderText',
          }}
        >
          Want to be the first to try Smart Photo Search Beta? Sign up to get
          early access.
        </Paragraph>

        <Box sx={{ p: '8px', textAlign: 'center' }}>
          <WaitlistButton handleJoinWaitlist={handleJoinWaitlist} />
        </Box>
      </>
    )
  },
)
