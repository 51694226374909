import { BrandIcon } from '@jotta/ui/BrandIcon'
import { Trans } from '@lingui/macro'
import { observer } from 'mobx-react-lite'
import type { PropsWithChildren } from 'react'
import styles from './NoSharedPhotos.module.scss'

import Debug from 'debug'
const debug = Debug('jotta:NoSharedPhotos')
export const NoSharedPhotos = observer<PropsWithChildren<{}>>(
  function NoSharedPhotos({ children }) {
    return (
      <div className={styles.container}>
        <BrandIcon icon="SvgNoSharedPhotos" className={styles.defaultSvg} />
        <h2 className={styles.title}>
          <Trans>You haven't shared anything yet!</Trans>
        </h2>
      </div>
    )
  },
)
