import dayjs from 'dayjs'
import { useRef, useEffect, useState, useMemo } from 'react'
import { useQuery, useMutation } from '@tanstack/react-query'
import { AppLayoutPortal } from '@jotta/ui/AppLayoutPortal'
import { useDebounce } from '@jotta/hooks'
import { bisectLeft } from '@jotta/utils/bisect'
import { fetchTimeline } from '../../../api/photosApi'
import throttle from 'lodash/throttle'
import { motion, AnimatePresence } from 'framer-motion'

/* eslint-disable jsx-a11y/no-static-element-interactions */

export function fromEpoch(epoch: string) {
  return dayjs.utc(Number(epoch.substring(0, epoch.length - 6)))
}

export function toEpoch(date: dayjs.Dayjs = dayjs()) {
  return `${date.valueOf()}000000`
}

export interface ScrubberSegment {
  fraction: number
  label: string
  type: 'hour' | 'day' | 'month' | 'year'
  date: Date
  limDate: Date
}

class ScrubberHelper {
  private readonly scale = 0
  private readonly fractionOffset: number[] = []

  constructor(public readonly segments: ScrubberSegment[] = []) {
    for (const seg of segments) {
      this.scale += seg.fraction
      this.fractionOffset.push(this.scale)
    }
  }

  public getSegmentIndexFromOffset(offset: number) {
    const i = bisectLeft(
      this.fractionOffset,
      fraction => fraction - offset * this.scale,
    )

    return Math.min(Math.max(i, 0), this.segments.length - 1)
  }

  public getSegmentFromOffset(offset: number) {
    return this.segments[this.getSegmentIndexFromOffset(offset)]
  }

  public getDateFromOffset(offset: number): dayjs.Dayjs {
    const i = this.getSegmentIndexFromOffset(offset)
    const seg = this.segments[i]
    const currentFrac = seg.fraction
    const prevOffset = this.fractionOffset[i - 1] || 0
    const frac = 1 - (offset * this.scale - prevOffset) / currentFrac

    return dayjs(
      (seg.limDate.valueOf() - seg.date.valueOf()) * frac + seg.date.valueOf(),
    )
  }

  public getOffsetFromDate(date: dayjs.Dayjs): number {
    let i = bisectLeft(
      this.segments,
      seg => date.valueOf() - seg.date.valueOf(),
    )

    i = Math.min(Math.max(i, 0), this.segments.length - 1)
    const seg = this.segments[i]

    const diff = Math.max(0, date.valueOf() - seg.date.valueOf())
    const frac = 1 - diff / (seg.limDate.valueOf() - seg.date.valueOf())

    return (
      (frac * seg.fraction + (this.fractionOffset[i - 1] || 0)) / this.scale
    )
  }

  public get gridTemplateRows(): string {
    return this.segments.map(seg => `${seg.fraction}fr`).join(' ')
  }
}

async function getScrubberDates(
  firstTs: string | undefined,
  lastTs: string | undefined,
): Promise<ScrubberSegment[]> {
  if (!firstTs || !lastTs) {
    return []
  }

  const first = fromEpoch(firstTs)
  const last = fromEpoch(lastTs)

  let current = first.startOf('year')
  let limDate = first.toDate()
  const segments: ScrubberSegment[] = []

  // First
  segments.push({
    date: current.toDate(),
    limDate,
    label: `${current.year()}`,
    type: 'year',
    fraction: 1,
  })

  limDate = current.toDate()

  // Five single-years
  for (let i = 0; i < 5 && current.year() > last.year(); i++) {
    current = current.subtract(1, 'year')
    segments.push({
      date: current.toDate(),
      label: `${current.year()}`,
      type: 'year',
      fraction: 1,
      limDate,
    })
    limDate = current.toDate()
  }

  // Align to nearest five-year
  let sub = current.year() % 5 || 5
  current = current.subtract(sub, 'year')

  for (let i = 0; i < 4 && current.year() >= last.year(); i++) {
    segments.push({
      date: current.toDate(),
      label: `${current.year()}`,
      type: 'year',
      fraction: 1,
      limDate,
    })

    limDate = current.toDate()
    current = current.subtract(5, 'year')
  }

  // Align to nearest five-year
  sub = current.year() % 10 || 10
  current = current.subtract(sub, 'year')

  for (let i = 0; i < 8 && current.year() >= last.year(); i++) {
    segments.push({
      date: current.toDate(),
      label: `${current.year()}`,
      type: 'year',
      fraction: 1,
      limDate,
    })

    limDate = current.toDate()
    current = current.subtract(10, 'year')
  }

  // Last
  if (current.year() >= last.year()) {
    segments.push({
      date: last.toDate(),
      label: `${last.year()}`,
      type: 'year',
      fraction: 1,
      limDate,
    })
  }

  return segments
}

async function refineScrubberDates(segments?: ScrubberSegment[]) {
  const refined: ScrubberSegment[] = []

  if (!segments || segments.length < 3) {
    return segments
  }

  refined.push(segments[0])
  let limDate = segments[1].limDate

  for (let i = 1; i < segments.length - 2; i++) {
    const seg = segments[i]
    const prevDate = refined[refined.length - 1].date

    if (prevDate.valueOf() <= seg.date.valueOf()) {
      continue
    }

    const res = await fetchTimeline({
      from: toEpoch(dayjs(seg.limDate)),
      params: { limit: 1 },
    })

    if (!res.result.length) {
      break
    }

    const photoDate = fromEpoch(res.result[0].timestamp)

    if (dayjs(seg.date).get(seg.type) !== photoDate.get(seg.type)) {
      refined.push({
        date: photoDate.startOf(seg.type).toDate(),
        limDate,
        type: seg.type, // TODO: may bot be correct
        fraction: seg.fraction,
        label: `${photoDate.year()}`, // TODO: May be other than year
      })
    } else {
      refined.push(seg)
    }

    limDate = dayjs(refined[refined.length - 1].date)
      .subtract(1, 'day')
      .endOf('year')
      .toDate()
  }

  if (segments.length > 2) {
    refined.push(segments[segments.length - 1])
  }

  return refined
}

export function ScrubberV2({
  first,
  last,
  onSelect = () => {},
  scrollingElement,
  indicator,
  autohide = false,
}: {
  first?: string
  last?: string
  onSelect?: (epoch?: string) => void
  indicator?: string
  showLabel?: boolean
  scrollingElement?: Element | Document | null
  autohide?: boolean
}) {
  const scrubberRef = useRef<HTMLDivElement>(null)
  const cursorRef = useRef<HTMLDivElement>(null)
  const indicatorRef = useRef<HTMLDivElement>(null)
  const labelRef = useRef<HTMLDivElement>(null)

  const datesQ = useQuery({
    queryFn: () => getScrubberDates(first, last),
    queryKey: ['scrubber_dates', first, last],
  })

  const {
    mutate: refine,
    data: refinedSegments,
    reset,
  } = useMutation({
    mutationFn: refineScrubberDates,
  })

  useEffect(() => {
    if (datesQ.data) {
      reset()
      refine(datesQ.data)
    }
  }, [datesQ.data, refine, reset])

  const [isScrolling, setIsScrolling] = useState(false)
  const setIsScrollingDelayed = useDebounce(setIsScrolling, 3000)

  useEffect(() => {
    if (!scrollingElement) {
      return
    }

    const handleScroll = throttle(() => {
      setIsScrollingDelayed(true)
      setIsScrollingDelayed.flush()
      setIsScrollingDelayed(false)

      // Update label position on scroll if it was moved with the mouse
      if (scrubberRef.current && !scrubberRef.current.matches(':hover')) {
        const label = labelRef.current
        const indicator = indicatorRef.current
        const cursor = cursorRef.current

        if (indicator && label && indicator.style.top !== label.style.top) {
          label.style.transition = 'none'
          label.style.top = indicator.style.top

          if (cursor) {
            cursor.style.display = 'none'
          }

          window.setTimeout(() => {
            label.style.transition = ''
          }, 0)
        }
      }
    }, 100)

    scrollingElement.addEventListener('scroll', handleScroll)
    handleScroll()
    handleScroll.flush()

    return () => {
      handleScroll.cancel()
      scrollingElement.removeEventListener('scroll', handleScroll)
    }
  }, [scrollingElement, setIsScrollingDelayed])

  // Initial text
  useEffect(() => {
    if (labelRef.current && !labelRef.current.innerText) {
      const date = first ? fromEpoch(first) : dayjs()
      labelRef.current.innerText = date.format('MMM YYYY')
    }
  }, [first])

  const segments = refinedSegments || datesQ.data
  const helper = useMemo(() => new ScrubberHelper(segments), [segments])

  // Move indicator and label when indicator prop changes
  useEffect(() => {
    if (
      !segments ||
      !indicator ||
      !scrubberRef.current ||
      !indicatorRef.current ||
      !labelRef.current ||
      !cursorRef.current
    ) {
      return
    }

    const offset = Math.round(
      helper.getOffsetFromDate(fromEpoch(indicator)) *
        scrubberRef.current.clientHeight,
    )

    if (!scrubberRef.current.matches(':hover')) {
      cursorRef.current.style.display = 'none'
      labelRef.current.style.top = `${offset}px`
      labelRef.current.innerText = indicator
        ? fromEpoch(indicator).format('MMM YYYY')
        : dayjs().format('MMM YYYY')
    }

    indicatorRef.current.style.top = `${offset}px`
  }, [indicator, segments, helper])

  if (!first || !last) {
    return null
  }

  return (
    <AppLayoutPortal slot="timelineScrubber">
      <div className="relative z-10 h-full w-full cursor-pointer select-none pb-4">
        <div
          ref={scrubberRef}
          data-show={!autohide || isScrolling}
          data-scrolling={isScrolling}
          style={{
            gridTemplateRows: helper.gridTemplateRows,
          }}
          className="group relative grid h-full auto-cols-[1fr] opacity-0 transition-all duration-300 data-[show=true]:opacity-100 hover:opacity-100"
          onMouseMove={e => {
            if (scrubberRef.current) {
              const top = scrubberRef.current.getBoundingClientRect().top
              if (cursorRef.current && labelRef.current) {
                cursorRef.current.style.display = ''
                cursorRef.current.style.top = `${Math.round(e.clientY - top)}px`
                labelRef.current.style.top = `${Math.round(e.clientY - top)}px`
                const date = helper.getDateFromOffset(
                  (e.clientY - top) / scrubberRef.current.clientHeight,
                )
                labelRef.current.innerText = date.format('MMM YYYY')
              }
            }
          }}
          onMouseDown={() => {
            if (indicatorRef.current) {
              indicatorRef.current.style.display = 'none'
              indicatorRef.current.style.transition = 'none'
            }
          }}
          onMouseUp={e => {
            const el = e.currentTarget as HTMLDivElement
            const { top, height } = el.getBoundingClientRect()

            if (indicatorRef.current && cursorRef.current) {
              indicatorRef.current.style.top = cursorRef.current.style.top
              indicatorRef.current.style.transition = ''
              indicatorRef.current.style.display = ''
            }

            const clickedDate = helper.getDateFromOffset(
              (e.clientY - top) / height,
            )

            onSelect(toEpoch(clickedDate))
          }}
        >
          <AnimatePresence initial={false}>
            {segments &&
              segments.map((seg, i) => (
                <motion.div
                  layout="size"
                  key={i}
                  className="flex items-end justify-end pr-2"
                >
                  <div className="group pointer-events-none flex translate-y-1/2 items-center gap-2">
                    <span className="right-3 top-[-6px] text-xs text-gray-500 transition-all">
                      {seg.label}
                    </span>
                    <span className="h-1 w-1 rounded-full bg-gray-500"></span>
                  </div>
                </motion.div>
              ))}
          </AnimatePresence>

          <div
            ref={cursorRef}
            className="pointer-events-none absolute right-0 h-[2px] w-6 -translate-y-1/2 bg-gray-800 opacity-0 transition-all duration-300 group-data-[scrolling=true]:opacity-100 group-hover:opacity-100 group-hover:transition-none group-hover:transition-opacity"
          />
          <div
            ref={labelRef}
            className="pointer-events-none absolute right-8 -translate-y-1/2 whitespace-nowrap rounded-md border border-[var(--color-light-gray)] bg-white px-3 py-2 text-base font-medium opacity-0 transition-all duration-300 group-data-[scrolling=true]:opacity-100 group-hover:opacity-100 group-hover:transition-opacity"
          />
          <div
            ref={indicatorRef}
            className="pointer-events-none absolute right-0 h-[2px] w-6 -translate-y-1/2 bg-purple-600 transition-all duration-300"
          />
        </div>
      </div>
    </AppLayoutPortal>
  )
}
