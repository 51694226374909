import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { Button } from '@jotta/ui/Button'
import { useAlertListStore } from '@jotta/ui/AlertStore'
import { Plural, t } from '@lingui/macro'
import { DialogTrigger } from '@radix-ui/react-dialog'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SelectAlbumDialog } from '../Album/Dialogs/SelectAlbumDialog'
import type { PhotoActionHandlerActionProps } from './PhotoActionHandlers'
import { useDispatchPhotoActionMutation } from './useDispatchPhotoActionMutation'
const debug = Debug('jotta:photos:action:AddPhotosToAlbum')

export const ActionAddPhotosToAlbum = observer<
  PhotoActionHandlerActionProps<'ADD_PHOTOS_TO_ALBUM'>
>(function ActionAddPhotosToAlbum({
  action,
  photoIds = [],
  albumId,
  addPhotoMode,
  albums = [],
  dispatch,
  onActionDialogClose,
  buttonVariant = 'buttons.albumActionButton',
  currentMediaId,
  disabled = false,
}) {
  const { alert } = useAlertListStore()
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const mutation = useDispatchPhotoActionMutation(
    'ADD_PHOTOS_TO_ALBUM',
    {
      onSettled: async albumPromise => {
        const album = await albumPromise
        debug('onSettled', album)
        if (addPhotoMode) {
          setOpen(false)
          navigate(`/share/${addPhotoMode}`, { replace: true })
          return
        }
        if (album && currentMediaId) {
          debug('Displaying alert')
          setOpen(false)
          if (onActionDialogClose) {
            onActionDialogClose('ADD_PHOTOS_TO_ALBUM')
          }
          alert.showAlert({
            // autoDismissAfterMsElapsed: 0,
            message: (
              <Plural
                value={album.photos ? album.photos.length : album.total}
                one="Added # file"
                other="Added # files"
              />
            ),
            severity: 'addPhotosToAlbumAlert',
            actions: [
              {
                action: `/photo/album/${album.id}`,
                text: t`ShowAlbumButton.label`,
                testId: 'AddedToAlbumAlert-ShowAlbum',
              },
            ],
          })
          return
        }
        if (album) {
          debug('Displaying alert')
          setOpen(false)
          if (onActionDialogClose) {
            onActionDialogClose('ADD_PHOTOS_TO_ALBUM')
          }
          alert.showAlert({
            // autoDismissAfterMsElapsed: 0,
            message: (
              <Plural
                value={album.photos ? album.photos.length : album.total}
                one="Added # file"
                other="Added # files"
              />
            ),
            severity: 'addPhotosToAlbumAlert',
            actions: [
              {
                action: `/photo?add-photos=${album.id}`,
                text: t`ShowAddMoreToAlbumButton.label`,
                testId: 'AddedToAlbumAlert-AddMore',
              },
              {
                action: `/photo/album/${album.id}`,
                text: t`ShowAlbumButton.label`,
                testId: 'AddedToAlbumAlert-ShowAlbum',
              },
            ],
          })
          return
        }
        if (onActionDialogClose) {
          onActionDialogClose('ADD_PHOTOS_TO_ALBUM')
        }
        setOpen(false)
      },
    },
    dispatch,
  )
  if (addPhotoMode) {
    return (
      <Button
        variant={buttonVariant}
        aria-label={action.label}
        data-testid={action.testid}
        aria-busy={mutation.isPending}
        disabled={disabled}
        key={action.actionType}
        icon={action.icon}
        onClick={() => mutation.mutateAsync([addPhotoMode, photoIds])}
      >
        {action.label}
      </Button>
    )
  }
  return (
    <SelectAlbumDialog
      open={open}
      onClose={open => {
        setOpen(open)
        if (!open && onActionDialogClose) {
          onActionDialogClose(action.actionType)
        }
      }}
      currentAlbumId={albumId}
      addToAlbumHandler={selectedAlbumId => {
        debug('addToAlbumHandler', selectedAlbumId)
        if (selectedAlbumId) {
          mutation.mutateAsync([selectedAlbumId, photoIds])
        }
        if (onActionDialogClose) {
          onActionDialogClose(action.actionType)
        }
        setOpen(false)
      }}
      triggerElement={
        <DialogTrigger
          aria-label={action.label}
          data-testid={action.testid}
          aria-busy={mutation.isPending}
          disabled={disabled}
          key={action.actionType}
          sx={{
            variant: buttonVariant,
          }}
        >
          <PlainBrandIcon icon={action.icon} />
          <span>{action.label}</span>
        </DialogTrigger>
      }
      dispatch={dispatch}
      albums={albums}
    />
  )
})
