import moize from 'moize'
import type { PathItemStore } from '../PathItem/PathItemStore'

export const getFileCarouselItems = moize.shallow(
  function getFileCarouselItems(
    file?: PathItemStore,
    files?: PathItemStore[],
  ): {
    index: number
    items: PathItemStore[]
    show: boolean
  } {
    if (file) {
      if (!files) {
        return {
          index: 0,
          items: [file],
          show: true,
        }
      }
      const index = files.indexOf(file)
      if (index === -1) {
        return {
          index: 0,
          items: [file, ...files],
          show: true,
        }
      }
      return {
        index,
        items: files,
        show: true,
      }
    }
    if (files) {
      return {
        index: -1,
        items: files,
        show: false,
      }
    }
    return {
      index: -1,
      items: [],
      show: false,
    }
  },
  {
    maxSize: 10,
  },
)
