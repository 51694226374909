export type EnumType<T> = {
  [id: string]: string | T
}

export function getEnumValues<T>(anEnum: EnumType<T>): T[] {
  const values: T[] = []
  Object.values(anEnum).forEach(v => {
    if (typeof v === 'string') {
      const reverse = anEnum[v]
      values.push((reverse === undefined ? v : reverse) as T)
    }
  })
  return values
}
export function getEnumKeys<T extends {}>(enumType: T): (keyof T)[] {
  return (Object.keys(enumType) as Array<keyof T>).filter(
    value => isNaN(Number(value)) !== false,
  )
}

export function getEnumKey<T extends {}>(
  enumType: T,
  value: T[keyof T],
): keyof T {
  return Object.entries(enumType)
    .find(([_, v]) => v === value)
    ?.at(0) as keyof T
}
