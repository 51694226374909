import { AppError } from '@jotta/types/AppError'
import { AppLayoutSignup } from '@jotta/ui/AppLayoutSignup'
import { FormattedContent } from '@jotta/ui/FormattedContent'
import { Trans } from '@lingui/macro'
import { useLocation } from 'react-router-dom'

export function AfterTerminate() {
  const location = useLocation()

  if (!location.state?.fromApp) {
    throw new AppError({ HTTPStatus: 404 })
  }

  return (
    <AppLayoutSignup>
      <div className="w-full p-16">
        <FormattedContent
          variant="formats.errorPage"
          className="md:justify-self-start"
        >
          <h2>
            <Trans>Your account is terminated</Trans>
          </h2>
          <p>
            <Trans>
              We're really sorry to lose you. Remember - you're always welcome
              back.
            </Trans>
          </p>
          <p>
            <a href="/">
              <Trans>Click here to go back to the homepage.</Trans>
            </a>
          </p>
        </FormattedContent>
      </div>
    </AppLayoutSignup>
  )
}
