import { Card } from '@jotta/ui/Card'
import type { ReactNode } from 'react'
import { useEffect } from 'react'
import { Trans } from '@lingui/macro'
import { AppLayoutSignup } from '@jotta/ui/AppLayoutSignup'
import { useAuthenticated } from '@jotta/auth-client/useAuthStatus'
import { useNavigate } from 'react-router-dom'
import { LoadingOverlaySpinner } from '@jotta/ui/LoadingOverlay'
import { BrandLogo } from '@jotta/ui/BrandLogo'

export function SignupContainer({
  title,
  children,
}: {
  title: string
  children: ReactNode
}) {
  const navigate = useNavigate()
  const authenticated = useAuthenticated()

  useEffect(() => {
    if (authenticated === 'TRUE') {
      navigate('/web/billing')
    }
  }, [navigate, authenticated])
  return (
    <AppLayoutSignup>
      <Card className="container !mx-auto flex max-w-lg flex-col gap-4 bg-white !p-8 sm:!mt-12 sm:!p-12">
        <BrandLogo className="self-center" icon="SvgLogoMain" height={55} />

        <h2 className="label-md my-4 self-center">
          <Trans id={title}></Trans>
        </h2>

        {authenticated === 'FALSE' ? (
          children
        ) : (
          <LoadingOverlaySpinner className="self-center" />
        )}
      </Card>
    </AppLayoutSignup>
  )
}
