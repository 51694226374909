import { getAddressBook } from '@jotta/grpc-js-client/sharingService'
import type { Address } from '@jotta/grpc-web/no/jotta/openapi/sharing/v2/sharing.v2_pb'
import { useQuery } from '@tanstack/react-query'
import { computed, makeAutoObservable } from 'mobx'
import { useMemo } from 'react'

export type AddressBookEntry = Address.AsObject

export class AddressBookStore {
  public readonly list: AddressBookEntry[] = []

  public constructor(list: AddressBookEntry[]) {
    this.list = list

    makeAutoObservable<typeof this, 'query' | 'filtered'>(
      this,
      {
        query: true,
        filtered: computed,
      },
      { autoBind: true },
    )
  }

  private query: string = ''

  private get filtered() {
    return this.list.filter(
      entry =>
        entry.email.toLowerCase().includes(this.query) ||
        entry.fullName.toLowerCase().includes(this.query),
    )
  }

  public search(query: string) {
    this.query = query.toLowerCase()
    return this.filtered
  }
}

export function useAddressBook() {
  const { data, ...query } = useQuery({
    queryKey: ['addressBook'],
    queryFn: () => getAddressBook(),
  })

  const addressBook = useMemo(
    () => (data ? new AddressBookStore(data) : null),
    [data],
  )

  return { data: addressBook, ...query }
}
