import type { PathItemObject } from '@jotta/types/Files'
import { PublicFileItem } from './PublicFileItem'

export const fileItemCache = new Map<string, WeakRef<PublicFileItem>>()
export function getFileItemFromCache(path: string) {
  if (fileItemCache.has(path)) {
    const ref = fileItemCache.get(path)
    return ref?.deref()
  }
}
export function addPathItem(
  pathItem: PathItemObject,
  ignoreChildren = false,
  parents: PublicFileItem | PathItemObject[] = [],
) {
  let item = getFileItemFromCache(pathItem.path)
  if (item) {
    // this.log('addPathItem: Updating cached item', item.path)
    item.updateItem(pathItem, ignoreChildren, parents)
  } else {
    item = new PublicFileItem(pathItem, parents)
    // this.log('addPathItem: Create new item', item.path)
    fileItemCache.set(item.path, new WeakRef(item))
  }
  return item
}
