export const brandZIndexes = [
  'loadingSpinner',
  'onboarding',
  'appNavbarOpenMobile',
  'appNavbarOpenMobileBg',
  'alertList',
  'modal',
  'languageSelector',
  'photoSearchBar',
  'appHeaderAvatar',
  'dropdown',
  'appHeader',
  'appNavbar',
  'appNavbarBg',
  'appMainBottom',
  'photoSearchResults',
  'timelineScrubber',
  'appMainBottomToolbar',
  'appMainBottomToolbarContent',
  'appMainBottomToolbarDetails',
  'appMainTopToolbar',
  'galleryOverlay',
  'appMainDataTableHeader',
  'appMainDataTableBody',
] as const

export type BrandZIndex = (typeof brandZIndexes)[number]

export function getBrandZIndex(key: BrandZIndex): number {
  return brandZIndexes.length - brandZIndexes.findIndex(val => val === key)
}
