import type { AppError } from '@jotta/types/AppError'
import Debug from 'debug'
import {
  action,
  makeAutoObservable,
  observable,
  onBecomeObserved,
  onBecomeUnobserved,
} from 'mobx'
import type {
  InfiniteQueryObserverResult,
  QueryKey,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query'
import { InfiniteQueryObserver } from '@tanstack/react-query'
import { queryClient } from './queryClient'

export function createObservableInfiniteQuery<
  TQueryFnData = unknown,
  TError = AppError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
  TPageParam = unknown,
  TName extends string = string,
>({
  name,
  infiniteQueryOptions,
  onQueryUpdate,
}: {
  name: TName
  infiniteQueryOptions: Pick<
    Required<
      UseInfiniteQueryOptions<
        TQueryFnData,
        TError,
        TData,
        TQueryFnData,
        TQueryKey,
        TPageParam
      >
    >,
    'queryFn' | 'queryKey'
  > &
    UseInfiniteQueryOptions<
      TQueryFnData,
      TError,
      TData,
      TQueryFnData,
      TQueryKey,
      TPageParam
    >
  onQueryUpdate?: (result: InfiniteQueryObserverResult<TData, TError>) => void
}) {
  // type ObservableQueryOptions = typeof options
  const debug = Debug(`jotta:InfiniteObservableQuery:${name}`)
  type ObservableInfiniteQueryResult = InfiniteQueryObserverResult<
    TData,
    TError
  >

  // Creates a react-query observer
  const queryObserver = new InfiniteQueryObserver<
    TQueryFnData,
    TError,
    TData,
    TQueryFnData,
    TQueryKey,
    TPageParam
  >(queryClient, infiniteQueryOptions)
  const state = makeAutoObservable(
    {
      result: queryObserver.getCurrentResult(),
      setResult(result: ObservableInfiniteQueryResult) {
        this.result = result
      },
      get status() {
        return this.result.status
      },
      get error() {
        return this.result.error
      },
      get data() {
        return this.result.data
      },
      get isLoading() {
        return this.result.isLoading
      },
      get isFetchingNextPage() {
        return this.result.isFetchingNextPage
      },
      get isFetched() {
        return this.result.isFetched
      },
      get isSuccess() {
        return this.result.isSuccess
      },
      get hasNextPage() {
        return this.result.hasNextPage
      },
      get fetchNextPage() {
        return this.result.fetchNextPage
      },
      refetch() {
        queryClient.invalidateQueries({
          queryKey: infiniteQueryOptions.queryKey,
        })
      },
    },
    {
      result: observable.ref,
      setResult: action,
    },
    { autoBind: true },
  )

  onBecomeObserved(state, 'result', () => {
    const unsubscribe = queryObserver.subscribe(result => {
      if (onQueryUpdate) {
        onQueryUpdate(result)
      }
      state.setResult(result)
    })

    // Fetch data when something starts observing the result
    debug('onBecomeObserved, fetch data')
    queryObserver.refetch()

    onBecomeUnobserved(state, 'result', () => {
      unsubscribe()
    })
  })

  return state
}
