import { tv } from 'tailwind-variants'

export const inputVariants = tv({
  slots: {
    input: '',
  },
  variants: {
    variant: {
      plain: {
        input: 'border-b',
      },
    },
  },
})
