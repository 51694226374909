import { TransportProvider } from '@connectrpc/connect-query'
import { publicTransport, authTransport } from './transport'
import type { ReactNode } from 'react'

export function PublicTransportProvider({ children }: { children: ReactNode }) {
  return (
    <TransportProvider transport={publicTransport}>
      {children}
    </TransportProvider>
  )
}
export function AuthTransportProvider({ children }: { children: ReactNode }) {
  return (
    <TransportProvider transport={authTransport}>{children}</TransportProvider>
  )
}
