import { Flex } from '@jotta/ui/Flex'
import type { ThemeUIStyleObject } from '@jotta/ui/themeui'
import type { Avatar as AvatarType } from '@jotta/types/Avatar'
import { Avatar } from '@jotta/ui/Avatar'
import { t } from '@lingui/macro'
import * as Popover from '@radix-ui/react-popover'
import { AnimatePresence, motion } from 'framer-motion'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import type { AlbumInfoStore } from '../../../store/AlbumInfoStore'

const popoverSx: ThemeUIStyleObject = {
  fontSize: 2,
  p: 3,
  mr: 4,
  overflow: 'auto',
  maxHeight: 'calc(100vh - 205px)',
  maxWidth: ['100vw', '400px'],
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  backgroundColor: 'muted',
  gap: 3,
  borderRadius: 2,
  boxShadow: 'rgb(0 0 0 / 12%) 0px 2px 7px',
  position: 'relative',
  transformOrigin: 'var(--radix-popper-transform-origin)',

  strong: {
    fontWeight: 500,
  },
}
const Member = observer<{ name: string; avatar: AvatarType }>(function Member({
  name,
  avatar,
}) {
  return (
    <Flex sx={{ gap: 2 }}>
      <Avatar size={24} avatar={avatar} />
      <span
        sx={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
      >
        {name}
      </span>
    </Flex>
  )
})

export const AlbumMembers = observer<{ store: AlbumInfoStore }>(
  function AlbumMembers({ store }) {
    const [open, setOpen] = useState(false)
    const album = store.model
    const ownerAvatar = album.ownerAvatar

    if (!album.owner || !ownerAvatar) {
      return null
    }

    return (
      <Popover.Root open={open} onOpenChange={setOpen} modal>
        <Popover.Trigger sx={{ display: 'flex', gap: 2 }}>
          <Avatar size={30} avatar={ownerAvatar} />
          {store.subscriberCount > 0 && (
            <Avatar
              size={30}
              avatar={{
                initials: `+${store.subscriberCount}`,
                backgroundColor:
                  album.shareInfo?.subscribers
                    .map(s => s.userAvatar.backgroundColor)
                    .find(s => s != ownerAvatar.backgroundColor) ||
                  ownerAvatar.backgroundColor,
              }}
            />
          )}
        </Popover.Trigger>
        <AnimatePresence>
          {open && (
            <Popover.Portal forceMount>
              <Popover.Content>
                <motion.div
                  sx={popoverSx}
                  initial={{ opacity: 0, top: '-16px' }}
                  animate={{ opacity: 1, top: 0 }}
                  exit={{ opacity: 0, top: '-16px' }}
                  transition={{ duration: 0.2 }}
                >
                  <strong>{t`AlbumContainer.owner`}</strong>
                  <Member name={album.owner} avatar={ownerAvatar} />
                  {store.subscriberCount > 0 && (
                    <strong>{t`AlbumContainer.subscribers`}</strong>
                  )}
                  {album.shareInfo?.subscribers.map(
                    ({ userFullName, userAvatar }, i) => (
                      <Member name={userFullName} avatar={userAvatar} key={i} />
                    ),
                  )}
                </motion.div>
              </Popover.Content>
            </Popover.Portal>
          )}
        </AnimatePresence>
      </Popover.Root>
    )
  },
)
