import { useSuspenseGetPaymentInfo } from '@jotta/grpc-connect-client/subscription'
import { Card } from '@jotta/ui/Card'
import { DefinitionListFromObject } from '@jotta/ui/DefinitionList'
import { TextField, TextFieldContainer } from '@jotta/ui/TextField'
import { t } from '@lingui/macro'
export function BizBillingForm() {
  const { data } = useSuspenseGetPaymentInfo({})
  return (
    <Card variant="cards.info" title={t`Billing`}>
      <DefinitionListFromObject value={data.toJson()} />
      <TextFieldContainer>
        <TextField
          type="text"
          name="businessname"
          label={t`Business name`}
          description={t`Your business name as you want it to be displayed.`}
        />
        <TextField
          type="text"
          name="organization_number"
          defaultValue="123"
          label={t`Organization number`}
          description={t`The company's official VAT number`}
        />
      </TextFieldContainer>
    </Card>
  )
}
