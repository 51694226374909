import { ItemKind } from '@jotta/grpc-js-client/fileService'
import type {
  ShareInfoState,
  ShareUser,
} from '@jotta/grpc-js-client/sharing/parseShareInfo'
import { parseShareInfo } from '@jotta/grpc-js-client/sharing/parseShareInfo'
import {
  disableFolderShare,
  disablePublicShare,
  enablePublicShare,
  inviteMember,
  removeAllMembers,
  removeMember,
} from '@jotta/grpc-js-client/sharingService'
import { queryClient } from '@jotta/query'
import type { FetchStatus } from '@jotta/types/IO'
import Debug from 'debug'
import type { Lambda } from 'mobx'
import {
  action,
  makeAutoObservable,
  observable,
  onBecomeObserved,
  set,
} from 'mobx'
import { createViewModel } from 'mobx-utils'
import type { FormEvent } from 'react'
import type { PathItemStore } from '../../PathItem/PathItemStore'
import { useShareInfo } from './FileActionShareApi'
const debug = Debug('jotta:files:action:ShareDialog:store')

export class ShareFileDialogStore {
  // #region Properties (5)

  private _disposers: Lambda[] = []
  private _shareInfo: ShareInfoState = parseShareInfo()
  private _viewModel
  private inviteKeys = observable.map<string, string>()
  private _scrollMemberList = false

  public get scrollMemberList() {
    return this._scrollMemberList
  }

  public set scrollMemberList(scroll: boolean) {
    this._scrollMemberList = scroll
  }

  private _userAlreadyInvited: string | null = null

  public get userAlreadyInvited() {
    return this._userAlreadyInvited
  }

  public set userAlreadyInvited(user: string | null) {
    this._userAlreadyInvited = user
  }

  public status: FetchStatus = 'IDLE'
  public unsentInvites = observable.set<string>()

  // #endregion Properties (5)

  // #region Constructors (1)

  constructor(private item: PathItemStore) {
    makeAutoObservable<typeof this, '_disposers' | '_shareinfo' | '_item'>(
      this,
      {
        _disposers: false,
        _item: observable.ref,
        _shareinfo: observable.deep,
        refetch: action.bound,
      },
      {
        autoBind: true,
      },
    )
    this._viewModel = createViewModel(this._shareInfo)
    this._disposers.push(onBecomeObserved(this, 'shareInfo', this.refetch))
  }

  // #endregion Constructors (1)

  // #region Public Accessors (11)

  public get inviteList(): ShareUser[] {
    return this.shareInfo.users.filter(v => v.role.startsWith('invite'))
  }

  public get isError() {
    return this.status === 'ERROR'
  }

  public get isLoading() {
    return this.status === 'LOADING'
  }

  public get isShareOwner() {
    return this.item.isOwner
  }

  public get shareLink() {
    if (this.isShared) {
      const url = new URL(window.location.origin)
      url.pathname = `/s/${this.shareInfo.publicLinkId}`
      return url.href
    }
    return ''
  }
  public get isShared() {
    return this.item.isShared
  }

  public set isShared(isShared) {
    this.shareInfo.isShared = isShared
  }

  public get isSuccess() {
    return this.status === 'SUCCESS'
  }

  public get memberList(): ShareUser[] {
    if (this.isShareOwner) {
      return this.shareInfo.users
    }

    return this.shareInfo.users.filter(v => {
      if (v.role === 'owner' || v.role === 'member') {
        return true
      }
      return false
    })
  }

  public get shareInfo() {
    return this._viewModel.model
  }

  public set shareInfo(shareInfo) {
    set(this._shareInfo, shareInfo)
    this._viewModel.reset()
  }

  // #endregion Public Accessors (11)

  // #region Public Methods (14)

  public addUnsentInvite(email: string) {
    if (!this.hasEmail(email)) {
      this.unsentInvites.add(email.toLocaleLowerCase())
    }
  }
  get path() {
    return this.item.path
  }
  getInviteKeyByEmail(email: string) {
    return this.inviteKeys.get(email.toLowerCase()) || ''
  }
  public disablePublicShare() {
    debug('disablePublicShare')
    if (!this.isShared || this.isLoading) {
      debug('disablePublicShare skipped')
      return
    }
    this.status = 'LOADING'
    const item = this.item
    disablePublicShare({
      path: this.path,
    })
      .then(
        action('disablePublicShareSuccess', res => {
          this.status = 'SUCCESS'
          if (res.item) {
            item.isShared = false
            item.refetch()
          }
        }),
        action('disablePublicShareError', err => {
          this.status = 'ERROR'
          this._viewModel.reset()
        }),
      )
      .finally(this.refetch)
  }

  public dispose() {
    debug('Dispose')
    for (const disposer of this._disposers) {
      disposer()
    }
    this._disposers = []
  }

  public enablePublicShare() {
    if (this.isShared || this.isLoading) {
      return
    }
    this.status = 'LOADING'
    this.isShared = true
    const item = this.item
    enablePublicShare({
      path: this.path,
    }).then(
      action('enablePublicShareSuccess', partialShareInfo => {
        this.status = 'SUCCESS'
        if (item) {
          item.isShared = true
          item.refetch()
        }
        this.refetch()
      }),
      action('enablePublicShareError', err => {
        this.status = 'ERROR'
        this._viewModel.reset()
      }),
    )
  }

  public getUserByEmail(email: string) {
    const key = email.toLocaleLowerCase()
    return this.shareInfo.usersByEmail[key]
  }

  public hasEmail(email: string) {
    const key = email.toLocaleLowerCase()
    return key in this.shareInfo.usersByEmail
  }

  public inviteMember(email: string) {
    if (this.isLoading) {
      return
    }
    const path = this.path
    debug('inviteMember %s', email, path)
    const user = this.getUserByEmail(email)
    if (user && user.role !== 'invite_declined') {
      debug('User already invited or member')
      this.userAlreadyInvited = user.email
      return
    }
    if (!user) {
      this.addUnsentInvite(email)
    }
    this.status = 'LOADING'

    this.setOptimisticCollaborationState(true)
    inviteMember({ email, path }).then(
      action('inviteMemberSuccess', res => {
        this.status = 'SUCCESS'
        if (res.inviteKey) {
          this.inviteKeys.set(email.toLowerCase(), res.inviteKey)
        }
        if (res.shareInfo) {
          this.shareInfo = parseShareInfo(res.shareInfo)
          const i = this.shareInfo.users.findIndex(user => user.email === email)
          if (i > -1) {
            const user = this.shareInfo.users.splice(i, 1)[0]
            this.shareInfo.users.push(user)
          }
        }
        this.scrollMemberList = true
      }),
      action('inviteMemberError', err => {
        this.status = 'ERROR'
        this._viewModel.reset()
      }),
    )
  }

  public onSubmitInviteMember(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    const form = e.target

    if (!(form instanceof HTMLFormElement)) {
      return
    }

    const formData = new FormData(form)
    const email = formData.get('inviteMember')?.toString()

    if (email) {
      this.inviteMember(email)

      for (const el of form.elements) {
        if (el instanceof HTMLInputElement && el.name === 'inviteMember') {
          el.value = ''
        }
      }
    } else {
      form.querySelector('input')?.focus()
    }
  }

  public refetch() {
    queryClient.invalidateQueries({
      queryKey: useShareInfo.getKey({
        path: this.path,
      }),
    })
  }

  public removeAllMembers() {
    const path = this.path
    this.status = 'LOADING'
    removeAllMembers({
      path,
    }).then(
      action('removeAllMembersSuccess', shareInfo => {
        this.status = 'SUCCESS'
        this.shareInfo = parseShareInfo(shareInfo)
        this.setOptimisticCollaborationState(false)
      }),
      action('removeAllMembersError', err => {
        this.status = 'ERROR'
        this._viewModel.reset()
        this.refetch()
      }),
    )
  }

  public removeMember(email: string) {
    if (this.isLoading) {
      return
    }
    if (!this.hasEmail(email)) {
      debug('%s is not a member', email)
      return
    }

    this.status = 'LOADING'
    removeMember({
      usernameOrEmail: email,
      folderShareId: this.shareInfo.folderShareId,
    }).then(
      action('removeMemberSuccess', ({ shareInfo }) => {
        this.status = 'SUCCESS'
        this.setOptimisticCollaborationState(
          Boolean(shareInfo?.membersList.length),
        )
        this._viewModel.submit()
        if (shareInfo) {
          this.shareInfo = parseShareInfo(shareInfo)
        } else {
          this.refetch()
        }
      }),
      action('removeMemberError', err => {
        this.status = 'ERROR'
        this._viewModel.reset()
        this.refetch()
      }),
    )
  }

  public retryDeclinedInvite(email: string) {
    if (this.isLoading) {
      return
    }
    const path = this.path
    debug('retry inviteMember %s', email, path)
    const user = this.getUserByEmail(email)
    if (!user || user.role !== 'invite_declined') {
      debug('User not found')
      return
    }
    this.status = 'LOADING'

    removeMember({
      usernameOrEmail: email,
      folderShareId: this.shareInfo.folderShareId,
    })
      .then(() => inviteMember({ email, path }))
      .then(
        action('retryDeclinedInviteSuccess', res => {
          this.status = 'SUCCESS'
          if (res.shareInfo) {
            this.shareInfo = parseShareInfo(res.shareInfo)
          }
        }),
        action('retryDeclinedInviteError', err => {
          this.status = 'ERROR'
          this._viewModel.reset()
        }),
      )
  }

  public stopCollaborationSharing() {
    if (this.isLoading) {
      return
    }
    if (this.isShareOwner) {
      disableFolderShare({
        folderShareId: this.shareInfo.folderShareId,
      }).then(
        action('stopCollaborationSharingSuccess', res => {
          this.status = 'SUCCESS'
          this.setOptimisticCollaborationState(false)
        }),
        action('stopCollaborationSharingError', err => {
          this.status = 'ERROR'
          this._viewModel.reset()
        }),
      )
    }
  }

  public toggleShare(on: boolean) {
    if (on && !this.isShared) {
      debug('toggleShare ON')
      this.enablePublicShare()
      return
    }
    if (!on && this.isShared) {
      debug('toggleShare OFF')
      this.disablePublicShare()
      return
    }
    debug('toggleShare skipped', on, this.isShared)
  }

  // #endregion Public Methods (14)

  // #region Private Methods (2)

  private setOptimisticCollaborationState(isCollaborationFolder: boolean) {
    const item = this.item
    switch (item.model.kind) {
      case ItemKind.REGULAR_FOLDER:
        {
          if (isCollaborationFolder) {
            item.model.kind = ItemKind.COLLABORATION_FOLDER
          }
        }
        break
      case ItemKind.COLLABORATION_FOLDER:
        {
          if (!isCollaborationFolder) {
            item.model.kind = ItemKind.REGULAR_FOLDER
          }
        }
        break

      default:
        break
    }
  }

  // #endregion Private Methods (2)
}
