import * as Switch from '@radix-ui/react-switch'
import type { ReactNode } from 'react'
import { forwardRef, useId } from 'react'
import styles from './ToggleButton.module.scss'
/**
 * Component to toggle something.
 *
 * Wraps a hidden checkbox which can be useful for making the component more accessible in the future
 */

function ButtonLabelWrapper({
  htmlFor,
  label,
  children,
}: {
  htmlFor: string
  label?: string
  children: ReactNode
}) {
  if (!label) {
    return <>{children}</>
  }

  return (
    <div className={styles.labelWrapper}>
      <label htmlFor={htmlFor}>{label}</label>
      {children}
    </div>
  )
}

export interface ToggleButtonProps extends Switch.SwitchProps {
  /** Button ON state */
  isChecked?: boolean
  disabled?: boolean
  className?: string
  onToggle?: (on: boolean) => void
  label?: string
}
export const ToggleButton = forwardRef<HTMLButtonElement, ToggleButtonProps>(
  function ToggleButton(
    { onToggle, isChecked, disabled, label, ...props },
    ref,
  ) {
    const id = useId()

    return (
      <ButtonLabelWrapper htmlFor={id} label={label}>
        <Switch.Root
          className={styles.toggle}
          checked={isChecked}
          onCheckedChange={onToggle}
          disabled={disabled}
          ref={ref}
          id={id}
          {...props}
        >
          <Switch.Thumb className={styles.thumb} />
        </Switch.Root>
      </ButtonLabelWrapper>
    )
  },
)
