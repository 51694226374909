import { Box } from '@jotta/ui/Box'
import { RouterLink } from '@jotta/ui/RouterLink'
import type { Notification } from '@jotta/types/Notifications'
import { avatarFromProtobuf } from '@jotta/utils/avatar'
import type { RefObject } from 'react'

import { forwardRef } from 'react'
import { ShortTime } from '../Elements/ShortTime/ShortTime'
import { ImageWithFallback } from '../Icons/ImageWithFallback'
import { Avatar } from '../Layout/Avatar/Avatar'

interface NotificationsListItemProps {
  notification: Notification
  ref: RefObject<HTMLAnchorElement> | null
  children?: React.ReactNode
}

export const NotificationsListItemLink = forwardRef<
  HTMLAnchorElement,
  NotificationsListItemProps
>(function NotificationsListItemLink(
  { notification: { deeplink, id, isNew }, children, ...props },
  ref,
) {
  if (deeplink) {
    return (
      <RouterLink
        sx={{
          display: 'grid',
          gridTemplateColumns: 'min-content 1fr 40px',
          gap: 2,
          borderBottom: '1px solid',
          borderBottomColor: 'lightGrey',
          alignItems: 'flex-start',
          p: 3,
          bg: isNew ? '#f0f0f0' : 'background',
          ':hover': {
            bg: '#f2f4f6',
          },
          ':focus-visible': {
            boxShadow: 'inset 0 0 0 2px var(--color-primary)',
            outline: 'none',
          },
        }}
        ref={ref}
        key={id}
        to={`/r/${deeplink.replace(/^.+\/r\//, '')}`}
      >
        {children}
      </RouterLink>
    )
  }
  return <Box>{children}</Box>
})

export const NotificationsListItem = forwardRef<
  HTMLAnchorElement,
  NotificationsListItemProps
>(function NotificationsListItem({ notification }, ref) {
  const { actorAvatar, html, createdAtMillis, thumbLink } = notification

  return (
    <NotificationsListItemLink ref={ref} notification={notification}>
      <Avatar avatar={avatarFromProtobuf(actorAvatar)} />
      <Box sx={{ fontSize: 0, color: 'scrubberYearDot' }}>
        <Box
          dangerouslySetInnerHTML={{ __html: html }}
          sx={{ fontSize: 3, color: '#666', overflowWrap: 'break-word' }}
        />
        <ShortTime {...{ timestamp: createdAtMillis }} />
      </Box>
      {thumbLink && (
        <ImageWithFallback
          alt="notification-thumb"
          src={thumbLink}
          fallback={
            <span
              style={{
                width: 40,
                height: 40,
                display: 'inline-block',
              }}
            />
          }
        />
      )}
    </NotificationsListItemLink>
  )
})
