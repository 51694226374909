import { useEffect } from 'react'

export function useDocumentEventListener<K extends keyof DocumentEventMap>(
  type: K,
  listener: (this: Document, ev: DocumentEventMap[K]) => any,
  {
    enabled = true,
    ...options
  }: AddEventListenerOptions & { enabled?: boolean } = {},
) {
  useEffect(() => {
    if (!enabled) {
      return
    }

    document.addEventListener(type, listener, options)

    return () => {
      document.removeEventListener(type, listener)
    }
  }, [type, listener, enabled, options])
}
