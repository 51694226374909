import type { AppError } from '@jotta/types/AppError'
import axios from 'axios'
import { createQuery } from 'react-query-kit'
import Debug from 'debug'
const debug = Debug('jotta:auth:useForceRefreshSession')

export const useForceRefreshSession = createQuery<void, void, AppError>({
  queryKey: ['forceRefreshSession'],
  fetcher: async () => {
    await axios.get(`/web/forceRefreshSession`, {
      withCredentials: true,
      validateStatus: status => {
        debug('Status %d', status)
        return true
      },
    })
  },
})
