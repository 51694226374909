import type { Photos } from '@jotta/types/Photos'
import { useMemo } from 'react'

export interface RowData<T> {
  elements: T[]
  fractions: number[]
}

export interface TimelinePhotoRow extends RowData<Photos.Media> {
  content: 'row'
  key: string
}

export interface TimelineHeader {
  content: 'header'
  day: number
  ids: string[]
  key: string
}

export type TimelineRowData = TimelinePhotoRow | TimelineHeader

export function isTimelineHeader(row: TimelineRowData): row is TimelineHeader {
  return row.content === 'header'
}

export function useGroupByDayAndRow(
  rowAspect: [number, number] | string,
  photos: readonly Photos.Media[],
  preserveAspect: boolean,
) {
  if (typeof rowAspect === 'string') {
    const temp = rowAspect.split('/')
    rowAspect = [Number(temp[0] || 1), Number(temp[1] || 1)]
  }

  const result = useMemo(() => {
    if (!photos.length) {
      return []
    }

    const rows = groupByDayAndRow(rowAspect, photos, preserveAspect)
    return rows
  }, [photos, rowAspect, preserveAspect])

  return result
}

export function groupByDayAndRow(
  rowAspect: [number, number],
  photos: readonly Photos.Media[],
  preserveAspect: boolean = false,
): TimelineRowData[] {
  if (!photos.length) {
    return []
  }

  const rows: TimelineRowData[] = []
  const defWidth = 16
  const defHeight = 9
  const THRESHOLD = 0.1
  const aspect = rowAspect[1] / rowAspect[0]

  const fractionFn = preserveAspect
    ? (media: Photos.Media) =>
        aspect / ((media.height || defHeight) / (media.width || defWidth))
    : (media: Photos.Media) => aspect

  let currentHeader: TimelineHeader = {
    content: 'header',
    day: -Infinity,
    ids: [],
    key: '',
  }
  let currentFraction = 0

  const finalizeRow = (row: TimelinePhotoRow, sumFraction?: number) => {
    sumFraction = sumFraction || row.fractions.reduce((sum, fr) => sum + fr, 0)
    // Final adjustment, check if last line needs to be stretched out or not
    if (currentFraction > 1 || Math.abs(1 - currentFraction) <= THRESHOLD) {
      row.fractions = row.fractions.map(f => f / currentFraction)
    } else if (preserveAspect) {
      row.fractions.push(1 - currentFraction)
    } else {
      for (
        let i = row.fractions.length;
        i < Math.round(rowAspect[0] / rowAspect[1]);
        i++
      ) {
        row.fractions.push(row.fractions[0])
      }
    }
  }

  for (let i = 0; i < photos.length; i++) {
    const media = photos[i]

    // New day group
    if (currentHeader.day !== media.capturedDay) {
      // Finalize last row in group
      if (rows.length) {
        const currentRow = rows[rows.length - 1] as TimelinePhotoRow
        finalizeRow(currentRow)
        currentFraction = 0
      }

      currentHeader = {
        content: 'header',
        day: media.capturedDay,
        ids: [],
        key: `h-${media.capturedDay}`,
      }
      rows.push(currentHeader)
      rows.push({ content: 'row', elements: [], fractions: [], key: '' })
    }

    let currentRow = rows[rows.length - 1] as TimelinePhotoRow
    const fraction = fractionFn(media)
    const nextFraction = currentFraction + fraction

    // New row
    if (
      currentRow.elements.length &&
      1 - currentFraction < Math.abs(1 - nextFraction)
    ) {
      // Adjust current row to use 100% fraction
      const cf = currentFraction
      currentRow.fractions = currentRow.fractions.map(f => (f / cf) * 100)

      // New row
      currentRow = { content: 'row', elements: [], fractions: [], key: '' }
      rows.push(currentRow)
      currentFraction = 0
    }

    // Finally add photo
    currentRow.elements.push(media)
    currentRow.fractions.push(fraction)
    currentHeader.ids.push(media.id)
    currentFraction += fraction

    if (currentRow.elements.length === 1) {
      currentRow.key = media.timestamp
    }
  }

  if (rows.length) {
    const row = rows[rows.length - 1]

    if (!isTimelineHeader(row)) {
      finalizeRow(row)
    }
  }

  return rows
}

export type GalleryRef = { scrollToMediaById: (id: string) => void }
