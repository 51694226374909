import { ErrorPage } from '@jotta/ui/ErrorPage'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import { ErrorBoundary } from '@sentry/react'
import { Suspense } from 'react'
import { AppLayoutSignup } from '@jotta/ui/AppLayoutSignup'
import { LoginPage } from './LoginPage'
import { useAndConsumeSearchParam } from '@jotta/router'

export function LoginRoute() {
  const token = useAndConsumeSearchParam('federation_token')
  // TODO should ErrorBoundary+Suspense be included in AppLayoutSignup?
  return (
    <AppLayoutSignup>
      <ErrorBoundary
        fallback={({ error, resetError }) => (
          <ErrorPage error={error} resetError={resetError} />
        )}
      >
        <Suspense fallback={<LoadingOverlay open />}>
          <LoginPage token={token} />
        </Suspense>
      </ErrorBoundary>
    </AppLayoutSignup>
  )
}
