import dayjs from 'dayjs'
import { Trans } from '@lingui/macro'
import type { FieldMeta } from '@tanstack/react-form'
import { useForm } from '@tanstack/react-form'
import { zodValidator } from '@tanstack/zod-form-adapter'
import Debug from 'debug'
import {
  Checkbox,
  FieldError,
  Input,
  Label,
  TextField,
} from 'react-aria-components'
import { useNavigate, useParams } from 'react-router-dom'
import { z } from 'zod'
import type { BizUserForm } from '../BizApi'
import { bizApi, fromBizUserForm, toBizUserForm } from '../BizApi'
import { cn } from '@jotta/utils/css'
const debug = Debug('jotta:biz:BizModifyUserFormTF')

export function BizModifyUserFormTF() {
  const { username = '' } = useParams()
  const userQuery = bizApi.user.byId.useSuspenseQuery({
    variables: { username },
  })
  const mutation = bizApi.user.update.useMutation()

  const navigate = useNavigate()
  const { Field, handleSubmit, Subscribe } = useForm<BizUserForm>({
    defaultValues: {
      ...toBizUserForm(userQuery.data),
    },
    onSubmit: async ({ value }) => {
      debug('submit', value)
      await mutation.mutateAsync({ user: fromBizUserForm(value) })
      navigate('../..', {
        relative: 'path',
      })
    },
  })

  return (
    <form
      className="form relative"
      onSubmit={e => {
        e.preventDefault()
        handleSubmit()
      }}
    >
      <Subscribe selector={state => state.isSubmitting}>
        {isSubmitting =>
          isSubmitting && <div className="loading loading-absolute"></div>
        }
      </Subscribe>
      <Field
        name="fullName"
        validatorAdapter={zodValidator()}
        validators={{
          onChange: z
            .string()
            .min(3, 'Name is required and must be at least 3 characters long.'),
        }}
      >
        {({ name, state, handleChange, handleBlur }) => (
          <TextField
            id={name}
            name={name}
            value={state.value}
            onChange={value => handleChange(value)}
            onBlur={handleBlur}
            isInvalid={!!state.meta.errors.length}
            className={'form-control'}
          >
            <Label>
              <Trans>Full name</Trans>
            </Label>
            <Input
              onChange={e => handleChange(e.currentTarget.value)}
              className="input"
            />
            <DisplayFieldErrors meta={state.meta} />
          </TextField>
        )}
      </Field>
      <Field
        name="email"
        validatorAdapter={zodValidator()}
        validators={{
          onChange: z
            .string()
            .email('Email is required and must be a valid email address.'),
        }}
      >
        {({ name, state, handleChange, handleBlur }) => (
          <TextField
            name={name}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={!!state.meta.errors.length}
            className={'form-control'}
          >
            <Label>
              <Trans>Email</Trans>
            </Label>
            <Input value={state.value} type="text" className="input" />
            <DisplayFieldErrors meta={state.meta} />
          </TextField>
        )}
      </Field>

      <div className="form-control">
        <Label>
          <Trans>Storage</Trans>
        </Label>
        <div className="grid-cols-max-fr1">
          <p id="unlimitedhint" className="form-control-text">
            <Trans>
              Unlimited means this user can upload as much as he/she likes, and
              is only limited by the total storage capacity of the company
              account.
            </Trans>
          </p>
          <Field name="unlimitedSpace">
            {({ name, state, handleChange, handleBlur }) => (
              <Checkbox
                className="checkbox col-span-full grid grid-cols-subgrid"
                name={name}
                onChange={handleChange}
                onBlur={handleBlur}
                isSelected={state.value}
                aria-describedby="unlimitedhint"
              >
                <Trans>Unlimited storage</Trans>
              </Checkbox>
            )}
          </Field>
          <Subscribe selector={state => state.values.unlimitedSpace}>
            {isUnlimited => (
              <Field
                name="spaceLimitString"
                validatorAdapter={zodValidator()}
                validators={{
                  onChange: z.literal('').or(z.coerce.number().finite()),
                }}
              >
                {({ name, state, handleChange, handleBlur }) => (
                  <TextField
                    id={name}
                    name={name}
                    value={state.value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!state.meta.errors.length}
                    className={cn('col-start-2 flex items-center gap-2', {
                      hidden: isUnlimited,
                    })}
                  >
                    <Input className="input" />
                    <Label>
                      <Trans>GB</Trans>
                    </Label>
                    <DisplayFieldErrors meta={state.meta} />
                  </TextField>
                )}
              </Field>
            )}
          </Subscribe>
        </div>
      </div>
      <div className="form-control">
        <Label>
          <Trans>Administrator</Trans>
        </Label>
        <Field name="isAdminUser">
          {({ name, state, handleChange, handleBlur }) => (
            <Checkbox
              className="checkbox col-span-full grid grid-cols-subgrid"
              name={name}
              onChange={handleChange}
              onBlur={handleBlur}
              isSelected={state.value}
            >
              <Trans>Make this user an administrator</Trans>
            </Checkbox>
          )}
        </Field>
      </div>
      <div className="form-control">
        <div className="label">
          <Trans>Last upload</Trans>
        </div>
        <div className="text-base">
          {dayjs(userQuery.data.lastUpload).fromNow()}
        </div>
      </div>
      <div className="form-buttons">
        <Subscribe
          selector={state => [state.isSubmitting, state.isDirty] as const}
        >
          {([isSubmitting, isDirty]) => (
            <button
              disabled={isSubmitting || !isDirty}
              type="submit"
              className="btn btn-primary"
            >
              <Trans>Modify user</Trans>
            </button>
          )}
        </Subscribe>
      </div>
    </form>
  )
}

function DisplayFieldErrors({ meta }: { meta: FieldMeta }) {
  return (
    <FieldError className="form-control-error">
      {meta.errors.map(err => (
        <div key={err?.toString()}>{err}</div>
      ))}
    </FieldError>
  )
}
