import { Box, Container } from '@jotta/ui/Box'
import { BrandIcon } from '@jotta/ui/BrandIcon'
import { Headline2 } from '@jotta/ui/Headline'
import { RadixModal } from '@jotta/ui/RadixModal'
import { Trans } from '@lingui/macro'
import { observer } from 'mobx-react-lite'

export const PhotoUploadOverlay = observer<{
  open?: boolean
}>(function PhotoUploadOverlay({ open = false }) {
  return (
    <RadixModal open={open} variant="layout.modalBackgroundPrimary">
      <Container variant="layout.center">
        <Box sx={{ mx: '16px' }}>
          <BrandIcon icon="SvgPhotosUpload" variant="styles.uploadIcon" />
          <Headline2 variant="styles.uploadHeading" sx={{ mt: 4 }}>
            <Trans>Drop your photos and videos here to upload</Trans>
          </Headline2>
        </Box>
      </Container>
    </RadixModal>
  )
})
