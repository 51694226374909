// import * as Sentry from '@sentry/react'
import { queryClient } from '@jotta/query'
import { parseAlbumInfo } from '@jotta/types/schemas/AlbumSchema'
import { getAlertListStore } from '@jotta/ui/AlertStore'
import { t } from '@lingui/macro'
import Debug from 'debug'
import { makeAutoObservable } from 'mobx'
import type {
  PhotoAction,
  PhotoActionType,
} from '../components/PhotoActions/PhotoActions.types'
import { defaultPhotoActionsApi } from '../components/PhotoActions/PhotoActions.types'
import type { PhotoStore } from './PhotoStore'
import { getInfiniteAlbumPhotosQueryKey } from './AlbumInfoQueryStore'
import { getAlbumListQueryKey } from '../components/Album/AlbumList/AlbumList.loaders'
export class PhotoActionStore {
  private log = Debug('jotta:photos:PhotoActionStore')
  constructor(
    private root: PhotoStore,
    private api = defaultPhotoActionsApi,
  ) {
    makeAutoObservable<typeof this, 'log'>(
      this,
      {
        log: false,
      },
      {
        autoBind: true,
      },
    )
  }

  async createAlbum(...args: PhotoAction<'CREATE_ALBUM'>['params']) {
    const data = await this.api.CREATE_ALBUM(...args)
    const album = parseAlbumInfo(data)
    const store = this.root.albumRoot.setAlbumInfo(album.id, album)
    queryClient.invalidateQueries({
      queryKey: getAlbumListQueryKey('NORMAL_ALBUM'),
    })
    return store.data
  }
  async deleteAlbum(...[albumId]: PhotoAction<'DELETE_ALBUM'>['params']) {
    const album = this.root.albumRoot.getAlbum(albumId)
    if (album) {
      album.delete()
    }
    try {
      await this.api.DELETE_ALBUM(albumId)
      if (album) {
        album.delete(true)
      }
      this.root.albumRoot.albums.delete(albumId)
    } catch (error) {
      if (album) {
        album.undelete()
      }
    }
  }
  async addSelectedPhotosToAlbum(
    ...[albumId, ids]: PhotoAction<'ADD_PHOTOS_TO_ALBUM'>['params']
  ) {
    const album = this.root.albumRoot.getAlbum(albumId)
    if (album) {
      album.model.photoIds = [...album.model.photoIds, ...ids]
      album.model.total = album.model.total + ids.length
    }
    try {
      const response = await this.api.ADD_PHOTOS_TO_ALBUM(albumId, ids)
      if (album) {
        if (album.model.isPropertyDirty('photoIds')) {
          album.model.submit()
        }
        album.updateAlbumInfo({
          coverPhoto: response.coverPhoto,
        })
      }
      return response
    } catch (error) {
      if (album && album.model.isPropertyDirty('photoIds')) {
        album.model.reset()
      }
    }
  }
  async removePhotosFromAlbum(
    ...[albumId, ids]: PhotoAction<'REMOVE_PHOTOS_FROM_ALBUM'>['params']
  ) {
    const album = this.root.albumRoot.getAlbum(albumId)
    if (album) {
      album.model.photoIds = album.model.photoIds.filter(
        id => !ids.includes(id),
      )
    }
    try {
      const response = await this.api.REMOVE_PHOTOS_FROM_ALBUM(albumId, ids)
      if (album) {
        if (album.model.isPropertyDirty('photoIds')) {
          album.model.submit()
        }
        album.updateAlbumInfo({
          coverPhoto: response.coverPhoto,
        })
      }
    } catch (error) {
      if (album && album.model.isPropertyDirty('photoIds')) {
        album.model.reset()
      }
      throw error
    }
  }
  async sharePhoto(...args: PhotoAction<'SHARE_PHOTO'>['params']) {
    const data = await this.api.SHARE_PHOTO(...args)
    const album = parseAlbumInfo(data)
    this.log('sharePhoto', data, album)
    const store = this.root.albumRoot.setAlbumInfo(album.id, album, true)
    return store
  }
  async shareAlbum(...args: PhotoAction<'SHARE_ALBUM'>['params']) {
    const data = await this.api.SHARE_ALBUM(...args)
    const album = parseAlbumInfo(data)
    const store = this.root.albumRoot.setAlbumInfo(album.id, album, true)
    queryClient.invalidateQueries({
      queryKey: getInfiniteAlbumPhotosQueryKey({ albumId: album.id }),
    })
    return store
  }
  async joinAlbum(...[albumId]: PhotoAction<'FOLLOW_ALBUM'>['params']) {
    await this.api.FOLLOW_ALBUM(albumId)
  }
  async leaveAlbum(...[albumId]: PhotoAction<'LEAVE_ALBUM'>['params']) {
    await this.api.LEAVE_ALBUM(albumId)
  }
  async renameAlbum(
    ...[albumId, title]: PhotoAction<'RENAME_ALBUM'>['params']
  ) {
    const album = this.root.albumRoot.getAlbum(albumId)
    if (album) {
      album.model.title = title
    }
    try {
      const data = await this.api.RENAME_ALBUM(albumId, title)
      this.log('Rename response', data)
      if (album && album.model.isPropertyDirty('title')) {
        album.model.submit()
      }
    } catch (error) {
      this.log('renameAlbum error', error)
      if (album && album.model.isPropertyDirty('title')) {
        album.model.reset()
      }
      getAlertListStore().error(t`Updating Album Title wasn't successful.`)
    }
  }
  async unshareAlbum(...[albumId]: PhotoAction<'UNSHARE_ALBUM'>['params']) {
    const album = this.root.albumRoot.getAlbum(albumId)
    if (album) {
      album.model.shareInfo = undefined
    }
    try {
      await this.api.UNSHARE_ALBUM(albumId)
      if (album && album.model.isPropertyDirty('shareInfo')) {
        album.model.submit()
      }
      await queryClient.invalidateQueries({ queryKey: ['shares'] })
      await queryClient.invalidateQueries({
        queryKey: getInfiniteAlbumPhotosQueryKey({ albumId: albumId }),
      })
    } catch (error) {
      this.log('unshareAlbum error', error)
      if (album && album.model.isPropertyDirty('shareInfo')) {
        album.model.reset()
      }
    }
  }
  async setAlbumAccess(
    ...[albumId, rw]: PhotoAction<'SET_ALBUM_ACCESS'>['params']
  ) {
    const album = this.root.albumRoot.getAlbum(albumId)
    if (album && album.model.shareInfo) {
      album.model.shareInfo.authorization = rw
    }
    try {
      await this.api.SET_ALBUM_ACCESS(albumId, rw)
      if (album && album.model.isPropertyDirty('shareInfo')) {
        album.model.submit()
      }
    } catch (error) {
      this.log('unshareAlbum error', error)
      if (album && album.model.isPropertyDirty('shareInfo')) {
        album.model.reset()
      }
    }
  }
  async downloadPhotos(...[ids]: PhotoAction<'DOWNLOAD_PHOTOS'>['params']) {
    await this.api.DOWNLOAD_PHOTOS(ids)
  }
  async deletePhotos(...[ids]: PhotoAction<'DELETE_PHOTOS'>['params']) {
    const { undo, commit } = this.root.mediaObjects.requestDelete(ids)
    try {
      await this.api.DELETE_PHOTOS(ids)
      this.root.timeline.delete(ids)
      commit()
    } catch (error) {
      this.log('Delete photos failed: ', error)
      undo()
    }
  }
  async unhidePhotos(...[ids]: PhotoAction<'UNHIDE_PHOTO'>['params']) {
    try {
      await this.api.UNHIDE_PHOTO(ids)
      this.root.mediaObjects.unhidePhotos(ids)
      this.root.timeline.unhide(ids)
    } catch (error) {
      this.log('Unhide photos failed: ', error)
    }
  }
  async hidePhotos(...[ids]: PhotoAction<'HIDE_PHOTO'>['params']) {
    try {
      await this.api.HIDE_PHOTO(ids)
      this.root.mediaObjects.hidePhotos(ids)
      this.root.timeline.hide(ids)
    } catch (error) {
      this.log('Hide photos failed: ', error)
    }
  }

  async dispatch<A extends PhotoActionType>(
    actionType: A extends PhotoActionType ? A : never,
    ...args: PhotoAction<typeof actionType>['params']
  ) {
    this.log('Dispatch', actionType, ...args)
    switch (actionType) {
      case 'CREATE_ALBUM': {
        const album = await this.createAlbum(
          ...(args as PhotoAction<'CREATE_ALBUM'>['params']),
        )
        return album
      }
      case 'DELETE_ALBUM':
        await this.deleteAlbum(
          ...(args as PhotoAction<'DELETE_ALBUM'>['params']),
        )
        break
      case 'FOLLOW_ALBUM':
        await this.joinAlbum(...(args as PhotoAction<'FOLLOW_ALBUM'>['params']))
        break
      case 'LEAVE_ALBUM':
        await this.leaveAlbum(...(args as PhotoAction<'LEAVE_ALBUM'>['params']))
        break
      case 'RENAME_ALBUM':
        await this.renameAlbum(
          ...(args as PhotoAction<'RENAME_ALBUM'>['params']),
        )
        break
      case 'SHARE_PHOTO': {
        const album = await this.sharePhoto(
          ...(args as PhotoAction<'SHARE_PHOTO'>['params']),
        )
        this.log('album', album)
        return album.data
      }
      case 'SHARE_ALBUM': {
        const album = await this.shareAlbum(
          ...(args as PhotoAction<'SHARE_ALBUM'>['params']),
        )
        return album
      }
      case 'UNSHARE_ALBUM':
        await this.unshareAlbum(
          ...(args as PhotoAction<'UNSHARE_ALBUM'>['params']),
        )
        break
      case 'SET_ALBUM_ACCESS':
        await this.setAlbumAccess(
          ...(args as PhotoAction<'SET_ALBUM_ACCESS'>['params']),
        )
        break
      case 'ADD_PHOTOS_TO_ALBUM': {
        const album = await this.addSelectedPhotosToAlbum(
          ...(args as PhotoAction<'ADD_PHOTOS_TO_ALBUM'>['params']),
        )
        return album
      }
      case 'REMOVE_PHOTOS_FROM_ALBUM':
        await this.removePhotosFromAlbum(
          ...(args as PhotoAction<'REMOVE_PHOTOS_FROM_ALBUM'>['params']),
        )
        break
      case 'DELETE_PHOTOS':
        await this.deletePhotos(
          ...(args as PhotoAction<'DELETE_PHOTOS'>['params']),
        )
        break
      case 'DOWNLOAD_PHOTOS':
        await this.downloadPhotos(
          ...(args as PhotoAction<'DOWNLOAD_PHOTOS'>['params']),
        )
        break
      case 'HIDE_PHOTO':
        await this.hidePhotos(...(args as PhotoAction<'HIDE_PHOTO'>['params']))
        break
      case 'UNHIDE_PHOTO':
        await this.unhidePhotos(
          ...(args as PhotoAction<'UNHIDE_PHOTO'>['params']),
        )
        break

      default:
        this.log('Dispatch %s not handled', actionType)
        break
    }
  }
}
