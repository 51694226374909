import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import type { BrandThemeIcon } from '@jotta/types/Brand'
import { t } from '@lingui/macro'
import clsx from 'clsx'
import type { PropsWithChildren, ReactNode } from 'react'
import { FormattedContent } from '../FormattedContent/FormattedContent'

export function BaseErrorPage({
  title = t`An error occured!`,
  icon = 'SvgCloudError',
  children,
  className,
}: PropsWithChildren<{
  title?: ReactNode
  icon?: BrandThemeIcon
  className?: string
}>) {
  return (
    <div
      className={clsx(
        'grid w-full grid-cols-1 justify-items-center gap-8 p-16 md:grid-cols-2',
        className,
      )}
    >
      <PlainBrandIcon icon={icon} className="max-w-64 md:justify-self-end" />
      <FormattedContent
        variant="formats.errorPage"
        className="md:justify-self-start"
      >
        <h2>{title}</h2>
        {children}
      </FormattedContent>
    </div>
  )
}
