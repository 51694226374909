import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import styles from './PathItemRow.module.scss'
import type { PathItemStore } from './PathItemStore'
const debug = Debug('jotta:files:PathItemCheckbox')

export interface PathItemCheckboxProps {
  item: PathItemStore
}

export const PathItemCheckbox = observer<PathItemCheckboxProps>(
  function PathItemCheckbox({ item }: PathItemCheckboxProps) {
    return (
      <label className={styles.checkbox}>
        <input
          type="checkbox"
          value={item.path}
          name="select"
          checked={item.isSelected}
          onChange={item.onSelectCheckboxChange}
        />
      </label>
    )
  },
)
