import { Box } from '@jotta/ui/Box'
import { observer } from 'mobx-react-lite'
import type { ReactNode } from 'react'
import { useBrandCode } from '../../hooks/useBrandTheme'

import { DownloadPageHeader } from '../DownloadPageHeader/DownloadPageHeader'
import { JottaFooter } from '../JottaFooter/JottaFooter'
import { PublicFooter } from '../PublicFooter/PublicFooter'

export interface AppLayoutDownloadProps {
  isLoggedIn?: boolean
  children?: ReactNode
}

export const AppLayoutDownload = observer<AppLayoutDownloadProps>(
  function AppLayoutDownload({ isLoggedIn = false, children, ...props }) {
    const showJottaFooter = useBrandCode() === 'JOTTACLOUD'

    return (
      <>
        <Box
          data-testid="appLayoutDownloadContainer"
          variant="layout.containerDownload"
          {...props}
        >
          <DownloadPageHeader isLoggedIn={isLoggedIn} />

          {children}
          {showJottaFooter ? <JottaFooter /> : <PublicFooter />}
        </Box>
      </>
    )
  },
)
