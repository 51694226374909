import { Box } from '@jotta/ui/Box'
import { Button } from '@jotta/ui/Button'
import { Input } from '@jotta/ui/Input'
import { Label } from '@jotta/ui/Label'
import type { AppError } from '@jotta/types/AppError'
import { RadixDialog } from '@jotta/ui/RadixDialog'
import { RadixDialogToast } from '@jotta/ui/RadixDialogToast'
import type { RadixModalProps } from '@jotta/ui/RadixModal'
import { RadixModal } from '@jotta/ui/RadixModal'
import { Trans, t } from '@lingui/macro'
import { DialogClose } from '@radix-ui/react-dialog'
import { useMutation } from '@tanstack/react-query'
import Debug from 'debug'
import { Code } from '@connectrpc/connect'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import type { FileActionStore } from '../FileActionStore'
const debug = Debug('jotta:files:action:RenameDialog')

type CreateFolderDialogProps = {
  createFolder: FileActionStore['createFolder']
  error: AppError | null
  clearError: (open?: boolean) => void
  initialName?: string
} & RadixModalProps

export const CreateFolderDialog = observer<CreateFolderDialogProps>(
  function CreateFolderDialog({
    error,
    clearError,
    open = false,
    createFolder,
    onClose,
    initialName = '',
    ...props
  }) {
    if (error) {
      debug('error', error, error.message, error.grpcStatus)
    }
    const [newName, setNewName] = useState(initialName)
    const [isEmptyError, setIsEmptyError] = useState(false)
    const createFolderMutation = useMutation({ mutationFn: createFolder })
    const isEmpty = !newName
    const slashInFolderName = /(\/|\\)/.test(newName)
    const isValid = !isEmpty && !slashInFolderName
    const errorText = getFolderErrorMessage(error)
    return (
      <RadixModal open={open} onClose={onClose} {...props}>
        <RadixDialog
          data-testid="CreateFolderDialog"
          title={t`Create a new folder`}
          onClose={onClose}
          toastPosition="belowcontent"
          customButtons={
            <Box variant="dialogs.footer">
              <Button
                disabled={!isValid}
                variant="buttons.primary"
                onClick={() => createFolderMutation.mutateAsync(newName)}
              >
                <Trans>Create</Trans>
              </Button>
              <DialogClose
                sx={{
                  variant: 'buttons.secondary',
                }}
              >
                <Trans>Close</Trans>
              </DialogClose>
            </Box>
          }
        >
          <form
            onSubmit={e => {
              e.preventDefault()
              if (isValid) {
                createFolderMutation.mutateAsync(newName)
              }
            }}
          >
            <Label
              label={t`Name`}
              errorMessage={
                isEmptyError
                  ? t`Name is required!`
                  : slashInFolderName
                    ? t`You can not use '/' in the folder name`
                    : undefined
              }
            >
              <Input
                ref={focusRef}
                type="text"
                id="createfolder"
                value={newName}
                placeholder={t`untitled folder`}
                onChange={e => {
                  setNewName(e.target.value)
                  setIsEmptyError(!e.target.value)
                }}
              />
            </Label>
          </form>
          <RadixDialogToast
            direction="fromBottom"
            severity="errorOutline"
            open={Boolean(errorText)}
            onOpenChange={clearError}
            title={errorText}
            data-testid="CreateFolderError"
          />
        </RadixDialog>
      </RadixModal>
    )
  },
)
function focusRef(el: HTMLInputElement | null) {
  if (el) {
    el.focus()
  }
}

export function getCreateFolderErrorMessageForStatusCode(status?: Code) {
  if (!status) {
    return ''
  }
  switch (status) {
    case Code.AlreadyExists:
      return t`There is already a file or folder with this name, the operation was aborted.`

    default:
      return t`Unknown error please try again later.`
  }
}
export function getFolderErrorMessage(error: AppError | null) {
  if (!error) {
    return ''
  }
  return getCreateFolderErrorMessageForStatusCode(error.grpcStatus)
}
