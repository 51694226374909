import { AuthServicePromiseClient } from '@jotta/grpc-web/no/jotta/openapi/auth/v2/auth.v2_grpc_web_pb'
import type {
  ConfirmTFAResponse,
  DeleteSessionResponse,
  DisableTFARequest,
  DisableTFAResponse,
  GenerateLoginTokenRequest,
  InitializeIdentityLinkingResponse,
  InitializeTFAResponse,
  ListEventsResponse,
  ListLinkedIdentitiesResponse,
  UnlinkIdentityResponse,
} from '@jotta/grpc-web/no/jotta/openapi/auth/v2/auth.v2_pb'
import {
  ConfirmTFARequest,
  DeleteSessionRequest,
  InitializeIdentityLinkingRequest,
  InitializeTFARequest,
  ListEventsRequest,
  ListLinkedIdentitiesRequest,
  ListSessionsRequest,
  UnlinkIdentityRequest,
} from '@jotta/grpc-web/no/jotta/openapi/auth/v2/auth.v2_pb'
import { Customer } from '@jotta/grpc-web/no/jotta/openapi/customer/customer.v2_pb'
import { withGrpcClientMiddleware } from './grpcutils'
import { ErrorType } from '@jotta/grpc-web/no/jotta/openapi/error_pb'
import { GrpcApiError } from '@jotta/types/GrpcApiError'
import { env } from '@jotta/utils/env'

const InitializeSMSTFARequest = InitializeTFARequest.InitializeSMSTFARequest
const InitializeTOTPRequest = InitializeTFARequest.InitializeTOTPRequest

let authClient: AuthServicePromiseClient

const GRPC_WEB_API = env.grpcApi

export function getAuthClient() {
  if (!authClient) {
    authClient = withGrpcClientMiddleware(
      'auth',
      new AuthServicePromiseClient(GRPC_WEB_API),
    )
  }

  return authClient
}

export type InitTFAResult = {
  result: InitTFAEnum
  response?: InitializeTFAResponse
}
export enum InitTFAEnum {
  OK,
  PHONE_NUMBER_IN_USE,
  INVALID_PHONE_NUMBER,
}

export const initTFA = async (
  type: Customer.TFAType.TOTP | Customer.TFAType.SMS,
  phoneNumber?: string,
): Promise<InitTFAResult> => {
  const request = new InitializeTFARequest()

  if (type === Customer.TFAType.TOTP) {
    request.setTotp(new InitializeTOTPRequest())
  }

  if (type === Customer.TFAType.SMS) {
    if (phoneNumber) {
      request.setSms(new InitializeSMSTFARequest().setPhoneNumber(phoneNumber))
    } else {
      throw new Error(`phoneNumber param is not provided.`)
    }
  }

  try {
    const res = await getAuthClient().initializeTFA(request)
    return {
      response: res,
      result: InitTFAEnum.OK,
    }
  } catch (err) {
    if (err instanceof GrpcApiError) {
      if (err.hasError(ErrorType.PHONE_NUMBER_IN_USE)) {
        return {
          response: undefined,
          result: InitTFAEnum.PHONE_NUMBER_IN_USE,
        }
      }
      if (err.hasError(ErrorType.INVALID_PHONE_NUMBER)) {
        return {
          response: undefined,
          result: InitTFAEnum.INVALID_PHONE_NUMBER,
        }
      }
    }
    throw err
  }
}

export const confirmTFA = (
  type: Customer.TFAType.TOTP | Customer.TFAType.SMS,
  verificationCode: string,
  verificationId?: string,
): Promise<ConfirmTFAResponse.AsObject> => {
  const request = new ConfirmTFARequest()

  if (type === Customer.TFAType.TOTP) {
    request.setTotp(
      new ConfirmTFARequest.ConfirmTOTPRequest().setVerificationCode(
        verificationCode,
      ),
    )
  }

  if (type === Customer.TFAType.SMS) {
    if (verificationId) {
      request.setSms(
        new ConfirmTFARequest.ConfirmSMSTFARequest()
          .setVerificationId(verificationId)
          .setVerificationCode(verificationCode),
      )
    } else {
      throw new Error(`verificationId param is not provided.`)
    }
  }

  return getAuthClient()
    .confirmTFA(request)
    .then(response => response.toObject())
}

export const disableTFA = (
  req: DisableTFARequest,
): Promise<DisableTFAResponse.AsObject> =>
  getAuthClient()
    .disableTFA(req)
    .then(response => response.toObject())

export const getLinkedIdentities =
  (): Promise<ListLinkedIdentitiesResponse.AsObject> => {
    const request = new ListLinkedIdentitiesRequest()

    return getAuthClient()
      .listLinkedIdentities(request)
      .then(response => response.toObject())
  }

export const linkProvider = (
  providerId: string,
  redirectUrl?: string,
): Promise<InitializeIdentityLinkingResponse.AsObject> => {
  const request = new InitializeIdentityLinkingRequest()
  request.setProviderId(providerId)

  if (redirectUrl) {
    request.setRedirectUrl(redirectUrl)
  }

  return getAuthClient()
    .initializeIdentityLinking(request)
    .then(response => response.toObject())
}

export const unLinkProvider = (
  providerId: string,
): Promise<UnlinkIdentityResponse.AsObject> => {
  const request = new UnlinkIdentityRequest()

  request.setProviderId(providerId)

  return getAuthClient()
    .unlinkIdentity(request)
    .then(response => response.toObject())
}

export const generateLoginToken = (request: GenerateLoginTokenRequest) =>
  getAuthClient()
    .generateLoginToken(request)
    .then(response => response.toObject())

export const listSessions = () => {
  const request = new ListSessionsRequest()
  return getAuthClient()
    .listSessions(request)
    .then(response => response.toObject())
}

export const deleteSession = (
  sessionId: string,
): Promise<DeleteSessionResponse.AsObject> => {
  const request = new DeleteSessionRequest()
  request.setSessionId(sessionId)
  return getAuthClient()
    .deleteSession(request)
    .then(response => response.toObject())
}

export const listEvents = (): Promise<ListEventsResponse.AsObject> => {
  const request = new ListEventsRequest()
  return getAuthClient()
    .listEvents(request)
    .then(response => response.toObject())
}
