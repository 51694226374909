import type { FunctionComponent, SVGProps } from 'react'
import type { BrandKey, BrandName } from './generated/brands'
import { brandKeys, brandNames, brandsByName } from './generated/brands'
import type { brandThemeIcons } from './generated/BrandThemeIcons'
import type { KeyMap } from './TypeUtils'

export * from './generated/brands'
export { brandThemeIcons } from './generated/BrandThemeIcons'

export interface BreakpointWidth {
  min: number
  mid: number
  max: number
}
export type ReactSvgComponent = FunctionComponent<SVGProps<SVGSVGElement>> & {
  title?: string | undefined
}
export type BrandThemeIcon = (typeof brandThemeIcons)[number]
export type BrandThemeIconMap = KeyMap<BrandThemeIcon, ReactSvgComponent>

export function isBrandKey(value: any): value is BrandKey {
  return typeof value === 'string' && brandKeys.includes(value as BrandKey)
}
export function isBrandName(value: any): value is BrandName {
  return typeof value === 'string' && brandNames.includes(value as BrandName)
}
export function getBrandCodeByNameOrCode(
  value: unknown,
  defaltCode: BrandKey = 'JOTTACLOUD',
): BrandKey {
  if (isBrandKey(value)) {
    return value
  }
  if (isBrandName(value)) {
    return brandsByName[value].code
  }
  return defaltCode
}
export type BrandMap<T> = KeyMap<BrandKey, T>

export type ForwardRef<T, P> = React.ForwardRefExoticComponent<
  React.PropsWithoutRef<P> & React.RefAttributes<T>
>
export type BreakpointList<T> = [T, T, T]
export type ResponsiveValue<T> = T[]

export interface BrandConfig {
  brand: BrandKey
  /** Path to theme file */
  themedir: string
  /** Text to show in HTML meta tag */
  metaTitle: string
  /** Path to app icon SVG file, used to generate app icons  */
  appIconSvg: string
  /** Path to fsvicon SVG file, used to generate favicon  */
  favIconSvg: string
  /** Directory under /webapp_static to put brand assets and generated icons */
  staticDir: string
  width: number
  height: number
  sidebar: {
    closedWidth: ResponsiveValue<number | string>
    openWidth: ResponsiveValue<number | string>
    showPoweredByJotta: boolean
  }
  header: {
    height: number
    showBrandLogo: boolean
    showHamburger: boolean
    showSignupButtons: boolean
  }
  topToolBar: {
    height: number
  }
  bottomToolBar: {
    height: number
  }
  photos: {
    timeline: {
      headerRowAspect: ResponsiveValue<number>
      imageRowAspect: ResponsiveValue<number>
      scrubberWidth: ResponsiveValue<number>
      mobileTimelineBreakpoint: number
    }
  }
  learnMoreLinkWaitlist: number
  learnMoreLinkSearch: number
  files: {
    folderRowHeight: number
    folderColumnWidth: number
    folderThumbAspect: number
    publicFolderRowHeight: number
  }
  showLanguageSelector: boolean
}
