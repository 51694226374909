import { Global } from '@emotion/react'

export const HideScrollbar: React.FC = () => (
  <Global
    styles={{
      html: {
        scrollbarWidth: 'none',
      },
      body: {
        scrollbarWidth: 'none',
        '::-webkit-scrollbar': {
          display: 'none',
        },
      },
    }}
  />
)
