import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { Headline3 } from '@jotta/ui/Headline'
import { t } from '@lingui/macro'
import clsx from 'clsx'

import styles from './PhotoSearch.module.scss'
import { useOnLearnMoreSearch } from '../../routes/useOnLearnMore'

export interface PhotoSearchSuggestionsProps {
  query: string
  searchSuggestions: string[] | undefined
  onSuggestionClick: (suggestion: string) => void
  selected: number
  setSelected: (n: number) => void
  onMouseDown?: React.MouseEventHandler
}

export const PhotoSearchSuggestions: React.FC<PhotoSearchSuggestionsProps> = ({
  query,
  searchSuggestions = [],
  onSuggestionClick,
  selected,
  setSelected,
  onMouseDown,
}) => {
  const { onLearnMoreSearch } = useOnLearnMoreSearch()
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      data-testid="photo-search-suggestions"
      className={styles.suggestions}
      onMouseDown={onMouseDown}
    >
      <div className={styles.suggestionsHeading}>
        <Headline3>{t`Suggestions`}</Headline3>
      </div>
      <ul
        role="listbox"
        aria-label="Search Suggestions"
        className={styles.suggestionsList}
      >
        {searchSuggestions?.map((suggestion, i) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <li
            key={suggestion}
            role="option"
            aria-selected={query === suggestion}
            className={clsx({ [styles.selected]: selected === i + 1 })}
            onMouseEnter={() => {
              setSelected(i + 1)
            }}
            onClick={() => onSuggestionClick(suggestion)}
          >
            <PlainBrandIcon icon="SvgSearchAlt" />
            <span>{suggestion}</span>
          </li>
        ))}
      </ul>
      <button
        className={clsx(styles.suggestionsLearnMore, {
          [styles.selected]:
            selected && selected === searchSuggestions.length + 1,
        })}
        onClick={onLearnMoreSearch}
        tabIndex={-1}
        onMouseEnter={() =>
          searchSuggestions.length && setSelected(searchSuggestions.length + 1)
        }
      >
        <PlainBrandIcon icon="SvgHelp" />
        <span>{t`Learn more`}</span>
      </button>
    </div>
  )
}
