import type { AppLayoutSlot } from '@jotta/ui/BrandStore'
import { useBrandStore } from '@jotta/ui/useBrandTheme'
import { observer } from 'mobx-react-lite'
import type { ReactNode } from 'react'
import { useEffect } from 'react'
import { Portal } from 'react-portal'

import Debug from 'debug'
const debug = Debug('jotta:AppLayoutPortal')
export interface AppLayoutPortalProps {
  slot: AppLayoutSlot
  children?: ReactNode
}
export const AppLayoutPortal = observer<AppLayoutPortalProps>(
  function AppLayoutPortal({ slot, children }) {
    const layout = useBrandStore()
    const hidden = (slot === 'actionButton' && layout.hideFab) || false

    useEffect(() => {
      if (hidden) {
        return
      }

      if (children) {
        layout.registerSlot(slot)
        return () => {
          layout.unregisterSlot(slot)
        }
      }
    }, [children, layout, slot, hidden])

    if (hidden) {
      return null
    }

    return <Portal node={layout.slots[slot].current}>{children}</Portal>
  },
)
