import type {
  GetUpdatesResponse,
  Update,
} from '@jotta/grpc-js-client/updateService'
import { listUpdates, markAsRead } from '@jotta/grpc-js-client/updateService'
import type { Notification } from '@jotta/types/Notifications'
import { useInfiniteQuery } from '@tanstack/react-query'
import Debug from 'debug'

export interface NotificationsApi {
  fetch: (next?: string) => Promise<GetUpdatesResponse.AsObject>
  markAsRead: (update: Update.AsObject) => Promise<unknown>
}
export const notificationsApi: NotificationsApi = {
  fetch: next =>
    listUpdates({
      cursor: next,
    }),
  markAsRead: update =>
    markAsRead({
      update: update,
    }),
}

const debug = Debug('jotta:ui:NotificationsApi')

export function useNotifications(api = notificationsApi) {
  const query = useInfiniteQuery({
    queryKey: ['notifications'],
    queryFn: ({ pageParam }) => api.fetch(pageParam),
    getNextPageParam: lastPage => lastPage.cursor || undefined,
    initialPageParam: '',
    refetchInterval: 60000,
    refetchOnWindowFocus: true,
    refetchIntervalInBackground: false,
  })
  const unreadCount =
    query.data && query.data.pages.length && query.data.pages[0].newUpdatesCount
  const notifications: Notification[] = query.data
    ? query.data.pages.flatMap(page => page.updateList)
    : []
  return {
    ...query,
    notifications,
    unreadCount,
  }
}
