import { t, Trans } from '@lingui/macro'
import { Link } from 'react-router-dom'
import { BaseErrorPage } from './BaseErrorPage'
import { useEffect } from 'react'

export function ErrorPageNotFound({ resetError }: { resetError?: () => void }) {
  useEffect(() => {
    return () => {
      if (resetError) {
        resetError()
      }
    }
  }, [resetError])

  return (
    <BaseErrorPage title={t`Page not found`}>
      <p>
        <Trans>
          Try to go back to the homepage, or have a look in the navigation menu
          below. We are sorry for the inconvenience, we hope you find what you
          are looking for.
        </Trans>
      </p>
      <p>
        <Link to="/" replace reloadDocument>
          <Trans>Click here to go back to the homepage.</Trans>
        </Link>
      </p>
    </BaseErrorPage>
  )
}
