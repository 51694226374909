import { t } from '@lingui/macro'
import { CreateUser } from './CreateUser'
import { SignupContainer } from './SignupContainer'

export function FreeSignup({ federationToken }: { federationToken?: string }) {
  return (
    <SignupContainer title={t`Create a free account`}>
      <CreateUser
        type="private"
        federationToken={federationToken}
        federationIntent={{
          case: 'signup',
          value: {},
        }}
      />
    </SignupContainer>
  )
}
