import type { Currency } from '@jotta/grpc-web/no/jotta/openapi/subscription/payment.v2_pb'
import { SubscriptionInterval } from '@jotta/grpc-web/no/jotta/openapi/subscription/payment.v2_pb'
import { t } from '@lingui/macro'
import type { Locale } from '@jotta/i18n'
import { nameOfCurrency } from '@jotta/crm-admin/src/products'

export type Money = {
  amount: bigint | number
  currency: Currency
}

export const formatPrice = ({ amount, currency }: Money, locale: Locale) => {
  return formatCurrency(Number(amount), currency, locale)
}

export const formatPriceConnect = (
  { amount, currency }: Money,
  locale: Locale,
) => {
  return formatCurrency(Number(amount), currency, locale)
}

export const formatPriceConnectWithInterval = (
  interval: SubscriptionInterval,
  { amount, currency }: Money,
  locale: Locale,
): string => {
  const intervalString =
    interval == SubscriptionInterval.YEARLY ? t`year` : t`month`
  return `${formatCurrency(Number(amount), currency, locale)} / ${intervalString}`
}

// See guidelines for price formatting:
// https://polaris.shopify.com/foundations/formatting-localized-currency
export function formatCurrency(
  amount_cents: number,
  currency: Currency,
  locale: Locale,
) {
  const centsAmount = amount_cents % 100
  const digits = centsAmount === 0 ? 0 : 2
  const currencyFormat = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: nameOfCurrency(currency),
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
  })
  const formatted = currencyFormat.format(amount_cents / 100)
  return formatted.endsWith('.') ? formatted.slice(undefined, -1) : formatted
}
