import { downgradeAccount } from '@jotta/grpc-js-client/customerService'
import {
  applyVoucher,
  changePaymentInterval,
  changePaymentMethod,
  createSetupIntent,
  createVippsIntent,
  describeVoucher,
  getSubscriptionDetails,
} from '@jotta/grpc-js-client/subscriptionService'
import { DowngradeAccountRequest } from '@jotta/grpc-web/no/jotta/openapi/customer/customer.v2_pb'
import type {
  DescribeVoucherResponse,
  GetPaymentInfoResponse,
  PaymentInfo,
  SubscriptionInterval,
  SubscriptionMessage,
} from '@jotta/grpc-web/no/jotta/openapi/subscription/payment.v2_pb'
import Debug from 'debug'
// import * as Sentry from '@sentry/react'
import { makeAutoObservable } from 'mobx'
import type {
  ChallengeableRequestParams,
  ResolveState,
} from '../../utils/ChallengeModel'
import { challengeCheck } from '../../utils/ChallengeUtils'
const debug = Debug('jotta:settings:PaymentStore')

export class PaymentStore {
  voucherInfo?: DescribeVoucherResponse.AsObject
  subscription?: GetPaymentInfoResponse.AsObject
  subscriptionMessagesList: SubscriptionMessage.MessageCase[] = [] // subscriptionMessagesList separated from the main object because of grpc binding problem. ref: https://github.com/protocolbuffers/protobuf/issues/7327
  paymentMethodCase?: PaymentInfo.PaymentMethodCase
  changeSubscriptionOptionCase?: GetPaymentInfoResponse.ChangeSubscriptionOptionCase
  isCardDialogOpen = false

  constructor() {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      },
    )
  }

  async fetchSubscriptionDetailsInfo() {
    debug('getSubscriptionDetails')
    const response = await getSubscriptionDetails()
    this.handleSubscriptionResponse(response)
    return response
  }

  handleSubscriptionResponse(response: GetPaymentInfoResponse) {
    this.changeSubscriptionOptionCase =
      response.getChangeSubscriptionOptionCase()
    this.subscriptionMessagesList = response
      .getSubscriptionMessagesList()
      .map(e => e.getMessageCase())
    this.paymentMethodCase = response.getPaymentInfo()?.getPaymentMethodCase()
    this.subscription = response.toObject()
  }

  createSetupIntent = () => createSetupIntent()
  createVippsIntent = createVippsIntent

  changePaymentMethod = async (paymentMethodToken: string) => {
    const res = await changePaymentMethod(paymentMethodToken)
    await this.fetchSubscriptionDetailsInfo()
    return res
  }

  changePaymentInterval = async (interval: SubscriptionInterval) => {
    try {
      const res = await changePaymentInterval(interval)
      await this.fetchSubscriptionDetailsInfo()
      if (this.subscription?.subscriptionInterval) {
        this.subscription.subscriptionInterval = interval
        //this.subscription?.price = interval
      }
      return res
    } catch (e) {
      debug(e)
      // Sentry.captureException(e)
    }
  }

  describeVoucher = async (code: string) => {
    try {
      this.voucherInfo = await describeVoucher(code)
    } catch (e) {
      debug(e)
      // Sentry.captureException(e)
    }
  }

  downgradeAccount = async ({
    request = new DowngradeAccountRequest(),
    onResolve,
    onFail,
  }: ChallengeableRequestParams) => {
    try {
      const response = await downgradeAccount(request)
      const f = (state?: ResolveState) => {
        this.fetchSubscriptionDetailsInfo().then(() => {
          if (onResolve && state) {
            onResolve(state)
          }
        })
      }
      challengeCheck({ request, onResolve: f, onFail }, response.challenges)
    } catch (e) {
      if (onFail) {
        onFail(e)
      }
    }
  }

  resetVoucherInfo = () => (this.voucherInfo = undefined)

  applyVoucher = async (code: string) => {
    const res = await applyVoucher(code)
    await this.fetchSubscriptionDetailsInfo()
    return res
  }

  toggleCardDialog = (state?: boolean) => {
    this.isCardDialogOpen = state || !this.isCardDialogOpen
  }
}
