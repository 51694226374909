import { useLingui } from '@lingui/react'
import { Elements } from '@stripe/react-stripe-js'
import type { Stripe, StripeElementLocale } from '@stripe/stripe-js'
import { loadStripe } from '@stripe/stripe-js/pure'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import type { PropsWithChildren } from 'react'
import { useFeatures, useGetConfig } from '@jotta/grpc-connect-client/config'

const debug = Debug('jotta:settings:StripeContainer')

const STRIPE_TEST_KEY = 'pk_test_Hx504wwR6fwwkcEm4QbbiksR'
let stripePromise: Promise<Stripe | null>

/*Opt-out of deep data collection from Stripe and stop cookies
loading for partners who do not use stripe. The Stripe cookies
load for brands that use stripe.
*/
loadStripe.setLoadParameters({ advancedFraudSignals: false })
async function loadStripeConfig(
  stripePublicKey: string,
): Promise<Stripe | null> {
  return loadStripe(stripePublicKey)
}

type Props = PropsWithChildren<{ publicKey?: string }>
export const StripeElementsContainer = observer<Props>(
  function StripeElementsContainer({ children, publicKey }) {
    const { i18n } = useLingui()
    const features = useFeatures()
    const { brandingInfo } = useGetConfig().data
    const stripePublicKey =
      publicKey || brandingInfo?.stripePublicKey || STRIPE_TEST_KEY

    const stripeLocale: StripeElementLocale =
      (i18n.locale as StripeElementLocale) || 'auto'

    if (!stripePromise && features.stripe) {
      stripePromise = loadStripeConfig(stripePublicKey)
    }

    return (
      stripePromise && (
        <Elements
          options={{
            locale: stripeLocale,
          }}
          stripe={stripePromise}
        >
          {children}
        </Elements>
      )
    )
  },
)
