import type { HTMLAttributes } from 'react'
import { forwardRef } from 'react'
import type { VariantProps } from 'tailwind-variants'
import { tv } from 'tailwind-variants'

const variants = tv({
  base: 'size-10 animate-[spin_1s_linear_infinite] rounded-full border-4 border-current border-l-transparent',
  variants: {
    variant: {
      primary: 'text-sx-primary',
      accent: 'text-sx-accent',
      danger: 'text-sx-danger',
    },
  },
})
export const Spinner = forwardRef<
  HTMLDivElement,
  VariantProps<typeof variants> & HTMLAttributes<HTMLDivElement>
>(function Spinner({ variant, className, ...props }, ref) {
  return (
    <div
      {...props}
      data-testid="uinext/spinner"
      className={variants({
        variant,
        className,
      })}
      // className="bg-primary px-3 py-2"
      ref={ref}
    />
  )
})
