import Debug from 'debug'
import Cookies from 'universal-cookie'
import type { ZodType } from 'zod'

const debug = Debug('jotta:utils:cookie')
const cookies = new Cookies()

export class ZodCookie<T> {
  readonly name: string
  readonly schema: ZodType<T>
  readonly def: T
  private current: T | undefined

  constructor(name: string, schema: ZodType<T>, def: T) {
    this.name = name
    this.def = def
    this.schema = schema
  }

  public remove() {
    this.current = this.def
    cookies.remove(this.name, { path: '/', domain: window.location.hostname })
  }

  public set(value: T, expiresInMs?: number) {
    if (value === this.current) {
      return
    }

    if (value === this.def) {
      return this.remove()
    }

    const expires = expiresInMs
      ? new Date(new Date().getTime() + expiresInMs).toUTCString()
      : 'Fri, 31 Dec 9999 23:59:59 GMT'

    this.current = value
    const str = typeof value === 'string' ? value : JSON.stringify(value)
    document.cookie = `${this.name}=${str}; Path=/; Domain=${window.location.hostname}; expires=${expires};`
  }

  public get() {
    if (typeof this.current !== 'undefined') {
      return this.current
    }

    const cookie: unknown = cookies.get(this.name)
    const val: unknown =
      (typeof cookie === 'string' &&
        (typeof this.def === 'string' ? cookie : JSON.parse(cookie))) ||
      undefined
    this.current = this.schema.catch(this.def).parse(val)

    return this.current
  }
}
