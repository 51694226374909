import type { ButtonHTMLAttributes, ReactNode } from 'react'
import { forwardRef } from 'react'
import type { VariantProps } from 'tailwind-variants'
import { textButtonVariants } from './TextButton.variants'

type ButtonProps = VariantProps<typeof textButtonVariants> & {
  icon?: ReactNode
  iconEnd?: boolean
  loading?: boolean
}
export const TextButton = forwardRef<
  HTMLButtonElement,
  ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
>(function TextButton(
  { className, icon, iconEnd, children, size, variant, ...props },
  ref,
) {
  return (
    <button
      {...props}
      data-testid="uinext/textbutton"
      className={textButtonVariants({
        disabled: props.disabled,
        className,
        variant,
        size,
      })}
      ref={ref}
    >
      {!!(icon && !iconEnd) && <div className="size-6">{icon}</div>}
      {children && <span>{children}</span>}
      {!!(icon && iconEnd) && <div className="size-6">{icon}</div>}
    </button>
  )
})
