import { useBrandStore } from './useBrandTheme'
import { useEffect } from 'react'

export function useHideFab(hidden: boolean = true) {
  const branding = useBrandStore()

  useEffect(() => {
    if (!hidden) {
      return
    }

    const hideFab = branding.hideFab
    branding.hideFab = true

    return () => {
      branding.hideFab = hideFab
    }
  }, [hidden])
}

export function useHideFabOnMobile(hidden: boolean = true) {
  const branding = useBrandStore()
  const isMobile = branding.currentBreakpoint <= 2

  useHideFab(hidden && isMobile)
}
