// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PersonRow_row__8en\\+w{display:grid;gap:var(--s3) var(--s1);padding:var(--s2) 0 var(--s4);width:100%}.PersonRow_row__8en\\+w .PersonRow_header__DuwwT{grid-column:1/-1}.PersonRow_row__8en\\+w a.PersonRow_more__zHYY2{align-items:center;aspect-ratio:1/1;background-color:var(--color-light-gray);border-radius:var(--s3);display:flex;justify-content:center;opacity:1;overflow:hidden;transition:opacity .3s}.PersonRow_row__8en\\+w a.PersonRow_more__zHYY2:hover{opacity:.5}`, "",{"version":3,"sources":["webpack://./../../libraries/photos/src/people/internal/components/PersonList/PersonRow.module.scss"],"names":[],"mappings":"AACA,uBACE,YAAA,CAGA,uBAAA,CADA,6BAAA,CADA,UAEA,CACA,gDACE,gBAAA,CAEF,+CAQE,kBAAA,CADA,gBAAA,CADA,wCAAA,CADA,uBAAA,CAFA,YAAA,CAMA,sBAAA,CAPA,SAAA,CAEA,eAAA,CAHA,sBAQA,CACA,qDACE,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `PersonRow_row__8en+w`,
	"header": `PersonRow_header__DuwwT`,
	"more": `PersonRow_more__zHYY2`
};
export default ___CSS_LOADER_EXPORT___;
