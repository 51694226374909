import type { BrandStore } from '@jotta/ui/BrandStore'
import { useBrandStore } from '@jotta/ui/useBrandTheme'
import { autorun, isObservable } from 'mobx'
import { useEffect, useState } from 'react'

export type ScrollObservable = Pick<BrandStore, 'rect' | 'scroll'>
export function useScroll(scrollObservable?: ScrollObservable) {
  if (scrollObservable && !isObservable(scrollObservable)) {
    throw new Error('scrollObservable must be a Mobx obervable')
  }
  const branding = useBrandStore()
  const data = scrollObservable || branding
  const [size, setSize] = useState({
    scrollY: 0,
    width: 0,
    height: 0,
  })
  useEffect(
    () =>
      autorun(() => {
        setSize({
          height: data.rect.height,
          width: data.rect.width,
          scrollY: data.scroll.top,
        })
      }),
    [data],
  )

  return size
}
