import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import { FileContextMenu } from '../FileContextMenu/FileContextMenu'
import styles from './PathItemRow.module.scss'
import type { PathItemStore } from './PathItemStore'

const debug = Debug('jotta:files:PathItemRowContextMenu')

export interface PathItemRowContextMenuProps {
  item: PathItemStore
}

export const PathItemRowContextMenu = observer<PathItemRowContextMenuProps>(
  function PathItemRowContextMenu({ item }: PathItemRowContextMenuProps) {
    const { contextMenuActions, isVisible, onContextMenuSelect, path } =
      item.listItemData
    if (!contextMenuActions.length) {
      return null
    }

    return (
      <span className={styles.actions}>
        {isVisible && (
          <FileContextMenu
            path={path}
            context={item.rowActionContext}
            onSelect={onContextMenuSelect}
            actions={contextMenuActions}
            triggerBehavior="no-touch-down"
            numAffectedItems={item.folder.numSelected}
          >
            <PlainBrandIcon icon="SvgMore" />
          </FileContextMenu>
        )}
      </span>
    )
  },
)
