import { AppLayoutSignup } from '@jotta/ui/AppLayoutSignup'
import { useBrandName } from '@jotta/grpc-connect-client/config'

import WelcomePng from './welcome.png'
import { Card } from '@jotta/ui/Card'
import { Headline1 } from '@jotta/ui/Headline'
import { RouterLink } from '@jotta/ui/RouterLink'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import { t, Trans } from '@lingui/macro'

export function SignupAccepted() {
  const brandName = useBrandName()
  if (!brandName) {
    return <LoadingOverlay />
  }
  return (
    <AppLayoutSignup>
      <Card className="flex flex-col items-center gap-8 bg-white !p-16">
        <Headline1>{t`Welcome to ${brandName}!`}</Headline1>
        <img src={WelcomePng} className="h-80" />
        <p>
          Congratulations on your new subscription! You will shortly receive a
          payment receipt by email.
        </p>
        <RouterLink to="/download" variant="links.text">
          <Trans>Continue to the download page.</Trans>
        </RouterLink>
      </Card>
    </AppLayoutSignup>
  )
}
