import { useBrandStore } from '@jotta/ui/useBrandTheme'
import type { CSSProperties } from 'react'
import { getResponsiveValue } from '@jotta/ui/getResponsiveValue'
import { px } from '@jotta/utils/css'
import { groupByColumns } from '@jotta/utils/groupByAspectRatio'
import Debug from 'debug'
import { runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { useEffect } from 'react'
import { AlbumPhotoThumb } from './AlbumPhotoThumb'
import styles from './AlbumPhotosSmall.module.scss'

const debug = Debug('jotta:photos:Album')
const minColumns = 2

export const AlbumPhotosSmall = observer<{
  ids: readonly string[]
  ownerAvatar?: boolean
  showSimilarSearch?: boolean
}>(function AlbumPhotosSmall({
  ids,
  ownerAvatar = false,
  showSimilarSearch = false,
}) {
  const branding = useBrandStore()
  const store = useLocalObservable(() => ({
    ids,
    get columnWidth() {
      return getResponsiveValue(branding.currentBreakpointIndex, [130, 200])
    },
    setIds(ids: readonly string[]) {
      this.ids = ids
    },
    get columns() {
      return Math.max(
        minColumns,
        Math.round(branding.contentWidthRounded / this.columnWidth),
      )
    },
    get grid() {
      return groupByColumns(this.ids, this.columns)
    },
    get rowHeight() {
      return Math.round(branding.contentWidthSafe / this.columns)
    },
    get viewportRows() {
      return Math.round(branding.viewportHeight / this.rowHeight)
    },
    get overscanRows() {
      return Math.round(this.viewportRows / 2)
    },
    get overscan() {
      return this.overscanRows * this.columns
    },
    get numberOfVisibleRows() {
      return this.viewportRows + this.overscanRows * 2
    },
    get firstIndex() {
      return branding.scrollTop
        ? Math.max(
            0,
            Math.floor((branding.scrollTop / this.rowHeight) * this.columns) -
              this.overscan,
          )
        : 0
    },
    get lastIndex() {
      return Math.min(
        this.ids.length,
        this.firstIndex + this.numberOfVisibleRows * this.columns,
      )
    },
    get visibleItems() {
      return this.grid.data.slice(this.firstIndex, this.lastIndex)
    },
    get totalHeight() {
      return this.grid.rows * this.rowHeight
    },
    get toJSON() {
      const { firstIndex, lastIndex, rowHeight, columns, numberOfVisibleRows } =
        this
      return {
        firstIndex,
        lastIndex,
        rowHeight,
        columns,
        numberOfVisibleRows,
        numberOfVisibleItems: this.visibleItems.length,
      }
    },
  }))
  useEffect(() => {
    runInAction(() => {
      store.setIds(ids)
    })
  }, [store, ids])

  return (
    <div className={styles.container}>
      <div
        className={styles.list}
        data-testid="AlbumPhotos"
        style={
          {
            '--columns': store.columns,
            '--rows': store.grid.rows,
            '--row-height': px(store.rowHeight),
          } as CSSProperties
        }
      >
        {store.visibleItems.map(p => {
          return (
            <div
              key={p.key}
              className={styles.row}
              data-col={p.col}
              data-row={p.row}
              style={{
                transform: `translate(${p.col * 100}%, ${p.row * 100}%)`,
              }}
            >
              <AlbumPhotoThumb
                id={p.item}
                ownerAvatar={ownerAvatar}
                showSimilarSearch={showSimilarSearch}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
})
