import type { BoxPropsAs } from '@jotta/ui/Box'
import { Box } from '@jotta/ui/Box'
import { BrandIcon } from '@jotta/ui/BrandIcon'
import { Grid } from '@jotta/ui/Grid'
import { LinkThemeUI } from '@jotta/ui/themeui'
import type { BrandThemeIcon } from '@jotta/types/Brand'
import { observer } from 'mobx-react-lite'

import { Headline2 } from '../../Typography/Headline/Headline'
import { Paragraph } from '../../Typography/Text/Text'

export interface DownloadSectionItem {
  icon: BrandThemeIcon
  iconButton: {
    label: string
    link: string
  }
  downloadLink: string
}
export interface DownloadSectionProps extends BoxPropsAs {
  sectionItems: DownloadSectionItem[]
  headerText: string
  subHeaderText: string
}

export const DownloadSection = observer<DownloadSectionProps>(
  ({ sectionItems, headerText, subHeaderText, sx, ...props }) => {
    return (
      <Box
        sx={{
          variant: 'layout.downloadSectionContainer1',
          ...sx,
        }}
        {...props}
      >
        <Headline2
          sx={{
            variant: 'styles.headerText',
          }}
        >
          {headerText}
        </Headline2>
        <Paragraph
          sx={{
            variant: 'styles.subHeaderText',
          }}
        >
          {subHeaderText}
        </Paragraph>

        <Box
          sx={{
            variant: 'layout.downloadSectionContainer2',
          }}
        >
          <Box>
            <Grid
              sx={{
                flexDirection: ['column', 'row'],
              }}
              columns={['1fr', '1fr 1fr']}
              gap={[5, 4]}
            >
              {sectionItems.map(({ iconButton, downloadLink, icon }, index) => (
                <Box
                  key={`${icon}-${index}`}
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    mt: 0,
                    mb: iconButton ? 4 : 0,
                  }}
                >
                  <LinkThemeUI href={downloadLink} sx={{ cursor: 'pointer' }}>
                    <BrandIcon
                      icon={icon}
                      sx={{
                        height: '64px',
                        width: '140px',
                        margin: 0,
                        mb: '40px',
                        variant: 'styles.downloadSvg',
                      }}
                    />
                  </LinkThemeUI>
                  {iconButton && (
                    <LinkThemeUI
                      href={iconButton.link}
                      variant="buttons.download"
                    >
                      {iconButton.label}
                    </LinkThemeUI>
                  )}
                </Box>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
    )
  },
)
