import { useUsername } from '@jotta/grpc-connect-client/customer'
import { Button } from '@jotta/ui/Button'
import { useDebounce, useDisableDocumentScroll } from '@jotta/hooks'
import { AppLayoutPortal } from '@jotta/ui/AppLayoutPortal'
import { t } from '@lingui/macro'
import * as Dialog from '@radix-ui/react-dialog'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Portal } from 'react-portal'
import { useSearchParams } from 'react-router-dom'
import { useSearchStore } from '../../store/PhotoContext'
import { waitlistStore } from '../../store/WaitlistStore'
import { MediaCarouselContainer } from '../MediaCarousel/MediaCarouselContainer'
import { SelectionManager } from '../SelectionManager/SelectionManager'
import styles from './PhotoSearch.module.scss'
import { PhotoSearchInput } from './PhotoSearchInput'
import { PhotoSearchSuggestions } from './PhotoSearchSuggestions'
import { PhotoSearchThumbList } from './PhotoSearchThumbList'
import { useOnLearnMoreSearch } from '../../routes/useOnLearnMore'

function Close({
  onClick,
  show = false,
}: {
  onClick?: React.MouseEventHandler
  show: boolean
}) {
  return (
    <div className={clsx(styles.closeButton, { [styles.hidden]: !show })}>
      <Button
        onClick={onClick}
        disabled={!show}
        type="button"
        icon="SvgClose"
      />
    </div>
  )
}

export const PhotoSearchPlaceholder: React.FC<{
  onClick?: React.MouseEventHandler
}> = ({ onClick }) => {
  return (
    <AppLayoutPortal slot="header">
      <div data-testid="photo-search-overlay" className={styles.overlay}>
        <div
          data-testid="photo-search-input-container"
          className={styles.inputContainer}
        >
          <div className={styles.inputContainerTransition1}>
            <div className={styles.inputContainerTransition2}>
              <PhotoSearchInput
                active={false}
                value=""
                disabled
                placeholder={`${t`Try our AI-Powered Photo Search`} ✨`}
              />
              <button
                sx={{
                  position: 'absolute',
                  left: 0,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  width: '100%',
                  background: 'transparent',
                  height: '40px',
                  borderRadius: '8px',
                }}
                onClick={onClick}
              ></button>
            </div>
          </div>
        </div>
      </div>
    </AppLayoutPortal>
  )
}

export const PhotoSearchOverlay = observer(function PhotoSearchOverlay() {
  const [params, setParams] = useSearchParams()
  const searchStore = useSearchStore()
  const photoStore = searchStore.photoStore
  const [query, setQuery] = useState('')
  const [mediaId, setMediaId] = useState<string | undefined>()
  const inputRef = useRef<HTMLInputElement>(null)
  const [forceRetry, setForceRetry] = useState(false)
  const [selected, setSelected] = useState(0)
  const preventBlur = useRef(false)
  const [suggestionsDismissed, setSuggestionsDismissed] = useState(false)
  const username = useUsername()

  const setSuggestionsDismissedDelayed = useDebounce(
    useCallback((dismissed: boolean) => setSuggestionsDismissed(dismissed), []),
    100,
  )

  const searchSuggestions = searchStore.searchQuerySuggestions.current()
  const hasSearchSuggestions = Boolean(searchSuggestions.length)

  const showSuggestions =
    hasSearchSuggestions &&
    searchStore.open &&
    !query &&
    searchStore.result?.type !== 'similarity' &&
    !suggestionsDismissed

  const { waitlistStatus } = waitlistStore

  useDisableDocumentScroll({ enabled: searchStore.open })

  useMemo(() => {
    if (params.has('q') || params.has('id')) {
      searchStore.open = true
    }
    // Run only on mount. Don't care if params change later.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [open, setOpen] = [
    searchStore.open,
    useCallback(
      (open: boolean) => {
        searchStore.open = open
      },
      [searchStore],
    ),
  ]

  useEffect(() => {
    if (forceRetry) {
      setForceRetry(false)
      searchStore.clear()
      return
    }

    const q = params.get('q')
    const id = params.get('id')
    setMediaId(params.get('photo') || undefined)

    if (id) {
      searchStore.findSimilarPhotos(id)
      setQuery('')
      setOpen(true)
      inputRef.current?.focus()
    } else if (q) {
      searchStore.search(q)
      setQuery(q)
      setOpen(true)
      inputRef.current?.focus()
    } else {
      setQuery('')
      searchStore.clear()
      setOpen(false)
    }
  }, [searchStore, params, forceRetry, setOpen])

  const submit = (query: string) => {
    setSelected(0)
    searchStore.photoStore.selection.selection.clear()

    if (
      searchStore.result &&
      searchStore.result.query === query &&
      !searchStore.result.isLoading &&
      !searchStore.result.isSuccess
    ) {
      setForceRetry(true)
    } else {
      const params = new URLSearchParams()
      params.set('q', query)
      setParams(params, { replace: false })
      setOpen(true)
    }
  }

  const onSubmit = (value: string) => {
    submit(value)
  }

  const clear = useCallback(() => {
    setParams(new URLSearchParams())
  }, [setParams])

  // Need to use ref to not trigger clear() on effect cleanup.
  const clearRef = useRef(clear)

  useEffect(() => {
    clearRef.current = clear
  }, [clear])

  useEffect(() => {
    if (!open) {
      setQuery('')
      return
    }

    if (inputRef.current) {
      inputRef.current.focus()
    }

    return () => {
      clearRef.current()
    }
  }, [open])

  const onNavigate = useCallback(
    (id: string) => {
      params.set('photo', id)
      setParams(params)
    },
    [params, setParams],
  )

  const handleSuggestionClick = (suggestion: string) => {
    setQuery(suggestion)
    submit(suggestion)
  }

  const carouselItems = useMemo(() => {
    if (!searchStore.result) {
      return []
    }
    const photos = searchStore.result.photos || []

    if (searchStore.result.similarPhoto) {
      photos.unshift(searchStore.result.similarPhoto)
    }

    return photos
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchStore,
    searchStore.result?.similarPhoto,
    searchStore.result?.photos,
  ])
  const { onLearnMoreSearch } = useOnLearnMoreSearch()
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (!searchSuggestions || !searchSuggestions.length) {
      return
    }

    if (!showSuggestions) {
      if (e.key == 'Escape') {
        if (query) {
          setQuery('')
          e.preventDefault()
          e.stopPropagation()
        } else if (inputRef.current) {
          inputRef.current.blur()
        }
      }
      return
    }

    const clamp = (v: number) => {
      if (v > searchSuggestions.length + 1) {
        return 0
      }
      if (v < 0) {
        return searchSuggestions.length + 1
      }
      return v
    }

    if (e.key === 'ArrowDown') {
      setSelected(old => clamp(old + 1))
      e.preventDefault()
    } else if (e.key === 'ArrowUp') {
      setSelected(old => clamp(old - 1))
      e.preventDefault()
    } else if (e.key === 'Enter') {
      if (selected === searchSuggestions.length + 1) {
        setSelected(0)
        onLearnMoreSearch()
        e.preventDefault()
      } else {
        const q = searchSuggestions[selected - 1] || ''

        if (q) {
          submit(q)
          e.preventDefault()
        }
      }
    } else if (e.key === 'Escape' && !suggestionsDismissed) {
      setSuggestionsDismissed(true)
      e.preventDefault()
      e.stopPropagation()
    }
  }

  useEffect(() => {
    if (!query) {
      setSuggestionsDismissed(false)
    }
  }, [query])

  useEffect(() => {
    setSelected(0)
  }, [searchSuggestions])

  const placeholder = waitlistStore.isOnWaitlist
    ? t`You are #${waitlistStatus?.getWaitlistPosition()} on the waitlist`
    : open
      ? t`Describe the photo you are looking for…`
      : `${t`Try our AI-Powered Photo Search`} ✨`

  return (
    <Dialog.Root modal={false} open>
      <Dialog.Portal>
        <Dialog.Content
          asChild
          onPointerDownOutside={e => {
            e.preventDefault()
          }}
          onInteractOutside={e => {
            e.preventDefault()
          }}
          onEscapeKeyDown={e => {
            if (searchStore.open && photoStore.selection.selection.size === 0) {
              setOpen(false)
              e.preventDefault()
            }
          }}
        >
          <>
            <AppLayoutPortal slot="header">
              <div
                data-testid="photo-search-overlay"
                className={clsx(styles.overlay, {
                  [styles.open]: searchStore.open,
                })}
              >
                <div
                  data-testid="photo-search-input-container"
                  className={styles.inputContainer}
                >
                  <Close onClick={() => setOpen(!open)} show={open} />
                  <div className={styles.inputContainerTransition1}>
                    <div className={styles.inputContainerTransition2}>
                      <PhotoSearchInput
                        ref={inputRef}
                        active={searchStore.open}
                        value={query}
                        onChange={setQuery}
                        disabled={waitlistStore.isOnWaitlist}
                        onSubmit={onSubmit}
                        placeholder={placeholder}
                        isLoading={searchStore.isLoading}
                        onKeyDown={handleKeyDown}
                        onFocus={() => {
                          setSuggestionsDismissedDelayed(false)
                        }}
                        onBlur={e => {
                          if (preventBlur.current) {
                            preventBlur.current = false
                            e.preventDefault()
                            e.stopPropagation()
                            if (inputRef.current) {
                              inputRef.current.focus()
                            }
                          } else {
                            setSuggestionsDismissedDelayed(true)
                          }
                        }}
                        onActiveChange={active => {
                          searchStore.open = active
                        }}
                      />
                      {!!showSuggestions && (
                        <PhotoSearchSuggestions
                          query={query}
                          onSuggestionClick={handleSuggestionClick}
                          searchSuggestions={searchSuggestions}
                          selected={selected}
                          setSelected={setSelected}
                          onMouseDown={() => {
                            preventBlur.current = true
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </AppLayoutPortal>
            {open && (
              <Portal>
                <div
                  data-testid="photo-search-thumb-overlay"
                  className={styles.thumbOverlay}
                >
                  <PhotoSearchThumbList
                    result={searchStore.result}
                    onClick={onNavigate}
                  />
                </div>
              </Portal>
            )}
            {open && (
              <SelectionManager
                username={username}
                actionContext={'SMART_PHOTO_SEARCH'}
                getDownloadInfo={
                  searchStore.photoStore.timeline.getDownloadInfo
                }
                restoreOnUnmount
              />
            )}
            <MediaCarouselContainer
              mediaId={mediaId}
              items={carouselItems}
              actionContext="SMART_SEARCH_VIEW_PHOTO"
              showSimilarSearch
              navOnQueryParam="photo"
            />
          </>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
})
