import type { GetPathItemResponse } from '@jotta/grpc-js-client/files/getPathItem'
import {
  sortPathItemByDate,
  sortPathItemByName,
  sortPathItemBySize,
} from '@jotta/grpc-js-client/files/sortPathItem'
import { queryClient } from '@jotta/query'
import { handleUnknownError } from '@jotta/types/AppError'
import type { PathItemObject } from '@jotta/types/Files'
import { excludesFalse } from '@jotta/types/TypeUtils'
import { getArrayItemByIndex } from '@jotta/utils/array'
import { createPath, getFilenameFromPath } from '@jotta/utils/pathUtils'
import { uppercase } from '@jotta/utils/text'
import type { InfiniteData } from '@tanstack/react-query'
import Debug from 'debug'
import { deepEqual } from 'fast-equals'
import { ObservableCaseInsensitiveMap } from '@jotta/collection/ObservableCaseInsensitiveMap'
import { ObservableCaseInsensitiveSet } from '@jotta/collection/ObservableCaseInsensitiveSet'
import { makeAutoObservable, runInAction } from 'mobx'
import { get } from 'radash'
import type { FunctionComponent } from 'react'
import { FileActionStore } from '../FileActions/FileActionStore'
import type { FileStore } from '../FileStore/FileStore'
import { PathItemStore } from '../PathItem/PathItemStore'
import { useInfinitePathItem, usePathItem } from '../PathItem/hooks/usePathItem'
import { usePendingInvitesQuery } from '../PathItem/hooks/usePendingInvites'
import { FolderSelectionStore } from './FolderSelectionStore'
import { folderStores } from './folderStoreCache'
// import { makeLoggable } from 'mobx-log'

const debug = Debug('jotta:files:folderStore')

export interface FolderChildProps {
  // #region Properties (2)

  path: string
  store: FolderStore

  // #endregion Properties (2)
}
export type FolderChildComponent = FunctionComponent<FolderChildProps>

export class FolderStore {
  // #region Properties (12)

  private _actions: FileActionStore
  public get actions() {
    return this._actions
  }
  public cursor = ''
  public entries: ObservableCaseInsensitiveMap<PathItemStore>
  /** Handles action for this folder and its children */
  public hasFetched: boolean
  public ids = new ObservableCaseInsensitiveSet()
  public itemHash = ''
  public itemsUpdatedAt = Date.now()
  public log = debug.extend('folder')
  public pathItem: PathItemStore
  public root: FileStore
  /** Handles selecting direct children of this folder */
  public selection: FolderSelectionStore

  // #endregion Properties (12)

  // #region Constructors (1)

  private _hasRendered = false

  public get hasRendered() {
    return this._hasRendered
  }

  public set hasRendered(b: boolean) {
    this._hasRendered = b
  }

  constructor(apiPath: string, fileStore: FileStore, hasFetched = false) {
    this.hasFetched = hasFetched
    this.root = fileStore
    const name = getFilenameFromPath(apiPath)
    this.log = debug.extend(name)
    this.log.enabled = false
    this.pathItem = new PathItemStore(
      name,
      {
        path: apiPath,
      },
      this,
      apiPath.startsWith('/search'),
    )
    this.pathItem.refetch()
    this.entries = new ObservableCaseInsensitiveMap(
      [[apiPath, this.pathItem]],
      {
        deep: false,
      },
    )
    // this._log = debug.extend(name)
    this.log('Create')
    this._actions = new FileActionStore(this)
    this.selection = new FolderSelectionStore(this)

    makeAutoObservable<typeof this, '_actions'>(
      this,
      {
        log: false,
        route: false,
        _actions: true,
        // ids: computed.struct,
      },
      {
        autoBind: true,

        name: `FolderStore:${name}`,
        equals: (a, b) => {
          this.log('equals', a, b)
          return deepEqual(a, b)
        },
      },
    )
    // makeLoggable(this)
  }

  // #endregion Constructors (1)

  // #region Public Accessors (24)

  private _hasNextPage = false
  public get hasNextPage() {
    return this._hasNextPage
  }

  public set hasNextPage(b) {
    this._hasNextPage = b
  }

  public get allSelected() {
    return (
      this.children.length > 0 &&
      this.selectedChildren.length === this.children.length
    )
  }

  public get numSelected() {
    return this.selectedChildren.length
  }

  public get apiPath() {
    return this.pathItem.path
  }

  public get carouselItems() {
    return this.children.filter(v => v.isFile).map(v => v.data)
  }

  public get carouselPathItems() {
    return this.children.filter(v => v.isFile)
  }

  public get children() {
    const sortDir = uppercase(this.route.activeSortMethod.dir)
    switch (this.route.sortColumn) {
      case 'name':
        return [...this.items].sort((a, b) =>
          sortPathItemByName(a.data, b.data, sortDir),
        )
      case 'modified':
        return [...this.items].sort((a, b) =>
          sortPathItemByDate(a.data, b.data, sortDir),
        )
      default:
        return [...this.items].sort((a, b) =>
          sortPathItemBySize(a.data, b.data, sortDir),
        )
    }
  }

  public get childrenItems() {
    return this.children.map(v => v.data)
  }

  public get currentFile() {
    const item = this.entries.get(this.root.route.apiPath)
    if (item && item.isFile) {
      return item
    }
  }

  public get hasSelection() {
    return Boolean(this.selectedChildren.length)
  }

  public get idsArray() {
    return [...this.ids]
  }

  public get isEmpty() {
    return !this.visibleChildren.length
  }

  public get isSyncFolder() {
    return this.root.context === 'sync' && this.pathItem.isSync
  }

  public get item() {
    return this.pathItem.data
  }

  public set item(item: PathItemObject) {
    this.pathItem.data = item
  }

  public get itemCount() {
    return this.visibleChildren.length
  }

  public get items() {
    return [...this.ids.values()]
      .map(id => this.entries.get(id))
      .filter(excludesFalse)
  }

  public get oneSelected() {
    return this.selectedChildren.length === 1
  }

  public get route() {
    return this.root.route
  }

  public get selectedActions() {
    if (this.selectedChildren.length === 1) {
      return this.selectedChildren[0].contextMenuActions
    }
    if (this.selectedChildren.length) {
      console.time('Selected actions')
      const result = this.selectedChildren[0].selectionActions.filter(a =>
        this.selectedChildren.every(c => c.selectionActions.includes(a)),
      )
      console.timeEnd('Selected actions')
      return result
    }
    return []
  }

  public get selectedChildren() {
    if (this.selection.hasSelection) {
      console.time('Selected children')
      const result = this.children.filter(v => v.isSelected)
      console.timeEnd('Selected children')
      return result
    }
    return []
  }

  public get selectedChildrenType() {
    return this.selectedChildren.map(child => child.model.type)
  }

  public get showEmptyFolderComponent() {
    return this.hasFetched && this.isEmpty && !this.hasNextPage
  }

  public get someSelected() {
    return this.hasSelection && !this.allSelected
  }

  public get virtualListItems() {
    const files = this.visibleChildren.map(data => ({
      type: 'file' as const,
      data,
    }))
    if (this.isSyncFolder && this.root.pendingInvites.length) {
      return [
        ...this.root.pendingInvites.map(data => ({
          type: 'invite' as const,
          data,
        })),
        ...files,
      ]
    }
    return files
  }

  public get visibleChildren() {
    // this.log('get visible items')
    const children = this.children.filter(child => child && child.isVisible)
    // this.log(children)
    return children
  }

  // #endregion Public Accessors (24)

  // #region Private Accessors (1)

  private get name() {
    const name =
      this.pathItem.name.toLocaleLowerCase() !== this.root.route.context
        ? `store:${this.root.route.context}:${this.pathItem.name}`
        : `store:${this.root.route.context}`
    this.log = debug.extend(name)
    return name
  }

  // #endregion Private Accessors (1)

  // #region Public Methods (14)

  public addEntry(item: PathItemStore) {
    const previousItem = this.entries.get(item.path)
    if (previousItem && previousItem === item) {
      this.log('addEntry - Item exists and is equal', item.path)
      return
    }
    if (previousItem) {
      throw new Error('Item already exists')
    }
    this.log('addEntry %s', item.path)
    this.entries.set(item.path, item)
    this.ids.add(item.path)
    return
  }

  public addItem(item: PathItemObject, updatedAt: number) {
    const previousItem = this.entries.get(item.path)
    if (previousItem) {
      if (item.path !== this.item.path && !this.ids.has(item.path)) {
        runInAction(() => {
          this.ids.add(item.path)
        })
      }
      // this.log('%s: Updated', previousItem.name)
      runInAction(() => {
        previousItem.updatedAt = updatedAt
        previousItem.data = item
        previousItem.permanentlyDeletedAt = 0
      })
      return previousItem
    }
    const newItem = new PathItemStore(item.name, item, this)
    runInAction(() => {
      newItem.updatedAt = updatedAt
      this.log('%s: Created', newItem.name)
      this.entries.set(newItem.path, newItem)
      if (newItem.path !== this.item.path) {
        this.log('Add id', newItem.path)
        this.ids.add(newItem.path)
      }
    })
    return newItem
  }

  public addItems(items: PathItemObject[], updatedAt: number) {
    const label = `Add ${items.length} items`
    console.group(label)
    console.time(label)
    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      this.addItem(item, updatedAt)
    }
    console.timeEnd(label)
    console.groupEnd()
    runInAction(() => {
      this.itemsUpdatedAt = updatedAt
    })
  }

  public clear() {
    this.entries.clear()
    this.ids.clear()
  }

  public getFileByPath(pathname: string) {
    const item = this.entries.get(pathname)
    if (item && item.isFile) {
      return item.data
    }
  }

  public getItemById(id: string) {
    return this.entries.get(id)
  }

  public getItemByIndex(index: number) {
    return getArrayItemByIndex(index, this.children)
  }

  public getItemByPath(path: string) {
    this.log('getItemByPath', path)
    return this.entries.get(path)
  }

  public handleInfiniteDataResponse(data: InfiniteData<GetPathItemResponse>) {
    this.log('handleInfiniteDataResponse %s', data.pages[0].item.path, data)
    this.hasFetched = true
    const lastItem = data.pages.at(-1)
    if (lastItem) {
      if (lastItem.item.path !== this.apiPath) {
        console.warn(
          'handleInfiniteDataResponse path mismatch, expected %s got %s',
          this.apiPath,
          lastItem.item.path,
        )
        return
      }
      const children = data.pages.flatMap(page => page.children)
      const entries = Object.fromEntries(children.map(v => [v.path, v]))
      const ids = Object.keys(entries)
      const folderResponse: GetPathItemResponse = {
        ...lastItem,
        children,
        entries,
        ids,
      }
      this.handleResponse(folderResponse)
    }
  }

  public handleResponse(response: GetPathItemResponse) {
    this.log(
      'handleResponse %s',
      response.item.path,
      response.children.length,
      response,
    )
    this.hasFetched = true
    if (response.item.path !== this.apiPath) {
      console.warn(
        'handleResponse path mismatch, expected %s got %s',
        this.apiPath,
        response.item.path,
      )
      return
    }
    if (response.cursor !== this.cursor) {
      this.cursor = response.cursor || ''
    }
    this.ids.replace(response.ids)
    const updatedAt = Date.now()
    this.addItem(response.item, updatedAt)
    this.addItems(response.children, updatedAt)
  }

  public refetchChildByName(name: string) {
    const path = createPath(this.apiPath, name)
    return this.refetchChildByPath(path)
  }

  public async refetchChildByPath(path: string) {
    const response = await queryClient
      .fetchQuery(
        usePathItem.getFetchOptions({
          context: this.route.context,
          path,
        }),
      )
      .catch(err => {
        this.log(
          'refetchChildByName: Error fetching %s',
          path,
          handleUnknownError(err).message,
        )
      })
    if (response) {
      this.addItem(response.item, Date.now())
    }
  }

  public refresh() {
    this.log('Refresh', this.pathItem.path)
    const { apiPath, isSyncFolder } = this
    queryClient.invalidateQueries({
      predicate: q => {
        const primaryKey = String(get(q, 'queryKey[0]'))
        const path = String(get(q, 'queryKey[1].path'))
        if (
          (primaryKey === usePathItem.getKey().at(0) ||
            primaryKey === useInfinitePathItem.getKey().at(0)) &&
          path.startsWith(apiPath)
        ) {
          return true
        }
        if (
          isSyncFolder &&
          primaryKey === usePendingInvitesQuery.getKey().at(0)
        ) {
          return true
        }
        return false
      },
    })
  }

  public removeItem(path: string) {
    this.ids.delete(path)
    return this.entries.delete(path)
  }

  // #endregion Public Methods (14)
}

export function getFolderStore(
  uniquePath: string,
  apiPath: string,
  fileStore: FileStore,
  refetch?: boolean,
) {
  // console.group('getFolderStore %s', apiPath)
  const log = debug.extend('getFolderStore')
  log.enabled = false
  log(`Get store for path${uniquePath}`)
  const ref = folderStores.get(uniquePath)
  if (ref) {
    log('Found ref')
    const store = ref.deref()
    if (store) {
      log('Found previous store', store)
      if (refetch) {
        log('Refetch')
        store.pathItem.refetch()
      }
      // console.groupEnd()
      return store
    }
  }
  log('Create new FolderStore')
  const store = new FolderStore(apiPath, fileStore)
  folderStores.set(uniquePath, new WeakRef(store))
  if (refetch) {
    log('Fetch')
    store.pathItem.refetch()
  }
  // console.groupEnd()
  return store
}
