import { useDropZone } from '@jotta/hooks'
import { AppLayoutPortal } from '@jotta/ui/AppLayoutPortal'
import { Fab } from '@jotta/ui/Fab'
import { Onboarding } from '@jotta/ui/Onboarding'
import { t } from '@lingui/macro'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useRef } from 'react'
import { usePhotoUploadStore } from '../../store/PhotoContext'
import { PhotoUploadOverlay } from '../PhotoUploadOverlay/PhotoUploadOverlay'
import { UploadPhotoQueueStatus } from '../UploadPhotoQueueStatus/UploadPhotoQueueStatus'
export const UploadPhotos = observer<{
  onUploadsComplete?: (idsByAlbum: Record<string, string[]>) => void
}>(function UploadPhotos({ onUploadsComplete }) {
  const uploadStore = usePhotoUploadStore()

  const fileInputRef = useRef<HTMLInputElement>(null)

  const isDragging = useDropZone({
    onAcceptFiles: uploadStore.upload,
    accept: 'image/*,video/*',
  })

  const handleClick = useCallback(() => {
    fileInputRef?.current?.click()
  }, [])

  const uploadIdsByAlbumId = uploadStore.uploadIdsByAlbumId

  useEffect(() => {
    if (onUploadsComplete && uploadIdsByAlbumId) {
      onUploadsComplete(uploadIdsByAlbumId)
    }
  }, [onUploadsComplete, uploadIdsByAlbumId])

  return (
    <>
      <AppLayoutPortal slot="actionButton">
        <Onboarding asChild task="fab" align="top">
          <Fab
            onClick={handleClick}
            data-testid="PhotoFabButton"
            aria-label={t`Upload images and videos`}
          />
        </Onboarding>
      </AppLayoutPortal>

      <input
        onChange={uploadStore.onFileInputChange}
        data-testid="file-input"
        ref={fileInputRef}
        multiple
        accept="video/*,image/*,.3fr,.ari,.arw,.bay,.cr2,.cr3,.crw,.cs1,.cxi,.dcr,.dng,.eip,.erf,.fff,.gpr,.iiq,.j6i,.k25,.kc2,.kdc,.mdc,.mef,.mfw,.mos,.mrw,.nef,.nksc,.nrw,.orf,.pef,.raf,.raw,.rw2,.rwl,.rwz,.sr2,.srf,.srw,.x3f"
        hidden
        id="uploadInput"
        type="file"
        name="uploadInput"
        aria-label={t`Upload images and videos`}
      />
      <AppLayoutPortal slot="uploadStatus">
        <UploadPhotoQueueStatus
          status={uploadStore.status}
          onCancel={uploadStore.cancel}
          onClose={uploadStore.clear}
        />
      </AppLayoutPortal>

      <PhotoUploadOverlay open={isDragging} />
    </>
  )
})
