import { tv } from 'tailwind-variants'
import { createThemeVariants } from '../../Brand/ThemeProvider/ThemeProvider'

export const textInputVariants = tv({
  base: 'input',
  variants: {
    theme: createThemeVariants('', {
      teliacloud:
        'border border-gray-300 px-3 focus:border-primary disabled:bg-gray-200 disabled:opacity-40',
    }),
  },
})
