import type { ShareInfo } from '@jotta/grpc-web/no/jotta/openapi/sharing/v2/sharing.v2_pb'
import { ShareMode } from '@jotta/grpc-web/no/jotta/openapi/sharing/v2/sharing.v2_pb'

const emptyShareInfo: ShareInfo.AsObject = {
  pathItem: undefined,
  publicUri: '',
  publicLink: '',
  shareOwner: undefined,
  membersList: [],
  invitesList: [],
  canShareCollaboratively: false,
  encodedContentRef: '',
  publicShareInfo: {
    hasPassword: false,
    encodedContentRef: '',
    publicUri: '',
    shareId: '',
    shareMode: ShareMode.NOT_SET,
  },
}

export function getEmptyShareInfo(): ShareInfo.AsObject {
  return structuredClone(emptyShareInfo)
}
