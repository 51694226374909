import { redirectToLogin } from '@jotta/utils/auth'
import type { ReactElement } from 'react'
import { useCallback, useEffect } from 'react'
import { useAuthenticated, useRefreshAccessToken } from './useAuthStatus'
import { ErrorBoundary } from '@sentry/react'
import { handleUnknownError } from '@jotta/types/AppError'
import type { FallbackRender } from '@sentry/react'
import Debug from 'debug'

const debug = Debug('jotta:auth')

export function WithAuth({ children }: { children: ReactElement }) {
  const authenticated = useAuthenticated()
  const refreshMutation = useRefreshAccessToken()
  const { mutate: refresh, reset } = refreshMutation
  const hasRefreshed = refreshMutation.isError || refreshMutation.isSuccess

  useEffect(() => {
    if (authenticated === 'FALSE') {
      debug('Refresh access token')
      refresh()
    }
  }, [authenticated, refresh])

  useEffect(() => {
    if (!hasRefreshed) {
      return
    }

    if (authenticated === 'FALSE') {
      debug('Redirect to login')
      redirectToLogin()
    } else {
      reset()
    }
  }, [authenticated, reset, hasRefreshed])

  const errorHandler = useCallback<FallbackRender>(({ error }) => {
    const appError = handleUnknownError(error)

    if (appError.isUnauthenticated) {
      redirectToLogin()
      return <></>
    } else {
      throw error
    }
  }, [])

  return (
    <ErrorBoundary fallback={errorHandler}>
      {authenticated === 'TRUE' && children}
    </ErrorBoundary>
  )
}
