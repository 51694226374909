import type { ButtonHTMLAttributes, ReactNode } from 'react'
import { forwardRef } from 'react'
import type { VariantProps } from 'tailwind-variants'
import { buttonVariants } from './Button.variants'
import { Spinner } from '../Spinner/Spinner'

type ButtonProps = VariantProps<typeof buttonVariants> & {
  icon?: ReactNode
  iconEnd?: boolean
  loading?: boolean
}
export const Button = forwardRef<
  HTMLButtonElement,
  ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
>(function Button(
  {
    variant,
    debugfocus = false,
    className,
    icon,
    iconEnd,
    responsive,
    children,
    loading = false,
    size,
    ...props
  },
  ref,
) {
  const { base, text } = buttonVariants({
    variant,
    disabled: props.disabled || loading,
    className,
    size,
    debugfocus,
    responsive,
  })
  return (
    <button
      {...props}
      data-testid="uinext/button"
      className={base()}
      aria-busy={loading}
      ref={ref}
    >
      {!!(icon && !iconEnd) && <div className="size-6">{icon}</div>}
      {children && <span className={text()}>{children}</span>}
      {!!(icon && iconEnd) && <div className="size-6">{icon}</div>}
      {loading && (
        <div className="absolute inset-0 grid place-items-center">
          <Spinner className="size-6 border-2" />
        </div>
      )}
    </button>
  )
})
