import { parsePathItem } from '@jotta/grpc-js-client/files/parsePathItem'
import type {
  SearchMatchV2,
  SearchParamsV2,
} from '@jotta/grpc-js-client/searchService'
import { searchV2 } from '@jotta/grpc-js-client/searchService'
import type { PathItemObject } from '@jotta/types/Files'
import Debug from 'debug'
import { makeAutoObservable, observable, set } from 'mobx'
import type { IDisposer } from 'mobx-utils'
import { chunkProcessor } from 'mobx-utils'
import type { FileStore } from '../FileStore/FileStore'
import type { FolderStore } from '../Folder/FolderStore'
import { getFolderStore } from '../Folder/FolderStore'
const debug = Debug('jotta:files:FileSearchStore')

export class FileSearchStore {
  // #region Properties (4)
  private searchResults = observable.array(undefined, {
    deep: false,
  })
  private stopSearchMatchProcessor: IDisposer | null = null
  public cancelSearch: VoidFunction | null = null
  public folder: FolderStore
  public params: SearchParamsV2 = {
    query: '',
    paths: [],
  }

  public status: 'idle' | 'searching' | 'complete' | 'error' = 'idle'

  // #endregion Properties (4)

  // #region Constructors (1)

  constructor(private root: FileStore) {
    this.folder = getFolderStore('/search', '/search', this.root)
    makeAutoObservable(
      this,
      {
        cancelSearch: observable.ref,
      },
      {
        autoBind: true,
      },
    )
  }

  // #endregion Constructors (1)

  // #region Public Methods (6)

  public processSearchMatchChunk(items: PathItemObject[]) {
    debug('process %d chunks', items.length)
    this.folder.addItems(items, Date.now())
  }
  public addMatch(item: PathItemObject) {
    this.searchResults.push(item)
  }

  public cancel() {
    debug('Cancel')
    if (this.stopSearchMatchProcessor) {
      this.stopSearchMatchProcessor()
      this.stopSearchMatchProcessor = null
    }
    if (this.cancelSearch) {
      this.cancelSearch()
      this.cancelSearch = null
    }
  }

  public async onMatch(match: SearchMatchV2) {
    const parsed = await parsePathItem({
      pathItem: match.item,
      ignoreChildren: true,
      ignoreDownloadLinks: false,
    })
    this.addMatch(parsed)
  }

  public onSearchEnd() {
    debug('Done')
    this.cancelSearch = null
    this.status = 'complete'
  }

  public onSearchError(err: Error) {
    debug('Error', err)
    this.status = 'error'
  }

  public setQuery(params: SearchParamsV2) {
    if (params.query) {
      if (this.stopSearchMatchProcessor) {
        this.stopSearchMatchProcessor()
      }
      if (this.cancelSearch) {
        this.cancelSearch()
      }
      this.folder.clear()
      this.status = 'searching'
      set(this.params, params)
      this.stopSearchMatchProcessor = chunkProcessor<PathItemObject>(
        this.searchResults,
        this.processSearchMatchChunk,
        250,
        10,
      )
      this.cancelSearch = searchV2(
        params,
        this.onMatch,
        this.onSearchEnd,
        this.onSearchError,
      )
    }
  }

  // #endregion Public Methods (6)
}
