import { useQuery } from '@connectrpc/connect-query'
import type {
  Bounds,
  GetFacesInPhotoResponse_FaceInPhoto,
} from '@jotta/grpc-connect-openapi/people'
import { getFacesInPhoto } from '@jotta/grpc-connect-openapi/peopleQuery'
import { excludesFalse } from '@jotta/types/TypeUtils'
import clsx from 'clsx'
import Debug from 'debug'
import ms from 'ms'
import { type CSSProperties, type ReactNode } from 'react'
import { Link, useParams } from 'react-router-dom'
import { getDebugPeople, peopleDebugEnabled } from '../PeopleDebug/PeopleDebug'
import styles from './FacesInPhoto.module.scss'
const debug = Debug('jotta:photos:FacesInPhoto')

type FacesInPhotoProps = {
  aspect: number
  md5: string
  enabled?: boolean
  isThumb?: boolean
  className?: string
  children: ReactNode
}

const { showBounds, showBoundsOnThumbs, showMd5, showMetaData } =
  getDebugPeople()

export function FacesInPhoto({
  enabled = peopleDebugEnabled(),
  isThumb,
  ...props
}: FacesInPhotoProps) {
  if (!enabled) {
    return props.children
  }

  const boundsEnabled = isThumb ? showBoundsOnThumbs : showBounds

  return (
    <>
      {showMd5 && (
        <div className="absolute bottom-0 left-0 z-20 bg-black bg-opacity-70 p-1 text-xs text-white">
          {props.md5}
        </div>
      )}

      {boundsEnabled ? <FacesInPhotoDebug {...props} /> : props.children}
    </>
  )
}
function FacesInPhotoDebug({
  md5,
  children,
  aspect,
}: Omit<FacesInPhotoProps, 'enabled'>) {
  const { personId = '' } = useParams()
  const facesQuery = useQuery(
    getFacesInPhoto,
    {
      md5,
      debug: true,
    },
    {
      staleTime: ms('5m'),
      select(data) {
        return data.face.map(faceInPhotoToStyles).filter(excludesFalse) || []
      },
    },
  )
  const faces = facesQuery.data || []

  return (
    <>
      {children}
      <div
        className="absolute"
        style={{
          aspectRatio: `${aspect} / 1`,
          ...(aspect > 1
            ? {
                top: 0,
                height: '100%',
                left: '50%',
                transform: 'translatex(-50%)',
              }
            : {
                top: '50%',
                left: 0,
                width: '100%',
                transform: 'translatey(-50%)',
              }),
        }}
      >
        {faces.map(({ name, id, style, debugMeta }, i) => (
          <Link
            to={`/photo/album/people/${id}`}
            className={clsx('p-1 text-xs', {
              [styles.bound]: true,
              [styles.mainPerson]: id && id === personId,
              [styles.unknownPerson]: !id,
            })}
            key={md5 + i}
            style={style}
          >
            {!!name && (
              <span className="absolute bottom-0 right-0 inline-block bg-black p-1 text-sm text-white">
                {name}
              </span>
            )}
            {showMetaData && !!debugMeta && (
              <span className="absolute right-0 top-0 inline-block bg-black p-1 text-sm text-white">
                {debugMeta}
              </span>
            )}
          </Link>
        ))}
      </div>
    </>
  )
}
function boundToPercent(n: number): string {
  return `${Math.round(100 * n)}%`
}
function faceInPhotoToStyles(face: GetFacesInPhotoResponse_FaceInPhoto):
  | {
      id: string
      name: string
      debugMeta?: string
      bounds: Bounds
      style: CSSProperties
    }
  | undefined {
  const bounds = face.debug?.bounds

  const meta = face.debug?.metadata

  if (bounds) {
    const { top, bottom, left, right } = bounds

    return {
      id: face.debug?.person?.id || '',
      name: face.debug?.person?.name || '',
      bounds,
      debugMeta: meta && JSON.stringify(meta),
      style: {
        inset: `${boundToPercent(top)} ${boundToPercent(
          1 - right,
        )} ${boundToPercent(1 - bottom)} ${boundToPercent(left)} `,
      },
    }
  }
}
