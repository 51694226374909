import { Button } from '@jotta/ui/Button'
import { Plural, Trans } from '@lingui/macro'

export const CommentsButton: React.FC<{
  count?: number
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}> = ({ count, onClick }) => {
  return (
    <Button
      variant="buttons.commentsButton"
      onClick={onClick}
      icon="SvgBubbleOutline"
      data-testid="CommentsButton"
    >
      {!count ? (
        <Trans id="Comments.input.placeholder" />
      ) : (
        <Plural value={count} one="# comment" other="# comments" />
      )}
    </Button>
  )
}
