import { Box } from '@jotta/ui/Box'
import loadable from '@loadable/component'
import { forwardRef, useRef } from 'react'
import {
  useAddComment,
  useAddReply,
  useDeleteComment,
  useInfiniteComments,
  useUpdateComment,
} from '../../store/CommentQuery'
import { useOSKDodge } from './useOSKDodge'
const CommentSection = loadable(
  () => import(/* webpackPrefetch: true */ './CommentSection'),
)

export const PhotoComments = forwardRef<
  HTMLDivElement,
  {
    hidden?: boolean
    contextKey?: string
    albumContextKey?: string
    onClose?: () => void
  }
>(function PhotoComments(
  { hidden = false, onClose, contextKey, albumContextKey },
  ref,
) {
  const addComment = useAddComment(albumContextKey)
  const addReply = useAddReply(albumContextKey)
  const deleteComment = useDeleteComment(albumContextKey)
  const updateComment = useUpdateComment(albumContextKey)
  const commentsRef = useRef<HTMLDivElement>(null)

  useOSKDodge(commentsRef.current)

  const {
    data: commentsResponse,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isFetched,
  } = useInfiniteComments(contextKey)

  const handleAddComment = (text: string) => {
    if (contextKey) {
      addComment.mutate({ text, contextKey })
    }
  }

  const handleAddReply = (
    contextKey: string,
    commentId: string,
    text: string,
  ) => {
    addReply.mutate({ contextKey, commentId, text })
  }

  const handleDeleteComment = (contextKey: string, commentId: string) => {
    deleteComment.mutate({ contextKey, commentId })
  }

  const handleEditComment = (
    contextKey: string,
    commentId: string,
    text: string,
  ) => {
    updateComment.mutate({ contextKey, commentId, text })
  }

  if (hidden || !contextKey) {
    return null
  }

  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
        height: '100%',
      }}
      ref={commentsRef}
    >
      <CommentSection
        key={contextKey}
        ref={ref}
        pages={commentsResponse?.pages}
        onClose={onClose}
        onEdit={handleEditComment}
        onAddComment={handleAddComment}
        onReply={handleAddReply}
        onDelete={handleDeleteComment}
        darkMode
        hasNextPage={Boolean(hasNextPage)}
        isFetchingNextPage={isFetchingNextPage || !isFetched}
        onFetchNextPage={() => fetchNextPage()}
      />
    </Box>
  )
})
