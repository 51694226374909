import { zodResolver } from '@hookform/resolvers/zod'
import { navigate } from '@jotta/router'
import { DefinitionListFromObject } from '@jotta/ui/DefinitionList'
import { cn } from '@jotta/utils/css'
import { Trans } from '@lingui/macro'
import Debug from 'debug'
import {
  Checkbox,
  FieldError,
  Input,
  Label,
  Switch,
  TextField,
} from 'react-aria-components'
import type {
  FieldError as RhfFieldError,
  SubmitHandler,
} from 'react-hook-form'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { bizUserSchema, fetchBizUser, type BizUser, bizApi } from '../BizApi'
const debug = Debug('jotta:biz:BizModifyUserFormRHF')

export function BizModifyUserFormRHF() {
  const { username = '' } = useParams()
  const mutation = bizApi.user.update.useMutation()

  const { handleSubmit, control, formState, watch } = useForm<BizUser>({
    mode: 'onBlur',
    defaultValues: async () => fetchBizUser({ username }),
    resolver: zodResolver(bizUserSchema),
  })
  const watchUnlimitedSpace = watch('unlimitedSpace')
  const onSubmit: SubmitHandler<BizUser> = async value => {
    debug('submit', value)
    await mutation.mutateAsync({ user: value })
    navigate('../..', {
      relative: 'path',
    })
  }
  return (
    <form
      className={cn('form', {
        'opacity-30': formState.isLoading,
      })}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        control={control}
        name="fullName"
        rules={{ required: 'Name is required.' }}
        render={({
          field: { name, value, onChange, onBlur, ref },
          fieldState: { invalid, error },
        }) => (
          <TextField
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            isRequired
            // Let React Hook Form handle validation instead of the browser.
            validationBehavior="aria"
            isInvalid={invalid}
            className="form-control"
          >
            <Label>
              <Trans>Name</Trans>
            </Label>
            <Input ref={ref} className="input" />
            <DisplayFieldErrors error={error} />
          </TextField>
        )}
      />
      <Controller
        control={control}
        name="email"
        rules={{
          pattern: /^\S+@\S+$/i,
          required: 'Email is required.',
        }}
        render={({
          field: { name, value, onChange, onBlur, ref },
          fieldState: { invalid, error },
        }) => (
          <TextField
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            isRequired
            // Let React Hook Form handle validation instead of the browser.
            validationBehavior="aria"
            isInvalid={invalid}
            className="form-control"
          >
            <Label>
              <Trans>Email</Trans>
            </Label>
            <Input ref={ref} className="input" />
            <DisplayFieldErrors error={error} />
          </TextField>
        )}
      />

      <div className="form-control">
        <Label>
          <Trans>Storage</Trans>
        </Label>
        <Controller
          control={control}
          name="unlimitedSpace"
          render={({ field: { name, value, onChange, onBlur, ref } }) => (
            <Switch
              name={name}
              isSelected={value}
              onChange={onChange}
              onBlur={onBlur}
              className="checkbox"
            >
              <span className="toggle" />
              <Trans>Unlimited storage</Trans>
            </Switch>
          )}
        />

        <Controller
          control={control}
          name="spaceLimit"
          render={({
            field: { name, value, onChange, onBlur, ref },
            fieldState: { invalid, error, isDirty },
          }) => (
            <TextField
              name={name}
              value={String(value)}
              onChange={e => onChange(Number(e))}
              onBlur={onBlur}
              isRequired
              // Let React Hook Form handle validation instead of the browser.
              validationBehavior="aria"
              isInvalid={invalid}
              className={cn({
                'animate-in fade-in': isDirty && !watchUnlimitedSpace,
                'animate-out fade-out ': isDirty && watchUnlimitedSpace,
              })}
            >
              <Label>
                {isDirty}
                <Trans>Name</Trans>
                {String(!!watchUnlimitedSpace)}
              </Label>
              <div className="flex items-center gap-2 self-start">
                <Input ref={ref} className="input" />
                <Label>
                  <Trans>GB</Trans>
                </Label>
              </div>
              <DisplayFieldErrors error={error} />
            </TextField>
          )}
        />
      </div>
      <div className="form-control">
        <Label>
          <Trans>Administrator</Trans>
        </Label>
        <Controller
          control={control}
          name="isAdminUser"
          render={({
            field: { name, value, onChange, onBlur, ref },
            fieldState: { invalid, error },
          }) => (
            <Checkbox
              name={name}
              isSelected={value}
              onChange={onChange}
              onBlur={onBlur}
              isRequired
              // Let React Hook Form handle validation instead of the browser.
              validationBehavior="aria"
              isInvalid={invalid}
              className="checkbox"
            >
              <Trans>Make this user an administrator</Trans>
              <DisplayFieldErrors error={error} />
            </Checkbox>
          )}
        />
      </div>
      <button
        disabled={!formState.isDirty || !formState.isValid}
        type="submit"
        className="btn btn-primary col-start-2"
      >
        Modify user
      </button>
      <div className="form-control">
        <div className="label">Debug</div>
        <DefinitionListFromObject value={{ ...formState }} />
      </div>
    </form>
  )
}

function DisplayFieldErrors({ error }: { error?: RhfFieldError }) {
  return (
    <FieldError>
      <div className="text-sx-danger">{error?.message}</div>
    </FieldError>
  )
}
