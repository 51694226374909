import {
  FileRouteSortBySchema,
  FileRouteViewModeSchema,
} from '@jotta/types/schemas/FileRouteSchema'
import { ZodCookie } from '@jotta/utils/cookie'

export const viewModeCookie = new ZodCookie(
  'viewMode',
  FileRouteViewModeSchema,
  'list',
)

export const sortByCookie = new ZodCookie(
  'sortBy',
  FileRouteSortBySchema,
  'name',
)
