import { useEffect } from 'react'

export function useSelectNamePart(el: HTMLInputElement | null) {
  useEffect(() => {
    if (!el) {
      return
    }

    el.focus()
    if (el.dataset['isfile'] === 'true') {
      const i = el.value.lastIndexOf('.')
      el.focus()
      el.setSelectionRange(0, i > 0 ? i : el.value.length)
    } else {
      el.setSelectionRange(0, el.value.length)
    }
  }, [el])
}
