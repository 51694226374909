import type { RefObject } from 'react'
import { useEffect, useMemo, useRef } from 'react'
import { useResizeObserver } from './useResizeObserver'

export interface Rect {
  bottom: number
  height: number
  left: number
  right: number
  top: number
  width: number
  x: number
  y: number
}

export function useElementSizeRef<T extends Element>(
  ref: RefObject<T>,
  cb?: (rect: Rect) => void,
) {
  const rect = useRef<Rect>({
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    x: 0,
    y: 0,
  })

  useEffect(() => {
    if (ref.current) {
      rect.current = ref.current.getBoundingClientRect().toJSON()
    }
  }, [ref])

  useResizeObserver(ref.current, events => {
    if (ref.current) {
      rect.current = ref.current.getBoundingClientRect().toJSON()

      if (cb) {
        cb({ ...rect.current })
      }
    }
  })

  return rect
}

export function useDocumentSize(cb?: (rect: Rect) => void) {
  const ref = useMemo(() => ({ current: document.scrollingElement }), [])
  return useElementSizeRef(ref, cb)
}

export function useAnyElementSizeRef(
  element: Document | HTMLElement | null,
  cb?: (rect: Rect) => void,
) {
  const ref = useMemo(
    () => ({
      current:
        element instanceof Document ? document.scrollingElement : element,
    }),
    [element],
  )
  const rect = useElementSizeRef(ref, cb)
  return { rect, scrollingElement: ref.current }
}
