import type { FileRouteParams } from '@jotta/types/schemas/FileRouteSchema'
import { createPath, removeTrailingSlash } from '@jotta/utils/pathUtils'
import { isString } from 'remeda'
import { getFileRouteParams } from './getFileRouteParams'

export function routeParamsToApiPath(route: string | FileRouteParams) {
  const params = isString(route) ? getFileRouteParams(route, 'catch') : route
  const { context } = params
  const routePath = removeTrailingSlash(createPath(params['*'] || ''))
  const contextPath = createPath(context)
  if (routePath === '/') {
    return contextPath
  }
  switch (context) {
    case 'search':
    case 'trash':
    case 'shared':
      return routePath
    default:
      return createPath(context, routePath)
  }
}
