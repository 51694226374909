import { useMemo, useEffect } from 'react'
import throttle from 'lodash/throttle'
import type { ThrottleSettings } from 'lodash'

export function useThrottle<T extends (...args: any[]) => any>(
  callback: T,
  delay: number,
  { leading, trailing }: ThrottleSettings = {},
) {
  const options = useMemo(
    () => ({
      ...(typeof leading === 'boolean' && { leading }),
      ...(typeof trailing === 'boolean' && { trailing }),
    }),
    [leading, trailing],
  )
  const throttled = useMemo(
    () => throttle(callback, delay, options),
    [delay, options, callback],
  )

  useEffect(
    () => () => {
      throttled.cancel()
    },
    [throttled],
  )

  return throttled
}
