import { getFolderShareInvite } from '@jotta/grpc-connect-openapi/esm/openapi/invite/v1/invite.v1-InviteService_connectquery'
import { createConnectQuery } from '../createConnectQuery'

export const useFolderInviteQuery = createConnectQuery(getFolderShareInvite)

export function useFolderInvite(folderShareInviteId: string) {
  return useFolderInviteQuery({
    variables: { folderShareInviteId },
    enabled: Boolean(folderShareInviteId),
  })
}
