import { forwardRef, type HtmlHTMLAttributes } from 'react'
import type {
  TextFieldProps as AriaTextFieldProps,
  ValidationResult,
} from 'react-aria-components'
import {
  TextField as AriaTextField,
  FieldError,
  Input,
  Label,
  Text,
} from 'react-aria-components'
import type { VariantProps } from 'tailwind-variants'
import { textFieldVariants } from './TextField.variants'
import type { ReactNode } from 'react'
import clsx from 'clsx'

type TextFieldProps = VariantProps<typeof textFieldVariants> &
  AriaTextFieldProps & {
    label?: string
    description?: string
    errorMessage?: string | ((validation: ValidationResult) => string)
  }

export function TextFieldContainer({
  className,
  ...props
}: {
  children: ReactNode
  className?: string
}) {
  return (
    <div
      className={clsx('grid grid-cols-max-fr1 gap-4', className)}
      {...props}
    ></div>
  )
}

export const TextField = forwardRef<
  HTMLDivElement,
  TextFieldProps & HtmlHTMLAttributes<HTMLDivElement>
>(function TextField(
  {
    className,
    description,
    label,
    layout,
    errorMessage,
    variant = 'plain',
    ...props
  },
  ref,
) {
  const v = textFieldVariants({
    variant,
    layout,
    className,
  })
  return (
    <AriaTextField {...props} className={v.root()} ref={ref}>
      <Label className={v.label()}>{label}</Label>
      <div className={v.inputColumn()}>
        <Input className={v.input()} />
        {description && (
          <Text slot="description" className={v.description()}>
            {description}
          </Text>
        )}
        <FieldError className={v.error()}>{errorMessage}</FieldError>
      </div>
    </AriaTextField>
  )
})
