import { Box } from '@jotta/ui/Box'
import type LivePhotosKit from 'livephotoskit'
import throttle from 'lodash/throttle'

import { useEffect, useRef, useState } from 'react'

export interface LivePhotoProps {
  livePhoto: string
  thumb?: string
}

function useLivePhotoPlayer(
  targetElement: HTMLElement | null | undefined,
  {
    autoplay,
    proactivelyLoadsVideo,
    photoSrc,
    videoSrc,
  }: Pick<
    Partial<LivePhotosKit.PlayerProps>,
    'autoplay' | 'proactivelyLoadsVideo' | 'photoSrc' | 'videoSrc'
  >,
) {
  const [livePhotosKit, setLivePhotosKit] = useState<
    typeof LivePhotosKit | undefined
  >()
  const [player, setPlayer] = useState<LivePhotosKit.Player | undefined>()

  useEffect(() => {
    let unmount = false
    import(
      /* webpackChunkName: "Livephotoskit" */
      'livephotoskit'
    ).then(module => {
      if (!unmount) {
        setLivePhotosKit(module)
      }
    })

    return () => {
      unmount = true
    }
  }, [])

  // Create player
  useEffect(() => {
    if (!livePhotosKit || !targetElement || player) {
      return
    }

    setPlayer(livePhotosKit.augmentElementAsPlayer(targetElement))
  }, [livePhotosKit, targetElement, player])

  // Apply fix for video player stopping after a random number of replays
  useEffect(() => {
    if (!player) {
      return
    }

    const fixPlaybackStoppingOnHover = () => {
      if (!player.video) {
        return
      }

      player.video.currentTime = player.video.duration
    }

    // Fix playback on hover breaking
    player.addEventListener('ended', fixPlaybackStoppingOnHover)

    return () => {
      player.removeEventListener('ended', fixPlaybackStoppingOnHover)
    }
  }, [player])

  // Update props for player
  useEffect(() => {
    if (!player) {
      return
    }

    player.photoSrc = photoSrc
    player.videoSrc = videoSrc
    player.autoplay = Boolean(autoplay)
    player.proactivelyLoadsVideo = Boolean(proactivelyLoadsVideo)
  }, [player, photoSrc, videoSrc, autoplay, proactivelyLoadsVideo])

  // Update player size
  useEffect(() => {
    if (!player || !targetElement) {
      return
    }

    const observer = new ResizeObserver(
      throttle(
        () => {
          player.updateSize()
        },
        16,
        { leading: true },
      ),
    )

    observer.observe(targetElement)

    return () => {
      observer.disconnect()
    }
  }, [player, targetElement])

  return player
}

export const LivePhoto: React.FunctionComponent<LivePhotoProps> = ({
  livePhoto,
  thumb,
}) => {
  const photoRef = useRef<HTMLDivElement>(null)

  const player = useLivePhotoPlayer(photoRef.current, {
    photoSrc: thumb,
    videoSrc: livePhoto,
    autoplay: true,
    proactivelyLoadsVideo: true,
  })

  useEffect(() => {
    if (!player) {
      return
    }

    const videoloadHandler = () => {
      player.play()
    }

    player.addEventListener('videoload', videoloadHandler)

    return () => {
      player.removeEventListener('videoload', videoloadHandler)
    }
  }, [player])

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        zIndex: '0',
      }}
      ref={photoRef}
    />
  )
}
