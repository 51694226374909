/** External */
import { useMutation } from '@connectrpc/connect-query'
import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { addPhotosToAlbum } from '@jotta/grpc-connect-openapi/albumQuery'
import { useAlertListStore } from '@jotta/ui/AlertStore'
import { AppLayoutPortal } from '@jotta/ui/AppLayoutPortal'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import { RadixDialog } from '@jotta/ui/RadixDialog'
import { RadixModal } from '@jotta/ui/RadixModal'
import { VirtualGrid } from '@jotta/virtualgrid/VirtualGrid'
import { Plural, t } from '@lingui/macro'
import * as Dialog from '@radix-ui/react-dialog'
import clsx from 'clsx'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useState } from 'react'
import { Form } from 'react-router-dom'

/** Internal */
import { SelectAlbumDialog } from '../../../../components/Album/Dialogs/SelectAlbumDialog'
import { SharePhotoDialog } from '../../../../components/Album/Dialogs/SharePhotoDialog'
import { photoActionsApi } from '../../../../components/PhotoActions/PhotoActions.config'
import { BaseSelectionManager } from '../../../../components/SelectionManager/BaseSelectionManager'
import { type PersonPhoto } from '../../api/PeopleApi'
import styles from './PersonPhotoList.module.scss'
import { PersonPhotoListStore } from './PersonPhotoListStore'
import { PersonPhotoThumb } from './PersonPhotoThumb'

export const PersonPhotoList = observer(function PersonPhotoList({
  photos,
  isLoading,
}: {
  photos: PersonPhoto[]
  isLoading?: boolean
}) {
  const [store] = useState(() => new PersonPhotoListStore())
  // useMakeLoggable(store, 'peopleselect')
  const {
    closeSelectionManager,
    selectedIds,
    selectedIdsAsArray,
    onKeyboardShiftPress,
    showSelectionManager,
  } = store
  const count = selectedIds.size
  const { alert } = useAlertListStore()
  useEffect(() => {
    runInAction(() => {
      store.photos = photos
    })
  }, [photos, store])
  useEffect(() => {
    document.addEventListener('keydown', onKeyboardShiftPress)
    document.addEventListener('keyup', onKeyboardShiftPress)
    return () => {
      document.removeEventListener('keydown', onKeyboardShiftPress)
      document.removeEventListener('keyup', onKeyboardShiftPress)
    }
  }, [onKeyboardShiftPress])
  const renderItem = useCallback(
    (index: number) => {
      const photo = photos.at(index)
      if (photo) {
        return (
          <PersonPhotoThumb
            selectedIds={selectedIds}
            key={photo.md5}
            to={photo.md5}
            photo={photo}
            loading={index < 10 ? 'eager' : index > 100 ? 'lazy' : undefined}
          />
        )
      }
      return <div className={styles.emptyThumb} />
    },
    [photos, selectedIds],
  )
  const addToAlbumMutation = useMutation(addPhotosToAlbum, {
    onSuccess: (data, args) => {
      const count = args.photoMd5?.length || 0
      alert.showAlert({
        // autoDismissAfterMsElapsed: 0,
        message: (
          <Plural value={count} one="Added # photo" other="Added # photos" />
        ),
        severity: 'addPhotosToAlbumAlert',
        actions: [
          {
            action: `/photo/album/${data.albumId}`,
            text: t`ShowAlbumButton.label`,
            testId: 'AddedToAlbumAlert-ShowAlbum',
          },
        ],
      })
      closeSelectionManager()
    },
  })

  const addToAlbumAction = photoActionsApi.ADD_PHOTOS_TO_ALBUM
  const sharePhotosAction = photoActionsApi.SHARE_PHOTO
  const downloadPhotosAction = photoActionsApi.DOWNLOAD_PHOTOS
  return (
    <div className={clsx(styles.list, store.viewMode)}>
      <LoadingOverlay open={isLoading} position="absolute" />
      {showSelectionManager && (
        <AppLayoutPortal slot="bottomStatusBar">
          <Dialog.Root open={showSelectionManager} modal={false}>
            <Dialog.Content
              asChild
              onEscapeKeyDown={() => store.clearSelection()}
            >
              <BaseSelectionManager
                selectionCount={count}
                onClose={store.clearSelection}
              >
                <SelectAlbumDialog
                  open={store.activeAction === 'addToAlbum'}
                  onClose={store.albumOpen}
                  addToAlbumHandler={albumId => {
                    if (albumId) {
                      addToAlbumMutation.mutate({
                        album: {
                          case: 'existingAlbumId',
                          value: albumId,
                        },
                        photoMd5: selectedIdsAsArray,
                      })
                    }
                  }}
                  triggerElement={
                    <Dialog.DialogTrigger
                      sx={{
                        variant: 'buttons.albumActionButton',
                      }}
                    >
                      <PlainBrandIcon icon={addToAlbumAction.icon} />
                      {addToAlbumAction.label}
                    </Dialog.DialogTrigger>
                  }
                ></SelectAlbumDialog>
                <SharePhotoDialog
                  open={store.activeAction === 'shareGroupOfPhotos'}
                  count={store.selectedIds.size}
                  shareId={store.shareId}
                  onSharePhoto={store.toggleShare}
                  onClose={store.shareOpen}
                  isLoading={store.loadingAction === 'shareGroupOfPhotos'}
                  triggerElement={
                    <Dialog.DialogTrigger
                      sx={{
                        variant: 'buttons.albumActionButton',
                      }}
                    >
                      <PlainBrandIcon icon={sharePhotosAction.icon} />
                      {sharePhotosAction.label}
                    </Dialog.DialogTrigger>
                  }
                ></SharePhotoDialog>
                <RadixModal
                  onClose={store.downloadOpen}
                  open={store.activeAction === 'downloadSelectedPhotos'}
                  triggerElement={
                    <Dialog.DialogTrigger
                      sx={{
                        variant: 'buttons.albumActionButton',
                      }}
                    >
                      <PlainBrandIcon icon={downloadPhotosAction.icon} />
                      {downloadPhotosAction.label}
                    </Dialog.DialogTrigger>
                  }
                >
                  <RadixDialog
                    title={t`Download`}
                    onClose={store.downloadOpen}
                    buttons={[
                      {
                        action: 'close',
                        label: t`Close`,
                      },
                    ]}
                  >
                    <p>
                      <Plural
                        value={count}
                        one="Discovered # photo. Your download will start soon."
                        other="Discovered # photos. Your download will start soon."
                      />
                    </p>
                  </RadixDialog>
                </RadixModal>
              </BaseSelectionManager>
            </Dialog.Content>
          </Dialog.Root>
        </AppLayoutPortal>
      )}
      <Form onChange={store.onFormChange}>
        <VirtualGrid
          options={{
            renderItem,
            overscanRows: 10,
            size: photos.length,
          }}
        />
      </Form>
    </div>
  )
})
