import { BizBillingForm } from './components/BizBillingForm'
import { BizCompanyInformationForm } from './components/BizCompanyInformationForm'
import { BizAccountSettingsForm } from './components/BizAccountSettingsForm'
import { BizSubscriptionForm } from './components/BizSubscriptionForm'

export function BizSettingsRoute() {
  return (
    <div className="flex flex-col gap-6">
      <BizCompanyInformationForm />
      <BizAccountSettingsForm />
      <BizSubscriptionForm />
      <BizBillingForm />
    </div>
  )
}
