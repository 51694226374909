import { emptyCustomer } from '@jotta/grpc-js-client/mockCustomer'
import { emptyConfigResponse } from '@jotta/grpc-connect-client/config'
import type { GetConfigResponse } from '@jotta/grpc-connect-openapi/esm/openapi/config/v2/config.v2_pb'
import { Customer } from '@jotta/grpc-web/no/jotta/openapi/customer/customer.v2_pb'
import { setLanguageCookie } from '@jotta/i18n'
import { queryClient } from '@jotta/query'
import type { AppError } from '@jotta/types/AppError'
import type { BrandKey } from '@jotta/types/Brand'
import { isBrandKey } from '@jotta/types/Brand'
import Debug from 'debug'
import { makeAutoObservable } from 'mobx'
import { useForceRefreshSession } from '../hooks/useForceRefreshSession'

const debug = Debug('jotta:auth:store')

export interface AuthCustomerConfigResponse {
  customer: Customer.AsObject
  config: GetConfigResponse
}

function getEmptyCustomerConfigResponse(): AuthCustomerConfigResponse {
  return {
    customer: {
      ...emptyCustomer,
    },
    config: emptyConfigResponse,
  }
}
export class CustomerConfigStore {
  xid: string = ''
  error: AppError | null = null
  loading = false
  private _customerConfig = getEmptyCustomerConfigResponse()

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true })
  }

  get intercom() {
    return this.customer.intercom
  }

  get customer() {
    return this._customerConfig.customer
  }
  get familyName(): string {
    return String(this.customer.family?.name)
  }

  get brandingInfo() {
    return this.config.brandingInfo
  }

  get brandName() {
    return String(this.config.brandingInfo?.brandName)
  }
  get termsLink() {
    return String(this.config.brandingInfo?.termsLink)
  }

  get canUpdatePhoneNumber() {
    return this.customer.tfaType !== Customer.TFAType.SMS
  }

  get config() {
    return this._customerConfig.config
  }
  get username() {
    return this.customer.username
  }

  get isAnonymousUser() {
    return !this.customer.username
  }

  get brandCode(): BrandKey {
    const code = this.config.brandingInfo?.brandCode
    return isBrandKey(code) ? code : 'TEST'
  }

  handleCustomerConfigResponse(
    data?: AuthCustomerConfigResponse,
    error: AppError | null = null,
  ) {
    if (data) {
      this._customerConfig = data
    } else {
      this._customerConfig = getEmptyCustomerConfigResponse()
    }
    this.error = error

    if (this.customer && !this.isAnonymousUser) {
      debug('Authentication successful %s', this.customer.username)
      setLanguageCookie(this.customer.languageCode)
      localStorage.setItem('locale', this.customer.languageCode)
    } else {
      debug('Not authorized')
    }
  }
  forceRefreshSession = () =>
    queryClient.fetchQuery(useForceRefreshSession.getFetchOptions())
}
