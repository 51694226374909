import type { BrandThemeIcon } from '@jotta/types/Brand'
import { absoluteUrl } from '@jotta/utils/url'
import Debug from 'debug'
import { forwardRef } from 'react'
import type { NavLinkProps } from 'react-router-dom'
import { NavLink, useHref } from 'react-router-dom'
import type { BoxPropsAs } from '../Box/Box'
import { AnchorBox } from '../Box/Box'
import { BrandIcon } from '../../Icons/BrandIcon/BrandIcon'

const debug = Debug('jotta:branding:RouterLink')

export type BaseRouterLinkProps = Omit<NavLinkProps, 'children'> & {
  icon?: BrandThemeIcon
}
export type RouterLinkProps = BoxPropsAs<'a', BaseRouterLinkProps>
export const RouterLink = forwardRef<HTMLAnchorElement, RouterLinkProps>(
  function RouterLink(
    {
      variant = 'links.primary',
      to,
      icon,
      children,
      reloadDocument,
      ...props
    }: RouterLinkProps,
    ref,
  ) {
    const isExternalURL = typeof to === 'string' && /^https?:\/\//.test(to)
    const content = typeof children === 'string' ? children.trim() : children
    const href = useHref(to)
    const linkUrl = isExternalURL ? to : reloadDocument ? absoluteUrl(href) : ''

    if (linkUrl) {
      return (
        <AnchorBox as="a" ref={ref} href={linkUrl} variant={variant} {...props}>
          {icon ? (
            <>
              <BrandIcon icon={icon} />
              {!!content && <span>{content}</span>}
            </>
          ) : (
            children
          )}
        </AnchorBox>
      )
    }

    return (
      <NavLink
        ref={ref}
        sx={{ variant }}
        to={to}
        reloadDocument={reloadDocument}
        {...props}
      >
        {icon ? (
          <>
            <BrandIcon icon={icon} />
            {!!content && <span>{content}</span>}
          </>
        ) : (
          children
        )}
      </NavLink>
    )
  },
)
