import type { BrandStore } from '@jotta/ui/BrandStore'
import type { FileRouteViewMode } from '@jotta/types/schemas/FileRouteSchema'
import Debug from 'debug'
import { makeAutoObservable, observable } from 'mobx'
import type { FileListRenderItem } from './FileList'
import type { GetRowDataProps } from './fileListUtils'
import { getRowData } from './fileListUtils'
// import { makeLoggable } from 'mobx-log'

export class FileListStore {
  // #region Properties (2)

  // Minimum number of virtual items
  private readonly MIN_PAGE_SIZE = 100
  private log

  // #endregion Properties (2)

  // #region Constructors (1)

  constructor(
    public renderItem: FileListRenderItem,
    public viewMode: FileRouteViewMode,
    public itemCount: number,
    private branding: BrandStore,
  ) {
    this.log = Debug('jotta:files:FileListStore')
    makeAutoObservable<typeof this, 'log'>(
      this,
      {
        log: false,
        renderItem: observable.ref,
      },
      {
        autoBind: true,
      },
    )
    // makeLoggable(this)
  }

  // #endregion Constructors (1)

  // #region Public Accessors (13)

  public get cellAspect() {
    return this.fileConfig.folderThumbAspect
  }

  public get columnWidth() {
    return this.fileConfig.folderColumnWidth
  }

  public get columns() {
    if (this.viewMode === 'list') {
      return 1
    }
    return Math.max(
      2,
      Math.round(
        this.branding.contentWidthRounded / this.fileConfig.folderColumnWidth,
      ),
    )
  }

  public get data() {
    return getRowData(this.getRowDataProps)
  }

  public get getRowDataProps(): GetRowDataProps {
    return {
      columns: this.columns,
      rowCount: this.rowCount,
      renderItem: this.renderItem,
      rowHeight: this.rowHeight,
      rows: this.rowCount,
      view: this.viewMode,
    }
  }

  public get hasScrolledToBottom() {
    return (
      this.branding.scrollHeight > this.branding.viewportHeight &&
      this.branding.scrollPercent >= 100
    )
  }

  public get listHeight() {
    return this.rowCount * this.rowHeight
  }

  public get overscanRows() {
    return Math.ceil(this.rowsPerPage / 4)
  }

  public get rowCount() {
    if (!this.itemCount) {
      return 0
    }
    if (this.columns === 1) {
      return this.itemCount
    }

    return Math.ceil(this.itemCount / this.columns)
  }

  public get rowHeight() {
    if (this.viewMode === 'list') {
      return this.branding.theme.branding.files.folderRowHeight
    }
    return Math.ceil(
      this.fileConfig.folderColumnWidth * this.fileConfig.folderThumbAspect,
    )
  }

  public get rowsPerPage() {
    if (this.viewMode === 'list') {
      return Math.max(
        this.MIN_PAGE_SIZE,
        Math.ceil(this.branding.viewportHeight / this.rowHeight),
      )
    }
    return Math.max(
      Math.ceil(this.MIN_PAGE_SIZE / this.columns),
      Math.ceil(this.branding.viewportHeight / this.rowHeight),
    )
  }

  public get visibleRowIndexes() {
    const i1 = Math.max(
      0,
      Math.floor(this.branding.scrollTop / this.rowHeight) - this.overscanRows,
    )
    const i2 = i1 + this.rowsPerPage + this.overscanRows * 2
    return [i1, i2]
  }

  public get visibleRows() {
    return this.data.slice(...this.visibleRowIndexes)
  }

  // #endregion Public Accessors (13)

  // #region Private Accessors (1)

  private get fileConfig() {
    return this.branding.theme.branding.files
  }

  // #endregion Private Accessors (1)
}
