import { Box } from '@jotta/ui/Box'
import { BrandIcon } from '@jotta/ui/BrandIcon'
import type { Notification } from '@jotta/types/Notifications'
import { Trans, t } from '@lingui/macro'
import * as Popover from '@radix-ui/react-popover'

import { useEffect, useRef } from 'react'
import { NotificationsListItem } from './NotificationsListItem'

interface NotificationsListProps {
  notifications: Notification[]
  hasNextPage: boolean
  onFetchNextPage: () => void
  isFetchingNextPage: boolean
}

export const NotificationsList: React.FC<NotificationsListProps> = ({
  notifications = [],
  onFetchNextPage,
  hasNextPage,
  isFetchingNextPage,
}) => {
  const notificationsTargetRef = useRef<HTMLAnchorElement>(null)

  useEffect(() => {
    const element = notificationsTargetRef.current

    if (!element || !hasNextPage || isFetchingNextPage) {
      return
    }

    const observer = new IntersectionObserver(
      ([target]) => {
        if (target.isIntersecting && hasNextPage) {
          onFetchNextPage()
        }
      },
      {
        threshold: 0.01,
      },
    )
    observer.observe(element)

    return () => {
      observer.unobserve(element)
      observer.disconnect()
    }
  }, [hasNextPage, onFetchNextPage, isFetchingNextPage])

  return (
    <Box bg="background">
      <Box
        as="header"
        sx={{
          bg: 'background',
          borderBottom: '1px solid',
          borderBottomColor: 'lightGrey',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '8px 16px',
          position: 'sticky',
          top: '-1px',
          width: 'auto',
        }}
      >
        <span>
          <Trans id="Notifications.label" />
        </span>
        <Popover.Close
          aria-label={t`Close`}
          sx={{ variant: 'buttons.closeIconButton' }}
        >
          <BrandIcon icon="SvgClose" />
        </Popover.Close>
      </Box>

      {!notifications.length && (
        <Box p="3">
          <Trans id="Notifications.empty" />
        </Box>
      )}

      <Box
        sx={{
          maxHeight: 'min(var(--content-height), 700px)',
          overflowY: 'auto',
        }}
      >
        {notifications.map(notification => (
          <NotificationsListItem
            ref={
              notifications[notifications.length - 1].id === notification.id
                ? notificationsTargetRef
                : null
            }
            key={notification.id}
            notification={notification}
          />
        ))}
      </Box>
    </Box>
  )
}
