import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { apiPathToRoute } from '../FileRoute/utils/apiPathToRoute'
import { useParsedFileRouteParams } from '../FileRoute/utils/parseFileRouteParams'
import { useFileStore } from '../FileStore/FileContext'
import type { FolderStore } from '../Folder/FolderStore'
import { CreateFolderDialog } from './CreateFolderDialog/CreateFolderDialog'
import { DeleteDeviceDialog } from './DeleteDeviceDialog/DeleteDeviceDialog'
import { EmptyTrashDialog } from './EmptyTrashDialog/EmptyTrashDialog'
import { FileRevisionsDialog } from './FileRevisionsDialog/FileRevisionsDialog'
import { FileTransferDialog } from './FileTransferDialog/FileTransferDialog'
import { PermanentlyDeleteDialog } from './PermanentlyDeleteDialog/PermanentlyDeleteDialog'
import { ShareFileDialog } from './ShareFileDialog/ShareFileDialog'
import type { FileActionContext } from '@jotta/types/FileActions'

const debug = Debug('jotta:files:FolderAction')

export interface DisplayActiveFileActionDialogProps {
  store: FolderStore
  /** Set to true when component is rendered inside a carousel or other modal view */
  isNestedModal?: boolean
}
export const DisplayActiveFileActionDialog =
  observer<DisplayActiveFileActionDialogProps>(
    function DisplayActiveFileActionDialog({ store, isNestedModal }) {
      const navigate = useNavigate()
      const { actions } = store
      const { dispatch } = actions
      const a = actions.activeFileActionData
      const { currentRouteIsFile } = useFileStore()
      const { apiPath } = useParsedFileRouteParams()
      const [searchParams, setSearchParams] = useSearchParams()
      const actionSearchParam = searchParams.get('action')
      const shareActionContext: FileActionContext | undefined =
        actionSearchParam === 'share' && a.type === 'Disabled'
          ? currentRouteIsFile
            ? 'FOLDER_VIEW'
            : 'FOLDER_LIST'
          : undefined
      const context = store.route.context
      useEffect(() => {
        if (shareActionContext) {
          dispatch('Share', shareActionContext, apiPath)
          setSearchParams(
            searchParams => {
              searchParams.delete('action')
              return searchParams
            },
            {
              replace: true,
            },
          )
        }
      }, [apiPath, dispatch, setSearchParams, shareActionContext])

      useEffect(() => {
        if (a.type === 'Disabled') {
          return
        }
        const item = store.getItemByPath(a.path)
        if (a.type === 'AcceptPendingInvite') {
          actions.acceptPendingInvite(a.path)
          return
        }
        if (a.type === 'DeclinePendingInvite') {
          actions.declinePendingInvite(a.path)
          return
        }
        /**
         * Most actions are done on either the folder itself or children of the folder and
         * can be cancelled if the paths doesn't exist in the store
         * The exception is Restore which adds a previously deleted child
         */
        if (a.type === 'Restore' && !item) {
          actions.restoreItems([a.path]).then(() => {
            store.selection.select(a.path)
          })

          return
        }
        if (!item) {
          return
        }
        const isSelection = a.context === 'SELECTION'
        const items = isSelection ? store.selectedChildren : [item]
        switch (a.type) {
          case 'OpenDocument':
          case 'OpenExcelDocument':
          case 'OpenWordDocument':
          case 'OpenPowerpointDocument': {
            const action = item.data.size === 0 ? 'editnew' : 'edit'
            const routePath = `${apiPathToRoute(a.path, {
              context,
            })}?action=${action}`
            debug('Navigate to %s', routePath, a.path)
            navigate(routePath)
            setTimeout(() => {
              actions.cancelActiveAction()
            }, 0)
            break
          }
          case 'Delete': {
            if (isSelection) {
              actions.deleteSelectedItems(items)
              store.selection.selectNone()
            } else {
              actions.deleteItem(a.path)
            }
            break
          }
          case 'Download': {
            if (isSelection) {
              actions.downloadSelectedItems(items)
            } else {
              actions.downloadItem()
            }
            break
          }
          case 'Restore': {
            actions.restoreItems(items.map(item => item.path))
            break
          }
          case 'NewExcelDocument':
            actions.createDocument({ path: a.path, documentType: 'xlsx' })
            break
          case 'NewWordDocument':
            actions.createDocument({ path: a.path, documentType: 'docx' })
            break
          case 'NewPowerpointDocument':
            actions.createDocument({ path: a.path, documentType: 'pptx' })
            break

          default:
            break
        }
      }, [a, navigate, store, actions, context])

      if (a.type === 'Disabled') {
        return null
      }

      switch (a.type) {
        case 'Copy':
          return (
            <FileTransferDialog
              error={a.error}
              clearError={actions.clearActiveFileActionError}
              transferType="COPY"
              filePaths={
                a.context === 'SELECTION'
                  ? store.selectedChildren.map(f => f.path)
                  : [a.path]
              }
              open={true}
              nested={isNestedModal}
              defaultOpen={true}
              transferFn={actions.transferItems}
              variant={'dialogs.overlay'}
              onClose={actions.cancelActiveAction}
              onFolderCreated={store.refresh}
            />
          )
        case 'CreateFolder': {
          return (
            <CreateFolderDialog
              createFolder={actions.createFolder}
              error={a.error}
              clearError={actions.clearActiveFileActionError}
              open={true}
              nested={isNestedModal}
              defaultOpen={true}
              variant={'dialogs.overlay'}
              onClose={actions.cancelActiveAction}
            />
          )
        }
        case 'DeletePermanently': {
          return (
            <PermanentlyDeleteDialog
              paths={
                a.context === 'SELECTION'
                  ? store.selectedChildren.map(f => f.path)
                  : [a.path]
              }
              onConfirm={actions.confirmDeleteItemsPermanently}
              open={true}
              nested={isNestedModal}
              defaultOpen={true}
              variant={'dialogs.overlay'}
              onClose={actions.cancelActiveAction}
            />
          )
        }
        case 'DeleteDevice': {
          const item =
            store.selectedChildren.length === 1
              ? store.selectedChildren[0]
              : store.children.find(item => item.path === a.path)

          if (!item || !item.isDevice) {
            return
          }

          const deviceId = item.path.split('/').slice(-1)[0]

          return (
            <DeleteDeviceDialog
              deleteDevice={actions.deleteDevice}
              error={a.error}
              deviceId={deviceId}
              deviceName={item.name}
              clearError={actions.clearActiveFileActionError}
              open={true}
              nested={isNestedModal}
              onClose={actions.cancelActiveAction}
              variant={'dialogs.overlay'}
            />
          )
        }
        case 'EmptyTrash':
          return (
            <EmptyTrashDialog
              onConfirm={actions.emptyTrash}
              open={true}
              nested={isNestedModal}
              defaultOpen={true}
              variant={'dialogs.overlay'}
              onClose={actions.cancelActiveAction}
            />
          )
        case 'Move':
          return (
            <FileTransferDialog
              error={a.error}
              clearError={actions.clearActiveFileActionError}
              transferType="MOVE"
              filePaths={
                a.context === 'SELECTION'
                  ? store.selectedChildren.map(f => f.path)
                  : [a.path]
              }
              open={true}
              nested={isNestedModal}
              defaultOpen={true}
              transferFn={actions.transferItems}
              variant={'dialogs.overlay'}
              onClose={actions.cancelActiveAction}
            />
          )
        case 'Revisions':
          return (
            <FileRevisionsDialog
              path={a.path}
              open={true}
              nested={isNestedModal}
              defaultOpen={true}
              variant={'dialogs.overlay'}
              onClose={actions.cancelActiveAction}
            />
          )
        case 'Share': {
          const item = store.getItemByPath(a.path)
          if (!item) {
            return
          }
          return (
            <ShareFileDialog
              item={item}
              open={true}
              nested={isNestedModal}
              defaultOpen={true}
              variant={'dialogs.overlay'}
              onClose={actions.cancelActiveAction}
              onLeaveShare={store.actions.leaveShare}
              context={context}
            />
          )
        }
        default:
          return null
        // debug('Default', a)
        // return (
        //   <RadixModal
        //     onClose={actions.cancelActiveAction}
        //     open
        //     defaultOpen={true}
        //     variant={'dialogs.overlay'}
        //   >
        //     <RadixDialog
        //       onClose={actions.cancelActiveAction}
        //       title={a.type}
        //       smallTitle="Unhandled action"
        //       buttons={[
        //         {
        //           action: 'close',
        //           label: 'Avbryt',
        //         },
        //       ]}
        //     >
        //       <DebugInfo info={a} />
        //     </RadixDialog>
        //   </RadixModal>
        // )
      }
    },
  )
