import { BaseErrorPage } from './BaseErrorPage'
import { Headline1 } from '../../Typography/Headline/Headline'
import { useVersion } from '@jotta/utils/frontendbackend'
import type { FallbackRender } from '@sentry/react/types/errorboundary'
import { useRouteError } from 'react-router-dom'
import { handleUnknownError } from '@jotta/types/AppError'

export const ErrorPageBasic = ({
  error,
}: {
  error: Error
  resetError?: () => void
}) => {
  const version = useVersion()

  return (
    <BaseErrorPage>
      <Headline1>{error.name}</Headline1>
      <p>{`${version ? [{ version }] : ''} ${error.message}`}</p>
      <a href={window.location.href}>refresh</a>
    </BaseErrorPage>
  )
}

export const ErrorPageBasicFallback: FallbackRender = ({ error }) => (
  <ErrorPageBasic error={error} />
)

export function ErrorPageBasicElement() {
  const error = useRouteError()
  return <ErrorPageBasic error={handleUnknownError(error)} />
}
