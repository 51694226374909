import type { HTMLAttributes, ReactNode } from 'react'
import type { Form as FormHook } from '@jotta/hooks'
import { ErrorMessage } from '../../Elements/ErrorMessage/ErrorMessage'
import { Trans } from '@lingui/macro'

type FormProps = {
  form: FormHook<any>
  header?: ReactNode
} & Exclude<HTMLAttributes<HTMLFormElement>, 'onSubmit'>

export function Form({ form, header, children, ...props }: FormProps) {
  const { error, status } = form

  const fieldsWithSummaryErrorByKey = Object.entries(form.fields).filter(
    ([_, field]) => {
      const error = field.error
      return error && error.displayInSummary
    },
  )

  return (
    <form onSubmit={form.onSubmit} {...props}>
      {header}
      {error && <ErrorMessage error={error} className="!mb-4" />}

      {status === 'invalid' && (
        <ErrorMessage>
          <Trans>Oops! Some details are missing from the form below</Trans>
          {fieldsWithSummaryErrorByKey.length > 1 && (
            <ul className="body-sm-ln pt-1 text-grey2">
              {fieldsWithSummaryErrorByKey.map(([key, field]) => {
                return (
                  <li key={key} className="list-disc">
                    {field.error?.view}
                  </li>
                )
              })}
            </ul>
          )}
        </ErrorMessage>
      )}

      {children}
    </form>
  )
}
