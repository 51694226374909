import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import { DisplayFilePath } from '../DisplayFilePath/DisplayFilePath'
import { FileApiPathLink } from '../FileRouteLink/FileApiPathLink'
import styles from './PathItemRow.module.scss'
import type { PathItemStore } from './PathItemStore'
import { PathItemThumb } from './PathItemThumb'

const debug = Debug('jotta:files:PathItemRowData')

export interface PathItemRowDataProps {
  item: PathItemStore
}

export const PathItemRowData = observer<PathItemRowDataProps>(
  function PathItemRowData({ item }: PathItemRowDataProps) {
    const { dateLabel, displayPath, fileSizeLabel, fileTypeLabel, name, path } =
      item.listItemData

    if (!item.isThumb && item.showRenameInput) {
      return null
    }

    return (
      <FileApiPathLink path={path} className={styles.rowData}>
        <PathItemThumb item={item} />
        <span className={styles.link}>
          <span className={styles.linkText}>{name}</span>
          {displayPath && (
            <span className={styles.linkPath}>
              <DisplayFilePath path={path} />
            </span>
          )}
        </span>

        <span className={styles.date}>{dateLabel}</span>
        <span className={styles.size}>{fileSizeLabel}</span>
        <span className={styles.type}>{fileTypeLabel}</span>
      </FileApiPathLink>
    )
  },
)
