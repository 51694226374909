import { Trans } from '@lingui/macro'
import { observer } from 'mobx-react-lite'
import type { DetailedHTMLProps, HTMLAttributes } from 'react'
import { useBrandingInfo } from '@jotta/grpc-connect-client/config'

export const PublicFooterText = observer(function PublicFooterText(
  props: DetailedHTMLProps<
    HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  >,
) {
  const { brandCode = '', brandName } = useBrandingInfo()
  const showJottaFooter = brandCode === 'JOTTACLOUD'
  const showElkjopFooterText = [
    'ELKJOP',
    'ELGIGANTEN',
    'ELKO',
    'GIGANTTI',
  ].includes(brandCode)

  if (showElkjopFooterText) {
    return (
      <p {...props}>
        <Trans id="PublicFooter.elkjop">
          {brandName} is a cloud storage service for individuals and companies
          that lets you backup, synchronize, store and share files from all your
          devices. The uploaded data is protected by one of the worlds strongest
          privacy laws, with all your data stored in Norway.
        </Trans>
      </p>
    )
  }
  if (showJottaFooter) {
    return (
      <p {...props}>
        <Trans id="PublicFooter.jotta">
          {brandName} Cloud is a cloud storage service that lets you backup,
          synchronize, store and share files from all your devices. The uploaded
          data is protected by one of the worlds strongest privacy laws, with
          all your data stored the Nordics.
        </Trans>
      </p>
    )
  }
  return null
})
