import type { BoxPropsAs } from '@jotta/ui/Box'
import { Box } from '@jotta/ui/Box'
import { forwardRef } from 'react'

export interface FormattedContentProps {
  center?: boolean
}

export const FormattedContent = forwardRef<
  HTMLDivElement,
  BoxPropsAs<'div', FormattedContentProps>
>(({ center, children, variant = 'formats.primary', ...props }, ref) => (
  <Box variant={variant} {...props} ref={ref}>
    {typeof children === 'string' ? <p>{children}</p> : children}
  </Box>
))
FormattedContent.displayName = 'FormattedContent'
