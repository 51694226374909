import type { WaitlistStatus } from '@jotta/grpc-web/no/jotta/openapi/search/search.v2_pb'
import { Headline2 } from '@jotta/ui/Headline'
import { Paragraph } from '@jotta/ui/Text'
import { observer } from 'mobx-react-lite'

export interface OnWaitlistSectionProps {
  waitlistStatus: WaitlistStatus | null
}

export const OnWaitlistSection: React.FC<OnWaitlistSectionProps> = observer(
  ({ waitlistStatus }) => {
    return (
      <>
        <Headline2
          sx={{
            variant: 'styles.headerText',
          }}
        >
          Thanks for signing up to be the first to experience the future of
          smart search!
        </Headline2>
        <Paragraph
          sx={{
            variant: 'styles.subHeaderText',
            borderRadius: '4px',
            padding: '24px',
            color: '#fff',
            backgroundColor: 'primary',
            backgroundImage: 'linear-gradient(-180deg,#9c61e8,#fd7efc)',
            opacity: 1,
            margin: '0 auto 32px',
            width: ['100%', '560px'],
            mt: '50px',
          }}
        >
          You are number{' '}
          <strong>#{waitlistStatus?.getWaitlistPosition()}</strong> on the
          waitlist
        </Paragraph>
      </>
    )
  },
)
