import type { Photos } from '@jotta/types/Photos'
import type { PhotosApi } from '@jotta/types/PhotosApi'
import { makeAutoObservable } from 'mobx'
import type { PhotoStore } from './PhotoStore'
import { TimelineFetcher } from './TimelineFetcher'

export class TimelineStore {
  // public mediaObjectStore
  private _timeline
  private _hiddenTimeline

  private get _current() {
    return this.isHidden ? this._hiddenTimeline : this._timeline
  }

  public get isLoading() {
    return this._current.isLoading
  }

  public get isFetching() {
    return this._current.isFetching
  }

  public get photos(): readonly Photos.Media[] {
    return this._current.photos
  }

  public get photoIds(): readonly string[] {
    return this._current.photoIds
  }

  public async hide(ids: readonly string[]) {
    this._timeline.remove(ids)
    this._hiddenTimeline.insert(ids)
  }

  public async unhide(ids: readonly string[]) {
    this._timeline.insert(ids)
    this._hiddenTimeline.remove(ids)
  }

  public async delete(ids: readonly string[]) {
    this._timeline.remove(ids)
    this._hiddenTimeline.remove(ids)
  }

  public invalidatePhotos(ids: readonly string[]) {
    this._timeline.remove(ids)
    this._hiddenTimeline.remove(ids)
  }

  public addFromUpload(photos: readonly Photos.Media[]) {
    this._timeline.insert(photos)
  }

  public constructor(public photoStore: PhotoStore) {
    this._timeline = new TimelineFetcher(this.photoStore.mediaObjects)
    this._hiddenTimeline = new TimelineFetcher(this.photoStore.mediaObjects, {
      hidden: true,
    })
    makeAutoObservable(this, undefined, {
      autoBind: true,
    })
  }

  public get isHidden() {
    return this.photoStore.galleryType === 'TIMELINE_HIDDEN'
  }

  public async getDownloadInfo(ids?: readonly string[]) {
    const initialState: Required<PhotosApi.DownloadPhotosInfo> = {
      urls: [],
      totalFileSize: 0,
      state: 'COMPLETED',
      fileName: '',
    }

    if (!ids) {
      return initialState
    }

    const photos = this.photoStore.mediaObjects.getPhotosByIdsUntracked(ids)

    return photos.reduce<Required<PhotosApi.DownloadPhotosInfo>>(
      (downloadInfo, photo, i) => {
        downloadInfo.totalFileSize += photo.filesize
        downloadInfo.urls.push(photo.file_url)
        return downloadInfo
      },
      initialState,
    )
  }
}
