import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import type { ShareUser } from '@jotta/grpc-js-client/sharing/parseShareInfo'
import { Trans, t } from '@lingui/macro'
import { observer } from 'mobx-react-lite'
import { getShareUserStatusLabel } from './ShareFileDialog'
import styles from './ShareFileDialog.module.scss'
import type { ShareFileDialogStore } from './ShareFileDialogStore'

export const ShareMembersListItem = observer<{
  user: ShareUser
  store: ShareFileDialogStore
}>(function ShareMembersListItem({ user, store }) {
  const { isShareOwner } = store
  const inviteKey = store.getInviteKeyByEmail(user.email)
  return (
    <div
      key={user.username}
      data-testid="ShareMember"
      data-invitekey={inviteKey}
      data-role={user.role}
      aria-label={user.email}
      className={styles.memberlistItem}
    >
      <div className={styles.memberName}>
        <span>{user.name || user.email}</span>
      </div>
      <div className={styles.memberStatus}>
        <div className={styles.memberStatusLabel}>
          {getShareUserStatusLabel(user.role)}
        </div>
        <div className={styles.memberStatusAction}>
          {isShareOwner && user.role === 'invite_declined' && (
            <button
              data-action="retry"
              data-email={user.email}
              title={t`Invite again`}
              onClick={() => store.retryDeclinedInvite(user.email)}
            >
              <PlainBrandIcon icon="SvgRestore" />
            </button>
          )}
          {isShareOwner && user.role !== 'owner' && (
            <button
              data-action="remove"
              data-email={user.email}
              title={t`Remove member`}
              onClick={() => store.removeMember(user.email)}
            >
              <PlainBrandIcon icon="SvgClose" />
            </button>
          )}
        </div>
      </div>
    </div>
  )
})
export const ShareMembersList = observer<{ store: ShareFileDialogStore }>(
  function ShareMembersList({ store }) {
    return (
      <div className={styles.memberlist}>
        {store.shareInfo.canShareCollaboratively && (
          <h3>
            <Trans>Invite people that can edit</Trans>
          </h3>
        )}
        {store.memberList.map(user => (
          <ShareMembersListItem key={user.email} user={user} store={store} />
        ))}
      </div>
    )
  },
)
