import moize from 'moize'
import {
  createPath,
  uriEncodePath,
  makeAbsolute,
  removePrefixFromPath,
} from '../pathUtils'

export interface BreadCrumbItem {
  /** Name of item */
  name: string
  /** Full path to item */
  path: string
  /** Full URI encoded path to item */
  encodedPath: string
  /** Depth, 0 is root */
  depth: number
  /** Basically depth === 0 */
  isRoot: boolean
  /** Item is the parent of the current item */
  isParent: boolean
  /** Item is the current path */
  isCurrent: boolean
}
export interface BreadCrumb {
  /** All path items starting from top */
  items: BreadCrumbItem[]
  /** All path items starting from current path item */
  /** Current path item */
  current: BreadCrumbItem
  /** Parent path item, if it exists */
  parent?: BreadCrumbItem
  /** Root item */
  root: BreadCrumbItem
}
export function makeBreadCrumbItem(
  name: string,
  index: number,
  items: string[],
  rootPath = '',
): BreadCrumbItem {
  const itemParts = items.slice(0, index + 1)
  const itemPath = createPath(rootPath, ...itemParts)
  const depth = itemParts.length - 1
  const lastIndex = items.length - 1
  const parentIndex = lastIndex - 1
  const isRoot = index === 0
  const isCurrent = index === lastIndex
  const isParent = index === parentIndex
  return {
    name,
    path: itemPath,
    encodedPath: uriEncodePath(itemPath),
    depth,
    isRoot,
    isParent,
    isCurrent,
  }
}
export const getBreadCrumb = moize(
  function getBreadCrumb(
    pathStr: string,
    context = '',
    rootName = '',
    rootPath = '',
  ): BreadCrumb {
    const currentPath = makeAbsolute(removePrefixFromPath(pathStr, context))
    const isRoot = currentPath === '/'
    const items = isRoot
      ? [makeBreadCrumbItem(rootName, 0, ['/'], rootPath)]
      : currentPath
          .split('/')
          .map((item, i, arr) => makeBreadCrumbItem(item, i, arr, rootPath))
    const itemsReversed = items.slice().reverse()
    const [root] = items
    const [current, parent] = itemsReversed
    root.name = rootName
    return {
      items,
      current,
      parent,
      root,
    }
  },
  {
    maxSize: 100,
  },
)
