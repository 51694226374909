import { photoActionContextTypes } from '@jotta/types/schemas/PhotoActionSchema'
import type { PhotoActionTypeContext } from './PhotoActionContext.config'
import { isPhotoActionTypeContext } from './PhotoActionContext.config'
import type { PhotoActionType } from './PhotoActions.types'

export function getPhotoActionContexts<A extends PhotoActionType>(
  actionType: A,
): PhotoActionTypeContext<A>[] {
  return photoActionContextTypes.filter<PhotoActionTypeContext<A>>(
    (ctx): ctx is PhotoActionTypeContext<A> =>
      isPhotoActionTypeContext(actionType, ctx),
  )
}
