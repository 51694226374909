// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NoTimelinePhotos_container__m-qPP{align-items:center;height:100%;justify-content:center;text-align:center;width:100%}.NoTimelinePhotos_container__m-qPP,.NoTimelinePhotos_wrapper__At7vC{display:flex;flex-direction:column}.NoTimelinePhotos_graySvg__Pz2cD,.NoTimelinePhotos_whiteSvg__oIzOx{background-position:50%;height:192px;width:270px}.NoTimelinePhotos_whiteSvg__oIzOx{--svg-fill-color:var(--color-background)}.NoTimelinePhotos_title__g9YB0{font-size:var(--fz4);font-weight:var(--fw-heading);margin:14px}.NoTimelinePhotos_subTitle__aY6K2,.NoTimelinePhotos_title__g9YB0{color:var(--color-purple-hue-gray)}.NoTimelinePhotos_subTitle__aY6K2{font-size:var(--fz3);font-weight:var(--fw-body);margin:0;opacity:.8}`, "",{"version":3,"sources":["webpack://./../../libraries/photos/src/components/NoTimelinePhotos/NoTimelinePhotos.module.scss"],"names":[],"mappings":"AACA,mCAGE,kBAAA,CAEA,WAAA,CAEA,sBAAA,CALA,iBAAA,CAIA,UACA,CAGF,oEATE,YAAA,CAGA,qBAQA,CAGF,mEAGE,uBAAA,CADA,YAAA,CADA,WAEA,CAGF,kCAEG,wCAAA,CAGH,+BAGE,oBAAA,CADA,6BAAA,CAEA,WAAA,CAGF,iEANE,kCAcA,CARF,kCAEE,oBAAA,CADA,0BAAA,CAOA,QAAA,CAJA,UAIA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `NoTimelinePhotos_container__m-qPP`,
	"wrapper": `NoTimelinePhotos_wrapper__At7vC`,
	"graySvg": `NoTimelinePhotos_graySvg__Pz2cD`,
	"whiteSvg": `NoTimelinePhotos_whiteSvg__oIzOx`,
	"title": `NoTimelinePhotos_title__g9YB0`,
	"subTitle": `NoTimelinePhotos_subTitle__aY6K2`
};
export default ___CSS_LOADER_EXPORT___;
