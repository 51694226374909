import {
  DescribeVoucherResponse,
  SubscriptionInterval,
} from '@jotta/grpc-web/no/jotta/openapi/subscription/payment.v2_pb'
import { t, Trans } from '@lingui/macro'
import dayjs from 'dayjs'
import type { FC } from 'react'
import { FormattedContent } from '../../Elements/FormattedContent/FormattedContent'
import { useCustomerGroupName } from '@jotta/grpc-connect-client/config'

export const VoucherDialogResponse: FC<DescribeVoucherResponse.AsObject> = ({
  duration,
  action,
  renewalExpireTimeMillis,
  discountPercentage,
  credit,
}) => {
  const customerGroupName = useCustomerGroupName()

  let months = 0
  switch (duration) {
    case SubscriptionInterval.FIXED_1MND:
      months = 1
      break
    case SubscriptionInterval.FIXED_2MND:
      months = 2
      break
    case SubscriptionInterval.FIXED_3MND:
      months = 3
      break
    case SubscriptionInterval.FIXED_6MND:
      months = 6
      break
    case SubscriptionInterval.FIXED_12MND:
      months = 12
      break
    case SubscriptionInterval.FIXED_24MND:
      months = 24
      break
    case SubscriptionInterval.FIXED_36MND:
      months = 36
      break
    case SubscriptionInterval.FIXED_60MND:
      months = 60
      break
  }

  const renewalDate = dayjs(renewalExpireTimeMillis).format('L')
  let msg: JSX.Element | null = null

  switch (action) {
    case DescribeVoucherResponse.VoucherAction.EXTEND_RENEWAL:
    case DescribeVoucherResponse.VoucherAction.EXTEND_EXPIRE:
      msg = (
        <p>
          <Trans>
            Your subscription will be extended with {months} months, it will be
            valid until {renewalDate}
          </Trans>
        </p>
      )
      break
    case DescribeVoucherResponse.VoucherAction.NEW_SUBSCRIPTION:
      msg = (
        <p>
          <Trans>
            You get a {months} months subscription, it will be valid until{' '}
            {renewalDate}
          </Trans>
        </p>
      )
      break
    case DescribeVoucherResponse.VoucherAction.NEW_RECURRING_SUBSCRIPTION:
      msg = (
        <p>
          {t`This voucher code will activate your subscription. The subscription is managed by ${customerGroupName} and is valid until cancelled.`}
        </p>
      )
      break
  }
  return (
    <FormattedContent>
      {msg}
      {credit > 0 && (
        <p>
          <Trans>
            You will get {credit} in additional credit after activating this
            code.
          </Trans>
        </p>
      )}
      {discountPercentage > 0 && (
        <p>
          <Trans>
            Your code will give you a {discountPercentage}% discount.
          </Trans>
        </p>
      )}
    </FormattedContent>
  )
}
