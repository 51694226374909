import { ImageThemeUI } from '@jotta/ui/themeui'
import type { FileThumbSize, PathItemObject } from '@jotta/types/Files'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import { observer, useLocalObservable } from 'mobx-react-lite'
import type { Dispatch, ReactNode, SetStateAction } from 'react'

import { getThumbLink } from '@jotta/file-utils'
import Debug from 'debug'
import { action } from 'mobx'

type FileViewImageStatus = 'loading' | 'success' | 'error'
export interface FileViewImageProps {
  /** Either a pathitem or just a plain url */
  item: Pick<PathItemObject, 'thumbLink'> | string
  /** Display fallback image loading fails */
  fallback?: ReactNode
  /** Thumb size, unused if item is a plain string */
  size?: FileThumbSize
  onLoadChange?: Dispatch<SetStateAction<boolean>>
  isActiveSlide?: boolean
  className?: string
}
const debug = Debug('jotta:FileThumb')
export const FileViewImage = observer<FileViewImageProps>(
  function FileViewImage({
    item,
    size = 'WS',
    fallback = null,
    onLoadChange,
    isActiveSlide = false,
    ...props
  }) {
    const state = useLocalObservable(
      () => ({
        loading: false,
        error: false,
        ref(el: HTMLImageElement | null) {
          if (el && el.loading) {
            // debug('Add listeners', el)
            this.loading = true
            el.addEventListener('load', this.onLoad)
            el.addEventListener('error', this.onError)
            return
          }
          this.loading = false
        },
        onLoad(e: Event) {
          // debug('onLoad', e)
          this.loading = false
        },
        onError(e: Event) {
          // debug('onError', e)
          this.loading = false
          this.error = true
        },
        get showFallback() {
          return Boolean(this.error || !this.thumbLink)
        },
        get thumbLink() {
          return getThumbLink(item, size)
        },
        get status(): FileViewImageStatus {
          if (this.loading) {
            return 'loading'
          }
          if (this.error) {
            return 'error'
          }
          return 'success'
        },
      }),
      {
        ref: action.bound,
      },
    )
    return (
      <>
        {!state.showFallback && isActiveSlide && (
          <LoadingOverlay open={state.loading} />
        )}
        {state.showFallback ? (
          fallback
        ) : (
          <ImageThemeUI
            variant="styles.fileItemTypeIconThumb"
            src={state.thumbLink}
            alt=""
            ref={state.ref}
            {...props}
          />
        )}
      </>
    )
  },
)
