// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FileTree_file__wKkt1{--plus-icon-size:16px;--folder-icon-size:24px;align-items:start;column-gap:var(--s1);display:grid;grid-template-columns:var(--plus-icon-size) 1fr;width:100%}.FileTree_file__wKkt1 .FileTree_plusMinusButton__RzaW3{border:1px solid var(--color-border);font-size:var(--fz1);height:var(--plus-icon-size);line-height:var(--plus-icon-size);transform:translateY(6px);width:var(--plus-icon-size)}.FileTree_file__wKkt1 .FileTree_plusMinusButton__RzaW3:disabled{opacity:0}.FileTree_file__wKkt1 .FileTree_folder__DZRsV{column-gap:var(--s2);display:flex;flex-flow:column nowrap}.FileTree_file__wKkt1 .FileTree_folder__DZRsV .FileTree_folderName__ggA3Y{align-items:start;border-radius:var(--radius2);font-size:var(--fz2);gap:var(--s2);padding:var(--s1);text-align:left}.FileTree_file__wKkt1 .FileTree_folder__DZRsV .FileTree_folderName__ggA3Y.FileTree_selected__XYUFW{background-color:var(--color-light-gray)}.FileTree_file__wKkt1 .FileTree_folder__DZRsV .FileTree_folderName__ggA3Y:disabled{opacity:.5}.FileTree_file__wKkt1 .FileTree_folder__DZRsV .FileTree_folderName__ggA3Y:not(.FileTree_selected__XYUFW):hover{background-color:var(--color-light-gray)}`, "",{"version":3,"sources":["webpack://./../../libraries/files/src/FileTree/FileTree.module.scss"],"names":[],"mappings":"AACA,sBACE,qBAAA,CACA,uBAAA,CAGA,iBAAA,CACA,oBAAA,CAHA,YAAA,CACA,+CAAA,CAGA,UAAA,CACA,uDAKE,oCAAA,CACA,oBAAA,CAHA,4BAAA,CACA,iCAAA,CAHA,yBAAA,CACA,2BAIA,CACA,gEACE,SAAA,CAGJ,8CAGE,oBAAA,CAFA,YAAA,CACA,uBACA,CACA,0EAEE,iBAAA,CAGA,4BAAA,CAJA,oBAAA,CAEA,aAAA,CAGA,iBAAA,CAFA,eAEA,CACA,mGACE,wCAAA,CAEF,mFACE,UAAA,CAEF,+GACE,wCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"file": `FileTree_file__wKkt1`,
	"plusMinusButton": `FileTree_plusMinusButton__RzaW3`,
	"folder": `FileTree_folder__DZRsV`,
	"folderName": `FileTree_folderName__ggA3Y`,
	"selected": `FileTree_selected__XYUFW`
};
export default ___CSS_LOADER_EXPORT___;
