import { BrandIcon } from '@jotta/ui/BrandIcon'
import { Button } from '@jotta/ui/Button'
import { openUploadDialog } from '@jotta/file-utils'
import { ItemAction } from '@jotta/grpc-js-client/fileService'
import type { BrandThemeIcon } from '@jotta/types/Brand'
import type { FileActionCallback } from '@jotta/types/FileActions'
import type { PathItemObject } from '@jotta/types/Files'
import type { FileRouteContext } from '@jotta/types/schemas/FileRouteSchema'
import { Headline2 } from '@jotta/ui/Headline'
import { Trans, t } from '@lingui/macro'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import type { PropsWithChildren } from 'react'
import type { FileActionStore } from '../FileActions/FileActionStore'
import styles from './EmptyFolder.module.scss'

const debug = Debug('jotta:files:EmptyFolder')

function getTitle(context: FileRouteContext): {
  title: string
  icon: BrandThemeIcon
} {
  switch (context) {
    case 'trash':
      return { title: t`Your trash can is empty`, icon: 'SvgTrash' }

    default:
      return {
        title: t`It looks like this folder is empty`,
        icon: 'SvgOverlayFolderEmpty',
      }
  }
}
export const EmptyFolder = observer<
  PropsWithChildren<{
    context?: FileRouteContext
    pathItem?: PathItemObject | null
    fileActionCallback?: FileActionCallback
    actions?: FileActionStore
  }>
>(function EmptyFolder({
  pathItem,
  context = 'sync',
  children,
  actions,
  fileActionCallback,
}) {
  const { title, icon } = getTitle(context)
  const { dispatch } = actions || {}
  const isTrash = context === 'trash'
  const showLoggedInFolderActions =
    !isTrash && pathItem && pathItem.actionList.includes(ItemAction.ADD_CHILD)

  const showPublicFolderActions = Boolean(
    !showLoggedInFolderActions &&
      pathItem &&
      pathItem.actionList.includes(ItemAction.ADD_CHILD),
  )
  return (
    <div data-testid="EmptyFolder" className={styles.grid}>
      <BrandIcon icon={icon} className={styles.icon} />
      <Headline2 className={styles.title}>{title}</Headline2>
      {children}
      {dispatch && actions && showLoggedInFolderActions && (
        <p className={styles.paragraph}>
          <Trans id="To get started drop a file into this window,<0/>choose a file to <1>Upload</1> or <2>Add a new folder</2>">
            To get started drop a file into this window,
            <br />
            choose a file to
            <Button
              variant="buttons.text"
              icon="SvgFileUpload"
              onClick={() =>
                openUploadDialog({
                  folderMode: false,
                  onSelection: actions.upload,
                  onError: error => {
                    debug('UploadFiles error')
                  },
                })
              }
              className={styles.boldButton}
            >
              Upload
            </Button>
            or
            <Button
              variant="buttons.text"
              icon="SvgFolderNew"
              onClick={() =>
                dispatch('CreateFolder', 'FOLDER_VIEW', pathItem.path)
              }
              className={styles.button}
            >
              Add a new folder
            </Button>
          </Trans>
        </p>
      )}
      {fileActionCallback && showPublicFolderActions && (
        <p className={styles.paragraph}>
          <Trans id="To get started drop a file into this window,<0/>choose a file to <1>Upload</1> or <2>Add a new folder</2>">
            To get started drop a file into this window,
            <br />
            choose a file to
            <Button
              variant="buttons.text"
              icon="SvgFileUpload"
              onClick={() => fileActionCallback('UPLOAD_FILES')}
              className={styles.boldButton}
            >
              Upload
            </Button>
            or
            <Button
              variant="buttons.text"
              icon="SvgFolderNew"
              onClick={() => fileActionCallback('UPLOAD_FOLDER')}
              className={styles.button}
            >
              Add a new folder
            </Button>
          </Trans>
        </p>
      )}
    </div>
  )
})
