import { Box } from '@jotta/ui/Box'
import { getThumbLink } from '@jotta/file-utils'
import { usePlaylistLink } from '@jotta/grpc-js-client/videoService'
import type { PathItemObject } from '@jotta/types/Files'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import { lazy } from 'react'

const debug = Debug('jotta:ui:FileViewVideo')
const VideoPlayer = lazy(
  () => import(/* webpackChunkName: "VideoPlayer" */ '@jotta/video'),
)

export interface FileViewVideoProps {
  file: Pick<
    PathItemObject,
    'name' | 'path' | 'token' | 'kind' | 'thumbLink' | 'downloadLink' | 'mime'
  >
  token?: string
  enabled?: boolean
}

export const FileViewVideo = observer<FileViewVideoProps>(
  function FileViewVideo({ file, token, enabled = false, ...props }) {
    const {
      isLoading,
      data: playlistLink = {
        playlistURL: '',
        videoURL: '',
      },
    } = usePlaylistLink(file, token)
    return (
      <Box
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          width: '100%',
          height: '100%',
          contain: 'content',
        }}
      >
        <LoadingOverlay darkMode open={isLoading} />
        <VideoPlayer
          name={file.name}
          active={enabled && !isLoading}
          playListURL={playlistLink.playlistURL}
          videoURL={playlistLink.videoURL}
          poster={getThumbLink(file, '2480x')}
          rewindOnEnd
        />
      </Box>
    )
  },
)
