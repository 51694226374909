import type { BoxPropsAs } from '@jotta/ui/Box'
import { Box } from '@jotta/ui/Box'
import { forwardRef } from 'react'

export interface ButtonGroupProps {
  alignRight?: boolean
}
export const ButtonGroup = forwardRef<
  HTMLDivElement,
  BoxPropsAs<'div', ButtonGroupProps, 'forms'>
>(function ButtonGroup({ alignRight = false, ...props }, ref) {
  return (
    <Box
      variant={alignRight ? 'forms.buttonGroupRight' : 'forms.buttonGroup'}
      ref={ref}
      {...props}
    />
  )
})
