import { delay } from '@jotta/utils/delay'
import { router } from 'react-query-kit'
import filesizeParser from 'filesize-parser'
import filesize from 'filesize'
import { queryClient } from '@jotta/query'
import { z } from 'zod'
import {
  addBizUser,
  deleteBizUser,
  getBizUserByUsername,
  getBizUsers,
  updateBizUser,
} from './BizMockServerState'

export type BizUser = {
  fullName: string
  email: string
  username: string
  unlimitedSpace: boolean
  spaceLimit: number
  spaceUsed: number
  isAdminUser: boolean
  lastUpload: number
}

export const bizUserSchema = z.object({
  fullName: z.string(),
  email: z.string().email(),
  username: z.string(),
  unlimitedSpace: z.boolean(),
  spaceLimit: z.number().min(1).optional(),
  spaceUsed: z.number(),
  isAdminUser: z.boolean(),
  lastUpload: z.number(),
})
export type BizUserForm = BizUser & {
  spaceLimitString: string
}
export function toBizUserForm(user: BizUser): BizUserForm {
  return {
    ...user,
    spaceLimitString: String(
      filesize(user.spaceLimit, {
        base: 1000,
        round: 0,
        output: 'object',
      }).value,
    ),
  }
}
export function fromBizUserForm({
  spaceLimitString,
  ...user
}: BizUserForm): BizUser {
  return {
    ...user,
    spaceLimit: filesizeParser(`${spaceLimitString}gb`, { base: 10 }),
  }
}

export function displayUser(user: BizUser) {
  return {
    ...user,
    spaceUsedPercent: user.unlimitedSpace
      ? -1
      : (user.spaceUsed / user.spaceLimit) * 100,
    spaceUsed: filesize(user.spaceUsed),
    spaceLimit: user.unlimitedSpace ? '∞' : filesize(user.spaceLimit),
    get space(): string {
      if (this.unlimitedSpace) {
        return `${this.spaceUsed} / ${this.spaceLimit}`
      }
      return `${this.spaceUsed} / ${this.spaceLimit}`
    },
  }
}
export const bizApi = router('biz', {
  user: {
    byId: router.query({
      fetcher: async ({ username }: { username: string }) => {
        await delay(300)
        return await getBizUserByUsername({ username })
      },
    }),
    list: router.query({
      fetcher: async () => {
        await delay(300)
        return await getBizUsers()
      },
    }),
    add: router.mutation({
      mutationFn: async ({ user }: { user: BizUser }) => {
        await delay(300)
        await addBizUser({ user })
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: bizApi.user.list.getKey(),
        })
      },
    }),
    update: router.mutation({
      mutationFn: async ({ user }: { user: BizUser }) => {
        await delay(300)
        await updateBizUser({ user })
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: bizApi.user.list.getKey(),
        })
      },
    }),
    delete: router.mutation({
      mutationFn: async ({ username }: { username: string }) => {
        await delay(300)
        await deleteBizUser({ username })
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: bizApi.user.list.getKey(),
        })
      },
    }),
  },
})

export function fetchBizUser(options: { username: string }) {
  return queryClient.fetchQuery(bizApi.user.byId.getFetchOptions(options))
}
