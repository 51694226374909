import type { BoxPropsAs } from '@jotta/ui/Box'
import { Box } from '@jotta/ui/Box'
import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { Button } from '@jotta/ui/Button'
import { Stack } from '@jotta/ui/Stack'
import type { SelectVariant } from '@jotta/ui/types/VariantTypes'
import { t } from '@lingui/macro'
import * as Dialog from '@radix-ui/react-dialog'
import { ToastProvider } from '@radix-ui/react-toast'
import type { ReactNode } from 'react'

import { forwardRef } from 'react'
import { FormattedContent } from '../../Elements/FormattedContent/FormattedContent'
import {
  RadixDialogToastBottomViewport,
  RadixDialogToastViewport,
} from '../RadixDialogToast/RadixDialogToastViewport'

export interface RadixDialogProps extends Dialog.DialogContentProps {
  /** Main component title */
  title?: string
  /** Small title placed above main title */
  smallTitle?: ReactNode
  /** Additional content below buttons */
  header?: ReactNode
  footer?: ReactNode
  center?: boolean
  /** Callback to run when close button or background is clicked */
  onClose?: (open: boolean) => void
  errorMessage?: string
  toastPosition?: 'top' | 'belowcontent'
  toastDuration?: number
  /** Array of buttons */
  buttons?: {
    /** Button text */
    label: string
    /** Button click handler */
    action: 'close' | React.MouseEventHandler | ((...props: any[]) => void)
    /** Button type */
    variant?: SelectVariant<'buttons'>
    visible?: boolean
    disabled?: boolean
    loading?: boolean
    testId?: string
  }[]
  customButtons?: ReactNode
}

export const RadixDialog = forwardRef<
  HTMLDivElement,
  BoxPropsAs<'div', RadixDialogProps, 'all'>
>(function RadixDialog(
  {
    title,
    smallTitle,
    header,
    errorMessage,
    buttons,
    footer,
    children,
    onClose,
    customButtons,
    toastDuration = 3000,
    toastPosition = 'top',
    ...props
  },
  ref,
) {
  const closeHandler = (open = false) => {
    if (onClose) {
      onClose(open)
    } else {
      console.warn('No close handler registered for dialog')
    }
  }
  const hasFooter = Boolean(buttons || footer)

  return (
    <ToastProvider duration={toastDuration}>
      <Dialog.Content ref={ref} {...props}>
        {toastPosition === 'top' && <RadixDialogToastViewport />}
        <div
          sx={{
            variant: 'dialogs.wrapper',
          }}
          data-testid="DialogWrapper"
        >
          <Box variant="dialogs.header">
            {onClose && (
              <Dialog.Close
                aria-label={t`Close`}
                sx={{
                  variant: 'buttons.closeIconButton',
                  position: 'absolute',
                  top: 1,
                  right: 1,
                }}
              >
                <PlainBrandIcon icon="SvgClose" />
              </Dialog.Close>
            )}
            {!!smallTitle && <h2>{smallTitle}</h2>}
            {title && <h1>{title}</h1>}
            {header}
          </Box>
          <FormattedContent px="4">
            <p
              sx={{
                color: 'danger',
              }}
            >
              {errorMessage}
            </p>
          </FormattedContent>
          {!!children && (
            <Box data-testid="DialogSection" variant="dialogs.section">
              {children}
            </Box>
          )}
          {toastPosition === 'belowcontent' && (
            <RadixDialogToastBottomViewport />
          )}

          {customButtons ? customButtons : null}
          {hasFooter && (
            <Box variant="dialogs.footer">
              {buttons ? (
                <Stack horizontal gap={2}>
                  {buttons
                    .filter(
                      button =>
                        button.visible === undefined || Boolean(button.visible),
                    )
                    .map((button, i, arr) => (
                      <Button
                        // Put the cancel button above progress overlay
                        sx={{
                          zIndex:
                            button.action === 'close' ||
                            button.action === closeHandler
                              ? 2
                              : 0,
                        }}
                        disabled={!!button.disabled}
                        loading={!!button.loading}
                        key={button.label}
                        variant={
                          button.variant
                            ? // Use variant if defined
                              button.variant
                            : i === arr.length - 1
                              ? // Primary if last
                                'buttons.primary'
                              : // Secondary for everything else
                                'buttons.secondary'
                        }
                        onClick={e => {
                          if (button.action === 'close') {
                            closeHandler(false)
                          } else {
                            button.action(e)
                          }
                        }}
                        data-testid={button.testId || button.label}
                      >
                        {button.label}
                      </Button>
                    ))}
                </Stack>
              ) : (
                footer
              )}
            </Box>
          )}
        </div>
      </Dialog.Content>
    </ToastProvider>
  )
})
