import pm from 'picomatch'
import { useCallback, useRef, useState, useMemo } from 'react'
import { useDocumentEventListener } from './useDocumentEventListener'
import Debug from 'debug'
import { wildcardMatch } from '@jotta/utils/wildcardMatch'
import { getFilesFromDataTransferItemList } from '@jotta/utils/files'
const debug = Debug('jotta:hooks:useDropZone')

interface UploadZoneProps {
  onAcceptFiles?: (files: File[]) => void
  onRejectFiles?: (files: File[]) => void
  onError?: (path: string[]) => void
  accept?: string | string[]
  allowFolderUploads?: boolean
  enabled?: boolean
  /**
   * Glob patterns to ignore
   *
   * See https://github.com/micromatch/picomatch for syntax
   */

  ignorePatterns?: string | string[]
}

export const useDropZone = ({
  onAcceptFiles,
  onRejectFiles,
  onError,
  accept,
  allowFolderUploads = false,
  ignorePatterns = '**/.DS_Store',
  enabled = true,
}: UploadZoneProps) => {
  const counter = useRef(0)
  const [isDragging, setIsDragging] = useState(false)

  const acceptFile = useMemo(() => {
    const acceptBlobs = accept
      ? Array.isArray(accept)
        ? accept
        : accept.split(',')
      : []
    const ignoreMatcher = pm(ignorePatterns)
    return (file: File) => {
      debug('filename: "%s", type: "%s"', file.name, file.type, file)
      const isIgnored = ignoreMatcher(file.name)
      const isAccepted =
        !file.type || // Some files don't have a type, so just let them through
        !acceptBlobs.length || // No rules defined, accept file
        wildcardMatch(file.type, acceptBlobs) // Accept if file type matches at least one rule
      return !isIgnored && isAccepted
    }
  }, [ignorePatterns, accept])

  const dragenter = useCallback((e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    counter.current++
    if (
      counter.current &&
      e.dataTransfer &&
      e.dataTransfer.types.includes('Files')
    ) {
      setIsDragging(true)
    }
  }, [])

  const dragover = useCallback((e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  const dragleave = useCallback((e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    counter.current--

    if (!counter.current) {
      setIsDragging(false)
    }
  }, [])

  const drop = useCallback(
    async (e: DragEvent) => {
      e.preventDefault()
      e.stopPropagation()

      counter.current = 0
      setIsDragging(false)

      if (!e.dataTransfer) {
        return
      }

      const files = await getFilesFromDataTransferItemList(
        e.dataTransfer.items,
        allowFolderUploads,
        onError,
      )

      const acceptedFiles: File[] = []
      const rejectedFiles: File[] = []

      for (const file of files) {
        if (acceptFile(file)) {
          acceptedFiles.push(file)
        } else {
          rejectedFiles.push(file)
        }
      }

      if (onAcceptFiles && acceptedFiles.length) {
        onAcceptFiles(acceptedFiles)
      }
      if (onRejectFiles && rejectedFiles.length) {
        onRejectFiles(rejectedFiles)
      }
    },
    [onAcceptFiles, onRejectFiles, acceptFile, allowFolderUploads, onError],
  )

  useDocumentEventListener('dragenter', dragenter, { enabled })
  useDocumentEventListener('dragleave', dragleave, { enabled })
  useDocumentEventListener('dragover', dragover, { enabled })
  useDocumentEventListener('drop', drop, { enabled })

  return isDragging
}
