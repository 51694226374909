// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErrorPage_container__KXl\\+k{align-items:center;display:grid;grid-template-rows:1fr min-content;height:100%;place-items:center}.ErrorPage_auth__jGJ8k{border:1px dashed red}.ErrorPage_error__HGOyY{gap:var(--s5);padding:var(--s6) var(--s4);width:min(800px,100%)}.ErrorPage_error__HGOyY>svg{height:auto;width:auto}.ErrorPage_redirect__EmHVt{align-items:center;display:flex;flex-flow:column nowrap;height:100%;padding:var(--s6) var(--s4);width:100%}`, "",{"version":3,"sources":["webpack://./../../libraries/ui/src/Elements/ErrorPage/ErrorPage.module.scss"],"names":[],"mappings":"AACA,6BAGE,kBAAA,CAFA,YAAA,CACA,kCAAA,CAGA,WAAA,CADA,kBACA,CAEF,uBACE,qBAAA,CAEF,wBAGE,aAAA,CADA,2BAAA,CADA,qBAEA,CACA,4BAEE,WAAA,CADA,UACA,CAGJ,2BAKE,kBAAA,CAFA,YAAA,CACA,uBAAA,CAFA,WAAA,CAIA,2BAAA,CALA,UAKA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ErrorPage_container__KXl+k`,
	"auth": `ErrorPage_auth__jGJ8k`,
	"error": `ErrorPage_error__HGOyY`,
	"redirect": `ErrorPage_redirect__EmHVt`
};
export default ___CSS_LOADER_EXPORT___;
