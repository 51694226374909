import type { AllVariantPaths } from '@jotta/ui/types/VariantTypes'
import * as Dialog from '@radix-ui/react-dialog'
import Debug from 'debug'
import type { FC, PropsWithChildren } from 'react'
const debug = Debug('jotta:ui:Modal')

export interface RadixModalProps {
  /** Modal open state */
  open?: boolean
  defaultOpen?: boolean
  nested?: boolean
  /** Theme-ui variant for the overlay container */
  variant?: AllVariantPaths
  triggerElement?: JSX.Element
  /** Callback to run when the modal background is clicked */
  onClose?: (open: boolean) => void
  noFade?: boolean
}

/**
 * Opens a modal based on an open state prop
 *
 * The modal handles its state through the `open` and `onClose` props
 *
 * Modal contents are not styled. Use the `Dialog` component for a typical modal
 *
 * ```
 * const [open, setOpen] = useState(false)
 * const closeModal = () => setOpen(false)
 *
 * <Modal open={open} onClose={closeModal}>
 *   <Dialog title="Modal content" onClose={closeModal}></Dialog>
 * </Modal>
 * ```
 */

export const RadixModal: FC<PropsWithChildren<RadixModalProps>> = ({
  open = false,
  defaultOpen = false,
  nested = false,
  variant = 'dialogs.overlay',
  onClose,
  children,
  triggerElement,
}) => {
  return (
    <Dialog.Root
      open={open}
      modal
      onOpenChange={onClose}
      defaultOpen={defaultOpen}
    >
      {triggerElement}
      {nested ? (
        <Dialog.Overlay data-testid="RadixModalOverlay" sx={{ variant }}>
          {children}
        </Dialog.Overlay>
      ) : (
        <Dialog.Portal>
          <Dialog.Overlay data-testid="RadixModalOverlay" sx={{ variant }}>
            {children}
          </Dialog.Overlay>
        </Dialog.Portal>
      )}
    </Dialog.Root>
  )
}
