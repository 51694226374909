import { PhotoContextProvider } from '@jotta/photos/provider'
import { ErrorPage } from '@jotta/ui/ErrorPage'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import { env } from '@jotta/utils/env'
import { ErrorBoundary } from '@sentry/react'
import { Suspense, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { ThemeSwitcher } from '../ThemeSwitcher'

import Debug from 'debug'
const debug = Debug('jotta:webapp:Root')

declare global {
  // eslint-disable-next-line no-var
  var _paq: any[] | undefined
}
export function Root() {
  const location = useLocation()
  useEffect(() => {
    const paq = globalThis._paq
    if (paq) {
      debug('Track page view to %s', location.pathname)
      paq.push(['setCustomUrl', location.pathname])
      paq.push(['trackPageView'])
    }
  }, [location])

  // const { redirectToAutoLogin } = useRootLoaderData()
  // debug('root', redirectToAutoLogin)
  return (
    <ErrorBoundary
      fallback={({ error, resetError }) => (
        <ErrorPage error={error} resetError={resetError} />
      )}
      beforeCapture={scope =>
        scope
          .setExtra('dimensions', {
            width: window.innerWidth,
            height: window.innerHeight,
          })
          .setTag('section', 'root')
      }
    >
      <Suspense fallback={<LoadingOverlay open />}>
        <PhotoContextProvider>
          <Outlet />
        </PhotoContextProvider>
        {process.env.NODE_ENV === 'development' &&
          env.activeBrands.length > 1 && <ThemeSwitcher />}
      </Suspense>
    </ErrorBoundary>
  )
}
