const alphabet =
  'sOwnPropMN49CEiq-hXvHJdSymlFURTag61GQfuD8YIWz2Zk5xKB7LV30_Abject'

function nonSecureId(size = 21) {
  let id = ''

  while (size--) {
    id += alphabet[(Math.random() * 64) | 0]
  }

  return id
}

export const nextXid = (): string => {
  return nonSecureId()
}
