// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Avatar_avatar__QhN-k{align-items:center;border-radius:50%;border-width:0;color:#fff;display:flex;flex:0 0 auto;font-weight:700;height:1em;justify-content:center;line-height:1em;opacity:1;position:relative;-webkit-user-select:none;user-select:none;width:1em}.Avatar_avatar__QhN-k span{font-size:.36em}`, "",{"version":3,"sources":["webpack://./../../libraries/ui/src/Layout/Avatar/Avatar.module.scss"],"names":[],"mappings":"AACA,sBAIE,kBAAA,CAGA,iBAAA,CADA,cAAA,CAOA,UAAA,CAXA,YAAA,CACA,aAAA,CAQA,eAAA,CAFA,UAAA,CAJA,sBAAA,CAKA,eAAA,CAIA,SAAA,CAbA,iBAAA,CAWA,wBAAA,CAAA,gBAAA,CAJA,SAMA,CAEA,2BACE,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatar": `Avatar_avatar__QhN-k`
};
export default ___CSS_LOADER_EXPORT___;
