import { Customer } from '@jotta/grpc-connect-openapi/esm/openapi/customer/customer.v2_pb'
import { WopiConsent } from '@jotta/grpc-connect-openapi/esm/openapi/wopi_pb'
import {
  AccountType,
  Customer_TFAType,
  PhotoState,
  QuotaState,
  SubscriptionType,
} from '@jotta/grpc-connect-openapi/esm/openapi/customer/customer.v2_pb'
import { Language } from '@jotta/grpc-connect-openapi/esm/openapi/language_pb'

export const emptyCustomer = new Customer({
  username: '',
  email: '',
  fullName: '',
  phoneNumber: '',
  phoneNumberVerified: false,
  countryCode: '',
  languageCode: '',
  language: Language.UNKNOWN_LANGUAGE,
  usageBytes: 0n,
  quotaBytes: 0n,
  photoState: PhotoState.UNKNOWN_PHOTO_STATE,
  accountType: AccountType.UNKNOWN_ACCOUNT_TYPE,
  subscriptionType: SubscriptionType.UNKNOWN_SUBSCRIPTION_TYPE,
  brandingInfo: {
    brandCode: '',
    brandName: '',
    customerGroupCode: '',
    customerGroupName: '',
  },
  wopiConsent: WopiConsent.UNKNOWN,
  tfaType: Customer_TFAType.NONE,
  messages: [],
  promoteUpgrade: false,
  canTerminateAccount: false,
  quota: QuotaState.VALID,
})
