import { CustomerServicePromiseClient } from '@jotta/grpc-web/no/jotta/openapi/customer/customer.v2_grpc_web_pb'
import type {
  CreateInvite,
  Customer,
  DowngradeAccountRequest,
  DowngradeAccountResponse,
  GetCustomerResponse,
  GetFamilyResponse,
  PatchCustomerRequest,
  PatchCustomerResponse,
  RevokeFamilyInviteResponse,
  SendFamilyInviteResponse,
  TerminateAccountRequest,
  TerminateAccountResponse,
} from '@jotta/grpc-web/no/jotta/openapi/customer/customer.v2_pb'
import {
  AccountType,
  GetCustomerRequest,
  GetFamilyRequest,
  RevokeFamilyInviteRequest,
  SendFamilyInviteRequest,
  SetOnboardingTaskRequest,
} from '@jotta/grpc-web/no/jotta/openapi/customer/customer.v2_pb'
export {
  AccountType,
  CreateInvite,
  Customer,
  FamilyInvite,
  GetFamilyResponse,
  PhotoState,
  SendFamilyInviteRequest,
} from '@jotta/grpc-web/no/jotta/openapi/customer/customer.v2_pb'

import { env } from '@jotta/utils/env'
import { withGrpcClientMiddleware } from './grpcutils'

export const customerServiceClient = withGrpcClientMiddleware(
  'customer',
  new CustomerServicePromiseClient(env.grpcApi),
)

export async function whoAmI(): Promise<GetCustomerResponse.AsObject> {
  const req = new GetCustomerRequest()
  const res = await customerServiceClient.getCustomer(req)

  return res.toObject()
}

export function showUpgradeLink(customer?: Customer.AsObject) {
  return Boolean(customer && customer.accountType !== AccountType.BUSINESS_USER)
}

export function showBusinessPanelLink(customer?: Customer.AsObject) {
  return Boolean(
    customer &&
      customer.accountType === AccountType.BUSINESS_ADMIN &&
      typeof getBusinessName(customer) === 'string',
  )
}

export async function getFamily(): Promise<GetFamilyResponse> {
  return await customerServiceClient.getFamily(new GetFamilyRequest())
}

export async function inviteFamilyMember(
  invite: CreateInvite,
): Promise<SendFamilyInviteResponse> {
  const req = new SendFamilyInviteRequest()
  req.setCreateInvite(invite)
  return await customerServiceClient.sendFamilyInvite(req)
}

export async function revokeFamilyInvite(
  inviteCode: string,
): Promise<RevokeFamilyInviteResponse> {
  const req = new RevokeFamilyInviteRequest()
  req.setInviteCode(inviteCode)
  return await customerServiceClient.revokeFamilyInvite(req)
}

export function getBusinessName(customer?: Customer.AsObject) {
  return customer?.business?.name || ''
}

export function patchCustomer(
  req: PatchCustomerRequest,
): Promise<PatchCustomerResponse.AsObject> {
  return customerServiceClient.patchCustomer(req).then(e => e.toObject())
}

export async function terminateAccount(
  req: TerminateAccountRequest,
): Promise<TerminateAccountResponse.AsObject> {
  return customerServiceClient.terminateAccount(req).then(e => e.toObject())
}

export async function getCustomer(): Promise<Customer | undefined> {
  const req = new GetCustomerRequest()
  return (await customerServiceClient.getCustomer(req)).getCustomer()
}

export const OnboardingPopupKeyArray = [
  'archive', // Files topnav
  'backup', // Files topnav
  'fab', // Photo upload button
  'files', // Sidebar
  'hasUploaded', // After first upload, recommend app
  'navMenu', // Hamburger
  'photos', // Sidebar
  'shared', // Files topnav
  'sync', // Files topnav
  'trash', // Sidebar
  'welcome', // Initial welcoming overlay
] as const

export type OnboardingPopupKeys = (typeof OnboardingPopupKeyArray)[number]
export const OnboardingAllKeyArray = [
  ...OnboardingPopupKeyArray,
  'smartPhotoSearchBanner', // Show banner
] as const
export type OnboardingAllKeys = (typeof OnboardingAllKeyArray)[number]

export type OnboardingStatusSet = Set<OnboardingAllKeys>

type OnboardingStatus = [string, boolean][]
type SerializableStatus = {
  toObject: () => { statusMap: OnboardingStatus }
}

function toStatusSet(res: SerializableStatus): OnboardingStatusSet {
  const status = res.toObject().statusMap as [OnboardingAllKeys, boolean][]
  return new Set(status.filter(([k, v]) => v).map(([k]) => k))
}

export async function getOnboarding() {
  const customer = await getCustomer()
  const onboarding = customer?.getOnboarding()

  if (onboarding) {
    return toStatusSet(onboarding)
  }
}

export async function setOnboarding(taskId: string, taskState: boolean) {
  const req = new SetOnboardingTaskRequest()
  req.setTaskId(taskId)
  req.setState(taskState)
  const res = await customerServiceClient.setOnboardingTask(req)
  return toStatusSet(res)
}

export async function downgradeAccount(
  req: DowngradeAccountRequest,
): Promise<DowngradeAccountResponse.AsObject> {
  return customerServiceClient.downgradeAccount(req).then(e => e.toObject())
}
