import { forwardRef } from 'react'
import type { InputProps as InputBaseProps } from 'theme-ui'
import { Input as InputBase } from 'theme-ui'
import type { BoxPropsAs } from '../../Elements/Box/Box'

export type InputProps = BoxPropsAs<
  'input',
  InputBaseProps & {
    errorMessage?: string
  },
  'forms'
>

/**
 * @deprecated does not and will not mimic a native input tag for all types. Prefer <type>Input components such as TextInput, PasswordInput, RadioInput, CheckboxInput
 */
export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  { errorMessage, ...props },
  ref,
) {
  return (
    <>
      {errorMessage && (
        <p
          sx={{
            color: 'red',
          }}
        >
          {errorMessage}
        </p>
      )}
      <InputBase ref={ref} {...props} />
    </>
  )
})
