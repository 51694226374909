import { kebabCase } from 'change-case'
import { clsx, type ClassValue } from 'clsx'
import { listify } from 'radash'
import { twMerge } from 'tailwind-merge'
import { ensureValidNumber, isNumber, isString } from './typeAssertions'

const pxRe = /^\d+px$/
const isNumericString = /^\d+(\.\d+)?$/

export type Px<T extends number = number> = `${T}px`
export type PxValue<T extends number> = T | `${T}px`
export type PxNum<T> = T extends number
  ? T
  : T extends `${number & infer Num}px`
    ? Num
    : never

export function rem(value: string | number): string {
  if (isNumber(value)) {
    return `${value / 16}rem`
  }
  if (isString(value) && isNumericString.test(value)) {
    const num = ensureValidNumber(value, 0)
    return `${num / 16}rem`
  }
  return value
}
export function isPx(value: unknown): value is Px {
  if (isString(value)) {
    return pxRe.test(value)
  }
  return false
}
export function px(value: string | number): string {
  if (isPx(value)) {
    return value
  }
  if (isNumber(value)) {
    return `${value}px`
  }
  if (isString(value) && isNumericString.test(value)) {
    const num = ensureValidNumber(value, 0)
    return `${num}px`
  }
  return value
}

export function objectToCSSVariables<T extends Record<string, string>>(obj: T) {
  const css = listify(obj, k => `--${kebabCase(k)}: ${obj[k]}`)
  return css
}

/**
 * Combines clsx and tailwind-merge
 * @param args Same as clsx
 * @returns className string ordered by tailwind merge
 */
export function cn(...args: ClassValue[]) {
  return twMerge(clsx(args))
}
