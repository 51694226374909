import type { Photos } from '@jotta/types/Photos'
import type { UseMutationOptions } from '@tanstack/react-query'
import Debug from 'debug'
import type { PhotoActionApiFunctions } from './PhotoActions.api'
import { photoActionsApiFunctions } from './PhotoActions.api'
const debug = Debug('jotta:photos:actions')

export type PhotoActionType = Photos.ActionType
export const defaultPhotoActionsApi: PhotoActionApiFunctions =
  photoActionsApiFunctions
export type PhotoAction<ActionType extends PhotoActionType> =
  PhotoActionApiFunctions[ActionType] extends (
    ...args: infer ActionParams
  ) => infer ActionResult
    ? {
        actionFn: (...args: ActionParams) => ActionResult
        actionType: ActionType
        params: ActionParams
        returnValue: ActionResult
        result: ActionResult
        useMutationOptions: UseMutationOptions<
          ActionResult,
          unknown,
          ActionParams,
          unknown
        >
        useDispatchMutationOptions: UseMutationOptions<
          ActionResult,
          unknown,
          {
            actionType: ActionType
            params: ActionParams
          },
          unknown
        >
      }
    : never
