import { isString } from './typeAssertions'

export function highresDateToNumber(value: bigint | string): number {
  const num = isString(value) ? BigInt(value) : value
  return parseInt((num / BigInt(1000000)).toString())
}
export function numberToHighresDate(value: number | string): string {
  return (BigInt(value) * BigInt(1000000)).toString()
}
export const startOfDay = (date: number = new Date().valueOf()) =>
  new Date(date).setUTCHours(0, 0, 0, 0)
