export function bisectRight<T>(a: T[], x: T | ((v: T) => number)) {
  if (!a.length) {
    return 0
  }

  const compareFn =
    x instanceof Function ? x : (val: T) => (x < val ? 1 : x > val ? -1 : 0)

  let hi = a.length
  let lo = 0

  while (lo < hi) {
    const mid = Math.trunc((hi + lo) / 2)
    if (compareFn(a[mid]) > 0) {
      hi = mid
    } else {
      lo = mid + 1
    }
  }

  return lo
}

export function bisectLeft<T>(a: T[], x: T | ((v: T) => number)) {
  if (!a.length) {
    return 0
  }

  const compareFn =
    x instanceof Function ? x : (val: T) => (x < val ? 1 : x > val ? -1 : 0)

  let hi = a.length
  let lo = 0

  while (lo < hi) {
    const mid = Math.trunc((hi + lo) / 2)
    if (compareFn(a[mid]) < 0) {
      lo = mid + 1
    } else {
      hi = mid
    }
  }

  return lo
}

export function binarySearch<T>(a: T[], x: T | ((v: T) => number)) {
  const i = bisectLeft(a, x)

  const compareFn =
    x instanceof Function ? x : (val: T) => (x < val ? 1 : x > val ? -1 : 0)

  if (compareFn(a[i]) === 0) {
    return i
  }

  return -1
}
