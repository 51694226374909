import { AbortError } from '@jotta/types/AbortError'
import { action, makeAutoObservable, observable, runInAction } from 'mobx'

export class PromiseQueue {
  private queue = observable.array<() => Promise<unknown>>([], { deep: false })
  private running = observable.set<Promise<unknown>>([], { deep: false })

  // This is how we control max number of concurrent uploads. Setting this limit too high (and it was set to 100), makes some requests to time out.
  private limit: number

  public get queuedPromises() {
    return Array.from(this.queue.values())
  }

  public constructor(limit = 6) {
    this.limit = limit
    makeAutoObservable(this)
  }

  private dispatch = action(() => {
    while (this.numQ && this.numRunning < this.limit) {
      const p = this.queue.shift()

      if (!p) {
        return
      }

      const dispatched = p().finally(() =>
        runInAction(() => {
          this.running.delete(dispatched)
          this.dispatch()
        }),
      )

      this.running.add(dispatched)
    }
  })

  public schedule<T = unknown>(p: () => Promise<T>, signal?: AbortSignal) {
    return new Promise<T>((resolve, reject) => {
      const entry = () => p().then(resolve, reject)

      this.queue.push(entry)

      if (signal) {
        signal.addEventListener('abort', () => {
          runInAction(() => this.queue.remove(entry))

          reject(new AbortError())
        })
      }
      this.dispatch()
    })
  }

  public get numRunning() {
    return this.running.size
  }

  public get numQ() {
    return this.queue.length
  }
}

export const queue = new PromiseQueue(6)
export const schedule: typeof queue.schedule = p => queue.schedule(p)
