import type { HTMLAttributes } from 'react'
import { forwardRef } from 'react'
import type { VariantProps } from 'tailwind-variants'
import { tv } from 'tailwind-variants'

export const headlineVariants = tv({
  base: 'font-heading font-medium',
  variants: {
    level: {
      h1: 'text-3xl',
      h2: 'text-2xl',
      h3: 'text-xl',
      h4: 'text-lg',
      h5: 'text-base',
      h6: 'text-sm',
    },
  },
})

interface HeadLineOwnProps {
  /** Tag is for the actual HTML tag used to render the component */
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  className?: string
}
type HeadLineProps = VariantProps<typeof headlineVariants> &
  HeadLineOwnProps &
  HTMLAttributes<HTMLHeadingElement>

export const Headline = forwardRef<HTMLHeadingElement, HeadLineProps>(
  function Headline({ level = 'h1', tag = 'h1', className, ...props }, ref) {
    const HeadlineComponent = tag
    return (
      <HeadlineComponent
        className={headlineVariants({
          level,
          className,
        })}
        ref={ref}
        {...props}
      />
    )
  },
)
export const Headline1 = Headline
export const Headline2 = forwardRef<HTMLHeadingElement, HeadLineProps>(
  function Headline({ level = 'h2', tag = 'h2', className, ...props }, ref) {
    const HeadlineComponent = tag
    return (
      <HeadlineComponent
        className={headlineVariants({
          level,
          className,
        })}
        ref={ref}
        {...props}
      />
    )
  },
)
export const Headline3 = forwardRef<HTMLHeadingElement, HeadLineProps>(
  function Headline({ level = 'h3', tag = 'h3', className, ...props }, ref) {
    const HeadlineComponent = tag
    return (
      <HeadlineComponent
        className={headlineVariants({
          level,
          className,
        })}
        ref={ref}
        {...props}
      />
    )
  },
)
export const Headline4 = forwardRef<HTMLHeadingElement, HeadLineProps>(
  function Headline({ level = 'h4', tag = 'h4', className, ...props }, ref) {
    const HeadlineComponent = tag
    return (
      <HeadlineComponent
        className={headlineVariants({
          level,
          className,
        })}
        ref={ref}
        {...props}
      />
    )
  },
)
export const Headline5 = forwardRef<HTMLHeadingElement, HeadLineProps>(
  function Headline({ level = 'h5', tag = 'h5', className, ...props }, ref) {
    const HeadlineComponent = tag
    return (
      <HeadlineComponent
        className={headlineVariants({
          level,
          className,
        })}
        ref={ref}
        {...props}
      />
    )
  },
)
export const Headline6 = forwardRef<HTMLHeadingElement, HeadLineProps>(
  function Headline({ level = 'h6', tag = 'h6', className, ...props }, ref) {
    const HeadlineComponent = tag
    return (
      <HeadlineComponent
        className={headlineVariants({
          level,
          className,
        })}
        ref={ref}
        {...props}
      />
    )
  },
)
