import { SubscriptionInterval } from '@jotta/grpc-connect-openapi/payment'
import { t, Trans } from '@lingui/macro'
import {
  formatPriceConnect,
  formatPriceConnectWithInterval,
} from '@jotta/settings/formatPrice'
import type { Offer } from '@jotta/grpc-connect-openapi/esm/openapi/offer/v1/offer_pb'
import { Offer_ProductScope } from '@jotta/grpc-connect-openapi/esm/openapi/offer/v1/offer_pb'
import { Money } from '@jotta/grpc-connect-openapi/esm/openapi/subscription/payment.v2_pb'
import clsx from 'clsx'
import { deepEqual } from 'fast-equals'
import { formatStorage } from '@jotta/settings/formatStorage'
import { cn } from '@jotta/utils/css'
import { useLocale } from '@jotta/i18n'

function SummaryPriceLine({
  interval,
  money,
  ...props
}: {
  labelText: string
  subtext: boolean
  className?: string
  money: Money
  interval?: SubscriptionInterval
  labelClassName?: string
  valueClassName?: string
}) {
  const locale = useLocale()
  return SummaryLine({
    value: interval
      ? formatPriceConnectWithInterval(interval, money, locale)
      : formatPriceConnect(money, locale),
    ...props,
  })
}

function SummaryLine({
  labelText,
  subtext,
  value,
  className,
  labelClassName,
  valueClassName,
}: {
  labelText: string
  subtext: boolean
  value?: string
  className?: string
  labelClassName?: string
  valueClassName?: string
}) {
  return (
    <div
      className={cn(
        'body-md-ln flex items-end justify-between py-1.5',
        {
          'text-signup-summary-subtext': subtext,
        },
        className,
      )}
    >
      <span className={labelClassName}>{labelText}</span>
      <span className={cn('text-right', valueClassName)}>{value}</span>
    </div>
  )
}

export function PaymentSummary({
  offer,
  interval,
  className,
}: {
  offer: Offer
  interval: SubscriptionInterval
  className?: string
}) {
  const locale = useLocale()
  const yearly = interval == SubscriptionInterval.YEARLY
  const payment = yearly ? offer.yearly : offer.monthly
  const basePrice = payment?.basePrice
  const baseStorage = offer.baseStorage
  const baseStorageFormatted = baseStorage && formatStorage(baseStorage)
  const recurringPrice = payment?.recurringPrice
  const modifiers = payment?.modifiers || []
  const vat = payment?.vat
  const billedNow = payment?.billedNow
  const subtotal =
    vat &&
    billedNow &&
    vat.currency === billedNow.currency &&
    new Money({
      amount: billedNow.amount - vat.amount,
      currency: vat.currency,
    })
  const displayVAT =
    offer.productScope === Offer_ProductScope.BUSINESS &&
    vat &&
    vat.amount > 0 &&
    subtotal
  const vatPercentage =
    displayVAT &&
    Math.round(Number(100n * vat.amount) / Number(subtotal.amount))
  return (
    <div className={clsx('flex flex-col gap-2', className)}>
      <h2 className="title-sm pb-14">{t`Summary`}</h2>

      <div>
        {basePrice && (
          <SummaryPriceLine
            labelClassName="h1"
            labelText={offer.productNameLocalized}
            subtext={false}
            interval={interval}
            money={basePrice}
          />
        )}

        {baseStorageFormatted && (
          <SummaryLine
            subtext={true}
            labelText={baseStorageFormatted}
            value={t`included`}
          />
        )}

        {modifiers.length > 0 && <hr />}

        {modifiers.map(({ descriptionLocalized, amount }) => {
          return (
            amount && (
              <SummaryPriceLine
                subtext={true}
                key={descriptionLocalized}
                labelText={descriptionLocalized}
                money={amount}
              />
            )
          )
        })}
      </div>

      {displayVAT && (
        <>
          <hr />
          <SummaryPriceLine
            subtext={true}
            labelText={`${t`Subtotal`} (${t`excl. VAT`})`}
            money={subtotal}
          />
          <SummaryPriceLine
            subtext={true}
            labelText={`${t`VAT`} (${vatPercentage}%)`}
            money={vat}
          />
        </>
      )}

      {billedNow && (
        <>
          <hr className="my-6" />
          <SummaryPriceLine
            subtext={false}
            labelText={t`Billed Now` + (displayVAT ? ` (${t`incl. VAT`})` : '')}
            valueClassName="h2"
            labelClassName="h2"
            money={billedNow}
          />
        </>
      )}

      <p className="text-signup-summary-subtext">
        {recurringPrice && !deepEqual(recurringPrice, billedNow) && (
          <>
            {t`Then renewed at ${formatPriceConnectWithInterval(interval, recurringPrice, locale)}.`}
            &nbsp;
          </>
        )}

        <Trans>
          You can cancel your subscription at any time before your next renewal.
        </Trans>
      </p>
    </div>
  )
}
