import { ItemType } from '@jotta/grpc-web/no/jotta/openapi/file/file.v2_pb'
import type { PathItemObject } from '@jotta/types/Files'
import { join } from 'path'
import type { GetPathOptions, GetPathParams } from './getPath'
import { getPath } from './getPath'

export interface GetPathItemResponse {
  children: PathItemObject[]
  cursor: string
  entries: Record<string, PathItemObject>
  ids: string[]
  item: PathItemObject
  pathItem: PathItemObject
  isFile: boolean
  isFolder: boolean
  options: GetPathOptions
  uniquePath: string
}
export async function getPathItem(
  params: GetPathParams,
): Promise<GetPathItemResponse> {
  const { cursor, item, options } = await getPath(params)

  const isFile = item.type === ItemType.FILE
  const uniquePath = join('/', item.owner, options.context, item.path)
  const entries = Object.fromEntries(item.childrenList.map(v => [v.path, v]))
  const ids = Object.keys(entries)

  return {
    children: item.childrenList,
    cursor,
    entries,
    options,
    ids,
    item: {
      ...item,
      childrenList: [],
    },
    pathItem: item,
    isFile,
    isFolder: !isFile,
    uniquePath,
  }
}
export async function getFolderPathItem(
  params: GetPathParams,
): Promise<GetPathItemResponse> {
  const response = await getPathItem(params)
  if (response.isFile) {
    throw new Error(`Path exists, but is not a folder: ${params.path}`)
  }
  return {
    ...response,
    isFile: false,
    isFolder: true,
    children: response.children.filter(item => item.type === ItemType.FOLDER),
  }
}
