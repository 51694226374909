import { makeAbsolute } from '@jotta/utils/pathUtils'

export function findByPath<T extends { path: string }>(
  path: string,
  items: T[] = [],
): T | undefined {
  const pathStr = makeAbsolute(path.toLowerCase())
  return items.find(
    item => makeAbsolute(item.path.toLocaleLowerCase()) === pathStr,
  )
}
