import { useBrandTheme } from '@jotta/ui/useBrandTheme'
import { createEmptyPathItem } from '@jotta/file-utils'
import { ItemType } from '@jotta/grpc-js-client/fileService'
import { parseGetPathParams } from '@jotta/grpc-js-client/files/getPath'
import type { GetPathItemResponse } from '@jotta/grpc-js-client/files/getPathItem'
import { getRouterStore } from '@jotta/router'
import { AppError } from '@jotta/types/AppError'
import type { PathItemObject } from '@jotta/types/Files'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import { createPath, getFilenameFromPath } from '@jotta/utils/pathUtils'
import { keepPreviousData } from '@tanstack/react-query'
import { runInAction } from 'mobx'
import moize from 'moize'
import type { PropsWithChildren } from 'react'
import { Suspense, createContext, useContext, useEffect, useState } from 'react'
import { getFileIcons } from '../FileIcon/getFileIcons'
import type { parseFileRouteParams } from '../FileRoute/utils/parseFileRouteParams'
import { useParsedFileRouteParams } from '../FileRoute/utils/parseFileRouteParams'
import { usePathItem } from '../PathItem/hooks/usePathItem'
import { FileStore } from './FileStore'
import { useSuspenseCustomer } from '@jotta/grpc-connect-client/customer'

export interface FileContextValue {
  fileStore: FileStore
  fileIcons: ReturnType<typeof getFileIcons>
}

export const FileContext = createContext<FileContextValue | undefined>(
  undefined,
)

export const createPathItemFromParsedRoute = moize(
  function createPathItemFromParsedRoute(
    route: ReturnType<typeof parseFileRouteParams>,
    owner: string,
  ): GetPathItemResponse {
    const item: PathItemObject = {
      ...createEmptyPathItem(),
      name: route.isRoot ? route.context : getFilenameFromPath(route.apiPath),
      path: route.apiPath,
      type: ItemType.FOLDER,
      owner,
    }
    return {
      children: [],
      cursor: '',
      entries: {},
      ids: [],
      isFile: false,
      isFolder: true,
      item,
      pathItem: item,
      uniquePath: createPath('/', owner, route.apiPath),
      options: parseGetPathParams(route.getPathParams),
    }
  },
  {
    maxSize: 100,
  },
)
export function FileContextProvider({
  children,
  fileStore,
}: PropsWithChildren<Partial<FileContextValue>>) {
  const { brandStore, theme } = useBrandTheme()
  const router = getRouterStore()
  const route = useParsedFileRouteParams()
  const { apiPath, context } = route
  const fileIcons = getFileIcons(theme)
  const isSearch = context === 'search'
  const [store, setStore] = useState(fileStore)

  const query = usePathItem({
    variables: {
      context,
      path: apiPath,
    },
    enabled: !isSearch,
    retry: false,
    placeholderData: keepPreviousData,
    throwOnError: true,
  })

  const { customer } = useSuspenseCustomer().data
  const username = customer?.username || ''
  const data = isSearch
    ? createPathItemFromParsedRoute(route, username)
    : query.data

  useEffect(() => {
    if (!data) {
      return
    }

    if (fileStore) {
      fileStore.currentItem = data
    } else {
      const store = new FileStore(data, brandStore, router)
      store.username = username
      runInAction(() => {
        store.folder.addItem(data.item, Date.now())
      })
      setStore(store)
    }
    // eslint-disable-next-line
  }, [brandStore, data, fileStore, router])

  useEffect(() => {
    if (store) {
      store.username = username
    }
  }, [store, username])

  if (!store) {
    return <LoadingOverlay open />
  }

  return (
    <Suspense fallback={<LoadingOverlay open />}>
      <FileContext.Provider
        value={{
          fileStore: store,
          fileIcons,
        }}
      >
        {children}
      </FileContext.Provider>
    </Suspense>
  )
}

export function useFileContext() {
  const fileContext = useContext(FileContext)
  if (!fileContext) {
    throw new AppError({
      message: 'useFileContext must be used inside FileContextProvider',
    })
  }
  return fileContext
}
export function useFileStore() {
  const fileContext = useFileContext()
  return fileContext.fileStore
}
