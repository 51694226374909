import { DefinitionListFromObject } from '@jotta/ui/DefinitionList'
import { Stack } from '@jotta/ui/Stack'
import { Plural, Trans } from '@lingui/macro'
import { observer } from 'mobx-react-lite'

import type { UploadPhotoQueueStatusProps } from './UploadPhotoQueueStatus'

export const UploadPhotoQueueStatusUploadCompletedWithErrors =
  observer<UploadPhotoQueueStatusProps>(
    function UploadPhotoQueueStatusUploadCompletedWithErrors({ status }) {
      const { total, errors } = status
      const outOfSpace = errors.some(e => e.code === 460)
      const errorDetails = errors.filter(e => e.type !== 'AbortError')
      const aborted = errorDetails.length !== errors.length

      return (
        <>
          <div
            sx={{
              color: 'danger',
            }}
          >
            {outOfSpace ? (
              <Trans>No more storage available!</Trans>
            ) : aborted ? (
              <Trans>Cancelled</Trans>
            ) : (
              <Trans>Couldn't continue uploading because of an error!</Trans>
            )}
          </div>
          {Boolean(status.resolved) && (
            <Plural
              value={status.resolved}
              one={`Uploaded # file of ${total} files!`}
              other={`Uploaded # files of ${total} files!`}
            />
          )}
          {Boolean(errorDetails.length) && (
            <details>
              <summary
                sx={{
                  pl: 0,
                  pb: 2,
                  ml: 4,
                  listStylePosition: 'outside',
                }}
              >
                <Trans>Technical details</Trans>
              </summary>
              <Stack
                gap={4}
                pl="4"
                sx={{ maxHeight: '50vh', overflowY: 'auto' }}
              >
                {errorDetails.map((err, i) => (
                  <DefinitionListFromObject
                    sx={{
                      fontSize: 1,
                      gap: 0,
                    }}
                    key={`error-${i}`}
                    value={err}
                  />
                ))}
              </Stack>
            </details>
          )}
        </>
      )
    },
  )
