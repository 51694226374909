/** External */
import Debug from 'debug'
import moize from 'moize'
import ms from 'ms'

/** External - ConnectRPC */
import type { PartialMessage } from '@bufbuild/protobuf'
import type { UseInfiniteQueryOptions } from '@connectrpc/connect-query'
import {
  callUnaryMethod,
  createConnectQueryKey,
  useInfiniteQuery,
  useQuery,
} from '@connectrpc/connect-query'

/** @jotta/grpc-connect */
import {
  sharePhotos,
  unshareAlbum,
} from '@jotta/grpc-connect-openapi/albumQuery'
import type {
  GetMergeCandidatesResponse,
  GetPeopleRequest,
  GetPeopleResponse,
  GetPeopleResponse_PagedPerson,
  GetPersonRequest,
  GetPhotosWithPersonRequest,
  GetPhotosWithPersonResponse_Photo,
  Person,
} from '@jotta/grpc-connect-openapi/people'
import {
  getPeople,
  getPerson,
  getPhotosWithPerson,
} from '@jotta/grpc-connect-openapi/peopleQuery'

/** Internal */
import { queryClient } from '@jotta/query'
import type { InfiniteData } from '@tanstack/react-query'
import { albumById } from '../../../api/photosApi'
import { useEffect } from 'react'
import { excludesFalse } from '@jotta/types/TypeUtils'
import { authTransport } from '@jotta/grpc-connect-client/transport'

const debug = Debug('jotta:people:api')

const initialPeoplePageSize = 10
const peoplePageSize = 1000
const initialPeopleQueryParams: PartialMessage<GetPeopleRequest> = {
  pageSize: initialPeoplePageSize,
}
const initialPeopleQueryKey = createConnectQueryKey<
  GetPeopleRequest,
  GetPeopleResponse
>(getPeople, initialPeopleQueryParams)
export type PersonPhoto = GetPhotosWithPersonResponse_Photo

export type ParsedGetPeopleResponse = {
  ids: string[]
  people: Person[]
  byId: Record<string, Person>
  count: number
}

type UsePeopleParams = PartialMessage<GetPeopleRequest> &
  Pick<Required<PartialMessage<GetPeopleRequest>>, 'cursor'>

export const getPersonsFromPagedPeople = moize.shallow(
  (data: GetPeopleResponse_PagedPerson[] | undefined) => {
    return data?.map(v => v.person).filter(excludesFalse) || []
  },
  {
    maxSize: 100,
  },
)
export const getPeopleFromMergeCandidatesResponse = moize(
  (data: GetMergeCandidatesResponse | undefined): Person[] => {
    return data?.mergeCandidate.map(v => v.person).filter(excludesFalse) || []
  },
  {
    maxSize: 100,
  },
)
export const getPersonsGetPeopleResponse = moize(
  (data: GetPeopleResponse | undefined) => {
    const result: ParsedGetPeopleResponse = {
      byId: {},
      ids: [],
      people: [],
      get count() {
        return this.people.length
      },
    }
    if (!data) {
      return result
    }
    for (const { person } of data.persons) {
      if (person) {
        const id = person.id
        result.ids.push(id)
        result.byId[id] = person
        result.people.push(person)
      }
    }
    return { ...result }
  },
  {
    maxSize: 100,
  },
)
export const getPersonsFromInfiniteGetPeopleResponse = moize(
  (data: InfiniteData<GetPeopleResponse, unknown | undefined> | undefined) => {
    const result: ParsedGetPeopleResponse = {
      byId: {},
      ids: [],
      people: [],
      get count() {
        return this.people.length
      },
    }
    if (!data) {
      return result
    }
    for (const page of data.pages) {
      for (const { person } of page.persons) {
        if (person) {
          const id = person.id
          result.ids.push(id)
          result.byId[id] = person
          result.people.push(person)
        }
      }
    }
    return { ...result }
  },
  {
    maxSize: 100,
  },
)

export function useInitialPeople() {
  const query = useQuery(getPeople, initialPeopleQueryParams, {
    transport: authTransport,
  })
  return [getPersonsGetPeopleResponse(query.data), query]
}
export function usePeople(
  params?: UsePeopleParams,
  options?: Partial<
    UseInfiniteQueryOptions<GetPeopleRequest, GetPeopleResponse, 'cursor'>
  >,
) {
  // const log = debug.extend('usePeople')
  const query = useInfiniteQuery(
    getPeople,
    {
      pageSize: peoplePageSize,
      ...params,
      cursor: params?.cursor || '',
    },
    {
      // initialData: () => {
      //   const data = queryClient.getQueryData(initialPeopleQueryKey)
      //   log('initialData', data)

      //   if (data && data instanceof GetPeopleResponse) {
      //     const result: InfiniteData<GetPeopleResponse, string | undefined> = {
      //       pageParams: [data.cursor],
      //       pages: [data],
      //     }
      //     return result
      //   }
      // },
      // initialDataUpdatedAt: 0,
      refetchOnWindowFocus: true,
      staleTime: ms('5min'),
      pageParamKey: 'cursor',
      getNextPageParam: lastPage => lastPage.cursor,
      ...options,
    },
  )
  const { isFetching, fetchNextPage, data } = query
  useEffect(() => {
    if (data && !isFetching && fetchNextPage) {
      fetchNextPage()
    }
  }, [isFetching, fetchNextPage, data])

  return [getPersonsFromInfiniteGetPeopleResponse(query.data), query] as const
}
export function invalidatePeople() {
  queryClient.invalidateQueries({
    queryKey: createConnectQueryKey(getPeople),
  })
}

export function fetchInitialPeople() {
  return queryClient.fetchQuery({
    queryKey: initialPeopleQueryKey,
    queryFn: () =>
      callUnaryMethod(getPeople, initialPeopleQueryParams, {
        transport: authTransport,
      }),
  })
}
export async function fetchPeople(params: PartialMessage<GetPeopleRequest>) {
  const log = debug.extend('fetchPeople')
  const data = await queryClient.fetchQuery({
    queryKey: createConnectQueryKey(getPeople, {
      pageSize: initialPeoplePageSize,
      ...params,
    }),
    queryFn: () =>
      callUnaryMethod(getPeople, params, {
        transport: authTransport,
      }),
  })
  log(data)
  return data
}
export function preFetchPeople() {
  return queryClient.prefetchQuery({
    queryKey: initialPeopleQueryKey,
    queryFn: () =>
      callUnaryMethod(getPeople, initialPeopleQueryParams, {
        transport: authTransport,
      }),
  })
}
export async function shareGroupOfPhotos(photoIds: string[]) {
  const res = await queryClient.fetchQuery({
    queryKey: createConnectQueryKey(sharePhotos, {
      photoMd5: photoIds,
    }),
    queryFn: () =>
      callUnaryMethod(
        sharePhotos,
        {
          photoMd5: photoIds,
        },
        {
          transport: authTransport,
        },
      ),
  })
  const shareId = String((await albumById(res.albumId)).shareInfo?.uri)

  return { albumId: res.albumId, shareId }
}
export function unshareGroupOfPhotos(albumId: string) {
  return queryClient.fetchQuery({
    queryKey: createConnectQueryKey(unshareAlbum, {
      albumId,
    }),
    queryFn: () =>
      callUnaryMethod(
        unshareAlbum,
        {
          albumId,
        },
        {
          transport: authTransport,
        },
      ),
  })
}
export function fetchPerson(params: PartialMessage<GetPersonRequest>) {
  return queryClient.fetchQuery({
    queryKey: createConnectQueryKey(getPerson, params),
    queryFn: () =>
      callUnaryMethod(getPerson, params, {
        transport: authTransport,
      }),
  })
}

export function usePerson(id: string) {
  return useQuery(
    getPerson,
    {
      id,
    },
    {
      transport: authTransport,
      enabled: Boolean(id),
    },
  )
}

export function fetchPhotosWithPerson(
  params: PartialMessage<GetPhotosWithPersonRequest>,
) {
  return queryClient.fetchQuery({
    queryKey: createConnectQueryKey(getPhotosWithPerson, params),
    queryFn: () =>
      callUnaryMethod(getPhotosWithPerson, params, {
        transport: authTransport,
      }),
  })
}
