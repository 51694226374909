import { useBrandStore } from '@jotta/ui/useBrandTheme'
import { Notifications } from '@jotta/ui/Notifications'
import { UserMenu } from '@jotta/ui/UserMenu'
import { ErrorBoundary } from '@sentry/react'
import { observer } from 'mobx-react-lite'
import { useBrandingInfo } from '@jotta/grpc-connect-client/config'
import { useGetCustomer } from '@jotta/grpc-connect-client/customer'

export const Header = observer(function Header() {
  const customer = useGetCustomer().data?.customer
  const showUpgradeLink = customer?.promoteUpgrade || false
  const { downloadPageLink } = useBrandingInfo()
  const branding = useBrandStore()

  if (!customer || !customer.username) {
    return null
  }

  return (
    <>
      <ErrorBoundary>
        <div
          ref={branding.slots.header}
          id="headercontent"
          // eslint-disable-next-line tailwindcss/no-custom-classname
          className="headerSlot min-h-header"
          data-testid="headerSlot"
          style={{
            alignSelf: 'stretch',
          }}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <Notifications />
      </ErrorBoundary>
      <ErrorBoundary>
        <UserMenu
          name={customer.fullName || customer.email}
          email={customer.email || customer.fullName}
          avatar={customer.avatar}
          enableUpgradeButton={customer.promoteUpgrade || false}
          customerQuotaBytes={Number(customer.quotaBytes)}
          customerUsageBytes={Number(customer.usageBytes)}
          downloadPageLink={downloadPageLink}
          userCanUpgrade={showUpgradeLink}
        />
      </ErrorBoundary>
    </>
  )
})
