import { t } from '@lingui/macro'
import { CreateUser } from './CreateUser'
import { SignupContainer } from './SignupContainer'
import { useBrandName } from '@jotta/grpc-connect-client/config'

export function BusinessSignup({
  customerGroupOverride,
}: {
  customerGroupOverride?: string
}) {
  const brandName = useBrandName()
  return (
    <SignupContainer title={t`Try ${brandName} for business free of charge`}>
      <CreateUser
        type="business"
        customerGroupOverride={customerGroupOverride}
      />
    </SignupContainer>
  )
}
