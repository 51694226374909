import { observer } from 'mobx-react-lite'
import { usePhotoStore } from '../../store/PhotoContext'
import { SharedPhotosPage } from './SharedPhotosPage'

export const SharedPhotosContainer = observer(function SharedPhotosContainer() {
  const store = usePhotoStore().albumRoot.shares

  return (
    <SharedPhotosPage albums={store.albumsData} isLoading={store.isLoading} />
  )
})
