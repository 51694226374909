import { delay } from './delay'
import Debug from 'debug'
export const debug = Debug('redirect')

export async function redirectAndWait(href: string) {
  debug('redirectAndWait', href)
  window.location.href = href
  // wait a bit while browser is loading the new location - suitable for keeping spinners around
  await delay(1000)
}
