import { Code } from '@connectrpc/connect'
import { ErrorType } from '@jotta/grpc-web/no/jotta/openapi/error_pb'
import Debug from 'debug'
import { isString } from 'remeda'
import z from 'zod'
const debug = Debug('jotta:types:ErrorSchema')

export const ApiErrorSchema = z.object({
  code: z.nativeEnum(ErrorType),
})

export const XidSchema = z
  .object({
    xid: z.string(),
  })
  .transform(o => o.xid)
  .or(
    z
      .object({
        'x-id': z.string(),
      })
      .transform(o => o['x-id']),
  )
  .or(
    z
      .object({
        response: z.object({
          xid: z.string(),
        }),
      })
      .transform(o => o.response.xid),
  )
  .or(
    z
      .object({
        response: z.object({
          'x-id': z.string(),
        }),
      })
      .transform(o => o.response['x-id']),
  )
export const ApiErrorResponseSchema = z.preprocess(
  val => {
    if (isString(val)) {
      try {
        const data = JSON.parse(val.replace(/^\[\w+\]/, ''))
        return data
      } catch (error) {
        // debug('ApiErrorResponseSchema - Failed to parse JSON', val)
      }
    }
    return val
  },
  z.object({
    xid: z.string().optional(),
    message: z.string().default(''),
    errors: z.array(ApiErrorSchema).default([]),
  }),
)

/**Schema for raw GRPC error object */
export const GrpcRawErrorSchema = z
  .object({
    code: z.nativeEnum(Code).default(Code.Unknown),
    message: ApiErrorResponseSchema,
    metadata: z.object({
      'content-length': z.string(),
      'content-type': z.string(),
      'grpc-message': z.string(),
      'grpc-status': z.string(),
    }),
  })
  .transform(data => ({
    code: data.code,
    parsed: data.message,
    xid: data.message.xid,
    errors: data.message.errors,
  }))

/**
 * Schema for the ApiError class in grpc-js-client
 */
export const GrpcApiErrorSchema = z.preprocess(
  val => {
    if (isString(val)) {
      try {
        const data = JSON.parse(val)
        return data
      } catch (error) {
        debug('GrpcApiErrorSchema - Failed to parse JSON', val)
      }
    }
    return val
  },
  z.object({
    code: z.nativeEnum(Code).default(Code.Unknown),
    parsed: ApiErrorResponseSchema,
    xid: z.string().optional(),
    message: z.string().optional(),
    errors: z.array(ApiErrorSchema).default([]),
  }),
)
