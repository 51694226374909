import moize from 'moize'
import type { FileListRowObject } from './fileListUtils'

export const FileListRow = moize.react(function FileListRow({
  row,
}: {
  row: FileListRowObject
}) {
  return (
    <div key={row.key} style={row.style}>
      {row.view === 'list'
        ? row.renderItem(row.rowIndex, row.key, 'list')
        : row.items.map(item => row.renderItem(item.index, item.key, 'thumbs'))}
    </div>
  )
})
