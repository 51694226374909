import { SelectionStore } from '@jotta/collection/SelectionStore'
import type { ItemKindKey } from '@jotta/file-utils'
import { fileKindConfig, findByPath, getItemKindKey } from '@jotta/file-utils'
import type { ItemKind } from '@jotta/grpc-js-client/fileService'
import { ItemAction, ItemType } from '@jotta/grpc-js-client/fileService'
import type { GetCurrentFolderResponse } from '@jotta/grpc-js-client/files/getFolder'
import type { PathItemObject, SortDir } from '@jotta/types/Files'
import { filesize } from '@jotta/utils/filesize'
import { getBreadCrumb } from '@jotta/utils/path/breadcrumb'
import { shiftPath, uriEncodePath } from '@jotta/utils/pathUtils'
import type { InfiniteData } from '@tanstack/react-query'
import { kebabCase } from 'change-case'
import dayjs from 'dayjs'
import Debug from 'debug'
import {
  action,
  isObservableObject,
  makeAutoObservable,
  observable,
  onBecomeObserved,
  onBecomeUnobserved,
} from 'mobx'
import { createTransformer } from 'mobx-utils'
import type { ChangeEvent, SyntheticEvent } from 'react'
import { addPathItem } from './fileItemCache'

const itemIDTransformer = createTransformer((item: PublicFileItem) => item.path)
export class PublicFileItem {
  private log: Debug.Debugger

  item: PathItemObject
  parent: PublicFileItem | null = null
  children = observable.array<PublicFileItem>()
  showPaths = false
  private _thumbLoadError = false

  page = 1
  selection = new SelectionStore(this.children, itemIDTransformer)
  private _activeFilePath = ''

  constructor(
    { childrenList, ...item }: PathItemObject,
    parents?: PublicFileItem | PathItemObject[],
  ) {
    this.log = Debug(
      `jotta:publicfiles:item:${kebabCase(
        item.name.substring(0, 10) || 'root',
      )}`,
    )
    this.item = {
      ...item,
      childrenList: [],
    }
    makeAutoObservable<typeof this, 'log'>(this, {
      log: false,
      item: observable.struct,
      onThumbLoadError: false,
      onCheckboxChange: action.bound,
    })
    if (childrenList.length) {
      this.replaceChildren(childrenList)
    }
    if (parents) {
      this.updateParent(parents)
    }
    if (this.item.name.startsWith('00b19') && isObservableObject(this)) {
      onBecomeObserved(this, 'isSelected', () => {
        this.log('observed', this.isSelected)
      })
      onBecomeUnobserved(this, 'isSelected', () => {
        this.log('unobserved', this.isSelected)
      })
    }
  }
  get root(): PublicFileItem {
    return this.parent?.root || this
  }
  get isRoot() {
    return !this.parent
  }
  get cursor() {
    return this.item.cursor
  }
  get hasNextPage() {
    return Boolean(this.cursor)
  }
  get fileViewerItems() {
    return this.children.filter(item => item.isFile)
  }

  get file(): PublicFileItem | undefined {
    if (this._activeFilePath) {
      return this.getFileViewerItemByPath(this._activeFilePath)
    }
  }

  /**
   * Check for the special case where a single file is shared
   * In that case the file is placed in a fake folder identical to the file,
   * and the file viewer should open by default
   */
  get singleSharedFile(): PublicFileItem | undefined {
    return this.isRoot &&
      this.children.length === 1 &&
      // Make sure we don't compare two empty checksums, since that will always
      // be true for all folders
      this.item.checksum &&
      this.item.checksum === this.children[0].item.checksum
      ? this.children[0]
      : undefined
  }

  get fileViewerIndex() {
    if (this.file) {
      return this.fileViewerItems.indexOf(this.file)
    }
    return -1
  }

  getChildByPath(path: string) {
    return findByPath(path, this.children)
  }
  getFileViewerItemByPath(path: string) {
    return findByPath(path, this.fileViewerItems)
  }
  selectFileViewerItemByPath(path?: string) {
    this._activeFilePath = path || ''
  }

  addPages({ pages, pageParams }: InfiniteData<GetCurrentFolderResponse>) {
    const [firstPage, ...rest] = pages
    if (!firstPage) {
      this.log('addPages: No pages')
    }
    this.log('addPages: %d pages', pages.length)
    const { folder, file, parents } = firstPage

    if (folder.path !== this.path) {
      this.log(
        'addPages: Path mismatch, expected %s, got %s',
        folder.path,
        this.path,
      )
      return
    }
    this.selectFileViewerItemByPath(file?.path)
    if (rest.length && this.cursor && folder.cursor === this.cursor) {
      const nextPages = pages.slice(this.page)
      if (!nextPages.length) {
        this.log('addPages: Found cursor, but no new pages to add')
        return
      }
      this.log(
        'addPages: Found cursor, adding %d pages %d-%d',
        nextPages.length,
        this.page + 1,
        pages.length,
      )
      for (const page of nextPages) {
        this.addNextPage(page.folder.childrenList)
      }
      return
    }
    this.log('addPages: %d pages', pages.length, this.cursor)
    this.page = 1
    this.updateItem(folder, false, parents)
    for (const page of rest) {
      this.addNextPage(page.folder.childrenList)
    }
  }

  replaceChildren(children: PathItemObject[]) {
    this.log('Replace %d children', children.length)
    this.page = 1
    this.children.replace(children.map(item => addPathItem(item, true, this)))
  }
  addNextPage(children: PathItemObject[]) {
    this.page += 1
    const items = children
      .map(item => addPathItem(item, true, this))
      .filter(item => !this.children.includes(item))
    this.children.push(...items)
    const after = this.children.length
    this.log(
      'page %d: Added %d of %d children, new total %d',
      this.page,
      items.length,
      children.length,
      after,
    )
  }

  static sortByName(
    a: PublicFileItem,
    b: PublicFileItem,
    dir: SortDir = 'ASC',
  ) {
    if (a.isFolder && b.isFile) {
      return -1
    }
    if (b.isFolder && a.isFile) {
      return 1
    }
    switch (dir) {
      case 'ASC':
        return a.name.localeCompare(b.name)
      default:
        return b.name.localeCompare(a.name)
    }
  }
  static sortByNameDesc(a: PublicFileItem, b: PublicFileItem) {
    return PublicFileItem.sortByName(a, b, 'DESC')
  }
  static sortByDate(
    a: PublicFileItem,
    b: PublicFileItem,
    dir: SortDir = 'ASC',
  ) {
    if (a.isFolder && b.isFile) {
      return -1
    }
    if (b.isFolder && a.isFile) {
      return 1
    }
    const asc = dir === 'ASC'
    const d1 = asc ? a.item.modifiedAtMillis : b.item.modifiedAtMillis
    const d2 = asc ? b.item.modifiedAtMillis : a.item.modifiedAtMillis
    if (d1 < d2) {
      return 1
    }
    if (d1 > d2) {
      return -1
    }
    return 0
  }
  static sortByDateDesc(a: PublicFileItem, b: PublicFileItem) {
    return PublicFileItem.sortByDate(a, b, 'DESC')
  }
  static sort(
    a: PublicFileItem,
    b: PublicFileItem,
    sortBy: 'name' | 'date',
    dir: SortDir = 'ASC',
  ) {
    switch (sortBy) {
      case 'name':
        return PublicFileItem.sortByName(a, b, dir)
      case 'date':
        return PublicFileItem.sortByDate(a, b, dir)
    }
  }
  public get thumbLoadError() {
    return this._thumbLoadError
  }
  public set thumbLoadError(value) {
    this._thumbLoadError = value
  }
  onThumbLoadError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    this.log('onThumbLoadError')
    this.thumbLoadError = true
  }
  get itemKind(): ItemKind {
    return this.item.kind
  }
  get breadCrumb() {
    return getBreadCrumb(this.path, '')
  }

  get htmlMeta(): Record<string, string> {
    const { current, root } = this.breadCrumb
    const title =
      root.name && root.name !== current.name
        ? `${current.name} - ${root.name}`
        : current.name
    const thumb = this.openGraphThumb || ''
    return {
      title,
      thumb,
    }
  }

  get itemKindKey(): ItemKindKey {
    return getItemKindKey(this.item.kind)
  }

  get iconConfig() {
    return fileKindConfig[this.itemKindKey]
  }
  get icon() {
    return this.iconConfig.icon
  }

  get listThumb() {
    if (this.item.thumbLink && !this.thumbLoadError) {
      try {
        const url = new URL(this.item.thumbLink)
        url.searchParams.set('size', 'WS')
        return url.href
      } catch (error) {
        this.log('Failed to create thumb')
      }
    }
    return ''
  }
  get thumb() {
    if (this.item.thumbLink && !this.thumbLoadError) {
      try {
        const url = new URL(this.item.thumbLink)
        url.searchParams.set('size', '130x130')
        return url.href
      } catch (error) {
        this.log('Failed to create thumb')
      }
    }
    return ''
  }

  get openGraphThumb() {
    if (this.item.thumbLink) {
      try {
        const url = new URL(this.item.thumbLink)
        url.searchParams.set('size', '1280x')
        return url.href
      } catch (error) {
        this.log('Failed to create thumb')
      }
    }
    return ''
  }

  get date() {
    return dayjs(this.item.modifiedAtMillis)
  }

  get dateStr() {
    return this.isFolder ? '-' : this.date.format('L')
  }
  get timeStr() {
    return this.date.format('LT')
  }

  get dateTimeStr() {
    return this.date.format('L LT')
  }

  get actionList(): ItemAction[] {
    return this.item.actionList
  }
  get canUpload() {
    const status = this.actionList.includes(ItemAction.ADD_CHILD)
    this.log('canUpload', status)
    return status
  }

  onCheckboxChange(e: ChangeEvent<HTMLInputElement>) {
    if (this.parent) {
      this.parent.selection.onCheckboxChange(e)
    }
  }
  get selectedItems() {
    return this.children.filter(item => this.selection.selected.has(item.path))
  }

  get size() {
    if (this.isFile) {
      return filesize(this.item.size, { round: 0 })
    }
    return '-'
  }

  get name() {
    return this.item.name
  }
  get path() {
    return this.item.path
  }
  get fileRoutePath() {
    return `.${uriEncodePath(shiftPath(this.path, 1)).toLocaleLowerCase()}`
  }
  get encodedPath() {
    return uriEncodePath(this.path.toLocaleLowerCase())
  }
  get publicShareRoutePath() {
    return `.${this.encodedPath}`
  }
  get isShare() {
    return Boolean(this.item.token?.shareId)
  }

  get isSelected() {
    return Boolean(this.parent?.selection.isSelected(this.path))
  }

  get itemType() {
    switch (this.item.type) {
      case ItemType.FILE:
        return 'file'
      case ItemType.FOLDER:
        return 'folder'
      case ItemType.UNKNOWN_ITEM_TYPE:
        return 'unknown'
    }
  }

  get isEmpty() {
    return !this.children.length
  }

  get isFolder() {
    return this.itemType === 'folder'
  }
  get isFile() {
    return this.itemType === 'file'
  }

  updateItem(
    { childrenList, ...item }: PathItemObject,
    ignoreChildren = false,
    parents: PublicFileItem | PathItemObject[] = [],
  ) {
    this.item = {
      ...item,
      childrenList: [],
    }
    if (!ignoreChildren) {
      this.replaceChildren(childrenList)
    }
    this.updateParent(parents)
  }
  updateParent(value: PublicFileItem | PathItemObject[] = []) {
    if (value instanceof PublicFileItem && this.parent !== value) {
      // this.log('Add parent %s', value.name)
      this.parent = value
    } else if (Array.isArray(value)) {
      const [parent, ...parents] = value

      if (parent && !this.parent) {
        // this.log('Add parent %s', parent.name)
        this.parent = addPathItem(parent, true, parents)
      }
    }
  }
}
