import { isPhotoActionContextType } from '@jotta/types/schemas/PhotoActionSchema'
import type { PhotoActionContextType } from './PhotoActionContext.types'
import type { PhotoActionType } from './PhotoActions.types'

function createPhotoActionsByContext<
  T extends Record<PhotoActionContextType, readonly PhotoActionType[]>,
>(data: T) {
  return data
}
export const photoActionsByContext = createPhotoActionsByContext({
  /**
   * Album contexts
   */
  ALBUM_LIST: ['CREATE_ALBUM'] as const,
  ALBUM_VIEW_NORMAL_ALBUM: [
    'ADD_PHOTOS',
    'SHARE_ALBUM',
    'COPY_SHARED_ALBUM_LINK',
    'DOWNLOAD_PHOTOS',
    'LEAVE_ALBUM',
    'DELETE_ALBUM',
  ] as const,
  ALBUM_VIEW_META_SHARED_ALBUM_TYPES: [
    'ADD_PHOTOS',
    'SHARE_ALBUM',
    'COPY_SHARED_ALBUM_LINK',
    'DOWNLOAD_PHOTOS',
    'LEAVE_ALBUM',
    'DELETE_ALBUM',
  ] as const,
  ALBUM_VIEW_LOCATION_ALBUM: ['DOWNLOAD_PHOTOS'] as const,
  ALBUM_VIEW_SELECTED_PHOTOS: [
    'SHARE_PHOTO',
    'ADD_PHOTOS_TO_ALBUM',
    'REMOVE_PHOTOS_FROM_ALBUM',
    'DOWNLOAD_PHOTOS',
  ] as const,
  ALBUM_VIEW_LOCATION_ALBUM_SELECTED_PHOTOS: [
    'SHARE_PHOTO',
    'ADD_PHOTOS_TO_ALBUM',
    'HIDE_PHOTO',
    'DELETE_PHOTOS',
    'DOWNLOAD_PHOTOS',
  ] as const,
  ADD_PHOTOS_TO_ALBUM_SELECTED_PHOTOS: ['ADD_PHOTOS_TO_ALBUM'] as const,
  /**
   * Public shared album view
   */
  SHARED_GROUP_OF_PHOTOS: [
    'SHARE_ALBUM',
    'COPY_SHARED_ALBUM_LINK',
    'DOWNLOAD_PHOTOS',
  ] as const,
  SHARED_ALBUM_VIEW_ANONYMOUS: [
    'ADD_PHOTOS_ANONYMOUS',
    'SHARE_ALBUM',
    'COPY_SHARED_ALBUM_LINK',
    'DOWNLOAD_PHOTOS',
    'FOLLOW_ALBUM_ANONYMOUS',
  ] as const,
  SHARED_ALBUM_VIEW_LOGGED_IN: [
    'ADD_PHOTOS_ANONYMOUS',
    'ADD_PHOTOS',
    'SHARE_ALBUM',
    'COPY_SHARED_ALBUM_LINK',
    'DOWNLOAD_PHOTOS',
    'FOLLOW_ALBUM',
  ] as const,
  SHARED_ALBUM_SELECTED_PHOTOS: [
    'ADD_PHOTOS_TO_ALBUM',
    'SHARE_PHOTO',
    'REMOVE_PHOTOS_FROM_ALBUM',
    'DOWNLOAD_PHOTOS',
  ] as const,
  SHARED_PHOTOS_SELECTED_PHOTOS: ['DOWNLOAD_PHOTOS'] as const,
  /**
   * Timeline contexts
   */
  TIMELINE_HIDDEN_PHOTOS: ['UPLOAD_PHOTO'] as const,
  TIMELINE_HIDDEN_PHOTOS_SELECTED_PHOTOS: [
    'UNHIDE_PHOTO',
    'DELETE_PHOTOS',
  ] as const,
  TIMELINE_PHOTOS: ['UPLOAD_PHOTO'] as const,
  TIMELINE_PHOTOS_SELECTED_PHOTOS: [
    'SHARE_PHOTO',
    'ADD_PHOTOS_TO_ALBUM',
    'HIDE_PHOTO',
    'DELETE_PHOTOS',
    'DOWNLOAD_PHOTOS',
  ] as const,
  SMART_PHOTO_SEARCH: [
    'SHARE_PHOTO',
    'ADD_PHOTOS_TO_ALBUM',
    'HIDE_PHOTO',
    'DELETE_PHOTOS',
    'DOWNLOAD_PHOTOS',
  ] as const,
  /**
   * Carousel contexts
   */
  SHARED_GROUP_OF_PHOTOS_VIEW_PHOTO: ['SHOW_INFO', 'DOWNLOAD_PHOTO'],
  ALBUM_VIEW_LOCATION_ALBUM_VIEW_PHOTO: [
    'SHARE_PHOTO',
    'ADD_PHOTOS_TO_ALBUM',
    'SHOW_INFO',
    'HIDE_PHOTO',
    'DELETE_PHOTOS',
    'DOWNLOAD_PHOTO',
  ] as const,
  ALBUM_VIEW_PHOTO: [
    'ADD_COMMENT',
    'SHARE_PHOTO',
    'ADD_PHOTOS_TO_ALBUM',
    'SHOW_INFO',
    'REMOVE_PHOTOS_FROM_ALBUM',
    'DOWNLOAD_PHOTO',
  ] as const,
  SHARED_ALBUM_VIEW_PHOTO: [
    'ADD_COMMENT',
    'SHOW_INFO',
    'DOWNLOAD_PHOTO',
  ] as const,
  SMART_SEARCH_VIEW_PHOTO: [
    'FIND_SIMILAR',
    'ADD_COMMENT',
    'SHARE_PHOTO',
    'ADD_PHOTOS_TO_ALBUM',
    'SHOW_INFO',
    'HIDE_PHOTO',
    'DELETE_PHOTOS',
    'DOWNLOAD_PHOTO',
  ] as const,
  TIMELINE_PHOTOS_VIEW_PHOTO: [
    'FIND_SIMILAR',
    'ADD_COMMENT',
    'SHARE_PHOTO',
    'ADD_PHOTOS_TO_ALBUM',
    'SHOW_INFO',
    'HIDE_PHOTO',
    'DELETE_PHOTOS',
    'DOWNLOAD_PHOTO',
  ] as const,
  TIMELINE_HIDDEN_PHOTOS_VIEW_PHOTO: [
    'SHOW_INFO',
    'UNHIDE_PHOTO',
    'DELETE_PHOTOS',
  ] as const,
  UPLOADS_COMPLETE_DIALOG: [
    'SHOW_INFO',
    'SHARE_PHOTO',
    'ADD_PHOTOS_TO_ALBUM',
  ] as const,
} as const)
export type PhotoActionsByContext = typeof photoActionsByContext

/**
 * valid contexts for the specified PhotoActionType
 */
export type PhotoActionTypeContext<A extends PhotoActionType> = {
  [Ctx in PhotoActionContextType]: A extends PhotoActionsByContext[Ctx]
    ? Ctx
    : never
}[PhotoActionContextType]

export function isPhotoActionTypeContext<A extends PhotoActionType>(
  actionType: A,
  value: unknown,
): value is PhotoActionTypeContext<A> {
  return (
    isPhotoActionContextType(value) &&
    (photoActionsByContext[value] as readonly PhotoActionType[]).includes(
      actionType,
    )
  )
}
