import { useBrandStore } from './useBrandTheme'
import { useEffect } from 'react'

export function useModuleNav(hidden: boolean = true) {
  const branding = useBrandStore()

  useEffect(() => {
    if (!hidden) {
      return
    }

    const moduleNav = branding.activeElements.moduleNav
    branding.activateElements({ moduleNav: false })

    return () => {
      branding.activateElements({ moduleNav })
    }
  }, [hidden])
}

export function useHideModuleNavOnMobile(hidden: boolean = true) {
  const branding = useBrandStore()
  const isMobile = branding.currentBreakpoint <= 2

  useModuleNav(hidden && isMobile)
}
