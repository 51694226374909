import { Button } from '@jotta/ui/Button'
import { DefinitionListFromObject } from '@jotta/ui/DefinitionList'
import { Flex } from '@jotta/ui/Flex'
import { Stack } from '@jotta/ui/Stack'
import {
  declineShareInvite,
  joinShare,
} from '@jotta/grpc-js-client/sharingService'
import { queryClient } from '@jotta/query'
import { createLoaderRoute } from '@jotta/router'
import { ErrorElement } from '@jotta/ui/ErrorElement'
import { FormattedContent } from '@jotta/ui/FormattedContent'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import { Trans } from '@lingui/macro'
import Debug from 'debug'
import {
  Form,
  redirect,
  useNavigation,
  type LoaderFunctionArgs,
} from 'react-router-dom'
import { apiPathToRoute } from '../FileRoute/utils/apiPathToRoute'
import {
  fetchPendingInvites,
  usePendingInvitesQuery,
} from '../PathItem/hooks/usePendingInvites'
const debug = Debug('jotta:files:FolderInviteRoute')

export const {
  route: acceptFolderInviteRoute,
  useAcceptInviteLoaderData,
  useAcceptInviteActionData,
} = createLoaderRoute({
  id: 'acceptInvite',
  path: 'folderInvite/:inviteKey',
  errorElement: (
    <ErrorElement
      captureContext={scope => scope.setTag('key', 'acceptFolderInvite')}
    />
  ),
  action: async ({ request, params }) => {
    const formData = await request.formData()
    const intent = formData.get('intent')
    const inviteKey = String(params.inviteKey)
    debug('Action %s', intent)
    switch (intent) {
      case 'accept': {
        const res = await joinShare({ inviteKey })

        queryClient.invalidateQueries({
          queryKey: usePendingInvitesQuery.getKey(),
        })
        return redirect(
          apiPathToRoute(res?.pathItem?.path || '/sync', { context: 'sync' }),
        )
      }
      case 'decline': {
        await declineShareInvite({ inviteKey })
        queryClient.invalidateQueries({
          queryKey: usePendingInvitesQuery.getKey(),
        })
        return redirect('/web/sync/list/name')
      }
      default:
        return {
          status: 'Unknown intent',
          ok: false,
          inviteKey,
        }
    }
  },
  loader: async ({ params }: LoaderFunctionArgs) => {
    const inviteKey = params.inviteKey
    if (!inviteKey) {
      throw new Response('', {
        status: 404,
        statusText: 'Not Found',
      })
    }
    const invites = await fetchPendingInvites()
    const invite = invites.find(inv => inv.inviteKey === inviteKey)
    if (!invite) {
      throw new Response('', {
        status: 404,
        statusText: 'Not Found',
      })
    }
    return invite
  },
  element: <FolderInviteRoute />,
})
export const { route: autoAcceptFolderInviteRoute } = createLoaderRoute({
  id: 'autoAcceptInvite',
  path: 'acceptInvite/:inviteKey',
  errorElement: (
    <ErrorElement
      captureContext={scope => scope.setTag('key', 'autoAcceptFolderInvite')}
    />
  ),
  loader: async ({ params }: LoaderFunctionArgs) => {
    const inviteKey = params.inviteKey
    if (!inviteKey) {
      throw new Response('', {
        status: 404,
        statusText: 'Not Found',
      })
    }
    const res = await joinShare({ inviteKey })

    return redirect(
      apiPathToRoute(res?.pathItem?.path || '/sync', { context: 'sync' }),
    )
  },
  element: <LoadingOverlay open />,
})

export function FolderInviteRoute() {
  const navigation = useNavigation()
  const invite = useAcceptInviteLoaderData()
  const user = invite.owner?.name || 'unknown'
  const actionData = useAcceptInviteActionData()

  return (
    <Flex
      p="4"
      sx={{
        flex: '1 1 auto',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Stack
        gap={4}
        sx={{
          maxWidth: 400,
        }}
      >
        <LoadingOverlay open={navigation.state === 'submitting'} />
        <FormattedContent>
          <h1>
            <Trans>Accept the shared folder invitation from {user}</Trans>
          </h1>
          {actionData && <h2>{actionData.status}</h2>}
          <DefinitionListFromObject value={invite} />
          <p>
            <Trans>
              You are invited to {user}'s shared folder. Changes to the content
              of shared folders are automatically synced between all members of
              the shared folders.
            </Trans>
          </p>
        </FormattedContent>
        <Form method="post">
          <Stack horizontal gap={4}>
            <Button
              type="submit"
              name="intent"
              value="accept"
              variant="buttons.accent"
            >
              <Trans>Accept</Trans>
            </Button>
            <Button
              type="submit"
              name="intent"
              value="decline"
              variant="buttons.secondary"
            >
              <Trans>Decline</Trans>
            </Button>
          </Stack>
        </Form>
      </Stack>
    </Flex>
  )
}
