import { AppError, handleUnknownError } from '@jotta/types/AppError'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { JottaFooter } from '../../Layout/JottaFooter/JottaFooter'
import { PublicFooter } from '../../Layout/PublicFooter/PublicFooter'
import styles from './ErrorPage.module.scss'
import { ErrorPageError } from './ErrorPageError'
import { ErrorPageNotFound } from './ErrorPageNotFound'
import { useBrandCode } from '../../hooks/useBrandTheme'

const debug = Debug('jotta:ui:ErrorPage')

export interface ErrorPageProps {
  error?: unknown
  notFound?: boolean
  resetError?: () => void
  hideFooter?: boolean
}
export const ErrorPage = observer(function ErrorPage({
  error,
  notFound = false,
  resetError,
  hideFooter = false,
  ...props
}: ErrorPageProps) {
  const showJottaFooter = useBrandCode() === 'JOTTACLOUD'

  const appError = useMemo(() => {
    if (!error && notFound) {
      return new AppError({ HTTPStatus: 404 })
    }
    return handleUnknownError(error)
  }, [error, notFound])
  return (
    <div className={styles.container} {...props}>
      {appError.isNotFound ? (
        <ErrorPageNotFound resetError={resetError} />
      ) : (
        <ErrorPageError error={appError} resetError={resetError} />
      )}
      {!hideFooter && (showJottaFooter ? <JottaFooter /> : <PublicFooter />)}
    </div>
  )
})
