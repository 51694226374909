import { makeObservable, computed, action, observable, autorun } from 'mobx'
import { BaseActionManager } from './BaseActionManager'
import { FileUploadAction } from './FileUploadAction'
import { FileConflictQueue } from '../FileConflictDialog/FileConflictStore'

export class FileUploadManager extends BaseActionManager<FileUploadAction> {
  private _conflicts = new FileConflictQueue()

  public get conflicts() {
    return this._conflicts
  }

  private _open = false

  public get open() {
    return this._open
  }

  public set open(open: boolean) {
    this._open = open
    if (!open) {
      this.detailsViewOpen = false
    }
  }

  private _detailsViewOpen = false

  public get detailsViewOpen() {
    return this._detailsViewOpen
  }

  public set detailsViewOpen(open: boolean) {
    this._detailsViewOpen = open
  }

  public upload(file: File, path: string) {
    this.open = true
    return this.addAction(new FileUploadAction(file, path, this.conflicts))
  }

  public cancel() {
    this.clear()
    this.open = false
  }

  public clear() {
    super.clear()
    this._conflicts.clear()
  }

  public get hasPendingUploads() {
    return this.actions.some(upload => upload.isUploading)
  }

  public get hasPendingPreparations() {
    return this.actions.some(upload => upload.isPending)
  }

  constructor() {
    super()
    makeObservable<FileUploadManager, '_open' | '_detailsViewOpen'>(this, {
      _open: observable,
      _detailsViewOpen: observable,
      open: computed,
      detailsViewOpen: computed,
      upload: action.bound,
      cancel: action.bound,
      hasPendingUploads: computed,
      hasPendingPreparations: computed,
    })

    autorun(() => {
      if (this._conflicts.isCancelled) {
        this.cancel()
      }
    })

    autorun(() => {
      if (this.hasCompleted) {
        this.conflicts.clear()
      }
    })

    autorun(() => {
      if (this.total > 1) {
        this.conflicts.hasMultiple = true
      }
    })
  }
}

export const fileUploadManager = new FileUploadManager()
