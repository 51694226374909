import { Trans } from '@lingui/macro'
import Debug from 'debug'
import { Suspense } from 'react'
import { NavLink, Outlet } from 'react-router-dom'
export const debug = Debug('jotta:biz:BizModifyUserRoute')

export function BizModifyUserRoute() {
  return (
    <>
      <h2 className="h2 col-span-full">
        <Trans>Modify user</Trans>
      </h2>
      <div className="flex gap-4">
        <NavLink className="link py-4" to="tf">
          Tanstack Form
        </NavLink>
        <NavLink className="link py-4" to="rhf">
          React Hook Form
        </NavLink>
        <NavLink className="link py-4" to="jhf">
          Jan Hook Form
        </NavLink>
      </div>
      <Suspense fallback={<div className="loading loading-absolute"></div>}>
        <Outlet />
      </Suspense>
    </>
  )
}
