import type { RouteObject } from 'react-router-dom'
import {
  publicAlbumInfoLoader,
  publicAlbumInfoLoaderId,
} from '../components/Album/AlbumInfo/AlbumInfo.loaders'
import { ErrorElement } from '@jotta/ui/ErrorElement'
import { ErrorPage } from '@jotta/ui/ErrorPage'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import { addPhotosLoader } from '../api/addPhotos.loader'
import { followAlbumLoader } from '../api/followAlbum.loader'
import { PublicAlbumRoute } from '../components/Album/Album/PublicAlbum.route'
import { PhotoLayout } from '../components/PhotoLayout/PhotoLayout'

export const SharedPhotosRoute: RouteObject = {
  id: publicAlbumInfoLoaderId,
  path: 'share',
  loader: publicAlbumInfoLoader,
  errorElement: (
    <ErrorElement
      captureContext={scope => scope.setTag('key', 'sharedphotosroute')}
    />
  ),
  children: [
    {
      index: true,
      element: <ErrorPage notFound />,
    },
    {
      id: 'photosShare',
      path: ':shareId',
      element: <PhotoLayout isPublic />,
      children: [
        {
          id: 'photosShareMediaId',
          path: '',
          element: <PublicAlbumRoute />,
          children: [
            {
              path: ':mediaId',
            },
          ],
        },
      ],
    },
    {
      id: 'photosShareActionAddPhotos',
      path: ':shareId/addphotos',
      loader: addPhotosLoader,
      element: <LoadingOverlay open />,
    },
    {
      id: 'photosShareActionFollowAlbum',
      path: ':shareId/follow',
      loader: followAlbumLoader,
      element: <LoadingOverlay open />,
    },
  ],
}
