import { t } from '@lingui/macro'
import type { Challenges } from '@jotta/grpc-web/no/jotta/openapi/credentials_pb'
import {
  Challenge,
  Credentials,
} from '@jotta/grpc-web/no/jotta/openapi/credentials_pb'
import {
  DowngradeAccountRequest,
  PatchCustomerRequest,
  TerminateAccountRequest,
} from '@jotta/grpc-web/no/jotta/openapi/customer/customer.v2_pb'
import { DisableTFARequest } from '@jotta/grpc-web/no/jotta/openapi/auth/v2/auth.v2_pb'
import type {
  ChallengeableRequestParams,
  ChallengeResolver,
} from './ChallengeModel'
import { ChallengeStates, ResolveState } from './ChallengeModel'
import { authStore, paymentStore, settingsStore } from '../state/stores'
import { challengeStore } from '../state/stores'

export const generateChallenges = (
  challenges: Challenges.AsObject,
  { request, onResolve, onFail, additionalProps }: ChallengeableRequestParams,
): ChallengeResolver[] => {
  if (!request) {
    return []
  }
  const credentials = request.getCredentials()

  return challenges.challengesList?.map(challenge => {
    let hasError: string | undefined = undefined

    switch (challenge) {
      case Challenge.PASSWORD:
        hasError = credentials?.getPassword()
          ? t({ id: 'The password was wrong!' })
          : undefined
        break
      case Challenge.TOTP:
        hasError = credentials?.getTotpCode()
          ? t({
              id: 'Invalid authentication code, please make sure the code is typed in correctly.',
            })
          : undefined
        break
      case Challenge.SMS_OTP:
        hasError = credentials?.getSmsCode()
          ? t({
              id: 'Invalid authentication code, please make sure the code is typed in correctly.',
            })
          : undefined
        break
    }

    return {
      request,
      type: challenge,
      state: ChallengeStates.PENDING,
      errorMessage: hasError,
      onResolve,
      onFail,
      additionalProps,
    }
  })
}

export const updateRequestsCredentialsByChallenge = (
  challenge: ChallengeResolver,
  value: string,
) => {
  const credentials = challenge.request.getCredentials() || new Credentials()

  switch (challenge.type) {
    case Challenge.PASSWORD:
      credentials.setPassword(value)
      break
    case Challenge.SMS_OTP:
      credentials.setSmsCode(value)
      break
    case Challenge.TOTP:
      credentials.setTotpCode(value)
      break
  }

  challenge.request.setCredentials(credentials)
}

export const retryRequestCall = (challenge: ChallengeResolver) => {
  const { request, onResolve, onFail, additionalProps } = challenge
  const callParams = { request, onResolve, onFail, additionalProps }

  if (request instanceof PatchCustomerRequest) {
    return settingsStore.makePatchCustomerRequest(callParams)
  } else if (request instanceof TerminateAccountRequest) {
    return settingsStore.terminateAccount(callParams)
  } else if (request instanceof DisableTFARequest) {
    return authStore.disableTFA(callParams)
  } else if (request instanceof DowngradeAccountRequest) {
    return paymentStore.downgradeAccount(callParams)
  } else {
    return authStore.generateLoginToken(callParams)
  }
}

export const challengeCheck = (
  requestParams: ChallengeableRequestParams,
  challenges?: Challenges.AsObject,
): boolean => {
  if (challenges?.challengesList?.length) {
    challengeStore.loadChallenges(challenges, requestParams)
  }
  if (requestParams.onResolve) {
    requestParams.onResolve(
      challenges?.challengesList?.length
        ? ResolveState.CHALLENGED
        : ResolveState.SUCCESS,
    )
  }
  return !!challenges?.challengesList?.length
}
