import { TextThemeUI } from '@jotta/ui/themeui'
import { RadixDialog } from '@jotta/ui/RadixDialog'
import type { RadixModalProps } from '@jotta/ui/RadixModal'
import { RadixModal } from '@jotta/ui/RadixModal'
import { Trans, plural, t } from '@lingui/macro'

export interface HidePhotoDialogProps extends RadixModalProps {
  count?: number
  isLoading?: boolean
  onHidePhoto: () => void
}

export const HidePhotoDialog: React.FC<HidePhotoDialogProps> = ({
  count = 1,
  open = false,
  isLoading = false,
  onClose,
  onHidePhoto,
  ...props
}) => {
  return (
    <RadixModal open={open} onClose={onClose} {...props}>
      <RadixDialog
        title={t({
          id: 'HideButton.confirmTitle',
          message: plural(count, {
            one: 'Are you sure you want to hide the selected photo?',
            other: 'Are you sure you want to hide the # selected photos?',
          }),
        })}
        onClose={onClose}
        buttons={[
          {
            action: 'close',
            label: t`Cancel`,
          },
          {
            label: t({
              id: 'HideButton.confirmLabel',
              message: plural(count, {
                one: 'Yes, hide photo',
                other: 'Yes, hide photos',
              }),
            }),
            loading: isLoading,
            action: onHidePhoto,
            variant: 'buttons.error',
          },
        ]}
      >
        <TextThemeUI variant="styles.p" sx={{ mb: 3 }}>
          <Trans id="HideButton.confirmBody">
            You can restore photos from “Hidden photos” in your user menu.
          </Trans>
        </TextThemeUI>
      </RadixDialog>
    </RadixModal>
  )
}
