import type { IEnhancer } from 'mobx'
import { ObservableSet } from 'mobx'

/**
 * A MobX ObservableSet<string> that is case-insensitive.
 *
 * The values are stored in lowercase, and all operations transform values to
 * lowercase before performing the operation.
 */
export class ObservableCaseInsensitiveSet extends ObservableSet<string> {
  constructor(
    initialData: string[] = [],
    enhancer?: IEnhancer<string>,
    name?: string,
  ) {
    super(new Set(initialData.map(s => s.toLowerCase())), enhancer, name)
  }

  add(value: string) {
    return super.add(value.toLowerCase())
  }

  delete(value: string) {
    return super.delete(value.toLowerCase())
  }

  has(value: string) {
    return super.has(value.toLowerCase())
  }

  replace(
    other: ObservableSet<string> | ObservableCaseInsensitiveSet | string[],
  ) {
    return super.replace(new Set(Array.from(other).map(s => s.toLowerCase())))
  }
}
