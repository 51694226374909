import type { SyntheticEvent } from 'react'

export function isKeyOf<T>(obj: T, value: unknown): value is keyof T {
  return isString(value) && isObject(obj) && value in obj
}

/** Assert that value is element of array  */
export function isElementOfArray<T>(arr: T[], value: unknown): value is T {
  return arr.includes(value as T)
}
export function isString(value: unknown): value is string {
  return typeof value === 'string'
}
export function maybeString(value: unknown): string | undefined {
  if (isString(value) && value.length) {
    return value
  }
}
export function isNumber(value: unknown): value is number {
  return typeof value === 'number' && !isNaN(value) && value !== Infinity
}
export function ensureValidNumber(value: unknown, fallback?: number): number {
  if (isNumber(value)) {
    return value
  }
  const num = parseInt(String(value), 10)
  if (isNumber(num)) {
    return num
  }

  if (fallback !== undefined) {
    return fallback
  }
  throw new Error('Not a number')
}
export function isPositiveNumber(value: unknown): value is number {
  return isNumber(value) && value > 0
}

/**
 * Return value if it's a real number larger than 0, or a fallback number
 * @param value Value that might be a number
 * @param fallback Return this if value is not a positive number, defaults to 0
 * @returns {number}
 */
export function positiveNumberOr(value: unknown, fallback = 0) {
  if (isPositiveNumber(value)) {
    return value
  }
  return fallback
}

/**
 *
 * @param value Value to compare
 * @param gt Must be greater than this
 * @param fallback Return this if not
 * @returns {number}
 */
export function gtOr(value: number, gt = 0, fallback = 0) {
  if (value > gt) {
    return value
  }
  return fallback
}
export function maybeNumber(value: unknown): number | undefined {
  if (isNumber(value)) {
    return value
  }
  if (typeof value === 'string') {
    const num = parseInt(value, 10)
    return isNumber(num) ? num : undefined
  }
}
export function isUndefined(value: unknown): value is undefined {
  return typeof value === 'undefined'
}

export function entriesTyped<T extends {}>(arg: T) {
  return Object.entries(arg)
}

export function isHTMLOrSVGElement(value: unknown): value is HTMLOrSVGElement {
  return isHTMLElement(value) || isSVGElement(value)
}
export function isHTMLElement(value: unknown): value is HTMLElement {
  if (value instanceof HTMLElement) {
    return true
  }
  return false
}
export function isSVGElement(value: unknown): value is SVGElement {
  if (value instanceof SVGElement) {
    return true
  }
  return false
}
export function isHTMLInputElement(
  el?: Element | EventTarget,
): el is HTMLInputElement {
  if (el && (el as any).tagName === 'INPUT') {
    return true
  }
  return false
}

/**
 * Asserts that value is an object
 * This makes `key in` assertions safe
 */

export function isObject(value: unknown): value is Object {
  return !!value && !Array.isArray(value) && typeof value === 'object'
}

/**
 * Asserts that value is a SubmitEvent
 */
export function isSubmitEvent(value: unknown): value is SubmitEvent {
  return value instanceof SubmitEvent
}

/**
 * Asserts that value is an Event
 */
export function isEvent(value: unknown): value is Event {
  return value instanceof Event
}

/**
 * Asserts that value is a React event
 */
export function isReactEvent(value: unknown): value is SyntheticEvent {
  return (
    value instanceof Object &&
    '_reactName' in value &&
    Object.getPrototypeOf(value).constructor.name === 'SyntheticBaseEvent'
  )
}
