import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { t } from '@lingui/macro'
import { DialogTrigger } from '@radix-ui/react-dialog'
import { HTTPError } from 'ky'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { ErrorDialog } from '../Album/Dialogs/ErrorDialog'
import { RemovePhotoDialog } from '../Album/Dialogs/RemovePhotoDialog'
import type { PhotoActionHandlerActionProps } from './PhotoActionHandlers'
import { useDispatchPhotoActionMutation } from './useDispatchPhotoActionMutation'

export const ActionRemovePhotosFromAlbum = observer<
  PhotoActionHandlerActionProps<'REMOVE_PHOTOS_FROM_ALBUM'>
>(function ActionRemovePhotosFromAlbum({
  action,
  albumId = '',
  photoIds = [],
  dispatch,
  buttonVariant = 'buttons.albumActionButton',
  onActionDialogClose,
  disabled = false,
}) {
  const [open, setOpen] = useState(false)
  const [error, setError] = useState<{ title: string; body: string } | null>(
    null,
  )

  const mutation = useDispatchPhotoActionMutation(
    'REMOVE_PHOTOS_FROM_ALBUM',
    {
      onSettled: () => setOpen(false),
      onError: e => {
        if (e instanceof HTTPError && e.response.status === 403) {
          setError({
            title: t`RemoveFromAlbumButton.errorForbiddenTitle`,
            body: t`RemoveFromAlbumButton.errorForbiddenBody`,
          })
        } else {
          setError({
            title: t`Something went wrong`,
            body: t`Unknown error please try again later.`,
          })
        }
      },
    },
    dispatch,
  )

  return (
    <>
      <RemovePhotoDialog
        open={open}
        onClose={open => {
          setOpen(open)
          if (!open && onActionDialogClose) {
            onActionDialogClose(action.actionType)
          }
        }}
        onRemovePhoto={() =>
          !disabled && mutation.mutateAsync([albumId, photoIds])
        }
        isLoading={mutation.isPending}
        triggerElement={
          <DialogTrigger
            aria-label={action.label}
            data-testid={action.testid}
            aria-busy={mutation.isPending}
            disabled={disabled}
            key={action.actionType}
            sx={{
              variant: buttonVariant,
            }}
          >
            <PlainBrandIcon icon={action.icon} />
            <span>{action.label}</span>
          </DialogTrigger>
        }
      />
      <ErrorDialog
        open={Boolean(error)}
        onClose={open => {
          if (!open) {
            setError(null)
          }
        }}
        title={error?.title || ''}
        body={error?.body || ''}
      />
    </>
  )
})
