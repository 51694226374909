import type { BoxPropsAs } from '@jotta/ui/Box'
import { Box } from '@jotta/ui/Box'
import { BrandIcon } from '@jotta/ui/BrandIcon'
import { LinkThemeUI } from '@jotta/ui/themeui'
import type { BrandThemeIcon } from '@jotta/types/Brand'
import { Trans } from '@lingui/macro'
import { observer } from 'mobx-react-lite'
import { useBrandingInfo } from '@jotta/grpc-connect-client/config'

import { Headline2 } from '../../Typography/Headline/Headline'
import { Paragraph } from '../../Typography/Text/Text'

export interface DownloadSectionCliItem {
  icon: BrandThemeIcon
  downloadLink?: string
}
export interface DownloadSectionCliProps extends BoxPropsAs {
  sectionItems: DownloadSectionCliItem[]
  instructionPageLink?: string
  headerText: string
  subHeaderText: string
}

export const DownloadSectionCli = observer<DownloadSectionCliProps>(
  ({
    instructionPageLink,
    sectionItems,
    headerText,
    subHeaderText,
    sx,
    ...props
  }) => {
    const { cliInstallationInstructionsLink } = useBrandingInfo()

    return (
      <Box
        sx={{
          variant: 'layout.downloadSectionContainer1',
          ...sx,
        }}
        {...props}
      >
        <Headline2
          sx={{
            variant: 'styles.headerText',
          }}
        >
          {headerText}
        </Headline2>
        <Paragraph
          sx={{
            variant: 'styles.subHeaderText',
          }}
        >
          {subHeaderText}
        </Paragraph>

        <Box
          sx={{
            variant: 'layout.downloadSectionContainer2',
          }}
        >
          <Box>
            <Box
              sx={{
                flexDirection: ['column', 'row', 'row'],
                gap: [5, 4],
                display: 'flex',
              }}
            >
              {sectionItems.map(({ downloadLink, icon }, index) => (
                <Box
                  key={`${icon}-${index}`}
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    mt: 0,
                    mb: instructionPageLink ? 4 : 0,
                  }}
                >
                  <LinkThemeUI href={downloadLink} sx={{ cursor: 'pointer' }}>
                    <BrandIcon
                      icon={icon}
                      sx={{
                        height: '64px',
                        width: '140px',
                        margin: 0,
                        mb: '40px',
                        variant: 'styles.downloadSvg',
                      }}
                    />
                  </LinkThemeUI>
                </Box>
              ))}
            </Box>
            <LinkThemeUI
              variant="buttons.download"
              href={cliInstallationInstructionsLink}
            >
              <Trans>Install instructions</Trans>
            </LinkThemeUI>
          </Box>
        </Box>
      </Box>
    )
  },
)
