/* eslint-disable tailwindcss/no-custom-classname */
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'

import { useSelectionStore } from '../../store/PhotoContext'
import { PhotoCheckboxButton } from '../PhotoCheckbox/PhotoCheckbox'
import { SearchSimilarButton } from '../PhotoGallery/SearchSimilarButton'
import styles from './PhotoGallery.module.scss'
import { TouchOverlay } from './TouchOverlay'

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

export const CheckboxOverlay = observer<{
  id: string
  showSimilarSearch?: boolean
}>(function CheckboxOverlay({ id, showSimilarSearch = false }) {
  const selectionStore = useSelectionStore()
  const checked = selectionStore.selection.has(id)

  return (
    <>
      <div
        className={clsx({
          [styles.checkboxWrapper]: true,
          selected: checked,
        })}
        onClick={selectionStore.onClickToggleIfActive}
        data-testid="checkbox-wrapper"
        data-photoid={id}
      >
        <PhotoCheckboxButton
          onClick={selectionStore.onClickToggle}
          data-photoid={id}
          checked={checked}
          className="photo-checkbox"
        />
      </div>
      {showSimilarSearch && <SearchSimilarButton photoId={id} />}
      <TouchOverlay
        onLongPress={selectionStore.onClickToggle}
        onClick={selectionStore.onClickToggleIfActive}
        longPressDuration={400}
        id={id}
      />
    </>
  )
})
