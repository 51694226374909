import type { GridProps } from '@jotta/ui/Grid'

import { px } from '@jotta/utils/css'
import Debug from 'debug'
import type { Variants } from 'framer-motion'
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion'
import { autorun, runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import type { ReactNode } from 'react'
import { useEffect, useLayoutEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { clamp, range } from 'remeda'
import { AlertContainer } from '../../Elements/Alert/Alert'
import { useAlertListStore } from '../../Elements/Alert/Alert.store'
import { Hamburger } from '../Hamburger/Hamburger'
import { Onboarding } from '../Onboarding/Onboarding'
import styles from './AppLayout.module.scss'
import { useBrandStore } from '@jotta/ui/useBrandTheme'
import {
  setCSSCustomProperty,
  setElementCSSCustomProperties,
} from '@jotta/ui/setCSSCustomProperty'
import { Box } from '@jotta/ui/Box'
import { BrandIcon } from '@jotta/ui/BrandIcon'
import { BrandLogo } from '@jotta/ui/BrandLogo'
import { Stack } from '@jotta/ui/Stack'
import { getBrandZIndex } from '@jotta/ui/zIndex'

const debug = Debug('jotta:ui:AppLayout')
const breakpointClassNames = range(0, 4)
  .map(n => `br-${n}`)
  .map((br, i, brs) => ({
    classNamesToAdd: [br, ...brs.slice(0, i + 1).map(v => `${v}-up`)],
    classNamesToRemove: [
      ...brs.filter(v => v !== br),
      ...brs.slice(i + 1).map(v => `${v}-up`),
    ],
  }))
function setBreakpointClass(index: number) {
  const i = clamp(index, {
    min: 0,
    max: breakpointClassNames.length - 1,
  })
  const { classNamesToAdd, classNamesToRemove } = breakpointClassNames[i]
  document.body.classList.add(...classNamesToAdd)
  document.body.classList.remove(...classNamesToRemove)
}
export interface ApplayoutProps extends GridProps {
  setNavOpen?: (open: boolean) => void
  header?: ReactNode
  navbar?: ReactNode
  banner?: ReactNode
  moduleNav?: ReactNode
  children?: ReactNode
}
export const AppLayout = observer<ApplayoutProps>(function AppLayout({
  children,
  header = null,
  navbar,
  banner,
  moduleNav,
}) {
  const headerRef = useRef<HTMLElement | null>(null)
  const { pathname } = useLocation()
  const branding = useBrandStore()
  const { alert } = useAlertListStore()
  const { showHamburger, currentBreakpoint, viewportWidth } = branding

  useEffect(() => {
    if (currentBreakpoint === 0) {
      branding.closeDrawer()
    }
  }, [pathname, branding, currentBreakpoint])

  const showPoweredByJottaSvg = branding.brandCode === 'MEDIAMARKT'
  const alertListVariants: Variants = {
    open: {
      opacity: 1,
    },
    closed: {
      opacity: 0,
    },
  }
  useEffect(
    () =>
      autorun(() => {
        setCSSCustomProperty(
          '--top-toolbar-height',
          px(branding.topToolbarHeight),
        )
        setCSSCustomProperty(
          '--bottom-toolbar-height',
          px(branding.bottomToolbarHeight),
        )
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
  useEffect(
    () =>
      autorun(() => {
        if (branding.isMobile) {
          document.body.classList.add('is-small')
          document.body.classList.remove('is-large')
        } else {
          document.body.classList.remove('is-small')
          document.body.classList.add('is-large')
        }
        if (branding.isTouchDevice()) {
          document.body.classList.add('is-touch')
        } else {
          document.body.classList.remove('is-touch')
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
  useEffect(
    () =>
      autorun(() => {
        setBreakpointClass(branding.currentBreakpointIndex)
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
  useEffect(
    () =>
      autorun(() => {
        setElementCSSCustomProperties({
          '--sidebar-width': px(branding.sidebarWidth),
        })
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
  useEffect(
    () =>
      autorun(() => {
        setElementCSSCustomProperties({
          '--visible-scrollbar-width': px(branding.visibleScrollBarWidth),
        })
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
  useEffect(
    () =>
      autorun(() => {
        setElementCSSCustomProperties({
          '--scrubber-width': branding.hasScrubber
            ? 'var(--scrubber-active-width, 0px)'
            : '0px',
        })
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
  useLayoutEffect(() => {
    runInAction(() => {
      branding.headerHeight = headerRef.current!.getBoundingClientRect().height
    })
  }, [banner, branding, viewportWidth])

  return (
    <>
      <header
        ref={headerRef}
        className="fixed left-0 top-0 flex flex-col"
        sx={{
          variant: 'layout.appHeader',
        }}
      >
        {banner}
        <div className="relative flex min-h-header items-center">
          {showHamburger && (
            <Onboarding task="navMenu" asChild>
              <Hamburger
                isOpen={branding.isOpen}
                onClick={branding.toggleDrawer}
                sx={{
                  variant: 'layout.appHeaderHamburger',
                }}
              />
            </Onboarding>
          )}
          {branding.showBrandLogo && (
            <a href="/" className={styles.logo}>
              <BrandLogo icon="SvgLogoHeader" />
            </a>
          )}
          <Box variant="layout.appHeaderContent">{header}</Box>
        </div>
      </header>

      <LayoutGroup>
        {!!navbar && (
          <Box as="nav" data-open={branding.isOpen} variant="layout.appNavbar">
            {navbar}
            {showPoweredByJottaSvg && branding.isOpen && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <BrandIcon
                  icon="SvgPoweredbyjotta"
                  variant="images.poweredByJotta"
                />
              </Box>
            )}
          </Box>
        )}
        {branding.hasTopModuleNav && (
          <div className={styles.toolbarTopModulenav}>
            <Box variant="layout.appTopToolbar" data-testid="status-top">
              {moduleNav}
            </Box>
          </div>
        )}
        <main className={navbar ? styles.mainDrawer : styles.main}>
          <AnimatePresence>
            {!!(
              navbar &&
              branding.currentBreakpoint < 3 &&
              branding.isOpen
            ) && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                onClick={branding.closeDrawer}
                sx={{
                  variant: 'layout.appNavbarBg',
                  zIndex: getBrandZIndex('appNavbarBg'),
                }}
              />
            )}
          </AnimatePresence>
          {children}
        </main>
        <Stack gap="2" data-testid="AlertContainer" variant="layout.appAlerts">
          {alert.alerts.map(alert => (
            <motion.div
              layout
              key={alert.id}
              variants={alertListVariants}
              initial="closed"
              animate="open"
              exit="closed"
            >
              <AlertContainer alertStore={alert} />
            </motion.div>
          ))}
          <motion.div
            layout="position"
            variants={alertListVariants}
            initial="closed"
            animate="open"
            exit="closed"
            ref={branding.slots.uploadStatus}
          ></motion.div>
        </Stack>
        <motion.div
          layout="position"
          data-testid="AppLayoutButtons"
          transition={{ duration: 0 }}
          sx={{
            position: 'fixed',
            right:
              'calc(var(--content-right) + var(--removed-body-scroll-bar-size, 0px))',
            bottom: 'var(--content-bottom)',
            height: '60px',
            zIndex: getBrandZIndex('appMainBottomToolbar'),
            width: 'auto',
            display: 'flex',
            mr: 4,
            mb: 3,
            alignItems: 'flex-end',
            alignSelf: 'flex-end',
            gap: 3,
          }}
        >
          <motion.div
            data-testid="AppLayoutCommentsButton"
            layout="position"
            transition={{ duration: 0 }}
            ref={branding.slots.commentsButton}
          />
          <motion.div
            data-testid="AppLayoutButtonsFab"
            layout="position"
            ref={branding.slots.actionButton}
          />
        </motion.div>
        <div
          data-testid="AppLayoutScrubber"
          sx={{
            position: 'fixed',
            right: 0,
            top: 'var(--content-top)',
            bottom: 'var(--content-bottom)',
            width: 'var(--scrubber-width)',
            zIndex: getBrandZIndex('timelineScrubber'),
          }}
          ref={branding.slots.timelineScrubber}
        />

        <motion.div
          layout="position"
          data-has-toolbar={branding.hasBottomToolbar}
          data-testid="AppLayoutBottomToolbar"
          transition={{ bounce: false, duration: 0.2 }}
          animate={{
            y: branding.hasBottomToolbar ? '0%' : '100%',
            opacity: branding.hasBottomToolbar ? 1 : 0,
          }}
          className={styles.toolbarBottom}
        >
          <div
            data-slot="bottomstatus"
            className={styles.toolbarBottomSlot}
            ref={branding.slots.bottomStatusBar}
            data-testid="bottomStatusBar"
          />
          {branding.hasBottomModuleNav && (
            <div
              className={styles.toolbarBottomModulenav}
              data-testid="AppLayoutModuleNavBottom"
              sx={{
                display: ['flex', 'flex', 'none'],
              }}
            >
              <Box variant="layout.appBottomToolbar" data-testid="status-top">
                {moduleNav}
              </Box>
            </div>
          )}
        </motion.div>
      </LayoutGroup>
    </>
  )
})
