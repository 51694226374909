import { useGetCustomer } from '@jotta/grpc-connect-client/customer'
import { useFolderInvite } from '@jotta/grpc-connect-client/invite'
import type { Customer } from '@jotta/grpc-connect-openapi/esm/openapi/customer/customer.v2_pb'
import type {
  GetFolderShareInviteResponse,
  GetFolderShareInviteResponse_Invite,
} from '@jotta/grpc-connect-openapi/esm/openapi/invite/v1/invite.v1_pb'
import { AppLayoutSignup } from '@jotta/ui/AppLayoutSignup'
import { BaseErrorPage } from '@jotta/ui/BaseErrorPage'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import { exhaustiveGuard } from '@jotta/utils/exhaustive'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CreateUser } from '../../auth/CreateUser'
import { useJoinShare } from '@jotta/grpc-connect-client/sharing'
import { Card } from '@jotta/ui/Card'
import { Trans, t } from '@lingui/macro'
import { apiPathToRoute } from '@jotta/files'
import { useFeatures } from '@jotta/grpc-connect-client/config'

function FolderInviteAccept({ inviteKey }: { inviteKey: string }) {
  const { mutate } = useJoinShare()
  const navigate = useNavigate()

  useEffect(() => {
    mutate(
      { inviteKey },
      {
        onSuccess: res => {
          navigate(apiPathToRoute(res?.shareInfo?.pathItem?.path || '/sync'))
        },
      },
    )
  }, [mutate, inviteKey, navigate])

  return <LoadingOverlay open />
}

function FolderInviteLogin({
  invite,
}: {
  invite: GetFolderShareInviteResponse_Invite
}) {
  const { inviteeEmail: email } = invite
  const [showSignup, setShowSignup] = useState(false)
  const user = invite.inviterName
  const { signup: canSignup } = useFeatures()

  return (
    <div>
      <h1 className="!mb-2 !mt-5">
        {t`Sign in to accept the shared folder invitation from ${user}`}
      </h1>
      <div className="mx-auto w-[455px]">
        <div className="mt-6 rounded bg-white p-6">
          <>
            {canSignup && (
              <div className="flex w-full justify-end">
                <button
                  onClick={() => setShowSignup(!showSignup)}
                  className="pb-4 !text-signup-link underline"
                >
                  {showSignup ? (
                    <Trans>Already have an account?</Trans>
                  ) : (
                    <Trans>Create a new account first?</Trans>
                  )}
                </button>
              </div>
            )}
            <Card variant="cards.message">
              <p>
                {t`You are invited to ${user}'s shared folder. Changes to the content of shared folders are automatically synced between all members of the shared folders.`}
              </p>
              {showSignup && (
                <p className="pt-3">
                  <Trans>
                    This is completely free, and you get 5 GB storage.
                  </Trans>
                </p>
              )}
            </Card>
            {showSignup ? (
              <CreateUser
                type="private"
                className="mt-4 block"
                prefill={{ email }}
                federationIntent={{ case: 'signup', value: {} }}
                keepRouteOnSuccess
              />
            ) : (
              <div className="flex justify-center p-6 pb-0">
                <a
                  href="/api/login"
                  className="!signup-cta-button mr-4 !min-w-44 rounded-sm border-none px-6 py-2 text-center"
                >
                  <Trans>Sign in</Trans>
                </a>
              </div>
            )}
          </>
        </div>
      </div>
    </div>
  )
}

export function FolderInviteSwitch({
  response,
  customer,
  inviteKey,
}: {
  response: GetFolderShareInviteResponse
  customer?: Customer
  inviteKey: string
}) {
  const { result } = response

  switch (result.case) {
    case 'accepted':
      return (
        <BaseErrorPage title={t`The invitation has already been used`}>
          <p>
            <Trans>
              If you already accepted this invitation,{' '}
              <a href="/api/login">log in</a> to see the folder, if not contact
              the one who sent you this invitation and ask if he/she can send a
              new one.
            </Trans>
          </p>
          <a href="/">
            <Trans>Click here to go back to the homepage.</Trans>
          </a>
        </BaseErrorPage>
      )

    case 'notFound':
      return (
        <BaseErrorPage title={t`Invitation not found`}>
          <p>
            <Trans>
              Please check the email with the invitation, and try to click on
              the link again.
            </Trans>
          </p>
          <p>
            <a href="/">
              <Trans>Click here to go back to the homepage.</Trans>
            </a>
          </p>
        </BaseErrorPage>
      )

    case 'invite':
      if (customer) {
        return <FolderInviteAccept inviteKey={inviteKey} />
      }
      return <FolderInviteLogin invite={result.value} />

    case undefined:
      throw new Error('Invalid response')

    default:
      return exhaustiveGuard(result)
  }
}

export function FolderInviteRoute() {
  const { inviteCode: inviteKey = '' } = useParams()
  const { data: inviteData } = useFolderInvite(inviteKey)
  const { data: customerData, isLoading: isCustomerLoading } = useGetCustomer()

  if (!inviteData || isCustomerLoading) {
    return <LoadingOverlay open />
  }

  return (
    <AppLayoutSignup>
      <FolderInviteSwitch
        inviteKey={inviteKey}
        response={inviteData}
        customer={customerData?.customer}
      />
    </AppLayoutSignup>
  )
}
