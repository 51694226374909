import { Product } from '@jotta/grpc-web/no/jotta/internal/order_pb'
import type { Money } from '@jotta/grpc-web/no/jotta/openapi/subscription/payment.v2_pb'
import {
  Currency,
  SubscriptionInterval,
} from '@jotta/grpc-web/no/jotta/openapi/subscription/payment.v2_pb'

export type ProductScopeKey = keyof typeof Product.Scope
export const productScopeKeys: ProductScopeKey[] = Object.keys(
  Product.Scope,
) as ProductScopeKey[]

export const nameOf = (productScope: Product.Scope): ProductScopeKey => {
  return productScopeKeys[productScope]
}

export const valueOf = (language: ProductScopeKey): Product.Scope => {
  return Product.Scope[language]
}

// @ts-ignore
export const productScopeValues: Product.Scope[] = productScopeKeys.map(k =>
  valueOf(k),
)

export type CurrencyKey = keyof typeof Currency
export const currencyKeys: CurrencyKey[] = Object.keys(
  Currency,
) as CurrencyKey[]

export const nameOfCurrency = (c: Currency): CurrencyKey => {
  return currencyKeys[c]
}

export const valueOfCurrency = (c: CurrencyKey): Currency => {
  return Currency[c]
}

// @ts-ignore
export const currencyValues: Currency[] = currencyKeys.map(k =>
  valueOfCurrency(k),
)

export type IntervalKey = keyof typeof SubscriptionInterval
export const intervalKeys: IntervalKey[] = Object.keys(
  SubscriptionInterval,
) as IntervalKey[]

export const nameOfInterval = (c: SubscriptionInterval): IntervalKey => {
  return intervalKeys[c]
}

export const valueOfInterval = (c: IntervalKey): SubscriptionInterval => {
  return SubscriptionInterval[c]
}

// @ts-ignore
export const intervalValues: SubscriptionInterval[] = intervalKeys.map(k =>
  valueOfInterval(k),
)

export const formatPrice = ({ amount, currency }: Money.AsObject) => {
  return formatCurrency(amount, currency)
}

// See guidelines for price formatting:
// https://polaris.shopify.com/foundations/formatting-localized-currency
export function formatCurrency(amount_cents: number, currency: Currency) {
  const positiveAmount = Math.abs(amount_cents)
  const centsAmount = positiveAmount % 100
  const cents = centsAmount.toFixed().padStart(2, '0')
  const hundreds = Math.abs((positiveAmount - centsAmount) / 100.0).toFixed()
  const parsedPrice = `${hundreds}.${cents}`.replace('.00', '')
  if (amount_cents < 0) {
    switch (currency) {
      case Currency.EUR:
        return `-${parsedPrice} €`
      case Currency.USD:
        return `-$${parsedPrice}`
      case Currency.DKK:
      case Currency.NOK:
      case Currency.SEK:
        return `-${parsedPrice} kr`
    }
  } else {
    switch (currency) {
      case Currency.EUR:
        return `${parsedPrice} €`
      case Currency.USD:
        return `$${parsedPrice}`
      case Currency.DKK:
      case Currency.NOK:
      case Currency.SEK:
        return `${parsedPrice} kr`
    }
  }
}

// See guidelines for price formatting:
// https://polaris.shopify.com/foundations/formatting-localized-currency
export function formatProductPrice(amount_cents: number) {
  const positiveAmount = Math.abs(amount_cents)
  const centsAmount = positiveAmount % 100
  const cents = centsAmount.toFixed().padStart(2, '0')
  const hundreds = Math.abs((positiveAmount - centsAmount) / 100.0).toFixed()
  //const parsedPrice = `${hundreds}.${cents}`.replace('.00', '')
  const parsedPrice = `${hundreds}.${cents}`

  if (amount_cents < 0) {
    return `-${parsedPrice}`
  } else {
    return parsedPrice
  }
}
