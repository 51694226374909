import { Button } from '@jotta/ui/Button'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import type { PhotoActionHandlerActionProps } from './PhotoActionHandlers'

export const ActionFindSimilar = observer<
  PhotoActionHandlerActionProps<'FIND_SIMILAR'>
>(function ActionFindSimilar({ action, currentMediaId, buttonVariant }) {
  const navigate = useNavigate()
  return (
    <Button
      variant={buttonVariant}
      aria-label={action.label}
      data-testid={action.testid}
      key={action.actionType}
      icon={action.icon}
      onClick={() => navigate(`/photo?id=${currentMediaId}`)}
    >
      {action.label}
    </Button>
  )
})
