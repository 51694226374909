import { t } from '@lingui/macro'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import type { AlbumInfoStore } from '../../../store/AlbumInfoStore'
import styles from './AlbumInfo.module.scss'

const debug = Debug('jotta:photos:AlbumTitle')

export const AlbumTitle = observer<{
  store: AlbumInfoStore
}>(function AlbumTitle({ store }) {
  if (!store.data.isOwner) {
    return <h1 className={styles.title}>{store.title}</h1>
  }
  return (
    <h1 data-editable className={styles.title}>
      <input
        tabIndex={0}
        onKeyUp={e => {
          debug('keyup', e)
          if (e.key === 'Escape') {
            store.onTitleCancelEdit()
            e.currentTarget.blur()
          }
          if (e.key === 'Enter') {
            e.currentTarget.blur()
          }
        }}
        onBlur={e => store.onTitleSubmit()}
        name="title"
        value={store.title}
        onChange={store.onTitleChange}
        placeholder={t`No Title`}
      />
    </h1>
  )
})
