import type {
  FileRouteContext,
  FileRouteSortBy,
  FileRouteViewMode,
} from '@jotta/types/schemas/FileRouteSchema'
import { uriEncodeRoutePath } from '@jotta/utils/pathUtils'
import type { LinkProps, NavLinkProps } from 'react-router-dom'
import { Link, NavLink } from 'react-router-dom'
import { apiPathToRoute } from '../FileRoute/utils/apiPathToRoute'
import { useFileRouteParams } from '../FileRoute/utils/getFileRouteParams'

export interface FileApiPathLinkOwnProps {
  path: string
  context?: FileRouteContext
  viewMode?: FileRouteViewMode
  sortBy?: FileRouteSortBy
}
export type FileApiPathLinkProps<
  T extends NavLinkProps | LinkProps = LinkProps,
> = FileApiPathLinkOwnProps & Omit<T, 'to'>

/**
 * Link to an api path from backend, preserves the current route context
 * (context, viewmode and sortby) unless you specifically override with your own values
 * Extends the React Router Link component, and all of its props are valid except "to"
 * @param props
 * @returns
 */
export function FileApiPathLink(props: FileApiPathLinkProps) {
  const { to, linkProps } = useFileApiPathLinkProps('FileApiPathLink', props)
  return <Link to={to} {...linkProps} />
}

/**
 * Link to an api path from backend, preserves the current route context
 * (context, viewmode and sortby) unless you specifically override with your own values
 * Extends the React Router NavLink component, and all of its props are valid except
 * "to"
 * @param props
 * @returns
 */
export function FileApiPathNavLink(props: FileApiPathLinkProps<NavLinkProps>) {
  const { to, linkProps } = useFileApiPathLinkProps<NavLinkProps>(
    'FileApiPathLink',
    props,
  )
  return <NavLink to={to} {...linkProps} />
}

function useFileApiPathLinkProps<
  T extends NavLinkProps | LinkProps = LinkProps,
>(
  name: string,
  {
    path,
    context: contextProp,
    viewMode: viewModeProp,
    sortBy: sortByProp,
    ...props
  }: FileApiPathLinkProps<T>,
) {
  // const debug = Debug(`jotta:files:${name}`)
  const params = useFileRouteParams()
  const context = contextProp || params.context
  const sortBy = sortByProp || params.sortBy
  const viewMode = viewModeProp || params.viewMode

  const route = apiPathToRoute(path, {
    context,
    sortBy,
    viewMode,
  })
  const to = context === 'search' ? route : uriEncodeRoutePath(route)
  // debug('%s to: "%s"', path, to)
  return {
    to,
    linkProps: props,
  }
}
