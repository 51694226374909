import { getFilenameFromPath, makeAbsolute } from '@jotta/utils/pathUtils'
import { t } from '@lingui/macro'

/**
 * Get translated folder names for top level paths
 * Returns the file name portion of the path if no translation is found
 * @param path Grpc file api path
 * @returns
 */
export function getTranslatedFilenameFromApiPath(
  path: string,
  fallback = getFilenameFromPath(path),
) {
  switch (makeAbsolute(path).toLocaleLowerCase()) {
    case '/archive':
      return t`Archive`
    case '/backup':
      return t`Backed up`
    case '/contacts':
      return t`Contacts`
    case '/photos':
      return 'Photo Timeline Uploads'
    case '/shared':
      return t`Shared`
    case '/sync':
      return t`Synced`
    case '/trash':
      return t`Trash`
    default:
      return fallback
  }
}
