import { Mutex } from '@jotta/utils/mutex'
import { action, makeAutoObservable, observable, runInAction } from 'mobx'

const fileConflicts = [
  'FILE_ONTO_FILE',
  'FOLDER_ONTO_FOLDER',
  'FILE_ONTO_FOLDER',
  'FOLDER_ONTO_FILE',
] as const

export type FileConflictType = (typeof fileConflicts)[number]

export function isFileConflict(
  maybeConflict: string,
): maybeConflict is FileConflictType {
  return fileConflicts.includes(maybeConflict as FileConflictType)
}

export type FileActionType = 'COPY' | 'MOVE' | 'UPLOAD'
export interface ConflictResponse {
  action: 'cancel' | 'overwrite' | 'rename' | 'skip'
  forAll: boolean
}

export interface ConflictRequest {
  readonly filename: string
  readonly conflictType: FileConflictType
  readonly actionType: FileActionType
  readonly hasMultiple: boolean
  readonly resolve: (action: ConflictResponse) => void
}

export class FileConflictStore {
  private lock = new Mutex()
  private _current: ConflictRequest | null = null

  public get current() {
    return this._current
  }

  public async setCurrent(request: ConflictRequest) {
    await this.lock.acquire()

    runInAction(() => {
      this._current = request
    })

    return () =>
      runInAction(() => {
        this._current = null
        this.lock.release()
      })
  }

  public constructor() {
    makeAutoObservable<typeof this, '_current'>(this, { _current: observable })
  }
}

export const currentFileConflict = new FileConflictStore()

export class FileConflictQueue {
  private _requests: ConflictRequest[] = []
  private clearCurrent: (() => void) | null = null
  private autoResponse = observable.map<
    FileConflictType,
    Exclude<ConflictResponse['action'], 'cancel'>
  >([])
  private _isCancelled = false
  private clearOnDone = false
  private activePromises = 0

  public constructor() {
    makeAutoObservable<typeof this, '_isCancelled' | 'showNext'>(this, {
      _isCancelled: true,
      showNext: action,
    })
  }

  public get isCancelled() {
    return this._isCancelled
  }

  public clear() {
    if (this.activePromises) {
      this.clearOnDone = true
    } else {
      this.autoResponse.clear()
      this._isCancelled = false
      this.clearOnDone = false
      this._hasMultiple = false
    }
  }

  private async showNext() {
    while (this._requests.length) {
      if (this.clearCurrent) {
        return
      }

      const req = this._requests[0]
      this._requests.shift()
      const autoResponse = this.autoResponse.get(req.conflictType)

      if (!this.isCancelled && !autoResponse) {
        const clearCurrent = await currentFileConflict.setCurrent(req)
        runInAction(() => {
          this.clearCurrent = clearCurrent
        })
        return
      }

      req.resolve({
        action: autoResponse || 'cancel',
        forAll: false,
      })
    }
  }

  private _hasMultiple = false

  public set hasMultiple(b: boolean) {
    this._hasMultiple = b
  }

  public get hasMultiple() {
    return this._hasMultiple
  }

  public async requestConflictAction(
    filename: string,
    conflictType: FileConflictType,
    actionType: FileActionType,
  ): Promise<ConflictResponse> {
    const p = new Promise<ConflictResponse>(resolve => {
      runInAction(() => {
        this.activePromises++
        this._requests.push({
          filename,
          conflictType,
          actionType,
          resolve,
          hasMultiple: this.hasMultiple,
        })
        this.showNext()
      })
    })

    p.then(response => {
      runInAction(() => {
        this.activePromises--

        if (response.action === 'cancel') {
          this._isCancelled = true
        } else if (response.forAll) {
          this.autoResponse.set(conflictType, response.action)
        }

        if (this.clearCurrent) {
          this.clearCurrent()
          this.clearCurrent = null
        }

        if (this._requests.length) {
          this.showNext()
        }

        if (!this.activePromises && this.clearOnDone) {
          this.clear()
        }
      })
    })

    return p
  }
}
