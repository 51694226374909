import type { FileActionContext } from '@jotta/types/FileActions'
import * as ContextMenu from '@radix-ui/react-context-menu'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import clsx from 'clsx'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import type { PointerEvent, PropsWithChildren } from 'react'
import { useCallback, useEffect, useState } from 'react'
import type {
  ActiveFileAction,
  ActiveFileActionType,
} from '../FileActions/fileActionConfig'
import styles from './FileContextMenu.module.scss'
import { FileContextMenuItem } from './FileContextMenuItem'
const debug = Debug('jotta:obos:FileContextMenu')

export interface FileContextMenuProps {
  actions?: readonly ActiveFileAction<ActiveFileActionType>[]
  path: string
  disabled?: boolean
  disableIcons?: boolean
  context: FileActionContext
  onSelect: (e: Event) => void
  defaultOpen?: boolean
  triggerClassName?: string
  triggerLabel?: string
  type?: 'DROPDOWN' | 'CONTEXT'
  asChild?: boolean
  triggerBehavior?: 'click-only' | 'no-touch-down' | 'default'
  numAffectedItems?: number
  className?: string
}

export const FileContextMenu = observer<
  PropsWithChildren<FileContextMenuProps>
>(function FileContextMenu({
  actions = [],
  disabled,
  disableIcons = false,
  triggerClassName = '',
  triggerLabel,
  onSelect,
  context,
  defaultOpen = false,
  children,
  path,
  type = 'DROPDOWN',
  asChild = false,
  triggerBehavior = 'default',
  numAffectedItems = 1,
  className,
  ...props
}) {
  const [open, setOpen] = useState(defaultOpen)

  useEffect(() => {
    if (!open) {
      return
    }

    document.body.classList.add('fab-open')
    return () => {
      document.body.classList.remove('fab-open')
    }
  }, [open])

  const handlePointerDown = useCallback(
    (e: PointerEvent) => {
      if (type === 'CONTEXT') {
        return
      }
      switch (triggerBehavior) {
        case 'click-only':
          e.preventDefault()
          break
        case 'no-touch-down':
          if (e.pointerType === 'touch') {
            e.preventDefault()
          }
          break
      }
    },
    [triggerBehavior, type],
  )

  const handleClick = useCallback(() => {
    if (type !== 'CONTEXT') {
      setOpen(true)
    }
  }, [type])

  const isFab = context === 'FAB'

  const Menu = type === 'DROPDOWN' ? DropdownMenu : ContextMenu
  const triggerClasses = clsx({
    [styles.fabTrigger]: isFab,
    [styles.trigger]: !isFab && type === 'DROPDOWN',
    [triggerClassName]: !!triggerClassName,
  })

  if (!actions.length) {
    return <>{children}</>
  }

  return (
    <Menu.Root open={open} onOpenChange={open => setOpen(open)}>
      <Menu.Trigger
        data-testid="FileContextMenuTrigger"
        aria-label={triggerLabel}
        className={triggerClasses}
        disabled={disabled}
        {...props}
        asChild={asChild}
        onPointerDown={handlePointerDown}
        onClick={handleClick}
      >
        {children}
      </Menu.Trigger>

      <Menu.Portal>
        <Menu.Content
          data-testid="FileContextMenu"
          align={isFab ? 'end' : 'center'}
          alignOffset={isFab ? -8 : 0}
          side={isFab ? 'top' : 'bottom'}
          sideOffset={isFab ? 16 : 0}
          collisionPadding={16}
          className={clsx(className, {
            [styles.fab]: isFab,
            [styles.content]: !isFab,
          })}
        >
          {!isFab && <Menu.Arrow className={styles.arrow} />}
          {actions.map((action, index) => (
            <FileContextMenuItem
              key={action.name}
              type={type}
              action={action}
              context={context}
              name={
                numAffectedItems > 1
                  ? action.pluralName(numAffectedItems)
                  : action.name
              }
              path={path}
              icon={action.icon}
              onSelect={onSelect}
              index={actions.length - index - 1}
            />
          ))}
        </Menu.Content>
      </Menu.Portal>
    </Menu.Root>
  )
})
