import { useQuery } from '@tanstack/react-query'
import { fetchPhotoById } from './photosApi'

export function usePhoto(
  id?: string,
  { enabled = true }: { enabled?: boolean } = {},
) {
  return useQuery({
    queryKey: ['photo', id] as const,
    queryFn: async () => fetchPhotoById(id || ''),
    enabled: Boolean(enabled && id),
  })
}
