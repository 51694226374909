import { useDelay } from '@jotta/hooks/useDelay'
import type { Photos } from '@jotta/types/Photos'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'

import { useLocation } from 'react-router-dom'
import { usePeople } from '../../../people/internal/api/PeopleApi'
import { PersonRow } from '../../../people/internal/components/PersonList/PersonRow'
import { AlbumSectionContainer } from '../AlbumsSection/AlbumSectionContainer'
import styles from './AlbumList.module.scss'

export interface AlbumListProps {
  showOnly?: Photos.DisplayCollectionTypeKey
}
export const AlbumList = observer<AlbumListProps>(function AlbumList({
  showOnly,
}) {
  const { pathname } = useLocation()
  const [{ people: persons }, peopleQuery] = usePeople(undefined, {
    enabled: !showOnly,
  })

  // Show spinner if people is still loading after 800ms
  const spinnerDelayedOpen = useDelay(800)
  const showLoadingSpinner = peopleQuery.isLoading && spinnerDelayedOpen
  return (
    <div
      className={clsx(styles.container, {
        [styles.loading]: peopleQuery.isLoading,
      })}
    >
      {!showOnly && (
        <LoadingOverlay open={showLoadingSpinner} position="absolute" />
      )}
      <div className={styles.content}>
        {showOnly ? (
          <AlbumSectionContainer
            albumType={showOnly}
            showAll
            backPath={pathname}
          />
        ) : (
          <>
            {!!persons?.length && (
              <PersonRow count={persons.length} people={persons} />
            )}
            <AlbumSectionContainer albumType="NORMAL_ALBUM" />
            <AlbumSectionContainer albumType="META_SHARED_ALBUM_TYPES" />
            <AlbumSectionContainer albumType="LOCATION_ALBUM" />
          </>
        )}
      </div>
    </div>
  )
})
