import { Language } from '@jotta/grpc-web/no/jotta/openapi/language_pb'
import { detect, fromNavigator } from '@lingui/detect-locale'
import { getLanguageCookie } from './languageCookie'
import { map, mapToObj } from 'remeda'

export const langs = [
  'da',
  'de',
  'en',
  'fi',
  'fr',
  'nb',
  'nl',
  'sv',
  'es',
  'ca',
  'pt',
  'pl',
] as const
export const languageMap: Record<string, Locale> = {
  no: 'nb',
  nn: 'nb',
  'nn-NO': 'nb',
  'nb-NO': 'nb',
}
export type Locale = (typeof langs)[number]
export type LocaleParam = Locale | 'no'

export type ValidLanguage = Exclude<Language, Language.UNKNOWN_LANGUAGE>

export const localeToLanguage: Record<Locale, ValidLanguage> = {
  en: Language.ENGLISH,
  nb: Language.NORWEGIAN,
  sv: Language.SWEDISH,
  da: Language.DANISH,
  fi: Language.FINNISH,
  fr: Language.FRENCH,
  de: Language.GERMAN,
  nl: Language.DUTCH,
  es: Language.SPANISH,
  ca: Language.CATALAN,
  pt: Language.PORTUGUESE,
  pl: Language.POLISH,
}
export const languageToLocale: Record<ValidLanguage, Locale> = {
  [Language.ENGLISH]: 'en',
  [Language.NORWEGIAN]: 'nb',
  [Language.SWEDISH]: 'sv',
  [Language.DANISH]: 'da',
  [Language.FINNISH]: 'fi',
  [Language.FRENCH]: 'fr',
  [Language.GERMAN]: 'de',
  [Language.DUTCH]: 'nl',
  [Language.SPANISH]: 'es',
  [Language.CATALAN]: 'ca',
  [Language.PORTUGUESE]: 'pt',
  [Language.POLISH]: 'pl',
}

export function isLocale(val: unknown): val is Locale {
  return Boolean(parseLocale(val))
}

export function parseLocale(val: unknown): Locale | undefined {
  if (typeof val === 'string') {
    if (languageMap[val]) {
      return languageMap[val]
    }

    if (val.length >= 2) {
      const lang = val.substr(0, 2)
      if (langs.includes(lang as Locale)) {
        return lang as Locale
      }
    }
  }
}
export const localeInfo: readonly {
  code: Locale
  name: string
  language: Language
  languageCode: string
}[] = [
  {
    code: 'en',
    languageCode: 'en-GB',
    name: 'English',
    language: Language.ENGLISH,
  },
  {
    code: 'nb',
    languageCode: 'nb-NO',
    name: 'Norsk',
    language: Language.NORWEGIAN,
  },
  {
    code: 'sv',
    languageCode: 'sv',
    name: 'Svenska',
    language: Language.SWEDISH,
  },
  {
    code: 'da',
    languageCode: 'da',
    name: 'Dansk',
    language: Language.DANISH,
  },
  {
    code: 'fi',
    languageCode: 'fi',
    name: 'Suomi',
    language: Language.FINNISH,
  },
  {
    code: 'de',
    languageCode: 'de',
    name: 'Deutsch',
    language: Language.GERMAN,
  },
  {
    code: 'fr',
    languageCode: 'fr',
    name: 'Français',
    language: Language.FRENCH,
  },
  {
    code: 'nl',
    languageCode: 'nl',
    name: 'Nederlands',
    language: Language.DUTCH,
  },
  {
    code: 'es',
    languageCode: 'es',
    name: 'Español',
    language: Language.SPANISH,
  },
  {
    code: 'ca',
    languageCode: 'ca',
    name: 'Català',
    language: Language.CATALAN,
  },
  {
    code: 'pt',
    languageCode: 'pt',
    name: 'Português',
    language: Language.PORTUGUESE,
  },
  {
    code: 'pl',
    languageCode: 'pl',
    name: 'Polski',
    language: Language.POLISH,
  },
] as const
export const localeInfoByCode = mapToObj(localeInfo, o => [o.code, o])
export const localeInfoByLanguage = mapToObj(localeInfo, o => [o.language, o])
export const locales = mapToObj(localeInfo, o => [o.code, o.name])
export const localesByLanguageSorted = map(
  localeInfo,
  o => [o.language, o.name] as const,
).sort((a, b) => a[1].localeCompare(b[1]))

export function getDetectedLocale() {
  return parseLocale(detect(getLanguageCookie(), fromNavigator())) || 'en'
}
