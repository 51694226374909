import type { PhotosApi } from '@jotta/types/PhotosApi'
import type { RadixModalProps } from '@jotta/ui/RadixModal'
import loadable from '@loadable/component'
import { AnimatePresence, motion } from 'framer-motion'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import {
  useAddComment,
  useAddReply,
  useDeleteComment,
  useInfiniteAggregatedComments,
  useUpdateComment,
} from '../../store/CommentQuery'
import { useOSKDodge } from './useOSKDodge'
const CommentSection = loadable(
  () => import(/* webpackPrefetch: true */ './CommentSection'),
)

import * as Dialog from '@radix-ui/react-dialog'

export interface CommentsDrawerProps extends RadixModalProps {
  album?: PhotosApi.Album
  open?: boolean
  onOpenChange: (open: boolean) => void
}

export const CommentsDrawer = observer<CommentsDrawerProps>(
  function CommentsDrawer({ album, open, onOpenChange }) {
    const addComment = useAddComment(album?.commentsGroupId)
    const deleteComment = useDeleteComment(album?.commentsGroupId)
    const updateComment = useUpdateComment(album?.commentsGroupId)
    const addReply = useAddReply(album?.commentsGroupId)
    const ref = useRef<HTMLDivElement>(null)
    const [scrollToReply, setScrollToReply] = useState('')
    const showDrawer = Boolean(open && album && album.shareInfo)

    useEffect(() => {
      if (addReply.data) {
        setScrollToReply(addReply.data.id)
      }
    }, [addReply.data])

    const {
      data: commentsResponse,
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage,
      isFetched,
    } = useInfiniteAggregatedComments(open ? album : undefined)

    const handleAddComment = (text: string) => {
      if (album && album.commentsGroupId) {
        addComment.mutate({ text, contextKey: album.commentsGroupId })
      }
    }

    const handleDeleteComment = (contextKey: string, commentId: string) => {
      deleteComment.mutate({ contextKey, commentId })
    }

    const handleEditComment = (
      contextKey: string,
      commentId: string,
      text: string,
    ) => {
      updateComment.mutate({ contextKey, commentId, text })
    }

    const handleReply = (
      contextKey: string,
      commentId: string,
      text: string,
    ) => {
      addReply.mutate({ contextKey, commentId, text })
    }

    const contentRef = useRef<HTMLDivElement>(null)
    useOSKDodge(contentRef.current)

    return (
      <Dialog.Root onOpenChange={onOpenChange} open={showDrawer}>
        <AnimatePresence>
          {showDrawer && (
            <Dialog.Portal forceMount>
              <Dialog.Content asChild>
                <motion.div
                  ref={contentRef}
                  initial="closed"
                  animate="open"
                  exit="closed"
                  transition={{ bounce: false, duration: 0.2 }}
                  sx={{ variant: 'dialogs.overlayCommentsDrawer' }}
                  variants={{
                    closed: { x: '100%', opacity: 0.2 },
                    open: { x: '0%', opacity: 1 },
                  }}
                >
                  <CommentSection
                    scrollToCommentId={scrollToReply}
                    ref={ref}
                    pages={commentsResponse?.pages}
                    onClose={() => onOpenChange(false)}
                    onAddComment={handleAddComment}
                    onDelete={handleDeleteComment}
                    onEdit={handleEditComment}
                    onReply={handleReply}
                    onFetchNextPage={() => fetchNextPage()}
                    hasNextPage={Boolean(hasNextPage)}
                    isFetchingNextPage={isFetchingNextPage || !isFetched}
                    album={album}
                  />
                </motion.div>
              </Dialog.Content>
            </Dialog.Portal>
          )}
        </AnimatePresence>
      </Dialog.Root>
    )
  },
)
