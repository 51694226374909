import type { BrandStore } from '@jotta/ui/BrandStore'
import type { GetPathItemResponse } from '@jotta/grpc-js-client/files/getPathItem'
import type { PendingInvite } from '@jotta/grpc-web/no/jotta/openapi/sharing/v2/sharing.v2_pb'
import type { RouterStore } from '@jotta/router'
import { createPath, popPath } from '@jotta/utils/pathUtils'
import Debug from 'debug'
import { computed, makeAutoObservable, observable, toJS } from 'mobx'
import { currentFileConflict } from '../FileConflictDialog/FileConflictStore'
import { parseFileRouteParams } from '../FileRoute/utils/parseFileRouteParams'
import { getFolderStore } from '../Folder/FolderStore'

export class FileStore {
  // #region Properties (3)

  private _currentItem: GetPathItemResponse

  public log
  public pendingInvites: PendingInvite.AsObject[] = []

  private _username: string = ''
  public get username() {
    return this._username
  }
  public set username(username: string) {
    this._username = username
  }

  // #endregion Properties (3)

  // #region Constructors (1)

  // public currentPathItem: GetPathItemResponse
  constructor(
    initialCurrentItem: GetPathItemResponse,
    public branding: BrandStore,
    public router: RouterStore,
  ) {
    this._currentItem = initialCurrentItem
    this.log = Debug('jotta:files:store')
    this.log('Create')
    makeAutoObservable<typeof this, '_currentItem'>(
      this,
      {
        _currentItem: observable.ref,
        folderApiPath: computed.struct,
        folderUniquePath: computed.struct,
        log: false,
      },
      {
        name: 'FileStore',
        autoBind: true,
      },
    )
    // makeLoggable(this)
  }

  // #endregion Constructors (1)

  // #region Public Accessors (10)

  public get context() {
    return this.route.context
  }

  public get currentFileConflictRequest() {
    return toJS(currentFileConflict.current)
  }

  public get currentItem(): GetPathItemResponse {
    return this._currentItem
  }

  public set currentItem(value: GetPathItemResponse) {
    this._currentItem = value
  }

  public get currentRouteIsFile() {
    return this._currentItem.isFile
  }

  public get folder() {
    this.log('get folder', this.folderUniquePath)
    return getFolderStore(this.folderUniquePath, this.folderApiPath, this, true)
  }

  public get folderApiPath() {
    return this.currentRouteIsFile
      ? popPath(this.currentItem.item.path)
      : this.currentItem.item.path
  }

  public get folderUniquePath() {
    return createPath(this.username, this.route.context, this.folderApiPath)
  }

  public get route() {
    return parseFileRouteParams(this.router.pathname, this.username)
  }

  // #endregion Public Accessors (10)
}
