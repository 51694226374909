import type { PartialMessage } from '@bufbuild/protobuf'
import { useQuery as useConnectQuery } from '@connectrpc/connect-query'
import {
  businessSignup as signupBusinessDescriptor,
  signup as signupDescriptor,
} from '@jotta/grpc-connect-openapi/signupQuery'
import { useGetConfig } from '@jotta/grpc-connect-client/config'
import { getCurrentCountry } from '@jotta/grpc-connect-openapi/geoQuery'
import type {
  SignupRequest_FormSignup,
  SignupResponse,
} from '@jotta/grpc-connect-openapi/signup'
import {
  BusinessSignupRequest,
  SignupRequest,
} from '@jotta/grpc-connect-openapi/signup'
import { generateSignupToken } from './signupForm'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import { autoLogin } from '@jotta/utils/auth'
import { exhaustiveGuard } from '@jotta/utils/exhaustive'
import { Trans } from '@lingui/macro'
import clsx from 'clsx'
import { useCallback, useEffect, useState } from 'react'
import { CreateBusinessUser } from './CreateBusinessUser'
import { CreatePrivateUser } from './CreatePrivateUser'
import { CreateVoucherUser } from './CreateVoucherUser'
import { useCurrentLanguage } from '@jotta/i18n'
import type { FederationIntent } from './Federation'
import { Federation } from './Federation'
import { Divider } from '@jotta/ui/Divider'
import { FederatedSignupConfirmation } from './FederatedSignupConfirmation'
import type { LoggedIn } from '@jotta/grpc-connect-openapi/login'
import { ModalDialog } from '@jotta/ui/ModalDialog'
import { ErrorMessage } from '@jotta/ui/ErrorMessage'
import { Feature } from '@jotta/grpc-connect-openapi/feature'
import { useMutation } from '@tanstack/react-query'
import { mutate } from '@jotta/grpc-connect-client/util'
import { publicTransport } from '@jotta/grpc-connect-client/transport'

export type UserType = 'private' | 'business' | 'voucher'
export type SignupRequestData = {
  form: PartialMessage<SignupRequest_FormSignup>
  voucherCode?: string
  customerGroupCode?: string
  wantsNewsletter: boolean
}
export type SignupRequestCallback = (
  request: SignupRequestData,
) => Promise<SignupResponse>

export function CreateUser({
  type,
  federationIntent,
  federationToken,
  className,
  keepRouteOnSuccess,
  customerGroupOverride,
  prefill,
  ctaLabelText,
}: {
  type: UserType
  federationIntent?: FederationIntent
  federationToken?: string
  className?: string
  keepRouteOnSuccess?: boolean
  customerGroupOverride?: string
  prefill?: { name?: string; email?: string; voucherCode?: string }
  ctaLabelText?: string
}) {
  const [federationTokenState, setFederationToken] = useState(federationToken)
  const [success, setSuccess] = useState(false)

  const language = useCurrentLanguage()
  const { data: getConfigResponse } = useGetConfig(customerGroupOverride)
  const brandingInfo = getConfigResponse.brandingInfo
  const customerGroupCode =
    customerGroupOverride || brandingInfo?.customerGroupCode
  const supportsVoucher = getConfigResponse?.activeFeatures?.includes(
    Feature.VOUCHER_SIGNUP,
  )

  const { data: country } = useConnectQuery(
    getCurrentCountry,
    {},
    {
      throwOnError: false,
      select: data => data.country,
    },
  )

  const {
    data: signupPrivateResponse,
    mutateAsync: signupPrivate,
    error: signupPrivateError,
    isPending: isPrivatePending,
  } = useMutation({
    throwOnError: false,
    mutationFn: async ({
      form,
      wantsNewsletter,
      voucherCode,
      customerGroupCode: requestedCustomerGroupCode,
    }: SignupRequestData) =>
      mutate(
        signupDescriptor,
        await generateSignupToken(
          new SignupRequest({
            customerGroupCode: requestedCustomerGroupCode || customerGroupCode,
            language,
            country,
            voucherCode,
            wantsNewsletter,
            request: {
              case: 'formSignup',
              value: form,
            },
          }),
          (message, token) => (message.token = token),
        ),
        publicTransport,
      ),
  })

  const {
    mutateAsync: signupBusiness,
    data: signupBusinessResponse,
    error: signupBusinessError,
    isPending: isBusinessPending,
  } = useMutation({
    throwOnError: false,
    mutationFn: async (req: PartialMessage<BusinessSignupRequest>) =>
      mutate(
        signupBusinessDescriptor,
        await generateSignupToken(
          new BusinessSignupRequest({
            customerGroupCode,
            language,
            country,
            ...req,
          }),
          (req, token) => (req.token = token),
        ),
        publicTransport,
      ),
  })

  const redirectTo = keepRouteOnSuccess
    ? location.href
    : `${location.origin}/web/sync`

  const onSuccess = useCallback(
    (success: LoggedIn) => {
      setSuccess(true)
      autoLogin({
        username: success.username,
        token: success.autoLoginCode,
        redirectTo: redirectTo,
      })
    },
    [redirectTo],
  )

  useEffect((): undefined => {
    if (signupPrivateResponse) {
      const signupResult = signupPrivateResponse.result
      switch (signupResult?.case) {
        case undefined:
          break
        case 'success':
          onSuccess(signupResult.value)
          break
        case 'emailInUse':
        case 'signupNotSupported':
          throw new Error('signup failed')
        default:
          return exhaustiveGuard(signupResult)
      }
    }
  }, [onSuccess, signupPrivateResponse])

  useEffect((): undefined => {
    if (signupBusinessResponse) {
      const signupResult = signupBusinessResponse.result
      switch (signupResult?.case) {
        case 'success':
          onSuccess(signupResult.value)
          break
        case undefined:
        case 'emailInUse':
        case 'signupNotSupported':
          throw new Error('signup failed')
        default:
          return exhaustiveGuard(signupResult)
      }
    }
  }, [onSuccess, signupBusinessResponse])

  if (!customerGroupCode || success) {
    return <LoadingOverlay open />
  }

  const baseLayoutClass = 'flex flex-col gap-4'
  return (
    <div className={clsx(baseLayoutClass, className)}>
      {type === 'private' &&
        federationIntent &&
        getConfigResponse?.viewConfig?.showLinkedAccounts && (
          <>
            <Federation intent={federationIntent} />

            <div className="flex items-center gap-4 text-gray-500">
              <Divider className="w-full" />
              <p className="py-12">or</p>
              <Divider className="w-full" />
            </div>
          </>
        )}
      {(() => {
        switch (type) {
          case 'voucher':
            return (
              <CreateVoucherUser
                customerGroupCode={customerGroupCode}
                className={baseLayoutClass}
                isPending={isPrivatePending}
                signup={signupPrivate}
                prefill={prefill}
              />
            )
          case 'private':
            return (
              <CreatePrivateUser
                customerGroupCode={customerGroupCode}
                className={baseLayoutClass}
                isPending={isPrivatePending}
                supportsVoucher={supportsVoucher}
                signup={signupPrivate}
                prefill={prefill}
                ctaLabelText={ctaLabelText}
              />
            )
          case 'business':
            return (
              <CreateBusinessUser
                customerGroupCode={customerGroupCode}
                className={baseLayoutClass}
                isPending={isBusinessPending}
                supportsVoucher={supportsVoucher}
                signup={signupBusiness}
                prefill={prefill}
                ctaLabelText={ctaLabelText}
              />
            )
          default:
            exhaustiveGuard(type)
        }
      })()}

      <p>
        <Trans>Already have an account?</Trans>&nbsp;
        <a href="/api/login" className="!text-signup-link underline">
          <Trans>Log in</Trans>
        </a>
      </p>

      <ErrorMessage error={signupPrivateError} />

      <ErrorMessage error={signupBusinessError} />

      <ModalDialog open={Boolean(federationTokenState)}>
        {federationTokenState && (
          <FederatedSignupConfirmation
            className="px-8 py-10"
            customerGroupCode={customerGroupCode}
            federationToken={federationTokenState}
            onSuccess={onSuccess}
            onCancel={() => setFederationToken(undefined)}
            country={country}
            language={language}
            ctaLabelText={ctaLabelText}
          />
        )}
      </ModalDialog>
    </div>
  )
}
