import { Button } from '@jotta/ui/Button'
import { Input } from '@jotta/ui/Input'
import { Label } from '@jotta/ui/Label'
import type { AppError } from '@jotta/types/AppError'
import { t } from '@lingui/macro'
import * as Dialog from '@radix-ui/react-dialog'
import type { ChangeEventHandler, FormEventHandler } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'

export function CreateNewFolder({
  path,
  targetPath,
  onConfirm,
  onCancel,
  error,
}: {
  path: string
  targetPath: string
  onConfirm: (targetPath: string, name: string) => void
  onCancel: () => void
  error?: AppError
}) {
  const [name, setName] = useState('')
  const [isEmptyError, setIsEmptyError] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const active = path.toLocaleLowerCase() === targetPath.toLocaleLowerCase()
  const slashInFolderName = /\//.test(name)

  useEffect(() => {}, [error])

  useEffect(() => {
    if (!active || !inputRef.current) {
      return
    }

    inputRef.current.focus()
  }, [active])

  const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    e => {
      e.preventDefault()
      if (!inputRef.current?.value) {
        return
      }
      onConfirm(targetPath, inputRef.current.value)
    },
    [onConfirm, targetPath],
  )

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(e => {
    setName(e.target.value)
    setIsEmptyError(!e.target.value)
  }, [])

  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (!open) {
        onCancel()
      }
    },
    [onCancel],
  )

  const handleEscapeKey = useCallback((e: KeyboardEvent) => {
    if (!inputRef.current) {
      return
    }

    if (inputRef.current.value) {
      e.preventDefault()
      setName('')
    }
  }, [])

  if (!active) {
    return null
  }

  return (
    <Dialog.Root open={active} onOpenChange={handleOpenChange} modal={false}>
      <Dialog.Content asChild onEscapeKeyDown={handleEscapeKey}>
        <Label
          errorMessage={
            isEmptyError
              ? t`Name is required!`
              : slashInFolderName
                ? t`You can not use '/' in the folder name`
                : undefined
          }
        >
          <form
            sx={{
              display: 'flex',
              flexFlow: 'row nowrap',
              gap: 2,
              button: { px: 3 },
            }}
            onSubmit={handleSubmit}
          >
            <Input
              type="text"
              variant="forms.input"
              value={name}
              placeholder={t`untitled folder`}
              onChange={handleChange}
              ref={inputRef}
            />
            <Button
              variant="buttons.primary"
              type="submit"
              icon="SvgCheckmark"
            />
            <Button
              variant="buttons.secondary"
              onClick={onCancel}
              icon="SvgClose"
            />
          </form>
        </Label>
      </Dialog.Content>
    </Dialog.Root>
  )
}
