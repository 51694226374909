import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

export function useNavigateRef() {
  const navigate = useNavigate()
  const ref = useRef(navigate)

  useEffect(() => {
    ref.current = navigate
  }, [navigate])

  return ref
}
