import { t } from '@lingui/macro'
import {
  forwardRef,
  type InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useThemeContext } from '../../Brand/ThemeProvider/ThemeProvider'
import { passwordInputVariants } from './PasswordInput.variants'

export type PasswordInputProps = { showPassword?: boolean } & Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'type' | 'checked'
>

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  function PasswordInput({ value, showPassword = false, ...props }, ref) {
    const buttonRef = useRef<HTMLButtonElement>(null)
    const [isVisible, setIsVisible] = useState(showPassword)
    const [paddingRight, setPaddingRight] = useState(0)
    const { theme } = useThemeContext()
    const variants = passwordInputVariants({
      theme: theme,
    })
    useEffect(() => {
      const offsetWidth = buttonRef.current?.offsetWidth
      offsetWidth && setPaddingRight(offsetWidth + 12)
    }, [buttonRef.current?.offsetWidth, isVisible])

    return (
      <div
        className={variants.container({
          className: props.className,
        })}
      >
        <input
          {...props}
          value={value}
          type={isVisible ? 'text' : 'password'}
          className={variants.input()}
          ref={ref}
          style={{
            paddingRight,
          }}
        />
        {!!value && (
          <button
            type="button"
            role="switch"
            aria-checked={isVisible}
            ref={buttonRef}
            onClick={() => setIsVisible(currentValue => !currentValue)}
            className={variants.button()}
          >
            {isVisible ? t({ id: 'Hide' }) : t({ id: 'Show' })}
          </button>
        )}
      </div>
    )
  },
)
