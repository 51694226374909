import type { Rect } from '@jotta/hooks'
import { useDocumentSize, useElementSizeRef, useThrottle } from '@jotta/hooks'
import { bisectLeft } from '@jotta/utils/bisect'
import type { CSSProperties, RefObject } from 'react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { getTimelineScrubberStore } from '../../store/TimelineScrubberStore'
import type { TimelineRowData } from './useGroupByDayAndRow'

const timelineScrubberStore = getTimelineScrubberStore()

export interface VirtualTimelineProps {
  rows: TimelineRowData[]
  parentRef: RefObject<HTMLDivElement>
  getSize: (index: number, width: number) => number
  onSizeChange?: (width: number) => void
  scrollingParent?: Document | HTMLElement | null
}

export interface VirtualItem {
  virtualIndex: number
  virtualStyle: CSSProperties
  row: TimelineRowData
}

export interface VirtualTimeline {
  virtualItems: VirtualItem[]
}

export function useVirtualTimeline({
  rows,
  parentRef,
  getSize,
  onSizeChange,
  scrollingParent = document,
}: VirtualTimelineProps): VirtualTimeline {
  const scrollEl =
    scrollingParent instanceof Document
      ? document.scrollingElement
      : scrollingParent
  const CHUNK = 1200
  const scrollTop = useRef(scrollEl?.scrollTop || 0)
  const scrollHeight = useRef(scrollEl?.scrollHeight || 0)
  const sizes = useRef<number[]>([])
  const offsets = useRef<number[]>([])
  const virtualStart = useRef(0)
  const virtualEnd = useRef(0)
  const lastScroll = useRef(0)
  const generateSizesRef = useRef<(() => void) | null>(null)
  const heightRef = useRef(0)
  const [virtualItems, setVirtualItems] = useState<VirtualItem[]>([])

  useEffect(() => {
    timelineScrubberStore.scrollingParent = scrollingParent
  }, [scrollingParent])

  const resizeHandler = useThrottle(
    useCallback(
      (rect: Rect) => {
        if (onSizeChange) {
          onSizeChange(rect.width)
        }
        if (generateSizesRef.current) {
          generateSizesRef.current()
        }
      },
      [onSizeChange],
    ),
    500,
    useMemo(() => ({ leading: false }), []),
  )

  const doc = useDocumentSize()
  const parent = useElementSizeRef(parentRef, resizeHandler)

  useEffect(() => {
    if (onSizeChange) {
      onSizeChange(parent.current.width)
    }
  }, [parent, onSizeChange])

  const generateVirtualItems = useCallback(() => {
    if (!rows.length) {
      setVirtualItems([])
      return
    }

    if (!parentRef.current || !offsets.current.length) {
      return
    }

    const OVERSCAN = CHUNK * 2

    const start = bisectLeft(
      offsets.current,
      v => v - (scrollTop.current - OVERSCAN),
    )

    const end = bisectLeft(
      offsets.current,
      v => v - (scrollTop.current + OVERSCAN),
    )

    if (
      end - start > 0 &&
      start === virtualStart.current &&
      end === virtualEnd.current
    ) {
      return
    }

    virtualStart.current = start
    virtualEnd.current = end

    const items: VirtualItem[] = []

    for (let i = start; i < end; i++) {
      const top = offsets.current[i]
      items.push({
        virtualIndex: i,
        virtualStyle: {
          position: 'absolute',
          top: `${top}px`,
          width: '100%',
          height: `${sizes.current[i]}px`,
        },
        row: rows[i],
      })
    }

    setVirtualItems(items)
    lastScroll.current = scrollTop.current
  }, [parentRef, doc, setVirtualItems, rows])

  const generateVirtualItemsThrottled = useThrottle(
    useCallback(generateVirtualItems, [generateVirtualItems]),
    400,
  )

  const generateSizes = useCallback(() => {
    if (!parentRef.current) {
      offsets.current = []
      return
    }

    const newSizes = []
    let height = 0

    for (let i = 0; i < rows.length; i++) {
      const s = getSize(i, parent.current.width)
      height += s
      newSizes.push(s)
    }

    if (height === heightRef.current) {
      return
    }

    heightRef.current = height
    sizes.current = newSizes

    // Change parent height
    parentRef.current.style.height = `${height}px`

    const newOffsets: number[] = []
    for (let i = 0; i < newSizes.length; i++) {
      if (i === 0) {
        newOffsets.push(0)
      } else {
        newOffsets.push(newOffsets[i - 1] + newSizes[i - 1])
      }
    }
    offsets.current = newOffsets

    timelineScrubberStore.setContent(rows, offsets.current, sizes.current)
    generateVirtualItemsThrottled()
  }, [getSize, rows, parentRef, parent, generateVirtualItemsThrottled])

  generateSizesRef.current = generateSizes

  const scrollHandler = useCallback(() => {
    if (!scrollEl) {
      return
    }

    scrollTop.current = scrollEl.scrollTop
    scrollHeight.current = scrollEl.scrollHeight

    if (Math.abs(lastScroll.current - scrollTop.current) < CHUNK) {
      return
    }

    generateVirtualItemsThrottled()
  }, [generateVirtualItemsThrottled, scrollEl])

  useEffect(() => {
    if (!scrollingParent) {
      return
    }

    scrollHandler()
    scrollingParent.addEventListener('scroll', scrollHandler, { passive: true })

    return () => {
      scrollingParent.removeEventListener('scroll', scrollHandler)
    }
  }, [scrollHandler, scrollingParent])

  useEffect(() => {
    generateSizes()
  }, [generateSizes, generateVirtualItemsThrottled])

  // Reset when rowdata changes to force recomputation of virtual items
  useEffect(() => {
    virtualStart.current = 0
    virtualEnd.current = 0
    generateVirtualItemsThrottled()
  }, [rows, generateVirtualItemsThrottled])

  return { virtualItems }
}
