import type { FieldError } from '@jotta/hooks/useForm'
import { useForm } from '@jotta/hooks/useForm'
import { delay } from '@jotta/utils/delay'
import { Trans } from '@lingui/macro'
import dayjs from 'dayjs'
import { useNavigate, useParams } from 'react-router-dom'
import type { ZodIssue, ZodType } from 'zod'
import z from 'zod'
import { bizApi } from '../BizApi'
function zodValidator(
  schema: ZodType,
  transformErrors?: (errors: ZodIssue[]) => string | undefined,
) {
  return async (value: unknown) => {
    const parsed = schema.safeParse(value)
    if (!parsed.success) {
      if (transformErrors) {
        return transformErrors(parsed.error.issues)
      }
      return parsed.error.issues.map(issue => issue.message).join(', ')
    }
    return undefined
  }
}
export function BizModifyUserFormJHF() {
  const navigate = useNavigate()
  const { username = '' } = useParams()
  const userQuery = bizApi.user.byId.useSuspenseQuery({
    variables: { username },
  })
  const mutation = bizApi.user.update.useMutation()

  const {
    onSubmit,
    onReset,
    isSubmitting,
    status,
    email,
    fullName,
    isAdminUser,
    spaceLimit,
    unlimitedSpace,
  } = useForm({
    fields: {
      fullName: {
        type: 'text',
        placeholder: 'a name',
        validate: zodValidator(
          z
            .string()
            .min(3, 'Name is required and must be at least 3 characters long.'),
        ),
      },
      email: {
        type: 'text',
        placeholder: 'an email',
        validate: zodValidator(
          z
            .string()
            .email('Email is required and must be a valid email address.'),
        ),
      },
      spaceLimit: {
        type: 'text',
        placeholder: 'Storage quota in gigabytes',
        validate: zodValidator(
          z.optional(
            z.coerce.number({
              invalid_type_error: 'Must be a number',
            }),
          ),
        ),
      },
      unlimitedSpace: {
        type: 'boolean',
        placeholder: 'accept',
      },
      isAdminUser: {
        type: 'boolean',
        placeholder: 'accept',
      },
    },
    defaultValues: () => ({
      ...userQuery.data,
      spaceLimit: userQuery.data.spaceLimit.toString(),
    }),
    submit: async value => {
      await delay(1000)
      const spaceLimit = value.spaceLimit
        ? parseInt(value.spaceLimit)
        : userQuery.data.spaceLimit
      await mutation.mutateAsync({
        user: {
          email: value.email ?? userQuery.data.email,
          fullName: value.fullName ?? userQuery.data.fullName,
          isAdminUser: value.isAdminUser ?? userQuery.data.isAdminUser,
          spaceLimit,
          unlimitedSpace: value.unlimitedSpace ?? userQuery.data.unlimitedSpace,
          username: userQuery.data.username,
          spaceUsed: userQuery.data.spaceUsed,
          lastUpload: userQuery.data.lastUpload,
        },
      })

      navigate('../..', {
        relative: 'path',
      })
    },
  })

  return (
    <form className="form relative" onSubmit={onSubmit} onReset={onReset}>
      {isSubmitting && <div className="loading loading-absolute"></div>}
      <div className="form-control">
        <label htmlFor={fullName.inputProps.name}>
          <Trans>Full name</Trans>
        </label>
        <input className="input" {...fullName.inputProps} />
        <DisplayFieldError error={fullName.error} />
      </div>
      <div className="form-control">
        <label htmlFor={email.inputProps.name}>
          <Trans>Email</Trans>
        </label>
        <input className="input" {...email.inputProps} />
        <DisplayFieldError error={email.error} />
      </div>

      <div className="form-control">
        <label htmlFor={unlimitedSpace.inputProps.name}>
          <Trans>Storage</Trans>
        </label>
        <p id="unlimitedhint" className="form-control-text">
          <Trans>
            Unlimited means this user can upload as much as he/she likes, and is
            only limited by the total storage capacity of the company account.
          </Trans>
        </p>
        <label className="checkbox">
          <input type="checkbox" {...unlimitedSpace.inputProps} />
          <Trans>Unlimited storage</Trans>
        </label>

        {!unlimitedSpace.inputProps.checked && (
          <label onFocus={() => {}} className="flex items-center gap-2">
            <input className="input" {...spaceLimit.inputProps} />
            <Trans>GB</Trans>
          </label>
        )}
        <DisplayFieldError error={spaceLimit.error} />
      </div>
      <div className="form-control">
        <label htmlFor={isAdminUser.inputProps.name}>
          <Trans>Administrator</Trans>
        </label>
        <label className="checkbox">
          <input type="checkbox" {...isAdminUser} />
          <Trans>Make this user an administrator</Trans>
        </label>
      </div>
      <div className="form-control">
        <div className="label">
          <Trans>Last upload</Trans>
        </div>
        <div className="text-base">
          {dayjs(userQuery.data.lastUpload).fromNow()}
        </div>
      </div>
      <div className="form-buttons">
        <button
          disabled={isSubmitting || status !== 'idle'}
          type="submit"
          className="btn btn-primary"
        >
          <Trans>Modify user</Trans>
        </button>
      </div>
      {/* <div className="form-control">
          <div className="label">Debug</div>
          <DefinitionListFromObject
            value={{
              status,
              email,
              fullName,
              isAdminUser,
              spaceLimit,
              unlimitedSpace,
            }}
          />
        </div> */}
    </form>
  )
}
function DisplayFieldError({ error }: { error?: FieldError }) {
  if (error) {
    return <div className="form-control-error">{error.view}</div>
  }
}
