import type { PartialMessage } from '@bufbuild/protobuf'
import { useSuspenseQuery } from '@connectrpc/connect-query'
import type {
  GetConfigRequest,
  GetConfigResponse,
} from '@jotta/grpc-connect-openapi/config'
import { getConfig } from '@jotta/grpc-connect-openapi/configQuery'
import { FeatureToggle } from '@jotta/types/FeatureToggle'
import { env } from '@jotta/utils/env'

export function useSuspenseConfig(
  requestOptions: PartialMessage<GetConfigRequest> = {},
  connectOptions: Parameters<
    typeof useSuspenseQuery<
      GetConfigRequest,
      GetConfigResponse,
      GetConfigResponse
    >
  >[2] = {},
) {
  const hostname = env.isLocal ? 'jottacloud.com' : window.location.hostname
  const featureToggleIds = Object.values(FeatureToggle)

  const query = useSuspenseQuery(
    getConfig,
    {
      hostname,
      featureToggleIds,
      ...requestOptions,
    },
    {
      ...connectOptions,
    },
  )
  const config = query.data
  return {
    config,
    query,
  }
}
