import type { BoxProps } from '@jotta/ui/Box'
import { Box } from '@jotta/ui/Box'
import { Button } from '@jotta/ui/Button'
import { Flex } from '@jotta/ui/Flex'
import { useBrandStore } from '@jotta/ui/useBrandTheme'
import type { Photos } from '@jotta/types/Photos'
import { Headline2 } from '@jotta/ui/Headline'
import { Plural } from '@lingui/macro'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useSelectionStore } from '../../store/PhotoContext'
import { PhotoCheckbox } from '../PhotoCheckbox/PhotoCheckbox'
import { SmartPhotoSearchBannersContainer } from '../SmartPhotoSearchBannersContainer/SmartPhotoSearchBannersContainer'
import { HideScrollbar } from './HideScrollbar'
import { PhotoRow } from './PhotoRow'
import type { GalleryRef } from './useGroupByDayAndRow'
import { isTimelineHeader, useGroupByDayAndRow } from './useGroupByDayAndRow'
import { useVirtualTimeline } from './useVirtualTimeline'

const HeaderItem = observer<{ day: number; ids: string[] }>(({ day, ids }) => {
  const selectionStore = useSelectionStore()

  const isSelected = ids.every(id => selectionStore.selection.has(id))
  const onToggleSelect = (selected: boolean) => {
    if (selected) {
      for (const id of ids) {
        selectionStore.selection.add(id)
      }
    } else {
      for (const id of ids) {
        selectionStore.selection.delete(id)
      }
    }
  }

  const clickHandler = () => onToggleSelect(!isSelected)

  return (
    <Box sx={{ overflow: 'hidden', ml: [2, 0] }}>
      <Flex
        sx={{
          alignItems: 'baseline',
          pt: 4,
          pb: '14px',
          pr: 2,
          transition: 'margin 0.2s',
          ml: !isSelected ? -4 : 0,
          '@media (hover: hover) and (pointer: fine)': {
            ':hover': {
              ml: 0,
            },
          },
        }}
      >
        <PhotoCheckbox
          id="checkbox"
          isSelected={isSelected}
          onToggleSelect={onToggleSelect}
          sx={{
            position: 'static',
            width: 4,
            transform: 'translateX(calc(-50% + 0.5em))',
            alignSelf: 'center',
          }}
        />
        <Button
          onClick={clickHandler}
          sx={{ cursor: 'pointer', display: 'flex' }}
          tabIndex={0}
        >
          <Headline2 variant="styles.timelineHeading">
            {dayjs.utc(day).format('LL')}
          </Headline2>
        </Button>
        <span
          sx={{
            fontSize: 2,
            ml: '14px',
            opacity: 0.8,
            fontWeight: 'body',
            color: 'graySlateBgColor',
            cursor: 'default',
          }}
        >
          {/* Lingui creates a duplicate empty translation if we use imageCount directly */}
          <Plural value={Number(ids.length)} one="# photo" other="# photos" />
        </span>
      </Flex>
    </Box>
  )
})

const PHOTO_HEIGHT = 240
const TARGET_MOBILE_SIZE = 90
const MAX_MOBILE_ROW_LEN = 4

export const Timeline = observer<
  Partial<BoxProps> & {
    photos: readonly Photos.Media[]
    showSimilarSearch?: boolean
    showWaitinglistBanners?: boolean
  },
  GalleryRef
>(
  forwardRef(function Timeline(
    { photos = [], showSimilarSearch = false, showWaitinglistBanners = false },
    ref,
  ) {
    const timelineRef = useRef<HTMLDivElement>(null)
    const [rowAspect, setRowAspect] = useState<[number, number]>([8, 1])
    const branding = useBrandStore()
    const rows = useGroupByDayAndRow(
      rowAspect,
      photos,
      !branding.isMobileTimeline,
    )
    const list = useRef<HTMLDivElement>(null)

    // Scroll to index
    const photoIndex = useMemo(
      () =>
        rows.reduce((map, row, i) => {
          if (!isTimelineHeader(row)) {
            for (const el of row.elements) {
              map.set(el.id, i)
            }
          }
          return map
        }, new Map<string, number>()),
      [rows],
    )

    useImperativeHandle(
      ref,
      () => ({
        scrollToMediaById(id: string) {
          const index = photoIndex.get(id)
          // if (index) {
          //   virtualRef.current.scrollToIndex(index)
          // }
        },
      }),
      [photoIndex],
    )

    const bk = branding.theme.branding.photos.timeline.mobileTimelineBreakpoint

    const onSizeChange = useCallback(
      (width: number) => {
        if (width >= bk) {
          const newRowAspect: [number, number] = [
            Math.max(2, Math.ceil(width / PHOTO_HEIGHT)),
            1,
          ]
          setRowAspect(prev => {
            if (prev[0] === newRowAspect[0] && prev[1] === newRowAspect[1]) {
              return prev
            }
            return newRowAspect
          })
        } else {
          const newRowAspect: [number, number] = [
            Math.max(
              1,
              Math.min(
                MAX_MOBILE_ROW_LEN,
                Math.trunc(width / TARGET_MOBILE_SIZE),
              ),
            ),
            1,
          ]
          setRowAspect(prev => {
            if (prev[0] === newRowAspect[0] && prev[1] === newRowAspect[1]) {
              return prev
            }
            return newRowAspect
          })
        }
      },
      [setRowAspect, bk],
    )

    const getSize = useCallback(
      (i: number, width: number) => {
        const row = rows[i]
        if (row.content === 'header') {
          return 60.5
        }

        return (width * rowAspect[1]) / rowAspect[0]
      },
      [rows, rowAspect],
    )

    const virtualizer = useVirtualTimeline({
      rows,
      parentRef: list,
      getSize,
      onSizeChange,
      scrollingParent:
        branding.currentBreakpoint > 1 ? document : timelineRef.current,
    })

    return (
      <Box
        ref={timelineRef}
        sx={{
          ml: [0, 3],
          userSelect: 'none',
          height: '100%',
          overflowY: ['auto', 'initial'],
          scrollbarWidth: 'none',
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}
        id="timeline"
      >
        {showWaitinglistBanners && <SmartPhotoSearchBannersContainer />}
        <HideScrollbar />

        <Box
          sx={{
            position: 'relative',
            pb: ['var(--s3)', 'calc(var(--content-bottom) + var(--s3))'],
            boxSizing: 'content-box',
          }}
          ref={list}
        >
          {virtualizer.virtualItems.map(
            ({ virtualIndex, virtualStyle, row }) => {
              return (
                <div key={virtualIndex} style={virtualStyle}>
                  {isTimelineHeader(row) ? (
                    <HeaderItem day={row.day} ids={row.ids} />
                  ) : (
                    <PhotoRow row={row} showSimilarSearch={showSimilarSearch} />
                  )}
                </div>
              )
            },
          )}
        </Box>
      </Box>
    )
  }),
)
