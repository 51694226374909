import { useUsername } from '@jotta/grpc-connect-client/customer'
import type { GetPathParams } from '@jotta/grpc-js-client/files/getPath'
import type { FileRouteContext } from '@jotta/types/schemas/FileRouteSchema'
import {
  createPath,
  createRelativePath,
  popPath,
  removeTrailingSlash,
} from '@jotta/utils/pathUtils'
import { isString } from '@jotta/utils/typeAssertions'
import Debug from 'debug'
import moize from 'moize'
import { useLocation } from 'react-router-dom'
import {
  StrictFileRouteSchema,
  getFileRouteParams,
  matchFileRoutePath,
} from './getFileRouteParams'
import { routeContextToApiPath } from './routeContextToApiPath'
import { routeParamsToApiPathBreadCrumb } from './routeParamsToApiPathBreadCrumb'
import { routeParamsToRoutePath } from './routeParamsToRoutePath'
import { getSortLinks } from './sortParamToSortBy'

/**
 *
 * @param params params object or a pathname to parse into params
 * @returns
 */
export const parseFileRouteParams = moize(
  function parseFileRouteParams(data: string, username: string) {
    const debug = Debug('jotta:files:parseFileRouteParams')
    const match = matchFileRoutePath(data, 'strict')
    const parsed = StrictFileRouteSchema.safeParse(match)
    const params = parsed.success
      ? parsed.data
      : getFileRouteParams(data, 'catch')
    // params['*'] = uriDecodeRoutePath(params['*'])
    const { context } = params
    const apiPath = routeContextToApiPath(params.context, params['*'])
    const apiPathBreadcrumb = routeParamsToApiPathBreadCrumb(params)
    let shouldRedirect =
      ['trash', 'shared'].includes(params.context) &&
      removeTrailingSlash(params['*'].toLowerCase()) === 'sync'

    let parsedRoute = routeParamsToRoutePath({
      ...params,
    })
    const inputRoute = parsed.success
      ? parsedRoute
      : isString(data)
        ? removeTrailingSlash(data)
        : String(data)
    if (shouldRedirect) {
      parsedRoute = popPath(parsedRoute)
    }
    shouldRedirect = shouldRedirect || inputRoute !== parsedRoute
    if (isString(data) && data.includes('%')) {
      debug('data', data)
      debug('inputRoute', inputRoute)
      debug('parsedRoute', parsedRoute)
      debug('shouldRedirect', shouldRedirect)
    }
    const rootRoute = routeParamsToRoutePath({
      ...params,
      '*': '',
    })
    const depth = apiPathBreadcrumb.length - 1
    const isRoot = apiPathBreadcrumb.length <= 1
    const parentApiPath = apiPathBreadcrumb.at(-2) || apiPath
    const sort = getSortLinks(data)

    const includeDeleted = context === 'trash'
    const getPathParams: GetPathParams = {
      path: apiPath,
      sort: sort.sortDir,
      includeDeleted,
    }
    const virtualBackupFolders: readonly FileRouteContext[] = [
      'photos',
      'contacts',
    ]
    const backupContexts: readonly FileRouteContext[] = [
      'backup',
      'photos',
      'contacts',
    ]
    const isBackup = backupContexts.includes(context)
    const isVirtual = virtualBackupFolders.includes(context)

    return {
      ...params,
      apiPath,
      depth,
      getPathParams,
      includeDeleted,
      isRoot: isRoot && !isVirtual,
      isShared: params.context === 'shared',
      isSync: params.context === 'sync',
      isTrash: params.context === 'trash',
      isTrashOrShared: ['trash', 'shared'].includes(params.context),
      isTrashOrSearch: ['trash', 'search'].includes(params.context),
      isSearch: ['search'].includes(params.context),
      isBackup,
      isVirtual,
      params,
      parentApiPath,
      relativeRoutePath: createRelativePath(params['*']),
      rootRoute,
      routePath: parsedRoute,
      shouldRedirect,
      ...sort,
      uniquePath: createPath(username || 'anonymous', params.context, apiPath),
      username,
    }
  },
  {
    maxSize: 100,
  },
)

export function useParsedFileRouteParams() {
  const location = useLocation()
  const username = useUsername()
  return parseFileRouteParams(location.pathname, username)
}

export type FileRouteData = ReturnType<typeof parseFileRouteParams>
