import type {
  FileTransferInfo,
  FileTransferParams,
} from '@jotta/grpc-js-client/fileService'
import {
  FileTransferConflictHandler,
  transferFile,
} from '@jotta/grpc-js-client/fileService'
import {
  CopyResponse,
  MoveResponse,
} from '@jotta/grpc-web/no/jotta/openapi/file/file.v2_pb'
import { AbortError } from '@jotta/types/AbortError'
import type { GrpcApiError } from '@jotta/types/GrpcApiError'
import { isGrpcApiError } from '@jotta/types/GrpcApiError'
import type { DecodedErrorMessage } from '@jotta/types/decodeErrorMessage'
import {
  isCopyErrorMessage,
  isMoveErrorMessage,
} from '@jotta/types/decodeErrorMessage'
import { getFilenameFromPath, popPath } from '@jotta/utils/pathUtils'
import { Code } from '@connectrpc/connect'
import type {
  ConflictResponse,
  FileConflictQueue,
  FileConflictType,
} from '../FileConflictDialog/FileConflictStore'

export async function transferFiles({
  filePaths,
  targetDirectoryPath,
  transferType,
  conflictStore,
  onFileTransferred,
}: FileTransferParams & { conflictStore?: FileConflictQueue }) {
  const conflicts: Promise<void>[] = []

  const getConflictType = (
    error: DecodedErrorMessage,
  ): FileConflictType | null => {
    if (isCopyErrorMessage(error)) {
      switch (error.toObject().copyError) {
        case CopyResponse.CopyError.COPY_FILE_ONTO_FILE:
          return 'FILE_ONTO_FILE'
        case CopyResponse.CopyError.COPY_FILE_ONTO_FOLDER:
          return 'FILE_ONTO_FOLDER'
        case CopyResponse.CopyError.COPY_FOLDER_ONTO_FILE:
          return 'FOLDER_ONTO_FILE'
        case CopyResponse.CopyError.COPY_FOLDER_ONTO_FOLDER:
          return 'FOLDER_ONTO_FOLDER'
      }
    }
    if (isMoveErrorMessage(error)) {
      switch (error.toObject().moveError) {
        case MoveResponse.MoveError.MOVE_FILE_ONTO_FILE:
          return 'FILE_ONTO_FILE'
        case MoveResponse.MoveError.MOVE_FILE_ONTO_FOLDER:
          return 'FILE_ONTO_FOLDER'
        case MoveResponse.MoveError.MOVE_FOLDER_ONTO_FILE:
          return 'FOLDER_ONTO_FILE'
        case MoveResponse.MoveError.MOVE_FOLDER_ONTO_FOLDER:
          return 'FOLDER_ONTO_FOLDER'
      }
    }

    return null
  }

  const getValidConflictHandler = (action: ConflictResponse['action']) => {
    switch (action) {
      case 'cancel':
      case 'skip':
        return null
      case 'overwrite':
        return FileTransferConflictHandler.CREATE_NEW_REVISION
      case 'rename':
        return FileTransferConflictHandler.CREATE_NEW_NAME
    }
  }

  const handleConflict = (
    fileName: string,
    sourceDirectoryPath: string,
    error: GrpcApiError,
  ) => {
    if (!conflictStore || !error.decodedError) {
      return
    }

    const conflictType = getConflictType(error.decodedError)

    if (!conflictType) {
      return
    }

    conflicts.push(
      conflictStore
        .requestConflictAction(fileName, conflictType, transferType)
        .then(res => {
          const conflictHandler = getValidConflictHandler(res.action)

          if (!conflictHandler) {
            if (onFileTransferred) {
              onFileTransferred({
                transferType,
                fileName,
                oldPath: sourceDirectoryPath,
                newPath: targetDirectoryPath,
                conflictHandler: 0,
                error: new AbortError('Aborted by user'),
              })
            }
            return
          }

          return transferFile({
            fileName,
            sourceDirectoryPath,
            targetDirectoryPath,
            transferType,
            onFileTransferred,
            conflictHandler,
          })
        }),
    )
  }

  const onFileTransferredWrapper = (info: FileTransferInfo) => {
    if (
      info.error &&
      isGrpcApiError(info.error) &&
      info.error.code === Code.AlreadyExists
    ) {
      handleConflict(info.fileName, info.oldPath, info.error)
    } else if (onFileTransferred) {
      onFileTransferred(info)
    }
  }

  await Promise.allSettled(
    filePaths.map(filePath =>
      transferFile({
        fileName: getFilenameFromPath(filePath),
        sourceDirectoryPath: popPath(filePath),
        targetDirectoryPath,
        transferType,
        onFileTransferred: onFileTransferredWrapper,
      }),
    ),
  )

  if (conflicts.length) {
    await Promise.all(conflicts)
  }
}
