import {
  CopyResponse,
  MoveResponse,
} from '@jotta/grpc-web/no/jotta/openapi/file/file.v2_pb'
import { Any } from 'google-protobuf/google/protobuf/any_pb'
import { Base64 } from 'js-base64'

export type DecodedErrorMessage =
  | CopyResponse.CopyErrorMessage
  | MoveResponse.MoveErrorMessage

const PREFIX = 'no.jotta.openapi.file.v2'
export function decodeErrorMessage(
  encodedError?: string,
): DecodedErrorMessage | null {
  if (!encodedError) {
    return null
  }

  const buffer = Base64.toUint8Array(encodedError)
  const any = Any.deserializeBinary(buffer)

  switch (any.getTypeName()) {
    case `${PREFIX}.CopyResponse.CopyErrorMessage`: {
      const unpacked = any.unpack(
        CopyResponse.CopyErrorMessage.deserializeBinary,
        any.getTypeName(),
      )

      return unpacked
    }

    case `${PREFIX}.MoveResponse.MoveErrorMessage`: {
      const unpacked = any.unpack(
        MoveResponse.MoveErrorMessage.deserializeBinary,
        any.getTypeName(),
      )

      return unpacked
    }
  }

  return null
}

export function isCopyErrorMessage(
  err: unknown,
): err is CopyResponse.CopyErrorMessage {
  return err instanceof CopyResponse.CopyErrorMessage
}

export function isMoveErrorMessage(
  err: unknown,
): err is MoveResponse.MoveErrorMessage {
  return err instanceof MoveResponse.MoveErrorMessage
}
