import { Box } from '@jotta/ui/Box'
import type { SelectVariant } from '@jotta/ui/types/VariantTypes'
import type { Photos } from '@jotta/types/Photos'
import type { PhotosApi } from '@jotta/types/PhotosApi'
import { observer } from 'mobx-react-lite'

import { ActionAddComment } from './ActionAddComment'
import { ActionAddPhotos } from './ActionAddPhotos'
import { ActionAddPhotosAnonymous } from './ActionAddPhotosAnonymous'
import { ActionAddPhotosToAlbum } from './ActionAddPhotosToAlbum'
import { ActionCopyAlbumShareLink } from './ActionCopyAlbumShareLink'
import { ActionCreateAlbum } from './ActionCreateAlbum'
import { ActionDeleteAlbum } from './ActionDeleteAlbum'
import { ActionDeletePhotos } from './ActionDeletePhotos'
import { ActionDeleteSharedGroupOfPhotos } from './ActionDeleteSharedGroupOfPhotos'
import { ActionDownloadPhoto } from './ActionDownloadPhoto'
import { ActionDownloadPhotos } from './ActionDownloadPhotos'
import { ActionFindSimilar } from './ActionFindSimilar'
import { ActionFollowAlbum } from './ActionFollowAlbum'
import { ActionFollowAlbumAnonymous } from './ActionFollowAlbumAnonymous'
import { ActionHidePhotos } from './ActionHidePhotos'
import { ActionLeaveAlbum } from './ActionLeaveAlbum'
import { ActionPlayAlbumSlideshow } from './ActionPlayAlbumSlideshow'
import { ActionRemovePhotosFromAlbum } from './ActionRemovePhotosFromAlbum'
import { ActionShareAlbum } from './ActionShareAlbum'
import { ActionSharePhotos } from './ActionSharePhotos'
import { ActionShowInfo } from './ActionShowInfo'
import { ActionUnhidePhoto } from './ActionUnhidePhoto'
import { ActionUnshareAlbum } from './ActionUnshareAlbum'
import type { PhotoActionContextType } from './PhotoActionContext.types'
import type { PhotoActionConfig, PhotoActionsApi } from './PhotoActions.config'
import { getPhotoActions } from './PhotoActions.config'
import type { PhotoAction, PhotoActionType } from './PhotoActions.types'
import type {
  OnPhotoActionDispatch,
  OnPhotoActionDispatchError,
  PhotoActionDispatcher,
} from './useDispatchPhotoActionMutation'
import { usePhotoActionDispatcher } from './useDispatchPhotoActionMutation'

export interface PhotoActionHandlerSharedProps {
  username?: string
  isAlbumSubscriber?: boolean
  isAlbumUser?: boolean
  isOwnerOfAllPhotos?: boolean
  readOnly?: boolean
  /** Album ID of the currently displayed album */
  albumId?: string
  album?: PhotosApi.AlbumInfo
  /** Album ID to return to after adding photos */
  addPhotoMode?: string | null
  hasComments?: boolean
  commentCount?: number | undefined
  buttonVariant?: SelectVariant<'buttons'>
  shareId?: string
  shareLink?: string
  downloadLink?: {
    fileUrl: string
    fileName: string
  }
  currentMediaId?: string
  currentPhoto?: Photos.Media
  photoIds?: readonly string[]
  albums?: PhotosApi.AlbumInfo[]
  dispatch?: PhotoActionDispatcher
  onActionDialogClose?: (actionType: PhotoActionType) => void
  getDownloadInfo?: (
    ids?: readonly string[],
  ) => Promise<PhotosApi.DownloadPhotosInfo>
  showSimilarSearch?: boolean
}
export interface PhotoActionHandlerActionProps<A extends PhotoActionType>
  extends PhotoActionHandlerSharedProps {
  action: PhotoActionsApi[A]
  disabled?: boolean
  mutationOptions?: Partial<PhotoAction<A>['useMutationOptions']>
  username: string
}
export interface PhotoActionHandlerProps extends PhotoActionHandlerSharedProps {
  action: PhotoActionConfig
  onDispatch?: OnPhotoActionDispatch
  onDispatchComplete?: OnPhotoActionDispatch
  onDispatchError?: OnPhotoActionDispatchError
  hideDisabled?: boolean
  username: string
}
export interface PhotoActionHandlersProps
  extends PhotoActionHandlerSharedProps {
  actionContext: PhotoActionContextType
  onDispatch?: OnPhotoActionDispatch
  onDispatchComplete?: OnPhotoActionDispatch
  onDispatchError?: OnPhotoActionDispatchError
  commentCount?: number | undefined
  hideDisabled?: boolean
  username: string
}
export const PhotoActionHandler = observer<PhotoActionHandlerProps>(
  function PhotoActionHandler({
    action,
    onDispatch,
    onDispatchComplete,
    onDispatchError,
    commentCount,
    dispatch: overrideDispatch,
    hideDisabled = false,
    ...props
  }) {
    const dispatch = usePhotoActionDispatcher({
      onDispatch,
      onDispatchComplete,
      onDispatchError,
      overrideDispatch,
    })

    if (action.hasAction && !action.hasAction(props)) {
      return null
    }

    const disabled = Boolean(action.isDisabled && action.isDisabled(props))

    if (hideDisabled && disabled) {
      return null
    }

    switch (action.actionType) {
      case 'ADD_PHOTOS_ANONYMOUS':
        return (
          <ActionAddPhotosAnonymous
            action={action}
            dispatch={dispatch}
            disabled={disabled}
            {...props}
          />
        )
      case 'ADD_PHOTOS_TO_ALBUM':
        return (
          <ActionAddPhotosToAlbum
            action={action}
            dispatch={dispatch}
            disabled={disabled}
            {...props}
          />
        )
      case 'ADD_PHOTOS':
        return (
          <ActionAddPhotos
            action={action}
            dispatch={dispatch}
            disabled={disabled}
            {...props}
          />
        )
      case 'COPY_SHARED_ALBUM_LINK':
        return (
          <ActionCopyAlbumShareLink
            action={action}
            dispatch={dispatch}
            disabled={disabled}
            {...props}
          />
        )
      case 'CREATE_ALBUM':
        return (
          <ActionCreateAlbum
            action={action}
            dispatch={dispatch}
            disabled={disabled}
            {...props}
          />
        )
      case 'DELETE_ALBUM':
        return (
          <ActionDeleteAlbum
            action={action}
            dispatch={dispatch}
            disabled={disabled}
            {...props}
          />
        )
      case 'DELETE_PHOTOS':
        return (
          <ActionDeletePhotos
            action={action}
            dispatch={dispatch}
            disabled={disabled}
            {...props}
          />
        )
      case 'ADD_COMMENT':
        return (
          <ActionAddComment
            action={action}
            dispatch={dispatch}
            disabled={disabled}
            {...props}
            commentCount={commentCount}
          />
        )
      case 'DELETE_SHARED_PHOTO_LINK':
        return (
          <ActionDeleteSharedGroupOfPhotos
            action={action}
            dispatch={dispatch}
            disabled={disabled}
            {...props}
          />
        )
      case 'DOWNLOAD_PHOTO':
        return (
          <ActionDownloadPhoto
            action={action}
            dispatch={dispatch}
            disabled={disabled}
            {...props}
          />
        )
      case 'DOWNLOAD_PHOTOS':
        return (
          <ActionDownloadPhotos
            action={action}
            dispatch={dispatch}
            disabled={disabled}
            {...props}
          />
        )
      case 'FIND_SIMILAR':
        return (
          <ActionFindSimilar
            action={action}
            dispatch={dispatch}
            disabled={disabled}
            {...props}
          />
        )
      case 'FOLLOW_ALBUM':
        return (
          <ActionFollowAlbum
            action={action}
            dispatch={dispatch}
            disabled={disabled}
            {...props}
          />
        )
      case 'FOLLOW_ALBUM_ANONYMOUS':
        return (
          <ActionFollowAlbumAnonymous
            action={action}
            dispatch={dispatch}
            disabled={disabled}
            {...props}
          />
        )
      case 'HIDE_PHOTO':
        return (
          <ActionHidePhotos
            action={action}
            dispatch={dispatch}
            disabled={disabled}
            {...props}
          />
        )
      case 'LEAVE_ALBUM':
        return (
          <ActionLeaveAlbum
            action={action}
            dispatch={dispatch}
            disabled={disabled}
            {...props}
          />
        )
      case 'PLAY_ALBUM_SLIDESHOW':
        return (
          <ActionPlayAlbumSlideshow
            action={action}
            dispatch={dispatch}
            disabled={disabled}
            {...props}
          />
        )
      case 'RENAME_ALBUM':
        return null
      case 'REMOVE_PHOTOS_FROM_ALBUM':
        return (
          <ActionRemovePhotosFromAlbum
            action={action}
            dispatch={dispatch}
            disabled={disabled}
            {...props}
          />
        )
      case 'SET_ALBUM_ACCESS':
        return <Box>SET_ALBUM_ACCESS not implemented</Box>
      case 'SHARE_ALBUM':
        return (
          <ActionShareAlbum
            action={action}
            dispatch={dispatch}
            disabled={disabled}
            {...props}
          />
        )
      case 'SHARE_PHOTO':
        return (
          <ActionSharePhotos
            action={action}
            dispatch={dispatch}
            disabled={disabled}
            {...props}
          />
        )
      case 'SHOW_INFO':
        return (
          <ActionShowInfo
            action={action}
            dispatch={dispatch}
            disabled={disabled}
            {...props}
          />
        )
      case 'UNHIDE_PHOTO':
        return (
          <ActionUnhidePhoto
            action={action}
            dispatch={dispatch}
            disabled={disabled}
            {...props}
          />
        )
      case 'UNSHARE_ALBUM':
        return (
          <ActionUnshareAlbum
            action={action}
            dispatch={dispatch}
            disabled={disabled}
            {...props}
          />
        )
      case 'UPLOAD_PHOTO':
        return <Box>UPLOAD_PHOTO not implemented</Box>
    }
  },
)

export const PhotoActionHandlers = observer<PhotoActionHandlersProps>(
  function PhotoActionHandlers({
    actionContext,
    hideDisabled = false,
    ...props
  }) {
    return (
      <>
        {getPhotoActions(actionContext).map(action => (
          <PhotoActionHandler
            key={action.actionType}
            hideDisabled={hideDisabled}
            action={action}
            {...props}
          />
        ))}
      </>
    )
  },
)
