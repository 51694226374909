import type { BrandTheme } from '@jotta/ui/types/BrandTheme'
import { fileKindConfig } from '@jotta/file-utils'
import moize from 'moize'
import { mapValues } from 'remeda'
export const getFileIcons = moize(
  function getFileIcons(theme: BrandTheme) {
    return mapValues(fileKindConfig, config => {
      return {
        ...config,
        Icon: theme.icons[config.icon],
      }
    })
  },
  {
    maxSize: 100,
    transformArgs: ([theme, locale]) => [theme.branding.name, locale],
  },
)
