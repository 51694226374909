import { Button } from '@jotta/ui/Button'
import type { AlertDialogProps } from '@radix-ui/react-alert-dialog'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@radix-ui/react-alert-dialog'
import type { ReactNode } from 'react'
import type { AlertMessageParam } from '../../Elements/Alert/Alert.store'
import styles from './ConfirmDialog.module.scss'
export interface ConfirmDialogProps {
  title: string
  description: ReactNode
  cancelText: string
  confirmText: string
  danger?: boolean
  triggerButton: ReactNode
  onConfirm: () => void
  onConfirmAlert?: AlertMessageParam
}

export function ConfirmDialog({
  title,
  description,
  cancelText,
  confirmText,
  triggerButton,
  danger,
  onConfirm,
  ...props
}: ConfirmDialogProps & AlertDialogProps) {
  return (
    <AlertDialog {...props}>
      <AlertDialogTrigger asChild>{triggerButton}</AlertDialogTrigger>
      <AlertDialogOverlay
        sx={{
          variant: 'dialogs.overlay',
        }}
      >
        <AlertDialogContent
          sx={{
            variant: 'dialogs.wrapper',
          }}
          className={styles.dialog}
        >
          <AlertDialogTitle>{title}</AlertDialogTitle>
          <AlertDialogDescription>{description}</AlertDialogDescription>

          <div className={styles.buttons}>
            <AlertDialogCancel asChild>
              <Button variant="buttons.secondary">{cancelText}</Button>
            </AlertDialogCancel>
            <AlertDialogAction asChild>
              <Button
                variant={danger ? 'buttons.error' : 'buttons.accent'}
                onClick={onConfirm}
              >
                {confirmText}
              </Button>
            </AlertDialogAction>
          </div>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
