import * as Toast from '@radix-ui/react-toast'
import styles from './RadixDialogToast.module.scss'
export type RadixDialogToastViewportProps = Toast.ToastViewportProps
export function RadixDialogToastViewport(props: RadixDialogToastViewportProps) {
  return <Toast.Viewport {...props} className={styles.viewport} />
}
export function RadixDialogToastBottomViewport(
  props: RadixDialogToastViewportProps,
) {
  return <Toast.Viewport {...props} className={styles.viewportBottom} />
}
