import type { ButtonProps } from '@jotta/ui/Button'
import { t } from '@lingui/macro'
import type { FC } from 'react'

export interface HamburgerProps extends ButtonProps {
  isOpen?: boolean
}

export const Hamburger: FC<HamburgerProps> = ({
  children,
  isOpen,
  variant = 'text',
  ...props
}) => {
  return (
    <button
      aria-label={t`Open the menu`}
      sx={{
        display: 'flex',
        height: '100%',
      }}
      {...props}
    >
      <svg
        viewBox="0 0 24 10"
        sx={{
          width: '24px',
          height: '24px',
          transition: 'opacity 300ms',
          opacity: 'var(--hamburger-opacity, 0.5)',
          strokeWidth: '2px',
          strokeLinecap: 'round',
          alignSelf: 'center',
          '> line': {
            transition: 'transform 300ms, opacity 300ms',
            transformOrigin: 'center center',
          },
        }}
      >
        <line
          x1="3"
          y1="50%"
          x2="19"
          y2="50%"
          stroke="currentColor"
          sx={{
            transform: [
              isOpen ? 'translateY(10%) rotateZ(45deg)' : 'translateY(-50%)',
              undefined,
              'translateY(-50%)',
            ],
          }}
        />
        <line
          x1="3"
          y1="50%"
          x2="19"
          y2="50%"
          stroke="currentColor"
          sx={{
            opacity: [isOpen ? 0 : 1, undefined, 1],
            transform: [
              isOpen ? 'rotateZ(-135deg)' : 'rotateZ(0deg)',
              undefined,
              'rotateZ(0deg)',
            ],
          }}
        />
        <line
          x1="3"
          y1="50%"
          x2="19"
          y2="50%"
          stroke="currentColor"
          sx={{
            transform: [
              isOpen ? 'rotateZ(-45deg)' : 'translateY(50%)',
              undefined,
              'translateY(50%)',
            ],
          }}
        />
      </svg>
      {!!children && <span>{children}</span>}
    </button>
  )
}
