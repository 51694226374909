import { Box } from '@jotta/ui/Box'
import { BrandIcon } from '@jotta/ui/BrandIcon'
import type { PhotosApi } from '@jotta/types/PhotosApi'
import { Trans } from '@lingui/macro'
import { DialogTrigger } from '@radix-ui/react-dialog'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import type { PhotoActionDispatcher } from '../../PhotoActions/useDispatchPhotoActionMutation'
import { useDispatchPhotoActionMutation } from '../../PhotoActions/useDispatchPhotoActionMutation'
import { BaseAlbumThumb } from '../AlbumThumb/BaseAlbumThumb'
import type { CreateAlbumDialogProps } from '../Dialogs/CreateAlbumDialog'
import { CreateAlbumDialog } from '../Dialogs/CreateAlbumDialog'

const debug = Debug('jotta:photos:CreateAlbumFromAlbumThumb')

interface CreateAlbumFromAlbumThumbProps
  extends Partial<CreateAlbumDialogProps> {
  dispatch?: PhotoActionDispatcher
  onAlbumCreated?: (album: PhotosApi.AlbumInfo) => void
}
export const CreateAlbumFromAlbumThumb =
  observer<CreateAlbumFromAlbumThumbProps>(function CreateAlbumFromAlbumThumb({
    dispatch,
    onAlbumCreated,
    ...props
  }) {
    const [open, setOpen] = useState(false)
    const onClose = props.onClose || setOpen
    const isOpen = props.open ?? open
    const mutation = useDispatchPhotoActionMutation(
      'CREATE_ALBUM',
      {
        onSettled: async albumPromise => {
          const album = await albumPromise
          if (album && onAlbumCreated) {
            onAlbumCreated(album)
          }
          debug('onSettled', album)
          onClose(false)
        },
      },
      dispatch,
    )
    return (
      <CreateAlbumDialog
        open={isOpen}
        onClose={onClose}
        createAlbumFn={async (ids, title) => {
          const album = await mutation.mutateAsync([ids, title])
          return album
        }}
        triggerElement={
          <DialogTrigger data-testid="ACTION_CREATE_ALBUM">
            <BaseAlbumThumb key={'new_album'}>
              <BrandIcon icon="SvgAdd" variant="styles.addMoreSvg" />
              <Box as="p" variant="styles.newAlbumP">
                <Trans>New album</Trans>
              </Box>
            </BaseAlbumThumb>
          </DialogTrigger>
        }
        {...props}
      />
    )
  })
