/* eslint-disable jsx-a11y/no-autofocus */
import {
  createConnectQueryKey,
  useMutation,
  useQuery,
} from '@connectrpc/connect-query'
import type { Person } from '@jotta/grpc-connect-openapi/esm/openapi/people/v1/people.v1_pb'
import {
  getMergeCandidates,
  getPeople,
  getPerson,
  patchPerson,
} from '@jotta/grpc-connect-openapi/peopleQuery'
import { Trans, t } from '@lingui/macro'
import type { FormEvent } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import type { Selection } from 'react-aria-components'
import {
  Button,
  Dialog,
  DialogTrigger,
  Form,
  Label,
  ListBox,
  ListBoxItem,
  Popover,
} from 'react-aria-components'
import { Input } from '@jotta/uinext/Input'
import { getPeopleFromMergeCandidatesResponse } from '../../api/PeopleApi'
import { PersonImage } from '../PersonList/PersonImage'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import { queryClient } from '@jotta/query'
import { ConfirmMergePeopleDialog } from './ConfirmMergePeopleDialog'
import { BrandIcon } from '@jotta/ui/BrandIcon'

export function EditPersonForm({ person }: { person: Person }) {
  const [name, setName] = useState(person.name || '')
  const [isOpen, setIsOpen] = useState(false)
  const [confirmMergeDialogIsOpen, setConfirmMergeDialogIsOpen] =
    useState(false)
  const [selected, setSelected] = useState<Selection>(new Set())
  const getMergeCandidatesQuery = useQuery(getMergeCandidates, {
    personId: person.id,
  })

  useEffect(() => {
    if (!isOpen && !confirmMergeDialogIsOpen) {
      setSelected(new Set())
    }
  }, [isOpen, confirmMergeDialogIsOpen])

  const people = getMergeCandidatesQuery.isFetching
    ? []
    : getPeopleFromMergeCandidatesResponse(getMergeCandidatesQuery.data)
  const editPersonMutation = useMutation(patchPerson, {
    onSuccess: () => {
      setIsOpen(false)
    },
    onSettled: () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: createConnectQueryKey(getPerson, {
            id: person.id,
          }),
        }),
        queryClient.invalidateQueries({
          queryKey: createConnectQueryKey(getPeople),
        }),
      ]),
  })

  // useEffect(() => {
  //   function cancelEditMode(e: KeyboardEvent) {
  //     if (e.key === 'Escape') {
  //       setEditmode(false)
  //     }
  //   }
  //   document.addEventListener('keydown', cancelEditMode)

  //   return () => {
  //     document.removeEventListener('keydown', cancelEditMode)
  //   }
  // }, [])

  function updateName(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    const selectedPeople =
      selected === 'all' ? people : people.filter(p => selected.has(p.id))
    const showMergePeopleConfirmationDialog = selectedPeople.length

    const data = new FormData(e.currentTarget)
    const name = String(data.get('name'))
    const nameHasChanged = name !== String(person?.name)
    if (showMergePeopleConfirmationDialog) {
      setConfirmMergeDialogIsOpen(true)
    }
    if (nameHasChanged) {
      editPersonMutation.mutate({
        name,
        personId: person.id,
      })
    } else {
      setIsOpen(false)
    }
  }
  const peopleSlice = people.length > 0 ? [person, ...people.slice(0, 15)] : []

  return (
    <>
      <LoadingOverlay open={editPersonMutation.isPending} position="absolute" />
      <DialogTrigger>
        <Button
          onPress={() => setIsOpen(true)}
          className="group flex h-12 items-center gap-4 rounded-lg px-2 text-lg font-bold text-sx-primary hover:bg-sx-primary/20 focus:bg-sx-primary/20"
        >
          {person.name || t`Add name`}
          <BrandIcon
            icon="SvgEdit"
            className="hidden group-hover:block group-focus:block"
          />
        </Button>
        <Popover
          isOpen={isOpen}
          onOpenChange={setIsOpen}
          placement={'bottom start'}
          offset={-56}
          crossOffset={-8}
        >
          <Dialog className=" w-full overflow-hidden rounded-2xl bg-white shadow-dialog">
            <Form className="flex flex-col gap-4 pt-2" onSubmit={updateName}>
              <div className="mx-4 flex h-12 py-1">
                <Input
                  autoFocus
                  onFocus={() => setIsOpen(true)}
                  value={name}
                  name="name"
                  onChange={e => setName(e.target.value)}
                  placeholder={t`Add name`}
                  className="w-full flex-auto text-lg placeholder:text-sx-primary"
                />
                <Button
                  type="submit"
                  isDisabled={editPersonMutation.isPending}
                  className="rounded-lg bg-sx-primary px-4 py-2 leading-none text-sx-primary-text"
                >
                  <Trans>Save</Trans>
                </Button>
              </div>
              {people.length > 0 && (
                <Label htmlFor="mergecandidates" className="snap-start px-4">
                  {name ? (
                    <Trans>Can you find more pictures of {name}?</Trans>
                  ) : (
                    <Trans>Can you find more pictures of this person</Trans>
                  )}
                </Label>
              )}
              <div className="snap-y snap-proximity scroll-py-2 overflow-y-auto px-4 py-2">
                <ListBox
                  aria-label={t`Can you find more pictures of this person`}
                  id="mergecandidates"
                  items={peopleSlice}
                  selectedKeys={selected}
                  disabledKeys={[person.id]}
                  onSelectionChange={setSelected}
                  layout="grid"
                  selectionMode={'multiple'}
                  className="grid w-full snap-start grid-cols-[repeat(4,120px)] gap-4"
                >
                  {p => (
                    <ListBoxItem
                      textValue={p.id}
                      className="group relative size-full snap-start rounded-md bg-gray-500 !shadow-none ring-transparent
                        ring-offset-2 hover:cursor-pointer focus-visible:ring-4 focus-visible:ring-blue-500 selected:opacity-100 selected:ring-4 selected:ring-blue-600"
                    >
                      <PersonImage
                        className="aspect-square size-full rounded-lg object-cover object-center opacity-50 transition-opacity duration-200 group-data-selected:opacity-100 group-hover:opacity-100 group-focus-visible:opacity-100"
                        person={p}
                      />
                      <svg
                        viewBox="0 0 18 18"
                        aria-hidden="true"
                        className={`absolute left-1 top-1  size-5 rounded-md text-white ${p === person ? 'bg-gray-600' : 'hidden bg-blue-600'} group-aria-selected:block`}
                        fill="none"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M4.5 10l3 3l6 -6" stroke="currentColor" />
                      </svg>
                    </ListBoxItem>
                  )}
                </ListBox>
              </div>
            </Form>
          </Dialog>
        </Popover>
      </DialogTrigger>
      {confirmMergeDialogIsOpen && (
        <ConfirmMergePeopleDialog
          person={person}
          name={name}
          people={people}
          selection={selected}
          setSelection={setSelected}
          open={confirmMergeDialogIsOpen}
          onOpenChange={setConfirmMergeDialogIsOpen}
        />
      )}
    </>
  )
}
