import { Box } from '@jotta/ui/Box'
import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { useNavigate } from 'react-router-dom'
import styles from './PhotoGallery.module.scss'

export const SearchSimilarButton = ({ photoId }: { photoId: string }) => {
  const navigate = useNavigate()

  return (
    <Box className={styles.findSimilarButton}>
      <button
        aria-label={'Find similar'}
        data-testid="FindSimilarPhotosButton"
        onClick={e => {
          navigate(`/photo?id=${photoId}`)
          e.preventDefault()
        }}
        sx={{
          variant: 'buttons.albumActionButton',
          backgroundColor: 'white',
          borderRadius: '20px',
          padding: 2,
        }}
      >
        <PlainBrandIcon icon="SvgPhotoSearch" />
      </button>
    </Box>
  )
}
