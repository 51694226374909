import Debug from 'debug'
import type { Photos } from '../Photos'
import { createEnum } from './schemaUtils'
const debug = Debug('jotta:photos:actions')
const photoActionContextEnum = createEnum('PhotoActionContextType', [
  'ADD_PHOTOS_TO_ALBUM_SELECTED_PHOTOS',
  'ALBUM_LIST',
  'ALBUM_VIEW_PHOTO',
  'ALBUM_VIEW_SELECTED_PHOTOS',
  'ALBUM_VIEW_NORMAL_ALBUM',
  'ALBUM_VIEW_META_SHARED_ALBUM_TYPES',
  'ALBUM_VIEW_LOCATION_ALBUM',
  'ALBUM_VIEW_LOCATION_ALBUM_SELECTED_PHOTOS',
  'ALBUM_VIEW_LOCATION_ALBUM_VIEW_PHOTO',
  'SHARED_GROUP_OF_PHOTOS',
  'SHARED_GROUP_OF_PHOTOS_VIEW_PHOTO',
  'SHARED_ALBUM_SELECTED_PHOTOS',
  'SHARED_ALBUM_VIEW_PHOTO',
  'SHARED_ALBUM_VIEW_LOGGED_IN',
  'SHARED_ALBUM_VIEW_ANONYMOUS',
  'SHARED_PHOTOS_SELECTED_PHOTOS',
  'SMART_PHOTO_SEARCH',
  'SMART_SEARCH_VIEW_PHOTO',
  'TIMELINE_HIDDEN_PHOTOS_SELECTED_PHOTOS',
  'TIMELINE_HIDDEN_PHOTOS_VIEW_PHOTO',
  'TIMELINE_HIDDEN_PHOTOS',
  'TIMELINE_PHOTOS_SELECTED_PHOTOS',
  'TIMELINE_PHOTOS_VIEW_PHOTO',
  'TIMELINE_PHOTOS',
  'UPLOADS_COMPLETE_DIALOG',
])

const photoActionEnum = createEnum('PhotoActionType', [
  'ADD_COMMENT',
  'ADD_PHOTOS_ANONYMOUS',
  'ADD_PHOTOS_TO_ALBUM',
  'ADD_PHOTOS',
  'COPY_SHARED_ALBUM_LINK',
  'CREATE_ALBUM',
  'DELETE_ALBUM',
  'DELETE_PHOTOS',
  'DELETE_SHARED_PHOTO_LINK',
  'DOWNLOAD_PHOTOS',
  'DOWNLOAD_PHOTO',
  'FIND_SIMILAR',
  'FOLLOW_ALBUM_ANONYMOUS',
  'FOLLOW_ALBUM',
  'HIDE_PHOTO',
  'LEAVE_ALBUM',
  'PLAY_ALBUM_SLIDESHOW',
  'REMOVE_PHOTOS_FROM_ALBUM',
  'RENAME_ALBUM',
  'SET_ALBUM_ACCESS',
  'SHARE_ALBUM',
  'SHARE_PHOTO',
  'SHOW_INFO',
  'UNHIDE_PHOTO',
  'UNSHARE_ALBUM',
  'UPLOAD_PHOTO',
])
export const {
  photoActionContextTypeSchema,
  getPhotoActionContextType,
  isPhotoActionContextType,
} = photoActionContextEnum
export const { photoActionTypeSchema, getPhotoActionType, isPhotoActionType } =
  photoActionEnum
export const photoActionTypes =
  photoActionEnum.photoActionTypes as readonly Photos.ActionType[]
export const photoActionContextTypes =
  photoActionContextEnum.photoActionContextTypes as readonly Photos.ActionContextType[]
