import Debug from 'debug'
const debug = Debug('jotta:utils:copyToClipboard')

export async function copyToClipboard(value: string, forceExecCommand = false) {
  if (navigator.clipboard && !forceExecCommand) {
    debug('Copy using Clipboard api: %s', value)
    try {
      const res = await navigator.clipboard.writeText(value)
      debug('Result', res)

      return
    } catch (err) {
      debug('Error', err)
    }
  }
  debug('Copy using execCommand: %s', value)
  const t = document.createElement('textarea')
  t.style.position = 'fixed'
  t.style.top = '-200%'
  t.value = value
  document.body.appendChild(t)
  t.focus()
  t.setSelectionRange(0, value.length)
  const r = document.execCommand('copy')
  document.body.removeChild(t)
  return r ? Promise.resolve() : Promise.reject()
}
