import { produce } from 'immer'
import { nanoid } from 'nanoid'
import { objectify } from 'radash'
import type { BizUser } from './BizApi'
import { BizMockUsers } from './__mock__/BizMockUsers'
export const BizMockServerState: {
  users: Record<string, BizUser>
} = {
  users: objectify(BizMockUsers, item => item.username),
}

export async function getBizUsers({
  sortBy = 'fullName',
  order = 'asc',
  limit = 100,
}: {
  sortBy?: 'fullName' | 'spaceUsed' | 'lastUpload'
  order?: 'asc' | 'desc'
  limit?: number
} = {}) {
  const users = structuredClone(Object.values(BizMockServerState.users))
  switch (sortBy) {
    case 'fullName':
      users.sort((a, b) => a.fullName.localeCompare(b.fullName))
      break
    case 'spaceUsed':
      users.sort((a, b) => b.spaceUsed - a.spaceUsed)
      break
    case 'lastUpload':
      users.sort((a, b) => b.lastUpload - a.lastUpload)
      break
  }
  if (order === 'desc') {
    users.reverse()
  }
  return users.slice(0, limit)
}
export async function getBizUserByUsername({ username }: { username: string }) {
  if (username in BizMockServerState.users) {
    const user = structuredClone(BizMockServerState.users[username])
    return user
  }
  throw new Error(`User with username ${username} not found`)
}
export async function updateBizUser({ user }: { user: BizUser }) {
  BizMockServerState.users = await produce(BizMockServerState.users, draft => {
    if (!draft[user.username]) {
      throw new Error(`User not found: ${user.username}`)
    }
    draft[user.username] = user
  })
}
export async function addBizUser({ user }: { user: BizUser }) {
  BizMockServerState.users = await produce(BizMockServerState.users, draft => {
    if (user.username) {
      throw new Error('User name must be empty when adding a new user')
    }
    const username = nanoid()
    draft[username] = {
      ...user,
      username,
    }
  })
}
export async function deleteBizUser({ username }: { username: string }) {
  BizMockServerState.users = await produce(BizMockServerState.users, draft => {
    if (!draft[username]) {
      throw new Error(`User not found: ${username}`)
    }
    delete draft[username]
  })
}
