import {
  CommentServiceAuthPromiseClient,
  CommentServiceNonAuthPromiseClient,
} from '@jotta/grpc-web/no/jotta/openapi/comment/v2/comment.v2_grpc_web_pb'
import {
  GetCommentRequest,
  AddCommentRequest,
  AddReplyRequest,
  UpdateCommentRequest,
  GetCommentsRequest,
  Direction,
  GetRepliesRequest,
  GetGroupRequest,
  GetGroupCommentCountRequest,
  DeleteCommentRequest,
} from '@jotta/grpc-web/no/jotta/openapi/comment/v2/comment.v2_pb'
import { withGrpcClientMiddleware } from './grpcutils'
import { env } from '@jotta/utils/env'

export {
  Comment,
  Direction,
} from '@jotta/grpc-web/no/jotta/openapi/comment/v2/comment.v2_pb'

export const commentServiceClientAuth: CommentServiceAuthPromiseClient =
  withGrpcClientMiddleware(
    'comment',
    new CommentServiceAuthPromiseClient(env.grpcApi),
  )
export const commentServiceClientNonAuth: CommentServiceNonAuthPromiseClient =
  withGrpcClientMiddleware(
    'comment',
    new CommentServiceNonAuthPromiseClient(env.grpcApi),
  )

export async function addComment(text: string, contextKey: string) {
  const req = new AddCommentRequest()
  req.setText(text)
  req.setContextKey(contextKey)
  const res = await commentServiceClientAuth.addComment(req)
  return res.toObject().comment
}

export async function addReply(
  text: string,
  commentId: string,
  contextKey: string,
) {
  const req = new AddReplyRequest()
  req.setText(text)
  req.setCommentId(commentId)
  req.setContextKey(contextKey)
  const res = await commentServiceClientAuth.addReply(req)
  return res.toObject().comment
}

export async function updateComment(text: string, commentId: string) {
  const req = new UpdateCommentRequest()
  req.setText(text)
  req.setCommentId(commentId)
  const res = await commentServiceClientAuth.updateComment(req)
  return res.toObject().comment
}

export async function getComments(
  contextKey: string,
  cursor?: string,
  direction: Direction = Direction.DESCENDING,
  sort: Direction = Direction.ASCENDING,
) {
  const req = new GetCommentsRequest()
  req.setContextKey(contextKey)
  if (cursor !== undefined) {
    req.setCursor(cursor)
  }
  req.setDirection(direction)
  req.setSort(sort)
  req.setSortReplies(Direction.ASCENDING)
  const res = await commentServiceClientNonAuth.getComments(req)

  return res.toObject()
}

export async function getAggregatedComments(
  contextKey: string,
  cursor?: string,
  direction: Direction = Direction.DESCENDING,
  sort: Direction = Direction.ASCENDING,
) {
  const req = new GetCommentsRequest()
  req.setContextKey(contextKey)
  if (cursor !== undefined) {
    req.setCursor(cursor)
  }
  req.setDirection(direction)
  req.setSort(sort)
  req.setSortReplies(Direction.ASCENDING)
  const res = await commentServiceClientNonAuth.getAggregatedComments(req)

  return res.toObject()
}

export async function getReplies(
  commentId: string,
  contextKey: string,
  cursor?: string,
  direction: Direction = Direction.DESCENDING,
  sort: Direction = Direction.ASCENDING,
  limit: number = 5,
) {
  const req = new GetRepliesRequest()
  req.setCommentId(commentId)
  req.setContextKey(contextKey)
  if (cursor !== undefined) {
    req.setCursor(cursor)
  }
  req.setDirection(direction)
  req.setSort(sort)
  if (limit) {
    req.setLimit(limit)
  }
  const res = await commentServiceClientNonAuth.getReplies(req)

  return res.toObject()
}

export async function getComment(id: string, contextKey: string) {
  const req = new GetCommentRequest()
  req.setCommentId(id)
  req.setContextKey(contextKey)
  req.setSort(Direction.ASCENDING)
  req.setLimit(5)
  const res = await commentServiceClientNonAuth.getComment(req)

  return res.toObject().comment
}

export async function getGroupCommentCount(contextKey: string) {
  const req = new GetGroupCommentCountRequest()
  req.setContextKey(contextKey)
  const res = await commentServiceClientNonAuth.getGroupCommentCount(req)

  return res.toObject()
}

export async function getGroup(contextKey: string) {
  const req = new GetGroupRequest()
  req.setContextKey(contextKey)
  const res = await commentServiceClientNonAuth.getGroup(req)

  return res.toObject()
}

export async function deleteComment(commentId: string) {
  const req = new DeleteCommentRequest()
  req.setCommentId(commentId)
  const res = await commentServiceClientAuth.deleteComment(req)
  return res.toObject()
}
