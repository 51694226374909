import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { t } from '@lingui/macro'
import { DialogTrigger } from '@radix-ui/react-dialog'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { LoginToContinueDialog } from '../Album/Dialogs/LoginToContinueDialog'
import type { PhotoActionHandlerActionProps } from './PhotoActionHandlers'

export const ActionFollowAlbumAnonymous = observer<
  PhotoActionHandlerActionProps<'FOLLOW_ALBUM_ANONYMOUS'>
>(function ActionFollowAlbumAnonymous({
  action,
  shareId = '',
  buttonVariant = 'buttons.albumActionButton',
  onActionDialogClose,
}) {
  const [open, setOpen] = useState(false)

  const isDisabled = !shareId
  const url = `/share/${shareId}/follow`
  return (
    <LoginToContinueDialog
      open={open}
      onClose={open => {
        setOpen(open)
        if (!open && onActionDialogClose) {
          onActionDialogClose(action.actionType)
        }
      }}
      url={url}
      title={t`Would you like to follow this album?`}
      triggerElement={
        <DialogTrigger
          aria-label={action.label}
          data-testid={action.testid}
          disabled={isDisabled}
          key={action.actionType}
          sx={{
            variant: buttonVariant,
          }}
        >
          <PlainBrandIcon icon={action.icon} />
          <span>{action.label}</span>
        </DialogTrigger>
      }
    />
  )
})
