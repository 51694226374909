import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import type { MouseEvent } from 'react'
import { useEffect } from 'react'
import { sentryCaptureMessage } from '@jotta/utils/error'
import { useIntercomConfig } from './useIntercomConfig'

const debug = Debug('jotta:intercom')

export interface IntercomUserData {
  username: string
  userHash: string
  fullName: string
  email: string
}

let bootTimeout: NodeJS.Timeout | undefined

declare global {
  interface Window {
    intercomSettings: Record<string, string>
    CookieConsent?: {
      renew?: () => void
    }
    Intercom?: (
      action:
        | 'boot'
        | 'shutdown'
        | 'show'
        | 'showNewMessage'
        | 'hide'
        | 'onShow'
        | 'onHide'
        | 'update'
        | 'reattach_activator'
        | 'showArticle',
      callback?: (() => void) | Record<string, string> | string | number,
    ) => void
  }
}
export const Intercom = observer(function Intercom() {
  const intercomConfig = useIntercomConfig()

  useEffect(() => {
    if (intercomConfig) {
      try {
        initIntercom(intercomConfig.appId, intercomConfig.userData)
      } catch (error) {
        sentryCaptureMessage('Failed to initialize Intercom', error)
      }
    } else {
      try {
        disableIntercom()
      } catch (error) {
        sentryCaptureMessage('Failed to disable Intercom', error)
      }
    }
  }, [intercomConfig])
  return null
})

const intercomPlaceholder: {
  q: any[]
  c: (args: any[]) => void
  (...args: any[]): void
} = (...args) => {
  intercomPlaceholder.c(args)
}
intercomPlaceholder.q = []
intercomPlaceholder.c = args => intercomPlaceholder.q.push(args)

function initIntercom(appId: string, userData?: IntercomUserData) {
  window.intercomSettings = userData
    ? {
        app_id: appId,
        name: userData.fullName,
        email: userData.email,
        user_id: userData.username,
        user_hash: userData.userHash,
      }
    : {
        app_id: appId,
      }
  const intercom = window.Intercom
  if (typeof intercom === 'function') {
    debug('Update', appId, window.intercomSettings)
    intercom('reattach_activator')
    intercom('update', window.intercomSettings)
  } else {
    debug('Load', appId, window.intercomSettings)
    window.Intercom = intercomPlaceholder
    if (document.readyState === 'complete') {
      insertIntercomScriptTag()
    } else {
      window.addEventListener('load', insertIntercomScriptTag, false)
    }
  }
  bootTimeout = setTimeout(() => bootIntercom(appId, userData), 100)
  function insertIntercomScriptTag() {
    const s = document.createElement('script')
    s.type = 'text/javascript'
    s.async = true
    s.src = `https://widget.intercom.io/widget/${appId}`
    const scriptEl = document.getElementsByTagName('script')[0]
    if (scriptEl) {
      if (scriptEl.id === 'CookieConsent') {
        scriptEl.after(s)
      } else {
        scriptEl.before(s)
      }
    }
  }
}

function bootIntercom(appId: string, userData?: IntercomUserData) {
  if (!window.Intercom) {
    bootTimeout = setTimeout(() => bootIntercom(appId, userData), 500)
    return
  }
  window.Intercom('onShow', () => document.body.classList.add('intercom'))
  window.Intercom('onHide', () => document.body.classList.remove('intercom'))
  window.Intercom(
    'boot',
    userData
      ? {
          app_id: appId,
          user_id: userData.username,
          user_hash: userData.userHash,
          name: userData.fullName,
          email: userData.email,
        }
      : {
          app_id: appId,
        },
  )
}

export function showIntercom(e: MouseEvent<HTMLElement>) {
  // debug('show')
  if (window.Intercom) {
    e.preventDefault()
    window.Intercom('show')
  }
}

export function showNewIntercomMessage(
  e: MouseEvent<HTMLElement>,
  content?: string,
) {
  if (window.Intercom) {
    e.preventDefault()
    window.Intercom('show')
    window.Intercom('showNewMessage', content)
  }
}

export function disableIntercom() {
  if (bootTimeout) {
    clearTimeout(bootTimeout)
  }
  if (window.Intercom) {
    window.Intercom('shutdown')
  }
}

export function showArticle(id: number | string) {
  if (window.Intercom) {
    window.Intercom('showArticle', id)
  }
}
