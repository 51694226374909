/* eslint-disable @typescript-eslint/no-explicit-any */
import { computed, makeAutoObservable, observable } from 'mobx'
import { getRouterStore } from '@jotta/router'
import Debug from 'debug'
import type { ChangeEvent, FormEvent, SyntheticEvent } from 'react'
import { HTMLCollectionStore } from '@jotta/collection/HTMLCollectionStore'

// #region Type aliases (1)

export type PhotoSearchDropdownItem = {
  type: 'tag' | 'suggestion' | 'learnmore'
  id: string
}

export class PhotoSearchStore {
  // #region Properties (7)

  private _query = ''
  private log = Debug('jotta:photos:search:store')

  public dropdownIsOpen = false
  public ids: {
    root: string
    form: string
    input: string
    dropdown: string
    activetag: string
    suggestion: string
  }
  tagElements: HTMLCollectionStore<HTMLButtonElement>
  suggestionElements: HTMLCollectionStore<HTMLButtonElement>

  public isFocused = false
  public isOpen = false
  public router = getRouterStore()

  // #endregion Properties (7)

  // #region Constructors (1)

  constructor(reactId: string) {
    const id = reactId.replaceAll(':', '-')
    this.ids = {
      root: id,
      form: `${id}form`,
      input: `${id}input`,
      dropdown: `${id}dropdown`,
      activetag: `${id}activetag`,
      suggestion: `${id}suggestion`,
    }
    this.tagElements = new HTMLCollectionStore<HTMLButtonElement>({
      classNames: this.ids.activetag,
      idAttribute: 'tag',
    })
    this.suggestionElements = new HTMLCollectionStore<HTMLButtonElement>({
      classNames: this.ids.suggestion,
      idAttribute: 'suggestion',
    })
    this._query = new URLSearchParams(window.location.search).get('q') || ''
    makeAutoObservable<typeof this, '_query' | 'log'>(
      this,
      {
        _query: observable,
        ids: false,
        log: false,
        input: false,
        selectedTags: computed.struct,
        tagElements: false,
        suggestionElements: false,
      },
      {
        autoBind: true,
      },
    )
    this.log('init', id)
  }

  // #endregion Constructors (1)

  // #region Public Getters And Setters (7)

  public get dropdown() {
    return document.getElementById(this.ids.dropdown) as HTMLDivElement
  }

  public get input() {
    return document.getElementById(this.ids.input) as HTMLInputElement
  }

  public get query() {
    return this._query
  }

  public set query(value) {
    this._query = value
  }

  public get searchParams() {
    const params = new URLSearchParams(this.router.search)
    const id = params.get('id') || ''
    const q = params.get('q') || ''
    const view = params.get('view') || ''
    const p = params.getAll('p')
    const hasPeople = params.has('p')
    const hasQuery = params.has('q')
    const hasId = params.has('id')
    const hasView = params.has('view')
    const isOpen = hasId || hasQuery || hasPeople
    const carouselIsOpen = hasView
    return {
      id,
      q,
      p,
      view,
      hasPeople,
      hasQuery,
      hasId,
      isOpen,
      carouselIsOpen,
    }
  }

  public get searchQuery() {
    return this.router.search
  }

  public get selectedTags() {
    return this.searchParams.p
  }

  public get hasResults() {
    const params = this.searchParams
    return params.q || params.p.length > 0
  }

  public get isCleared() {
    return !this.query && !this.hasResults
  }

  // #endregion Public Getters And Setters (7)

  // #region Public Methods (17)

  public addId(id: string) {
    if (this.searchParams.id !== id) {
      const params = new URLSearchParams(this.router.search)
      params.set('id', id)
      this.router.searchParam(params)
    }
  }

  public addPerson(id: string) {
    if (!this.searchParams.p.includes(id)) {
      const params = new URLSearchParams(this.router.search)
      if (params.has('p')) {
        params.append('p', id)
      } else {
        params.set('p', id)
      }
      this.log('addPerson', id, params.toString())
      this.router.searchParam(params)
    }
  }
  public addSuggestedPerson(id: string) {
    this.addPerson(id)
    this.focusInput()
  }

  public addSuggestion(text: string) {
    const params = new URLSearchParams(this.router.search)
    params.set('q', text)
    this.query = text
    this.log('addSuggestion', text, params.toString())
    // this.dropdownIsOpen = false
    this.router.searchParam(params)
    this.focusInput()
  }

  public clear() {
    this.query = ''
    const params = new URLSearchParams()
    params.set('q', '')
    params.delete('p')
    this.router.searchParam(params)
    this.dropdownIsOpen = true
    this.focusInput()
  }

  public close() {
    if (this.isOpen || this.searchParams.isOpen) {
      // Do nothing, let carousel handle closing itself
      if (this.router.getSearchParams().has('photo')) {
        return
      }
      if (this.dropdownIsOpen) {
        this.dropdownIsOpen = false
      } else if (this.query || this.tagElements.elements.length) {
        this.clear()
      } else {
        this.router.clearSearchParam()
      }
    }
  }

  public closeCarousel() {
    if (this.searchParams.carouselIsOpen) {
      const p = new URLSearchParams(this.router.search)
      p.delete('view')
      this.router.searchParam(p)
    }
  }

  public onBlur() {
    this.isFocused = false
  }

  public onClick(e: SyntheticEvent<HTMLElement>) {
    this.log('onClick', e)
    const addPersonId = e.currentTarget.dataset.searchPersonAdd
    if (addPersonId) {
      this.addPerson(addPersonId)
      return
    }
    const id = e.currentTarget.dataset.searchIdAdd
    if (id) {
      this.addId(id)
      return
    }
    const q = e.currentTarget.dataset.searchQuery
    if (q) {
      this.setQuery(q)
      return
    }
  }

  focusInput() {
    this.input.focus()
  }

  public onDropdownOpenChange(open = false) {
    this.dropdownIsOpen = open
  }

  public onFocus() {
    if (!this.isOpen) {
      this.open()
    } else {
      this.isFocused = true
      this.dropdownIsOpen = true
      this.input.focus()
    }
  }
  public onInputFocus() {
    this.dropdownIsOpen = true
  }

  public onInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    const el = e.currentTarget
    switch (e.key) {
      case 'Backspace':
        {
          if (!el.selectionStart && !el.selectionEnd) {
            const activeId = this.tagElements.getActiveId()
            const lastId = this.tagElements.getLastId()
            this.log(activeId, lastId, this.tagElements)
            if (activeId) {
              this.removePerson(activeId)
            } else if (lastId) {
              this.removePerson(lastId)
            } else {
              this.tagElements.previous()
            }
            this.suggestionElements.clear()
          }
        }
        break
      case 'ArrowLeft':
        {
          if (!el.selectionStart && !el.selectionEnd) {
            this.tagElements.previous()
            this.suggestionElements.clear()
          }
        }
        break
      case 'ArrowRight':
        {
          if (!el.selectionStart && !el.selectionEnd) {
            this.tagElements.next()
            this.suggestionElements.clear()
          }
        }
        break
      case 'ArrowUp':
        {
          this.dropdownIsOpen = true
          this.tagElements.clear()
          this.suggestionElements.previous()
        }
        break
      case 'ArrowDown':
        {
          this.dropdownIsOpen = true
          this.tagElements.clear()
          this.suggestionElements.next()
        }
        break
      case 'Enter':
        {
          const el = this.suggestionElements.getActiveElement()
          if (el) {
            e.preventDefault()
            this.log('Click suggestions')
            this.suggestionElements.deactivateElement(el)
            el.click()
          }
          this.tagElements.clear()
          this.suggestionElements.clear()
        }
        break
    }
  }

  public onFocusRef(el: HTMLInputElement | null) {
    if (el) {
      el.focus()
    }
  }

  public onDocumentKeyDown(e: KeyboardEvent) {
    switch (e.key) {
      case 'Escape':
        this.close()
        break
      // case 'ArrowDown':
      //   {
      //     if (!this.dropdownIsOpen) {
      //       this.dropdownIsOpen = true
      //     }
      //   }
      //   break

      default:
        break
    }
  }

  public onQueryChange(e: ChangeEvent<HTMLInputElement>) {
    const value = e.currentTarget.value
    if (value !== this.query) {
      this.query = value
    }
  }

  public onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    const params = new URLSearchParams(this.router.search)
    params.set('q', this.query)
    params.delete('id')
    this.router.searchParam(params)
  }

  public open() {
    if (!this.isOpen) {
      this.isOpen = true
    }
    if (!this.searchParams.hasQuery) {
      const params = new URLSearchParams(this.router.search)
      params.set('q', '')
      this.router.searchParam(params)
    }
    this.input.focus()
    this.dropdownIsOpen = true
  }

  public removePerson(id: string) {
    if (this.searchParams.p.includes(id)) {
      const params = new URLSearchParams(this.router.search)
      params.delete('p', id)
      this.router.searchParam(params)
      this.focusInput()
    }
  }

  public setQuery(q: string) {
    if (q !== this.query) {
      this.query = q
    }
  }

  // #endregion Public Methods (17)
}

// #endregion Classes (1)
