import type { SelectHTMLAttributes } from 'react'
import { Trans } from '@lingui/macro'
import { useLocalizedCountryList } from '@jotta/i18n'
import clsx from 'clsx'

export type SelectProps = SelectHTMLAttributes<HTMLSelectElement>
export const Select = ({ className, ...rest }: SelectProps) => {
  return <select className={clsx('input p-2', className)} {...rest} />
}

export interface SelectCountryProps extends SelectProps {
  country?: string
  setCountry: (country: string) => void
}
export const SelectCountry = ({
  country,
  setCountry,
  ...rest
}: SelectCountryProps) => {
  const { countries } = useLocalizedCountryList()
  return (
    <Select
      value={country}
      onChange={e => setCountry(e.target.value)}
      {...rest}
    >
      {countries.map(({ code, value, key }) => (
        <option value={code.toLowerCase()} key={key}>
          <Trans id={key} />
        </option>
      ))}
    </Select>
  )
}
