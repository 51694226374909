import type { GetLinksResponse } from '@jotta/grpc-js-client/wopiService'
import type { PathItemObject } from '@jotta/types/Files'
import clsx from 'clsx'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import path from 'path'
import { useEffect, useMemo, useRef } from 'react'
import styles from './WopiView.module.scss'
import type { WopiPostMessage, WopiPostMessageType } from './WopiViewContainer'
const debug = Debug('jotta:files:wopiview')
/**
 * View initializing the Office Online iframe view.
 * See a sample page from Microsoft here:
 * https://github.com/Microsoft/Office-Online-Test-Tools-and-Documentation/blob/master/samples/SampleHostPage.html
 * and documentation on how to build a compliant page:
 * https://wopi.readthedocs.io/en/latest/hostpage.html#host-page
 *
 * TODO:
 * Implement ClosePostMessage listening for Close via iframe.
 * https://wopi.readthedocs.io/en/latest/scenarios/customization.html#term-closepostmessage
 * https://wopi.readthedocs.io/en/latest/scenarios/postmessage.html#UI_Close
 *
 * Implement RenamePostMessage
 * https://wopi.readthedocs.io/en/latest/scenarios/postmessage.html#File_Rename
 *
 * Implement UI_Sharing
 * https://wopi.readthedocs.io/en/latest/scenarios/postmessage.html#UI_Sharing
 *
 * @param props.wopiLinks Data to setup view
 */
export const WopiView = observer(function WopiView({
  item,
  onWopiEvent,
  wopiLinks,
  showWopiEditView = false,
}: {
  item: PathItemObject
  onWopiEvent?: (e: WopiPostMessageType, data?: string) => void
  wopiLinks: GetLinksResponse.AsObject
  showWopiEditView?: boolean
}) {
  const formRef = useRef<HTMLFormElement | null>(null)
  const fileExtension = path.extname(item.path)
  useEffect(() => {
    const el = formRef.current
    if (el) {
      debug('Submit', wopiLinks.actionUrl)
      el.submit()
    } else {
      debug('Form not found')
    }
  }, [wopiLinks.actionUrl])

  useEffect(() => {
    const listener = (evt: MessageEvent) => {
      if (
        /^https:\/\/[\w-]+\.officeapps\.live\.com$/.test(evt.origin) ||
        /^https:\/\/[\w-]+\.officeapps-df\.live\.com$/.test(evt.origin)
      ) {
        const msg = JSON.parse(evt.data) as WopiPostMessage

        debug('got msg ', msg)

        switch (msg.MessageId) {
          case 'File_Rename': {
            const newName = msg.Values.NewName
            if (!newName) {
              debug(
                'Error: office event message is missing the new name! Dropping event',
                msg,
              )
              return
            }
            const newFileName = `${newName}.${fileExtension}`
            if (onWopiEvent) {
              onWopiEvent(msg.MessageId, newFileName)
            }
            return
          }
          default: {
            if (onWopiEvent) {
              onWopiEvent(msg.MessageId)
            }
            return
          }
        }
      }
    }
    window.addEventListener('message', listener, false)

    return () => {
      window.removeEventListener('message', listener, false)
    }
  })

  useEffect(() => {
    const { favIconUrl } = wopiLinks
    if (!favIconUrl || favIconUrl === '') {
      return
    }
    const favIcon = document.querySelector(
      'link[rel="icon"][href$="favicon.ico"]',
    )
    const previousFavIconUrl = favIcon?.getAttribute('href')
    if (favIcon && previousFavIconUrl) {
      favIcon.setAttribute('href', favIconUrl)
      return () => {
        favIcon.setAttribute('href', previousFavIconUrl)
      }
    }
  }, [wopiLinks])

  const key = useMemo(() => new Date().getTime(), [])

  // const editSandBoxValues =
  //   wopiLinks && wopiLinks.actionType !== WopiActionType.EMBEDVIEW
  //     ? 'allow-scripts allow-same-origin allow-forms allow-popups allow-top-navigation allow-popups-to-escape-sandbox'
  //     : undefined
  return (
    <div
      id="embedded-wopi-root"
      className={clsx(styles.container, {
        [styles.editview]: showWopiEditView,
      })}
    >
      <form
        ref={formRef}
        action={wopiLinks.actionUrl}
        method="POST"
        id="office_form"
        name="office_form"
        target="office_frame"
        className={styles.form}
      >
        <input
          name="access_token"
          value={wopiLinks.accessToken}
          type="hidden"
        />
        <input
          name="access_token_ttl"
          value={wopiLinks.accessTokenTtl}
          type="hidden"
        />
      </form>
      <iframe
        key={key}
        id="office_frame"
        name="office_frame"
        title="Office Online"
        // // The sandbox attribute is needed to allow automatic redirection to the O365 sign-in page in the business user flow
        sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-top-navigation allow-popups-to-escape-sandbox allow-downloads"
        // This attribute allows true fullscreen mode in slideshow view
        // when using PowerPoint's 'view' action.
        allowFullScreen
      />
    </div>
  )
})
