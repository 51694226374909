import type { BoxPropsAs } from '@jotta/ui/Box'
import { Box } from '@jotta/ui/Box'
import { useBrandTheme } from '@jotta/ui/useBrandTheme'
import type { Avatar as AvatarType } from '@jotta/types/Avatar'
import { observer } from 'mobx-react-lite'
import type { ReactNode } from 'react'
import { forwardRef, useState } from 'react'
import styles from './Avatar.module.scss'

type AvatarContentProps = {
  avatar: AvatarType
  // fallbackState: State<boolean>
  onError?: () => void
  useFallback?: boolean
}

const AvatarContent = observer<AvatarContentProps>(function AvatarContent({
  avatar,
  onError,
  useFallback = false,
}) {
  const { initials, profilePhotoUrl } = avatar

  if (useFallback || !profilePhotoUrl) {
    return <span>{initials}</span>
  } else {
    return (
      <img
        style={{
          borderRadius: '100%',
          height: '100%',
          width: '100%',
        }}
        src={profilePhotoUrl}
        alt=""
        onError={onError}
      />
    )
  }
})
interface AvatarPlainProps {
  avatar: AvatarType
  /** Avatar size in pixels */
  size?: number
  /** override avatar content */
  contentOverride?: ReactNode
}

export type AvatarProps = BoxPropsAs<'div', AvatarPlainProps, 'all'>
/**
 * Creates a round avatar icon for a user with displaying their
 * intials with a randomly generated background color
 */
export const Avatar = observer<AvatarProps, HTMLDivElement>(
  forwardRef(function Avatar(
    { avatar, sx, size = 36, contentOverride, children, ...props },
    ref,
  ) {
    const { theme } = useBrandTheme()
    const [profilePhotoLoadError, setProfilePhotoLoadError] = useState(false)
    const {
      initials,
      profilePhotoUrl,
      backgroundColor: requestedBackgroundColor,
    } = avatar
    const useFallBack = Boolean(!profilePhotoUrl || profilePhotoLoadError)
    const backgroundColor =
      useFallBack && !contentOverride ? requestedBackgroundColor : ''
    return (
      <Box
        ref={ref}
        sx={{
          variant: 'styles.avatar',
          border: `1px ${theme.colors.gray} solid`,
          backgroundColor: backgroundColor,
          height: `${size}px`,
          width: `${size}px`,
          fontSize: `${size}px`,
          ...sx,
        }}
        data-initials={initials}
        role="presentation"
        title={initials}
        {...props}
      >
        {contentOverride || (
          <AvatarContent
            avatar={avatar}
            useFallback={useFallBack}
            onError={() => setProfilePhotoLoadError(true)}
          />
        )}
        {children}
      </Box>
    )
  }),
)

export const AvatarPlain = observer<AvatarPlainProps, HTMLDivElement>(
  forwardRef(function Avatar({ avatar, size = 36, ...props }, ref) {
    const [profilePhotoLoadError, setProfilePhotoLoadError] = useState(false)
    const { initials, profilePhotoUrl, backgroundColor } = avatar
    const useFallBack = Boolean(!profilePhotoUrl || profilePhotoLoadError)

    return (
      <div
        ref={ref}
        className={styles.avatar}
        style={{
          fontSize: `${size}px`,
          backgroundColor: useFallBack ? backgroundColor : '',
        }}
        data-initials={initials}
        role="presentation"
        title={initials}
        {...props}
      >
        <AvatarContent
          avatar={avatar}
          useFallback={useFallBack}
          onError={() => setProfilePhotoLoadError(true)}
        />
      </div>
    )
  }),
)
