import type { BoxOwnProps } from '@jotta/ui/Box'
import { Box } from '@jotta/ui/Box'
import { TextThemeUI } from '@jotta/ui/themeui'
import type { ProgressStatus } from '@jotta/types/IO'
import { px } from '@jotta/utils/css'
import { filesize } from '@jotta/utils/filesize'
import type { FC } from 'react'

export interface ProgressBarProps extends Partial<ProgressStatus> {
  height?: string | number
  variant?: BoxOwnProps['variant']
  className?: string
}
export const ProgressBar: FC<ProgressBarProps> = ({
  completed = 0,
  total = 0,
  height: h = 10,
  variant = 'styles.progressBar',
  ...props
}) => {
  const percentComplete = Math.round((completed / total) * 100)
  const height = px(h)

  return (
    <Box
      {...props}
      sx={{
        '--progressbar-height': height,
        '--progressbar-radius': 'calc(var(--progressbar-height) / 2)',
        width: '100%',
        height: 'var(--progressbar-height)',
        minWidth: '50px',
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
        borderRadius: 'var(--progressbar-radius)',

        '&::after': {
          variant,
          content: '" "',
          display: 'block',
          height: '100%',
          width: `${percentComplete}%`,
          borderRadius: 'var(--progressbar-radius)',
        },
      }}
    />
  )
}

export const StorageProgressBar: FC<ProgressBarProps> = ({
  total = 0,
  completed = 0,
  height = 20,
  ...props
}) => {
  return (
    <Box
      sx={{
        py: 1,
        px: 2,
        position: 'relative',
      }}
      {...props}
    >
      <ProgressBar
        completed={completed}
        total={total}
        height={height}
        variant="styles.storageProgressBar"
      />
      <TextThemeUI
        variant="styles.b"
        sx={{
          fontSize: 0,
          color: 'white',
          position: 'absolute',
          top: '5px',
          left: '18px',
        }}
      >
        {filesize(completed)}
      </TextThemeUI>
    </Box>
  )
}
