import clsx from 'clsx'
import { useBrandTheme } from '../../hooks/useBrandTheme'
import { PlainBrandIcon } from '../BrandIcon/BrandIcon'
import styles from './BrandLogo.module.scss'
export function BrandLogo({
  icon,
  className,
  height,
  ...props
}: {
  icon: 'SvgLogoMain' | 'SvgLogoHeader'
  className?: string
  height?: number
}) {
  const { theme } = useBrandTheme()

  return (
    <div
      {...props}
      className={clsx(styles.logo, className)}
      style={
        height
          ? undefined
          : {
              width: theme.branding.width,
              height: theme.branding.height,
            }
      }
    >
      <PlainBrandIcon icon={icon} height={height} />
    </div>
  )
}
