import path from 'path'

export function addTrailingSlash(pathStr: string = '/') {
  if (pathStr.endsWith('/')) {
    return pathStr
  }
  return `${pathStr}/`
}
export function removeTrailingSlash(pathStr: string = '/') {
  return pathStr.replace(/^(.+)\/$/, '$1')
}

/**
 * Get depth of path
 * @example
 * const depth = getPathDepth('/sync')
 * // depth == 1
 * @param pathStr A file path
 * @returns The depth of the path
 */
export function getPathDepth(pathStr: string) {
  return (addTrailingSlash(makeAbsolute(pathStr)).match(/\//g)?.length || 1) - 1
}
export function getFilenameFromPath(pathStr: string) {
  return path.basename(pathStr)
}
export function getParentPath(pathStr = '/') {
  return path.join('/', path.normalize(pathStr), '..')
}
export function renamePath(pathStr = '/', name = '') {
  const oldName = path.basename(pathStr)
  if (!oldName) {
    return pathStr
  }
  return path.join('/', path.dirname(pathStr), path.basename(name))
}
export function makeAbsolute(pathStr = '') {
  return removeTrailingSlash(path.join('/', path.normalize(pathStr)))
}
export function makeRelative(pathStr = '') {
  return makeAbsolute(pathStr).substring(1)
}

export function addPrefixToPath(pathStr: string, prefix: string) {
  const prefixPath = makeAbsolute(prefix)
  const absPath = makeAbsolute(pathStr)
  if (!absPath.startsWith(prefixPath)) {
    return path.join(prefixPath, absPath)
  }
  return absPath
}
export function removePrefixFromPath(pathStr: string, prefix: string) {
  const prefixPath = makeAbsolute(prefix)
  const absPath = makeAbsolute(pathStr)
  if (absPath.startsWith(prefixPath)) {
    return makeAbsolute(absPath.slice(prefixPath.length))
  }
  return absPath
}

export function encodePath(str: string) {
  const codeUnits = new Uint16Array(str.length)
  for (let i = 0; i < codeUnits.length; i++) {
    codeUnits[i] = str.charCodeAt(i)
  }
  return btoa(String.fromCharCode(...new Uint8Array(codeUnits.buffer)))
}
/**
 * Runs encodeURIComponent on each element in a path,  returning original pathname if
 * decoding throws
 * @param pathname
 * @returns Encoded path
 */
export function uriEncodePath(pathname: string) {
  try {
    return pathname.split('/').map(encodeURIComponent).join('/')
  } catch (error) {
    console.warn('uriEncodePath %s failed', pathname, error)
    return pathname
  }
}
/**
 * Runs decodeURIComponent on pathname, returning original pathname if decoding throws
 * @param pathname
 * @returns Decoded path
 */
export function uriDecodePath(pathname: string) {
  try {
    return decodeURIComponent(pathname)
  } catch (error) {
    console.warn('uriDecodePath %s failed', pathname, error)
    return pathname
  }
}
export function safeEncodeURI(pathname: string, showWarnings = true) {
  try {
    return encodeURI(pathname)
  } catch (error) {
    if (showWarnings) {
      console.warn(
        'encodeURI %s failed, returning unmodified string',
        pathname,
        error,
      )
    }
    return pathname
  }
}
export function safeEncodeURIComponent(pathname: string, showWarnings = true) {
  try {
    return encodeURIComponent(pathname)
  } catch (error) {
    if (showWarnings) {
      console.warn(
        'encodeURIComponent %s failed, returning unmodified string',
        pathname,
        error,
      )
    }
    return pathname
  }
}
export function safeDecodeURI(pathname: string, showWarnings = true) {
  try {
    return decodeURI(pathname)
  } catch (error) {
    if (showWarnings) {
      console.warn(
        'decodeURI %s failed, returning unmodified string',
        pathname,
        error,
      )
    }
    return pathname
  }
}
export function safeDecodeURIComponent(pathname: string, showWarnings = true) {
  try {
    return decodeURIComponent(pathname)
  } catch (error) {
    if (showWarnings) {
      console.warn(
        'decodeURIComponent %s failed, returning unmodified string',
        pathname,
        error,
      )
    }
    return pathname
  }
}
/**
 * React router has a bug that first decodes a path with decodeURI, then each segment
 * of the path with decodeURIComponent, causing partial decoding and errors if the
 * path contains any encoded characters decoded by decodeURI which then cause
 * decodeURIComponent to throw a Malformed URI error
 *
 * This function encodes those characters twice to make it safe for react routers decoding
 * https://github.com/remix-run/react-router/issues/10814
 *
 * @param pathname
 * @returns Encoded path
 */
export function uriEncodeRoutePath(pathname: string) {
  return pathname.split('/').map(uriEncodeRoutePathSegment).join('/')
}

/**
 *
 * @param segment
 * @returns
 */
export function uriEncodeRoutePathSegment(segment: string) {
  return safeEncodeURIComponent(
    segment.replace(
      /[#$&+,/:;=?@%]/g,
      c => `%${c.charCodeAt(0).toString(16).toUpperCase()}`,
    ),
  )
}
/**
 * React router has a bug that first decodes a path with decodeURI, then each segment
 * of the path with decodeURIComponent, causing partial decoding and errors if the
 * path contains any encoded characters decoded by decodeURI which then cause
 * decodeURIComponent to throw a Malformed URI error
 *
 * This function decodes in the same way, primarily for testing uriEncodeRoutePath
 *
 * @param pathname
 * @returns Encoded path
 */
export function uriDecodeRoutePath(pathname: string, showWarnings = true) {
  return safeDecodeURIComponent(
    safeDecodeURI(pathname, showWarnings),
    showWarnings,
  )
}

export function decodePath(str: string) {
  const binary = atob(str)
  const bytes = new Uint8Array(binary.length)
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = binary.charCodeAt(i)
  }
  return String.fromCharCode(...new Uint16Array(bytes.buffer))
}

export function pathToArray(pathStr: string) {
  const p = makeAbsolute(pathStr)
  if (p === '/') {
    return ['']
  }
  return p.split('/')
}
export function arrayToPath(arr: string[], start?: number, end?: number) {
  return makeAbsolute(arr.slice(start, end).join('/'))
}
export function createPath(...items: string[]) {
  return removeTrailingSlash(path.normalize(path.join('/', ...items)))
}
export function createRelativePath(...items: string[]) {
  return `.${path.normalize(path.join('/', ...items))}`
}

export function popPath(pathStr: string, levels = 1) {
  const parentPath = pathToArray(pathStr)
  return arrayToPath(parentPath, 0, -Math.abs(levels))
}

/**
 * Remove first n items from path
 * @param pathStr File path
 * @param levels Number of items to remove
 * @returns Shifted path
 */
export function shiftPath(pathStr: string, levels = 1) {
  return createPath(...pathToArray(pathStr).slice(Math.abs(levels + 1)))
}
export function relativeShiftPath(pathStr: string, levels = 1) {
  return makeRelative(arrayToPath(pathToArray(pathStr), Math.abs(levels + 1)))
}
