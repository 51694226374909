import type {
  OnboardingStatusSet,
  OnboardingPopupKeys,
  OnboardingAllKeys,
} from '@jotta/grpc-js-client/customerService'
import { OnboardingPopupKeyArray } from '@jotta/grpc-js-client/customerService'
import {
  getOnboarding,
  setOnboarding,
} from '@jotta/grpc-js-client/customerService'
import { makeAutoObservable, observable, runInAction } from 'mobx'

type FetchState = 'idle' | 'fetching' | 'done' | 'error'

export class OnboardingStore {
  constructor() {
    makeAutoObservable(this)
  }

  private _fetchState: FetchState = 'idle'
  public get fetchState(): FetchState {
    return this._fetchState
  }
  public set fetchState(value: FetchState) {
    this._fetchState = value
  }
  private _status = observable.set<OnboardingAllKeys>()

  private _triggerPopup: OnboardingPopupKeys | null = null

  public get triggerPopup() {
    return this._triggerPopup
  }

  private async updateStatus() {
    this.fetchState = 'fetching'

    try {
      const result = await getOnboarding()

      runInAction(() => {
        if (result) {
          this._status.replace(result)
        }

        this.fetchState = 'done'

        if (!this.isCompleted('welcome')) {
          this._triggerPopup = 'welcome'
        }
      })
    } catch (e) {
      runInAction(() => {
        this.fetchState = 'error'
      })
    }
  }

  public get status(): OnboardingStatusSet | null {
    if (this.fetchState === 'idle') {
      this.updateStatus()
    }

    if (this.fetchState !== 'done') {
      return null
    }

    return this._status
  }

  public isCompleted(name: OnboardingPopupKeys) {
    return !this.status || this.status.has(name)
  }

  public async complete(name: OnboardingPopupKeys) {
    const result = await setOnboarding(name, true)

    if (!result.has(name)) {
      return
    }

    this._status.add(name)

    runInAction(() => {
      // Trigger next popup in onboarding series
      if (name === this.triggerPopup) {
        switch (name) {
          case 'welcome':
            this._triggerPopup = 'navMenu'
            break

          case 'navMenu':
            this._triggerPopup = 'files'
            break

          case 'files':
            this._triggerPopup = 'photos'
            break

          case 'photos':
            this._triggerPopup = 'trash'
            break

          case 'trash':
            this._triggerPopup = 'fab'
            break

          default:
            this._triggerPopup = null
        }
      }
    })

    return this.status
  }

  public async reset(name: OnboardingPopupKeys) {
    const result = await setOnboarding(name, false)

    if (result.has(name)) {
      return
    }

    runInAction(() => {
      this._status.add(name)
    })

    return this.status
  }

  public async resetAll() {
    for (const key of OnboardingPopupKeyArray) {
      await setOnboarding(key, false)
    }

    runInAction(() => {
      for (const key of OnboardingPopupKeyArray) {
        this._status.delete(key)
      }

      this._triggerPopup = 'welcome'
    })
  }

  public async completeAll() {
    runInAction(() => {
      this._triggerPopup = null
    })

    for (const key of OnboardingPopupKeyArray) {
      await setOnboarding(key, true)
    }

    runInAction(() => {
      for (const key of OnboardingPopupKeyArray) {
        this._status.add(key)
      }
    })
  }
}

export const onboardingStore = new OnboardingStore()
