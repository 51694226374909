import type { CarouselItemView } from '@jotta/carousel'
import { ErrorPage } from '@jotta/ui/ErrorPage'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import { ErrorBoundary } from '@sentry/react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { Suspense } from 'react'
import { FileView } from '../FileView/FileView'
import type { PathItemStore } from '../PathItem/PathItemStore'

export const FileCarouselView: CarouselItemView<PathItemStore> = observer(
  function FileCarouselView({ store, item }) {
    const file = toJS(item.item.data)
    return (
      <ErrorBoundary
        fallback={({ error, resetError }) => (
          <ErrorPage error={error} resetError={resetError} />
        )}
      >
        <Suspense
          fallback={<LoadingOverlay open position="absolute" darkMode />}
        >
          <FileView file={file} isActive={item.id === store.currentItem.id} />
        </Suspense>
      </ErrorBoundary>
    )
  },
)
