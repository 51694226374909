// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PeopleLayout_container__rnXUZ{--sticky-header-height:50px;display:flex;flex-flow:column nowrap;padding:0 0 80px;position:relative;width:var(--content-width-safe)}`, "",{"version":3,"sources":["webpack://./../../libraries/photos/src/people/internal/components/PeopleLayout/PeopleLayout.module.scss"],"names":[],"mappings":"AACA,+BACE,2BAAA,CAEA,YAAA,CACA,uBAAA,CACA,gBAAA,CACA,iBAAA,CAJA,+BAIA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PeopleLayout_container__rnXUZ`
};
export default ___CSS_LOADER_EXPORT___;
