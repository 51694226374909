import { TextThemeUI } from '@jotta/ui/themeui'
import { RadixDialog } from '@jotta/ui/RadixDialog'
import type { RadixModalProps } from '@jotta/ui/RadixModal'
import { RadixModal } from '@jotta/ui/RadixModal'

export interface ErrorDialogProps extends RadixModalProps {
  title: string
  body?: string
}

export const ErrorDialog: React.FC<ErrorDialogProps> = ({
  open = false,
  onClose,
  title,
  body = '',
  ...props
}) => {
  return (
    <RadixModal open={open} onClose={onClose} {...props}>
      <RadixDialog
        title={title}
        onClose={onClose}
        buttons={[
          {
            action: 'close',
            label: 'OK',
            variant: 'buttons.error',
          },
        ]}
      >
        <TextThemeUI variant="styles.p" sx={{ mb: 3 }}>
          {body}
        </TextThemeUI>
      </RadixDialog>
    </RadixModal>
  )
}
