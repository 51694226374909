import { Button } from '@jotta/ui/Button'
import { AccountType } from '@jotta/grpc-js-client/customerService'
import type { GetPaymentInfoResponse } from '@jotta/grpc-web/no/jotta/openapi/subscription/payment.v2_pb'
import { useAlertListStore } from '@jotta/ui/AlertStore'
import { Card } from '@jotta/ui/Card'
import { Trans, t } from '@lingui/macro'

import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { paymentStore } from '../../state/stores'
import { ResolveState } from '../../utils/ChallengeModel'
import { DowngradeSubscriptionDialog } from './DowngradeSubscriptionDialog/DowngradeSubscriptionDialog'

export interface DowngradeSubscriptionProps {
  subscription: GetPaymentInfoResponse.AsObject
  accountType?: AccountType
  familyMemberCount?: number
  customerUsageBytes?: number
}

export const DowngradeSubscription: React.FC<DowngradeSubscriptionProps> = ({
  subscription: { freeStorageLimit },
  accountType,
  customerUsageBytes = 0,
  familyMemberCount = 0,
}) => {
  const { alert } = useAlertListStore()
  const { search } = useLocation()
  const [isDowngradeDialogOpen, setDowngradeDialogOpen] = useState(
    new URLSearchParams(search).has('downgrade'),
  )

  const onResolve = (state?: ResolveState) => {
    if (state === ResolveState.SUCCESS) {
      setDowngradeDialogOpen(false)
      alert.success(<Trans>Your subscription was downgraded to free.</Trans>)
    }
  }

  const downgradeAccount = () => {
    paymentStore.downgradeAccount({ onResolve })
  }

  return (
    <Card variant="cards.compact" title={t`Downgrade subscription`}>
      <Button
        variant="buttons.error"
        sx={{ mt: 3 }}
        onClick={() => setDowngradeDialogOpen(true)}
      >
        <Trans>Downgrade subscription</Trans>
      </Button>
      <DowngradeSubscriptionDialog
        isOpen={isDowngradeDialogOpen}
        onCancel={() => setDowngradeDialogOpen(false)}
        errorMessage=""
        familyMemberCount={familyMemberCount}
        isFamily={accountType === AccountType.FAMILY_OWNER}
        storage={freeStorageLimit}
        handleSubmit={downgradeAccount}
        freeSpaceExceeded={freeStorageLimit <= customerUsageBytes}
      />
    </Card>
  )
}
