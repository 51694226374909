import type { GetPathItemResponse } from '@jotta/grpc-js-client/files/getPathItem'
import { getFolderPathItem } from '@jotta/grpc-js-client/files/getPathItem'
import { getTranslatedFilenameFromApiPath } from '@jotta/grpc-js-client/getTranslatedFilenameFromApiPath'
import { ItemAction } from '@jotta/grpc-web/no/jotta/openapi/file/file.v2_pb'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { createQuery } from 'react-query-kit'
import { CreateNewFolder } from './CreateNewFolder'
import styles from './FileTree.module.scss'

export interface FileTreeProps {
  path: string
  includeDeleted?: boolean
  initiallyExpanded?: boolean
  selectedPath?: string
  onSelectFolder: (pathname: string) => void
  createFolderPath?: string
  onCreateFolder: (targetPath: string, name: string) => void
  onCancelCreateFolder: () => void
}

export const usePathItemFolder = createQuery<
  GetPathItemResponse,
  { path: string; includeDeleted?: boolean },
  unknown
>({
  queryKey: ['folder'],
  fetcher: async ({ path, includeDeleted = false }) =>
    getFolderPathItem({
      path,
      includeDeleted,
      includeChildren: true,
      includeThumb: false,
      limit: 1000,
    }),
})

export function FileTree({
  onSelectFolder,
  path,
  includeDeleted,
  initiallyExpanded = false,
  selectedPath = '',
  createFolderPath = '',
  onCreateFolder,
  onCancelCreateFolder,
}: FileTreeProps) {
  const [expanded, setExpanded] = useState(initiallyExpanded)
  const query = usePathItemFolder({
    variables: {
      path,
      includeDeleted,
    },
  })
  const { data: response } = query
  const folders = response ? response.children : []
  const isSelected =
    path.toLocaleLowerCase() === selectedPath.toLocaleLowerCase()

  useEffect(() => {
    if (
      selectedPath.toLocaleLowerCase().startsWith(path.toLowerCase()) &&
      selectedPath.length > path.length
    ) {
      setExpanded(true)
    }
  }, [selectedPath, path])

  return (
    <div className={styles.file}>
      <button
        onClick={() => setExpanded(e => !e)}
        disabled={!folders.length}
        className={styles.plusMinusButton}
        data-testid={`filetree-${expanded ? 'collapse' : 'expand'}-${path}`}
      >
        {expanded ? '-' : '+'}
      </button>
      <div className={styles.folder}>
        <button
          data-testid={`filetree-select-${path}`}
          className={clsx(styles.folderName, {
            [styles.selected]: isSelected,
          })}
          disabled={
            !response ||
            !response.item.actionList.includes(ItemAction.ADD_CHILD)
          }
          onClick={() => onSelectFolder(path)}
        >
          {getTranslatedFilenameFromApiPath(path)}
        </button>
        <CreateNewFolder
          path={path}
          targetPath={createFolderPath}
          onConfirm={onCreateFolder}
          onCancel={onCancelCreateFolder}
        />
        {expanded
          ? folders.map(item => (
              <FileTree
                onSelectFolder={onSelectFolder}
                key={item.path}
                path={item.path}
                selectedPath={selectedPath}
                includeDeleted={includeDeleted}
                createFolderPath={createFolderPath}
                onCreateFolder={onCreateFolder}
                onCancelCreateFolder={onCancelCreateFolder}
              />
            ))
          : null}
      </div>
    </div>
  )
}
