import type { BrandKey } from '@jotta/types/generated/brands'
import type { BrowserOptions } from '@sentry/react'
import * as Sentry from '@sentry/react'
import Debug from 'debug'
import { useEffect } from 'react'
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import { handleUnknownError } from '@jotta/types/AppError'

const debug = Debug('jotta:webapp:sentry')

export function initializeSentry({
  brand,
  dsn,
  environment,
  releaseName,
}: {
  brand: BrandKey
  dsn?: string
  environment?: string
  releaseName?: string
}) {
  const options: BrowserOptions = {
    dsn,
    environment,
    release: `${releaseName}@${process.env.CI_COMMIT_SHA}`,
    initialScope: {
      tags: {
        brand,
      },
    },
    // https://sentry.io/answers/react-resizeobserver-loop-completed-with-undelivered-notifications/#workaround-4-sentry-error-monitoring
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
    beforeSend(event, hint) {
      const { xid, grpcStatus } = handleUnknownError(hint.originalException)

      const tags = (event.tags = event.tags || {})
      if (xid) {
        tags['xid'] = xid
      }
      if (grpcStatus) {
        tags['grpc_status'] = grpcStatus
      }

      return event
    },
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          /^https:\/\/(api|content|pdfapi)\.(jottacloud\.com|jotta\.cloud)/,
        ],
      }),
    ],
  }
  Sentry.init(options)
  debug(options.release, { options })
}

export const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)
