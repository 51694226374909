import type { ProgressCallback } from '@jotta/types/IO'
import type { File as NodeJSFile } from 'node:buffer'
import { AbortError } from '@jotta/types/AbortError'
import Debug from 'debug'
import filesizeParser from 'filesize-parser'
import { createMD5 } from 'hash-wasm'
import { delay } from './delay'

const debug = Debug('jotta:utils:crypto')

export function randomRange(min: number, max: number) {
  return Math.round(Math.random() * (max - min) + min)
}
export function randomArrayItem<T>(arr: T[]): T {
  const index = randomRange(0, arr.length - 1)
  return arr[index]
}

export interface CalulateFileMd5Props {
  file: File | NodeJSFile
  chunkSizeStr?: string | number
  progressCallback?: ProgressCallback
  signal?: AbortSignal
  progressTotal?: number
}

export const calculateFileMd5 = async ({
  file,
  progressCallback = () => {},
  signal,
  chunkSizeStr = '10mb',
}: CalulateFileMd5Props): Promise<string> => {
  try {
    progressCallback(0)
    const chunksize = filesizeParser(chunkSizeStr)
    const stream = file.stream()
    const reader = stream.getReader()
    const md5Hasher = await createMD5()
    md5Hasher.init()
    let bytesProcessed = 0

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const { done, value } = await reader.read()

      if (done) {
        break
      }

      if (!value) {
        throw new Error(`${file.name}: Error reading file`)
      }

      for (let i = 0; i < value.byteLength; i += chunksize) {
        const chunk = value.slice(i, i + chunksize)
        md5Hasher.update(chunk)
        bytesProcessed += chunk.byteLength
        progressCallback(bytesProcessed)

        await delay(0)

        if (signal?.aborted) {
          throw new AbortError()
        }
      }
    }

    const hash = md5Hasher.digest('hex')
    return hash
  } catch (error) {
    debug(error)
    throw error
  }
}
