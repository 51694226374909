import { makeAutoObservable } from 'mobx'

export class EmptyTrashActionStore {
  private _count = 0
  private _ts = new Date().valueOf()

  public get timestamp() {
    return this._ts
  }

  get count() {
    return this._count
  }

  set count(val: number) {
    this._count = val
  }

  private _status: 'pending' | 'success' | 'error' | 'cancelled' = 'pending'

  public get status() {
    return this._status
  }

  public set status(status: typeof this._status) {
    this._status = status
  }

  get done() {
    return this._status !== 'pending'
  }

  constructor() {
    makeAutoObservable(this)
  }
}
