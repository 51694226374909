import { TextInput } from '@jotta/ui/TextInput'
import { CTAButton } from './CTAButton'
import { t, Trans } from '@lingui/macro'
import type {
  BusinessSignupRequest,
  BusinessSignupResponse,
} from '@jotta/grpc-connect-openapi/signup'
import type { PartialMessage } from '@bufbuild/protobuf'
import { useForm } from '@jotta/hooks'
import { TermsAndConditionsCheckbox } from './TermsAndConditionsCheckbox'
import {
  useAcceptedTermsField,
  useEmailField,
  newsletterField,
  usePasswordField,
  useNameField,
  voucherCodeField,
  useActivationCodeTerminology,
} from './signupForm'
import { FormField } from '@jotta/ui/FormField'
import { Divider } from '@jotta/ui/Divider'
import { Form } from '@jotta/ui/Form'
import { PasswordField } from './PasswordField'

export function CreateBusinessUser({
  customerGroupCode,
  className,
  isPending,
  signup,
  supportsVoucher,
  prefill = {},
  ctaLabelText,
}: {
  customerGroupCode: string
  className: string
  isPending: boolean
  signup: (
    signup: PartialMessage<BusinessSignupRequest>,
  ) => Promise<BusinessSignupResponse>
  supportsVoucher: boolean
  prefill?: { name?: string; email?: string }
  ctaLabelText?: string
}) {
  const activationCodeTerminology = useActivationCodeTerminology()

  const form = useForm({
    fields: {
      companyName: {
        type: 'text',
        required: true,
      },
      name: useNameField(),
      email: useEmailField(customerGroupCode),
      password: usePasswordField(),
      voucherCode: voucherCodeField,
      acceptedTerms: useAcceptedTermsField(),
      newsletter: newsletterField,
    },
    suppressIndicatorsForRequiredFields: true,
    defaultValues: () => prefill,
    submit: ({ companyName, name, email, password, voucherCode, newsletter }) =>
      signup({
        businessName: companyName,
        adminFullname: name,
        adminEmail: email,
        adminPassword: password,
        voucherCode,
        wantsNewsletter: Boolean(newsletter),
      }),
  })

  const {
    companyName,
    name,
    password,
    email,
    voucherCode,
    acceptedTerms,
    newsletter,
    isSubmitting,
  } = form

  return (
    <Form form={form} className={className}>
      <FormField label={t`Company Name`} field={companyName}>
        <TextInput {...companyName.inputProps} />
      </FormField>

      <Divider />

      <FormField label={t`Name of administrator`} field={name}>
        <TextInput {...name.inputProps} />
      </FormField>

      <FormField label={t`Email`} field={email}>
        <TextInput {...email.inputProps} />
      </FormField>

      <PasswordField field={password} />

      {supportsVoucher && (
        <FormField
          label={
            activationCodeTerminology ? t`Activation code` : t`Voucher code`
          }
          field={voucherCode}
        >
          <TextInput {...voucherCode.inputProps} />
        </FormField>
      )}

      <TermsAndConditionsCheckbox
        acceptedTerms={acceptedTerms}
        newsletter={newsletter}
      />

      <CTAButton loading={isPending || isSubmitting} type="submit">
        {ctaLabelText || <Trans>Create account</Trans>}
      </CTAButton>
    </Form>
  )
}
