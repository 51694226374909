/** External */
import { t } from '@lingui/macro'
import { ErrorBoundary } from '@sentry/react'
import clsx from 'clsx'
import Debug from 'debug'
import moize from 'moize'
import { unique } from 'radash'
import { Suspense, useLayoutEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import type { RouteObject } from 'react-router-dom'
import { Link, Outlet, useOutletContext } from 'react-router-dom'

/** GRPC */
import { useQuery, useSuspenseInfiniteQuery } from '@connectrpc/connect-query'
import type {
  GetPhotosWithPersonResponse,
  GetPhotosWithPersonResponse_Photo,
} from '@jotta/grpc-connect-openapi/people'
import {
  getPerson,
  getPhotosWithPerson,
} from '@jotta/grpc-connect-openapi/peopleQuery'
import { createLoaderRoute } from '@jotta/router'
import { Button } from '@jotta/ui/Button'
import { ErrorElement } from '@jotta/ui/ErrorElement'
import { createPath, uriEncodeRoutePathSegment } from '@jotta/utils/pathUtils'
import type { InfiniteData } from '@tanstack/react-query'
/** Internal */
import type { PersonPhoto } from '../api/PeopleApi'
import {
  PeopleBreadCrumb,
  PeopleBreadCrumbHandle,
} from '../components/PeopleBreadCrumb/PeopleBreadCrumb'
import { PersonHeader } from '../components/PersonHeader/PersonHeader'
import { PersonPhotoList } from '../components/PersonPhotos/PersonPhotoList'
import { StickyToolbar } from '../components/StickyToolbar/StickyToolbar'
import { personPhotoRoute } from './PersonPhotoRoute'

import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import styles from './PersonRoute.module.scss'

const debug = Debug('jotta:people:PersonRoute')
const createPhotosFromInfinitePersonWithPhotosResponse = moize(
  (data?: InfiniteData<GetPhotosWithPersonResponse>): PersonPhoto[] => {
    if (!data) {
      return []
    }
    return unique(
      data.pages.flatMap(p => p.photo),
      p => p.md5,
    )
  },
  {
    maxSize: 10000,
    isShallowEqual: true,
  },
)
export const { route: personRoute, usePersonLoaderData } = createLoaderRoute({
  id: 'person',
  path: ':personId/*',
  element: <PersonRoute />,
  errorElement: (
    <ErrorElement captureContext={scope => scope.setTag('key', 'person')} />
  ),
  children: [personPhotoRoute as RouteObject],
  loader: async ({ params }) => {
    const { personId = '' } = params
    return {
      id: personId,
    }
  },
  handle: {
    crumb: new PeopleBreadCrumbHandle(() => <PersonCrumbItem />),
  },
} satisfies RouteObject)

type PersonContextType = {
  photos: GetPhotosWithPersonResponse_Photo[]
}
function PersonCrumbItem() {
  const { id } = usePersonLoaderData()
  const query = useQuery(
    getPerson,
    {
      id,
    },
    {
      enabled: Boolean(id),
    },
  )
  const person = query.data?.person
  if (person) {
    return (
      <Link
        to={createPath(
          '/photo/album/people',
          uriEncodeRoutePathSegment(id || ''),
        )}
      >
        {person.name || 'no name'}
      </Link>
    )
  }
}
function PersonRoute() {
  return (
    <>
      <div>
        <StickyToolbar>
          <PeopleBreadCrumb />
        </StickyToolbar>
        <div className="relative h-48 p-4 px-6">
          <ErrorBoundary
            fallback={errorData => (
              <div>Header error {errorData.error.message}</div>
            )}
          >
            <Suspense fallback={<LoadingOverlay position="absolute" />}>
              <PersonHeader />
            </Suspense>
          </ErrorBoundary>
        </div>
      </div>
      <ErrorBoundary
        fallback={errorData => <div>Body error {errorData.error.message}</div>}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <PersonRouteView />
        </Suspense>
      </ErrorBoundary>
    </>
  )
}
function PersonRouteView() {
  const { ref, inView } = useInView()
  const { id } = usePersonLoaderData()
  const { data, isFetching, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useSuspenseInfiniteQuery(
      getPhotosWithPerson,
      {
        personId: id,
        cursor: '',
        pageSize: 1000,
      },
      {
        pageParamKey: 'cursor',
        getNextPageParam: lastPage => lastPage.cursor,
      },
    )
  const photos = createPhotosFromInfinitePersonWithPhotosResponse(data)
  useLayoutEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, hasNextPage])
  return (
    <>
      <Outlet
        context={
          {
            photos,
          } satisfies PersonContextType
        }
      />
      {!!photos && (
        <PersonPhotoList
          isLoading={!photos.length && isFetching}
          photos={photos}
        />
      )}
      <div
        ref={ref}
        className={clsx({
          [styles.hidden]: !hasNextPage,
        })}
      >
        {hasNextPage && (
          <Button
            variant="buttons.secondary"
            onClick={() => fetchNextPage()}
            loading={isFetchingNextPage}
            disabled={!hasNextPage || isFetchingNextPage}
          >
            {isFetchingNextPage
              ? t`Loading...`
              : hasNextPage
                ? t`Load more`
                : t`Nothing more to load`}
          </Button>
        )}
      </div>
    </>
  )
}
export function usePersonPhotos() {
  return useOutletContext<PersonContextType>()
}
