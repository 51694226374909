import { useForm } from '@jotta/hooks'
import { TermsAndConditionsCheckbox } from './TermsAndConditionsCheckbox'
import { Form } from '@jotta/ui/Form'
import { useMutation } from '@tanstack/react-query'
import { signup as signupDescriptor } from '@jotta/grpc-connect-openapi/signupQuery'
import type { Language } from '@jotta/grpc-connect-openapi/language'
import type { Country } from '@jotta/grpc-connect-openapi/country'
import { CTAButton } from './CTAButton'
import { exhaustiveGuard } from '@jotta/utils/exhaustive'
import type { LoggedIn } from '@jotta/grpc-connect-openapi/login'
import {
  useAcceptedTermsField,
  EmailInUse,
  newsletterField,
  generateSignupToken,
} from './signupForm'
import { Trans } from '@lingui/macro'
import { Button } from '@jotta/ui/Button'
import clsx from 'clsx'
import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { AppError } from '@jotta/types/AppError'
import { mutate } from '@jotta/grpc-connect-client/util'
import { SignupRequest } from '@jotta/grpc-connect-openapi/signup'
import { publicTransport } from '@jotta/grpc-connect-client/transport'

export function FederatedSignupConfirmation({
  customerGroupCode,
  federationToken,
  onSuccess,
  onCancel,
  language,
  country,
  className,
  ctaLabelText,
}: {
  customerGroupCode: string
  federationToken: string
  onSuccess: (loggedIn: LoggedIn) => void
  onCancel: () => void
  language: Language
  country?: Country
  className?: string
  ctaLabelText?: string
}) {
  const { mutateAsync: signup, isPending } = useMutation({
    throwOnError: false,
    mutationFn: async (signupRequest: SignupRequest) => {
      return mutate(
        signupDescriptor,
        await generateSignupToken(
          signupRequest,
          (message, token) => (message.token = token),
        ),
        publicTransport,
      )
    },
    onSuccess: signupResponse => {
      const signupResult = signupResponse?.result
      switch (signupResult?.case) {
        case undefined:
        case 'signupNotSupported':
          throw new Error('invalid signup response')
        case 'success':
          onSuccess(signupResult.value)
          break
        case 'emailInUse':
          throw new AppError({
            view: <EmailInUse />,
          })
        default:
          return exhaustiveGuard(signupResult)
      }
    },
  })

  const form = useForm({
    fields: {
      acceptedTerms: useAcceptedTermsField(),
      wantsNewsletter: newsletterField,
    },
    submit: ({ wantsNewsletter }) =>
      signup(
        new SignupRequest({
          customerGroupCode,
          language,
          country,
          wantsNewsletter,
          request: {
            case: 'federatedSignup',
            value: {
              token: federationToken,
            },
          },
        }),
      ),
  })

  const { acceptedTerms, wantsNewsletter } = form

  return (
    <Form
      className={clsx('my-2 flex flex-col gap-4', className)}
      form={form}
      header={
        <>
          <div className="alert alert-good mb-8 flex">
            <PlainBrandIcon
              icon="SvgCheckCircle"
              height={24}
              className="pr-4"
            />
            <Trans>Logged in</Trans>
          </div>
          <h2 className="h2">
            <Trans>Complete signup</Trans>
          </h2>
        </>
      }
    >
      <TermsAndConditionsCheckbox
        acceptedTerms={acceptedTerms}
        newsletter={wantsNewsletter}
      />
      <CTAButton type="submit" loading={isPending}>
        {ctaLabelText || <Trans>Sign up</Trans>}
      </CTAButton>
      <Button
        className="btn btn-outline"
        disabled={isPending}
        onClick={event => {
          event.preventDefault()
          onCancel()
        }}
      >
        <Trans>Cancel</Trans>
      </Button>
    </Form>
  )
}
