import {
  addComment,
  addReply,
  deleteComment,
  getAggregatedComments,
  getComments,
  getGroup,
  getGroupCommentCount,
  updateComment,
} from '@jotta/grpc-js-client/commentService'
import { Direction } from '@jotta/grpc-web/no/jotta/openapi/comment/v2/comment.v2_pb'
import type {
  Comment,
  GetCommentsResponse,
} from '@jotta/grpc-web/no/jotta/openapi/comment/v2/comment.v2_pb.d'
import type { Photos } from '@jotta/types/Photos'
import type { PhotosApi } from '@jotta/types/PhotosApi'
import type { Query } from '@tanstack/react-query'
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { useLayoutEffect, useMemo } from 'react'
import type { Group, GroupStore } from './GroupStore'
import { groupStore } from './GroupStore'

export { Direction }

export type CommentWithImage = Comment.AsObject & {
  media?: Photos.Media
}

export interface CommentResponseWithImage extends GetCommentsResponse.AsObject {
  commentsList: CommentWithImage[]
}

function usePhotoMap(album?: PhotosApi.Album): {
  [key: string]: PhotosApi.Media
} {
  return useMemo(
    () =>
      album
        ? Object.fromEntries(
            album.photos?.map(photo => [photo.commentsItemId, photo]) || [],
          )
        : {},
    [album],
  )
}

function useAggregatedCommentsWithImage(
  album: PhotosApi.Album | undefined,
  direction: Direction = Direction.DESCENDING,
  sort: Direction = Direction.ASCENDING,
) {
  const photoMap = usePhotoMap(album)
  const contextKey = album?.commentsGroupId || ''

  return async ({
    pageParam,
  }: {
    pageParam?: string
  }): Promise<CommentResponseWithImage> => {
    const res = await getAggregatedComments(
      contextKey,
      pageParam,
      direction,
      sort,
    )

    return {
      ...res,
      commentsList: res.commentsList.map(comment => ({
        ...comment,
        media: photoMap[comment.contextKey],
      })),
    }
  }
}

export function useInfiniteAggregatedComments(
  album: PhotosApi.Album | undefined,
  direction: Direction = Direction.DESCENDING,
  sort: Direction = Direction.ASCENDING,
) {
  const contextKey = album?.commentsGroupId || ''
  const fetchComments = useAggregatedCommentsWithImage(album, direction, sort)

  return useInfiniteQuery({
    queryKey: ['getInfiniteAggregatedComments', contextKey] as const,
    queryFn: fetchComments,
    initialPageParam: '',
    getNextPageParam: (lastPage, pages) => lastPage.cursor?.value,
    enabled: Boolean(contextKey),
  })
}

export function useCommentCount(contextKey: string = '') {
  return useQuery({
    queryKey: ['getCommentCount', contextKey] as const,
    queryFn: () => {
      return getComments(contextKey) as Promise<CommentResponseWithImage>
    },
    enabled: Boolean(contextKey),
    select: data => data.totalCommentCount,
  })
}

export function useInfiniteComments(
  contextKey: string = '',
  direction: Direction = Direction.DESCENDING,
  sort: Direction = Direction.ASCENDING,
) {
  return useInfiniteQuery({
    queryKey: ['getInfiniteComments', contextKey] as const,
    queryFn: ({ pageParam }) =>
      getComments(contextKey, pageParam, direction, sort),
    initialPageParam: '',
    getNextPageParam: lastPage => lastPage.cursor?.value,
    enabled: Boolean(contextKey),
  })
}

export function useGroupCommentCount(contextKey: string = '') {
  return useQuery({
    queryKey: ['getGroupCommentCount', contextKey] as const,
    queryFn: async () => {
      const res = await getGroupCommentCount(contextKey)
      return res.totalCommentCount
    },
    enabled: Boolean(contextKey),
  })
}

export function useGroup(
  contextKey: string = '',
  store: GroupStore = groupStore,
) {
  const q = useQuery({
    queryKey: ['getGroup', contextKey] as const,
    queryFn: async (): Promise<Group> => {
      const { totalCommentCount, itemsList } = await getGroup(contextKey)

      return {
        contextKey,
        totalCommentCount: totalCommentCount,
        members: itemsList.reduce<Group['members']>(
          (acc, { contextKey, totalCommentCount }) =>
            Object.assign(acc, { [contextKey]: totalCommentCount }),
          {},
        ),
      }
    },
    enabled: Boolean(contextKey),
  })

  const { data, isSuccess } = q

  useLayoutEffect(() => {
    if (data && isSuccess) {
      store.group = data
    }
  }, [data, isSuccess])

  return q
}

function matchAnyOf(...args: unknown[]) {
  const keys = args.filter(key => key)

  return (query: Query) =>
    query.queryKey instanceof Array
      ? query.queryKey.some(key => keys.includes(key))
      : keys.includes(query.queryKey)
}

export function useAddComment(albumContextKey?: string) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      text,
      contextKey,
    }: {
      text: string
      contextKey: string
    }) => {
      return addComment(text, contextKey)
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        predicate: matchAnyOf(variables.contextKey, albumContextKey),
      })
    },
  })
}

export function useAddReply(albumContextKey?: string) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      text,
      contextKey,
      commentId,
    }: {
      text: string
      contextKey: string
      commentId: string
    }) => {
      return addReply(text, commentId, contextKey)
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        predicate: matchAnyOf(variables.contextKey, albumContextKey),
      })
    },
  })
}

export function useUpdateComment(albumContextKey?: string) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      commentId,
      text,
    }: {
      commentId: string
      text: string
      contextKey: string
    }) => {
      return updateComment(text, commentId)
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        predicate: matchAnyOf(variables.contextKey, albumContextKey),
      })
    },
  })
}

export function useDeleteComment(albumContextKey?: string) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      commentId,
      contextKey,
    }: {
      commentId: string
      contextKey: string
    }) => {
      return deleteComment(commentId)
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        predicate: matchAnyOf(variables.contextKey, albumContextKey),
      })
    },
  })
}
