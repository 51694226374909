import { I18nProvider } from '@lingui/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { configure } from 'mobx'
import { observer } from 'mobx-react-lite'
import type { FC, ReactElement } from 'react'
import { createRoot } from 'react-dom/client'
import { Helmet } from 'react-helmet'
import { BaseBrandProvider } from '@jotta/ui/BrandProvider'
import type { BrandTheme } from '@jotta/ui/types/BrandTheme'
import { PublicTransportProvider } from '@jotta/grpc-connect-client/provider'
import { Intercom } from '@jotta/intercom'
import type { Locale } from '@jotta/i18n'
import { useActivateLocale } from '@jotta/i18n'
import { queryClient } from '@jotta/query'
import { RouterProvider } from '@jotta/router'
import type { BrandKey } from '@jotta/types/generated/brands'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'

import { initializeSentry } from '@jotta/utils/initializeSentry'
import { router } from './routes/RootRoutes'
import * as serviceWorker from './serviceWorker'
import { createPortal } from 'react-dom'
import { env } from '@jotta/utils/env'
import { useBrandName } from '@jotta/grpc-connect-client/config'

if (process.env.NODE_ENV === 'development') {
  document.body.classList.add('envdevelopment')
  configure({
    enforceActions: 'always',
    computedRequiresReaction: true,
    reactionRequiresObservable: false,
    observableRequiresReaction: true,
    disableErrorBoundaries: false,
  })
}
export const App: FC<{
  overrideLocale?: Locale
}> = observer(function App({ overrideLocale }) {
  const brandName = useBrandName()
  const { isSuccess, i18n } = useActivateLocale({
    overrideLocale,
  })

  if (isSuccess) {
    return (
      <I18nProvider i18n={i18n}>
        <Helmet defaultTitle={brandName} titleTemplate={`%s - ${brandName}`}>
          <meta lang={i18n.locale} />
        </Helmet>
        <Intercom />
        <RouterProvider router={router} />
      </I18nProvider>
    )
  }
  return null
})

export function start(brand: BrandKey, element: ReactElement) {
  initializeSentry({
    brand,
    dsn: env.sentryDsn,
    environment: env.sentryEnv,
    releaseName: env.releaseName,
  })
  const container = document.getElementById('root')
  if (!container) {
    throw new Error('#root element missing')
  }
  const root = createRoot(container)
  root.render(element)
  serviceWorker.unregister()
}

export function startApp(theme: BrandTheme, overrideLocale?: Locale) {
  if (process.env.NODE_ENV === 'development') {
    document.body.classList.add('envdevelopment')
    configure({
      enforceActions: 'always',
      computedRequiresReaction: true,
      reactionRequiresObservable: false,
      observableRequiresReaction: true,
      disableErrorBoundaries: false,
    })
  }

  start(
    theme.branding.brand,
    <PublicTransportProvider>
      <QueryClientProvider client={queryClient}>
        <BaseBrandProvider theme={theme} fallback={<LoadingOverlay open />}>
          <App overrideLocale={overrideLocale} />
        </BaseBrandProvider>
        <DevTools />
      </QueryClientProvider>
    </PublicTransportProvider>,
  )
}

function DevTools() {
  if (process.env.NODE_ENV === 'development') {
    return createPortal(
      <span
        id="tsqd"
        style={{
          pointerEvents: 'auto',
        }}
      >
        <ReactQueryDevtools
          initialIsOpen={false}
          buttonPosition="bottom-left"
          client={queryClient}
        />
      </span>,
      document.body,
    )
  }
}
