import { sentryCaptureMessage } from './error'
let scrollbarWidth = 0
/**
 * Detects browser scrollbar width
 * @returns Detected scrollbar width in pixels
 */
export function detectScrollbarWidth(): number {
  if (scrollbarWidth) {
    return scrollbarWidth
  }
  try {
    const scrollDiv = document.createElement('div')
    scrollDiv.style.width = '100px'
    scrollDiv.style.height = '100px'
    scrollDiv.style.overflow = 'scroll'
    scrollDiv.style.position = 'absolute'
    scrollDiv.style.top = '-9999px'
    document.body.appendChild(scrollDiv)
    scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
    document.body.removeChild(scrollDiv)
  } catch (error) {
    sentryCaptureMessage('Failed to detect scrollbar width', error)
  }
  return scrollbarWidth
}
