import Debug from 'debug'
import { delay } from './delay'
import { isString } from 'remeda'
const debug = Debug('jotta:download')

export function downloadZeroBytes(fileName: string, mime: string) {
  const blob = new Blob([], { type: mime })
  const url = (window.URL || window.webkitURL).createObjectURL(blob)

  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = url
  a.target = '_self'
  // the filename you want
  a.download = fileName
  document.body.appendChild(a)
  a.click()
  window.URL.revokeObjectURL(url)
  document.body.removeChild(a)
}
export type KeyValue = [string, string]
export type KeyValueList = KeyValue[]

export interface DownloadWithFormRequest {
  url: string
  method: string
  parameters: KeyValueList
}
export async function downloadWithForm({
  url,
  method,
  parameters,
}: DownloadWithFormRequest) {
  const form = document.createElement('form')
  form.action = url
  form.method = method
  parameters.forEach(([key, value]) => {
    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = key
    input.value = value
    form.appendChild(input)
  })

  document.body.append(form)

  debug('form download', form.target, method, url, parameters, form)
  form.submit()

  await delay(10000)
  form.remove()
}
export async function downloadWithFormData({
  url,
  data,
  onComplete,
}: {
  url: string
  data: FormData
  onComplete?: () => void
}) {
  const form = document.createElement('form')
  form.action = url
  form.method = 'POST'
  form.style.display = 'none'
  for (const [key, value] of data.entries()) {
    if (isString(value)) {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = key
      input.value = value
      form.appendChild(input)
    }
  }
  document.body.appendChild(form)

  debug('form download', form.target, url, data, form)
  form.submit()

  await delay(500)
  document.body.removeChild(form)
}

export function downloadBlob(blob: Blob, name: string) {
  const a = document.createElement('a')
  const url = URL.createObjectURL(blob)
  a.href = url
  a.download = name || 'download'

  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url)
      a.removeEventListener('click', clickHandler)
    }, 150)
  }
  a.addEventListener('click', clickHandler, false)
  a.click()
}

export async function downloadWithUrl(url: string, name: string) {
  const a = document.createElement('a')
  a.href = url
  a.download = name || 'download'

  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url)
      a.removeEventListener('click', clickHandler)
    }, 150)
  }
  a.addEventListener('click', clickHandler, false)
  a.click()
  await delay(100)
  a.remove()
}
