/* eslint-disable jsx-a11y/label-has-associated-control */
import { addUrlParams } from '@jotta/utils/url'
import clsx from 'clsx'
import Debug from 'debug'
import type { ObservableSet } from 'mobx'
import { observer } from 'mobx-react-lite'
import { expr } from 'mobx-utils'
import type { LinkProps } from 'react-router-dom'
import { Link } from 'react-router-dom'
import type { PersonPhoto } from '../../api/PeopleApi'
import { FacesInPhoto } from '../FacesInPhoto/FacesInPhoto'
import styles from './PersonPhotoThumb.module.scss'
const debug = Debug('jotta:people:PersonPhotoThumb')
function createThumb(thumbUrl: string, width = 's', height = width) {
  return addUrlParams(thumbUrl, { size: `${width}` })
}
type PersonPhotoThumbProps = {
  photo: PersonPhoto
  selectedIds: ObservableSet<string>
  loading?: HTMLImageElement['loading']
} & LinkProps
export const PersonPhotoThumb = observer<PersonPhotoThumbProps>(
  function PersonPhotoThumb({ photo, selectedIds, loading, ...props }) {
    const checked = expr(() => selectedIds.has(photo.md5))
    return (
      <figure className={clsx(styles.thumb, { [styles.selected]: checked })}>
        <label htmlFor={photo.md5}>
          <input
            type="checkbox"
            name="photo"
            id={photo.md5}
            value={photo.md5}
            checked={checked}
            readOnly
          />
        </label>
        <Link {...props}>
          <FacesInPhoto aspect={1} isThumb md5={photo.md5}>
            <img
              src={createThumb(photo.thumbUrl)}
              // srcSet={createSrcSet(photo.thumbUrl)}
              loading={loading}
              decoding="async"
              alt=""
            />
          </FacesInPhoto>
        </Link>
      </figure>
    )
  },
)
