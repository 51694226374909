import Debug from 'debug'
const debug = Debug('jotta:branding:setCSSCustomProperty')

export function setCSSCustomProperty(
  propertyName: string,
  value: string | null,
  priority?: string,
) {
  document.documentElement.style.setProperty(propertyName, value, priority)
}
export function setElementCSSCustomProperty(
  el: HTMLElement,
  propertyName: string,
  value: string | null,
  priority?: string,
) {
  el.style.setProperty(propertyName, value, priority)
}
export function getCSSCustomProperty(
  property: string,
  el = document.documentElement,
) {
  const styles = getComputedStyle(el)
  return styles.getPropertyValue(property)
}

export function getCurrentBreakpoint() {
  const brProp = getCSSCustomProperty('--current-breakpoint')
  debug(brProp)
  const br = parseInt(String(brProp), 10)
  if (isNaN(br)) {
    debug('Failed to get breakpoint', brProp, br)
  }
  return br
}

type PropertyValues = Record<string, string | number>
type PropertyCacheValue = {
  ref: WeakRef<HTMLElement>
  values: PropertyValues
}

// Map of HTML elements and pending CSS custom property changes
const propertyCache = new WeakMap<HTMLElement, PropertyCacheValue>()

// Set of elements part of property updates
const elementCache = new Set<WeakRef<HTMLElement>>()

// ID of pending requestAnimationFrame
let frameId: number | void

// Add custom properties to pending updates
function mergeValues(el: HTMLElement, values: PropertyValues) {
  const cacheValue = propertyCache.get(el)
  // Update existing changed item
  if (cacheValue) {
    cacheValue.values = {
      ...cacheValue.values,
      ...values,
    }
    elementCache.add(cacheValue.ref)
    return
  }
  // No previous value, create new
  const ref = new WeakRef(el)
  propertyCache.set(el, {
    ref,
    values,
  })
  elementCache.add(ref)
}

// Apply pending updates to all elements and clear cache
function applyProperties() {
  // Check each cached element for pending updates
  for (const ref of elementCache) {
    const el = ref.deref()
    // Element has not been garbage collected
    if (el) {
      // Apply all pending updates
      const cached = propertyCache.get(el)
      if (cached) {
        for (const [k, v] of Object.entries(cached.values)) {
          el.style.setProperty(k, String(v))
        }
        // console.log(cached.values)
        /**el.style.cssText = Object.entries(cached.values)
          .map(([k, v]) => `${k}: ${String(v)};`)
          .join(" ")*/
      }
      // Clear cache
      propertyCache.delete(el)
    }
    elementCache.delete(ref)
  }
  elementCache.clear()
}

/**
 * Apply CSS custom properties to HTML element
 *
 * Multiple updates are batched into a single requestAnimationFrame
 *
 * @param values Object of CSS custom properties and values
 * @param el Apply properties to this element. Defaults to document.documentElement (:root in CSS)
 */
export function setElementCSSCustomProperties(
  values: Record<string, string | number>,
  el: HTMLElement = document.documentElement,
) {
  mergeValues(el, values)
  if (frameId) {
    frameId = cancelAnimationFrame(frameId)
  }
  frameId = requestAnimationFrame(applyProperties)
}
