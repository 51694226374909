import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query'

import Debug from 'debug'

const debug = Debug('jotta:query')

const isTest =
  process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development'

const queryCache = new QueryCache({
  onError: error => {
    debug(error)
  },
})

const mutationCache = new MutationCache({
  onError: error => {
    debug(error)
  },
})

export const queryClient = new QueryClient({
  queryCache,
  mutationCache,
  defaultOptions: {
    queries: {
      retry: isTest ? false : 3,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      throwOnError: true,
    },
    mutations: {
      throwOnError: true,
    },
  },
})
