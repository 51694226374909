import { VideoServicePromiseClient } from '@jotta/grpc-web/no/jotta/openapi/video_grpc_web_pb'
import type { GetPlaylistLinkResponse } from '@jotta/grpc-web/no/jotta/openapi/video_pb'
import { GetPlaylistLinkRequest } from '@jotta/grpc-web/no/jotta/openapi/video_pb'
import type { PathItemObject } from '@jotta/types/Files'
import Debug from 'debug'
import { useQuery } from '@tanstack/react-query'
import { getAuthMeta, withGrpcClientMiddleware } from './grpcutils'
import { env } from '@jotta/utils/env'
export { User } from '@jotta/grpc-web/no/jotta/openapi/sharing/v2/sharing.v2_pb'

const debug = Debug('jotta:grpc:video')

let videoServiceClient: VideoServicePromiseClient

export function getVideoServiceClient() {
  if (!videoServiceClient) {
    videoServiceClient = withGrpcClientMiddleware(
      'video',
      new VideoServicePromiseClient(env.grpcApi),
    )
  }
  return videoServiceClient
}

export async function getPlaylistLink(
  path: string,
  shareToken?: string,
): Promise<GetPlaylistLinkResponse.AsObject> {
  const playlistLinkRequest = new GetPlaylistLinkRequest()
  playlistLinkRequest.setPath(path)

  debug('playlistLinkRequest', playlistLinkRequest.toObject())

  const res = await getVideoServiceClient().getPlaylistLink(
    playlistLinkRequest,
    shareToken ? await getAuthMeta(shareToken) : undefined,
  )
  return res.toObject()
}
export function usePlaylistLink(
  pathItem: Pick<PathItemObject, 'path' | 'downloadLink' | 'mime'>,
  token?: string,
) {
  return useQuery({
    queryKey: ['file', 'playlist', pathItem.path, token?.substring(0, 32)],
    queryFn: async () => {
      const res = await getPlaylistLink(pathItem.path, token)
      return {
        playlistURL: res.playlistLink,
        videoURL: pathItem.downloadLink,
      }
    },
  })
}
