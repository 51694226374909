import { TextThemeUI } from '@jotta/ui/themeui'
import { RadixDialog } from '@jotta/ui/RadixDialog'
import type { RadixModalProps } from '@jotta/ui/RadixModal'
import { RadixModal } from '@jotta/ui/RadixModal'
import { Trans, plural, t } from '@lingui/macro'

export interface DeletePhotoDialogProps extends RadixModalProps {
  count?: number
  isLoading?: boolean
  onClose: (open: boolean) => void
  onDeletePhoto: () => void
}

export const DeletePhotoDialog: React.FC<DeletePhotoDialogProps> = ({
  count = 1,
  isLoading = false,
  onClose,
  onDeletePhoto,
  ...props
}) => {
  return (
    <RadixModal onClose={onClose} {...props}>
      <RadixDialog
        title={t({
          id: 'DeleteButton.confirmTitle',
          message: plural(count, {
            one: 'Are you sure you want to delete the selected photo?',
            other: 'Are you sure you want to delete the # selected photos?',
          }),
        })}
        onClose={onClose}
        data-testid="DeletePhotoDialog"
        buttons={[
          {
            action: 'close',
            label: t`Cancel`,
            testId: 'cancel',
          },
          {
            label: t({
              id: 'DeleteButton.confirmLabel',
              message: plural(count, {
                one: 'Yes, delete photo',
                other: 'Yes, delete photos',
              }),
            }),
            loading: isLoading,
            action: onDeletePhoto,
            variant: 'buttons.error',
            testId: 'confirm',
          },
        ]}
      >
        <TextThemeUI variant="styles.p" sx={{ mb: 3 }}>
          <Trans id="DeleteButton.confirmBody" />
        </TextThemeUI>
      </RadixDialog>
    </RadixModal>
  )
}
