import { useMemo } from 'react'
import { useGetConfig } from './useGetConfig'
import { Feature } from '@jotta/grpc-connect-openapi/esm/openapi/feature/v2/feature.v2_pb'
import { FeatureToggle } from '@jotta/types/FeatureToggle'

export function useFeatures() {
  const { data } = useGetConfig()
  const features = data.activeFeatures || []
  const featureToggles = data.activeFeatureToggleIds || []

  return useMemo(
    () => ({
      signup: features.includes(Feature.SIGNUP),
      newsletter: features.includes(Feature.NEWSLETTER),
      stripe: features.includes(Feature.STRIPE),
      cli: features.includes(Feature.CLI),
      photoSearch: featureToggles.includes(FeatureToggle.PhotoSearch),
      vipps: features.includes(Feature.VIPPS),
      twoFactor: features.includes(Feature.TWO_FACTOR),
      wopiTestMode: featureToggles.includes(FeatureToggle.WopiTestMode),
      timelineV2: featureToggles.includes(FeatureToggle.TimelineV2),
    }),
    [features],
  )
}
