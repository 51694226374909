import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { DialogTrigger } from '@radix-ui/react-dialog'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { DeletePhotoDialog } from '../Album/Dialogs/DeletePhotoDialog'
import type { PhotoActionHandlerActionProps } from './PhotoActionHandlers'
import { useDispatchPhotoActionMutation } from './useDispatchPhotoActionMutation'

export const ActionDeletePhotos = observer<
  PhotoActionHandlerActionProps<'DELETE_PHOTOS'>
>(function ActionDeletePhotos({
  action,
  photoIds = [],
  dispatch,
  buttonVariant = 'buttons.albumActionButton',
  onActionDialogClose,
  disabled = false,
}) {
  const [open, setOpen] = useState(false)
  const mutation = useDispatchPhotoActionMutation(
    'DELETE_PHOTOS',
    undefined,
    dispatch,
  )

  return (
    <DeletePhotoDialog
      open={open}
      onClose={open => {
        setOpen(open)
        if (!open && onActionDialogClose) {
          onActionDialogClose(action.actionType)
        }
      }}
      onDeletePhoto={() => !disabled && mutation.mutateAsync([photoIds])}
      count={photoIds.length}
      isLoading={mutation.isPending}
      triggerElement={
        <DialogTrigger
          aria-label={action.label}
          data-testid={action.testid}
          aria-busy={mutation.isPending}
          disabled={disabled}
          key={action.actionType}
          sx={{
            variant: buttonVariant,
          }}
        >
          <PlainBrandIcon icon={action.icon} />
          <span>{action.label}</span>
        </DialogTrigger>
      }
    />
  )
})
