import Debug from 'debug'
import type { RouteObject } from 'react-router-dom'
import { redirect, type LoaderFunctionArgs, Outlet } from 'react-router-dom'

import { WithAuth } from '@jotta/auth-client/WithAuth'
import { isAuthenticated } from '@jotta/auth-client/useAuthStatus'
import { ErrorElement } from '@jotta/ui/ErrorElement'

import { fetchPhotoByMD5 } from '../api/photosApi'
import { AlbumRoute } from '../components/Album/Album/Album.route'
import {
  albumInfoLoader,
  albumInfoLoaderId,
} from '../components/Album/AlbumInfo/AlbumInfo.loaders'
import { AlbumList } from '../components/Album/AlbumList/AlbumList'
import { PhotoLayout } from '../components/PhotoLayout/PhotoLayout'
import { SharedPhotosContainer } from '../components/SharedPhotosPage/SharedPhotosContainer'
import { peopleRoute } from '../people'
import { preFetchPeople } from '../people/internal/api/PeopleApi'
import { PhotoTimelineRoute } from './PhotoTimelineRoute'

const debug = Debug('jotta:photos:route')

export const PrivatePhotoRoute: RouteObject = {
  element: (
    <WithAuth>
      <PhotoLayout />
    </WithAuth>
  ),
  errorElement: (
    <ErrorElement
      captureContext={scope => scope.setTag('section', 'timelineroute')}
    />
  ),
  loader: async () => {
    await preFetchPeople()
    return null
  },
  path: 'photo',
  children: [
    {
      path: 'album',
      errorElement: (
        <ErrorElement
          captureContext={scope => scope.setTag('section', 'photos_album')}
        />
      ),
      children: [
        {
          index: true,
          id: 'photosAlbumList',
          element: <AlbumList />,
        },
        peopleRoute,
        {
          path: 'all',
          id: 'photosAlbumsNormal',
          element: <AlbumList showOnly="NORMAL_ALBUM" />,
        },
        {
          path: 'shared',
          id: 'photosAlbumsShared',
          element: <AlbumList showOnly="META_SHARED_ALBUM_TYPES" />,
        },
        {
          id: 'photosAlbumsLocation',
          path: 'location',
          element: <AlbumList showOnly="LOCATION_ALBUM" />,
        },
        {
          path: ':albumId',
          id: albumInfoLoaderId,
          loader: albumInfoLoader,
          element: <AlbumRoute />,
          children: [
            {
              path: ':mediaId',
            },
          ],
        },
      ],
    },
    {
      id: 'photosAllShared',
      path: 'all_shared',
      errorElement: (
        <ErrorElement
          captureContext={scope => scope.setTag('section', 'photos_all_shared')}
        />
      ),
      element: <SharedPhotosContainer />,
    },
    {
      path: 'all_hidden',
      element: <PhotoTimelineRoute hidden />,
      errorElement: (
        <ErrorElement
          captureContext={scope => scope.setTag('section', 'timeline_hidden')}
        />
      ),
      children: [
        {
          path: ':mediaId',
          errorElement: (
            <ErrorElement
              captureContext={scope =>
                scope.setTag('section', 'timeline_hidden_carousel')
              }
            />
          ),
        },
      ],
    },
    {
      element: <PhotoTimelineRoute />,
      children: [
        {
          element: <Outlet />,
          index: true,
        },
        {
          path: ':mediaId',
          element: <Outlet />,
          // Redirect urls with MD5 photo hash to the actual ID
          loader: async ({ params }: LoaderFunctionArgs) => {
            const { mediaId = '' } = params
            const isMd5 = /^[0-9a-f]{32}$/.test(mediaId)
            const authenticated = await isAuthenticated()
            if (isMd5 && authenticated) {
              try {
                debug(':mediaId looks like MD5, trying to fetch', mediaId)
                const photo = await fetchPhotoByMD5(mediaId)
                return redirect(`/photo/${photo.id}`)
              } catch (error) {
                return null
              }
            }
            return null
          },
          errorElement: (
            <ErrorElement
              captureContext={scope =>
                scope.setTag('section', 'timeline_carousel')
              }
            />
          ),
        },
      ],
    },
  ],
}
