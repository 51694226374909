import { ErrorBoundary } from '@sentry/react'
import { observer } from 'mobx-react-lite'
import { Suspense } from 'react'
import { AppLayoutDownload } from '@jotta/ui/AppLayoutDownload'
import { ErrorPage } from '@jotta/ui/ErrorPage'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import { useAuthenticated } from '@jotta/auth-client/useAuthStatus'

import { SmartPhotoSearchBetaLandingPage } from '../components/SmartPhotoSearchBetaLandingPage/SmartPhotoSearchBetaLandingPage'

export const SmartPhotoSearchBetaLandingPageRoute = observer(
  function SmartPhotoSearchBetaLandingPageRoute() {
    const authenticated = useAuthenticated() === 'TRUE'

    return (
      <AppLayoutDownload isLoggedIn={authenticated}>
        <ErrorBoundary
          fallback={({ error, resetError }) => (
            <ErrorPage error={error} resetError={resetError} />
          )}
        >
          <Suspense fallback={<LoadingOverlay open />}>
            <SmartPhotoSearchBetaLandingPage />
          </Suspense>
        </ErrorBoundary>
      </AppLayoutDownload>
    )
  },
)
