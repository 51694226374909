// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WopiView_container__lw8ID{contain:content;height:100%;overflow:hidden;position:relative;width:100%}.WopiView_container__lw8ID.WopiView_editview__X7oRR{bottom:0;left:0;position:fixed;right:0;top:0;z-index:10000}.WopiView_container__lw8ID .WopiView_form__2LH3n{z-index:10}.WopiView_container__lw8ID .WopiView_form__2LH3n,.WopiView_container__lw8ID>iframe{border:none;bottom:0;height:100%;left:0;margin:0;position:absolute;right:0;top:0;width:100%}.WopiView_container__lw8ID>iframe{display:block;z-index:11}`, "",{"version":3,"sources":["webpack://./../../libraries/files/src/FileView/WopiView/WopiView.module.scss"],"names":[],"mappings":"AACA,2BAKE,eAAA,CAHA,WAAA,CACA,eAAA,CACA,iBAAA,CAHA,UAIA,CACA,oDAKE,QAAA,CAFA,MAAA,CAFA,cAAA,CAGA,OAAA,CAFA,KAAA,CAIA,aAAA,CAEF,iDAUE,UAAA,CAEF,mFAHE,WAAA,CAFA,QAAA,CALA,WAAA,CAGA,MAAA,CAGA,QAAA,CALA,iBAAA,CAGA,OAAA,CAFA,KAAA,CAHA,UAsBA,CAXF,kCAUE,aAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `WopiView_container__lw8ID`,
	"editview": `WopiView_editview__X7oRR`,
	"form": `WopiView_form__2LH3n`
};
export default ___CSS_LOADER_EXPORT___;
