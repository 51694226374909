import {
  findSimilarPhotos,
  photoSearch,
  searchQuerySuggestions,
} from '@jotta/grpc-js-client/searchService'
import type { PhotoMatch } from '@jotta/grpc-web/no/jotta/openapi/search/search.v2_pb'
import type { Photos } from '@jotta/types/Photos'
import Debug from 'debug'
import { makeAutoObservable, observable, runInAction } from 'mobx'
import { lazyObservable } from 'mobx-utils'
import { fetchPhotoById } from '../api/photosApi'
import type { PhotoStore } from './PhotoStore'

const debug = Debug('jotta:photos:PhotoSearchStore')
export class PhotoSearchResult {
  private _matches = observable.array<PhotoMatch>([])

  public get matches() {
    return this._matches
  }

  private _query: string

  public get query() {
    return this._query
  }

  private _type: 'query' | 'similarity'

  public get type() {
    return this._type
  }

  private _status: 'loading' | 'error' | 'success'

  public get status() {
    return this._status
  }

  public get isLoading() {
    return this._status === 'loading'
  }

  public get isError() {
    return this._status === 'error'
  }

  public get isSuccess() {
    return this._status === 'success'
  }

  private _error: unknown

  public get error() {
    return this._error
  }

  public photoStore: PhotoStore

  public get photos(): Photos.Media[] {
    return this.photoStore.mediaObjects.getPhotosByIds(
      this.matches.map(match => match.getPhotoId()),
    )
  }

  public get queryPhoto(): Photos.Media | undefined {
    if (this._type !== 'similarity') {
      return
    }

    return this.photoStore.mediaObjects.getPhoto(this._query)
  }

  private async fetchPhotos(
    photos: string | string[] | PhotoMatch | PhotoMatch[],
  ) {
    const ar = Array.isArray(photos) ? photos : [photos]
    const ids = ar
      .map(item => (typeof item === 'string' ? item : item.getPhotoId()))
      .filter(id => !this.photoStore.mediaObjects.getPhoto(id))

    if (!ids.length) {
      return
    }

    try {
      const photos = await Promise.all(ids.map(fetchPhotoById))
      this.photoStore.mediaObjects.setPhotos(photos)
    } catch (err) {
      // TODO
      debug(err)
    }
  }

  public get similarPhoto() {
    if (this.type !== 'similarity' || !this.query) {
      return
    }

    const photo = this.photoStore.mediaObjects.getPhoto(this.query)

    if (!photo) {
      this.fetchPhotos(this.query)
    }

    return photo
  }

  public constructor(
    photoStore: PhotoStore,
    type: 'query' | 'similarity',
    query: string,
    _debug = false,
  ) {
    this.photoStore = photoStore
    this._type = type
    this._query = query
    this._status = 'loading'
    makeAutoObservable(this)

    const fn = type === 'query' ? photoSearch : findSimilarPhotos

    if (type === 'similarity') {
      this.fetchPhotos(query)
    }

    let minimumAestheticScore: number | undefined = undefined

    if (_debug) {
      minimumAestheticScore = Number.parseFloat(
        (window as any).minimumAestheticScore,
      )
      minimumAestheticScore = Number.isNaN(minimumAestheticScore)
        ? undefined
        : minimumAestheticScore
      debug(`minimumAestheticScore: ${minimumAestheticScore}`)
    }

    fn(query, _debug, minimumAestheticScore)
      .then(result => {
        this.fetchPhotos(result)
        runInAction(() => {
          this._status = 'success'
          this._matches = observable.array(result)
        })
      })
      .catch(error => {
        // TODO
        runInAction(() => {
          this._status = 'error'
          this._error = error
        })
        debug(error)
      })
  }
}

export class PhotoSearchStore {
  private _result: PhotoSearchResult | null = null
  private _debug = false

  public get result() {
    return this._result
  }

  public photoStore: PhotoStore

  constructor(photoStore: PhotoStore) {
    debug('Init PhotoSearchStore')
    this.photoStore = photoStore

    try {
      this._debug = Boolean(localStorage.debug)
    } catch (err) {
      /* Ignore */
    }

    makeAutoObservable(this)
  }

  search(query: string) {
    if (
      this._result &&
      this._result.type === 'query' &&
      this._result.query === query &&
      this._result.status !== 'error'
    ) {
      return
    }

    if (!query) {
      this.clear()
    } else {
      this._result = new PhotoSearchResult(
        this.photoStore,
        'query',
        query,
        this._debug,
      )
    }
    return this._result
  }

  findSimilarPhotos(id: string) {
    if (
      this._result &&
      this._result.type === 'similarity' &&
      this._result.query === id
    ) {
      return
    }

    this._result = new PhotoSearchResult(
      this.photoStore,
      'similarity',
      id,
      this._debug,
    )
    return this._result
  }

  public searchQuerySuggestions = lazyObservable<string[]>(async sink => {
    const result = await searchQuerySuggestions()
    sink(result)
  }, [])
  clear() {
    this._result = null
  }

  get isLoading() {
    return Boolean(this._result && this._result.status === 'loading')
  }

  get isError() {
    return Boolean(this._result && this._result.status === 'error')
  }

  get isSuccess() {
    return Boolean(this._result && this._result.status === 'success')
  }

  get error() {
    return (this._result && this._result.error) || undefined
  }

  private _open = false

  get open() {
    return this._open
  }

  set open(b: boolean) {
    this._open = b
  }
}
