import { t } from '@lingui/macro'
import { useQuery } from '@tanstack/react-query'
import { observer } from 'mobx-react-lite'
import Skeleton from 'react-loading-skeleton'
import { useFamilyMemberCount } from '../../../state/hooks/useFamily'
import { paymentStore, settingsStore } from '../../../state/stores'
import { LoadingBillingHistory } from '../../BillingHistory/BillingHistory'
import { DowngradeSubscription } from '../../PaymentInformation/DowngradeSubscription'
import { PaymentInformation } from '../../PaymentInformation/PaymentInformation'
import { Subscription } from '../../Subscription/Subscription'
import { SubscriptionAlerts } from '../../SubscriptionAlerts/SubscriptionAlerts'
import { SettingsPageContainer } from '../SettingsContainer'
import { StripeElementsContainer } from '../StripeContainer/StripeContainer'
import { useFeatures } from '@jotta/grpc-connect-client/config'

export const SubscriptionContainer = observer(function SubscriptionContainer({
  mock = false,
}: {
  mock?: boolean
}) {
  const { isLoading } = useQuery({
    queryKey: ['subscriptionDetails'] as const,
    queryFn: paymentStore.fetchSubscriptionDetailsInfo,
    enabled: !mock,
  })

  const familyMemberCount = useFamilyMemberCount()
  const features = useFeatures()

  return (
    <SettingsPageContainer
      title={t({ id: 'Subscription' })}
      /* backward-compatible e2e selector */
      className="settings_billing"
    >
      {paymentStore.subscription && !isLoading ? (
        <>
          {settingsStore.customer && (
            <SubscriptionAlerts
              subscription={paymentStore.subscription}
              alerts={paymentStore.subscriptionMessagesList}
              brandingInfo={settingsStore.customer.brandingInfo}
              onModifyPaymentMethod={paymentStore.toggleCardDialog}
            />
          )}
          <Subscription
            subscription={paymentStore.subscription}
            voucherInfo={paymentStore.voucherInfo}
            upgradeOptionCase={paymentStore.changeSubscriptionOptionCase}
          />
          {features.stripe && (
            <StripeElementsContainer>
              <PaymentInformation
                subscription={paymentStore.subscription}
                brandName={settingsStore.customer?.brandingInfo?.brandName}
                isCardDialogOpen={paymentStore.isCardDialogOpen}
                toggleCardDialog={paymentStore.toggleCardDialog}
                activeFeatureToggles={settingsStore.activeFeatureToggles}
              />
            </StripeElementsContainer>
          )}
          {paymentStore.subscription.canDowngrade && (
            <DowngradeSubscription
              familyMemberCount={familyMemberCount}
              subscription={paymentStore.subscription}
              accountType={settingsStore.customer?.accountType}
              customerUsageBytes={settingsStore.customer?.usageBytes}
            />
          )}
        </>
      ) : (
        <>
          <Skeleton count={5} height={50} />
          <Skeleton count={5} height={50} />
        </>
      )}
      <LoadingBillingHistory />
    </SettingsPageContainer>
  )
})
