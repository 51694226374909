import { RouterLink } from '@jotta/ui/RouterLink'
import { useBrandStore } from '@jotta/ui/useBrandTheme'
import { AccountType } from '@jotta/grpc-js-client/customerService'
import { Onboarding } from '@jotta/ui/Onboarding'
import { Trans } from '@lingui/macro'
import { observer } from 'mobx-react-lite'

import { useLocation } from 'react-router-dom'
import { showIntercom } from '@jotta/intercom'
import { useBrandingInfo } from '@jotta/grpc-connect-client/config'
import { useGetCustomer } from '@jotta/grpc-connect-client/customer'

export const NavBar = observer<{
  businessName?: string
  accountType?: AccountType
  userCanUpgrade?: boolean
}>(function NavBar({ businessName, accountType, userCanUpgrade }) {
  const { supportLink = '' } = useBrandingInfo()
  const intercomEnabled = Boolean(useGetCustomer().data?.customer?.intercom)
  const branding = useBrandStore()

  const { pathname } = useLocation()
  const syncButtonIsActive = /^\/web\/(sync|backup|archive|shared)\/?/.test(
    pathname,
  )
  const settingsButtonIsActive =
    /^\/web\/(account|secure|billing|family|connected)/.test(pathname)
  const trashButtonIsActive = /^\/web\/(trash)/.test(pathname)
  const photosButtonIsActive = /^\/(photo)/.test(pathname)
  const bizButtonIsActive = /^\/(biz)/.test(pathname)
  const helpButtonIsActive = intercomEnabled || supportLink
  const upgradeButtonIsActive = /^\/web\/(upgrade)/.test(pathname)

  return (
    <>
      <Onboarding task="files" onOpen={branding.openDrawer}>
        <RouterLink
          onClick={branding.onNavigate}
          data-section="files"
          variant="links.appNavbarButton"
          to="/web/sync"
          className={syncButtonIsActive ? 'force-active' : ''}
          icon="SvgFilesApp"
        >
          <Trans id="Files" />
        </RouterLink>
      </Onboarding>

      <Onboarding task="photos" onOpen={branding.openDrawer}>
        <RouterLink
          onClick={branding.onNavigate}
          data-section="photos"
          variant="links.appNavbarButton"
          to="/photo"
          icon="SvgPhotos"
          className={photosButtonIsActive ? 'force-active' : ''}
        >
          <Trans id="Photos" />
        </RouterLink>
      </Onboarding>

      <Onboarding task="trash" onOpen={branding.openDrawer}>
        <RouterLink
          onClick={branding.onNavigate}
          data-section="trash"
          variant="links.appNavbarButton"
          to="/web/trash"
          icon="SvgTrash"
          className={trashButtonIsActive ? 'force-active' : ''}
        >
          <Trans id="Trash" />
        </RouterLink>
      </Onboarding>

      {helpButtonIsActive && (
        <RouterLink
          onClick={e => {
            branding.onNavigate()
            showIntercom(e)
          }}
          data-section="help"
          variant="links.appNavbarButtonHelp"
          to={supportLink}
          icon="SvgHelp"
        >
          <Trans id="ZenDeskButton.label" />
        </RouterLink>
      )}

      {userCanUpgrade && (
        <RouterLink
          onClick={branding.onNavigate}
          data-section="upgrade"
          // Must do a full reload since route is an external app in prod
          reloadDocument
          variant="links.appNavbarButton"
          to="/web/upgrade"
          icon="SvgStarOutline"
          className={upgradeButtonIsActive ? 'force-active' : ''}
        >
          <Trans id="Upgrade" />
        </RouterLink>
      )}

      {accountType === AccountType.BUSINESS_ADMIN && (
        <RouterLink
          onClick={branding.onNavigate}
          data-section="business"
          // Must do a full reload since route is an external app in prod
          reloadDocument
          variant="links.appNavbarButton"
          to="/biz"
          icon="SvgBiz"
          className={bizButtonIsActive ? 'force-active' : ''}
        >
          {businessName || 'Business'}
        </RouterLink>
      )}

      <RouterLink
        onClick={branding.onNavigate}
        data-section="settings"
        variant="links.appNavbarButton"
        to="/web/account"
        icon="SvgSettings"
        className={settingsButtonIsActive ? 'force-active' : ''}
      >
        <Trans id="Settings" />
      </RouterLink>
    </>
  )
})
