import type { Interceptor } from '@connectrpc/connect'
import { Code, ConnectError } from '@connectrpc/connect'
import { createGrpcWebTransport } from '@connectrpc/connect-web'
import { getOrRefreshAccessToken } from '@jotta/auth-client/useAuthStatus'
import { env } from '@jotta/utils/env'
import { nextXid } from '@jotta/utils/xid'
import Debug from 'debug'
import { sleep } from 'radash'
import { enrichError } from '@jotta/utils/error'

const debug = Debug('grpc:transport')

declare global {
  interface Window {
    __CONNECT_WEB_DEVTOOLS__: Interceptor | undefined
  }
}

export const devToolsInterceptor: Interceptor = next => {
  const connectwebdevtools = window.__CONNECT_WEB_DEVTOOLS__
  return connectwebdevtools ? connectwebdevtools(next) : req => next(req)
}

export const logInterceptor: (name: string) => Interceptor =
  transportName => next => async req => {
    const xid = nextXid()
    const methodName = req.method.name
    const log = debug.extend(transportName).extend(methodName).extend(xid)
    try {
      req.header.set('x-id', xid)
      log('Request', req)
      const res = await next(req)
      log('Response', res)
      return res
    } catch (error) {
      enrichError(error, xid, methodName, transportName)
      if (error instanceof ConnectError) {
        const { code } = error
        const codeName = Code[code]
        log('%s - code "%s" message "%s"', error.name, codeName, error.message)
      } else if (error instanceof Error) {
        log('%s: message: %s', error.name, error.message, error)
      } else {
        log('Unknown error', error)
      }
      throw error
    }
  }

export const authInterceptor: Interceptor = next => async req => {
  const log = debug.extend(req.method.name)
  const token = await getOrRefreshAccessToken()

  if (token) {
    req.header.set('Authorization', `Bearer ${token}`)
  } else {
    log('Token not found, performing request without authorization')
  }

  return next(req)
}

/**
 * Slow down requests
 *
 * Only use for testing loading states during development!
 */
export const slowInterceptor: Interceptor = next => async req => {
  await sleep(500)
  return next(req)
}

export const publicTransport = createGrpcWebTransport({
  baseUrl: env.grpcApi,
  defaultTimeoutMs: 10000,
  interceptors: [devToolsInterceptor, logInterceptor('publicTransport')],
})
export const authTransport = createGrpcWebTransport({
  baseUrl: env.grpcApi,
  defaultTimeoutMs: 10000,
  interceptors: [
    devToolsInterceptor,
    logInterceptor('authTransport'),
    authInterceptor,
  ],
})
