import { isAuthenticated } from '@jotta/auth-client/useAuthStatus'
import { lookupLink } from '@jotta/grpc-js-client/configService'
import { fromEncodedContentRef } from '@jotta/grpc-js-client/ref'
import { ExternalLinkRef } from '@jotta/grpc-web/no/jotta/openapi/ref_pb'
import { ErrorElement } from '@jotta/ui/ErrorElement'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import Debug from 'debug'
// import * as Sentry from '@sentry/react'
import { createLoaderRoute } from '@jotta/router'

import type { LoaderFunctionArgs } from 'react-router-dom'
import { redirect } from 'react-router-dom'
import { contentRefToRoute } from './contentRefToRoute'

const debug = Debug('jotta:webapp:ContentRefRoute')

export const { route: contentRefRoute, useContentRrefLoaderData } =
  createLoaderRoute({
    id: 'contentRref',
    path: 'r/:encodedContentRef?',
    errorElement: (
      <ErrorElement
        captureContext={scope => scope.setTag('key', 'contentRref')}
      />
    ),
    loader: async ({ params, request }: LoaderFunctionArgs) => {
      const { encodedContentRef = '' } = params
      const url = new URL(request.url)
      const username = url.searchParams.get('username') || ''
      const token = url.searchParams.get('token') || ''
      const action = url.searchParams.get('action') || ''
      // const action = url.searchParams.get('action') || ''
      const authenticated = await isAuthenticated()
      const result = fromEncodedContentRef(encodedContentRef)
      if (result.isErr()) {
        debug('Error decoding ref', result.error)
        return redirect('/web/sync')
      }
      const ref = result.value
      const route = contentRefToRoute({
        contentRef: ref,
        authenticated,
      })
      if (route instanceof ExternalLinkRef) {
        const res = await lookupLink(route)
        window.location.replace(res.getValidLink())
        return null
      }
      const redirectUrl = new URL(route, url.origin)
      if (/^(share|edit)$/.test(action)) {
        redirectUrl.searchParams.set('action', action)
      }
      const redirectTo = redirectUrl.pathname + redirectUrl.search
      if (username && token && !authenticated) {
        const autoLoginUrl = new URL('/api/autologin', url.origin)
        autoLoginUrl.searchParams.set('username', username)
        autoLoginUrl.searchParams.set('token', token)
        autoLoginUrl.searchParams.set('redirect_uri', redirectTo)
        window.location.replace(autoLoginUrl)
        return null
      }
      window.location.replace(redirectTo)
      return null
    },
    element: <ContentRefRoute />,
  })

export function ContentRefRoute() {
  return <LoadingOverlay open />
}
