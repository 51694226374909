import type { BoxPropsAs } from '@jotta/ui/Box'
import { SvgBox } from '@jotta/ui/Box'
import { Button } from '@jotta/ui/Button'
import { useBrandTheme } from '@jotta/ui/useBrandTheme'
import type { BrandThemeIcon } from '@jotta/types/Brand'
import { observer } from 'mobx-react-lite'
import { expr } from 'mobx-utils'
import { forwardRef } from 'react'
import { useSelectionStore } from '../../store/PhotoContext'

export interface PhotoCheckboxProps {
  /** Click handler */
  onToggleSelect: (selected: boolean) => void
  isSelected?: boolean
}
/**
 * BrandIcon component
 */
export const PhotoCheckbox = forwardRef<
  SVGSVGElement,
  BoxPropsAs<'svg', PhotoCheckboxProps, 'all'>
>(function PhotoCheckbox({ isSelected, onToggleSelect, ...props }, ref) {
  const { theme } = useBrandTheme()
  const Icon =
    theme.icons[isSelected ? 'SvgCheckcircleSelected' : 'SvgCheckcircleEmpty']

  return (
    <SvgBox
      as={Icon}
      sx={{
        fontSize: 4,
        display: 'block',
        cursor: 'pointer',
        transition: 'opacity,transform,width .2s',
        position: 'absolute',
      }}
      onClick={() => onToggleSelect(!isSelected)}
      ref={ref}
      width="1em"
      height="1em"
      {...props}
    />
  )
})

export const PhotoCheckboxObserver = observer<{ id: string }>(
  function PhotoCheckboxObserver({ id }) {
    const store = useSelectionStore()
    const { selection } = store
    const isSelected = expr(() => selection.has(id))
    const icon: BrandThemeIcon = isSelected
      ? 'SvgCheckcircleSelected'
      : 'SvgCheckcircleEmpty'
    return (
      <Button
        icon={icon}
        aria-checked={isSelected}
        data-photoid={id}
        onClick={store.onClickToggle}
      />
    )
  },
)
export const PhotoCheckboxPlain = ({
  isSelected,
  onToggleSelect,
}: PhotoCheckboxProps) => {
  const { theme } = useBrandTheme()
  const Icon =
    theme.icons[isSelected ? 'SvgCheckcircleSelected' : 'SvgCheckcircleEmpty']
  return (
    <Icon
      style={{
        fontSize: '19px',
        display: 'block',
        cursor: 'pointer',
        transition: 'opacity,transform,width .2s',
        position: 'absolute',
      }}
      onClick={e => {
        e.stopPropagation()
        e.preventDefault()
        onToggleSelect(!isSelected)
      }}
      width="1em"
      height="1em"
      data-skip-click
    />
  )
}

export const PhotoCheckboxButton = ({
  checked = false,
  ...props
}: {
  checked?: boolean
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>) => {
  const { theme } = useBrandTheme()
  const Icon =
    theme.icons[checked ? 'SvgCheckcircleSelected' : 'SvgCheckcircleEmpty']
  return (
    <button
      {...props}
      aria-checked={checked}
      role="checkbox"
      data-skip-click
      tabIndex={-1}
    >
      <Icon
        style={{
          fontSize: '19px',
          display: 'block',
          cursor: 'pointer',
          transition: 'opacity,transform,width .2s',
          position: 'absolute',
        }}
        width="1em"
        height="1em"
      />
    </button>
  )
}
