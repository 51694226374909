/* eslint-disable tailwindcss/no-custom-classname */
import { Button } from '@jotta/ui/Button'
import { SpinnerThemeUI } from '@jotta/ui/themeui'
import { Trans } from '@lingui/macro'
import type { FocusEventHandler, KeyboardEventHandler } from 'react'
import { forwardRef, useImperativeHandle, useRef } from 'react'
import styles from './PhotoSearch.module.scss'

interface PhotoSearchInputProps {
  placeholder?: string
  disabled?: boolean
  active?: boolean
  onActiveChange?: (active: boolean) => void
  onSubmit?: (value: string) => void
  value?: string
  onChange?: (value: string) => void
  onClear?: () => void
  isLoading?: boolean
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
}

export const PhotoSearchInput = forwardRef<
  HTMLInputElement,
  PhotoSearchInputProps
>(function PhotoSearchInput(
  {
    placeholder = '',
    disabled = false,
    active = false,
    onSubmit,
    value = '',
    onChange,
    isLoading = false,
    onActiveChange,
    onKeyDown,
    onFocus,
    onBlur,
  },
  forwardedRef,
) {
  const inputRef = useRef<HTMLInputElement>(null)
  useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(
    forwardedRef,
    () => inputRef.current,
  )

  const onClearWrapper = () => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
    if (onChange) {
      onChange('')
    }
  }

  return (
    <div className={styles.searchContainer}>
      <form
        onSubmit={e => {
          e.preventDefault()
          if (!active && onActiveChange) {
            onActiveChange(true)
          }
          if (value.length && onSubmit) {
            onSubmit(value)
          }
          if (inputRef.current) {
            inputRef.current.focus()
          }
        }}
        className={`${styles.searchForm} ${active ? 'active' : ''}`}
      >
        <input
          ref={inputRef}
          onKeyDown={onKeyDown}
          onFocus={onFocus}
          onBlur={onBlur}
          autoCapitalize="off"
          type="text"
          className={styles.searchInput}
          placeholder={placeholder}
          onClick={() => onActiveChange && onActiveChange(true)}
          value={value}
          onChange={e => {
            onChange && onChange(e.target.value)
          }}
          disabled={disabled}
        />

        {isLoading ? (
          <SpinnerThemeUI className={styles.searchSpinner} />
        ) : (
          <Button
            type="submit"
            icon="SvgSearchAlt"
            tabIndex={-1}
            disabled={disabled}
            className={styles.searchSubmit}
          />
        )}
        {active && value.length > 0 && (
          <Button
            type="button"
            onClick={onClearWrapper}
            className={styles.searchClear}
          >
            <Trans>Clear</Trans>
          </Button>
        )}
      </form>
    </div>
  )
})
