import { Box } from '@jotta/ui/Box'
import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { Button } from '@jotta/ui/Button'
import { Headline1 } from '@jotta/ui/Headline'
import { Paragraph } from '@jotta/ui/Text'
import { Trans } from '@lingui/macro'
import { observer } from 'mobx-react-lite'
import { useOnLearnMoreWaitlist } from '../../routes/useOnLearnMore'

export interface WaitlistBannerInactiveProps {
  onDismiss: () => void
  onJoinWaitlist: (event: React.MouseEvent<HTMLButtonElement>) => void
  showSpinner: boolean
}

export const WaitlistBannerInactive: React.FC<WaitlistBannerInactiveProps> =
  observer<WaitlistBannerInactiveProps>(function WaitlistBannerInactive({
    onDismiss,
    onJoinWaitlist,
    showSpinner,
  }) {
    const { onLearnMoreWaitlist, onLearnMoreWaitlistId } =
      useOnLearnMoreWaitlist()
    return (
      <Box variant="layout.smartPhotoBannerContainer">
        <Headline1 variant="styles.smartPhotoSearchHeading">
          <Trans>New Photo Search is here</Trans>✨
        </Headline1>
        <Paragraph variant="styles.smartPhotoSearchParagraph">
          <Trans id="smartPhotoSearch.description">
            Our new AI-powered photo search understands long sentences,
            activities, colors, feelings and situations, allowing you to find
            exactly what you're looking for. You can also search for similar
            photos!
          </Trans>
        </Paragraph>
        <Box
          sx={{
            gap: 3,
            display: 'flex',
            flexDirection: ['column', 'column', 'row'],
          }}
        >
          <Button
            variant="buttons.smartSearchPrimaryButton"
            type="submit"
            loading={showSpinner}
            onClick={onJoinWaitlist}
          >
            <Trans>Get access now</Trans>
          </Button>
          {!!onLearnMoreWaitlistId && (
            <Button
              variant="buttons.smartSearchTextButton"
              sx={{
                justifyContent: 'flex-start',
              }}
              onClick={onLearnMoreWaitlist}
            >
              <Trans>Learn more</Trans>
            </Button>
          )}
        </Box>
        <Button
          sx={{
            variant: 'buttons.closeIconButton',
            position: 'absolute',
            top: 1,
            right: 1,
            color: 'primary',
          }}
          onClick={onDismiss}
        >
          <PlainBrandIcon icon="SvgClose" />
        </Button>
      </Box>
    )
  })
