import Debug from 'debug'
import { makeAutoObservable } from 'mobx'
const debug = Debug('jotta:collection:HTMLCollectionStore')

// #endregion Type aliases (1)
// #region Classes (1)

export class HTMLCollectionStore<T extends HTMLElement = HTMLElement> {
  readonly elements: HTMLCollectionOf<T>
  activeIndex: number
  size = 0
  classNames: string
  selectedAttribute: string
  idAttribute: string
  constructor({
    classNames,
    idAttribute,
    selectedAttribute = 'active',
    initialActiveIndex = -1,
  }: {
    classNames: string
    idAttribute: string
    selectedAttribute?: string
    initialActiveIndex?: number
  }) {
    this.activeIndex = initialActiveIndex
    this.classNames = classNames
    this.idAttribute = idAttribute
    this.selectedAttribute = selectedAttribute
    this.elements = document.getElementsByClassName(
      this.classNames,
    ) as HTMLCollectionOf<T>
    this.size = this.elements.length
    this.activateElement(this.elements.item(this.activeIndex))
    makeAutoObservable(
      this,
      {
        elements: false,
      },
      { autoBind: true },
    )
  }
  getActiveElement() {
    const index = this.getCurrentIndex()
    return this.elements.item(index)
  }
  getActiveId() {
    const item = this.getActiveElement()
    return item?.dataset[this.idAttribute] || ''
  }
  getLastId() {
    const elements = this.elements
    const item = elements.item(elements.length - 1)
    return item?.dataset[this.idAttribute] || ''
  }
  clear() {
    this.deactivateElement(this.getActiveElement())
    this.activeIndex = -1
    this.size = this.elements.length
  }
  activateElement(el?: T | null) {
    if (el) {
      el.dataset[this.selectedAttribute] = 'true'
      return el
    }
  }
  deactivateElement(el?: T | null) {
    if (el) {
      delete el.dataset[this.selectedAttribute]
      return el
    }
  }
  getCurrentIndex() {
    this.size = this.elements.length
    if (this.activeIndex >= this.elements.length) {
      this.activeIndex = -1
    }
    return this.activeIndex
  }
  getNextIndex() {
    if (this.activeIndex < this.elements.length - 1) {
      return this.activeIndex + 1
    }
    return -1
  }
  getPreviousIndex() {
    if (this.activeIndex >= 0) {
      return this.activeIndex - 1
    }
    return this.elements.length - 1
  }

  onKeyDown(e: KeyboardEvent | React.KeyboardEvent) {
    switch (e.key) {
      case 'ArrowLeft':
        e.preventDefault()
        this.previous()
        break
      case 'ArrowRight':
        e.preventDefault()
        this.next()
        break

      default:
        break
    }
  }
  next() {
    const currentIndex = this.getCurrentIndex()
    const nextIndex = this.getNextIndex()
    this.activeIndex = nextIndex
    const current = this.elements.item(currentIndex)
    const next = this.elements.item(nextIndex)
    this.deactivateElement(current)
    this.activateElement(next)
    debug('next', this.activeIndex)
  }
  previous() {
    const currentIndex = this.getCurrentIndex()
    const previousIndex = this.getPreviousIndex()
    this.activeIndex = previousIndex
    const current = this.elements.item(currentIndex)
    const previous = this.elements.item(previousIndex)
    this.deactivateElement(current)
    this.activateElement(previous)
    debug('previous', this.activeIndex)
  }
}
