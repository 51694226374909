import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { useObservableProps } from '@jotta/hooks'
import Debug from 'debug'
import { observable } from 'mobx'

import { observer } from 'mobx-react-lite'
import type { FunctionComponent } from 'react'
import { useEffect, useRef, useState } from 'react'
import styles from './Carousel.module.scss'
import type { CarouselStoreProps } from './CarouselStore'
import { CarouselStore } from './CarouselStore'
import { useSlideTransition } from './useSlideTransition'
const debug = Debug('jotta:carousel')

export type CarouselProps<T> = {
  currentItemIndex: number
  items: T[]
  getId: (item: T) => string
  setActiveIndex: (index: number) => void
  ItemHeader?: CarouselItemView<T>
  ItemFooter?: CarouselItemView<T>
  ItemView: CarouselItemView<T>
}
export type CarouselItemViewProps<T> = {
  store: CarouselStore<T>
  item: CarouselItem<T>
}
export type CarouselItemView<T> = FunctionComponent<CarouselItemViewProps<T>>
export type CarouselItem<T> = {
  item: T
  id: string
  index: number
}

export const Carousel = observer(function Carousel<T>({
  currentItemIndex,
  items,
  getId,
  ItemView,
  ItemHeader,
  ItemFooter,
  setActiveIndex,
  ...props
}: CarouselProps<T>) {
  const storeProps = useObservableProps<CarouselStoreProps<T>>(
    {
      getId,
      currentItemIndex,
      items,
      setActiveIndex,
    },
    {
      getId: observable.ref,
      setActiveIndex: observable.ref,
      items: observable.shallow,
    },
    {
      name: 'CarouselProps',
    },
  )
  const [store] = useState(() => new CarouselStore<T>(storeProps))
  const prevButton = useRef<HTMLButtonElement>(null)
  const nextButton = useRef<HTMLButtonElement>(null)
  const { currentSlide, handleTouch, nextSlide, prevSlide } =
    useSlideTransition({
      index: store.currentIndex,
      total: store.total,
      prev: store.prev,
      next: store.next,
    })
  useEffect(() => {
    document.addEventListener('keydown', store.handleKeyDown)
    return () => {
      document.removeEventListener('keydown', store.handleKeyDown)
    }
  }, [store.handleKeyDown])

  return (
    <div
      data-testid="Carousel"
      id="carousel"
      className={styles.container}
      {...props}
    >
      {ItemHeader && <ItemHeader store={store} item={store.currentItem} />}
      <div
        onTouchStart={handleTouch}
        onTouchMove={handleTouch}
        onTouchEnd={handleTouch}
        className={styles.slideContainer}
      >
        <div className={styles.slides}>
          {store.prevItem && (
            <>
              <div ref={prevSlide} className={styles.slidePrev}>
                <ItemView
                  store={store}
                  key={store.prevItem.id}
                  item={store.prevItem}
                />
              </div>
              <button
                id="carousel-prev-button"
                ref={prevButton}
                onClick={store.prev}
                className={styles.navbuttonPrev}
              >
                <PlainBrandIcon icon="SvgCaretLeft" />
              </button>
            </>
          )}
          <div
            ref={currentSlide}
            id="carousel-current-slide"
            className={styles.slide}
          >
            <ItemView
              store={store}
              key={store.currentItem.id}
              item={store.currentItem}
            />
          </div>
          {store.nextItem && (
            <>
              <div ref={nextSlide} className={styles.slideNext}>
                <ItemView
                  store={store}
                  key={store.nextItem.id}
                  item={store.nextItem}
                />
              </div>
              <button
                id="carousel-next-button"
                onClick={store.next}
                ref={nextButton}
                className={styles.navbuttonNext}
              >
                <PlainBrandIcon icon="SvgCaretRight" />
              </button>
            </>
          )}
        </div>
      </div>
      {ItemFooter && <ItemFooter store={store} item={store.currentItem} />}
    </div>
  )
})
