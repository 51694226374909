import { forwardRef } from 'react'
import type { BoxPropsAs } from '../Box/Box'
import { Box } from '../Box/Box'

export type FlexProps = BoxPropsAs<
  'div',
  {
    /** Children should wrap */
    wrap?: boolean
    column?: boolean
    centerItemsHorizontal?: boolean
    centerItemsVertical?: boolean
  },
  'all'
>

export const Flex = forwardRef<HTMLDivElement, FlexProps>(function Flex(
  {
    wrap = false,
    column = false,
    centerItemsHorizontal = false,
    centerItemsVertical = false,
    ...props
  },
  ref,
) {
  const alignItems = centerItemsVertical ? 'center' : 'stretch'
  const justifyContent = centerItemsHorizontal ? 'center' : 'stretch'
  return (
    <Box
      ref={ref as any}
      sx={{
        display: 'flex',
        flexWrap: wrap ? 'wrap' : 'nowrap',
        flexDirection: column ? 'column' : 'row',
        alignItems: column ? justifyContent : alignItems,
        justifyContent: column ? alignItems : justifyContent,
      }}
      {...props}
    />
  )
})
