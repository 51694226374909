import { BrandIcon } from '@jotta/ui/BrandIcon'
import { Trans } from '@lingui/macro'
import styles from './NoTimelinePhotos.module.scss'

import Debug from 'debug'

const debug = Debug('jotta:NoTimelinePhotos')

export const NoTimelinePhotos: React.FC<{ hidden?: boolean }> = ({
  hidden = false,
}) => {
  if (hidden) {
    return (
      <div className={styles.container}>
        <BrandIcon icon="SvgPhotosGray" className={styles.whiteSvg} />
        <div className={styles.wrapper}>
          <h2 className={styles.title}>
            <Trans>You dont have any hidden photos yet!</Trans>
          </h2>
          <p className={styles.subTitle}>
            <Trans>Hidden files will be shown here.</Trans>
          </p>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <BrandIcon icon="SvgPhotosGray" className={styles.graySvg} />
      <h2 className={styles.title}>
        <Trans>You have no photos in your library yet!</Trans>
      </h2>
    </div>
  )
}
