import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import type { PhotosApi } from '@jotta/types/PhotosApi'
import { DialogTrigger } from '@radix-ui/react-dialog'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { DownloadPhotosDialog } from '../Album/Dialogs/DownloadPhotosDialog'
import type { PhotoActionHandlerActionProps } from './PhotoActionHandlers'
import { useDispatchPhotoActionMutation } from './useDispatchPhotoActionMutation'
const debug = Debug('jotta:photos:action:DownloadPhotos')

export const ActionDownloadPhotos = observer<
  PhotoActionHandlerActionProps<'DOWNLOAD_PHOTOS'>
>(function ActionDownloadPhotos({
  action,
  getDownloadInfo,
  photoIds,
  dispatch,
  buttonVariant = 'buttons.albumActionButton',
}) {
  const [open, setOpen] = useState(false)
  const [downloadState, setDownloadState] = useState<
    PhotosApi.DownloadPhotosInfo | undefined
  >(undefined)
  const mutation = useDispatchPhotoActionMutation(
    'DOWNLOAD_PHOTOS',
    undefined,
    dispatch,
  )
  return (
    <DownloadPhotosDialog
      open={open}
      onClose={async open => {
        if (open && getDownloadInfo) {
          setOpen(true)
          const info = await getDownloadInfo(
            photoIds && photoIds.length ? photoIds : undefined,
          )
          debug('info', info)
          setDownloadState(info)
          const { urls, fileName } = info
          if (urls && urls.length) {
            mutation.mutateAsync([urls, fileName])
          }
        } else {
          setOpen(false)
        }
      }}
      downloadState={downloadState}
      triggerElement={
        <DialogTrigger
          aria-label={action.label}
          data-testid={action.testid}
          aria-busy={mutation.isPending}
          key={action.actionType}
          sx={{
            variant: buttonVariant,
          }}
        >
          <PlainBrandIcon icon={action.icon} />
          <span>{action.label}</span>
        </DialogTrigger>
      }
    />
  )
})
