import { AlertStore, useAlertListStore } from '@jotta/ui/AlertStore'
import { Trans, t } from '@lingui/macro'
import { useMemo } from 'react'
export function useLoginToAddPhotosAlert(publicShareUri = '') {
  const { alert } = useAlertListStore()
  return useMemo(() => {
    const loginToAddPhotosAlert = new AlertStore({
      testid: 'LoginToAddPhotosAlert',
      message: <Trans>Log in to add your own photos</Trans>,
      severity: 'loginToAddPhotosAlert',
      actions: [
        {
          action: `/share/${publicShareUri}/addphotos`,
          text: t`AddPhotosButton.label`,
          testId: 'LoginToAddPhotosAlertButton',
        },
      ],
    })
    function displayLoginToAddPhotosAlert() {
      loginToAddPhotosAlert.reOpen()
      alert.showAlert(loginToAddPhotosAlert)
    }
    return {
      loginToAddPhotosAlert,
      displayLoginToAddPhotosAlert,
    }
  }, [publicShareUri, alert])
}
