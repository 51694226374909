import { Button } from '@jotta/ui/Button'
import type { SelectVariant } from '@jotta/ui/types/VariantTypes'
import type { BrandingInfo } from '@jotta/grpc-web/no/jotta/openapi/customer/customer.v2_pb'
import type { GetPaymentInfoResponse } from '@jotta/grpc-web/no/jotta/openapi/subscription/payment.v2_pb'
import { SubscriptionMessage } from '@jotta/grpc-web/no/jotta/openapi/subscription/payment.v2_pb'
import { Card } from '@jotta/ui/Card'
import { FormattedContent } from '@jotta/ui/FormattedContent'
import { Trans, t } from '@lingui/macro'
import dayjs from 'dayjs'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'

const debug = Debug('jotta:SubscriptionAlerts')

type MessageContent = {
  [key in keyof typeof SubscriptionMessage.MessageCase]?: {
    title: string
    message: React.ReactElement
    variant: SelectVariant<'cards'>
  }
}
export interface SubscriptionAlertsProps {
  alerts: SubscriptionMessage.MessageCase[]
  brandingInfo?: Pick<BrandingInfo.AsObject, 'brandName' | 'customerGroupName'>
  subscription?: Pick<GetPaymentInfoResponse.AsObject, 'expirationDateMillis'>
  onModifyPaymentMethod: () => void
}

export const SubscriptionAlerts = observer<SubscriptionAlertsProps>(
  ({ alerts, onModifyPaymentMethod, subscription, brandingInfo }) => {
    const modifyPaymentMethodHandler = () => onModifyPaymentMethod()
    const brandName = brandingInfo?.brandName || 'Jottacloud'
    const serviceName = brandingInfo?.customerGroupName || 'Jottacloud'
    const date = subscription?.expirationDateMillis?.value
      ? dayjs(subscription?.expirationDateMillis?.value).format('L')
      : dayjs().format('L')

    const messageContents: MessageContent = {
      [SubscriptionMessage.MessageCase.CARD_EXPIRED]: {
        title: t`Subscription notice`,
        variant: 'cards.dangerOutline',
        message: (
          <p>
            <Trans
              id={
                'Your credit card has expired, you should <0>update your payment information.</0>'
              }
            >
              Your credit card has expired, you should{' '}
              <Button
                variant="buttons.link"
                onClick={modifyPaymentMethodHandler}
              >
                update your payment information.
              </Button>
            </Trans>
          </p>
        ),
      },
      [SubscriptionMessage.MessageCase.CARD_EXPIRES_SOON]: {
        title: t`Subscription notice`,
        variant: 'cards.dangerOutline',
        message: (
          <p>
            <Trans
              id={
                'Your credit card is about to expire, you should <0>update your payment information.</0>'
              }
            >
              Your credit card is about to expire, you should{' '}
              <Button
                variant="buttons.link"
                onClick={modifyPaymentMethodHandler}
              >
                update your payment information.
              </Button>
            </Trans>
          </p>
        ),
      },
      [SubscriptionMessage.MessageCase.DUE_FOR_TERMINATION_OFFER]: {
        title: t`Subscription notice`,
        variant: 'cards.dangerOutline',
        message: (
          <p>
            <Trans
              id={
                'Your {brandName} subscription expires {date} due to your termination with {serviceName}. The {brandName} service is provided by Jottacloud. If you want to continue keeping your files and photos safely backed up, your {brandName} account can <0>easily be converted to a Jottacloud account</0>.'
              }
            >
              Your {brandName} subscription expires {date} due to your
              termination with {serviceName}. The {brandName} service is
              provided by Jottacloud. If you want to continue keeping your files
              and photos safely backed up, your {brandName} account can{' '}
              <a href="/web/convertTerm">
                easily be converted to a Jottacloud account.
              </a>
            </Trans>
          </p>
        ),
      },
    }

    return (
      <>
        {alerts.map(type => {
          const content = messageContents[type]

          return (
            content && (
              <Card
                title={t`Subscription notice`}
                key={type}
                variant="cards.dangerOutline"
              >
                <FormattedContent>{content.message}</FormattedContent>
              </Card>
            )
          )
        })}
      </>
    )
  },
)
