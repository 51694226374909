import type { ApiError } from '@jotta/grpc-web/no/jotta/openapi/api_error'
import type { ApiErrorResponse } from '@jotta/grpc-web/no/jotta/openapi/api_error'
import { Code, ConnectError } from '@connectrpc/connect'
import { ErrorType } from '@jotta/grpc-web/no/jotta/openapi/error_pb'
import axios from 'axios'
import Debug from 'debug'
import { nanoid } from 'nanoid'
import { isRouteErrorResponse } from 'react-router-dom'
import { ZodError, z } from 'zod'
import { HTTPErrorSchema } from './schemas/HTTPErrorSchema'
import {
  ApiErrorResponseSchema,
  GrpcApiErrorSchema,
  GrpcRawErrorSchema,
} from './schemas/ErrorSchema'
import { GrpcApiError } from './GrpcApiError'
import { StatusCode } from 'grpc-web'
import { mapValues, shake } from 'radash'
import dayjs from 'dayjs'
import type { ReactNode } from 'react'

const debug = Debug('jotta:types:AppError')

export const notFoundErrorTypes = [
  ErrorType.NOT_FOUND,
  ErrorType.FILE_NOT_FOUND,
  ErrorType.PATH_NOT_FOUND,
] as const
export type NotFoundErrorType = (typeof notFoundErrorTypes)[number]

export function isNotFoundErrorType(code: unknown): code is NotFoundErrorType {
  return notFoundErrorTypes.includes(code as NotFoundErrorType)
}

export interface AppErrorArgs {
  xid?: string
  GrpcStatus?: Code
  HTTPStatus?: number
  message?: string
  stack?: string
  errors?: ApiError[]
  cause?: unknown
  view?: ReactNode
}
export class AppError extends Error {
  xid?: string
  grpcStatus?: Code
  grpcStatusText?: string
  status?: number
  stack?: string
  errors: ApiError[]
  timestamp: number
  href: string
  view?: ReactNode
  constructor({
    xid,
    GrpcStatus,
    HTTPStatus = GrpcStatus === Code.NotFound ? 404 : undefined,
    message = 'Unknown error',
    stack,
    errors = [],
    cause,
    view,
  }: AppErrorArgs) {
    // 'Error' breaks prototype chain here
    super(`[${xid}]: ${message}`)
    this.name = this.constructor.name

    // restore prototype chain
    const actualProto = new.target.prototype

    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(this, actualProto)
    } else {
      //@ts-ignore
      this.__proto__ = actualProto
    }
    this.cause = cause
    this.message = message
    this.timestamp = Date.now()
    this.href = window.location.href
    this.xid = xid
    this.grpcStatus = GrpcStatus
    this.grpcStatusText = GrpcStatus && StatusCode[GrpcStatus]
    this.status = HTTPStatus
    this.stack = stack
    this.errors = errors
    this.view = view
  }
  get isNotFound() {
    if (
      this.cause instanceof ConnectError &&
      this.cause.code === Code.NotFound
    ) {
      return true
    }
    return this.grpcStatus === Code.NotFound || this.status === 404
  }
  get isUnauthenticated() {
    if (
      this.cause instanceof ConnectError &&
      this.cause.code === Code.Unauthenticated
    ) {
      return true
    }
    return this.grpcStatus === Code.Unauthenticated
  }
  toJSON(): Record<string, string> {
    return mapValues(
      shake({
        xid: this.xid,
        grpcStatus: this.grpcStatus,
        grpcStatusText: this.grpcStatusText,
        stack: this.stack,
        status: this.status,
        message: this.message,
        timestamp: this.timestamp,
        date: dayjs(this.timestamp).format('LL LTS'),
        href: this.href,
      }),
      String,
    )
  }
}
export function isAppError(error: unknown): error is AppError {
  return error instanceof AppError
}

export function isAppErrorNotFound(error: unknown) {
  return isAppError(error) ? error.isNotFound : false
}

export function isApiErrorResponse(error: unknown): error is ApiErrorResponse {
  const result = ApiErrorResponseSchema.safeParse(error)
  if (result instanceof z.ZodError) {
    return false
  }
  return true
}

export class HTTPError extends Error {
  response: Response
  request: Request
  constructor(response: Response, request: Request) {
    const code = response.status || response.status === 0 ? response.status : ''
    const title = response.statusText || ''
    const status = `${code} ${title}`.trim()
    const reason = status ? `status code ${status}` : 'an unknown error'
    super(`Request failed with ${reason}`)
    this.name = 'HTTPError'
    this.response = response
    this.request = request
  }
}

export const handleGrpcError = (error: unknown) => {
  if (error instanceof AppError) {
    return error
  }
  const grpcApiError = GrpcApiErrorSchema.safeParse(error)
  if (grpcApiError.success) {
    return new AppError({
      xid: grpcApiError.data.xid,
      errors: grpcApiError.data.parsed.errors,
      message: grpcApiError.data.message || grpcApiError.data.parsed.message,
      GrpcStatus: grpcApiError.data.code,
    })
  }
  const grpcError = GrpcRawErrorSchema.safeParse(error)
  if (grpcError.success) {
    return new AppError({
      ...grpcError.data.parsed,
      GrpcStatus: grpcError.data.code,
    })
  }
  const parsed = ApiErrorResponseSchema.safeParse(error)
  if (parsed.success) {
    return new AppError(parsed.data)
  }
}

export function handleUnknownError(err: unknown): AppError {
  debug(err)

  if (err instanceof Array) {
    return handleUnknownError(err[0])
  }

  if (err instanceof AppError) {
    return err
  }
  if (err instanceof ConnectError) {
    const parsedMessage = GrpcApiErrorSchema.safeParse(err.message)
    if (parsedMessage.success) {
      return new AppError({
        xid: parsedMessage.data.xid,
        errors: parsedMessage.data.errors,
        message: parsedMessage.data.message,
        GrpcStatus: parsedMessage.data.code,
        cause: err,
      })
    }
    const parsedResponse = ApiErrorResponseSchema.safeParse(err.rawMessage)
    if (parsedResponse.success) {
      const { xid, errors, message } = parsedResponse.data
      return new AppError({
        xid,
        errors,
        message,
        GrpcStatus: err.code,
        cause: err,
      })
    }
    return new AppError({
      cause: err,
      message: err.message,
      GrpcStatus: err.code,
    })
  }
  if (err instanceof GrpcApiError) {
    return new AppError({
      xid: err.xid,
      errors: err.errors,
      message: err.message,
      GrpcStatus: err.code,
      cause: err,
    })
  }
  if (isRouteErrorResponse(err)) {
    return new AppError({
      HTTPStatus: err.status,
      message: err.statusText,
      cause: err,
    })
  }
  if (err instanceof HTTPError) {
    return new AppError({
      HTTPStatus: err.response.status,
      message: err.message,
      stack: err.stack,
      cause: err,
    })
  }
  if (err instanceof ZodError) {
    const message = `${err.name}:${err.errors
      .map(({ path, message }) => `${path}:${message}`)
      .join(', ')}`
    debug('ZodError', message)
    return new AppError({
      message,
      stack: err.stack,
      cause: err,
    })
  }
  const parsedHTTPError = HTTPErrorSchema.safeParse(err)
  if (parsedHTTPError.success) {
    const { message, stack, xid, response } = parsedHTTPError.data
    return new AppError({
      HTTPStatus: response.status,
      message,
      stack,
      xid: xid || response.headers.get('x-id') || undefined,
      cause: err,
    })
  }
  if (axios.isAxiosError(err)) {
    return new AppError({
      xid: nanoid(),
      message: err.message,
      HTTPStatus: err.response?.status,
      stack: err.stack,
      cause: err,
    })
  }
  const grpcError = handleGrpcError(err)
  if (grpcError) {
    return grpcError
  }

  if (err instanceof Error) {
    return new AppError({
      message: err.message,
      stack: err.stack,
      cause: err,
    })
  }

  return new AppError({
    message: 'Unrecognized error',
    cause: err,
  })
}
