import { queryClient } from '@jotta/query'
import type { MethodUnaryDescriptor } from '@connectrpc/connect-query'
import {
  callUnaryMethod,
  createConnectQueryKey,
} from '@connectrpc/connect-query'
import type { Message, PartialMessage } from '@bufbuild/protobuf'
import type { Transport } from '@connectrpc/connect'

export async function query<I extends Message<I>, O extends Message<O>>(
  descriptor: MethodUnaryDescriptor<I, O>,
  data: PartialMessage<I>,
  transport: Transport,
): Promise<O> {
  return await queryClient.fetchQuery({
    queryKey: createConnectQueryKey(descriptor, data),
    queryFn: context =>
      callUnaryMethod(descriptor, data, {
        transport,
      }),
  })
}

export async function mutate<I extends Message<I>, O extends Message<O>>(
  descriptor: MethodUnaryDescriptor<I, O>,
  data: PartialMessage<I>,
  transport: Transport,
): Promise<O> {
  return callUnaryMethod(descriptor, data, {
    transport,
  })
}
