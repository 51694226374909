import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { Button } from '@jotta/ui/Button'
import { useHideFabOnMobile } from '@jotta/ui/useHideFab'
import { useHideModuleNavOnMobile } from '@jotta/ui/useHideModuleNav'
import { Trans } from '@lingui/macro'
import * as Popover from '@radix-ui/react-popover'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import styles from './PathItemRow.module.scss'
import type { PathItemStore } from './PathItemStore'
import { useSelectNamePart } from './useSelectNamePart'

const debug = Debug('jotta:files:PathItemThumbRename')
export interface PathItemThumbRenameProps {
  item: PathItemStore
}

export const PathItemThumbRename = observer<PathItemThumbRenameProps>(
  function PathItemThumbRename({ item }) {
    const [inputEl, setInputEl] = useState<HTMLInputElement | null>(null)
    const open = item.showRenameInput

    useHideFabOnMobile(Boolean(inputEl))
    useHideModuleNavOnMobile(Boolean(inputEl))
    useSelectNamePart(inputEl)

    useEffect(() => {
      if (open) {
        document.body.classList.add('fab-open')
      }

      return () => {
        document.body.classList.remove('fab-open')
      }
    }, [open])

    return (
      <Popover.Root open={open} onOpenChange={item.onRenameCancel} modal={true}>
        <Popover.Trigger asChild>
          <div className={styles.renamePopoverTrigger} />
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content data-rename className={styles.renamePopover}>
            <div className={styles.renamePopoverTitle}>
              <p>
                <Trans>Change name</Trans>
              </p>
              <Popover.Close>
                <PlainBrandIcon icon="SvgClose" />
              </Popover.Close>
            </div>
            <form onSubmit={item.onRenameSubmit}>
              <input
                ref={setInputEl}
                data-isfile={item.isFile}
                type="text"
                value={item.model.name}
                onChange={item.onNameInputChange}
              />
              <Button variant="buttons.accent" type="submit">
                <Trans>Ok</Trans>
              </Button>
            </form>
            <Popover.Arrow />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    )
  },
)
