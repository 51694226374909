import { isGrpcConnectNotFoundError } from '@jotta/grpc-connect-client/error'
import { useGetFlow } from '@jotta/grpc-connect-client/vipps'
import { AppLayoutSignupContent } from '@jotta/ui/AppLayoutSignup'
import { ErrorPage } from '@jotta/ui/ErrorPage'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import { autoLogin } from '@jotta/utils/auth'
import { t } from '@lingui/macro'
import Debug from 'debug'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
const debug = Debug('jotta:webapp:SignupStatusRoute')

export function PaymentStatusRoute() {
  const { flowId = '' } = useParams()
  const query = useGetFlow({
    variables: {
      flowId,
    },
  })
  const { data, error } = query
  const title = t`Waiting for payment confirmation`
  useEffect(() => {
    if (data) {
      switch (data.state) {
        case 'success':
          {
            if (data.username && data.token) {
              autoLogin({
                redirectTo: data.redirectUrl,
                token: data.token,
                username: data.username,
                replace: true,
              })
            } else {
              window.location.replace(data.redirectUrl)
            }
          }
          break

        case 'failed':
          window.location.replace(data.redirectUrl)
          break
      }
    }
  })
  if (error) {
    if (isGrpcConnectNotFoundError(error)) {
      return (
        <AppLayoutSignupContent title={t`Page not found`}>
          <ErrorPage hideFooter notFound />
        </AppLayoutSignupContent>
      )
    }
    return (
      <AppLayoutSignupContent title={t`An error occured!`}>
        <ErrorPage hideFooter error={error} />
      </AppLayoutSignupContent>
    )
  }
  return (
    <AppLayoutSignupContent title={title}>
      <div className="relative min-h-72 flex-auto">
        <LoadingOverlay open={!data} position="absolute" />
      </div>
    </AppLayoutSignupContent>
  )
}
