import { Button } from '@jotta/ui/Button'
import { Flex } from '@jotta/ui/Flex'
import type { ThemeUIStyleObject } from '@jotta/ui/themeui'
import { getBrandZIndex } from '@jotta/ui/zIndex'
import type { OnboardingPopupKeys } from '@jotta/grpc-js-client/customerService'
import { Trans } from '@lingui/macro'
import * as Popover from '@radix-ui/react-popover'
import { observer } from 'mobx-react-lite'
import type { ReactNode } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { Portal } from 'react-portal'
import { FormattedContent } from '../../Elements/FormattedContent/FormattedContent'
import { onboardingStore } from './OnboardingStore'
import { useBrandName } from '@jotta/grpc-connect-client/config'

function OnboardingOverlay() {
  return (
    <Portal>
      <div
        data-testid="onboarding-overlay"
        sx={{
          position: 'fixed',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          bg: 'rgba(0,0,0,0.4)',
          zIndex: getBrandZIndex('modal'),
        }}
      ></div>
    </Portal>
  )
}

const onboardingDot: ThemeUIStyleObject = {
  '@keyframes dot-jsx': {
    to: {
      opacity: 0.75,
    },
  },
  '@keyframes puls-jsx': {
    to: {
      opacity: 0.6,
      transform: 'scale(0.9)',
    },
  },
  bg: 'var(--color-onboarding, var(--color-primary))',
  borderRadius: '100%',
  height: '14px',
  width: '14px',
  position: 'absolute',
  animation: 'dot-jsx 1s alternate both infinite ease-in-out',
  alignSelf: 'center',
  zIndex: getBrandZIndex('modal'),
  right: 0,
  '&::before': {
    content: '""',
    bg: 'transparent',
    border: '5px solid var(--color-onboarding, var(--color-primary))',
    borderRadius: '100%',
    height: '36px',
    width: '36px',
    position: 'absolute',
    top: '-11px',
    left: '-11px',
    zIndex: '-1',
    opacity: '0.75',
    transform: 'scale(0.6)',
    boxSizing: 'border-box',
    animation: 'puls-jsx 1s alternate both infinite ease-in-out',
  },
}

function OnboardingArchive() {
  const brandName = useBrandName()

  return (
    <>
      <h3>
        <Trans id="Archive" />
      </h3>
      <p>
        <Trans id="Onboarding.archive">
          Copy files and folders to the Archive to store them forever in{' '}
          {brandName}. You can then remove them from you computer or phone to
          save storage space.
        </Trans>
      </p>
    </>
  )
}

function OnboardingBackup() {
  return (
    <>
      <h3>
        <Trans id="Backed up" />
      </h3>
      <p>
        <Trans id="Onboarding.backup">
          All files and folders that are backed up from your computers and
          mobiles, organized in the original folder structure that you know and
          love.
        </Trans>
      </p>
    </>
  )
}

function OnboardingFab() {
  return (
    <>
      <h3>
        <Trans id="Onboarding.fabTitle">Action button</Trans>
      </h3>
      <p>
        <Trans id="Onboarding.fab">
          Click the button to get started with your uploads. You can also drag
          files from your desktop and drop them in the browser.
        </Trans>
      </p>
    </>
  )
}

function OnboardingFiles() {
  return (
    <>
      <h3>
        <Trans id="Files" />
      </h3>
      <p>
        <Trans id="Onboarding.files">
          All your files and folders, organized in the original folder structure
          that you know and love.
        </Trans>
      </p>
    </>
  )
}

function OnboardingNavMenu() {
  return (
    <>
      <h3>
        <Trans id="Onboarding.navMenuTitle">Navigation menu</Trans>
      </h3>
      <p>
        <Trans id="Onboarding.navMenu">
          Use the menu to view your Files and Photos.
        </Trans>
      </p>
    </>
  )
}

function OnboardingPhotos() {
  return (
    <>
      <h3>
        <Trans id="Photos" />
      </h3>
      <p>
        <Trans id="Onboarding.photos">
          A timeline of all the photos you have stored. Easily organize and
          share your photos.
        </Trans>
      </p>
    </>
  )
}

function OnboardingShared() {
  return (
    <>
      <h3>
        <Trans id="Shared" />
      </h3>
      <p>
        <Trans id="Onboarding.shared">
          All the files and folders people have shared with you, or you have
          shared with others, are shown here.
        </Trans>
      </p>
    </>
  )
}

function OnboardingSync() {
  return (
    <>
      <h3>
        <Trans id="Synced" />
      </h3>
      <p>
        <Trans id="Onboarding.sync">
          Synchronize files and folders between all your devices and friends.
        </Trans>
      </p>
    </>
  )
}

function OnboardingTrash() {
  return (
    <>
      <h3>
        <Trans id="Trash" />
      </h3>
      <p>
        <Trans id="Onboarding.trash">
          If you delete a file or folder it's moved to trash, where it is stored
          for 30 days. Pretty sweet!
        </Trans>
      </p>
    </>
  )
}

function OnboardingContent({ task }: { task: OnboardingPopupKeys }) {
  let content: React.ReactNode = null

  switch (task) {
    case 'archive':
      content = <OnboardingArchive />
      break

    case 'backup':
      content = <OnboardingBackup />
      break

    case 'fab':
      content = <OnboardingFab />
      break

    case 'files':
      content = <OnboardingFiles />
      break

    case 'navMenu':
      content = <OnboardingNavMenu />
      break

    case 'photos':
      content = <OnboardingPhotos />
      break

    case 'shared':
      content = <OnboardingShared />
      break

    case 'sync':
      content = <OnboardingSync />
      break

    case 'trash':
      content = <OnboardingTrash />
      break

    default:
      return null
  }

  return (
    <FormattedContent variant="formats.onboarding">{content}</FormattedContent>
  )
}

export const Onboarding = observer<{
  task: OnboardingPopupKeys
  asChild?: boolean
  align?: 'center' | 'top' | 'bottom'
  children?: ReactNode
  onOpen?: () => void
}>(function ({ task, children, asChild = false, align = 'center', onOpen }) {
  const completed = onboardingStore.isCompleted(task)
  const { triggerPopup } = onboardingStore
  const [open, setOpen] = useState(onboardingStore.triggerPopup === task)

  useEffect(() => {
    if (triggerPopup === task) {
      setOpen(true)
    }
  }, [task, triggerPopup])

  useEffect(() => {
    if (open && onOpen) {
      onOpen()
    }
  }, [open, onOpen])

  const alignSelf =
    align === 'top' ? 'flex-start' : align === 'bottom' ? 'flex-end' : 'center'

  const completeTask = useCallback(() => {
    onboardingStore.complete(task)
  }, [task])

  const skip = useCallback(() => {
    onboardingStore.completeAll()
  }, [])

  if (completed) {
    return <>{children}</>
  }

  const trigger = children ? (
    <Popover.Trigger
      asChild={asChild}
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      <div
        sx={{
          display: 'flex',
          position: 'relative',
          cursor: 'pointer',
        }}
      >
        {children}
        <div
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
          }}
        />
        <div sx={{ ...onboardingDot, alignSelf }} />
      </div>
    </Popover.Trigger>
  ) : (
    <Popover.Trigger
      sx={{
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
      }}
      onClick={e => {
        setOpen(true)
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <div sx={{ ...onboardingDot, alignSelf }} />
    </Popover.Trigger>
  )

  return (
    <Popover.Root modal open={open} onOpenChange={setOpen}>
      {trigger}
      <Popover.Portal>
        <Popover.Content
          sx={{
            background: 'white',
            padding: 4,
            borderRadius: 2,
            boxShadow: '0 0 8px rgba(0, 0, 0, 0.15)',
            maxWidth: '320px',
            position: 'relative',
            zIndex: getBrandZIndex('onboarding'),
          }}
          sideOffset={-10}
        >
          <OnboardingOverlay />
          <OnboardingContent task={task} />
          <div className="mt-6 flex justify-between gap-2">
            <Popover.Close asChild>
              <Button variant="buttons.secondary" onClick={skip}>
                <Trans id="Skip" />
              </Button>
            </Popover.Close>
            <Popover.Close asChild>
              <Button variant="buttons.primary" onClick={completeTask}>
                <Trans id="Onboarding.gotIt">Got it!</Trans>
              </Button>
            </Popover.Close>
          </div>
          <Popover.Arrow width={12} height={6} sx={{ fill: 'white' }} />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
})
