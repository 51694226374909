import { tv } from 'tailwind-variants'

export const buttonVariants = tv({
  slots: {
    base: 'relative grid auto-cols-max grid-flow-col items-center gap-2 rounded-md border border-gray-300 px-4 py-2 transition-[filter_300ms] hover:brightness-75 hover:contrast-150 focus-visible:ring-2 focus-visible:ring-offset-1',
    text: 'block text-nowrap px-1 leading-6',
  },
  variants: {
    variant: {
      primary: {
        base: 'border-gray-300 bg-primary text-sx-primary ring-primary',
      },
      secondary: {
        base: 'border-gray-300 bg-sx-secondary text-sx-secondary-text ring-primary',
      },
      accent: {
        base: 'bg-sx-accent text-sx-accent-text ring-sx-accent',
      },
      danger: {
        base: 'bg-danger text-sx-danger-text ring-danger',
      },
      plain: {
        base: 'border-transparent text-sx-text ring-primary',
        text: 'text-left',
      },
    },
    size: {
      small: {
        base: 'py-1 text-sm sm:py-1',
      },
      flex: {
        base: 'h-full py-0 sm:py-0',
      },
    },
    responsive: {
      true: {
        text: 'hidden sm:block',
      },
    },
    disabled: {
      true: 'pointer-events-none opacity-50',
    },
    debugfocus: {
      true: 'ring-2 ring-offset-1',
    },
  },
})
