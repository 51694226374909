import { Box } from '@jotta/ui/Box'
import { Button } from '@jotta/ui/Button'
import { Flex } from '@jotta/ui/Flex'
import { getBrandZIndex } from '@jotta/ui/zIndex'
import { Trans } from '@lingui/macro'
import * as Dialog from '@radix-ui/react-dialog'
import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'
import { FormattedContent } from '../../Elements/FormattedContent/FormattedContent'
import { onboardingStore } from './OnboardingStore'
import { useBrandName } from '@jotta/grpc-connect-client/config'
import WelcomeSvg from './welcome.svg'

export const WelcomeContent = observer<{ onClose: () => void }>(function ({
  onClose,
}) {
  const completed = onboardingStore.isCompleted('welcome')
  const brandName = useBrandName()

  const onSkip = useCallback(() => {
    onboardingStore.completeAll()
    onClose()
  }, [onClose])

  if (completed) {
    return null
  }

  return (
    <Flex
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        bg: 'rgba(255,255,255,0.9)',
        justifyContent: 'center',
        alignItems: 'flex-start',
        pt: '160px',
        zIndex: getBrandZIndex('modal'),
      }}
    >
      <Flex
        sx={{
          flexWrap: 'wrap',
          maxWidth: '832px',
          width: '100%',
        }}
      >
        <Box sx={{ px: 4, flex: ['0 1 auto', '0 0 50%'] }}>
          <WelcomeSvg
            sx={{
              width: '100%',
              height: 'auto',
            }}
          />
        </Box>
        <Box sx={{ px: 4, flex: '1 1 416px' }}>
          <FormattedContent variant="formats.welcome">
            <Trans id="Onboarding.welcomeTitle">
              <h2>Welcome to</h2>
              <h3>{brandName}!</h3>
            </Trans>
            <p>
              <Trans id="Onboarding.welcome">
                You now have an easy way to keep your data safe, and share files
                with your friends and family.
              </Trans>
            </p>
          </FormattedContent>
          <div className="mt-6 flex gap-3">
            <Button variant="buttons.primary" onClick={onClose}>
              <Trans id="Onboarding.getStarted">Get started!</Trans>
            </Button>
            <Button variant="buttons.secondary" onClick={onSkip}>
              <Trans id="Skip" />
            </Button>
          </div>
        </Box>
      </Flex>
    </Flex>
  )
})

export const WelcomeDialog = observer(function WelcomeDialog() {
  const completed = onboardingStore.isCompleted('welcome')

  const completeTask = useCallback(() => {
    onboardingStore.complete('welcome')
  }, [])
  if (completed) {
    return null
  }

  return (
    <Dialog.Root open>
      <Dialog.Portal>
        <Dialog.Content asChild>
          <WelcomeContent onClose={completeTask} />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
})
