import { arrayToPath } from './pathUtils'

export function addUrlParams(
  urlStr: string,
  params: Record<string, string>,
  overwriteExistingParams = false,
) {
  const url = new URL(urlStr)
  Object.entries(params).forEach(([key, value]) => {
    if (overwriteExistingParams || !url.searchParams.has(key)) {
      url.searchParams.set(key, value)
    }
  })
  return url.href
}
export function absoluteUrl(urlStr: string) {
  return new URL(urlStr, document.location.origin).href
}
export function createUrl(...parts: string[]) {
  return new URL(arrayToPath(parts), document.location.origin).href
}

export function redirect(
  from = '/',
  to = '/',
  origin = window?.location?.origin || 'http://localhost',
) {
  const base = new URL(from, origin)
  const next = new URL(to, origin)
  // make sure we retain all query params:
  base.searchParams.forEach((value, key) => {
    if (!next.searchParams.has(key)) {
      next.searchParams.set(key, value)
    }
  })
  return next.href
}
