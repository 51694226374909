import dayjs from 'dayjs'
import debounce from 'debounce-fn'
import Debug from 'debug'
import {
  action,
  computed,
  makeAutoObservable,
  observable,
  onBecomeObserved,
  onBecomeUnobserved,
  reaction,
  set,
} from 'mobx'
import { now } from 'mobx-utils'
import type { RefObject } from 'react'
import { pipe, pick, mapValues } from 'remeda'
import { isString } from 'remeda'
import Cookies from 'universal-cookie'

import { brands } from '@jotta/types/Brand'
import type { Rect } from '@jotta/types/Dimensions'
import { objectKeys } from '@jotta/types/TypeUtils'
import { fileRouteRegEx } from '@jotta/types/schemas/FileRouteSchema'
import { isFolderInputSupported } from '@jotta/utils/browserFeatureChecks'
import { px } from '@jotta/utils/css'
import { detectScrollbarWidth } from '@jotta/utils/detectScrollbarWidth'
import { ensureValidNumber } from '@jotta/utils/typeAssertions'

import type { BrandTheme } from '../../types/BrandTheme'
import { getResponsiveValue } from '../../utils/getResponsiveValue'
import { getThemeCSSVariables } from '../../utils/getThemeCSSVariables'
import {
  getCSSCustomProperty,
  setElementCSSCustomProperties,
} from '../../utils/setCSSCustomProperty'

const cookies = new Cookies()

function setWideDrawerCookie(isOpen: boolean) {
  document.cookie = `wideDrawerPreference=${isOpen}; Path=/; Domain=${window.location.hostname}; expires=Fri, 31 Dec 9999 23:59:59 GMT;`
}
export type AppLayoutSlot = keyof BrandStore['slots']
function createObservableRef<T>(initialValue: null | T = null) {
  const refValue = observable.box(initialValue, {
    deep: false,
  })
  const ref = makeAutoObservable<RefObject<T>>({
    get current() {
      return refValue.get()
    },
    set current(value) {
      refValue.set(value)
    },
  })
  return ref
}

function getDocumentRect(): Rect {
  const rect = document.documentElement.getBoundingClientRect()
  // toJSON doesn't exist in Jest and breaks the tests
  if (rect.toJSON) {
    return rect.toJSON()
  }
  return rect
}
export class BrandStore {
  theme: BrandTheme
  activeElements = {
    timelineScrubber: false,
    topNav: fileRouteRegEx.test(window.location.pathname),
    moduleNav: true,
    // Should have an initial value of false on public pages
    leftNav: !/^\/(s|share|download)\/./.test(window.location.pathname),
  }
  slots = {
    timelineScrubber: createObservableRef<HTMLDivElement>(),
    moduleNav: createObservableRef<HTMLDivElement>(),
    leftNav: createObservableRef<HTMLDivElement>(),
    actionButton: createObservableRef<HTMLDivElement>(),
    commentsButton: createObservableRef<HTMLDivElement>(),
    uploadStatus: createObservableRef<HTMLDivElement>(),
    bottomStatusBar: createObservableRef<HTMLDivElement>(),
    header: createObservableRef<HTMLDivElement>(),
  }
  slotCounts: Record<AppLayoutSlot, number> = {
    timelineScrubber: 0,
    moduleNav: 0,
    leftNav: 0,
    actionButton: 0,
    commentsButton: 0,
    bottomStatusBar: 0,
    uploadStatus: 0,
    header: 0,
  }
  registerSlot(slot: AppLayoutSlot) {
    this.slotCounts[slot]++
  }
  unregisterSlot(slot: AppLayoutSlot) {
    this.slotCounts[slot]--
  }
  // Width of window, including scrollbar
  windowSize = {
    width: 0,
    height: 0,
  }
  // Size of viewport, excluding scrollbars
  rect: Rect
  scroll = {
    top: 0,
    scrollHeight: 0,
  }
  headerHeight: number
  scrollUnthrottled = {
    top: 0,
    scrollHeight: 0,
  }
  scrollUpdatedAt = 0
  scrollbarWidth = detectScrollbarWidth()
  private breakpointObserver: ResizeObserver
  private resizeObserver: ResizeObserver
  private _wideDrawerPreference: boolean =
    String(cookies.get('wideDrawerPreference')) === 'true'
  private openState: number = -1

  private log = Debug('jotta:branding:BrandStore')
  currentBreakpoint: number
  constructor(theme: BrandTheme) {
    this.log(
      'Create store %s',
      theme.branding.brand,
      cookies.get('wideDrawerPreference'),
    )
    this.headerHeight = theme.branding.header.height
    this.theme = theme
    /**
     * Setting correct initial dimensions allows scroll restoration to work
     * automatically with react-query when creating virtual lists based on these
     * dimensions. If they are briefly 0 when you click back the scroll resets to top.
     */
    this.rect = observable<Rect>(getDocumentRect())
    this.scroll.top = document.documentElement.scrollTop
    this.scroll.scrollHeight = document.documentElement.scrollHeight
    this.windowSize.width = window.innerWidth
    this.windowSize.height = window.innerHeight
    this.currentBreakpoint = this.getBreakPoint()
    makeAutoObservable<
      typeof this,
      'resizeObserver' | 'breakpointObserver' | 'log'
    >(
      this,
      {
        activateElements: action.bound,
        breakpoints: computed.struct,
        breakpointObserver: false,
        globalStyles: computed.struct,
        log: false,
        onResize: false,
        onResizeThrottled: false,
        onScrollThrottled: false,
        onScrollUnthrottled: false,
        resizeObserver: false,
        theme: observable.struct,
      },
      {
        autoBind: true,
      },
    )

    this.breakpointObserver = new window.ResizeObserver(this.updateBreakpoint)
    this.breakpointObserver.observe(document.documentElement)
    this.resizeObserver = new window.ResizeObserver(this.onResizeThrottled)
    this.resizeObserver.observe(document.documentElement)

    onBecomeObserved(this.scroll, 'top', this.startObserveScrolling)
    onBecomeUnobserved(this.scroll, 'top', this.stopObserveScrolling)
    onBecomeObserved(
      this.scrollUnthrottled,
      'top',
      this.startObserveScrollingUnthrottled,
    )
    onBecomeUnobserved(
      this.scrollUnthrottled,
      'top',
      this.stopObserveScrollingUnthrottled,
    )

    reaction(
      () => [this.currentBreakpoint],
      () => {
        isFolderInputSupported.clear()
      },
    )
  }
  activateElements(elements: Partial<BrandStore['activeElements']>) {
    set(this.activeElements, elements)
  }

  get hasScrubber() {
    return (
      this.activeElements.timelineScrubber ||
      Boolean(this.slotCounts.timelineScrubber)
    )
  }

  get hasActionButton() {
    return Boolean(this.slotCounts.actionButton)
  }
  get hasCommentsButton() {
    return Boolean(this.slotCounts.commentsButton)
  }
  get hasModuleNav() {
    return this.activeElements.moduleNav
  }
  get isMobile() {
    return this.currentBreakpoint <= 2
  }
  get hasTopModuleNav() {
    return this.hasModuleNav && !this.isMobile
  }
  get hasBottomModuleNav() {
    return this.hasModuleNav && this.isMobile
  }
  get wideDrawerPreference() {
    return this._wideDrawerPreference
  }
  set wideDrawerPreference(pref: boolean) {
    setWideDrawerCookie(pref)
    this._wideDrawerPreference = pref
  }
  get isMobileTimeline() {
    return (
      this.windowWidth <
      this.theme.branding.photos.timeline.mobileTimelineBreakpoint
    )
  }
  get isOpen() {
    const isOpen = this.openState === this.currentBreakpointIndex
    const wideOpen =
      this.currentBreakpointIndex >= 2 && this.wideDrawerPreference
    return isOpen || wideOpen
  }

  get sidebarWidth() {
    if (!this.hasDrawer) {
      return 0
    }
    if (this.isOpen) {
      return this.sidebarOpenWidth
    }
    return this.sidebarClosedWidth
  }

  get sidebarClosedWidth() {
    const w = getResponsiveValue(
      this.currentBreakpointIndex,
      this.theme.branding.sidebar.closedWidth,
    )
    if (isString(w)) {
      return parseInt(w, 10)
    }
    return w
  }
  get sidebarOpenWidth() {
    const w = getResponsiveValue(
      this.currentBreakpointIndex,
      this.theme.branding.sidebar.openWidth,
    )
    if (isString(w)) {
      return parseInt(w, 10)
    }
    return w
  }

  get hasDrawer() {
    return this.activeElements.leftNav || Boolean(this.slotCounts.leftNav)
  }
  get visibleScrollBarWidth() {
    return this.windowSize.width - this.viewportWidth
  }
  get hasScrollbar() {
    return Boolean(this.visibleScrollBarWidth)
  }
  get windowWidth() {
    return this.windowSize.width
  }
  get viewportWidth() {
    return this.rect.width
  }
  get viewportWidthScroll() {
    return this.viewportWidth - this.scrollbarWidth + this.visibleScrollBarWidth
  }
  get viewportHeight() {
    return this.rect.height
  }
  get scrollbarOffset() {
    return this.scrollbarWidth - this.visibleScrollBarWidth
  }
  get scrollTop() {
    return this.scroll.top
  }
  get isScrolledToTop() {
    return !this.scroll.top
  }
  get scrollHeight() {
    return this.scroll.scrollHeight
  }

  get scrollPercent() {
    if (!this.scrollTop) {
      return 0
    }
    return (this.scrollTop / (this.scrollHeight - this.viewportHeight)) * 100
  }

  get scrollPercentWithCSSCustomProperty() {
    const p = this.scrollPercent
    setElementCSSCustomProperties({
      '--scroll-percent': `${p}%`,
    })
    return p
  }

  setTheme = action('setTheme', (theme: BrandTheme) => {
    if (theme.branding.brand !== this.brandCode) {
      this.theme = theme
      this.log('setTheme %s', this.brandName)
    }
  })

  get breakpoints(): number[] {
    return [0, ...this.theme.breakpoints.map(b => parseInt(b, 10))]
  }
  private getBreakPoint() {
    // For browsers
    if (window && window.matchMedia) {
      return this.breakpoints.filter(
        bp => window.matchMedia(`screen and (min-width: ${bp}px)`).matches,
      ).length
    }
    // For jest jsdom that doesn't understand window.matchMedia
    const i = this.breakpoints.findIndex(br => br >= window.innerWidth)
    if (i >= 0) {
      return i + 1
    }
    return this.breakpoints.length
  }

  get breakpointRanges() {
    const maxIndex = this.breakpoints.length - 1
    return this.breakpoints.map((min, i) => {
      const max: number = i < maxIndex ? this.breakpoints[i + 1] - 1 : Infinity
      return {
        min,
        max,
        isCurrent: i === this.currentBreakpoint,
      }
    })
  }
  get currentBreakpointIndex() {
    return this.currentBreakpoint - 1
  }
  isTouchDevice() {
    if (window && window.matchMedia) {
      return !window.matchMedia('(hover: hover) and (pointer: fine)').matches
    }
    return false
  }
  get showHamburger() {
    return this.hasDrawer && this.theme.branding.header.showHamburger
  }
  get showLearnMoreWaitlistLink() {
    return this.theme.branding.learnMoreLinkWaitlist
  }

  get showLearnMoreSearchLink() {
    return this.theme.branding.learnMoreLinkSearch
  }
  get showBrandLogo() {
    return this.theme.branding.header.showBrandLogo
  }
  get showPoweredByJotta() {
    return this.theme.branding.sidebar.showPoweredByJotta
  }

  get hasBottomToolbar() {
    return Boolean(this.slotCounts.bottomStatusBar || this.hasBottomModuleNav)
  }
  get topToolbarHeight() {
    return this.hasTopModuleNav ? this.theme.branding.topToolBar.height : 0
  }
  get bottomToolbarHeight() {
    return this.hasBottomToolbar ? this.theme.branding.bottomToolBar.height : 0
  }
  /** Width of content area, including scrollbar */
  get contentWidth() {
    if (this.isMobile) {
      return this.viewportWidth - this.scrubberWidth
    }
    return this.viewportWidth - this.scrubberWidth - this.contentLeft
  }
  /**
   * Width of content area, excluding measured scrollbar
   * This value should be stable even if scrollbars appear or disappear
   */
  get contentWidthSafe() {
    if (this.isMobile) {
      return this.windowWidth - this.scrubberWidth - this.scrollbarWidth
    }
    return (
      this.windowWidth -
      this.scrubberWidth -
      this.contentLeft -
      this.scrollbarWidth
    )
  }
  /**
   * Safe content width rounded to 100 pixels
   * This causes less recomputations if an approximate value is good enough
   */
  get contentWidthRounded() {
    const roundby = 100
    return Math.round(this.contentWidthSafe / roundby) * roundby
  }

  get contentTop() {
    if (this.hasTopModuleNav) {
      return this.headerHeight + this.theme.branding.topToolBar.height
    }
    return this.headerHeight
  }

  get contentBottom() {
    if (this.hasBottomToolbar) {
      return this.theme.branding.bottomToolBar.height
    }
    return 0
  }
  get contentHeight() {
    return this.viewportHeight - this.contentTop - this.contentBottom
  }
  get contentLeft() {
    if (this.isMobile) {
      return 0
    }
    return this.sidebarWidth
  }
  get contentRight() {
    if (this.isMobileTimeline) {
      return this.sidebarWidth
    }
    return this.sidebarWidth + this.scrubberWidth
  }
  get scrubberWidth() {
    if (!this.hasScrubber) {
      return 0
    }
    return getResponsiveValue(
      this.currentBreakpointIndex,
      this.theme.branding.photos.timeline.scrubberWidth,
    )
  }
  get brandName() {
    return brands[this.theme.branding.brand].name
  }
  get brandCode() {
    return brands[this.theme.branding.brand].code
  }
  get globalStyles() {
    return getThemeCSSVariables({
      theme: this.theme,
      scrollbarWidth: this.scrollbarWidth,
      headerHeight: this.headerHeight,
      activeElements: this.activeElements,
    })
  }

  get toJSON(): Record<string, string> {
    const debugKeys: (keyof BrandStore)[] = [
      'currentBreakpoint',
      'currentBreakpointIndex',
      'windowWidth',
      'windowWidth',
      'viewportWidth',
      'viewportHeight',
      'headerHeight',
      'contentWidth',
      'contentWidthSafe',
      'contentHeight',
      'contentBottom',
      'contentLeft',
      'contentTop',
      'scrubberWidth',
      'scrollbarWidth',
      'visibleScrollBarWidth',
      'topToolbarHeight',
      'bottomToolbarHeight',
      'hasActionButton',
      'hasBottomToolbar',
      'hasCommentsButton',
      'hasDrawer',
      'hasModuleNav',
      'hasScrollbar',
    ]
    return pipe(this, pick(debugKeys), mapValues(String))
  }
  get scrollInfo(): Record<string, string> {
    return {
      scrollTop: px(this.scrollTop),
      scrollPercent: `${Math.round(this.scrollPercent)}%`,
      scrollHeight: px(this.scrollHeight),
      scrollbarWidth: px(this.scrollbarWidth),
      visibleScrollBarWidth: px(this.visibleScrollBarWidth),
      scrollbarOffset: px(this.scrollbarOffset),
      scrollUpdatedAt: dayjs(this.scrollUpdatedAt).format('DD.MM.YY HH:ss'),
    }
  }
  get scrubberInfo() {
    return {
      width: this.scrubberWidth,
    }
  }

  startObserveScrollingUnthrottled() {
    this.updateScroll()
    document.addEventListener('scroll', this.onScrollUnthrottled, {
      passive: true,
    })
  }

  stopObserveScrollingUnthrottled() {
    document.removeEventListener('scroll', this.onScrollUnthrottled)
  }
  startObserveScrolling() {
    this.updateScroll()
    document.addEventListener('scroll', this.onScrollThrottled, {
      passive: true,
    })
  }

  stopObserveScrolling() {
    this.onScrollThrottled.cancel()
    document.removeEventListener('scroll', this.onScrollThrottled)
  }

  closeDrawer() {
    this.log('closeDrawer', this.currentBreakpointIndex)
    // Persist state at the largest breakpoint
    if (this.currentBreakpointIndex >= 2) {
      setWideDrawerCookie(false)
      this.wideDrawerPreference = false
    }
    this.openState = -1
  }
  openDrawer() {
    this.log('openDrawer', this.currentBreakpointIndex)
    // Persist state at the largest breakpoint
    if (this.currentBreakpointIndex >= 2) {
      setWideDrawerCookie(true)
      this.wideDrawerPreference = true
    }
    this.openState = this.currentBreakpointIndex
  }
  toggleDrawer() {
    this.log('Toggle')
    this.isOpen ? this.closeDrawer() : this.openDrawer()
  }

  onNavigate() {
    if (this.hasDrawer && this.currentBreakpoint < 3) {
      this.closeDrawer()
    }
  }

  get isScrolling() {
    return now() - this.scrollUpdatedAt < 500
  }

  updateBreakpoint = action('updateBreakpoint', () => {
    const br = this.getBreakPoint()
    if (br !== this.currentBreakpoint) {
      this.currentBreakpoint = br
    }
  })
  updateScroll = action('updateScroll', (e?: Event) => {
    const top = document.documentElement.scrollTop
    const scrollHeight = document.documentElement.scrollHeight
    const windowWidth = window.innerWidth
    let updatedAt = this.scrollUpdatedAt
    if (top !== this.scroll.top) {
      this.scroll.top = top
      updatedAt = Date.now()
    }
    if (scrollHeight !== this.scroll.scrollHeight) {
      this.scroll.scrollHeight = scrollHeight
      updatedAt = Date.now()
    }
    if (windowWidth !== this.windowSize.width) {
      this.windowSize.width = windowWidth
      updatedAt = Date.now()
    }
    if (updatedAt !== this.scrollUpdatedAt) {
      this.scrollUpdatedAt = updatedAt
    }
  })
  updateScrollUnthrottled = action('updateScrollUnthrottled', () => {
    const top = document.documentElement.scrollTop
    const scrollHeight = document.documentElement.scrollHeight
    const windowWidth = window.innerWidth
    let updatedAt = this.scrollUpdatedAt
    if (top !== this.scrollUnthrottled.top) {
      this.scrollUnthrottled.top = top
      updatedAt = Date.now()
    }
    if (scrollHeight !== this.scrollUnthrottled.scrollHeight) {
      this.scrollUnthrottled.scrollHeight = scrollHeight
      updatedAt = Date.now()
    }
    if (windowWidth !== this.windowSize.width) {
      this.windowSize.width = windowWidth
      updatedAt = Date.now()
    }
    if (updatedAt !== this.scrollUpdatedAt) {
      this.scrollUpdatedAt = updatedAt
    }
  })
  updateSize = action('updateSize', (rect: Rect) => {
    for (const key of objectKeys(rect)) {
      if (this.rect[key] !== rect[key]) {
        if (key === 'width') {
          const removedBodyScrollBarSize = ensureValidNumber(
            getCSSCustomProperty(
              '--removed-body-scroll-bar-size',
              document.body,
            ),
            0,
          )
          this.rect[key] = rect[key] - removedBodyScrollBarSize
        } else {
          this.rect[key] = rect[key]
        }
      }
    }
    this.updateScroll()
  })
  onResize = action<ResizeObserverCallback>('onResize', ([entry]) => {
    if (entry) {
      const rect = entry.contentRect.toJSON() as Rect
      // requestAnimationFrame(() => this.updateSize(rect))
      this.updateSize(rect)
    }
  })
  onScrollUnthrottled = (e: Event) => {
    this.updateScrollUnthrottled()
  }
  onResizeThrottled = debounce(this.onResize, {
    wait: 100,
    maxWait: 600,
  })
  onScrollThrottled = debounce(this.updateScroll, {
    wait: 100,
    maxWait: 600,
  })

  private _hideFab = false

  public get hideFab() {
    return this._hideFab
  }

  public set hideFab(hidden: boolean) {
    this._hideFab = hidden
  }
}

let brandStore: BrandStore
let currentTheme: BrandTheme
export function getbrandStore(theme: BrandTheme, store?: BrandStore) {
  if (store) {
    if (currentTheme !== theme) {
      store.setTheme(theme)
      currentTheme = theme
    }
    return store
  }
  if (!brandStore) {
    brandStore = new BrandStore(theme)
    return brandStore
  }
  if (currentTheme !== theme) {
    brandStore.setTheme(theme)
    currentTheme = theme
  }
  return brandStore
}
