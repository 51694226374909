import type { ReactNode } from 'react'
import styles from './StickyToolbar.module.scss'

export function StickyToolbar({
  children,
  stickyShadow = false,
}: {
  children?: ReactNode
  stickyShadow?: boolean
}) {
  return (
    <div className={styles.container} data-shadow={stickyShadow}>
      {children}
    </div>
  )
}
