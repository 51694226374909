import { makeObservable, computed, action, observable, runInAction } from 'mobx'
import type { BaseAction } from './BaseAction'

export class BaseActionManager<
  A extends BaseAction<unknown> = BaseAction<unknown>,
> {
  actions = observable.array<A>([], { deep: false })

  get completedSize() {
    return [...this.actions.values()].reduce(
      (sum, action) => sum + action.completed,
      0,
    )
  }

  get totalSize() {
    return [...this.actions.values()].reduce(
      (sum, action) => sum + action.total,
      0,
    )
  }

  get completed() {
    return [...this.actions.values()].filter(
      action => action.status === 'success',
    ).length
  }

  get total() {
    return this.actions.length
  }

  get hasCompleted() {
    if (!this.actions.length) {
      return false
    }

    return this.actions.every(a => a.isDone)
  }

  get hasAborted() {
    return this.actions.some(a => a.isAborted)
  }

  get allAborted() {
    return this.actions.every(a => a.isAborted)
  }

  get hasErrors() {
    return this.actions.some(a => a.isError)
  }

  get failedActions() {
    return this.actions.filter(a => a.isError)
  }

  abortAll() {
    for (const action of this.actions) {
      action.abort()
    }
  }

  clear() {
    this.abortAll()
    this.actions.replace([])
  }

  protected addAction(action: A) {
    action.remove = () =>
      runInAction(() => {
        this.removeAction(action)
      })
    this.actions.push(action)
    return action.asPromise()
  }

  protected removeAction(action: A) {
    const i = this.actions.indexOf(action)
    if (i >= 0) {
      this.actions.spliceWithArray(i, 1)
    }
  }

  constructor() {
    makeObservable(this, {
      actions: true,
      completed: computed,
      completedSize: computed,
      total: computed,
      totalSize: computed,
      hasCompleted: computed,
      hasErrors: computed,
      failedActions: computed,
      abortAll: action.bound,
      clear: action.bound,
    })
  }
}
