import { forwardRef } from 'react'

import type { BrandThemeIcon } from '@jotta/types/Brand'

import { BrandIcon } from '../../Icons/BrandIcon/BrandIcon'
import type { BoxPropsAs } from '../../Elements/Box/Box'
import { SvgBox } from '../../Elements/Box/Box'
import { Box } from '../../Elements/Box/Box'

export interface ButtonOwnProps {
  /** Disabled state */
  disabled?: boolean
  /** Shows spinner when loading */
  loading?: boolean
  icon?: BrandThemeIcon
}

export type ButtonProps = BoxPropsAs<'button', ButtonOwnProps, 'buttons'>

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(
    { loading = false, disabled = false, icon, children, ...props },
    ref,
  ) {
    const Btn = Box as any
    return (
      <Btn
        as="button"
        aria-busy={loading}
        disabled={loading || disabled}
        ref={ref as any}
        {...props}
      >
        {!!icon &&
          (typeof icon === 'string' ? (
            <BrandIcon icon={icon} />
          ) : (
            <SvgBox as={icon} className="h-5/6" />
          ))}
        {!!children && <span>{children}</span>}
      </Btn>
    )
  },
)
