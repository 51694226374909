import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { useBrandStore } from '@jotta/ui/useBrandTheme'
import { useDocumentEventListener } from '@jotta/hooks'
import type { Photos } from '@jotta/types/Photos'
import type { RadixModalProps } from '@jotta/ui/RadixModal'
import { RadixModal } from '@jotta/ui/RadixModal'
import { Trans } from '@lingui/macro'
import * as Dialog from '@radix-ui/react-dialog'
import clsx from 'clsx'
import Debug from 'debug'
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useToggle } from 'react-use'
import { usePhoto } from '../../api/usePhoto'
import { groupStore } from '../../store/GroupStore'
import { PhotoComments } from '../Comments/PhotoComments'
import { useCommentsOpenState } from '../Comments/useCommentsOpenState'
import type { PhotoActionHandlerSharedProps } from '../PhotoActions/PhotoActionHandlers'
import { PhotoActionHandlers } from '../PhotoActions/PhotoActionHandlers'
import { PhotoInfo } from '../PhotoInfo/PhotoInfo'
import styles from './MediaCarousel.module.scss'
import { MediaView } from './MediaView'

const debug = Debug('jotta:photos:MediaCarousel')

export interface MediaCarouselProps extends RadixModalProps {
  actionContext: Photos.MediaCarouselActionContexts
  commentsGroupId?: string
  items: readonly Photos.Media[]
  currentMediaId?: string
  onClose: (closed: boolean) => void
  onSlideChange?: (slider: string) => void
  slideShowMode?: boolean
  actionHandlerProps?: PhotoActionHandlerSharedProps
  showOwnerName?: boolean
  showSimilarSearch?: boolean
  navOnQueryParam?: string
}

export const MediaCarousel = observer<MediaCarouselProps>(
  function MediaCarousel({
    actionContext,
    items,
    onClose,
    currentMediaId,
    slideShowMode,
    commentsGroupId,
    actionHandlerProps,
    showOwnerName = false,
    showSimilarSearch = false,
    navOnQueryParam,
    ...props
  }) {
    const [showInfoWindow, toggleInfoWindow] = useToggle(false)
    const [showPhotoComments, togglePhotoComments] = useCommentsOpenState()
    const [playing, setPlaying] = useState(false)
    const [deleting, setDeleting] = useState(false)
    const [params, setParams] = useSearchParams()

    const currentItemIndex = items.findIndex(item => item.id === currentMediaId)
    let currentItem = currentItemIndex >= 0 ? items[currentItemIndex] : null

    const { data: currentPhoto } = usePhoto(currentMediaId, {
      enabled: !currentItem && !deleting,
    })

    if (!currentItem && currentPhoto) {
      currentItem = currentPhoto
    }

    const prevItem = currentItemIndex > 0 ? items[currentItemIndex - 1] : null
    const nextItem =
      currentItemIndex >= 0 && currentItemIndex < items.length - 1
        ? items[currentItemIndex + 1]
        : null
    const commentCount = currentItem
      ? groupStore.getCommentCount(currentItem.commentsItemId)
      : undefined

    const prevButton = useRef<HTMLButtonElement>(null)
    const nextButton = useRef<HTMLButtonElement>(null)
    const prevSlide = useRef<HTMLDivElement>(null)
    const nextSlide = useRef<HTMLDivElement>(null)
    const currentSlide = useRef<HTMLDivElement>(null)
    const branding = useBrandStore()

    useEffect(() => {
      if (showInfoWindow) {
        togglePhotoComments(false)
      }
    }, [showInfoWindow, togglePhotoComments])
    useEffect(() => {
      if (showPhotoComments) {
        toggleInfoWindow(false)
      }
    }, [showPhotoComments, toggleInfoWindow])

    const swipeOrigin = useRef(0)
    const offsetOrigin = useRef(0)

    useEffect(() => {
      const els = [
        prevSlide.current,
        currentSlide.current,
        nextSlide.current,
      ].filter((el): el is HTMLDivElement => Boolean(el))

      for (const el of els) {
        el.style.transition = ''
        el.style.transform = ''
      }

      swipeOrigin.current = 0
      offsetOrigin.current = 0
    }, [currentMediaId])

    const navigateTimeout = useRef(0)

    const handleTouchStart: React.TouchEventHandler<HTMLDivElement> =
      useCallback(e => {
        swipeOrigin.current = e.touches[0].clientX

        if (navigateTimeout.current) {
          window.clearTimeout(navigateTimeout.current)
          navigateTimeout.current = 0
        }

        const els = [
          prevSlide.current,
          currentSlide.current,
          nextSlide.current,
        ].filter((el): el is HTMLDivElement => Boolean(el))

        offsetOrigin.current = 0
        if (currentSlide.current) {
          offsetOrigin.current =
            currentSlide.current.getBoundingClientRect().left
        }

        for (const el of els) {
          el.style.transition = ''
          el.style.transform = `translateX(${offsetOrigin.current}px)`
        }
      }, [])

    const navigate = useNavigate()

    const navigateTo = useCallback(
      (id: string) => {
        if (navOnQueryParam) {
          params.set(navOnQueryParam, id)
          setParams(params, { replace: true })
        } else {
          navigate(
            `./${id}${params.toString() ? `?${params.toString()}` : ''}`,
            {
              replace: true,
            },
          )
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [navOnQueryParam, params, setParams],
    )

    const handleTouchEnd: React.TouchEventHandler<HTMLDivElement> = useCallback(
      e => {
        const els = [
          prevSlide.current,
          currentSlide.current,
          nextSlide.current,
        ].filter((slide): slide is HTMLDivElement => Boolean(slide))

        const offsetLeft =
          currentSlide.current?.getBoundingClientRect().left || 0
        const width = document.body.clientWidth

        let transform = ''
        const offsetPercent = (offsetLeft / width) * 100
        const transitionTime = 300

        if (offsetPercent > 20 && prevItem) {
          transform = 'translateX(100%)'
          navigateTimeout.current = window.setTimeout(() => {
            navigateTimeout.current = 0
            navigateTo(prevItem.id)
          }, transitionTime - 100)
        } else if (offsetPercent < -20 && nextItem) {
          transform = 'translateX(-100%)'
          navigateTimeout.current = window.setTimeout(() => {
            navigateTimeout.current = 0
            navigateTo(nextItem.id)
          }, transitionTime - 100)
        }

        for (const el of els) {
          el.style.transition = `transform ${transitionTime}ms`
          el.style.transform = transform
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [nextItem, prevItem],
    )

    const handleTouchMove: React.TouchEventHandler<HTMLDivElement> =
      useCallback(e => {
        const swipePixels =
          e.touches[0].clientX - swipeOrigin.current + offsetOrigin.current
        const els = [
          prevSlide.current,
          currentSlide.current,
          nextSlide.current,
        ].filter((el): el is HTMLDivElement => Boolean(el))

        for (const el of els) {
          el.style.transform = `translateX(${swipePixels}px)`
        }
      }, [])

    const handleKeyDown = useCallback((e: KeyboardEvent) => {
      if (
        e.repeat ||
        e.shiftKey ||
        e.ctrlKey ||
        e.target instanceof HTMLTextAreaElement ||
        (e.key !== 'ArrowLeft' && e.key !== 'ArrowRight')
      ) {
        return
      }
      switch (e.key) {
        case 'ArrowLeft':
          {
            prevButton.current?.focus()
            prevButton.current?.click()
          }
          break
        case 'ArrowRight':
          {
            nextButton.current?.focus()
            nextButton.current?.click()
          }
          break

        default:
          break
      }
    }, [])

    useDocumentEventListener('keydown', handleKeyDown)

    // Keep scroll position which is reset by video fullscreen
    useEffect(() => {
      const el = document.scrollingElement

      if (!currentMediaId || !el) {
        return
      }

      const scrollTop = el.scrollTop
      return () => {
        el.scrollTop = scrollTop
      }
    }, [currentMediaId])

    const handleClose = useCallback(
      (open: boolean) => {
        if (open) {
          return
        }

        if (showInfoWindow) {
          toggleInfoWindow(false)
        } else if (showPhotoComments) {
          togglePhotoComments(false)
        } else if (onClose) {
          onClose(false)
        }
      },
      [
        showInfoWindow,
        showPhotoComments,
        onClose,
        toggleInfoWindow,
        togglePhotoComments,
      ],
    )

    return (
      <RadixModal
        variant="dialogs.overlayFileViewer"
        onClose={handleClose}
        {...props}
      >
        <Dialog.Content
          // ref={dialogRef}
          onPointerDownOutside={e => {
            e.preventDefault()
          }}
          // onOpenAutoFocus={e => {
          //   e.preventDefault()
          //   updateFocusableItems()
          // }}
        >
          <div
            data-testid="Carousel"
            id="carousel"
            className={styles.container}
          >
            <div
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
              className={styles.slideContainer}
            >
              <div className={styles.slides}>
                {prevItem && (
                  <>
                    <div ref={prevSlide} className={styles.slidePrev}>
                      <MediaView
                        key={prevItem.id}
                        item={prevItem}
                        slideShowMode={slideShowMode}
                        width={prevItem.width}
                        height={prevItem.height}
                      />
                    </div>
                    <button
                      id="carousel-prev-button"
                      ref={prevButton}
                      onClick={() => navigateTo(prevItem.id)}
                      className={styles.prevButton}
                      style={{
                        opacity: slideShowMode || playing ? 0 : 1,
                      }}
                    >
                      <PlainBrandIcon icon="SvgCaretLeft" />
                    </button>
                  </>
                )}
                {currentItem && (
                  <div
                    ref={currentSlide}
                    id="carousel-current-slide"
                    className={styles.slide}
                  >
                    <MediaView
                      key={currentItem.id}
                      item={currentItem}
                      active
                      slideShowMode={slideShowMode}
                      width={currentItem.width}
                      height={currentItem.height}
                      setPlaying={setPlaying}
                    />
                  </div>
                )}
                {nextItem && (
                  <>
                    <div ref={nextSlide} className={styles.slideNext}>
                      <MediaView
                        key={nextItem.id}
                        item={nextItem}
                        slideShowMode={slideShowMode}
                        width={nextItem.width}
                        height={nextItem.height}
                      />
                    </div>
                    <button
                      id="carousel-next-button"
                      onClick={() => navigateTo(nextItem.id)}
                      ref={nextButton}
                      className={styles.nextButton}
                      style={{
                        opacity: slideShowMode || playing ? 0 : 1,
                      }}
                    >
                      <PlainBrandIcon icon="SvgCaretRight" />
                    </button>
                  </>
                )}
              </div>
              {currentItem && !playing && (
                <div
                  // eslint-disable-next-line tailwindcss/no-custom-classname
                  className={clsx(styles.footer, {
                    'sidepanel-visible':
                      (showInfoWindow || showPhotoComments) && currentItem,
                  })}
                  id="carousel-actions"
                  data-testid="CarouselActions"
                >
                  <PhotoActionHandlers
                    hideDisabled
                    {...actionHandlerProps}
                    hasComments={Boolean(currentItem.commentsItemId)}
                    photoIds={[currentItem.id]}
                    username={actionHandlerProps?.username || ''}
                    isOwnerOfAllPhotos={Boolean(
                      actionHandlerProps?.username &&
                        currentItem.username === actionHandlerProps.username,
                    )}
                    key={currentItem.id}
                    actionContext={actionContext}
                    downloadLink={{
                      fileUrl: currentItem.file_url,
                      fileName: currentItem.filename,
                    }}
                    commentCount={commentCount}
                    currentMediaId={currentMediaId}
                    showSimilarSearch={showSimilarSearch}
                    buttonVariant="buttons.carouselActionButton"
                    onDispatch={(actionType, ...args) => {
                      switch (actionType) {
                        case 'DELETE_PHOTOS': {
                          setDeleting(true)
                          return
                        }
                        case 'SHOW_INFO': {
                          toggleInfoWindow()
                          return false
                        }
                        case 'ADD_COMMENT': {
                          togglePhotoComments(open => !open)
                          return false
                        }
                      }
                    }}
                    onDispatchComplete={(actionType, ...args) => {
                      debug('onDispatchComplete', actionType)
                      if (
                        actionType === 'DELETE_PHOTOS' ||
                        actionType === 'HIDE_PHOTO' ||
                        actionType === 'UNHIDE_PHOTO' ||
                        actionType === 'REMOVE_PHOTOS_FROM_ALBUM'
                      ) {
                        if (nextItem?.id) {
                          navigateTo(nextItem.id)
                        } else if (prevItem?.id) {
                          navigateTo(prevItem.id)
                        } else {
                          navigate('./', { replace: true })
                        }
                      }
                    }}
                    onDispatchError={(actionType, error) => {
                      debug('onDispatchError', actionType, error)
                      if (actionType === 'DELETE_PHOTOS') {
                        setDeleting(false)
                      }
                    }}
                  />
                </div>
              )}
              {currentItem && (
                <Dialog.Close
                  id="carousel-close-button"
                  data-testid="CarouselClose"
                  // eslint-disable-next-line tailwindcss/no-custom-classname
                  className={clsx({
                    'carousel-close': true,
                    [styles.closeButton]: true,
                  })}
                >
                  <PlainBrandIcon icon="SvgClose" />
                  <span>
                    <Trans>Close</Trans>
                  </span>
                </Dialog.Close>
              )}
            </div>

            <LayoutGroup>
              <AnimatePresence>
                {showInfoWindow && currentItem && (
                  <motion.div
                    className={styles.sidePanel}
                    initial={{ width: 0, opacity: 0 }}
                    animate={{
                      width: branding.isMobileTimeline
                        ? '100%'
                        : 'var(--carousel-sidepanel-width)',
                      opacity: 1,
                    }}
                    transition={{ duration: 0.2 }}
                    exit={{ width: 0, opacity: 0 }}
                  >
                    <PhotoInfo
                      media={currentItem}
                      onClose={toggleInfoWindow}
                      showOwnerName={showOwnerName}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
              <AnimatePresence>
                {showPhotoComments && currentItem && (
                  <motion.div
                    className={styles.sidePanel}
                    initial={{ width: 0, opacity: 0 }}
                    animate={{
                      width: branding.isMobileTimeline
                        ? '100%'
                        : 'var(--carousel-sidepanel-width)',
                      opacity: 1,
                    }}
                    transition={{ duration: 0.2 }}
                    exit={{ width: 0, opacity: 0 }}
                  >
                    <PhotoComments
                      contextKey={currentItem.commentsItemId}
                      albumContextKey={commentsGroupId}
                      onClose={() => togglePhotoComments(false)}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </LayoutGroup>
          </div>
        </Dialog.Content>
      </RadixModal>
    )
  },
)
