import moize from 'moize'
import isMobileJs from 'ismobilejs'

const dirnames = ['', 'moz', 'o', 'ms', 'webkit'].map(
  prefix => `${prefix}directory`,
)

export const isFolderInputSupported = moize(() => {
  if (typeof window === 'undefined' || isMobileJs().any) {
    return false
  }

  const input = document.createElement('input')
  input.type = 'file'

  return dirnames.some(dirname => dirname in input)
})
