import { useContext } from 'react'
import type { ThemeUIContextValue } from 'theme-ui'
import { useThemeUI } from 'theme-ui'

import { AppError } from '@jotta/types/AppError'
import type { BrandKey } from '@jotta/types/Brand'
import { brands } from '@jotta/types/Brand'

import { BrandContext } from '../Brand/BrandProvider/BrandProvider'
import type { BrandTheme } from '../types/BrandTheme'

interface BrandThemeContextValue extends Omit<ThemeUIContextValue, 'theme'> {
  theme: BrandTheme
  brandCode: BrandKey
  brandName: string
}

/**
 * Re-export useThemeUI typed as BrandTheme
 * https://github.com/system-ui/theme-ui/blob/master/packages/docs/src/pages/guides/typescript.mdx
 */
export function useBrandTheme() {
  const context = (useThemeUI as unknown as () => BrandThemeContextValue)()
  const brandContext = useContext(BrandContext)
  if (!brandContext) {
    throw new AppError({
      message: 'useBrandTheme must be used inside BrandContextProvider',
    })
  }

  const brand = brands[context.theme.branding.brand]
  return {
    ...context,
    brandStore: brandContext.store,
    brandCode: brand.code,
    brandName: brand.name,
  }
}
export function useBrandSettings() {
  const { theme } = useBrandTheme()
  return theme.branding
}
export function useBrandStore() {
  const brandContext = useContext(BrandContext)
  if (!brandContext) {
    throw new AppError({
      message: 'useBrandTheme must be used inside BrandContextProvider',
    })
  }

  return brandContext.store
}

export function useBrandCode() {
  return useBrandTheme().brandCode
}
