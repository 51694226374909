import type { Person } from '@jotta/grpc-connect-openapi/people'
import type { LinkProps } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { PersonImage } from './PersonImage'
import clsx from 'clsx'

import styles from './PersonThumb.module.scss'
import type { ButtonHTMLAttributes } from 'react'

type PersonThumbProps = {
  person: Person
  size?: string
}
export function PersonThumbLink({
  person,
  className,
  ...props
}: PersonThumbProps & LinkProps) {
  return (
    <Link className={clsx(styles.thumb, className)} {...props}>
      <PersonImage person={person} loading="lazy" decoding="async" />
      {person.name && <span>{person.name}</span>}
    </Link>
  )
}

export function PersonThumbButton({
  person,
  className,
  ...props
}: {
  person: Person
} & ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      data-person={person.id}
      className={clsx(styles.thumb, className)}
      {...props}
    >
      <PersonImage person={person} loading="lazy" decoding="async" />
      {person.name && <span>{person.name}</span>}
    </button>
  )
}
