import { AppLayoutDownload } from '@jotta/ui/AppLayoutDownload'
import { DownloadPage } from '@jotta/ui/DownloadPage'
import { ErrorPage } from '@jotta/ui/ErrorPage'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import { ErrorBoundary } from '@sentry/react'
import { observer } from 'mobx-react-lite'
import { Suspense } from 'react'
import { useAuthenticated } from '@jotta/auth-client/useAuthStatus'

export const DownloadPageRoute = observer(function DownloadPageRoute() {
  const authenticated = useAuthenticated() === 'TRUE'

  return (
    <AppLayoutDownload isLoggedIn={authenticated}>
      <ErrorBoundary
        fallback={({ error, resetError }) => (
          <ErrorPage error={error} resetError={resetError} hideFooter />
        )}
      >
        <Suspense fallback={<LoadingOverlay open />}>
          <DownloadPage />
        </Suspense>
      </ErrorBoundary>
    </AppLayoutDownload>
  )
})
