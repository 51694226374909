import clsx from 'clsx'
import styles from './CommentsBubble.module.scss'

export interface CommentsBubbleProps {
  count: number
  className?: string
}

export function CommentsBubble({
  count,
  className,
  ...props
}: CommentsBubbleProps) {
  return (
    <div className={styles.commentsWrapper}>
      <div
        className={clsx({
          [styles.commentsBubble]: 1,
          className,
        })}
        {...props}
      >
        {count}
        <svg viewBox="0 0 4 3">
          <path
            stroke="white"
            fill="white"
            d="M0.5 -0.5v3l3.3-3Z"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth="1"
          />
        </svg>
      </div>
    </div>
  )
}
