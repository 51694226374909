import { createPromiseClient } from '@connectrpc/connect'
import { VippsService } from '@jotta/grpc-connect-openapi/vippsService'
import moize from 'moize'
import { createQuery } from 'react-query-kit'
import { publicTransport } from './transport'
import { isGrpcConnectNotFoundError } from './error'

class FlowPendingError extends Error {
  id: string
  constructor({
    message = 'Flow pending',
    id,
  }: {
    message?: string
    id: string
  }) {
    super(message)
    this.name = 'PendingError'
    this.id = id
  }
}
class FlowFailedError extends Error {
  id: string
  constructor({
    message = 'Flow failed',
    id,
  }: {
    message?: string
    id: string
  }) {
    super(message)
    this.name = 'PendingError'
    this.id = id
  }
}
export const getVippsClient = moize(() =>
  createPromiseClient(VippsService, publicTransport),
)
export const useGetFlow = createQuery<
  | {
      state: 'success'
      redirectUrl: string
      username: string
      token: string
    }
  | {
      state: 'failed'
      redirectUrl: string
    },
  {
    flowId: string
  }
>({
  queryKey: ['vipps'],
  throwOnError: true,
  fetcher: async ({ flowId }) => {
    const flow = await getVippsClient().getFlow({
      flowId,
    })
    switch (flow.state.case) {
      case 'pending':
        throw new FlowPendingError({ id: flowId })
      case 'failed':
        return {
          state: 'failed',
          redirectUrl: flow.legacyFailureRedirectUrl,
        }
      case 'success': {
        const token = flow.state.value.autoLogin?.token || ''
        const username = flow.state.value.autoLogin?.username || ''
        return {
          state: 'success',
          redirectUrl: flow.legacySuccessRedirectUrl,
          token,
          username,
        }
      }
      default:
        throw new FlowFailedError({
          message: 'Unexpected empty case',
          id: flowId,
        })
    }
  },
  retry(failureCount, error) {
    if (error instanceof FlowPendingError) {
      return true
    }
    if (isGrpcConnectNotFoundError(error)) {
      return false
    }

    return failureCount < 3
  },
})
