import { useGetConfig } from './useGetConfig'
import { emptyBrandingInfo } from './emptyConfigResponse'

export function useBrandingInfo() {
  const { data } = useGetConfig()
  return data.brandingInfo || emptyBrandingInfo
}

export function useBrandName() {
  return useBrandingInfo().brandName
}

export function useCustomerGroupName() {
  return useBrandingInfo().customerGroupName
}
