import type { ApiErrorResponse } from '@jotta/grpc-web/no/jotta/openapi/api_error'
import { ErrorType } from '@jotta/grpc-web/no/jotta/openapi/error_pb'
import * as Sentry from '@sentry/react'
import type { Debugger } from 'debug'
import Debug from 'debug'
import { RpcError, StatusCode } from 'grpc-web'
import { Code } from '@connectrpc/connect'
import { isPlainObject } from 'remeda'
import type { DecodedErrorMessage } from './decodeErrorMessage'
import { decodeErrorMessage } from './decodeErrorMessage'

const debug = Debug('jotta:grpc:ApiError')

export function mapErrorCode(errorCode: StatusCode): Code {
  switch (errorCode) {
    case StatusCode.OK:
    case StatusCode.UNKNOWN:
      return Code.Unknown
    case StatusCode.CANCELLED:
      return Code.Canceled
    case StatusCode.INVALID_ARGUMENT:
      return Code.InvalidArgument
    case StatusCode.DEADLINE_EXCEEDED:
      return Code.DeadlineExceeded
    case StatusCode.NOT_FOUND:
      return Code.NotFound
    case StatusCode.ALREADY_EXISTS:
      return Code.AlreadyExists
    case StatusCode.PERMISSION_DENIED:
      return Code.PermissionDenied
    case StatusCode.RESOURCE_EXHAUSTED:
      return Code.ResourceExhausted
    case StatusCode.FAILED_PRECONDITION:
      return Code.FailedPrecondition
    case StatusCode.ABORTED:
      return Code.Aborted
    case StatusCode.OUT_OF_RANGE:
      return Code.OutOfRange
    case StatusCode.UNIMPLEMENTED:
      return Code.Unimplemented
    case StatusCode.INTERNAL:
      return Code.Internal
    case StatusCode.UNAVAILABLE:
      return Code.Unavailable
    case StatusCode.DATA_LOSS:
      return Code.DataLoss
    case StatusCode.UNAUTHENTICATED:
      return Code.Unauthenticated
  }
}

export class GrpcApiError extends Error {
  xid: string
  namespace: string
  callName: string
  parsed: ApiErrorResponse
  decodedError: DecodedErrorMessage | null = null
  handled: boolean
  code: Code
  private log: Debugger

  constructor(
    parsed: ApiErrorResponse & { encodedError?: string },
    {
      cause,
      namespace,
      callName,
    }: {
      namespace: string
      callName: string
      cause?: unknown
    },
  ) {
    // 'Error' breaks prototype chain here
    super(`[${parsed.xid}]: ${parsed.message}`)
    // restore prototype chain
    const actualProto = new.target.prototype

    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(this, actualProto)
    } else {
      // @ts-ignore
      this.__proto__ = actualProto
    }
    this.code = mapErrorCode(
      cause instanceof RpcError ? cause.code : StatusCode.UNKNOWN,
    )
    this.handled =
      isPlainObject(cause) && 'handled' in cause
        ? Boolean(cause.handled)
        : false
    this.parsed = parsed
    this.namespace = namespace
    this.callName = callName
    this.cause = cause
    this.xid = parsed.xid
    this.log = debug.extend(`${this.namespace}:${this.callName}`)
    this.log(parsed.message, parsed.xid)
    if (parsed.encodedError) {
      this.decodedError = decodeErrorMessage(parsed.encodedError)
    }
    const apiErrors = parsed.errors
    if (apiErrors) {
      apiErrors.forEach(e => {
        if (e) {
          this.log(parsed.message, parsed.xid, e.code)
        }
      })
    }
  }
  get errors() {
    return this.parsed.errors
  }

  get isNotAuthenticated() {
    if (this.code === Code.Unauthenticated) {
      return true
    }
    if (this.hasError(ErrorType.UNAUTHENTICATED)) {
      Sentry.captureMessage(
        'ApiError used ErrorType.UNAUTHENTICATED to report authentication status',
      )
      return true
    }
    return false
  }

  hasError(errorType: ErrorType): boolean {
    return this.parsed.errors.some(e => e.code === errorType)
  }
}
export function isGrpcApiError(error: any): error is GrpcApiError {
  return error instanceof GrpcApiError
}
