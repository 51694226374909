import { z } from 'zod'
import type { Photos } from '../Photos'
import type { PhotosApi } from '../PhotosApi'
import { MediaSchema } from './MediaSchema'
import { ShareInfoSchema } from './ShareInfoSchema'

export enum CollectionType {
  /** Normal unshared album */
  NORMAL_ALBUM = 3,
  /** Shared group of photos */
  SHARED_PHOTOS = 5,
  /** Shared album */
  META_SHARED_ALBUM_TYPES = 6,
  /** Location album */
  LOCATION_ALBUM = 7,
}

export const BaseAlbumSchema = z.object({
  bytes: z.number(),
  collectionId: z.number().optional(),
  collectionType: z.nativeEnum(CollectionType),
  commentsGroupId: z.string().optional(),
  coverPhoto: MediaSchema.optional(),
  createdDate: z.number(),
  description: z.string().optional(),
  hash: z.string().optional().default(''),
  id: z.string(),
  lastModified: z.number(),
  maxCapturedDate: z.number(),
  meta: z.string().optional(),
  minCapturedDate: z.number(),
  nextPageParam: z.string().optional(),
  photoIds: z.string().array().optional(),
  photos: MediaSchema.array().optional().default([]),
  shareInfo: ShareInfoSchema.optional(),
  title: z.string(),
  total: z.number().optional(),
  username: z.string().optional(),
})
export const BaseAlbumListResponseSchema = z.object({
  lastModified: z.number().optional(),
  result: BaseAlbumSchema.array(),
})
export const AlbumSchema = BaseAlbumSchema.transform(transformAlbum)
export const AlbumInfoListResponseSchema = z.object({
  lastModified: z.number().optional(),
  result: AlbumSchema.array(),
})
export function parseAlbumInfoIncludingPhotoIds(
  data: unknown,
): PhotosApi.AlbumInfo {
  const album = AlbumSchema.parse(data)
  return album
}

export function parseAlbumInfo(data: unknown): PhotosApi.AlbumInfo {
  const { photos, ...album } = AlbumSchema.parse(data)
  return album
}
export function safeParseAlbumInfo(
  data: unknown,
): PhotosApi.AlbumInfo | undefined {
  const parsed = AlbumSchema.safeParse(data)
  if (parsed.success) {
    const { photos, ...album } = parsed.data
    return album
  }
}
export function parseAlbumInfoList(data: unknown): PhotosApi.AlbumInfo[] {
  const albums = AlbumSchema.array()
    .parse(data)
    .map(({ photos, ...album }) => album)
  return albums
}
function transformAlbum({
  photos,
  collectionType,
  shareInfo,
  minCapturedDate,
  maxCapturedDate,
  ...args
}: z.infer<typeof BaseAlbumSchema>) {
  // Placeholder for actiontypes
  const actionList: Photos.ActionType[] = []
  const isNormalAlbum = collectionType === CollectionType.NORMAL_ALBUM
  const isLocationAlbum = collectionType === CollectionType.LOCATION_ALBUM
  const isSharedAlbum =
    collectionType === CollectionType.META_SHARED_ALBUM_TYPES
  const isSharedPhotos = collectionType === CollectionType.SHARED_PHOTOS
  const isShared = shareInfo !== undefined
  const showOwnerName = Boolean(isShared && !isSharedPhotos)
  const isShareOwner = Boolean(isShared && shareInfo.admin)
  const isOwner = (!isShared && isNormalAlbum) || isShareOwner
  const canAddPhotos =
    (isNormalAlbum || isSharedAlbum) &&
    (isOwner || (shareInfo && shareInfo.authorization === 'WRITE'))
  const isEditable =
    (isNormalAlbum && isOwner) ||
    (isSharedAlbum && shareInfo?.authorization === 'WRITE')
  const owner = isShared ? shareInfo.ownerFullName || '' : ''
  const ownerAvatar = shareInfo?.ownerAvatar
  const total = args.total || photos.length
  const hasMorePhotos = photos.length > 1 && total > photos.length
  // const sortedPhotos: PhotosApi.Media[] = [...photos].sort(
  //   (a, b) => a.capturedDate - b.capturedDate,
  // )
  const photoIds: readonly string[] =
    args.photoIds && !photos.length ? [...args.photoIds] : photos.map(p => p.id)
  // const minCapturedDate = sortedPhotos.length ? sortedPhotos[0].capturedDate : 0
  // const maxCapturedDate = sortedPhotos.length
  //   ? photos[sortedPhotos.length - 1].capturedDate
  //   : 0
  const showCaptureDate = Boolean(minCapturedDate && maxCapturedDate)
  const thumbUrl =
    args.coverPhoto && args.coverPhoto.thumbnail_url
      ? `${args.coverPhoto.thumbnail_url}.s`
      : ''
  return {
    ...args,
    actionList,
    canAddPhotos,
    collectionType,
    hasMorePhotos,
    isEditable,
    isLocationAlbum,
    isNormalAlbum,
    isOwner,
    isShared,
    isSharedAlbum,
    isSharedPhotos,
    isShareOwner,
    showOwnerName,
    maxCapturedDate,
    minCapturedDate,
    owner,
    ownerAvatar,
    photoIds,
    photos,
    shareInfo,
    showCaptureDate,
    thumbUrl,
    total,
  }
}
