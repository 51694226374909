import { Box } from '@jotta/ui/Box'
import { RouterLink } from '@jotta/ui/RouterLink'
import { Base64 } from 'js-base64'
import type { LoaderFunctionArgs } from 'react-router-dom'

import { apiPathToRoute, apiPathToRouteContext } from '@jotta/files'
import { createLoaderRoute } from '@jotta/router'
import { ErrorElement } from '@jotta/ui/ErrorElement'
import { uriEncodeRoutePath } from '@jotta/utils/pathUtils'
import Debug from 'debug'
const debug = Debug('jotta:WebFileGotoRoute')

export const { route: webFileGotoRoute, useFileGotoLoaderData } =
  createLoaderRoute({
    id: 'fileGoto',
    path: 'file/goto',
    errorElement: (
      <ErrorElement captureContext={scope => scope.setTag('key', 'fileGoto')} />
    ),
    loader: async ({ request }: LoaderFunctionArgs) => {
      const reqUrl = new URL(request.url)
      const pathHash = reqUrl.searchParams.get('path')
      const action = reqUrl.searchParams.get('action')
      if (!pathHash) {
        throw new Response('Path parameter missing', {
          status: 404,
        })
      }
      const apiPath = uriEncodeRoutePath(Base64.decode(pathHash))
      const url = new URL(
        apiPathToRoute(apiPath, { context: apiPathToRouteContext(apiPath) }),
        reqUrl.origin,
      )
      if (action) {
        url.searchParams.set('action', action)
      }
      const redirectTo = url.pathname + url.search
      window.location.replace(redirectTo)
      return redirectTo
    },
    element: <WebFileGotoRoute />,
  })

function WebFileGotoRoute() {
  const url = useFileGotoLoaderData()

  return (
    <Box>
      Redirecting to <RouterLink to={url}>{url}</RouterLink>...
    </Box>
  )
}
