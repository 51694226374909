import { ErrorType } from '@jotta/grpc-web/no/jotta/openapi/error_pb'
import { FileServicePromiseClient } from '@jotta/grpc-web/no/jotta/openapi/file/file.v2_grpc_web_pb'
import type {
  AllocateResponse,
  GetPathRequest,
  PathItem,
} from '@jotta/grpc-web/no/jotta/openapi/file/file.v2_pb'
import {
  AllocateConflictHandler,
  AllocateRequest,
  CopyRequest,
  CreateFolderRequest,
  DeletePermanentlyRequest,
  DeleteRequest,
  DownloadZipRequest,
  ItemKind,
  ItemType,
  ListRevisionsRequest,
  MoveRequest,
  RecentFilesRequest,
  RestoreRequest,
  SearchRequest,
} from '@jotta/grpc-web/no/jotta/openapi/file/file.v2_pb'
import { PingRequest } from '@jotta/grpc-web/no/jotta/openapi/ping_pb'
import type { PathItemObject } from '@jotta/types/Files'
import {
  downloadWithForm,
  downloadWithUrl,
  type KeyValueList,
} from '@jotta/utils/download'

import Debug from 'debug'
import type { Result } from 'neverthrow'
import { err, ok } from 'neverthrow'
import { getPath } from './files/getPath'
import { parsePathItem } from './files/parsePathItem'
import { getAuthMeta, getMeta, withGrpcClientMiddleware } from './grpcutils'
import { isApiErrorResponse } from '@jotta/types/AppError'
import { env } from '@jotta/utils/env'
import {
  createPath,
  getFilenameFromPath,
  popPath,
  renamePath,
} from '@jotta/utils/pathUtils'

export {
  AllocateResponse,
  FileRevision,
  FileState,
  ItemAction,
  ItemKind,
  ItemType,
  ListRevisionsResponse,
  PathItem,
} from '@jotta/grpc-web/no/jotta/openapi/file/file.v2_pb'

export const debug = Debug('jotta:grpc:file')

export type SortDir = keyof typeof GetPathRequest.Sort
let fileServiceClient: FileServicePromiseClient

export function getFileServiceClient() {
  if (!fileServiceClient) {
    fileServiceClient = withGrpcClientMiddleware(
      'file',
      new FileServicePromiseClient(env.grpcApi),
    )
  }
  return fileServiceClient
}

export function isFolder(item: PathItemObject) {
  return item.type === ItemType.FOLDER
}

export async function getRecentFiles(): Promise<PathItemObject[]> {
  const meta = await getAuthMeta()
  const req = new RecentFilesRequest()
  const res = await getFileServiceClient().recentFiles(req, meta)
  const items = await Promise.all(
    res.getItemsList().map(pathItem =>
      parsePathItem({
        pathItem,
        ignoreChildren: true,
      }),
    ),
  )
  return items
}

export interface SearchMatch {
  query: string
  item: PathItem
}

export function search(
  params: SearchParameters,
  receiver: (match: SearchMatch) => void,
  onEnd: () => void,
  onError?: (err: Error) => void,
) {
  const query = params.query
  const searchRequest = new SearchRequest()
  searchRequest.setQuery(params.query)
  if (params.paths) {
    searchRequest.setPathList(params.paths)
  }

  const stream = getFileServiceClient().search(searchRequest)
  stream.on('data', function (r) {
    const pathItem = r.getMatch()
    if (pathItem) {
      receiver({ query, item: pathItem })
    }
  })
  stream.on('end', () => {
    if (onEnd) {
      onEnd()
    }
  })
  // TODO: do something with error...
  stream.on('error', grpcErr => {
    if (onError) {
      const error = new Error('search error')
      ;(error as any).details = grpcErr
      onError(error)
    }
  })
}

export interface SearchParameters {
  query: string
  paths?: string[]
}

export async function downloadZip(pathList: string[], token?: string) {
  const meta = await getAuthMeta(token)
  const downloadZipRequest = new DownloadZipRequest()
  downloadZipRequest.setPathList(pathList)

  const downloadZipResponse = await getFileServiceClient().downloadZip(
    downloadZipRequest,
    meta,
  )

  const parameters: KeyValueList = downloadZipResponse
    .getParameterList()
    .map(item => [item.getName(), item.getValue()])

  await downloadWithForm({
    url: downloadZipResponse.getUrl(),
    method: downloadZipResponse.getMethod(),
    parameters,
  })
  debug('downloadZip success')
}

export async function deleteItem({ path }: { path: string }) {
  const deleteRequest = new DeleteRequest()
  deleteRequest.setPath(path)

  await getFileServiceClient().delete(deleteRequest)
}
export async function getDownloadLink({
  path,
  includeDeleted = false,
}: {
  path: string
  includeDeleted?: boolean
}): Promise<string> {
  const { item } = await getPath({
    path,
    includeDeleted,
    includeChildren: false,
    includeThumb: false,
    includeDownloadLinks: true,
  })
  return item.downloadLink
}
export async function downloadItem({
  path,
  includeDeleted = false,
}: {
  path: string
  includeDeleted?: boolean
}) {
  const { item } = await getPath({
    path,
    includeDeleted,
    includeChildren: false,
    includeThumb: false,
    includeDownloadLinks: true,
  })
  downloadWithUrl(item.downloadLink, item.name)
}
export async function deleteItemPermanently({ path }: { path: string }) {
  const deleteRequest = new DeletePermanentlyRequest()
  deleteRequest.setPath(path)

  return fileServiceClient.deletePermanently(deleteRequest)
}

export function restoreItem({ path }: { path: string }) {
  const restoreRequest = new RestoreRequest()
  restoreRequest.setPath(path)
  return getFileServiceClient().restore(restoreRequest)
}

export async function move(
  oldPath: string,
  newPath: string,
  conflictHandler = MoveRequest.ConflictHandler.CREATE_NEW_NAME,
) {
  const moveRequest = new MoveRequest()
  moveRequest.setFromPath(oldPath)
  moveRequest.setToPath(newPath)
  moveRequest.setConflictHandler(conflictHandler)

  return getFileServiceClient().move(moveRequest)
}
export async function rename({
  path,
  newName,
}: {
  path: string
  newName: string
}) {
  const newPath = renamePath(path, newName)

  return move(path, newPath, MoveRequest.ConflictHandler.REJECT_CONFLICTS)
}

export async function copy(
  oldPath: string,
  newPath: string,
  conflictHandler = CopyRequest.ConflictHandler.CREATE_NEW_NAME,
) {
  const copyRequest = new CopyRequest()
  copyRequest.setFromPath(oldPath)
  copyRequest.setToPath(newPath)
  copyRequest.setConflictHandler(conflictHandler)

  return getFileServiceClient().copy(copyRequest)
}

export enum FileTransferConflictHandler {
  UNKNOWN = 0,
  REJECT_CONFLICTS = 1,
  CREATE_NEW_NAME = 2,
  CREATE_NEW_REVISION = 3,
}

export interface FileTransferInfo {
  transferType: 'COPY' | 'MOVE'
  fileName: string
  error?: unknown
  oldPath: string
  newPath: string
  conflictHandler: FileTransferConflictHandler
}

export interface SingeFileTransferParams {
  transferType: 'COPY' | 'MOVE'
  fileName: string
  sourceDirectoryPath: string
  targetDirectoryPath: string
  onFileTransferred?: (options: FileTransferInfo) => void
  conflictHandler?: FileTransferConflictHandler
}

export async function transferFile({
  fileName,
  sourceDirectoryPath,
  targetDirectoryPath,
  transferType,
  onFileTransferred,
  conflictHandler = FileTransferConflictHandler.REJECT_CONFLICTS,
}: SingeFileTransferParams): Promise<void> {
  const oldPath = createPath(sourceDirectoryPath, fileName)
  const newPath = createPath(targetDirectoryPath, fileName)
  const transferFn = transferType === 'COPY' ? copy : move
  try {
    await transferFn(oldPath, newPath, conflictHandler as any)
    if (onFileTransferred) {
      onFileTransferred({
        transferType,
        fileName,
        oldPath: popPath(oldPath),
        newPath: popPath(newPath),
        conflictHandler,
      })
    }
  } catch (error) {
    if (onFileTransferred) {
      onFileTransferred({
        transferType,
        fileName,
        oldPath: popPath(oldPath),
        newPath: popPath(newPath),
        error,
        conflictHandler,
      })
    }
  }
}

export interface FileTransferParams {
  transferType: 'COPY' | 'MOVE'
  filePaths: string[]
  targetDirectoryPath: string
  onFileTransferred?: (info: FileTransferInfo) => void
}

export async function transferFiles({
  filePaths,
  targetDirectoryPath,
  transferType,
  onFileTransferred,
}: FileTransferParams): Promise<void> {
  await Promise.allSettled(
    filePaths.map(filePath =>
      transferFile({
        fileName: getFilenameFromPath(filePath),
        sourceDirectoryPath: popPath(filePath),
        targetDirectoryPath,
        transferType,
        onFileTransferred,
      }),
    ),
  )
}

export const NullByteChecksum = 'd41d8cd98f00b204e9800998ecf8427e'
export type AllocateError =
  | 'FILE_ONTO_FILE'
  | 'FILE_ONTO_FOLDER'
  | 'FOLDER_ONTO_FILE'

export interface AllocateParams {
  file: File
  path: string
  md5: string
  conflictHandler: AllocateConflictHandler
  token?: string
}

export async function ping(message?: string): Promise<string> {
  const pingRequest = new PingRequest()
  pingRequest.setMessage(message || 'ping')
  const response = await getFileServiceClient().ping(pingRequest)
  return response.getMessage()
}

export async function allocate(
  params: AllocateParams,
): Promise<Result<AllocateResponse.AsObject, AllocateError>> {
  const { file, path, conflictHandler, md5, token } = params

  const allocateRequest = new AllocateRequest()
  allocateRequest.setPath(path)
  allocateRequest.setMd5(md5)
  allocateRequest.setSize(file.size)
  allocateRequest.setCreatedAtMillis(file.lastModified)
  allocateRequest.setModifiedAtMillis(file.lastModified)
  allocateRequest.setConflictHandler(conflictHandler)
  const meta = await getAuthMeta(token)
  try {
    const response = await getFileServiceClient().allocate(
      allocateRequest,
      meta,
    )
    const responseObject = response.toObject()
    const { uploadUrl, uploadId } = responseObject
    if (!uploadUrl || !uploadId) {
      throw new Error('Missing upload URL or ID')
    }

    return ok(responseObject)
  } catch (error) {
    if (isApiErrorResponse(error)) {
      debug('allocate error.errors', error.errors)
      const code = error.errors[0].code
      const allocErr: AllocateError | null =
        code === ErrorType.FILE_ONTO_FILE
          ? 'FILE_ONTO_FILE'
          : code === ErrorType.FILE_ONTO_FOLDER
            ? 'FILE_ONTO_FOLDER'
            : code === ErrorType.FOLDER_ONTO_FILE
              ? 'FOLDER_ONTO_FILE'
              : null

      if (allocErr) {
        debug(`allocate ErrorType:${allocErr}`)
        return err(allocErr)
      }
    }

    debug('allocate unhandled error %O', error)

    throw error
  }
}

export async function createFolder({
  path,
  conflictHandler = CreateFolderRequest.ConflictHandler.CREATE_NEW_FOLDER,
}: {
  path: string
  conflictHandler?: CreateFolderRequest.ConflictHandler
}): Promise<PathItemObject> {
  const createFolderRequest = new CreateFolderRequest()
  createFolderRequest.setPath(path)
  createFolderRequest.setConflictHandler(conflictHandler)

  const response =
    await getFileServiceClient().createFolder(createFolderRequest)
  const pathItem = response.getFolder()
  if (!pathItem) {
    throw new Error('pathItem missing from creteFolder response')
  }
  const item = await parsePathItem({ pathItem })
  return item
}

export async function listRevisions(path: string) {
  const req = new ListRevisionsRequest()
  req.setPath(path)

  const response = await getFileServiceClient().listRevisions(req, getMeta())
  return response.toObject().revisionsList
}

export function getEmptyPathItem(): PathItemObject {
  return {
    actionList: [],
    checksum: '',
    childrenList: [],
    commentAuthToken: '',
    commentItemId: '',
    createdAtMillis: Date.now(),
    deletedAtMillis: 0,
    downloadLink: '',
    kind: ItemKind.REGULAR_FOLDER,
    mime: 'application/octet-stream',
    modifiedAtMillis: 0,
    name: '',
    owner: '',
    path: '/',
    publicLinkId: '',
    encodedContentRef: '',
    size: 0,
    thumbLink: '',
    type: ItemType.FOLDER,
    folderShareId: '',
    contentId: '',
  }
}
export async function create({ path, name }: { path: string; name: string }) {
  const result = await allocate({
    file: new File([], name),
    md5: NullByteChecksum,
    path: createPath(path, name),
    conflictHandler: AllocateConflictHandler.CREATE_NEW_FILE,
  })
  if (result.isOk()) {
    const allocateResponse = result._unsafeUnwrap()
    // if (allocateResponse.state === FileState.COMPLETED) {
    //   const item = await getPath({

    //   })
    // }
    return allocateResponse
  } else {
    throw new Error(result._unsafeUnwrapErr())
  }
}
