import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export function useCommentsOpenState(initialOpenState = false) {
  const [search, setSearch] = useSearchParams()
  const commentSearchParam = search.get('comment')
  const [commentsIsOpen, toggleComments] = useState(
    Boolean(commentSearchParam) || initialOpenState,
  )
  useEffect(() => {
    if (commentSearchParam) {
      toggleComments(true)
      const timeout = window.setTimeout(() => {
        setSearch(undefined, {
          replace: true,
        })
      }, 3000)
      return () => {
        window.clearTimeout(timeout)
      }
    }
  }, [commentSearchParam, setSearch])
  return [commentsIsOpen, toggleComments] as const
}
