import { useCallback } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'

const addPhotoParam = 'add-photos' as const

export function getAddPhotoMode() {
  const url = new URL(window.location.toString())
  return url.searchParams.get(addPhotoParam)
}

export function hasAddPhotoMode() {
  return Boolean(getAddPhotoMode())
}

export function useAddPhotoMode() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const setAddPhotoMode = useCallback(
    (albumId: string | null) => {
      if (albumId) {
        navigate(`/photo?${addPhotoParam}=${albumId}`)
      } else {
        const url = new URL(window.location.toString())
        if (url.searchParams.has(addPhotoParam)) {
          url.searchParams.delete(addPhotoParam)
          window.history.replaceState(null, '', url.toString())
        }
      }
    },
    [navigate],
  )

  const addPhotoMode = searchParams.get(addPhotoParam) || null

  return [addPhotoMode, setAddPhotoMode] as const
}
