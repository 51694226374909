import { useEffect } from 'react'

export function useOSKDodge(el: HTMLElement | null | undefined) {
  useEffect(() => {
    if (!el || !window.visualViewport) {
      return
    }

    const resizeHandler = () => {
      if (!el || !window.visualViewport) {
        return
      }

      const vHeight = window.visualViewport.height
      const vTop = window.visualViewport.offsetTop
      const elHeight = el.clientHeight

      if (vHeight !== elHeight || vTop !== 0) {
        el.style.height = `${vHeight}px`
        el.style.top = `${vTop}px`
      }
    }
    window.visualViewport.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () =>
      window.visualViewport?.removeEventListener('resize', resizeHandler)
  }, [el])
}
