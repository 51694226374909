import { Button } from '@jotta/ui/Button'
import { Flex } from '@jotta/ui/Flex'
import { FormattedContent } from '@jotta/ui/FormattedContent'
import { Trans } from '@lingui/macro'
import { useQueryClient } from '@tanstack/react-query'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import { useCallback, useState } from 'react'
import { joinTimeline } from '../../api/photosApi'
import { useGetCustomer } from '@jotta/grpc-connect-client/customer'
import { PhotoState } from '@jotta/grpc-connect-openapi/esm/openapi/customer/customer.v2_pb'

const debug = Debug('jotta:photo:timelineActivation')

export const TimelineActivation = observer(function TimelineActivation() {
  const [busy, setBusy] = useState(false)
  const queryClient = useQueryClient()
  const isPhotosActivated = Boolean(
    useGetCustomer().data?.customer?.photoState !== PhotoState.NOT_ACTIVE,
  )
  const join = useCallback(async () => {
    setBusy(true)
    await joinTimeline()
    await queryClient.invalidateQueries({
      predicate: ({ queryKey }) =>
        Boolean(
          Array.isArray(queryKey) &&
            queryKey.length &&
            queryKey[0] === 'customer',
        ),
    })
  }, [queryClient])

  if (isPhotosActivated) {
    return null
  }

  return (
    <Flex
      sx={{
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'nowrap',
        flexDirection: 'column',
      }}
    >
      <FormattedContent
        variant="formats.photoState"
        sx={{ maxWidth: ['100%', '600px'], p: 4 }}
      >
        <h2>
          <Trans id="Join.title">Photo timeline</Trans>
        </h2>
        <p>
          <Trans id="Join.body">
            Did you know that when you store photos and videos with us, we can
            organize them in a timeline? The timeline makes it super easy to
            find them. In addition, you can create albums and share with friends
            and family.
          </Trans>
        </p>
      </FormattedContent>
      <Button variant="buttons.primary" onClick={join} loading={busy}>
        <Trans id="Join.getStarted">Get Started!</Trans>
      </Button>
    </Flex>
  )
})
