import { Container } from '@jotta/ui/Box'
import { AnimatePresence, motion } from 'framer-motion'
import type { FC, MouseEventHandler, PropsWithChildren } from 'react'
import { useRef } from 'react'
import { Portal } from 'react-portal'
import { useKeyPressEvent } from 'react-use'
import type { DialogProps } from '../Dialog/Dialog'
// import Debug from 'debug'
// const debug = Debug('jotta:ui:modal')

export type ModalControl = {
  open(content?: React.ReactNode, options?: DialogProps): void
  close(): void
}

export interface ModalProps {
  /** Modal open state */
  open?: boolean
  /** Callback to run when close button or background is clicked */
  onClose?: () => void
}

export const Modal: FC<PropsWithChildren<ModalProps>> = ({
  open = false,
  children,
  onClose,
  ...props
}) => {
  const closeHandler = () => {
    if (onClose) {
      onClose()
      return
    }
    console.warn('No close handler registered for dialog')
  }
  const ref = useRef<HTMLDivElement>(null)
  const clickAwayHandler: MouseEventHandler = e => {
    const clickedEl = e.target as HTMLElement
    if (ref.current && ref.current === clickedEl) {
      closeHandler()
    }
  }
  useKeyPressEvent('Escape', closeHandler)
  return (
    <AnimatePresence>
      {open && (
        <Portal>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            sx={{
              variant: 'layout.modalBackground',
              position: 'fixed',
            }}
            {...props}
          >
            <Container
              variant="layout.container"
              onClick={clickAwayHandler}
              ref={ref}
            >
              {children}
            </Container>
          </motion.div>
        </Portal>
      )}
    </AnimatePresence>
  )
}
