import { Grid } from '@jotta/ui/Grid'
import { RouterLink } from '@jotta/ui/RouterLink'
import { isBrandKey, getBrandName } from '@jotta/config/brand'
import { Headline3 } from '@jotta/ui/Headline'
import { env } from '@jotta/utils/env'
import { Fragment } from 'react'
import { useBrandParam } from '../../useBrandParam'
import { AppError } from '@jotta/types/AppError'

const environments: {
  title: string
  url: string
}[] = [
  {
    title: 'Alpha',
    url: 'https://alpha.jotta.cloud',
  },
  {
    title: 'Beta',
    url: 'https://beta.jotta.cloud',
  },
  {
    title: 'Alta',
    url: 'https://alta.webapp.jotta.us',
  },
  {
    title: 'Fauske',
    url: 'https://fauske.webapp.jotta.us',
  },
  {
    title: 'Namsos',
    url: 'https://namsos.webapp.jotta.us',
  },
  {
    title: 'Otta',
    url: 'https://otta.webapp.jotta.us',
  },
  {
    title: 'Raufoss',
    url: 'https://raufoss.webapp.jotta.us',
  },
  {
    title: 'Next',
    url: 'https://webapp.jotta.us',
  },
  {
    title: 'Prod',
    url: 'https://jottacloud.com',
  },
]
const urls: {
  title: string

  links: {
    title: string
    url: string
  }[]
}[] = [
  {
    title: 'Files',
    links: [
      {
        title: 'Sync',
        url: '/web/sync',
      },
      {
        title: 'Backup',
        url: '/web/backup',
      },
      {
        title: 'Archive',
        url: '/web/archive',
      },
      {
        title: 'Shared',
        url: '/web/shared',
      },
      {
        title: 'Search',
        url: '/web/search/list/name/jpg',
      },
      {
        title: 'Recent files',
        url: '/web/recentfiles',
      },
    ],
  },
  {
    title: 'Shared files',
    links: [
      {
        title: 'Single shared file - Txt',
        url: '/s/176c3db1ed79f534aad9fe9d3df0f452a97',
      },
      {
        title: 'Single shared file - Image',
        url: '/s/1763ed3a2557a814e8ba8ec5674d85719ae',
      },
      {
        title: 'Shared folder',
        url: '/s/1769a9c64bf3d5d4c30afba017e1887cf46',
      },
      {
        title: 'Uploads allowed',
        url: '/s/2050ab9d4ec92084bb4898cef90651eb30b',
      },
      {
        title: 'P-link',
        url: '/p/ulnswfz4qcsoomnfct6qxltg/7db575693dfc4630ac6a48d482a6ae3a',
      },
    ],
  },
  {
    title: 'Settings',
    links: [
      {
        title: 'Account',
        url: '/web/account',
      },
    ],
  },
  {
    title: 'Biz',
    links: [
      {
        title: 'Dashboard',
        url: '/biz',
      },
      {
        title: 'Users',
        url: '/biz/users',
      },
      {
        title: 'Reports',
        url: '/biz/reports',
      },
      {
        title: 'Settings',
        url: '/biz/settings',
      },
    ],
  },
  {
    title: 'Payment',
    links: [
      {
        title: 'Upgrade',
        url: '/web/upgrade',
      },
      {
        title: 'Status',
        url: '/web/payment/status/123',
      },
    ],
  },
  {
    title: 'Photos',
    links: [
      {
        title: 'Timeline',
        url: '/photo',
      },
      {
        title: 'Albums',
        url: '/photo/album',
      },
      {
        title: 'Normal albums',
        url: '/photo/album/all',
      },
      {
        title: 'Shared photos',
        url: '/photo/album/shared',
      },
      {
        title: 'Shared albums',
        url: '/photo/all_shared',
      },
      {
        title: 'Hidden photos',
        url: '/photo/all_hidden',
      },
      {
        title: 'People',
        url: '/photo/album/people',
      },
    ],
  },
  {
    title: 'Shared photos',
    links: [
      {
        title: 'e2e comments',
        url: '/share/73sfui734hfh',
      },
    ],
  },
  {
    title: 'Direct comment links',
    links: [
      {
        title: 'Album comment',
        url: '/share/73sfui734hfh?comment=eyJpZCI6ImU2YjY0M2Q4LWEyNzEtNDg3Yy04NTdmLTBkYmM0NjY1OTc3YyJ9',
      },
      {
        title: 'Photo comment',
        url: '/share/rm0eihdtqdp0/T3U-xOYkyNjUCnkQ9fIe_qjyCYl8G4MMTShdfnXWWlcMGlQA8_LmmGKdHwN71bmUuir7wFOIKmANWKsulDTsTQ.QtgzzmL_53VHWCfP13jLMg?comment=eyJpZCI6IjBiMmNiNDk0LTc0MDEtNGU1OS1iNzIxLWU2NzZmNmMxNGVkYyJ9',
      },
    ],
  },
  {
    title: 'ContentRef URLs',
    links: [
      {
        title: 'Album comment',
        url: '/r/IpABCiRhYmU0YTA4OC0xZmI2LTQ4N2QtOGQ1OS0wYmJlNWQ2YTAzMjmqBmcKV1NxZ2RrYWJBOXRpR3d2REd3QlphbmhmbUdhZU1Ia1d5TXNOMHBYcFNTZHpXMVNEZGZkbncwVXdyaWhGTjd2dkQuVE9PejNuNjRGRFpwVFBLdkwxNmladxIMZm0yODVvYWIycjRj',
      },
      {
        title: 'Photo comment',
        url: '/r/Ir4CCiQ3MWMxYjFlMy04OTQxLTQ5ZGMtYjdiMy02MTdjYTdhMTZmNjmiBpQCCm02dTlDbFl2MnNKSGtfU1dXNkZUVVZhNk8zbVh3cllWdndKYkVtQm40NVdocmxFYXZYbUN6QV9NR0R2V3BXRElzSWpIUWpUcWxTVUpFbmdNUFJ6ZjFHUS5wdUpSbUNyRzQ4WFQ4M1l2MElZOURnEldTcWdka2FiQTl0aUd3dkRHd0JaYW5oZm1HYWVNSGtXeU1zTjBwWHBTU2R6VzFTRGRmZG53MFV3cmloRk43dnZELlRPT3ozbjY0RkRacFRQS3ZMMTZpWncaDGZtMjg1b2FiMnI0YyI8Chg2MTY1NzEwM2FkNDJjMzAxMzRhMzg1YTQSIDIxZWY1YjEyYjE3NTk5ZjMzZWQ5YWQxODY2Y2MzMjVm',
      },
      {
        title: 'Photo',
        url: '/r/EoICCm1rQUpkbjQ4QW1SMjl3WkhLal9UTHBzY3F3OEdaSWlBY3RXTmJpV3ZmbnYxZkFaWkttQnRuWEdVejc4Z2l4NVMteUZpUGY2NjZEdHpDWGNTYTlJaVBzZy5ZendxWWVQZldaNnc5UmYwN3czVTVREldTNmJFV3ZkOVllMzZIWDdYeWxlQ1NMOE1JQ0ZJNmM0cGxxZFkxX2haeW1mMUtzTFhoWG1SYXN2MzB0NlY4S0YyLlU5QVhPWVdNMU5abTNMUHB1RGpIZWcaDHJtMGVpaGR0cWRwMCIqCgZyb2xhbmQSIDU4N2NkZTRhOTQ2YjJhNDU5ZDI4MTAzN2RkZGJiYmE5',
      },
    ],
  },
  {
    title: 'Auth',
    links: [
      {
        title: 'Free Signup',
        url: '/signup',
      },
      {
        title: 'Business Signup',
        url: '/signup/business',
      },
      {
        title: 'Paid Signup',
        url: '/signup/private_personal',
      },
      {
        title: 'Paid Signup Business',
        url: '/signup/business_small',
      },
      {
        title: 'Voucher Signup',
        url: '/signup/voucher?voucher_code=1234',
      },
      {
        title: 'Login',
        url: '/login',
      },
      {
        title: 'Apple TV login',
        url: '/appletv',
      },
    ],
  },
]
export function HomeRoute() {
  const [brand, setBrand] = useBrandParam()
  const s = brand ? `?brand=${brand}` : ''

  // Never show this in production. Try reload
  if (process.env.NODE_ENV === 'production') {
    if (window.history.state?.reload === true) {
      throw new AppError({ HTTPStatus: 404 })
    } else {
      window.history.replaceState({ reload: true }, '', window.location.href)
      window.location.reload()
    }

    return null
  }

  return (
    <Grid
      columns={`1fr repeat(${environments.length}, min-content)`}
      sx={{
        p: 4,
        mx: 'auto',
        maxWidth: 'max-content',
        minHeight: '100%',
        fontSize: 2,
        rowGap: 1,
        columnGap: 2,
        justifyItems: 'start',
        textAlign: 'left',
        alignContent: 'center',
        h3: {
          gridColumn: '1 / -1',
        },
        'a,a:visited': {
          display: 'inline-block',
          color: 'primary',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      }}
    >
      {process.env.NODE_ENV === 'development' &&
        env.activeBrands.length > 1 && (
          <>
            <Headline3>Select brand</Headline3>
            <select
              id="selectbrand"
              onChange={e => {
                const { value } = e.currentTarget
                if (isBrandKey(value)) {
                  setBrand(value)
                } else {
                  setBrand(undefined)
                }
              }}
            >
              <option value="" selected={!brand}>
                None
              </option>
              {env.activeBrands
                .sort((a, b) => getBrandName(a).localeCompare(getBrandName(b)))
                .map(b => (
                  <option key={b} value={b} selected={b === brand}>
                    {getBrandName(b)}
                  </option>
                ))}
            </select>
          </>
        )}
      {urls.map(({ title, links }) => (
        <Fragment key={title}>
          <Headline3>{title}</Headline3>
          {links.map(l => (
            <Fragment key={l.title}>
              <RouterLink to={l.url + s}>{l.title}</RouterLink>
              {environments.map(env => (
                <RouterLink key={env.title + l.title} to={env.url + l.url}>
                  {env.title}
                </RouterLink>
              ))}
            </Fragment>
          ))}
        </Fragment>
      ))}
    </Grid>
  )
}
