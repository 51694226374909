import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { getFileKindLabel } from '@jotta/file-utils'
import { ItemKind } from '@jotta/grpc-js-client/fileService'
import type { PendingInvite } from '@jotta/grpc-web/no/jotta/openapi/sharing/v2/sharing.v2_pb'
import type { FileActionContext } from '@jotta/types/FileActions'
import { Trans } from '@lingui/macro'
import clsx from 'clsx'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import { fileActionApi } from '../FileActions/fileActionConfig'
import { FileContextMenu } from '../FileContextMenu/FileContextMenu'
import { getFileActionMenuItemsByContext } from '../FileContextMenu/getFileActionMenuItems'
import { FileIcon } from '../FileIcon/FileIcon'
import type { FolderStore } from '../Folder/FolderStore'
import styles from './PathItemRow.module.scss'

const debug = Debug('jotta:files:PathItemRow')
export interface PendingInviteRowProps {
  invite: PendingInvite.AsObject
  store: FolderStore
}
export const PendingInviteRow = observer<PendingInviteRowProps>(
  function PendingInviteRow({ invite, store }: PendingInviteRowProps) {
    const inviteKey = invite.inviteKey
    const acceptAction = fileActionApi.AcceptPendingInvite
    const actionContext: FileActionContext = 'PENDING_INVITE_LISTITEM'
    return (
      <div
        title={invite.name}
        className={clsx(styles.row, styles.pendingInviteRow)}
      >
        <div className={styles.checkbox} />
        <div className={styles.rowDataWrapper}>
          <div className={styles.rowData}>
            <div className={styles.icon}>
              <FileIcon kind={ItemKind.COLLABORATION_FOLDER} />
            </div>
            <span className={styles.link}>
              <p className={styles.linkInvite}>
                <span>{invite.name}</span>
                <small>
                  {' '}
                  (<Trans>Waiting for you to join...</Trans>)
                </small>
              </p>
            </span>
            <span className={styles.date}>-</span>
            <span className={styles.size}>-</span>
            <span className={styles.type}>
              {getFileKindLabel(ItemKind.COLLABORATION_FOLDER)}
            </span>
          </div>
        </div>
        <div className={styles.actions}>
          <div className={styles.splitButton}>
            <button
              className={styles.splitButtonPrimary}
              data-file-path={inviteKey}
              data-file-action={acceptAction.action}
              data-file-context={actionContext}
              onClick={() =>
                store.actions.dispatch(
                  'AcceptPendingInvite',
                  'FOLDER_LIST',
                  inviteKey,
                )
              }
            >
              {acceptAction.name}
            </button>
            <FileContextMenu
              disableIcons
              onSelect={store.actions.onContextMenuSelect}
              context={actionContext}
              path={inviteKey}
              triggerClassName={styles.splitButtonSecondary}
              actions={getFileActionMenuItemsByContext(
                'PENDING_INVITE_LISTITEM',
              )}
            >
              <PlainBrandIcon icon="SvgSmallArrowDown" />
            </FileContextMenu>
          </div>
        </div>
      </div>
    )
  },
)
