import { useBizContext } from './BizLayout'

export function BizDashboardRoute() {
  const { customer } = useBizContext()
  return (
    <div>
      <h1>Dashboard {customer.messages.length}</h1>
    </div>
  )
}
