import { observer } from 'mobx-react-lite'
import { groupStore } from '../../../store/GroupStore'
import { useMediaObjectStore } from '../../../store/PhotoContext'
import { MediaThumb } from '../../PhotoGallery/MediaThumb'

export interface AlbumPhotoThumbProps {
  id: string
  ownerAvatar?: boolean
  comments?: number
  showSimilarSearch?: boolean
}

export const AlbumPhotoThumb = observer<AlbumPhotoThumbProps>(
  function AlbumPhotoThumb({
    id,
    ownerAvatar = false,
    showSimilarSearch = false,
  }) {
    const store = useMediaObjectStore()
    const photo = store.getPhoto(id)

    if (!photo) {
      return null
    }

    return (
      <MediaThumb
        id={id}
        showOwnerAvatar={ownerAvatar}
        filename={photo.filename}
        ownerAvatar={photo.ownerAvatar}
        comments={groupStore.getCommentCount(photo.commentsItemId)}
        src={`${photo.thumbnail_url}.s`}
        playIcon={photo.content === 'video'}
        duration={photo.duration}
        showSimilarSearch={showSimilarSearch}
      />
    )
  },
)
