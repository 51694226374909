import { RadixDialog } from '@jotta/ui/RadixDialog'
import { RadixModal } from '@jotta/ui/RadixModal'
import { Trans, t } from '@lingui/macro'
import { useCallback } from 'react'

export function UserAlreadyInvitedDialog({
  user,
  onClose,
}: {
  user: string | null
  onClose: () => void
}) {
  const onOpenChange = useCallback(
    (open: boolean) => {
      if (!open) {
        onClose()
      }
    },
    [onClose],
  )

  return (
    <RadixModal open={Boolean(user)} onClose={onOpenChange}>
      <RadixDialog
        buttons={[
          { action: 'close', label: t`Ok`, variant: 'buttons.secondary' },
        ]}
        onClose={onOpenChange}
      >
        <p>
          <Trans id="<0>{user}</0> is already a member of this folder!">
            <strong>{user}</strong> is already a member of this folder!
          </Trans>
        </p>
      </RadixDialog>
    </RadixModal>
  )
}
