import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAlbumInfoLoaderData } from '../AlbumInfo/AlbumInfo.loaders'
import { AlbumContainer } from './Album.container'

const debug = Debug('jotta:photos:Album.route')

export const AlbumRoute = observer(function AlbumRoute() {
  const data = useAlbumInfoLoaderData()

  const navigate = useNavigate()
  useEffect(() => {
    if (data.redirectTo) {
      debug('Redirect to', data.redirectTo)

      navigate(data.redirectTo, {
        replace: true,
      })
    }
  }, [data.redirectTo, navigate])

  if (data.redirectTo) {
    return <LoadingOverlay open />
  }
  return <AlbumContainer />
})
