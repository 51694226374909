import type { BrandAlias, BrandKey } from '@jotta/config/brand'
import { getBrandAlias, brandAliases } from '@jotta/config/brand'
import { excludesFalse } from '@jotta/types/TypeUtils'
import Debug from 'debug'
const debug = Debug('jotta:branding:setBodyTheme')

/**
 * Keep the old classnames until css files using them have been updated
 */
export const aliasMap = {
  test: 'test',
  comhem: '',
  elgiganten: 'elkjop',
  elkjop: '',
  elko: 'elkjop',
  gigantti: 'elkjop',
  halebop: '',
  jottacloud: 'jotta',
  mediamarkt: '',
  obos: '',
  onlime: '',
  saturn: '',
  teliacloud: 'telia',
  teliasky: 'telia',
  tsky: '',
} as const satisfies Record<BrandAlias, string>
const aliases = Object.values(aliasMap).filter(excludesFalse)

export function setBodyThemeClassName(brand: BrandKey) {
  const id = getBrandAlias(brand)
  const alias = aliasMap[id]
  debug('Set theme: %s', id)
  document.documentElement.dataset.theme = id
  const cls = document.body.classList
  if (cls.contains(id)) {
    return
  }
  cls.add(id)
  cls.remove(...brandAliases.filter(item => item !== id))
  if (alias) {
    cls.add(alias)
    cls.remove(...aliases.filter(item => item !== alias))
  }
}
