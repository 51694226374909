import { tv } from 'tailwind-variants'

export const textFieldVariants = tv({
  slots: {
    root: '',
    label: '',
    inputColumn: 'flex flex-col',
    input: 'border-b',
    description: 'text-sm',
    error: 'text-sm',
  },
  variants: {
    layout: {
      horizontal: {
        root: 'col-span-full grid grid-cols-subgrid gap-4',
        label: 'text-right',
      },
      vertical: {
        root: 'flex flex-col',
        label: 'pb-2 text-base',
      },
    },
    variant: {
      plain: 'border-b',
    },
  },
  defaultVariants: {
    layout: 'horizontal',
    variant: 'plain',
  },
})
