import type { Photos } from '@jotta/types/Photos'
import { excludesFalse } from '@jotta/types/TypeUtils'
import Debug from 'debug'
import { deepEqual } from 'fast-equals'
import { makeAutoObservable, observable } from 'mobx'
import { computedFn } from 'mobx-utils'
import type { PhotoStore } from './PhotoStore'

const debug = Debug('jotta:photos:MediaObjectStore')

export class MediaObjectStore {
  public media = observable.map<string, Photos.Media>([], {
    deep: false,
  })
  public constructor(public photo: PhotoStore) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      },
    )
  }
  deletePending = observable.set<string>()

  hardDelete(ids: readonly string[]) {
    for (const id of ids) {
      this.media.delete(id)
      this.deletePending.delete(id)
    }
  }
  requestDelete(ids: readonly string[]) {
    for (const id of ids) {
      this.deletePending.add(id)
    }
    return {
      undo: () => this.cancelPendingDeletes(ids),
      commit: () => this.hardDelete(ids),
    }
  }
  cancelPendingDeletes(ids: readonly string[]) {
    for (const id of ids) {
      this.deletePending.delete(id)
    }
  }
  hidePhotos(ids: readonly string[]) {
    for (const id of ids) {
      const photo = this.media.get(id)
      if (photo && !photo.hidden) {
        photo.hidden = true
      }
    }
  }
  unhidePhotos(ids: readonly string[]) {
    for (const id of ids) {
      const photo = this.media.get(id)
      if (photo && photo.hidden) {
        photo.hidden = false
      }
    }
  }

  getPhotosByIdsUntracked(ids: readonly string[]) {
    const uniqueIds = [...new Set(ids)]
    const photos = uniqueIds.map(id => this.getPhoto(id)).filter(excludesFalse)
    debug('getPhotosByIds %d ids %d photos found', ids.length, photos.length)
    return photos
  }
  getPhotosByIds = computedFn((ids: readonly string[]) => {
    debug('computed: get %d photos by ids', ids.length)
    return [...new Set(ids.filter(id => !this.deletePending.has(id)))]
      .map(this.getPhoto)
      .filter(excludesFalse)
  })
  setPhotos(data: Photos.Media[]) {
    // this.photos.merge(data.map(item => [item.id, item]))
    // debug('setPhotos', data)
    return data.map(item => this.setPhoto(item))
  }

  setPhoto(data: Photos.Media): Photos.Media {
    // debug('setPhoto', data.id)
    const photo = this.media.get(data.id)
    if (photo && deepEqual(photo, data)) {
      // debug('setPhoto cache hit', photo)
      return photo
    }
    this.media.set(data.id, data)
    return this.media.get(data.id) || data
  }
  getPhoto(id: string) {
    if (!this.deletePending.has(id)) {
      return this.media.get(id)
    }
    // const photo = this.photos.get(id)
    // debug('getPhoto', id, photo)
    // return photo
  }
}
