import { Box } from '@jotta/ui/Box'
import { BrandIcon } from '@jotta/ui/BrandIcon'
import { Trans } from '@lingui/macro'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'

import styles from './NoFilesResults.module.scss'

const debug = Debug('jotta:NoFilesResults')

export const NoFilesResults: React.FC = observer(() => {
  return (
    <Box className={styles.container}>
      <BrandIcon icon="SvgNoResults" className={styles.defaultSvg} />
      <h2 className={styles.title}>
        <Trans>No files were found!</Trans>
      </h2>
    </Box>
  )
})
