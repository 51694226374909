import { RadixDialog } from '@jotta/ui/RadixDialog'
import type { RadixModalProps } from '@jotta/ui/RadixModal'
import { RadixModal } from '@jotta/ui/RadixModal'
import { t, Trans } from '@lingui/macro'
import { useMutation } from '@tanstack/react-query'
import { observer } from 'mobx-react-lite'

export interface EmptyTrashDialogProps extends RadixModalProps {
  onConfirm: () => Promise<void>
}
export const EmptyTrashDialog = observer<EmptyTrashDialogProps>(
  function EmptyTrashDialog({ onClose, onConfirm, ...props }) {
    const emptyTrashMutation = useMutation({
      mutationFn: onConfirm,
      onSuccess() {
        if (onClose) {
          onClose(false)
        }
      },
    })
    return (
      <RadixModal onClose={onClose} {...props}>
        <RadixDialog
          onClose={onClose}
          title={t`Trash`}
          smallTitle={t`Permanently delete`}
          buttons={[
            {
              action: 'close',
              label: t`Cancel`,
            },
            {
              action: emptyTrashMutation.mutateAsync,
              label: t`Permanently delete`,
              variant: 'buttons.error',
            },
          ]}
        >
          <Trans>
            Are you sure you want to remove all the files in your trash? This
            operation can not be undone.
          </Trans>
        </RadixDialog>
      </RadixModal>
    )
  },
)
