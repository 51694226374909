import type { BrandThemeIcon } from '@jotta/types/Brand'
import type { Locale } from './locale'

export interface LanguageToolbarItemConfig {
  value: Locale
  title: string
  flag: string
  flagIcon: BrandThemeIcon
}

export const languageToolbar: LanguageToolbarItemConfig[] = [
  {
    value: 'ca',
    title: 'Català',
    flag: '🇪🇸',
    flagIcon: 'SvgFlagCa',
  },
  {
    value: 'da',
    title: 'Dansk',
    flag: '🇩🇰',
    flagIcon: 'SvgFlagDk',
  },
  {
    value: 'de',
    title: 'Deutsch',
    flag: '🇩🇪',
    flagIcon: 'SvgFlagDe',
  },
  {
    value: 'en',
    title: 'English',
    flag: '🇬🇧',
    flagIcon: 'SvgFlagGb',
  },
  {
    value: 'es',
    title: 'Español',
    flag: '🇪🇸',
    flagIcon: 'SvgFlagEs',
  },
  {
    value: 'fr',
    title: 'Français',
    flag: '🇫🇷',
    flagIcon: 'SvgFlagFr',
  },
  {
    value: 'nl',
    title: 'Nederlands',
    flag: '🇳🇱',
    flagIcon: 'SvgFlagNl',
  },
  {
    value: 'nb',
    title: 'Norsk',
    flag: '🇳🇴',
    flagIcon: 'SvgFlagNo',
  },
  {
    value: 'pl',
    title: 'Polski',
    flag: '🇵🇱',
    flagIcon: 'SvgFlagPl',
  },
  {
    value: 'pt',
    title: 'Português',
    flag: '🇵🇹',
    flagIcon: 'SvgFlagPt',
  },
  {
    value: 'fi',
    title: 'Suomi',
    flag: '🇫🇮',
    flagIcon: 'SvgFlagFi',
  },
  {
    value: 'sv',
    title: 'Svenska',
    flag: '🇸🇪',
    flagIcon: 'SvgFlagSe',
  },
]
