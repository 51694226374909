import type { Person } from '@jotta/grpc-connect-openapi/people'
import { VirtualGrid } from '@jotta/virtualgrid/VirtualGrid'
import type { CSSProperties } from 'react'
import { type ReactNode, useCallback } from 'react'
import { PersonThumbLink } from './PersonThumb'

type PersonListProps = {
  people: Person[]
  children?: ReactNode
}

export function PersonList({ people, children, ...props }: PersonListProps) {
  const renderItem = useCallback(
    (index: number) => {
      const person = people.at(index)
      if (person) {
        return (
          <div className="grid flex-auto grid-rows-[max-content_1fr] p-1">
            <PersonThumbLink person={person} to={person.id} />
          </div>
        )
      }
      return <div className="bg-white" />
    },
    [people],
  )
  return (
    <div
      className="relative flex w-full px-6"
      style={
        {
          '--grid-opacity': '0',
        } as CSSProperties
      }
      {...props}
    >
      <VirtualGrid
        options={{
          renderItem,
          thumbAspect: [1.1],
          minColumns: [2],
          overscanRows: 10,
          columnWidth: [250],
          size: people.length,
        }}
      />
    </div>
  )
}
