import { openUploadDialog } from '@jotta/file-utils'
import { deleteDevice } from '@jotta/grpc-js-client/deviceService'
import {
  ItemKind,
  ItemType,
  create,
  createFolder,
  deleteItem,
  deleteItemPermanently,
  downloadItem,
  downloadZip,
  rename,
  restoreItem,
} from '@jotta/grpc-js-client/fileService'
import { getPath } from '@jotta/grpc-js-client/files/getPath'
import {
  declineShareInvite,
  joinShare,
  leaveShare,
} from '@jotta/grpc-js-client/sharingService'
import { CreateFolderRequest } from '@jotta/grpc-web/no/jotta/openapi/file/file.v2_pb'
import { navigate } from '@jotta/router'
import type { AppError } from '@jotta/types/AppError'
import { handleUnknownError } from '@jotta/types/AppError'
import type { FileActionContext } from '@jotta/types/FileActions'
import { isFileActionContext } from '@jotta/types/schemas/FileActionSchema'
import { getAlertListStore } from '@jotta/ui/AlertStore'
import { delay } from '@jotta/utils/delay'
import { env } from '@jotta/utils/env'
import {
  createPath,
  getFilenameFromPath,
  renamePath,
} from '@jotta/utils/pathUtils'
import { plural, t } from '@lingui/macro'
import debug from 'debug'
import { action, autorun, computed, makeAutoObservable, toJS, when } from 'mobx'
import { fileUploadManager } from '../ActionManager/FileUploadManager'
import { fetchDisplayPath } from '../DisplayFilePath/DisplayFilePath'
import { FileConflictQueue } from '../FileConflictDialog/FileConflictStore'
import type { FolderStore } from '../Folder/FolderStore'
import { PathItemStore } from '../PathItem/PathItemStore'
import { EmptyTrashActionStore } from './EmptyTrashActionStore'
import type { FileTransferType } from './FileTransferDialog/FileTransferDialog'
import { displayJoinFolderShareSuccessAlert } from './displayJoinFolderShareSuccessAlert'
import type { ActiveFileActionType } from './fileActionConfig'
import { isActiveFileActionType } from './fileActionConfig'
import { transferFiles } from './transferFiles'

export class FileActionStore {
  // #region Properties (3)

  private _api = {
    create,
    createFolder,
    deleteDevice,
    deleteItem,
    deleteItemPermanently,
    downloadItem,
    downloadZip,
    restoreItem,
    transferFiles,
    rename,
  }

  private log

  public activeFileAction:
    | {
        type: 'Disabled'
        context: null
        error: null
        path: null
      }
    | {
        type: ActiveFileActionType
        context: FileActionContext
        error: null | AppError
        path: string
      } = {
    type: 'Disabled',
    context: null,
    error: null,
    path: null,
  }

  // #endregion Properties (3)

  // #region Constructors (1)

  constructor(private folder: FolderStore) {
    this.log = folder.log.extend('action')
    makeAutoObservable<typeof this, 'log' | '_api' | '_mockapi' | 'api'>(
      this,
      {
        activeFileActionData: computed.struct,
        log: false,
        _api: false,
        _mockapi: false,
        api: false,
      },
      {
        autoBind: true,
      },
    )

    autorun(() => {
      if (fileUploadManager.completed > 0) {
        this.folder.refresh()
      }
    })
  }

  // #endregion Constructors (1)

  // #region Public Accessors (4)

  public get actionItems() {
    if (this.activeFileAction.type === 'Disabled') {
      return undefined
    }
    if (
      this.activeFileAction.context === 'SELECTION' &&
      this.folder.selectedChildren.length
    ) {
      return this.folder.selectedChildren
    }
    const item = this.folder.getItemByPath(this.activeFileAction.path)
    if (item) {
      return [item]
    }
    return undefined
  }

  public get activeFileActionData() {
    return toJS(this.activeFileAction)
  }

  public get api() {
    if (env.isProduction) {
      return this._api
    }
    // return this._mockApi
    return this._api
  }

  public get hasActiveAction() {
    return this.activeFileAction.type !== 'Disabled'
  }

  // #endregion Public Accessors (4)

  // #region Public Methods (20)

  public cancelActiveAction(open?: boolean, reason = 'Cancelled') {
    if (!open && this.hasActiveAction) {
      this.log('cancelActiveAction: %s, %s', this.activeFileAction.type, reason)
      this.activeFileAction = {
        type: 'Disabled',
        context: null,
        error: null,
        path: null,
      }
      console.groupEnd()
    } else {
      this.log('cancelActiveAction: skipped')
    }
  }

  public clearActiveFileActionError(open?: boolean) {
    if (!open && this.activeFileAction.error) {
      this.activeFileAction.error = null
    }
  }

  public async confirmDeleteItemsPermanently(
    progressUpdate?: (status: { completed: number; total: number }) => void,
  ) {
    if (this.activeFileAction.type !== 'DeletePermanently') {
      this.log('Active file action mismatch')
      return
    }
    if (this.emptyTrashPending) {
      this.log('Empty trash is currently in progress')
      this.cancelActiveAction()
      return
    }
    const items = this.actionItems
    if (!items) {
      this.cancelActiveAction(false, 'No items to delete found')
      return
    }

    let completed = 0
    const total = items.length

    if (progressUpdate) {
      progressUpdate({ completed, total })
    }

    const deletePermanently = progressUpdate
      ? async (item: PathItemStore) => {
          await this.deleteItemPermanently(item)
          completed++
          progressUpdate({ completed, total })
        }
      : this.deleteItemPermanently

    await Promise.all(items.map(deletePermanently))
    this.cancelActiveAction(false)
  }

  public async createDocument({
    path,
    documentType,
  }: {
    path: string
    documentType: 'docx' | 'xlsx' | 'pptx'
  }) {
    console.group('Create document %s %s', path, documentType)
    const documentTypeToKind: Record<typeof documentType, ItemKind> = {
      docx: ItemKind.WORD,
      xlsx: ItemKind.EXCEL,
      pptx: ItemKind.POWERPOINT,
    }
    if (
      /^New(Word|Excel|Powerpoint)Document$/.test(this.activeFileAction.type)
    ) {
      const log = this.log.extend(`new:${documentType}:${path}`)
      const item = this.folder.pathItem
      if (item.path === path) {
        const fileName = `Untitled.${documentType}`
        const res = await create({
          path,
          name: fileName,
        })
        log('allocated', res)
        const fileItem = new PathItemStore(
          res.name,
          {
            name: res.name,
            path: res.path,
            size: res.bytes,
            kind: documentTypeToKind[documentType],
            type: ItemType.FILE,
          },
          this.folder,
        )
        this.folder.addEntry(fileItem)
        this.folder.refresh()
        this.cancelActiveAction()
        this.dispatch('OpenDocument', 'FOLDER_LIST', fileItem.path)
      }
    }
    console.groupEnd()
  }

  public async deleteDevice(id: string) {
    const log = this.log.extend('deleteDevice')
    if (this.activeFileAction.type !== 'DeleteDevice') {
      log('File action type mismatch')
      return
    }
    try {
      await this.api.deleteDevice(id)
      this.cancelActiveAction()
      this.folder.refresh()
    } catch (error) {
      this.setError(error, 'DeleteDevice')
    }
  }

  public async createFolder(name: string) {
    const log = this.log.extend('createFolder')
    const folder = this.folder
    const apiPath = folder.apiPath
    if (this.activeFileAction.type !== 'CreateFolder') {
      log('File action type mismatch')
      return
    }
    try {
      log('Creating folder with name %s', name)
      const item = await this.api.createFolder({
        path: createPath(apiPath, name),
        conflictHandler: CreateFolderRequest.ConflictHandler.REJECT_CONFLICTS,
      })
      log('Created', item)
      folder.addItem(item, Date.now())
      this.cancelActiveAction()
    } catch (error) {
      this.setError(error, 'CreateFolder')
    }
  }

  public async deleteItem(path: string) {
    const filename = getFilenameFromPath(path)
    const item = this.folder.getItemByPath(path)
    if (item && item.isDeleted) {
      this.cancelActiveAction(false, 'Item already deleted')
      return
    }
    const log = this.log.extend(`delete:${filename}`)
    // Adding a small delay gives the context
    await delay(10)
    if (item) {
      item.model.deletedAtMillis = Date.now()
    }
    try {
      await this.api.deleteItem({
        path,
      })
      log('Success')
      if (item) {
        item.model.submit()
        this.folder.refresh()
        this.folder.selection.unselect(item.path)
      }
      log('Remove item', this.folder.removeItem(path))
      this.cancelActiveAction()
      getAlertListStore().showAlert({
        testid: 'FileDeleteSuccessAlert',
        message: t`This file has been moved to the Trash and will be permanently deleted in 30 days.`,
        severity: 'info',
        actions: [
          {
            text: t`Undo`,
            action: () => this.dispatch('Restore', 'FOLDER_LIST', path),
          },
        ],
      })
    } catch (error) {
      log('Error', error)
      if (item) {
        item.model.reset()
      }
      this.cancelActiveAction()
      throw error
    }
  }

  public async deleteItemPermanently(item: PathItemStore) {
    const log = this.log.extend(`permanentlyDelete:${item.name}`)
    if (item.permanentlyDeletedAt) {
      this.log('Cancelled, files is already permanently deleted')
      return
    }
    // Adding a small delay gives the context
    await delay(10)
    if (item) {
      item.permanentlyDeletedAt = Date.now()
    }
    try {
      await this.api.deleteItemPermanently({
        path: item.path,
      })
      log('Success')
    } catch (error) {
      log('Error', error)
      if (item) {
        item.permanentlyDeletedAt = 0
      }
      throw error
    }
  }

  public async deleteSelectedItems(items: PathItemStore[]) {
    if (
      this.activeFileAction.type === 'Delete' &&
      this.activeFileAction.context === 'SELECTION'
    ) {
      const log = this.log.extend(
        `deleteSelected:${this.folder.selectedChildren.length}`,
      )
      log('Start delete')
      await Promise.allSettled(
        items.map(file => {
          file.model.deletedAtMillis = Date.now()
          return this.api
            .deleteItem({
              path: file.data.path,
            })
            .then(() => {
              if (file) {
                file.model.submit()
              }
            })
            .catch(error => {
              log('Error', error)
              if (file) {
                file.model.reset()
              }
            })
        }),
      )
      log('Done')
      this.cancelActiveAction()
      getAlertListStore().showAlert({
        testid: 'FileDeleteSuccessAlert',
        message: t`The files have been moved to the Trash and will be permanently deleted in 30 days.`,
        severity: 'info',
        actions: [
          {
            text: t`Undo`,
            action: async () => {
              await this.restoreItems(items.map(item => item.path))
              items.map(item => this.folder.selection.select(item.path))
            },
          },
        ],
      })
      return
    }
  }

  public dispatch(
    action: ActiveFileActionType,
    context: FileActionContext,
    path: string,
  ) {
    if (this.hasActiveAction) {
      this.log(
        'Dispatch %s rejected, %s action in progress',
        action,
        this.activeFileAction.type,
      )
      return
    }
    switch (action) {
      /**
       * Actions that can be dispatched without checking for a pathItem
       */
      case 'Rename':
      case 'AcceptPendingInvite':
      case 'DeclinePendingInvite':
        {
          console.group('Dispatch action %s %s', action, path)
          this.activeFileAction = {
            type: action,
            context,
            error: null,
            path,
          }
        }

        break

      /**
       * Actions that require the current folder to contain a pathitem
       * matching the action path
       */
      default:
        {
          if (action !== 'Restore') {
            const item = this.folder.getItemByPath(path)
            if (!item) {
              this.log(
                'Dispatch %s rejected, item for path %s not found',
                action,
                path,
              )
              return
            }
          }

          console.group('Dispatch action %s %s', action, path)
          this.activeFileAction = {
            type: action,
            context,
            error: null,
            path,
          }
        }
        break
    }
  }

  public acceptPendingInvite(inviteKey: string) {
    const log = this.log.extend(`Accept invite`)
    if (this.activeFileAction.type !== 'AcceptPendingInvite') {
      log('Active file action mismatch')
      return
    }
    const invite = this.folder.root.pendingInvites.find(
      invite => invite.inviteKey === inviteKey,
    )
    if (!invite) {
      log('Invite with key %s not found', inviteKey)
      this.cancelActiveAction()
    }
    joinShare({ inviteKey })
      .then(
        action('joinShareSuccess', shareInfo => {
          const folder = shareInfo?.pathItem?.name || 'folder'
          displayJoinFolderShareSuccessAlert(folder)
          this.folder.refresh()
        }),
        action('joinShareError', err => {
          this.folder.refresh()
        }),
      )
      .finally(this.cancelActiveAction)
  }
  public declinePendingInvite(inviteKey: string) {
    const log = this.log.extend(`Decline invite`)
    if (this.activeFileAction.type !== 'DeclinePendingInvite') {
      log('Active file action mismatch')
      return
    }
    const invite = this.folder.root.pendingInvites.find(
      invite => invite.inviteKey === inviteKey,
    )
    if (!invite) {
      log('Invite with key %s not found', inviteKey)
      this.cancelActiveAction()
    }
    declineShareInvite({ inviteKey })
      .then(
        action('declineShareSuccess', shareInfo => {
          this.folder.refresh()
        }),
        action('declineShareError', err => {
          this.folder.refresh()
        }),
      )
      .finally(this.cancelActiveAction)
  }
  public async dispatchCancelActiveAction(action: ActiveFileActionType) {
    if (this.activeFileAction.type === action) {
      await delay(10)
      this.cancelActiveAction()
    }
  }

  /**
   * The action is happening on the current route
   * Actions that change or remove the route requires navigation when the action succeeds
   */
  get isActiveRouteAction() {
    return this.activeFileAction.path === this.folder.apiPath
  }
  public async downloadItem() {
    const log = this.log.extend(`downloadItem`)
    const useZipDownload =
      this.activeFileAction.path?.toLowerCase() === '/contacts'

    if (useZipDownload) {
      const res = await getPath({ path: '/Contacts' })
      const paths = res.item.childrenList.map(child => child.path)
      await this.api.downloadZip(paths)
      this.cancelActiveAction()
      return
    }

    if (this.activeFileAction.type === 'Download') {
      log(this.activeFileAction.path)
      await this.api.downloadItem({
        path: this.activeFileAction.path,
        includeDeleted: this.folder.pathItem.isTrash,
      })
      this.cancelActiveAction()
    }
  }

  public async downloadSelectedItems(items: PathItemStore[]) {
    if (
      this.activeFileAction.type === 'Download' &&
      this.activeFileAction.context === 'SELECTION'
    ) {
      const canDownloadFolder =
        !this.folder.pathItem.isModule && !this.folder.pathItem.isDevice

      const log = this.log.extend(`downloadSelectedItems:${items.length}`)
      // Download the current folder if every item in the list is selected
      if (
        items.length > 1 &&
        this.folder.selection.allSelected &&
        canDownloadFolder
      ) {
        log('start all selected folder download')
        await this.api.downloadItem({
          path: this.folder.pathItem.path,
          includeDeleted: this.folder.pathItem.isDeleted,
        })
        this.cancelActiveAction()
        return
      }
      switch (items.length) {
        // No items, cancel
        case 0: {
          this.cancelActiveAction(false, 'No items to download')
          return
        }
        // Use single item download for lists with exactly one item
        case 1:
          {
            log('start single file download')
            await this.api.downloadItem({
              path: items[0].path,
              includeDeleted: items[0].isDeleted,
            })
          }
          break
        // ZIP download for all other cases
        default:
          {
            log('start multiple file download')
            const paths = items.map(item => item.path)
            await this.api.downloadZip(paths)
          }
          break
      }
      this.cancelActiveAction()
    }
  }

  emptyTrashPending: EmptyTrashActionStore | null = null

  public async emptyTrash() {
    if (this.emptyTrashPending) {
      this.cancelActiveAction(false, 'Trash is already emptying')
      return
    }

    this.emptyTrashPending = new EmptyTrashActionStore()

    when(() => this.emptyTrashPending === null).then(() => {
      if (this.folder.route.isTrash) {
        if (this.folder.route.isTrash) {
          navigate('/web/trash', { replace: true })
        }
        // Add a small delay to make sure pending requests are completed (applies if operation is cancelled)
        window.setTimeout(() => this.folder.refresh(), 200)
      }
    })
  }

  public leaveShare(folderShareId: string) {
    if (this.activeFileAction.type === 'Share') {
      const { isActiveRouteAction } = this
      leaveShare({
        folderShareId,
      }).then(
        action('leaveShareSuccess', () => {
          if (isActiveRouteAction) {
            this.folder.root.router.up()
          }
          this.folder.refresh()
          this.cancelActiveAction()
        }),
        action('leaveShareError', err => {
          this.log(err)
          getAlertListStore().showAlert({
            message: t`An error occured!`,
            severity: 'error',
          })
        }),
      )
    }
  }
  public onContextMenuSelect(e: Event) {
    const el = e.target
    if (el instanceof HTMLElement) {
      const path = el.dataset.filePath || ''
      const action = el.dataset.fileAction || ''
      const context = el.dataset.fileContext || ''
      console.group('onContextMenuSelect %s %s', action, path)
      this.log('onContextMenuSelect', {
        action,
        path,
        context,
        included: this.folder.ids.has(path),
        isActiveFileActionType: isActiveFileActionType(action),
        isFileActionContext: isFileActionContext(context),
      })

      if (
        path &&
        isActiveFileActionType(action) &&
        isFileActionContext(context)
      ) {
        this.log('onContextMenuSelect', e, action, context, path)
        switch (action) {
          case 'UploadFiles':
            {
              openUploadDialog({
                folderMode: false,
                onSelection: this.folder.actions.upload,
                onError: error => {
                  debug('UploadFiles error')
                },
              })
            }
            break
          case 'UploadFolder':
            {
              openUploadDialog({
                folderMode: true,
                onSelection: this.folder.actions.upload,
                onError: error => {
                  debug('UploadFiles error')
                },
              })
            }
            break

          default:
            setTimeout(() => {
              this.dispatch(action, context, path)
            }, 200)
            break
        }
      }
      console.groupEnd()
    }
  }

  public async rename({ path, newName }: { path: string; newName: string }) {
    if (this.activeFileAction.type === 'Rename') {
      if (path === this.activeFileAction.path) {
        console.group('Rename %s to %s', path, newName)
        const log = this.log.extend(`rename:${path}`)
        const item = this.folder.getItemByPath(path)
        if (item) {
          try {
            item.model.name = newName
            item.model.path = renamePath(path, newName)
            await this.api.rename({ path, newName })
            item.model.submit()
            this.folder.addEntry(item)
            this.folder.removeItem(path)
            log('Success', item.name, item.path)
          } catch (error) {
            log('Error', error)
            if (item) {
              item.model.reset()
            }
            throw error
          } finally {
            this.cancelActiveAction()
            console.groupEnd()
          }
        } else {
          log('Item for path %s not found', path)
          this.cancelActiveAction()
          console.groupEnd()
        }
      }
      this.cancelActiveAction()
    }
  }

  public async restoreItem(path: string) {
    const log = this.log.extend(`restore:${path}`)
    const item = this.folder.getItemByPath(path)
    log('Start')
    try {
      if (item) {
        item.model.deletedAtMillis = 0
      }
      await this.api.restoreItem({ path })
    } catch (error) {
      log('Error', error)
      if (item) {
        item.model.reset()
      }
    }
  }

  public async restoreItems(paths: string[]) {
    if (!paths.length) {
      this.cancelActiveAction(false, 'Empty item array')
      return
    }
    if (paths.length === 1) {
      const destinationPath = paths[0]
      const [destination] = await Promise.all([
        fetchDisplayPath(destinationPath),
        this.restoreItem(destinationPath),
      ])
      this.cancelActiveAction()
      getAlertListStore().showAlert({
        message: t`${destination} was restored`,
        severity: 'success',
      })
      this.folder.refetchChildByPath(destinationPath)
      return
    }
    await Promise.allSettled(paths.map(path => this.restoreItem(path)))
    this.folder.refresh()
    const count = paths.length
    getAlertListStore().showAlert({
      message: t`${count} files or folders were restored`,
      severity: 'success',
    })
    this.cancelActiveAction()
  }

  public setError(error: unknown, action: ActiveFileActionType) {
    if (this.activeFileAction.type === action) {
      this.activeFileAction.error = handleUnknownError(error)
    }
  }

  public readonly conflicts = new FileConflictQueue()

  public async transferItems({
    transferType,
    targetDirectoryPath,
  }: {
    transferType: FileTransferType
    targetDirectoryPath: string
  }) {
    if (transferType === 'MOVE' && this.activeFileAction.type !== 'Move') {
      this.log(
        `Can't move when Active file action is ${this.activeFileAction.type}`,
      )
      return
    }
    if (transferType === 'COPY' && this.activeFileAction.type !== 'Copy') {
      this.log(
        `Can't copy when Active file action is ${this.activeFileAction.type}`,
      )
      return
    }
    const items = this.actionItems
    if (!items) {
      this.cancelActiveAction(false, 'No items to transfer ')
      return
    }

    if (items.length > 1) {
      this.conflicts.hasMultiple = true
    }

    if (transferType === 'MOVE') {
      for (const item of items) {
        item.model.deletedAtMillis = Date.now()
        item.model.submit()
      }
    }

    let numFilesTransferred = 0
    const errors: unknown[] = []

    const [displayPath] = await Promise.all([
      fetchDisplayPath(targetDirectoryPath),
      transferFiles({
        filePaths: items.map(f => f.path),
        transferType,
        targetDirectoryPath,
        conflictStore: this.conflicts,
        onFileTransferred: ({ oldPath, fileName, error }) => {
          if (!error) {
            numFilesTransferred++
          } else {
            errors.push(error)
          }
          const item = this.folder.getItemByPath(createPath(oldPath, fileName))
          if (item && error) {
            item.model.deletedAtMillis = 0
            item.model.submit()
          } else if (item && !error && transferType === 'MOVE') {
            this.folder.removeItem(createPath(oldPath, fileName))
          }
        },
      }).catch(e => {
        this.conflicts.clear()
        this.folder.refresh()
        this.cancelActiveAction()
        throw e
      }),
    ])

    this.conflicts.clear()
    this.folder.refresh()

    if (numFilesTransferred) {
      const destination = getFilenameFromPath(displayPath)

      switch (transferType) {
        case 'COPY':
          {
            getAlertListStore().showAlert({
              testid: 'FileCopySuccessAlert',
              message: t({
                id: 'FileTransferCopySuccess',
                message: plural(numFilesTransferred, {
                  one: `File copied to: ${destination}`,
                  other: `The files were copied to: ${destination}`,
                }),
              }),
              severity: 'info',
            })
          }
          break
        case 'MOVE':
          {
            const count = items.length
            getAlertListStore().showAlert({
              testid: 'FileMoveSuccessAlert',
              message: t({
                id: 'FileTransferMoveSuccess',
                message: plural(count, {
                  one: `File moved to: ${destination}`,
                  other: `The files were moved to: ${destination}`,
                }),
              }),
              severity: 'info',
            })
          }
          break
      }
    }

    if (errors.length) {
      throw errors
    }
  }

  public upload(files: File[]) {
    Promise.allSettled(
      files.map(file => fileUploadManager.upload(file, this.folder.apiPath)),
    )
  }

  // #endregion Public Methods (20)
}
