// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FileList_container__W7ft2{contain:content;flex:0 0 auto;overflow:hidden;position:relative;width:100%}.FileList_container__W7ft2.FileList_empty__Ug1Oy{align-items:center;flex:1 1 auto}.FileList_container__W7ft2.FileList_list__85z5d{background:linear-gradient(#0000 calc(100% - 1px),var(--color-border) calc(100% - 1px),var(--color-border) 100%);background-repeat:space;background-size:100% var(--virtual-row-height)}.FileList_container__W7ft2.FileList_thumbs__l8ok0{--grid-line-height:30px;--grid-line-width:var(--s3);--width:calc(100%/var(--virtual-columns, 2));background:linear-gradient(#fff var(--grid-line-height),#0000 var(--grid-line-height),#0000 var(--virtual-row-height)),linear-gradient(90deg,#fff var(--grid-line-width),#0000 var(--grid-line-width)),var(--color-muted);background-position:-8px -16px;background-size:var(--width) var(--virtual-row-height)}.FileList_container__W7ft2:not(.FileList_empty__Ug1Oy)>div{display:grid;grid-template-columns:repeat(var(--virtual-columns,1),1fr);height:var(--virtual-row-height);left:0;position:absolute;right:0;top:0}.FileList_disabled__NdwlN{opacity:.5;pointer-events:none}.FileList_loadingSpinner__l-R3t{left:calc(50% + var(--content-left)/2);position:fixed;top:calc(50% + var(--content-top)/2);transform:translate(-50%,-50%)}`, "",{"version":3,"sources":["webpack://./../../libraries/files/src/FileList/FileList.module.scss"],"names":[],"mappings":"AACA,2BAKE,eAAA,CAFA,aAAA,CAGA,eAAA,CAFA,iBAAA,CAFA,UAIA,CACA,iDAEE,kBAAA,CADA,aACA,CAEF,gDACE,gHAAA,CAMA,uBAAA,CADA,8CACA,CAEF,kDACE,uBAAA,CACA,2BAAA,CACA,4CAAA,CACA,yNAEE,CAaF,8BAAA,CADA,sDACA,CAEF,2DAKE,YAAA,CACA,0DAAA,CACA,gCAAA,CAJA,MAAA,CAFA,iBAAA,CAGA,OAAA,CAFA,KAKA,CAKJ,0BACE,UAAA,CACA,mBAAA,CAGF,gCAGE,sCAAA,CAFA,cAAA,CACA,oCAAA,CAEA,8BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `FileList_container__W7ft2`,
	"empty": `FileList_empty__Ug1Oy`,
	"list": `FileList_list__85z5d`,
	"thumbs": `FileList_thumbs__l8ok0`,
	"disabled": `FileList_disabled__NdwlN`,
	"loadingSpinner": `FileList_loadingSpinner__l-R3t`
};
export default ___CSS_LOADER_EXPORT___;
