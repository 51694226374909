import { ObservableQuery } from '@jotta/query'
import type { PhotosApi } from '@jotta/types/PhotosApi'
import type { Debugger } from 'debug'
import Debug from 'debug'
import { computed, makeAutoObservable, runInAction } from 'mobx'
import { fetchSharedList } from '../api/photosApi'
import type { AlbumInfoStore } from './AlbumInfoStore'
import type { PhotoStore } from './PhotoStore'

export class AlbumSharesQueryStore {
  private log: Debugger
  query

  constructor(public root: PhotoStore) {
    this.log = Debug('jotta:photos:query:shares')
    this.query = new ObservableQuery({
      name: 'shares',
      queryOptions: {
        staleTime: 1000 * 60 * 2, // 2 minutes
        queryKey: ['shares'],
        queryFn: async () => {
          const response = await fetchSharedList()
          this.log('Fetch albums', response)
          return runInAction(() =>
            this.root.albumRoot.setAlbums(response.albums, true),
          )
        },
      },
    })
    makeAutoObservable<typeof this, 'log'>(
      this,
      {
        log: false,
        albumIds: computed.struct,
      },
      {
        autoBind: true,
      },
    )
  }

  get isLoading() {
    return this.query.isLoading
  }

  get albumIds(): readonly string[] {
    return this.query.data || []
  }
  get albums(): readonly AlbumInfoStore[] {
    return this.root.albumRoot
      .getAlbumsByIds(this.albumIds, 'lastModified', 'DESC')
      .filter(album => album.isShared)
  }
  get albumsData(): readonly PhotosApi.AlbumInfo[] {
    return this.albums.map(a => a.data)
  }
}
