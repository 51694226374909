import type { Storage } from '@jotta/grpc-connect-openapi/storage'
import { t } from '@lingui/macro'

export const formatBytes = (bytes: bigint): string => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const kilo = Number(1000)
  let unitIndex = 0
  let value = Number(bytes)

  // Convert bytes to appropriate unit
  while (value >= kilo && unitIndex < units.length - 1) {
    value /= kilo
    unitIndex++
  }
  value = Math.round(value * 10) / 10
  const formattedValue = value % 1 === 0 ? value.toString() : value.toFixed(1)

  return `${formattedValue} ${units[unitIndex]}`
}

export const formatStorage = (storage: Storage): string | undefined => {
  switch (storage.type.case) {
    case 'unlimited':
      return t`Unlimited storage`
    case 'bytes':
      return formatBytes(storage.type.value)
    case undefined:
      return undefined
  }
}
