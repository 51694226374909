import type { CSSProperties } from 'react'
import { forwardRef } from 'react'
import type { ResponsiveStyleValue } from 'theme-ui'
import type { BoxPropsAs } from '../Box/Box'
import { Box } from '../Box/Box'

export interface VHStackProps {
  /** Spacing between items */
  gap?: ResponsiveStyleValue<number | string>
}
export interface StackProps {
  /** Spacing between items */
  gap?: ResponsiveStyleValue<number | string>
  horizontal?: boolean | boolean[]
  justifyContent?: CSSProperties['justifyItems']
  alignItems?: CSSProperties['alignItems']
}
export const HStack = forwardRef<
  HTMLDivElement,
  BoxPropsAs<'div', VHStackProps, 'all'>
>(function HStack({ gap = 0, ...props }: VHStackProps, ref) {
  return (
    <Box
      ref={ref}
      sx={{
        display: 'grid',
        gap,
        gridAutoFlow: 'column',
        gridTemplateColumns: 'auto',
        gridTemplateRows: 'auto',
      }}
      {...props}
    />
  )
})
export const VStack = forwardRef<
  HTMLDivElement,
  BoxPropsAs<'div', VHStackProps, 'all'>
>(function VStack({ gap = 0, ...props }: VHStackProps, ref) {
  return (
    <Box
      ref={ref}
      sx={{
        display: 'grid',
        gap,
        gridAutoFlow: 'row',
        gridTemplateColumns: 'auto',
        gridTemplateRows: 'auto',
      }}
      {...props}
    />
  )
})
export const Stack = forwardRef<
  HTMLDivElement,
  BoxPropsAs<'div', StackProps, 'all'>
>(function Stack(
  {
    gap = 0,
    horizontal = false,
    justifyContent = 'stretch',
    alignItems = 'stretch',
    ...props
  }: StackProps,
  ref,
) {
  return (
    <Box
      ref={ref}
      sx={{
        display: 'grid',
        gap,
        gridAutoFlow: horizontal
          ? Array.isArray(horizontal)
            ? horizontal.map(v => (v ? 'column' : 'row'))
            : 'column'
          : 'row',
        gridTemplateColumns: 'auto',
        gridTemplateRows: 'auto',
        justifyContent,
        alignItems,
      }}
      {...props}
    />
  )
})
