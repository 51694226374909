import { ObservableQuery } from '@jotta/query'
import { CollectionType } from '@jotta/types/schemas/AlbumSchema'
import type { Debugger } from 'debug'
import Debug from 'debug'
import { makeAutoObservable, runInAction } from 'mobx'
import { fetchAlbumListByType } from '../api/photosApi'
import type { CollectionTypeKey } from '../components/Album/AlbumList/AlbumList.loaders'
import { getAlbumListQueryKey } from '../components/Album/AlbumList/AlbumList.loaders'
import type { AlbumRootStore } from './AlbumRootStore'

export class AlbumListQueryStore<T extends CollectionTypeKey> {
  private log: Debugger
  query
  constructor(
    public root: AlbumRootStore,
    private albumType: T,
  ) {
    this.log = Debug(`jotta:photos:query:${albumType}`)
    this.query = new ObservableQuery({
      name: albumType,
      queryOptions: {
        staleTime: 1000 * 60 * 2, // 2 minutes
        queryKey: getAlbumListQueryKey(albumType),
        queryFn: async () => {
          const response = await fetchAlbumListByType({
            albumType,
          })
          this.log('Fetch albums', response)
          return runInAction(() => this.root.setAlbums(response.result, true))
        },
      },
    })
    makeAutoObservable<typeof this, 'log'>(
      this,
      {
        log: false,
      },
      {
        autoBind: true,
      },
    )
  }
  get collectionType(): CollectionType {
    return CollectionType[this.albumType]
  }
  get isLoading() {
    return this.query.isLoading
  }
  get albumIds(): readonly string[] {
    return this.query.data || []
  }
}
