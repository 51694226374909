import { makeAutoObservable } from 'mobx'

export interface Group {
  contextKey: string
  totalCommentCount: number
  members: {
    [key: string]: number
  }
}

export class GroupStore {
  public constructor() {
    makeAutoObservable(this)
  }

  private _group: Group | null = null

  public set group(group: Group | null) {
    this._group = group
  }

  public get group(): Readonly<Group> | null {
    return this._group
  }

  public parentContextKey(contextKey?: string): string | undefined {
    if (this._group && contextKey && this._group.members[contextKey]) {
      return this._group.contextKey
    }
  }

  public getCommentCount(contextKey?: string): number | undefined {
    if (!this._group || !contextKey) {
      return
    }

    if (this._group.contextKey === contextKey) {
      return this._group.totalCommentCount
    }

    return this._group.members[contextKey]
  }
}

export const groupStore = new GroupStore()
