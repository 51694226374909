import { FormField } from '@jotta/ui/FormField'
import { t } from '@lingui/macro'
import { minimumPasswordLength } from './signupForm'
import { PasswordInput } from '@jotta/ui/PasswordInput'
import { PasswordStrengthIndicator } from './PasswordStrengthIndicator'
import type { Field } from '@jotta/hooks'

export function PasswordField({ field }: { field: Field }) {
  return (
    <>
      <FormField
        label={t`Password`}
        description={t`Minimum ${minimumPasswordLength} characters`}
        field={field}
      >
        <PasswordInput {...field.inputProps} />
      </FormField>

      <PasswordStrengthIndicator password={field.value} />
    </>
  )
}
