import { useBrandStore } from '@jotta/ui/useBrandTheme'
import { useCallback } from 'react'
import { showArticle } from '@jotta/intercom'

export function useOnLearnMoreSearch() {
  const branding = useBrandStore()
  const onLearnMoreSearchId = branding.showLearnMoreWaitlistLink
  const onLearnMoreSearch = useCallback(() => {
    if (onLearnMoreSearchId) {
      showArticle(onLearnMoreSearchId)
    }
  }, [onLearnMoreSearchId])
  return {
    onLearnMoreSearch,
    onLearnMoreSearchId,
  }
}
export function useOnLearnMoreWaitlist() {
  const branding = useBrandStore()
  const onLearnMoreWaitlistId = branding.showLearnMoreSearchLink
  const onLearnMoreWaitlist = useCallback(() => {
    if (onLearnMoreWaitlistId) {
      showArticle(onLearnMoreWaitlistId)
    }
  }, [onLearnMoreWaitlistId])
  return {
    onLearnMoreWaitlist,
    onLearnMoreWaitlistId,
  }
}
