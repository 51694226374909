import Debug from 'debug'
import { Base64 } from 'js-base64'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { DisplayActiveFileActionDialog } from '../FileActions/DisplayActiveFileActionDialog'
import { FileCarousel } from '../FileCarousel/FileCarousel'
import { useFileStore } from '../FileStore/FileContext'
import { FolderVirtualList } from '../Folder/FolderVirtualList'
import { PathItemRowHeader } from '../PathItem/PathItemRowHeader'
import type { PathItemStore } from '../PathItem/PathItemStore'
import { FileSearchStore } from './FileSearchStore'
import { NoFilesResults } from './NoFilesResults'
const debug = Debug('jotta:searchroute')
export const FileSearchRoute = observer(function FileSearchRoute() {
  const fileStore = useFileStore()
  const { query = '', '*': filehash = '' } = useParams()
  const [store] = useState(new FileSearchStore(fileStore))
  const folder = store.folder
  let file: PathItemStore | undefined = undefined

  try {
    if (filehash) {
      file = folder.getItemByPath(Base64.decode(filehash))
    }
  } catch (error) {
    debug(error)
  }
  useEffect(() => {
    if (query) {
      store.setQuery({
        query,
      })

      return store.cancel
    }
  }, [store, query])

  return (
    <>
      <PathItemRowHeader store={store.folder} />
      {store.status === 'complete' && store.folder.isEmpty ? (
        <NoFilesResults />
      ) : (
        <>
          <FolderVirtualList store={store.folder} />
          <DisplayActiveFileActionDialog store={store.folder} />
          <FileCarousel
            open={!!filehash}
            context="search"
            file={file}
            files={folder.carouselPathItems}
          />
        </>
      )}
    </>
  )
})
