import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { DialogTrigger } from '@radix-ui/react-dialog'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { SharePhotoDialog } from '../Album/Dialogs/SharePhotoDialog'
import type { PhotoActionHandlerActionProps } from './PhotoActionHandlers'
import { useDispatchPhotoActionMutation } from './useDispatchPhotoActionMutation'
const debug = Debug('jotta:photos:action:SharePhotos')

export const ActionSharePhotos = observer<
  PhotoActionHandlerActionProps<'SHARE_PHOTO'>
>(function ActionSharePhotos({
  action,
  photoIds = [],
  dispatch,
  mutationOptions,
  shareId: initialShareId,
  buttonVariant = 'buttons.albumActionButton',
  onActionDialogClose,
  disabled = false,
}) {
  const [open, setOpen] = useState(false)
  const [shareId, setShareId] = useState(initialShareId)
  const [albumId, setAlbumId] = useState('')

  const shareMutation = useDispatchPhotoActionMutation(
    'SHARE_PHOTO',
    {
      ...mutationOptions,
      onSettled: async data => {
        const album = await data

        debug('onSettled', album)
        if (album) {
          const shareId = album.shareInfo && album.shareInfo.uri
          setShareId(shareId)
          setAlbumId(album.id)
        }
      },
    },
    dispatch,
  )
  const unshareMutation = useDispatchPhotoActionMutation(
    'UNSHARE_ALBUM',
    {
      onSuccess: async data => {
        await data
        setShareId('')
        return undefined
      },
    },
    dispatch,
  )

  return (
    <SharePhotoDialog
      open={open}
      count={photoIds.length}
      onClose={open => {
        setOpen(open)
        if (!open && onActionDialogClose) {
          onActionDialogClose(action.actionType)
        }
      }}
      shareId={shareId}
      onSharePhoto={nextShareState => {
        if (!disabled) {
          if (!nextShareState && albumId) {
            unshareMutation.mutateAsync([albumId])
          } else {
            shareMutation.mutateAsync([photoIds])
          }
        }
      }}
      isLoading={shareMutation.isPending}
      triggerElement={
        <DialogTrigger
          aria-label={action.label}
          data-testid={action.testid}
          aria-busy={shareMutation.isPending}
          disabled={disabled}
          key={action.actionType}
          sx={{
            variant: buttonVariant,
          }}
        >
          <PlainBrandIcon icon={action.icon} />
          <span>{action.label}</span>
        </DialogTrigger>
      }
    />
  )
})
