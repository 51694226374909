import { useAuthenticated } from '@jotta/auth-client/useAuthStatus'
import { useFeatures } from '@jotta/grpc-connect-client/config'
import { useGetCustomer } from '@jotta/grpc-connect-client/customer'
import { TransportProvider } from '@connectrpc/connect-query'
import { addBreadcrumb } from '@sentry/react'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import {
  useGetOnboarding,
  useSetOnboarding,
} from '@jotta/grpc-js-client/onboarding'
import {
  getDebugPeople,
  PeopleDebug,
} from '../../people/internal/components/PeopleDebug/PeopleDebug'
import { usePhotoStore } from '../../store/PhotoContext'
import { waitlistStore } from '../../store/WaitlistStore'
import { TimelineActivation } from '../PhotoGallery/TimelineActivation'
import {
  PhotoSearchOverlay,
  PhotoSearchPlaceholder,
} from '../PhotoSearch/PhotoSearchOverlay'
import { UploadPhotos } from '../UploadPhotos/UploadPhotos'
import { PhotoSearchResult } from '../../search/internal/PhotoSearchResult'
import { AppLayoutPortal } from '@jotta/ui/AppLayoutPortal'
import { PhotoSearchFormSimple } from '../../search/internal/PhotoSearchFormSimple'
import { authTransport } from '@jotta/grpc-connect-client/transport'
import { PhotoState } from '@jotta/grpc-connect-openapi/esm/openapi/customer/customer.v2_pb'
/**
 * Root route container for all photo routes
 */
export const PhotoLayout = observer<{
  isPublic?: boolean
}>(function PhotoLayout({ isPublic = false }) {
  const features = useFeatures()
  const authenticated = useAuthenticated()
  const { customer } = useGetCustomer().data || {}
  const isPhotosActivated =
    isPublic || (customer && customer.photoState !== PhotoState.NOT_ACTIVE)

  useEffect(() => {
    addBreadcrumb({
      level: 'info',
      category: 'PhotoRouteContainer',
      data: {
        section: isPhotosActivated ? 'PhotoLayout' : 'TimelineActivation',
      },
    })
  }, [isPhotosActivated])

  const navigate = useNavigate()
  const { data } = useGetOnboarding({
    enabled: authenticated === 'TRUE',
  })
  const setOnboarding = useSetOnboarding()

  const searchActivated =
    isPhotosActivated &&
    features.photoSearch &&
    (waitlistStore.isActive || waitlistStore.isOnWaitlist)

  const peopleSearchActivated = getDebugPeople().peopleSearch && searchActivated

  const showPlaceholder = Boolean(
    isPhotosActivated &&
      features.photoSearch &&
      waitlistStore.isInactive &&
      data &&
      data.has('smartPhotoSearchBanner'),
  )

  const clickHandler = async () => {
    await setOnboarding.mutateAsync(['smartPhotoSearchBanner', false])
    navigate('/photo')
  }
  const { albumId = '' } = useParams()
  const photoStore = usePhotoStore()

  useEffect(() => {
    photoStore.currentAlbumId = albumId
  }, [albumId, photoStore])
  useEffect(() => {
    photoStore.isPublicRoute = isPublic
  }, [isPublic, photoStore])

  if (peopleSearchActivated) {
    const photoSearchStore = photoStore.search2
    return (
      <TransportProvider transport={authTransport}>
        <AppLayoutPortal slot="header">
          <PhotoSearchFormSimple />
        </AppLayoutPortal>
        {photoSearchStore.hasResults ? (
          <PhotoSearchResult search={photoSearchStore} />
        ) : (
          <Outlet />
        )}
        <UploadPhotos />
        <PeopleDebug />
      </TransportProvider>
    )
  }

  return (
    <TransportProvider transport={authTransport}>
      {isPhotosActivated ? <Outlet /> : <TimelineActivation />}
      {searchActivated && <PhotoSearchOverlay />}
      {showPlaceholder && <PhotoSearchPlaceholder onClick={clickHandler} />}

      {!isPublic && <UploadPhotos />}
      <PeopleDebug />
    </TransportProvider>
  )
})
