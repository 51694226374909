import type {
  BrandConfig,
  BrandThemeIconMap,
  BreakpointList,
} from '@jotta/types/Brand'
import type { PartialDeep, Simplify } from 'type-fest'
import type CSS from 'csstype'
import { exactBaseBrandTheme } from '@jotta/ui/themes/base'
import type { Theme } from 'theme-ui'
import type { BrandColorMap } from './ColorTypes'
import type { PartialVariantStyles, VariantStyles } from './VariantTypes'

export type ExactBaseBrandTheme = typeof exactBaseBrandTheme

/**
 * Get an array of keys for a top level property in the base theme
 * @example
 * ```
 * getKeysForBaseThemeProperty('button')
 * // Returns array of button types ['primary','secondary',...]
 * ```
 * @param key A key of the base theme style object
 */
export function getKeysForBaseThemeProperty<
  K extends keyof ExactBaseBrandTheme,
>(key: K) {
  return Object.keys(exactBaseBrandTheme[key]) as Array<
    keyof ExactBaseBrandTheme[K]
  >
}
export type InputValidState = 'NOT_VALIDATED' | 'VALID' | 'NOT_VALID'

export type BrandTheme = Simplify<
  Theme &
    VariantStyles & {
      branding: BrandConfig
      breakpoints: BreakpointList<string>
      icons: BrandThemeIconMap
      space: [
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number,
      ]
      fontSizes: [
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number,
      ]
      sizes: [
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number,
      ]
      radii: [number, number, number, number]
      colors: BrandColorMap<CSS.Property.Color>
    }
>

export type PartialBrandTheme = Simplify<
  Theme &
    PartialVariantStyles &
    Partial<{
      branding: PartialDeep<BrandConfig>
      breakpoints: BreakpointList<string>
      icons: Partial<BrandThemeIconMap>
      space: [
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number,
      ]
      fontSizes: [
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number,
      ]
      sizes: [
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number,
      ]
      radii: [number, number, number, number]
      colors: Partial<BrandColorMap<CSS.Property.Color>>
    }>
>

export const brandTheme: BrandTheme =
  exactBaseBrandTheme as unknown as BrandTheme
