import { useSuspenseCustomer } from '@jotta/grpc-connect-client/customer'
import { useSuspenseConfig } from '@jotta/grpc-connect-client/config'
import { Outlet, useOutletContext } from 'react-router-dom'
import type { GetConfigResponse } from '@jotta/grpc-connect-openapi/esm/openapi/config/v2/config.v2_pb'
import type { Customer } from '@jotta/grpc-connect-openapi/esm/openapi/customer/customer.v2_pb'

type BizContextType = {
  business: string
  config: GetConfigResponse
  customer: Customer
}

export function useBizContext() {
  return useOutletContext<BizContextType>()
}
export function BizLayout() {
  const customerQuery = useSuspenseCustomer({})
  const { config } = useSuspenseConfig({})
  const customer = customerQuery.data.customer
  const business = customer?.business?.name
  if (!customer) {
    return <div>No customer</div>
  }
  if (!business) {
    return <div>Not a business</div>
  }
  return (
    <div className="px-6 py-4">
      <h1 className="h4">
        {business}
        <small> {customer.username}</small>
      </h1>
      <Outlet
        context={{ business, config, customer } satisfies BizContextType}
      />
    </div>
  )
}
