import { useBrandStore } from '@jotta/ui/useBrandTheme'
import { AppError } from '@jotta/types/AppError'
import { runInAction } from 'mobx'
import type { PropsWithChildren } from 'react'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { PhotoStore } from './PhotoStore'
import { SelectionStore } from './SelectionStore'
import { useUsername } from '@jotta/grpc-connect-client/customer'

interface PhotoContextValue {
  photoStore: PhotoStore
}

export const PhotoContext = createContext<PhotoContextValue | undefined>(
  undefined,
)

function useCreatePhotoStore(photoStore?: PhotoStore) {
  const brandStore = useBrandStore()
  const username = useUsername()
  const [store] = useState(
    () => photoStore || new PhotoStore(brandStore, username),
  )

  useEffect(() => {
    store.username = username
  }, [username, store])

  return store
}

export function PhotoContextProvider({
  children,
  photoStore,
}: PropsWithChildren<Partial<PhotoContextValue>>) {
  const store = useCreatePhotoStore(photoStore)

  return (
    <PhotoContext.Provider
      value={{
        photoStore: store,
      }}
    >
      {children}
    </PhotoContext.Provider>
  )
}

export function usePhotoStore() {
  const photoContext = useContext(PhotoContext)
  if (!photoContext) {
    throw new AppError({
      message: 'usePhotoStore must be used inside PhotoContextProvider',
    })
  }
  return photoContext.photoStore
}
export function usePhotoUploadStore() {
  const store = usePhotoStore()
  return store.upload
}

export function useSelectionStore(
  key: string | undefined = undefined,
  clearOnUnmount = false,
) {
  const photoStore = usePhotoStore()

  const store = useMemo(() => {
    if (key === 'SMART_PHOTO_SEARCH') {
      return new SelectionStore(photoStore)
    }

    return photoStore.selection
  }, [key, photoStore])

  useEffect(() => {
    if (!key) {
      return
    }

    if (key !== 'SMART_PHOTO_SEARCH') {
      if (clearOnUnmount) {
        runInAction(() => {
          photoStore.selection.selection.clear()
        })
      }

      return
    }
    let old: SelectionStore
    let oldValues: readonly string[]

    runInAction(() => {
      old = photoStore.selection
      oldValues = old.values
      old.selection.clear()
      photoStore.selection = store
    })
    return () => {
      runInAction(() => {
        photoStore.selection = old
        if (!clearOnUnmount) {
          photoStore.selection.values = oldValues
        }
      })
    }
  }, [key, store, photoStore, clearOnUnmount])

  return store
}
export function useTimelineStore() {
  const store = usePhotoStore()
  return store.timeline
}
export function useMediaObjectStore() {
  const store = usePhotoStore()
  return store.mediaObjects
}
export function useSearchStore() {
  const store = usePhotoStore()
  return store.search
}
