import Debug from 'debug'
import type { IObservableArray, ObservableMap } from 'mobx'
import {
  action,
  isObservableArray,
  isObservableMap,
  makeAutoObservable,
  ObservableSet,
  values,
} from 'mobx'
import type { ITransformer } from 'mobx-utils'
import type { ChangeEventHandler } from 'react'
const debug = Debug('jotta:collection:selection:store')
export class SelectionStore<T> {
  private _items:
    | {
        type: 'array'
        items: IObservableArray<T>
        getItemId: ITransformer<T, string>
      }
    | {
        type: 'map'
        items: ObservableMap<string, T>
      }
  selected = new ObservableSet<string>()
  private _lastItem = {
    id: '',
    selected: true,
  }
  shiftPressed = false
  constructor(
    items: readonly T[] | IObservableArray<T> | ObservableMap<string, T>,
    private _getItemId?: ITransformer<T, string>,
  ) {
    if (isObservableArray(items)) {
      if (!_getItemId) {
        throw new Error('getItemId argument missing')
      }
      this._items = {
        type: 'array',
        items,
        getItemId: _getItemId,
      }
    } else if (isObservableMap(items)) {
      this._items = {
        type: 'map',
        items,
      }
    } else {
      throw new Error('items must be observable')
    }
    makeAutoObservable(this, {
      toggleSelectAll: action.bound,
    })
  }
  get items() {
    switch (this._items.type) {
      case 'array':
        return this._items.items.map(this._items.getItemId)
      case 'map':
        return [...this._items.items.keys()]
    }
  }

  reset() {
    this.selected.clear()
  }

  get selection() {
    return values(this.selected).filter(id => this.items.includes(id))
  }

  get first() {
    if (this.selection.length) {
      return this.selection[0]
    }
    return undefined
  }

  get last() {
    if (this.selection.length) {
      return this.selection[this.selection.length - 1]
    }
    return undefined
  }

  get total() {
    return this.selection.length
  }

  get hasSelection() {
    return Boolean(this.total)
  }

  get isEmpty() {
    return !this.hasSelection
  }

  get oneSelected() {
    return this.total === 1
  }

  get someSelected() {
    return Boolean(this.total && this.total < this.items.length)
  }
  get allSelected() {
    return this.total === this.items.length
  }

  shiftDown = action('shiftDown', () => {
    debug('shiftDown')
    this.shiftPressed = true
  })
  shiftUp = action('shiftUp', () => {
    debug('shiftUp')
    this.shiftPressed = false
  })

  select = (start: string, end: string = start) => {
    if (start === end) {
      this.selectOne(true, start, true)
    } else {
      this.selectRange(true, start, end)
    }
  }
  unselect = (start: string, end: string = start) => {
    if (start === end) {
      this.selectOne(false, start, true)
    } else {
      this.selectRange(false, start, end)
    }
  }
  get lastSelectedItem() {
    if (this._lastItem.id) {
      return { ...this._lastItem }
    }
    return undefined
  }
  clearLastSelectedItem() {
    this._lastItem.id = ''
  }
  setLastSelectedItem(selected: boolean, id: string) {
    this._lastItem.id = id
    this._lastItem.selected = selected
  }
  selectOne(selected: boolean, id: string, updateLastItem = true) {
    if (updateLastItem) {
      this.setLastSelectedItem(selected, id)
    }
    if (selected) {
      this.selected.add(id)
    } else {
      this.selected.delete(id)
    }
  }
  selectRangeFromLastItem(selected: boolean, id: string) {
    const lastId = this._lastItem.id
    if (lastId) {
      this.selectRange(this._lastItem.selected, id, lastId)
      return
    }
    this.selectOne(selected, id)
  }

  selectRange = (
    selected: boolean,
    firstId: string,
    lastId: string = firstId,
  ) => {
    if (firstId === lastId) {
      if (this.items.includes(firstId)) {
        this.selectOne(selected, firstId)
      }
      return
    }
    let ids: string[] = []
    const index1 = this.items.indexOf(firstId)
    const index2 = this.items.indexOf(lastId)
    if (index1 !== -1 && index2 !== -1) {
      if (index2 > index1) {
        this.setLastSelectedItem(selected, lastId)
        ids = this.items.slice(index1, index2 + 1)
      } else {
        this.setLastSelectedItem(selected, firstId)
        ids = this.items.slice(index2, index1 + 1)
      }
    }
    if (ids) {
      this.selectMany(selected, ids)
    }
  }
  selectMany = (selected: boolean, ids: string[]) => {
    ids.forEach(id => this.selectOne(selected, id, false))
  }
  selectAll() {
    this.selected.replace(this.items)
  }
  selectNone() {
    this.selected.clear()
  }

  isSelected(id: string) {
    return this.selected.has(id)
  }
  onCheckboxChange = action<ChangeEventHandler<HTMLInputElement>>(
    'onCheckboxChange',
    e => {
      const checkbox = e.currentTarget
      const id = checkbox.value
      debug('onCheckboxChange', checkbox, id, checkbox.checked)
      if (this.shiftPressed) {
        this.selectRangeFromLastItem(checkbox.checked, id)
      } else {
        this.selectOne(checkbox.checked, id)
      }
    },
  )

  toggle(id: string) {
    if (this.isSelected(id)) {
      this.selected.delete(id)
    } else {
      this.selected.add(id)
    }
  }

  toggleSelectAll() {
    debug('Toggle select all')
    if (!this.allSelected) {
      this.selectAll()
    } else {
      this.selectNone()
    }
  }
}
