import type { Photos } from '@jotta/types/Photos'
import type { PhotosApi } from '@jotta/types/PhotosApi'
import { excludesFalse } from '@jotta/types/TypeUtils'
import { bisectLeft } from '@jotta/utils/bisect'
import Debug from 'debug'
import {
  action,
  makeAutoObservable,
  observable,
  onBecomeObserved,
  onBecomeUnobserved,
  reaction,
  runInAction,
} from 'mobx'
import { fetchTimeline } from '../api/photosApi'
import type { MediaObjectStore } from './MediaObjectStore'
const debug = Debug('jotta:photos:timelineFetcher')

export class TimelineFetcher {
  private timeline = observable.array<{ id: string; timestamp: string }>([], {
    deep: false,
  })
  private fetchBuffer = this.timeline
  private pageParam: string | undefined = undefined
  private timestamp: Date | null = null
  private keepFetching = false
  private isObserved = false
  private pending: Promise<PhotosApi.Response<Photos.Media[]>> | null = null
  private hidden = false

  /** Timeline has no data and is currently fetching */
  public get isLoading() {
    return Boolean(!this.timestamp)
  }

  /** Timeline is currently fetching */
  public get isFetching() {
    return Boolean(this.pending)
  }

  public get photoIds() {
    return this.timeline.map(({ id }) => id)
  }

  public get photos() {
    return this.timeline
      .map(({ id }) => this.photo.media.get(id))
      .filter((media): media is Photos.Media => Boolean(media))
  }

  private async fetchNextPage() {
    if (!this.isObserved) {
      return
    }

    const limit = this.pageParam ? 2000 : 200

    this.pending = fetchTimeline({
      from: this.pageParam,
      params: { limit, hidden: this.hidden },
    })
    const res = await this.pending

    runInAction(() => {
      this.pending = null

      if (res.result.length) {
        for (const media of res.result) {
          if (!this.photo.media.has(media.id)) {
            this.photo.media.set(media.id, media)
          }
          this.fetchBuffer.push({ id: media.id, timestamp: media.timestamp })
        }
      }

      if (!this.pageParam) {
        this.timestamp = new Date()
      }

      this.pageParam =
        res.result.length === limit
          ? res.result[res.result.length - 1].timestamp
          : undefined

      if (!this.pageParam) {
        this.keepFetching = false

        if (this.backgroundFetch) {
          this.flipBackgroundBuffer()
        }
      }
    })

    return res.result
  }

  private setBackgroundFetch(background: boolean = true) {
    if (background) {
      this.fetchBuffer = observable<{ id: string; timestamp: string }>([])
    } else {
      this.fetchBuffer = this.timeline
    }
  }

  private flipBackgroundBuffer() {
    this.timeline = this.fetchBuffer
  }

  private get backgroundFetch() {
    return this.timeline !== this.fetchBuffer
  }

  private startFetch(pageParam?: string) {
    this.pageParam = pageParam
    this.keepFetching = true

    if (!pageParam) {
      this.fetchBuffer.clear()
    }
  }

  public remove(ids: readonly string[]) {
    if (!this.timeline.length) {
      return
    }

    const timestamps = ids
      .map(id => {
        const media = this.photo.media.get(id)

        if (!media) {
          debug(`Photo with id "${id}" not present in photo store`)
          return undefined
        }

        return media.timestamp
      })
      .filter(excludesFalse)

    for (const timestamp of timestamps) {
      const i = bisectLeft(this.timeline, v =>
        timestamp.localeCompare(v.timestamp),
      )

      if (this.timeline[i] && this.timeline[i].timestamp === timestamp) {
        this.timeline.spliceWithArray(i, 1)
      }
    }
  }

  public insert(ids: readonly string[] | readonly Photos.Media[]) {
    const neverFetched =
      !this.keepFetching && !this.pageParam && !this.timestamp

    if (!ids.length || neverFetched) {
      return
    }

    for (const item of ids) {
      const media = typeof item === 'string' ? this.photo.media.get(item) : item

      if (!media) {
        throw new Error(`Photo with id "${item}" not present in photo store`)
      }

      if (!this.photo.media.has(media.id)) {
        this.photo.media.set(media.id, media)
      }

      // Skip if we haven't fetched to this timestamp yet
      if (
        this.pageParam &&
        media.timestamp.localeCompare(this.pageParam) <= 0
      ) {
        continue
      }

      const i = bisectLeft(this.timeline, m =>
        media.timestamp.localeCompare(m.timestamp),
      )

      if (
        this.timeline.length &&
        i < this.timeline.length &&
        this.timeline[i].id === media.id
      ) {
        continue
      }

      this.timeline.spliceWithArray(i, 0, [
        {
          id: media.id,
          timestamp: media.timestamp,
        },
      ])
    }
  }

  public async update() {
    if (this.keepFetching) {
      return
    }

    if (!this.timeline.length) {
      // fetch full timeline
      this.setBackgroundFetch(false)
      this.startFetch()
    } else if (this.timeline.length && this.pageParam) {
      // Existing, incomplete data
      this.setBackgroundFetch(false)
      this.startFetch(this.timeline[this.timeline.length - 1].timestamp)
    } else if (this.timeline.length && !this.pageParam) {
      // For now, do nothing
    }
  }

  public constructor(
    public photo: MediaObjectStore,
    options?: { hidden: boolean },
  ) {
    if (options?.hidden) {
      this.hidden = true
    }
    makeAutoObservable<this, 'startFetch'>(this, {
      startFetch: action.bound,
    })

    onBecomeObserved(this, 'timeline', () => {
      runInAction(() => (this.isObserved = true))
      this.update()
    })

    onBecomeUnobserved(this, 'timeline', () => {
      runInAction(() => (this.isObserved = false))
    })
    reaction(
      () => [this.keepFetching, this.pending, this.isObserved],
      () => {
        if (!this.isObserved || !this.keepFetching || this.pending) {
          return
        }

        this.fetchNextPage()
      },
    )
  }
}
