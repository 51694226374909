import Debug from 'debug'
import { makeAutoObservable, observable } from 'mobx'
import { useLocalObservable } from 'mobx-react-lite'
import type { RefObject } from 'react'

export function createObservableRef<T>(initialValue: null | T = null) {
  const refValue = observable.box(initialValue, {
    deep: false,
  })
  const ref = makeAutoObservable<RefObject<T>>({
    get current() {
      return refValue.get()
    },
    set current(value) {
      refValue.set(value)
    },
  })
  return ref
}
export type ObservableElementRefCallback<T extends HTMLElement = HTMLElement> =
  (el: T | null, previousEl: T | null) => void

export class ObservableElementRef<T extends HTMLElement = HTMLElement> {
  private _current = null as T | null
  private _log
  constructor(
    name: string,
    private _onElementUpdate?: ObservableElementRefCallback<T>,
  ) {
    this._log = Debug(`jotta:ObservableElementRef:${name}`)

    makeAutoObservable<typeof this, '_current' | '_log' | '_onElementUpdate'>(
      this,
      {
        _current: observable.ref,
        _log: false,
        _onElementUpdate: false,
      },
    )
  }
  public get current() {
    return this._current
  }
  public set current(el) {
    this._log('ref updated', String(el))
    this._onElementUpdate?.(el, this.current)
    this._current = el
  }
}
export function useObservableElementRef<T extends HTMLElement = HTMLElement>(
  debugName: string,
  onElementUpdate?: (el: T | null, previousEl: T | null) => void,
) {
  const ref = useLocalObservable(
    () => new ObservableElementRef(debugName, onElementUpdate),
  )
  return ref
}
