import type { GetConfigResponse } from '@jotta/grpc-connect-openapi/esm/openapi/config/v2/config.v2_pb'
import { getCustomer } from '@jotta/grpc-js-client/customerService'
import { emptyCustomer } from '@jotta/grpc-js-client/mockCustomer'
import type { Customer } from '@jotta/grpc-web/no/jotta/openapi/customer/customer.v2_pb'
import { AppError, handleUnknownError } from '@jotta/types/AppError'
import { useLayoutEffect } from 'react'
import { createQuery } from 'react-query-kit'
import { settingsStore } from '../stores'
import { useUsername } from '@jotta/grpc-connect-client/customer'
import { queryClient } from '@jotta/query'
import { getConfig } from '@jotta/grpc-connect-client/config'

export interface AuthCustomerConfigResponse {
  customer: Customer.AsObject
  config: GetConfigResponse
}

const useCustomerConfigQuery = createQuery<
  AuthCustomerConfigResponse,
  { username?: string },
  AppError
>({
  queryKey: ['customer'],
  fetcher: async ({ username }) => {
    try {
      if (username === '') {
        const config = await getConfig()
        return {
          config,
          customer: emptyCustomer,
        }
      }
      const [customer, config] = await Promise.all([getCustomer(), getConfig()])
      if (!customer) {
        throw new AppError({
          message: 'Customer loaded, but response was empty',
        })
      }
      const result: AuthCustomerConfigResponse = {
        config,
        customer: customer.toObject(),
      }
      return result
    } catch (error) {
      throw handleUnknownError(error)
    }
  },
})

export function invalidateCustomerConfig() {
  return queryClient.invalidateQueries({
    queryKey: [useCustomerConfigQuery.getKey()],
  })
}

export function useCustomerConfig() {
  const username = useUsername()
  return useCustomerConfigQuery({ variables: { username } })
}

export function useSettingsStore() {
  const { data, error, isFetched } = useCustomerConfig()

  useLayoutEffect(() => {
    if (isFetched) {
      settingsStore.customerConfigStore.handleCustomerConfigResponse(
        data,
        error,
      )
    }
  }, [data, error, isFetched])

  return settingsStore
}
