import { Box } from '@jotta/ui/Box'
import { getAlertListStore } from '@jotta/ui/AlertStore'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import { ProgressBar } from '@jotta/ui/ProgressBar'
import { RadixDialog } from '@jotta/ui/RadixDialog'
import type { RadixModalProps } from '@jotta/ui/RadixModal'
import { RadixModal } from '@jotta/ui/RadixModal'
import { getFilenameFromPath } from '@jotta/utils/pathUtils'
import { Plural, t } from '@lingui/macro'
import { useMutation } from '@tanstack/react-query'
import { observer, useLocalObservable } from 'mobx-react-lite'

export interface PermanentlyDeleteDialogProps extends RadixModalProps {
  onConfirm: (
    progressUpdate: (status: { completed: number; total: number }) => void,
  ) => Promise<void>
  paths: string[] | 'emptyTrash'
}
export const PermanentlyDeleteDialog = observer<PermanentlyDeleteDialogProps>(
  function PermanentlyDeleteDialog({ onClose, onConfirm, paths, ...props }) {
    const state = useLocalObservable(() => ({
      paths: [...paths],
      completed: 0,
      complete(num: number) {
        this.completed = num
      },
      get count() {
        return this.paths.length
      },
      get source() {
        return this.count === 1 ? getFilenameFromPath(state.paths[0]) : ''
      },
    }))

    const { count: count, completed, source } = state

    const deleteMutation = useMutation({
      mutationFn: onConfirm,
      onSuccess() {
        getAlertListStore().showAlert({
          message: state.source
            ? t`${source} was permanently deleted!`
            : t`${count} files or folders were permanently deleted!`,
          severity: 'info',
          testid: 'PermanentlyDeleteSuccessAlert',
        })
        if (onClose) {
          onClose(false)
        }
      },
    })

    return (
      <RadixModal onClose={onClose} {...props}>
        <RadixDialog
          data-testid="PermanentlyDeleteDialog"
          onClose={onClose}
          title={count > 1 ? t`Permanently delete ${count} files` : source}
          smallTitle={t`Permanently delete`}
          buttons={[
            {
              action: 'close',
              label: t`Cancel`,
              testId: 'PermanentlyDeleteDialogCancelButton',
            },
            {
              action: () =>
                deleteMutation.mutateAsync(({ completed, total }) => {
                  state.complete(completed)
                }),
              label: t`Delete forever`,
              variant: 'buttons.error',
              testId: 'PermanentlyDeleteDialogDeleteButton',
            },
          ]}
        >
          <Box sx={{ position: 'relative', flex: '1 1 auto' }}>
            <LoadingOverlay open={deleteMutation.isPending} position="absolute">
              <ProgressBar completed={completed} total={count} />
            </LoadingOverlay>
          </Box>
          <Plural
            value={count}
            one="Are you sure you want to remove this file from your trash? This operation can not be undone."
            other="Are you sure you want to remove these files from your trash? This operation can not be undone."
          />
        </RadixDialog>
      </RadixModal>
    )
  },
)
