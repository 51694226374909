import type { FileRouteParams } from '@jotta/types/schemas/FileRouteSchema'
import {
  FileRouteContextSchema,
  FileRouteSchema,
  FileRouteSearchQuerySchema,
  FileRouteSortBySchema,
  FileRouteViewModeSchema,
} from '@jotta/types/schemas/FileRouteSchema'
import { safeDecodeURIComponent } from '@jotta/utils/pathUtils'
import { isString } from '@jotta/utils/typeAssertions'
import moize from 'moize'
import { mapValues } from 'radash'
import type { Params } from 'react-router-dom'
import { matchPath, useParams } from 'react-router-dom'
import { z } from 'zod'
import { sortByCookie, viewModeCookie } from './cookies'

function reEncodeParams<T extends string>(
  params: Params<T> | undefined,
): typeof params {
  if (params) {
    return mapValues(params, v => (v ? safeDecodeURIComponent(v) : v))
  }
  return undefined
}
function matchFileRouteSearchPath(path: string) {
  const searchMatch = matchPath(
    '/web/search/:viewMode/:sortBy/:query/*',
    path,
  )?.params
  if (searchMatch) {
    return {
      context: 'search',
      ...reEncodeParams(searchMatch),
    }
  }
}
function strictMatchFileRoutePath(path: string) {
  const params = matchPath('/web/:context/:viewMode/:sortBy/*', path)?.params
  return matchFileRouteSearchPath(path) || reEncodeParams(params)
}
function partialMatchFileRoutePath(path: string) {
  return (
    matchFileRouteSearchPath(path) ||
    reEncodeParams(
      matchPath('/web/:context/:viewMode/:sortBy/*', path)?.params,
    ) ||
    reEncodeParams(matchPath('/web/:context/:viewMode', path)?.params) ||
    reEncodeParams(matchPath('/web/:context', path)?.params)
  )
}
export const StrictFileRouteSchema = z.object({
  context: FileRouteContextSchema,
  viewMode: FileRouteViewModeSchema,
  sortBy: FileRouteSortBySchema,
  query: FileRouteSearchQuerySchema.optional(),
  '*': z.string().optional().default(''),
})

const defaultFileRouteSchema: typeof StrictFileRouteSchema._type = {
  context: 'sync',
  sortBy: 'name',
  viewMode: 'thumbs',
  query: undefined,
  '*': '',
}

export const makeFileRouteCatchSchema = (
  defaults: Partial<typeof defaultFileRouteSchema>,
) => {
  const catchObj = Object.assign({}, defaultFileRouteSchema, defaults)

  return z
    .object({
      context: FileRouteContextSchema.catch(catchObj.context).default(
        catchObj.context,
      ),
      viewMode: FileRouteViewModeSchema.catch(catchObj.viewMode).default(
        catchObj.viewMode,
      ),
      sortBy: FileRouteSortBySchema.catch(catchObj.sortBy).default(
        catchObj.sortBy,
      ),
      query: FileRouteSearchQuerySchema.optional().catch(catchObj.query),
      '*': z.string().optional().catch(catchObj['*']).default(catchObj['*']),
    })
    .catch(catchObj)
    .transform(v => ({
      ...v,
      context: v.query !== undefined ? 'search' : v.context,
    }))
}

/**
 * Parse string to FileRouteParams
 * Modes:
 * - 'strict': Throw if invalid
 * - 'partial': Allow partial matches such as /web/:context and fill in defaults for the rest
 * - 'catch': Never throws, any invalid values are replaced with defauks
 * @param value Value to parse
 * @param mode See above
 */
export const matchFileRoutePath = moize(function matchFileRoutePath(
  value: string,
  mode: 'strict' | 'partial' | 'catch',
): Partial<Record<keyof FileRouteParams, string | undefined>> | undefined {
  switch (mode) {
    case 'strict':
      return strictMatchFileRoutePath(value)
    case 'partial':
      return partialMatchFileRoutePath(value)
    case 'catch':
      return partialMatchFileRoutePath(value)
  }
})

/**
 * Parse value to valid FileRouteParams
 * Modes:
 * - 'strict': Throw if invalid
 * - 'partial': Allow partial matches such as /web/:context and fill in defaults for the rest
 * - 'catch': Never throws, any invalid values are replaced with defauks
 * @param value Value to parse
 * @param mode See above
 */
export const getFileRouteParams = moize.shallow(function getFileRouteParams(
  value: unknown,
  mode: 'strict' | 'partial' | 'catch',
): FileRouteParams {
  const params = isString(value) ? matchFileRoutePath(value, mode) : value
  switch (mode) {
    case 'strict':
      return StrictFileRouteSchema.parse(params)
    case 'partial':
      return FileRouteSchema.parse(params)
    case 'catch':
      return makeFileRouteCatchSchema({
        viewMode: viewModeCookie.get(),
        sortBy: sortByCookie.get(),
      }).parse(params)
  }
})

export const isFileRouteParams = moize.shallow(function isFileRouteParams(
  value: unknown,
): value is FileRouteParams {
  try {
    return Boolean(StrictFileRouteSchema.parse(value))
  } catch (error) {
    return false
  }
})

export function useFileRouteParams() {
  const params = useParams()

  return getFileRouteParams(params, 'catch')
}
