import { Box } from '@jotta/ui/Box'
import { useLowQualityImagePlaceholder } from '@jotta/hooks'
import type { Photos } from '@jotta/types/Photos'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import { ErrorBoundary } from '@sentry/react'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import { Suspense, lazy } from 'react'
import { LivePhoto } from './LivePhoto'
import { FacesInPhoto } from '../../people/internal/components/FacesInPhoto/FacesInPhoto'
const debug = Debug('jotta:photos:MediaView')
const VideoPlayer = lazy(
  () => import(/* webpackChunkName: "VideoPlayer" */ '@jotta/video'),
)

export interface MediaViewProps {
  item: Photos.Media
  active?: boolean
  slideShowMode?: boolean
  setPlaying?: (active: boolean) => void
  width?: number
  height?: number
}

export const MediaView = observer<MediaViewProps>(function MediaView({
  item,
  active = false,
  slideShowMode,
  // goToNext,
  setPlaying,
  width,
  height,
}) {
  const thumbnailSrc = useLowQualityImagePlaceholder(
    `${item.thumbnail_url}.l`,
    `${item.thumbnail_url}.s`,
  )

  // const onVideoEndedHandler = () => {
  //   if (slideShowMode && goToNext) {
  //     goToNext()
  //   }
  // }

  if (item.content === 'video') {
    return (
      <div className="flex size-full flex-auto py-16">
        <ErrorBoundary
          beforeCapture={scope => {
            scope.setTag('reactComponent', 'VideoPlayer')
            scope.setTag('jottaModule', 'MediaCarousel')
          }}
          onError={(error, componentStack, eventId) => {
            debug(error, componentStack, eventId)
          }}
        >
          <Suspense
            fallback={<LoadingOverlay open position="absolute" darkMode />}
          >
            <VideoPlayer
              active={active}
              playListURL={item.video_url}
              videoURL={item.file_url}
              poster={thumbnailSrc}
              autoPlay={slideShowMode}
              name={item.filename}
              rewindOnEnd
              playerProps={{
                onPlay: () => setPlaying && setPlaying(true),
                onPause: () => setPlaying && setPlaying(false),
                onEnded: () => setPlaying && setPlaying(false),
              }}
            />
          </Suspense>
        </ErrorBoundary>
      </div>
    )
  } else if (item.content === 'image' && item.live_photo_url && active) {
    return (
      <div
        className="relative size-full bg-contain bg-center bg-no-repeat object-contain object-center"
        style={{
          backgroundImage: `url("${thumbnailSrc}")`,
        }}
      >
        <Box variant="layout.mediaItemBackground" />
        <LivePhoto livePhoto={item.live_photo_url} thumb={thumbnailSrc} />
      </div>
    )
  } else {
    return (
      <div className="relative size-full bg-no-repeat object-contain object-center">
        <Box variant="layout.mediaItemBackground" />
        <FacesInPhoto md5={item.md5} aspect={item.aspect}>
          <img alt="" src={thumbnailSrc} className="size-full" />
        </FacesInPhoto>
      </div>
    )
  }
})
