import { isString } from 'radash'
import type { BrandKey, BrandName } from './generated/brands'
import {
  brandAliases,
  brandKeys,
  brandNames,
  brands,
  brandsByName,
  brandKeyToAliasMap,
} from './generated/brands'

export * from './generated/brands'

export const themeIds = [
  'comhem',
  'elkjop',
  'halebop',
  'jottacloud',
  'mediamarkt',
  'obos',
  'onlime',
  'saturn',
  'teliacloud',
  'test',
  'tsky',
] as const satisfies readonly BrandAlias[]
export type ThemeId = (typeof themeIds)[number]
export const brandAliasToThemeIdMap = {
  comhem: 'comhem',
  elgiganten: 'elkjop',
  elkjop: 'elkjop',
  elko: 'elkjop',
  gigantti: 'elkjop',
  halebop: 'halebop',
  jottacloud: 'jottacloud',
  mediamarkt: 'mediamarkt',
  obos: 'obos',
  onlime: 'onlime',
  saturn: 'saturn',
  teliacloud: 'teliacloud',
  teliasky: 'teliacloud',
  test: 'test',
  tsky: 'tsky',
} as const satisfies Record<BrandAlias, ThemeId>
export function getThemeIdFromBrandAlias<T extends BrandAlias>(
  value: T,
): (typeof brandAliasToThemeIdMap)[T] {
  return brandAliasToThemeIdMap[value]
}

export type BrandKeyToIdMap = typeof brandKeyToAliasMap
export type BrandAliasToKeyMap = {
  readonly [K in BrandKey as BrandKeyToIdMap[K]]: K
}
export const brandAliasToKeyMap: BrandAliasToKeyMap = Object.fromEntries(
  brandKeys.map(k => [brandKeyToAliasMap[k], k] as const),
) as unknown as BrandAliasToKeyMap
export type BrandAlias = (typeof brandKeyToAliasMap)[BrandKey]
export const brandAliasRegex = new RegExp(`^(${brandAliases.join('|')})$`)
export const brandKeyRegex = new RegExp(`^(${brandKeys.join('|')})$`)

export const elkjopIds: readonly BrandAlias[] = [
  'elgiganten',
  'elkjop',
  'elko',
  'gigantti',
] as const
export const elkjopKeys: readonly BrandKey[] = [
  'ELGIGANTEN',
  'ELKJOP',
  'ELKO',
  'GIGANTTI',
] as const

export function isBrandAlias(value: unknown): value is BrandAlias {
  return brandAliases.includes(value as any)
}
export function isBrandName(value: unknown): value is BrandName {
  return brandNames.includes(value as any)
}
export function isBrandKey(value: unknown): value is BrandKey {
  return brandKeys.includes(value as any)
}
export function getBrandKey(value: BrandAliasentifier): BrandKey {
  if (isBrandKey(value)) {
    return value
  }
  if (isBrandAlias(value)) {
    return brandAliasToKeyMap[value]
  }
  return brandsByName[value].code
}
export function getBrandKeyFromUnknown(
  value: unknown,
  retry = true,
): BrandKey | undefined {
  if (isBrandKey(value)) {
    return value
  }
  if (isBrandAlias(value)) {
    return brandAliasToKeyMap[value]
  }
  if (isBrandName(value)) {
    return brandsByName[value].code
  }
  if (isString(value) && retry) {
    return (
      getBrandKeyFromUnknown(value.toUpperCase(), false) ||
      getBrandKeyFromUnknown(value.toLowerCase(), false)
    )
  }
}
export type BrandAliasentifier = BrandAlias | BrandName | BrandKey

export function getBrandAlias(value: BrandAliasentifier): BrandAlias {
  if (isBrandAlias(value)) {
    return value
  }
  if (isBrandKey(value)) {
    return brandKeyToAliasMap[value]
  }
  return brandKeyToAliasMap[brandsByName[value].code]
}

export function getBrandAliasFromUnknown(
  value: unknown,
  retry = true,
): BrandAlias | undefined {
  if (isBrandAlias(value)) {
    return value
  }
  if (isBrandKey(value)) {
    return brandKeyToAliasMap[value]
  }
  if (isBrandName(value)) {
    return brandKeyToAliasMap[brandsByName[value].code]
  }
  if (isString(value) && retry) {
    return (
      getBrandAliasFromUnknown(value.toUpperCase(), false) ||
      getBrandAliasFromUnknown(value.toLowerCase(), false)
    )
  }
}
export function getBrandName(value: BrandAliasentifier): BrandName {
  if (isBrandName(value)) {
    return value
  }
  if (isBrandKey(value)) {
    return brands[value].name
  }
  return brands[getBrandKey(value)].name
}

export function getBrandNameFromUnknown(
  value: unknown,
  retry = true,
): BrandName | undefined {
  if (isBrandName(value)) {
    return value
  }
  if (isBrandKey(value)) {
    return brands[value].name
  }
  if (isBrandAlias(value)) {
    return brands[getBrandKey(value)].name
  }
  if (isString(value) && retry) {
    return (
      getBrandNameFromUnknown(value.toUpperCase(), false) ||
      getBrandNameFromUnknown(value.toLowerCase(), false)
    )
  }
}
