/** GRPC */
import { useSuspenseQuery } from '@connectrpc/connect-query'
import { getPerson } from '@jotta/grpc-connect-openapi/peopleQuery'

/** Internal */
import { usePersonLoaderData } from '../../routes/PersonRoute'
import { PersonImage } from '../PersonList/PersonImage'
import { EditPersonForm } from './EditPersonForm'
import { ErrorBoundary } from '@sentry/react'
import { t } from '@lingui/macro'

export function focusRef(el: HTMLInputElement | null) {
  if (el) {
    el.focus()
  }
}
export function PersonHeader() {
  const { id } = usePersonLoaderData()
  const query = useSuspenseQuery(getPerson, {
    id,
  })
  const person = query.data?.person

  return (
    <div className="flex h-full gap-4">
      {!!person && (
        <div className="aspect-square overflow-hidden rounded-lg">
          <PersonImage person={person} className="size-full" />
        </div>
      )}
      <ErrorBoundary
        fallback={errorData => (
          <div className="self-center">{person?.name || t`Unnamed person`}</div>
        )}
      >
        {!!person && <EditPersonForm person={person} />}
      </ErrorBoundary>
    </div>
  )
}
