import { Box } from '@jotta/ui/Box'
import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { Button } from '@jotta/ui/Button'
import { Headline1 } from '@jotta/ui/Headline'
import { Paragraph } from '@jotta/ui/Text'
import { Trans } from '@lingui/macro'
import { observer } from 'mobx-react-lite'

import Confetti from 'react-confetti'
import { useWindowSize } from 'react-use'
import { waitlistStore } from '../../store/WaitlistStore'

export interface WaitlistBannerOnWaitlistProps {
  handleDismiss: () => void
  handleExplodeConfetti: (event: React.MouseEvent<HTMLButtonElement>) => void
  confettiActive: boolean
  onConfettiComplete: () => void
  buttonCoords: {
    x: number
    y: number
    w: number
    h: number
  }
}

export const WaitlistBannerOnWaitlist: React.FC<WaitlistBannerOnWaitlistProps> =
  observer<WaitlistBannerOnWaitlistProps>(function WaitlistBannerOnWaitlist({
    handleDismiss,
    handleExplodeConfetti,
    confettiActive,
    buttonCoords,
    onConfettiComplete,
  }) {
    const { waitlistStatus } = waitlistStore
    const { width, height } = useWindowSize()

    return (
      <Box variant="layout.smartPhotoBannerContainer">
        <Headline1 variant="styles.smartPhotoSearchHeading">
          <Trans id="Yay, you're on the waitlist!" />
        </Headline1>
        <Paragraph variant="styles.smartPhotoSearchParagraph">
          <Trans id="smartPhotoSearch.waitlistDescription">
            We will process your photos as soon as possible, we'll let you know
            when it's ready!
          </Trans>
        </Paragraph>
        <Box>
          <Button
            variant="buttons.smartSearchTextButton"
            sx={{ p: 0, alignSelf: 'flex-start' }}
            type="submit"
            onClick={handleExplodeConfetti}
          >
            <span sx={{ mr: 2 }}>🎉</span>
            <span>
              <Trans>
                You are{' '}
                <strong>#{waitlistStatus?.getWaitlistPosition()}</strong> on the
                waitlist
              </Trans>
            </span>
          </Button>
          {confettiActive && (
            <Confetti
              width={width}
              height={height}
              confettiSource={buttonCoords}
              colors={[
                '#9C61E8',
                '#FF79FF',
                '#764CAC',
                '#A65AB6',
                '#6053BD',
                '#FFC3F7',
              ]}
              recycle={false}
              tweenDuration={3000}
              onConfettiComplete={onConfettiComplete}
              style={{
                position: 'fixed',
                left: 0,
                top: 0,
                zIndex: 10000,
              }}
            />
          )}
        </Box>

        <Button
          sx={{
            variant: 'buttons.closeIconButton',
            position: 'absolute',
            top: 1,
            right: 1,
            color: 'primary',
          }}
          onClick={handleDismiss}
        >
          <PlainBrandIcon icon="SvgClose" />
        </Button>
      </Box>
    )
  })
