import {
  checkPasswordReset,
  resetPassword,
} from '@jotta/grpc-connect-openapi/esm/openapi/login/v1/login.v1-LoginService_connectquery'
import { useQuery, useMutation } from '@connectrpc/connect-query'

export function useCheckPasswordReset(resetKey?: string) {
  return useQuery(
    checkPasswordReset,
    { resetKey },
    { enabled: Boolean(resetKey) },
  )
}

export function useResetPasswordMutation() {
  return useMutation(resetPassword)
}
