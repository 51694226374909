import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import type { FileActionContext } from '@jotta/types/FileActions'
import { plural, t, Trans } from '@lingui/macro'
import clsx from 'clsx'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useFileRouteParams } from '../FileRoute/utils/getFileRouteParams'
import type { FolderStore } from '../Folder/FolderStore'
import styles from './PathItemRow.module.scss'
const debug = Debug('jotta:files:PathItemRow')
export const PathItemRowHeader = observer<{
  store: FolderStore
}>(function PathItemRowHeader({ store }) {
  const { context } = useFileRouteParams()
  const isSearch = context === 'search'
  const {
    actions,
    selection,
    selectedActions,
    hasSelection: checked,
    someSelected: indeterminate,
  } = store
  const selectedFiles = store.selectedChildren.filter(
    child => child.isFile,
  ).length
  const selectedFolders = store.selectedChildren.filter(
    child => child.isFolder,
  ).length

  let selectionText = ''

  if (selectedFiles + selectedFolders === 1) {
    selectionText = store.selectedChildren[0].name
  } else {
    if (selectedFolders) {
      const count = selectedFolders
      selectionText = plural(count, { one: '# folder', other: '# folders' })
    }
    if (selectedFiles && selectedFolders) {
      selectionText += ` ${t`and`} `
    }
    if (selectedFiles) {
      const count = selectedFiles
      selectionText += plural(count, { one: '# file', other: '# files' })
    }
  }

  const selectionActionContext: FileActionContext = store.oneSelected
    ? 'FOLDER_LIST'
    : 'SELECTION'
  const selectionActionPath = store.selectedChildren.at(0)?.path || ''

  useEffect(() => {
    selection.setIndeterminate(indeterminate)
  }, [selection, indeterminate])

  return (
    <div
      className={clsx(styles.row, styles.header, 'viewmodelist')}
      data-testid="FolderViewHeader"
      data-header
    >
      <label className={styles.checkbox}>
        <input
          ref={selection.headerInputRef}
          type="checkbox"
          // disabled={store.children.isEmpty}
          checked={checked}
          onChange={selection.toggleSelectAll}
        />
      </label>

      {!store.selectedChildren.length ? (
        <div className={styles.rowData}>
          <div className={styles.icon} />
          <div
            data-testid="FolderSortByName"
            aria-selected={store.route.sortColumn === 'name'}
            className={clsx(styles.link, styles.sortLink, {
              [styles.desc]: store.route.sortDescending,
              [styles.activeSortLink]: store.route.sortColumn === 'name',
            })}
          >
            <Link to={store.route.sortLinks.name.to} replace={isSearch}>
              <span>
                <Trans>Name</Trans>
              </span>
              <PlainBrandIcon icon="SvgSmallArrowDown" />
            </Link>
          </div>

          <div
            data-testid="FolderSortByModified"
            aria-selected={store.route.sortColumn === 'modified'}
            className={clsx(styles.dateHeader, styles.sortLink, {
              [styles.desc]: store.route.sortDescending,
              [styles.activeSortLink]: store.route.sortColumn === 'modified',
            })}
          >
            <Link to={store.route.sortLinks.modified.to} replace={isSearch}>
              <span>
                <Trans>Modified</Trans>
              </span>
              <PlainBrandIcon icon="SvgSmallArrowDown" />
            </Link>
          </div>
          <div
            data-testid="FolderSortBySize"
            aria-selected={store.route.sortColumn === 'size'}
            className={clsx(styles.sizeHeader, styles.sortLink, {
              [styles.desc]: store.route.sortDescending,
              [styles.activeSortLink]: store.route.sortColumn === 'size',
            })}
          >
            <Link to={store.route.sortLinks.size.to} replace={isSearch}>
              <span>
                <Trans>Size</Trans>
              </span>
              <PlainBrandIcon icon="SvgSmallArrowDown" />
            </Link>
          </div>
          <div className={styles.type}>
            <Trans>Type</Trans>
          </div>
        </div>
      ) : (
        <div className={styles.selectionLink}>
          <span className={styles.selectionLinkLabel} title={selectionText}>
            {selectionText}
          </span>

          <div className={styles.selectionLinkButtonContainer}>
            {selectedActions.map(a => (
              <button
                key={a.name}
                data-select-action={a.action}
                className={styles.selectionLinkButton}
                onClick={() => {
                  actions.dispatch(
                    a.action,
                    selectionActionContext,
                    selectionActionPath,
                  )
                }}
                onKeyDown={e => {
                  if (e.nativeEvent.key === ' ') {
                    actions.dispatch(
                      a.action,
                      selectionActionContext,
                      selectionActionPath,
                    )
                  }
                }}
              >
                <PlainBrandIcon icon={a.icon} />
                <span>{a.name}</span>
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  )
})
