import type { FileRouteParams } from '@jotta/types/schemas/FileRouteSchema'
import { createPath } from '@jotta/utils/pathUtils'
import moize from 'moize'

export const routeParamsToRoutePath = moize.shallow(
  function fileRoutePathFromParams({
    query = 'undefined',
    context,
    '*': splat,
    sortBy,
    viewMode,
  }: FileRouteParams) {
    if (context === 'search') {
      return createPath('/web', context, viewMode, sortBy, query, splat)
    }
    return createPath('/web', context, viewMode, sortBy, splat)
  },
  {
    maxSize: 100,
  },
)
