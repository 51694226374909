import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { t } from '@lingui/macro'
import { DialogTrigger } from '@radix-ui/react-dialog'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { LoginToContinueDialog } from '../Album/Dialogs/LoginToContinueDialog'
import type { PhotoActionHandlerActionProps } from './PhotoActionHandlers'

export const ActionAddPhotosAnonymous = observer<
  PhotoActionHandlerActionProps<'ADD_PHOTOS_ANONYMOUS'>
>(function ActionAddPhotosAnonymous({
  action,
  buttonVariant = 'buttons.albumActionButton',
  onActionDialogClose,
  shareId,
}) {
  const [open, setOpen] = useState(false)

  return (
    <LoginToContinueDialog
      title={t`Log in to add your own photos`}
      url={`/share/${shareId}/addphotos`}
      open={open}
      onClose={open => {
        setOpen(open)
        if (!open && onActionDialogClose) {
          onActionDialogClose(action.actionType)
        }
      }}
      triggerElement={
        <DialogTrigger
          aria-label={action.label}
          data-testid={action.testid}
          key={action.actionType}
          sx={{
            variant: buttonVariant,
          }}
        >
          <PlainBrandIcon icon={action.icon} />
          <span>{action.label}</span>
        </DialogTrigger>
      }
    ></LoginToContinueDialog>
  )
})
