import type { PathItemComparator } from '@jotta/types/Files'
import { isFolder } from '../fileService'

export const sortPathItemByName: PathItemComparator = (a, b, dir = 'ASC') => {
  if (isFolder(a) && !isFolder(b)) {
    return -1
  }
  if (isFolder(b) && !isFolder(a)) {
    return 1
  }
  if (dir === 'ASC') {
    return a.name.localeCompare(b.name)
  }

  return b.name.localeCompare(a.name)
}
export const sortPathItemByDate: PathItemComparator = (a, b, dir = 'ASC') => {
  if (isFolder(a) && !isFolder(b)) {
    return 1
  }
  if (isFolder(b) && !isFolder(a)) {
    return -1
  }
  if (dir === 'ASC') {
    return a.modifiedAtMillis - b.modifiedAtMillis
  }
  return b.modifiedAtMillis - a.modifiedAtMillis
}
export const sortPathItemBySize: PathItemComparator = (a, b, dir = 'ASC') => {
  if (isFolder(a) && !isFolder(b)) {
    return 1
  }
  if (isFolder(b) && !isFolder(a)) {
    return -1
  }
  if (!a.size && !b.size) {
    return sortPathItemByName(a, b) - 10
  }
  if (dir === 'ASC') {
    return a.size - b.size
  }
  return b.size - a.size
}
