import {
  CommentRef,
  ContentRef,
  FileOrFolderRef,
  PhotoAlbumRef,
  PhotoRef,
} from '@jotta/grpc-web/no/jotta/openapi/ref_pb'
import { Base64 } from 'js-base64'
import { Result } from 'neverthrow'

/**
 * Get ContentRef from a Base64 encoded string
 * @param ref Content ref
 * @returns Base64 encoded ref
 */
export const fromEncodedContentRef: (
  encodedContentRef: string,
) => Result<ContentRef, Error> = Result.fromThrowable(
  (r: string) => {
    if (!Base64.isValid(r)) {
      throw new Error('invalid base64 format')
    }
    const byteArray = Base64.toUint8Array(r)
    return ContentRef.deserializeBinary(byteArray)
  },
  unknown => (unknown instanceof Error ? unknown : new Error(`${unknown}`)),
)

/**
 * Get base64 encoded string for a ContentRef
 * @param ref Content ref
 * @returns Base64 encoded ref
 */
export function toEncodedContentRef(ref: ContentRef) {
  return Base64.fromUint8Array(ref.serializeBinary())
}

/**
 * Create ContentRef for a file or folder path
 * @param path Path to file or folder
 * @returns ConentRef
 */
export function fileOrFolderPathToEncodedContentRef(path: string) {
  const ref = new ContentRef()
  const fileRef = new FileOrFolderRef()
  fileRef.setPath(path)
  ref.setFileOrFolder(fileRef)
  return ref
}
export function photoToEncodedContentRef(id: string) {
  const ref = new ContentRef()
  const photoRef = new PhotoRef()
  photoRef.setChecksum(id)

  ref.setPhoto(photoRef)
  return ref
}
export function photoAlbumToEncodedContentRef(
  id: string,
  shareId?: string,
  commentId?: string,
) {
  const ref = new ContentRef()
  const albumRef = new PhotoAlbumRef()
  albumRef.setId(id)
  if (shareId) {
    albumRef.setShareId(shareId)
  }
  if (commentId) {
    const commentRef = new CommentRef()
    commentRef.setId(commentId)
    commentRef.setPhotoAlbum(albumRef)
    ref.setComment(commentRef)
    return ref
  }
  ref.setPhotoAlbum(albumRef)
  return ref
}

export function toUrl(
  encodedContentRef: string,
  protocol: string = window.location.protocol,
  host: string = window.location.host,
) {
  return `${protocol}//${host}/r/${encodedContentRef}`
}

export function openEncodedContentRef(encodedContentRef: string) {
  window.location.replace(toUrl(encodedContentRef))
}
