import { useEffect, useState } from 'react'

export function useAnimateHiddenClass(hidden?: boolean): string {
  const [initialHidden, setInitialHidden] = useState(hidden)
  useEffect(() => {
    if (hidden !== initialHidden) {
      setInitialHidden(undefined)
    }
  }, [hidden, initialHidden])
  if (initialHidden !== undefined) {
    // prevent animations in initial state
    return initialHidden ? 'hidden' : ''
  }
  return hidden === undefined
    ? ''
    : `fill-mode-forwards ${hidden ? 'animate-hide' : 'animate-unhide'}`
}
