import { forwardRef } from 'react'
import type { ResponsiveStyleValue } from 'theme-ui'
import { Grid as UiGrid } from 'theme-ui'
import type { BoxPropsAs } from '../Box/Box'
export type GridProps = BoxPropsAs<
  'div',
  {
    /**
     * Minimum width of child elements
     */
    width?: ResponsiveStyleValue<string | number>
    /**
     * 	Number of columns to use for the layout (cannot be used in conjunction with the width prop)
     */
    columns?: ResponsiveStyleValue<string | number>
    /**
     * Space between child elements
     */
    gap?: ResponsiveStyleValue<string | number>
  },
  'all'
>

export const Grid = forwardRef<HTMLSpanElement, GridProps>(function Grid(
  { ...props },
  ref,
) {
  const Component = UiGrid as any
  return <Component ref={ref} {...props} />
})
