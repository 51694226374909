import { Box } from '@jotta/ui/Box'
import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { Button } from '@jotta/ui/Button'
import { Headline1 } from '@jotta/ui/Headline'
import { Trans } from '@lingui/macro'
import { observer } from 'mobx-react-lite'
import { useOnLearnMoreWaitlist } from '../../routes/useOnLearnMore'

export interface SmartPhotoSearchAccessBannerProps {
  handleDismiss: () => void
  handleTrySmartSearch: () => void
}

export const SmartPhotoSearchAccessBanner: React.FC<SmartPhotoSearchAccessBannerProps> =
  observer(function SmartPhotoSearchAccessBanner({
    handleDismiss,
    handleTrySmartSearch,
  }) {
    const { onLearnMoreWaitlist } = useOnLearnMoreWaitlist()
    return (
      <Box
        variant="layout.smartPhotoBannerContainer"
        data-testid="SmartPhotoSearchBanner"
      >
        <Headline1 variant="styles.smartPhotoSearchHeading">
          <Trans>AI powered Photo Search is here!</Trans>
        </Headline1>
        <Box
          sx={{
            gap: 3,
            display: 'flex',
            flexDirection: ['column', 'column', 'row'],
            mt: 4,
          }}
        >
          <Button
            variant="buttons.smartSearchLightButton"
            type="submit"
            icon="SvgSearch"
            onClick={handleTrySmartSearch}
          >
            <Trans>Try our AI-Powered Photo Search</Trans>✨
          </Button>

          <Button
            variant="buttons.smartSearchTextButton"
            sx={{
              justifyContent: 'flex-start',
            }}
            onClick={onLearnMoreWaitlist}
          >
            <Trans>Learn more</Trans>
          </Button>
        </Box>
        <Button
          sx={{
            variant: 'buttons.closeIconButton',
            position: 'absolute',
            top: 1,
            right: 1,
            color: 'primary',
          }}
          onClick={handleDismiss}
          data-testid="SmartPhotoSearchBannerClose"
        >
          <PlainBrandIcon icon="SvgClose" />
        </Button>
      </Box>
    )
  })
