import Debug from 'debug'
import moize from 'moize'
import { z } from 'zod'
import type { PhotosApi } from '../PhotosApi'
import { AvatarSchema } from './AvatarSchema'

const MILLIS_IN_DAY = 86400000 // 1000 * 60 * 60 * 24

const startOfDayUTC = (date: number) => date - (date % MILLIS_IN_DAY)

export const StringNumberSchema = z.preprocess(arg => {
  if (typeof arg === 'string') {
    return parseInt(arg)
  }
  return arg
}, z.number())
export const BaseMediaSchema = z.object({
  capturedDate: StringNumberSchema,
  commentsItemId: z.string().optional(),
  encoded_content_ref: z.string(),
  file_url: z.string(),
  filename: z.string(),
  filesize: z.number(),
  mimetype: z.string(),
  geoAddress: z.string().optional(),
  geoHash: z.string().optional(),
  gpsCoords: z.string().optional(),
  height: z.number(),
  hidden: z.boolean().default(false),
  id: z.string(),
  md5: z.string().min(32).max(32),
  ownerFullName: z.string().optional(),
  ownerAvatar: AvatarSchema.optional(),
  thumbnail_url: z.string(),
  timestamp: z.string(),
  username: z.string(),
  width: z.number(),
})

export const TimelineOverviewSchema = BaseMediaSchema.pick({
  capturedDate: true,
  height: true,
  width: true,
  id: true,
  md5: true,
  timestamp: true,
  username: true,
}).merge(
  z.object({
    content: z.literal('overview').optional().default('overview'),
  }),
)

export const TimelineOverviewResponseSchema = z.object({
  result: z.array(TimelineOverviewSchema),
  lastModified: z.number().optional(),
})

export const PhotoObjectSchema = BaseMediaSchema.extend({
  camera: z.string().optional(),
  content: z.literal('image'),
  exposure: z.string().optional(),
  focalLength: z.string().optional(),
  iso: z.number().optional(),
  live_photo_device_type: z.string().optional(),
  live_photo_mime_type: z.string().optional(),
  live_photo_url: z.string().optional(),
  codec: z.string().optional().default(''),
  duration: z.string().optional().default(''),
  video_url: z.string().optional().default(''),
})
export const VideoObjectSchema = BaseMediaSchema.extend({
  codec: z.string().optional().default(''),
  content: z.literal('video'),
  duration: z.string(),
  video_url: z.string(),
})

export const AllocateTimelineMediaResponseSchema = z.object({
  content: z.literal('allocate').optional().default('allocate'),
  state: z.string().optional(),
  upload_id: z.string(),
  upload_url: z.string().optional(),
  resume_pos: z.number().optional(),
  filename: z.string(),
  file_size: z.number().optional(),
  code: z.number().optional(),
  cause: z.string().optional(),
  id: z.string().optional(),
})

export const MediaSchema = z
  .discriminatedUnion('content', [PhotoObjectSchema, VideoObjectSchema])
  .transform(item => ({
    ...item,
    aspect: item.width / item.height,
    capturedDay: startOfDayUTC(item.capturedDate),
  }))

export const MediaResponseSchema = z.object({
  result: z.array(MediaSchema),
  lastModified: z.number().optional(),
})

const debug = Debug('jotta:types:MediaSchema')

export const parseMediaItem = moize.deep(
  (data: unknown) => {
    debug('parseMediaItem')
    return MediaSchema.parse(data) as PhotosApi.Media
  },
  {
    onCacheHit: () => debug('parseMediaItem - Cache hit'),
  },
)
export const parseMediaItems = moize.shallow(
  (data: unknown[] = []) => data.map(parseMediaItem),
  { onCacheHit: () => debug('parseMediaItems - Cache hit') },
)

export const AllocateResponseOrMediaSchema =
  AllocateTimelineMediaResponseSchema.or(MediaSchema)

export function parseAllocateOrMediaResponse(data: unknown) {
  return AllocateResponseOrMediaSchema.parse(data) as
    | PhotosApi.UploadMediaResponse
    | PhotosApi.AllocateMediaResponse
}

// export const PhotoMetaSchema = z.object({
//   adultContent: z.string().optional(),
//   clusterHostMapId: z.string().optional(),
//   collection_photo_order: z.bigint().optional(),
//   createdISO8601: z.string().optional(),
//   description: z.string().optional(),
//   excluded: z.string().optional(),
//   excludeInfo: z.string().optional(),
//   geoAddressRaw: z.string().optional(),
//   insertedIntoIndexDate: z.bigint().optional(),
//   jfsDate: z.string().optional(),
//   keywords: z.string().optional(),
//   livePhoto: z.string().optional(),
//   location: z.string().optional(),
//   nsfwScore: z.bigint().optional(),
//   orientation: z.string().optional(),
//   path: z.string().optional(),
//   publicId: z.string().optional(),
//   rawExif: jsonSchema.optional(),
//   tempId: z.string().optional(),
//   title: z.string().optional(),
// })
