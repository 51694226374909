import { Box } from '@jotta/ui/Box'
import { Button } from '@jotta/ui/Button'
import type { SelectVariant } from '@jotta/ui/types/VariantTypes'
import type { FC, PropsWithChildren, ReactNode } from 'react'

import { forwardRef } from 'react'
import type { CardProps } from '../../Elements/Card/Card'
import { Card } from '../../Elements/Card/Card'
import { FormattedContent } from '../../Elements/FormattedContent/FormattedContent'
import { ButtonGroup } from '../../Forms/ButtonGroup/ButtonGroup'
// import Debug from 'debug'
// const debug = Debug('jotta:ui:dialog')

export interface DialogProps extends CardProps {
  /** Callback to run when close button or background is clicked */
  onClose?: () => void
  errorMessage?: string
  /** Array of buttons */
  buttons?: {
    /** Button text */
    label: string
    /** Button click handler */
    action: 'close' | React.MouseEventHandler | ((...props: any[]) => void)
    /** Button type */
    variant?: SelectVariant<'buttons'>
    visible?: boolean
    disabled?: boolean
    loading?: boolean
    testId?: string
  }[]
  customButtons?: ReactNode
}

// DialogButtonGroup is a helper for anyone using customButtons in Dialog.
export const DialogButtonGroup: FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <ButtonGroup
      bg="background"
      alignRight
      sx={{
        mt: 'auto',
        pt: 4,
        px: 4,
      }}
    >
      {children}
    </ButtonGroup>
  )
}

export const Dialog = forwardRef<HTMLDivElement, DialogProps>(function Dialog(
  {
    variant = 'dialogs.content',
    errorMessage,
    buttons,
    children,
    onClose,
    customButtons,
    ...props
  },
  ref,
) {
  const closeHandler = onClose
    ? onClose
    : () => {
        console.warn('No close handler registered for dialog')
      }

  return (
    <Card variant={variant} ref={ref} {...props}>
      {onClose && (
        <Button
          p={4}
          icon="SvgClose"
          onClick={closeHandler}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
          }}
        />
      )}
      <FormattedContent px="4">
        <p
          sx={{
            color: 'danger',
          }}
        >
          {errorMessage}
        </p>
      </FormattedContent>
      <Box
        py="2"
        px="4"
        sx={{
          overflow: 'auto',
          color: 'darkGray',
          fontSize: 2,
        }}
      >
        {children}
      </Box>

      {customButtons ? customButtons : null}
      {buttons && (
        <DialogButtonGroup>
          {buttons
            .filter(
              button => button.visible === undefined || Boolean(button.visible),
            )
            .map((button, i, arr) => (
              <Button
                // Put the cancel button above progress overlay
                sx={{
                  zIndex:
                    button.action === 'close' || button.action === closeHandler
                      ? 2
                      : 0,
                }}
                disabled={!!button.disabled}
                loading={!!button.loading}
                key={button.label}
                variant={
                  button.variant
                    ? // Use variant if defined
                      button.variant
                    : i === arr.length - 1
                      ? // Primary if last
                        'buttons.primary'
                      : // Secondary for everything else
                        'buttons.secondary'
                }
                onClick={
                  button.action === 'close' ? closeHandler : button.action
                }
                data-testid={button.testId || button.label}
              >
                {button.label}
              </Button>
            ))}
        </DialogButtonGroup>
      )}
    </Card>
  )
})
