import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import { useParams } from 'react-router-dom'
import { ConfirmUpgrade } from './ConfirmUpgrade'
import { usePaymentInfo } from '../payment/usePaymentInformation'
import { SubscriptionInterval } from '@jotta/grpc-web/no/jotta/openapi/subscription/payment.v2_pb'

export const ConfirmUpgradeRoute = () => {
  const { productCode } = useParams()
  const { data } = usePaymentInfo()

  const subscriptionInterval = data?.subscriptionInterval
  if (!productCode || subscriptionInterval === undefined) {
    return <LoadingOverlay open />
  }

  return (
    <ConfirmUpgrade
      productCode={productCode}
      subscriptionInterval={
        subscriptionInterval === SubscriptionInterval.MONTHLY
          ? subscriptionInterval
          : SubscriptionInterval.YEARLY
      }
    />
  )
}
