import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import Debug from 'debug'
import { useNavigate } from 'react-router-dom'
import { usePublicAlbumInfoLoaderData } from '../AlbumInfo/AlbumInfo.loaders'
import { PublicAlbumContainer } from './PublicAlbum.container'
const debug = Debug('jotta:photos:PublicAlbumRoute')

import { useEffect } from 'react'
export function PublicAlbumRoute() {
  const data = usePublicAlbumInfoLoaderData()

  const navigate = useNavigate()
  useEffect(() => {
    if (data.redirectTo) {
      debug('Redirect to', data.redirectTo)
      navigate(data.redirectTo, {
        replace: true,
      })
    }
  }, [data.redirectTo, navigate])

  if (data.redirectTo) {
    return <LoadingOverlay open />
  }
  return <PublicAlbumContainer />
}
