import { dash } from 'radash'
import type { KebabCase } from 'type-fest'
import { isString } from './typeAssertions'

export function kebabcase<T extends string>(str: T): KebabCase<T> {
  return dash(str) as KebabCase<T>
}

export function capitalize<T extends string>(str: T): Capitalize<T> {
  return (str.charAt(0).toUpperCase() + str.slice(1)) as Capitalize<T>
}

export function uncapitalize<T extends string>(str: T): Uncapitalize<T> {
  return (str.charAt(0).toLowerCase() + str.slice(1)) as Uncapitalize<T>
}

export function uppercase<T extends string>(str: T): Uppercase<T> {
  return str.toUpperCase() as Uppercase<T>
}

export function lowercase<T extends string>(str: T): Lowercase<T> {
  return str.toLowerCase() as Lowercase<T>
}

export function padBase64(str: string) {
  const mod = str.length % 4
  if (!mod) {
    return str
  }
  return str.padEnd(4 + str.length - mod, '=')
}
export function compareBase64(a: string, b: string) {
  const aa = padBase64(a)
  const bb = padBase64(b)
  return aa === bb
}
export function caseInsensitiveIsEqual(a: string, b: string) {
  return a.toLocaleLowerCase() === b.toLocaleLowerCase()
}

export type PrefixString<
  Prefix extends string,
  Name extends string,
> = `${Prefix}${Capitalize<Name>}`
export function prefixString<Prefix extends string, Name extends string>(
  prefix: Prefix,
  name: Name,
): PrefixString<Prefix, Name> {
  return (prefix + capitalize(name)) as PrefixString<Prefix, Name>
}

export function createConstants<T extends string>(...values: readonly T[]) {
  type Val = (typeof values)[number]
  const isConstant = (value: any): value is Val => {
    return isString(value) && values.includes(value as Val)
  }
  const getConstant = (value: any, defaultValue: Val): Val => {
    if (isConstant(value)) {
      return value
    }
    return defaultValue
  }
  return [values, isConstant, getConstant] as const
}

/**
 * Convert string and other values to boolean
 * Returns false for words that should represent false in strings
 * Use for localStorage, search params and other places where values
 * are always stringified
 * @param value
 * @returns
 */
export function toBoolean(value?: unknown) {
  if (!value) {
    return false
  }
  if (isString(value)) {
    return !/^(disabled|false|0|\s+)$/.test(value)
  }
  return Boolean(value)
}
