import { Box } from '@jotta/ui/Box'
import { ItemKind } from '@jotta/grpc-js-client/fileService'
import { isWopi } from '@jotta/grpc-js-client/wopiService'
import type { PathItemObject } from '@jotta/types/Files'
import { selectCodeView } from '@jotta/ui/CodeView'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import { FileItemTypeIcon } from '../FileItemTypeIcon/FileItemTypeIcon'
import { FileCodeView } from './FileCodeView/FileCodeView'
import { FileTextView } from './FileCodeView/FileTextView'
import { FileViewImage } from './FileViewImage/FileViewImage'
import { FileViewVideo } from './FileViewVideo/FileViewVideo'
import { FilePdfView } from './PdfView/PdfView'
import { WopiViewContainer } from './WopiView/WopiViewContainer'
import { useFeatures } from '@jotta/grpc-connect-client/config'

const debug = Debug('jotta:ui:FileView')

export interface FileViewProps {
  file: PathItemObject
  token?: string
  isActive?: boolean
}

export const FileView = observer<FileViewProps>(function FileView({
  file,
  token,
  isActive = false,
}) {
  const features = useFeatures()

  if (isWopi(file.name, features.wopiTestMode)) {
    if (!isActive) {
      return null
    }
    return <WopiViewContainer item={file} shareToken={token} />
  }
  switch (file.kind) {
    case ItemKind.PDF:
      return (
        <FilePdfView forcePdfJs file={file} isActive={isActive} token={token} />
      )
    case ItemKind.IMAGE:
      return (
        <FileViewImage
          item={file}
          size="2480x"
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            objectPosition: 'center',
          }}
        />
      )
    case ItemKind.VIDEO: {
      return <FileViewVideo file={file} token={token} enabled={isActive} />
    }
  }
  const view = selectCodeView(file)
  switch (view.view) {
    case 'CODE':
      return (
        <Box variant="layout.center">
          {isActive && (
            <FileCodeView token={token} language={view.lang} file={file} />
          )}
        </Box>
      )
    case 'TEXT':
      return (
        <Box variant="layout.center">
          {isActive && <FileTextView token={token} file={file} />}
        </Box>
      )

    default:
      return (
        <FileItemTypeIcon
          sx={{
            variant: 'layout.center',
          }}
          size={'50%'}
          item={file}
        />
      )
  }
})
