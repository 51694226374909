import { useEffect, useState } from 'react'
import { useThrottle } from './useThrottle'

function useVisualViewportResizeHandler(active: boolean) {
  const [rect, setRect] = useState<{
    top: number
    left: number
    width: number
    height: number
  } | null>(null)

  const setRectThrottled = useThrottle(setRect, 100)

  useEffect(() => {
    if (!active) {
      return
    }

    if (!window.visualViewport) {
      return
    }

    const resizeHandler = () => {
      if (!window.visualViewport) {
        return
      }

      const vp = window.visualViewport

      setRectThrottled({
        top: vp.pageTop,
        left: vp.pageLeft,
        width: vp.width,
        height: vp.height,
      })
    }

    resizeHandler()
    window.visualViewport.addEventListener('resize', resizeHandler)

    return () => {
      window.visualViewport?.removeEventListener('resize', resizeHandler)
      setRectThrottled.cancel()
      setRect(null)
    }
  }, [active])

  return rect
}

export function useScrollIntoVisualViewport(
  el: HTMLElement | null,
  options: {
    block?: ScrollLogicalPosition
    behavior?: ScrollBehavior
    offsetTop?: number
    offsetBottom?: number
  } = {},
) {
  const {
    block = 'nearest',
    behavior = 'auto',
    offsetTop = 0,
    offsetBottom = 0,
  } = options
  const viewport = useVisualViewportResizeHandler(Boolean(el))

  useEffect(() => {
    if (!el || !viewport) {
      return
    }

    const { top, height } = el.getBoundingClientRect()
    const upperBound = viewport.top + top - offsetTop
    const lowerBound =
      viewport.top + top - viewport.height + height + offsetBottom

    switch (block) {
      case 'start':
        window.scrollTo({ top: upperBound, behavior })
        break

      case 'end':
        window.scrollTo({ top: lowerBound, behavior })
        break

      case 'nearest':
        if (top < offsetTop) {
          window.scrollTo({ top: upperBound, behavior })
        } else if (top + height > viewport.height + offsetBottom) {
          window.scrollTo({ top: lowerBound, behavior })
        }
        break

      case 'center':
        window.scrollTo({ top: (lowerBound - upperBound - height) / 2 })
        break
    }
  }, [viewport, el, block, behavior, offsetTop, offsetBottom])
}
