import { createObservableInfiniteQuery, ObservableQuery } from '@jotta/query'
import type { AppError } from '@jotta/types/AppError'
import type { PhotosApi } from '@jotta/types/PhotosApi'
import { AlbumSchema, parseAlbumInfo } from '@jotta/types/schemas/AlbumSchema'
import type {
  InfiniteData,
  QueryFunctionContext,
  QueryObserverOptions,
  UseQueryOptions,
} from '@tanstack/react-query'
import { keepPreviousData } from '@tanstack/react-query'
import type { Debugger } from 'debug'
import Debug from 'debug'
import { autorun, makeAutoObservable, runInAction } from 'mobx'
import path from 'path'
import type { SetRequired } from 'type-fest'
import { fetchAlbumPhotos, getApi } from '../api/photosApi'

import type { PhotoStore } from './PhotoStore'
const debug = Debug('jotta:photos:PublicAlbumQueryStore')

export interface FetchPublicAlbumInfoOptions {
  shareId: string
}
export interface FetchPublicAlbumPhotosOptions {
  shareId: string
  limit?: number
  comments?: boolean
  fetchAllPages?: boolean
  from?: string
  to?: string
}

const defaultFetchPublicAlbumPhotosOptions: Required<FetchPublicAlbumPhotosOptions> =
  {
    shareId: '',
    limit: 500,
    comments: true,
    fetchAllPages: false,
    from: '',
    to: '',
  }
export type PublicAlbumInfoQueryKey = readonly [
  key: 'publicSharedPhotos',
  view: 'info',
  options: Required<FetchPublicAlbumInfoOptions>,
]
export type PublicAlbumPhotosQueryKey = readonly [
  key: 'publicSharedPhotos',
  view: 'photos',
  options: Required<FetchPublicAlbumPhotosOptions>,
]
export type PublicAlbumInfoQueryFunctionContext = QueryFunctionContext<
  PublicAlbumInfoQueryKey,
  string
>
export type PublicAlbumInfoQueryOptions = SetRequired<
  UseQueryOptions<
    PhotosApi.AlbumInfo,
    unknown,
    PhotosApi.AlbumInfo,
    PublicAlbumInfoQueryKey
  >,
  'queryFn' | 'queryKey'
>
export type PublicAlbumInfoQueryObserverOptions = SetRequired<
  QueryObserverOptions<
    PhotosApi.AlbumInfo,
    AppError,
    PhotosApi.AlbumInfo,
    PublicAlbumInfoQueryKey
  >,
  'queryFn' | 'queryKey'
>
export function getPublicAlbumInfoQueryKey({
  shareId,
}: FetchPublicAlbumInfoOptions): PublicAlbumInfoQueryKey {
  return [
    'publicSharedPhotos',
    'info',
    {
      shareId,
    },
  ]
}
export function getInfinitePublicAlbumPhotosQueryKey(
  args: FetchPublicAlbumPhotosOptions,
): PublicAlbumPhotosQueryKey {
  const options: Required<FetchPublicAlbumPhotosOptions> = {
    ...defaultFetchPublicAlbumPhotosOptions,
    ...args,
  }
  return ['publicSharedPhotos', 'photos', options]
}

export async function fetchPublicAlbumInfo({
  shareId,
}: FetchPublicAlbumInfoOptions) {
  const apiPath = path.join('public', shareId)
  debug('fetchPublicAlbumInfo', apiPath, shareId)
  const result = await getApi()
    .get(apiPath, {
      searchParams: {
        limit: 1,
        comments: false,
      },
    })
    .json<unknown>()
  const data: PhotosApi.AlbumInfo = parseAlbumInfo(result)
  data.photoIds = []
  data.total = 0
  return data
}

/**
 * Query options for fetching Album Info (the album itself, not the children)
 * Creates queryOptions that can be used both with useQuery and queryClient.fetchQuery
 * @param fetchOptions
 * @param options
 * @returns
 */
export function getPublicAlbumInfoQueryObserverOptions(
  fetchOptions: FetchPublicAlbumInfoOptions,
  options: Partial<PublicAlbumInfoQueryObserverOptions> = {},
): PublicAlbumInfoQueryObserverOptions {
  const queryOptions: PublicAlbumInfoQueryObserverOptions = {
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 2, // 2 minutes
    queryKey: getPublicAlbumInfoQueryKey(fetchOptions),
    queryFn: () => {
      // debug('pageParam', pageParam)
      return fetchPublicAlbumInfo(fetchOptions)
    },
    ...options,
  }
  return queryOptions
}
export class PublicAlbumQueryStore {
  private initialLimit = 10
  private pageLimit = 500
  private PublicAlbumInfoQuery
  private PublicAlbumPhotosQuery: ReturnType<
    typeof createObservableInfiniteQuery<
      PhotosApi.AlbumInfo,
      AppError,
      PhotosApi.AlbumInfo,
      PublicAlbumPhotosQueryKey,
      string
    >
  >
  private log: Debugger
  public shareId: string
  constructor(
    shareId: string,
    private root: PhotoStore,
    albumData?: PhotosApi.AlbumInfo,
  ) {
    if (!shareId) {
      throw new Error('Missing share ID')
    }

    this.shareId = shareId

    this.log = debug.extend(shareId)
    this.log.enabled = false
    this.log('init', shareId)
    if (albumData) {
      runInAction(() => {
        this.root.albumRoot.setAlbumInfo(this.shareId, albumData, true)
      })
    }
    makeAutoObservable<typeof this, 'log'>(
      this,
      {
        log: false,
      },
      {
        autoBind: true,
      },
    )
    this.PublicAlbumInfoQuery = new ObservableQuery({
      name: 'PublicAlbumInfo',
      queryOptions: getPublicAlbumInfoQueryObserverOptions({
        shareId,
      }),
    })

    this.PublicAlbumPhotosQuery = createObservableInfiniteQuery<
      PhotosApi.AlbumInfo,
      AppError,
      PhotosApi.AlbumInfo,
      PublicAlbumPhotosQueryKey,
      string | undefined,
      string
    >({
      name: 'PublicPhotos',
      infiniteQueryOptions: {
        placeholderData: keepPreviousData,
        initialPageParam: '',
        staleTime: 1000 * 60 * 2, // 2 minutes
        queryKey: getInfinitePublicAlbumPhotosQueryKey({
          shareId: shareId,
        }),
        getNextPageParam: lastPage => lastPage.nextPageParam,
        queryFn: this.fetchPhotos,
      },
    })

    autorun(() => {
      const {
        isSuccess,
        isFetchingNextPage,
        hasNextPage,
        data,
        isFetched,
        fetchNextPage,
      } = this.PublicAlbumPhotosQuery
      if (data && isFetched) {
        this.onFetchSuccess(this.PublicAlbumPhotosQuery.data)
      }

      if (isSuccess && !isFetchingNextPage && hasNextPage) {
        this.log('Fetching page %d', data.pages.length + 1)
        fetchNextPage()
      }
    })
  }

  async fetchPhotos({ pageParam = '' }: { pageParam?: string }) {
    const apiPath = path.join('public', this.shareId)
    this.log('apiPath', apiPath)
    const limit = !pageParam ? this.initialLimit : this.pageLimit
    const result = await fetchAlbumPhotos({
      from: pageParam,
      params: {
        id: this.shareId,
        limit,
        publicAlbum: true,
        comments: true,
      },
    })
    const { photos, ...album } = AlbumSchema.parse(result)
    const nextPageParam = photos.at(-1)?.timestamp
    if (
      photos.length === limit &&
      nextPageParam &&
      nextPageParam !== pageParam
    ) {
      album.nextPageParam = nextPageParam
    }
    this.log('Adding %d photos', photos.length)
    this.root.mediaObjects.setPhotos(structuredClone(photos))
    return album
  }

  onFetchSuccess(data: InfiniteData<PhotosApi.AlbumInfo>) {
    if (data.pages.length) {
      const album: PhotosApi.AlbumInfo = structuredClone(
        data.pages[data.pages.length - 1],
      )
      album.photoIds = data.pages.flatMap(page => page.photoIds)
      this.log('onSuccess', album)
      this.root.albumRoot.setAlbum(this.shareId, album)
    }
  }
  get isLoading() {
    return (
      this.PublicAlbumInfoQuery.isLoading ||
      this.PublicAlbumPhotosQuery.isLoading
    )
  }

  get album() {
    return this.root.albumRoot.getAlbum(this.shareId)
  }
}
