import { excludesFalse } from '@jotta/types/TypeUtils'
import { useCallback, useEffect, useRef } from 'react'

type UseSlideTransitionProps = {
  prev: () => void
  next: () => void
  index: number
  total: number
}

export function useSlideTransition({
  prev,
  next,
  index,
  total,
}: UseSlideTransitionProps) {
  const prevSlide = useRef<HTMLDivElement>(null)
  const nextSlide = useRef<HTMLDivElement>(null)
  const currentSlide = useRef<HTMLDivElement>(null)

  const swipeState = useRef({
    origin: 0,
    offset: 0,
  })

  const handleTouch: React.TouchEventHandler<HTMLDivElement> = useCallback(
    e => {
      const state = swipeState.current
      const els = [
        prevSlide.current,
        currentSlide.current,
        nextSlide.current,
      ].filter(excludesFalse)
      const prevEl = prevSlide.current
      const currentEl = currentSlide.current
      const nextEl = nextSlide.current
      if (!currentEl || els.length < 2) {
        return
      }
      const hasNextSlide = nextEl && index < total - 1
      const hasPrevSlide = prevEl && index > 0

      switch (e.type) {
        case 'touchstart':
          {
            state.origin = e.touches[0].clientX

            state.offset = currentEl.getBoundingClientRect().left

            for (const el of els) {
              el.style.transition = ''
              el.style.transform = `translateX(${state.offset}px)`
            }
          }

          break

        case 'touchend':
          {
            const offsetLeft = currentEl.getBoundingClientRect().left || 0
            const width = document.body.clientWidth

            let transform = ''
            const offsetPercent = (offsetLeft / width) * 100
            const transitionTime = 300

            if (hasPrevSlide && offsetPercent > 20) {
              transform = 'translateX(100%)'
              prevEl.addEventListener('transitionend', prev, {
                once: true,
              })
            } else if (hasNextSlide && offsetPercent < -20) {
              transform = 'translateX(-100%)'
              nextEl.addEventListener('transitionend', next, {
                once: true,
              })
            }

            for (const el of els) {
              el.style.transition = `transform ${transitionTime}ms`
              el.style.transform = transform
            }
          }
          break
        case 'touchmove':
          {
            const swipePixels =
              e.touches[0].clientX - state.origin + state.offset

            for (const el of els) {
              el.style.transform = `translateX(${swipePixels}px)`
            }
          }
          break
        default:
          break
      }
    },
    [index, next, prev, total],
  )
  useEffect(() => {
    const els = [
      prevSlide.current,
      currentSlide.current,
      nextSlide.current,
    ].filter(excludesFalse)

    for (const el of els) {
      el.style.transition = ''
      el.style.transform = ''
    }

    swipeState.current = {
      offset: 0,
      origin: 0,
    }
  }, [index])

  // Keep scroll position which is reset by video fullscreen
  useEffect(() => {
    const el = document.scrollingElement

    if (index < 0 || !el) {
      return
    }

    const scrollTop = el.scrollTop

    return () => {
      el.scrollTop = scrollTop
    }
  }, [index])

  return {
    prevSlide,
    nextSlide,
    currentSlide,
    handleTouch,
  }
}
