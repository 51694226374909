// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PersonRoute_hidden__PQG-v,.PersonRoute_hidden__PQG-v:disabled{opacity:0}`, "",{"version":3,"sources":["webpack://./../../libraries/photos/src/people/internal/routes/PersonRoute.module.scss"],"names":[],"mappings":"AACA,+DAEE,SAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hidden": `PersonRoute_hidden__PQG-v`
};
export default ___CSS_LOADER_EXPORT___;
