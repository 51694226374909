// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FacesInPhoto_bound__aOEAH{--bound-color:#adff2f;box-shadow:0 0 0 var(--bounds-border-width,2px) var(--bound-color);color:var(--bound-color);filter:0 0 4px;opacity:1;position:absolute;z-index:2}.FacesInPhoto_bound__aOEAH.FacesInPhoto_unknownPerson__2nfpY{--bound-color:blue}.FacesInPhoto_bound__aOEAH.FacesInPhoto_mainPerson__j43zk{--bound-color:#ff4500}@media(hover:hover)and (pointer:fine){.FacesInPhoto_bound__aOEAH{opacity:.7}.FacesInPhoto_bound__aOEAH:hover{box-shadow:0 0 0 var(--bounds-border-width,2px) var(--bound-color),0 0 80px 10px #000;opacity:1}}`, "",{"version":3,"sources":["webpack://./../../libraries/photos/src/people/internal/components/FacesInPhoto/FacesInPhoto.module.scss"],"names":[],"mappings":"AACA,2BACE,qBAAA,CAKA,kEAAA,CADA,wBAAA,CAEA,cAAA,CALA,SAAA,CACA,iBAAA,CACA,SAGA,CAEA,6DACE,kBAAA,CAEF,0DACE,qBAAA,CAGJ,sCACE,2BACE,UAAA,CACA,iCAEE,qFACE,CAFF,SAEE,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bound": `FacesInPhoto_bound__aOEAH`,
	"unknownPerson": `FacesInPhoto_unknownPerson__2nfpY`,
	"mainPerson": `FacesInPhoto_mainPerson__j43zk`
};
export default ___CSS_LOADER_EXPORT___;
