import { getItemKindKey } from '@jotta/file-utils'
import clsx from 'clsx'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import { FileContextMenu } from '../FileContextMenu/FileContextMenu'
import { PathItemCheckbox } from './PathItemCheckbox'
import styles from './PathItemRow.module.scss'
import { PathItemRowContextMenu } from './PathItemRowContextMenu'
import { PathItemRowData } from './PathItemRowData'
import { PathItemRowRename } from './PathItemRowRename'
import type { PathItemStore } from './PathItemStore'
import { PathItemThumbRename } from './PathItemThumbRename'

const debug = Debug('jotta:files:PathItemRow')
export interface PathItemRowProps {
  item: PathItemStore
}

export const PathItemRow = observer<PathItemRowProps>(function PathItemRow({
  item,
}: PathItemRowProps) {
  return (
    <FileContextMenu
      path={item.path}
      context={item.rowActionContext}
      onSelect={item.onContextMenuSelect}
      actions={item.contextMenuActions}
      type="CONTEXT"
      asChild
      disabled={item.showRenameInput}
      numAffectedItems={item.folder.numSelected}
    >
      <div
        data-kind={getItemKindKey(item.model.kind)}
        data-context={item.rowActionContext}
        data-testid="FileListItem"
        className={clsx(styles.row, {
          [styles.rowRename]: item.showRenameInput,
        })}
        title={item.name}
        onContextMenu={e =>
          !item.contextMenuActions.length && e.preventDefault()
        }
      >
        <PathItemCheckbox item={item} />
        <div className={styles.rowDataWrapper}>
          <PathItemRowData item={item} />
          {item.isThumb ? (
            <PathItemThumbRename item={item} />
          ) : (
            <PathItemRowRename item={item} />
          )}
        </div>
        <PathItemRowContextMenu item={item} />
      </div>
    </FileContextMenu>
  )
})
