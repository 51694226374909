import { CreateUser } from './CreateUser'
import { PaymentInformation } from '../payment/PaymentInformation'
import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { TransportProvider } from '@connectrpc/connect-query'
import { LoadingOverlay, LoadingOverlaySpinner } from '@jotta/ui/LoadingOverlay'
import {
  authTransport,
  publicTransport,
} from '@jotta/grpc-connect-client/transport'
import type { RecurringSubscriptionInterval } from '../payment/useOfferState'
import { useOfferState } from '../payment/useOfferState'
import type { Offer } from '@jotta/grpc-connect-openapi/offer'
import {
  Currency,
  SubscriptionInterval,
} from '@jotta/grpc-connect-openapi/payment'
import { getEnumKey } from '@jotta/utils/enum'
import { SubscriptionType } from '@jotta/grpc-connect-openapi/customer'
import { t } from '@lingui/macro'
import Step1Svg from './step1.svg'
import Step2Svg from './step2.svg'
import { exhaustiveGuard } from '@jotta/utils/exhaustive'
import { BusinessSignup } from './BusinessSignup'
import { FreeSignup } from './FreeSignup'
import { PaymentLayout } from '../payment/PaymentLayout'

function successUrl(offer: Offer, interval: SubscriptionInterval) {
  const currency = offer?.yearly?.basePrice?.currency
  const intervalKey = getEnumKey(SubscriptionInterval, interval)
  const currencyKey = currency && getEnumKey(Currency, currency)
  return `${location.origin}/web/signupAccepted/${offer.productCode}/${intervalKey}?currency=${currencyKey}&product=${offer.productCode}`
}

export function PaidSignup({
  federationToken,
  productCode,
  defaultSubscriptionInterval,
}: {
  productCode: string
  federationToken?: string
  defaultSubscriptionInterval: RecurringSubscriptionInterval
}) {
  const navigate = useNavigate()
  const gotoBilling = useCallback(() => navigate('/web/billing'), [navigate])
  const offerState = useOfferState(productCode, defaultSubscriptionInterval)
  const {
    type,
    free,
    authenticated,
    offer,
    paymentMethods,
    interval,
    subscriptionType,
  } = offerState

  useEffect(() => {
    if (
      subscriptionType != SubscriptionType.FREE &&
      subscriptionType != SubscriptionType.UNKNOWN_SUBSCRIPTION_TYPE
    ) {
      gotoBilling()
    }
  }, [subscriptionType, gotoBilling])

  if (!type || !offer) {
    return <LoadingOverlay open />
  }

  if (free) {
    switch (type) {
      case 'private':
      case 'voucher':
        return <FreeSignup federationToken={federationToken} />
      case 'business':
        return <BusinessSignup />
      default:
        return exhaustiveGuard(type)
    }
  }

  return (
    <PaymentLayout
      backPath="/"
      offer={offer}
      interval={interval}
      progressIndicator={authenticated ? <Step2Svg /> : <Step1Svg />}
      title={authenticated ? t`Enter payment details` : t`Create user`}
    >
      {summary =>
        authenticated ? (
          <TransportProvider
            transport={authenticated ? authTransport : publicTransport}
          >
            {offer && paymentMethods ? (
              <PaymentInformation
                offerState={offerState}
                successUrl={successUrl(offer, interval)}
                summary={summary}
              />
            ) : (
              <LoadingOverlaySpinner />
            )}
          </TransportProvider>
        ) : (
          <CreateUser
            className="mt-12"
            keepRouteOnSuccess
            type={type}
            federationToken={federationToken}
            ctaLabelText={t`Continue`}
            federationIntent={{
              case: 'signup',
              value: {
                productCode,
              },
            }}
          />
        )
      }
    </PaymentLayout>
  )
}
