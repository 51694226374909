import Debug from 'debug'
import { isObservableObject, makeAutoObservable } from 'mobx'
import type { CarouselProps } from './Carousel'

export type CarouselStoreProps<T> = Omit<
  CarouselProps<T>,
  'ItemHeader' | 'ItemFooter' | 'ItemView'
>

export class CarouselStore<T> {
  private _log
  private _props: CarouselStoreProps<T>
  constructor(props: CarouselStoreProps<T>) {
    if (!isObservableObject(props)) {
      throw new Error('Props must be an observable object')
    }
    this._props = props
    this._log = Debug('jotta:carousel:store')
    this._log('Create carousel')
    makeAutoObservable<typeof this, '_log' | '_props'>(
      this,
      {
        _log: false,
        _props: false,
        items: false,
      },
      {
        autoBind: true,
      },
    )
  }
  private get getId() {
    return this._props.getId
  }
  handleKeyDown(e: KeyboardEvent) {
    if (
      e.repeat ||
      e.shiftKey ||
      e.ctrlKey ||
      e.target instanceof HTMLTextAreaElement ||
      (e.key !== 'ArrowLeft' && e.key !== 'ArrowRight')
    ) {
      return
    }
    switch (e.key) {
      case 'ArrowLeft':
        {
          this.prev()
        }
        break
      case 'ArrowRight':
        {
          this.next()
        }
        break

      default:
        break
    }
  }
  get items() {
    return this._props.items
  }

  get itemData() {
    return this.items.map((item, index) => {
      const id = this.getId(item)
      return {
        id,
        index,
        item,
      }
    })
  }
  get hasItems() {
    return Boolean(this.items.length)
  }
  get total() {
    return this.items.length
  }
  get firstIndex() {
    return this.total ? 0 : -1
  }
  get lastIndex() {
    return this.total - 1
  }
  get currentIndex() {
    return this._props.currentItemIndex
  }
  set currentIndex(index) {
    if (index <= this.lastIndex && index >= this.firstIndex) {
      this._props.setActiveIndex(index)
    }
  }
  get currentItem() {
    return this.itemData[this.currentIndex]
  }
  get nextItem() {
    if (this.currentIndex < this.lastIndex) {
      return this.itemData[this.currentIndex + 1]
    }
    return undefined
  }
  get prevItem() {
    if (this.currentIndex > 0) {
      return this.itemData[this.currentIndex - 1]
    }
    return undefined
  }
  setCurrentIndex(index: number) {
    this.currentIndex = index
  }
  next() {
    if (this.nextItem) {
      this.currentIndex = this.nextItem.index
    }
  }
  prev() {
    if (this.prevItem) {
      this.currentIndex = this.prevItem.index
    }
  }
}
