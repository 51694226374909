// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Fab_fab__asAqM{all:unset;--size:var(--fab-size,60px);--icon-size:calc(var(--size)/2);align-items:center;background-color:var(--color-primary);border-color:var(--color-primary);border-radius:calc(var(--size)/2);box-shadow:0 3px 15px 0 #0006;color:var(--color-primary-text);cursor:pointer;display:flex;height:var(--size);justify-content:center;opacity:1;transform:scale(1);transition:transform .3s ease,opacity .3s ease;width:var(--size)}.Fab_fab__asAqM:hover{transform:scale(1.1)}.Fab_fab__asAqM:disabled,:disabled>.Fab_fab__asAqM{opacity:0;transform:scale(0)}.Fab_fab__asAqM>svg{height:var(--icon-size);transition:transform .3s ease;width:var(--icon-size)}.Fab_fab__asAqM[data-open=false]>svg,[aria-expanded=false] .Fab_fab__asAqM svg{transform:rotate(0deg)}.Fab_fab__asAqM:not(:disabled)[data-open=true]>svg,[aria-expanded=true] .Fab_fab__asAqM svg{transform:rotate(135deg)}body.mediamarkt .Fab_fab__asAqM{background-color:var(--color-text)}body.saturn .Fab_fab__asAqM{background-color:var(--color-accent)}body.halebop .Fab_fab__asAqM{--icon-size:24px}body.tsky .Fab_fab__asAqM{background-color:var(--color-t-sky-teal)}body.onlime .Fab_fab__asAqM{background-color:var(--color-secondary-green)}`, "",{"version":3,"sources":["webpack://./../../libraries/ui/src/Navigation/Fab/Fab.module.scss"],"names":[],"mappings":"AACA,gBACE,SAAA,CACA,2BAAA,CACA,+BAAA,CAEA,kBAAA,CAGA,qCAAA,CACA,iCAAA,CAIA,iCAAA,CAGA,6BAAA,CANA,+BAAA,CAHA,cAAA,CAHA,YAAA,CAQA,kBAAA,CANA,sBAAA,CAWA,SAAA,CAFA,kBAAA,CADA,8CAAA,CAHA,iBAMA,CAEA,sBACE,oBAAA,CAEF,mDAGE,SAAA,CADA,kBACA,CAEF,oBAGE,uBAAA,CAFA,6BAAA,CACA,sBACA,CAEF,+EAEE,sBAAA,CAEF,4FAEE,wBAAA,CAGF,gCACE,kCAAA,CAGF,4BACE,oCAAA,CAGF,6BACE,gBAAA,CAGF,0BACE,wCAAA,CAGF,4BACE,6CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fab": `Fab_fab__asAqM`
};
export default ___CSS_LOADER_EXPORT___;
