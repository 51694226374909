import { px } from '@jotta/utils/css'
import { kebabCase } from 'change-case'
import Debug from 'debug'
import { parseToRgb } from 'polished'
import type { BrandStore } from '../Brand/BrandStore/BrandStore'
import { brandZIndexes } from '../themes/zIndex'
import type { BrandTheme } from '../types/BrandTheme'
import { getResponsiveValue } from './getResponsiveValue'
const debug = Debug('jotta:branding:getThemeCSSVariables')

export function getThemeCSSVariables({
  theme,
  scrollbarWidth = 0,
  headerHeight = 0,
  activeElements = {
    leftNav: true,
    topNav: false,
    moduleNav: true,
    timelineScrubber: false,
  },
}: {
  theme: BrandTheme
  scrollbarWidth?: number
  headerHeight?: number
  activeElements?: BrandStore['activeElements']
}) {
  debug('getThemeCSSVariables')
  const { topToolBar, bottomToolBar, header, sidebar, files, photos } =
    theme.branding
  // this.log('globalStyles')
  return {
    ':root': {
      ...zIndexVars(),
      ...themeFontFamilyVars(theme),
      ...themeFontSizeVars(theme),
      ...themeFontWeightVars(theme),
      ...themeSizeVars(theme),
      ...themeRadiiVars(theme),
      // Measured width of scrollbar
      '--scrollbar-width': px(scrollbarWidth),
      '--scrollbar-offset':
        'calc(var(--scrollbar-width, 0px) - var(--visible-scrollbar-width, 0px))',
      '--public-folder-row-height': px(files.publicFolderRowHeight),
      '--folder-row-height': px(files.folderRowHeight),
      '--folder-column-width': px(files.folderColumnWidth),
      '--folder-thumb-aspect': files.folderThumbAspect,
      '--scrubber-active-width': px(
        getResponsiveValue(0, photos.timeline.scrubberWidth),
      ),
      '--sidebar-open-width': sidebar.openWidth[0],
      '--sidebar-closed-width': sidebar.closedWidth[0],
      '--sidebar-width': activeElements.leftNav
        ? 'var(--sidebar-closed-width)'
        : '0px',
      '--header-height': `${headerHeight ? headerHeight : header.height}px`,
      '--top-toolbar-open-height': `${topToolBar.height}px`,
      '--top-toolbar-height': '0px',
      '--bottom-toolbar-open-height': `${bottomToolBar.height}px`,
      '--bottom-toolbar-details-height': `calc(100vh - ${
        header.height + bottomToolBar.height
      }px)`,
      '--bottom-toolbar-height': '0px',
      '--current-breakpoint': '0',
      '--content-bottom': 'var(--bottom-toolbar-height)',
      '--content-top':
        'calc(var(--header-height, 0px) + var(--top-toolbar-height, 0px))',
      '--content-width':
        'calc(100vw - var(--scrubber-width, 0px) - var(--visible-scrollbar-width, 0px))',
      '--content-width-safe':
        'calc(100vw - var(--scrubber-width, 0px) - var(--scrollbar-width, 0px))',
      '--content-left': '0px',
      '--content-right': '0px',
      '--content-right-safe': 'calc(var(--scrollbar-offset, 0px))',
      '--content-height':
        'calc(100vh - var(--content-top) - var(--bottom-toolbar-height))',
      '--carousel-sidepanel-width': '100%',
      ...themeColorVars(theme),
    },
    [`@media screen and (min-width: ${px(
      theme.branding.photos.timeline.mobileTimelineBreakpoint,
    )})`]: {
      ':root': {
        '--content-right': 'calc(0px + var(--scrubber-width, 0px))',
        '--content-right-safe':
          'calc(var(--scrollbar-offset, 0px) + var(--scrubber-width, 0px))',
        '--scrubber-active-width': px(
          getResponsiveValue(1, photos.timeline.scrubberWidth),
        ),
      },
    },
    [`@media screen and (min-width: ${theme.breakpoints[0]})`]: {
      ':root': {
        '--content-left': '0px',
        '--sidebar-open-width': sidebar.openWidth[1],
        '--sidebar-closed-width': sidebar.closedWidth[1],
        '--carousel-sidepanel-width': '360px',
        '--current-breakpoint': '1',
      },
    },
    [`@media screen and (min-width: ${theme.breakpoints[1]})`]: {
      ':root': {
        '--content-width':
          'calc(100vw - var(--scrubber-width, 0px) - var(--sidebar-width, 0px) - var(--visible-scrollbar-width, 0px))',
        '--content-width-safe':
          'calc(100vw - var(--scrubber-width, 0px) - var(--sidebar-width, 0px) - var(--scrollbar-width, 0px))',
        '--content-left': 'var(--sidebar-width)',
        '--sidebar-open-width': sidebar.openWidth[2],
        '--sidebar-closed-width': sidebar.closedWidth[2],
        '--current-breakpoint': '2',
        '--top-toolbar-height': activeElements.moduleNav
          ? 'var(--top-toolbar-open-height)'
          : '0px',
      },
    },
    [`@media screen and (min-width: ${theme.breakpoints[2]})`]: {
      ':root': {
        '--current-breakpoint': '3',
      },
    },
  }
}

function themeColorVars(theme: BrandTheme): Record<string, string | number> {
  // this.log('colorVars')
  return Object.fromEntries(
    Object.entries(theme.colors).flatMap(([k, v]) => {
      const colorVar = [`--color-${kebabCase(k)}`, String(v)]
      const rgbVar = [`--rgb-${kebabCase(k)}`]
      if (typeof v !== 'string') {
        return [colorVar]
      }

      const rgb = parseToRgb(v)
      rgbVar[1] = `${rgb.red} ${rgb.green} ${rgb.blue}`

      return [colorVar, rgbVar]
    }),
  )
}
function zIndexVars(): Record<string, string | number> {
  return Object.fromEntries(
    brandZIndexes.map((key, i, arr) => [
      `--zindex-${kebabCase(key)}`,
      arr.length - i,
    ]),
  )
}
function themeSizeVars(theme: BrandTheme): Record<string, string | number> {
  return Object.fromEntries(theme.sizes.map((s, i) => [`--s${i}`, px(s)]))
}
function themeFontSizeVars(theme: BrandTheme): Record<string, string | number> {
  return Object.fromEntries(theme.fontSizes.map((s, i) => [`--fz${i}`, px(s)]))
}
function themeRadiiVars(theme: BrandTheme): Record<string, string | number> {
  return Object.fromEntries(theme.radii.map((s, i) => [`--radius${i}`, px(s)]))
}
function themeFontWeightVars(theme: BrandTheme): Record<string, string> {
  return Object.fromEntries(
    Object.entries(theme.fontWeights || {}).map(([key, value]) => [
      `--fw-${kebabCase(key)}`,
      String(value),
    ]),
  )
}
function themeFontFamilyVars(theme: BrandTheme): Record<string, string> {
  return Object.fromEntries(
    Object.entries(theme.fonts || {}).map(([key, value]) => [
      `--ff-${kebabCase(key)}`,
      String(value),
    ]),
  )
}
