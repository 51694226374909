// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CommentsBubble_comments-wrapper__\\+ulBk{align-items:center;bottom:0;display:flex;height:40px;justify-content:flex-start;left:0;min-width:40px;padding-left:10px;pointer-events:none;position:absolute}.CommentsBubble_comments-bubble__SLV6F{background-color:var(--color-background,#fff);border-radius:var(--radius2);color:#000;filter:drop-shadow(0 0 5px rgba(0,0,0,.5));font-size:var(--fz0);line-height:1;min-width:2em;padding:3px 5px;position:relative;text-align:center;top:-3px}.CommentsBubble_comments-bubble__SLV6F svg{height:6px;left:25%;position:absolute;top:100%}`, "",{"version":3,"sources":["webpack://./../../libraries/ui/src/Elements/CommentsBubble/CommentsBubble.module.scss"],"names":[],"mappings":"AACA,yCAOE,kBAAA,CALA,QAAA,CAGA,YAAA,CADA,WAAA,CAEA,0BAAA,CALA,MAAA,CAEA,cAAA,CAOA,iBAAA,CADA,mBAAA,CADA,iBAEA,CAGF,uCAIE,6CAAA,CAIA,4BAAA,CALA,UAAA,CAGA,0CAAA,CADA,oBAAA,CAKA,aAAA,CADA,aAAA,CAFA,eAAA,CANA,iBAAA,CACA,iBAAA,CASA,QAAA,CAEA,2CAEE,UAAA,CAEA,QAAA,CAHA,iBAAA,CAEA,QACA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comments-wrapper": `CommentsBubble_comments-wrapper__+ulBk`,
	"commentsWrapper": `CommentsBubble_comments-wrapper__+ulBk`,
	"comments-bubble": `CommentsBubble_comments-bubble__SLV6F`,
	"commentsBubble": `CommentsBubble_comments-bubble__SLV6F`
};
export default ___CSS_LOADER_EXPORT___;
