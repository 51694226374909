import { Box } from '@jotta/ui/Box'
import { CopyInputFragment } from '@jotta/ui/CopyInput'
import { RadixDialog } from '@jotta/ui/RadixDialog'
import { RadixDialogToast } from '@jotta/ui/RadixDialogToast'
import type { RadixModalProps } from '@jotta/ui/RadixModal'
import { RadixModal } from '@jotta/ui/RadixModal'
import { createUrl } from '@jotta/utils/url'
import { t } from '@lingui/macro'

import { useState } from 'react'
export interface CopyShareLinkDialogProps extends RadixModalProps {
  shareId: string
}

export const CopyShareLinkDialog: React.FC<CopyShareLinkDialogProps> = ({
  open = false,
  onClose,
  shareId,
  ...props
}) => {
  const [toastOpen, setToastOpen] = useState(false)
  const shareLink = createUrl('share', shareId)
  return (
    <RadixModal open={open} onClose={onClose} {...props}>
      <RadixDialog
        title={t`Share`}
        onClose={onClose}
        buttons={[
          {
            action: 'close',
            label: t`Close`,
            variant: 'buttons.secondary',
          },
        ]}
      >
        <Box variant="dialogs.shareDialogWrapper">
          <CopyInputFragment
            value={shareLink}
            onCopy={() => setToastOpen(true)}
            buttonVariant="buttons.copyInputButton"
          />
        </Box>

        <RadixDialogToast
          severity="success"
          open={open && toastOpen}
          onOpenChange={setToastOpen}
          title={t`Copied to clipboard`}
          data-testid="CopySharedPhotosSuccess"
        />
      </RadixDialog>
    </RadixModal>
  )
}
