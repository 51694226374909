import { z } from 'zod'
import { PhotoSubscriberSchema } from './PhotoSubscriberSchema'
import { AvatarSchema } from './AvatarSchema'

export const ShareInfoSchema = z.object({
  admin: z.boolean().default(false).optional(),
  authorization: z.enum(['READ', 'WRITE']).optional(),
  nonPublic: z.boolean().optional(),
  owner: z.string().optional(),
  ownerFullName: z.string().optional(),
  ownerAvatar: AvatarSchema,
  removeGeoData: z.boolean().optional(),
  secret: z.string().optional(),
  shareDate: z.number().optional(),
  subscribed_collection_publicId: z.string().optional(),
  subscribers: z.array(PhotoSubscriberSchema).optional().default([]),
  subscriptionDate: z.number().optional(),
  uri: z.string(),
})
