import { Input } from '@jotta/ui/Input'
import { Label } from '@jotta/ui/Label'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import { RadixDialog } from '@jotta/ui/RadixDialog'
import type { RadixModalProps } from '@jotta/ui/RadixModal'
import { RadixModal } from '@jotta/ui/RadixModal'
import { t } from '@lingui/macro'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { createAlbum } from '../../../api/photosApi'

const debug = Debug('jotta:photos:CreateAlbumDialog')

export interface CreateAlbumDialogProps extends RadixModalProps {
  ids?: readonly string[] | string[]
  isLoading?: boolean
  onClose: (open: boolean) => void
  createAlbumFn?: typeof createAlbum
}

export interface CreateAlbumFormValues {
  title: string
}
function focusRef(el: HTMLInputElement | null) {
  if (el) {
    el.focus()
  }
}
export const CreateAlbumDialog = observer<CreateAlbumDialogProps>(
  function CreateAlbumDialog({
    ids = [],
    isLoading = false,
    onClose,
    createAlbumFn = createAlbum,
    ...props
  }) {
    const [title, setTitle] = useState('')
    const onSubmit = async () => {
      const album = await createAlbumFn([...ids], title)
      setTitle('')
      onClose(false)
      return album
    }

    return (
      <RadixModal onClose={onClose} {...props}>
        <RadixDialog
          title={t`Create new album`}
          onClose={onClose}
          buttons={[
            {
              action: 'close',
              label: t`Cancel`,
            },
            {
              action: onSubmit,
              label: t`Save`,
              variant: 'buttons.primary',
            },
          ]}
        >
          <LoadingOverlay open={isLoading} />
          <Label>
            <Input
              ref={focusRef}
              tabIndex={0}
              placeholder={t`Album title`}
              value={title}
              onChange={e => setTitle(e.currentTarget.value)}
              onKeyDown={e => e.key === 'Enter' && onSubmit()}
            />
          </Label>
        </RadixDialog>
      </RadixModal>
    )
  },
)
