import moize from 'moize'
import { range } from 'remeda'
import Debug from 'debug'
const debug = Debug('jotta:virtualized:getSingleColumnPositions')

export interface VirtualPositions {
  height: number
  positions: {
    index: number
    height: number
    top: number
    bottom: number
  }[]
}
export const getFixedVirtualRowHeightPositions = moize(
  function getSingleColumnPositions(
    size: number,
    rowHeight: number,
  ): VirtualPositions {
    // debug('Get positions')
    let totalHeight = 0
    const positions = range(0, size).map(index => {
      const top = totalHeight
      totalHeight += rowHeight
      const bottom = totalHeight
      return {
        index,
        height: rowHeight,
        top,
        bottom,
      }
    })
    return {
      height: totalHeight,
      positions,
    }
  },
)

export const getDynamicRowHeightPositions = moize(
  function getDynamicRowHeightPositions(
    numberOfItems: number,
    getRowHeight: (index: number) => number,
  ): VirtualPositions {
    debug('Get dynamic positions')
    let totalHeight = 0
    const positions = range(0, numberOfItems).map(index => {
      const rowHeight = getRowHeight(index)
      const top = totalHeight
      totalHeight += rowHeight
      const bottom = totalHeight
      return {
        index,
        height: rowHeight,
        top,
        bottom,
      }
    })
    return {
      height: totalHeight,
      positions,
    }
  },
  {
    isShallowEqual: true,
  },
)
