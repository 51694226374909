import { Box } from '@jotta/ui/Box'
import { WaitlistStatus } from '@jotta/grpc-web/no/jotta/openapi/search/search.v2_pb'
import { Headline1 } from '@jotta/ui/Headline'
import { redirectToLogin } from '@jotta/utils/auth'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { waitlistStore } from '../../store/WaitlistStore'
import { ActiveSection } from '../SmartPhotoSearchLandingPageSections/ActiveSection'
import { InactiveSection } from '../SmartPhotoSearchLandingPageSections/InactiveSection'
import { OnWaitlistSection } from '../SmartPhotoSearchLandingPageSections/OnWaitlistSection'
import { SmartSearchDownload } from '../SmartSearchDownload/SmartSearchDownload'
import { useAuthenticated } from '@jotta/auth-client/useAuthStatus'
import { useFeatures } from '@jotta/grpc-connect-client/config'
import { useBrandName } from '@jotta/grpc-connect-client/config'

export const SmartPhotoSearchBetaLandingPage = observer(() => {
  const { waitlistStatus } = waitlistStore
  const navigate = useNavigate()
  const authenticated = useAuthenticated()
  const features = useFeatures()
  const brandName = useBrandName()

  const showLandingPage = features.photoSearch

  if (!brandName) {
    return null
  }

  const handleJoinWaitlist = () => {
    if (authenticated === 'TRUE') {
      waitlistStore.joinWaitlist()
    } else {
      redirectToLogin()
    }
  }

  const status = waitlistStatus?.getStatus()

  const handleSmartSearchRoute = (event: React.MouseEvent) => {
    event.preventDefault()
    navigate(`/photo/`)
  }

  return (
    <>
      {showLandingPage && (
        <Box
          sx={{
            variant: 'layout.downloadPageContainer',
          }}
        >
          <Box
            sx={{
              variant: 'layout.downloadPageWrapper',
              mb: '48px',
            }}
          >
            <Headline1
              sx={{
                variant: 'styles.downloadPageHeader',
              }}
            >
              {brandName} Smart Photo Search Beta! <br />
              Connecting text and images, automated by AI
            </Headline1>
          </Box>
          <Box
            sx={{
              variant: 'layout.downloadSectionContainer1',
              py: 2,
            }}
          >
            {status === WaitlistStatus.Status.INACTIVE && (
              <InactiveSection
                brandName={brandName}
                handleJoinWaitlist={handleJoinWaitlist}
              />
            )}

            {status === WaitlistStatus.Status.ON_WAITLIST && (
              <OnWaitlistSection waitlistStatus={waitlistStatus} />
            )}

            {status === WaitlistStatus.Status.ACTIVE && (
              <ActiveSection handleSmartSearchRoute={handleSmartSearchRoute} />
            )}
          </Box>
          <SmartSearchDownload />
        </Box>
      )}
    </>
  )
})
