import { Button } from '@jotta/ui/Button'
import { observer } from 'mobx-react-lite'
import type { PhotoActionHandlerActionProps } from './PhotoActionHandlers'
import { useDispatchPhotoActionMutation } from './useDispatchPhotoActionMutation'

export const ActionShowInfo = observer<
  PhotoActionHandlerActionProps<'SHOW_INFO'>
>(function ActionShowInfo({ action, dispatch, buttonVariant }) {
  const mutation = useDispatchPhotoActionMutation(
    'SHOW_INFO',
    undefined,
    dispatch,
  )
  return (
    <Button
      variant={buttonVariant}
      aria-label={action.label}
      data-testid={action.testid}
      aria-busy={mutation.isPending}
      key={action.actionType}
      icon={action.icon}
      onClick={() => mutation.mutate([])}
    >
      {action.label}
    </Button>
  )
})
