import type { GetPathItemResponse } from '@jotta/grpc-js-client/files/getPathItem'
import { getPathItem } from '@jotta/grpc-js-client/files/getPathItem'
import type {
  FileRouteContext,
  FileRouteSortBy,
} from '@jotta/types/schemas/FileRouteSchema'
import Debug from 'debug'
import type { inferData, inferVariables } from 'react-query-kit'
import { createInfiniteQuery, createQuery } from 'react-query-kit'
import { sortParamToSortBy } from '../../FileRoute/utils/sortParamToSortBy'
const debug = Debug('jotta:files:usePathItem')

export const usePathItem = createQuery<
  GetPathItemResponse,
  {
    context: FileRouteContext
    path: string
  },
  unknown
>({
  queryKey: ['PathItem'],
  fetcher: ({ path, context }) =>
    getPathItem({
      context,
      path,
      includeDeleted: context === 'trash',
      includeChildren: true,
      limit: 2,
    }),
})

export type UsePathItemOptions = Omit<
  Parameters<typeof usePathItem>['0'],
  'variables'
>
export type UsePathItemVariables = inferVariables<typeof usePathItem>
export type UsePathItemResult = inferData<typeof usePathItem>
export const useInfinitePathItem = createInfiniteQuery<
  GetPathItemResponse,
  {
    context: FileRouteContext
    sortBy: FileRouteSortBy
    path: string
  },
  unknown,
  string | undefined
>({
  queryKey: ['InfinitePathItem'],
  fetcher: ({ path, context, sortBy }, { pageParam }) =>
    getPathItem({
      cursor: pageParam,
      context,
      sort: sortParamToSortBy[sortBy],
      path,
      // Limit 50 on first page, then 100 for shared pages and 500 for all others
      // Shared is particularly slow...
      limit: pageParam ? (context === 'shared' ? 100 : 500) : 100,
      includeDeleted: context === 'trash',
      includeChildren: true,
    }),

  getNextPageParam: lastPage => lastPage.cursor || undefined,
  initialPageParam: '',
  refetchOnWindowFocus: true,
  refetchOnReconnect: true,
})
export type UseInfinitePathItemOptions = Omit<
  Parameters<typeof useInfinitePathItem>['0'],
  'variables'
>

export type UseInfinitePathItemVariables = inferVariables<
  typeof useInfinitePathItem
>

export type UseInfinitePathItemResult = inferData<typeof useInfinitePathItem>
