import { observer } from 'mobx-react-lite'
import { Outlet } from 'react-router-dom'
import { useAuthenticated } from '@jotta/auth-client/useAuthStatus'

import { WebappLayout } from '../../components/Layout/WebappLayout'
import { WebappLayoutPublic } from '../../components/Layout/WebappLayoutPublic'

export const PublicPhotoRouteContainer = observer(
  function PublicPhotoRouteContainer() {
    const authenticated = useAuthenticated() === 'TRUE'

    if (authenticated) {
      return (
        <WebappLayout section="photo">
          <Outlet />
        </WebappLayout>
      )
    }
    return (
      <WebappLayoutPublic section="sharedphotos">
        <Outlet />
      </WebappLayoutPublic>
    )
  },
)
