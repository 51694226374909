import { TextThemeUI } from '@jotta/ui/themeui'
import { t, Trans } from '@lingui/macro'
import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'
import { RadixDialog } from '../../Dialogs/RadixDialog/RadixDialog'
import type { RadixModalProps } from '../../Dialogs/RadixModal/RadixModal'
import { RadixModal } from '../../Dialogs/RadixModal/RadixModal'
import { onboardingStore } from './OnboardingStore'

export const HasUploadedDialog = observer<RadixModalProps>(
  function HasUploadedDialog({ onClose, ...props }) {
    const completed = onboardingStore.isCompleted('hasUploaded')

    const completeTask = useCallback(() => {
      onboardingStore.complete('hasUploaded')
      if (onClose) {
        onClose(false)
      }
    }, [onClose])

    const download = '/download'

    return (
      <RadixModal {...props} onClose={completeTask} open={!completed}>
        <RadixDialog
          title={t({
            id: 'Onboarding.hasUploadedTitle',
            message: 'Desktop app available for windows and mac',
          })}
          buttons={[
            {
              action: completeTask,
              label: 'OK',
            },
          ]}
        >
          <TextThemeUI variant="styles.p" sx={{ mb: 3 }}>
            <Trans id="Onboarding.hasUploaded">
              Have you tried our desktop app? Our desktop app gives you fast and
              reliable uploads from all your computers, and synchronizes your
              changes across your devices.{' '}
              <a href={download}>Download the app</a>.
            </Trans>
          </TextThemeUI>
        </RadixDialog>
      </RadixModal>
    )
  },
)
