import moize from 'moize'
import { isArray } from 'remeda'
export const getResponsiveValue = moize.shallow(
  function getResponsiveValue<T>(breakpoint: number, values: T | T[]) {
    if (!isArray(values)) {
      return values
    }
    if (breakpoint < values.length) {
      return values[breakpoint]
    }
    return values[values.length - 1]
  },
  {
    maxSize: 100,
    isDeepEqual: true,
  },
)
