import { useBreakpointIndex } from '@jotta/ui/useBreakpointIndex'
import { observer } from 'mobx-react-lite'

export interface ResponsiveTableProps {
  columns: string[]
  widths?: string[]
  rowData: Array<string | React.ReactNode>[]
  actionColumn?: (rowIndex: number) => JSX.Element
}

export const ResponsiveTable = observer<ResponsiveTableProps>(
  function ResponsiveTable({ actionColumn, columns, widths, rowData }) {
    const mobileSize: boolean = useBreakpointIndex() < 1

    return (
      <>
        <table
          sx={{
            variant: 'tables.responsiveTable',
            width: '100%',
            fontSize: 2,
            thead: {
              color: 'darkGray',
            },
            ...(mobileSize && {
              wordWrap: 'break-word',
              thead: {
                display: 'none',
              },
              'tbody,td': {
                display: 'block',
              },
              'td:not([data-scope="action"])': {
                float: 'left',
                width: 'calc(100% - 54px)',
                clear: 'both',
                boxSizing: 'border-box',
                mb: 2,
              },
              'td:before': {
                display: 'block',
                content: 'attr(data-th)',
                fontWeight: 'bold',
                fontSize: 2,
                color: 'darkGray',
              },
              tr: {
                position: 'relative',
                float: 'left',
                width: '100%',
                borderRadius: '2px',
                boxShadow:
                  '0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)',
                mb: 3,
                p: 3,
              },
              'td[data-scope="action"]': {
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
              },
            }),
          }}
        >
          {columns && (
            <thead>
              <tr>
                {columns.map((e, i) => (
                  <th
                    key={`col-${i.toString()}`}
                    data-scope="col"
                    sx={{
                      width:
                        widths && widths[i]
                          ? widths[i]
                          : `${Math.round(100 / columns.length).toString()}%`,
                    }}
                  >
                    {e}
                  </th>
                ))}
                {actionColumn && <th data-scope="col" sx={{ width: '54px' }} />}
              </tr>
            </thead>
          )}
          {rowData && (
            <tbody>
              {rowData.map((row, rowIndex) => (
                <tr
                  sx={{ variant: 'styles.tr' }}
                  key={`row-${rowIndex.toString()}`}
                >
                  {row.map((e, i) => (
                    <td key={`cell-${i.toString()}`} data-th={columns[i] || ''}>
                      {e}
                    </td>
                  ))}
                  {actionColumn && (
                    <td data-scope="action">{actionColumn(rowIndex)}</td>
                  )}
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </>
    )
  },
)
