import dayjs from 'dayjs'
import { makeAutoObservable, observable } from 'mobx'
import type {
  TimelineHeader,
  TimelineRowData,
} from '../components/PhotoGallery/useGroupByDayAndRow'

export interface LabelItem {
  offset: number // Offset from top in percentage (0 - 1)
  type: 'month' | 'year'
  date: number
  percentage: number // Size on timeline in percentage (0 - 100)
}

export class TimelineScrubberStore {
  private _rows = observable.array<TimelineRowData>([], {
    deep: false,
  })

  public get rows() {
    return this._rows
  }

  private offsets: number[] = []
  private sizes: number[] = []
  private _scrubberHeight: number = 0

  public get scrubberHeight() {
    return this._scrubberHeight
  }

  public set scrubberHeight(height: number) {
    this._scrubberHeight = height
  }

  private _scrollingParent: Document | HTMLElement | null = null

  public set scrollingParent(element: Document | HTMLElement | null) {
    this._scrollingParent = element
  }

  public get scrollingParent() {
    return this._scrollingParent
  }

  public setContent(
    rows: TimelineRowData[],
    offsets: number[],
    sizes: number[],
  ) {
    this._rows.replace(rows)
    this.offsets = offsets
    this.sizes = sizes
  }

  public get headerRows() {
    return this.rows.filter(
      (row): row is TimelineHeader => row.content === 'header',
    )
  }

  get labels(): LabelItem[] {
    if (!this.rows.length) {
      return []
    }

    if (this.rows[0].content !== 'header') {
      throw new Error('Expected first element to be a header row')
    }

    const length = Math.min(
      this.rows.length,
      this.offsets.length,
      this.sizes.length,
    )

    if (!length) {
      return []
    }

    const totalHeight = this.sizes.reduce((total, size) => total + size, 0)
    const labels: LabelItem[] = []
    let currentDate = dayjs.utc(this.rows[0].day).startOf('month').valueOf()

    for (let i = 0; i < length; i++) {
      const label = this.rows[i]

      if (
        label.content === 'header' &&
        (i === 0 ||
          currentDate !== dayjs.utc(label.day).startOf('month').valueOf())
      ) {
        const offset = this.offsets[i] / totalHeight

        labels.push({
          type:
            dayjs.utc(currentDate).year() === dayjs.utc(label.day).year()
              ? 'month'
              : 'year',
          date: label.day,
          offset,
          percentage: 0,
        })

        currentDate = dayjs.utc(label.day).startOf('month').valueOf()
      }
    }

    // Set percentage heights
    for (let i = labels.length - 1; i >= 0; i--) {
      const offset = i < labels.length - 1 ? labels[i + 1].offset : 1
      labels[i].percentage = (offset - labels[i].offset) * 100
    }

    // Shift offsets by one since markers in scrubber are at the bottom
    for (let i = 0; i < labels.length; i++) {
      labels[i].offset = i < labels.length - 1 ? labels[i + 1].offset : 1
      labels[i].type = i < labels.length - 1 ? labels[i + 1].type : 'month'
    }

    return labels
  }

  get visibleLabels(): LabelItem[] {
    if (!this.labels.length) {
      return []
    }

    const YEAR = 14
    const MONTH = 8
    const labels: LabelItem[] = []
    let extraHeight = 0
    let yearHeight = 0

    const yearSlider: { labels: LabelItem[]; totalHeight: number } = {
      labels: [],
      totalHeight: 0,
    }

    for (let i = 0; i < this.labels.length; i++) {
      const label = this.labels[i]
      const height = (label.percentage * this.scrubberHeight) / 100
      yearHeight += height

      if (label.type === 'month') {
        if (height + extraHeight >= MONTH) {
          const percentage = extraHeight
            ? ((height + extraHeight) / this.scrubberHeight) * 100
            : label.percentage

          // Handle slider
          yearSlider.labels.push({ ...label, percentage })
          yearSlider.totalHeight += height + extraHeight

          while (
            yearSlider.totalHeight -
              (yearSlider.labels[0].percentage * this.scrubberHeight) / 100 >=
            YEAR
          ) {
            const insertLabel = yearSlider.labels.shift()
            if (insertLabel) {
              labels.push(insertLabel)
              yearSlider.totalHeight -=
                (insertLabel.percentage * this.scrubberHeight) / 100
            }
          }

          extraHeight = 0
        } else {
          extraHeight += height
        }
      } else {
        // Year
        if (yearHeight >= YEAR) {
          while (
            yearSlider.labels.length &&
            yearSlider.totalHeight +
              height -
              (yearSlider.labels[0].percentage * this.scrubberHeight) / 100 >=
              MONTH
          ) {
            const insertLabel = yearSlider.labels.shift()
            if (insertLabel) {
              labels.push(insertLabel)
              yearSlider.totalHeight -=
                (insertLabel.percentage * this.scrubberHeight) / 100
            }
          }

          extraHeight += yearSlider.totalHeight
          yearSlider.labels = []
          yearSlider.totalHeight = 0
          const percentage = extraHeight
            ? ((height + extraHeight) / this.scrubberHeight) * 100
            : label.percentage
          labels.push({ ...label, percentage })
          extraHeight = 0
          yearHeight = 0
        } else {
          extraHeight += height
        }
      }
    }

    labels.push(...yearSlider.labels)

    return labels
  }

  constructor() {
    makeAutoObservable(this)
  }
}

let timelineScrubberStore: TimelineScrubberStore

export function getTimelineScrubberStore() {
  if (!timelineScrubberStore) {
    timelineScrubberStore = new TimelineScrubberStore()
  }
  return timelineScrubberStore
}
