import {
  createPath,
  getFilenameFromPath,
  popPath,
  removeTrailingSlash,
  uriDecodeRoutePath,
} from '@jotta/utils/pathUtils'
import Debug from 'debug'
import { makeAutoObservable, observable } from 'mobx'
// import { makeLoggable } from 'mobx-log'
import type { Params } from 'react-router-dom'
import { isNumber } from 'remeda'
import path from 'path'
import { pick } from 'radash'
import type {
  Router,
  RouterNavigateFn,
  RouterNavigateOptions,
  RouterState,
  To,
} from './Types'
import { z } from 'zod'
const log = Debug('jotta:router:Store')
const actionSchema = z.preprocess(
  v => String(v).toLocaleLowerCase(),
  z.enum(['share', 'edit', '']).default('').catch(''),
)
export type RouteActionParam = z.infer<typeof actionSchema>
export class RouterStore {
  // #region Properties (6)

  #router: Router
  #unsubscribe: VoidFunction | null = null

  public encodedPathname = '/'
  public params: Params<string> = {}

  /**
   * Return the current route ID if specifified in the route definition, otherwise an
   * empty string is returned
   */
  public routeId = ''
  public search = ''

  // #endregion Properties (6)

  // #region Constructors (1)

  constructor(router: Router) {
    this.#router = router
    this.updateState(router.state)
    makeAutoObservable<typeof this, '#unsubscribe' | '#router'>(
      this,
      {
        '#router': false,
        '#unsubscribe': false,
        params: observable.struct,
      },
      {
        autoBind: true,
        name: 'Jotta.Router',
      },
    )
    // makeLoggable(this)
    log('Create, initial path: %s%s', this.pathname, this.search)
    this.#unsubscribe = router.subscribe(this.onRouteChange)
  }

  // #endregion Constructors (1)

  // #region Public Accessors (15)

  /**
   * Get action search param, if any
   */
  public get action(): RouteActionParam {
    try {
      const action = actionSchema.parse(
        new URLSearchParams(this.search).get('action'),
      )
      return action
    } catch (error) {
      return ''
    }
  }

  /**
   * Get the base route path (path with splat removed)
   */
  public get basepath() {
    return this.pathname.slice(0, this.pathname.length - this.splat.length)
  }

  /**
   * Get array of route paths starting with basepath to current route path
   */
  public get breadcrumb() {
    return this.relativePathnameArray.map((p, i, arr) =>
      createPath(this.basepath, ...arr.slice(0, i + 1)),
    )
  }

  public get depth() {
    return this.relativePathnameArray.length
  }

  getSearchParams() {
    return new URLSearchParams(this.search)
  }

  public get fileExtension() {
    return path.extname(this.filename).slice(1)
  }

  /**
   * Get the filename portion of the current path
   */
  public get filename() {
    return getFilenameFromPath(this.pathname)
  }

  /**
   * Route is considered a root if the splat is empty or /
   */
  public get isRoot() {
    return !this.depth
  }

  /**
   * The id is defined in apps/webapp/src/routes/RootRoutes.tsx
   */
  public get isSearch() {
    return this.routeId === 'search'
  }

  /**
   * Get the parent route path
   */
  public get parentPathname() {
    return popPath(this.pathname)
  }

  /**
   * Get the current route path
   */
  public get pathname() {
    return uriDecodeRoutePath(this.encodedPathname)
  }

  /**
   * Get the search query param when the route is search
   */
  public get query() {
    return this.isSearch ? this.params.query || '' : ''
  }

  /**
   * Get the pathname for the splat (*) portion of the current path
   */
  public get relativePathname() {
    return removeTrailingSlash(createPath(this.splat))
  }

  /**
   * Get the pathname for the splat (*) portion of the current path as array
   */
  public get relativePathnameArray() {
    return this.relativePathname.split('/')
  }

  /**
   * Get the * portion of the current path
   */
  public get splat() {
    return this.params['*'] || ''
  }

  public get state() {
    return pick(this, [
      'action',
      'basepath',
      'breadcrumb',
      'depth',
      'encodedPathname',
      'isRoot',
      'params',
      'pathname',
      'relativePathnameArray',
      'relativePathname',
      'routeId',
      'search',
      'splat',
    ])
  }

  // #endregion Public Accessors (15)

  // #region Public Methods (7)

  /**
   * Move back in history
   */
  public back() {
    this.navigate(-1)
  }

  /**
   * Move forward in history
   */
  public forward() {
    this.navigate(1)
  }

  public searchParam(params: URLSearchParams) {
    log('searchParam', `?${params}`)
    this.#router.navigate(`?${params}`)
  }
  public clearSearchParam() {
    this.#router.navigate('?')
  }

  public handleAction(action: string, fn: () => void) {
    if (this.action && this.action === action) {
      fn()
      this.#router.navigate(this.pathname, {
        replace: true,
      })
    }
  }

  /**
   * Navigate forward/backward in the history stack
   * @param args
   */
  public navigate(to: To, options?: RouterNavigateOptions) {
    log('navigate', to, options)
    // Override incorrect typings
    const navigate = this.#router.navigate as any as RouterNavigateFn

    // Typescript doesn't infer correct types for navigate unless we needlessly check
    // the type of the to param
    if (isNumber(to)) {
      navigate(to, options)
      return
    }
    navigate(to, options)
  }

  /**
   * React Router route change listener, bound in the constructor
   * Updates state, when a route changes
   * React Router calls the listener AFTER the change has happened, so it can't be
   * prevented
   * @param state
   */
  public onRouteChange(state: RouterState) {
    this.updateState(state)

    log(
      '%s %s%s',
      state.historyAction,
      state.location.pathname,
      state.location.search,
      this.state,
      state,
    )
  }

  /**
   * Navigate to the parent route
   * @param replace
   */
  public up(replace?: boolean) {
    this.navigate(this.parentPathname, {
      replace,
    })
  }

  public updateState(state: RouterState) {
    const serialized = serializeRouterState(state)
    const { encodedPathname, params, pathname, search, routeId } = serialized
    if (
      document.body.classList.contains('fab-open') &&
      this.pathname !== pathname
    ) {
      document.body.classList.remove('fab-open')
    }
    this.search = search
    this.encodedPathname = encodedPathname
    this.params = params
    this.routeId = routeId
    return serialized
  }

  // #endregion Public Methods (7)
}

function serializeRouterState(state: RouterState) {
  const encodedPathname = state.location.pathname
  const pathname = uriDecodeRoutePath(encodedPathname)
  const match = state.matches.at(-1)
  const serialized = {
    search: state.location.search,
    encodedPathname,
    pathname,
    params: match?.params || {},
    routeId: match?.route.id || '',
  }
  return serialized
}
