import { FormattedContent } from '@jotta/ui/FormattedContent'
import { RadixDialog } from '@jotta/ui/RadixDialog'
import { RadixModal } from '@jotta/ui/RadixModal'
import { filesize } from '@jotta/utils/filesize'
import { Trans, t } from '@lingui/macro'
import type { FC, FormEvent } from 'react'

export interface DowngradeSubscriptionDialogProps {
  isOpen: boolean
  onCancel?: () => void
  errorMessage: string
  isFamily: boolean
  storage: number
  familyMemberCount?: number
  handleSubmit: (event?: FormEvent) => void
  freeSpaceExceeded?: boolean
}

export const DowngradeSubscriptionDialog: FC<
  DowngradeSubscriptionDialogProps
> = ({
  isOpen,
  onCancel = (open?: boolean) => {},
  errorMessage,
  isFamily = false,
  familyMemberCount = 0,
  storage: storageBytes,
  handleSubmit = (event?: FormEvent) => {
    event?.preventDefault()
  },
  freeSpaceExceeded = false,
}) => {
  if (!isOpen) {
    return null
  }
  const storage = filesize(storageBytes)

  if (freeSpaceExceeded) {
    return (
      <RadixModal open={isOpen} onClose={onCancel}>
        <RadixDialog
          title={t({ id: 'Downgrade to a free subscription' })}
          errorMessage={errorMessage}
          buttons={[
            {
              action: onCancel,
              label: t({ id: 'Cancel' }),
              variant: 'buttons.secondary',
            },
          ]}
        >
          <FormattedContent>
            <p>
              <Trans>
                You have stored more than {storage} in your account, you need to
                reduce your storage amount to be able to downgrade.
              </Trans>
            </p>
          </FormattedContent>
        </RadixDialog>
      </RadixModal>
    )
  }

  if (isFamily && familyMemberCount > 0) {
    return (
      <RadixModal open={isOpen} onClose={onCancel}>
        <RadixDialog
          onClose={onCancel}
          title={t({ id: 'Downgrade to a free subscription' })}
          errorMessage={errorMessage}
          buttons={[
            {
              action: 'close',
              label: t({ id: 'Cancel' }),
              variant: 'buttons.secondary',
            },
          ]}
        >
          <FormattedContent>
            <p>
              <Trans id="You must remove all family members from your current subscription before you can downgrade to a free subscription. This can be done on the <0>family settings page</0>.">
                You must remove all family members from your current
                subscription before you can downgrade to a free subscription.
                This can be done on the{' '}
                <a href="/web/family#family">family settings page</a>.
              </Trans>
            </p>
          </FormattedContent>
        </RadixDialog>
      </RadixModal>
    )
  }

  return (
    <RadixModal open={isOpen} onClose={onCancel}>
      <RadixDialog
        onClose={onCancel}
        title={t({ id: 'Downgrade to a free subscription' })}
        errorMessage={errorMessage}
        buttons={[
          {
            action: 'close',
            label: t({ id: 'Cancel' }),
          },
          {
            label: t({ id: 'Downgrade subscription' }),
            action: handleSubmit,
            variant: 'buttons.error',
          },
        ]}
      >
        <FormattedContent>
          <p>
            <Trans>Are you sure you want to cancel?</Trans>
          </p>
          <p>
            <Trans>
              By cancelling this subscription you will be downgraded to a free
              account with only {storage} space.
            </Trans>
          </p>
          <p>
            <Trans>
              If you still want to downgrade, click the button below and confirm
              with your password.
            </Trans>
          </p>
        </FormattedContent>
      </RadixDialog>
    </RadixModal>
  )
}
