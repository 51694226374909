import type { WopiActionTypeKey } from '@jotta/grpc-js-client/wopiService'
import type { PathItemObject } from '@jotta/types/Files'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { WopiView } from './WopiView'
import { useWopiLinks } from './useWopiLinks'

const debug = Debug('jotta:files:WopiViewContainer')

export type WopiPostMessageType =
  | 'File_Rename'
  // The UI_Sharing message is posted when the user activates the Share UI in Office for the web.
  // The host should use this message to trigger any custom sharing UI.
  | 'UI_Sharing'
  // The UI_Close message is posted when the Office for the web application is closing, either due to an error or a user action
  | 'UI_Close'
  // The UI_Edit message is posted when the user activates the Edit UI in Office for the web. This UI is only visible when using the view action.
  | 'UI_Edit'
export type WopiPostMessage = {
  MessageId: WopiPostMessageType
  SendTime: number
  Values: Record<string, string>
}
export interface WopiViewContainerProps {
  item: PathItemObject
  shareToken?: string
  onWopiEvent?: (e: WopiPostMessageType, data?: string) => void
}
export const WopiViewContainer = observer(function WopiViewContainer({
  item,
  shareToken,
  onWopiEvent,
  ...props
}: WopiViewContainerProps) {
  const [search] = useSearchParams()
  const filePath = item.path
  const fileExtension = filePath.split('.').pop()?.toLowerCase() || ''

  const isTestFile = ['wopitest', 'wopitestx'].includes(fileExtension) && 'VIEW'
  const isNewFile = !item.size && 'EDITNEW'
  const isEditAction = !isNewFile && search.get('action') === 'edit' && 'EDIT'
  const isLegacyFormat =
    isEditAction && ['doc', 'ppt', 'xls'].includes(fileExtension) && 'CONVERT'
  const action: WopiActionTypeKey =
    isTestFile || isNewFile || isLegacyFormat || isEditAction || 'EMBEDVIEW'
  const showWopiEditView = action !== 'EMBEDVIEW'

  useEffect(() => {
    console.group('WopiView')
    return () => {
      console.groupEnd()
    }
  }, [])

  const query = useWopiLinks({
    variables: {
      action,
      path: filePath,
      shareToken,
    },
  })

  const wopiLinks = query.data

  return (
    <>
      <LoadingOverlay open={query.isInitialLoading} />
      {wopiLinks && (
        <WopiView
          wopiLinks={wopiLinks}
          item={item}
          onWopiEvent={(e, data) => {
            debug('onWopiEvent', e, data)
          }}
          showWopiEditView={showWopiEditView}
        />
      )}
    </>
  )
})
