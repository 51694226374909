/**
 * THIS FILE IS AUTOGENERATED
 * Do not edit directly as it will be overwritten by the task that generated it
 */

import { Country } from '@jotta/grpc-connect-openapi/esm/openapi/country_pb'
import { t } from '@lingui/macro'

export type CountryCode =
  | 'ad'
  | 'ae'
  | 'af'
  | 'ag'
  | 'ai'
  | 'al'
  | 'am'
  | 'ao'
  | 'aq'
  | 'ar'
  | 'as'
  | 'at'
  | 'au'
  | 'aw'
  | 'az'
  | 'ba'
  | 'bb'
  | 'bd'
  | 'be'
  | 'bf'
  | 'bg'
  | 'bh'
  | 'bi'
  | 'bj'
  | 'bl'
  | 'bm'
  | 'bn'
  | 'bo'
  | 'br'
  | 'bs'
  | 'bt'
  | 'bv'
  | 'bw'
  | 'by'
  | 'bz'
  | 'ca'
  | 'cc'
  | 'cd'
  | 'cf'
  | 'cg'
  | 'ch'
  | 'ci'
  | 'ck'
  | 'cl'
  | 'cm'
  | 'cn'
  | 'co'
  | 'cr'
  | 'cu'
  | 'cv'
  | 'cx'
  | 'cy'
  | 'cz'
  | 'de'
  | 'dj'
  | 'dk'
  | 'dm'
  | 'do'
  | 'dz'
  | 'ec'
  | 'ee'
  | 'eg'
  | 'eh'
  | 'er'
  | 'es'
  | 'et'
  | 'fi'
  | 'fj'
  | 'fk'
  | 'fm'
  | 'fo'
  | 'fr'
  | 'ga'
  | 'gb'
  | 'gd'
  | 'ge'
  | 'gf'
  | 'gg'
  | 'gh'
  | 'gi'
  | 'gl'
  | 'gm'
  | 'gn'
  | 'gp'
  | 'gq'
  | 'gr'
  | 'gs'
  | 'gt'
  | 'gu'
  | 'gw'
  | 'gy'
  | 'hk'
  | 'hm'
  | 'hn'
  | 'hr'
  | 'ht'
  | 'hu'
  | 'id'
  | 'ie'
  | 'il'
  | 'im'
  | 'in'
  | 'io'
  | 'iq'
  | 'ir'
  | 'is'
  | 'it'
  | 'je'
  | 'jm'
  | 'jo'
  | 'jp'
  | 'ke'
  | 'kg'
  | 'kh'
  | 'ki'
  | 'km'
  | 'kn'
  | 'kp'
  | 'kr'
  | 'kw'
  | 'ky'
  | 'kz'
  | 'la'
  | 'lb'
  | 'lc'
  | 'li'
  | 'lk'
  | 'lr'
  | 'ls'
  | 'lt'
  | 'lu'
  | 'lv'
  | 'ly'
  | 'ma'
  | 'mc'
  | 'md'
  | 'me'
  | 'mf'
  | 'mg'
  | 'mh'
  | 'mk'
  | 'ml'
  | 'mm'
  | 'mn'
  | 'mo'
  | 'mp'
  | 'mq'
  | 'mr'
  | 'ms'
  | 'mt'
  | 'mu'
  | 'mv'
  | 'mw'
  | 'mx'
  | 'my'
  | 'mz'
  | 'na'
  | 'nc'
  | 'ne'
  | 'nf'
  | 'ng'
  | 'ni'
  | 'nl'
  | 'no'
  | 'np'
  | 'nr'
  | 'nu'
  | 'nz'
  | 'om'
  | 'pa'
  | 'pe'
  | 'pf'
  | 'pg'
  | 'ph'
  | 'pk'
  | 'pl'
  | 'pm'
  | 'pn'
  | 'pr'
  | 'ps'
  | 'pt'
  | 'pw'
  | 'py'
  | 'qa'
  | 're'
  | 'ro'
  | 'rs'
  | 'ru'
  | 'rw'
  | 'sa'
  | 'sb'
  | 'sc'
  | 'sd'
  | 'se'
  | 'sg'
  | 'sh'
  | 'si'
  | 'sj'
  | 'sk'
  | 'sl'
  | 'sm'
  | 'sn'
  | 'so'
  | 'sr'
  | 'st'
  | 'sv'
  | 'sy'
  | 'sz'
  | 'tc'
  | 'td'
  | 'tf'
  | 'tg'
  | 'th'
  | 'tj'
  | 'tk'
  | 'tl'
  | 'tm'
  | 'tn'
  | 'to'
  | 'tr'
  | 'tt'
  | 'tv'
  | 'tw'
  | 'tz'
  | 'ua'
  | 'ug'
  | 'um'
  | 'us'
  | 'uy'
  | 'uz'
  | 'va'
  | 'vc'
  | 've'
  | 'vg'
  | 'vi'
  | 'vn'
  | 'vu'
  | 'wf'
  | 'ws'
  | 'ye'
  | 'yt'
  | 'za'
  | 'zm'
  | 'zw'
export const countryCodes: readonly CountryCode[] = [
  'ad',
  'ae',
  'af',
  'ag',
  'ai',
  'al',
  'am',
  'ao',
  'aq',
  'ar',
  'as',
  'at',
  'au',
  'aw',
  'az',
  'ba',
  'bb',
  'bd',
  'be',
  'bf',
  'bg',
  'bh',
  'bi',
  'bj',
  'bl',
  'bm',
  'bn',
  'bo',
  'br',
  'bs',
  'bt',
  'bv',
  'bw',
  'by',
  'bz',
  'ca',
  'cc',
  'cd',
  'cf',
  'cg',
  'ch',
  'ci',
  'ck',
  'cl',
  'cm',
  'cn',
  'co',
  'cr',
  'cu',
  'cv',
  'cx',
  'cy',
  'cz',
  'de',
  'dj',
  'dk',
  'dm',
  'do',
  'dz',
  'ec',
  'ee',
  'eg',
  'eh',
  'er',
  'es',
  'et',
  'fi',
  'fj',
  'fk',
  'fm',
  'fo',
  'fr',
  'ga',
  'gb',
  'gd',
  'ge',
  'gf',
  'gg',
  'gh',
  'gi',
  'gl',
  'gm',
  'gn',
  'gp',
  'gq',
  'gr',
  'gs',
  'gt',
  'gu',
  'gw',
  'gy',
  'hk',
  'hm',
  'hn',
  'hr',
  'ht',
  'hu',
  'id',
  'ie',
  'il',
  'im',
  'in',
  'io',
  'iq',
  'ir',
  'is',
  'it',
  'je',
  'jm',
  'jo',
  'jp',
  'ke',
  'kg',
  'kh',
  'ki',
  'km',
  'kn',
  'kp',
  'kr',
  'kw',
  'ky',
  'kz',
  'la',
  'lb',
  'lc',
  'li',
  'lk',
  'lr',
  'ls',
  'lt',
  'lu',
  'lv',
  'ly',
  'ma',
  'mc',
  'md',
  'me',
  'mf',
  'mg',
  'mh',
  'mk',
  'ml',
  'mm',
  'mn',
  'mo',
  'mp',
  'mq',
  'mr',
  'ms',
  'mt',
  'mu',
  'mv',
  'mw',
  'mx',
  'my',
  'mz',
  'na',
  'nc',
  'ne',
  'nf',
  'ng',
  'ni',
  'nl',
  'no',
  'np',
  'nr',
  'nu',
  'nz',
  'om',
  'pa',
  'pe',
  'pf',
  'pg',
  'ph',
  'pk',
  'pl',
  'pm',
  'pn',
  'pr',
  'ps',
  'pt',
  'pw',
  'py',
  'qa',
  're',
  'ro',
  'rs',
  'ru',
  'rw',
  'sa',
  'sb',
  'sc',
  'sd',
  'se',
  'sg',
  'sh',
  'si',
  'sj',
  'sk',
  'sl',
  'sm',
  'sn',
  'so',
  'sr',
  'st',
  'sv',
  'sy',
  'sz',
  'tc',
  'td',
  'tf',
  'tg',
  'th',
  'tj',
  'tk',
  'tl',
  'tm',
  'tn',
  'to',
  'tr',
  'tt',
  'tv',
  'tw',
  'tz',
  'ua',
  'ug',
  'um',
  'us',
  'uy',
  'uz',
  'va',
  'vc',
  've',
  'vg',
  'vi',
  'vn',
  'vu',
  'wf',
  'ws',
  'ye',
  'yt',
  'za',
  'zm',
  'zw',
] as const

export interface CountryInfo {
  key: string
  code: CountryCode
  value: string
}

export const countries: readonly CountryInfo[] = [
  {
    key: 'COUNTRY__AD',
    code: 'ad',
    value: t({ id: 'COUNTRY__AD' }),
  },
  {
    key: 'COUNTRY__AE',
    code: 'ae',
    value: t({ id: 'COUNTRY__AE' }),
  },
  {
    key: 'COUNTRY__AF',
    code: 'af',
    value: t({ id: 'COUNTRY__AF' }),
  },
  {
    key: 'COUNTRY__AG',
    code: 'ag',
    value: t({ id: 'COUNTRY__AG' }),
  },
  {
    key: 'COUNTRY__AI',
    code: 'ai',
    value: t({ id: 'COUNTRY__AI' }),
  },
  {
    key: 'COUNTRY__AL',
    code: 'al',
    value: t({ id: 'COUNTRY__AL' }),
  },
  {
    key: 'COUNTRY__AM',
    code: 'am',
    value: t({ id: 'COUNTRY__AM' }),
  },
  {
    key: 'COUNTRY__AO',
    code: 'ao',
    value: t({ id: 'COUNTRY__AO' }),
  },
  {
    key: 'COUNTRY__AQ',
    code: 'aq',
    value: t({ id: 'COUNTRY__AQ' }),
  },
  {
    key: 'COUNTRY__AR',
    code: 'ar',
    value: t({ id: 'COUNTRY__AR' }),
  },
  {
    key: 'COUNTRY__AS',
    code: 'as',
    value: t({ id: 'COUNTRY__AS' }),
  },
  {
    key: 'COUNTRY__AT',
    code: 'at',
    value: t({ id: 'COUNTRY__AT' }),
  },
  {
    key: 'COUNTRY__AU',
    code: 'au',
    value: t({ id: 'COUNTRY__AU' }),
  },
  {
    key: 'COUNTRY__AW',
    code: 'aw',
    value: t({ id: 'COUNTRY__AW' }),
  },
  {
    key: 'COUNTRY__AZ',
    code: 'az',
    value: t({ id: 'COUNTRY__AZ' }),
  },
  {
    key: 'COUNTRY__BA',
    code: 'ba',
    value: t({ id: 'COUNTRY__BA' }),
  },
  {
    key: 'COUNTRY__BB',
    code: 'bb',
    value: t({ id: 'COUNTRY__BB' }),
  },
  {
    key: 'COUNTRY__BD',
    code: 'bd',
    value: t({ id: 'COUNTRY__BD' }),
  },
  {
    key: 'COUNTRY__BE',
    code: 'be',
    value: t({ id: 'COUNTRY__BE' }),
  },
  {
    key: 'COUNTRY__BF',
    code: 'bf',
    value: t({ id: 'COUNTRY__BF' }),
  },
  {
    key: 'COUNTRY__BG',
    code: 'bg',
    value: t({ id: 'COUNTRY__BG' }),
  },
  {
    key: 'COUNTRY__BH',
    code: 'bh',
    value: t({ id: 'COUNTRY__BH' }),
  },
  {
    key: 'COUNTRY__BI',
    code: 'bi',
    value: t({ id: 'COUNTRY__BI' }),
  },
  {
    key: 'COUNTRY__BJ',
    code: 'bj',
    value: t({ id: 'COUNTRY__BJ' }),
  },
  {
    key: 'COUNTRY__BL',
    code: 'bl',
    value: t({ id: 'COUNTRY__BL' }),
  },
  {
    key: 'COUNTRY__BM',
    code: 'bm',
    value: t({ id: 'COUNTRY__BM' }),
  },
  {
    key: 'COUNTRY__BN',
    code: 'bn',
    value: t({ id: 'COUNTRY__BN' }),
  },
  {
    key: 'COUNTRY__BO',
    code: 'bo',
    value: t({ id: 'COUNTRY__BO' }),
  },
  {
    key: 'COUNTRY__BR',
    code: 'br',
    value: t({ id: 'COUNTRY__BR' }),
  },
  {
    key: 'COUNTRY__BS',
    code: 'bs',
    value: t({ id: 'COUNTRY__BS' }),
  },
  {
    key: 'COUNTRY__BT',
    code: 'bt',
    value: t({ id: 'COUNTRY__BT' }),
  },
  {
    key: 'COUNTRY__BV',
    code: 'bv',
    value: t({ id: 'COUNTRY__BV' }),
  },
  {
    key: 'COUNTRY__BW',
    code: 'bw',
    value: t({ id: 'COUNTRY__BW' }),
  },
  {
    key: 'COUNTRY__BY',
    code: 'by',
    value: t({ id: 'COUNTRY__BY' }),
  },
  {
    key: 'COUNTRY__BZ',
    code: 'bz',
    value: t({ id: 'COUNTRY__BZ' }),
  },
  {
    key: 'COUNTRY__CA',
    code: 'ca',
    value: t({ id: 'COUNTRY__CA' }),
  },
  {
    key: 'COUNTRY__CC',
    code: 'cc',
    value: t({ id: 'COUNTRY__CC' }),
  },
  {
    key: 'COUNTRY__CD',
    code: 'cd',
    value: t({ id: 'COUNTRY__CD' }),
  },
  {
    key: 'COUNTRY__CF',
    code: 'cf',
    value: t({ id: 'COUNTRY__CF' }),
  },
  {
    key: 'COUNTRY__CG',
    code: 'cg',
    value: t({ id: 'COUNTRY__CG' }),
  },
  {
    key: 'COUNTRY__CH',
    code: 'ch',
    value: t({ id: 'COUNTRY__CH' }),
  },
  {
    key: 'COUNTRY__CI',
    code: 'ci',
    value: t({ id: 'COUNTRY__CI' }),
  },
  {
    key: 'COUNTRY__CK',
    code: 'ck',
    value: t({ id: 'COUNTRY__CK' }),
  },
  {
    key: 'COUNTRY__CL',
    code: 'cl',
    value: t({ id: 'COUNTRY__CL' }),
  },
  {
    key: 'COUNTRY__CM',
    code: 'cm',
    value: t({ id: 'COUNTRY__CM' }),
  },
  {
    key: 'COUNTRY__CN',
    code: 'cn',
    value: t({ id: 'COUNTRY__CN' }),
  },
  {
    key: 'COUNTRY__CO',
    code: 'co',
    value: t({ id: 'COUNTRY__CO' }),
  },
  {
    key: 'COUNTRY__CR',
    code: 'cr',
    value: t({ id: 'COUNTRY__CR' }),
  },
  {
    key: 'COUNTRY__CU',
    code: 'cu',
    value: t({ id: 'COUNTRY__CU' }),
  },
  {
    key: 'COUNTRY__CV',
    code: 'cv',
    value: t({ id: 'COUNTRY__CV' }),
  },
  {
    key: 'COUNTRY__CX',
    code: 'cx',
    value: t({ id: 'COUNTRY__CX' }),
  },
  {
    key: 'COUNTRY__CY',
    code: 'cy',
    value: t({ id: 'COUNTRY__CY' }),
  },
  {
    key: 'COUNTRY__CZ',
    code: 'cz',
    value: t({ id: 'COUNTRY__CZ' }),
  },
  {
    key: 'COUNTRY__DE',
    code: 'de',
    value: t({ id: 'COUNTRY__DE' }),
  },
  {
    key: 'COUNTRY__DJ',
    code: 'dj',
    value: t({ id: 'COUNTRY__DJ' }),
  },
  {
    key: 'COUNTRY__DK',
    code: 'dk',
    value: t({ id: 'COUNTRY__DK' }),
  },
  {
    key: 'COUNTRY__DM',
    code: 'dm',
    value: t({ id: 'COUNTRY__DM' }),
  },
  {
    key: 'COUNTRY__DO',
    code: 'do',
    value: t({ id: 'COUNTRY__DO' }),
  },
  {
    key: 'COUNTRY__DZ',
    code: 'dz',
    value: t({ id: 'COUNTRY__DZ' }),
  },
  {
    key: 'COUNTRY__EC',
    code: 'ec',
    value: t({ id: 'COUNTRY__EC' }),
  },
  {
    key: 'COUNTRY__EE',
    code: 'ee',
    value: t({ id: 'COUNTRY__EE' }),
  },
  {
    key: 'COUNTRY__EG',
    code: 'eg',
    value: t({ id: 'COUNTRY__EG' }),
  },
  {
    key: 'COUNTRY__EH',
    code: 'eh',
    value: t({ id: 'COUNTRY__EH' }),
  },
  {
    key: 'COUNTRY__ER',
    code: 'er',
    value: t({ id: 'COUNTRY__ER' }),
  },
  {
    key: 'COUNTRY__ES',
    code: 'es',
    value: t({ id: 'COUNTRY__ES' }),
  },
  {
    key: 'COUNTRY__ET',
    code: 'et',
    value: t({ id: 'COUNTRY__ET' }),
  },
  {
    key: 'COUNTRY__FI',
    code: 'fi',
    value: t({ id: 'COUNTRY__FI' }),
  },
  {
    key: 'COUNTRY__FJ',
    code: 'fj',
    value: t({ id: 'COUNTRY__FJ' }),
  },
  {
    key: 'COUNTRY__FK',
    code: 'fk',
    value: t({ id: 'COUNTRY__FK' }),
  },
  {
    key: 'COUNTRY__FM',
    code: 'fm',
    value: t({ id: 'COUNTRY__FM' }),
  },
  {
    key: 'COUNTRY__FO',
    code: 'fo',
    value: t({ id: 'COUNTRY__FO' }),
  },
  {
    key: 'COUNTRY__FR',
    code: 'fr',
    value: t({ id: 'COUNTRY__FR' }),
  },
  {
    key: 'COUNTRY__GA',
    code: 'ga',
    value: t({ id: 'COUNTRY__GA' }),
  },
  {
    key: 'COUNTRY__GB',
    code: 'gb',
    value: t({ id: 'COUNTRY__GB' }),
  },
  {
    key: 'COUNTRY__GD',
    code: 'gd',
    value: t({ id: 'COUNTRY__GD' }),
  },
  {
    key: 'COUNTRY__GE',
    code: 'ge',
    value: t({ id: 'COUNTRY__GE' }),
  },
  {
    key: 'COUNTRY__GF',
    code: 'gf',
    value: t({ id: 'COUNTRY__GF' }),
  },
  {
    key: 'COUNTRY__GG',
    code: 'gg',
    value: t({ id: 'COUNTRY__GG' }),
  },
  {
    key: 'COUNTRY__GH',
    code: 'gh',
    value: t({ id: 'COUNTRY__GH' }),
  },
  {
    key: 'COUNTRY__GI',
    code: 'gi',
    value: t({ id: 'COUNTRY__GI' }),
  },
  {
    key: 'COUNTRY__GL',
    code: 'gl',
    value: t({ id: 'COUNTRY__GL' }),
  },
  {
    key: 'COUNTRY__GM',
    code: 'gm',
    value: t({ id: 'COUNTRY__GM' }),
  },
  {
    key: 'COUNTRY__GN',
    code: 'gn',
    value: t({ id: 'COUNTRY__GN' }),
  },
  {
    key: 'COUNTRY__GP',
    code: 'gp',
    value: t({ id: 'COUNTRY__GP' }),
  },
  {
    key: 'COUNTRY__GQ',
    code: 'gq',
    value: t({ id: 'COUNTRY__GQ' }),
  },
  {
    key: 'COUNTRY__GR',
    code: 'gr',
    value: t({ id: 'COUNTRY__GR' }),
  },
  {
    key: 'COUNTRY__GS',
    code: 'gs',
    value: t({ id: 'COUNTRY__GS' }),
  },
  {
    key: 'COUNTRY__GT',
    code: 'gt',
    value: t({ id: 'COUNTRY__GT' }),
  },
  {
    key: 'COUNTRY__GU',
    code: 'gu',
    value: t({ id: 'COUNTRY__GU' }),
  },
  {
    key: 'COUNTRY__GW',
    code: 'gw',
    value: t({ id: 'COUNTRY__GW' }),
  },
  {
    key: 'COUNTRY__GY',
    code: 'gy',
    value: t({ id: 'COUNTRY__GY' }),
  },
  {
    key: 'COUNTRY__HK',
    code: 'hk',
    value: t({ id: 'COUNTRY__HK' }),
  },
  {
    key: 'COUNTRY__HM',
    code: 'hm',
    value: t({ id: 'COUNTRY__HM' }),
  },
  {
    key: 'COUNTRY__HN',
    code: 'hn',
    value: t({ id: 'COUNTRY__HN' }),
  },
  {
    key: 'COUNTRY__HR',
    code: 'hr',
    value: t({ id: 'COUNTRY__HR' }),
  },
  {
    key: 'COUNTRY__HT',
    code: 'ht',
    value: t({ id: 'COUNTRY__HT' }),
  },
  {
    key: 'COUNTRY__HU',
    code: 'hu',
    value: t({ id: 'COUNTRY__HU' }),
  },
  {
    key: 'COUNTRY__ID',
    code: 'id',
    value: t({ id: 'COUNTRY__ID' }),
  },
  {
    key: 'COUNTRY__IE',
    code: 'ie',
    value: t({ id: 'COUNTRY__IE' }),
  },
  {
    key: 'COUNTRY__IL',
    code: 'il',
    value: t({ id: 'COUNTRY__IL' }),
  },
  {
    key: 'COUNTRY__IM',
    code: 'im',
    value: t({ id: 'COUNTRY__IM' }),
  },
  {
    key: 'COUNTRY__IN',
    code: 'in',
    value: t({ id: 'COUNTRY__IN' }),
  },
  {
    key: 'COUNTRY__IO',
    code: 'io',
    value: t({ id: 'COUNTRY__IO' }),
  },
  {
    key: 'COUNTRY__IQ',
    code: 'iq',
    value: t({ id: 'COUNTRY__IQ' }),
  },
  {
    key: 'COUNTRY__IR',
    code: 'ir',
    value: t({ id: 'COUNTRY__IR' }),
  },
  {
    key: 'COUNTRY__IS',
    code: 'is',
    value: t({ id: 'COUNTRY__IS' }),
  },
  {
    key: 'COUNTRY__IT',
    code: 'it',
    value: t({ id: 'COUNTRY__IT' }),
  },
  {
    key: 'COUNTRY__JE',
    code: 'je',
    value: t({ id: 'COUNTRY__JE' }),
  },
  {
    key: 'COUNTRY__JM',
    code: 'jm',
    value: t({ id: 'COUNTRY__JM' }),
  },
  {
    key: 'COUNTRY__JO',
    code: 'jo',
    value: t({ id: 'COUNTRY__JO' }),
  },
  {
    key: 'COUNTRY__JP',
    code: 'jp',
    value: t({ id: 'COUNTRY__JP' }),
  },
  {
    key: 'COUNTRY__KE',
    code: 'ke',
    value: t({ id: 'COUNTRY__KE' }),
  },
  {
    key: 'COUNTRY__KG',
    code: 'kg',
    value: t({ id: 'COUNTRY__KG' }),
  },
  {
    key: 'COUNTRY__KH',
    code: 'kh',
    value: t({ id: 'COUNTRY__KH' }),
  },
  {
    key: 'COUNTRY__KI',
    code: 'ki',
    value: t({ id: 'COUNTRY__KI' }),
  },
  {
    key: 'COUNTRY__KM',
    code: 'km',
    value: t({ id: 'COUNTRY__KM' }),
  },
  {
    key: 'COUNTRY__KN',
    code: 'kn',
    value: t({ id: 'COUNTRY__KN' }),
  },
  {
    key: 'COUNTRY__KP',
    code: 'kp',
    value: t({ id: 'COUNTRY__KP' }),
  },
  {
    key: 'COUNTRY__KR',
    code: 'kr',
    value: t({ id: 'COUNTRY__KR' }),
  },
  {
    key: 'COUNTRY__KW',
    code: 'kw',
    value: t({ id: 'COUNTRY__KW' }),
  },
  {
    key: 'COUNTRY__KY',
    code: 'ky',
    value: t({ id: 'COUNTRY__KY' }),
  },
  {
    key: 'COUNTRY__KZ',
    code: 'kz',
    value: t({ id: 'COUNTRY__KZ' }),
  },
  {
    key: 'COUNTRY__LA',
    code: 'la',
    value: t({ id: 'COUNTRY__LA' }),
  },
  {
    key: 'COUNTRY__LB',
    code: 'lb',
    value: t({ id: 'COUNTRY__LB' }),
  },
  {
    key: 'COUNTRY__LC',
    code: 'lc',
    value: t({ id: 'COUNTRY__LC' }),
  },
  {
    key: 'COUNTRY__LI',
    code: 'li',
    value: t({ id: 'COUNTRY__LI' }),
  },
  {
    key: 'COUNTRY__LK',
    code: 'lk',
    value: t({ id: 'COUNTRY__LK' }),
  },
  {
    key: 'COUNTRY__LR',
    code: 'lr',
    value: t({ id: 'COUNTRY__LR' }),
  },
  {
    key: 'COUNTRY__LS',
    code: 'ls',
    value: t({ id: 'COUNTRY__LS' }),
  },
  {
    key: 'COUNTRY__LT',
    code: 'lt',
    value: t({ id: 'COUNTRY__LT' }),
  },
  {
    key: 'COUNTRY__LU',
    code: 'lu',
    value: t({ id: 'COUNTRY__LU' }),
  },
  {
    key: 'COUNTRY__LV',
    code: 'lv',
    value: t({ id: 'COUNTRY__LV' }),
  },
  {
    key: 'COUNTRY__LY',
    code: 'ly',
    value: t({ id: 'COUNTRY__LY' }),
  },
  {
    key: 'COUNTRY__MA',
    code: 'ma',
    value: t({ id: 'COUNTRY__MA' }),
  },
  {
    key: 'COUNTRY__MC',
    code: 'mc',
    value: t({ id: 'COUNTRY__MC' }),
  },
  {
    key: 'COUNTRY__MD',
    code: 'md',
    value: t({ id: 'COUNTRY__MD' }),
  },
  {
    key: 'COUNTRY__ME',
    code: 'me',
    value: t({ id: 'COUNTRY__ME' }),
  },
  {
    key: 'COUNTRY__MF',
    code: 'mf',
    value: t({ id: 'COUNTRY__MF' }),
  },
  {
    key: 'COUNTRY__MG',
    code: 'mg',
    value: t({ id: 'COUNTRY__MG' }),
  },
  {
    key: 'COUNTRY__MH',
    code: 'mh',
    value: t({ id: 'COUNTRY__MH' }),
  },
  {
    key: 'COUNTRY__MK',
    code: 'mk',
    value: t({ id: 'COUNTRY__MK' }),
  },
  {
    key: 'COUNTRY__ML',
    code: 'ml',
    value: t({ id: 'COUNTRY__ML' }),
  },
  {
    key: 'COUNTRY__MM',
    code: 'mm',
    value: t({ id: 'COUNTRY__MM' }),
  },
  {
    key: 'COUNTRY__MN',
    code: 'mn',
    value: t({ id: 'COUNTRY__MN' }),
  },
  {
    key: 'COUNTRY__MO',
    code: 'mo',
    value: t({ id: 'COUNTRY__MO' }),
  },
  {
    key: 'COUNTRY__MP',
    code: 'mp',
    value: t({ id: 'COUNTRY__MP' }),
  },
  {
    key: 'COUNTRY__MQ',
    code: 'mq',
    value: t({ id: 'COUNTRY__MQ' }),
  },
  {
    key: 'COUNTRY__MR',
    code: 'mr',
    value: t({ id: 'COUNTRY__MR' }),
  },
  {
    key: 'COUNTRY__MS',
    code: 'ms',
    value: t({ id: 'COUNTRY__MS' }),
  },
  {
    key: 'COUNTRY__MT',
    code: 'mt',
    value: t({ id: 'COUNTRY__MT' }),
  },
  {
    key: 'COUNTRY__MU',
    code: 'mu',
    value: t({ id: 'COUNTRY__MU' }),
  },
  {
    key: 'COUNTRY__MV',
    code: 'mv',
    value: t({ id: 'COUNTRY__MV' }),
  },
  {
    key: 'COUNTRY__MW',
    code: 'mw',
    value: t({ id: 'COUNTRY__MW' }),
  },
  {
    key: 'COUNTRY__MX',
    code: 'mx',
    value: t({ id: 'COUNTRY__MX' }),
  },
  {
    key: 'COUNTRY__MY',
    code: 'my',
    value: t({ id: 'COUNTRY__MY' }),
  },
  {
    key: 'COUNTRY__MZ',
    code: 'mz',
    value: t({ id: 'COUNTRY__MZ' }),
  },
  {
    key: 'COUNTRY__NA',
    code: 'na',
    value: t({ id: 'COUNTRY__NA' }),
  },
  {
    key: 'COUNTRY__NC',
    code: 'nc',
    value: t({ id: 'COUNTRY__NC' }),
  },
  {
    key: 'COUNTRY__NE',
    code: 'ne',
    value: t({ id: 'COUNTRY__NE' }),
  },
  {
    key: 'COUNTRY__NF',
    code: 'nf',
    value: t({ id: 'COUNTRY__NF' }),
  },
  {
    key: 'COUNTRY__NG',
    code: 'ng',
    value: t({ id: 'COUNTRY__NG' }),
  },
  {
    key: 'COUNTRY__NI',
    code: 'ni',
    value: t({ id: 'COUNTRY__NI' }),
  },
  {
    key: 'COUNTRY__NL',
    code: 'nl',
    value: t({ id: 'COUNTRY__NL' }),
  },
  {
    key: 'COUNTRY__NO',
    code: 'no',
    value: t({ id: 'COUNTRY__NO' }),
  },
  {
    key: 'COUNTRY__NP',
    code: 'np',
    value: t({ id: 'COUNTRY__NP' }),
  },
  {
    key: 'COUNTRY__NR',
    code: 'nr',
    value: t({ id: 'COUNTRY__NR' }),
  },
  {
    key: 'COUNTRY__NU',
    code: 'nu',
    value: t({ id: 'COUNTRY__NU' }),
  },
  {
    key: 'COUNTRY__NZ',
    code: 'nz',
    value: t({ id: 'COUNTRY__NZ' }),
  },
  {
    key: 'COUNTRY__OM',
    code: 'om',
    value: t({ id: 'COUNTRY__OM' }),
  },
  {
    key: 'COUNTRY__PA',
    code: 'pa',
    value: t({ id: 'COUNTRY__PA' }),
  },
  {
    key: 'COUNTRY__PE',
    code: 'pe',
    value: t({ id: 'COUNTRY__PE' }),
  },
  {
    key: 'COUNTRY__PF',
    code: 'pf',
    value: t({ id: 'COUNTRY__PF' }),
  },
  {
    key: 'COUNTRY__PG',
    code: 'pg',
    value: t({ id: 'COUNTRY__PG' }),
  },
  {
    key: 'COUNTRY__PH',
    code: 'ph',
    value: t({ id: 'COUNTRY__PH' }),
  },
  {
    key: 'COUNTRY__PK',
    code: 'pk',
    value: t({ id: 'COUNTRY__PK' }),
  },
  {
    key: 'COUNTRY__PL',
    code: 'pl',
    value: t({ id: 'COUNTRY__PL' }),
  },
  {
    key: 'COUNTRY__PM',
    code: 'pm',
    value: t({ id: 'COUNTRY__PM' }),
  },
  {
    key: 'COUNTRY__PN',
    code: 'pn',
    value: t({ id: 'COUNTRY__PN' }),
  },
  {
    key: 'COUNTRY__PR',
    code: 'pr',
    value: t({ id: 'COUNTRY__PR' }),
  },
  {
    key: 'COUNTRY__PS',
    code: 'ps',
    value: t({ id: 'COUNTRY__PS' }),
  },
  {
    key: 'COUNTRY__PT',
    code: 'pt',
    value: t({ id: 'COUNTRY__PT' }),
  },
  {
    key: 'COUNTRY__PW',
    code: 'pw',
    value: t({ id: 'COUNTRY__PW' }),
  },
  {
    key: 'COUNTRY__PY',
    code: 'py',
    value: t({ id: 'COUNTRY__PY' }),
  },
  {
    key: 'COUNTRY__QA',
    code: 'qa',
    value: t({ id: 'COUNTRY__QA' }),
  },
  {
    key: 'COUNTRY__RE',
    code: 're',
    value: t({ id: 'COUNTRY__RE' }),
  },
  {
    key: 'COUNTRY__RO',
    code: 'ro',
    value: t({ id: 'COUNTRY__RO' }),
  },
  {
    key: 'COUNTRY__RS',
    code: 'rs',
    value: t({ id: 'COUNTRY__RS' }),
  },
  {
    key: 'COUNTRY__RU',
    code: 'ru',
    value: t({ id: 'COUNTRY__RU' }),
  },
  {
    key: 'COUNTRY__RW',
    code: 'rw',
    value: t({ id: 'COUNTRY__RW' }),
  },
  {
    key: 'COUNTRY__SA',
    code: 'sa',
    value: t({ id: 'COUNTRY__SA' }),
  },
  {
    key: 'COUNTRY__SB',
    code: 'sb',
    value: t({ id: 'COUNTRY__SB' }),
  },
  {
    key: 'COUNTRY__SC',
    code: 'sc',
    value: t({ id: 'COUNTRY__SC' }),
  },
  {
    key: 'COUNTRY__SD',
    code: 'sd',
    value: t({ id: 'COUNTRY__SD' }),
  },
  {
    key: 'COUNTRY__SE',
    code: 'se',
    value: t({ id: 'COUNTRY__SE' }),
  },
  {
    key: 'COUNTRY__SG',
    code: 'sg',
    value: t({ id: 'COUNTRY__SG' }),
  },
  {
    key: 'COUNTRY__SH',
    code: 'sh',
    value: t({ id: 'COUNTRY__SH' }),
  },
  {
    key: 'COUNTRY__SI',
    code: 'si',
    value: t({ id: 'COUNTRY__SI' }),
  },
  {
    key: 'COUNTRY__SJ',
    code: 'sj',
    value: t({ id: 'COUNTRY__SJ' }),
  },
  {
    key: 'COUNTRY__SK',
    code: 'sk',
    value: t({ id: 'COUNTRY__SK' }),
  },
  {
    key: 'COUNTRY__SL',
    code: 'sl',
    value: t({ id: 'COUNTRY__SL' }),
  },
  {
    key: 'COUNTRY__SM',
    code: 'sm',
    value: t({ id: 'COUNTRY__SM' }),
  },
  {
    key: 'COUNTRY__SN',
    code: 'sn',
    value: t({ id: 'COUNTRY__SN' }),
  },
  {
    key: 'COUNTRY__SO',
    code: 'so',
    value: t({ id: 'COUNTRY__SO' }),
  },
  {
    key: 'COUNTRY__SR',
    code: 'sr',
    value: t({ id: 'COUNTRY__SR' }),
  },
  {
    key: 'COUNTRY__ST',
    code: 'st',
    value: t({ id: 'COUNTRY__ST' }),
  },
  {
    key: 'COUNTRY__SV',
    code: 'sv',
    value: t({ id: 'COUNTRY__SV' }),
  },
  {
    key: 'COUNTRY__SY',
    code: 'sy',
    value: t({ id: 'COUNTRY__SY' }),
  },
  {
    key: 'COUNTRY__SZ',
    code: 'sz',
    value: t({ id: 'COUNTRY__SZ' }),
  },
  {
    key: 'COUNTRY__TC',
    code: 'tc',
    value: t({ id: 'COUNTRY__TC' }),
  },
  {
    key: 'COUNTRY__TD',
    code: 'td',
    value: t({ id: 'COUNTRY__TD' }),
  },
  {
    key: 'COUNTRY__TF',
    code: 'tf',
    value: t({ id: 'COUNTRY__TF' }),
  },
  {
    key: 'COUNTRY__TG',
    code: 'tg',
    value: t({ id: 'COUNTRY__TG' }),
  },
  {
    key: 'COUNTRY__TH',
    code: 'th',
    value: t({ id: 'COUNTRY__TH' }),
  },
  {
    key: 'COUNTRY__TJ',
    code: 'tj',
    value: t({ id: 'COUNTRY__TJ' }),
  },
  {
    key: 'COUNTRY__TK',
    code: 'tk',
    value: t({ id: 'COUNTRY__TK' }),
  },
  {
    key: 'COUNTRY__TL',
    code: 'tl',
    value: t({ id: 'COUNTRY__TL' }),
  },
  {
    key: 'COUNTRY__TM',
    code: 'tm',
    value: t({ id: 'COUNTRY__TM' }),
  },
  {
    key: 'COUNTRY__TN',
    code: 'tn',
    value: t({ id: 'COUNTRY__TN' }),
  },
  {
    key: 'COUNTRY__TO',
    code: 'to',
    value: t({ id: 'COUNTRY__TO' }),
  },
  {
    key: 'COUNTRY__TR',
    code: 'tr',
    value: t({ id: 'COUNTRY__TR' }),
  },
  {
    key: 'COUNTRY__TT',
    code: 'tt',
    value: t({ id: 'COUNTRY__TT' }),
  },
  {
    key: 'COUNTRY__TV',
    code: 'tv',
    value: t({ id: 'COUNTRY__TV' }),
  },
  {
    key: 'COUNTRY__TW',
    code: 'tw',
    value: t({ id: 'COUNTRY__TW' }),
  },
  {
    key: 'COUNTRY__TZ',
    code: 'tz',
    value: t({ id: 'COUNTRY__TZ' }),
  },
  {
    key: 'COUNTRY__UA',
    code: 'ua',
    value: t({ id: 'COUNTRY__UA' }),
  },
  {
    key: 'COUNTRY__UG',
    code: 'ug',
    value: t({ id: 'COUNTRY__UG' }),
  },
  {
    key: 'COUNTRY__UM',
    code: 'um',
    value: t({ id: 'COUNTRY__UM' }),
  },
  {
    key: 'COUNTRY__US',
    code: 'us',
    value: t({ id: 'COUNTRY__US' }),
  },
  {
    key: 'COUNTRY__UY',
    code: 'uy',
    value: t({ id: 'COUNTRY__UY' }),
  },
  {
    key: 'COUNTRY__UZ',
    code: 'uz',
    value: t({ id: 'COUNTRY__UZ' }),
  },
  {
    key: 'COUNTRY__VA',
    code: 'va',
    value: t({ id: 'COUNTRY__VA' }),
  },
  {
    key: 'COUNTRY__VC',
    code: 'vc',
    value: t({ id: 'COUNTRY__VC' }),
  },
  {
    key: 'COUNTRY__VE',
    code: 've',
    value: t({ id: 'COUNTRY__VE' }),
  },
  {
    key: 'COUNTRY__VG',
    code: 'vg',
    value: t({ id: 'COUNTRY__VG' }),
  },
  {
    key: 'COUNTRY__VI',
    code: 'vi',
    value: t({ id: 'COUNTRY__VI' }),
  },
  {
    key: 'COUNTRY__VN',
    code: 'vn',
    value: t({ id: 'COUNTRY__VN' }),
  },
  {
    key: 'COUNTRY__VU',
    code: 'vu',
    value: t({ id: 'COUNTRY__VU' }),
  },
  {
    key: 'COUNTRY__WF',
    code: 'wf',
    value: t({ id: 'COUNTRY__WF' }),
  },
  {
    key: 'COUNTRY__WS',
    code: 'ws',
    value: t({ id: 'COUNTRY__WS' }),
  },
  {
    key: 'COUNTRY__YE',
    code: 'ye',
    value: t({ id: 'COUNTRY__YE' }),
  },
  {
    key: 'COUNTRY__YT',
    code: 'yt',
    value: t({ id: 'COUNTRY__YT' }),
  },
  {
    key: 'COUNTRY__ZA',
    code: 'za',
    value: t({ id: 'COUNTRY__ZA' }),
  },
  {
    key: 'COUNTRY__ZM',
    code: 'zm',
    value: t({ id: 'COUNTRY__ZM' }),
  },
  {
    key: 'COUNTRY__ZW',
    code: 'zw',
    value: t({ id: 'COUNTRY__ZW' }),
  },
]

export const countryCodeByCountry: Map<Country, CountryCode> = new Map([
  [5, 'ad'],
  [229, 'ae'],
  [1, 'af'],
  [9, 'ag'],
  [7, 'ai'],
  [2, 'al'],
  [11, 'am'],
  [6, 'ao'],
  [8, 'aq'],
  [10, 'ar'],
  [4, 'as'],
  [14, 'at'],
  [13, 'au'],
  [12, 'aw'],
  [15, 'az'],
  [27, 'ba'],
  [19, 'bb'],
  [18, 'bd'],
  [21, 'be'],
  [34, 'bf'],
  [33, 'bg'],
  [17, 'bh'],
  [35, 'bi'],
  [23, 'bj'],
  [182, 'bl'],
  [24, 'bm'],
  [32, 'bn'],
  [26, 'bo'],
  [30, 'br'],
  [16, 'bs'],
  [25, 'bt'],
  [29, 'bv'],
  [28, 'bw'],
  [20, 'by'],
  [22, 'bz'],
  [38, 'ca'],
  [46, 'cc'],
  [50, 'cd'],
  [41, 'cf'],
  [49, 'cg'],
  [211, 'ch'],
  [53, 'ci'],
  [51, 'ck'],
  [43, 'cl'],
  [37, 'cm'],
  [44, 'cn'],
  [47, 'co'],
  [52, 'cr'],
  [55, 'cu'],
  [39, 'cv'],
  [45, 'cx'],
  [56, 'cy'],
  [57, 'cz'],
  [80, 'de'],
  [59, 'dj'],
  [58, 'dk'],
  [60, 'dm'],
  [61, 'do'],
  [3, 'dz'],
  [62, 'ec'],
  [67, 'ee'],
  [63, 'eg'],
  [241, 'eh'],
  [66, 'er'],
  [204, 'es'],
  [68, 'et'],
  [72, 'fi'],
  [71, 'fj'],
  [69, 'fk'],
  [142, 'fm'],
  [70, 'fo'],
  [73, 'fr'],
  [77, 'ga'],
  [230, 'gb'],
  [85, 'gd'],
  [79, 'ge'],
  [74, 'gf'],
  [89, 'gg'],
  [81, 'gh'],
  [82, 'gi'],
  [84, 'gl'],
  [78, 'gm'],
  [90, 'gn'],
  [86, 'gp'],
  [65, 'gq'],
  [83, 'gr'],
  [203, 'gs'],
  [88, 'gt'],
  [87, 'gu'],
  [91, 'gw'],
  [92, 'gy'],
  [97, 'hk'],
  [94, 'hm'],
  [96, 'hn'],
  [54, 'hr'],
  [93, 'ht'],
  [98, 'hu'],
  [101, 'id'],
  [104, 'ie'],
  [106, 'il'],
  [105, 'im'],
  [100, 'in'],
  [31, 'io'],
  [103, 'iq'],
  [102, 'ir'],
  [99, 'is'],
  [107, 'it'],
  [110, 'je'],
  [108, 'jm'],
  [111, 'jo'],
  [109, 'jp'],
  [113, 'ke'],
  [118, 'kg'],
  [36, 'kh'],
  [114, 'ki'],
  [48, 'km'],
  [184, 'kn'],
  [115, 'kp'],
  [116, 'kr'],
  [117, 'kw'],
  [40, 'ky'],
  [112, 'kz'],
  [119, 'la'],
  [121, 'lb'],
  [185, 'lc'],
  [125, 'li'],
  [205, 'lk'],
  [123, 'lr'],
  [122, 'ls'],
  [126, 'lt'],
  [127, 'lu'],
  [120, 'lv'],
  [124, 'ly'],
  [148, 'ma'],
  [144, 'mc'],
  [143, 'md'],
  [146, 'me'],
  [186, 'mf'],
  [130, 'mg'],
  [136, 'mh'],
  [129, 'mk'],
  [134, 'ml'],
  [150, 'mm'],
  [145, 'mn'],
  [128, 'mo'],
  [162, 'mp'],
  [137, 'mq'],
  [138, 'mr'],
  [147, 'ms'],
  [135, 'mt'],
  [139, 'mu'],
  [133, 'mv'],
  [131, 'mw'],
  [141, 'mx'],
  [132, 'my'],
  [149, 'mz'],
  [151, 'na'],
  [155, 'nc'],
  [158, 'ne'],
  [161, 'nf'],
  [159, 'ng'],
  [157, 'ni'],
  [154, 'nl'],
  [163, 'no'],
  [153, 'np'],
  [152, 'nr'],
  [160, 'nu'],
  [156, 'nz'],
  [164, 'om'],
  [168, 'pa'],
  [171, 'pe'],
  [75, 'pf'],
  [169, 'pg'],
  [172, 'ph'],
  [165, 'pk'],
  [174, 'pl'],
  [187, 'pm'],
  [173, 'pn'],
  [176, 'pr'],
  [167, 'ps'],
  [175, 'pt'],
  [166, 'pw'],
  [170, 'py'],
  [177, 'qa'],
  [178, 're'],
  [179, 'ro'],
  [194, 'rs'],
  [180, 'ru'],
  [181, 'rw'],
  [192, 'sa'],
  [200, 'sb'],
  [195, 'sc'],
  [206, 'sd'],
  [210, 'se'],
  [197, 'sg'],
  [183, 'sh'],
  [199, 'si'],
  [208, 'sj'],
  [198, 'sk'],
  [196, 'sl'],
  [190, 'sm'],
  [193, 'sn'],
  [201, 'so'],
  [207, 'sr'],
  [191, 'st'],
  [64, 'sv'],
  [212, 'sy'],
  [209, 'sz'],
  [225, 'tc'],
  [42, 'td'],
  [76, 'tf'],
  [218, 'tg'],
  [216, 'th'],
  [214, 'tj'],
  [219, 'tk'],
  [217, 'tl'],
  [224, 'tm'],
  [222, 'tn'],
  [220, 'to'],
  [223, 'tr'],
  [221, 'tt'],
  [226, 'tv'],
  [213, 'tw'],
  [215, 'tz'],
  [228, 'ua'],
  [227, 'ug'],
  [232, 'um'],
  [231, 'us'],
  [233, 'uy'],
  [234, 'uz'],
  [95, 'va'],
  [188, 'vc'],
  [236, 've'],
  [238, 'vg'],
  [239, 'vi'],
  [237, 'vn'],
  [235, 'vu'],
  [240, 'wf'],
  [189, 'ws'],
  [242, 'ye'],
  [140, 'yt'],
  [202, 'za'],
  [243, 'zm'],
  [244, 'zw'],
])
