import { useCheckEmail } from '@jotta/grpc-connect-client/customer/useCheckEmail'
import { exhaustiveGuard } from '@jotta/utils/exhaustive'
import { t, Trans } from '@lingui/macro'
import { useBrandCode } from '@jotta/ui/useBrandTheme'
import type { Message } from '@bufbuild/protobuf'
import type { Agent } from '@fingerprintjs/fingerprintjs'
import { load } from '@fingerprintjs/fingerprintjs'

export const minimumPasswordLength = 8

const agentPromise = load()
const algorithm = 'AES-GCM'

export async function generateSignupToken<T extends Message<T>>(
  message: T,
  updateToken: (message: T, token: Uint8Array) => void,
  agent?: Agent,
): Promise<T> {
  const theAgent = agent || (await agentPromise)
  const fingerprintResult = await theAgent.get()
  const visitorId = fingerprintResult.visitorId

  const preliminaryToken = `${Date.now()}:${visitorId}`
  updateToken(message, new TextEncoder().encode(preliminaryToken))
  const messageData = message.toBinary()

  const key = await crypto.subtle.generateKey(
    {
      name: algorithm,
      length: 128,
    },
    true,
    ['encrypt'],
  )
  const exportedKey = await crypto.subtle.exportKey('raw', key)
  const iv = crypto.getRandomValues(new Uint8Array(12))
  const cipherText = await crypto.subtle.encrypt(
    { name: algorithm, iv: iv },
    key,
    messageData,
  )

  const byteLength = cipherText.byteLength
  const keyLength = exportedKey.byteLength
  const ivLength = iv.byteLength

  const uint8Array = new Uint8Array(keyLength + ivLength + byteLength)
  uint8Array.set(new Uint8Array(exportedKey))
  uint8Array.set(iv, keyLength)
  uint8Array.set(new Uint8Array(cipherText), keyLength + ivLength)

  updateToken(message, uint8Array)

  return message
}

export function useActivationCodeTerminology() {
  return useBrandCode() === 'MEDIAMARKT'
}

export function EmailInUse() {
  return (
    <span>
      <Trans id="Email already in use. Would you like to <0>log in instead?</0>">
        Email already in use. Would you like to{' '}
        <a className="link" href="/api/login">
          log in instead?
        </a>
      </Trans>
    </span>
  )
}

export function useNameField() {
  return {
    type: 'text',
    required: t`We need your name in order to sign you up`,
  } as const
}

export function useEmailField(customerGroupCode: string) {
  const { mutateAsync } = useCheckEmail()

  return {
    type: 'text',
    required: t`We need your email in order to sign you up`,
    validate: async (email?: string) => {
      if (!email) {
        return undefined
      }

      const checkEmailResponse = await mutateAsync({
        customerGroupCode,
        email,
      })

      const result = checkEmailResponse.result
      switch (result.case) {
        case undefined:
        case 'emailNotUsed':
          return undefined
        case 'emailUsed':
          return {
            message: t`Email address in use!`,
            view: <EmailInUse />,
          }
        case 'emailInvalid':
          return t`We were unable to recognize "${email}" as an email address. Please look for typos and try again`
        default:
          exhaustiveGuard(result)
      }
    },
  } as const
}

export function usePasswordField() {
  return {
    type: 'text',
    autoComplete: 'new-password',
    required: true,
    validate: (password?: string) =>
      !password || password.length < minimumPasswordLength
        ? t`Create a password with ${minimumPasswordLength} characters or more`
        : undefined,
  } as const
}

export function useAcceptedTermsField() {
  return {
    type: 'boolean',
    required: t`We need you to accept the terms and conditions in order to continue`,
  } as const
}

export const voucherCodeField = { type: 'text' } as const

export const newsletterField = {
  type: 'boolean',
} as const
