import type { Photos } from '@jotta/types/Photos'
import { CollectionType } from '@jotta/types/schemas/AlbumSchema'
export type PhotoActionContextType = Photos.ActionContextType
export const albumViewContexts: Record<CollectionType, PhotoActionContextType> =
  {
    [CollectionType.LOCATION_ALBUM]: 'ALBUM_VIEW_LOCATION_ALBUM',
    [CollectionType.META_SHARED_ALBUM_TYPES]:
      'ALBUM_VIEW_META_SHARED_ALBUM_TYPES',
    [CollectionType.NORMAL_ALBUM]: 'ALBUM_VIEW_NORMAL_ALBUM',
    [CollectionType.SHARED_PHOTOS]: 'SHARED_GROUP_OF_PHOTOS',
  } as const
