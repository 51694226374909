import { getCustomer } from '@jotta/grpc-connect-openapi/esm/openapi/customer/customer.v2-CustomerService_connectquery'
import type {
  Customer,
  GetCustomerRequest,
} from '@jotta/grpc-connect-openapi/esm/openapi/customer/customer.v2_pb'
import type { PartialMessage } from '@bufbuild/protobuf'
import { callUnaryMethod } from '@connectrpc/connect-query'
import { authTransport } from '../transport'
import { emptyCustomer } from './emptyCustomer'
import { queryClient } from '../../../query/src/queryClient'
import { getCustomerQueryKey } from './useGetCustomer'

export async function fetchCustomer(
  args: PartialMessage<GetCustomerRequest> = {},
): Promise<Customer> {
  const { customer = emptyCustomer } = await queryClient.fetchQuery({
    queryKey: getCustomerQueryKey,
    queryFn: () =>
      callUnaryMethod(getCustomer, args, {
        transport: authTransport,
      }),
  })
  return customer
}
