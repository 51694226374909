import type { PathItem } from '@jotta/grpc-web/no/jotta/openapi/file/file.v2_pb'
import { ItemAction } from '@jotta/grpc-web/no/jotta/openapi/file/file.v2_pb'
import type { PathItemObject } from '@jotta/types/Files'
import { getItemActionKey } from '@jotta/types/schemas/FileActionSchema'
import { addUrlParams } from '@jotta/utils/url'

export interface ParsePathItemParams {
  pathItem: PathItem
  additionalChildren?: PathItem[]
  token?: string
  ignoreChildren?: boolean
  ignoreDownloadLinks?: boolean
  responseCursor?: string
}

export async function parsePathItem(
  params: ParsePathItemParams,
): Promise<PathItemObject> {
  const {
    additionalChildren,
    pathItem,
    token,
    ignoreChildren,
    ignoreDownloadLinks,
    responseCursor,
  } = params
  if (/^\/(backup|photos|contacts)($|\/)/i.test(pathItem.getPath())) {
    const EXCLUDED_ACTIONS = [ItemAction.ADD_CHILD]
    pathItem.setActionList(
      pathItem.getActionList().filter(a => !EXCLUDED_ACTIONS.includes(a)),
    )
  }
  if (ignoreChildren) {
    pathItem.clearChildrenList()
  }

  const downloadLink = pathItem.getDownloadLink()
  // Clear all downloadLinks to get stable hashes
  pathItem.setDownloadLink('')
  const pathItemChildren = ignoreChildren
    ? []
    : additionalChildren && additionalChildren.length
      ? [...pathItem.getChildrenList(), ...additionalChildren]
      : pathItem.getChildrenList()
  !ignoreChildren && additionalChildren
  pathItem.clearChildrenList()
  if (!ignoreDownloadLinks && downloadLink) {
    pathItem.setDownloadLink(
      token
        ? addUrlParams(downloadLink, {
            access_token: token,
          })
        : downloadLink,
    )
  }
  const item: PathItemObject = {
    ...pathItem.toObject(),
    actions: pathItem.getActionList().map(action => getItemActionKey(action)),
    cursor: responseCursor,
    childrenList: ignoreChildren
      ? []
      : await Promise.all(
          pathItemChildren.map(item =>
            parsePathItem({ ...params, ignoreChildren: true, pathItem: item }),
          ),
        ),
  }
  return item
}
