import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { LanguageSelector } from '@jotta/ui/LanguageSelector'
import { PaymentSummary } from './PaymentSummary'
import { useBreakpointIndex } from '@jotta/ui/useBreakpointIndex'
import type { ReactNode } from 'react'
import type { Offer } from '@jotta/grpc-connect-openapi/offer'
import type { SubscriptionInterval } from '@jotta/grpc-connect-openapi/payment'

export function PaymentLayout({
  children,
  title,
  interval,
  backPath,
  progressIndicator,
  offer,
}: {
  children: (summary: ReactNode) => ReactNode
  offer: Offer
  title: string
  backPath: string
  progressIndicator?: ReactNode
  interval: SubscriptionInterval
}) {
  const wideLayout = useBreakpointIndex() > 1
  return (
    <div className="grid h-full w-full grid-cols-1 bg-white lg:grid-cols-2">
      <div className="flex w-full max-w-xl flex-col justify-self-center p-6 md:p-16 lg:justify-self-end">
        <div className="flex justify-between pb-10">
          <a href={backPath}>
            <PlainBrandIcon icon="SvgLogoMain" height="38px" />
          </a>
          {!wideLayout && <LanguageSelector />}
        </div>
        {progressIndicator && (
          <div className="pb-8 pt-12">{progressIndicator}</div>
        )}
        <h1 className="title-lg mb-6">{title}</h1>
        {children(
          wideLayout ? undefined : (
            <>
              <hr className="my-4" />
              <div className="rounded-md bg-signup-background-right p-8">
                <PaymentSummary offer={offer} interval={interval} />
              </div>
            </>
          ),
        )}
      </div>
      {wideLayout && offer && (
        <div className="bg-signup-background-right p-16">
          {wideLayout && <LanguageSelector className="float-right" />}
          <PaymentSummary
            className="max-w-screen-sm py-44"
            offer={offer}
            interval={interval}
          />
        </div>
      )}
    </div>
  )
}
