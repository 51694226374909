import { Flex } from '@jotta/ui/Flex'
import { Grid } from '@jotta/ui/Grid'
import { TextThemeUI } from '@jotta/ui/themeui'
import type { PhotosApi } from '@jotta/types/PhotosApi'
import { CollectionType } from '@jotta/types/schemas/AlbumSchema'
import { t } from '@lingui/macro'
import { observer } from 'mobx-react-lite'
import { BaseAlbumThumb } from '../AlbumThumb/BaseAlbumThumb'

export interface MoreAlbumsThumbProps {
  albums: PhotosApi.AlbumInfo[]
  sectionType: CollectionType
}

export const MoreAlbumsThumb = observer<MoreAlbumsThumbProps>(
  function MoreAlbumsThumb({ albums, sectionType }) {
    const texts: Record<CollectionType, string> = {
      [CollectionType.NORMAL_ALBUM]: t`View all your albums`,
      [CollectionType.LOCATION_ALBUM]: t`View all locations`,
      [CollectionType.META_SHARED_ALBUM_TYPES]: t`View all shared`,
      [CollectionType.SHARED_PHOTOS]: t`View all shared`,
    }

    return (
      <BaseAlbumThumb title={texts[sectionType]}>
        <Grid columns={2} sx={{ height: '100%', gridGap: 0 }}>
          {albums.slice(0, 4).map(
            album =>
              album.coverPhoto?.thumbnail_url && (
                <img
                  key={album.id}
                  sx={{
                    display: 'block',
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover',
                  }}
                  draggable={false}
                  src={`${album.coverPhoto.thumbnail_url}.s`}
                  alt={`${album?.title || 'Album'}`}
                />
              ),
          )}
        </Grid>
        <Flex
          sx={{
            opacity: 0.8,
            background: 'lightGray',
            position: 'absolute',
            top: '0',
            right: '0',
            bottom: '0',
            left: '0',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <TextThemeUI
            variant="styles.h1"
            color="backgroundText"
          >{`+${albums.length}`}</TextThemeUI>
        </Flex>
      </BaseAlbumThumb>
    )
  },
)
