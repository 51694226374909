import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { TextThemeUI } from '@jotta/ui/themeui'
import type { CarouselItemView } from '@jotta/carousel'

import { Trans } from '@lingui/macro'
import * as Dialog from '@radix-ui/react-dialog'
import * as Popover from '@radix-ui/react-popover'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useFileSize } from '../FileSize/FileSize'
import { FileViewerMenu } from '../FileViewerMenu/FileViewerMenu'
import type { PathItemStore } from '../PathItem/PathItemStore'
import styles from './FileCarousel.module.scss'

export const FileCarouselHeader: CarouselItemView<PathItemStore> = observer(
  function FileCarouselHeader({ store, item: { item } }) {
    const { isFile } = item
    const size = useFileSize(item.data.size).join(' ')

    const [isHovered, setIsHovered] = useState(false)

    const handleMouseEnter = () => {
      setIsHovered(true)
    }

    const handleMouseLeave = () => {
      setIsHovered(false)
    }

    return (
      <div data-testid="FileCarouselHeader" className={styles.header}>
        <Dialog.Close
          data-testid="FileCarouselCloseButton"
          className={styles.closeIconButton}
        >
          <PlainBrandIcon icon="SvgCaretLeft" />
        </Dialog.Close>
        <Popover.Root open={isHovered}>
          <Popover.Trigger
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            sx={{ display: 'flex' }}
          >
            <div className={styles.col1}>
              <h3 data-testid="FileCarouselHeaderFileName">
                {item.name} {isFile && <small>{size}</small>}
              </h3>

              {isFile && (
                <time
                  dateTime={dayjs(item.data.modifiedAtMillis).toISOString()}
                >
                  {dayjs(item.data.modifiedAtMillis).format('lll')}
                </time>
              )}
            </div>
          </Popover.Trigger>
          <Popover.Content
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={styles.popoverContent}
            align="start"
          >
            <span className={styles.itemNameOnHover}>{item.name}</span>
          </Popover.Content>
        </Popover.Root>
        <FileViewerMenu file={item} />
        <div className={styles.col3}>
          <div className={styles.iconButton}>
            <button
              data-testid="FileCarouselPrevButton"
              className={styles.iconButton}
              disabled={!store.prevItem}
              onClick={store.prev}
            >
              <PlainBrandIcon icon="SvgCaretLeft" />
            </button>
            <TextThemeUI>
              {store.currentIndex + 1} / {store.total}
            </TextThemeUI>
            <button
              data-testid="FileCarouselNextButton"
              className={styles.iconButton}
              disabled={!store.nextItem}
              onClick={store.next}
            >
              <PlainBrandIcon icon="SvgCaretRight" />
            </button>
          </div>
          <Dialog.Close
            data-testid="FileCarouselCloseButton"
            className={styles.iconButton}
          >
            <PlainBrandIcon icon="SvgClose" />
            <span>
              <Trans>Close</Trans>
            </span>
          </Dialog.Close>
        </div>
      </div>
    )
  },
)
