import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'
import type { PhotoActionStore } from '../../store/PhotoActionStore'
import { usePhotoStore } from '../../store/PhotoContext'
import type { PhotoActionType, PhotoAction } from './PhotoActions.types'

export type PhotoActionDispatcher = PhotoActionStore['dispatch']
export type OnPhotoActionDispatch = <A extends PhotoActionType>(
  actionType: A extends PhotoActionType ? A : never,
  ...args: PhotoAction<typeof actionType>['params']
) => false | undefined | void
export type OnPhotoActionDispatchError = <A extends PhotoActionType>(
  actionType: A extends PhotoActionType ? A : never,
  error: unknown,
) => false | undefined | void
export function usePhotoActionDispatcher({
  onDispatch,
  onDispatchComplete,
  onDispatchError,
  overrideDispatch,
}: {
  overrideDispatch?: PhotoActionDispatcher
  onDispatch?: OnPhotoActionDispatch
  onDispatchComplete?: OnPhotoActionDispatch
  onDispatchError?: OnPhotoActionDispatchError
}) {
  const store = usePhotoStore().actions
  const originalDispatch = overrideDispatch || store.dispatch
  const dispatch = useCallback<PhotoActionDispatcher>(
    async (actionType, ...args) => {
      try {
        if (onDispatch && onDispatch(actionType, ...args) === false) {
          return
        }
        const result = await originalDispatch(actionType, ...args)
        if (onDispatchComplete) {
          onDispatchComplete(actionType, ...args)
        }
        return result
      } catch (error) {
        if (onDispatchError && onDispatchError(actionType, error) === false) {
          return
        }
        throw error
      }
    },
    [onDispatch, originalDispatch, onDispatchComplete, onDispatchError],
  )
  return dispatch
}

export function useDispatchPhotoActionMutation<A extends PhotoActionType>(
  actionType: A extends PhotoActionType ? A : never,
  options?: Partial<PhotoAction<typeof actionType>['useMutationOptions']>,
  dispatch?: PhotoActionDispatcher,
) {
  type Action = PhotoAction<A>
  const store = usePhotoStore()
  return useMutation<Action['result'], unknown, Action['params'], unknown>({
    mutationFn: args =>
      (dispatch || store.actions.dispatch)(actionType, ...args),
    ...options,
  })
}
