import { ObservableMap } from 'mobx'
new ObservableMap(undefined)
type CaseInsensitiveMapEntries<T> = [string, T][] | (readonly [string, T])[]

/**
 * A MobX ObservableMap where the keys are case-insensitive.
 * The keys are stored in lowercase, and all operations transform keys to lowercase
 * before performing the operation.
 */
export class ObservableCaseInsensitiveMap<T = any> extends ObservableMap<
  string,
  T
> {
  constructor(
    initialData?: CaseInsensitiveMapEntries<T>,
    options: {
      name?: string
      deep?: boolean
    } = {
      name: undefined,
      deep: true,
    },
  ) {
    super(
      initialData?.map(([k, v]) => [k.toLowerCase(), v] as const),
      /**
       * The enhancer option is a function that optionally transforms map values
       * into observables
       *
       * Mobx applies deepEnhancer by default, so if `deep` is true we should set
       * it to undefined
       *
       * If `deep` is false, we give it a function that returns the value as is
       */
      options.deep ? undefined : v => v,
      options.name,
    )
  }

  merge(other?: CaseInsensitiveMapEntries<T>) {
    return super.merge(other?.map(([k, v]) => [k.toLowerCase(), v] as const))
  }
  replace(other: CaseInsensitiveMapEntries<T>) {
    return super.replace(other.map(([k, v]) => [k.toLowerCase(), v] as const))
  }
  set(key: string, value: T): this {
    return super.set(key.toLowerCase(), value)
  }
  get(key: string) {
    return super.get(key.toLowerCase())
  }

  delete(value: string) {
    return super.delete(value.toLowerCase())
  }

  has(value: string) {
    return super.has(value.toLowerCase())
  }
}
