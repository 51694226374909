import {
  ItemAction,
  ItemKind,
  ItemType,
} from '@jotta/grpc-js-client/fileService'
import {
  isWopiDocument,
  isWopiPresentation,
  isWopiSpreadsheet,
} from '@jotta/grpc-js-client/wopiService'
import type { BrandThemeIcon } from '@jotta/types/Brand'
import type { FileActionContext } from '@jotta/types/FileActions'
import type { PathItemObject } from '@jotta/types/Files'
import { isFolderInputSupported } from '@jotta/utils/browserFeatureChecks'
import { popPath } from '@jotta/utils/pathUtils'
import { t } from '@lingui/macro'
import { isString } from 'remeda'

type FileActionConfig = {
  action: string
  name: string
  pluralName: (count: number) => string
  icon: BrandThemeIcon
  enabledContexts: readonly FileActionContext[]
  hasAction?:
    | ItemAction
    | ((item: PathItemObject, isOwner?: boolean) => boolean)
  actions?: readonly FileActionConfig[]
  hidden?: boolean
}
const baseFileActionConfig = [
  {
    action: 'OpenDocument',
    get name() {
      return t`Open`
    },
    pluralName() {
      return this.name
    },
    icon: 'SvgThumbDocx',
    enabledContexts: ['FOLDER_LIST', 'FOLDER_VIEW'],
    hasAction: (item, isOwner = true) => isWopiDocument(item.name),
    hidden: true,
  },
  {
    action: 'OpenDocument',
    get name() {
      return t`Open`
    },
    pluralName() {
      return this.name
    },
    icon: 'SvgThumbDocx',
    enabledContexts: ['FOLDER_LIST', 'FOLDER_VIEW'],
    hasAction: (item, isOwner = true) => isWopiDocument(item.name),
    hidden: true,
  },
  {
    action: 'OpenWordDocument',
    get name() {
      return t`Open in Microsoft Word`
    },
    pluralName() {
      return this.name
    },
    icon: 'SvgThumbDocx',
    enabledContexts: ['FOLDER_LIST', 'FOLDER_VIEW'],
    hasAction: (item, isOwner = true) => isWopiDocument(item.name),
    hidden: false,
  },
  {
    action: 'OpenExcelDocument',
    get name() {
      return t`Open in Microsoft Excel`
    },
    pluralName() {
      return this.name
    },
    icon: 'SvgThumbXlsx',
    enabledContexts: ['FOLDER_LIST', 'FOLDER_VIEW'],
    hasAction: (item, isOwner = true) => isWopiSpreadsheet(item.name),
    hidden: false,
  },
  {
    action: 'OpenPowerpointDocument',
    get name() {
      return t`Open in Microsoft PowerPoint`
    },
    pluralName() {
      return this.name
    },
    icon: 'SvgThumbPptx',
    enabledContexts: ['FOLDER_LIST', 'FOLDER_VIEW'],
    hasAction: (item, isOwner = true) => isWopiPresentation(item.name),
    hidden: false,
  },
  {
    action: 'EmptyTrash',
    get name() {
      return t`Empty trash`
    },
    pluralName() {
      return this.name
    },
    icon: 'SvgTrash',
    enabledContexts: [],
    hasAction: (item: PathItemObject) => item.path.toLowerCase() === '/trash',
    hidden: true,
  },
  {
    action: 'Download',
    get name() {
      return t`Download`
    },
    pluralName(count) {
      return t`Download ${count} files`
    },
    icon: 'SvgDownload',
    enabledContexts: [
      'FOLDER_LIST',

      'FOLDER_VIEW',
      'BREADCRUMB',
      'PUBLIC_LINKS',
      'SELECTION',
    ],
    hasAction: (item: PathItemObject) =>
      !['/photos'].includes(item.path.toLowerCase()) &&
      popPath(item.path.toLowerCase()) !== '/backup',
    hidden: false,
  },
  {
    action: 'Share',
    get name() {
      return t`Share`
    },
    pluralName() {
      return this.name
    },
    icon: 'SvgShare',
    enabledContexts: ['FOLDER_LIST', 'FOLDER_VIEW', 'BREADCRUMB'],
    hasAction: (item, isOwner = true) =>
      !!item.folderShareId ||
      item.actionList.some(action =>
        [ItemAction.PUBLIC_SHARE, ItemAction.PUBLIC_UNSHARE].includes(action),
      ) ||
      (!isOwner && item.kind === ItemKind.COLLABORATION_FOLDER),
    hidden: false,
  },

  {
    action: 'Copy',
    get name() {
      return t`Copy`
    },
    pluralName(count) {
      return t`Copy ${count} files`
    },
    icon: 'SvgCopy',
    enabledContexts: ['FOLDER_LIST', 'FOLDER_VIEW', 'SELECTION'],
    hasAction: ItemAction.COPY,
    hidden: false,
  },
  {
    action: 'Move',
    get name() {
      return t`Move`
    },
    pluralName(count) {
      return t`Move ${count} files`
    },
    icon: 'SvgFolderMove',
    enabledContexts: ['FOLDER_LIST', 'FOLDER_VIEW', 'SELECTION'],
    hasAction: ItemAction.MOVE,
    hidden: false,
  },
  {
    action: 'Rename',
    get name() {
      return t`Rename`
    },
    pluralName() {
      return this.name
    },
    icon: 'SvgEdit',
    enabledContexts: ['FOLDER_LIST', 'FOLDER_VIEW'],
    hasAction: ItemAction.MOVE,
    hidden: false,
  },
  {
    action: 'Revisions',
    get name() {
      return t`Previous versions`
    },
    pluralName() {
      return this.name
    },
    icon: 'SvgVersionHistory',
    enabledContexts: ['FOLDER_LIST', 'FOLDER_VIEW'],
    hasAction: (item: PathItemObject) => item.type === ItemType.FILE,
    hidden: false,
  },
  {
    action: 'Delete',
    get name() {
      return t`Delete`
    },
    pluralName(count) {
      return t`Delete ${count} files`
    },
    icon: 'SvgTrash',
    enabledContexts: ['FOLDER_LIST', 'FOLDER_VIEW', 'SELECTION'],
    hasAction: ItemAction.DELETE,
    hidden: false,
  },
  {
    action: 'DeletePermanently',
    get name() {
      return t`Permanently delete`
    },
    pluralName() {
      return this.name
    },
    icon: 'SvgTrash',
    enabledContexts: ['FOLDER_LIST', 'FOLDER_VIEW', 'SELECTION'],
    hasAction: ItemAction.PERMANENTLY_DELETE,
    hidden: false,
  },
  {
    action: 'Restore',
    get name() {
      return t`Restore`
    },
    pluralName() {
      return this.name
    },
    icon: 'SvgRestore',
    enabledContexts: ['FOLDER_LIST', 'FOLDER_VIEW', 'SELECTION'],
    hasAction: ItemAction.RESTORE,
    hidden: false,
  },

  {
    action: 'NewDocument',
    get name() {
      return t`New document`
    },
    pluralName() {
      return this.name
    },
    icon: 'SvgFileAdd',
    enabledContexts: ['FAB'],
    hasAction: ItemAction.ADD_CHILD,
    hidden: false,
    actions: [
      {
        action: 'NewWordDocument',
        name: 'Microsoft Word',
        pluralName() {
          return this.name
        },
        icon: 'SvgThumbWordMono',
        enabledContexts: ['FAB'],
        hasAction: ItemAction.ADD_CHILD,
        hidden: false,
      },
      {
        action: 'NewExcelDocument',
        name: 'Microsoft Excel',
        pluralName() {
          return this.name
        },
        icon: 'SvgThumbExcelMono',
        enabledContexts: ['FAB'],
        hasAction: ItemAction.ADD_CHILD,
        hidden: false,
      },
      {
        action: 'NewPowerpointDocument',
        name: 'Microsoft PowerPoint',
        pluralName() {
          return this.name
        },
        icon: 'SvgThumbPowerpointMono',
        enabledContexts: ['FAB'],
        hasAction: ItemAction.ADD_CHILD,
        hidden: false,
      },
    ],
  },
  {
    action: 'NewWordDocument',
    name: 'Microsoft Word',
    pluralName() {
      return this.name
    },
    icon: 'SvgThumbWordMono',
    enabledContexts: ['FAB'],
    hasAction: ItemAction.ADD_CHILD,
    hidden: true,
  },
  {
    action: 'NewExcelDocument',
    name: 'Microsoft Excel',
    pluralName() {
      return this.name
    },
    icon: 'SvgThumbExcelMono',
    enabledContexts: ['FAB'],
    hasAction: ItemAction.ADD_CHILD,
    hidden: true,
  },
  {
    action: 'NewPowerpointDocument',
    name: 'Microsoft PowerPoint',
    pluralName() {
      return this.name
    },
    icon: 'SvgThumbPowerpointMono',
    enabledContexts: ['FAB'],
    hasAction: ItemAction.ADD_CHILD,
    hidden: true,
  },
  {
    action: 'CreateFolder',
    get name() {
      return t`Create new folder`
    },
    pluralName() {
      return this.name
    },
    icon: 'SvgFolderNew',
    enabledContexts: ['FAB'],
    hasAction: ItemAction.ADD_CHILD,
    hidden: false,
  },
  {
    action: 'UploadFiles',
    get name() {
      return t`Upload files`
    },
    pluralName() {
      return this.name
    },
    icon: 'SvgFileUpload',
    enabledContexts: ['FAB', 'PUBLIC_LINKS'],
    hasAction: ItemAction.ADD_CHILD,
    hidden: false,
  },
  {
    action: 'UploadFolder',
    get name() {
      return t`Upload folder`
    },
    pluralName() {
      return this.name
    },
    icon: 'SvgFolderUpload',
    enabledContexts: ['FAB', 'PUBLIC_LINKS'],
    hasAction: item =>
      isFolderInputSupported() &&
      item.actionList.includes(ItemAction.ADD_CHILD),
    hidden: false,
  },
  {
    action: 'AcceptPendingInvite',
    get name() {
      return t`Accept`
    },
    pluralName() {
      return this.name
    },
    icon: 'SvgCheckmark',
    enabledContexts: ['PENDING_INVITE_LISTITEM'],
    hasAction: undefined,
    hidden: false,
  },
  {
    action: 'DeclinePendingInvite',
    get name() {
      return t`Decline`
    },
    pluralName() {
      return this.name
    },
    icon: 'SvgStop',
    enabledContexts: ['PENDING_INVITE_LISTITEM'],
    hasAction: undefined,
    hidden: false,
  },
  {
    action: 'DeleteDevice',
    get name() {
      return t`Delete`
    },
    pluralName() {
      return this.name
    },
    icon: 'SvgTrash',
    enabledContexts: ['FOLDER_LIST', 'BREADCRUMB'],
    hasAction: item => {
      return [
        ItemKind.WORKSTATION,
        ItemKind.LAPTOP,
        ItemKind.MACBOOK,
        ItemKind.IMAC,
        ItemKind.MACPRO,
        ItemKind.IPHONE,
        ItemKind.IPAD,
        ItemKind.ANDROID,
        ItemKind.WINDOWS_PHONE,
        ItemKind.WINDOWS_TABLET,
        ItemKind.CLI,
      ].includes(item.kind)
    },
    hidden: false,
  },
] as const satisfies readonly FileActionConfig[]

type ActiveFileActionBaseConfig = (typeof baseFileActionConfig)[number]
type ActiveFileActionConfig = {
  [Action in ActiveFileActionBaseConfig as Action['action']]: Action
}
export type ActiveFileActionType = keyof ActiveFileActionConfig

export type ActiveFileAction<T extends ActiveFileActionType> =
  T extends 'Disabled'
    ? ActiveFileActionConfig[T] & {
        context: null
        params: [null]
      }
    : ActiveFileActionConfig[T]['enabledContexts'][number] extends 'SELECTION'
      ? ActiveFileActionConfig[T] & {
          context: FileActionContext
          params: [
            {
              path: string
            },
          ]
        }
      : ActiveFileActionConfig[T] & {
          context: FileActionContext
          params: [
            {
              paths: string[]
            },
          ]
        }

export const fileActionApiArray = baseFileActionConfig
export const selectionFileActions = baseFileActionConfig.filter(a =>
  (a.enabledContexts as readonly string[]).includes('SELECTION'),
)
export const fileActionApi: ActiveFileActionConfig = Object.fromEntries(
  baseFileActionConfig.map(item => [item.action, item]),
) as ActiveFileActionConfig
export function isActiveFileActionType(
  value: unknown,
): value is ActiveFileActionType {
  return isString(value) && value in fileActionApi
}
