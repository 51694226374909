import { Box } from '@jotta/ui/Box'
import { Button } from '@jotta/ui/Button'
import { Grid } from '@jotta/ui/Grid'
import { LinkThemeUI } from '@jotta/ui/themeui'
import type { DescribeVoucherResponse } from '@jotta/grpc-web/no/jotta/openapi/subscription/payment.v2_pb'
import { GetPaymentInfoResponse } from '@jotta/grpc-web/no/jotta/openapi/subscription/payment.v2_pb'
import { ButtonGroup } from '@jotta/ui/ButtonGroup'
import { Card } from '@jotta/ui/Card'
import { Modal } from '@jotta/ui/Modal'
import { StorageProgressBar } from '@jotta/ui/ProgressBar'
import { Paragraph } from '@jotta/ui/Text'
import { VoucherDialog } from '@jotta/ui/VoucherDialog'
import { filesize } from '@jotta/utils/filesize'
import { Trans, t } from '@lingui/macro'
import { observer } from 'mobx-react-lite'
import { useToggle } from 'react-use'
import { paymentStore } from '../../state/stores'
import { SubscriptionExpirationInfo } from './SubscriptionExpirationInfo'
import { SubscriptionUpgradeCase } from './SubscriptionUpgradeCase'
import { useBrandName } from '@jotta/grpc-connect-client/config'

export interface SubscriptionProps {
  subscription: GetPaymentInfoResponse.AsObject
  voucherInfo?: DescribeVoucherResponse.AsObject
  upgradeOptionCase?: GetPaymentInfoResponse.ChangeSubscriptionOptionCase
}

export const Subscription = observer<SubscriptionProps>(
  ({ subscription, voucherInfo, upgradeOptionCase }) => {
    const brandName = useBrandName()
    const { resetVoucherInfo } = paymentStore
    const {
      subscriptionNameLocalized,
      customerQuotaBytes = 0,
      customerUsageBytes = 0,
      canUseVoucher,
    } = subscription
    const [isVoucherDialogOpen, toggleVoucherDialog] = useToggle(false)
    const closeVoucherHandler = () => toggleVoucherDialog(false)
    const onUpgrade = () =>
      window.location.assign(`${window.location.origin}/web/upgrade`)
    const onUseVoucher = () => {
      resetVoucherInfo()
      toggleVoucherDialog()
    }
    const saSubscription =
      upgradeOptionCase ===
      GetPaymentInfoResponse.ChangeSubscriptionOptionCase.SA_SUBSCRIPTION
    const changeSubscription =
      saSubscription ||
      subscription.normalUpgrade?.upgradeButtonHint ===
        GetPaymentInfoResponse.NormalUpgrade.UpgradeType.CHANGE_SUBSCRIPTION
    const userCanUpgrade =
      saSubscription ||
      upgradeOptionCase ===
        GetPaymentInfoResponse.ChangeSubscriptionOptionCase.NORMAL_UPGRADE

    const canManageExternally =
      upgradeOptionCase ===
      GetPaymentInfoResponse.ChangeSubscriptionOptionCase
        .MANAGE_SUBSCRIPTION_EXTERNALLY

    const manageSubscriptionExternallyUrl =
      subscription.manageSubscriptionExternally?.url || ''

    const canRegisterCard =
      subscription.expirationDateMillis &&
      (upgradeOptionCase ===
        GetPaymentInfoResponse.ChangeSubscriptionOptionCase.SA_SUBSCRIPTION ||
        upgradeOptionCase ===
          GetPaymentInfoResponse.ChangeSubscriptionOptionCase.NORMAL_UPGRADE)
    const registerCardLink =
      upgradeOptionCase ===
      GetPaymentInfoResponse.ChangeSubscriptionOptionCase.SA_SUBSCRIPTION
        ? '/web/renew'
        : '/web/expire'

    return (
      <Card variant="cards.compact" title={t`Subscription`}>
        <Grid columns={[1, 1, '1fr auto']}>
          <Box>
            <Paragraph>
              <Trans id="Your subscription: <0>{subscriptionNameLocalized}</0>">
                Your subscription: <strong>{subscriptionNameLocalized}</strong>
              </Trans>
            </Paragraph>
            <Grid
              columns="auto 1fr"
              mb={3}
              sx={{
                alignItems: 'center',
              }}
            >
              <Paragraph>
                <Trans>Usage:</Trans>
                {customerQuotaBytes < 0 && (
                  <strong>{` ${filesize(customerUsageBytes)}`}</strong>
                )}
              </Paragraph>
              {customerQuotaBytes > 0 && (
                <StorageProgressBar
                  completed={customerUsageBytes}
                  total={customerQuotaBytes}
                  sx={{
                    maxWidth: '300px',
                  }}
                />
              )}
            </Grid>
            <SubscriptionExpirationInfo
              subscription={subscription}
              upgradeOptionCase={upgradeOptionCase}
              brandName={brandName}
            />
            <SubscriptionUpgradeCase
              subscription={subscription}
              upgradeOptionCase={upgradeOptionCase}
            />
          </Box>
          {(userCanUpgrade || canUseVoucher || canManageExternally) && (
            <ButtonGroup>
              {userCanUpgrade && (
                <Button variant="buttons.primary" onClick={onUpgrade}>
                  {changeSubscription ? (
                    <Trans>Change subscription</Trans>
                  ) : (
                    <Trans>Upgrade</Trans>
                  )}
                </Button>
              )}
              {canUseVoucher && (
                <Button variant="buttons.secondary" onClick={onUseVoucher}>
                  <Trans>I have a code</Trans>
                </Button>
              )}
              {canManageExternally && (
                <ButtonGroup>
                  <LinkThemeUI
                    variant="buttons.primary"
                    href={manageSubscriptionExternallyUrl}
                  >
                    <Trans>Manage subscription</Trans>
                  </LinkThemeUI>
                </ButtonGroup>
              )}
              {canRegisterCard && (
                <LinkThemeUI
                  variant="buttons.secondary"
                  href={registerCardLink}
                >
                  <Trans>Register card</Trans>
                </LinkThemeUI>
              )}
            </ButtonGroup>
          )}
        </Grid>
        {isVoucherDialogOpen && (
          <Modal open={isVoucherDialogOpen} onClose={closeVoucherHandler}>
            <VoucherDialog onCancel={closeVoucherHandler} />
          </Modal>
        )}
      </Card>
    )
  },
)
