import type { Message, PartialMessage } from '@bufbuild/protobuf'
import type { CallOptions, ConnectError, Transport } from '@connectrpc/connect'
import type {
  ConnectQueryKey,
  DisableQuery,
  MethodUnaryDescriptor,
} from '@connectrpc/connect-query'
import { callUnaryMethod } from '@connectrpc/connect-query'
import { createConnectQueryKey, useQuery } from '@connectrpc/connect-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { queryClient } from '@jotta/query'

export type ConnectQueryHook<I extends Message<I>, O extends Message<O>> = {
  (
    options?: Parameters<typeof useQuery<I, O>>[2] & {
      variables?: DisableQuery | PartialMessage<I>
    },
  ): UseQueryResult<O, ConnectError>
  fetch: (
    request: PartialMessage<I>,
    options: {
      transport: Transport
      callOptions?: CallOptions | undefined
    },
  ) => Promise<O>
  descriptor: MethodUnaryDescriptor<I, O>
  getKey: (input?: DisableQuery | PartialMessage<I>) => ConnectQueryKey<I>
  invalidate: (input?: DisableQuery | PartialMessage<I>) => void
}

export function createConnectQuery<I extends Message<I>, O extends Message<O>>(
  methodSig: MethodUnaryDescriptor<I, O>,
): ConnectQueryHook<I, O> {
  // @ts-ignore
  const fn: ConnectQueryHook<I, O> = ({ variables, ...options }) =>
    useQuery(methodSig, variables, options)
  fn.descriptor = methodSig
  fn.fetch = (request, options) =>
    queryClient.fetchQuery({
      queryKey: createConnectQueryKey(methodSig, request),
      queryFn: context => {
        return callUnaryMethod(methodSig, request, options)
      },
    })
  fn.getKey = input => createConnectQueryKey(methodSig, input)
  fn.invalidate = input => {
    queryClient.invalidateQueries({
      queryKey: createConnectQueryKey(methodSig, input),
    })
  }

  return fn
}
