import { useUsername } from '@jotta/grpc-connect-client/customer'
import { useBrandStore } from '@jotta/ui/useBrandTheme'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import Debug from 'debug'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { usePhotoStore } from '../../../store/PhotoContext'
import { PublicAlbumQueryStore } from '../../../store/PublicAlbumQueryStore'
import { MediaCarouselContainer } from '../../MediaCarousel/MediaCarouselContainer'
import { SelectionManager } from '../../SelectionManager/SelectionManager'
import { AlbumInfo } from '../AlbumInfo/AlbumInfo'
import { usePublicAlbumInfoLoaderData } from '../AlbumInfo/AlbumInfo.loaders'
import { AlbumPhotosLarge } from '../AlbumPhotos/AlbumPhotosLarge'
import { AlbumPhotosSmall } from '../AlbumPhotos/AlbumPhotosSmall'
import { useFollowSharedAlbumAlert } from './AlbumActionAlerts/FollowSharedAlbumAlert'
import { useLoginToAddPhotosAlert } from './AlbumActionAlerts/LoginToAddPhotosAlert'
import { AlbumComments } from './AlbumComments'

const debug = Debug('jotta:photos:PublicAlbumRoute')

export const PublicAlbumContainer = observer(function PublicAlbumContainer() {
  const { shareId = '', mediaId = '' } = useParams()
  const { album: albumInfo } = usePublicAlbumInfoLoaderData()
  const redirected = useRef(false)
  const photoStore = usePhotoStore()
  const store = useLocalObservable(
    () => new PublicAlbumQueryStore(shareId, photoStore, albumInfo),
  )
  const branding = useBrandStore()
  const album = store.album
  const showAlert = Boolean(album && album.showLoginToAddPhotosAlert)
  const { displayLoginToAddPhotosAlert } = useLoginToAddPhotosAlert(shareId)
  const showOwnerName = album?.model.showOwnerName
  const username = useUsername()

  useEffect(() => {
    if (showAlert) {
      displayLoginToAddPhotosAlert()
    }
  }, [showAlert, displayLoginToAddPhotosAlert])

  const showFollowAlbumAlert = Boolean(album?.showFollowSharedAlbumAlert)

  const { displayFollowAlbumAlert, followSharedAlbumAlert } =
    useFollowSharedAlbumAlert(shareId)
  useEffect(() => {
    if (!showFollowAlbumAlert) {
      return
    }

    displayFollowAlbumAlert()
    return () => {
      followSharedAlbumAlert.remove()
    }
  }, [
    shareId,
    followSharedAlbumAlert,
    displayFollowAlbumAlert,
    showFollowAlbumAlert,
  ])

  const isSharedPhotos = Boolean(album?.model.isSharedPhotos)
  const photoIds = album?.photoIds || []
  const navigate = useNavigate()

  useEffect(() => {
    if (
      isSharedPhotos &&
      store.album?.photoIds.length === 1 &&
      !mediaId &&
      !redirected.current
    ) {
      redirected.current = true
      navigate(`./${store.album.photoIds[0]}`)
    }
  }, [isSharedPhotos, mediaId, photoIds])

  return (
    <>
      <LoadingOverlay open={store.isLoading} />
      {album && (
        <>
          <AlbumInfo publicView store={album} />
          {branding.isMobileTimeline ? (
            <AlbumPhotosSmall
              ids={album.photoIds}
              ownerAvatar={album.isShared}
            />
          ) : (
            <AlbumPhotosLarge
              ids={album.photoIds}
              ownerAvatar={album.isShared}
            />
          )}
          <SelectionManager
            username={username}
            album={album.data}
            albumId={album.id}
            getDownloadInfo={album.getDownloadInfo}
            shareLink={album.shareLink}
            actionContext={album.viewContexts.selection}
            isAlbumSubscriber={album.isSubscriber}
          />
          {mediaId && (
            <MediaCarouselContainer
              items={album?.photos}
              mediaId={mediaId}
              actionContext={album.viewContexts.carousel}
              showOwnerName={showOwnerName}
            />
          )}
          {!mediaId && album.showComments.enabled && (
            <AlbumComments store={album} />
          )}
        </>
      )}
    </>
  )
})
