function createRedirectToLoginUrl({
  api = 'login',
  currentUrl = new URL(window.location.href),
  redirectBackUrl,
  idpHint,
}: {
  api?: 'login' | 'logout' | 'signup'
  currentUrl?: URL
  redirectBackUrl?: string
  idpHint?: string
}) {
  const url = new URL(`/api/${api}`, currentUrl.origin)
  if (redirectBackUrl) {
    url.searchParams.set('redirect_uri', redirectBackUrl)
  }
  const kcIdpHint = idpHint || currentUrl.searchParams.get('kc_idp_hint')
  if (kcIdpHint) {
    url.searchParams.append('kc_idp_hint', kcIdpHint)
  }
  return {
    url,
    relativeHref: url.href.substring(url.origin.length),
  }
}

export function redirectToLogin(params?: {
  redirectUri?: string
  idpHint?: string
}) {
  const { redirectUri: redirectBackUrl, idpHint } = params || {}
  if (redirectBackUrl) {
    window.history.pushState({}, '', redirectBackUrl)
  }
  const url = createRedirectToLoginUrl({
    api: 'login',
    redirectBackUrl,
    idpHint,
  })

  window.location.href = url.relativeHref
}

function getSignupUrl(redirectBackUrl?: string) {
  const url = createRedirectToLoginUrl({
    api: 'signup',
    redirectBackUrl,
  })

  return url
}
export function redirectToSignup(redirectBackUrl?: string, state?: {}) {
  if (redirectBackUrl) {
    window.history.pushState(state || {}, '', redirectBackUrl)
  }
  const url = getSignupUrl(redirectBackUrl)
  window.location.href = url.relativeHref
}

function getLogoutUrl(redirectBackUrl?: string) {
  const url = createRedirectToLoginUrl({
    api: 'logout',
    redirectBackUrl,
  })

  return url
}
export function redirectToLogout(redirectBackUrl?: string) {
  if (redirectBackUrl) {
    window.history.pushState({}, '', redirectBackUrl)
  }
  fetch('/web/logout').catch(() => {})
  const url = getLogoutUrl(redirectBackUrl)
  window.location.href = url.relativeHref
}

export function autoLogin({
  username,
  token,
  redirectTo,
  redirectFrom = location.href,
  replace = false,
}: {
  username: string
  token: string
  redirectTo: string
  redirectFrom?: string
  replace?: boolean
}) {
  const redirectUri = new URL(redirectTo)
  redirectUri.searchParams.delete('username')
  redirectUri.searchParams.delete('token')

  const url = new URL(redirectFrom)
  const autoLoginUrl = new URL('/api/autologin', url.origin)
  autoLoginUrl.searchParams.set('username', username)
  autoLoginUrl.searchParams.set('token', token)
  autoLoginUrl.searchParams.set(
    'redirect_uri',
    redirectUri.pathname + redirectUri.search,
  )
  if (replace) {
    window.location.replace(autoLoginUrl.href)
    return
  }
  window.location.href = autoLoginUrl.href
  return
}
