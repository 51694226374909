import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { Plural, t } from '@lingui/macro'
import { observer } from 'mobx-react-lite'
import { forwardRef, type ReactNode } from 'react'
import styles from './BaseSelectionManager.module.scss'
export interface BaseSelectionManagerProps {
  hidden?: boolean
  selectionCount?: number
  onClose: (open: boolean) => void
  children?: ReactNode
}

export const BaseSelectionManager = observer<BaseSelectionManagerProps>(
  forwardRef<HTMLDivElement, BaseSelectionManagerProps>(
    function BaseSelectionManager(
      { children, hidden = false, selectionCount = 0, onClose, ...props },
      ref,
    ) {
      return (
        <div
          className={styles.container}
          data-hidden={hidden}
          {...props}
          ref={ref}
        >
          <span className={styles.count}>
            <Plural
              value={selectionCount || 0}
              one="# photo selected"
              other="# photos selected"
            />
          </span>
          <span
            className={styles.actions}
            data-testid="SelectionManagerActions"
          >
            {children}
          </span>

          <button
            className={styles.closeButton}
            aria-label={t`Close`}
            data-testid="SelectionManagerClose"
            onClick={() => onClose(false)}
          >
            <PlainBrandIcon icon="SvgClose" />
          </button>
        </div>
      )
    },
  ),
)
