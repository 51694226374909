import type { ItemAction } from '@jotta/grpc-js-client/fileService'
import type { FileActionContext } from '@jotta/types/FileActions'
import type { PathItemObject } from '@jotta/types/Files'
import moize from 'moize'
import { isFunction } from 'remeda'
import type {
  ActiveFileAction,
  ActiveFileActionType,
} from '../FileActions/fileActionConfig'
import { fileActionApiArray } from '../FileActions/fileActionConfig'

/**
 * Get file actions matching context without checking permissions
 * @param context Filter items by context
 */
export const getFileActionMenuItemsByContext = moize(
  function getFileActionMenuItems(
    context: FileActionContext = 'FOLDER_LIST',
  ): ActiveFileAction<ActiveFileActionType>[] {
    const filteredItems = fileActionApiArray.filter(
      action =>
        !action.hidden &&
        (action.enabledContexts as readonly FileActionContext[]).includes(
          context,
        ),
    ) as ActiveFileAction<ActiveFileActionType>[]
    return filteredItems
  },
  {
    maxSize: 10,
  },
)
/**
 * Get file actions matching context and allowed for path item
 * @param context Filter items by context
 * @param item Path item to check permissions on
 */
export const getFileActionMenuItems = moize.shallow(
  function getFileActionMenuItems(
    context: FileActionContext = 'FOLDER_LIST',
    item: PathItemObject,
    isOwner = true,
  ): ActiveFileAction<ActiveFileActionType>[] {
    const filteredItems = getFileActionMenuItemsByContext(context).filter(
      action =>
        isFunction(action.hasAction)
          ? action.hasAction(item, isOwner)
          : item.actionList.includes(action.hasAction as ItemAction),
    ) as ActiveFileAction<ActiveFileActionType>[]
    return filteredItems
  },
)
