import type { PhotosApi } from '@jotta/types/PhotosApi'
import type { CollectionType } from '@jotta/types/schemas/AlbumSchema'
import type { UseQueryOptions } from '@tanstack/react-query'
import type { SetRequired } from 'type-fest'

export type CollectionTypeKey = keyof typeof CollectionType
export type FetchAlbumListOptions = Required<PhotosApi.AlbumListFetchProps>
export type AlbumListQueryKey<T extends CollectionTypeKey = CollectionTypeKey> =
  readonly [key: 'albumList', albumType: T]
export function getAlbumListQueryKey<T extends CollectionTypeKey>(
  albumType: T,
): AlbumListQueryKey<T> {
  return ['albumList', albumType]
}
export type BaseAlbumListQueryOptions<T extends CollectionTypeKey> =
  UseQueryOptions<
    readonly string[],
    unknown,
    readonly string[],
    AlbumListQueryKey<T>
  >

export type AlbumListQueryOptions<T extends CollectionTypeKey> = SetRequired<
  UseQueryOptions<
    readonly string[],
    unknown,
    readonly string[],
    AlbumListQueryKey<T>
  >,
  'queryFn' | 'queryKey'
>
