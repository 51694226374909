import {
  useGetOnboarding,
  useSetOnboarding,
} from '@jotta/grpc-js-client/onboarding'
import { WaitlistStatus } from '@jotta/grpc-web/no/jotta/openapi/search/search.v2_pb'
import { observer } from 'mobx-react-lite'

import { useState } from 'react'
import { useSearchStore } from '../../store/PhotoContext'
import { waitlistStore } from '../../store/WaitlistStore'
import { SmartPhotoSearchAccessBanner } from '../SmartPhotoSearchAccessBanner/SmartPhotoSearchAccessBanner'
import { WaitlistBannerInactive } from '../WaitlistBannerInactive/WaitlistBannerInactive'
import { WaitlistBannerOnWaitlist } from '../WaitlistBannerOnWaitlist/WaitlistBannerOnWaitlist'

export const SmartPhotoSearchBannersContainer = observer(
  function SmartPhotoSearchBannersContainer() {
    const [confettiActive, setConfettiActive] = useState(false)
    const [buttonCoords, setButtonCoords] = useState({ x: 0, y: 0, w: 0, h: 0 })
    const [showSpinner, setShowSpinner] = useState(false)
    const searchStore = useSearchStore()
    const { data: onboardingStatus } = useGetOnboarding({})
    const setOnboarding = useSetOnboarding()
    const { waitlistStatus } = waitlistStore
    const status = waitlistStatus?.getStatus()

    const showBanner =
      Boolean(onboardingStatus) &&
      !(onboardingStatus as any).has('smartPhotoSearchBanner')

    const handleExplodeConfetti = (
      event: React.MouseEvent<HTMLButtonElement>,
    ) => {
      setConfettiActive(true)
      setButtonCoords({
        x: event.clientX,
        y: event.clientY,
        w: event.currentTarget.offsetWidth,
        h: event.currentTarget.offsetHeight,
      })
    }

    const handleJoinWaitlist = (event: React.MouseEvent<HTMLButtonElement>) => {
      setShowSpinner(true)
      handleExplodeConfetti(event)
      waitlistStore.joinWaitlist()
    }

    const handleDismiss = () => {
      setOnboarding.mutate(['smartPhotoSearchBanner', true])
    }

    const handleTrySmartSearch = () => {
      setOnboarding.mutate(['smartPhotoSearchBanner', true])
      searchStore.open = true
    }

    if (!showBanner) {
      return null
    }

    return (
      <>
        {status === WaitlistStatus.Status.INACTIVE && (
          <WaitlistBannerInactive
            onDismiss={handleDismiss}
            onJoinWaitlist={handleJoinWaitlist}
            showSpinner={showSpinner}
          />
        )}
        {status === WaitlistStatus.Status.ON_WAITLIST && (
          <WaitlistBannerOnWaitlist
            handleDismiss={handleDismiss}
            handleExplodeConfetti={handleExplodeConfetti}
            confettiActive={confettiActive}
            buttonCoords={buttonCoords}
            onConfettiComplete={() => setConfettiActive(false)}
          />
        )}
        {status === WaitlistStatus.Status.ACTIVE && (
          <SmartPhotoSearchAccessBanner
            handleDismiss={handleDismiss}
            handleTrySmartSearch={handleTrySmartSearch}
          />
        )}
      </>
    )
  },
)
