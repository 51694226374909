import { Button } from '@jotta/ui/Button'
import { RouterLink } from '@jotta/ui/RouterLink'
import { useBrandStore } from '@jotta/ui/useBrandTheme'
import type { Photos } from '@jotta/types/Photos'
import type { PhotosApi } from '@jotta/types/PhotosApi'
import { CollectionType } from '@jotta/types/schemas/AlbumSchema'
import { observer } from 'mobx-react-lite'
import { Link, useNavigate } from 'react-router-dom'
import { createAlbum } from '../../../api/photosApi'
import { AlbumThumb } from '../AlbumThumb/AlbumThumb'
import { MoreAlbumsThumb } from '../MoreAlbumsThumb/MoreAlbumsThumb'
import { BaseAlbumsSection } from './BaseAlbumsSection'
import { CreateAlbumFromAlbumThumb } from './CreateAlbumFromAlbumThumb'

const albumTypeRouteMap: Record<CollectionType, string> = {
  [CollectionType.NORMAL_ALBUM]: 'all',
  [CollectionType.SHARED_PHOTOS]: 'shared',
  [CollectionType.META_SHARED_ALBUM_TYPES]: 'shared',
  [CollectionType.LOCATION_ALBUM]: 'location',
}

export interface AlbumsSectionProps {
  sectionType?: Photos.DisplayCollectionTypeKey
  showAll?: boolean
  isLoading?: boolean
  title?: string
  albums?: PhotosApi.AlbumInfo[]
  createAlbumFn?: typeof createAlbum
  selectedAlbum?: string
  setSelectedAlbum?: (albumId: string) => void
  backPath?: string
  showAvatar?: boolean
}
export const AlbumsSection = observer<AlbumsSectionProps>(
  function AlbumListSection({
    sectionType = 'NORMAL_ALBUM',
    createAlbumFn = createAlbum,
    albums = [],
    selectedAlbum = '',
    setSelectedAlbum,
    isLoading = false,
    showAll = false,
    title,
    backPath,
    showAvatar = false,
  }) {
    const navigate = useNavigate()
    const branding = useBrandStore()
    const columns = Math.max(2, Math.round(branding.contentWidthSafe / 230))
    const showCreateAlbumButton = sectionType === 'NORMAL_ALBUM'
    const visible = showAll
      ? albums.length
      : Math.min(albums.length, columns - (showCreateAlbumButton ? 2 : 1))
    const visibleAlbums = albums.slice(0, visible)
    const hiddenAlbums: PhotosApi.AlbumInfo[] = showAll
      ? []
      : albums.slice(visible)
    const displayShowAllAlbumsButton = Boolean(hiddenAlbums.length)

    if (
      !isLoading &&
      !showCreateAlbumButton &&
      !visibleAlbums.length &&
      !hiddenAlbums.length
    ) {
      return null
    }

    return (
      <BaseAlbumsSection
        columns={columns}
        albumCount={albums.length}
        isLoading={isLoading}
        showAll={showAll}
        title={title}
        albumType={sectionType}
      >
        {showCreateAlbumButton && (
          <CreateAlbumFromAlbumThumb
            onAlbumCreated={album => navigate(`/photo/album/${album.id}`)}
          />
        )}
        {visibleAlbums.map(album =>
          setSelectedAlbum ? (
            <Button
              key={album.id}
              onClick={() =>
                setSelectedAlbum(selectedAlbum === album.id ? '' : album.id)
              }
              aria-selected={selectedAlbum === album.id}
            >
              <AlbumThumb album={album} showAvatar={showAvatar} />
            </Button>
          ) : (
            <RouterLink
              to={showAll ? `../${album.id}` : album.id}
              key={album.id}
              variant="links.plain"
              state={backPath ? { backPath } : null}
            >
              <AlbumThumb album={album} showAvatar={showAvatar} />
            </RouterLink>
          ),
        )}
        {displayShowAllAlbumsButton && (
          <Link
            key="has_more"
            to={`./${albumTypeRouteMap[CollectionType[sectionType]]}`}
          >
            <MoreAlbumsThumb
              albums={hiddenAlbums}
              sectionType={CollectionType[sectionType]}
            />
          </Link>
        )}
      </BaseAlbumsSection>
    )
  },
)
