// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AlbumPhotosSmall_container__y4gYe{padding-bottom:80px;width:var(--content-width-safe)}.AlbumPhotosSmall_list__2YWUS{width:100%;--height:calc(var(--rows)*var(--row-height));height:var(--height);position:relative;--width:calc(100%/var(--columns, 2));--grid-line-width:2px}.AlbumPhotosSmall_list__2YWUS:before{background:linear-gradient(#fff var(--grid-line-width),#0000 var(--grid-line-width),#0000 var(--row-height)),linear-gradient(90deg,#fff var(--grid-line-width),#0000 var(--grid-line-width)),#f5f5f5;background-size:var(--width) var(--row-height);content:" ";height:calc(100% - var(--row-height));width:100%}.AlbumPhotosSmall_list__2YWUS:before,.AlbumPhotosSmall_row__EeuFq{left:0;position:absolute;top:0}.AlbumPhotosSmall_row__EeuFq{aspect-ratio:1;display:flex;height:var(--row-height);overflow:hidden;padding:1px;width:var(--width)}.AlbumPhotosSmall_row__EeuFq>div{display:flex;flex:1 1 auto}.AlbumPhotosSmall_row__EeuFq>div img{flex:1 1 auto;height:100%;max-height:100%;object-fit:cover;object-position:center;width:100%}`, "",{"version":3,"sources":["webpack://./../../libraries/photos/src/components/Album/AlbumPhotos/AlbumPhotosSmall.module.scss"],"names":[],"mappings":"AACA,mCAEE,mBAAA,CADA,+BACA,CAEF,8BACE,UAAA,CACA,4CAAA,CACA,oBAAA,CACA,iBAAA,CACA,oCAAA,CACA,qBAAA,CACA,qCAOE,oMAEE,CAYF,8CAAA,CApBA,WAAA,CAKA,qCAAA,CADA,UAgBA,CAGJ,kEApBI,MAAA,CAFA,iBAAA,CACA,KA8BF,CATF,6BAGE,cAAA,CACA,YAAA,CAFA,wBAAA,CAOA,eAAA,CADA,WAAA,CAPA,kBAQA,CACA,iCAEE,YAAA,CADA,aACA,CAEA,qCACE,aAAA,CACA,WAAA,CAEA,eAAA,CACA,gBAAA,CACA,sBAAA,CAHA,UAGA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AlbumPhotosSmall_container__y4gYe`,
	"list": `AlbumPhotosSmall_list__2YWUS`,
	"row": `AlbumPhotosSmall_row__EeuFq`
};
export default ___CSS_LOADER_EXPORT___;
