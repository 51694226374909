import { useEffect, useState } from 'react'

/**
 * Returns a boolean that chnges from `false` to `true` after delayMs milliseconds has passed
 * @param delayMs Milliseconds to delay
 * @returns false, then true after delay
 */
export function useDelay(delayMs: number) {
  const [value, setValue] = useState(false)
  useEffect(() => {
    function handler() {
      setValue(true)
    }
    const timeout = setTimeout(handler, delayMs)
    return () => clearTimeout(timeout)
  }, [])
  return value
}
