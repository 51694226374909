import {
  PublicSharingServicePromiseClient,
  SharingServicePromiseClient,
} from '@jotta/grpc-web/no/jotta/openapi/sharing/v2/sharing.v2_grpc_web_pb'
import type { Address } from '@jotta/grpc-web/no/jotta/openapi/sharing/v2/sharing.v2_pb'
import {
  DeclineShareRequest,
  DisableFolderShareRequest,
  DisableShareRequest,
  EnableShareRequest,
  GetAddressBookRequest,
  GetShareInfoRequest,
  InviteMemberRequest,
  JoinShareRequest,
  LeaveShareRequest,
  LookupShareRequest,
  LookupShareResponse,
  RemoveMemberRequest,
  ShareMode,
} from '@jotta/grpc-web/no/jotta/openapi/sharing/v2/sharing.v2_pb'
import { AppError } from '@jotta/types/AppError'
import { env } from '@jotta/utils/env'
import Debug from 'debug'
import { getAuthMeta, withGrpcClientMiddleware } from './grpcutils'
import type { ShareInfoState } from './sharing/parseShareInfo'
import { parseShareInfo } from './sharing/parseShareInfo'
import { Code } from '@connectrpc/connect'
import { nanoid } from 'nanoid'
import type { ShareTokenInfo } from '@jotta/types/Files'
export { User } from '@jotta/grpc-web/no/jotta/openapi/sharing/v2/sharing.v2_pb'
export * from './sharing/emptyShareInfo'
export * from './sharing/parseShareInfo'
const debug = Debug('jotta:grpc:sharing')

let sharingServiceClient: SharingServicePromiseClient
let publicSharingServiceClient: PublicSharingServicePromiseClient

export function getSharingServiceClient() {
  if (!sharingServiceClient) {
    sharingServiceClient = withGrpcClientMiddleware(
      'sharing',
      new SharingServicePromiseClient(env.grpcApi),
    )
  }
  return sharingServiceClient
}
export function getPublicSharingServiceClient() {
  if (!publicSharingServiceClient) {
    publicSharingServiceClient = withGrpcClientMiddleware(
      'publicsharing',
      new PublicSharingServicePromiseClient(env.grpcApi),
    )
  }
  return publicSharingServiceClient
}

export async function lookupShare(
  shareId: string,
  xId: string = nanoid(),
): Promise<LookupShareResponse> {
  const lookupShareRequest = new LookupShareRequest()
  lookupShareRequest.setShareId(shareId)

  const meta = {
    'X-ID': xId,
  }

  debug(`lookupShareRequest [${xId}]`, lookupShareRequest.toObject())
  return await getPublicSharingServiceClient().lookupShare(
    lookupShareRequest,
    meta,
  )
}

export interface GetShareInfoParams {
  path: string
  token?: string
}
export async function getShareInfo({ path, token }: GetShareInfoParams) {
  const getShareInfoRequest = new GetShareInfoRequest()
  getShareInfoRequest.setPath(path)

  debug('getShareInfoRequest', getShareInfoRequest.toObject())

  const meta = await getAuthMeta(token)

  const response = await getSharingServiceClient().getShareInfo(
    getShareInfoRequest,
    meta,
  )
  const { shareInfo } = response.toObject()
  if (!shareInfo) {
    throw new AppError({
      message: 'getShareInfo: shareInfo missing from response',
    })
  }
  return shareInfo
}
export async function getShareInfoState(
  params: GetShareInfoParams,
): Promise<ShareInfoState> {
  const shareInfo = await getShareInfo(params)
  return parseShareInfo(shareInfo)
}

function getErrorKindMessage(
  errorKind: LookupShareResponse.LookupShareError.ShareError,
): string {
  switch (errorKind) {
    case LookupShareResponse.LookupShareError.ShareError.UNKNOWN_ERROR:
      return 'Unknown share error'
    case LookupShareResponse.LookupShareError.ShareError.NOT_FOUND:
      return 'Not Found'
    case LookupShareResponse.LookupShareError.ShareError.DOWNLOADS_EXCEEDED:
      return 'Downloads exceeded'
    case LookupShareResponse.LookupShareError.ShareError.DISABLED:
      return 'Disabled'
    case LookupShareResponse.LookupShareError.ShareError.DISABLED_BY_ADMIN:
      return 'Disabled by admin'
    case LookupShareResponse.LookupShareError.ShareError.SHARE_EXPIRED:
      return 'Expired'
  }
}

/**
 * Get share token from cookie if it exists, if not lookup share, set token cookie,
 * then return the token
 * @param shareId Share ID to get token for
 */
export async function getShareToken(shareId: string): Promise<ShareTokenInfo> {
  const xId = nanoid()

  const res = await lookupShare(shareId, xId)
  const response = res.toObject()

  switch (res.getResultCase()) {
    case LookupShareResponse.ResultCase.GRANTED: {
      const granted = response.granted!
      return {
        shareId,
        accessToken: granted.token?.accessToken || '',
        expiresInMillis: granted.token?.expiresInMillis || 0,
        expiresAtMillis: granted.token?.expiresAtMillis || 0,
        ownerFullName: granted.shareOwnerName || '',
      }
    }
    case LookupShareResponse.ResultCase.ERRORS: {
      const err = response.errors!
      const msg = getErrorKindMessage(err.errorKind)

      throw new AppError({
        GrpcStatus: Code.NotFound,
        message: msg,
        xid: xId,
      })
    }
    case LookupShareResponse.ResultCase.CHALLENGE: // TODO: implement challenges
    case LookupShareResponse.ResultCase.RESULT_NOT_SET:
      throw new AppError({
        GrpcStatus: Code.NotFound,
        message: 'Not found',
        xid: xId,
      })
  }
}

/**
 * Get address list
 * @param token
 * @returns Address list or empty array
 */
export async function getAddressBook(
  token?: string,
): Promise<Address.AsObject[]> {
  const meta = await getAuthMeta(token)
  const req = new GetAddressBookRequest()

  const response = await getSharingServiceClient().getAddressBook(req, meta)
  const list = response.getAddressBook()?.toObject().addressesList || []
  const deduped = new Map<string, Address.AsObject>(
    list.map(a => [a.username, a]),
  )
  return [...deduped.values()]
}
export interface TogglePublicShareParams extends GetShareInfoParams {
  on: boolean
}
export async function togglePublicShare({
  path,
  on,
  token,
}: TogglePublicShareParams) {
  if (on) {
    const response = await enablePublicShare({ path, token })
    return {
      type: 'enable',
      response,
    }
  }
  const response = await disablePublicShare({ path, token })
  return {
    type: 'disable',
    response,
  }
}
export interface EnablePublicShareParams extends GetShareInfoParams {
  shareMode?: ShareMode
}

export async function enablePublicShare({
  path,
  shareMode = ShareMode.VIEW,
  token,
}: EnablePublicShareParams) {
  const meta = await getAuthMeta(token)
  const enableShareRequest = new EnableShareRequest()
  enableShareRequest.setPath(path)
  enableShareRequest.setShareMode(shareMode)
  debug('enablePublicShare', enableShareRequest.toObject())

  const res = await getSharingServiceClient().enablePublicShare(
    enableShareRequest,
    meta,
  )
  return res.toObject()
}

export type DisablePublicShareParams = GetShareInfoParams

export async function disablePublicShare({
  path,
  token,
}: DisablePublicShareParams) {
  const meta = await getAuthMeta(token)
  const disableShareRequest = new DisableShareRequest()
  disableShareRequest.setPath(path)

  debug('disablePublicShare', disableShareRequest.toObject())

  const res = await getSharingServiceClient().disablePublicShare(
    disableShareRequest,
    meta,
  )
  return res.toObject()
}
export async function disableFolderShare({
  folderShareId,
  token,
}: {
  folderShareId: string
  token?: string
}) {
  const meta = await getAuthMeta(token)
  const req = new DisableFolderShareRequest()
  req.setFolderShareId(folderShareId)

  debug('disableFolderShare', req.toObject())

  const res = await getSharingServiceClient().disableFolderShare(req, meta)
  return res.toObject()
}
export async function removeAllMembers(params: GetShareInfoParams) {
  let shareInfo = await getShareInfo(params)
  const folderShareId = shareInfo.pathItem?.folderShareId
  if (folderShareId) {
    const emails = shareInfo.membersList.map(member => member.email)
    debug('removeAllMembers', emails)

    for (const email of emails) {
      await removeMember({ usernameOrEmail: email, folderShareId })
    }

    shareInfo = await getShareInfo(params)
  }
  return shareInfo
}
export async function removeMember({
  usernameOrEmail,
  folderShareId,
}: {
  usernameOrEmail: string
  folderShareId?: string
}) {
  const meta = await getAuthMeta()
  const removeMemberRequest = new RemoveMemberRequest()
  removeMemberRequest.setMemberUsernameOrEmail(usernameOrEmail)
  if (folderShareId) {
    removeMemberRequest.setFolderShareId(folderShareId)
  }

  debug('disablePublicShare', removeMemberRequest.toObject())

  const res = await getSharingServiceClient().removeMember(
    removeMemberRequest,
    meta,
  )
  return res.toObject()
}
export async function inviteMember({
  email,
  path,
}: {
  email: string
  path: string
}) {
  const meta = await getAuthMeta()
  const inviteMemberRequest = new InviteMemberRequest()
  inviteMemberRequest.setEmail(email)
  inviteMemberRequest.setPath(path)

  const res = await getSharingServiceClient().inviteMember(
    inviteMemberRequest,
    meta,
  )
  return res.toObject()
}
export async function leaveShare({ folderShareId }: { folderShareId: string }) {
  const meta = await getAuthMeta()
  const leaveShareRequest = new LeaveShareRequest()
  leaveShareRequest.setFolderShareId(folderShareId)

  await getSharingServiceClient().leaveShare(leaveShareRequest, meta)
  return
}
export async function joinShare({ inviteKey }: { inviteKey: string }) {
  const meta = await getAuthMeta()
  const joinShareRequest = new JoinShareRequest()
  joinShareRequest.setInviteKey(inviteKey)

  const res = await getSharingServiceClient().joinShare(joinShareRequest, meta)
  const shareInfo = res.getShareInfo()
  if (shareInfo) {
    return shareInfo.toObject()
  }
  return
}
export async function declineShareInvite({ inviteKey }: { inviteKey: string }) {
  const meta = await getAuthMeta()
  const declineShareRequest = new DeclineShareRequest()
  declineShareRequest.setInviteKey(inviteKey)

  await getSharingServiceClient().declineShare(declineShareRequest, meta)

  return
}
