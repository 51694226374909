import { Box } from '@jotta/ui/Box'
import { Input } from '@jotta/ui/Input'
import { Label } from '@jotta/ui/Label'
import { TextThemeUI } from '@jotta/ui/themeui'
import {
  applyVoucher,
  describeVoucher,
} from '@jotta/grpc-js-client/subscriptionService'
import { DescribeVoucherResponse } from '@jotta/grpc-web/no/jotta/openapi/subscription/payment.v2_pb'
import { t } from '@lingui/macro'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import Debug from 'debug'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { LoadingOverlay } from '../../Layout/LoadingOverlay/LoadingOverlay'
import { Dialog } from '../Dialog/Dialog'
import { VoucherDialogResponse } from './VoucherDialogResponse'
const debug = Debug('jotta:ui:VoucherDialog')

export interface VoucherDialogProps {
  title?: string
  initialDescribeVoucherResponse?: DescribeVoucherResponse.AsObject
  initialVoucherCode?: string

  applyVoucherFn?: typeof applyVoucher
  describeVoucherFn?: typeof describeVoucher
  onCancel: () => void
}

export function VoucherDialog({
  describeVoucherFn = describeVoucher,
  applyVoucherFn = applyVoucher,
  onCancel,
  initialDescribeVoucherResponse,
  initialVoucherCode = '',
}: VoucherDialogProps) {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<{
    code: string
  }>({
    defaultValues: { code: '' },
  })
  const [submittedCode, setSubmittedCode] = useState('')
  const [voucherInfo, setVoucherInfo] = useState(initialDescribeVoucherResponse)

  const isVoucherValid =
    voucherInfo?.status === DescribeVoucherResponse.VoucherStatus.OPEN

  const voucherQuery = useQuery({
    queryKey: ['voucher', submittedCode] as const,
    queryFn: ({ queryKey: [key, voucherCode] }) =>
      describeVoucherFn(voucherCode),
    enabled: Boolean(submittedCode),
  })

  const { data, isSuccess } = voucherQuery

  useEffect(() => {
    if (data && isSuccess) {
      setVoucherInfo(data)
    }
  }, [data, isSuccess])

  const queryClient = useQueryClient()
  const applyVoucherMutation = useMutation({
    mutationFn: applyVoucherFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['subscriptionDetails'] })
    },
  })

  useEffect(() => {
    debug('Initial code')
    if (initialVoucherCode) {
      setSubmittedCode(initialVoucherCode)
    }
    return () => {}
  }, [initialVoucherCode])
  useEffect(() => {
    debug('Initial code')
    if (initialVoucherCode) {
      setSubmittedCode(initialVoucherCode)
    }
    return () => {}
  }, [initialVoucherCode])
  const isLoading = voucherQuery.isLoading || applyVoucherMutation.isPending
  const errorMessage = ''

  const submitHandler = handleSubmit(({ code }) => {
    if (isVoucherValid && submittedCode) {
      applyVoucherMutation.mutateAsync(submittedCode).then(() => {
        onCancel()
      })
    } else {
      setSubmittedCode(code)
    }
  })

  const statusMessage = getStatusMessage(voucherInfo?.status)

  return (
    <Dialog
      title={t`Voucher`}
      buttons={[
        {
          label: t`Cancel`,
          action: onCancel,
        },
        {
          label: isVoucherValid ? t`Activate` : t`Next`,
          action: submitHandler,
        },
      ]}
    >
      <LoadingOverlay open={isLoading} />
      {isVoucherValid && voucherInfo ? (
        <VoucherDialogResponse {...voucherInfo} />
      ) : (
        <Box as="form" onSubmit={submitHandler}>
          <Label
            label={t`Type in your voucher code`}
            errorMessage={errorMessage}
          >
            <Input
              type="text"
              defaultValue={initialVoucherCode}
              placeholder={t`Voucher code`}
              {...register('code', {
                required: 'Please provide the voucher code.',
              })}
            />
          </Label>
          {(errorMessage || errors.code || statusMessage) && (
            <TextThemeUI
              variant="styles.p"
              sx={{ color: 'danger', fontSize: 1 }}
            >
              {errorMessage || errors.code?.message || statusMessage}
            </TextThemeUI>
          )}
        </Box>
      )}
    </Dialog>
  )
}
function getStatusMessage(status?: DescribeVoucherResponse.VoucherStatus) {
  switch (status) {
    case DescribeVoucherResponse.VoucherStatus.EXPIRED:
      return t`The voucher code you have supplied is expired! Please verify that the code is correct.`
    case DescribeVoucherResponse.VoucherStatus.USED:
      return t`Your voucher code is already in use! Please verify that the code is correct.`
    case DescribeVoucherResponse.VoucherStatus.NOT_FOUND:
    case DescribeVoucherResponse.VoucherStatus.UNKNOWN_STATUS:
      return t`The voucher code is invalid!`
  }

  return undefined
}
