import { useState } from 'react'
import { useGetConfig } from '@jotta/grpc-connect-client/config'
import type { Field } from '@jotta/hooks/useForm'
import { Modal } from '@jotta/ui/Modal'
import JottaTerms from '@jotta/config/JottaTerms'
import { getDetectedLocale } from '@jotta/i18n'
import { Dialog } from '@jotta/ui/Dialog'
import { useBrandName } from '@jotta/grpc-connect-client/config'
import { t, Trans } from '@lingui/macro'
import { FormFieldErrorMessage } from '@jotta/ui/Label'

export function TermsAndConditionsCheckbox({
  acceptedTerms,
  newsletter,
}: {
  acceptedTerms: Field
  newsletter: Field
}) {
  const [open, setOpen] = useState(false)
  const locale = getDetectedLocale()
  const termsHtml = locale in JottaTerms ? JottaTerms[locale] : JottaTerms.en
  const { data: getConfigResponse } = useGetConfig()
  const brandingInfo = getConfigResponse.brandingInfo
  const termsLink = brandingInfo?.termsLink
  const isJotta = brandingInfo?.brandCode === 'JOTTACLOUD'
  const isMediamarkt = brandingInfo?.brandCode === 'MEDIAMARKT'
  const actualBrandName = useBrandName()
  const brand = isMediamarkt ? 'Jottacloud' : actualBrandName
  const acceptedTermsError = acceptedTerms.error
  const newsletterError = newsletter.error
  return (
    <div>
      <label className="checkbox">
        <input type="checkbox" {...acceptedTerms.inputProps} />
        <span>
          <Trans id="I have read and accepted {brand}'s <0>terms and conditions</0>">
            I have read and accepted {brand}'s&nbsp;
            <a
              href={termsLink}
              className="!text-signup-link underline"
              target="_blank"
              rel="noreferrer"
              onClick={event => {
                if (isJotta) {
                  event.preventDefault()
                  setOpen(true)
                }
              }}
            >
              terms and conditions
            </a>
          </Trans>
        </span>
      </label>
      {acceptedTermsError && (
        <FormFieldErrorMessage
          className="col-span-2"
          errorMessage={acceptedTermsError.view}
        />
      )}
      <label className="checkbox">
        <input type="checkbox" {...newsletter.inputProps} />
        {t`I would like to receive email updates with news and information about ${brand}`}
      </label>
      {newsletterError && (
        <FormFieldErrorMessage
          className="col-span-2"
          errorMessage={newsletterError.view}
        />
      )}

      <Modal open={open} onClose={() => setOpen(false)}>
        <Dialog
          className="!max-w-screen-lg !p-0"
          onClose={() => setOpen(false)}
        >
          <div
            className="p-8 text-2xl [&_a]:!text-signup-link [&_a]:!underline [&_h1]:font-bold  [&_h3]:pb-2 [&_h3]:font-bold [&_p]:pb-2"
            dangerouslySetInnerHTML={{
              __html: termsHtml,
            }}
          />
        </Dialog>
      </Modal>
    </div>
  )
}
