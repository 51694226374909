import { Trans } from '@lingui/macro'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'

import { RouterLink } from '@jotta/ui/RouterLink'
import { useBrandStore } from '@jotta/ui/useBrandTheme'
import { useBreakpointIndex } from '@jotta/ui/useBreakpointIndex'
import { AccountType } from '@jotta/grpc-js-client/customerService'
import { Onboarding } from '@jotta/ui/Onboarding'
import type { PrivateSection } from './PrivateSection'
// import Debug from 'debug'
// const debug = Debug('jotta:webapp:TopNavBar')

export const SettingsTopNavBar: React.FC<{
  accountType?: AccountType
}> = ({ accountType }) => {
  const breakPointIndex = useBreakpointIndex()

  return breakPointIndex < 2 ? null : (
    <>
      <RouterLink variant="links.navbar" key="account" to="/web/account">
        <Trans id="Account and profile" />
      </RouterLink>
      <RouterLink variant="links.navbar" key="secure" to="/web/secure">
        <Trans id="Security" />
      </RouterLink>
      <RouterLink variant="links.navbar" key="billing" to="/web/billing">
        <Trans id="Subscription" />
      </RouterLink>
      {accountType === AccountType.FAMILY_OWNER && (
        <RouterLink variant="links.navbar" key="family" to="/web/family">
          <Trans id="Family members" />
        </RouterLink>
      )}
      <RouterLink variant="links.navbar" key="connected" to="/web/connected">
        <Trans id="Connected apps" />
      </RouterLink>
    </>
  )
}

export const TopNavBar = observer<{
  accountType?: AccountType
  section: PrivateSection
}>(function TopNavBar({ section, accountType }) {
  const layout = useBrandStore()
  const { context } = useParams()
  const isHidden = section === 'settings' && layout.currentBreakpoint < 2

  if (isHidden) {
    return null
  }

  switch (section) {
    case 'settings':
      return <SettingsTopNavBar accountType={accountType} />
    case 'web':
      return context === 'trash' ? (
        <>
          <RouterLink variant="links.navbar" to="/web/trash" icon="SvgTrash">
            <Trans>Trash</Trans>
          </RouterLink>
        </>
      ) : (
        <>
          <RouterLink
            variant="links.navbar"
            to="/web/sync/list/name"
            icon="SvgNavSynced"
          >
            <Trans>Synced</Trans>
            <Onboarding task="sync" />
          </RouterLink>
          <RouterLink
            variant="links.navbar"
            to="/web/backup/list/name"
            icon="SvgNavBackedUp"
          >
            <Trans>Backed up</Trans>
            <Onboarding task="backup" />
          </RouterLink>
          <RouterLink
            variant="links.navbar"
            to="/web/archive/list/name"
            icon="SvgNavArchived"
          >
            <Trans>Archive</Trans>
            <Onboarding task="archive" />
          </RouterLink>
          <RouterLink
            variant="links.navbar"
            to="/web/shared/list/name"
            icon="SvgNavShared"
          >
            <Trans>Shared</Trans>
            <Onboarding task="shared" />
          </RouterLink>
        </>
      )
    case 'photo':
      return (
        <>
          <RouterLink variant="links.navbar" to="/photo" end icon="SvgPhotos">
            <Trans id="photostream.allPhotos" />
          </RouterLink>
          <RouterLink variant="links.navbar" to="/photo/album" icon="SvgAlbum">
            <Trans id="photostream.albums" />
          </RouterLink>
          <RouterLink
            variant="links.navbar"
            to="/photo/all_shared"
            icon="SvgLink"
          >
            <Trans id="photostream.allShared" />
          </RouterLink>
          <RouterLink
            variant="links.navbar"
            to="/photo/all_hidden"
            icon="SvgHide"
          >
            <Trans id="HiddenPhotosButton.label" />
          </RouterLink>
        </>
      )
    case 'biz':
      return (
        <>
          <RouterLink variant="links.navbar" to="/biz" end>
            <Trans>Dashboard</Trans>
          </RouterLink>
          <RouterLink variant="links.navbar" to="/biz/users">
            <Trans>Users</Trans>
          </RouterLink>
          <RouterLink variant="links.navbar" to="/biz/settings">
            <Trans>Settings</Trans>
          </RouterLink>
        </>
      )
    default:
      return null
  }
})
