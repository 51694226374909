// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AlbumPhotosLarge_container__skEiX{padding-bottom:80px;padding-left:var(--s4);padding-right:var(--s4);width:var(--content-width-safe)}.AlbumPhotosLarge_list__tiH5i{width:100%;--height:calc(var(--rows)*var(--row-height));height:var(--height);position:relative;--grid-line-width:2px}.AlbumPhotosLarge_list__tiH5i:before{background:linear-gradient(#fff var(--grid-line-width),#0000 var(--grid-line-width),#0000 var(--row-height)),#f5f5f5;background-size:100% var(--row-height);content:" ";height:calc(100% - var(--row-height));left:0;top:0}.AlbumPhotosLarge_list__tiH5i:before,.AlbumPhotosLarge_row__Mg7Fn{position:absolute;width:100%}.AlbumPhotosLarge_row__Mg7Fn{display:flex;gap:2px;height:var(--row-height,200px);justify-content:stretch;max-width:100%;overflow:hidden;padding-bottom:2px}.AlbumPhotosLarge_row__Mg7Fn[data-align-start=true]{justify-content:flex-start}.AlbumPhotosLarge_row__Mg7Fn[data-align-start=true]>div{flex:0 0 auto}.AlbumPhotosLarge_row__Mg7Fn>div{display:flex;flex:1 1 auto}.AlbumPhotosLarge_row__Mg7Fn>div img{flex:1 1 auto;height:100%;max-height:100%;object-fit:cover;object-position:center;width:100%}`, "",{"version":3,"sources":["webpack://./../../libraries/photos/src/components/Album/AlbumPhotos/AlbumPhotosLarge.module.scss"],"names":[],"mappings":"AACA,mCAIE,mBAAA,CAFA,sBAAA,CACA,uBAAA,CAFA,+BAGA,CAEF,8BACE,UAAA,CACA,4CAAA,CACA,oBAAA,CACA,iBAAA,CACA,qBAAA,CAEA,qCAOE,oHAEE,CAMF,sCAAA,CAdA,WAAA,CAKA,qCAAA,CAFA,MAAA,CADA,KAYA,CAGJ,kEAhBI,iBAAA,CAGA,UAsBF,CATF,6BAGE,YAAA,CAIA,OAAA,CAFA,8BAAA,CADA,uBAAA,CAFA,cAAA,CAOA,eAAA,CADA,kBACA,CACA,oDACE,0BAAA,CACA,wDACE,aAAA,CAGJ,iCAEE,YAAA,CADA,aACA,CAEA,qCACE,aAAA,CACA,WAAA,CAEA,eAAA,CACA,gBAAA,CACA,sBAAA,CAHA,UAGA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AlbumPhotosLarge_container__skEiX`,
	"list": `AlbumPhotosLarge_list__tiH5i`,
	"row": `AlbumPhotosLarge_row__Mg7Fn`
};
export default ___CSS_LOADER_EXPORT___;
