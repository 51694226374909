import {
  action,
  makeAutoObservable,
  reaction,
  ObservableSet,
  values,
} from 'mobx'
import type { SyntheticEvent } from 'react'
import type { PressEvent } from 'react-aria'
import type { PhotoStore } from './PhotoStore'
import { hasAddPhotoMode } from '../components/PhotoGallery/useAddPhotoMode'
import Debug from 'debug'
const debug = Debug('jotta:photos:SelectionStore')

export class SelectionStore {
  public readonly selection = new ObservableSet<string>()
  private lastSelected: string | null = null

  public get values() {
    return values(this.selection)
  }

  public set values(ids: readonly string[]) {
    for (const id of ids) {
      this.selection.add(id)
    }
  }

  public get hasSelection() {
    return Boolean(this.selection.size)
  }

  private getCurrentId = action<(event: SyntheticEvent) => string | null>(
    'toggleCurrent',
    e => {
      e.preventDefault()
      e.stopPropagation()
      const el = e.currentTarget || e.target
      if (el instanceof HTMLElement) {
        const id = el.dataset['photoid']
        return id || null
      }
      return null
    },
  )

  onClickToggle = action<(event: SyntheticEvent) => void>(
    'onClickToggle',
    e => {
      const currentId = this.getCurrentId(e)

      if (!currentId) {
        return
      }

      const shiftKey = Boolean((e as React.KeyboardEvent).shiftKey)

      if (shiftKey && this.lastSelected && currentId !== this.lastSelected) {
        // Select/deselect range

        const [start, end] = [
          this.photo.photoIds.indexOf(this.lastSelected),
          this.photo.photoIds.indexOf(currentId),
        ].sort((a, b) => a - b)

        if (start >= 0 && end >= 0) {
          for (let i = start; i < end; i++) {
            this.selection.add(this.photo.photoIds[i])
          }
          this.selection.add(currentId)
        }
      } else {
        this.toggle(currentId)
      }

      if (this.selection.has(currentId)) {
        this.lastSelected = currentId
      } else {
        this.lastSelected = null
      }
    },
  )

  onClickToggleIfActive = action<(event: SyntheticEvent) => void>(
    'onClickToggleIfActive',
    e => {
      const shiftKey = (e as unknown as React.KeyboardEvent).shiftKey
      debug(e)
      if (this.selection.size || hasAddPhotoMode() || shiftKey) {
        this.onClickToggle(e)
      }
    },
  )

  public toggle(id: string, selected?: boolean) {
    selected =
      typeof selected === 'boolean' ? selected : !this.selection.has(id)

    if (selected) {
      this.selection.add(id)
    } else {
      this.selection.delete(id)
    }
  }

  public clearOnClose(open = false) {
    if (!open) {
      this.selection.clear()
    }
  }
  public onPressToggle(e: Pick<PressEvent, 'target' | 'shiftKey'>) {
    const currentId =
      e.target.closest<HTMLElement>('[data-photoid]')?.dataset?.photoid
    if (!currentId) {
      return
    }
    if (e.shiftKey && this.lastSelected && currentId !== this.lastSelected) {
      // Select/deselect range

      const [start, end] = [
        this.photo.photoIds.indexOf(this.lastSelected),
        this.photo.photoIds.indexOf(currentId),
      ].sort((a, b) => a - b)

      if (start >= 0 && end >= 0) {
        for (let i = start; i < end; i++) {
          this.selection.add(this.photo.photoIds[i])
        }
        this.selection.add(currentId)
      }
    } else {
      this.toggle(currentId)
    }

    if (this.selection.has(currentId)) {
      this.lastSelected = currentId
    } else {
      this.lastSelected = null
    }
  }

  public constructor(public photo: PhotoStore) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      },
    )

    reaction(
      () => [this.selection.size],
      () => {
        if (this.selection.size === 0) {
          this.lastSelected = null
        }
      },
    )
  }
}
