import { useQuery } from '@connectrpc/connect-query'
import type { FindSimilarPhotosResponse } from '@jotta/grpc-connect-openapi/esm/openapi/search/search.v2_pb'
import { PhotoSearchResponse } from '@jotta/grpc-connect-openapi/esm/openapi/search/search.v2_pb'
import {
  findSimilarPhotos,
  photoSearch,
} from '@jotta/grpc-connect-openapi/searchQuery'
import moize from 'moize'
import ms from 'ms'
import { useSearchParams } from 'react-router-dom'
import type { ParsedGetPeopleResponse } from '../../people/internal/api/PeopleApi'
import { usePeople } from '../../people/internal/api/PeopleApi'
import { authTransport } from '@jotta/grpc-connect-client/transport'

const parseMatches = moize(
  (
    res?: PhotoSearchResponse | FindSimilarPhotosResponse,
    peopleIdsInSearch: string[] = [],
    peopleRes?: ParsedGetPeopleResponse,
    id: string | null = null,
  ) => {
    const matches = res?.matches || []
    const matchedPersonIds =
      res instanceof PhotoSearchResponse ? res.matchedPersonIds || [] : []
    const ids = matches.map(v => v.md5)
    const photoIds = matches.map(v => v.photoId)
    const matchedPeople =
      peopleRes?.people.filter(
        item =>
          matchedPersonIds.includes(item.id) &&
          !peopleIdsInSearch.includes(item.id),
      ) || []
    const suggestedPeople = res ? matchedPeople : peopleRes?.people || []
    return {
      ids,
      photoIds,
      matchedPersonIds,
      matches,
      matchedPeople,
      suggestedPeople,
      similarPhotoId: id,
    }
  },
)

export function usePhotoSearchResult() {
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  const personIds = searchParams.getAll('p')
  const q = searchParams.get('q') || ''
  const [peopleResponse] = usePeople()

  const photoSearchQuery = useQuery(
    photoSearch,
    {
      debug: true,
      personId: personIds.length ? personIds : undefined,
      query: q,
      minimumAestheticScore: 0,
    },
    {
      transport: authTransport,
      retry: 0,
      enabled: Boolean((!id && q) || personIds.length),
      staleTime: ms('2m'),
    },
  )
  const findSimilarPhotosQuery = useQuery(
    findSimilarPhotos,
    {
      debug: true,
      photoId: String(id),
      minimumAestheticScore: 0,
    },
    {
      transport: authTransport,
      retry: 0,
      enabled: Boolean(id),
      staleTime: ms('2m'),
    },
  )
  const query = id ? findSimilarPhotosQuery : photoSearchQuery
  const result = parseMatches(query.data, personIds, peopleResponse, id)

  return [result, query] as const
}
