import type { FC, ImgHTMLAttributes } from 'react'
import { useEffect, useState } from 'react'

export interface ImageWithFallbackProp {
  fallback: JSX.Element
  initialError?: boolean
  initialLoaded?: boolean
}

export type ImageWithFallbackProps = ImgHTMLAttributes<HTMLImageElement> &
  ImageWithFallbackProp

export const ImageWithFallback: FC<ImageWithFallbackProps> = ({
  src,
  fallback,
  initialError = false,
  initialLoaded = false,
  ...rest
}) => {
  // use state to stop a potential infinite error loop
  const [hasErrored, setError] = useState(initialError)
  const [hasLoaded, setLoaded] = useState(initialLoaded)

  useEffect(() => {
    if (src) {
      // preload image
      const htmlImageElement = new Image()
      htmlImageElement.onload = () => setLoaded(true)
      htmlImageElement.onerror = () => setError(true)
      htmlImageElement.src = src

      return () => {
        htmlImageElement.onload = null
        htmlImageElement.onerror = null
        if (htmlImageElement.remove) {
          htmlImageElement.remove()
        }
      }
    }
  }, [src])

  if (hasErrored) {
    return fallback
  } else if (hasLoaded) {
    return (
      <div
        sx={{
          position: 'relative',
          width: '100%',
          height: 0,
          paddingTop: '100%',
        }}
      >
        <img
          alt=""
          src={src}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            objectFit: 'cover',
            width: '100%',
            height: '100%',
          }}
          onLoad={ev => {
            setLoaded(true)
          }}
          onError={ev => {
            setError(true)
          }}
          {...rest}
        />
      </div>
    )
  } else {
    return fallback
  }
}
