import { useState, useMemo } from 'react'

export function useLowQualityImagePlaceholder(
  imageUrl: string,
  placeholderUrl: string,
) {
  const image = useMemo(() => {
    const image = new Image()
    image.src = imageUrl
    return image
  }, [imageUrl])

  const [url, setUrl] = useState(image.complete ? imageUrl : placeholderUrl)

  if (!image.complete && !image.onload) {
    image.onload = () => {
      setUrl(imageUrl)
    }
  }

  return url
}
