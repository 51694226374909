import { useBrandStore } from '@jotta/ui/useBrandTheme'
import type { FileRouteViewMode } from '@jotta/types/schemas/FileRouteSchema'
import { LoadingOverlaySpinner } from '@jotta/ui/LoadingOverlay'
import Debug from 'debug'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import type { CSSProperties, ReactNode } from 'react'
import { useEffect, useState } from 'react'
import styles from './FileList.module.scss'
import { FileListRow } from './FileListRow'
import { FileListStore } from './FileListStore'
import { cn } from '@jotta/utils/css'
const debug = Debug('jotta:files:FileList')
export type FileListRenderItem = (
  index: number,
  key: string,
  viewMode: FileRouteViewMode,
) => JSX.Element

export interface FileListProps {
  itemCount: number
  renderItem: FileListRenderItem
  emptyContent?: ReactNode
  showEmptyContentWhenEmpty?: boolean
  viewMode: FileRouteViewMode
  className?: string
  disabled?: boolean
  loading?: boolean
  onRenderItemCount?: (count: number) => void
}

export const FileListRows = observer(function FileList({
  store,
}: {
  store: FileListStore
}) {
  return (
    <>
      {store.visibleRows.map(row => (
        <FileListRow key={row.key} row={row} />
      ))}
    </>
  )
})
export const FileList = observer(function FileList({
  className,
  itemCount,
  renderItem,
  emptyContent = null,
  showEmptyContentWhenEmpty = false,
  viewMode,
  disabled = false,
  onRenderItemCount,
  loading = false,
}: FileListProps) {
  const branding = useBrandStore()
  const [store] = useState(
    new FileListStore(renderItem, viewMode, itemCount, branding),
  )
  const isEmpty = !itemCount && showEmptyContentWhenEmpty
  useEffect(() => {
    runInAction(() => {
      if (itemCount !== store.itemCount) {
        store.itemCount = itemCount
      }
      if (viewMode !== store.viewMode) {
        store.viewMode = viewMode
      }
      if (renderItem !== store.renderItem) {
        store.renderItem = renderItem
      }
    })
  }, [store, itemCount, renderItem, viewMode])

  useEffect(() => {
    if (!itemCount && !isEmpty) {
      return
    }
    if (onRenderItemCount) {
      onRenderItemCount(itemCount)
    }
  }, [itemCount, onRenderItemCount, isEmpty])

  return (
    <>
      <div
        data-testid={
          viewMode === 'list' ? 'FolderViewList' : 'FolderViewThumbs'
        }
        className={cn(styles.container, className, {
          viewmodelist: viewMode === 'list',
          viewmodethumbs: viewMode === 'thumbs',
          [styles.empty]: isEmpty,
          [styles.list]: viewMode === 'list' && !isEmpty,
          [styles.thumbs]: viewMode === 'thumbs' && !isEmpty,
          [styles.disabled]: disabled,
        })}
        style={
          {
            '--virtual-columns': `${store.columns}`,
            '--virtual-row-height': `${store.rowHeight}px`,
            height: store.listHeight || 'auto',
          } as CSSProperties
        }
      >
        {isEmpty ? emptyContent : <FileListRows store={store} />}
      </div>
      {(loading || disabled) && (
        <div className={styles.loadingSpinner}>
          <LoadingOverlaySpinner />
        </div>
      )}
    </>
  )
})
