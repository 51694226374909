import { isAuthenticated } from '@jotta/auth-client/useAuthStatus'
import { queryClient } from '@jotta/query'
import type { AppError } from '@jotta/types/AppError'
import { handleUnknownError } from '@jotta/types/AppError'
import type { PhotosApi } from '@jotta/types/PhotosApi'
import { parseAlbumInfo } from '@jotta/types/schemas/AlbumSchema'
import type {
  QueryFunctionContext,
  QueryObserverOptions,
  UseQueryOptions,
} from '@tanstack/react-query'
import { keepPreviousData } from '@tanstack/react-query'
import Debug from 'debug'
import path from 'path'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { useRouteLoaderData } from 'react-router-dom'
import type { SetRequired } from 'type-fest'
import { getApi } from '../../../api/photosApi'
import { fetchCustomer } from '@jotta/grpc-connect-client/customer'
const debug = Debug('jotta:photos:Album.loaders')

export interface FetchAlbumInfoOptions {
  albumId: string
  isPublic?: boolean
}
export async function fetchAlbumInfo({
  albumId,
  isPublic = false,
}: FetchAlbumInfoOptions) {
  const apiPath = path.join(isPublic ? 'public' : 'albums', albumId)
  const result = await getApi()
    .get(apiPath, {
      searchParams: {
        limit: 1,
        comments: false,
      },
    })
    .json<unknown>()
  const data: PhotosApi.AlbumInfo = parseAlbumInfo(result)
  data.photoIds = []
  data.total = 0
  return data
}

/**
 * Loaders for doing the inital album fetch, before starting infinite loading
 */
export type AlbumInfoQueryKey = readonly [
  key: 'album',
  view: 'info',
  options: Required<FetchAlbumInfoOptions>,
]
export function getAlbumInfoQueryKey({
  albumId,
  isPublic = false,
}: FetchAlbumInfoOptions): AlbumInfoQueryKey {
  return ['album', 'info', { albumId, isPublic }]
}
export type AlbumInfoQueryFunctionContext = QueryFunctionContext<
  AlbumInfoQueryKey,
  string
>
export type AlbumInfoQueryOptions = SetRequired<
  UseQueryOptions<
    PhotosApi.AlbumInfo,
    unknown,
    PhotosApi.AlbumInfo,
    AlbumInfoQueryKey
  >,
  'queryFn' | 'queryKey'
>
export type AlbumInfoQueryObserverOptions = SetRequired<
  QueryObserverOptions<
    PhotosApi.AlbumInfo,
    AppError,
    PhotosApi.AlbumInfo,
    AlbumInfoQueryKey
  >,
  'queryFn' | 'queryKey'
>

/**
 * Query options for fetching Album Info (the album itself, not the children)
 * Creates queryOptions that can be used both with useQuery and queryClient.fetchQuery
 * @param fetchOptions
 * @param options
 * @returns
 */
export function getAlbumInfoQueryOptions(
  fetchOptions: FetchAlbumInfoOptions,
  options: Partial<AlbumInfoQueryOptions> = {},
): AlbumInfoQueryOptions {
  const queryOptions: AlbumInfoQueryOptions = {
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 2, // 2 minutes
    queryKey: getAlbumInfoQueryKey(fetchOptions),
    queryFn: () => {
      // debug('pageParam', pageParam)
      return fetchAlbumInfo(fetchOptions)
    },
    ...options,
  }
  return queryOptions
}
/**
 * Query options for fetching Album Info (the album itself, not the children)
 * Creates queryOptions that can be used both with useQuery and queryClient.fetchQuery
 * @param fetchOptions
 * @param options
 * @returns
 */
export function getAlbumInfoQueryObserverOptions(
  fetchOptions: FetchAlbumInfoOptions,
  options: Partial<AlbumInfoQueryObserverOptions> = {},
): AlbumInfoQueryObserverOptions {
  const queryOptions: AlbumInfoQueryObserverOptions = {
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 2, // 2 minutes
    queryKey: getAlbumInfoQueryKey(fetchOptions),
    queryFn: () => {
      // debug('pageParam', pageParam)
      return fetchAlbumInfo(fetchOptions)
    },
    ...options,
  }
  return queryOptions
}

export function isAlbumOwner(
  album: PhotosApi.AlbumInfo,
  username: string,
): boolean {
  return (
    album.isOwner ||
    album.shareInfo?.admin ||
    (username && album.username === username) ||
    album.shareInfo?.owner === username
  )
}
export function isAlbumShareOwner(
  album: PhotosApi.AlbumInfo,
  username: string,
): boolean {
  return Boolean(
    username &&
      album.shareInfo &&
      album.shareInfo.admin &&
      album.shareInfo.owner === username,
  )
}
export function isAlbumSubscriber(
  album: PhotosApi.AlbumInfo,
  username: string,
): boolean {
  return Boolean(
    username &&
      album.shareInfo &&
      album.shareInfo.subscribers.some(s => s.username === username),
  )
}
export function isAlbumUser(
  album: PhotosApi.AlbumInfo,
  username: string,
): boolean {
  return isAlbumOwner(album, username) || isAlbumSubscriber(album, username)
}
export interface AlbumInfoLoaderData {
  album: PhotosApi.AlbumInfo
  redirectTo: string
}
export const publicAlbumInfoLoaderId = 'PublicAlbumInfo' as const
export const albumInfoLoaderId = 'AlbumInfo' as const
export function usePublicAlbumInfoLoaderData() {
  const data = useRouteLoaderData(
    publicAlbumInfoLoaderId,
  ) as AlbumInfoLoaderData
  return data
}
export function useAlbumInfoLoaderData() {
  const data = useRouteLoaderData(albumInfoLoaderId) as AlbumInfoLoaderData
  return data
}

/**
 * Route loader for public shared album
 * @param args
 * @returns
 */
export async function publicAlbumInfoLoader(
  args: LoaderFunctionArgs,
): Promise<AlbumInfoLoaderData | null> {
  const log = debug.extend('publicAlbumInfoLoader')
  log(args)
  const { shareId = '', mediaId = '' } = args.params
  if (!shareId) {
    return null
  }
  log('share ID', shareId)
  try {
    const authenticated = await isAuthenticated()
    const username = authenticated ? (await fetchCustomer()).username : ''

    const options = getAlbumInfoQueryOptions({
      albumId: shareId,
      isPublic: true,
    })
    const album =
      queryClient.getQueryData<PhotosApi.Album>(options.queryKey) ??
      (await queryClient.fetchQuery(options))
    log('album', album)
    let redirectTo = ''
    if (
      authenticated &&
      !album.isSharedPhotos &&
      isAlbumUser(album, username)
    ) {
      const url = new URL(args.request.url)
      url.pathname = path.join('/photo/album/', shareId, mediaId)
      redirectTo = url.pathname + url.search
      log('redirectTo', redirectTo)
    }
    return {
      album,
      redirectTo,
    }
  } catch (err) {
    const error = handleUnknownError(err)
    throw new Response(error.message, { status: error.status })
  }
}
export async function albumInfoLoader(
  args: LoaderFunctionArgs,
): Promise<AlbumInfoLoaderData> {
  const log = debug.extend('albumInfoLoader')
  const { albumId, mediaId = '' } = args.params
  log('Album ID', albumId)
  const isPublic = false
  if (!albumId) {
    throw new Error('missing album ID')
  }
  try {
    const authenticated = await isAuthenticated()
    if (authenticated) {
      const options = getAlbumInfoQueryOptions({
        albumId,
        isPublic,
      })
      const album =
        queryClient.getQueryData<PhotosApi.Album>(options.queryKey) ??
        (await queryClient.fetchQuery(options))
      let redirectTo = ''
      if (album.id !== albumId) {
        const url = new URL(args.request.url)
        url.pathname = path.join('/photo/album/', album.id, mediaId)
        redirectTo = url.pathname + url.search
      }
      return {
        album,
        redirectTo,
      }
    }
    throw new Response('Not authorized', { status: 401 })
  } catch (err) {
    const error = handleUnknownError(err)
    throw new Response(error.message, { status: error.status })
  }
}
