import { Box } from '@jotta/ui/Box'
import { DownloadSection } from '@jotta/ui/DownloadSection'
import { observer } from 'mobx-react-lite'
import { useGetConfig } from '@jotta/grpc-connect-client/config'

export const SmartSearchDownload: React.FC = observer(
  function SmartSearchDownload() {
    const { brandingInfo } = useGetConfig().data

    if (!brandingInfo) {
      return null
    }

    const {
      brandName,
      downloadMacLink,
      downloadWinLink,
      downloadIosLink,
      downloadAndroidLink,
    } = brandingInfo

    const hasMobileLinks = !!downloadIosLink && !!downloadAndroidLink

    return (
      <Box
        sx={{
          variant: 'layout.downloadPageContainer',
        }}
      >
        {hasMobileLinks && (
          <DownloadSection
            headerText={` Download ${brandName}`}
            subHeaderText={`Try Smart Photo Search by downloading the Jottacloud apps!`}
            sectionItems={[
              {
                icon: 'SvgAppleLogo',
                downloadLink: downloadIosLink,
                iconButton: {
                  label: 'App Store',
                  link: downloadIosLink,
                },
              },
              {
                icon: 'SvgAndroid',
                downloadLink: downloadAndroidLink,
                iconButton: {
                  label: 'Google play',
                  link: downloadAndroidLink,
                },
              },
              {
                icon: 'SvgAppleLogo',
                downloadLink: downloadMacLink,
                iconButton: {
                  label: 'MacOS',
                  link: downloadMacLink,
                },
              },
              {
                icon: 'SvgWindows',
                downloadLink: downloadWinLink,
                iconButton: {
                  label: 'Windows',
                  link: downloadWinLink,
                },
              },
            ]}
            sx={{
              variant: 'layout.downloadSection',
              pt: 0,
              pb: 0,
              margin: '50px auto',
              bg: 'white',
            }}
          />
        )}
      </Box>
    )
  },
)
