import { AlertStore, useAlertListStore } from '@jotta/ui/AlertStore'
import { t, Trans } from '@lingui/macro'
import { useMemo } from 'react'
export function useFollowSharedAlbumAlert(shareId = '') {
  const { alert } = useAlertListStore()

  return useMemo(() => {
    const followSharedAlbumAlert = new AlertStore({
      testid: 'followSharedAlbumAlert',
      message: <Trans>Would you like to follow this album?</Trans>,
      severity: 'followSharedAlbum',
      actions: [
        {
          action: `/share/${shareId}/follow`,
          text: t`JoinAlbumButton.label`,
          testId: 'FollowSharedAlbum',
        },
      ],
    })
    function displayFollowAlbumAlert() {
      alert.showAlert(followSharedAlbumAlert)
    }
    return {
      followSharedAlbumAlert,
      displayFollowAlbumAlert,
    }
  }, [alert, shareId])
}
