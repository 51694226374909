import { DeviceServicePromiseClient } from '@jotta/grpc-web/no/jotta/openapi/device/v2/device.v2_grpc_web_pb'
import { DeleteRequest } from '@jotta/grpc-web/no/jotta/openapi/device/v2/device.v2_pb'
import { withGrpcClientMiddleware } from './grpcutils'
import { env } from '@jotta/utils/env'

let deviceServiceClient: DeviceServicePromiseClient

export function getDeviceServiceClient() {
  if (!deviceServiceClient) {
    deviceServiceClient = withGrpcClientMiddleware(
      'device',
      new DeviceServicePromiseClient(env.grpcApi),
    )
  }
  return deviceServiceClient
}

export async function deleteDevice(deviceId: string) {
  const request = new DeleteRequest().setId(deviceId)
  return getDeviceServiceClient().delete(request)
}
