import { GetPathRequest } from '@jotta/grpc-web/no/jotta/openapi/file/file.v2_pb'
import { z } from 'zod'

export const FileRouteContextSchema = z.enum([
  'archive',
  'backup',
  'contacts',
  'trash',
  'shared',
  'sync',
  'photos',
  'search',
])
export const fileRouteRegEx = new RegExp(
  `^/web/(${FileRouteContextSchema.options.join('|')})`,
)
export type FileRouteContext = z.infer<typeof FileRouteContextSchema>
export const fileRouteContexts = FileRouteContextSchema.options
export function isFileRouteContext(value: unknown): value is FileRouteContext {
  return FileRouteContextSchema.safeParse(value).success
}
export const FileRouteViewModeSchema = z.enum(['list', 'thumbs'])
export type FileRouteViewMode = z.infer<typeof FileRouteViewModeSchema>
export function isFileRouteViewMode(
  value: unknown,
): value is FileRouteViewMode {
  return FileRouteViewModeSchema.safeParse(value).success
}
export const FileRouteSortBySchema = z.enum([
  'name',
  'name-desc',
  'modified',
  'modified-desc',
  'size',
  'size-desc',
])
export const FileRouteSearchQuerySchema = z.string()
export type FileRouteSortBy = z.infer<typeof FileRouteSortBySchema>
export function isFileRouteSortBy(value: unknown): value is FileRouteSortBy {
  return FileRouteSortBySchema.safeParse(value).success
}
export const FileRouteSortByToSortColumnAndDir = {
  name: {
    dir: 'asc',
    name: 'name',
    current: 'name',
    next: 'name-desc',
  },
  'name-desc': {
    dir: 'desc',
    name: 'name',
    current: 'name-desc',
    next: 'name',
  },
  modified: {
    dir: 'asc',
    name: 'modified',
    current: 'modified',
    next: 'modified-desc',
  },
  'modified-desc': {
    dir: 'desc',
    name: 'modified',
    current: 'modified-desc',
    next: 'modified',
  },
  size: {
    dir: 'asc',
    name: 'size',
    current: 'size',
    next: 'size-desc',
  },
  'size-desc': {
    dir: 'desc',
    name: 'size',
    current: 'size-desc',
    next: 'size',
  },
} satisfies Record<
  FileRouteSortBy,
  {
    dir: 'asc' | 'desc'
    name: FileRouteSortByColumn
    current: FileRouteSortBy
    next: FileRouteSortBy
  }
>
export type FileRouteSortByColumn = 'name' | 'modified' | 'size'

export const fileRouteSortByToGetPathrequestSortByEnum: Record<
  FileRouteSortBy,
  GetPathRequest.Sort
> = {
  name: GetPathRequest.Sort.NAME_ASC,
  'name-desc': GetPathRequest.Sort.NAME_DESC,
  modified: GetPathRequest.Sort.DATE_ASC,
  'modified-desc': GetPathRequest.Sort.DATE_DESC,
  size: GetPathRequest.Sort.SIZE_ASC,
  'size-desc': GetPathRequest.Sort.SIZE_DESC,
}

export const FileRouteSchema = z
  .object({
    context: FileRouteContextSchema.default('sync'),
    viewMode: FileRouteViewModeSchema.default('list'),
    sortBy: FileRouteSortBySchema.default('name'),
    query: FileRouteSearchQuerySchema.optional(),
    '*': z.string().optional().default(''),
  })
  .transform(v => ({
    ...v,
    context: v.query !== undefined ? 'search' : v.context,
  }))

export type FileRouteParams = z.infer<typeof FileRouteSchema>
