import type { Challenges } from '@jotta/grpc-web/no/jotta/openapi/credentials_pb'
import { action, makeAutoObservable, runInAction } from 'mobx'
import type {
  ChallengeResolver,
  ChallengeableRequestParams,
} from '../../utils/ChallengeModel'
import { ChallengeStates, ResolveState } from '../../utils/ChallengeModel'
import {
  generateChallenges,
  updateRequestsCredentialsByChallenge,
  retryRequestCall,
} from '../../utils/ChallengeUtils'

export class ChallengeStore {
  challenges: ChallengeResolver[] = []
  isRequesting = false

  constructor() {
    makeAutoObservable(this)
  }

  loadChallenges = (
    challenges: Challenges.AsObject,
    callerInformation: ChallengeableRequestParams,
  ) => {
    this.isRequesting = false
    this.challenges = generateChallenges(challenges, callerInformation)
  }

  setUsersAnswer = (challenge: ChallengeResolver, value: string) => {
    runInAction(() => (this.isRequesting = true))

    updateRequestsCredentialsByChallenge(challenge, value)

    if (!this.nextResolver) {
      retryRequestCall(challenge)
    }
    this.setChallengeState(challenge, ChallengeStates.ANSWERED)
  }

  cancelChallenges = action((open: boolean) => {
    if (!open) {
      this.challenges = []
      if (this.currentResolver?.onResolve) {
        this.currentResolver.onResolve(ResolveState.CANCELLED_BY_USER)
      }
    }
  })

  setChallengeState = (
    challenge: ChallengeResolver,
    state: ChallengeStates,
  ) => {
    const itemIndex = this.challenges.findIndex(e => e === challenge)
    if (itemIndex >= 0) {
      this.challenges[itemIndex].state = state
    }
  }

  get currentResolver(): ChallengeResolver | null {
    for (const challenge of this.challenges) {
      if (challenge.state === ChallengeStates.PENDING) {
        return challenge
      }
    }

    return null
  }

  get nextResolver(): ChallengeResolver | null {
    for (const challenge of this.challenges.filter(
      e => e.type !== this.currentResolver?.type,
    )) {
      if (challenge.state === ChallengeStates.PENDING) {
        return challenge
      }
    }

    return null
  }
}
