import { Box } from '@jotta/ui/Box'
import { BrandIcon } from '@jotta/ui/BrandIcon'
import { Button } from '@jotta/ui/Button'
import { Stack } from '@jotta/ui/Stack'
import { ProgressBar } from '@jotta/ui/ProgressBar'
import { Trans } from '@lingui/macro'
import { observer } from 'mobx-react-lite'
import type { UploadPhotoQueueStatusProps } from './UploadPhotoQueueStatus'

import Debug from 'debug'
const debug = Debug('jotta:photos:UploadPhotoQueueStatus')

export const UploadPhotoQueueStatusUploading =
  observer<UploadPhotoQueueStatusProps>(
    function UploadPhotoQueueStatusUploading({ status, onCancel }) {
      const { resolved: completed, total, progress } = status

      return (
        <Stack
          horizontal
          gap={2}
          sx={{
            gridTemplateColumns: '80px 1fr',
            alignItems: 'center',
          }}
        >
          {status.thumbSrc ? (
            <img
              data-testid="img"
              sx={{
                display: 'block',
                height: '80px',
                width: '80px',
                aspectRatio: '1',
                objectFit: 'cover',
                objectPosition: 'center',
              }}
              draggable={false}
              src={status.thumbSrc}
              alt=""
            />
          ) : (
            <BrandIcon
              icon={
                status.type.startsWith('image/')
                  ? 'SvgThumbImage'
                  : 'SvgThumbVideo'
              }
              sx={{
                width: '80px',
                height: '80px',
              }}
            />
          )}

          <Stack gap={2}>
            <Box>
              <Trans>
                Uploading {completed} of {total} files
              </Trans>
              &#x3000;
              {onCancel && (
                <Button variant="buttons.text" onClick={onCancel}>
                  <Trans>Cancel</Trans>
                </Button>
              )}
            </Box>
            <ProgressBar
              total={1}
              completed={progress}
              variant="styles.uploadProgress"
            />
          </Stack>
        </Stack>
      )
    },
  )
