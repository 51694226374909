import { useQuery } from '@connectrpc/connect-query'
import { useAuthenticated } from '@jotta/auth-client/useAuthStatus'
import { getConfig as getConfigDescriptor } from '@jotta/grpc-connect-openapi/esm/openapi/config/v2/config.v2-ConfigService_connectquery'
import { FeatureToggle } from '@jotta/types/FeatureToggle'
import { env } from '@jotta/utils/env'
import { useEffect, useMemo } from 'react'
import { emptyConfigResponse } from './emptyConfigResponse'
import { getCurrentLanguage } from '@jotta/i18n'
import { createConnectQuery } from '../createConnectQuery'
import { authTransport } from '../transport'
import type { GetConfigRequest } from '@jotta/grpc-connect-openapi/config'

const getConfigQuery = createConnectQuery(getConfigDescriptor)

function makeRequest(
  customerGroupOverride?: string,
): Partial<GetConfigRequest> {
  return {
    hostname: env.isLocal ? 'jottacloud.com' : window.location.hostname,
    featureToggleIds: Object.values(FeatureToggle),
    preferredLanguage: getCurrentLanguage(),
    customerGroupCode: customerGroupOverride,
  }
}
export async function getConfig() {
  return (
    (await getConfigQuery.fetch(makeRequest(), {
      transport: authTransport,
    })) || emptyConfigResponse
  )
}

export function useGetConfig(customerGroupOverride?: string) {
  const authenticated = useAuthenticated()
  const enabled = authenticated !== 'UNKNOWN'
  const request = makeRequest(customerGroupOverride)

  const q = useQuery(getConfigDescriptor, request, {
    refetchOnMount: false,
    enabled,
  })
  const { refetch } = q

  // Refetch when authenticated changes
  useEffect(() => {
    if (enabled) {
      // Run in cleanup to avoid double refetch
      return () => {
        refetch()
      }
    }
  }, [enabled, refetch, authenticated])

  return useMemo(
    () => ({
      ...q,
      data: q.data || emptyConfigResponse,
    }),
    [q],
  )
}
