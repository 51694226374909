import { useResetPasswordMutation } from '@jotta/grpc-connect-client/login'
import { useForm } from '@jotta/hooks/useForm'
import { Card } from '@jotta/ui/Card'
import { Form } from '@jotta/ui/Form'
import { Trans, t } from '@lingui/macro'
import { CTAButton } from './CTAButton'
import { PasswordField } from './PasswordField'
import { usePasswordField } from './signupForm'

export function SetPassword({
  className,
  resetKey,
  onSuccess,
}: {
  className?: string
  resetKey: string
  onSuccess?: () => void
}) {
  const { mutate: resetPassword, isSuccess } = useResetPasswordMutation()

  const form = useForm({
    fields: {
      password: { ...usePasswordField(), focusOnMount: true },
    },
    submit: ({ password }) => {
      resetPassword(
        { resetKey, password },
        {
          onSuccess: () => {
            if (onSuccess) {
              onSuccess()
            }
          },
        },
      )
    },
  })

  const { password, onSubmit, isSubmitting } = form

  if (isSuccess) {
    return (
      <>
        <Card variant="cards.message">
          <p>
            <Trans>
              Your password has been changed. Please login with your new
              password.
            </Trans>
          </p>
        </Card>
        <a
          className="mt-4 block !text-signup-link underline"
          href="/api/logout"
        >{t`Log in`}</a>
      </>
    )
  }

  return (
    <Form form={form} className={className} onSubmit={onSubmit}>
      <PasswordField field={password} />
      <CTAButton loading={isSubmitting} type="submit" className="w-full">
        <Trans>Set password</Trans>
      </CTAButton>
    </Form>
  )
}
