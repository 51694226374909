import type { OnboardingPopupKeys } from '@jotta/grpc-js-client/customerService'
import type { BrandThemeIcon } from '@jotta/types/Brand'
import type { FileRouteContext } from '@jotta/types/schemas/FileRouteSchema'
import { t } from '@lingui/macro'

const moduleNav: readonly FileRouteContext[] = [
  'sync',
  'backup',
  'archive',
  'shared',
] as const
export const fileRouteContextConfig: Record<
  FileRouteContext,
  {
    apiPath: string
    to: string
    icon: BrandThemeIcon
    text: string
    onboarding: OnboardingPopupKeys
    rootContext?: FileRouteContext
    moduleNav: readonly FileRouteContext[]
  }
> = {
  sync: {
    to: '/web/sync/list/name',
    apiPath: '/sync',
    moduleNav,
    get text() {
      return t`Synced`
    },
    icon: 'SvgNavSynced',
    onboarding: 'sync',
  },
  backup: {
    to: '/web/backup/list/name',
    apiPath: '/backup',
    moduleNav,
    get text() {
      return t`Backed up`
    },
    icon: 'SvgNavBackedUp',
    onboarding: 'backup',
  },
  archive: {
    to: '/web/archive/list/name',
    apiPath: '/archive',
    moduleNav,
    get text() {
      return t`Archive`
    },
    icon: 'SvgNavArchived',
    onboarding: 'archive',
  },
  shared: {
    to: '/web/shared/list/name',
    apiPath: '/shared',
    moduleNav,
    get text() {
      return t`Shared`
    },
    icon: 'SvgNavShared',
    onboarding: 'shared',
  },
  search: {
    to: '/web/search/list/name',
    apiPath: '/search',
    moduleNav: ['search'] as const,
    get text() {
      return t`Search:`
    },
    icon: 'SvgCaretLeft',
    onboarding: 'sync',
  },
  photos: {
    to: '/web/photos/list/name',
    apiPath: '/photos',
    moduleNav: ['photos'] as const,
    get text() {
      return 'Photo Timeline Uploads'
    },
    icon: 'SvgNavBackedUp',
    onboarding: 'backup',
    rootContext: 'backup',
  },
  contacts: {
    to: '/web/contacts/list/name',
    apiPath: '/contacts',
    moduleNav: ['contacts'] as const,
    get text() {
      return t`Contacts`
    },
    icon: 'SvgNavBackedUp',
    onboarding: 'backup',
    rootContext: 'backup',
  },
  trash: {
    to: '/web/trash',
    apiPath: '/trash',
    moduleNav: ['trash'] as const,
    get text() {
      return t`Trash`
    },
    icon: 'SvgTrash',
    onboarding: 'trash',
  },
}
