import { Box } from '@jotta/ui/Box'
import { BrandIcon } from '@jotta/ui/BrandIcon'
import { useBreakpointIndex } from '@jotta/ui/useBreakpointIndex'
import type { ThemeUIStyleObject } from '@jotta/ui/themeui'
import { LinkThemeUI } from '@jotta/ui/themeui'
import { Trans } from '@lingui/macro'
import { observer } from 'mobx-react-lite'
import { useBrandingInfo } from '@jotta/grpc-connect-client/config'

const footerWrapper: ThemeUIStyleObject = {
  alignItems: 'flex-start',
  display: 'flex',
  flexWrap: ['wrap', 'wrap', 'nowrap'],
  justifyContent: ['space-between', 'space-between', 'space-evenly'],
}

const linkContainer: ThemeUIStyleObject = {
  width: ['50%', 'auto', 'auto'],
  mb: ['16px', 0, 0],
  gap: 3,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}
const footerHeading: ThemeUIStyleObject = {
  color: 'black',
  fontWeight: 'bold',
  fontSize: 3,
  marginBlockStart: 0,
  marginBlockEnd: 0,
}

const footerLink: ThemeUIStyleObject = {
  '&[href]': {
    display: 'block',
    color: 'gray',
    fontSize: 3,
    fontWeight: 'body',
    letterSpacing: '0.5px',
    overflowWrap: 'break-word',
    wordBreak: 'break-all',
    whiteSpace: 'normal',
    transition: 'opacity 200ms',
    '&:hover': {
      color: 'black',
    },
  },
}

const logoContainer: ThemeUIStyleObject = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}

const logoWrapper: ThemeUIStyleObject = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
}

const logoLink: ThemeUIStyleObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
}

const copyRightStyle: ThemeUIStyleObject = {
  ml: 1,
  mb: 2,
  color: 'gray',
}

const orgStyle: ThemeUIStyleObject = {
  fontWeight: 'body',
  fontSize: 2,
  letterSpacing: '0.5px',
  color: 'gray',
}

const bottomLogoContainer: ThemeUIStyleObject = {
  display: ['flex', 'none', 'none'],
}

export const JottaFooter = observer(function JottaFooter() {
  const {
    aboutUsLink,
    downloadPageLink,
    blogLink,
    termsLink,
    privacyLink,
    supportLink,
    homepageLink,
    pricingLink,
    cookiePolicyLink,
    partnerInfoLink,
  } = useBrandingInfo()

  const mobileSize: boolean = useBreakpointIndex() < 1
  return (
    <Box sx={{ variant: 'layout.jottaAppFooterContainer' }} as="footer">
      <Box sx={footerWrapper}>
        <Box
          sx={{
            display: ['none', 'block'],
          }}
        >
          <Box sx={logoContainer}>
            <Box sx={logoWrapper}>
              <LinkThemeUI href={homepageLink} sx={logoLink}>
                <BrandIcon
                  icon="SvgLogoMain"
                  sx={{ width: '116px', height: '25px' }}
                />
                <span sx={copyRightStyle}>&copy;</span>
              </LinkThemeUI>
              <span sx={orgStyle}>
                <Trans>Organization number</Trans>
              </span>
              <span sx={orgStyle}>992 603 615</span>
            </Box>
          </Box>
        </Box>

        <Box sx={linkContainer}>
          <h4 sx={footerHeading}>
            <Trans>Product</Trans>
          </h4>
          <LinkThemeUI href={supportLink} sx={footerLink}>
            <Trans>Help</Trans>
          </LinkThemeUI>
          <LinkThemeUI href={pricingLink} sx={footerLink}>
            <Trans>Pricing</Trans>
          </LinkThemeUI>
          <LinkThemeUI href={downloadPageLink} sx={footerLink}>
            <Trans>Download</Trans>
          </LinkThemeUI>
        </Box>

        <Box sx={linkContainer}>
          <h4 sx={footerHeading}>
            <Trans>Company</Trans>
          </h4>
          <LinkThemeUI href={aboutUsLink} sx={footerLink}>
            <Trans>About us</Trans>
          </LinkThemeUI>
          <LinkThemeUI href={blogLink} sx={footerLink}>
            <Trans>Blog</Trans>
          </LinkThemeUI>
          <LinkThemeUI href={partnerInfoLink} sx={footerLink}>
            <Trans>Partner Info</Trans>
          </LinkThemeUI>
        </Box>

        <Box sx={linkContainer}>
          <h4 sx={footerHeading}>
            <Trans>Legal</Trans>
          </h4>
          <LinkThemeUI href={cookiePolicyLink} sx={footerLink}>
            <Trans>Cookie Policy</Trans>
          </LinkThemeUI>
          {window.CookieConsent && window.CookieConsent.renew && (
            <LinkThemeUI
              href="#"
              onClick={window.CookieConsent.renew}
              sx={footerLink}
            >
              <Trans>Cookie Settings</Trans>
            </LinkThemeUI>
          )}
          <LinkThemeUI href={termsLink} sx={footerLink}>
            <Trans>Terms &amp; Conditions</Trans>
          </LinkThemeUI>
          <a href={privacyLink} sx={footerLink}>
            <Trans>Privacy Policy</Trans>
          </a>
        </Box>
      </Box>

      <Box
        sx={{
          ...(mobileSize && {
            justifyContent: 'space-between',
            mt: 3,
            alignItems: 'center',
          }),
          ...bottomLogoContainer,
        }}
      >
        <Box sx={logoWrapper}>
          <LinkThemeUI href={homepageLink} sx={logoLink}>
            <BrandIcon
              icon="SvgLogoMain"
              sx={{ width: '116px', height: '25px' }}
            />
            <span sx={copyRightStyle}>&copy;</span>
          </LinkThemeUI>
          <span sx={orgStyle}>
            <Trans>Organization number</Trans>
          </span>
          <span sx={orgStyle}>992 603 615</span>
        </Box>
      </Box>
    </Box>
  )
})
