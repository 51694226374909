import { createObservableInfiniteQuery, ObservableQuery } from '@jotta/query'
import type { AppError } from '@jotta/types/AppError'
import type { PhotosApi } from '@jotta/types/PhotosApi'
import { AlbumSchema } from '@jotta/types/schemas/AlbumSchema'
import type { InfiniteData } from '@tanstack/react-query'
import { keepPreviousData } from '@tanstack/react-query'
import type { Debugger } from 'debug'
import Debug from 'debug'
import { autorun, makeAutoObservable, runInAction } from 'mobx'
import path from 'path'
import { getApi } from '../api/photosApi'
import { getAlbumInfoQueryObserverOptions } from '../components/Album/AlbumInfo/AlbumInfo.loaders'
import type { PhotoStore } from './PhotoStore'
const debug = Debug('jotta:photos:AlbumInfoQueryStore')

export interface FetchAlbumPhotosOptions {
  albumId: string
  limit?: number
  comments?: boolean
  isPublic?: boolean
  fetchAllPages?: boolean
  from?: string
  to?: string
}

const defaultFetchAlbumPhotosOptions: Required<FetchAlbumPhotosOptions> = {
  albumId: '',
  limit: 500,
  comments: true,
  isPublic: false,
  fetchAllPages: false,
  from: '',
  to: '',
}

export type AlbumPhotosQueryKey = readonly [
  key: 'album',
  view: 'photos',
  options: Required<FetchAlbumPhotosOptions>,
]

export function getInfiniteAlbumPhotosQueryKey(
  args: FetchAlbumPhotosOptions,
): AlbumPhotosQueryKey {
  const options: Required<FetchAlbumPhotosOptions> = {
    ...defaultFetchAlbumPhotosOptions,
    ...args,
  }
  return ['album', 'photos', options]
}

export class AlbumInfoQueryStore {
  private initialLimit = 10
  private pageLimit = 500
  private albumInfoQuery
  private albumQuery: ReturnType<
    typeof createObservableInfiniteQuery<
      PhotosApi.AlbumInfo,
      AppError,
      PhotosApi.AlbumInfo,
      AlbumPhotosQueryKey,
      string
    >
  >
  private log: Debugger
  public id: string
  constructor(
    id: string,
    private root: PhotoStore,
    albumData?: PhotosApi.AlbumInfo,
  ) {
    if (!id) {
      throw new Error('Missing album ID')
    }
    this.id = id
    this.log = debug.extend(id.slice(0, 8))
    this.log.enabled = false
    this.log('init', id)
    if (albumData) {
      runInAction(() => {
        this.root.albumRoot.setAlbumInfo(this.id, albumData, true)
      })
    }
    makeAutoObservable<typeof this, 'log'>(
      this,
      {
        log: false,
      },
      {
        autoBind: true,
      },
    )
    this.albumInfoQuery = new ObservableQuery({
      name: 'AlbumInfo',
      queryOptions: getAlbumInfoQueryObserverOptions({
        albumId: id,
        isPublic: this.root.isPublicRoute,
      }),
    })

    this.albumQuery = createObservableInfiniteQuery<
      PhotosApi.AlbumInfo,
      AppError,
      PhotosApi.AlbumInfo,
      AlbumPhotosQueryKey,
      string
    >({
      name: 'AlbumPhotos',
      infiniteQueryOptions: {
        placeholderData: keepPreviousData,
        staleTime: 1000 * 60 * 2, // 2 minutes
        queryKey: getInfiniteAlbumPhotosQueryKey({
          albumId: id,
          isPublic: this.root.isPublicRoute,
        }),
        initialPageParam: '',
        getNextPageParam: lastPage => lastPage.nextPageParam,
        queryFn: this.fetchAlbums,
        // onSuccess: this.onFetchSuccess,
      },
      // onQueryUpdate: this.onQueryUpdate,
    })

    autorun(() => {
      const {
        data,
        isFetched,
        isFetchingNextPage,
        hasNextPage,
        isSuccess,
        fetchNextPage,
      } = this.albumQuery

      if (data && isFetched) {
        this.onFetchSuccess(data)
      }

      if (isSuccess && !isFetchingNextPage && hasNextPage) {
        this.log('Fetching page %d', data.pages.length + 1)
        fetchNextPage()
      }
    })
  }

  async fetchAlbums({ pageParam = '' }: { pageParam?: string }) {
    const apiPath = path.join('albums', this.id, pageParam)
    this.log('apiPath', apiPath)
    const limit = !pageParam ? this.initialLimit : this.pageLimit
    const result = await getApi()
      .get(apiPath, {
        searchParams: {
          limit,
          comments: true,
        },
      })
      .json<unknown>()
    const { photos, ...album } = AlbumSchema.parse(result)
    if (photos.length) {
      const nextPageParam = photos[photos.length - 1].timestamp
      if (nextPageParam !== pageParam) {
        album.nextPageParam = nextPageParam
      }
    }
    this.log('Adding %d photos', photos.length)
    runInAction(() => {
      this.root.mediaObjects.setPhotos(photos)
    })
    return album
  }

  onFetchSuccess(data: InfiniteData<PhotosApi.AlbumInfo>) {
    if (data.pages.length) {
      const album: PhotosApi.AlbumInfo = structuredClone(
        data.pages[data.pages.length - 1],
      )
      album.photoIds = data.pages.flatMap(page => page.photoIds)
      this.log('onSuccess', album)
      this.root.albumRoot.setAlbum(album.id, album)
    }
  }
  get isLoading() {
    return this.albumInfoQuery.isLoading || this.albumQuery.isLoading
  }
  get album() {
    return this.root.albumRoot.albums.get(this.id)
  }
}
