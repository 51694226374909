import Debug from 'debug'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { useLoaderData } from 'react-router-dom'
import { getConfig } from '@jotta/grpc-connect-client/config'
const debug = Debug('jotta:routes:apps:loader')

export async function appsRouteLoader(args: LoaderFunctionArgs) {
  const config = await getConfig()
  debug(JSON.stringify(config, null, 2))
  const branding = config.brandingInfo
  debug(branding)
  if (branding) {
    const { downloadIosLink, downloadAndroidLink } = branding
    const isIOS = /(iPad|iPhone)/.test(navigator.userAgent)
    const isAndroid = /(Android)/.test(navigator.userAgent)

    if (isIOS && downloadIosLink) {
      debug('Redirect to iOS app store', downloadIosLink)
      // window.open(downloadIosLink, '_blank', 'rel="noreferrer"')
      window.location.replace(downloadIosLink)
      return downloadIosLink
    } else if (isAndroid && downloadAndroidLink) {
      debug('Redirect to Android app store', downloadAndroidLink)
      // window.open(downloadAndroidLink, '_blank', 'rel="noreferrer"')
      window.location.replace(downloadAndroidLink)
      return downloadAndroidLink
    }
  }
  debug('Redirect to download page')
  window.location.replace('/download')
  return ''
}
export function usePhotoAppsRouteLoaderData() {
  return useLoaderData() as Awaited<ReturnType<typeof appsRouteLoader>>
}
