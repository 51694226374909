import { capitalize } from '@jotta/utils/text'
import type { ActionFunctionArgs, LoaderFunctionArgs } from 'react-router-dom'
import {
  useActionData,
  useRouteLoaderData,
  type RouteObject,
} from 'react-router-dom'
import type { Simplify } from 'type-fest'
export function createLoaderHookName<const ID extends string>(id: ID) {
  return `use${capitalize(id)}LoaderData` as const
}
export function createActionHookName<const ID extends string>(id: ID) {
  return `use${capitalize(id)}ActionData` as const
}
type LoaderHookName<T> = T extends string
  ? ReturnType<typeof createLoaderHookName<T>>
  : never
type ActionHookName<T> = T extends string
  ? ReturnType<typeof createActionHookName<T>>
  : never
type LoaderRouteParam = Simplify<
  {
    id: string
    loader?: (args: LoaderFunctionArgs) => any
    action?: (args: ActionFunctionArgs) => any
  } & Omit<RouteObject, 'loader' | 'id' | 'action'>
>
type LoaderRouteObject<T extends LoaderRouteParam> = T extends {
  id: infer ID
  loader?: (...args: infer LoaderFnArgs) => infer LoaderReturnType
  action?: (...args: infer ActionFnArgs) => infer ActionReturnType
}
  ? Simplify<{
      id: ID
      loaderReturnType: Awaited<LoaderReturnType>
      actionReturnType: Awaited<ActionReturnType>
      result: Simplify<
        {
          id: ID
        } & {
          [K in LoaderHookName<ID>]: () => Awaited<LoaderReturnType>
        } & {
          [K in ActionHookName<ID>]: () => Awaited<ActionReturnType> | undefined
        } & {
          route: T
        }
      >
    }>
  : never

export function createLoaderRoute<const T extends LoaderRouteParam>(
  route: T,
): LoaderRouteObject<typeof route>['result'] {
  type Data = LoaderRouteObject<typeof route>
  const loaderHookName = createLoaderHookName(route.id)
  const actionHookName = createActionHookName(route.id)
  const result = {
    id: route.id,
    route,
    [loaderHookName]: () => {
      return useRouteLoaderData(route.id) as Data['loaderReturnType']
    },
    [actionHookName]: () => {
      return useActionData() as Data['actionReturnType']
    },
  } as Data['result']
  return result
}
