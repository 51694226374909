import type { Locale } from '@jotta/i18n'
import { dynamicActivate, languageToolbar } from '@jotta/i18n'
import * as Select from '@radix-ui/react-select'
import { observer } from 'mobx-react-lite'

import { useLingui } from '@lingui/react'
import { BrandIcon } from '../../Icons/BrandIcon/BrandIcon'
import { cn } from '@jotta/utils/css'

export const LanguageSelector = observer(function LanguageSelector({
  className,
}: {
  className?: string
}) {
  const { i18n } = useLingui()
  const locale = i18n.locale as Locale

  const selectedToolbarItem =
    languageToolbar.find(item => item.value === locale) || languageToolbar[0]
  return (
    <Select.Root
      defaultValue={locale}
      onValueChange={(value: Locale) => dynamicActivate(value)}
    >
      <Select.Trigger
        aria-label={selectedToolbarItem.title}
        className={cn('flex items-center gap-2', className)}
      >
        <span className="uppercase">{selectedToolbarItem.value}</span>
        <BrandIcon className="w-4" icon="SvgCaretDown" />
      </Select.Trigger>
      <Select.Portal>
        <Select.Content
          position="popper"
          side="top"
          sideOffset={10}
          align="end"
          className="z-languageSelector"
        >
          <Select.Viewport className="flex flex-col border bg-white p-2">
            {languageToolbar.map((item, index) => (
              <Select.Item
                key={item.value}
                value={item.value}
                className={cn(
                  'btn btn-ghost inline rounded-none pr-16 data-[state=checked]:font-bold',
                  {
                    'border-t-light': index > 0,
                  },
                )}
              >
                {item.title}
              </Select.Item>
            ))}
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  )
})
