import { AppLayoutPortal } from '@jotta/ui/AppLayoutPortal'
import * as Dialog from '@radix-ui/react-dialog'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  useMediaObjectStore,
  useSelectionStore,
} from '../../store/PhotoContext'
import type { PhotoActionHandlersProps } from '../PhotoActions/PhotoActionHandlers'
import { PhotoActionHandlers } from '../PhotoActions/PhotoActionHandlers'
import { BaseSelectionManager } from './BaseSelectionManager'

export const SelectionManager = observer<
  PhotoActionHandlersProps & { restoreOnUnmount?: boolean }
>(function SelectionManager({
  actionContext,
  restoreOnUnmount = false,
  ...props
}) {
  const { addPhotoMode = '', albumId = '' } = props
  const store = useSelectionStore(
    actionContext + addPhotoMode + albumId,
    !restoreOnUnmount,
  )
  const navigate = useNavigate()
  const show = store.hasSelection || Boolean(addPhotoMode)
  const hidden = actionContext === 'TIMELINE_HIDDEN_PHOTOS_SELECTED_PHOTOS'
  const onClose = useCallback(
    (open: boolean) => {
      if (!open) {
        store.selection.clear()
        if (addPhotoMode) {
          navigate(`/photo/album/${addPhotoMode}`)
        }
      }
    },
    [addPhotoMode, store],
  )

  const mediaStore = useMediaObjectStore()

  const isOwnerOfAllPhotos = useMemo(
    () =>
      !store.values.length ||
      mediaStore
        .getPhotosByIds(store.values)
        .every(photo => photo.username === props.username),
    [store.values, mediaStore, props.username],
  )

  if (show) {
    return (
      <AppLayoutPortal slot="bottomStatusBar">
        <Dialog.Root open={show} modal={false}>
          <Dialog.Content
            asChild
            onEscapeKeyDown={e => {
              onClose(false)
              e.preventDefault()
            }}
          >
            <BaseSelectionManager
              hidden={hidden}
              selectionCount={store.selection.size}
              onClose={onClose}
              data-testid="SelectionManager"
              data-actioncontext={actionContext}
            >
              <PhotoActionHandlers
                actionContext={actionContext}
                photoIds={store.values}
                isOwnerOfAllPhotos={isOwnerOfAllPhotos}
                buttonVariant="buttons.responsiveActionButton"
                onActionDialogClose={() => {
                  onClose(false)
                }}
                onDispatchComplete={(actionType, ...args) => {
                  if (
                    actionType !== 'SHARE_PHOTO' &&
                    actionType !== 'UNSHARE_ALBUM' &&
                    actionType !== 'DOWNLOAD_PHOTO' &&
                    actionType !== 'DOWNLOAD_PHOTOS'
                  ) {
                    onClose(false)
                  }
                }}
                {...props}
              />
            </BaseSelectionManager>
          </Dialog.Content>
        </Dialog.Root>
      </AppLayoutPortal>
    )
  }

  return null
})
