import { useCustomerConfig } from '@jotta/settings/state/hooks/useSettingsStore'
import { AccountType, getFamily } from '@jotta/grpc-js-client/customerService'
import { InviteState } from '@jotta/grpc-web/no/jotta/openapi/customer/customer.v2_pb'
import { useQuery } from '@tanstack/react-query'
import Debug from 'debug'
import { useLayoutEffect } from 'react'
import {} from '../stores/SettingsAPI'
import { useSettingsStore } from './useSettingsStore'

const debug = Debug('jotta:settings:useFamily')

export function useFamilyQuery() {
  const settingsStore = useSettingsStore()
  const { family: familyStore, api } = settingsStore
  const query = useCustomerConfig()
  const familyQuery = useQuery({
    ...api.fetchFamily,
    queryKey: ['family', query.data?.customer.username || 'anonymous'],
    enabled:
      query.isSuccess &&
      query.data.customer.accountType === AccountType.FAMILY_OWNER,
  })

  const { data, isSuccess } = familyQuery

  useLayoutEffect(() => {
    if (data && isSuccess) {
      familyStore.familyResponse = data
    }
  }, [data, isSuccess])
  return familyQuery
}
export function useFamilyMemberCount() {
  const customerQuery = useCustomerConfig()
  const isFamilyOwner =
    customerQuery.data?.customer.accountType === AccountType.FAMILY_OWNER
  const { data = 0 } = useQuery({
    queryKey: ['familymembercount'] as const,
    queryFn: async () => {
      const family = await getFamily()
      const invites = family
        .getInvitesList()
        .filter(invite => invite.getState() === InviteState.INVITE_ACCEPTED)
      return invites.length
    },
    enabled: isFamilyOwner,
  })
  return data
}
export function useFamilyStore() {
  const settingsStore = useSettingsStore()
  return settingsStore.family
}
