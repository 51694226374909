import clsx from 'clsx'
import type { SVGProps } from 'react'

export function CheckmarkIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      className={clsx(
        'group cursor-pointer rounded-full fill-white stroke-gray-400 text-gray-400 ring-current ring-offset-2 transition-all duration-200 data-selected:fill-[#18AC3A] data-selected:stroke-[#149231] data-selected:text-[#18AC3A] focus:ring-2 disabled:cursor-auto',
        props.className,
      )}
      viewBox="0 0 24 24"
    >
      <circle strokeWidth="1.5" cx="12" cy="12" r="11.25" />
      <path
        d="M7.5 12.5l3 3l6 -6"
        className="stroke-gray-500 group-data-selected:stroke-white"
        strokeWidth="2.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
