import Debug from 'debug'
import { deepEqual } from 'fast-equals'
import type { AnnotationsMap, CreateObservableOptions } from 'mobx'
import { action, makeAutoObservable, observable } from 'mobx'
import moize from 'moize'
import { useEffect, useRef } from 'react'
const debug = Debug('jotta:hooks:useObservableProps')

type ObservablePropsArgs<T extends Record<string, any>> = [
  props: T,
  annotations?: AnnotationsMap<T, never>,
  options?: CreateObservableOptions,
]
export class ObservableProps<T extends Record<string, any>> {
  private _log
  props
  public setProps = moize.deep(
    (props: T) => {
      for (const key in props) {
        const previous = this.props[key]
        const value = props[key]
        if (!deepEqual(value, previous)) {
          this._log('setProps %s', key, value, props)
          this.props[key] = value
        }
      }
    },
    {
      maxSize: 1,
    },
  )
  constructor(...[props, annotations, options = {}]: ObservablePropsArgs<T>) {
    const { name = '' } = options
    this._log = debug.extend(name ? `store:${name}` : 'store')
    this._log.enabled = false
    this.props = observable(
      {
        ...props,
      },
      annotations,
      {
        ...options,
      },
    )

    makeAutoObservable<typeof this, '_log'>(
      this,
      {
        _log: false,
        setProps: action.bound,
      },
      {
        autoBind: true,
      },
    )
  }
}
export function useObservableProps<T extends Record<string, any>>(
  ...[inputProps, annotations, options]: ObservablePropsArgs<T>
) {
  const ref = useRef(new ObservableProps(inputProps, annotations, options))

  useEffect(() => {
    ref.current.setProps(inputProps)
  }, [inputProps])
  return ref.current.props
}
