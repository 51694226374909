import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { observer } from 'mobx-react-lite'
import type {
  ComponentPropsWithRef,
  ElementType,
  FC,
  MouseEventHandler,
} from 'react'
import { createElement } from 'react'

import styles from './Fab.module.scss'
export interface FabProps {
  open?: boolean
  disabled?: boolean
  onClick: MouseEventHandler
}
createElement
export function createFabElement<T extends ElementType>(
  element: T,
  name: string,
) {
  const FabComponent: FC<ComponentPropsWithRef<T>> = props => {
    return createElement(
      element,
      {
        className: styles.fab,
        ...props,
      },
      <PlainBrandIcon icon="SvgAdd" />,
    )
  }
  FabComponent.displayName = name
  return FabComponent
}
const FabButton = createFabElement('button', 'FabButton')
export const Fab = observer<FabProps>(function Fab({
  open = false,
  disabled,
  onClick,
  ...props
}) {
  return (
    <FabButton
      data-open={open}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      <PlainBrandIcon icon="SvgAdd" />
    </FabButton>
  )
})
