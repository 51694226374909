import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { excludesFalse } from '@jotta/types/TypeUtils'
import Debug from 'debug'
import type { ReactNode } from 'react'
import { useMatches } from 'react-router-dom'
import { z } from 'zod'
import styles from './PeopleBreadCrumb.module.scss'
const debug = Debug('jotta:people:PeopleBreadCrumb')
debug.enabled = false
export class PeopleBreadCrumbHandle {
  crumbItemRenderFunctions: CrumbItemRenderFunction[]
  constructor(...items: CrumbItemRenderFunction[]) {
    this.crumbItemRenderFunctions = items
  }
}
const PropsSchema = z.object({
  id: z.string().optional(),
  title: z.string().optional(),
})
const MatchSchema = z.object({
  handle: z.object({
    crumb: z.instanceof(PeopleBreadCrumbHandle),
  }),
  data: PropsSchema.optional(),
})
type CrumbItemProps = z.infer<typeof PropsSchema>
type CrumbItemRenderFunction = (
  props: CrumbItemProps,
  index: number,
) => ReactNode

export function PeopleBreadCrumb() {
  const matches = useMatches()
  const crumbs = matches
    .filter(m => m.handle)
    .flatMap(match => {
      const parsed = MatchSchema.safeParse(match)
      if (parsed.success) {
        const data = parsed.data
        debug('Data', data)
        return data.handle.crumb.crumbItemRenderFunctions.map(crumb => ({
          crumb,
          data: data.data,
        }))
      } else {
        const problems = parsed.error.issues
        debug('Problems: %s', problems.map(p => p.message).join(', '), match)
      }
      return undefined
    })
    .filter(excludesFalse)
  return (
    <ol className={styles.container}>
      {crumbs.map(({ crumb, data }, index) => (
        <li key={index}>
          {!!index && <PlainBrandIcon icon="SvgCaretRight" />}
          {crumb((data = {}), index)}
        </li>
      ))}
    </ol>
  )
}
