import type { BrandThemeIcon } from '@jotta/types/Brand'
import { t } from '@lingui/macro'
import { photoActionsByContext } from './PhotoActionContext.config'
import type { PhotoActionContextType } from './PhotoActionContext.types'
import { getPhotoActionContexts } from './PhotoActionContext.utils'
import type { PhotoActionHandlerSharedProps } from './PhotoActionHandlers'
import { photoActionsApiFunctions } from './PhotoActions.api'
import type { PhotoAction, PhotoActionType } from './PhotoActions.types'

export const photoActionsApi = {
  ADD_COMMENT: {
    actionType: 'ADD_COMMENT',
    actionFn: photoActionsApiFunctions['ADD_COMMENT'],
    hasAction: ({ hasComments }) => Boolean(hasComments),
    isDisabled: null,
    actionContexts: getPhotoActionContexts('ADD_COMMENT'),
    testid: 'ACTION_ADD_COMMENT',
    description: 'Add a comment to an album or photo',
    icon: 'SvgBubbleOutline',
    get label() {
      return t`Comments.input.placeholder`
    },
  },
  ADD_PHOTOS_ANONYMOUS: {
    actionType: 'ADD_PHOTOS_ANONYMOUS',
    actionFn: photoActionsApiFunctions['ADD_PHOTOS_ANONYMOUS'],
    hasAction: ({ username, albumId, readOnly }) =>
      Boolean(!username && albumId && !readOnly),
    isDisabled: null,
    actionContexts: getPhotoActionContexts('ADD_PHOTOS_ANONYMOUS'),
    testid: 'ACTION_ADD_PHOTOS_ANONYMOUS',
    description: 'Add selected photos to album after login',
    icon: 'SvgAdd',
    get label() {
      return t`AddPhotosButton.label`
    },
  },
  ADD_PHOTOS_TO_ALBUM: {
    actionType: 'ADD_PHOTOS_TO_ALBUM',
    actionFn: photoActionsApiFunctions['ADD_PHOTOS_TO_ALBUM'],
    hasAction: ({ username, albumId, addPhotoMode, readOnly }) =>
      // Boolean(username && (albumId || addPhotoMode) && !readOnly),
      Boolean(username),
    isDisabled: ({ isOwnerOfAllPhotos, photoIds }) =>
      !(isOwnerOfAllPhotos && photoIds && photoIds.length),
    actionContexts: getPhotoActionContexts('ADD_PHOTOS_TO_ALBUM'),
    testid: 'ACTION_ADD_PHOTOS_TO_ALBUM',
    description: 'Add selected photos to album',
    icon: 'SvgAlbumAdd',
    get label() {
      return t`AddToAlbumButton.label`
    },
  },
  ADD_PHOTOS: {
    actionType: 'ADD_PHOTOS',
    actionFn: photoActionsApiFunctions['ADD_PHOTOS'],
    hasAction: ({ album, isAlbumSubscriber, readOnly, username }) =>
      Boolean(album?.isOwner || (username && !readOnly)),
    isDisabled: null,
    actionContexts: getPhotoActionContexts('ADD_PHOTOS'),
    testid: 'ACTION_ADD_PHOTOS',
    description: 'Start selecting photos to add to album',
    icon: 'SvgAdd',
    get label() {
      return t`AddPhotosButton.label`
    },
  },
  COPY_SHARED_ALBUM_LINK: {
    actionType: 'COPY_SHARED_ALBUM_LINK',
    actionFn: photoActionsApiFunctions['COPY_SHARED_ALBUM_LINK'],
    hasAction: ({ album }) => Boolean(!album || (album && !album.isOwner)),
    isDisabled: ({ shareId }) => !shareId,
    actionContexts: getPhotoActionContexts('COPY_SHARED_ALBUM_LINK'),
    testid: 'ACTION_COPY_SHARED_ALBUM_LINK',
    description: 'Copy shared album link',
    icon: 'SvgShare',
    get label() {
      return t`ShareButton.label`
    },
  },
  CREATE_ALBUM: {
    actionType: 'CREATE_ALBUM',
    actionFn: photoActionsApiFunctions['CREATE_ALBUM'],
    hasAction: ({ username }) => Boolean(username),
    isDisabled: null,
    actionContexts: getPhotoActionContexts('CREATE_ALBUM'),
    testid: 'ACTION_CREATE_ALBUM',
    description: 'Create new album',
    icon: 'SvgAlbumAdd',
    get label() {
      return t`Create new album`
    },
  },
  DELETE_ALBUM: {
    actionType: 'DELETE_ALBUM',
    actionFn: photoActionsApiFunctions['DELETE_ALBUM'],
    hasAction: ({ album }) => Boolean(album?.isOwner),
    isDisabled: ({ albumId }) => !albumId,
    actionContexts: getPhotoActionContexts('DELETE_ALBUM'),
    testid: 'ACTION_DELETE_ALBUM',
    description: 'Delete album',
    icon: 'SvgTrash',
    get label() {
      return t`DeleteAlbumButton.label`
    },
  },
  DELETE_PHOTOS: {
    actionType: 'DELETE_PHOTOS',
    actionFn: photoActionsApiFunctions['DELETE_PHOTOS'],
    hasAction: ({ username }) => Boolean(username),
    isDisabled: ({ isOwnerOfAllPhotos, photoIds }) =>
      !(isOwnerOfAllPhotos && photoIds && photoIds.length),
    actionContexts: getPhotoActionContexts('DELETE_PHOTOS'),
    testid: 'ACTION_DELETE_PHOTOS',
    description: 'Delete photos from timeline',
    icon: 'SvgTrash',
    get label() {
      return t`DeleteButton.label`
    },
  },
  DELETE_SHARED_PHOTO_LINK: {
    actionType: 'DELETE_SHARED_PHOTO_LINK',
    actionFn: photoActionsApiFunctions['DELETE_SHARED_PHOTO_LINK'],
    hasAction: ({ username }) => Boolean(username),
    isDisabled: ({ albumId }) => !albumId,
    actionContexts: getPhotoActionContexts('DELETE_SHARED_PHOTO_LINK'),
    testid: 'ACTION_DELETE_SHARED_PHOTO_LINK',
    description: 'Remove shared photos link',
    icon: 'SvgTrash',
    get label() {
      return t`Remove link`
    },
  },
  DOWNLOAD_PHOTO: {
    actionType: 'DOWNLOAD_PHOTO',
    actionFn: photoActionsApiFunctions['DOWNLOAD_PHOTO'],
    hasAction: ({ downloadLink }) => Boolean(downloadLink),
    isDisabled: null,
    actionContexts: getPhotoActionContexts('DOWNLOAD_PHOTO'),
    testid: 'ACTION_DOWNLOAD_PHOTO',
    description: 'Download single photo',
    icon: 'SvgDownload',
    get label() {
      return t`DownloadButton.label`
    },
  },
  DOWNLOAD_PHOTOS: {
    actionType: 'DOWNLOAD_PHOTOS',
    actionFn: photoActionsApiFunctions['DOWNLOAD_PHOTOS'],
    hasAction: ({ getDownloadInfo }) => Boolean(getDownloadInfo),
    isDisabled: null,
    actionContexts: getPhotoActionContexts('DOWNLOAD_PHOTOS'),
    testid: 'ACTION_DOWNLOAD_PHOTOS',
    description: 'Download single photo or group of selected photos',
    icon: 'SvgDownload',
    get label() {
      return t`DownloadButton.label`
    },
  },
  FIND_SIMILAR: {
    actionType: 'FIND_SIMILAR',
    actionFn: photoActionsApiFunctions['FIND_SIMILAR'],
    hasAction: ({ isOwnerOfAllPhotos, showSimilarSearch }) =>
      Boolean(isOwnerOfAllPhotos && showSimilarSearch),
    isDisabled: null,
    actionContexts: getPhotoActionContexts('FIND_SIMILAR'),
    testid: 'ACTION_FIND_SIMILAR',
    description: 'Find similar photos',
    icon: 'SvgPhotoSearch',
    get label() {
      return t`Find similar`
    },
  },
  FOLLOW_ALBUM: {
    actionType: 'FOLLOW_ALBUM',
    actionFn: photoActionsApiFunctions['FOLLOW_ALBUM'],
    hasAction: ({ username, album, isAlbumSubscriber }) =>
      Boolean(username && album && !album.isOwner && !isAlbumSubscriber),
    isDisabled: null,
    actionContexts: getPhotoActionContexts('FOLLOW_ALBUM'),
    testid: 'ACTION_FOLLOW_ALBUM',
    description: 'Follow album',
    icon: 'SvgAlbumAdd',
    get label() {
      return t`JoinAlbumButton.label`
    },
  },
  FOLLOW_ALBUM_ANONYMOUS: {
    actionType: 'FOLLOW_ALBUM_ANONYMOUS',
    actionFn: photoActionsApiFunctions['FOLLOW_ALBUM_ANONYMOUS'],
    hasAction: ({ username }) => Boolean(!username),
    isDisabled: null,
    actionContexts: getPhotoActionContexts('FOLLOW_ALBUM_ANONYMOUS'),
    testid: 'ACTION_FOLLOW_ALBUM_ANONYMOUS',
    description: 'Follow album',
    icon: 'SvgAlbumAdd',
    get label() {
      return t`JoinAlbumButton.label`
    },
  },
  HIDE_PHOTO: {
    actionType: 'HIDE_PHOTO',
    actionFn: photoActionsApiFunctions['HIDE_PHOTO'],
    hasAction: null,
    isDisabled: ({ photoIds }) => !(photoIds && photoIds.length),
    actionContexts: getPhotoActionContexts('HIDE_PHOTO'),
    testid: 'ACTION_HIDE_PHOTO',
    description: 'Hide photo from timeline',
    icon: 'SvgHide',
    get label() {
      return t`HideButton.label`
    },
  },
  LEAVE_ALBUM: {
    actionType: 'LEAVE_ALBUM',
    actionFn: photoActionsApiFunctions['LEAVE_ALBUM'],
    hasAction: ({ isAlbumSubscriber }) => Boolean(isAlbumSubscriber),
    isDisabled: ({ albumId }) => !albumId,
    actionContexts: getPhotoActionContexts('LEAVE_ALBUM'),
    testid: 'ACTION_LEAVE_ALBUM',
    description: 'Stop following album',
    icon: 'SvgStop',
    get label() {
      return t`LeaveAlbumShareButton.label`
    },
  },
  PLAY_ALBUM_SLIDESHOW: {
    actionType: 'PLAY_ALBUM_SLIDESHOW',
    actionFn: photoActionsApiFunctions['PLAY_ALBUM_SLIDESHOW'],
    hasAction: null,
    isDisabled: ({ albumId }) => !albumId,
    actionContexts: getPhotoActionContexts('PLAY_ALBUM_SLIDESHOW'),
    testid: 'ACTION_PLAY_ALBUM_SLIDESHOW',
    description: 'Play album photos as a slideshow',
    icon: 'SvgPlayCircleButton',
    get label() {
      return t`PlayButton.label`
    },
  },
  RENAME_ALBUM: {
    actionType: 'RENAME_ALBUM',
    actionFn: photoActionsApiFunctions['RENAME_ALBUM'],
    hasAction: ({ album }) => Boolean(album?.isOwner),
    isDisabled: null,
    actionContexts: getPhotoActionContexts('RENAME_ALBUM'),
    testid: 'ACTION_RENAME_ALBUM',
    description: 'Update album title',
    icon: 'SvgEdit',
    get label() {
      return t`Album title`
    },
  },
  REMOVE_PHOTOS_FROM_ALBUM: {
    actionType: 'REMOVE_PHOTOS_FROM_ALBUM',
    actionFn: photoActionsApiFunctions['REMOVE_PHOTOS_FROM_ALBUM'],
    hasAction: ({ album, isAlbumSubscriber }) =>
      Boolean(album?.isOwner || isAlbumSubscriber),
    isDisabled: ({ isOwnerOfAllPhotos, albumId, photoIds }) =>
      !(isOwnerOfAllPhotos && photoIds && photoIds.length && albumId),
    actionContexts: getPhotoActionContexts('REMOVE_PHOTOS_FROM_ALBUM'),
    testid: 'ACTION_REMOVE_PHOTOS_FROM_ALBUM',
    description: 'Remove photos from album',
    icon: 'SvgTrash',
    get label() {
      return t`Remove`
    },
  },
  SET_ALBUM_ACCESS: {
    actionType: 'SET_ALBUM_ACCESS',
    actionFn: photoActionsApiFunctions['SET_ALBUM_ACCESS'],
    hasAction: ({ album }) => Boolean(album?.isOwner),
    isDisabled: null,
    actionContexts: getPhotoActionContexts('SET_ALBUM_ACCESS'),
    testid: 'ACTION_SET_ALBUM_ACCESS',
    description: 'Togglle READ/WRITE access for shared album',
    icon: 'SvgSettings',
    get label() {
      return t`SetShareTypeButton.label`
    },
  },
  SHARE_ALBUM: {
    actionType: 'SHARE_ALBUM',
    actionFn: photoActionsApiFunctions['SHARE_ALBUM'],
    hasAction: ({ album }) => Boolean(album && album.isOwner),
    isDisabled: ({ albumId }) => !albumId,
    actionContexts: getPhotoActionContexts('SHARE_ALBUM'),
    testid: 'ACTION_SHARE_ALBUM',
    description: 'Share album',
    icon: 'SvgShare',
    get label() {
      return t`ShareButton.label`
    },
  },
  SHARE_PHOTO: {
    actionType: 'SHARE_PHOTO',
    actionFn: photoActionsApiFunctions['SHARE_PHOTO'],
    hasAction: ({ username }) => Boolean(username),
    isDisabled: ({ isOwnerOfAllPhotos, photoIds }) =>
      !(isOwnerOfAllPhotos && photoIds && photoIds.length),
    actionContexts: getPhotoActionContexts('SHARE_PHOTO'),
    testid: 'ACTION_SHARE_PHOTO',
    description: 'Share photo',
    icon: 'SvgShare',
    get label() {
      return t`ShareButton.label`
    },
  },
  SHOW_INFO: {
    actionType: 'SHOW_INFO',
    actionFn: photoActionsApiFunctions['SHOW_INFO'],
    hasAction: null,
    isDisabled: null,
    actionContexts: getPhotoActionContexts('SHOW_INFO'),
    testid: 'ACTION_SHOW_INFO',
    description: 'Toggle photo info in viewer',
    icon: 'SvgInfo',
    get label() {
      return t`ShowInfoButton.label`
    },
  },
  UNHIDE_PHOTO: {
    actionType: 'UNHIDE_PHOTO',
    actionFn: photoActionsApiFunctions['UNHIDE_PHOTO'],
    hasAction: ({ username }) => Boolean(username),
    isDisabled: ({ photoIds }) => !(photoIds && photoIds.length),
    actionContexts: getPhotoActionContexts('UNHIDE_PHOTO'),
    testid: 'ACTION_UNHIDE_PHOTO',
    description: 'Unhide photo',
    icon: 'SvgRestore',
    get label() {
      return t`UnHideButton.label`
    },
  },
  UNSHARE_ALBUM: {
    actionType: 'UNSHARE_ALBUM',
    actionFn: photoActionsApiFunctions['UNSHARE_ALBUM'],
    hasAction: ({ album }) => Boolean(album?.isOwner),
    isDisabled: ({ albumId }) => !albumId,
    actionContexts: getPhotoActionContexts('UNSHARE_ALBUM'),
    testid: 'ACTION_UNSHARE_ALBUM',
    description: 'Unshare album',
    icon: 'SvgStop',
    get label() {
      return t`StopAlbumShareButton.label`
    },
  },
  UPLOAD_PHOTO: {
    actionType: 'UPLOAD_PHOTO',
    actionFn: photoActionsApiFunctions['UPLOAD_PHOTO'],
    isDisabled: null,
    hasAction: ({ username, album, readOnly, isAlbumSubscriber }) =>
      Boolean(
        (username && !album) ||
          (album && album.isOwner) ||
          (album && isAlbumSubscriber && !readOnly),
      ),
    actionContexts: getPhotoActionContexts('UPLOAD_PHOTO'),
    testid: 'ACTION_UPLOAD_PHOTO',
    description: '',
    icon: 'SvgAlbumAdd',
    get label() {
      return t`Upload`
    },
  },
} as const satisfies {
  [ActionType in PhotoActionType]: {
    actionType: ActionType
    actionContexts: readonly PhotoActionContextType[]
    actionFn: PhotoAction<ActionType>['actionFn']
    hasAction: null | ((args: PhotoActionHandlerSharedProps) => boolean)
    isDisabled: null | ((args: PhotoActionHandlerSharedProps) => boolean)
    testid: `ACTION_${ActionType}`
    description: string
    icon: BrandThemeIcon
    label: string
  }
}

export type PhotoActionsApi = typeof photoActionsApi
export type PhotoActionConfig<A extends PhotoActionType = PhotoActionType> =
  PhotoActionsApi[A]
export function getPhotoActions<Ctx extends PhotoActionContextType>(
  context: Ctx,
): PhotoActionConfig[] {
  return photoActionsByContext[context].map(
    actionType => photoActionsApi[actionType],
  )
}
