import {
  getWaitListStatus,
  joinWaitlist,
} from '@jotta/grpc-js-client/searchService'
import { WaitlistStatus } from '@jotta/grpc-web/no/jotta/openapi/search/search.v2_pb'
import { action, makeAutoObservable, runInAction } from 'mobx'

export class WaitlistStore {
  private status: WaitlistStatus | null = null
  private pending: Promise<WaitlistStatus | undefined> | null = null

  get waitlistStatus() {
    if (this.status === null) {
      this.getStatus()
    }

    return this.status
  }

  get isActive() {
    return Boolean(
      this.waitlistStatus?.getStatus() === WaitlistStatus.Status.ACTIVE,
    )
  }

  get isOnWaitlist(): boolean {
    const status = this.waitlistStatus?.getStatus()
    return status === WaitlistStatus.Status.ON_WAITLIST
  }

  get isInactive(): boolean {
    const status = this.waitlistStatus?.getStatus()
    return status === WaitlistStatus.Status.INACTIVE
  }

  get statusUnkown(): boolean {
    const status = this.waitlistStatus?.getStatus()
    return status === WaitlistStatus.Status.UNKNOWN
  }

  emailList: string[] = []
  email: string = ''
  message: string = ''

  constructor() {
    makeAutoObservable(
      this,
      {
        getStatus: action,
      },
      {
        autoBind: true,
      },
    )
  }

  getStatus() {
    if (this.pending) {
      return
    }
    this.pending = getWaitListStatus()
    this.pending.then(status =>
      runInAction(() => {
        this.status = status || null
        this.pending = null
      }),
    )
  }

  async joinWaitlist() {
    this.pending = (this.pending || Promise.resolve()).then(joinWaitlist)
    const status = await this.pending

    runInAction(() => {
      this.status = status || null
      this.pending = null
    })
  }

  @action setEmail(email: string) {
    this.email = email
  }
  @action setMessage(message: string) {
    this.message = message
  }
}

export const waitlistStore = new WaitlistStore()
