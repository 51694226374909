import type { GetPathItemSortDir } from '@jotta/grpc-js-client/files/getPath'
import type {
  FileRouteParams,
  FileRouteSortBy,
} from '@jotta/types/schemas/FileRouteSchema'
import { FileRouteSortByToSortColumnAndDir } from '@jotta/types/schemas/FileRouteSchema'
import { uriEncodeRoutePath } from '@jotta/utils/pathUtils'
import moize from 'moize'
import { objectify } from 'radash'
import { useLocation } from 'react-router-dom'
import { getFileRouteParams } from './getFileRouteParams'
import { routeParamsToRoutePath } from './routeParamsToRoutePath'

export const sortParamToSortBy: Record<
  FileRouteParams['sortBy'],
  GetPathItemSortDir
> = {
  name: 'NAME_ASC',
  'name-desc': 'NAME_DESC',
  modified: 'DATE_ASC',
  'modified-desc': 'DATE_DESC',
  size: 'SIZE_ASC',
  'size-desc': 'SIZE_DESC',
}

/**
 *
 * @param params params object or a pathname to parse into params
 * @returns
 */
export const getSortLinks = moize.shallow(
  function getSortLinks(data: any) {
    const params = getFileRouteParams(data, 'catch')
    const activeSortMethod = FileRouteSortByToSortColumnAndDir[params.sortBy]
    const sortDescending = activeSortMethod.dir === 'desc'
    const sortDir = sortParamToSortBy[params.sortBy]
    const sortLinks = objectify(
      (['name', 'modified', 'size'] as const).map(sortColumn => {
        const sortBy: FileRouteSortBy = sortDescending
          ? `${sortColumn}-desc`
          : sortColumn
        const nextSort: FileRouteSortBy = sortDescending
          ? sortColumn
          : `${sortColumn}-desc`
        const isCurrent = sortBy === activeSortMethod.current
        const to = routeParamsToRoutePath({
          ...params,
          sortBy: isCurrent ? nextSort : sortBy,
        })
        return {
          sortBy,
          sortColumn,
          active: activeSortMethod.name === sortColumn,
          to,
          encodedTo: uriEncodeRoutePath(to),
        }
      }),
      s => s.sortColumn,
    )
    return {
      replace: params.context === 'search',
      activeSortMethod,
      sortDir,
      sortLinks,
      sortDescending,
      sortColumn: activeSortMethod.name,
    }
  },
  {
    maxSize: 100,
  },
)
export function useSortLinks() {
  const location = useLocation()
  return getSortLinks(location.pathname)
}
