import type { BrandStore } from '@jotta/ui/BrandStore'
import { useBrandStore } from '@jotta/ui/useBrandTheme'
import { AppLayout } from '@jotta/ui/AppLayout'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import { WelcomeDialog } from '@jotta/ui/WelcomeDialog'
import { observer } from 'mobx-react-lite'
import type { FC, PropsWithChildren } from 'react'
import { Suspense, useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import type { PrivateSection } from './PrivateSection'
import { Header } from './Header'
import { NavBar } from './NavBar'
import { TopNavBar } from './TopNavBar'
import { WebappBanner } from './WebappBanner'
import { useGetCustomer } from '@jotta/grpc-connect-client/customer'

export const WebappLayout: FC<
  PropsWithChildren<{
    section: PrivateSection
    activeElements?: Partial<BrandStore['activeElements']>
  }>
> = observer(function WebappLayout({
  section,
  activeElements,
  children = <Outlet />,
}) {
  const { context } = useParams()
  const brandStore = useBrandStore()
  const hideModuleNav =
    section === 'upgrade' ||
    (section === 'settings' && brandStore.isMobile) ||
    (section === 'web' && (context === 'trash' || !brandStore.isMobile))
  useEffect(() => {
    brandStore.activateElements({
      leftNav: section !== 'upgrade',
      moduleNav: !hideModuleNav,
      timelineScrubber: false,
      ...activeElements,
    })
  }, [brandStore, hideModuleNav, activeElements, section])

  const { customer } = useGetCustomer().data || {}
  const showUpgradeLink = customer?.promoteUpgrade || false

  const businessName = customer?.business?.name
  return (
    <AppLayout
      navbar={
        <NavBar
          businessName={businessName}
          accountType={customer?.accountType}
          userCanUpgrade={showUpgradeLink}
        />
      }
      banner={<WebappBanner />}
      moduleNav={
        !hideModuleNav && (
          <TopNavBar section={section} accountType={customer?.accountType} />
        )
      }
      header={<Header />}
    >
      <Suspense fallback={<LoadingOverlay open />}>{children}</Suspense>
      <WelcomeDialog />
    </AppLayout>
  )
})
