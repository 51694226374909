import { t } from '@lingui/macro'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { excludesFalse } from '@jotta/types/TypeUtils'

import { usePeople } from '../../people/internal/api/PeopleApi'
import { PhotoSearchDropdown } from './PhotoSearchDropdown'
import { PhotoSearchSelectedTag } from './PhotoSearchSelectedTags'

import {
  Popover,
  PopoverAnchor,
  PopoverContent,
  PopoverPortal,
} from '@radix-ui/react-popover'
import { AnimatePresence } from 'framer-motion'
import { usePhotoStore } from '../../store/PhotoContext'

const debug = Debug('jotta:photos:search:form')
export const PhotoSearchFormSimple = observer(function PhotoSearchFormSimple() {
  const search = usePhotoStore().search2
  const [{ byId }] = usePeople()
  const {
    searchParams: { isOpen, p: personIds },
    isCleared,
    onDocumentKeyDown,
    dropdownIsOpen,
    onDropdownOpenChange,
  } = search
  const selectedPeople = personIds.map(id => byId[id]).filter(excludesFalse)
  useEffect(() => {
    document.addEventListener('keydown', onDocumentKeyDown)
    return () => {
      document.removeEventListener('keydown', onDocumentKeyDown)
    }
  }, [onDocumentKeyDown])

  return (
    <div
      className={`flex ${isOpen ? '' : 'w-full max-w-[500px] justify-self-center'}`}
    >
      <Popover
        modal={false}
        open={dropdownIsOpen}
        onOpenChange={onDropdownOpenChange}
      >
        <PopoverAnchor asChild>
          <form
            className={`grid w-full grid-cols-[max-content_1fr_max-content] rounded-lg border border-sx-primary ${isOpen ? '' : 'bg-sx-primary/15'}`}
            onSubmit={search.onSubmit}
            id={search.ids.form}
          >
            <button className="items-center rounded-lg px-2">
              <PlainBrandIcon icon="SvgSearchAlt" className="size-6" />
            </button>
            <div className="flex min-h-10 flex-wrap gap-1">
              {selectedPeople.map((person, index) => (
                <PhotoSearchSelectedTag
                  key={person.id}
                  person={person}
                  store={search}
                  index={index}
                />
              ))}
              <input
                id={search.ids.input}
                className={`flex-auto bg-transparent caret-primary focus-visible:shadow-none ${isOpen ? 'placeholder:text-black/20' : 'placeholder:text-current'}`}
                name="q"
                minLength={1}
                autoComplete="off"
                value={search.query}
                onKeyDown={search.onInputKeyDown}
                onChange={search.onQueryChange}
                onClick={search.open}
                placeholder={
                  isOpen
                    ? t`Describe the photo you are looking for…`
                    : `${t`Try our AI-Powered Photo Search`} ✨`
                }
              />
            </div>
            {!isCleared && (
              <button className="px-2 text-sm" onClick={search.clear}>
                {t`Clear`}
              </button>
            )}
          </form>
        </PopoverAnchor>
        <AnimatePresence>
          {dropdownIsOpen && (
            <PopoverPortal forceMount>
              <PopoverContent
                sideOffset={16}
                forceMount
                onOpenAutoFocus={e => e.preventDefault()}
                onInteractOutside={e => {
                  if (
                    e.target instanceof HTMLElement &&
                    (e.target.id === search.ids.input ||
                      e.target.closest(`#${search.ids.form}`) ||
                      e.target.closest(`#${search.ids.dropdown}`))
                  ) {
                    debug('Prevent dropdown close')
                    e.preventDefault()
                  } else {
                    debug('Close dropdown')
                  }
                }}
                className="z-[150] flex w-[var(--radix-popper-anchor-width)] flex-col rounded-xl bg-white shadow-[2px_2px_10px_rgba(0,0,0,0.5)]"
              >
                <PhotoSearchDropdown search={search} />
              </PopoverContent>
            </PopoverPortal>
          )}
        </AnimatePresence>
      </Popover>
      {isOpen && (
        <button onClick={() => search.close()}>
          <PlainBrandIcon className="w-6" icon="SvgClose" />
        </button>
      )}
    </div>
  )
})
