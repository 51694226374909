import type { CSSProperties } from 'react'
import { useBrandStore } from '@jotta/ui/useBrandTheme'
import type { Photos } from '@jotta/types/Photos'
import { px } from '@jotta/utils/css'
import { groupByAspectRatio } from '@jotta/utils/groupByAspectRatio'
import {
  getFixedVirtualRowHeightPositions,
  getVisibleIndexes,
} from '@jotta/virtualized'
import Debug from 'debug'
import { observable, runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { useEffect } from 'react'
import { usePhotoStore } from '../../../store/PhotoContext'
import { AlbumPhotoThumb } from './AlbumPhotoThumb'
import styles from './AlbumPhotosLarge.module.scss'

const debug = Debug('jotta:photos:Album')
const rowHeight = 225

export const AlbumPhotosLarge = observer<{
  ids: readonly string[]
  ownerAvatar?: boolean
  showSimilarSearch?: boolean
}>(function AlbumPhotosLarge({
  ids,
  ownerAvatar = false,
  showSimilarSearch = false,
}) {
  const branding = useBrandStore()
  const photoStore = usePhotoStore()
  const store = useLocalObservable(
    () => ({
      ids,
      setIds(ids: readonly string[]) {
        this.ids = ids
      },
      get photos() {
        return photoStore.mediaObjects.getPhotosByIds(this.ids)
      },
      get rowAspect() {
        return branding.contentWidthRounded / rowHeight
      },
      get grouped() {
        return groupByAspectRatio<Photos.Media>(this.photos, this.rowAspect)
      },
      get positions() {
        return getFixedVirtualRowHeightPositions(
          this.grouped.rows.length,
          rowHeight,
        )
      },
      get visibleIndexes() {
        return getVisibleIndexes(
          rowHeight,
          branding.viewportHeight,
          branding.scrollTop,
          10,
        )
      },
      get visibleRows() {
        return this.positions.positions
          .slice(this.visibleIndexes.first, this.visibleIndexes.last)
          .map(p => ({
            ...p,
            row: this.grouped.rows[p.index],
          }))
      },
    }),
    {
      ids: observable.ref,
    },
  )
  useEffect(() => {
    runInAction(() => {
      store.setIds(ids)
    })
  }, [store, ids])

  return (
    <div className={styles.container}>
      <div
        data-testid="AlbumPhotos"
        className={styles.list}
        style={
          {
            '--rows': store.grouped.rows.length,
            '--row-height': px(rowHeight),
          } as CSSProperties
        }
      >
        {store.visibleRows.map((p, i) => {
          return (
            <div
              key={p.row.key}
              className={styles.row}
              data-align-start={
                i === store.visibleRows.length - 1 || p.row.fillRatio < 0.5
              }
              style={{
                top: `${p.top}px`,
              }}
            >
              {p.row.items.map(photo => (
                <AlbumPhotoThumb
                  id={photo.id}
                  key={photo.id}
                  ownerAvatar={ownerAvatar}
                  showSimilarSearch={showSimilarSearch}
                />
              ))}
            </div>
          )
        })}
      </div>
    </div>
  )
})
