import Debug from 'debug'
import { useMemo } from 'react'

const debug = Debug('frontendbackend')

type EnvironmentVariables = Record<string, string | undefined> | undefined
export function readEnvironment(): EnvironmentVariables {
  const envBase64 = document.head
    .querySelector('meta[name=frontendbackend_env]')
    ?.getAttribute('content')

  if (envBase64) {
    try {
      return JSON.parse(atob(envBase64))
    } catch (e) {
      debug(e)
    }
  }
}

export function readVersion(): string | undefined {
  return (
    document.head
      .querySelector('meta[name=frontendbackend_version]')
      ?.getAttribute('content') || undefined
  )
}

export function useVersion(): string | undefined {
  return useMemo(() => readVersion(), [])
}
