import type { BrandThemeIcon } from '@jotta/types/Brand'
import Debug from 'debug'
import { forwardRef } from 'react'
import { useBrandTheme } from '../../hooks/useBrandTheme'
import type { BoxPropsAs } from '../../Elements/Box/Box'
import { SvgBox } from '../../Elements/Box/Box'
const debug = Debug('jotta:branding:BrandIcon')

export interface BrandIconProps {
  /** Click handler */
  icon: BrandThemeIcon
  className?: string
}
/**
 * BrandIcon component
 */
export const BrandIcon = forwardRef<
  SVGSVGElement,
  BoxPropsAs<'svg', BrandIconProps, 'all'>
>(function BrandIcon({ icon, ...props }, ref) {
  const { theme } = useBrandTheme()

  const Icon = theme.icons[icon]
  return <SvgBox as={Icon} ref={ref} width="1em" height="1em" {...props} />
})
export function PlainBrandIcon({
  icon,
  noSize = false,
  width,
  height,
  ...props
}: {
  icon: BrandThemeIcon
  noSize?: boolean
  width?: string | number
  height?: string | number
  className?: string
}) {
  const { theme } = useBrandTheme()
  const Icon = theme.icons[icon]

  const hasSize = width !== undefined || height !== undefined
  width = hasSize ? width : '100%'
  height = hasSize ? height : '100%'
  return <Icon width={width} height={height} {...props} />
}
