import type { FileRouteParams } from '@jotta/types/schemas/FileRouteSchema'
import { createPath, pathToArray } from '@jotta/utils/pathUtils'
import { unique } from 'radash'
import { fileRouteContextConfig } from '../fileRouteContextConfig'
import { getFileRouteParams } from './getFileRouteParams'
import { routeParamsToApiPath } from './routeParamsToApiPath'

export function routeParamsToApiPathBreadCrumb(
  route: string | FileRouteParams,
) {
  const params = getFileRouteParams(route, 'catch')
  const { '*': splat, context } = params
  const contextConfig = fileRouteContextConfig[context]
  const parentContext = contextConfig.rootContext
  const parentContextConfig = parentContext
    ? fileRouteContextConfig[parentContext]
    : undefined

  if (context === 'search') {
    return ['/search']
  }
  const sliceIndex = ['trash', 'shared'].includes(context) ? 1 : 0
  const splatArray = pathToArray(splat)
  const prependPaths = parentContextConfig ? [parentContextConfig.apiPath] : []
  const breadcrumb = unique([
    ...prependPaths,
    ...splatArray.map((p, i, arr) =>
      routeParamsToApiPath({
        ...params,
        '*': createPath(...arr.slice(0, i + 1)),
      }),
    ),
  ])
  if (sliceIndex) {
    breadcrumb.splice(sliceIndex, 1)
  }
  return breadcrumb
}
