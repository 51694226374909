import { LinkThemeUI } from '@jotta/ui/themeui'
import { GetPaymentInfoResponse } from '@jotta/grpc-web/no/jotta/openapi/subscription/payment.v2_pb'
import { FormattedContent } from '@jotta/ui/FormattedContent'
import { Headline3 } from '@jotta/ui/Headline'
import { HelpArticle, generateHelpArticleLink } from '@jotta/utils/helpArticles'
import { Trans, plural } from '@lingui/macro'
import dayjs from 'dayjs'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useBrandName } from '@jotta/grpc-connect-client/config'
import { useGetCustomer } from '@jotta/grpc-connect-client/customer'
const debug = Debug('jotta:SubscriptionUpgradeCase')

const {
  MANAGE_IN_PLAY_STORE,
  MANAGE_IN_APP_STORE,
  CONTACT_RESELLER,
  CONTACT_SUPPORT,
  CONTACT_FAMILY_OWNER,
  CONTACT_BUSINESS_ADMIN,
  MANAGE_IN_BUSINESS_PAGES,
  CONVERT_FROM_RESELLER,
} = GetPaymentInfoResponse.ChangeSubscriptionOptionCase

export const IAPExpirationWarning: FC<{
  serviceName: string
  expirationMillis?: number
}> = observer(function IAPExpirationWarning({ serviceName, expirationMillis }) {
  const languageCode = useGetCustomer().data?.customer?.languageCode || 'en'
  const expirationDate =
    expirationMillis && dayjs(expirationMillis).locale(languageCode).format('l')
  return (
    <FormattedContent variant="cards.dangerOutline">
      <Headline3 color="danger">
        <Trans id="Subscription notice" />
      </Headline3>
      <p>
        <Trans id="subscription_ended_but_will_keep_data">
          {expirationDate}
          {serviceName}
        </Trans>
      </p>
      <p>
        <Trans id="have_you_changed_your_mind" />
      </p>
      <p>
        <LinkThemeUI href="/web/upgrade">
          <Trans id="start_new_subscription" />
        </LinkThemeUI>
      </p>
    </FormattedContent>
  )
})

export interface SubscriptionUpgradeCaseProps {
  subscription: GetPaymentInfoResponse.AsObject
  upgradeOptionCase?: GetPaymentInfoResponse.ChangeSubscriptionOptionCase
}

export const SubscriptionUpgradeCase = observer<SubscriptionUpgradeCaseProps>(
  ({
    subscription: {
      contactFamilyOwner,
      contactReseller,
      contactSupport,
      expirationDateMillis,
      convertFromReseller,
    },
    upgradeOptionCase,
  }) => {
    const expirationMillis = expirationDateMillis?.value || 0
    const isExpired = expirationMillis && Date.now() > expirationMillis
    const resellerName = contactReseller?.resellerName || ''
    const familyOwner = contactFamilyOwner?.familyOwnerName
    const brandName = useBrandName()
    switch (upgradeOptionCase) {
      case CONVERT_FROM_RESELLER:
        return (
          <FormattedContent>
            <p>
              <Trans
                id={
                  'Your subscription has been cancelled and your account will be terminated in {daysUntilTermination}. Backup has stopped and everything stored in this account will automatically be deleted when terminated. You can download saved content or transfer your account to Jottacloud. Jottacloud is a Norwegian company and the provider of {serviceName}. If you transfer to Jottacloud, you’ll keep your account with all stored content.'
                }
                values={{
                  daysUntilTermination: plural(
                    convertFromReseller?.daysUntilTermination || 0,
                    {
                      one: '# day',
                      other: '# days',
                    },
                  ),
                  serviceName: brandName || '',
                }}
              />
            </p>
          </FormattedContent>
        )
      case CONTACT_RESELLER:
        return (
          <FormattedContent>
            <p>
              <Trans
                id={
                  'Your subscription is managed by {resellerName}. If you want to make changes to your subscription, please <0>contact them</0> directly.'
                }
              >
                Your subscription is managed by {resellerName}. If you want to
                make changes to your subscription, please{' '}
                <a
                  href={contactReseller?.resellerSupportUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  contact them directly
                </a>
                .
              </Trans>
            </p>
          </FormattedContent>
        )
      case CONTACT_FAMILY_OWNER:
        return (
          <FormattedContent>
            <p>
              <Trans id="Your account is part of a family subscription. Please contact {familyOwner} to administer your subscription.">
                Text {familyOwner} rest of text.
              </Trans>
            </p>
          </FormattedContent>
        )
      case CONTACT_SUPPORT:
        return (
          <FormattedContent>
            <p>
              <Trans
                id={
                  'If you want to make changes to your subscription, please <0>contact support</0>.'
                }
              >
                If you want to make changes to your subscription, please
                <a
                  href={contactSupport?.supportUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  contact support
                </a>
                {'.'}
              </Trans>
            </p>
          </FormattedContent>
        )
      case MANAGE_IN_APP_STORE:
        return isExpired ? (
          <IAPExpirationWarning
            serviceName="App Store"
            expirationMillis={expirationMillis}
          />
        ) : (
          <FormattedContent>
            <p>
              <Trans id="manage_app_store_subscription">
                <a
                  href={generateHelpArticleLink(
                    HelpArticle.HOW_TO_START_ON_MOBILE,
                  )}
                  target="_blank"
                  rel="noreferrer"
                >
                  Text
                </a>
              </Trans>
            </p>
          </FormattedContent>
        )
      case MANAGE_IN_PLAY_STORE:
        return isExpired ? (
          <IAPExpirationWarning
            serviceName="Play Store"
            expirationMillis={expirationMillis}
          />
        ) : (
          <FormattedContent>
            <p>
              <Trans id="manage_play_store_subscription">
                <a
                  href={generateHelpArticleLink(
                    HelpArticle.HOW_TO_START_ON_MOBILE,
                  )}
                  target="_blank"
                  rel="noreferrer"
                >
                  Text
                </a>
              </Trans>
            </p>
          </FormattedContent>
        )
      case CONTACT_BUSINESS_ADMIN: {
        return (
          <FormattedContent>
            <p>
              <Trans
                id={
                  'Your account is part of a business subscription. Please contact a business administrator to make changes.'
                }
              />
            </p>
          </FormattedContent>
        )
      }
      case MANAGE_IN_BUSINESS_PAGES: {
        return (
          <FormattedContent>
            <p>
              <Trans
                id={
                  'You are on a business subscription. Go to the <0>business pages</0> to manage your subscription.'
                }
              >
                You are on a business subscription. Go to the{' '}
                <a href={'/biz/settings'}>business pages</a> to manage your
                subscription.
              </Trans>
            </p>
          </FormattedContent>
        )
      }
      default:
        return null
    }
  },
)
