import { ItemKind, ItemType } from '@jotta/grpc-js-client/fileService'
import type { FileItem, FolderItem, PathItemObject } from '@jotta/types/Files'

export function createEmptyPathItem(): PathItemObject {
  return {
    actionList: [],
    checksum: '',
    childrenList: [],
    commentAuthToken: '',
    commentItemId: '',
    createdAtMillis: 0,
    deletedAtMillis: 0,
    downloadLink: '',
    encodedContentRef: '',
    folderShareId: '',
    kind: ItemKind.UNKNOWN_ITEM_KIND,
    mime: '',
    modifiedAtMillis: 0,
    name: '',
    owner: '',
    path: '',
    publicLinkId: '',
    size: 0,
    thumbLink: '',
    type: ItemType.UNKNOWN_ITEM_TYPE,
    contentId: '',
  }
}
export function createEmptyFolderItem(): FolderItem {
  return {
    ...(createEmptyPathItem() as FolderItem),
    type: ItemType.FOLDER,
  }
}
export function createEmptyFileItem(): FileItem {
  return {
    ...(createEmptyPathItem() as FileItem),
    type: ItemType.FILE,
  }
}
