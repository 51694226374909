import { getGroupCommentCount } from '@jotta/grpc-js-client/commentService'
import type { PhotosApi } from '@jotta/types/PhotosApi'
import { t } from '@lingui/macro'
import { observer } from 'mobx-react-lite'
import type { ReactNode } from 'react'
import { useEffect, useState } from 'react'
import { BaseAlbumThumb } from './BaseAlbumThumb'

export interface AlbumThumbProps {
  album?: PhotosApi.AlbumInfo
  showAvatar?: boolean
  children?: ReactNode
}

export function getAlbumTitle(album?: PhotosApi.AlbumInfo) {
  if (album) {
    if (album.title) {
      return album.title
    }
    if (album.isSharedPhotos) {
      return t`Group of photos`
    }
  }
  return t`No Title`
}

export const AlbumThumb = observer<AlbumThumbProps>(function AlbumThumb({
  album,
  children,
  showAvatar = false,
}) {
  const [totalCommentCount, setTotalCommentCount] = useState(0)
  const thumb = album?.coverPhoto?.thumbnail_url
  const commentsGroupId = album?.commentsGroupId

  useEffect(() => {
    if (commentsGroupId) {
      getGroupCommentCount(commentsGroupId).then(({ totalCommentCount }) =>
        setTotalCommentCount(totalCommentCount),
      )
    }
  }, [commentsGroupId])
  const title = getAlbumTitle(album)

  return (
    <BaseAlbumThumb
      title={title}
      commentCount={totalCommentCount}
      ownerAvatar={album?.shareInfo?.ownerAvatar}
      imageCount={album?.total}
      showAvatar={showAvatar}
      // date={album?.createdDate}
    >
      {children ||
        (thumb && <img draggable={false} src={`${thumb}.s`} alt={title} />)}
    </BaseAlbumThumb>
  )
})
