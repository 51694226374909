import type { UploadDialogActions } from '@jotta/types/Upload'
import { renameFile } from '@jotta/utils/files'
export const openUploadDialog = (actions: UploadDialogActions) => {
  const htmlInputElement =
    (document.getElementById('fileInput') as HTMLInputElement) ||
    document.createElement('input')

  htmlInputElement.id = 'fileInput'
  htmlInputElement.type = 'file'
  htmlInputElement.multiple = true
  htmlInputElement.classList.add('fileuploadinput')
  htmlInputElement.style.opacity = '0'

  if (actions.folderMode) {
    htmlInputElement.setAttribute('directory', '')
    htmlInputElement.setAttribute('webkitdirectory', '')
  } else {
    htmlInputElement.removeAttribute('directory')
    htmlInputElement.removeAttribute('webkitdirectory')
  }

  if (actions.allowPhotosOnly) {
    htmlInputElement.setAttribute('accept', 'image/*')
  } else {
    htmlInputElement.removeAttribute('accept')
  }

  htmlInputElement.onchange = ev => {
    try {
      const files = htmlInputElement.files
      if (files) {
        const fileArray = Array.from(files).map(file =>
          file.webkitRelativePath
            ? renameFile(file, file.webkitRelativePath)
            : file,
        )
        actions.onSelection(fileArray)
      }
    } catch (e) {
      actions.onError(e)
    }
    htmlInputElement.remove()
  }

  //In safari on iOS we need to put the input element into the DOM:
  document.body.appendChild(htmlInputElement)

  htmlInputElement.click()
}
