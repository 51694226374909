import type { Avatar as AvatarType } from '@jotta/types/Avatar'
import { Avatar } from '@jotta/ui/Avatar'
import { CommentsBubble } from '@jotta/ui/CommentsBubble'
import { Plural, Trans } from '@lingui/macro'
import dayjs from 'dayjs'
import type { PropsWithChildren } from 'react'
import { isNumber } from 'remeda'
import styles from './AlbumThumb.module.scss'

export type BaseAlbumThumbProps = PropsWithChildren<{
  title?: string
  imageCount?: number
  commentCount?: number
  date?: number
  ownerAvatar?: AvatarType
  showAvatar?: boolean
}>

export function BaseAlbumThumb({
  children,
  title = '',
  date,
  imageCount,
  commentCount = 0,
  ownerAvatar,
  showAvatar = false,
}: BaseAlbumThumbProps) {
  return (
    <div className={styles.albumThumb}>
      <div className={styles.thumb}>
        {children}
        {ownerAvatar && showAvatar ? (
          <div className={styles.avatar}>
            <Avatar avatar={ownerAvatar} size={30} />
          </div>
        ) : null}
        {commentCount > 0 && <CommentsBubble count={commentCount} />}
      </div>

      {title && (
        <div className={styles.title}>{title || <Trans>No Title</Trans>}</div>
      )}
      {title && isNumber(imageCount) && (
        <div className={styles.count}>
          <Plural value={imageCount || 0} one="# photo" other="# photos" />
        </div>
      )}
      {!!date && (
        <div className={styles.date}>{dayjs.utc(date).format('L LT')}</div>
      )}
    </div>
  )
}
