import type { InputProps } from '@jotta/ui/Input'
import { Input } from '@jotta/ui/Input'
import type { InputValidState } from '@jotta/ui/types/BrandTheme'
import type { FC } from 'react'

import { useEffect, useRef } from 'react'

const inputSizes = { FULL: '100%', SMALL: '25%', MEDIUM: '50%' } as const
type InputSize = keyof typeof inputSizes
export interface FormInputTextProps extends InputProps {
  /** Center text inside input */
  center?: boolean
  /** Validation state  */
  validState?: InputValidState
  /** Sets the width of the input */
  sizePreset?: InputSize
  /** selection from index */
  selectionFrom?: number
  /** selection to index */
  selectionTo?: number
  ref?: React.RefObject<HTMLInputElement>
}

export const FormInputText: FC<FormInputTextProps> = ({
  type = 'text',
  selectionFrom = 0,
  selectionTo = 0,
  ...props
}) => {
  const ref = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (ref.current && (selectionFrom || selectionTo)) {
      // NB: this must execute within an user event to work on ios
      ref.current.focus()
      ref.current.setSelectionRange(selectionFrom, selectionTo)
    }
    return () => {
      // cleanup
    }
  }, [ref, selectionFrom, selectionTo])

  return <Input type={type} ref={ref} {...props} />
}
