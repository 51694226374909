import { Box } from '@jotta/ui/Box'
import { BrandIcon } from '@jotta/ui/BrandIcon'
import { Button } from '@jotta/ui/Button'
import { Flex } from '@jotta/ui/Flex'
import { Grid } from '@jotta/ui/Grid'
import { Stack } from '@jotta/ui/Stack'
import { TextThemeUI } from '@jotta/ui/themeui'
import { useBrandTheme } from '@jotta/ui/useBrandTheme'
import { Currency } from '@jotta/grpc-js-client/subscriptionService'
import type { GetPaymentInfoResponse } from '@jotta/grpc-web/no/jotta/openapi/subscription/payment.v2_pb'
import { SubscriptionInterval } from '@jotta/grpc-web/no/jotta/openapi/subscription/payment.v2_pb'
import type { BrandThemeIcon } from '@jotta/types/Brand'
import type { FeatureToggle } from '@jotta/types/FeatureToggle'
import { useAlertListStore } from '@jotta/ui/AlertStore'
import { ButtonGroup } from '@jotta/ui/ButtonGroup'
import { Card } from '@jotta/ui/Card'
import { ChangePaymentMethodDialog } from '@jotta/ui/ChangePaymentMethodDialog'
import { FormattedContent } from '@jotta/ui/FormattedContent'
import { Modal } from '@jotta/ui/Modal'
import { Paragraph } from '@jotta/ui/Text'
import { capitalize } from '@jotta/utils/text'
import { Trans, t } from '@lingui/macro'
import { useStripe } from '@stripe/react-stripe-js'
import type { StripeCardNumberElement } from '@stripe/stripe-js'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useToggle } from 'react-use'
import { paymentStore } from '../../state/stores'
import { formatPrice } from '../../utils/formatPrice'
import { ChangePaymentIntervalDialog } from './ChangePaymentIntervalDialog/ChangePaymentIntervalDialog'
import { useFeatures } from '@jotta/grpc-connect-client/config'
import { useLocale } from '@jotta/i18n'
import { redirectAndWait } from '@jotta/utils/redirect'

export interface PaymentInformationProps {
  subscription: GetPaymentInfoResponse.AsObject
  brandName?: string
  isCardDialogOpen: boolean
  toggleCardDialog: (state?: boolean) => void
  activeFeatureToggles: FeatureToggle[]
}

export const PaymentInformation = observer<PaymentInformationProps>(
  ({
    subscription,
    brandName,
    isCardDialogOpen,
    toggleCardDialog,
    activeFeatureToggles,
  }) => {
    const {
      paymentInfo,
      subscriptionInterval,
      credit,
      discountPercentage,
      price,
      possiblePaymentIntervalsList,
      renewalDateMillis,
    } = subscription
    const card = paymentInfo?.card
    const cardVendor = card?.cardVendor
    const cardMask = card?.cardMask
    const cardExpiry = card?.cardExpiry

    const vippsBilling = paymentInfo?.vippsBilling
    const isVippsBilling: boolean = !!vippsBilling || false
    const features = useFeatures()
    const locale = useLocale()

    const { alert } = useAlertListStore()

    const { theme } = useBrandTheme()
    const [isPaymentIntervalDialogOpen, togglePaymentIntervalDialog] =
      useToggle(false)
    const [isRequesting, toggleRequestState] = useToggle(false)
    const [cardDialogError, setCardDialogError] = useState('')
    const stripe = useStripe()
    const vendorIcon =
      cardVendor && capitalize(`Svg${capitalize(cardVendor.toLowerCase())}`)
    const isVendorIconExist =
      vendorIcon && Object.keys(theme.icons).includes(vendorIcon)
    const onToggleCardDialog = () => {
      toggleCardDialog()
      setCardDialogError('')
    }

    const onVerify = (cardNumberElement: StripeCardNumberElement | null) => {
      if (cardNumberElement && stripe) {
        toggleRequestState()
        setCardDialogError('')
        paymentStore.createSetupIntent().then(({ clientSecret }) => {
          stripe
            .confirmCardSetup(clientSecret, {
              payment_method: { card: cardNumberElement },
            })
            .then(({ setupIntent, error }) => {
              if (error) {
                setCardDialogError(
                  error?.message || t`Unknown error please try again later.`,
                )
                toggleRequestState()
              } else {
                if (setupIntent?.payment_method) {
                  paymentStore
                    .changePaymentMethod(setupIntent.payment_method)
                    .finally(() => {
                      toggleRequestState()
                      toggleCardDialog()
                      setCardDialogError('')
                      alert.success(
                        <Trans>
                          Payment information updated successfully.
                        </Trans>,
                      )
                    })
                }
              }
            })
        })
      }
    }

    const onPaymentIntervalChange = (interval: SubscriptionInterval) => {
      toggleRequestState()
      paymentStore
        .changePaymentInterval(interval)
        .then(() => {
          alert.success(
            <Trans>Payment information updated successfully.</Trans>,
          )
        })
        .finally(() => {
          toggleRequestState()
          togglePaymentIntervalDialog()
        })
    }

    const onSetupVipps = (interval?: SubscriptionInterval) => {
      paymentStore
        .createVippsIntent({
          interval: interval,
        })
        .then(data => redirectAndWait(data.confirmationUrl))
        .catch(err => {
          alert.error(<Trans>Something went wrong</Trans>)
        })
    }

    const enableVipps = features.vipps && price?.currency === Currency.NOK

    if (
      !(card || vippsBilling) &&
      !price &&
      !discountPercentage &&
      !credit &&
      !renewalDateMillis
    ) {
      return null
    }

    return (
      <Card variant="cards.compact" title={t`Payment information`}>
        <Grid columns={[1, 1, '1fr auto']}>
          <Stack gap={1}>
            {card && (
              <Box sx={{ flexGrow: 1 }} mb={4}>
                <Flex
                  sx={{
                    alignItems: 'center',
                  }}
                >
                  {isVendorIconExist && (
                    <BrandIcon
                      icon={vendorIcon as BrandThemeIcon}
                      sx={{ fontSize: 6, mr: 3 }}
                    />
                  )}
                  <TextThemeUI>{cardMask}</TextThemeUI>
                  {cardExpiry && (
                    <TextThemeUI ml={2}>({cardExpiry})</TextThemeUI>
                  )}
                </Flex>
              </Box>
            )}
            {vippsBilling && (
              <Box sx={{ flexGrow: 1 }} mb={4}>
                <Flex
                  sx={{
                    flexDirection: 'column',
                    alignItems: 'left',
                  }}
                >
                  <Paragraph>
                    <Flex
                      sx={{
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <BrandIcon
                        icon={'SvgVippsMark'}
                        sx={{ fontSize: 6, mr: 2 }}
                      />
                      <TextThemeUI as={'span'}>{'Vipps'}</TextThemeUI>
                    </Flex>
                  </Paragraph>
                  <Paragraph>
                    <Trans id={'Status'} />
                    {': '}
                    {vippsBilling?.isActive ? (
                      <TextThemeUI as={'span'} sx={{ color: 'success' }}>
                        <Trans id={'Enabled'} />
                      </TextThemeUI>
                    ) : (
                      <Trans id={'Disabled'} />
                    )}
                  </Paragraph>
                </Flex>
              </Box>
            )}
            {renewalDateMillis && (
              <FormattedContent>
                <p>
                  {subscriptionInterval === SubscriptionInterval.MONTHLY &&
                    t({
                      id: 'Your subscription renews automatically on {date} for another month.',
                      values: {
                        date: dayjs(renewalDateMillis.value).format('L'),
                      },
                    })}
                  {subscriptionInterval === SubscriptionInterval.YEARLY &&
                    t({
                      id: 'Your subscription renews automatically on {date} for another year.',
                      values: {
                        date: dayjs(renewalDateMillis.value).format('L'),
                      },
                    })}
                </p>
              </FormattedContent>
            )}
            {price && (
              <TextThemeUI variant="styles.p">
                <Trans>
                  You'll be charged{' '}
                  <strong>{`${formatPrice(price, locale)}`}</strong>.
                </Trans>
              </TextThemeUI>
            )}
            {credit && (
              <TextThemeUI variant="styles.p">
                {t({
                  id: 'Credit at {brandName}:',
                  values: {
                    brandName: brandName,
                  },
                })}{' '}
                <strong>{`${formatPrice(credit, locale)}.`}</strong>
              </TextThemeUI>
            )}
            {!!discountPercentage && (
              <TextThemeUI variant="styles.p">
                <Trans id="Discount:" />{' '}
                <strong>{`${discountPercentage}%`}</strong>
              </TextThemeUI>
            )}
          </Stack>
          {(card || vippsBilling) && (
            <ButtonGroup>
              <Button variant="buttons.secondary" onClick={onToggleCardDialog}>
                <Trans>Change payment method</Trans>
              </Button>
              {possiblePaymentIntervalsList &&
                possiblePaymentIntervalsList.length > 0 && (
                  <Button
                    variant="buttons.secondary"
                    onClick={togglePaymentIntervalDialog}
                  >
                    <Trans>Change payment interval</Trans>
                  </Button>
                )}
            </ButtonGroup>
          )}
        </Grid>
        {isCardDialogOpen && (
          <Modal open={isCardDialogOpen} onClose={onToggleCardDialog}>
            <ChangePaymentMethodDialog
              onVerify={onVerify}
              enableVipps={enableVipps}
              errorMessage={cardDialogError}
              setErrorMessage={setCardDialogError}
              isLoading={isRequesting}
              onCancel={onToggleCardDialog}
              onSetupVipps={() => {
                setCardDialogError('')
                onSetupVipps()
              }}
            />
          </Modal>
        )}
        {possiblePaymentIntervalsList && (
          <ChangePaymentIntervalDialog
            isOpen={isPaymentIntervalDialogOpen}
            onVerify={onPaymentIntervalChange}
            onCancel={togglePaymentIntervalDialog}
            isVippsBilling={isVippsBilling}
            onSetupVipps={next => onSetupVipps(next)}
            paymentIntervalList={possiblePaymentIntervalsList}
            selectedInterval={subscriptionInterval}
            isLoading={isRequesting}
          />
        )}
      </Card>
    )
  },
)
