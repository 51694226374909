import type { BoxPropsAs } from '@jotta/ui/Box'
import { Box } from '@jotta/ui/Box'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'
import type { HeadLineProps } from '../../Typography/Headline/Headline'
import { Headline, Headline6 } from '../../Typography/Headline/Headline'
import { useAnimateHiddenClass } from '../../utils/animation'
import clsx from 'clsx'

export type CardProps = BoxPropsAs<
  'div',
  {
    secondaryTitle?: ReactNode
  } & HeadLineProps,
  'all'
>
export const Card = forwardRef<HTMLDivElement, CardProps>(function Card(
  {
    variant = 'cards.primary',
    tag = 'h2',
    level = 2,
    title,
    secondaryTitle,
    children,
    className,
    hidden,
    ...props
  },
  ref,
) {
  return (
    <Box
      variant={variant}
      ref={ref}
      className={clsx(useAnimateHiddenClass(hidden), className)}
      {...props}
    >
      {title && (
        <hgroup>
          {!!secondaryTitle && <Headline6 pb="0">{secondaryTitle}</Headline6>}
          <Headline
            pb={2}
            sx={{
              marginBlockStart: 0,
            }}
            tag={tag}
            level={level}
          >
            {title}
          </Headline>
        </hgroup>
      )}
      {children}
    </Box>
  )
})
