import * as Popover from '@radix-ui/react-popover'
import type { PropsWithChildren } from 'react'
import { useEffect, useRef, useState } from 'react'
import { useKey, useKeyPressEvent } from 'react-use'

export interface AddressBookEntry {
  username: string
  email: string
  fullName: string
}

export interface AddressBookProperties {
  list?: AddressBookEntry[]
  open?: boolean
  onOpenChange: (open: boolean) => void
  onSelect?: (entry?: AddressBookEntry) => void
  onKeyHighlight?: (entry?: AddressBookEntry) => void
}

export function AddressBookDropdown({
  list = [],
  children,
  open = false,
  onOpenChange,
  onSelect,
  onKeyHighlight,
}: PropsWithChildren<AddressBookProperties>) {
  const [selected, setSelected] = useState(-1)
  const contentRef = useRef<HTMLDivElement>(null)

  useKey(
    'ArrowUp',
    e => {
      if (!list.length || !open) {
        return
      }

      const next = selected < -1 ? list.length : selected - 1
      setSelected(next)
      if (onKeyHighlight) {
        onKeyHighlight(next > -1 ? list[next] : undefined)
      }
      e.preventDefault()
      contentRef.current?.children[next].scrollIntoView({ block: 'nearest' })
    },
    undefined,
    [list, selected, open],
  )

  useKey(
    'ArrowDown',
    e => {
      if (!list.length || !open) {
        return
      }

      const next = selected + 1 === list.length ? -1 : selected + 1
      setSelected(next)
      if (onKeyHighlight) {
        onKeyHighlight(next > -1 ? list[next] : undefined)
      }
      e.preventDefault()
      contentRef.current?.children[next].scrollIntoView({ block: 'nearest' })
    },
    undefined,
    [list, selected, open],
  )

  const submit = (i: number) => {
    if (onSelect) {
      onSelect(i > -1 ? list[i] : undefined)
    }
    onOpenChange(false)
  }

  useKeyPressEvent('Enter', e => {
    if (open && list.length) {
      e.preventDefault()
      submit(selected)
    }
  })

  const isOpen = list.length > 0 && open

  // Clear selection on list change and open change
  useEffect(() => {
    setSelected(-1)
  }, [isOpen, list])

  return (
    <Popover.Root open={isOpen} onOpenChange={onOpenChange} modal={false}>
      <Popover.Trigger
        asChild
        onClick={e => {
          // Don't toggle
          onOpenChange(true)
          e.preventDefault()
        }}
        onChange={() => {
          onOpenChange(true)
        }}
      >
        {children}
      </Popover.Trigger>
      <Popover.Content
        onOpenAutoFocus={e => e.preventDefault()}
        sideOffset={10}
        ref={contentRef}
        id="POPOVER-ID"
        onMouseDown={e => {
          e.preventDefault()
        }}
        onMouseLeave={() => setSelected(-1)}
        sx={{
          width: 'calc(var(--radix-popover-trigger-width) + 2 * var(--s3))',
          position: 'relative',
          maxHeight: '198px',
          border: '1px solid rgb(218, 218, 218)',
          boxShadow: '0 2px 8px 1px rgba(64,60,67,.24)',
          backgroundColor: 'white',
          borderRadius: '12px',
          fontSize: 2,
          overflowY: 'auto',
          overflowX: 'hidden',
          zIndex: 10,
        }}
      >
        <div>
          {list.map((entry, i) => (
            <button
              key={entry.username}
              sx={{
                display: 'block',
                lineHeight: '20px',
                width: '100%',
                textAlign: 'left',
                fontSize: 3,
                px: 3,
                py: 2,
                color: 'var(--color-text)',
                background: selected === i ? 'rgb(240, 240, 240)' : 'white',
                fontWeight: 'light',
              }}
              onMouseMove={() => {
                setSelected(i)
              }}
              onClick={() => submit(i)}
            >
              <span sx={{ display: 'block' }}>{entry.fullName}</span>
              <span sx={{ display: 'block', color: '#666', fontSize: 1 }}>
                {entry.email}
              </span>
            </button>
          ))}
        </div>
      </Popover.Content>
    </Popover.Root>
  )
}
