export class Mutex {
  private queue: Array<() => void> = []
  private locked: boolean = false

  public acquire() {
    return new Promise<void>(resolve => {
      if (!this.locked) {
        this.locked = true
        resolve()
      } else {
        this.queue.push(resolve)
      }
    })
  }

  public release() {
    if (!this.locked) {
      throw new Error("Call to mutex.release() while mutex isn't locked")
    }

    if (this.queue.length) {
      this.queue[0]()
      this.queue.shift()
    } else {
      this.locked = false
    }
  }

  public get isLocked() {
    return this.locked
  }
}
