import { useUsername } from '@jotta/grpc-connect-client/customer'
import type { Photos } from '@jotta/types/Photos'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import { useCallback, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { usePhotoStore } from '../../store/PhotoContext'
import { useAddPhotoMode } from '../PhotoGallery/useAddPhotoMode'
import type { MediaCarouselProps } from './MediaCarousel'
import { MediaCarousel } from './MediaCarousel'

const debug = Debug('jotta:photos:MediaCarousel')

function useNavigateBack(mediaId?: string) {
  const navigate = useNavigate()
  // If no mediaId when mounted, we can safely navigate back to timeline/album
  const canNavigateBack = useRef(!mediaId)

  return useCallback(
    (open: boolean) => {
      if (!open) {
        debug('close')
        if (window.history.length > 1 && canNavigateBack.current && mediaId) {
          navigate(-1)
        } else {
          navigate('./', { replace: true })
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mediaId],
  )
}

export const MediaCarouselContainer = observer<{
  mediaId?: string
  items?: readonly Photos.Media[]
  isPublic?: boolean
  actionContext?: Photos.MediaCarouselActionContexts
  showOwnerName?: boolean
  showSimilarSearch?: boolean
  navOnQueryParam?: string
  onClose?: (closed: boolean) => void
}>(function MediaCarouselContainer({
  mediaId,
  items = [],
  isPublic,
  actionContext,
  showOwnerName,
  showSimilarSearch = false,
  navOnQueryParam,
  onClose,
}) {
  const store = usePhotoStore()
  const username = useUsername()
  const navigateBack = useNavigateBack(mediaId)
  const open = Boolean(mediaId)
  const hidden = store.galleryType === 'TIMELINE_HIDDEN'
  const [addPhotoMode] = useAddPhotoMode()

  const carouselProps: MediaCarouselProps = {
    open,
    actionHandlerProps: {
      albumId: store.currentAlbumId,
      album: store.currentAlbum?.data,
      username,
      isAlbumSubscriber: store.currentAlbum?.isSubscriber,
    },
    actionContext:
      actionContext ||
      (isPublic
        ? 'SHARED_ALBUM_VIEW_PHOTO'
        : hidden
          ? 'TIMELINE_HIDDEN_PHOTOS_VIEW_PHOTO'
          : addPhotoMode
            ? 'ALBUM_VIEW_PHOTO'
            : 'TIMELINE_PHOTOS_VIEW_PHOTO'),
    items,
    currentMediaId: mediaId,
    slideShowMode: false,
    onClose: onClose || navigateBack,
    showOwnerName,
    showSimilarSearch,
    navOnQueryParam,
  }

  if (!mediaId) {
    return null
  }

  return <MediaCarousel {...carouselProps} />
})
