import type { PhotosApi } from '@jotta/types/PhotosApi'
import { RadixDialog } from '@jotta/ui/RadixDialog'
import type { RadixModalProps } from '@jotta/ui/RadixModal'
import { RadixModal } from '@jotta/ui/RadixModal'
import { filesize } from '@jotta/utils/filesize'
import { Trans, t } from '@lingui/macro'
import type { FC } from 'react'

export interface DownloadPhotosDialogProps extends RadixModalProps {
  downloadState?: PhotosApi.DownloadPhotosInfo
}

export const DownloadPhotosDialog: FC<DownloadPhotosDialogProps> = ({
  downloadState: { totalFileSize = 0, state, fileName = '', urls = [] } = {
    state: 'DISCOVERY',
    totalFileSize: 0,
    fileName: '',
    urls: [],
  },
  onClose,
  ...props
}) => {
  const readableFileSize = filesize(totalFileSize)
  const hasCompleted = state === 'COMPLETED'
  return (
    <RadixModal onClose={onClose} {...props}>
      <RadixDialog
        title={t`Download`}
        onClose={onClose}
        buttons={[
          {
            action: 'close',
            label: t`Close`,
            visible: hasCompleted,
          },
        ]}
      >
        {hasCompleted ? (
          <p>
            <Trans>Discovered {readableFileSize} of photos...</Trans>
          </p>
        ) : (
          <p>
            <Trans>
              Discovered {readableFileSize} of photos. Your download will start
              soon.
            </Trans>
          </p>
        )}
      </RadixDialog>
    </RadixModal>
  )
}
