import type { BrandStore } from '@jotta/ui/BrandStore'
import Debug from 'debug'
import { makeAutoObservable } from 'mobx'
import { PhotoUploadCollection } from '../api/PhotoUploadCollection'
import { AlbumRootStore } from './AlbumRootStore'
import { MediaObjectStore } from './MediaObjectStore'
import { PhotoActionStore } from './PhotoActionStore'
import { PhotoSearchStore } from './PhotoSearchStore'
import { PhotoSearchStore as PhotoSearchStore2 } from '../search/internal/PhotoSearchStore'
import { SelectionStore } from './SelectionStore'
import { TimelineStore } from './TimelineStore'

const debug = Debug('jotta:photos:PhotoStore')

export class PhotoStore {
  timeline: TimelineStore
  branding: BrandStore
  upload: PhotoUploadCollection
  private _selection: SelectionStore
  mediaObjects: MediaObjectStore
  albumRoot: AlbumRootStore
  actions: PhotoActionStore
  search: PhotoSearchStore
  search2: PhotoSearchStore2
  private _isPublicRoute = false
  private _galleryType: 'TIMELINE' | 'TIMELINE_HIDDEN' | 'ALBUM' | '' = ''

  public get selection() {
    return this._selection
  }

  public set selection(selection: SelectionStore) {
    this._selection = selection
  }

  public get galleryType() {
    return this._galleryType
  }

  public set galleryType(type: typeof this._galleryType) {
    this._galleryType = type
  }
  public get currentAlbum() {
    return this.albumRoot.getAlbum(this._currentAlbumId)
  }
  public get currentAlbumId() {
    return this._currentAlbumId
  }

  public set currentAlbumId(albumId) {
    debug('set albumId', albumId)
    this._currentAlbumId = albumId
  }
  private _currentAlbumId = ''
  public get isPublicRoute() {
    return this._isPublicRoute
  }
  public set isPublicRoute(value) {
    if (this._isPublicRoute !== value) {
      this._isPublicRoute = value
    }
  }

  public get photoIds() {
    if (this.currentAlbum) {
      return this.currentAlbum.photoIds
    }
    return this.timeline.photoIds
  }

  private _username: string

  public get username() {
    return this._username
  }

  public set username(username: string) {
    this._username = username
  }

  public get isAuthenticated() {
    return Boolean(this.username)
  }

  constructor(brandStore: BrandStore, username: string) {
    debug('Init photoStore')
    this._username = username
    this.branding = brandStore
    this.mediaObjects = new MediaObjectStore(this)
    this.timeline = new TimelineStore(this)
    this._selection = new SelectionStore(this)
    this.albumRoot = new AlbumRootStore(this)
    this.actions = new PhotoActionStore(this)
    this.upload = new PhotoUploadCollection(this)
    this.search = new PhotoSearchStore(this)
    this.search2 = new PhotoSearchStore2('photoStore')
    makeAutoObservable(this, {}, { autoBind: true })
  }
}
