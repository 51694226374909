import { useFeatures } from '@jotta/grpc-connect-client/config'
import { useUsername } from '@jotta/grpc-connect-client/customer'
import { observer } from 'mobx-react-lite'
import { useRef, useState } from 'react'
import { usePhotoStore } from '../../store/PhotoContext'
import { waitlistStore } from '../../store/WaitlistStore'
import { MediaCarouselContainer } from '../MediaCarousel/MediaCarouselContainer'
import { NoTimelinePhotos } from '../NoTimelinePhotos/NoTimelinePhotos'
import type { PhotoActionContextType } from '../PhotoActions/PhotoActionContext.types'
import { SelectionManager } from '../SelectionManager/SelectionManager'
import { TimelineScrubber } from '../TimelineScrubber/TimelineScrubber'
import { Timeline } from './Timeline'
import { useAddPhotoMode } from './useAddPhotoMode'
import type { GalleryRef } from './useGroupByDayAndRow'
import { TimelineV2 } from './timelinev2/TimelineV2'
import type { Photos } from '@jotta/types/Photos'

export const TimelineGallery = observer<{
  mediaId?: string
  hidden?: boolean
}>(function TimelineGallery({ mediaId, hidden = false }) {
  const ref = useRef<GalleryRef>(null)
  const photoStore = usePhotoStore()
  const features = useFeatures()
  const username = useUsername()

  const [addPhotoMode] = useAddPhotoMode()
  const actionContext: PhotoActionContextType = addPhotoMode
    ? 'ADD_PHOTOS_TO_ALBUM_SELECTED_PHOTOS'
    : hidden
      ? 'TIMELINE_HIDDEN_PHOTOS_SELECTED_PHOTOS'
      : addPhotoMode
        ? 'ALBUM_VIEW_SELECTED_PHOTOS'
        : 'TIMELINE_PHOTOS_SELECTED_PHOTOS'
  const showSimilarSearch =
    !hidden && features.photoSearch && waitlistStore.isActive

  const [photosV2, setPhotosV2] = useState<Photos.Media[]>([])

  const { timelineV2 } = useFeatures()

  if (timelineV2) {
    return (
      <>
        <TimelineV2 onPhotos={setPhotosV2} />
        <SelectionManager
          username={username}
          addPhotoMode={addPhotoMode}
          actionContext={actionContext}
          getDownloadInfo={photoStore.timeline.getDownloadInfo}
        />
        <MediaCarouselContainer
          mediaId={mediaId}
          items={photosV2}
          showSimilarSearch={showSimilarSearch}
        />
      </>
    )
  }

  const { isLoading, isHidden, photos } = photoStore.timeline
  const showWaitinglistBanners = !hidden && features.photoSearch && !isLoading

  return (
    <>
      {!isLoading && !photos.length ? (
        <NoTimelinePhotos hidden={isHidden} />
      ) : (
        <Timeline
          ref={ref}
          photos={photos}
          showWaitinglistBanners={showWaitinglistBanners}
        />
      )}
      {photos.length > 0 && <TimelineScrubber />}
      <SelectionManager
        username={username}
        addPhotoMode={addPhotoMode}
        actionContext={actionContext}
        getDownloadInfo={photoStore.timeline.getDownloadInfo}
      />
      <MediaCarouselContainer
        mediaId={mediaId}
        items={photos}
        showSimilarSearch={showSimilarSearch}
      />
    </>
  )
})
