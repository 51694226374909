import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import type { PhotoActionHandlerActionProps } from './PhotoActionHandlers'

export const ActionFollowAlbum = observer<
  PhotoActionHandlerActionProps<'FOLLOW_ALBUM'>
>(function ActionFollowAlbum({
  action,
  shareId = '',
  dispatch,
  buttonVariant = 'buttons.albumActionButton',
  onActionDialogClose,
}) {
  return (
    <Link
      aria-label={action.label}
      data-testid={action.testid}
      key={action.actionType}
      to={`/share/${shareId}/follow`}
      sx={{ variant: buttonVariant }}
    >
      <PlainBrandIcon icon={action.icon} />
      <span>{action.label}</span>
    </Link>
  )
})
