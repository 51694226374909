import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { useHideFabOnMobile } from '@jotta/ui/useHideFab'
import { useHideModuleNavOnMobile } from '@jotta/ui/useHideModuleNav'
import { useScrollIntoVisualViewport } from '@jotta/hooks'
import { Button } from '@jotta/uinext/Button'
import { Trans } from '@lingui/macro'
import * as Dialog from '@radix-ui/react-dialog'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import styles from './PathItemRow.module.scss'
import type { PathItemStore } from './PathItemStore'
import { useSelectNamePart } from './useSelectNamePart'

const debug = Debug('jotta:files:PathItemRowRename')
export interface PathItemRowRenameProps {
  item: PathItemStore
}

export const PathItemRowRename = observer<PathItemRowRenameProps>(
  function PathItemRowRename({ item }) {
    const open = item.showRenameInput
    const [inputEl, setInputEl] = useState<HTMLInputElement | null>(null)

    useHideFabOnMobile(Boolean(inputEl))
    useHideModuleNavOnMobile(Boolean(inputEl))
    useSelectNamePart(inputEl)

    const virtualRowHeight = inputEl
      ? parseInt(
          getComputedStyle(inputEl).getPropertyValue('--virtual-row-height'),
          10,
        )
      : 0
    const headerHeight =
      parseInt(
        getComputedStyle(document.documentElement).getPropertyValue(
          '--header-height',
        ),
        10,
      ) || 0

    useScrollIntoVisualViewport(inputEl, {
      offsetTop: virtualRowHeight + headerHeight + 8,
      offsetBottom: 8,
    })

    return (
      <Dialog.Root open={open} onOpenChange={item.onRenameCancel}>
        <Dialog.Overlay>
          <Dialog.Content data-rename className={styles.rename}>
            <form onSubmit={item.onRenameSubmit}>
              <input
                ref={setInputEl}
                data-isfile={item.isFile}
                type="text"
                value={item.model.name}
                onChange={item.onNameInputChange}
              />
              <Button
                variant="accent"
                size="flex"
                responsive
                icon={<PlainBrandIcon icon="SvgCheckmark" />}
              >
                <Trans>Ok</Trans>
              </Button>
              <Dialog.Close asChild>
                <Button
                  variant="secondary"
                  size="flex"
                  responsive
                  icon={<PlainBrandIcon icon="SvgClose" />}
                >
                  <Trans>Cancel</Trans>
                </Button>
              </Dialog.Close>
            </form>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Root>
    )
  },
)
