import type { GetPathParams } from '@jotta/grpc-js-client/files/getPath'
import { getPath } from '@jotta/grpc-js-client/files/getPath'
import Debug from 'debug'
import { createQuery } from 'react-query-kit'
const debug = Debug('jotta:files:useFileDownloadLinkContent')

/**
 * Fetch download link as text
 * For fetching uploaded text documents
 * Returns empty string if
 */
export const useFileDownloadLinkContent = createQuery<
  string,
  Pick<GetPathParams, 'path' | 'includeDeleted' | 'token'>
>({
  queryKey: ['FileDownloadLinkContent'],
  fetcher: async params => {
    try {
      const { item } = await getPath({
        ...params,
        includeThumb: false,
        includeChildren: false,
        includeDownloadLinks: true,
      })
      const { downloadLink } = item
      if (!downloadLink) {
        return ''
      }
      const res = await fetch(downloadLink)
      const content = await res.text()
      return content
    } catch (error) {
      debug('Fetch failed', error)
      return ''
    }
  },
})
