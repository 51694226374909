import _filesize from 'filesize'

type FilesizeOptions = Parameters<typeof _filesize>[1]
const DEFAULT_OPTIONS: FilesizeOptions = {
  base: 10,
  standard: 'jedec',
  round: 1,
}

interface ResultTypeMap {
  array: [number, string]
  exponent: number
  object: {
    value: number
    symbol: string
    exponent: number
    unit: string
  }
  string: string
}
type DefaultOutput<O extends FilesizeOptions> =
  Exclude<O['output'], keyof ResultTypeMap> extends never ? never : 'string'
type CanonicalOutput<O extends FilesizeOptions> =
  | Extract<O['output'], keyof ResultTypeMap>
  | DefaultOutput<O>

export function filesize<O extends FilesizeOptions = {}>(
  bytes: number,
  options?: O,
): ResultTypeMap[CanonicalOutput<O>] {
  return _filesize<O>(bytes, {
    ...DEFAULT_OPTIONS,
    ...(options || {}),
  } as O) as ResultTypeMap[CanonicalOutput<O>]
}
