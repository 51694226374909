// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BaseAlbumsSection_albumSection__Tzwe2{position:relative;width:100%}.BaseAlbumsSection_placeHolder__PVz38{aspect-ratio:1;background-color:var(--color-gray)}.BaseAlbumsSection_rows__Z6bMT{align-items:start;display:grid;grid-auto-rows:1fr;grid-template-columns:repeat(var(--columns),minmax(0,1fr));grid-template-rows:max-content 1fr;position:relative;width:100%}.BaseAlbumsSection_rows__Z6bMT>a,.BaseAlbumsSection_rows__Z6bMT>button{border-radius:var(--radius2);display:flex;width:100%}.BaseAlbumsSection_rows__Z6bMT>a:focus-visible,.BaseAlbumsSection_rows__Z6bMT>button:focus-visible{box-shadow:0 0 0 2px var(--color-focus);outline:0}.BaseAlbumsSection_rows__Z6bMT>a[aria-selected=true],.BaseAlbumsSection_rows__Z6bMT>button[aria-selected=true]{background-color:var(--color-border)}`, "",{"version":3,"sources":["webpack://./../../libraries/photos/src/components/Album/AlbumsSection/BaseAlbumsSection.module.scss"],"names":[],"mappings":"AACA,uCAEE,iBAAA,CADA,UACA,CAGF,sCACE,cAAA,CACA,kCAAA,CAEF,+BAKE,iBAAA,CAJA,YAAA,CAGA,kBAAA,CAFA,0DAAA,CACA,kCAAA,CAIA,iBAAA,CADA,UACA,CACA,uEAEE,4BAAA,CACA,YAAA,CACA,UAAA,CACA,mGAEE,uCAAA,CADA,SACA,CAEF,+GACE,oCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"albumSection": `BaseAlbumsSection_albumSection__Tzwe2`,
	"placeHolder": `BaseAlbumsSection_placeHolder__PVz38`,
	"rows": `BaseAlbumsSection_rows__Z6bMT`
};
export default ___CSS_LOADER_EXPORT___;
