import { useLocalizedCountryList } from '@jotta/i18n/useLocalizedCountryList'
import { Card } from '@jotta/ui/Card'
import { TextField, TextFieldContainer } from '@jotta/ui/TextField'
import { Trans, t } from '@lingui/macro'
import {
  Button,
  Label,
  ListBox,
  ListBoxItem,
  Popover,
  Select,
  SelectValue,
} from 'react-aria-components'
import { useBizContext } from '../BizLayout'

export function BizCompanyInformationForm() {
  const { business } = useBizContext()
  const { countries } = useLocalizedCountryList()

  return (
    <Card variant="cards.info" title={t`Company information`}>
      <TextFieldContainer>
        <TextField
          type="text"
          name="businessname"
          defaultValue={business}
          label={t`Business name`}
          description={t`Your business name as you want it to be displayed.`}
        />
        <TextField
          type="text"
          name="organization_number"
          defaultValue="123"
          label={t`Organization number`}
          description={t`The company's official VAT number`}
          errorMessage={'Invalid VAT number'}
        />
        <Select className="col-span-full grid grid-cols-subgrid">
          <Label className="col-start-1">
            <Trans>Country</Trans>
          </Label>
          <Button className="col-start-2">
            <SelectValue />
            <span aria-hidden="true">▼</span>
          </Button>
          <Popover className="card card-dialog absolute z-10">
            <ListBox items={countries}>
              {c => <ListBoxItem textValue={c.code}>{c.value}</ListBoxItem>}
            </ListBox>
          </Popover>
        </Select>
        <button className="btn btn-primary col-start-2 justify-self-start">
          Savee
        </button>
      </TextFieldContainer>
    </Card>
  )
}
