// TODO convert into design system classnames
import { cn } from '@jotta/utils/css'
import { t, Trans } from '@lingui/macro'

function strengthLabel(strength: number) {
  switch (strength) {
    case 0:
      return t`weak`
    case 1:
      return t`medium`
    case 2:
      return t`strong`
    case 3:
      return t`very strong`
  }
}

function Indicator({ index, strength }: { index: number; strength: number }) {
  return (
    <div
      className={cn({
        'h-1 w-full rounded-sm': true,
        'bg-danger-fg': strength === 0,
        'bg-warning-fg': strength === 1,
        'bg-signup-strong-password': strength === 2,
        'bg-good-fg': strength === 3,
        'bg-grey6': index > strength,
      })}
    />
  )
}

export function PasswordStrengthIndicator({ password }: { password?: string }) {
  const length = password?.length || 0
  const uniqueCount = new Set(password).size
  const strength =
    length > 0 ? Math.round(Math.min((length + uniqueCount) / 24, 3)) : -1

  return (
    <div>
      <div className="flex gap-4">
        <Indicator index={0} strength={strength} />
        <Indicator index={1} strength={strength} />
        <Indicator index={2} strength={strength} />
        <Indicator index={3} strength={strength} />
      </div>
      <div className="py-3">
        {length > 0 && (
          <>
            <span className="pr-2 font-semibold">
              <Trans>Password strength</Trans>
            </span>
            {strengthLabel(strength)}
            {strength < 3 && (
              <div className="body-sm-ln mt-3 bg-signup-password-box p-4">
                <Trans>
                  Tip: make your password longer to improve security
                </Trans>
              </div>
            )}
          </>
        )}
        &nbsp;
      </div>
    </div>
  )
}
