import type { FileTransferParams } from '@jotta/grpc-js-client/fileService'
import { createFolder } from '@jotta/grpc-js-client/fileService'
import {
  CopyResponse,
  MoveResponse,
} from '@jotta/grpc-web/no/jotta/openapi/file/file.v2_pb'
import { useObjectKeys } from '@jotta/hooks'
import { queryClient } from '@jotta/query'
import type { AppError } from '@jotta/types/AppError'
import { handleUnknownError } from '@jotta/types/AppError'
import { isGrpcApiError } from '@jotta/types/GrpcApiError'
import {
  isCopyErrorMessage,
  isMoveErrorMessage,
} from '@jotta/types/decodeErrorMessage'
import { RadixDialog } from '@jotta/ui/RadixDialog'
import type { RadixDialogToastProps } from '@jotta/ui/RadixDialogToast'
import { RadixDialogToast } from '@jotta/ui/RadixDialogToast'
import type { RadixModalProps } from '@jotta/ui/RadixModal'
import { RadixModal } from '@jotta/ui/RadixModal'
import { createPath, popPath } from '@jotta/utils/pathUtils'
import { createEnum } from '@jotta/utils/schema'
import { plural, t } from '@lingui/macro'
import { useMutation } from '@tanstack/react-query'
import Debug from 'debug'
import { observable } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { useCallback, useEffect, useState } from 'react'
import type { z } from 'zod'
import { FileTree, usePathItemFolder } from '../../FileTree/FileTree'
import type { FileActionStore } from '../FileActionStore'
import { caseInsensitiveIncludes } from '@jotta/utils/array'

const debug = Debug('jotta:files:FileTransferDialog')

function isMoveError(error: MoveResponse.MoveError) {
  return (e: unknown) =>
    isGrpcApiError(e) &&
    isMoveErrorMessage(e.decodedError) &&
    e.decodedError.toObject().moveError === error
}

function isCopyError(error: CopyResponse.CopyError) {
  return (e: unknown) =>
    isGrpcApiError(e) &&
    isCopyErrorMessage(e.decodedError) &&
    e.decodedError.toObject().copyError === error
}

function isBetweenUsersError(e: unknown) {
  return (
    isMoveError(MoveResponse.MoveError.MOVE_BETWEEN_USERS)(e) ||
    isCopyError(CopyResponse.CopyError.COPY_BETWEEN_USERS)(e)
  )
}

export type FileTransferType = 'COPY' | 'MOVE'

const labels: Record<
  FileTransferType,
  {
    button: string
    verb: string
    title: string
    smallTitle: string
  }
> = {
  get COPY() {
    return {
      button: t`Copy to folder`,
      verb: t`Copying`,
      title: t`Select the folder you want to copy the files to.`,
      smallTitle: t`Copy`,
    }
  },
  get MOVE() {
    return {
      button: t`Move to folder`,
      verb: t`Moving`,
      title: t`Select the folder you want to move the files to.`,
      smallTitle: t`Move`,
    }
  },
}

export const { isLoadingState, loadingStateSchema, loadingStates } = createEnum(
  'loadingState',
  ['ready', 'navigating', 'creating-folder', 'transferring'],
)
export type LoadingState = z.infer<typeof loadingStateSchema>

export type FileTransferDialogProps = RadixModalProps &
  Omit<FileTransferParams, 'targetDirectoryPath'> & {
    error: AppError | null
    clearError: (open?: boolean) => void
    transferFn: FileActionStore['transferItems']
    onFolderCreated?: (path: string, name: string) => void
  }

type Toast = Pick<RadixDialogToastProps, 'title' | 'severity'>

export const FileTransferDialog = observer<FileTransferDialogProps>(
  function FileTransferDialog({
    error: legacyError,
    clearError,
    transferType,
    filePaths,
    onFileTransferred,
    transferFn,
    onFolderCreated,
    ...props
  }) {
    const sourceDirectoryPaths = filePaths.map(v =>
      popPath(v).toLocaleLowerCase(),
    )
    const [targetDirectoryPath, setSelectedFolder] = useState<string>('')
    const [createFolderPath, setCreateFolderPath] = useState('')
    const getKey = useObjectKeys()
    const { toasts } = useLocalObservable(() => ({
      toasts: observable.set<Toast>([]),
    }))

    const mutation = useMutation({ mutationFn: transferFn })
    const { isSuccess, error } = mutation

    useEffect(() => {
      if (!error) {
        return
      }

      const errors = Array.isArray(error) ? error : [error]
      const isShareOntoShare = isMoveError(
        MoveResponse.MoveError.MOVE_SHARE_ONTO_SHARE,
      )

      if (errors.some(isBetweenUsersError)) {
        toasts.add({
          title: t`Cannot move/copy between users`,
          severity: 'error',
        })
      }
      if (errors.some(isShareOntoShare)) {
        toasts.add({
          title: t`You can't move a shared folder into another shared folder`,
          severity: 'error',
        })
      }
      if (
        errors.filter(e => !isBetweenUsersError(e) && !isShareOntoShare).length
      ) {
        toasts.add({
          title: t`Something went wrong`,
          severity: 'error',
        })
      }

      mutation.reset()
    }, [error, props, mutation, toasts])

    useEffect(() => {
      if (!error && isSuccess && props.onClose) {
        props.onClose(false)
      }
    }, [error, isSuccess, props])

    const createFolderMutation = useMutation({
      mutationFn: async ({
        targetPath,
        newFolderName,
      }: {
        targetPath: string
        newFolderName: string
      }) => {
        if (targetPath && newFolderName) {
          await createFolder({
            path: createPath(targetPath, newFolderName),
          })
        }
      },
      onSuccess: (data, { targetPath, newFolderName }) => {
        queryClient
          .invalidateQueries({
            queryKey: usePathItemFolder.getKey({
              path: targetPath,
            }),
          })
          .then(() => {
            setSelectedFolder(`${targetPath}/${newFolderName}`)
          })
        if (onFolderCreated) {
          onFolderCreated(targetPath, newFolderName)
        }
      },
      onError(err) {
        const error = handleUnknownError(err)
        return error
      },
      onSettled: () => setCreateFolderPath(''),
    })
    const createFolderCallback = useCallback(
      (targetPath: string, newFolderName: string) => {
        createFolderMutation.mutate({ targetPath, newFolderName })
      },
      [createFolderMutation],
    )
    const isSameFolder = caseInsensitiveIncludes(
      sourceDirectoryPaths,
      targetDirectoryPath,
    )
    const count = filePaths.length

    const cancelCreateFolder = useCallback(() => {
      setCreateFolderPath('')
    }, [])

    return (
      <>
        <RadixModal {...props}>
          <RadixDialog
            toastPosition="belowcontent"
            data-testid="FileTransferDialog"
            title={labels[transferType].title}
            smallTitle={
              count === 1
                ? filePaths[0]
                : plural(count, {
                    one: '# file',
                    other: '# files',
                  })
            }
            onClose={props.onClose}
            buttons={[
              {
                action: () => setCreateFolderPath(targetDirectoryPath),
                label: t`Create new folder`,
                variant: 'buttons.secondary',
                disabled: !targetDirectoryPath,
              },
              {
                action: 'close',
                label: t`Cancel`,
                variant: 'buttons.secondary',
              },
              {
                action: () =>
                  mutation
                    .mutateAsync({
                      targetDirectoryPath,
                      transferType,
                    })
                    .catch(() => {}),
                loading: mutation.isPending,
                label: labels[transferType].button,
                variant: 'buttons.primary',
                disabled: !targetDirectoryPath || isSameFolder,
                testId: 'ok',
              },
            ]}
          >
            <p>From folder: {[...new Set(sourceDirectoryPaths)].join(', ')}</p>
            <p>To folder: {targetDirectoryPath}</p>
            <FileTree
              onSelectFolder={setSelectedFolder}
              selectedPath={targetDirectoryPath}
              path="/archive"
              createFolderPath={createFolderPath}
              onCancelCreateFolder={cancelCreateFolder}
              onCreateFolder={createFolderCallback}
            />
            <FileTree
              onSelectFolder={setSelectedFolder}
              selectedPath={targetDirectoryPath}
              initiallyExpanded
              path="/sync"
              createFolderPath={createFolderPath}
              onCancelCreateFolder={cancelCreateFolder}
              onCreateFolder={createFolderCallback}
            />
            {Array.from(toasts.values()).map(toast => (
              <RadixDialogToast
                key={getKey(toast)}
                open
                title={toast.title}
                severity={toast.severity}
                onOpenChange={open => {
                  if (!open) {
                    toasts.delete(toast)
                  }
                }}
              />
            ))}
          </RadixDialog>
        </RadixModal>
      </>
    )
  },
)
