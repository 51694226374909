import { Box } from '@jotta/ui/Box'
import { useScroll } from '@jotta/ui/useScroll'
import { observer } from 'mobx-react-lite'
import { getFixedVirtualRowHeightPositions } from './getSingleColumnPositions'
export interface SingleColumnVirtualListProps<T = unknown> {
  items: T[]
  rowHeight?: number
  getItemKey: (item: T) => string
  renderItem: (index: number) => JSX.Element | null
}

export function getVisibleIndexes(
  rowHeight: number,
  viewPortHeight: number,
  scrollY: number,
  overscan = 10,
): {
  first: number
  last: number
} {
  return {
    first: Math.max(Math.floor(scrollY / rowHeight) - overscan, 0),
    last: Math.max(
      Math.floor((scrollY + viewPortHeight) / rowHeight) + overscan,
      0,
    ),
  }
}
export const SingleColumnVirtualList = observer(
  function SingleColumnVirtualList<T>({
    items,
    rowHeight = 50,
    renderItem,
    getItemKey,
  }: SingleColumnVirtualListProps<T>) {
    const { height, scrollY } = useScroll()
    const p = getFixedVirtualRowHeightPositions(items.length, rowHeight)
    const idx = getVisibleIndexes(rowHeight, height, scrollY)
    return (
      <Box
        sx={{
          position: 'relative',
          height: `${p.height}px`,
          background:
            'linear-gradient(transparent calc(100% - 1px), lightgray)',
          backgroundSize: `100% ${rowHeight}px`,
          backgroundRepeat: `space space`,
          width: '100%',
          '& > div': {
            position: 'absolute',
            width: '100%',
            left: '0',
            height: `${rowHeight}px`,
            contain: 'content',
          },
        }}
      >
        {p.positions.slice(idx.first, idx.last).map(pos => (
          <div
            key={getItemKey(items[pos.index])}
            style={{
              top: `${pos.top}px`,
            }}
          >
            {renderItem(pos.index)}
          </div>
        ))}
      </Box>
    )
  },
)
