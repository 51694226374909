import { getDownloadLink } from '@jotta/grpc-js-client/fileService'
import { AppError, handleUnknownError } from '@jotta/types/AppError'
import type { PathItemObject } from '@jotta/types/Files'
import { useQuery } from '@tanstack/react-query'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import styles from './PdfView.module.scss'
import { useAuthStatus } from '@jotta/auth-client/useAuthStatus'
const debug = Debug('jotta:ui:PdfView')
const pdfSupport = isPdfSupported()
const pdfPath =
  process.env.PDF_VIEWER_STATIC_PATH ||
  '/webapp_static/pdf-viewer/web/viewer.html'
export interface PdfViewProps {
  url: string
  forcePdfJs?: boolean
}
export const PdfView = observer<PdfViewProps>(function PdfView({
  url,
  forcePdfJs = false,
}) {
  if (pdfSupport && !forcePdfJs) {
    debug('Render embed', url)
    return (
      <embed
        className={styles.embed}
        type="application/pdf"
        src={url}
        width="100%"
        height="100%"
      ></embed>
    )
  }
  debug('Render iframe', url)
  return (
    <iframe
      title="PDF viewer"
      className={styles.embed}
      src={`${pdfPath}?file=${encodeURIComponent(url)}`}
      width="100%"
      height="100%"
    ></iframe>
  )
})

interface FilePdfViewProps {
  file: PathItemObject
  forcePdfJs?: boolean
  isActive?: boolean
  fetchDownloadLink?: typeof getDownloadLink
  token?: string
}

export const FilePdfView = observer<FilePdfViewProps>(function FilePdfView({
  file,
  forcePdfJs = false,
  isActive = false,
  fetchDownloadLink = getDownloadLink,
  token,
}) {
  const auth = useAuthStatus()
  const accessToken =
    file.token?.accessToken || token || auth.data?.accessToken || ''
  let downloadLink = createPdfUrl(file, accessToken)
  if (isActive) {
    debug(downloadLink.length, downloadLink)
  }
  const query = useQuery({
    enabled: isActive && !downloadLink,
    queryKey: ['downloadLink', file.path, !!file.deletedAtMillis] as const,
    retry: false,
    queryFn: async () => {
      debug('Missing downloadLink, fetching...')
      const downloadLink = await fetchDownloadLink({
        path: file.path,
        includeDeleted: !!file.deletedAtMillis,
      })
      const pdfUrl = createPdfUrl(
        {
          ...file,
          downloadLink,
        },
        accessToken,
      )
      if (!pdfUrl) {
        throw new AppError({
          message: 'Failed to create PDF url',
        })
      }
      const res = await fetch(pdfUrl, {
        method: 'GET',
      })
      if (!res.ok) {
        throw new AppError({
          message: `Invalid PDF url ${pdfUrl} ${res.status}`,
          HTTPStatus: res.status,
        })
      }
      return downloadLink
    },
    throwOnError: false,
  })
  if (query.data) {
    downloadLink = query.data
  }
  if (downloadLink) {
    return <PdfView url={downloadLink} forcePdfJs={forcePdfJs} />
  }
  if (query.error) {
    return <p>{handleUnknownError(query.error).message}</p>
  }
  return null
})

function createPdfUrl(file: PathItemObject, token: string) {
  if (!file.downloadLink) {
    return ''
  }
  if (file.downloadLink.startsWith('/')) {
    if (file.downloadLink.endsWith('/')) {
      return file.downloadLink + file.name
    }
    return `${file.downloadLink}/${file.name}`
  }
  const url = new URL(file.downloadLink)
  url.pathname = `${url.pathname}/${encodeURIComponent(file.name)}`
  debug('URL token', url.searchParams.get('access_token')?.substring(0, 32))

  if (!url.searchParams.has('access_token')) {
    url.searchParams.set('access_token', token)
  }
  url.searchParams.set('inlineOverride', 'true')
  url.searchParams.set('contentOverride', 'application/pdf')

  return url.href
}

function isPdfSupported() {
  // as of iOS 12, inline PDF rendering is still not supported in Safari or native webview
  // 3rd-party browsers (eg Chrome, Firefox) use Apple's webview for rendering, and thus the same result as Safari
  // Therefore if iOS, we shall assume that PDF support is not available
  if (/iphone|ipad|ipod/i.test(window.navigator.userAgent.toLowerCase())) {
    return false
  }

  // Browsers that still support the original MIME type check
  if (navigator.mimeTypes && navigator.mimeTypes.namedItem('application/pdf')) {
    return true
  }

  // Firefox started shipping PDF.js in Firefox 19.
  // If this is Firefox 19 or greater, assume PDF.js is available
  if (/firefox/i.test(window.navigator.userAgent.toLowerCase())) {
    // parse userAgent string to get release version ("rv")
    // ex: Mozilla/5.0 (Macintosh; Intel Mac OS X 10.12; rv:57.0) Gecko/20100101 Firefox/57.0
    return (
      parseInt(window.navigator.userAgent.split('rv:')[1].split('.')[0], 10) >
      18
    )
  }
}
