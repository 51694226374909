/* eslint-disable jsx-a11y/label-has-associated-control */
import { TextField, TextFieldContainer } from '@jotta/ui/TextField'
import { Trans, t } from '@lingui/macro'
import type { FieldApi } from '@tanstack/react-form'
import { useForm } from '@tanstack/react-form'
import { useBizContext } from '../BizLayout'

export function BizSubscriptionForm() {
  const { business } = useBizContext()
  const form = useForm({
    defaultValues: {
      businessname: business,
      organization_number: '123',
    },
  })
  return (
    <form className="card">
      <h2 className="card-title">{t`Subscription`}</h2>
      <TextFieldContainer>
        <form.Field
          name="businessname"
          validators={{
            onBlur: value => (!value ? t`This field is required` : undefined),
          }}
        >
          {f => (
            <>
              <label htmlFor={f.name}>
                <Trans>Business name</Trans>
              </label>
              <div>
                <input
                  type="text"
                  id={f.name}
                  name={f.name}
                  value={f.state.value}
                  onChange={e => f.handleChange(e.target.value)}
                  onBlur={f.handleBlur}
                />
                <div>
                  <Trans>
                    Your business name as you want it to be displayed.
                  </Trans>
                </div>
                <FieldInfo field={f} />
              </div>
            </>
          )}
        </form.Field>
        <TextField
          type="text"
          name="organization_number"
          defaultValue="123"
          label={t`Organization number`}
          description={t`The company's official VAT number`}
        />
      </TextFieldContainer>
    </form>
  )
}

function FieldInfo({ field }: { field: FieldApi<any, any, any, any> }) {
  return (
    <>
      {field.state.meta.touchedErrors ? (
        <em>{field.state.meta.touchedErrors}</em>
      ) : null}
      {field.state.meta.isValidating ? 'Validating...' : null}
    </>
  )
}
