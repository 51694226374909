// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NoFilesResults_container__OS1--{align-items:center;display:flex;flex-direction:column;height:100%;justify-content:flex-start;padding-top:100px;text-align:center;width:100%}.NoFilesResults_defaultSvg__I0xxf{background-position:50%;height:138px;width:136px}.NoFilesResults_title__MBSD-{color:var(--color-grey-files-text);font-size:var(--fz5);font-weight:var(--fw-light);line-height:29px;margin:0;padding:32px 0 25px}`, "",{"version":3,"sources":["webpack://./../../libraries/files/src/FileSearch/NoFilesResults.module.scss"],"names":[],"mappings":"AACA,iCAGE,kBAAA,CAFA,YAAA,CAGA,qBAAA,CACA,WAAA,CAEA,0BAAA,CACA,iBAAA,CANA,iBAAA,CAIA,UAEA,CAGF,kCAGE,uBAAA,CADA,YAAA,CADA,WAEA,CAGF,6BACE,kCAAA,CAEA,oBAAA,CADA,2BAAA,CAIA,gBAAA,CADA,QAAA,CADA,mBAEA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `NoFilesResults_container__OS1--`,
	"defaultSvg": `NoFilesResults_defaultSvg__I0xxf`,
	"title": `NoFilesResults_title__MBSD-`
};
export default ___CSS_LOADER_EXPORT___;
