import { ItemAction } from '@jotta/grpc-web/no/jotta/openapi/file/file.v2_pb'
import { z } from 'zod'
import { createEnum } from './schemaUtils'
import { invert, isNumber } from 'remeda'
import moize from 'moize'

export type ItemActionKey = keyof typeof ItemAction
export const ItemActionSchema = z.nativeEnum(ItemAction)
export const itemActionEntries = Object.entries(ItemAction)
  .filter((entry): entry is [ItemActionKey, ItemAction] => isNumber(entry[1]))
  .sort((a, b) => a[0].localeCompare(b[0]))
const ItemActionObj: Record<ItemActionKey, ItemAction> = { ...ItemAction }
const ItemActionReverse: Record<ItemAction, ItemActionKey> =
  invert(ItemActionObj)
export const getItemActionKey = moize(
  (Action: ItemAction = ItemAction.UNKNOWN_FILE_ACTION): ItemActionKey =>
    ItemActionReverse[Action] || 'UNKNOWN_FILE_ACTION',
)
export const getItemActionByKey = moize(
  (key: ItemActionKey = 'UNKNOWN_FILE_ACTION'): ItemAction =>
    ItemActionObj[key] || ItemAction.UNKNOWN_FILE_ACTION,
)
export const { fileActionContextSchema, isFileActionContext } = createEnum(
  'FileActionContext',
  [
    'BREADCRUMB',
    'FAB',
    'FOLDER_LIST',
    'FOLDER_VIEW',
    'PUBLIC_LINKS',
    'SELECTION',
    'PENDING_INVITE_LISTITEM',
  ],
)
export const FileActionSchema = z.enum([
  'ADD_CHILD',
  'COPY',
  'CREATE_DOCUMENT',
  'CREATE_FOLDER',
  'CREATE_PRESENTATION',
  'CREATE_SPREADSHEET',
  'DELETE',
  'DISPLAY_REVISIONS',
  'DOWNLOAD',
  'RENAME',
  'MOVE',
  'SHARE',
  'UPLOAD_FILES',
  'UPLOAD_FOLDER',
  'PERMANENTLY_DELETE',
  'PUBLIC_SHARE',
  'PUBLIC_UNSHARE',
  'RESTORE',
  'UNKNOWN_FILE_ACTION',
  'UPDATE',
  'PENDING_INVITE_ACCEPT',
  'PENDING_INVITE_DECLINE',
])
