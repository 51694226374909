import { RouterLink } from '@jotta/ui/RouterLink'
import { Stack } from '@jotta/ui/Stack'
import { TextThemeUI } from '@jotta/ui/themeui'
import { Headline1 } from '@jotta/ui/Headline'
import { RadixDialog } from '@jotta/ui/RadixDialog'
import type { RadixModalProps } from '@jotta/ui/RadixModal'
import { RadixModal } from '@jotta/ui/RadixModal'
import { Trans, t } from '@lingui/macro'
import type { MouseEventHandler } from 'react'

export interface LoginToContinueDialogProps extends RadixModalProps {
  url: string
  title: string
  onClickLink?: MouseEventHandler<HTMLAnchorElement>
}

export const LoginToContinueDialog: React.FC<LoginToContinueDialogProps> = ({
  open = false,
  onClose,
  url,
  title,
  onClickLink,
  ...props
}) => {
  return (
    <RadixModal open={open} onClose={onClose} {...props}>
      <RadixDialog
        variant="dialogs.loginToContinue"
        onClose={onClose}
        data-testid="LoginToContinueDialog"
      >
        <Stack
          gap={4}
          pb="4"
          pt="2"
          sx={{
            justifyItems: 'center',
            textAlign: 'center',
            flex: '0 0 auto',
          }}
        >
          <Headline1 level={2}>{title}</Headline1>
          <RouterLink
            data-testid="LoginToContinueDialogButton"
            variant="buttons.accent"
            to={url}
            onClick={onClickLink}
            sx={{
              px: 6,
            }}
          >
            <Trans>Log in</Trans>
          </RouterLink>
          <div>
            <TextThemeUI sx={{ fontSize: 1, mt: 4, mb: 0 }}>
              {t`Don't have an account?`}
            </TextThemeUI>
            <RouterLink
              to={url}
              onClick={onClickLink}
              state={{ redirect: 'signup' }}
              variant="links.text"
            >
              <Trans>Sign up for free</Trans>
            </RouterLink>
          </div>
        </Stack>
      </RadixDialog>
    </RadixModal>
  )
}
