import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { DialogTrigger } from '@radix-ui/react-dialog'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { ShareAlbumDialog } from '../Album/Dialogs/ShareAlbumDialog'
import type { PhotoActionHandlerActionProps } from './PhotoActionHandlers'
import { useDispatchPhotoActionMutation } from './useDispatchPhotoActionMutation'
const debug = Debug('jotta:photos:action:ShareAlbum')

export const ActionShareAlbum = observer<
  PhotoActionHandlerActionProps<'SHARE_ALBUM'>
>(function ActionShareAlbum({
  action,
  album,
  shareId,
  dispatch,
  buttonVariant = 'buttons.albumActionButton',
  onActionDialogClose,
  disabled = false,
}) {
  const [open, setOpen] = useState(false)
  const shareMutation = useDispatchPhotoActionMutation(
    'SHARE_ALBUM',
    undefined,
    dispatch,
  )
  const unshareMutation = useDispatchPhotoActionMutation(
    'UNSHARE_ALBUM',
    undefined,
    dispatch,
  )
  const albumAccessMutation = useDispatchPhotoActionMutation(
    'SET_ALBUM_ACCESS',
    undefined,
    dispatch,
  )
  const albumId = album?.id || ''
  const hasWriteAccess = album?.shareInfo?.authorization === 'WRITE'
  return (
    <ShareAlbumDialog
      open={open}
      hasWriteAccess={hasWriteAccess}
      onClose={open => {
        setOpen(open)
        if (!open && onActionDialogClose) {
          onActionDialogClose(action.actionType)
        }
      }}
      onShareAlbum={async shared => {
        debug('onShareAlbum', shared)
        if (shared) {
          const res = await shareMutation.mutateAsync([albumId])
          return res
        }
        const res = await unshareMutation.mutateAsync([albumId])
        return res
      }}
      onToggleAccess={async hasWriteAccess => {
        debug('onToggleAccess', hasWriteAccess)
        const res = await albumAccessMutation.mutateAsync([
          albumId,
          hasWriteAccess ? 'WRITE' : 'READ',
        ])
        return res
      }}
      shareId={shareId}
      isLoading={shareMutation.isPending}
      triggerElement={
        <DialogTrigger
          aria-label={action.label}
          data-testid={action.testid}
          disabled={disabled}
          key={action.actionType}
          sx={{
            variant: buttonVariant,
          }}
        >
          <PlainBrandIcon icon={action.icon} />
          <span>{action.label}</span>
        </DialogTrigger>
      }
    />
  )
})
