import { Box } from '@jotta/ui/Box'
import { useFileDownloadLinkContent } from '@jotta/file-utils'
import type { PathItemObject } from '@jotta/types/Files'

export function FileTextView({
  file,
  token,
  ...props
}: {
  file: PathItemObject
  token?: string
}) {
  const { data = '' } = useFileDownloadLinkContent({
    variables: {
      path: file.path,
      includeDeleted: Boolean(file.deletedAtMillis),
      token: token || file.token?.accessToken,
    },
  })

  return (
    <Box
      p="6"
      bg="background"
      color="text"
      sx={{
        height: '100%',
        flex: '1 1 auto',
        overflow: 'auto',
        whiteSpace: 'pre-line',
      }}
      {...props}
    >
      {data}
    </Box>
  )
}
