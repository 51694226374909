import type { Person } from '@jotta/grpc-connect-openapi/people'
import { addUrlParams } from '@jotta/utils/url'
import type { ImgHTMLAttributes } from 'react'
import { forwardRef } from 'react'
import styles from './PersonImage.module.scss'

export const PersonImage = forwardRef<
  HTMLImageElement,
  {
    person: Person
    size?: string
  } & ImgHTMLAttributes<HTMLImageElement>
>(function PersonImage({ person, size = 's', ...props }, ref) {
  return (
    <img
      className={styles.image}
      ref={ref}
      src={addUrlParams(person.thumbUrl, {
        size,
      })}
      alt={person.name || ''}
      {...props}
    />
  )
})
