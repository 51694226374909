import type {
  FileRouteContext,
  FileRouteParams,
} from '@jotta/types/schemas/FileRouteSchema'
import { createPath, shiftPath } from '@jotta/utils/pathUtils'
import { Base64 } from 'js-base64'

const isRootApiPath = /^\/\w+\/?$/i
const isPhotosApiPath = /^\/photos\/?$/i
const isContactsApiPath = /^\/contacts\/?$/i

import { routeParamsToRoutePath } from './routeParamsToRoutePath'

export function apiPathToRoute(
  apiPath: string,
  {
    context: rootContext = 'sync',
    sortBy = 'name',
    viewMode = 'list',
    query,
  }: Partial<FileRouteParams> = {},
) {
  const context: FileRouteContext =
    rootContext !== 'backup'
      ? rootContext
      : isPhotosApiPath.test(apiPath)
        ? 'photos'
        : isContactsApiPath.test(apiPath)
          ? 'contacts'
          : rootContext
  let path = createPath(apiPath)
  const contextPath = createPath(context)
  const isContextApiPath =
    path.toLocaleLowerCase() === contextPath.toLocaleLowerCase()
  switch (context) {
    case 'search':
      return Base64.encode(apiPath, true)
    case 'trash':
    case 'shared':
      {
        path =
          isContextApiPath || isRootApiPath.test(apiPath)
            ? shiftPath(apiPath)
            : apiPath
      }
      break

    default:
      path = shiftPath(apiPath)
      break
  }
  return routeParamsToRoutePath({
    context,
    sortBy,
    viewMode,
    query,
    '*': path,
  })
}
