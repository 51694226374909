import { Box } from '@jotta/ui/Box'
import { Trans } from '@lingui/macro'
import { observer } from 'mobx-react-lite'
import { useBrandingInfo } from '@jotta/grpc-connect-client/config'

export const PublicFooter = observer(function PublicFooter() {
  const {
    brandName,
    brandCode = '',
    aboutUsLink,
    downloadPageLink,
    blogLink,
    termsLink,
    privacyLink,
    supportLink,
  } = useBrandingInfo()
  const showElkjopFooterText = [
    'ELKJOP',
    'ELGIGANTEN',
    'ELKO',
    'GIGANTTI',
  ].includes(brandCode)

  return (
    <Box
      sx={{ variant: 'layout.appFooterPublicContainer' }}
      as="footer"
      data-testid="footer"
    >
      <Box sx={{ variant: 'layout.publicFooterWrapper' }}>
        <Box sx={{ variant: 'links.publicFooterLink' }}>
          <a href={supportLink}>
            <Trans>Help</Trans>
          </a>
        </Box>

        {aboutUsLink && (
          <Box sx={{ variant: 'links.publicFooterLink' }}>
            <a href={aboutUsLink}>
              <Trans>About us</Trans>
            </a>
          </Box>
        )}

        <Box sx={{ variant: 'links.publicFooterLink' }}>
          <a href={downloadPageLink}>
            <Trans>Download</Trans>
          </a>
        </Box>

        {blogLink && (
          <Box sx={{ variant: 'links.publicFooterLink' }}>
            <a href={blogLink}>
              <Trans>Blog</Trans>
            </a>
          </Box>
        )}

        {termsLink && (
          <Box sx={{ variant: 'links.publicFooterLink' }}>
            <a href={termsLink}>
              <Trans>Terms &amp; Conditions</Trans>
            </a>
          </Box>
        )}

        {privacyLink && (
          <Box sx={{ variant: 'links.publicFooterLink' }}>
            <a href={privacyLink}>
              <Trans>Privacy</Trans>
            </a>
          </Box>
        )}
      </Box>

      {showElkjopFooterText && (
        <Box
          sx={{
            variant: 'styles.publicFooterText',
          }}
        >
          <Trans id="PublicFooter.elkjop">
            {brandName} is a cloud storage service that lets you backup,
            synchronize, store and share files from all your devices. The
            uploaded data is protected by one of the worlds strongest privacy
            laws, with all your data stored the Nordics.
          </Trans>
        </Box>
      )}
    </Box>
  )
})
