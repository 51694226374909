import Debug from 'debug'
import moize from 'moize'
const debug = Debug('jotta:utils:groupByAspectRatio')

export interface GroupedByAspectRatioRow<T> {
  key: string
  aspect: number
  fillRatio: number
  row: number
  items: T[]
}
export interface ItemWithSize {
  id: string
  width: number
  height: number
}

export interface GroupedByAspectRatio<T> {
  totalAspect: number
  heightPercent: number
  rowAspect: number
  rows: GroupedByAspectRatioRow<T>[]
}
function getInitialData<T>(maxRowAspect: number): GroupedByAspectRatio<T> {
  return {
    totalAspect: 0,
    heightPercent: 0,
    rowAspect: maxRowAspect,
    rows: [
      {
        key: `row-0-${maxRowAspect}`,
        row: 0,
        aspect: 0,
        fillRatio: 0,
        items: [] as T[],
      },
    ],
  }
}
export function groupByAspectRatio<T extends ItemWithSize>(
  items: T[] = [],
  maxRowAspect: number,
  fixedImageAspect?: number,
): GroupedByAspectRatio<T> {
  let currentAspect = 0
  const data = getInitialData<T>(maxRowAspect)
  const result: GroupedByAspectRatio<T> = {
    ...data,
    rows: items.reduce<GroupedByAspectRatioRow<T>[]>((acc, photo, i) => {
      // Get last row
      const currentRow = acc[acc.length - 1]

      const aspect = fixedImageAspect || photo.width / photo.height
      currentAspect += aspect

      // Always add at least one photo to a row
      if (!currentRow.items.length || currentAspect <= maxRowAspect) {
        currentRow.aspect = currentAspect
        currentRow.fillRatio = currentAspect / maxRowAspect
        currentRow.items.push(photo)
        return acc
      }

      // No more space, create new row
      acc.push({
        key: `row-${currentRow.row + 1}-${maxRowAspect}`,
        row: currentRow.row + 1,
        aspect,
        fillRatio: aspect / maxRowAspect,
        items: [photo],
      })
      currentAspect = aspect
      return acc
    }, data.rows),
  }
  result.totalAspect = result.rowAspect / result.rows.length
  result.heightPercent = (result.rows.length / result.rowAspect) * 100
  // debug(result)
  return result
}

export function groupByColumns<T>(items: readonly T[] = [], columns: number) {
  const size = items.length
  const rows = Math.ceil(size / columns)
  const data = items.map((item, i) => {
    const row = i > 0 ? Math.floor(i / columns) : 0
    const col = i % columns
    return {
      key: `row${row}-col${col}`,
      item,
      row,
      col,
      index: i,
    }
  })
  return {
    size,
    rows,
    columns,
    data,
  }
}
export const groupByAspectRatioMoized = moize(groupByAspectRatio, {
  isDeepEqual: true,
  onCacheHit: () => debug('Cache hit'),
})
