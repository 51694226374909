import { useCallback, useRef } from 'react'
import styles from './PhotoGallery.module.scss'

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

export function TouchOverlay({
  onLongPress = () => {},
  onClick,
  longPressDuration = 400,
  id,
}: {
  onLongPress?: React.TouchEventHandler
  longPressDuration?: number
  onClick?: React.MouseEventHandler
  id: string
}) {
  const timer = useRef(0)

  const startTimeout = useCallback<React.TouchEventHandler>(
    e => {
      timer.current = window.setTimeout(() => {
        onLongPress(e)
      }, longPressDuration)
    },
    [longPressDuration, onLongPress],
  )

  const cancelTimeout = useCallback<React.TouchEventHandler>(e => {
    if (timer.current) {
      window.clearTimeout(timer.current)
      timer.current = 0
    }
  }, [])

  return (
    <div
      className={styles.touchOverlay}
      onContextMenu={e => e.preventDefault()}
      onTouchStart={startTimeout}
      onTouchEnd={cancelTimeout}
      onTouchMove={cancelTimeout}
      onClick={onClick}
      data-photoid={id}
    />
  )
}
