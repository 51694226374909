import { Carousel } from '@jotta/carousel'
import { getWopiActionTypeKey, isWopi } from '@jotta/grpc-js-client/wopiService'
import { useStableRef } from '@jotta/hooks'
import type { FileRouteContext } from '@jotta/types/schemas/FileRouteSchema'
import { popPath, uriEncodeRoutePath } from '@jotta/utils/pathUtils'
import * as Dialog from '@radix-ui/react-dialog'
import Debug from 'debug'
import { Base64 } from 'js-base64'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { apiPathToRoute } from '../FileRoute/utils/apiPathToRoute'
import { WopiViewContainer } from '../FileView/WopiView/WopiViewContainer'
import type { PathItemStore } from '../PathItem/PathItemStore'
import styles from './FileCarousel.module.scss'
import { FileCarouselHeader } from './FileCarouselHeader'
import { FileCarouselView } from './FileCarouselView'
import { getFileCarouselItems } from './getFileCarouselItems'
import { useFeatures } from '@jotta/grpc-connect-client/config'
import { DisplayActiveFileActionDialog } from '../FileActions/DisplayActiveFileActionDialog'

const debug = Debug('jotta:files:FileCarousel')

function useCarouselNav(hasFile: boolean) {
  // const [canNavBack, setCanNavBack] = useState(!hasFile)
  const canNavBack = useRef(!hasFile)
  const navigate = useNavigate()
  const location = useLocation()
  const locationRef = useStableRef(location)

  if (!hasFile) {
    canNavBack.current = true
  }

  const nav = useCallback(() => {
    if (canNavBack.current) {
      navigate(-1)
    } else {
      navigate(popPath(locationRef.current.pathname), {
        replace: true,
      })
    }
  }, [navigate, locationRef])

  return nav
}

export interface FileCarouselProps extends Dialog.DialogProps {
  context?: FileRouteContext
  open?: boolean
  loading?: boolean
  file?: PathItemStore
  files?: PathItemStore[]
}
export const FileCarousel = observer<FileCarouselProps>(function FileCarousel({
  open = false,
  context = 'sync',
  file,
  files,
  children,
  loading = false,
  ...props
}) {
  const [data, setData] = useState(() => getFileCarouselItems(file, files))
  const [search] = useSearchParams()
  const features = useFeatures()
  const wopiAction = getWopiActionTypeKey(search.get('action'))
  const showWopiEditView =
    file &&
    isWopi(file.name, features.wopiTestMode) &&
    search.get('action') === 'edit' &&
    wopiAction !== 'EMBEDVIEW'
  const navigate = useNavigate()

  const setActiveIndex = useCallback(
    (i: number) => {
      if (i >= 0 && i < data.items.length) {
        const item = data.items[i]
        navigate(
          context === 'search'
            ? `./${Base64.encode(item.data.path, true)}`
            : uriEncodeRoutePath(
                apiPathToRoute(item.data.path, {
                  context,
                }),
              ),
          {
            replace: true,
          },
        )
      }
    },
    [context, data.items, navigate],
  )

  useEffect(() => {
    if (!loading) {
      setData(getFileCarouselItems(file, files))
    }
  }, [file, files, files?.length, loading])

  const navBack = useCarouselNav(Boolean(file))
  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (!open) {
        navBack()
      }
    },
    [navBack],
  )

  const prevIndex = useRef(data.index)

  // data.index needs to be -1 and file undefined before we can safely close carousel (they do not update simultaneously)
  useEffect(() => {
    if (
      open &&
      !file &&
      data.index < 0 &&
      prevIndex.current >= 0 &&
      data.items.length > 0
    ) {
      // File has been moved or deleted
      setActiveIndex(prevIndex.current % data.items.length)
    } else if (!loading && open && !file && data.index < 0) {
      handleOpenChange(false)
    }

    prevIndex.current = data.index
  }, [
    data.index,
    open,
    handleOpenChange,
    loading,
    file,
    data.items.length,
    setActiveIndex,
  ])

  // Skip carousel completely when editing with WOPI - Had issues with conflicting
  // event handlers when both were active
  if (showWopiEditView) {
    return <WopiViewContainer item={file.data} />
  }

  return (
    <Dialog.Root open={open} onOpenChange={handleOpenChange} {...props}>
      {children}
      <Dialog.Portal>
        <Dialog.Overlay className={styles.overlay}>
          <Dialog.Content
            className={styles.container}
            data-testid="FileCarousel"
          >
            {!!data.items.length && data.index >= 0 && (
              <Carousel
                ItemHeader={FileCarouselHeader}
                ItemView={FileCarouselView}
                items={data.items}
                currentItemIndex={data.index}
                getId={getPathItemId}
                setActiveIndex={setActiveIndex}
              />
            )}
          </Dialog.Content>
          {file && open && (
            <DisplayActiveFileActionDialog isNestedModal store={file.folder} />
          )}
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  )
})

function getPathItemId(item: PathItemStore) {
  return item.data.path
}
