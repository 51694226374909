import { AppLayoutSignup } from '@jotta/ui/AppLayoutSignup'

import UpgradePng from './upgrade.png'
import { Card } from '@jotta/ui/Card'
import { Headline1 } from '@jotta/ui/Headline'
import { RouterLink } from '@jotta/ui/RouterLink'

export function UpgradeAccepted() {
  return (
    <AppLayoutSignup>
      <Card className="flex flex-col items-center gap-8 bg-white !p-16">
        <Headline1>{`Your account has been upgraded!`}</Headline1>
        <img src={UpgradePng} className="h-80" />
        <p>
          Congratulations on your new subscription! You will shortly receive a
          payment receipt by email.
        </p>
        <RouterLink to="/web/billing" variant="links.text">
          Continue
        </RouterLink>
      </Card>
    </AppLayoutSignup>
  )
}
