import {
  DecryptionFailureStrategy,
  useEncryptedLocalStorageState,
} from '@jotta/hooks'
import { useEffect } from 'react'

export function useRecentSearches(currentSearch: string) {
  const [searches, setSearches] = useEncryptedLocalStorageState<string[]>(
    'recent_searches',
    [],
    DecryptionFailureStrategy.DELETE_RECORD,
  )
  useEffect(() => {
    if (searches && currentSearch && searches[0] !== currentSearch) {
      const filteredSearches = searches
        .filter(s => s !== currentSearch)
        .slice(0, 50)
      setSearches([currentSearch, ...filteredSearches])
    }
  }, [currentSearch, searches])
  return searches ? searches : []
}
