import { Input as AriaInput } from 'react-aria-components'
import { inputVariants } from './Input.variants'
import { type InputHTMLAttributes, forwardRef } from 'react'
import type { VariantProps } from 'tailwind-variants'
import clsx from 'clsx'

type InputProps = VariantProps<typeof inputVariants> & {}

export const Input = forwardRef<
  HTMLInputElement,
  InputProps & InputHTMLAttributes<HTMLInputElement>
>(function Input({ className, variant = 'plain', ...props }, ref) {
  const { input } = inputVariants({
    variant,
    className,
  })
  return <AriaInput {...props} className={clsx(input(), className)} ref={ref} />
})
