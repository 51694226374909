import type { MutationStatus, UseMutationResult } from '@tanstack/react-query'
import { useEffect } from 'react'

export function useResetAfterTimeout({
  mutation,
  timeout: defaultTimeout,
  successTimeout = defaultTimeout,
  errorTimeout = defaultTimeout,
}: {
  mutation: UseMutationResult<any, any, any, any>
  timeout: number
  successTimeout?: number
  errorTimeout?: number
}) {
  const { reset, status } = mutation
  const timeouts: Record<MutationStatus, number> = {
    idle: 0,
    pending: 0,
    success: successTimeout,
    error: errorTimeout,
  }
  const timeout = timeouts[status]
  useEffect(() => {
    if (timeout) {
      const timeoutId = setTimeout(() => {
        reset()
      }, timeout)
      return () => clearTimeout(timeoutId)
    }
  }, [status, reset, timeout])
}
