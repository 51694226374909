import { observer } from 'mobx-react-lite'

import type { PhotoSearchResult } from '../../store/PhotoSearchStore'
import styles from './PhotoSearch.module.scss'
import { PhotoSearchThumb } from './PhotoSearchThumb'

export const PhotoSearchThumbList = observer<{
  result: PhotoSearchResult | null
  onClick?: (id: string) => void
}>(function PhotoSearchThumbList({ result, onClick }) {
  if (!result) {
    return null
  }

  return (
    <>
      <div className={styles.thumbList}>
        {Boolean(result.isSuccess && result.matches.length > 0) && (
          <>
            {typeof result.similarPhoto !== 'undefined' && (
              <PhotoSearchThumb
                key={result.similarPhoto.id}
                id={result.similarPhoto.id}
                width={result.similarPhoto.width}
                height={result.similarPhoto.height}
                filename={result.similarPhoto.filename}
                src={`${result.similarPhoto?.thumbnail_url}.s`}
                onNavigate={onClick}
                playIcon={result.similarPhoto.content === 'video'}
                duration={result.similarPhoto.duration}
                border
              />
            )}
            {result.matches.map(match => (
              <PhotoSearchThumb
                key={match.getPhotoId()}
                id={match.getPhotoId()}
                width={match.getWidth()}
                height={match.getHeight()}
                filename={match.getThumbnailUrl()}
                src={`${match.getThumbnailUrl()}.s`}
                onNavigate={onClick}
                playIcon={match.hasVideoDuration()}
                duration={match.getVideoDuration()}
                debugInfo={match.getDebugInfo()?.toObject()}
              />
            ))}
          </>
        )}

        {Boolean(result.isSuccess && !result.matches.length) && (
          <div>No results for current query</div>
        )}
        {Boolean(result.isError) && (
          <div className={styles.thumbListError}>Something went wrong</div>
        )}
      </div>
    </>
  )
})
