// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PersonImage_image__cZziA{aspect-ratio:1/1;background-color:var(--color-light-gray-bg);border-radius:50%;height:100%;object-fit:cover;object-position:center;width:100%}.PersonImage_image__cZziA.PersonImage_imgLoadError__kdr9S:after{color:var(--color-danger);content:"✕";font-size:var(--fz8);left:50%;position:absolute;top:50%;transform:translate(-50%,-50%)}`, "",{"version":3,"sources":["webpack://./../../libraries/photos/src/people/internal/components/PersonList/PersonImage.module.scss"],"names":[],"mappings":"AAAA,0BAKE,gBACA,CAHA,2CACA,CAAA,iBACA,CAHA,WACA,CAGA,gBACA,CAAA,sBACA,CAPF,UAOE,CAAA,gEAGI,yBACA,CAFF,WACE,CAKA,oBACA,CAHA,QACA,CAHA,iBACA,CAAA,OACA,CACA,8BAEA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": `PersonImage_image__cZziA`,
	"imgLoadError": `PersonImage_imgLoadError__kdr9S`
};
export default ___CSS_LOADER_EXPORT___;
