/**
 * Manually add polyfills not inserted by "@babel/preset-env"
 */
//@ts-expect-error
import { WeakRef, FinalizationGroup } from '@ungap/weakrefs'
//@ts-expect-error
import structuredClone from '@ungap/structured-clone'
if (!('structuredClone' in globalThis)) {
  ;(globalThis as any).structuredClone = structuredClone
}
if (!('WeakRef' in globalThis)) {
  ;(globalThis as any).WeakRef = WeakRef
}
if (!('FinalizationGroup' in globalThis)) {
  ;(globalThis as any).FinalizationGroup = FinalizationGroup
}
