import { observer } from 'mobx-react-lite'
import { PathItemRow } from '../PathItem/PathItemRow'
import { PendingInviteRow } from '../PathItem/PendingInviteRow'
import styles from './Folder.module.scss'
import type { FolderStore } from './FolderStore'

export const FolderVirtualListItem = observer<{
  index: number
  store: FolderStore
}>(function FolderVirtualListItem({ store, index }) {
  const item = store.virtualListItems.at(index)

  if (!item) {
    return <div className={styles.emptyVirtualListItem} />
  }
  switch (item.type) {
    case 'file':
      return <PathItemRow item={item.data} />
    case 'invite':
      return <PendingInviteRow store={store} invite={item.data} />
  }
})
