import { createContext, forwardRef, useRef, useCallback } from 'react'
import type { UseQueryResult } from '@tanstack/react-query'
import type {
  PropsWithChildren,
  FormHTMLAttributes,
  FormEventHandler,
} from 'react'

export interface Resolver {
  resolve: (value: string) => void
  status: UseQueryResult['status'] | 'idle'
}

export interface Field {
  element: HTMLInputElement | undefined | null
  name: string
  validate: () => boolean
}

export interface FormContextProps {
  fields: Field[]
}

export const FormContext = createContext<FormContextProps>({ fields: [] })

export interface FormProps {}

export const Form = forwardRef<
  HTMLFormElement,
  PropsWithChildren<FormProps & FormHTMLAttributes<HTMLFormElement>>
>(function Form({ children, onSubmit, onInvalid, ...props }, ref) {
  const ctx = useRef<FormContextProps>({ fields: [] })

  const submit = useCallback<FormEventHandler<HTMLFormElement>>(
    e => {
      e.preventDefault()
      let valid = true
      for (const field of ctx.current.fields) {
        const result = field.validate()
        valid &&= result
      }
      if (valid && onSubmit) {
        onSubmit(e)
      }
    },
    [ctx, onSubmit],
  )

  return (
    <form ref={ref} onSubmit={submit} {...props} noValidate>
      <FormContext.Provider value={ctx.current}>
        {children}
      </FormContext.Provider>
    </form>
  )
})
