import {
  patchCustomer,
  terminateAccount,
} from '@jotta/grpc-js-client/customerService'
import type { WopiConsent } from '@jotta/grpc-js-client/wopiService'
import {
  UpdateWopiConsentRequest,
  updateConsent,
} from '@jotta/grpc-js-client/wopiService'
import { BytesValue } from '@jotta/grpc-web/google/protobuf/wrappers_pb'
import {
  Challenge,
  Credentials,
} from '@jotta/grpc-web/no/jotta/openapi/credentials_pb'
import type {
  Customer,
  UserSettings,
} from '@jotta/grpc-web/no/jotta/openapi/customer/customer.v2_pb'
import {
  LanguageValue,
  PatchCustomerRequest,
  TerminateAccountRequest,
} from '@jotta/grpc-web/no/jotta/openapi/customer/customer.v2_pb'
import {
  BoolValue,
  StringValue,
} from '@jotta/grpc-web/no/jotta/protobuf/wrappers_pb'
import type { FeatureToggle } from '@jotta/types/FeatureToggle'
import { isString } from '@jotta/utils/typeAssertions'
import { t } from '@lingui/macro'
import type { MutationFunction } from '@tanstack/react-query'
import Debug from 'debug'
import { makeAutoObservable, runInAction } from 'mobx'
import type { ChallengeableRequestParams } from '../../utils/ChallengeModel'
import { ResolveState } from '../../utils/ChallengeModel'
import { challengeCheck } from '../../utils/ChallengeUtils'
import { FamilySettingsStore } from './FamilySettingsStore'
import { settingsAPI } from './SettingsAPI'
import { CustomerConfigStore } from './CustomerConfigStore'
import { invalidateCustomerConfig } from '../hooks/useSettingsStore'
import { queryClient } from '@jotta/query'
import { getCustomerQueryKey } from '@jotta/grpc-connect-client/customer'

const debug = Debug('jotta:settings:SettingsStore')

export class SettingsStore {
  customerConfigStore = new CustomerConfigStore()
  family: FamilySettingsStore

  constructor(public api = settingsAPI) {
    makeAutoObservable(this)

    this.family = new FamilySettingsStore(this)
  }

  get brandingInfo() {
    return this.customerConfigStore.brandingInfo
  }

  get activeFeatureToggles() {
    return this.customerConfigStore.config
      .activeFeatureToggleIds as FeatureToggle[]
  }

  get customer(): Customer.AsObject {
    return this.customerConfigStore.customer
  }

  makePatchCustomerRequest = async ({
    request,
    onResolve,
    onFail,
  }: ChallengeableRequestParams) => {
    try {
      const response = await patchCustomer(request as PatchCustomerRequest)
      challengeCheck({ request, onResolve, onFail }, response.challenges)
    } catch (e) {
      if (onFail) {
        onFail(e)
      }
    }
  }

  updateProfileInformation = (
    changes: Partial<
      Pick<
        Customer.AsObject,
        'email' | 'fullName' | 'countryCode' | 'phoneNumber' | 'language'
      >
    >,
    profilePhoto?: Uint8Array,
    onResolve?: ChallengeableRequestParams['onResolve'],
    onFail?: ChallengeableRequestParams['onFail'],
  ) => {
    const { email, fullName, countryCode, phoneNumber, language } = changes
    const request = new PatchCustomerRequest()

    if (email && email !== this.customer?.email) {
      request.setEmail(new StringValue().setValue(email))
    }
    if (fullName && fullName !== this.customer?.fullName) {
      request.setName(new StringValue().setValue(fullName))
    }
    if (
      countryCode &&
      countryCode?.toLowerCase() !== this.customer?.countryCode?.toLowerCase()
    ) {
      request.setCountryCode(new StringValue().setValue(countryCode))
    }

    if (language && Number(language) !== Number(this.customer?.language)) {
      request.setLanguage(new LanguageValue().setLanguage(language))
    }
    if (
      isString(phoneNumber) &&
      this.customerConfigStore.canUpdatePhoneNumber &&
      phoneNumber !== this.customer.phoneNumber
    ) {
      debug('Update phonenumber', phoneNumber)
      request.setPhoneNumber(new StringValue().setValue(phoneNumber))
    }

    if (profilePhoto != null) {
      const profilePhotoBytes = new BytesValue()
      profilePhotoBytes.setValue(profilePhoto)
      request.setProfilePhoto(profilePhotoBytes)
    }

    return this.makePatchCustomerRequest({
      request,
      onResolve: state => {
        if (state === ResolveState.SUCCESS) {
          this.customerConfigStore.forceRefreshSession()
          invalidateCustomerConfig()
          queryClient.invalidateQueries({
            queryKey: getCustomerQueryKey,
          })
        }
        if (onResolve) {
          onResolve(state)
        }
      },
      onFail,
    })
  }

  toggleCustomerSetting: MutationFunction<
    [setting: keyof UserSettings.AsObject, value: boolean],
    [setting: keyof UserSettings.AsObject, value: boolean]
  > = ([setting, value]) => {
    return new Promise((resolve, reject) => {
      const request = new PatchCustomerRequest()

      const newValue = new BoolValue().setValue(value)

      switch (setting) {
        case 'excessiveTrashWarningEnabled':
          request.setExcessiveTrashWarningEnabled(newValue)
          break
        case 'emailActivityNotificationsEnabled':
          request.setEmailActivityNotificationsEnabled(newValue)
          break
        case 'appActivityNotificationsEnabled':
          request.setAppActivityNotificationsEnabled(newValue)
          break
        case 'newsletterEnabled':
          request.setNewsletterEnabled(newValue)
          break
      }

      request.setCredentials()

      this.makePatchCustomerRequest({
        request,
        onResolve: state => {
          if (state === ResolveState.SUCCESS) {
            resolve([setting, value])
          } else {
            reject(state)
          }
        },
        onFail: err => reject(err),
      })
    })
  }

  patchCustomerSettingToggles = (
    setting: keyof UserSettings.AsObject,
    value: boolean,
    onResolve?: ChallengeableRequestParams['onResolve'],
    onFail?: ChallengeableRequestParams['onFail'],
  ) => {
    const request = new PatchCustomerRequest()

    const newValue = new BoolValue().setValue(value)

    switch (setting) {
      case 'excessiveTrashWarningEnabled':
        request.setExcessiveTrashWarningEnabled(newValue)
        break
      case 'emailActivityNotificationsEnabled':
        request.setEmailActivityNotificationsEnabled(newValue)
        break
      case 'appActivityNotificationsEnabled':
        request.setAppActivityNotificationsEnabled(newValue)
        break
      case 'newsletterEnabled':
        request.setNewsletterEnabled(newValue)
        break
    }

    request.setCredentials()

    return this.makePatchCustomerRequest({
      request,
      onResolve: state => {
        if (this.customer?.userSettings) {
          this.customer.userSettings[setting] = value
        }
        if (onResolve) {
          onResolve(state)
        }
      },
      onFail,
    })
  }

  terminateAccount = async ({
    request = new TerminateAccountRequest(),
    onResolve,
    onFail,
  }: ChallengeableRequestParams) => {
    try {
      const response = await terminateAccount(request)
      challengeCheck({ request, onResolve, onFail }, response.challenges)
    } catch (e) {
      if (onFail) {
        onFail(e)
      }
    }
  }

  changeWopiConsent = async (next: WopiConsent) => {
    const req = new UpdateWopiConsentRequest()
    req.setConsent(next)

    try {
      await updateConsent(req)
      runInAction(() => {
        if (this.customer) {
          this.customer.wopiConsent = next
        }
      })
    } catch (e) {
      debug(e)
      // Sentry.captureException(e)
    }
  }

  changePassword = async ({
    currentPassword,
    newPassword,
    onFail,
    onChallenge,
  }: ChangePasswordParams): Promise<boolean> => {
    const updater = new PatchCustomerRequest()
    updater.setPassword(new StringValue().setValue(newPassword))
    updater.setCredentials(new Credentials().setPassword(currentPassword))

    const resp = await patchCustomer(updater)

    if (resp?.challenges?.challengesList?.find(e => e === Challenge.PASSWORD)) {
      throw new Error(t({ id: 'The password was wrong!' }))
    }
    challengeCheck(
      {
        request: updater,
        onFail,
        onResolve: onChallenge,
      },
      resp?.challenges,
    )
    return true
  }
}

interface ChangePasswordParams {
  currentPassword: string
  newPassword: string
  onFail: (error: unknown) => void
  onChallenge: (state: ResolveState) => void
}
