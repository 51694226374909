import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type { OnboardingAllKeys } from './customerService'
import { getOnboarding, setOnboarding } from './customerService'

const QUERY_KEY = 'onboarding'

export function useGetOnboarding({ enabled }: { enabled?: boolean }) {
  return useQuery({ queryKey: [QUERY_KEY], queryFn: getOnboarding, enabled })
}

export function useSetOnboarding() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ([task, state = true]: [OnboardingAllKeys, boolean]) =>
      setOnboarding(task, state),
    onSuccess: data => {
      queryClient.setQueryData([QUERY_KEY], data)
    },
  })
}
