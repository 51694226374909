// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmptyFolder_grid__keTYw{align-items:start;display:grid;flex:1 1 auto;grid-template-rows:max-content max-content 1fr;justify-items:center;padding-top:100px}body.br-1 .EmptyFolder_grid__keTYw{padding-top:40px}body.br-0 .EmptyFolder_grid__keTYw{padding-top:40px}.EmptyFolder_icon__AZTRX{color:var(--info-page-icon-color);height:122px;width:135px}h2.EmptyFolder_title__FGZQ1{color:var(--color-grey-files-text);font-size:var(--fz5);margin-bottom:0;margin-top:0;padding-bottom:25px;padding-top:32px}.EmptyFolder_paragraph__oqvXq,h2.EmptyFolder_title__FGZQ1{font-weight:var(--fw-light);text-align:center}.EmptyFolder_paragraph__oqvXq{color:#788189;font-size:var(--fz3);line-height:30px}button.EmptyFolder_boldButton__gMC97{font-weight:var(--fw-bold);margin-left:2px;margin-right:4px}button.EmptyFolder_button__sg0dd{margin-left:4px}`, "",{"version":3,"sources":["webpack://./../../libraries/files/src/EmptyFolder/EmptyFolder.module.scss"],"names":[],"mappings":"AACA,yBAGE,iBAAA,CAFA,YAAA,CAIA,aAAA,CAHA,8CAAA,CAEA,oBAAA,CAEA,iBAAA,CAGA,mCACE,gBAAA,CAIF,mCACE,gBAAA,CAIJ,yBAGE,iCAAA,CADA,YAAA,CADA,WAEA,CAGF,4BACE,kCAAA,CAEA,oBAAA,CAGA,eAAA,CADA,YAAA,CAGA,mBAAA,CADA,gBACA,CAGF,0DATE,2BAAA,CAEA,iBAYA,CALF,8BAEE,aAAA,CADA,oBAAA,CAGA,gBACA,CAGF,qCACE,0BAAA,CACA,eAAA,CACA,gBAAA,CAGF,iCACE,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": `EmptyFolder_grid__keTYw`,
	"icon": `EmptyFolder_icon__AZTRX`,
	"title": `EmptyFolder_title__FGZQ1`,
	"paragraph": `EmptyFolder_paragraph__oqvXq`,
	"boldButton": `EmptyFolder_boldButton__gMC97`,
	"button": `EmptyFolder_button__sg0dd`
};
export default ___CSS_LOADER_EXPORT___;
