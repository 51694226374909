import clsx from 'clsx'
import Debug from 'debug'
import type { CSSProperties, PropsWithChildren, ReactNode } from 'react'
import { useEffect, useState } from 'react'
import styles from './LoadingOverlay.module.scss'
const debug = Debug('jotta:ui:LoadingOverlay')
debug.enabled = false

export interface LoadingOverlayProps {
  /** Open state */

  open?: boolean
  /** Dark version for modals and carousels  */
  text?: ReactNode
  darkMode?: boolean
  /**
   * Fade out before onmounting component
   */
  animateClose?: boolean
  /**
   * Duration of fade animation
   *
   * Defaults to '300ms'
   */
  animationDuration?: string
  position?: 'fixed' | 'absolute'
}
export function LoadingOverlaySpinner({
  className,
  ...props
}: PropsWithChildren<{ className?: string }>) {
  return <div className={clsx(styles.spinner, className)} {...props} />
}
export type OpenStates = 'open' | 'closed' | 'opening' | 'closing'
export const closingStates: readonly OpenStates[] = [
  'closed',
  'closing',
] as const
export const openingStates: readonly OpenStates[] = ['open', 'opening'] as const

/**
 * Shows a loading overlay
 * The overlay uses position: fixed, which means it's fills the nearest parent
 * in the stacking context:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context
 */
export function LoadingOverlay({
  open = false,
  children = <LoadingOverlaySpinner />,
  darkMode = false,
  animateClose = false,
  position = 'fixed',
  animationDuration = '300ms',
  text = null,
  ...props
}: PropsWithChildren<LoadingOverlayProps>) {
  if (!open) {
    return null
  }
  return (
    <div
      aria-busy={open}
      className={clsx(styles.overlay, styles.static, {
        'is-dark': darkMode,
        'is-fixed': position === 'fixed',
      })}
      data-testid="LoadingOverlay"
      {...props}
    >
      {children}
      {!!text && <div className={styles.text}>{text}</div>}
    </div>
  )
}

/**
 * A loading overlay that fades out before onmounting
 *
 * This is a separate component since animating when the component unmounts adds
 * complexity - it has to maintain open states and handle animationEnd events
 */
export function AnimatedLoadingOverlay({
  open = false,
  children = <LoadingOverlaySpinner />,
  darkMode = false,
  animateClose = false,
  position = 'fixed',
  animationDuration = '300ms',
  text = null,
  ...props
}: PropsWithChildren<LoadingOverlayProps>) {
  const [openState, setOpenState] = useState<OpenStates>(
    open ? 'opening' : 'closing',
  )
  const isClosed = Boolean(animateClose ? openState === 'closed' : !open)

  useEffect(() => {
    setOpenState(v => {
      let nextState = v
      if (open && closingStates.includes(v)) {
        nextState = 'opening'
      } else if (!open && openingStates.includes(v)) {
        nextState = animateClose ? 'closing' : 'closed'
      }
      debug('setOpenState %s -> %s', v, nextState)
      return nextState
    })
  }, [animateClose, open])
  if (isClosed) {
    return null
  }
  return (
    <div
      style={
        {
          '--fade-duration': animationDuration,
        } as CSSProperties
      }
      aria-busy={open}
      onAnimationEnd={() => {
        debug('onAnimationEnd')
        setOpenState(v => {
          const nextState: OpenStates = open ? 'open' : 'closed'
          debug('setOpenState %s -> %s', v, nextState)
          return nextState
        })
      }}
      className={clsx(styles.overlay, styles[openState], styles.animated, {
        'is-dark': darkMode,
        'is-fixed': position === 'fixed',
      })}
      data-testid="LoadingOverlay"
      {...props}
    >
      {children}
      {!!text && <div className={styles.text}>{text}</div>}
    </div>
  )
}
