import { Box } from '@jotta/ui/Box'
import { Button } from '@jotta/ui/Button'
import { Grid } from '@jotta/ui/Grid'
import type { FileRevision } from '@jotta/grpc-js-client/fileService'
import { listRevisions } from '@jotta/grpc-js-client/fileService'
import { RadixDialog } from '@jotta/ui/RadixDialog'
import type { RadixModalProps } from '@jotta/ui/RadixModal'
import { RadixModal } from '@jotta/ui/RadixModal'
import { downloadWithUrl } from '@jotta/utils/download'
import { filesize } from '@jotta/utils/filesize'
import { getFilenameFromPath } from '@jotta/utils/pathUtils'
import { addUrlParams } from '@jotta/utils/url'
import { Trans, t } from '@lingui/macro'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import type { FC } from 'react'
import { Fragment } from 'react'
import { useAuthStatus } from '@jotta/auth-client/useAuthStatus'

export type DownloadRevisionsHandler = (revision: FileRevision.AsObject) => void

export interface FileRevisionsDialogProps extends RadixModalProps {
  path: string
  fetchRevisions?: typeof listRevisions
  onClose: () => void
}

export const FileRevisionsDialog: FC<FileRevisionsDialogProps> = ({
  path,
  fetchRevisions = listRevisions,
  onClose,
  ...props
}) => {
  const query = useQuery({
    queryKey: ['listRevisions', path] as const,
    queryFn: () => fetchRevisions(path),
  })
  const name = getFilenameFromPath(path)
  const token = useAuthStatus().data?.accessToken

  return (
    <RadixModal onClose={onClose} {...props}>
      <RadixDialog
        onClose={onClose}
        smallTitle={t`Previous versions`}
        title={name}
        buttons={[
          {
            action: 'close',
            label: t`Close`,
          },
        ]}
      >
        {query.isSuccess && (
          <Grid
            columns="1fr max-content max-content"
            sx={{
              alignItems: 'center',
            }}
          >
            <Box>
              <Trans>Last changed</Trans>
            </Box>
            <Box>
              <Trans>Size</Trans>
            </Box>
            <Box></Box>
            {query.data.map(rev => (
              <Fragment key={rev.id}>
                <Box>{dayjs(rev.modifiedAtMillis).format('lll')}</Box>
                <Box>{filesize(rev.size, { round: 0 })}</Box>
                <Button
                  variant={'buttons.secondary'}
                  onClick={() => {
                    downloadWithUrl(
                      addUrlParams(rev.url, {
                        authToken: token || '',
                      }),
                      name,
                    )
                  }}
                >
                  <Trans>Download</Trans>
                </Button>
              </Fragment>
            ))}
          </Grid>
        )}
      </RadixDialog>
    </RadixModal>
  )
}
