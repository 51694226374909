import { HasUploadedDialog } from '@jotta/ui/HasUploadedDialog'
import { Plural } from '@lingui/macro'
import { observer } from 'mobx-react-lite'

import type { UploadPhotoQueueStatusProps } from './UploadPhotoQueueStatus'

export const UploadPhotoQueueStatusUploadComplete =
  observer<UploadPhotoQueueStatusProps>(
    function UploadPhotoQueueStatusUploadComplete({ status }) {
      return (
        <>
          {status.isAlbumUpload ? (
            <Plural
              value={status.resolved}
              one="Done uploading file!"
              other="Done uploading # files!"
            />
          ) : (
            <Plural
              value={status.resolved}
              one="Done uploading # file! What do you want to do with it?"
              other="Done uploading # files! What do you want to do with them?"
            />
          )}
          <HasUploadedDialog />
        </>
      )
    },
  )
