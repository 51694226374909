/** External */
import { useUsername } from '@jotta/grpc-connect-client/customer'
import { createLoaderRoute } from '@jotta/router'
import { AppError } from '@jotta/types/AppError'
import Debug from 'debug'
import { useEffect, useMemo } from 'react'
import type { RouteObject } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
/** Internal */
import { usePhotoByMD5 } from '../../../api/photosApi'
import { MediaIdCarousel } from '../../../components/MediaCarousel/MediaIdCarousel'
import { usePersonPhotos } from './PersonRoute'

const debug = Debug('jotta:people:PersonPhotoRoute')

export const { route: personPhotoRoute, usePersonPhotoLoaderData } =
  createLoaderRoute({
    id: 'personPhoto',
    path: ':photoMD5',
    element: <PersonPhotoRouteView />,
    loader: ({ params }) => {
      const { photoMD5 = '' } = params
      return {
        photoMD5,
      }
    },
  } satisfies RouteObject)
function PersonPhotoRouteView() {
  const { photoMD5 } = usePersonPhotoLoaderData()
  const { photos } = usePersonPhotos()
  const nav = useNavigate()
  const query = usePhotoByMD5({
    variables: {
      checksum: photoMD5,
    },
  })
  const { error } = query
  useEffect(() => {
    if (error instanceof AppError && error.isNotFound) {
      nav(-1)
    }
  }, [error, nav])
  const items = useMemo(() => {
    return photos.map(p => p.md5)
  }, [photos])
  const username = useUsername()
  return (
    <MediaIdCarousel
      open
      ids={items}
      currentMediaId={photoMD5}
      defaultOpen
      onClose={open => {
        if (!open) {
          nav(-1)
        }
      }}
      actionHandlerProps={{
        username,
      }}
      actionContext="TIMELINE_PHOTOS_VIEW_PHOTO"
    />
  )
}
