import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { DialogTrigger } from '@radix-ui/react-dialog'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { LeaveAlbumDialog } from '../Album/Dialogs/LeaveAlbumDialog'
import type { PhotoActionHandlerActionProps } from './PhotoActionHandlers'
import { useDispatchPhotoActionMutation } from './useDispatchPhotoActionMutation'

export const ActionLeaveAlbum = observer<
  PhotoActionHandlerActionProps<'LEAVE_ALBUM'>
>(function ActionLeaveAlbum({
  action,
  albumId = '',
  dispatch,
  buttonVariant = 'buttons.albumActionButton',
  onActionDialogClose,
  disabled = false,
}) {
  const [open, setOpen] = useState(false)
  const mutation = useDispatchPhotoActionMutation(
    'LEAVE_ALBUM',
    {
      onSettled: () => setOpen(false),
    },
    dispatch,
  )

  return (
    <LeaveAlbumDialog
      open={open}
      onClose={open => {
        setOpen(open)
        if (!open && onActionDialogClose) {
          onActionDialogClose(action.actionType)
        }
      }}
      onLeaveAlbum={() => albumId && mutation.mutateAsync([albumId])}
      isLoading={mutation.isPending}
      triggerElement={
        <DialogTrigger
          aria-label={action.label}
          data-testid={action.testid}
          aria-busy={mutation.isPending}
          disabled={disabled}
          key={action.actionType}
          sx={{
            variant: buttonVariant,
          }}
        >
          <PlainBrandIcon icon={action.icon} />
          <span>{action.label}</span>
        </DialogTrigger>
      }
    />
  )
})
