import {
  AccountType,
  Customer,
  Family,
  GetFamilyResponse,
  InviteState,
  PhotoState,
  QuotaState,
  SubscriptionType,
} from '@jotta/grpc-web/no/jotta/openapi/customer/customer.v2_pb'
import { Language } from '@jotta/grpc-web/no/jotta/openapi/language_pb'
import { WopiConsent } from '@jotta/grpc-web/no/jotta/openapi/wopi_pb'

export const emptyCustomer: Readonly<Customer.AsObject> = {
  username: '',
  email: '',
  fullName: '',
  phoneNumber: '',
  phoneNumberVerified: false,
  countryCode: '',
  languageCode: '',
  language: Language.UNKNOWN_LANGUAGE,
  usageBytes: 0,
  quotaBytes: 0,
  photoState: PhotoState.UNKNOWN_PHOTO_STATE,
  accountType: AccountType.UNKNOWN_ACCOUNT_TYPE,
  subscriptionType: SubscriptionType.UNKNOWN_SUBSCRIPTION_TYPE,
  brandingInfo: {
    brandCode: '',
    brandName: '',
    customerGroupCode: '',
    customerGroupName: '',
  },
  wopiConsent: WopiConsent.WOPI_CONSENT_UNKNOWN,
  tfaType: Customer.TFAType.NONE,
  messagesList: [],
  promoteUpgrade: false,
  canTerminateAccount: false,
  quota: QuotaState.VALID,
}
export const mockCustomer: Readonly<Customer.AsObject> = {
  username: 'test',
  email: 'test@test.com',
  fullName: 'Test User',
  phoneNumber: '123456',
  phoneNumberVerified: true,
  countryCode: '47',
  languageCode: 'en',
  language: Language.ENGLISH,
  usageBytes: 0,
  quotaBytes: 0,
  photoState: PhotoState.NOT_ACTIVE,
  accountType: AccountType.CONSUMER,
  subscriptionType: SubscriptionType.FREE,
  brandingInfo: {
    brandCode: '',
    brandName: '',
    customerGroupCode: '',
    customerGroupName: '',
  },
  wopiConsent: WopiConsent.WOPI_CONSENT_ACCEPTED,
  tfaType: Customer.TFAType.NONE,
  messagesList: [],
  promoteUpgrade: false,
  canTerminateAccount: false,
  quota: QuotaState.VALID,
}
export const mockFamilyOwner: Readonly<Customer.AsObject> = {
  ...mockCustomer,
  username: 'family-owner',
  accountType: AccountType.FAMILY_OWNER,
  usageBytes: 300000000,
  family: {
    name: 'Test family',
  },
}
export const mockFamilyMember: Readonly<Customer.AsObject> = {
  ...mockCustomer,
  accountType: AccountType.FAMILY_MEMBER,
  family: {
    name: 'Test family',
  },
}

export function getMockFamily(
  familyOwner: Partial<Customer.AsObject> = {},
  maxInvites: number = 5,
) {
  const owner = getMockFamilyOwner(familyOwner)
  const testFamily = new GetFamilyResponse()
  testFamily.setFamily(new Family().setName(owner?.family?.name || ''))
  testFamily.setFamilyQuotaBytes(1000000000)
  testFamily.setMaxInvitesCount(maxInvites)
  testFamily.setOwnerUsageBytes(owner.usageBytes)
  testFamily.setTotalUsageBytes(2 * owner.usageBytes)
  const obj = testFamily.toObject()
  obj.invitesList = [
    {
      state: InviteState.INVITE_ACCEPTED,
      inviteCode: 'code1',
      inviteLink: 'https://www.jottacloud.com/f/code1',
      toUserFullname: 'ASuperlong Namethathas Threepartstothename',
      toUsername: 'username',
      sendAddress: 'testinvite@jotta.no',
      fromUsername: '',
      fsUsageBytes: 32141423,
    },
    {
      state: InviteState.INVITE_SENT,
      inviteCode: 'code2',
      inviteLink: 'https://www.jottacloud.com/f/code2',

      toUserFullname: 'ASuperlong Twopartstothename',
      toUsername: '',
      sendAddress: 'testinvite@jotta.no',
      fromUsername: '',
      fsUsageBytes: 0,
    },
    {
      state: InviteState.UNKNOWN,
      inviteCode: 'code3',
      inviteLink: 'https://www.jottacloud.com/f/code3',
      toUserFullname: 'ASuperlong Twopartstothename',
      toUsername: '',
      sendAddress: '+4781549300',
      fromUsername: '',
      fsUsageBytes: 0,
    },
    {
      state: InviteState.UNKNOWN,
      inviteCode: 'avail-1',
      inviteLink: '',
      toUserFullname: '',
      toUsername: '',
      sendAddress: '',
      fromUsername: '',
      fsUsageBytes: 0,
    },
    {
      state: InviteState.UNKNOWN,
      inviteCode: 'avail-2',
      inviteLink: '',
      toUserFullname: '',
      toUsername: '',
      sendAddress: '',
      fromUsername: '',
      fsUsageBytes: 0,
    },
  ]
  return obj
}

function getMockFamilyOwner(
  owner: Partial<Customer.AsObject> = {},
): Customer.AsObject {
  return {
    ...mockFamilyOwner,
    usageBytes: 232132,
    fullName: 'Anlaug Larsen',
    username: 'anlaug',
    countryCode: 'se',
    brandingInfo: {
      brandName: 'Jottacloud',
      brandCode: 'jotta',
      customerGroupCode: 'CUST',
      customerGroupName: 'ABC',
    },
    family: {
      name: 'Larsen',
    },
    ...owner,
  }
}
