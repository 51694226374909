import type { AppError } from '@jotta/types/AppError'
import type {
  QueryKey,
  QueryObserverOptions,
  QueryObserverResult,
} from '@tanstack/react-query'
import { QueryObserver } from '@tanstack/react-query'
import type { Debugger } from 'debug'
import Debug from 'debug'
import {
  action,
  makeAutoObservable,
  observable,
  onBecomeObserved,
  onBecomeUnobserved,
  runInAction,
} from 'mobx'
import { queryClient } from './queryClient'

export class ObservableQuery<
  TQueryFnData = unknown,
  TError = AppError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
  TName extends string = string,
> {
  private log: Debugger
  private unsubscribe:
    | ReturnType<typeof this.queryObserver.subscribe>
    | undefined = undefined
  private _result
  private queryOptions
  private queryObserver
  private onQueryUpdate
  constructor({
    name,
    queryOptions,
    onQueryUpdate,
  }: {
    name: TName
    queryOptions: QueryObserverOptions<TQueryFnData, TError, TData, TQueryKey>
    onQueryUpdate?: (result: QueryObserverResult<TData, TError>) => void
  }) {
    this.log = Debug(`jotta:ObservableQuery:${name}`)
    this.queryOptions = queryOptions
    this.queryObserver = new QueryObserver(queryClient, this.queryOptions)
    this.onQueryUpdate = onQueryUpdate
    this._result = this.queryObserver.getCurrentResult()
    makeAutoObservable<typeof this, '_result' | 'log' | 'setResult'>(
      this,
      {
        _result: observable.ref,
        log: false,
        setResult: action.bound,
      },
      {
        autoBind: true,
      },
    )
    onBecomeObserved(this, '_result', this.resume)
    onBecomeUnobserved(this, '_result', this.suspend)
  }

  private setResult(result: typeof this._result) {
    // this.log('setResult', result)
    if (this.onQueryUpdate) {
      this.onQueryUpdate(result)
    }
    this._result = result
  }
  // Fetch data when something starts observing the result
  resume() {
    runInAction(() => {
      this.unsubscribe = this.queryObserver.subscribe(this.setResult)
    })
    this.log('onBecomeObserved', this._result)
    this.queryObserver.fetchOptimistic(this.queryOptions)
  }

  suspend() {
    if (this.unsubscribe) {
      this.log('suspend, unsubscribe')
      this.unsubscribe()
    } else {
      this.log('suspend')
    }
  }

  // When react-query starts observing this will get the unusbscribe function

  get status() {
    return this._result.status
  }
  get error() {
    return this._result.error
  }
  get data() {
    return this._result.data
  }
  get isLoading() {
    return this._result.isLoading
  }
  get isFetching() {
    return this._result.isFetching
  }
  refetch() {
    queryClient.invalidateQueries({ queryKey: this.queryOptions.queryKey })
  }
}
