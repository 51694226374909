import clsx from 'clsx'
import type { CSSProperties } from 'react'
import type { MediaThumbProps } from '../PhotoGallery/MediaThumb'
import { MediaThumb } from '../PhotoGallery/MediaThumb'
import styles from './PhotoSearch.module.scss'

export function PhotoSearchThumb({
  onNavigate,
  border = false,
  debugInfo,
  width,
  height,
  ...props
}: {
  onNavigate?: (id: string) => void
  border?: boolean
  width: number
  height: number
  debugInfo?: { [key: string]: string | number | boolean }
} & Omit<MediaThumbProps, 'showSimilarSearch' | 'title'>) {
  const title = debugInfo
    ? Object.entries(debugInfo)
        .map(([key, val]) => `${key}=${JSON.stringify(val)}`)
        .join('\n')
    : ''

  return (
    <div
      style={{ '--aspect-ratio': width / height } as CSSProperties}
      className={clsx(styles.thumbListThumb, { [styles.border]: border })}
    >
      <MediaThumb
        {...props}
        title={title}
        showSimilarSearch
        onNavigate={onNavigate}
      />
    </div>
  )
}
