import { SubscriptionServicePromiseClient } from '@jotta/grpc-web/no/jotta/openapi/subscription/payment.v2_grpc_web_pb'
import type {
  ApplyVoucherResponse,
  ChangePaymentResponse,
  CreateStripeIntentResponse,
  CreateVippsIntentResponse,
  DescribeVoucherResponse,
  GetPaymentInfoResponse,
  ListOrdersResponse,
  SubscriptionInterval,
} from '@jotta/grpc-web/no/jotta/openapi/subscription/payment.v2_pb'
import {
  ApplyVoucherRequest,
  ChangePaymentRequest,
  CreateStripeIntentRequest,
  CreateVippsIntentRequest,
  DescribeVoucherRequest,
  GetPaymentInfoRequest,
  ListOrdersRequest,
} from '@jotta/grpc-web/no/jotta/openapi/subscription/payment.v2_pb'
import { withGrpcClientMiddleware } from './grpcutils'
import { env } from '@jotta/utils/env'

export {
  Currency,
  GetPaymentInfoResponse,
  Order,
  SubscriptionInterval,
} from '@jotta/grpc-web/no/jotta/openapi/subscription/payment.v2_pb'

let subscriptionClient: SubscriptionServicePromiseClient

const GRPC_WEB_API = env.grpcApi

export function getSubscriptionClient() {
  if (!subscriptionClient) {
    subscriptionClient = withGrpcClientMiddleware(
      'subscription',
      new SubscriptionServicePromiseClient(GRPC_WEB_API),
    )
  }

  return subscriptionClient
}

export const getSubscriptionDetails = (): Promise<GetPaymentInfoResponse> => {
  const request = new GetPaymentInfoRequest()

  return getSubscriptionClient().getPaymentInfo(request)
}

export const createSetupIntent =
  (): Promise<CreateStripeIntentResponse.AsObject> => {
    const request = new CreateStripeIntentRequest()
    return getSubscriptionClient()
      .createSetupIntent(request)
      .then(response => response.toObject())
  }

export const changePaymentMethod = (
  paymentMethodToken: string,
): Promise<ChangePaymentResponse.AsObject> => {
  const request = new ChangePaymentRequest()
  request.setPaymentMethod(paymentMethodToken)
  return getSubscriptionClient()
    .changePayment(request)
    .then(response => response.toObject())
}

export const changePaymentInterval = (
  interval: SubscriptionInterval,
): Promise<ChangePaymentResponse.AsObject> => {
  const request = new ChangePaymentRequest()
  request.setInterval(interval)
  return getSubscriptionClient()
    .changePayment(request)
    .then(response => response.toObject())
}

export async function getBillingHistory(): Promise<ListOrdersResponse> {
  return await getSubscriptionClient().listOrders(new ListOrdersRequest())
}

export async function describeVoucher(
  code: string,
): Promise<DescribeVoucherResponse.AsObject> {
  const request = new DescribeVoucherRequest()
  request.setCode(code)
  const response = await getSubscriptionClient().describeVoucher(request)
  return response.toObject()
}

export async function applyVoucher(
  code: string,
): Promise<ApplyVoucherResponse.AsObject> {
  const request = new ApplyVoucherRequest()
  request.setCode(code)
  const response = await getSubscriptionClient().applyVoucher(request)
  return response.toObject()
}

export const createVippsIntent = ({
  redirectUrl,
  interval,
}: {
  redirectUrl?: string
  interval?: SubscriptionInterval
}): Promise<CreateVippsIntentResponse.AsObject> => {
  const redirect = redirectUrl || window.location.toString()

  const request = new CreateVippsIntentRequest()
  request.setRedirectUrl(redirect)
  if (interval) {
    request.setInterval(interval)
  }

  return getSubscriptionClient()
    .createVippsIntent(request)
    .then(response => response.toObject())
}
