import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import type { PhotoActionHandlerActionProps } from './PhotoActionHandlers'
const debug = Debug('jotta:photos:action:DownloadPhotos')

export const ActionDownloadPhoto = observer<
  PhotoActionHandlerActionProps<'DOWNLOAD_PHOTO'>
>(function ActionDownloadPhotos({
  action,
  downloadLink,
  buttonVariant = 'buttons.albumActionButton',
  onActionDialogClose,
}) {
  // debug(action, downloadLink)
  return (
    <a
      sx={{
        variant: buttonVariant,
      }}
      href={downloadLink?.fileUrl}
      download={downloadLink?.fileName}
      aria-label={action.label}
      data-testid={action.testid}
      key={action.actionType}
    >
      <PlainBrandIcon icon={action.icon} />
      <span>{action.label}</span>
    </a>
  )
})
