import { Box } from '@jotta/ui/Box'
import { t } from '@lingui/macro'
import { observer } from 'mobx-react-lite'
import { useGetConfig, useFeatures } from '@jotta/grpc-connect-client/config'

import { Headline1 } from '../../Typography/Headline/Headline'
import { DownloadSectionCli } from '../DownloaSectionCli/DownloadSectionCli'
import { DownloadSection } from '../DownloadSection/DownloadSection'

export const DownloadPage: React.FC = observer(function DownloadPage() {
  const features = useFeatures()
  const {
    brandName,
    downloadMacLink = '',
    downloadWinLink = '',
    downloadIosLink = '',
    downloadAndroidLink = '',
    cliInstallationInstructionsLink = '',
  } = useGetConfig().data.brandingInfo || {}

  const hasMobileLinks = !!downloadIosLink && !!downloadAndroidLink

  return (
    <Box
      sx={{
        variant: 'layout.downloadPageContainer',
      }}
    >
      <Box
        sx={{
          variant: 'layout.downloadPageWrapper',
        }}
      >
        <Headline1
          sx={{
            variant: 'styles.downloadPageHeader',
          }}
        >
          {t`Download our apps`}
        </Headline1>
      </Box>

      <DownloadSection
        headerText={t`${brandName} for computers`}
        subHeaderText={t`Back up files, and synchronize folders across computers.`}
        sectionItems={[
          {
            icon: 'SvgAppleLogo',
            downloadLink: downloadMacLink,
            iconButton: {
              label: 'MacOS',
              link: downloadMacLink,
            },
          },
          {
            icon: 'SvgWindows',
            downloadLink: downloadWinLink,
            iconButton: {
              label: 'Windows',
              link: downloadWinLink,
            },
          },
        ]}
        sx={{
          paddingTop: 0,
          textAlign: 'center',
          paddingBottom: '100px',
          px: '2',
        }}
      />

      {hasMobileLinks && (
        <DownloadSection
          headerText={t`${brandName} for mobile`}
          subHeaderText={t`Back up your photos and access all your files on the go.`}
          sectionItems={[
            {
              icon: 'SvgAppleLogo',
              downloadLink: downloadIosLink,
              iconButton: {
                label: 'App Store',
                link: downloadIosLink,
              },
            },
            {
              icon: 'SvgAndroid',
              downloadLink: downloadAndroidLink,
              iconButton: {
                label: 'Google play',
                link: downloadAndroidLink,
              },
            },
          ]}
          sx={{
            variant: 'layout.downloadSection',
          }}
        />
      )}

      {features.cli && cliInstallationInstructionsLink && (
        <DownloadSectionCli
          headerText={t`${brandName} Command-Line Tool`}
          subHeaderText={t`Automatic folder backup of any system.`}
          sectionItems={[
            {
              icon: 'SvgAppleLogo',
              downloadLink: cliInstallationInstructionsLink,
            },
            {
              icon: 'SvgWindows',
              downloadLink: cliInstallationInstructionsLink,
            },
            {
              icon: 'SvgLinux',
              downloadLink: cliInstallationInstructionsLink,
            },
          ]}
        />
      )}
    </Box>
  )
})
