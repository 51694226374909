import { WopiServicePromiseClient } from '@jotta/grpc-web/no/jotta/openapi/wopi_grpc_web_pb'
import type { UpdateWopiConsentRequest } from '@jotta/grpc-web/no/jotta/openapi/wopi_pb'
import {
  GetLinksRequest,
  WopiActionType,
} from '@jotta/grpc-web/no/jotta/openapi/wopi_pb'
import { isString } from 'remeda'
import { z } from 'zod'
import { getMeta, withGrpcClientMiddleware } from './grpcutils'
import { getEnumKeys } from '@jotta/utils/enum'
import { env } from '@jotta/utils/env'

export {
  GetLinksResponse,
  UpdateWopiConsentRequest,
  WopiActionType,
  WopiConsent,
} from '@jotta/grpc-web/no/jotta/openapi/wopi_pb'
export const WopiDocumentExtensionsSchema = z.enum(['doc', 'docx', 'odt'])
export const WopiSpreadsheetExtensionsSchema = z.enum([
  'xlsx',
  'xls',
  'xlsm',
  'ods',
])
export const WopiPresentationExtensionsSchema = z.enum(['pptx', 'ppt', 'odp'])
export const WopiValidatorExtensionSchema = z.enum(['wopitest', 'wopitestx'])
export const wopiTestExtensions = WopiValidatorExtensionSchema.options
export const WopiExtensionsSchema = z.enum([
  ...WopiDocumentExtensionsSchema.options,
  ...WopiPresentationExtensionsSchema.options,
  ...WopiSpreadsheetExtensionsSchema.options,
])
export const wopiExtensions = WopiExtensionsSchema.options
export type WopiExtension = z.infer<typeof WopiExtensionsSchema>
export type WopiDocumentExtension = z.infer<typeof WopiDocumentExtensionsSchema>
export type WopiSpreadsheetExtension = z.infer<
  typeof WopiSpreadsheetExtensionsSchema
>
export type WopiPresentationExtension = z.infer<
  typeof WopiPresentationExtensionsSchema
>

/**
 * Check that file name matches one of the WOPI extensions
 * @param file File name
 * @returns True if filename ends with a WOPI extension
 */
export function isWopi(
  file: string,
  wopiTestMode = false,
): file is `${string}.${WopiExtension}` {
  const isWopi = wopiExtensions.some(ext => file.endsWith(`.${ext}`))
  const isWopiTest =
    wopiTestMode && wopiTestExtensions.some(ext => file.endsWith(`.${ext}`))

  return isWopi || isWopiTest
}
export function isWopiDocument(
  file: string,
): file is `${string}.${WopiDocumentExtension}` {
  return WopiDocumentExtensionsSchema.options.some(ext =>
    file.endsWith(`.${ext}`),
  )
}
export function isWopiSpreadsheet(
  file: string,
): file is `${string}.${WopiSpreadsheetExtension}` {
  return WopiSpreadsheetExtensionsSchema.options.some(ext =>
    file.endsWith(`.${ext}`),
  )
}
export function isWopiPresentation(
  file: string,
): file is `${string}.${WopiPresentationExtension}` {
  return WopiPresentationExtensionsSchema.options.some(ext =>
    file.endsWith(`.${ext}`),
  )
}
const wopiActionKeys = getEnumKeys(WopiActionType).filter(
  (v): v is WopiActionTypeKey => v !== 'UNKNOWN_ACTION_TYPE',
)
export type WopiActionTypeKey = Exclude<
  keyof typeof WopiActionType,
  'UNKNOWN_ACTION_TYPE'
>
export function isWopiActionTypeKey(value: any): value is WopiActionTypeKey {
  return wopiActionKeys.includes(value)
}
export function getWopiActionTypeKey(
  value: any,
  defaultValue: WopiActionTypeKey = 'EMBEDVIEW',
): WopiActionTypeKey {
  if (isString(value)) {
    const upper = value.toUpperCase()
    if (isWopiActionTypeKey(upper)) {
      return upper
    }
  }
  return defaultValue
}
let wopiServiceClient: WopiServicePromiseClient
export function getWopiServiceClient() {
  if (!wopiServiceClient) {
    wopiServiceClient = withGrpcClientMiddleware(
      'wopi',
      new WopiServicePromiseClient(env.grpcApi),
    )
  }
  return wopiServiceClient
}

export interface GetWopiLinksRequest {
  path: string
  action?: WopiActionType
  shareToken?: string
}

export function generateWopiLink(request: GetWopiLinksRequest) {
  const current_origin = window.location.origin.replace(
    'http://localhost:3300',
    'https://www.jottacloud.com',
  )

  const current_uri = `${current_origin}${window.location.pathname}`
  const folder_uri = current_uri.substring(0, current_uri.lastIndexOf('/'))

  const req = new GetLinksRequest()
  req.setActionType(request.action || WopiActionType.EMBEDVIEW)
  req.setPath(request.path)
  req.setDownloadLink(folder_uri)
  req.setEditLink(`${current_uri}?action=edit`)
  req.setViewLink(`${current_uri}?action=view`)
  req.setFolderLink(folder_uri)
  req.setCloseLink(folder_uri)

  return getWopiServiceClient().getLinks(
    req,
    request.shareToken ? getMeta({}, request.shareToken) : undefined,
  )
}

export async function updateConsent(request: UpdateWopiConsentRequest) {
  return await getWopiServiceClient().updateWopiConsent(request)
}
