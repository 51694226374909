import type { GetPathResponse } from '@jotta/grpc-web/no/jotta/openapi/file/file.v2_pb'
import {
  GetPathRequest,
  ItemType,
} from '@jotta/grpc-web/no/jotta/openapi/file/file.v2_pb'
import type { PathItemObject } from '@jotta/types/Files'
import Debug from 'debug'
import type { SortDir } from '../fileService'
import { getFileServiceClient } from '../fileService'
import { getTranslatedFilenameFromApiPath } from '../getTranslatedFilenameFromApiPath'
import { getAuthMeta } from '../grpcutils'
import { parsePathItem } from './parsePathItem'
import type { FileRouteContext } from '@jotta/types/schemas/FileRouteSchema'
import { getAccessToken } from '@jotta/auth-client/useAuthStatus'

export type GetPathItemSortDir = Exclude<SortDir, 'UNKNOWN'>

const debug = Debug('jotta:grpc:file:getPathItem')

export interface GetPathParams {
  context?: FileRouteContext
  path: string
  includeChildren?: boolean
  includeDeleted?: boolean
  includeDownloadLinks?: boolean
  includeThumb?: boolean
  sort?: GetPathItemSortDir
  token?: string
  cursor?: string
  limit?: number
}
export type GetPathOptions = Required<Omit<GetPathParams, 'token'>> & {
  token?: string
}

export interface PathItemObjectResponse {
  cursor: string
  item: PathItemObject
  isFile: boolean
  isFolder: boolean
  options: GetPathOptions
}

/**
 * Set defaults for all undefined GetPathParams
 * @param params Optional params
 * @returns Params with defaults set
 */
export function parseGetPathParams(params: GetPathParams): GetPathOptions {
  const {
    context = 'sync',
    path,
    sort = 'NAME_ASC',
    includeChildren = true,
    includeDeleted = false,
    includeDownloadLinks = false,
    includeThumb = false,
    token = getAccessToken(),
    limit = includeChildren ? 100 : 1,
    cursor = '',
  } = params
  return {
    context,
    path,
    sort,
    includeDeleted,
    includeChildren,
    includeDownloadLinks,
    includeThumb,
    token,
    limit,
    cursor,
  }
}

export async function baseGetPath(params: GetPathParams) {
  const o = parseGetPathParams(params)
  const meta = await getAuthMeta(o.token)
  const sorting = GetPathRequest.Sort[o.sort]
  const getPathRequest = new GetPathRequest()
  getPathRequest.setPath(o.path)
  getPathRequest.setSort(sorting)
  getPathRequest.setIncludeChildren(o.includeChildren)
  getPathRequest.setIncludeDeleted(o.includeDeleted)
  getPathRequest.setIncludeThumb(o.includeThumb)
  getPathRequest.setLimit(o.limit)
  if (o.cursor) {
    getPathRequest.setCursor(o.cursor)
  }
  const response = await getFileServiceClient().getPath(getPathRequest, meta)
  const cursor = response.getCursor()
  const pathItem = response.getPathItem()

  if (!pathItem) {
    throw new Error('pathItem missing from getPath response')
  }
  return {
    options: o,
    cursor,
    pathItem,
  }
}
export async function getPath(
  params: GetPathParams,
): Promise<PathItemObjectResponse> {
  const o = parseGetPathParams(params)
  const meta = await getAuthMeta(o.token)
  const sorting = GetPathRequest.Sort[o.sort]
  const getPathRequest = new GetPathRequest()
  getPathRequest.setPath(o.path)
  getPathRequest.setSort(sorting)
  getPathRequest.setIncludeChildren(o.includeChildren)
  getPathRequest.setIncludeDeleted(o.includeDeleted)
  getPathRequest.setIncludeThumb(o.includeThumb)
  getPathRequest.setLimit(o.limit)
  if (o.cursor) {
    getPathRequest.setCursor(o.cursor)
  }
  const getPathRequestPromise = getFileServiceClient().getPath(
    getPathRequest,
    meta,
  )
  const fetchBackupChildren = Boolean(
    o.path === '/backup' && o.includeChildren && !o.cursor,
  )

  const requestPromises: [
    mainRequestPromise: Promise<GetPathResponse>,
    ...additionalChildrenRequestPromises: Promise<GetPathResponse>[],
  ] = [getPathRequestPromise]
  if (fetchBackupChildren) {
    const contactsRequest = new GetPathRequest()
    contactsRequest.setPath('/contacts')
    contactsRequest.setSort(sorting)
    contactsRequest.setIncludeChildren(false)
    contactsRequest.setIncludeDeleted(o.includeDeleted)
    contactsRequest.setIncludeThumb(o.includeThumb)
    contactsRequest.setLimit(1)
    requestPromises.push(getFileServiceClient().getPath(contactsRequest, meta))
    const photosRequest = new GetPathRequest()
    photosRequest.setPath('/photos')
    photosRequest.setSort(sorting)
    photosRequest.setIncludeChildren(false)
    photosRequest.setIncludeDeleted(o.includeDeleted)
    photosRequest.setIncludeThumb(o.includeThumb)
    photosRequest.setLimit(1)
    requestPromises.push(getFileServiceClient().getPath(photosRequest, meta))
  }
  const [response, ...additionalChildrenResponses] =
    await Promise.all(requestPromises)
  const cursor = response.getCursor()
  const pathItem = response.getPathItem()

  if (!pathItem) {
    throw new Error('pathItem missing from getPath response')
  }
  const additionalChildren = additionalChildrenResponses.length
    ? additionalChildrenResponses.map(res => {
        const pathItem = res.getPathItem()
        if (!pathItem) {
          throw new Error('pathItem missing from getPath response')
        }
        pathItem.setName(getTranslatedFilenameFromApiPath(pathItem.getName()))
        return pathItem
      })
    : undefined

  const item = await parsePathItem({
    pathItem,
    token: o.token,
    additionalChildren,
    ignoreDownloadLinks: !o.includeDownloadLinks,
    ignoreChildren: !o.includeChildren,
    responseCursor: cursor,
  })

  const isFile = item.type === ItemType.FILE
  return {
    cursor,
    options: o,
    item,
    isFile,
    isFolder: !isFile,
  }
}
