import type { PhotosApi } from '@jotta/types/PhotosApi'
import { useAlertListStore } from '@jotta/ui/AlertStore'
import { Plural, t, Trans } from '@lingui/macro'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { Fragment } from 'react'

import { Link } from 'react-router-dom'
import { getAlbumTitle } from '../Album/AlbumThumb/AlbumThumb'
import { useDispatchPhotoActionMutation } from '../PhotoActions/useDispatchPhotoActionMutation'
import styles from './SharedPhotosPage.module.scss'
export const copy = (txt: string) => {
  if (navigator.clipboard) {
    return navigator.clipboard.writeText(txt)
  }
  const t = document.createElement('textarea')
  t.style.position = 'fixed'
  t.style.top = '-200%'
  t.value = txt
  document.body.appendChild(t)
  t.focus()
  t.setSelectionRange(0, txt.length)
  const r = document.execCommand('copy')
  document.body.removeChild(t)
  return r ? Promise.resolve() : Promise.reject()
}

export interface SharedPhotosPageRowProps {
  album: PhotosApi.AlbumInfo
}
export const SharedPhotosPageRow = observer<SharedPhotosPageRowProps>(
  function SharedPhotosPageRow({ album }) {
    const { alert } = useAlertListStore()
    const mutation = useDispatchPhotoActionMutation('UNSHARE_ALBUM')

    return (
      <Fragment key={album.id}>
        <Link
          className={clsx(styles.thumb, styles.thumbColumn)}
          to={`/share/${album.shareInfo?.uri}`}
          aria-label={t`Copy shared photos or shared album link`}
        >
          {album.thumbUrl && <img src={album.thumbUrl} alt={album.title} />}
        </Link>
        <div className={clsx(styles.column, styles.titleColumn)}>
          <h3>{getAlbumTitle(album)}</h3>
          <Link
            to={`/share/${album.shareInfo?.uri}`}
            aria-label={t`Copy shared photos or shared album link`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Trans>View photos</Trans>
          </Link>
        </div>
        <div className={clsx(styles.column, styles.contentColumn)}>
          {album.isSharedPhotos ? (
            <Plural value={album.total} one="# photo" other="# photos" />
          ) : album.total ? (
            <Plural
              value={album.total}
              one="Album with # photo"
              other="Album with # photos"
            />
          ) : (
            <Trans>AlbumContainer.emptyAlbum</Trans>
          )}
        </div>
        <div className={clsx(styles.column, styles.createdColumn)}>
          {album && dayjs(album.createdDate, { utc: false }).fromNow()}
        </div>
        <div className={clsx(styles.column, styles.copyLinkColumn)}>
          <div className={styles.copylink}>
            <div className={styles.link}>
              {window.location.origin}/share/
              {album.shareInfo?.uri}
            </div>
            <button
              aria-label={t`Copy shared photos or shared album link`}
              className={styles.copyLinkButton}
              onClick={() => {
                const shareUri = album.shareInfo?.uri
                if (shareUri) {
                  copy(
                    `${window.location.origin}/share/${album.shareInfo?.uri}`,
                  ).then(() => {
                    alert.showAlert({
                      message: <Trans id="Copied to clipboard" />,
                      severity: 'info',
                    })
                  })
                }
              }}
            >
              <Trans>Copy</Trans>
            </button>
          </div>

          <button
            className={styles.removelink}
            disabled={mutation.isPending}
            aria-label={t`Remove shared photos or shared album link`}
            onClick={() => {
              mutation.mutateAsync([album.id])
            }}
          >
            <Trans>Remove link</Trans>
          </button>
        </div>
        <hr />
      </Fragment>
    )
  },
)
