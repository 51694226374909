import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { DialogTrigger } from '@radix-ui/react-dialog'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { HidePhotoDialog } from '../Album/Dialogs/HidePhotoDialog'
import type { PhotoActionHandlerActionProps } from './PhotoActionHandlers'
import { useDispatchPhotoActionMutation } from './useDispatchPhotoActionMutation'
const debug = Debug('jotta:photos:action:HidePhotos')

export const ActionHidePhotos = observer<
  PhotoActionHandlerActionProps<'HIDE_PHOTO'>
>(function ActionHidePhotos({
  action,
  photoIds = [],
  dispatch,
  buttonVariant = 'buttons.albumActionButton',
  onActionDialogClose,
  disabled = false,
}) {
  const [open, setOpen] = useState(false)
  const mutation = useDispatchPhotoActionMutation(
    'HIDE_PHOTO',
    {
      onSettled: () => setOpen(false),
    },
    dispatch,
  )
  debug(open)

  return (
    <HidePhotoDialog
      open={open}
      onClose={open => {
        setOpen(open)
        if (!open && onActionDialogClose) {
          onActionDialogClose(action.actionType)
        }
      }}
      onHidePhoto={() => !disabled && mutation.mutateAsync([photoIds])}
      count={photoIds.length}
      isLoading={mutation.isPending}
      triggerElement={
        <DialogTrigger
          aria-label={action.label}
          data-testid={action.testid}
          aria-busy={mutation.isPending}
          disabled={disabled}
          key={action.actionType}
          sx={{
            variant: buttonVariant,
          }}
        >
          <PlainBrandIcon icon={action.icon} />
          <span>{action.label}</span>
        </DialogTrigger>
      }
    />
  )
})
