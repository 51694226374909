// import { ScheduledPromise } from '@jotta/files'
import type { PromiseQueue } from '@jotta/promiseq'
import { queue } from '@jotta/promiseq'
import { AbortError } from '@jotta/types/AbortError'
import type { AppError } from '@jotta/types/AppError'
import { handleUnknownError } from '@jotta/types/AppError'
import type { Photos } from '@jotta/types/Photos'
import type { PhotosApi } from '@jotta/types/PhotosApi'
import { calculateFileMd5 } from '@jotta/utils/crypto'
import Debug from 'debug'
import { action, makeAutoObservable, observable, reaction } from 'mobx'
import type { ChangeEvent } from 'react'
import type { PhotoStore } from '../store/PhotoStore'
import { allocate, uploadXhr } from './photosApi'
const debug = Debug('jotta:photos:PhotoUploadCollection')

type Status = 'queued' | 'pending' | 'resolved' | 'rejected' | 'aborted'
export type PhotoUploadCounts = Record<Status, number>

export class PhotoUpload {
  private _abortController = new AbortController()
  private _hashProgress: number = 0
  private _uploadProgress: number = 0
  private _response: PhotosApi.UploadMediaResponse | null = null
  private _error: AppError | null = null
  private _p: Promise<PhotosApi.UploadMediaResponse | null>
  private log: Debug.Debugger
  public status: Status = 'queued'
  public statusUpdatedAt = Date.now()

  public get type() {
    return this._file.type
  }

  public get size() {
    return this._file.size
  }

  public get progress() {
    return this._uploadProgress
  }

  constructor(
    private _file: File,
    private _queue: PromiseQueue,
    public api = {
      allocate,
      upload: uploadXhr,
    },
    public albumId = '',
  ) {
    this.log = Debug(`jotta:photos:upload:${this.file.name}`)
    this.log.enabled = true

    this.signal.addEventListener('abort', () => {
      this.setStatus('aborted')
    })

    const abortCheck = () => {
      if (this.signal.aborted) {
        throw new AbortError()
      }
    }

    const fn = async () => {
      abortCheck()

      this.setStatus('pending')
      this.log('upload pending')

      const md5 = await this.md5
      abortCheck()
      this.log('Hash:', md5)
      const allocation = await api.allocate({
        filename: this.file.name,
        size: this.file.size,
        createdDate: new Date(
          this.file.lastModified || Date.now(),
        ).toISOString(),
        modifiedDate: new Date(
          this.file.lastModified || Date.now(),
        ).toISOString(),
        md5,
      })
      abortCheck()
      this.log('Allocate done: ', allocation)

      if (allocation.content === 'image' || allocation.content === 'video') {
        this.response = allocation
        this.setStatus('resolved')
        this.uploadProgress = this.size
        this.log('Allocate is UploadMediaResponse')
        return allocation
      }

      if (allocation.code) {
        throw new Error(allocation.cause)
      }

      this.uploadProgress = 0

      const upload_id = allocation.upload_id
      if (!upload_id) {
        this.log('invalid allocation response')
        throw new Error('Invalid allocation response')
      }

      this.log('Start upload')
      const response = await this.api.upload(
        { upload_id, file: this._file },
        e => {
          this.uploadProgress = e.loaded // e.total
          this.log('Progress update', this._uploadProgress)
        },
        this.signal,
      )

      this.response = response
      this.log('response', response)
      this.setStatus('resolved')

      return response
    }

    this._p = this._queue.schedule(fn, this.signal).catch(e => {
      return this.handleError(e)
    })

    makeAutoObservable<
      typeof this,
      'setResponse' | 'setStatus' | '_file' | '_reader'
    >(
      this,
      {
        api: observable.ref,
        _file: observable.ref,
        setResponse: action.bound,
        setStatus: action.bound,
        _reader: observable.ref,
      },
      { autoBind: true },
    )
  }

  handleError(e: unknown) {
    const error = handleUnknownError(e)
    this.log('error', error.name, error.status)
    this._error = error
    if (this.status !== ('aborted' as Status)) {
      this.setStatus('rejected')
    }
    return null
  }

  get thumb() {
    const url = this._response?.thumbnail_url
    if (url) {
      return `${url}.s`
    }
    return ''
  }

  private setStatus(status: Status) {
    this.status = status
    this.statusUpdatedAt = Date.now()
  }

  private get md5() {
    return calculateFileMd5({
      file: this.file,
      chunkSizeStr: '1mb',
      progressCallback: completed => {
        this._hashProgress = completed / this.file.size
      },
      signal: this.signal,
    })
  }

  public get id() {
    return this.response?.id || ''
  }

  public get file() {
    return this._file
  }

  public get filename() {
    return this._file.name
  }

  public get signal() {
    return this._abortController.signal
  }

  public cancel() {
    this._abortController.abort()
  }

  public get response() {
    return this._response
  }

  private set response(response: PhotosApi.UploadMediaResponse | null) {
    this._response = response
  }

  public asAsync() {
    return this._p
  }

  public get error() {
    return this._error || undefined
  }

  public get hashProgress() {
    return this._hashProgress
  }

  public get uploadProgress() {
    return this._uploadProgress
  }
  public set uploadProgress(progress) {
    this._uploadProgress = progress
  }
}

export class PhotoUploadCollection {
  private queue: PromiseQueue
  public uploads = observable.array<PhotoUpload>([])

  constructor(
    public photo: PhotoStore,
    public api = {
      allocate,
      upload: uploadXhr,
    },
  ) {
    makeAutoObservable(
      this,
      {
        api: observable.ref,
      },
      {
        autoBind: true,
      },
    )

    this.queue = queue
    reaction(
      () => [this.hasCompleted, this.total],
      () => {
        if (this.hasCompleted && this.total > 0) {
          if (this.uploadIdsByAlbumId) {
            Promise.all(
              Object.entries(this.uploadIdsByAlbumId).map(([albumId, ids]) =>
                this.photo.actions.addSelectedPhotosToAlbum(albumId, ids),
              ),
            )
          }
        }
      },
    )
  }

  public upload(files: File[] | FileList) {
    files = Array.from(files)
    const albumId = this.photo.currentAlbumId
    const uploads = files.map(
      file => new PhotoUpload(file, this.queue, this.api, albumId),
    )

    for (const upload of uploads) {
      reaction(
        () => [upload.status],
        () => {
          if (upload.status === 'resolved' && upload.response) {
            const photos = [upload.response] as Photos.Media[]
            this.photo.mediaObjects.setPhotos(photos)
            this.photo.timeline.addFromUpload(photos)
          }
        },
      )
    }

    this.uploads.push(...uploads)

    return uploads
  }

  public onFileInputChange(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files?.length) {
      this.upload(Array.from(event.target.files))
      event.target.value = ''
    }
  }

  public get total() {
    return this.uploads.length
  }

  public get completed() {
    return this.uploads.filter(upload => upload.status === 'resolved').length
  }

  public get hasErrors() {
    return !this.uploads.every(upload => upload.error === null)
  }

  public get hasCompleted() {
    return Boolean(
      this.uploads.every(
        upload => !['queued', 'pending'].includes(upload.status),
      ),
    )
  }

  public get pending() {
    return this.uploads.find(u => u.status === 'pending')
  }

  public get thumb() {
    let thumb = ''
    for (let i = this.uploads.length - 1; i >= 0; i--) {
      const upload = this.uploads[i]
      if (upload.status === 'resolved' && upload.thumb) {
        thumb = upload.thumb
        break
      }
    }
    return thumb
  }

  public get type() {
    return this.pending?.type || ''
  }

  public get totalSize() {
    return this.uploads.reduce((total, upload) => total + upload.size, 0)
  }

  public get pendingUploads() {
    return this.uploads.filter(upload =>
      ['pending', 'queued'].includes(upload.status),
    )
  }

  public cancel() {
    for (const upload of this.pendingUploads) {
      upload.cancel()
    }
  }

  public clear() {
    this.cancel()
    this.uploads.clear()
  }

  public get photoIds() {
    return this.uploads.map(upload => upload.id).filter(id => id)
  }

  public get status() {
    return createPhotoUploadStats(this.uploads, this.type, this.thumb)
  }

  public get uploadIdsByAlbumId() {
    if (this.status.uploadsCompleted) {
      return this.status.idsByAlbum
    }
    return null
  }
}

export function createPhotoUploadStats(
  uploads: {
    id: string
    status: Status
    filename?: string
    albumId?: string
    error?: AppError
    size: number
    progress: number
  }[] = [],
  type: string,
  thumbSrc = '',
) {
  const total = uploads.length
  const idsByAlbum: Record<string, string[]> = {}
  const stats = {
    queued: 0,
    pending: 0,
    resolved: 0,
    rejected: 0,
    aborted: 0,
    progress: 0,
    total,
    ids: [] as string[],
    errors: [] as {
      filename: string
      message: string
      type: string
      xid: string
      code: number
    }[],
    type,
    thumbSrc,
    get state() {
      if (this.isIdle) {
        return 'idle'
      }
      if (this.isUploading) {
        return 'uploading'
      }
      if (this.uploadsCompletedSuccessfully) {
        return 'uploadsCompleted'
      }
      if (this.uploadsCompletedWithErrors) {
        return 'uploadsCompletedWithErrors'
      }
      if (this.hasErrors) {
        return 'error'
      }
    },
    get isIdle() {
      return !this.hasUploads
    },
    get uploading() {
      return this.queued + this.pending
    },
    get failed() {
      return this.aborted + this.rejected
    },
    get isUploading() {
      return Boolean(this.uploading)
    },
    // All uploads completed, possibly with errors
    get uploadsCompleted() {
      return !this.isUploading && this.hasUploads
    },
    // All uploads completed without errors
    get uploadsCompletedSuccessfully() {
      return this.resolved === this.total
    },
    // All uploads completed, at least one successfully, some have errors
    get uploadsCompletedWithErrors() {
      return !this.uploading && this.hasSuccessfulUploads && this.hasErrors
    },
    // All uploads failed
    get uploadingFailed() {
      return !this.uploading && !this.hasSuccessfulUploads && this.hasErrors
    },
    get hasUploads() {
      return Boolean(this.total)
    },
    get hasSuccessfulUploads() {
      return Boolean(this.resolved)
    },
    get hasErrors() {
      return Boolean(this.rejected || this.aborted)
    },
  }
  let totalSize = 0
  let completed = 0
  const timeline: string[] = []

  for (const upload of uploads) {
    const { id, error, filename = 'unknown' } = upload
    const albumId = upload.albumId
    if (error) {
      stats.errors.push({
        filename,
        code: error.status || 0,
        message: error.message,
        xid: error.xid || 'unknown',
        type: error.name,
      })
    }
    if (id) {
      stats.ids.push(id)
      if (albumId) {
        const arr = idsByAlbum[albumId] || []
        arr.push(id)
        idsByAlbum[albumId] = arr
      } else {
        timeline.push(id)
      }
    }
    stats[upload.status]++
    totalSize += upload.size
    completed += upload.progress
    stats.progress = totalSize ? completed / totalSize : 0
  }

  return {
    ...stats,
    idsByAlbum,
    isAlbumUpload: !timeline.length,
  }
}
