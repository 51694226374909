// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PeopleBreadCrumb_container__3Qdfh{display:flex;flex:1 1 auto;flex-flow:row nowrap;font-size:var(--fz1);height:100%;list-style:none;margin:0;padding:0;padding:0 var(--s3);position:relative;text-transform:uppercase;white-space:nowrap;width:100%}.PeopleBreadCrumb_container__3Qdfh>li{flex-flow:row nowrap;padding:3px}.PeopleBreadCrumb_container__3Qdfh>li,.PeopleBreadCrumb_container__3Qdfh>li>a{display:flex;overflow:hidden;text-overflow:ellipsis}.PeopleBreadCrumb_container__3Qdfh>li>a{align-content:center;align-items:center;border-radius:var(--radius1);flex:1 1 auto;padding:0 var(--s1)}.PeopleBreadCrumb_container__3Qdfh>li>a:first-child{padding-left:var(--s2)}body.br-1-up .PeopleBreadCrumb_container__3Qdfh>li>a{padding:0 var(--s2)}.PeopleBreadCrumb_container__3Qdfh>li>svg{align-self:center;flex:0 0 auto;height:12px;width:12px}`, "",{"version":3,"sources":["webpack://./../../libraries/photos/src/people/internal/components/PeopleBreadCrumb/PeopleBreadCrumb.module.scss","webpack://./../../libraries/ui/sass/mixins.scss"],"names":[],"mappings":"AACA,mCAEE,YAAA,CADA,aAAA,CAEA,oBAAA,CAQA,oBAAA,CANA,WAAA,CACA,eAAA,CAGA,QAAA,CACA,SAAA,CAGA,mBAAA,CAEA,iBAAA,CAHA,wBAAA,CAEA,kBAAA,CAVA,UAWA,CAEA,sCAEE,oBAAA,CAGA,WAAA,CACA,8EALA,YAAA,CAEA,eAAA,CACA,sBAUE,CARF,wCAME,oBAAA,CACA,kBAAA,CANA,4BAAA,CAEA,aAAA,CAKA,mBAAA,CACA,oDACE,sBAAA,CC4BJ,qDDzBI,mBAAA,CAGJ,0CACE,iBAAA,CACA,aAAA,CAEA,WAAA,CADA,UACA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PeopleBreadCrumb_container__3Qdfh`
};
export default ___CSS_LOADER_EXPORT___;
