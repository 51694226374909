import { getFileKindConfig } from '@jotta/file-utils'
import {
  ItemAction,
  ItemKind,
  ItemType,
} from '@jotta/grpc-js-client/fileService'
import { observableLocale } from '@jotta/i18n'
import { queryClient } from '@jotta/query'
import type { FileActionContext } from '@jotta/types/FileActions'
import type { PathItemObject } from '@jotta/types/Files'
import { filesize } from '@jotta/utils/filesize'
import { t } from '@lingui/macro'
import Debug from 'debug'
import { computed, makeAutoObservable, observable, set, toJS } from 'mobx'
import { createViewModel } from 'mobx-utils'
import { counting } from 'radash'
import type { ChangeEvent, FormEvent } from 'react'
import { getFileActionMenuItems } from '../FileContextMenu/getFileActionMenuItems'
import type { FolderStore } from '../Folder/FolderStore'
import { usePathItem } from './hooks/usePathItem'
const debug = Debug('jotta:files:pathitem:store')

export class PathItemStore {
  // #region Properties (8)

  private _item: PathItemObject
  private log

  public downloadLink = ''
  public itemHash = ''
  model
  public permanentlyDeletedAt = 0
  public updatedAt = 0

  // #endregion Properties (7)

  // #region Constructors (1)

  constructor(
    name: string,
    item: Partial<PathItemObject>,
    readonly folder: FolderStore,
    public virtual = false,
  ) {
    this.log = debug.extend(name)
    this.log.enabled = false
    this._item = makeAutoObservable(
      {
        name,
        childrenList: [],
        actionList: [],
        checksum: '',
        commentAuthToken: '',
        commentItemId: '',
        createdAtMillis: 0,
        deletedAtMillis: 0,
        downloadLink: '',
        encodedContentRef: '',
        folderShareId: '',
        kind: ItemKind.UNKNOWN_ITEM_KIND,
        mime: '',
        modifiedAtMillis: 0,
        owner: '',
        path: '',
        publicLinkId: '',
        size: 0,
        thumbLink: '',
        type: ItemType.UNKNOWN_ITEM_TYPE,
        contentId: '',
        ...item,
      },
      {
        childrenList: false,
        createdAtMillis: false,
        downloadLink: false,
        commentAuthToken: false,
        commentItemId: false,
      },
    )

    makeAutoObservable<typeof this, '_item' | 'log'>(
      this,
      {
        _item: observable.deep,
        log: false,
        onSelectCheckboxChange: false,
        onContextMenuSelect: false,
        contextMenuActions: computed.struct,
        listItemData: computed({
          keepAlive: true,
        }),
      },
      {
        name,
        autoBind: true,
      },
    )
    this.model = createViewModel(this._item)
  }

  // #endregion Constructors (1)

  // #region Public Accessors (34)

  public get canUpload() {
    return this.isFolder && this._item.actionList.includes(ItemAction.ADD_CHILD)
  }

  public get selectionCount() {
    return {
      total: this.folder.selectedChildren.length,
      ...counting(this.folder.selectedChildren, item =>
        item.isFile ? t`files` : t`folders`,
      ),
    }
  }
  public get contextMenuActions() {
    const actionItems = getFileActionMenuItems(
      this.rowActionContext,
      toJS(this.model),
    )

    this.log('contextMenuActions', this.rowActionContext, actionItems)
    return actionItems
  }

  public get data() {
    return this._item
  }

  public set data(item: PathItemObject) {
    if (item.downloadLink && item.downloadLink !== this.downloadLink) {
      this.downloadLink = item.downloadLink
    }
    this.log('set data')
    set(
      this._item,
      structuredClone({
        ...item,
        downloadLink: '',
        childrenList: [],
      }),
    )
  }

  public get dateLabel() {
    if (!this.data.modifiedAtMillis) {
      return ''
    }
    if (this.folder.root.branding.isMobileTimeline) {
      return observableLocale.dateFormatterShort.format(
        this.data.modifiedAtMillis,
      )
    } else {
      return observableLocale.dateTimeFormatterMedium.format(
        this.data.modifiedAtMillis,
      )
    }
  }

  public get deletedAtMillis() {
    return this.model.deletedAtMillis
  }

  public set deletedAtMillis(n: number) {
    this._item.deletedAtMillis = n
  }

  public get displayPath() {
    return this.folder.route.isTrashOrSearch
  }

  public get fabMenuActions() {
    // Rerun on breakpoint change
    if (this.folder.root.branding.currentBreakpoint > -1) {
      getFileActionMenuItems.clear()
    }
    return getFileActionMenuItems('FAB', toJS(this.model), true)
  }

  public get fileKindConfig() {
    return getFileKindConfig(this.model.kind)
  }

  public get fileSizeLabel() {
    if (this.isFolder && !this.data.size) {
      return ''
    }

    if (typeof this.data.size === 'number' && this.data.size !== 0) {
      let roundValue = 0
      let sizeInBytes = this.data.size

      if (sizeInBytes >= 1024 * 1024 * 1024 * 1024) {
        // TB
        sizeInBytes /= 1024 * 1024 * 1024 * 1024
      } else if (sizeInBytes >= 1024 * 1024 * 1024) {
        // GB
        sizeInBytes /= 1024 * 1024 * 1024
      } else if (sizeInBytes >= 1024 * 1024) {
        // MB
        sizeInBytes /= 1024 * 1024
      } else if (sizeInBytes >= 1024) {
        // KB
        sizeInBytes /= 1024
      }

      if (sizeInBytes < 10) {
        roundValue = 1
      }

      return filesize(this.data.size, {
        round: roundValue,
        locale: observableLocale.locale,
      })
    }

    return ''
  }
  public get isContacts() {
    return this.data.path.toLocaleLowerCase() === '/contacts'
  }

  public get isDeleted() {
    return Boolean(this.model.deletedAtMillis)
  }

  public get isDevice() {
    return [
      ItemKind.WORKSTATION,
      ItemKind.LAPTOP,
      ItemKind.MACBOOK,
      ItemKind.IMAC,
      ItemKind.MACPRO,
      ItemKind.IPHONE,
      ItemKind.IPAD,
      ItemKind.ANDROID,
      ItemKind.WINDOWS_PHONE,
      ItemKind.WINDOWS_TABLET,
      ItemKind.CLI,
    ].includes(this.model.kind)
  }

  public get isFile() {
    return this.model.type === ItemType.FILE
  }

  public get isModule() {
    return this.model.kind === ItemKind.MODULE
  }

  public get isFolder() {
    return this.model.type === ItemType.FOLDER
  }

  public get isOwner() {
    return this.folder.root.username === this._item.owner
  }

  public get isSharedFolder() {
    return Boolean(this.model.folderShareId)
  }

  public get isPhotos() {
    return this.data.path.toLocaleLowerCase() === '/photos'
  }

  public get isSearch() {
    return Boolean(this.data.path.startsWith('/search'))
  }

  public get isSelected() {
    return (
      this.isVisible && this.folder.selection._selection.has(this.model.path)
    )
  }

  public get isShared() {
    return Boolean(this.model.publicLinkId)
  }
  public set isShared(isShared) {
    if (isShared && !this.model.publicLinkId) {
      this.model.publicLinkId = '...'
    }
    if (!isShared && this.model.publicLinkId) {
      this.model.publicLinkId = ''
    }
  }

  public get isSync() {
    return this.data.path.toLowerCase() === '/sync'
  }

  public get isTrash() {
    return this.data.path.toLocaleLowerCase() === '/trash'
  }

  public get isVisible() {
    return (
      !this.permanentlyDeletedAt && this.isDeleted === this.folder.route.isTrash
    )
  }

  public get listItemData() {
    return {
      onContextMenuSelect: this.onContextMenuSelect,
      contextMenuActions: this.contextMenuActions,
      dateLabel: this.dateLabel,
      displayPath: this.displayPath,
      fileSizeLabel: this.fileSizeLabel,
      fileTypeLabel: this.fileKindConfig.label,
      icon: this.fileKindConfig.icon,
      isShared: this.isShared,
      isVisible: this.isVisible,
      name: this.name,
      path: this.path,
      thumbLink: this.thumbLink,
    }
  }

  public get name() {
    return this.model.name
  }

  public get onContextMenuSelect() {
    return this.folder.actions.onContextMenuSelect
  }

  public get onSelectCheckboxChange() {
    return this.folder.selection.onSelectCheckboxChange
  }

  public get path() {
    return this.model.path
  }

  public get rowActionContext(): FileActionContext {
    if (this.folder.selectedChildren.length > 1) {
      return 'SELECTION'
    } else {
      return 'FOLDER_LIST'
    }
  }

  public get selectionActions() {
    return getFileActionMenuItems('SELECTION', toJS(this.model))
  }

  public get showRenameInput() {
    return (
      this.folder.actions.activeFileAction.type === 'Rename' &&
      this.folder.actions.activeFileAction.path === this.path
    )
  }

  public get thumbLink() {
    if (!this.model.thumbLink) {
      return undefined
    }
    if (this.folder.route.viewMode === 'list') {
      return this.model.thumbLink
    }
    return `${this.model.thumbLink}?size=130x130`
  }

  public get isSearchResult() {
    return Boolean(this.folder.pathItem.isSearch)
  }

  // #endregion Public Accessors (39)

  // #region Public Methods (11)

  public delete() {}

  public invalidate() {
    this.log('invalidate')
    if (this.virtual) {
      return
    }
    queryClient.invalidateQueries({
      queryKey: [
        usePathItem.getKey({
          path: this.path,
          context: this.folder.route.context,
        }),
      ],
    })
    this.refetch()
  }

  public onNameInputChange(e: ChangeEvent<HTMLInputElement>) {
    if (this.showRenameInput) {
      this.model.name = e.target.value
    }
  }

  public onRenameCancel(open?: boolean) {
    if (!open && this.showRenameInput) {
      this.model.reset()
      this.folder.actions.cancelActiveAction()
    }
  }

  public onRenameSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    this.folder.actions.rename({ path: this.path, newName: this.model.name })
  }

  public async refetch() {
    if (this.virtual) {
      return
    }
    const response = await queryClient.fetchQuery(
      usePathItem.getFetchOptions({
        path: this.path,
        context: this.folder.route.context,
      }),
    )

    this.log('refetch', response)
    this.data = response.item
  }

  public get isThumb() {
    return this.folder.route.viewMode === 'thumbs'
  }

  // #endregion Public Methods (6)
}
