import { Button } from '@jotta/ui/Button'
import { Paragraph } from '@jotta/ui/Text'
import { observer } from 'mobx-react-lite'

export interface ActiveSectionProps {
  handleSmartSearchRoute: (event: React.MouseEvent) => void
}

export const ActiveSection: React.FC<ActiveSectionProps> = observer(
  ({ handleSmartSearchRoute }) => {
    return (
      <>
        <Paragraph
          sx={{
            variant: 'styles.subHeaderText',
          }}
        >
          Congratualtions! You are active and have access to the smart photo
          search beta when you go to your photo timeline. For easy access click
          this button below.
        </Paragraph>
        <Button
          onClick={handleSmartSearchRoute}
          type="submit"
          variant="buttons.download"
        >
          Go to Smart Search Beta
        </Button>
      </>
    )
  },
)
