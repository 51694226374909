import { downloadWithFormData } from '@jotta/utils/download'
import Debug from 'debug'
import type { ObservableSet } from 'mobx'
import {
  action,
  makeAutoObservable,
  observable,
  runInAction,
  values,
} from 'mobx'
import type { FormEvent } from 'react'
import type { PersonPhoto } from '../../api/PeopleApi'
import { shareGroupOfPhotos, unshareGroupOfPhotos } from '../../api/PeopleApi'
import { getOrRefreshAccessToken } from '@jotta/auth-client/useAuthStatus'

const debug = Debug('jotta:people:PersonPhotoListStore')

type PersonPhotoListAction =
  | 'addToAlbum'
  | 'shareGroupOfPhotos'
  | 'downloadSelectedPhotos'
  | 'none'

export class PersonPhotoListStore {
  // #region Properties (9)

  public activeAction: PersonPhotoListAction = 'none'
  public lastIndex = -1
  public loadingAction: PersonPhotoListAction = 'none'
  public photos: PersonPhoto[] = []
  public selectedIds = new Set<string>() as ObservableSet<string>
  public shareAlbumId = ''
  public shareId = ''
  public shiftPressed = false
  public viewMode: 'cover' | 'contain' = 'cover'

  // #endregion Properties (9)

  // #region Constructors (1)

  constructor() {
    makeAutoObservable(
      this,
      {
        photos: observable.ref,
        onKeyboardShiftPress: action.bound,
      },
      {
        autoBind: true,
      },
    )
  }

  // #endregion Constructors (1)

  // #region Public Accessors (4)

  public get ids() {
    return this.photos.map(p => p.md5)
  }

  public get selectedIdsAsArray() {
    return [...values(this.selectedIds)]
  }

  public get selectedPhotos() {
    return this.photos.filter(p => this.selectedIds.has(p.md5))
  }

  public get showSelectionManager() {
    return Boolean(this.selectedIds.size)
  }

  // #endregion Public Accessors (4)

  // #region Public Methods (13)

  public albumOpen(open?: boolean) {
    this.activeAction = open ? 'addToAlbum' : 'none'
  }

  public cancelActiveAction(open?: boolean) {
    if (!open) {
      this.activeAction = 'none'
    }
  }

  public clearLoadingAction() {
    this.loadingAction = 'none'
  }

  public clearSelection(open?: boolean) {
    if (!open) {
      this.selectedIds.clear()
    }
  }

  public clearShareId() {
    this.shareId = ''
    this.shareAlbumId = ''
  }

  public closeSelectionManager() {
    this.activeAction = 'none'
    this.selectedIds.clear()
  }

  public async downloadOpen(open?: boolean) {
    if (open) {
      this.activeAction = 'downloadSelectedPhotos'
      const formdata = new FormData()
      formdata.append(
        'Authorization',
        `Bearer ${await getOrRefreshAccessToken()}`,
      )
      formdata.append('name', 'photos')
      for (const p of this.selectedPhotos) {
        formdata.append('ecr', p.encodedContentRef)
      }
      downloadWithFormData({
        url: 'https://zip.jotta.cloud/zip/',
        data: formdata,
        onComplete: () => debug('Success!'),
      })
    } else {
      this.closeSelectionManager()
    }
  }

  /**
   * Handle checkbox change events
   */
  public onFormChange(e: FormEvent<HTMLFormElement>) {
    // Element is the expected checkbox
    const el = e.target
    // debug(e, el, el instanceof HTMLInputElement, (el as any).name)
    if (el instanceof HTMLInputElement && el.name === 'photo') {
      const id = el.value
      const currentIndex = this.ids.indexOf(id)
      // Unexpected - ID not found
      if (currentIndex === -1) {
        // debug('Index not found', id)
        return
      }
      // Select or unselect range og photos when shift is pressed
      if (this.shiftPressed && this.lastIndex > -1) {
        // First and last index in sorted order to make shift select work
        // in either direction
        const [i1, i2] = [this.lastIndex, currentIndex].sort()
        const ids = this.ids.slice(i1, i2 + 1)
        for (const id of ids) {
          this.selectedIds.add(id)
        }
        this.lastIndex = currentIndex
        return
      }
      // Select or unselect single photo
      this.lastIndex = currentIndex
      if (el.checked) {
        this.selectedIds.add(id)
      } else {
        this.selectedIds.delete(id)
      }
    }
  }

  // Toggle shift pressed state
  public onKeyboardShiftPress(e: KeyboardEvent) {
    // debug('key', e)
    if (e.key !== 'Shift') {
      return
    }
    switch (e.type) {
      case 'keydown':
        {
          this.shiftPressed = true
        }
        break
      case 'keyup':
        {
          this.shiftPressed = false
        }
        break

      default:
        break
    }
  }

  public setShareId({
    albumId,
    shareId,
  }: {
    albumId: string
    shareId: string
  }) {
    this.shareId = shareId
    this.shareAlbumId = albumId
  }

  public shareOpen(open?: boolean) {
    this.activeAction = open ? 'shareGroupOfPhotos' : 'none'
  }

  public toggleShare(shared?: boolean) {
    const shareId = this.shareId
    const albumId = this.shareAlbumId
    this.loadingAction = 'shareGroupOfPhotos'
    if (!shared && albumId) {
      this.clearShareId()
      unshareGroupOfPhotos(albumId)
        .catch(() =>
          runInAction(() => {
            this.setShareId({
              albumId,
              shareId,
            })
          }),
        )
        .finally(this.clearLoadingAction)
      return
    }
    const selectedIds = this.selectedIdsAsArray
    this.setShareId({
      albumId: '...',
      shareId: '...',
    })
    shareGroupOfPhotos(selectedIds)
      .then(this.setShareId)
      .catch(this.clearShareId)
      .finally(this.clearLoadingAction)
  }

  public toggleViewmode() {
    if (this.viewMode === 'contain') {
      this.viewMode = 'cover'
    } else {
      this.viewMode = 'contain'
    }
  }

  // #endregion Public Methods (13)
}
