import { queryClient } from '@jotta/query'
import { asTrilean } from '@jotta/utils/trilean'
import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import Debug from 'debug'
import debounce from 'lodash/debounce'
import Cookies from 'universal-cookie'
import z from 'zod'

const debug = Debug('jotta:auth')
const cookies = new Cookies()
const AuthStatusKey = ['authstatus']

const AuthStatusSchema = z.object({
  authenticated: z.boolean(),
  refreshable: z.boolean(),
  username: z.string().nullable(),
  expiresAt: z.number().nullable(),
  expiresInMillis: z.number().nullable(),
  refreshableInMillis: z.number().nullable(),
  version: z.string(),
})

type AuthStatus = z.infer<typeof AuthStatusSchema>

/**
 * Prevent Axios from throwing on unauthenticated status codes
 * @param status HTTP status code
 * @returns True if valid status
 */
function validateAuthStatus(status: number): boolean {
  return status < 300 || status === 401 || status === 403
}

async function refetchAuthStatus() {
  await queryClient.invalidateQueries({ queryKey: AuthStatusKey })
  return queryClient.fetchQuery<ApiStatusType>({
    queryKey: AuthStatusKey,
    queryFn: getOrRefreshApiStatus,
  })
}

async function apiRefresh() {
  const log = debug.extend('/api/refresh')
  log('request')
  const res = await axios.get<AuthStatus>('/api/refresh', {
    validateStatus: validateAuthStatus,
  })
  log('response', res)

  return AuthStatusSchema.parse(res.data)
}

async function apiStatus() {
  const log = debug.extend('/api/status')
  try {
    log('request')
    const res = await axios.get<AuthStatus>('/api/status', {
      validateStatus: validateAuthStatus,
    })
    log('response', res)

    return AuthStatusSchema.parse(res.data)
  } catch (err) {
    // Something went wrong, try refresh
    log('response', err)
    return apiRefresh()
  }
}

let refreshTimeoutId = 0

async function getOrRefreshApiStatus(): Promise<{
  authenticated: boolean
  accessToken: string
  username: string | null
}> {
  const log = debug.extend('getOrRefreshApiStatus')
  window.clearTimeout(refreshTimeoutId)
  refreshTimeoutId = 0
  let status = await apiStatus()

  if (status.refreshable) {
    status = await apiRefresh()
  }

  const {
    authenticated,
    username,
    refreshableInMillis: refreshInMillis,
  } = status
  const accessToken = String(cookies.get('access_token'))

  if (typeof refreshInMillis === 'number' && refreshInMillis > 0) {
    const timeout = refreshInMillis + Math.round(Math.random() * 10_000)
    log(`Setting timeout for token refresh in ${timeout}ms`)
    refreshTimeoutId = window.setTimeout(() => refetchAuthStatus(), timeout)
  }

  return { authenticated, accessToken, username }
}

type ApiStatusType = Awaited<ReturnType<typeof getOrRefreshApiStatus>>

export function useAuthStatus() {
  return useQuery({
    queryKey: AuthStatusKey,
    queryFn: getOrRefreshApiStatus,
    placeholderData: keepPreviousData,
    refetchOnMount: false,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
  })
}

export function useAuthenticated() {
  return asTrilean(useAuthStatus().data?.authenticated)
}

export function ensureAuthStatus() {
  return queryClient.ensureQueryData({
    queryKey: AuthStatusKey,
    queryFn: getOrRefreshApiStatus,
  })
}

export function getAccessToken() {
  const data = queryClient.getQueryData<ApiStatusType>(AuthStatusKey)

  return data?.accessToken
}

export async function isAuthenticated() {
  return (await ensureAuthStatus()).authenticated
}

export const refreshAccessToken = debounce(
  async () => {
    const res = await refetchAuthStatus()
    return res.authenticated ? res.accessToken : ''
  },
  4_000,
  { leading: true, trailing: false, maxWait: 4_000 },
)

export function useRefreshAccessToken() {
  return useMutation({ mutationFn: refreshAccessToken })
}

export async function getOrRefreshAccessToken() {
  const status = await ensureAuthStatus()

  if (status.authenticated) {
    return status.accessToken
  }

  return refreshAccessToken()
}
