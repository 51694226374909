// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PersonPhotoList_list__nW8nP{flex:1 0 auto;overflow:hidden;padding:0 var(--s4);position:relative;width:100%}.PersonPhotoList_emptyThumb__LByVH{background:#fff;height:100%;opacity:.8;width:100%}.PersonPhotoList_actions__LMW9l{display:flex;flex:1 1 auto;padding:2px var(--s3);width:100%;z-index:3}.PersonPhotoList_viewmodeButton__GUs9j{border-radius:var(--radius1)}.PersonPhotoList_viewmodeButton__GUs9j svg{height:24px;width:24px}`, "",{"version":3,"sources":["webpack://./../../libraries/photos/src/people/internal/components/PersonPhotos/PersonPhotoList.module.scss"],"names":[],"mappings":"AACA,6BACE,aAAA,CAGA,eAAA,CACA,mBAAA,CAFA,iBAAA,CADA,UAGA,CAEF,mCACE,eAAA,CAGA,WAAA,CAFA,UAAA,CACA,UACA,CAEF,gCACE,YAAA,CACA,aAAA,CAGA,qBAAA,CAFA,UAAA,CACA,SACA,CAEF,uCACE,4BAAA,CAEA,2CAEE,WAAA,CADA,UACA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `PersonPhotoList_list__nW8nP`,
	"emptyThumb": `PersonPhotoList_emptyThumb__LByVH`,
	"actions": `PersonPhotoList_actions__LMW9l`,
	"viewmodeButton": `PersonPhotoList_viewmodeButton__GUs9j`
};
export default ___CSS_LOADER_EXPORT___;
