import { getBrandZIndex } from '@jotta/ui/zIndex'
import { Dialog, DialogContent } from '@radix-ui/react-dialog'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import {
  PhotoActionHandlers,
  type PhotoActionHandlersProps,
} from '../../components/PhotoActions/PhotoActionHandlers'
import { BaseSelectionManager } from '../../components/SelectionManager/BaseSelectionManager'
import { useMediaObjectStore } from '../../store/PhotoContext'
import type { SelectionStore } from '../../store/SelectionStore'

export const PhotoSearchSelectionManager = observer<
  PhotoActionHandlersProps & {
    restoreOnUnmount?: boolean
    store: SelectionStore
  }
>(function PhotoSearchSelectionManager({
  actionContext,
  restoreOnUnmount = false,
  store,
  ...props
}) {
  const { hasSelection, clearOnClose } = store

  const mediaStore = useMediaObjectStore()

  const isOwnerOfAllPhotos = useMemo(
    () =>
      !store.values.length ||
      mediaStore
        .getPhotosByIds(store.values)
        .every(photo => photo.username === props.username),
    [store.values, mediaStore, props.username],
  )

  if (hasSelection) {
    return (
      <Dialog open={hasSelection} modal={false}>
        <DialogContent
          className="absolute inset-x-0 bottom-0 z-10 h-12"
          style={{
            zIndex: getBrandZIndex('alertList'),
          }}
          onEscapeKeyDown={e => {
            clearOnClose(false)
            e.preventDefault()
          }}
        >
          <BaseSelectionManager
            selectionCount={store.selection.size}
            onClose={clearOnClose}
            data-testid="SelectionManager"
            data-actioncontext={actionContext}
          >
            <PhotoActionHandlers
              actionContext={actionContext}
              photoIds={store.values}
              isOwnerOfAllPhotos={isOwnerOfAllPhotos}
              buttonVariant="buttons.responsiveActionButton"
              onActionDialogClose={() => {
                clearOnClose(false)
              }}
              onDispatchComplete={(actionType, ...args) => {
                if (
                  actionType !== 'SHARE_PHOTO' &&
                  actionType !== 'UNSHARE_ALBUM' &&
                  actionType !== 'DOWNLOAD_PHOTO' &&
                  actionType !== 'DOWNLOAD_PHOTOS'
                ) {
                  clearOnClose(false)
                }
              }}
              {...props}
            />
          </BaseSelectionManager>
        </DialogContent>
      </Dialog>
    )
  }

  return null
})
