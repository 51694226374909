import type {
  FolderShareInvite,
  User,
} from '@jotta/grpc-web/no/jotta/openapi/sharing/v2/sharing.v2_pb'
import {
  FolderShareInviteStatus,
  ShareInfo,
  ShareMode,
} from '@jotta/grpc-web/no/jotta/openapi/sharing/v2/sharing.v2_pb'
import { objectify } from 'radash'
import { getEmptyShareInfo } from './emptyShareInfo'

const shareMemberStatus = ['owner', 'member'] as const
// type ShareMemberStatus = (typeof memberStatus)[number]
// const shareMemberStatusSchema = z.enum(memberStatus)

const shareInviteStatus = [
  'invite_pending',
  'invite_declined',
  'invite_not_sent',
  'invite_error',
] as const
type ShareInviteStatus = (typeof shareInviteStatus)[number]
// const shareInviteStatusSchema = z.enum(inviteStatus)

const shareUserStatus = [...shareMemberStatus, ...shareInviteStatus] as const
// const shareUserStatusSchema = z.enum(shareUserStatus)
export type ShareUserStatus = (typeof shareUserStatus)[number]
export function compareShareStatus(a: ShareUser, b: ShareUser) {
  if (a.role !== b.role) {
    return shareUserStatus.indexOf(a.role) - shareUserStatus.indexOf(b.role)
  }
  return a.name.localeCompare(b.name)
}
function inviteStatusToShareUserStatus(
  status: FolderShareInviteStatus,
): ShareInviteStatus {
  switch (status) {
    case FolderShareInviteStatus.DECLINED:
      return 'invite_declined'
    case FolderShareInviteStatus.NOT_SENT:
      return 'invite_not_sent'
    case FolderShareInviteStatus.SENT:
      return 'invite_pending'
    case FolderShareInviteStatus.UNKNOWN:
      return 'invite_error'
  }
}
export interface ShareUser<T extends ShareUserStatus = ShareUserStatus>
  extends User.AsObject {
  // #region Properties (2)

  role: T

  // #endregion Properties (2)
}
function inviteToShareUser(
  { email, status }: FolderShareInvite.AsObject,
  user?: User.AsObject,
): ShareUser {
  return {
    name: '',
    email,
    username: '',
    ...user,
    role: inviteStatusToShareUserStatus(status),
  }
}

export function parseShareInfo(
  params?: ShareInfo | Partial<ShareInfo.AsObject>,
) {
  const shareInfo: ShareInfo.AsObject = params
    ? params instanceof ShareInfo
      ? params.toObject()
      : {
          ...getEmptyShareInfo(),
          ...params,
        }
    : getEmptyShareInfo()
  const {
    pathItem,
    publicUri,
    publicLink,
    shareOwner,
    membersList,
    invitesList,
    canShareCollaboratively,
    encodedContentRef,
    publicShareInfo: p,
  } = shareInfo
  const usersByEmail = objectify(
    membersList.map<ShareUser>(member => ({
      role: 'member',
      ...member,
    })),
    member => member.email.toLowerCase(),
  )
  let owner: ShareUser<'owner'> | undefined
  if (shareOwner) {
    owner = {
      role: 'owner',
      ...shareOwner,
    }
    usersByEmail[shareOwner.email.toLowerCase()] = owner
  }
  for (const invite of invitesList) {
    const key = invite.email.toLowerCase()
    usersByEmail[key] = inviteToShareUser(invite, usersByEmail[key])
  }
  const users = Object.values(usersByEmail).sort(compareShareStatus)
  const hasMembers = users.some(u => u.role === 'member')
  const publicLinkId = shareInfo.pathItem?.publicLinkId || ''
  return {
    canShareCollaboratively,
    encodedContentRef,
    folderShareId: shareInfo.pathItem?.folderShareId || '',
    hasMembers,
    hasPassword: p?.hasPassword || false,
    isShared: Boolean(publicLinkId),
    owner,
    ownerusername: owner?.username || pathItem?.owner || '',
    publicLink,
    publicLinkId: publicLinkId,
    publicUri: p ? p.publicUri : publicUri,
    shareId: p?.shareId || '',
    shareMode: p?.shareMode || ShareMode.NOT_SET,
    usersByEmail,
    users,
  }
}
export type ShareInfoState = ReturnType<typeof parseShareInfo>
