/* eslint-disable tailwindcss/no-custom-classname */
import type { BoxPropsAs } from '@jotta/ui/Box'
import { Box } from '@jotta/ui/Box'
import type { ButtonProps } from '@jotta/ui/Button'
import { Button } from '@jotta/ui/Button'
import { AnimatePresence, motion } from 'framer-motion'
import { observer } from 'mobx-react-lite'
import { forwardRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { isString } from 'remeda'
import type { FormattedContentProps } from '../FormattedContent/FormattedContent'
import { FormattedContent } from '../FormattedContent/FormattedContent'
import type { AlertMessageParam, AlertStore } from './Alert.store'
import { Stack } from '@jotta/ui/Stack'
import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import type { UseMutationResult } from '@tanstack/react-query'

export type AlertProps = Omit<AlertMessageParam, 'message'> &
  FormattedContentProps & { onKeepOpen?: () => void } & ButtonProps
export const Alert = forwardRef<
  HTMLDivElement,
  BoxPropsAs<'div', AlertProps, 'alerts'>
>(function Alert(
  {
    open: initiallyOpen = true,
    center = true,
    actions = [],
    onClose = () => {},
    onRemove = () => {},
    onKeepOpen = () => {},
    severity = 'info',
    dismissable = true,
    persistKey = '',
    children: message,
    icon,
    testid,
    autoDismissAfterMsElapsed,
    ...props
  },
  ref,
) {
  const [open, setOpen] = useState(initiallyOpen)

  const handleClose = () => {
    if (dismissable) {
      if (persistKey) {
        localStorage.setItem(persistKey, String(Date.now()))
      }
      setOpen(false)
    }
    onClose()
  }

  const hasActions = Boolean(actions.length)

  return (
    <AnimatePresence onExitComplete={onRemove}>
      {open && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          sx={{
            position: 'relative',
            pointerEvents: 'all',
          }}
          {...(testid ? { 'data-testid': testid } : {})}
        >
          <Box {...props} variant={`alerts.${severity}`} ref={ref}>
            {dismissable && (
              <Button
                sx={{
                  float: 'right',
                  m: 2,
                  fontSize: 1,
                }}
                variant="buttons.text"
                icon="SvgClose"
                onClick={handleClose}
              />
            )}
            <FormattedContent className="alert__content" center={center}>
              {typeof message === 'string' ? <p>{message}</p> : message}
            </FormattedContent>

            {hasActions && (
              <Stack
                className="alert__actions !border-light"
                horizontal
                sx={{
                  borderTop: '1px solid currentColor',
                }}
              >
                {actions.map(({ action, text, testId, icon, keepOpen }) => {
                  if (isString(action)) {
                    return (
                      <Link
                        to={action}
                        key={text}
                        className="alert__button"
                        data-testid={testId}
                        onClick={e => {
                          if (keepOpen) {
                            onKeepOpen()
                          } else {
                            handleClose()
                          }
                        }}
                        sx={{
                          px: 2,
                          variant: 'buttons.plain',
                        }}
                      >
                        {icon && <PlainBrandIcon icon={icon} />}
                        {text}
                      </Link>
                    )
                  }
                  return (
                    <Button
                      key={text}
                      onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()

                        action()

                        if (keepOpen) {
                          onKeepOpen()
                        } else {
                          handleClose()
                        }
                      }}
                      px={2}
                      variant="buttons.plain"
                      data-testid={testId}
                      icon={icon}
                      className="alert__button !border-light"
                    >
                      {text}
                    </Button>
                  )
                })}
              </Stack>
            )}
          </Box>
        </motion.div>
      )}
    </AnimatePresence>
  )
})

export const AlertContainer = observer<
  BoxPropsAs<
    'div',
    {
      alertStore: AlertStore
    } & FormattedContentProps &
      ButtonProps,
    'alerts'
  >,
  HTMLDivElement
>(
  forwardRef(function AlertContainer({ alertStore, icon, ...props }, ref) {
    return (
      <Alert
        {...alertStore.message}
        onKeepOpen={() => alertStore.clearTimeout()}
        {...props}
        ref={ref}
        icon={icon}
      >
        {alertStore.message.message}
      </Alert>
    )
  }),
)

export function MutationStatusAlert({
  successText,
  errorText,
  mutation,
}: {
  successText: string
  errorText: string
  mutation: UseMutationResult<any, any, any, any>
}) {
  switch (mutation.status) {
    case 'success':
      return <div className="alert alert-good">{successText}</div>
    case 'error':
      return <div className="alert alert-danger">{errorText}</div>
  }
}
