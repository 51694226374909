import { Button } from '@jotta/ui/Button'
import type { SelectVariant } from '@jotta/ui/types/VariantTypes'
import { copyToClipboard } from '@jotta/utils/copyToClipboard'
import { Trans } from '@lingui/macro'

import { FormInputText } from '../FormInputText/FormInputText'

interface CopyInputProps {
  value: string
  buttonText?: string
  onCopy?: () => void
  onError?: (error: any) => void
  onSettled?: (error?: any) => void
  className?: string
  testId?: string
  buttonVariant?: SelectVariant<'buttons'>
}

export const CopyInputFragment: React.FC<CopyInputProps> = ({
  value,
  buttonText,
  onCopy,
  onError,
  onSettled,
  testId,
  buttonVariant = 'buttons.copyInputButton',
}) => {
  const copyHandler = () => {
    copyToClipboard(value).then(onCopy).catch(onError).finally(onSettled)
  }

  return (
    <>
      <FormInputText
        type="text"
        readOnly
        value={value}
        data-testid={testId || 'CopyInput'}
      />
      <Button
        type="button"
        onClick={copyHandler}
        data-testid={`${testId || 'CopyInput'}-Button`}
        variant={buttonVariant}
      >
        {buttonText || <Trans id="CopyButton.label" />}
      </Button>
    </>
  )
}
export const CopyInput: React.FC<CopyInputProps> = props => {
  return (
    <div className={props.className} css={{ display: 'flex' }}>
      <CopyInputFragment {...props} />
    </div>
  )
}
