import { TextField, TextFieldContainer } from '@jotta/ui/TextField'
import { Form, Button } from 'react-aria-components'
import { Trans, t } from '@lingui/macro'
import { useBizContext } from '../BizLayout'

export function BizAccountSettingsForm() {
  const { business } = useBizContext()
  return (
    <Form className="card" validationBehavior="aria">
      <h2 className="card-title">{t`Account settings`}</h2>
      <TextFieldContainer>
        <TextField
          type="text"
          name="businessname"
          defaultValue={business}
          label={t`Business name`}
          description={t`Your business name as you want it to be displayed.`}
          isRequired
        />
        <TextField
          type="text"
          name="organization_number"
          defaultValue="123"
          label={t`Organization number`}
          description={t`The company's official VAT number`}
        />
        <Button
          className="btn btn-primary col-start-2 justify-self-start"
          type="submit"
        >
          <Trans>Update</Trans>
        </Button>
      </TextFieldContainer>
    </Form>
  )
}
