/* eslint-disable tailwindcss/no-custom-classname */
import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'

import styles from './PathItemRow.module.scss'
import type { PathItemStore } from './PathItemStore'
const debug = Debug('jotta:files:PathItemThumb')

export interface PathItemThumbProps {
  item: PathItemStore
}

export const PathItemThumb = observer<PathItemThumbProps>(
  function PathItemThumb({ item }: PathItemThumbProps) {
    return (
      <span className={styles.icon}>
        {item.thumbLink ? (
          <>
            <img src={item.thumbLink} loading="lazy" alt="" />
            {item.isShared && (
              <PlainBrandIcon icon="SvgShared" className={styles.shareicon} />
            )}
          </>
        ) : (
          <span className={styles.svgicon}>
            <PlainBrandIcon
              icon={item.fileKindConfig.icon}
              className="fileicon"
              aria-label={item.fileKindConfig.label}
            />
            {item.isShared && (
              <PlainBrandIcon icon="SvgShared" className={styles.shareicon} />
            )}
          </span>
        )}
      </span>
    )
  },
)
