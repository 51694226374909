import { Button } from '@jotta/ui/Button'
import { Flex } from '@jotta/ui/Flex'
import { Input } from '@jotta/ui/Input'
import type { AppError } from '@jotta/types/AppError'
import { RadixDialog } from '@jotta/ui/RadixDialog'
import type { RadixModalProps } from '@jotta/ui/RadixModal'
import { RadixModal } from '@jotta/ui/RadixModal'
import { Trans, t } from '@lingui/macro'
import { useMutation } from '@tanstack/react-query'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import type { FileActionStore } from '../FileActionStore'
import { useBrandName } from '@jotta/grpc-connect-client/config'

const debug = Debug('jotta:files:action:RenameDialog')

type DeleteDeviceDialogProps = {
  deleteDevice: FileActionStore['deleteDevice']
  deviceName: string
  deviceId: string
  error: AppError | null
  clearError: (open?: boolean) => void
} & RadixModalProps

export const DeleteDeviceDialog = observer<DeleteDeviceDialogProps>(
  function DeleteDeviceDialog({
    deleteDevice,
    error,
    clearError,
    open,
    onClose,
    deviceName,
    deviceId,
    ...props
  }) {
    const deleteDeviceMutation = useMutation({ mutationFn: deleteDevice })
    const [challengeInput, setChallengeInput] = useState('')
    const deleteButton = useRef<HTMLButtonElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)
    const challengeAccepted = challengeInput === deviceName
    const brandName = useBrandName()

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }, [])

    useEffect(() => {
      if (challengeAccepted && deleteButton.current) {
        deleteButton.current.focus()
      }
    }, [challengeAccepted])

    return (
      <RadixModal open={open} onClose={onClose} {...props}>
        <RadixDialog
          data-testid="DeleteDeviceDialog"
          title={t`Confirm delete device`.toUpperCase()}
          onClose={onClose}
        >
          <h1 sx={{ fontSize: 5 }}>{deviceName}</h1>
          <p sx={{ mt: 2, mb: 0, fontSize: 2 }}>
            <Trans id="You are about to permanently delete <0>{deviceName}</0> from your {brandName} account.">
              You are about to permanently delete <strong>{deviceName}</strong>{' '}
              from your {brandName} account.
            </Trans>
          </p>
          <p sx={{ mt: 2, mb: 0, fontSize: 2 }}>
            {t({
              id: 'Please uninstall {brandName} from the device before you proceed.',
              values: {
                brandName,
              },
            })}
          </p>
          <p sx={{ mt: 2, mb: 0, fontSize: 2 }}>
            <Trans id="All files backed up by <0>{deviceName}</0> will be permanently deleted from your account.">
              All files backed up by <strong>{deviceName}</strong> will be
              permanently deleted from your account.
            </Trans>
          </p>
          <p
            sx={{ mt: 2, mb: 0, fontSize: 2 }}
          >{t`The change is permanent and can not be undone. Are you sure you want to continue?`}</p>
          <div sx={{ mt: 5 }}>
            <p sx={{ margin: 0 }}>
              <Trans id="Please type <0>{deviceName}</0> below to confirm">
                Please type <span sx={{ color: 'red' }}>{deviceName}</span>{' '}
                below to confirm
              </Trans>
              :
            </p>
            <Input
              ref={inputRef}
              value={challengeInput}
              onChange={e => setChallengeInput(e.target.value)}
              placeholder={t`Device Name`}
              sx={{ mt: 2, fontSize: 4 }}
            />
          </div>
          <Flex sx={{ gap: 3, justifyContent: 'flex-end', mt: 4, mb: 2 }}>
            <Button
              variant="buttons.secondary"
              onClick={() => onClose && onClose(false)}
            >{t`Cancel`}</Button>
            <Button
              ref={deleteButton}
              variant="buttons.error"
              onClick={() => {
                deleteDeviceMutation.mutate(deviceId)
                // onClose && onClose(false)
              }}
              disabled={!challengeAccepted}
            >
              {t`Delete`}
            </Button>
          </Flex>
        </RadixDialog>
      </RadixModal>
    )
  },
)
