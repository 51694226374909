import { schedule } from '@jotta/promiseq'
import { AbortError } from '@jotta/types/AbortError'
import type { AppError } from '@jotta/types/AppError'
import { handleUnknownError } from '@jotta/types/AppError'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'

export type ActionStatus = 'idle' | 'pending' | 'error' | 'success' | 'aborted'

export abstract class BaseAction<RES = any> {
  private _status: ActionStatus = 'idle'

  public get status() {
    return this._status
  }

  private set status(s: ActionStatus) {
    this._status = s
  }

  private _error: AppError | null = null

  public get error() {
    return this._error
  }

  private setError(e: unknown | null) {
    this._error = e ? handleUnknownError(e) : null
    this.status = 'error'
  }

  private _result: RES | null = null

  public get result() {
    return this._result
  }

  private set result(res: RES | null) {
    this._result = res
    this._completed = this.total
    this.status = 'success'
  }

  private _createdAt: Date = new Date()

  public get createdAt() {
    return this._createdAt
  }

  private _startedAt: Date | null = null

  public get startedAt() {
    return this._startedAt
  }

  private _finishedAt: Date | null = null

  public get finishedAt() {
    return this._finishedAt
  }

  private abortController: AbortController

  public get signal() {
    return this.abortController.signal
  }

  public abort() {
    this.abortController.abort()
  }

  private _p: Promise<RES | undefined> = new Promise(() => undefined)

  public asPromise() {
    return this._p
  }

  public get isIdle() {
    return this._status === 'idle'
  }

  public get isPending() {
    return this._status === 'pending'
  }

  public get isError() {
    return this._status === 'error'
  }

  public get isAborted() {
    return this._status === 'aborted'
  }

  public get isSuccess() {
    return this._status === 'success'
  }

  public get isDone() {
    return (
      this._status === 'success' ||
      this._status === 'error' ||
      this._status === 'aborted'
    )
  }

  private _completed: number = 0

  public get completed() {
    return this._completed
  }

  protected set completed(n: number) {
    this._completed = n
  }

  public abstract get total(): number

  public get progress() {
    return this.completed / this.total
  }

  private async start() {
    this.status = 'pending'
    this._startedAt = new Date()

    try {
      const result = (this.result = await this.run())
      return result
    } catch (e: unknown) {
      if (e instanceof AbortError) {
        this.status = 'aborted'
      } else {
        this.setError(e)
      }
    } finally {
      runInAction(() => {
        this._finishedAt = new Date()
      })
    }
  }

  protected async schedule() {
    this._p = schedule(() => this.start(), this.signal)
    return this._p
  }

  public async retry() {
    if (
      !this._finishedAt ||
      (this.status !== 'aborted' && this.status !== 'error')
    ) {
      return null
    }
    this._finishedAt = null
    this._status = 'idle'
    this._error = null
    return this.schedule()
  }

  protected abstract run(): Promise<RES>

  public remove = () => {}

  public constructor(abortController: AbortController = new AbortController()) {
    this.abortController = abortController
    makeObservable<
      BaseAction<RES>,
      | '_status'
      | '_error'
      | '_result'
      | '_startedAt'
      | '_finishedAt'
      | '_p'
      | 'start'
      | 'schedule'
      | '_completed'
    >(this, {
      _status: observable,
      status: computed,
      _error: observable,
      error: computed,
      _result: observable,
      result: computed,
      _startedAt: observable,
      startedAt: computed,
      _finishedAt: observable,
      finishedAt: computed,
      _p: observable,
      asPromise: action.bound,
      start: action.bound,
      schedule: action.bound,
      signal: computed,
      abort: action.bound,
      isIdle: computed,
      isError: computed,
      isPending: computed,
      isSuccess: computed,
      isDone: computed,
      _completed: observable,
      progress: computed,
      completed: computed,
    })
  }
}
