import type {
  GetLinksResponse,
  WopiActionTypeKey,
} from '@jotta/grpc-js-client/wopiService'
import {
  WopiActionType,
  generateWopiLink,
} from '@jotta/grpc-js-client/wopiService'
import { createQuery } from 'react-query-kit'

export const useWopiLinks = createQuery<
  GetLinksResponse.AsObject,
  {
    path: string
    action: WopiActionTypeKey
    shareToken?: string
  },
  unknown
>({
  queryKey: ['WopiLinks'],
  fetcher: async ({ action, path, shareToken }) => {
    const response = await generateWopiLink({
      path,
      action: WopiActionType[action],
      shareToken,
    })
    return response.toObject()
  },
})
