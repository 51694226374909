import { useEffect } from 'react'

export function useDisableDocumentScroll({ enabled }: { enabled: boolean }) {
  useEffect(() => {
    if (!enabled) {
      return
    }

    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = ''
    }
  }, [enabled])
}
