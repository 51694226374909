import { observableLocale } from '@jotta/i18n'
import type { Photos } from '@jotta/types/Photos'
import type { PhotosApi } from '@jotta/types/PhotosApi'
import { parseAlbumInfo } from '@jotta/types/schemas/AlbumSchema'
import { createUrl } from '@jotta/utils/url'
import dayjs from 'dayjs'
import type { Debugger } from 'debug'
import Debug from 'debug'
import { deepEqual } from 'fast-equals'
import { action, computed, makeAutoObservable, set, toJS } from 'mobx'
import type { IViewModel } from 'mobx-utils'
import { createViewModel } from 'mobx-utils'
import type { ChangeEventHandler } from 'react'
import {
  isAlbumOwner,
  isAlbumShareOwner,
  isAlbumSubscriber,
  isAlbumUser,
} from '../components/Album/AlbumInfo/AlbumInfo.loaders'
import { albumViewContexts } from '../components/PhotoActions/PhotoActionContext.types'
import type { PhotoActionHandlerSharedProps } from '../components/PhotoActions/PhotoActionHandlers'
import type { PhotoStore } from './PhotoStore'
const debug = Debug('jotta:photos:AlbumInfoStore')

export class AlbumInfoStore {
  private _data: PhotosApi.AlbumInfo
  deletedAt = 0
  deletedRequestedAt = 0
  private log: Debugger
  model: PhotosApi.AlbumInfo & IViewModel<PhotosApi.AlbumInfo>
  constructor(
    album: PhotosApi.AlbumInfo,
    private root: PhotoStore,
  ) {
    this._data = album
    this.log = debug.extend(album.id.slice(0, 8))
    if (!this.root.albumRoot.albums.has(album.id)) {
      this.root.albumRoot.albums.set(album.id, this)
    }

    makeAutoObservable<typeof this, 'log'>(
      this,
      {
        log: false,
        photoIds: computed.struct,
        updateAlbumInfo: action.bound,
      },
      {
        autoBind: true,
      },
    )
    this.model = createViewModel(this._data)
  }
  get id() {
    return this._data.id
  }
  delete(force = false) {
    if (force) {
      this.deletedAt = Date.now()
    }
    this.deletedRequestedAt = Date.now()
  }
  undelete() {
    this.deletedAt = 0
    this.deletedRequestedAt = 0
  }
  get data(): PhotosApi.AlbumInfo {
    if (this.model.isDirty) {
      return parseAlbumInfo(this.model)
    }
    return this._data
  }
  set data(data: PhotosApi.AlbumInfo) {
    this.log('set data', data)
    if (this.model.isDirty) {
      if (!deepEqual(this.model, data)) {
        set(this.model, data)
      }
    } else {
      this._data = data
    }
  }

  get shareLink() {
    const shareId = this.model.shareInfo && this.model.shareInfo.uri
    if (shareId) {
      return createUrl('share', shareId)
    }
  }
  get isShared() {
    return Boolean(this.shareLink)
  }

  get isOwner() {
    return isAlbumOwner(this.model, this.root.username)
  }
  get isAlbumUser() {
    return isAlbumUser(this.model, this.root.username)
  }

  get isShareOwner() {
    return isAlbumShareOwner(this.model, this.root.username)
  }
  get isSubscriber() {
    return isAlbumSubscriber(this.model, this.root.username)
  }

  get viewContexts(): {
    album: Photos.ActionContextType
    carousel: Photos.MediaCarouselActionContexts
    selection: Photos.SelectionManagerContext
  } {
    if (this.data.isSharedPhotos) {
      return {
        album: 'SHARED_GROUP_OF_PHOTOS',
        carousel: 'SHARED_GROUP_OF_PHOTOS_VIEW_PHOTO',
        selection: 'SHARED_PHOTOS_SELECTED_PHOTOS',
      }
    }
    if (this.root.isPublicRoute) {
      if (this.root.isAuthenticated) {
        return {
          album: 'SHARED_ALBUM_VIEW_LOGGED_IN',
          carousel: 'SHARED_ALBUM_VIEW_PHOTO',
          selection: 'SHARED_ALBUM_SELECTED_PHOTOS',
        }
      }
      return {
        album: 'SHARED_ALBUM_VIEW_ANONYMOUS',
        carousel: 'SHARED_ALBUM_VIEW_PHOTO',
        selection: 'SHARED_ALBUM_SELECTED_PHOTOS',
      }
    }
    if (this.data.isLocationAlbum) {
      return {
        selection: 'ALBUM_VIEW_LOCATION_ALBUM_SELECTED_PHOTOS',
        album: albumViewContexts[this.albumType],
        carousel: 'ALBUM_VIEW_LOCATION_ALBUM_VIEW_PHOTO',
      }
    }

    return {
      selection: 'ALBUM_VIEW_SELECTED_PHOTOS',
      album: albumViewContexts[this.albumType],
      carousel: 'ALBUM_VIEW_PHOTO',
    }
  }
  get hidden() {
    return Boolean(this.deletedRequestedAt || this.deletedAt)
  }
  get albumType() {
    return this.data.collectionType
  }
  get title() {
    return this.model.title
  }
  set title(title) {
    this.data.title = title
  }
  onTitleChange = action<ChangeEventHandler<HTMLInputElement>>(
    'onTitleChange',
    e => {
      this.model.title = e.currentTarget.value
      this.log('onTitleChange', this.model.changedValues)
    },
  )
  onTitleCancelEdit() {
    this.log('onTitleCancelEdit')
    if (this.model.isPropertyDirty('title')) {
      this.model.reset()
    }
  }
  onTitleSubmit() {
    this.log('onTitleSubmit')
    if (this.model.isPropertyDirty('title')) {
      this.log('onTitleSubmit RENAME')
      this.root.actions.dispatch('RENAME_ALBUM', this.id, this.model.title)
    }
  }
  get captureDate() {
    // Adding observableLocale.locale here forces a recompute when the locale changes
    if (this.photos.length && observableLocale.locale) {
      const from = dayjs(this.photos[0].capturedDate)
      const to = dayjs(this.photos[this.photos.length - 1].capturedDate)

      const format = 'LL'
      let span = from.format(format)

      if (to.diff(from, 'day') > 0) {
        span += ` - ${to.format(format)}`
      }

      return span
    }
    return ''
  }

  updateAlbumInfo(data: Partial<PhotosApi.AlbumInfo>) {
    set(this._data, data)
    this.model.reset()
  }
  updateAlbumInfoOnly({ photoIds, ...data }: Partial<PhotosApi.AlbumInfo>) {
    set(this._data, data)
  }

  get total() {
    return this.photoIds.length
  }
  get subscriberCount() {
    return this.data.shareInfo?.subscribers.length || 0
  }

  get info() {
    return {
      ...this.data,
      total: this.total,
    }
  }

  get showComments() {
    const { commentsGroupId } = this.data
    const enabled = Boolean(this.data.shareInfo && !this.data.isSharedPhotos)
    return {
      enabled,
      commentsGroupId,
    }
  }

  get photoIds() {
    return this.model.photoIds
  }
  get selectedPhotoIds() {
    return this.root.selection.values.filter(id => this.photoIds.includes(id))
  }

  get photos() {
    return this.root.mediaObjects.getPhotosByIds(this.photoIds)
  }

  get showLoginToAddPhotosAlert() {
    return (
      !this.data.isLocationAlbum &&
      !this.root.username &&
      this.data.canAddPhotos
    )
  }

  get showFollowSharedAlbumAlert() {
    return (
      !this.data.isOwner &&
      this.root.isAuthenticated &&
      this.data.isShared &&
      this.data.isNormalAlbum &&
      !this.isSubscriber
    )
  }

  get album(): PhotosApi.Album {
    return {
      ...this.data,
      photos: toJS(this.photos),
    }
  }

  get downloadInfo() {
    const initialState: Required<PhotosApi.DownloadPhotosInfo> = {
      urls: [],
      totalFileSize: 0,
      state: 'DISCOVERY',
      fileName: '',
    }
    return this.photos.reduce<Required<PhotosApi.DownloadPhotosInfo>>(
      (downloadInfo, photo) => {
        downloadInfo.totalFileSize += photo.filesize
        downloadInfo.urls.push(photo.file_url)
        downloadInfo.state = this.data.hasMorePhotos ? 'DISCOVERY' : 'COMPLETED'
        return downloadInfo
      },
      initialState,
    )
  }

  getDownloadInfo = action<PhotoActionHandlerSharedProps['getDownloadInfo']>(
    'getDownloadInfo',
    ids => {
      this.log('getDownloadInfo', ids)
      if (!ids) {
        return Promise.resolve(this.downloadInfo)
      }
      const initialState: Required<PhotosApi.DownloadPhotosInfo> = {
        urls: [],
        totalFileSize: 0,
        state: 'DISCOVERY',
        fileName: '',
      }
      const info = this.photos.reduce<Required<PhotosApi.DownloadPhotosInfo>>(
        (downloadInfo, photo) => {
          if (!ids || ids.includes(photo.id)) {
            downloadInfo.totalFileSize += photo.filesize
            downloadInfo.urls.push(photo.file_url)
            downloadInfo.state = this.data.hasMorePhotos
              ? 'DISCOVERY'
              : 'COMPLETED'
          }
          return downloadInfo
        },
        initialState,
      )
      return Promise.resolve(info)
    },
  )
}
