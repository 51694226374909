import { queryClient } from '@jotta/query'
import type { ProgressHandler } from '@jotta/types/IO'
import type { Photos } from '@jotta/types/Photos'
import type { PhotosApi } from '@jotta/types/PhotosApi'
import {
  AlbumInfoListResponseSchema,
  AlbumSchema,
  BaseAlbumSchema,
  CollectionType,
  parseAlbumInfoList,
} from '@jotta/types/schemas/AlbumSchema'
import {
  AllocateResponseOrMediaSchema,
  MediaResponseSchema,
  MediaSchema,
  TimelineOverviewResponseSchema,
  parseMediaItem,
} from '@jotta/types/schemas/MediaSchema'
import { env } from '@jotta/utils/env'
import { fileUploadXhr } from '@jotta/utils/fileUploadXhr'
import { startOfDay } from '@jotta/utils/time'
import Debug from 'debug'
import ky from 'ky'
import path from 'path'
import { createQuery } from 'react-query-kit'
import { getAlbumSorter } from '../store/AlbumRootStore'
import { photosApiBaseUrl } from './getPhotosApiUrl'
import type { MediaObjectStore } from '../store/MediaObjectStore'
import { useQuery } from '@tanstack/react-query'
import { getOrRefreshAccessToken } from '@jotta/auth-client/useAuthStatus'
const debugBase = Debug('jotta:photos:api')

type PhotosApiFetcher = ReturnType<typeof ky.create>
export function toFormData<T extends {}>(data: T): FormData {
  const form = new FormData()
  Object.entries(data).forEach(([key, value]) => {
    form.append(key, String(value))
  })
  return form
}

export function toSearchParams<T extends {}>(data: T): URLSearchParams {
  const params = new URLSearchParams()
  Object.entries(data).forEach(([key, value]) => {
    params.append(key, String(value))
  })
  return params
}

export function fixPath(pathStr: string) {
  return path.normalize(pathStr).replace(/\/$/, '')
}

let api: PhotosApiFetcher = ky.create({
  prefixUrl: photosApiBaseUrl,
  timeout: 10000,
  retry: {
    limit: 2,
    statusCodes: [401],
  },
  hooks: {
    beforeRequest: [
      async request => {
        const token = await getOrRefreshAccessToken()
        if (token) {
          request.headers.set('Authorization', `Bearer ${token}`)
        }
      },
    ],
  },
})

export function getApi(): PhotosApiFetcher {
  return api
}

export function setApi(kyApi: typeof api) {
  api = kyApi
}

export function initTimeline(hidden = false) {
  return api.get('timeline', {
    searchParams: {
      limit: 200,
      hidden,
    },
  })
}

export const fetchAlbumPhotos: PhotosApi.Album.Fetcher = async ({
  params: { id, limit = 100, order = 'ASC', publicAlbum, comments = true },
  to,
  from,
  signal,
}) => {
  return api
    .get(
      `${publicAlbum ? 'public' : 'albums'}/${id}/${to ? `${to}/` : ''}${
        from || ''
      }`,
      {
        searchParams: {
          order,
          limit,
          comments,
        },
      },
    )
    .json<PhotosApi.Album>()
}

export const fetchTimeline: PhotosApi.Fetcher<
  Photos.Media[],
  PhotosApi.Timeline.TimelineFetchProps
> = async ({ params, to, from, signal }) => {
  const result = await api
    .get(fixPath(`timeline/${to ? `${to}/` : ''}${from || ''}`), {
      searchParams: toSearchParams(params),
      signal,
    })
    .json<{ result: unknown[] }>()

  const parsed = MediaResponseSchema.parse(result)

  if (params.hidden) {
    for (const item of parsed.result) {
      item.hidden = true
    }
  }

  return parsed
}

export const fetchTimelineOverview: PhotosApi.Fetcher<
  Photos.Overview[],
  PhotosApi.Timeline.TimelineFetchProps
> = async ({ params, to, from, signal }) => {
  const result = await api
    .get(fixPath(`timeline/${to ? `${to}/` : ''}${from || ''}`), {
      searchParams: toSearchParams({ overview: true, ...params }),
      signal,
    })
    .json<{ result: unknown[] }>()

  return TimelineOverviewResponseSchema.parse(result)
}

/**
 * Pre-generated sizes available on the test server
 * https://test.petraflux.com/timeline-{size}.json
 *
 * Fetches the closest available size and slices the result to the requested limit
 */
export const availableMockTimelineSizes = [
  100, 1000, 10000, 20000, 30000, 40000, 50000, 100000, 120000,
] as const
export type MockTimelineSize = (typeof availableMockTimelineSizes)[number]
export function findMockTimelineSizeForLimit(limit: number) {
  return (
    availableMockTimelineSizes.find(s => s > limit) ||
    availableMockTimelineSizes[availableMockTimelineSizes.length - 1]
  )
}
export const fetchMockTimeline: PhotosApi.Fetcher<
  Photos.Media[],
  PhotosApi.Timeline.TimelineFetchProps & {
    limit: number
  }
> = async ({ params, to, from, signal, limit }) => {
  const size = findMockTimelineSizeForLimit(limit)
  if (from || to) {
    return {
      lastModified: 0,
      result: [],
    }
  }
  const result = await ky
    .get(`https://test.petraflux.com/timeline-${size}.json`, {
      credentials: 'same-origin',
    })
    .json<Photos.Media[]>()

  for (const media of result) {
    media.capturedDay = startOfDay(media.capturedDate)
  }

  return {
    lastModified: result.reduce(
      (acc, { capturedDate }) => (capturedDate > acc ? capturedDate : acc),
      0,
    ),
    result: result.slice(0, limit),
  }
}

export async function allocate(data: PhotosApi.AllocateMediaRequest) {
  const debug = debugBase.extend('allocate')
  debug(data, toSearchParams(data))
  const result = await api
    .post('allocate/phototimeline', {
      body: toSearchParams(data),
    })
    .json()
  debug(result)
  const parsed:
    | PhotosApi.AllocateMediaResponse
    | PhotosApi.UploadMediaResponse =
    AllocateResponseOrMediaSchema.parse(result)
  return parsed
}

export const upload: (
  data: PhotosApi.UploadMediaRequest,
) => Promise<PhotosApi.UploadMediaResponse> = async data => {
  const debug = debugBase.extend('upload')
  debug(data, toSearchParams(data))
  const result = await api
    .post(`upload/${data.upload_id}`, {
      body: data.file,
    })
    .json()
  debug(result)
  const parsed: PhotosApi.UploadMediaResponse = MediaSchema.parse(result)
  return parsed
}

export const uploadXhr: (
  data: PhotosApi.UploadMediaRequest,
  onProgress?: ProgressHandler,
  signal?: AbortSignal,
) => Promise<PhotosApi.UploadMediaResponse> = async (
  data,
  onProgress,
  signal,
) => {
  const debug = debugBase.extend('uploadXhr')
  debug(data, toSearchParams(data))
  const token = await getOrRefreshAccessToken()
  const result = await fileUploadXhr(
    data.file,
    `${photosApiBaseUrl}/upload/${data.upload_id}`,
    token,
    onProgress,
    signal,
  )
  debug(result)
  const parsed: PhotosApi.UploadMediaResponse = MediaSchema.parse(result)
  return parsed
}

export const fetchAlbumList: PhotosApi.Fetcher<
  PhotosApi.Album[],
  PhotosApi.AlbumListFetchProps
> = ({ albumType, comments = false }) => {
  return api
    .get(`albums_by_type/${CollectionType[albumType]}`, {
      searchParams: {
        comments,
      },
    })
    .json<PhotosApi.Response<PhotosApi.Album[]>>()
}
export const fetchAlbumListByType: PhotosApi.Fetcher<
  PhotosApi.AlbumInfo[],
  {
    albumType: keyof typeof CollectionType
  }
> = async ({ albumType }) => {
  // console.time(`fetchAlbumListByType ${albumType}`)
  const res = await api
    .get(`albums_by_type/${albumType}`, {
      searchParams: {
        comments: true,
      },
      timeout: 20000,
    })
    .json<PhotosApi.Response<PhotosApi.AlbumInfo[]>>()
  // console.timeEnd(`fetchAlbumListByType ${albumType}`)
  res.result = parseAlbumInfoList(res.result)
  return res
}

/**
 * Fetch a photo by ID or MD5 hash
 * @param id Photo ID or photo MD5 hash
 */
export async function fetchPhotoById(id: string) {
  const isMD5 = /^[a-f0-9]{32}$/gi.test(id)
  const url = isMD5 ? `photos_from_md5/${id}` : `photos/${id}`
  const result = await api.get(url).json<Photos.Photo>()
  const parsed = MediaSchema.parse(result)
  return parsed
}

export async function fetchPhotoIds(ids: string[], store: MediaObjectStore) {
  const debug = debugBase.extend('fetchPhotoIds')

  const notFoundIds = ids.filter(id => !store.getPhoto(id))

  if (!notFoundIds.length) {
    return []
  }

  const photos = await Promise.all(notFoundIds.map(fetchPhotoById))
  store.setPhotos(photos)
  return photos
}
export function useFetchPhotoIds(ids: string[], store: MediaObjectStore) {
  return useQuery({
    queryKey: ['fetchPhotoIds', ids],
    queryFn: () => fetchPhotoIds(ids, store),
  })
}
export const usePhotoByMD5 = createQuery<
  PhotosApi.Media,
  {
    checksum: string
  },
  unknown
>({
  queryKey: ['photoByMD5'],
  fetcher: async ({ checksum }) => {
    const result = await api.get(`photos_from_md5/${checksum}`).json()

    return parseMediaItem(result)
  },
})
export function fetchPhotoByMD5(checksum: string) {
  return queryClient.fetchQuery(usePhotoByMD5.getFetchOptions({ checksum }))
}

export interface FetchAlbumOptions {
  albumId: string
  limit?: number
  comments?: boolean
  from?: string
  to?: string
}

export async function fetchAlbum({
  albumId,
  limit = 200,
  comments = true,
  from = '',
  to = '',
}: FetchAlbumOptions) {
  const debug = debugBase.extend('fetchAlbum')
  const apiPath = path.join('albums', albumId, String(to), String(from))
  debug(apiPath)
  const result = await api
    .get(apiPath, {
      searchParams: {
        limit,
        comments,
      },
    })
    .json<unknown>()
  const data = AlbumSchema.parse(result)
  return data
}
export function albumById(id: string, publicAlbum = false) {
  return api
    .get(`${publicAlbum ? 'public' : 'albums'}/${id}`, {
      searchParams: {
        limit: 200,
        comments: true,
      },
    })
    .json<PhotosApi.Album>()
}

export function createAlbum(ids?: string[], title?: string) {
  const data = {} as any
  if (ids) {
    data.id_list = ids.join(',')
  }
  if (title) {
    data.title = title
  }
  return api
    .post('albums', {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: toSearchParams(data),
    })
    .json<PhotosApi.Album>()
}

export function updateAlbumTitle(id: string, title: string) {
  return api.post(`albums/${id}/modify_title`, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: toSearchParams({ title }),
  })
}

export function deleteAlbum(id: string) {
  return api.delete(`albums/${id}`)
}

export function deletePhotos(ids: readonly string[]) {
  return api.post(`delete`, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: toSearchParams({
      id_list: ids.join(','),
    }),
  })
}

export function hidePhotos(ids: readonly string[]) {
  return api.post(`photos_hide_or_unhide`, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: toSearchParams({
      hide_unhide: 'HIDE',
      id_list: ids.join(','),
    }),
  })
}

export function unhidePhotos(ids: readonly string[]) {
  return api.post(`photos_hide_or_unhide`, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: toSearchParams({
      hide_unhide: 'UNHIDE',
      id_list: ids.join(','),
    }),
  })
}

export async function fetchSharedList(): Promise<{
  albums: PhotosApi.AlbumInfo[]
  ids: readonly string[]
}> {
  const response = api.get('shares')
  const data = await response.json()

  const parsed = AlbumInfoListResponseSchema.parse(data)
  const albums: PhotosApi.AlbumInfo[] = parsed.result.sort(
    getAlbumSorter('createdDate', 'DESC'),
  )
  const ids = albums.map(album => album.id)
  return {
    albums,
    ids,
  }
}

export function shareAlbum(id: string) {
  return api
    .post(`shares/${id}`, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    })
    .json<{ shareInfo: PhotosApi.ShareInfo; commentsGroupId: string }>()
}

export async function addToAlbum(id: string, photoIds: readonly string[]) {
  // const debug = debugBase.extend('addToAlbum')

  const result = await api.post(`albums/${id}/add_photos`, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: toSearchParams({
      id_list: photoIds.join(','),
      comments: true,
    }),
  })
  const data = await result.json()
  // debug(data)
  const album = AlbumSchema.parse(data)
  return album
}

export async function removeFromAlbum(
  id: string,
  photoIds: readonly string[],
): Promise<Partial<PhotosApi.BaseAlbum>> {
  const result = api.post(`albums/${id}/remove_photos`, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: toSearchParams({
      id_list: photoIds.join(','),
    }),
  })
  const data = await result.json()
  // debug(data)
  const album = BaseAlbumSchema.partial().parse(data)
  return album
}

export function sharePhotos(ids: readonly string[]) {
  return api
    .post(`shares`, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: toSearchParams({
        id_list: ids.join(','),
      }),
    })
    .json<PhotosApi.Album>()
}

export function unShareAlbum(id: string) {
  return api.delete(`shares/${id}`)
}

export function setAlbumAccess(id: string, rw: 'READ' | 'WRITE') {
  return api
    .post(`albums/${id}/change_access`, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: toSearchParams({ rw }),
    })
    .json<{ shareInfo: PhotosApi.ShareInfo; commentsGroupId: string }>()
}

export function leaveAlbum(id: string) {
  return api.delete(`subscriptions/${id}`)
}

export function joinAlbum(id: string) {
  return api.post(`subscriptions/${id}`)
}

export const downloadPhotos = (
  fileUrls: string | string[],
  fileName?: string,
) => {
  const log = debugBase.extend('downloadPhotos')
  log('Start')
  fileUrls = Array.isArray(fileUrls) ? fileUrls : [fileUrls]

  if (!fileUrls.length) {
    return
  }

  if (fileUrls.length > 1) {
    const form = document.createElement('form')
    form.action = env.zipApi
    form.method = 'POST'
    form.style.display = 'none'
    fileUrls.forEach(fileUrl => {
      const input = document.createElement('input')
      input.type = 'text'
      input.name = 'jwt'
      input.value = fileUrl
      form.appendChild(input)
    })
    document.body.appendChild(form)
    form.submit()
    setTimeout(() => {
      log('Remove form')
      document.body.removeChild(form)
    }, 500)
  } else {
    const link = document.createElement('a')
    link.style.display = 'none'
    if (fileName) {
      link.download = fileName
    }
    link.href = fileUrls[0]
    document.body.appendChild(link)
    link.click()
    setTimeout(() => {
      log('Remove link')
      document.body.removeChild(link)
    }, 500)
  }
}

const accountApi = ky.create({
  prefixUrl: 'https://api.jottacloud.com/account/v1',
  hooks: {
    beforeRequest: [
      async request => {
        const token = await getOrRefreshAccessToken()
        if (token) {
          request.headers.set('Authorization', `Bearer ${token}`)
        }
      },
    ],
  },
})

export interface JoinTimelineResponse {
  jpapiState: string // "QUEUED"
  queueSlot: number
  username: string
}

export async function joinTimeline(): Promise<JoinTimelineResponse> {
  const debug = debugBase.extend('phototimeline/join')
  const result = await accountApi
    .post('phototimeline/join')
    .json<JoinTimelineResponse>()
  debug(result)
  return result
}
