import { useUsername } from '@jotta/grpc-connect-client/customer'
import { useBrandStore } from '@jotta/ui/useBrandTheme'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'

import { useParams } from 'react-router-dom'
import { usePhotoStore } from '../../../store/PhotoContext'
import { MediaCarouselContainer } from '../../MediaCarousel/MediaCarouselContainer'
import { SelectionManager } from '../../SelectionManager/SelectionManager'
import { AlbumInfo } from '../AlbumInfo/AlbumInfo'
import { AlbumPhotosLarge } from '../AlbumPhotos/AlbumPhotosLarge'
import { AlbumPhotosSmall } from '../AlbumPhotos/AlbumPhotosSmall'
import { AlbumComments } from './AlbumComments'

const debug = Debug('jotta:photos:Album.route')

export const AlbumContainer = observer(function AlbumContainer() {
  const { albumId = '', mediaId = '' } = useParams()
  const photoStore = usePhotoStore()
  const username = useUsername()
  const store = photoStore.albumRoot.getAlbumInfoQueryStore(albumId)
  const branding = useBrandStore()
  const album = store.album
  const showOwnerName = album?.model.showOwnerName

  return (
    <>
      <LoadingOverlay open={store.isLoading} />
      {album && (
        <>
          <AlbumInfo store={album} />
          {branding.isMobileTimeline ? (
            <AlbumPhotosSmall
              ids={album.photoIds}
              ownerAvatar={album.isShared}
            />
          ) : (
            <AlbumPhotosLarge
              ids={album.photoIds}
              ownerAvatar={album.isShared}
            />
          )}
          <SelectionManager
            username={username}
            album={album.data}
            albumId={album.id}
            getDownloadInfo={album.getDownloadInfo}
            shareLink={album.shareLink}
            actionContext={album.viewContexts.selection}
            isAlbumSubscriber={album.isSubscriber}
          />
          <MediaCarouselContainer
            items={album?.photos}
            mediaId={mediaId}
            actionContext={album.viewContexts.carousel}
            showOwnerName={showOwnerName}
          />
          {!mediaId && album.showComments.enabled && (
            <AlbumComments store={album} />
          )}
        </>
      )}
    </>
  )
})
