// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PeopleRoute_container__5qX3y{padding:var(--s4)}`, "",{"version":3,"sources":["webpack://./../../libraries/photos/src/people/internal/routes/PeopleRoute.module.scss"],"names":[],"mappings":"AACA,8BACE,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PeopleRoute_container__5qX3y`
};
export default ___CSS_LOADER_EXPORT___;
