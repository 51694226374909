/* eslint-disable tailwindcss/no-custom-classname */
import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { Button } from '@jotta/uinext/Button'
import { toBoolean } from '@jotta/utils/text'
import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from '@radix-ui/react-popover'
import Debug from 'debug'
import ls from 'localstorage-slim'
import moize from 'moize'
import { listify } from 'radash'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import z from 'zod'
import FaceSvg from './face.svg'
const debug = Debug('jotta:photos:PeopleDebug')
const debugPeopleKey = 'debugpeople'
const debugPeopleDataKey = `${debugPeopleKey}-data`

const peopleDebugInfo = z
  .object({
    showBounds: z.boolean(),
    showBoundsOnThumbs: z.boolean(),
    showMd5: z.boolean(),
    peopleSearch: z.boolean(),
    showMetaData: z.boolean(),
  })
  .catch(() => ({
    showBounds: false,
    showBoundsOnThumbs: false,
    showMd5: false,
    peopleSearch: false,
    showMetaData: false,
  }))
const labels: Record<keyof PeopleDebugInfo, string> = {
  showBounds: 'Show face bounds in carousel',
  showBoundsOnThumbs: 'Show face bounds on thumbs',
  showMd5: 'Show MD5',
  peopleSearch: 'Enable people search',
  showMetaData: 'Show face metadata',
}
type PeopleDebugInfo = z.infer<typeof peopleDebugInfo>

export const peopleDebugEnabled = moize(function peopleDebugEnabled() {
  const debugPeople = toBoolean(ls.get(debugPeopleKey))
  debug(debugPeople ? 'People debug enabled' : 'People debug disabled')
  return debugPeople
})

export function getDebugPeople() {
  return peopleDebugInfo.parse(ls.get(debugPeopleDataKey))
}
function setDebugPeople(data: PeopleDebugInfo) {
  ls.set(debugPeopleDataKey, data)
}

function PeopleDebugInfo() {
  const [data, setData] = useState(getDebugPeople)
  return (
    <div id="peopledebug" className="fixed bottom-16 left-3 z-[9999]">
      <Popover modal>
        <PopoverTrigger
          className="size-12 rounded-full bg-sx-accent p-2 text-sx-accent-text"
          style={{
            pointerEvents: 'auto',
          }}
        >
          <FaceSvg />
        </PopoverTrigger>
        <PopoverContent
          className="z-[9999] flex flex-col gap-1 rounded-md bg-white p-4 pt-8 text-base shadow-lg"
          side="top"
          align="start"
        >
          <PopoverClose className="absolute right-2 top-2 size-6">
            <PlainBrandIcon icon="SvgClose" />
          </PopoverClose>
          {listify(data, (k, v) => [k, v] as const).map(([k, v]) => {
            return (
              <label key={k}>
                <input
                  type="checkbox"
                  id={k}
                  checked={data[k]}
                  onChange={e => {
                    const isChecked = e.currentTarget.checked
                    setData(v => {
                      const newData: PeopleDebugInfo = {
                        ...v,
                        [k]: isChecked,
                      }
                      setDebugPeople(newData)
                      return newData
                    })
                  }}
                />{' '}
                {labels[k]}
              </label>
            )
          })}
          <Button variant="primary" onClick={() => window.location.reload()}>
            Reload
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              localStorage.removeItem('debugpeople')
              window.location.reload()
            }}
          >
            Disable
          </Button>
        </PopoverContent>
      </Popover>
    </div>
  )
}
export function PeopleDebug() {
  if (!peopleDebugEnabled()) {
    return null
  }
  return createPortal(<PeopleDebugInfo />, document.body)
}
