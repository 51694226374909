// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FileItemTypeIcon_wrapper__9hk7\\+{align-items:center;display:flex;flex:1 1 auto;height:var(--thumb-size);justify-content:center;position:relative;text-align:center;width:var(--thumb-size)}.FileItemTypeIcon_wrapper__9hk7\\+[data-has-thumbnail=true]{align-items:stretch;justify-content:stretch}.FileItemTypeIcon_shareIcon__lqjDw{align-items:center;bottom:0;color:#46cd85;display:flex;font-size:var(--fz4);justify-content:center;position:absolute;right:0;transform:translate(25%,25%)}.FileItemTypeIcon_playIcon__tyh7s{color:#fff;height:clamp(20px,40%,64px);left:50%;position:absolute;top:50%;transform:translate(-50%,-50%);width:clamp(20px,40%,64px)}.FileItemTypeIcon_thumb__lTSHl{display:block;flex:1 1 auto;height:100%;object-fit:cover;object-position:center;width:100%}`, "",{"version":3,"sources":["webpack://./../../libraries/files/src/FileItemTypeIcon/FileItemTypeIcon.module.scss"],"names":[],"mappings":"AACA,kCAKE,kBAAA,CAFA,YAAA,CADA,aAAA,CAMA,wBAAA,CAFA,sBAAA,CALA,iBAAA,CAGA,iBAAA,CAGA,uBACA,CAEA,2DACE,mBAAA,CACA,uBAAA,CAGJ,mCAIE,kBAAA,CAGA,QAAA,CANA,aAAA,CAEA,YAAA,CADA,oBAAA,CAGA,sBAAA,CACA,iBAAA,CAEA,OAAA,CACA,4BAAA,CAEF,kCACE,UAAA,CAEA,2BAAA,CAEA,QAAA,CADA,iBAAA,CAEA,OAAA,CACA,8BAAA,CALA,0BAKA,CAEF,+BAGE,aAAA,CACA,aAAA,CAFA,WAAA,CAGA,gBAAA,CACA,sBAAA,CALA,UAKA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `FileItemTypeIcon_wrapper__9hk7+`,
	"shareIcon": `FileItemTypeIcon_shareIcon__lqjDw`,
	"playIcon": `FileItemTypeIcon_playIcon__tyh7s`,
	"thumb": `FileItemTypeIcon_thumb__lTSHl`
};
export default ___CSS_LOADER_EXPORT___;
