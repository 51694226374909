import { Button } from '@jotta/ui/Button'
import { useBrandStore } from '@jotta/ui/useBrandTheme'
import { Plural } from '@lingui/macro'
import { observer } from 'mobx-react-lite'
import type { PhotoActionHandlerActionProps } from './PhotoActionHandlers'
import { useDispatchPhotoActionMutation } from './useDispatchPhotoActionMutation'

export const ActionAddComment = observer<
  PhotoActionHandlerActionProps<'ADD_COMMENT'>
>(function ActionAddComment({
  action,
  dispatch,
  onActionDialogClose,
  commentCount: count,
  buttonVariant = 'buttons.albumActionButton',
}) {
  const mutation = useDispatchPhotoActionMutation(
    'ADD_COMMENT',
    undefined,
    dispatch,
  )

  const store = useBrandStore()
  const isMobile = store.isMobileTimeline

  return (
    <Button
      variant={buttonVariant}
      aria-label={action.label}
      data-testid={action.testid}
      aria-busy={mutation.isPending}
      key={action.actionType}
      icon={action.icon}
      onClick={() => mutation.mutate([])}
    >
      {isMobile ? (
        count
      ) : !count ? (
        action.label
      ) : (
        <span sx={{ display: ['none', 'initial'] }}>
          <Plural value={count} one="# comment" other="# comments" />
        </span>
      )}
    </Button>
  )
})
