import filesizeParser from 'filesize-parser'
import { safeArrayIndex } from './number'

/**
 * Ensure that a value that is either an array of T or a single item of T becomes an array
 * @param value Array of items, or a single item
 * @returns Array of items
 */
export function ensureArray<T>(value: T | T[]): T[] {
  if (Array.isArray(value)) {
    return value
  }
  return [value]
}

/**
 * Creates a random Uint8Array
 * @param size Size of generated buffer '1mb', '300b', '2gb', etc...
 */
export function createRandomArrayBuffer(size: string | number) {
  performance.mark('randombuffer-start')
  const maxLength = 65536
  const length = typeof size === 'number' ? size : filesizeParser(size)
  const numberOfChunks = Math.ceil(length / maxLength)
  const arr = new Uint8Array(length)
  if (numberOfChunks <= 1) {
    globalThis.crypto.getRandomValues(arr)
    return arr
  }
  const lastChunkSize = length - (numberOfChunks - 1) * maxLength
  for (let i = 0; i < numberOfChunks; i++) {
    const isLast = i < numberOfChunks - 1
    const offset = i * maxLength
    const chunkSize = isLast ? maxLength : lastChunkSize
    const chunkArr = new Uint8Array(chunkSize)
    globalThis.crypto.getRandomValues(chunkArr)
    arr.set(chunkArr, offset)
  }
  performance.mark('randombuffer-end')
  return arr
}

/**
 * Process a typed array in chunks
 * @param arr Array to process
 * @param chunkSize Size in bytes
 * @param chunkHandler Handler function for the chunks
 */
export function chunkedTypedArrayMapper(
  arr: Uint8Array,
  chunkSize: number = 65536,
  chunkHandler: (chunk: Uint8Array) => void,
) {
  const { byteLength } = arr.buffer
  const numberOfChunks = Math.ceil(byteLength / chunkSize)
  if (numberOfChunks <= 1) {
    chunkHandler(arr)
    return
  }
  for (let i = 0; i < numberOfChunks; i++) {
    const isLast = i === numberOfChunks - 1
    const offset = i * chunkSize
    if (isLast) {
      chunkHandler(arr.slice(offset))
      return
    }
    chunkHandler(arr.slice(offset, chunkSize))
  }
  performance.mark('randombuffer-end')
  return arr
}

export function lastItemInArray<T>(arr: T[], fallback?: T) {
  if (arr.length) {
    return arr[arr.length - 1]
  }
  return fallback
}
export function getArrayItemByIndex<T>(index: number, arr: T[] | readonly T[]) {
  const i = safeArrayIndex(index, arr)
  if (i !== null) {
    return arr[i]
  }
}

export function caseInsensitiveFind<T extends string[] | readonly string[]>(
  arr: T,
  value: string,
): string | undefined {
  return arr.find(
    item => item.toLocaleLowerCase() === value.toLocaleLowerCase(),
  )
}

export function caseInsensitiveIncludes<T extends string[] | readonly string[]>(
  arr: T,
  value: string,
): boolean {
  return arr.some(
    item => item.toLocaleLowerCase() === value.toLocaleLowerCase(),
  )
}
