import { TransportProvider } from '@connectrpc/connect-query'
import { Outlet } from 'react-router-dom'
import styles from './PeopleLayout.module.scss'
import { authTransport } from '@jotta/grpc-connect-client/transport'

export function PeopleLayout() {
  return (
    <TransportProvider transport={authTransport}>
      <div className={styles.container}>
        <Outlet />
      </div>
    </TransportProvider>
  )
}
