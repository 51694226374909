import { Box } from '@jotta/ui/Box'
import { Button } from '@jotta/ui/Button'
import type { PhotosApi } from '@jotta/types/PhotosApi'
import { RadixDialog } from '@jotta/ui/RadixDialog'
import type { RadixModalProps } from '@jotta/ui/RadixModal'
import { RadixModal } from '@jotta/ui/RadixModal'
import { plural, t } from '@lingui/macro'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { usePhotoStore } from '../../../store/PhotoContext'
import type { PhotoActionDispatcher } from '../../PhotoActions/useDispatchPhotoActionMutation'
import { AlbumThumb } from '../AlbumThumb/AlbumThumb'
import { CreateAlbumFromAlbumThumb } from '../AlbumsSection/CreateAlbumFromAlbumThumb'
export interface SelectAlbumDialogProps extends RadixModalProps {
  onClose: (open: boolean) => void
  addToAlbumHandler: (albumId?: string) => void
  currentAlbumId?: string
  albums?: PhotosApi.AlbumInfo[]
  dispatch?: PhotoActionDispatcher
}

export const SelectAlbumDialog = observer<SelectAlbumDialogProps>(
  function SelectAlbumDialog({
    currentAlbumId,
    albums: albumsProp,
    addToAlbumHandler,
    onClose,
    dispatch,
    ...props
  }) {
    const photoStore = usePhotoStore()
    const store = useLocalObservable(() => ({
      get dialogAlbums() {
        return photoStore.albumRoot.editableAlbums.data
      },
    }))
    const albums =
      albumsProp && albumsProp.length ? albumsProp : store.dialogAlbums

    const albumCount = albums.length

    return (
      <RadixModal onClose={onClose} {...props}>
        <RadixDialog
          title={t`Choose album`}
          smallTitle={plural(albumCount || 0, {
            one: '# album',
            other: '# albums',
          })}
          onClose={onClose}
        >
          <Box
            sx={{
              display: 'grid',
              pt: 1,
              gap: 4,
              gridTemplateColumns: [
                '1fr 1fr',
                'repeat(auto-fill, minmax(150px, 1fr))',
              ],
              alignItems: 'start',
              width: '100%',
              position: 'relative',
              '> button': {
                p: 1,
                '&:focus': {
                  boxShadow: '0 0 0 2px var(--color-primary)',
                },
                '&[aria-selected="true"]': {
                  backgroundColor: 'var(--color-border)',
                },
              },
            }}
          >
            <CreateAlbumFromAlbumThumb
              dispatch={dispatch}
              onAlbumCreated={album => addToAlbumHandler(album.id)}
            />

            {albums &&
              albums
                .filter(album => album.id !== currentAlbumId)
                .map(album => {
                  return (
                    <Button
                      key={album.id}
                      onClick={() => addToAlbumHandler(album.id)}
                    >
                      <AlbumThumb album={album} />
                    </Button>
                  )
                })}
          </Box>
        </RadixDialog>
      </RadixModal>
    )
  },
)
