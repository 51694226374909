import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import type { BrandThemeIcon } from '@jotta/types/Brand'
import type { FileActionContext } from '@jotta/types/FileActions'
import * as ContextMenu from '@radix-ui/react-context-menu'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import clsx from 'clsx'
import type {
  ActiveFileAction,
  ActiveFileActionType,
} from '../FileActions/fileActionConfig'
import styles from './FileContextMenu.module.scss'
export interface FileContextMenuItemProps {
  name: string
  path: string
  context: FileActionContext
  action: ActiveFileAction<ActiveFileActionType>
  icon: BrandThemeIcon
  index: number
  onSelect: (e: Event) => void
  type: 'DROPDOWN' | 'CONTEXT'
}

export function FileContextMenuItem({
  name,
  path,
  icon,
  action,
  context,
  index,
  onSelect,
  type,
}: FileContextMenuItemProps) {
  const Menu = type === 'DROPDOWN' ? DropdownMenu : ContextMenu
  const isFab = context === 'FAB'
  if (action.action === 'NewDocument') {
    return (
      <Menu.Sub>
        <Menu.SubTrigger
          data-file-path={path}
          data-file-action={action.action}
          data-file-context={context}
        >
          <div className={styles.menuitem}>
            <span className={styles.icon}>
              <PlainBrandIcon icon={icon} />
            </span>
            <span className={styles.text}>{name}</span>
          </div>
        </Menu.SubTrigger>
        <Menu.Portal>
          <Menu.SubContent
            className={clsx({
              [styles.fab]: isFab,
              [styles.submenu]: isFab,
              [styles.content]: !isFab,
            })}
          >
            {action.actions.map((subAction, subIndex) => (
              <Menu.Item
                key={subAction.name}
                className={styles.menuitem}
                data-file-path={path}
                data-file-action={subAction.action}
                data-file-context={context}
                onSelect={onSelect}
              >
                <span className={styles.icon}>
                  <PlainBrandIcon icon={subAction.icon} />
                </span>
                <span className={styles.text}>{subAction.name}</span>
              </Menu.Item>
            ))}
          </Menu.SubContent>
        </Menu.Portal>
      </Menu.Sub>
    )
  }
  return (
    <Menu.Item
      className={styles.menuitem}
      data-file-path={path}
      data-file-action={action.action}
      data-file-context={context}
      onSelect={onSelect}
    >
      <span className={styles.icon}>
        <PlainBrandIcon icon={icon} />
      </span>
      <span className={styles.text}>{name}</span>
    </Menu.Item>
  )
}
