import { TextThemeUI } from '@jotta/ui/themeui'
import { RadixDialog } from '@jotta/ui/RadixDialog'
import type { RadixModalProps } from '@jotta/ui/RadixModal'
import { RadixModal } from '@jotta/ui/RadixModal'
import { t } from '@lingui/macro'

export interface DeleteAlbumDialogProps extends RadixModalProps {
  isLoading?: boolean
  onDeleteAlbum: () => void
}

export const DeleteAlbumDialog: React.FC<DeleteAlbumDialogProps> = ({
  isLoading = false,
  onDeleteAlbum,
  ...props
}) => {
  return (
    <RadixModal {...props}>
      <RadixDialog
        title={t`DeleteAlbumButton.confirmTitle`}
        onClose={props.onClose}
        buttons={[
          {
            action: 'close',
            label: t`Cancel`,
          },
          {
            label: t`DeleteAlbumButton.confirmLabel`,
            loading: isLoading,
            action: onDeleteAlbum,
            variant: 'buttons.error',
          },
        ]}
      >
        <TextThemeUI variant="styles.p" sx={{ mb: 3 }}>
          {t`DeleteAlbumButton.confirmBody`}
        </TextThemeUI>
      </RadixDialog>
    </RadixModal>
  )
}
