import type { FileRouteContext } from '@jotta/types/schemas/FileRouteSchema'
import {
  createPath,
  makeAbsolute,
  removeTrailingSlash,
} from '@jotta/utils/pathUtils'

/**
 * The apiPath for a file route '/web/:context/:viewMode/:sortBy/*'
 * is :context and * combined, except for trash and shared which doesn't prepend
 * the context to nested paths
 *
 * @param context Route context ('archive'|'backup'|'trash'|'shared'|'sync')
 * @param routePath The * in the route path
 * @returns apiPath for fetching from backend
 */
export function routeContextToApiPath(
  context: FileRouteContext,
  routePath: string,
) {
  const path = makeAbsolute(routePath)
  if (path === '/' || !['trash', 'shared'].includes(context)) {
    return removeTrailingSlash(createPath(context, path))
  }
  return path
}
