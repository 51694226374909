import { tv } from 'tailwind-variants'
import { textInputVariants } from '../TextInput/TextInput.variants'

export const passwordInputVariants = tv({
  slots: {
    container: 'relative',
    input: textInputVariants({
      className: 'w-full',
    }),
    button: 'absolute right-2 top-1/2 -translate-y-1/2',
  },
})
