import type { PartialMessage } from '@bufbuild/protobuf'
import { useQuery, useSuspenseQuery } from '@connectrpc/connect-query'
import type { GetPaymentInfoRequest } from '@jotta/grpc-connect-openapi/esm/openapi/subscription/payment.v2_pb'
import { getPaymentInfo } from '@jotta/grpc-connect-openapi/subscriptionQuery'

export function useGetPaymentInfo(args: PartialMessage<GetPaymentInfoRequest>) {
  const query = useQuery(getPaymentInfo, args)
  return query
}

export function useSuspenseGetPaymentInfo(
  args: PartialMessage<GetPaymentInfoRequest>,
) {
  const query = useSuspenseQuery(getPaymentInfo, args)
  return query
}
