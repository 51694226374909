import { useNavigateRef } from '@jotta/hooks'
import type { Avatar } from '@jotta/types/Avatar'
import { AvatarPlain } from '@jotta/ui/Avatar'
import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { CommentsBubble } from '@jotta/ui/CommentsBubble'
import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'
import { useSelectionStore } from '../../store/PhotoContext'
import { CheckboxOverlay } from './CheckboxOverlay'
import styles from './PhotoGallery.module.scss'

export interface MediaThumbProps {
  src: string
  comments?: number
  showOwnerAvatar?: boolean
  ownerAvatar?: Avatar
  filename: string
  id: string
  duration?: string
  playIcon?: boolean
  showSimilarSearch?: boolean
  title?: string
  onNavigate?: (id: string) => void
}

export const MediaThumb = observer<MediaThumbProps>(function MediaThumb({
  src,
  comments = 0,
  showOwnerAvatar = false,
  ownerAvatar,
  filename,
  id,
  duration,
  playIcon = false,
  showSimilarSearch = false,
  title,
  onNavigate,
}) {
  const { onClickToggleIfActive } = useSelectionStore()
  const navigate = useNavigateRef()

  const clickHandler = useCallback(
    (e: React.SyntheticEvent) => {
      if (
        e.nativeEvent instanceof KeyboardEvent &&
        e.nativeEvent.key !== 'Enter'
      ) {
        return
      }
      onClickToggleIfActive(e)

      if (e.defaultPrevented) {
        return
      }

      if (onNavigate) {
        onNavigate(id)
      } else {
        navigate.current(`./${id}`)
      }
    },
    [onClickToggleIfActive, navigate, id, onNavigate],
  )

  return (
    <div
      role="button"
      data-testid="MediaThumb"
      className={styles.mediaWrapper}
      onClick={clickHandler}
      onKeyDown={clickHandler}
      data-photoid={id}
      draggable={false}
      tabIndex={0}
    >
      <img
        alt={filename}
        title={title}
        src={src}
        data-filename={filename}
        loading="lazy"
        draggable={false}
      />

      {playIcon && (
        <PlainBrandIcon
          className={styles.playButton}
          icon="SvgPlayCircleButton"
        />
      )}

      {duration && <div className={styles.duration}>{duration}</div>}

      <CheckboxOverlay id={id} showSimilarSearch={showSimilarSearch} />

      {showOwnerAvatar && ownerAvatar ? (
        <div className={styles.avatarWrapper}>
          <AvatarPlain avatar={ownerAvatar} size={30} />
        </div>
      ) : null}

      {comments > 0 && <CommentsBubble count={comments} />}
    </div>
  )
})
