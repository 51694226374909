export function wildcardMatch(str: string, rule: string | string[]) {
  const escapeRegex = (str: string) =>
    str.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1')

  const rules = Array.isArray(rule) ? rule : [rule]

  for (const rule of rules) {
    if (
      new RegExp(`^${rule.split('*').map(escapeRegex).join('.*')}$`).test(str)
    ) {
      return true
    }
  }
  return false
}
