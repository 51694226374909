import type { ButtonProps } from '@jotta/ui/Button'
import { Button } from '@jotta/ui/Button'
import SvgPayWithVipps from '../../Icons/PaymentIcons/pay-with-vipps.svg'
export type VippsButtonProps = Omit<
  ButtonProps,
  'icon' | 'children' | 'variant'
>

export function VippsButton(props: VippsButtonProps) {
  return (
    <Button variant="buttons.vipps" {...props}>
      <SvgPayWithVipps height="100%" width="100%" />
    </Button>
  )
}
