import { useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

export function useAndConsumeSearchParam(
  parameter: string,
  callback?: (value: string) => void,
) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [value, setValue] = useState<string>()
  useEffect(() => {
    if (searchParams.has(parameter)) {
      const parameterValue = searchParams.get(parameter)
      if (parameterValue) {
        setValue(parameterValue)
        if (callback) {
          callback(parameterValue)
        }
      }
      searchParams.delete(parameter)
      setSearchParams(searchParams, {
        replace: true,
      })
    }
  }, [parameter, searchParams])
  return value
}
