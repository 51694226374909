import type {
  ExternalLinkRef,
  FederatedIdentityRef,
} from '@jotta/grpc-web/no/jotta/openapi/ref_pb'
import {
  ContentRef,
  PurchaseRef,
  SettingsRef,
} from '@jotta/grpc-web/no/jotta/openapi/ref_pb'
import { generateHelpArticleLink } from '@jotta/utils/helpArticles'
import { createPath, uriEncodeRoutePath } from '@jotta/utils/pathUtils'
import { Base64 } from 'js-base64'
import { FederationIntent } from '@jotta/grpc-web/no/jotta/openapi/federation_pb'

const { RefCase } = ContentRef
const { SectionCase } = SettingsRef
const { TypeCase } = PurchaseRef
const defaultRoute = '/web/sync'
function getCommentBase64(comment: any /* TODO: CommentRef */) {
  const refComment = { id: comment.getId() }
  const parentId = comment.getParentId()
  if (parentId) {
    Object.assign(refComment, { parentId })
  }
  return encodeURIComponent(btoa(JSON.stringify(refComment)))
}

function federatedIdentity(
  federatedIdentity: FederatedIdentityRef | undefined,
): string {
  const token = federatedIdentity?.getToken() || ''
  const intent = federatedIdentity?.getIntent()
  const query = `?federation_token=${token}`
  switch (intent?.getIntentCase()) {
    case undefined:
    case FederationIntent.IntentCase.INTENT_NOT_SET:
    case FederationIntent.IntentCase.LOGIN:
      return `/login${query}`
    case FederationIntent.IntentCase.SIGNUP:
      const productCode = intent?.getSignup()?.getProductCode()
      return productCode ? `/signup/${productCode}${query}` : `/signup${query}`
  }
}

export function contentRefToRoute({
  contentRef,
  authenticated,
}: {
  contentRef: ContentRef
  authenticated?: boolean
}): string | ExternalLinkRef {
  switch (contentRef.getRefCase()) {
    case ContentRef.RefCase.REF_NOT_SET: {
      return defaultRoute
    }
    case ContentRef.RefCase.DEVICE_LOGIN_CODE:
      const deviceLoginCode = contentRef.getDeviceLoginCode()
      return `/appletv?code=${deviceLoginCode}`
    case ContentRef.RefCase.PASSWORD_RESET_KEY: {
      const passwordResetKey = contentRef.getPasswordResetKey()
      return passwordResetKey
        ? `/start/${passwordResetKey.getResetKey()}`
        : '/login'
    }
    // #region Files
    case RefCase.SYNC_FOLDER:
      return '/web/sync'
    case RefCase.ARCHIVE:
      return '/web/archive'
    case RefCase.TRASH:
      return '/web/trash'
    case ContentRef.RefCase.FILE_REVISION:
      return '/web/sync' // TODO can we handle this ref better?
    case RefCase.FILE_OR_FOLDER: {
      const fileOrFolder = contentRef.getFileOrFolder()
      if (!fileOrFolder) {
        return '/web/sync/list/name'
      }
      const apiPath = fileOrFolder.getPath()
      const val = Base64.encode(apiPath, true)
      return `/web/file/goto?path=${val}`
    }
    case RefCase.PUBLIC_SHARE: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const share = contentRef.getPublicShare()!.toObject()
      if (share.path) {
        return createPath(
          's',
          share.shareId,
          'list',
          uriEncodeRoutePath(share.path),
        )
      } else {
        return `/s/${share.shareId}`
      }
    }

    // #endregion Files
    // #region Settings
    case RefCase.SETTINGS: {
      switch (contentRef.getSettings()?.getSectionCase()) {
        case SectionCase.SECURITY:
          return '/web/secure'
        case SectionCase.PAYMENT_INFO:
          return '/web/billing'
        default:
          return '/web/account'
      }
    }
    case ContentRef.RefCase.VOUCHER: {
      const voucherCode = contentRef?.getVoucher()?.getVoucherCode()
      return authenticated
        ? '/web/billing'
        : `/signup/voucher?voucher_code=${voucherCode}`
    }
    case ContentRef.RefCase.ORDER:
      return '/web/billing' // TODO can we handle this ref better?
    case RefCase.PURCHASE: {
      switch (contentRef.getPurchase()?.getTypeCase()) {
        case TypeCase.RENEW:
          return '/web/renew'
        case TypeCase.UPGRADE: {
          const productCode = contentRef
            ?.getPurchase()
            ?.getUpgrade()
            ?.getProductCode()
          return `/web/${
            productCode ? `upgradePayment/${productCode}` : `upgrade`
          }`
        }
        default:
          return defaultRoute
      }
    }
    // #endregion Settings
    // #region Photos
    case RefCase.PHOTOS_REF:
      return `/photo`
    case RefCase.PHOTO_IN_ALBUM: {
      const ref = contentRef.getPhotoInAlbum()
      if (!ref) {
        return '/photo'
      }
      const shareId = ref.getShareId()
      const photoId = ref.getPhotoId()
      if (shareId && photoId) {
        return `/share/${shareId}/${photoId}`
      }
      if (shareId) {
        return `/share/${shareId}`
      }
      return '/photo'
    }
    case RefCase.PHOTO: {
      const photoRef = contentRef.getPhoto()
      if (!photoRef) {
        return '/photo'
      }
      return `/photo/${photoRef.getChecksum()}`
    }
    case RefCase.PHOTO_ALBUM: {
      const ref = contentRef.getPhotoAlbum()
      if (!ref) {
        return '/photo/album'
      }
      const shareId = ref.getShareId()
      if (shareId) {
        return `/share/${shareId}`
      }
      return `/photo/album/${ref.getId()}`
    }
    case RefCase.COMMENT: {
      const commentRef = contentRef.getComment()
      if (!commentRef) {
        return '/photo'
      }
      const photo = commentRef.getPhotoInAlbum()
      if (photo) {
        return `/share/${photo.getShareId()}/${photo.getPhotoId()}?comment=${getCommentBase64(
          commentRef,
        )}`
      }
      const album = commentRef.getPhotoAlbum()
      if (album) {
        return `/share/${album.getShareId()}?comment=${getCommentBase64(
          commentRef,
        )}`
      }
      return '/photo'
    }

    case ContentRef.RefCase.FAMILY_INVITE: {
      const inviteCode = contentRef.getFamilyInvite()!.getInviteCode()
      return `/f/${inviteCode}`
    }

    // #endregion Photos
    // #region Other
    case ContentRef.RefCase.HELP_ARTICLE: {
      const articleId = contentRef.getHelpArticle()?.getArticleId()
      return articleId ? generateHelpArticleLink(articleId) : defaultRoute
    }
    case RefCase.EXTERNAL_LINK: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const linkRef = contentRef.getExternalLink()!
      return linkRef
    }
    case RefCase.FEDERATED_IDENTITY: {
      return federatedIdentity(contentRef.getFederatedIdentity())
    }
    case ContentRef.RefCase.FACE_REF: {
      // const faceRef = contentRef.getFaceRef()
      return '/photo/album/people'
    }
    case ContentRef.RefCase.VIPPS_FLOW:
      return `/web/payment/status/${contentRef.getVippsFlow()!.getFlowId()}`
    // #endregion Other
  }
}
