import { z } from 'zod'
import type { NamedProperties } from '../TypeUtils'
export function capitalize<T extends string>(str: T): Capitalize<T> {
  return (str.charAt(0).toUpperCase() + str.slice(1)) as Capitalize<T>
}

export function uncapitalize<T extends string>(str: T): Uncapitalize<T> {
  return (str.charAt(0).toLowerCase() + str.slice(1)) as Uncapitalize<T>
}
export type CreateLiteralResult<
  Name extends string,
  T extends string,
> = NamedProperties<
  Name,
  {
    NameSchema: z.ZodLiteral<T>
    isName: (val: any) => val is T
    Name: T
  }
>
export function createLiteral<Name extends string, T extends string>(
  name: Name,
  value: T,
) {
  const schema = z.literal(value)
  const result = {
    [`${uncapitalize(name)}Schema`]: schema,
    [`is${capitalize(name)}`]: (val: any) => schema.safeParse(val).success,
    [name]: schema.value,
  } as CreateLiteralResult<Name, T>
  return result
}

export type CreateEnumResult<
  Name extends string,
  K extends string,
  T extends [K, ...K[]],
> = NamedProperties<
  Name,
  {
    Name: z.ZodEnum<T>
    NameSchema: z.ZodEnum<T>
    isName: (val?: any) => val is z.infer<z.ZodEnum<T>>
    getName: (
      val?: any,
      defaultValue?: z.infer<z.ZodEnum<T>>,
    ) => z.infer<z.ZodEnum<T>> | typeof defaultValue
    Names: readonly K[]
  }
>

export function createEnum<
  Name extends string,
  K extends string,
  T extends [K, ...K[]],
>(name: Name, value: T, defaultGetValue?: T[number]) {
  const schema = z.enum(value)
  const result = {
    [name]: schema,
    [`${uncapitalize(name)}Schema`]: schema,
    [`is${capitalize(name)}`]: (val: any): val is K =>
      schema.safeParse(val).success,
    [`get${capitalize(name)}`]: (val: any, defaultValue: K) =>
      schema.safeParse(val).success ? val : defaultValue || defaultGetValue,
    [`${uncapitalize(name)}s`]: schema.options,
  } as CreateEnumResult<Name, K, T>
  return result
}
