import { getAlertListStore } from '@jotta/ui/AlertStore'
import { t } from '@lingui/macro'

export function displayJoinFolderShareSuccessAlert(folder: string) {
  getAlertListStore().showAlert({
    message: t`You are now a member of ${folder} and changes to this folder is automatically synced between you and the other members.`,
    severity: 'success',
    testid: 'JoinShareSuccess',
  })
}
