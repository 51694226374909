import { Fragment } from 'react'
import { Trans } from '@lingui/macro'
import { Link } from 'react-router-dom'
import { displayUser, bizApi } from './BizApi'
import { BrandIcon } from '@jotta/ui/BrandIcon'

import dayjs from 'dayjs'

export function BizUsersRoute() {
  const query = bizApi.user.list.useSuspenseQuery({
    select: users => users.map(displayUser),
  })
  const users = query.data

  return (
    <div>
      <h2 className="h2">
        <Trans>Business users</Trans>
      </h2>
      <div className="grid grid-cols-[repeat(5,max-content)] items-center gap-x-4 text-base">
        {users.map(user => (
          <Fragment key={user.email}>
            <Link to={`${user.username}/tf`} className="col-span-full">
              <h2 className="h4">{user.fullName}</h2>
            </Link>
            <Link to={`${user.username}/tf`}>{user.email}</Link>
            <div>{user.isAdminUser ? 'Admin' : 'User'}</div>
            <div>{user.space}</div>
            <div>{dayjs(user.lastUpload).fromNow()}</div>
            <div className="flex gap-1">
              <Link to={`${user.username}/tf`} className="btn btn-outline px-2">
                <BrandIcon icon="SvgEdit" />
                Tanstack
              </Link>
              <Link
                to={`${user.username}/jhf`}
                className="btn btn-outline px-2"
              >
                <BrandIcon icon="SvgEdit" />
                Jan
              </Link>
              <Link
                to={`${user.username}/rhf`}
                className="btn btn-outline px-2"
              >
                <BrandIcon icon="SvgEdit" />
                RHF
              </Link>
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  )
}
