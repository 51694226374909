import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
dayjs.extend(relativeTime)
;(window as any).dayjs = dayjs

export const RelativeTime: React.FC<{ timestamp: number }> = ({
  timestamp,
}) => {
  const ts = dayjs(timestamp)
  return (
    <span
      title={ts.format('L LTS')}
      sx={{
        whiteSpace: 'nowrap',
      }}
    >
      {ts.fromNow()}
    </span>
  )
}

/**
 * Photo capture date is wall clock time disguised as UTC
 * Don't use this for anything other than photo capture date
 */
export const RelativeTimePhotoCaptureDate: React.FC<{ timestamp: number }> = ({
  timestamp,
}) => {
  const ts = dayjs(timestamp).add(new Date().getTimezoneOffset(), 'm')
  return (
    <span
      title={ts.format('L LTS')}
      sx={{
        whiteSpace: 'nowrap',
      }}
    >
      {ts.fromNow()}
    </span>
  )
}
