// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StickyToolbar_container__cVQjB{background-color:var(--color-background);display:flex;height:var(--sticky-header-height,40px);left:0;position:sticky;top:var(--content-top);width:100%;z-index:2}.StickyToolbar_container__cVQjB[data-shadow=true]:after{background:linear-gradient(#0000001a,#0000);content:" ";height:10px;left:0;opacity:1;position:absolute;top:100%;transition:opacity .3s;width:100%;z-index:1}.is-scroll-top .StickyToolbar_container__cVQjB[data-shadow=true]:after{opacity:0}`, "",{"version":3,"sources":["webpack://./../../libraries/photos/src/people/internal/components/StickyToolbar/StickyToolbar.module.scss"],"names":[],"mappings":"AACA,gCAQE,wCAAA,CAPA,YAAA,CAEA,uCAAA,CAEA,MAAA,CADA,eAAA,CAEA,sBAAA,CAJA,UAAA,CAKA,SACA,CACA,wDAaE,2CAAA,CAZA,WAAA,CAIA,WAAA,CAEA,MAAA,CACA,SAAA,CANA,iBAAA,CAIA,QAAA,CAMA,sBAAA,CATA,UAAA,CACA,SASA,CAJA,uEACE,SAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `StickyToolbar_container__cVQjB`
};
export default ___CSS_LOADER_EXPORT___;
