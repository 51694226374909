import type { BrandingInfo } from '@jotta/grpc-connect-openapi/esm/openapi/config/v2/config.v2_pb'
import {
  GetConfigResponse,
  InputFieldVisibility,
} from '@jotta/grpc-connect-openapi/esm/openapi/config/v2/config.v2_pb'
import { Language } from '@jotta/grpc-connect-openapi/esm/openapi/language_pb'
import { Country } from '@jotta/grpc-connect-openapi/esm/openapi/country_pb'
import type { PartialMessage } from '@bufbuild/protobuf'

export const emptyBrandingInfo: PartialMessage<BrandingInfo> = {
  aboutUsLink: '',
  accountTerminationLink: '',
  activeDomain: [],
  activeDomainPostfix: [],
  blogLink: '',
  brandCode: '',
  brandName: '',
  cookiePolicyLink: '',
  customerGroupCode: '',
  customerGroupName: '',
  downloadAndroidLink: '',
  downloadCliLink: '',
  downloadIosLink: '',
  downloadMacLink: '',
  downloadPageLink: '',
  downloadWinLink: '',
  facebookLink: '',
  faqLink: '',
  homepageLink: '',
  cliInstallationInstructionsLink: '',
  partnerInfoLink: '',
  pricingLink: '',
  privacyLink: '',
  signupBusinessLink: '',
  signupFreeLink: '',
  stripePublicKey: '',
  supportLink: '',
  termsLink: '',
  twitterLink: '',
  upgradeLink: '',
  defaultLanguage: Language['UNKNOWN_LANGUAGE'],
  defaultCountry: Country['UNKNOWN_COUNTRY'],
}

export const emptyConfigResponse = new GetConfigResponse({
  activeFeatureToggleIds: [],
  activeFeatures: [],
  brandingInfo: emptyBrandingInfo,
  inputFieldsConfig: {
    inputFieldEmail: InputFieldVisibility.ENABLED,
    inputFieldName: InputFieldVisibility.ENABLED,
    inputFieldPhone: InputFieldVisibility.ENABLED,
    inputFieldCountry: InputFieldVisibility.ENABLED,
    inputFieldLanguage: InputFieldVisibility.ENABLED,
  },
})
