import createCache from '@emotion/cache'
import { CacheProvider, Global } from '@emotion/react'
import { observer } from 'mobx-react-lite'
import type { PropsWithChildren, ReactNode, SuspenseProps } from 'react'
import { Suspense, createContext, useLayoutEffect, useMemo } from 'react'
import type { Theme } from 'theme-ui'
import { ThemeUIProvider } from 'theme-ui'
import { SpinnerThemeUI } from '@jotta/ui/themeui'

import type { BrandStore } from '../BrandStore/BrandStore'
import { getbrandStore } from '../BrandStore/BrandStore'
import type { BrandTheme } from '../../types/BrandTheme'
import { setBodyThemeClassName } from '../../utils/setBodyTheme'
import { Box } from '../../Elements/Box/Box'
import { getBrandAlias } from '@jotta/config/brand'
import { ThemeContextProvider } from '../ThemeProvider/ThemeProvider'
import { ErrorBoundary } from '@sentry/react'
import { ErrorPageBasic as ErrorPage } from '../../Elements/ErrorPage/ErrorPageBasic'

export interface BrandContextValue {
  store: BrandStore
}
export const BrandContext = createContext<BrandContextValue | undefined>(
  undefined,
)
export function BrandContextProvider({
  store,
  children,
}: PropsWithChildren<{ store: BrandStore }>) {
  return (
    <BrandContext.Provider
      value={{
        store,
      }}
    >
      {children}
    </BrandContext.Provider>
  )
}

const myCache = createCache({
  key: 'jotta',
})
myCache.compat = true
export const BaseBrandProvider = observer<{
  theme: BrandTheme
  fallback?: SuspenseProps['fallback']
  children?: ReactNode
}>(function BaseBrandProvider({
  theme,
  children,
  fallback = (
    <Box variant="styles.spinnerOverlayBackground">
      <SpinnerThemeUI />
    </Box>
  ),
}) {
  useLayoutEffect(() => {
    setBodyThemeClassName(theme.branding.brand)
  }, [theme])
  const store = useMemo(() => getbrandStore(theme), [theme])
  return (
    <ThemeContextProvider brandAlias={getBrandAlias(theme.branding.brand)}>
      <CacheProvider value={myCache}>
        <Suspense fallback={fallback}>
          <Global styles={store.globalStyles} />
          <ThemeUIProvider theme={theme as any as Theme}>
            <BrandContextProvider store={store}>
              <ErrorBoundary
                fallback={({ error, resetError }) => (
                  <ErrorPage error={error} resetError={resetError} />
                )}
              >
                {children}
              </ErrorBoundary>
            </BrandContextProvider>
          </ThemeUIProvider>
        </Suspense>
      </CacheProvider>
    </ThemeContextProvider>
  )
})
