import { sum } from './sum'
export type UploadStatus = 'UPLOADING' | 'SUCCESS' | 'ERROR'
export type FileAsObject = Pick<
  File,
  'name' | 'size' | 'type' | 'lastModified'
> & {
  relativePath?: string
}

export function fileAsObject(file: File): FileAsObject {
  return {
    name: file.name,
    size: file.size,
    type: file.type,
    lastModified: file.lastModified,
    relativePath: (file as any).webbkitRelativePath,
  }
}
export interface Upload {
  id: string
  uploadId?: string
  status: UploadStatus
  uploadUrl?: string
  fileName: File['name']
  fileSize: File['size']
  fileType: File['type']
  uploadedBytes: number
  error?: any
}

export interface UploadsMap {
  [uploadId: string]: Upload
}

export interface UploadSummary {
  totalFiles: number
  completedFiles: number
  totalBytes: number
  completedBytes: number
}

export function uploadSummary(uploadsMap: UploadsMap): UploadSummary {
  const uploads = Object.values(uploadsMap)
  return {
    completedBytes: sum(uploads.map(u => u.uploadedBytes)),
    totalBytes: sum(uploads.map(u => u.fileSize)),
    completedFiles: uploads.filter(u => u.status !== 'UPLOADING').length,
    totalFiles: uploads.length,
  }
}

export function isFileEntry(
  entry: FileSystemEntry | null,
): entry is FileSystemFileEntry {
  return Boolean(entry && entry.isFile)
}

export function isDirectoryEntry(
  entry: FileSystemEntry | null,
): entry is FileSystemDirectoryEntry {
  return Boolean(entry && entry.isDirectory)
}

export function renameFile(file: File, newName: string) {
  return new File([file], newName, {
    type: file.type,
    lastModified: file.lastModified,
  })
}

function readEntriesPromised(
  dirReader: FileSystemDirectoryReader,
): Promise<FileSystemEntry[]> {
  return new Promise((resolve, reject) => {
    dirReader.readEntries(resolve, reject)
  })
}

async function readAllEntries(dirReader: FileSystemDirectoryReader) {
  const entries: FileSystemEntry[] = []
  let readEntries: FileSystemEntry[] = []

  do {
    readEntries = (await readEntriesPromised(dirReader)) || []
    entries.push(...readEntries)
  } while (readEntries.length)

  return entries
}

export function entryAsFile(entry: FileSystemFileEntry): Promise<File> {
  return new Promise((resolve, reject) => {
    entry.file(resolve, reject)
  })
}

export async function getFilesFromEntries(
  entries: FileSystemEntry[],
  {
    rename = false,
    onError,
  }: { rename: boolean; onError?: (path: string[]) => void } = {
    rename: false,
  },
) {
  const errorPath: string[] = []
  const p: Promise<(File | File[])[]> = Promise.all(
    entries.map(entry => {
      if (isFileEntry(entry)) {
        const { fullPath, name } = entry
        return entryAsFile(entry).then(file =>
          rename ? renameFile(file, `${fullPath || name}`) : file,
        )
      } else if (isDirectoryEntry(entry)) {
        const dirReader = entry.createReader()
        return readAllEntries(dirReader)
          .then(entries => getFilesFromEntries(entries, { rename }))
          .catch(() => {
            errorPath.push(entry.fullPath)
            return []
          })
          .finally(() => {
            if (onError && errorPath.length) {
              onError(errorPath)
            }
          })
      } else {
        errorPath.push(entry.fullPath)
        return []
      }
    }),
  )

  return (await p).flat()
}

export async function getFilesFromDataTransferItem(
  item: DataTransferItem,
  rename = false,
  onError?: (path: string[]) => void,
) {
  const entry = item.webkitGetAsEntry()
  if (entry) {
    return getFilesFromEntries([entry], {
      rename,
      onError,
    })
  }

  const file = item.getAsFile()
  return file ? [file] : []
}

export async function getFilesFromDataTransferItemList(
  list: DataTransferItemList,
  rename = false,
  onError?: (path: string[]) => void,
) {
  const errorPath: string[] = []
  const files = (
    await Promise.all(
      Array.from(list).map(item =>
        getFilesFromDataTransferItem(item, rename, path =>
          errorPath.push(...path),
        ),
      ),
    )
  ).flat()

  if (onError && errorPath.length) {
    onError(errorPath)
  }

  return files
}

export function doesEventHaveFiles(ev: DragEvent) {
  if (!ev.dataTransfer) {
    return false
  }
  const { items, files } = ev.dataTransfer
  if (items) {
    let result = false
    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      if (item.getAsFile() || item.kind === 'file') {
        result = true
        break
      } else {
        const webkitEntry = item.webkitGetAsEntry()
        if (webkitEntry && (webkitEntry.isDirectory || webkitEntry.isFile)) {
          result = true
          break
        }
      }
    }
    return result
  } else if (files && files.length) {
    return true
  }
}

export function getFileExtension(path: string): string {
  const idx = path.lastIndexOf('.')
  if (idx === -1) {
    return ''
  } else {
    return path.substring(idx + 1)
  }
}
