export enum HelpArticle {
  HOW_TO_START_ON_MOBILE = 4710510,
}

export const HELP_ARTICLES_BASE_URL = 'https://docs.jottacloud.com'
export function generateHelpArticleLink(
  articleId: string | HelpArticle,
): string {
  return `${HELP_ARTICLES_BASE_URL}/articles/${articleId}`
}
