import { ItemKind } from '@jotta/grpc-web/no/jotta/openapi/file/file.v2_pb'
import type { BrandThemeIcon } from '@jotta/types/Brand'
import { useBrandTheme } from '@jotta/ui/useBrandTheme'
import { t } from '@lingui/macro'
import Debug from 'debug'
import moize from 'moize'
import { invert, isNumber } from 'remeda'
const debug = Debug('jotta:types:FileItemType')

export const itemKindEntries = Object.entries(ItemKind)
  .filter((entry): entry is [ItemKindKey, ItemKind] => isNumber(entry[1]))
  .sort((a, b) => a[0].localeCompare(b[0]))

export type ItemKindKey = keyof typeof ItemKind
export type FileItemTypeConfig = {
  [K in keyof typeof ItemKind & string]: {
    label: string
    icon: BrandThemeIcon
    kind: (typeof ItemKind)[K]
  }
}
const ItemKindObj: Record<ItemKindKey, ItemKind> = { ...ItemKind }
const ItemKindReverse: Record<ItemKind, ItemKindKey> = invert(ItemKindObj)
export const getItemKindKey = moize(
  (kind: ItemKind = ItemKind.UNKNOWN_ITEM_KIND): ItemKindKey =>
    ItemKindReverse[kind] || 'UNKNOWN_ITEM_KIND',
)
export const getItemKindByKey = moize(
  (key: ItemKindKey = 'UNKNOWN_ITEM_KIND'): ItemKind =>
    ItemKindObj[key] || ItemKind.UNKNOWN_ITEM_KIND,
)
debug('keys', ItemKindReverse)
export const fileKindConfig: FileItemTypeConfig = {
  ANDROID: {
    get label() {
      return t({
        id: 'ItemKind.ANDROID',
      })
    },
    icon: 'SvgThumbDeviceIphone',
    kind: ItemKind.ANDROID,
  },
  ARCHIVE_KIND: {
    get label() {
      return t({
        id: 'ItemKind.ARCHIVE_KIND',
      })
    },
    icon: 'SvgThumbZip',
    kind: ItemKind.ARCHIVE_KIND,
  },
  AUDIO: {
    get label() {
      return t({
        id: 'ItemKind.AUDIO',
      })
    },
    icon: 'SvgThumbAudio',
    kind: ItemKind.AUDIO,
  },
  CLI: {
    get label() {
      return t({
        id: 'ItemKind.CLI',
      })
    },
    icon: 'SvgThumbDeviceCli',
    kind: ItemKind.CLI,
  },
  COLLABORATION_FOLDER: {
    get label() {
      return t({
        id: 'ItemKind.COLLABORATION_FOLDER',
      })
    },
    icon: 'SvgThumbFolderShared',
    kind: ItemKind.COLLABORATION_FOLDER,
  },
  DOCUMENT: {
    get label() {
      return t({
        id: 'ItemKind.DOCUMENT',
      })
    },
    icon: 'SvgThumbFile',
    kind: ItemKind.DOCUMENT,
  },
  EXCEL: {
    get label() {
      return t({
        id: 'ItemKind.EXCEL',
      })
    },
    icon: 'SvgThumbXlsx',
    kind: ItemKind.EXCEL,
  },
  HTML: {
    get label() {
      return t({
        id: 'ItemKind.HTML',
      })
    },
    icon: 'SvgThumbHtmlxml',
    kind: ItemKind.HTML,
  },
  IMAC: {
    get label() {
      return t({
        id: 'ItemKind.IMAC',
      })
    },
    icon: 'SvgThumbDeviceDesktop',
    kind: ItemKind.IMAC,
  },
  IMAGE: {
    get label() {
      return t({
        id: 'ItemKind.IMAGE',
      })
    },
    icon: 'SvgThumbImage',
    kind: ItemKind.IMAGE,
  },
  IPAD: {
    get label() {
      return t({
        id: 'ItemKind.IPAD',
      })
    },
    icon: 'SvgThumbDeviceIpad',
    kind: ItemKind.IPAD,
  },
  IPHONE: {
    get label() {
      return t({
        id: 'ItemKind.IPHONE',
      })
    },
    icon: 'SvgThumbDeviceIphone',
    kind: ItemKind.IPHONE,
  },
  LAPTOP: {
    get label() {
      return t({
        id: 'ItemKind.LAPTOP',
      })
    },
    icon: 'SvgThumbDeviceLaptop',
    kind: ItemKind.LAPTOP,
  },
  MACBOOK: {
    get label() {
      return t({
        id: 'ItemKind.MACBOOK',
      })
    },
    icon: 'SvgThumbDeviceLaptop',
    kind: ItemKind.MACBOOK,
  },
  MACPRO: {
    get label() {
      return t({
        id: 'ItemKind.MACPRO',
      })
    },
    icon: 'SvgThumbDeviceDesktop',
    kind: ItemKind.MACPRO,
  },
  MODULE: {
    get label() {
      return t({
        id: 'ItemKind.MODULE',
      })
    },
    icon: 'SvgThumbFolder',
    kind: ItemKind.MODULE,
  },
  OTHER: {
    get label() {
      return t({
        id: 'ItemKind.OTHER',
      })
    },
    icon: 'SvgThumbFile',
    kind: ItemKind.OTHER,
  },
  PDF: {
    get label() {
      return t({
        id: 'ItemKind.PDF',
      })
    },
    icon: 'SvgThumbPdf',
    kind: ItemKind.PDF,
  },
  POWERPOINT: {
    get label() {
      return t({
        id: 'ItemKind.POWERPOINT',
      })
    },
    icon: 'SvgThumbPptx',
    kind: ItemKind.POWERPOINT,
  },
  PRESENTATION: {
    get label() {
      return t({
        id: 'ItemKind.PRESENTATION',
      })
    },
    icon: 'SvgThumbPresentation',
    kind: ItemKind.PRESENTATION,
  },
  REGULAR_FOLDER: {
    get label() {
      return t({
        id: 'ItemKind.REGULAR_FOLDER',
      })
    },
    icon: 'SvgThumbFolder',
    kind: ItemKind.REGULAR_FOLDER,
  },
  RICH_TEXT: {
    get label() {
      return t({
        id: 'ItemKind.RICH_TEXT',
      })
    },
    icon: 'SvgThumbRichText',
    kind: ItemKind.RICH_TEXT,
  },
  SPREADSHEET: {
    get label() {
      return t({
        id: 'ItemKind.SPREADSHEET',
      })
    },
    icon: 'SvgThumbSpreadsheet',
    kind: ItemKind.SPREADSHEET,
  },
  TEXT: {
    get label() {
      return t({
        id: 'ItemKind.TEXT',
      })
    },
    icon: 'SvgThumbPlainText',
    kind: ItemKind.TEXT,
  },
  UNKNOWN_ITEM_KIND: {
    get label() {
      return t({
        id: 'ItemKind.UNKNOWN_ITEM_KIND',
      })
    },
    icon: 'SvgThumbFile',
    kind: ItemKind.UNKNOWN_ITEM_KIND,
  },
  VIDEO: {
    get label() {
      return t({
        id: 'ItemKind.VIDEO',
      })
    },
    icon: 'SvgThumbVideo',
    kind: ItemKind.VIDEO,
  },
  WINDOWS_PHONE: {
    get label() {
      return t({
        id: 'ItemKind.WINDOWS_PHONE',
      })
    },
    icon: 'SvgThumbDeviceIphone',
    kind: ItemKind.WINDOWS_PHONE,
  },
  WINDOWS_TABLET: {
    get label() {
      return t({
        id: 'ItemKind.WINDOWS_TABLET',
      })
    },
    icon: 'SvgThumbDeviceIpad',
    kind: ItemKind.WINDOWS_TABLET,
  },
  WORD: {
    get label() {
      return t({
        id: 'ItemKind.WORD',
      })
    },
    icon: 'SvgThumbDocx',
    kind: ItemKind.WORD,
  },
  WORKSTATION: {
    get label() {
      return t({
        id: 'ItemKind.WORKSTATION',
      })
    },
    icon: 'SvgThumbDeviceDesktop',
    kind: ItemKind.WORKSTATION,
  },
}

export function getFileKindConfig(kind: ItemKind) {
  return fileKindConfig[ItemKindReverse[kind]]
}
export function getFileKindLabel(kind: ItemKind) {
  return getFileKindConfig(kind).label
}
export const getFileKindIcon = moize(function getFileKindIcon(kind: ItemKind) {
  return getFileKindConfig(kind).icon
})
export function useFileKindIcon(kind: ItemKind) {
  const { theme } = useBrandTheme()
  return theme.icons[getFileKindIcon(kind)]
}
