import type { ReactNode } from 'react'
import { Trans } from '@lingui/macro'
import { PlainBrandIcon } from '../../Icons/BrandIcon/BrandIcon'
import { showNewIntercomMessage } from '@jotta/intercom'
import { useGetConfig } from '@jotta/grpc-connect-client/config'
import { cn } from '@jotta/utils/css'
import { handleUnknownError } from '@jotta/types/AppError'

type ErrorMessageProps = {
  className?: string
  error?: Error | null
  children?: ReactNode
  marginTop?: boolean
}

export function ErrorMessage({
  error,
  children,
  className,
  marginTop,
}: ErrorMessageProps) {
  const supportLink = useGetConfig()?.data?.brandingInfo?.supportLink
  const errorMessage = error?.message
  const view = error && handleUnknownError(error).view
  return (
    (error || children || view) && (
      <div
        className={cn(
          'alert alert-danger grid grid-cols-2 items-start',
          { 'mt-4': marginTop },
          className,
        )}
        style={{
          gridTemplateColumns: '32px auto',
        }}
      >
        <PlainBrandIcon icon="SvgInfo" height={24} />
        {error && !view && (
          <>
            <h3 className="h3">
              <Trans>We're sorry! Something went wrong</Trans>
            </h3>
            <div />
            <div>
              <Trans>
                Please try again or reach out to our{' '}
                <a
                  target="_blank"
                  className="link"
                  rel="noreferrer"
                  href={supportLink}
                  onClick={e => showNewIntercomMessage(e, errorMessage)}
                >
                  customer support
                </a>
              </Trans>
              <div className="mt-4">{errorMessage}</div>
            </div>
            <div />
          </>
        )}
        <div>
          {children}
          {view}
        </div>
      </div>
    )
  )
}
