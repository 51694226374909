// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AlbumInfo_title__hZDeQ{border-bottom:1px solid #0000;color:var(--color-heading-album-info,var(--color-background-text));font-size:var(--fz6);font-weight:var(--fw-body);margin:0;max-width:100%;outline:none;padding:0}.AlbumInfo_title__hZDeQ input{all:unset;width:100%}.AlbumInfo_title__hZDeQ[data-editable=true]:focus-within{border-bottom-color:#000}@media(hover:hover){.AlbumInfo_title__hZDeQ[data-editable=true]:hover{border-bottom-color:#000}}body.saturn .AlbumInfo_title__hZDeQ{color:var(--color-gray-bg)}body.saturn .AlbumInfo_title__hZDeQ:focus-within{opacity:.8}body.comhem .AlbumInfo_title__hZDeQ{color:var(--color-purple-hue-gray)}.AlbumInfo_publicShareTitle__6ex0N{font-size:var(--fz5);font-weight:var(--fw-body);margin:0;padding:0}`, "",{"version":3,"sources":["webpack://./../../libraries/photos/src/components/Album/AlbumInfo/AlbumInfo.module.scss"],"names":[],"mappings":"AACA,wBAME,6BAAA,CAGA,kEAAA,CALA,oBAAA,CAGA,0BAAA,CALA,QAAA,CADA,cAAA,CAOA,YAAA,CALA,SAMA,CACA,8BACE,SAAA,CACA,UAAA,CAGA,yDACE,wBAAA,CAGF,oBACE,kDACE,wBAAA,CAAA,CAKN,oCACE,0BAAA,CACA,iDACC,UAAA,CAIF,oCACC,kCAAA,CAGJ,mCAGE,oBAAA,CACA,0BAAA,CAHA,QAAA,CACA,SAEA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `AlbumInfo_title__hZDeQ`,
	"publicShareTitle": `AlbumInfo_publicShareTitle__6ex0N`
};
export default ___CSS_LOADER_EXPORT___;
