import { useBrandStore } from '@jotta/ui/useBrandTheme'
import type { Person } from '@jotta/grpc-connect-openapi/people'
import { createPath } from '@jotta/utils/pathUtils'
import { plural, t } from '@lingui/macro'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import { AlbumsSectionHeader } from '../../../../components/Album/AlbumsSection/AlbumsSectionHeader'
import styles from './PersonRow.module.scss'
import { PersonThumbLink } from './PersonThumb'
import { tt } from '@jotta/i18n'

/**
 * Single row of people for use on album page
 */
export const PersonRow = observer(function PersonRow({
  people,
  count = people.length,
  /** Each thumb links to this path + personId */
  linkBase = '/photo/album/people',
  /** Each thumb links to this path + personId */
  allPeopleLink = linkBase,
  allPeopleLinkText,
}: {
  count?: number
  people: Person[]
  linkBase?: string
  allPeopleLink?: string
  allPeopleLinkText?: string
}) {
  const branding = useBrandStore()
  const columns = Math.max(2, Math.round(branding.contentWidthSafe / 130))
  const countLabel = plural(count, {
    one: '# person',
    other: '# people',
  })
  return (
    <div
      className={styles.row}
      style={{
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
      }}
    >
      <AlbumsSectionHeader
        title={t`People`}
        countLabel={countLabel}
        className={styles.header}
      />
      {people.slice(0, columns - 1).map(person => (
        <PersonThumbLink
          person={person}
          to={createPath(linkBase, person.id)}
          key={person.id}
        />
      ))}
      <Link className={styles.more} to={allPeopleLink}>
        {allPeopleLinkText || tt`See all`}
      </Link>
    </div>
  )
})
