import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import { isAlbumUser } from '../Album/AlbumInfo/AlbumInfo.loaders'
import type { PhotoActionHandlerActionProps } from './PhotoActionHandlers'

export const ActionAddPhotos = observer<
  PhotoActionHandlerActionProps<'ADD_PHOTOS'>
>(function ActionAddPhotos({
  action,
  albumId,
  shareId,
  album,
  buttonVariant = 'buttons.albumActionButton',
  onActionDialogClose,
  username,
}) {
  // const id = !isAlbumUser && shareId ? shareId : albumId
  return (
    <Link
      aria-label={action.label}
      data-testid={action.testid}
      key={action.actionType}
      to={
        album && isAlbumUser(album, username)
          ? `/photo?add-photos=${album.id}`
          : `/share/${shareId}/addphotos`
      }
      sx={{ variant: buttonVariant }}
    >
      <PlainBrandIcon icon={action.icon} />
      <span>{action.label}</span>
    </Link>
  )
})
