import type { FC } from 'react'

import type { BoxPropsAs } from '@jotta/ui/Box'
import { Box } from '@jotta/ui/Box'
import { forwardRef } from 'react'
export const Text: FC<React.HTMLProps<HTMLSpanElement>> = props => {
  return <span {...props} />
}

export const Paragraph = forwardRef<
  HTMLHeadingElement,
  BoxPropsAs<'p', {}, 'styles'>
>(function Paragraph({ variant = 'styles.p', ...props }, ref) {
  const Cmp = Box as any
  return <Cmp variant={variant} ref={ref} {...props} />
})
