import { Button } from '@jotta/ui/Button'
import { Input } from '@jotta/ui/Input'
import { Stack } from '@jotta/ui/Stack'
import { getTranslatedFilenameFromApiPath } from '@jotta/grpc-js-client/getTranslatedFilenameFromApiPath'
import type { ShareUserStatus } from '@jotta/grpc-js-client/sharing/parseShareInfo'
import { parseShareInfo } from '@jotta/grpc-js-client/sharing/parseShareInfo'
import { FolderShareInviteStatus } from '@jotta/grpc-web/no/jotta/openapi/sharing/v2/sharing.v2_pb'
import { useDebounce } from '@jotta/hooks'
import type { FileRouteContextSchema } from '@jotta/types/schemas/FileRouteSchema'
import { ConfirmDialog } from '@jotta/ui/ConfirmDialog'
import { CopyInputFragment } from '@jotta/ui/CopyInput'
import { RadixDialog } from '@jotta/ui/RadixDialog'
import { RadixDialogToast } from '@jotta/ui/RadixDialogToast'
import type { RadixModalProps } from '@jotta/ui/RadixModal'
import { RadixModal } from '@jotta/ui/RadixModal'
import { ToggleButton } from '@jotta/ui/ToggleButton'
import { Trans, t } from '@lingui/macro'
import { DialogClose } from '@radix-ui/react-dialog'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useRef, useState } from 'react'
import type { PathItemStore } from '../../PathItem/PathItemStore'
import { AddressBookDropdown } from './AddressBookDropdown'
import { useAddressBook } from './AddressBookStore'
import { useShareInfo } from './FileActionShareApi'
import styles from './ShareFileDialog.module.scss'
import { ShareFileDialogStore } from './ShareFileDialogStore'
import { ShareMembersList } from './ShareMembersList'
import { UserAlreadyInvitedDialog } from './UserAlreadyInvitedDialog'

const debug = Debug('jotta:files:action:ShareDialog')

function getInviteStatusLabel(status: FolderShareInviteStatus) {
  switch (status) {
    case FolderShareInviteStatus.DECLINED:
      return t`Invitation declined`
    case FolderShareInviteStatus.NOT_SENT:
      return t`Pending`
    case FolderShareInviteStatus.SENT:
      return t`Invited`
    case FolderShareInviteStatus.UNKNOWN:
      return t`Failed`
  }
}
export function getShareUserStatusLabel(status: ShareUserStatus) {
  switch (status) {
    case 'invite_declined':
      return getInviteStatusLabel(FolderShareInviteStatus.DECLINED)
    case 'invite_not_sent':
      return getInviteStatusLabel(FolderShareInviteStatus.NOT_SENT)
    case 'invite_pending':
      return getInviteStatusLabel(FolderShareInviteStatus.SENT)
    case 'member':
      return t`Member`
    case 'owner':
      return t`Owner`
  }
}

interface ShareFileDialogProps extends RadixModalProps {
  item: PathItemStore
  context: keyof typeof FileRouteContextSchema.Values
  onLeaveShare?: (folderShareId: string) => void
}

export const ShareFileDialog = observer<ShareFileDialogProps>(
  function ShareFileDialog({
    item,
    open = false,
    context,
    onLeaveShare,
    onClose,
    ...props
  }) {
    const [toastOpen, setToastOpen] = useState(false)
    const [store] = useState(() => new ShareFileDialogStore(item))
    const { path } = item
    const { isShareOwner, removeAllMembers } = store
    const query = useShareInfo({
      variables: {
        path,
      },
      refetchOnWindowFocus: true,
    })

    useEffect(() => {
      if (query.data) {
        store.shareInfo = parseShareInfo(query.data)
      }
    }, [query.data, store])

    const { hasMembers, isShared, folderShareId, canShareCollaboratively } =
      store.shareInfo
    const title = getTranslatedFilenameFromApiPath(path)
    const showLeaveFolderButton = !!onLeaveShare && !isShareOwner && hasMembers
    const showRemoveAllMembersButton = isShareOwner && !!store.memberList.length
    const formRef = useRef<HTMLFormElement>(null)
    const scrollMemberList = store.scrollMemberList
    const userAlreadyInvited = store.userAlreadyInvited
    const closeUserAlreadyInvitedDialog = useCallback(() => {
      store.userAlreadyInvited = null
    }, [store])

    const showCollaborationError = ![
      'photos',
      'archive',
      'contacts',
      'backup',
    ].includes(context)

    const { data: addressBook } = useAddressBook()
    const [addressBookOpen, setAddressBookOpen] = useState(false)

    useEffect(() => {
      if (!scrollMemberList) {
        return
      }

      if (formRef.current) {
        formRef.current.scrollIntoView(true)
      }

      store.scrollMemberList = false
    }, [store, scrollMemberList])

    useEffect(() => {
      if (!userAlreadyInvited) {
        return
      }

      const form = formRef.current

      return () => {
        if (form) {
          form.querySelector('input')?.focus()
        }
      }
    }, [userAlreadyInvited])

    const [inputValue, setInputValue] = useState('')

    const submitDelayed = useDebounce(
      useCallback((email?: string) => {
        const el = formRef.current?.querySelector('input')
        if (el && email) {
          el.value = email
        }
        formRef.current?.dispatchEvent(
          new Event('submit', { cancelable: true, bubbles: true }),
        )
      }, []),
      150,
    )

    return (
      <RadixModal open={open} onClose={onClose} {...props}>
        <RadixDialog
          onInteractOutside={e => {
            if (
              e.target instanceof HTMLElement &&
              e.target.closest('#cookie-information-template-wrapper')
            ) {
              e.preventDefault()
            }
          }}
          smallTitle={t`Share`}
          title={title}
          onClose={onClose}
          toastPosition="belowcontent"
          data-testid="ShareFileDialog"
          footer={
            <Stack horizontal gap={2}>
              {showLeaveFolderButton && (
                <Button
                  variant="buttons.secondary"
                  onClick={() => onLeaveShare(folderShareId)}
                >
                  <Trans>Leave folder</Trans>
                </Button>
              )}
              {showRemoveAllMembersButton && (
                <ConfirmDialog
                  title={t`Remove all members`}
                  description={t`Are you sure you want to remove all the members? If you regret this you can always share the folder and invite all the members again.`}
                  cancelText={t`Cancel`}
                  confirmText={t`Remove all members`}
                  triggerButton={
                    <Button variant="buttons.secondary">
                      <Trans>Remove all members</Trans>
                    </Button>
                  }
                  onConfirm={removeAllMembers}
                />
              )}
              {userAlreadyInvited && (
                <UserAlreadyInvitedDialog
                  user={userAlreadyInvited}
                  onClose={closeUserAlreadyInvitedDialog}
                />
              )}
              <DialogClose
                sx={{
                  variant: 'buttons.accent',
                }}
              >
                <Trans>Close</Trans>
              </DialogClose>
            </Stack>
          }
        >
          <div className={styles.container}>
            {isShareOwner && (
              <div className={styles.togglegrid}>
                <label id="toggleFileSharingLabel" htmlFor="toggleFileSharing">
                  <Trans>Enable public access to share as a link.</Trans>
                </label>
                <div className={styles.toggle}>
                  <ToggleButton
                    id="toggleFileSharing"
                    aria-labelledby="toggleFileSharingLabel"
                    isChecked={isShared}
                    onToggle={store.toggleShare}
                  />
                </div>
                {isShared && (
                  <CopyInputFragment
                    value={store.shareLink}
                    onCopy={() => setToastOpen(true)}
                    buttonVariant="buttons.copyInputButton"
                    buttonText={t`Copy link`}
                  />
                )}
              </div>
            )}
            <ShareMembersList store={store} />
            {canShareCollaboratively && (
              <form
                onSubmit={store.onSubmitInviteMember}
                className={styles.grid}
                autoComplete="off"
                ref={formRef}
              >
                <AddressBookDropdown
                  open={addressBookOpen}
                  onOpenChange={setAddressBookOpen}
                  onKeyHighlight={entry => {
                    const el = formRef.current?.querySelector('input')
                    if (!el) {
                      return
                    }
                    if (!entry) {
                      el.value = inputValue
                    } else {
                      el.value = entry.email
                    }
                  }}
                  list={addressBook?.search(inputValue) || []}
                  onSelect={entry => {
                    const form = formRef.current
                    const el = form?.querySelector('input')
                    if (!form || !el) {
                      return
                    }

                    if (entry) {
                      el.value = entry.email
                      submitDelayed(entry.email)
                    } else {
                      submitDelayed()
                      submitDelayed.flush()
                    }
                  }}
                >
                  <Input
                    type="text"
                    name="inviteMember"
                    placeholder={t`Invite with email address`}
                    onChange={e => {
                      setInputValue(e.target.value)
                    }}
                    sx={{ fontSize: '16px' }}
                  />
                </AddressBookDropdown>
                <Button variant="buttons.secondary">
                  <Trans>Add members</Trans>
                </Button>
              </form>
            )}
            {item.isFolder &&
              isShareOwner &&
              !canShareCollaboratively &&
              showCollaborationError && (
                <p>
                  <Trans>
                    You can't add members to this folder because some parent or
                    child folder is already shared.
                  </Trans>
                </p>
              )}
            {!isShareOwner && !showLeaveFolderButton && (
              <p>
                <Trans>Only owners can share files and folders</Trans>
              </p>
            )}
          </div>
          <RadixDialogToast
            duration={2000}
            severity="successOutline"
            direction="fromBottom"
            open={open && toastOpen}
            onOpenChange={setToastOpen}
            title={t`Copied to clipboard`}
            data-testid="CopySharedPhotosSuccess"
          />
        </RadixDialog>
      </RadixModal>
    )
  },
)
