import { Box, Container } from '@jotta/ui/Box'
import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { Grid } from '@jotta/ui/Grid'
import { getRecentFiles } from '@jotta/grpc-js-client/fileService'
import {
  ItemKind,
  ItemType,
} from '@jotta/grpc-web/no/jotta/openapi/file/file.v2_pb'
import type { PathItemObject } from '@jotta/types/Files'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { PublicFileItem } from '../store/PublicFileItem'
import { useUsername } from '@jotta/grpc-connect-client/customer'

const debug = Debug('jotta:publicfiles:routes:web:recentFiles')

function FileRow(props: { item: PublicFileItem }) {
  const item = props.item
  return (
    <Grid gap={2} columns={[3, '1fr 11fr 2fr']}>
      <Box
        p={2}
        sx={{
          alignSelf: 'start',
          justifySelf: 'center',
          alignItems: 'center',
          width: '32px',
        }}
      >
        {item.thumb ? (
          <img src={item.thumb} onError={item.onThumbLoadError} alt="" />
        ) : (
          <PlainBrandIcon icon={item.icon} />
        )}
      </Box>
      <Box p={2} sx={{ flex: '1 1 auto' }}>
        <Grid
          columns={1}
          sx={{
            '> span': {
              opacity: 0,
              transition: 'opacity .25s ease-in-out .0s',
            },
            '&:hover': {
              '> span': {
                opacity: 1,
              },
            },
          }}
        >
          <Box sx={{ fontSize: 2 }}>{item.name}</Box>
          <span
            sx={{
              fontWeight: 'light',
              textOverflow: 'ellipsis',
              // transition: 'opacity .15s ease-in-out .0s',
              // opacity: 0,
              // '&:hover': {
              //   opacity: 1,
              // },
            }}
          >
            {item.path}
          </span>
        </Grid>
      </Box>
      <Box p={2}>
        <span>{item.dateTimeStr}</span>
      </Box>
    </Grid>
  )
}

const FilesList: React.FC<{ item: PublicFileItem }> = ({ item }) => {
  return (
    // eslint-disable-next-line tailwindcss/no-custom-classname
    <Container className={'list'}>
      <Grid gap={2} columns={[3, '1fr 10fr 2fr']}>
        <Box p={2}></Box>
        <Box p={2} sx={{ fontWeight: 'bold' }}>
          {'Name'}
        </Box>
        <Box p={2} sx={{ fontWeight: 'bold' }}>
          {'Changed at'}
        </Box>
      </Grid>
      {item.children.map(fileItem => (
        <FileRow key={fileItem.path} item={fileItem} />
      ))}
    </Container>
  )
}

export const RecentFilesRoute = observer<{}>(function RecentFilesRoute() {
  const username = useUsername()
  const [isLoading, setIsLoading] = useState(true)
  const [rootItem, setRootItem] = useState<PublicFileItem | undefined>(
    undefined,
  )

  useEffect(() => {
    let mounted = true
    getRecentFiles().then((res: Array<PathItemObject>) => {
      if (mounted) {
        const fakeRootItem: PathItemObject = {
          childrenList: res,
          kind: ItemKind.MODULE,
          name: 'Recent Files',
          owner: username,
          path: '/',
          type: ItemType.FOLDER,
          downloadLink: '',
          size: 0,
          checksum: '',
          createdAtMillis: 0,
          modifiedAtMillis: 0,
          deletedAtMillis: 0,
          mime: '',
          publicLinkId: '',
          actionList: [],
          thumbLink: '',
          commentItemId: '',
          commentAuthToken: '',
          folderShareId: '',
          encodedContentRef: '',
          contentId: '',
        }
        const fi = new PublicFileItem(fakeRootItem)

        setRootItem(fi)
        setIsLoading(false)
      }
    })
    return () => {
      mounted = false
    }
  }, [username])

  return (
    <Container
      data-testid="RecentFilesContainer"
      variant="layout.container"
      sx={{
        flexFlow: 'column nowrap',
      }}
    >
      <Helmet>
        <title>{'Recent Files'}</title>
      </Helmet>
      <LoadingOverlay open={isLoading} />
      {rootItem && <FilesList item={rootItem} />}
    </Container>
  )
})
