/* eslint-disable tailwindcss/no-custom-classname */
import { Box } from '@jotta/ui/Box'
import { Button } from '@jotta/ui/Button'
import { Grid } from '@jotta/ui/Grid'
import type { Photos } from '@jotta/types/Photos'
import { RelativeTimePhotoCaptureDate } from '@jotta/ui/RelativeTime'
import { filesize } from '@jotta/utils/filesize'
import { Trans, t } from '@lingui/macro'
import dayjs from 'dayjs'
import type { FC } from 'react'
import { Fragment } from 'react'

export interface PhotoInfoProps {
  media: Photos.Media
  showOwnerName?: boolean
  onClose?: () => void
}

const getDetails = (media: Photos.Media, showOwnerName?: boolean): any[][] => [
  [t`PhotoInfo.fileSize`, filesize(media.filesize)],
  [t`PhotoInfo.dimension`, `${media.width} x ${media.height}`],
  [t`PhotoInfo.location`, media.geoAddress],
  [t`PhotoInfo.duration`, media.content === `video` && media.duration],
  [t`PhotoInfo.owner`, showOwnerName && media.ownerFullName],
]

const getCameraSettings = (media: Photos.Media): any[][] | undefined =>
  media.content === 'image'
    ? [
        [t`PhotoInfo.camera`, media.camera],
        [t`PhotoInfo.focalLength`, media.focalLength],
        [t`PhotoInfo.exposure`, media.exposure],
        [`ISO`, media.iso],
      ]
    : undefined

export const PhotoInfo: FC<PhotoInfoProps> = ({
  media,
  showOwnerName,
  onClose,
}) => {
  const formattedCaptureDate = dayjs.utc(media.capturedDate).format('lll')
  const cameraSettings = getCameraSettings(media)
  const closeButtonHandler = () => {
    if (onClose) {
      onClose()
    }
  }

  return (
    <Grid variant="styles.photoInfoStyles">
      {onClose && (
        <Button
          icon="SvgClose"
          sx={{
            position: 'absolute',
            background: 'none',
            border: 'none',
            px: 3,
            opacity: 0.6,
            height: '60px',
            color: 'inherit',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
              opacity: 0.9,
            },
            svg: { fontSize: 4 },
          }}
          onClick={closeButtonHandler}
        />
      )}
      <h2 sx={{ fontWeight: 'heading' }}>{formattedCaptureDate}</h2>
      <h4 className="faded" sx={{ fontWeight: 'body' }}>
        {media.filename}
      </h4>
      <p className="faded">
        <RelativeTimePhotoCaptureDate timestamp={media.capturedDate} />
      </p>
      <Grid columns="1fr 1fr" gap="0" sx={{ mt: 4, mb: 3 }}>
        {getDetails(media, showOwnerName).map(
          ([name, value]) =>
            !!value && (
              <Fragment key={name}>
                <p className="faded">{name}</p>
                <p>{value}</p>
              </Fragment>
            ),
        )}
      </Grid>
      {media.gpsCoords && (
        <img
          sx={{ width: '100%', height: '240px', objectFit: 'cover' }}
          src={`//maps.googleapis.com/maps/api/staticmap?markers=${media.gpsCoords.replace(
            ' ',
            '',
          )}&zoom=13&scale=2&size=300x240&maptype=roadmap&format=jpg&visual_refresh=true&key=AIzaSyCD2pEVt8hMzhUi57udZ37BLm3iByghYw0`}
          alt="Geo Location"
        />
      )}
      {cameraSettings && (
        <>
          {cameraSettings.every(e => !!e[1]) && (
            <Box sx={{ mt: 3, mb: 2, opacity: 0.5 }}>
              <h4 sx={{ fontWeight: 'heading' }}>
                <Trans id="PhotoInfo.cameraSettings" />
              </h4>
              <hr sx={{ width: '100%', m: 0, opacity: 0.1 }} />
            </Box>
          )}
          <Grid columns="1fr 1fr" gap="0">
            {cameraSettings.map(
              ([name, value]) =>
                !!value && (
                  <Fragment key={name}>
                    <p className="faded">{name}</p>
                    <p>{value}</p>
                  </Fragment>
                ),
            )}
          </Grid>
        </>
      )}
    </Grid>
  )
}
