import { Box } from '@jotta/ui/Box'
import { useAlertListStore } from '@jotta/ui/AlertStore'
import { CopyInputFragment } from '@jotta/ui/CopyInput'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import { RadixDialog } from '@jotta/ui/RadixDialog'
import { RadixDialogToast } from '@jotta/ui/RadixDialogToast'
import type { RadixModalProps } from '@jotta/ui/RadixModal'
import { RadixModal } from '@jotta/ui/RadixModal'
import { ToggleButton } from '@jotta/ui/ToggleButton'
import { createUrl } from '@jotta/utils/url'
import { Trans, plural, t } from '@lingui/macro'

import { useEffect, useState } from 'react'
import { useCopyToClipboard } from 'react-use'
export interface SharePhotoDialogProps extends RadixModalProps {
  isLoading?: boolean
  onSharePhoto: (shared: boolean) => void
  shareId?: string
  count: number
}

export const SharePhotoDialog: React.FC<SharePhotoDialogProps> = ({
  open = false,
  isLoading = false,
  onClose,
  onSharePhoto,
  shareId,
  count,
  ...props
}) => {
  const [toastOpen, setToastOpen] = useState(false)
  const isShared = Boolean(shareId)
  const shareLink = shareId ? createUrl('share', shareId) : ''

  const [{ value: copySuccess, error: copyError }] = useCopyToClipboard()
  const { alert } = useAlertListStore()
  useEffect(() => {
    if (copySuccess) {
      console.log('success', toastOpen)
      setToastOpen(true)
    }
  }, [copySuccess, toastOpen])
  useEffect(() => {
    if (copyError) {
      console.warn(copyError)
    }
  }, [alert, copyError])

  return (
    <RadixModal open={open} onClose={onClose} {...props}>
      <RadixDialog
        title={t({
          id: 'SharePhotoDialog.confirmTitle',
          message: plural(count, {
            one: 'Share photo',
            other: 'Share # photos',
          }),
        })}
        onClose={onClose}
        buttons={[
          {
            action: 'close',
            label: t`Close`,
            variant: 'buttons.secondary',
          },
        ]}
      >
        <LoadingOverlay position="absolute" open={isLoading} />

        <Box variant="dialogs.shareDialogWrapper">
          <label id="togglePhotoSharingLabel" htmlFor="togglePhotoSharing">
            <Trans>Turn on photo sharing</Trans>
          </label>

          <ToggleButton
            id="togglePhotoSharing"
            aria-labelledby="togglePhotoSharingLabel"
            isChecked={isShared}
            onToggle={onSharePhoto}
          />

          {shareId && (
            <CopyInputFragment
              value={shareLink}
              onCopy={() => setToastOpen(true)}
              buttonVariant="buttons.copyInputButton"
            />
          )}
        </Box>
        <RadixDialogToast
          severity="sharePhotos"
          open={toastOpen}
          onOpenChange={setToastOpen}
          title={t`Copied to clipboard`}
          data-testid="CopySharedPhotosSuccess"
        />
      </RadixDialog>
    </RadixModal>
  )
}
