import { Container } from '@jotta/ui/Box'
import {
  TranslateOldShareLinkResponse,
  translateOldShareLink,
} from '@jotta/grpc-js-client/configService'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

const debug = Debug('jotta:publicfiles:routes:legacyshare:index')

export const LegacyPublicShareRoute = observer(
  function LegacyPublicShareRoute() {
    const { shareId = '', username = '' } = useParams<'shareId' | 'username'>()

    debug(`LegacyPublicShareRoute shareId=${shareId} username=${username}`)

    useEffect(() => {
      if (username && shareId) {
        translateOldShareLink(username, shareId).then(res => {
          switch (res.getResultCase()) {
            case TranslateOldShareLinkResponse.ResultCase.FOUND: {
              const encodedRef = res.getFound()?.getEncodedContentRef()
              const link = encodedRef ? `/r/${encodedRef}` : '/'
              window.location.assign(link)
              break
            }
            case TranslateOldShareLinkResponse.ResultCase.NOT_FOUND: {
              // just go to the root:
              window.location.assign('/')
              break
            }
          }
        })
      }
    }, [username, shareId])

    return (
      <Container
        variant="layout.container"
        sx={{
          flexFlow: 'column nowrap',
        }}
      >
        <LoadingOverlay open={true} />
      </Container>
    )
  },
)
