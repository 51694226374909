import type { Photos } from '@jotta/types/Photos'
import { t } from '@lingui/macro'
import { observer } from 'mobx-react-lite'
import { usePhotoStore } from '../../../store/PhotoContext'
import { AlbumsSection } from './AlbumsSection'

export interface AlbumSectionContainerProps {
  albumType: Photos.DisplayCollectionTypeKey
  showAll?: boolean
  backPath?: string
}

export const AlbumSectionContainer = observer<AlbumSectionContainerProps>(
  function AlbumSectionContainer({ albumType, showAll, backPath }) {
    const store = usePhotoStore()

    switch (albumType) {
      case 'NORMAL_ALBUM':
        return (
          <AlbumsSection
            showAll={showAll}
            sectionType="NORMAL_ALBUM"
            title={t`Your albums`}
            albums={store.albumRoot.normalAlbums.data}
            isLoading={store.albumRoot.normalAlbums.isLoading}
            backPath={backPath}
          />
        )
      case 'META_SHARED_ALBUM_TYPES':
        return (
          <AlbumsSection
            showAll={showAll}
            sectionType="META_SHARED_ALBUM_TYPES"
            title={t`Shared`}
            albums={store.albumRoot.sharedAlbums.data}
            isLoading={store.albumRoot.sharedAlbums.isLoading}
            backPath={backPath}
            showAvatar
          />
        )
      case 'LOCATION_ALBUM':
        return (
          <AlbumsSection
            showAll={showAll}
            sectionType="LOCATION_ALBUM"
            title={t`Locations`}
            albums={store.albumRoot.locationAlbums.data}
            isLoading={store.albumRoot.locationAlbums.isLoading}
            backPath={backPath}
          />
        )
    }
  },
)
