import { useLingui } from '@lingui/react'
import { useMemo } from 'react'
import { map, mapToObj, pipe, sort } from 'remeda'
import type { CountryInfo } from './generated/CountryList'
import { countryCodes, countries as countryList } from './generated/CountryList'
import { getDetectedLocale } from './locale'
/**
 * Get localized Country names
 * @returns Names indexed by country code
 */
export function useLocalizedCountryList() {
  const { i18n } = useLingui()
  const detectedCountry = getDetectedLocale()
  const { locale } = i18n
  const { countries, countryNamesByCode } = useMemo(() => {
    const countries = pipe(
      countryList,
      map<readonly CountryInfo[], CountryInfo>(o => ({
        key: o.key,
        code: o.code,
        value: i18n._(o.key),
      })),
      sort((a, b) => a.value.localeCompare(b.value, locale)),
    )
    const countryCodes = countries.map(c => c.code)
    const countryNamesByCode = pipe(
      countries,
      mapToObj(o => {
        return [o.code, o.value]
      }),
    )
    return {
      countries,
      countryCodes,
      countryNamesByCode,
    }
  }, [locale, i18n])
  return {
    detectedCountry: detectedCountry === 'nb' ? 'no' : detectedCountry,
    countries,
    countryCodes,
    countryNamesByCode,
    locale,
  }
}
