import * as Sentry from '@sentry/react'
import { isError } from 'remeda'
import Debug from 'debug'

const debug = Debug('error')

/**
 * Use in the catch clause of a try/catch to log an error as a message to Sentry
 * @param msg Error message
 * @param error The error
 */
export function sentryCaptureMessage(msg: string, error?: unknown) {
  if (isError(error)) {
    Sentry.captureMessage(`${msg}: ${error.message}`)
  } else {
    Sentry.captureMessage(msg)
  }
}

export function enrichError(error: any, ...context: any[]) {
  let richMessage
  try {
    if (context && context.length > 0) {
      error.message =
        richMessage = `[${new Date().getTime()}]${context.map(value => `[${value}]`).join(' ')} ${error.message}`
    }
  } catch (e) {
    debug(richMessage, e)
  }
  return error
}
