import { TextThemeUI } from '@jotta/ui/themeui'
import { RadixDialog } from '@jotta/ui/RadixDialog'
import type { RadixModalProps } from '@jotta/ui/RadixModal'
import { RadixModal } from '@jotta/ui/RadixModal'
import { t } from '@lingui/macro'

export interface LeaveAlbumDialogProps extends RadixModalProps {
  isLoading?: boolean
  onLeaveAlbum: () => void
}

export const LeaveAlbumDialog: React.FC<LeaveAlbumDialogProps> = ({
  open = false,
  isLoading = false,
  onClose,
  onLeaveAlbum,
  ...props
}) => {
  return (
    <RadixModal open={open} onClose={onClose} {...props}>
      <RadixDialog
        title={t`LeaveAlbumShareButton.confirmTitle`}
        onClose={onClose}
        buttons={[
          {
            action: 'close',
            label: t`Cancel`,
          },
          {
            label: t`LeaveAlbumShareButton.confirmLabel`,
            loading: isLoading,
            action: onLeaveAlbum,
            variant: 'buttons.error',
            testId: 'ConfirmLeaveAlbum',
          },
        ]}
      >
        <TextThemeUI variant="styles.p" sx={{ mb: 3 }}>
          {t`LeaveAlbumShareButton.confirmBody`}
        </TextThemeUI>
      </RadixDialog>
    </RadixModal>
  )
}
