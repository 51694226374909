import { useQuery } from '@connectrpc/connect-query'
import type {
  GetOffersResponse_Category,
  Offer,
} from '@jotta/grpc-connect-openapi/esm/openapi/offer/v1/offer_pb'
import { getOffers } from '@jotta/grpc-connect-openapi/offerQuery'
import { tt, useLocale } from '@jotta/i18n'
import { formatPriceConnect } from '@jotta/settings/formatPrice'
import { LoadingOverlaySpinner } from '@jotta/ui/LoadingOverlay'
import { Trans } from '@lingui/macro'
import { Link } from 'react-router-dom'
import { TabContent, UpgradeTabSwitch } from './UpgradeTab'
import { Divider } from '@jotta/ui/Divider'
import { BrandIcon } from '@jotta/ui/BrandIcon'
import { usePaymentInfo } from '../payment/usePaymentInformation'
import { exhaustiveGuard } from '@jotta/utils/exhaustive'

function OfferAlert({ offer }: { offer: Offer }) {
  if (!offer.status.case) {
    return null
  }

  switch (offer.status.case) {
    case 'currentSubscription':
      return (
        <div className="body-md-ln bg-good p-4 text-good">{tt`You have this plan today`}</div>
      )

    case 'storageExceeded':
      return (
        <div className="body-md-ln bg-danger p-4 text-danger">{tt`You store too much for this plan`}</div>
      )

    case 'businessHasActiveUsers':
    case 'familyHasActiveUsers':
      // /web/family
      return (
        <div className="body-md-ln bg-danger p-4 text-danger">
          <p>{`This plan only allows ${offer.status.value.allowedUsers} user${offer.status.value.allowedUsers !== 1 ? 's' : ''}.`}</p>
          <p>
            Users can be removed via <a href="/web/account">settings</a>
          </p>
        </div>
      )

    case 'businessUserNotPermitted':
      return (
        <div className="body-md-ln bg-informative p-4 text-informative">
          Your subscription is administered by{' '}
          {offer.status.value.businessAdminEmails[0]}
          {offer.status.value.businessAdminEmails.length > 1
            ? ` + ${offer.status.value.businessAdminEmails.length - 1} others`
            : ''}
        </div>
      )

    case 'invalid':
    case 'valid':
      return null

    default:
      return exhaustiveGuard(offer.status)
  }
}

function OfferView({ offer }: { offer: Offer }) {
  const locale = useLocale()
  const monthlyPrice = offer.monthly?.basePrice
  const users = offer.productFeaturesLocalized[0]
  const features = offer.productFeaturesLocalized
    .slice(1)
    .filter(feature => !feature.startsWith('*'))
  const notice = offer.productFeaturesLocalized
    .slice(1)
    .filter(feature => feature.startsWith('*'))
  const extraStorageTB = Math.round(
    Number(
      (usePaymentInfo().data?.extraStorageBytes || 0n) / 1_000_000_000_000n,
    ),
  )

  const isCurrent = offer.status.case === 'currentSubscription'
  const isValid = offer.status.case === 'valid'
  const faded = !isCurrent && !isValid
  const highlighted = Boolean((monthlyPrice?.amount || 0) > 0 || isCurrent)
  const link = `/web/upgrade/${offer.productCode}`

  if (offer.status.case === 'invalid') {
    return null
  }

  return (
    <div
      className={`flex min-h-[530px] w-[295px] flex-col justify-between rounded-lg ${faded ? 'bg-grey1' : 'bg-primary'} mt-8 shadow-[0px_0px_10px_0px_rgba(205,201,223,0.80)]`}
    >
      <div className={`flex-auto px-8 pt-8 ${faded ? 'text-grey4' : ''}`}>
        <p className={`body-md-ln ${highlighted ? 'text-[#744FF6]' : ''}`}>
          {offer.productHighlightLocalized || '\u00A0'}
        </p>
        <h3 className="title-xl mt-3">{offer.productNameLocalized}</h3>
        <p className="title-sm mt-4">
          {monthlyPrice && formatPriceConnect(monthlyPrice, locale)}/
          <Trans id="month" />
        </p>
        <Divider className="my-8" />
        <p className="body-sm-ln mb-3">{users}</p>
        {features.map((feature, i) => (
          <div className="flex flex-nowrap items-baseline gap-2" key={i}>
            <p className="relative top-[2px]">
              <BrandIcon icon="SvgBoldCheck" />
            </p>
            <p className="body-sm-ln" key={i}>
              {feature}
            </p>
          </div>
        ))}
        {notice.length > 0 && (
          <div className="body-sm-ln mt-3">
            {notice.map((note, i) => (
              <p key={i}>{note}</p>
            ))}
          </div>
        )}
      </div>
      {isValid && (
        <div className="body-sm-ln mt-2 px-8 pb-8">
          {offer.extraStorageAvailable && isCurrent ? (
            <>
              <p>{`${extraStorageTB}TB extra storage added`}</p>
              <Link to={link} className="btn btn-xl btn-outline mt-2 w-full">
                <BrandIcon icon="SvgBoldPlus" />
                {tt`Add more storage`}
              </Link>
            </>
          ) : (
            <Link
              to={link}
              className="btn btn-xl btn-primary w-full"
            >{tt`Buy now`}</Link>
          )}
        </div>
      )}
      <OfferAlert offer={offer} />
    </div>
  )
}

function OfferCategoryView({
  category,
}: {
  category: GetOffersResponse_Category
}) {
  return (
    <div className="flex flex-wrap justify-center gap-4 p-12">
      {category.offers.map(value => (
        <OfferView key={value.productCode} offer={value}></OfferView>
      ))}
    </div>
  )
}

export const Upgrade: React.FC = () => {
  const { data: getOffersResponse, isLoading } = useQuery(getOffers)

  if (isLoading) {
    return (
      <div className="flex min-h-full w-full items-center justify-center">
        <LoadingOverlaySpinner />
      </div>
    )
  }

  return (
    <div className="w-[calc(100vw-var(--content-left))] flex-auto bg-[#F3F2F8] pr-[var(--scrollbar-width,0px)]">
      <div className="flex flex-col p-12">
        <UpgradeTabSwitch>
          {getOffersResponse?.categories.map(category => (
            <TabContent
              name={category.nameLocalized}
              key={category.nameLocalized}
            >
              <OfferCategoryView category={category}></OfferCategoryView>
            </TabContent>
          ))}
        </UpgradeTabSwitch>
      </div>
    </div>
  )
}
