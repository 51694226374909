import type { BoxPropsAs } from '@jotta/ui/Box'
import type { PhotosApi } from '@jotta/types/PhotosApi'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import { Trans } from '@lingui/macro'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import 'dayjs/plugin/relativeTime'
import { observer } from 'mobx-react-lite'

import { NoSharedPhotos } from '../NoSharedPhotos/NoSharedPhotos'
import styles from './SharedPhotosPage.module.scss'
import { SharedPhotosPageRow } from './SharedPhotosPageRow'

dayjs.extend(advancedFormat)

export interface SharedPhotosPageProps extends BoxPropsAs {
  albums?: readonly PhotosApi.AlbumInfo[]
  isLoading?: boolean
}

export const SharedPhotosPage = observer<SharedPhotosPageProps>(
  function SharedPhotosPage({ albums, isLoading = false }) {
    const showLoadingOverlay = isLoading
    const showEmpty = Boolean(!showLoadingOverlay && albums && !albums.length)
    const showAlbumList = typeof albums !== 'undefined' && albums.length > 0

    return (
      <>
        <LoadingOverlay position="absolute" open={showLoadingOverlay} />
        {showEmpty && <NoSharedPhotos />}
        {showAlbumList && (
          <div className={styles.grid}>
            <h2>
              <Trans>Photos</Trans>
            </h2>
            <h2>
              <Trans>Content</Trans>
            </h2>
            <h2>
              <Trans>Created</Trans>
            </h2>
            <h2>
              <Trans>Link</Trans>
            </h2>
            {albums.map(album => (
              <SharedPhotosPageRow key={album.id} album={album} />
            ))}
          </div>
        )}
      </>
    )
  },
)
