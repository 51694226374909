import type { FC } from 'react'
import type { DialogProps } from '../Dialog/Dialog'
import { Dialog } from '../Dialog/Dialog'
import type { ModalProps } from '../Modal/Modal'
import { Modal } from '../Modal/Modal'
// import Debug from 'debug'
// const debug = Debug('jotta:ui:modal')

export type ModalDialogProps = ModalProps & DialogProps

export const ModalDialog: FC<ModalDialogProps> = ({
  open = false,
  onClose,
  ...props
}) => (
  <Modal open={open} onClose={onClose}>
    <Dialog onClose={onClose} {...props} />
  </Modal>
)
