import { AppLayoutPortal } from '@jotta/ui/AppLayoutPortal'
import { observer } from 'mobx-react-lite'
import type { AlbumInfoStore } from '../../../store/AlbumInfoStore'
import { useGroup } from '../../../store/CommentQuery'
import { CommentsButton } from '../../Comments/CommentsButton'
import { CommentsDrawer } from '../../Comments/CommentsDrawer'
import { useCommentsOpenState } from '../../Comments/useCommentsOpenState'

export const AlbumComments = observer<{ store: AlbumInfoStore }>(
  function AlbumComments({ store }) {
    const [showComments, toggleComments] = useCommentsOpenState()
    const { enabled, commentsGroupId } = store.showComments
    const { data: group } = useGroup(commentsGroupId)

    return (
      <AppLayoutPortal slot="commentsButton">
        <CommentsButton
          count={group?.totalCommentCount}
          onClick={e => {
            toggleComments(true)
            e.stopPropagation()
          }}
        />
        {enabled && (
          <CommentsDrawer
            open={showComments}
            onOpenChange={toggleComments}
            album={store.album}
          />
        )}
      </AppLayoutPortal>
    )
  },
)
