import dayjs from 'dayjs'
import type { Locale } from './locale'
import { isLocale, parseLocale } from './locale'
import Cookies from 'universal-cookie'
const cookies = new Cookies()
import Debug from 'debug'
const debug = Debug('jotta:i18n:languageCookie')

export function setLanguageCookie(locale: any) {
  if (isLocale(locale)) {
    const cookieContent = `lang=${parseLocale(locale)}; Path=/; Domain=${
      window.location.hostname
    }; expires=${dayjs().add(1, 'month').toDate()};`
    debug('Set language cookie: %s', cookieContent)
    cookies.remove('lang', { path: '/', domain: 'jottacloud.com' })
    document.cookie = cookieContent
  }
}
export function getLanguageCookie(): Locale | undefined {
  const lang = cookies.get('lang')
  if (isLocale(lang)) {
    return lang
  }
  return undefined
}
