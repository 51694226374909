import type { BoxOwnProps, BoxProps, BoxPropsAs } from '@jotta/ui/Box'
import { Box } from '@jotta/ui/Box'
import { forwardRef } from 'react'

export interface HeadLineProps {
  /** Level defines the visual heading level */
  level?: 1 | 2 | 3 | 4 | 5 | 6
  /** Tag is for the actual HTML tag used to render the component */
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  className?: string
}
export const Headline = forwardRef<
  HTMLHeadingElement,
  BoxPropsAs<'h1', HeadLineProps, 'styles'>
>(function Headline({ level = 1, tag = 'h1', ...props }, ref) {
  const HeadlineComponent = Box as BoxProps<'h1', BoxOwnProps<'all'>>
  const variant = `styles.h${level}` as const
  return <HeadlineComponent as={tag} variant={variant} ref={ref} {...props} />
})
export const Headline1 = Headline
export const Headline2 = forwardRef<
  HTMLHeadingElement,
  BoxPropsAs<'h2', HeadLineProps, 'styles'>
>(function Headline({ level = 2, tag = 'h2', ...props }, ref) {
  const HeadlineComponent = Box as BoxProps<'h2', BoxOwnProps<'all'>>
  const variant = `styles.h${level}` as const
  return <HeadlineComponent as={tag} variant={variant} ref={ref} {...props} />
})
export const Headline3 = forwardRef<
  HTMLHeadingElement,
  BoxPropsAs<'h3', HeadLineProps, 'styles'>
>(function Headline({ level = 3, tag = 'h3', ...props }, ref) {
  const HeadlineComponent = Box as BoxProps<'h3', BoxOwnProps<'all'>>
  const variant = `styles.h${level}` as const
  return <HeadlineComponent as={tag} variant={variant} ref={ref} {...props} />
})
export const Headline4 = forwardRef<
  HTMLHeadingElement,
  BoxPropsAs<'h4', HeadLineProps, 'styles'>
>(function Headline({ level = 4, tag = 'h4', ...props }, ref) {
  const HeadlineComponent = Box as BoxProps<'h4', BoxOwnProps<'all'>>
  const variant = `styles.h${level}` as const
  return <HeadlineComponent as={tag} variant={variant} ref={ref} {...props} />
})
export const Headline5 = forwardRef<
  HTMLHeadingElement,
  BoxPropsAs<'h5', HeadLineProps, 'styles'>
>(function Headline({ level = 5, tag = 'h5', ...props }, ref) {
  const HeadlineComponent = Box as BoxProps<'h5', BoxOwnProps<'all'>>
  const variant = `styles.h${level}` as const
  return <HeadlineComponent as={tag} variant={variant} ref={ref} {...props} />
})
export const Headline6 = forwardRef<
  HTMLHeadingElement,
  BoxPropsAs<'h6', HeadLineProps, 'styles'>
>(function Headline({ level = 6, tag = 'h6', ...props }, ref) {
  const HeadlineComponent = Box as BoxProps<'h6', BoxOwnProps<'all'>>
  const variant = `styles.h${level}` as const
  return <HeadlineComponent as={tag} variant={variant} ref={ref} {...props} />
})
