import type { FileRouteContext } from '@jotta/types/schemas/FileRouteSchema'
import { isFileRouteContext } from '@jotta/types/schemas/FileRouteSchema'
import { pathToArray } from '@jotta/utils/pathUtils'

export function apiPathToRouteContext(apiPath: string): FileRouteContext {
  const firstPathSegment = (pathToArray(apiPath).at(1) || '').toLowerCase()
  if (isFileRouteContext(firstPathSegment)) {
    return firstPathSegment
  }
  return 'sync'
}
