import type { Field } from '@jotta/hooks'
import { Label } from '../Label/Label'
import type { ReactNode } from 'react'
import { Trans } from '@lingui/macro'

export function FormField({
  field,
  children,
  label,
  description,
}: {
  field: Field
  children: ReactNode
  label?: string
  description?: string
}) {
  const { spec, formSpec, error } = field
  return (
    <Label className="pt-1" label={label} errorMessage={error?.view}>
      {spec.required && !formSpec.suppressIndicatorsForRequiredFields && (
        <span className="body-sm-sh absolute right-0 top-1 text-grey4">
          <Trans>Required</Trans>
        </span>
      )}
      <div className="body-sm-ln pb-2 text-grey4">{description}</div>
      {children}
    </Label>
  )
}
