/**
 * Create an array of numbers from n1 to n2
 * @param n1 Start number
 * @param n2 End number
 * @returns Array of numbers
 */
export function range(n1: number, n2: number): number[] {
  if (n2 === n1) {
    return [n1]
  }
  if (n2 < n1) {
    throw new Error('Second number must be larger than or equal to the first')
  }
  return new Array(n2 - n1).fill(0).map((_, i) => n1 + i)
}
