import { tv } from 'tailwind-variants'

export const textButtonVariants = tv({
  base: 'inline-grid auto-cols-max grid-flow-col items-center gap-2 rounded-md leading-4',
  variants: {
    variant: {
      link: 'text-primary',
    },
    size: {
      small: 'py-1 text-sm sm:py-1',
      flex: 'h-full py-0 sm:py-0',
    },
    disabled: {
      true: 'opacity-50',
    },
  },
})
