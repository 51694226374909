import { randomRange } from './crypto'

/**
 * Wait and then resolve
 *
 * @example
 * Pauses for 500 milliseconds
 * ```ts
 * await delay(500)
 * ```
 *
 * @param waitMs Number of milliseconds to wait
 * @param resolvedValue Reolve with this value, or true if not defined
 * @returns True or specified return value
 */
export function delay<T>(
  waitMs: number,
  resolvedValue: T = true as T,
): Promise<T> {
  return new Promise(resolve =>
    setTimeout(() => resolve(resolvedValue), waitMs),
  )
}

export function randomDelay(minMs: number, maxMs: number) {
  const ms = randomRange(minMs, maxMs)
  return new Promise(resolve => setTimeout(resolve, ms))
}
