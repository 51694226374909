import type { BoxPropsAs } from '@jotta/ui/Box'
import { Stack } from '@jotta/ui/Stack'
import { Headline1 } from '@jotta/ui/Headline'
import { forwardRef } from 'react'

export const SettingsPageContainer = forwardRef<
  HTMLDivElement,
  BoxPropsAs<'div', {}, 'cards'>
>(function SettingsPageContainer({ title, children, ...props }, ref) {
  return (
    <Stack sx={{ variant: 'layout.settingsContainer' }} {...props} ref={ref}>
      <Headline1
        sx={{
          display: ['block', 'block', 'none', 'none'],
          marginBlockEnd: 0,
          mb: -2,
        }}
      >
        {title}
      </Headline1>
      {children}
    </Stack>
  )
})
