import { Box } from '@jotta/ui/Box'
import { BrandLogo } from '@jotta/ui/BrandLogo'
import { RouterLink } from '@jotta/ui/RouterLink'
import type { ThemeUIStyleObject } from '@jotta/ui/themeui'
import { useBrandStore } from '@jotta/ui/useBrandTheme'
import { Trans } from '@lingui/macro'
import { observer } from 'mobx-react-lite'
import { LanguageSelector } from '../LanguageSelector/LanguageSelector'
import { useBrandName } from '@jotta/grpc-connect-client/config'

export interface DownloadPageHeaderProps {
  isLoggedIn?: boolean
}
const wrapper: ThemeUIStyleObject = {
  variant: 'layout.appHeaderContent',
  display: 'flex',
  justifyContent: 'flex-end',
}
const arrow: ThemeUIStyleObject = {
  content: '""',
  borderBottom: '4px solid transparent',
  borderTop: '4px solid transparent',
  borderRight: '4px solid currentColor',
  display: 'inline-block',
  height: 0,
  mt: '-2px',
  verticalAlign: 'middle',
  mr: '10px',
  width: 0,
}

export const DownloadPageHeader = observer<DownloadPageHeaderProps>(
  function DownloadPageHeader({ isLoggedIn = false }) {
    const { theme } = useBrandStore()
    const showLanguageSelector = theme.branding.showLanguageSelector
    const brandName = useBrandName()

    return (
      <header sx={{ variant: 'layout.appHeaderDownload' }}>
        {theme.branding.header.showBrandLogo && (
          <a href="/web/sync" sx={{ cursor: 'pointer' }}>
            <BrandLogo icon="SvgLogoHeader" />
          </a>
        )}
        <Box sx={wrapper}>
          {isLoggedIn ? (
            <RouterLink variant="buttons.openWebInterface" to="/web/sync">
              <Trans id="Open the web interface" values={{ brandName }} />
            </RouterLink>
          ) : (
            <RouterLink variant="links.backHomePageLink" to="/web/sync">
              <span sx={arrow} />
              <Trans>Back to homepage</Trans>
            </RouterLink>
          )}

          {showLanguageSelector && <LanguageSelector />}
        </Box>
      </header>
    )
  },
)
