import { Label } from '@jotta/ui/Label'
import { Stack } from '@jotta/ui/Stack'
import { RadioThemeUI } from '@jotta/ui/themeui'
import type { PaymentInterval } from '@jotta/grpc-web/no/jotta/openapi/subscription/payment.v2_pb'
import { SubscriptionInterval } from '@jotta/grpc-web/no/jotta/openapi/subscription/payment.v2_pb'
import { ModalDialog } from '@jotta/ui/ModalDialog'
import { Text } from '@jotta/ui/Text'
import { Trans, t } from '@lingui/macro'
import type { FC } from 'react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { formatPrice } from '../../../utils/formatPrice'
import { useLocale } from '@jotta/i18n'
// import Debug from 'debug'
// const debug = Debug('jotta:settings:ChangePaymentIntervalDialog')

export interface ChangePaymentIntervalDialogProps {
  /** Modal shown state */
  isOpen: boolean
  /** Modal error message */
  errorMessage?: string
  /** Verify button loading state  */
  isLoading?: boolean
  /** Verification handler  */
  onVerify: (interval: SubscriptionInterval) => void
  /** Cancel handler  */
  onCancel?: () => void
  // are we billing with Vipps?
  isVippsBilling: boolean
  onSetupVipps: (nextInterval: SubscriptionInterval) => void
  /** List of payment intervals */
  paymentIntervalList: PaymentInterval.AsObject[]
  selectedInterval?: SubscriptionInterval
}
export interface ChangePaymentIntervalFormValues {
  interval: SubscriptionInterval
}
export const ChangePaymentIntervalDialog: FC<
  ChangePaymentIntervalDialogProps
> = ({
  isOpen,
  errorMessage,
  isLoading,
  onCancel = () => {},
  onVerify,
  paymentIntervalList,
  selectedInterval,
  isVippsBilling,
  onSetupVipps,
}) => {
  const locale = useLocale()
  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, defaultValues },
    watch,
  } = useForm<ChangePaymentIntervalFormValues>({
    defaultValues: { interval: selectedInterval },
  })

  const onSubmit = ({ interval }: { interval: SubscriptionInterval }) => {
    const subscriptionInterval = Number(interval) as SubscriptionInterval
    if (isVippsBilling) {
      onSetupVipps(subscriptionInterval)
    } else {
      onVerify(subscriptionInterval)
    }
  }

  useEffect(() => {
    reset({ interval: selectedInterval })
    return () => {
      reset()
    }
  }, [selectedInterval, reset, isOpen])

  if (!isOpen) {
    return null
  }

  const intervalValue = watch('interval')
  const isSameInterval = Number(intervalValue) === defaultValues?.interval

  return (
    <ModalDialog
      open={isOpen}
      onClose={onCancel}
      title={t({ id: 'Change interval' })}
      errorMessage={errorMessage}
      buttons={[
        {
          action: 'close',
          label: t({ id: 'Cancel' }),
        },
        {
          label: t({ id: 'Change interval' }),
          loading: isLoading,
          action: handleSubmit(onSubmit),
          disabled: !isDirty || isSameInterval,
        },
      ]}
    >
      <form onSubmit={handleSubmit(onSubmit)} sx={{ mb: 3 }}>
        <Stack gap="2">
          {paymentIntervalList.map(e => (
            <Label key={e.interval} variant="forms.labelHorizontal">
              <RadioThemeUI
                value={e.interval}
                defaultChecked={e.interval === defaultValues?.interval}
                {...register('interval', { required: true })}
              />
              {e.interval === SubscriptionInterval.MONTHLY &&
                t({
                  id: '{PRICE} per month',
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  values: { PRICE: formatPrice(e.price!, locale) },
                })}
              {e.interval === SubscriptionInterval.YEARLY &&
                t({
                  id: '{PRICE} per year',
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  values: { PRICE: formatPrice(e.price!, locale) },
                })}
            </Label>
          ))}
          {isVippsBilling && (
            <Text sx={{ paddingTop: 3 }}>
              <Trans
                id={
                  'Select your new billing interval and sign the Agreement in the Vipps app.'
                }
              />
            </Text>
          )}
        </Stack>
      </form>
    </ModalDialog>
  )
}
