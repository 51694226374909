import { t } from '@lingui/macro'
import { observer } from 'mobx-react-lite'
import { useBrandingInfo } from '@jotta/grpc-connect-client/config'

export const PublicFooterLinks = observer(function PublicFooterLinks() {
  const {
    supportLink,
    aboutUsLink,
    downloadPageLink,
    blogLink,
    termsLink,
    privacyLink,
  } = useBrandingInfo()
  const links = [
    {
      link: supportLink,
      text: t`Help`,
    },
    {
      link: aboutUsLink,
      text: t`About us`,
    },
    {
      link: downloadPageLink,
      text: t`Download`,
    },
    {
      link: blogLink,
      text: t`Blog`,
    },
    {
      link: termsLink,
      text: t`Terms & Conditions`,
    },
    {
      link: privacyLink,
      text: t`Privacy`,
    },
  ].filter(v => Boolean(v.link))

  return (
    <nav>
      {links.map(({ link, text }) => (
        <a key={link} href={link}>
          {text}
        </a>
      ))}
    </nav>
  )
})
