import { queryClient } from '@jotta/query'
import { createPath } from '@jotta/utils/pathUtils'
import Debug from 'debug'
import moize from 'moize'
import { isArray } from 'radash'

import { usePathItem } from '../PathItem/hooks/usePathItem'
const debug = Debug('jotta:files:DisplayFilePath')

export function DisplayFilePath({ path }: { path: string }) {
  const displayPath = useDisplayPath(path)
  return <>{displayPath}</>
}
// Copied from https://github.com/colinhacks/zod/blob/master/src/types.ts#L546

const isBackupDevicePathRegex =
  /^(\/backup\/([0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}))($|\/.*$)/i

export const parseBackupDevicePath = moize(
  function parseBackupDevicePath(path: string) {
    const matches = path.match(isBackupDevicePathRegex)
    const isBackupDevice = isArray(matches)
    const deviceApiPath = matches?.[1] || ''
    const deviceId = matches?.[2] || ''
    const childPath = matches?.[3] || ''
    return {
      path,
      isBackupDevice,
      deviceApiPath,
      deviceId,
      childPath,
    }
  },
  {
    maxSize: 1000,
  },
)
const staleTime = 1000 * 60 * 5
export async function fetchDisplayPath(path: string) {
  try {
    const { isBackupDevice, deviceApiPath, childPath } =
      parseBackupDevicePath(path)
    if (!isBackupDevice) {
      return path
    }
    const item = await queryClient.fetchQuery({
      ...usePathItem.getFetchOptions({
        path: deviceApiPath,
        context: 'backup',
      }),
      staleTime,
    })
    return createPath('backup', item.item.name, childPath)
  } catch (error) {
    debug('Error', error)
    return path
  }
}
export function useDisplayPath(path: string) {
  const { isBackupDevice, deviceApiPath, childPath } =
    parseBackupDevicePath(path)
  const deviceQuery = usePathItem({
    variables: {
      path: deviceApiPath,
      context: 'backup',
    },
    enabled: isBackupDevice,
    staleTime,
  })
  if (!isBackupDevice) {
    return path
  }
  const deviceName = deviceQuery.data?.item.name || ''
  return createPath('backup', deviceName, childPath)
}
