import { FreeSignup } from './FreeSignup'
import { useAndConsumeSearchParam } from '@jotta/router'
import { useParams, useSearchParams } from 'react-router-dom'
import { PaidSignup } from './PaidSignup'
import { useCustomerConfig } from '@jotta/settings/state/hooks/useSettingsStore'
import { Feature } from '@jotta/grpc-connect-openapi/feature'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import { BusinessSignup } from './BusinessSignup'
import { VoucherSignup } from './VoucherSignup'
import { SubscriptionInterval } from '@jotta/grpc-web/no/jotta/openapi/subscription/payment.v2_pb'
import type { RecurringSubscriptionInterval } from '../payment/useOfferState'
import { isLocale, useActivateLocale } from '@jotta/i18n'

function Signup({
  productCode,
  voucherCode,
  federationToken,
  defaultSubscriptionInterval,
}: {
  productCode?: string
  voucherCode?: string
  federationToken?: string
  defaultSubscriptionInterval: RecurringSubscriptionInterval
}) {
  const { data: customerConfig } = useCustomerConfig()

  if (customerConfig) {
    const activeFeaturesList = customerConfig.config?.activeFeatures
    const hasSignup = activeFeaturesList?.includes(Feature.SIGNUP)
    const hasVoucherSignup = activeFeaturesList?.includes(
      Feature.VOUCHER_SIGNUP,
    )

    if (hasSignup) {
      if (productCode) {
        switch (productCode) {
          case 'voucher':
            return <VoucherSignup voucherCode={voucherCode} />
          case 'business':
            return <BusinessSignup />
          case 'free':
            return <FreeSignup federationToken={federationToken} />
          default:
            return (
              <PaidSignup
                productCode={productCode}
                federationToken={federationToken}
                defaultSubscriptionInterval={defaultSubscriptionInterval}
              />
            )
        }
      } else {
        return <FreeSignup federationToken={federationToken} />
      }
    } else if (hasVoucherSignup) {
      return <VoucherSignup voucherCode={voucherCode} />
    } else {
      window.location.href = '/'
    }
  }

  return <LoadingOverlay open />
}

const productCodeMap: Record<string, string> = {
  personal: 'private_personal',
  unlimited: 'private_personal',
  business_small: 'business_small_v2020',
  business_medium: 'business_medium_v2020',
  business_large: 'business_large_v2020',
}

export function SignupRoute() {
  const { productCode: productCodeParam } = useParams()
  const federationToken = useAndConsumeSearchParam('federation_token')
  const voucherCode = useAndConsumeSearchParam('voucher_code')
  const lang = useAndConsumeSearchParam('lang') // propagated from cms
  const [searchParams] = useSearchParams()

  useActivateLocale({
    overrideLocale: isLocale(lang) ? lang : undefined,
  })

  const subscriptionInterval =
    searchParams.get('interval') === 'monthly'
      ? SubscriptionInterval.MONTHLY
      : SubscriptionInterval.YEARLY

  const productCode = productCodeParam
    ? productCodeMap[productCodeParam] || productCodeParam
    : undefined

  return (
    <Signup
      productCode={productCode}
      voucherCode={voucherCode}
      federationToken={federationToken}
      defaultSubscriptionInterval={subscriptionInterval}
    />
  )
}
