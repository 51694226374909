import type { Photos } from '@jotta/types/Photos'
import type { GroupedByAspectRatioRow } from '@jotta/utils/groupByAspectRatio'
import { observer } from 'mobx-react-lite'

import { groupStore } from '../../store/GroupStore'
import { MediaThumb } from './MediaThumb'
import styles from './PhotoGallery.module.scss'
import type { TimelinePhotoRow } from './useGroupByDayAndRow'

export const PhotoRow: React.FC<{
  row: TimelinePhotoRow
  showOwnerAvatar?: boolean
  showSimilarSearch?: boolean
}> = observer(function PhotoRow({
  row,
  showOwnerAvatar = false,
  showSimilarSearch = false,
}) {
  return (
    <div
      style={{
        gridTemplateColumns: row.fractions.map(f => `${f}fr`).join(' '),
        height: '100%',
      }}
      className={styles.photoRow}
    >
      {row.elements.map((asset, i) => {
        return (
          <MediaThumb
            key={asset.id}
            src={`${asset.thumbnail_url}.s`}
            comments={groupStore.getCommentCount(asset.commentsItemId)}
            ownerAvatar={asset.ownerAvatar}
            id={asset.id}
            filename={asset.filename}
            duration={asset.duration}
            playIcon={asset.content === 'video'}
            showOwnerAvatar={showOwnerAvatar}
            showSimilarSearch={showSimilarSearch}
          />
        )
      })}
    </div>
  )
})
export const GroupedPhotoRow: React.FC<{
  row: GroupedByAspectRatioRow<Photos.Media>
  showOwnerAvatar?: boolean
}> = observer(function PhotoRow({ row, showOwnerAvatar = false }) {
  return (
    <>
      {row.items.map((asset, i) => {
        return (
          <MediaThumb
            key={asset.id}
            src={`${asset.thumbnail_url}.s`}
            ownerAvatar={asset.ownerAvatar}
            id={asset.id}
            filename={asset.filename}
            duration={asset.duration}
            playIcon={asset.content === 'video'}
            showOwnerAvatar={showOwnerAvatar}
            comments={groupStore.getCommentCount(asset.commentsItemId)}
          />
        )
      })}
    </>
  )
})
