import { addBreadcrumb } from '@sentry/react'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { usePhotoStore } from '../store/PhotoContext'
import { TimelineGallery } from '../components/PhotoGallery/TimelineGallery'

const debug = Debug('jotta:PhotoTimelineRoute')
export const PhotoTimelineRoute = observer<{ hidden?: boolean }>(
  function PhotoTimelineRoute({ hidden = false }) {
    const { mediaId } = useParams()
    const store = usePhotoStore()
    useEffect(() => {
      store.galleryType = hidden ? 'TIMELINE_HIDDEN' : 'TIMELINE'
      addBreadcrumb({
        level: 'info',
        category: 'PhotoTimelineRoute',
        data: {
          section: hidden ? 'TimelineHidden' : 'Timeline',
          carousel: mediaId ? 'true' : 'false',
        },
      })
    }, [hidden, mediaId, store])

    return (
      <TimelineGallery
        data-testid="PhotoTimelineRoute"
        mediaId={mediaId}
        hidden={hidden}
      />
    )
  },
)
