import type { ProgressHandler } from '@jotta/types/IO'
import { AppError } from '@jotta/types/AppError'
import Debug from 'debug'
import { XidSchema } from '@jotta/types/schemas/ErrorSchema'
import { AbortError } from '@jotta/types/AbortError'
const debug = Debug('jotta:utils:fileUploadXhr')

export class XMLHttpError extends Error {
  status: number
  event: ProgressEvent<EventTarget>

  constructor({
    message,
    status,
    event,
  }: {
    message?: string
    status: number
    event: ProgressEvent<EventTarget>
  }) {
    super(message)
    this.status = status
    this.event = event
  }
}

export const fileUploadXhr = <T = unknown>(
  file: File,
  url: string,
  accessToken: string,
  onProgress?: ProgressHandler,
  signal?: AbortSignal,
) =>
  new Promise<T>((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    function fileUploadXhrOnLoad() {
      const { statusText: message, status } = xhr
      if (status >= 300) {
        const parsed = XidSchema.safeParse(xhr.response)
        return reject(
          new AppError({
            message,
            HTTPStatus: status,
            xid: parsed.success ? parsed.data : undefined,
          }),
        )
      }
      return resolve(xhr.response)
    }
    function fileUploadXhrOnError(event: ProgressEvent<EventTarget>) {
      const { statusText: message, status } = xhr
      return reject(new XMLHttpError({ message, status, event }))
    }

    xhr.onload = fileUploadXhrOnLoad
    xhr.onerror = fileUploadXhrOnError
    if (signal) {
      signal.addEventListener('abort', () => {
        xhr.abort()
        reject(new AbortError())
      })
    }
    xhr.overrideMimeType('application/octet-stream')
    xhr.open('POST', url)
    xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`)
    xhr.setRequestHeader('Content-Type', 'application/octet-stream')
    xhr.responseType = 'json'

    if (onProgress) {
      xhr.upload.addEventListener('progress', onProgress)
      // This is the last ProgressEvent of upload, but it happens before
      // the xhr.response is there, hence I'm removing it.
      // xhr.upload.onload = onload
      xhr.upload.onerror = fileUploadXhrOnError
      xhr.upload.ontimeout = fileUploadXhrOnError
    }

    xhr.send(file)
  })
