import type { ThemeId, brandAliasToThemeIdMap } from '@jotta/config/brand'
import { getThemeIdFromBrandAlias, type BrandAlias } from '@jotta/config/brand'
import type { PropsWithChildren } from 'react'
import { createContext, useContext } from 'react'

interface ThemeContextValue<T extends BrandAlias> {
  /**
   * Main theme (elkjop for gigantti, elko, and elgiganten - teliacloud for teliasky)
   */
  theme: (typeof brandAliasToThemeIdMap)[T]
  /**
   * Exact theme
   */
  brandAlias: T
}

export function createThemeVariants<T>(
  emptyValue: T,
  variants: Partial<Record<ThemeId, T>>,
): Record<ThemeId, T> {
  const result: Record<ThemeId, T> = {
    comhem: emptyValue,
    elkjop: emptyValue,
    halebop: emptyValue,
    jottacloud: emptyValue,
    mediamarkt: emptyValue,
    obos: emptyValue,
    onlime: emptyValue,
    saturn: emptyValue,
    teliacloud: emptyValue,
    test: emptyValue,
    tsky: emptyValue,
    ...variants,
  }
  return result
}

const defaultContextValue: ThemeContextValue<'test'> = {
  brandAlias: 'test',
  theme: 'test',
}
export const ThemeContext =
  createContext<ThemeContextValue<BrandAlias>>(defaultContextValue)
export function ThemeContextProvider({
  brandAlias,
  children,
}: PropsWithChildren<{ brandAlias: BrandAlias }>) {
  return (
    <ThemeContext.Provider
      value={{
        theme: getThemeIdFromBrandAlias(brandAlias),
        brandAlias,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

export function useThemeContext() {
  return useContext(ThemeContext)
}
