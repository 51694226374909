import { Button } from '@jotta/ui/Button'
import type { MouseEventHandler } from 'react'
import { useCallback } from 'react'
import { useEffect } from 'react'
import { useMutation } from '@connectrpc/connect-query'
import { t } from '@lingui/macro'
import { federate as federateDescriptor } from '@jotta/grpc-connect-openapi/esm/openapi/federation/v1/federation.v1-FederationService_connectquery'
import type { FederationIntent as FederationIntentProto } from '@jotta/grpc-connect-openapi/esm/openapi/federation_pb'
import { FederationType } from '@jotta/grpc-connect-openapi/esm/openapi/federation_pb'
import type { PlainMessage } from '@bufbuild/protobuf'
import type { BrandThemeIcon } from '@jotta/types/Brand'
import { getEnumKey } from '@jotta/utils/enum'
import { capitalize } from '@jotta/utils/text'

export type FederationIntent = PlainMessage<FederationIntentProto>['intent']

function FederateWith({
  intent,
  type,
  icon,
}: {
  intent: FederationIntent
  type: FederationType
  icon: BrandThemeIcon
}) {
  const { data, isPending, mutate: federate } = useMutation(federateDescriptor)
  const redirectUrl = data?.redirectUrl

  useEffect(() => {
    if (redirectUrl) {
      window.location.assign(redirectUrl)
    }
  }, [redirectUrl])

  const federateWith = useCallback<MouseEventHandler>(
    event => {
      event.preventDefault()

      federate({
        type,
        intent: {
          intent,
        },
        redirectDomain: location.hostname,
      })
    },
    [intent, type],
  )
  const federationProviderName = capitalize(
    getEnumKey(FederationType, type).toLowerCase(),
  )
  return (
    <Button
      loading={Boolean(isPending || redirectUrl)}
      className="btn btn-lg btn-outline"
      icon={icon}
      onClick={federateWith}
    >
      {t`Continue with ${federationProviderName}`}
    </Button>
  )
}

export function Federation({ intent }: { intent: FederationIntent }) {
  return (
    <>
      <FederateWith
        intent={intent}
        icon="SvgApple"
        type={FederationType.APPLE}
      />
      <FederateWith
        intent={intent}
        icon="SvgGoogle"
        type={FederationType.GOOGLE}
      />
      <FederateWith
        intent={intent}
        icon="SvgFacebook"
        type={FederationType.FACEBOOK}
      />
    </>
  )
}
