/** External */
import { Trans } from '@lingui/macro'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import type React from 'react'
import { useMemo } from 'react'

/** GRPC */
import { useQuery } from '@connectrpc/connect-query'
import { photoSearchQuerySuggestions } from '@jotta/grpc-connect-openapi/searchQuery'

/** Project */
import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { Headline } from '@jotta/uinext/Headline'
import { TextButton } from '@jotta/uinext/TextButton'

/** Internal */
import { PersonThumbButton } from '../../people/internal/components/PersonList/PersonThumb'
import { useOnLearnMoreSearch } from '../../routes/useOnLearnMore'
import type { PhotoSearchStore } from './PhotoSearchStore'
import { usePhotoSearchResult } from './usePhotoSearchResult'
import clsx from 'clsx'
import { useRecentSearches } from './useRecentSearches'
import { authTransport } from '@jotta/grpc-connect-client/transport'

const debug = Debug('jotta:photos:PhotoSearchDropdown')

/**
 * Prevents activeElement from being changed when item is clicked
 * Ensures that focus stays on the search input
 */
function onMouseDown(e: React.MouseEvent<HTMLElement, MouseEvent>) {
  e.preventDefault()
}
export const PhotoSearchDropdown = observer<{
  search: PhotoSearchStore
}>(function PhotoSearchDropdown({ search }) {
  const [{ suggestedPeople }] = usePhotoSearchResult()
  const currentSearch = search.searchParams.q
  const recentSearches = useRecentSearches(currentSearch)
  const { onLearnMoreSearch } = useOnLearnMoreSearch()
  const suggestionsQuery = useQuery(
    photoSearchQuerySuggestions,
    {},
    { transport: authTransport },
  )
  const { query } = search
  const filteredPeople = useMemo(
    () =>
      (query
        ? suggestedPeople.filter(
            p =>
              p.name &&
              p.name.toLocaleLowerCase().includes(query.toLocaleLowerCase()),
          )
        : suggestedPeople
      ).slice(0, 12),
    [query, suggestedPeople],
  )
  const { addSuggestion, addSuggestedPerson } = search
  const value = search.query
  const suggestionClassName = search.ids.suggestion

  const suggestions = suggestionsQuery.data?.suggestedQueries || []
  const filteredSuggestion = value
    ? suggestions.filter(
        v =>
          v.toLocaleLowerCase().includes(value.toLocaleLowerCase()) &&
          v.toLocaleLowerCase() !== value.toLocaleLowerCase(),
      )
    : suggestions

  const recentSearchesFiltered = recentSearches
    .filter(
      v =>
        currentSearch !== v &&
        v.toLocaleLowerCase().includes(value.toLocaleLowerCase()) &&
        v.toLocaleLowerCase() !== value.toLocaleLowerCase(),
    )
    .slice(0, 3)

  return (
    <div id={search.ids.dropdown} className="flex flex-col gap-2">
      {!!recentSearchesFiltered.length && (
        <section>
          <Headline
            tag="h2"
            level="h4"
            className="border-b px-6 pb-2 first-of-type:pt-6"
          >
            <Trans>Recent Searches</Trans>
          </Headline>
          {recentSearchesFiltered.map(recentSearch => (
            <button
              className={clsx(
                suggestionClassName,
                'grid grid-cols-max-fr1 gap-2 px-6 py-2 text-left data-active:bg-gray-100 hover:bg-gray-100',
              )}
              key={recentSearch}
              onMouseDown={onMouseDown}
              data-item
              onClick={() => addSuggestion(recentSearch)}
            >
              <PlainBrandIcon className="size-6" icon="SvgSearchAlt" />
              {recentSearch}
            </button>
          ))}
        </section>
      )}
      {!!filteredSuggestion.length && (
        <section>
          <Headline
            tag="h2"
            level="h4"
            className="border-b px-6 pb-2 first-of-type:pt-6"
          >
            <Trans>Suggestions</Trans>
          </Headline>
          {filteredSuggestion.map(suggestion => (
            <button
              className={clsx(
                suggestionClassName,
                'grid grid-cols-max-fr1 gap-2 px-6 py-2 text-left data-active:bg-gray-100 hover:bg-gray-100',
              )}
              key={suggestion}
              onMouseDown={onMouseDown}
              data-item
              onClick={() => addSuggestion(suggestion)}
            >
              <PlainBrandIcon className="size-6" icon="SvgSearchAlt" />
              {suggestion}
            </button>
          ))}
        </section>
      )}
      {!value && (
        <TextButton
          icon={<PlainBrandIcon icon="SvgHelp" />}
          data-item
          className="min-h-11 border-t border-t-gray-300 px-6"
          onMouseDown={onMouseDown}
          onClick={onLearnMoreSearch}
        >
          <Trans>Learn more</Trans>
        </TextButton>
      )}
      {!!filteredPeople.length && (
        <>
          <Headline tag="h2" level="h4" className="border-b p-6 pb-2">
            <Trans>People</Trans>
          </Headline>
          <div className="grid grid-cols-6 px-4 py-2 lg:grid-cols-12">
            {filteredPeople.map(person => (
              <PersonThumbButton
                className={clsx(suggestionClassName, 'data-active:bg-gray-200')}
                key={person.id}
                data-item
                person={person}
                onMouseDown={onMouseDown}
                onClick={() => addSuggestedPerson(person.id)}
                type="button"
              />
            ))}
          </div>
        </>
      )}
    </div>
  )
})
