/**
 * Return number bounded by min and max
 * @param min Number to return if value is too small
 * @param value Returns if between min and max
 * @param max Number to return if value is too big
 * @returns Value if bounded by min and max
 */
export function clamp(min: number, value: number, max: number) {
  return Math.max(min, Math.min(value, max))
}
export function safeArrayIndex<T>(
  index: number,
  array: T[] | readonly T[],
  returnSafeIndexIfOutOfBounds = false,
) {
  if (!array.length) {
    return null
  }
  const safeIndex = clamp(0, index, array.length - 1)
  if (safeIndex === index) {
    return index
  }
  if (returnSafeIndexIfOutOfBounds) {
    return safeIndex
  }
  return null
}
