import { createConnectQueryKey, useQuery } from '@connectrpc/connect-query'
import { useAuthenticated } from '@jotta/auth-client/useAuthStatus'
import { getCustomer } from '@jotta/grpc-connect-openapi/esm/openapi/customer/customer.v2-CustomerService_connectquery'
import { authTransport } from '../transport'

export function useGetCustomer() {
  const authenticated = useAuthenticated()

  return useQuery(
    getCustomer,
    {},
    {
      refetchOnMount: false,
      enabled: authenticated === 'TRUE',
      transport: authTransport,
      throwOnError: false,
    },
  )
}

export const getCustomerQueryKey = createConnectQueryKey(getCustomer)
