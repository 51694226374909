import { Button } from '@jotta/ui/Button'
import { useBrandTheme } from '@jotta/ui/useBrandTheme'
import { LanguageSelector } from '@jotta/ui/LanguageSelector'
import { redirectToLogin, redirectToSignup } from '@jotta/utils/auth'
import { Trans } from '@lingui/macro'
import { observer } from 'mobx-react-lite'
import { useBrandingInfo, useFeatures } from '@jotta/grpc-connect-client/config'

export const PublicHeader = observer(function PublicHeader() {
  const features = useFeatures()
  const { signupFreeLink } = useBrandingInfo()
  const { theme } = useBrandTheme()
  const showSignupButtons =
    features.signup && theme.branding.header.showSignupButtons
  const showLanguageSelector = theme.branding.showLanguageSelector

  return (
    <div className="ml-auto grid h-full auto-cols-min grid-flow-col justify-items-end gap-2 pr-3 sm:gap-4">
      {showSignupButtons && (
        <>
          <Button
            variant="buttons.primary"
            sx={{ px: [3, 4], display: 'block', minWidth: 'unset' }}
            onClick={() =>
              signupFreeLink
                ? window.location.assign(signupFreeLink)
                : redirectToSignup()
            }
          >
            <Trans>Sign up</Trans>
          </Button>
          <Button
            variant="buttons.secondary"
            sx={{ px: [2, 4], display: 'block', minWidth: 'unset' }}
            onClick={() => redirectToLogin()}
          >
            <Trans>Log in</Trans>
          </Button>
        </>
      )}
      {showLanguageSelector && <LanguageSelector />}
    </div>
  )
})
