import { z } from 'zod'
const RequestSchema = z.custom<Request>(
  data =>
    globalThis && globalThis.Request && data instanceof globalThis.Request,
)
const ResponseSchema = z.custom<Response>(
  data =>
    globalThis && globalThis.Response && data instanceof globalThis.Response,
)
export const HTTPErrorSchema = z.object({
  request: RequestSchema,
  response: ResponseSchema,
  message: z.string().optional(),
  stack: z.string().optional(),
  xid: z.string().optional(),
})
