import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import { getBrandZIndex } from '@jotta/ui/zIndex'
import { ProgressThemeUI } from '@jotta/ui/themeui'
import { redirectToLogout } from '@jotta/utils/auth'
import { avatarFromProtobuf } from '@jotta/utils/avatar'
import { filesize } from '@jotta/utils/filesize'
import { Trans } from '@lingui/macro'
import { AnimatePresence, motion } from 'framer-motion'
import { observer } from 'mobx-react-lite'
import { useRef, useState } from 'react'
import { Headline4 } from '../../Typography/Headline/Headline'
import { Avatar } from '../Avatar/Avatar'

import type { User } from '@jotta/grpc-web/no/jotta/openapi/sharing/v2/sharing.v2_pb'
import type { GetPaymentInfoResponse } from '@jotta/grpc-web/no/jotta/openapi/subscription/payment.v2_pb'
import type { DropdownMenuProps } from '@radix-ui/react-dropdown-menu'
import * as Dropdown from '@radix-ui/react-dropdown-menu'
import { useNavigate } from 'react-router-dom'
import styles from './UserMenu.module.scss'
export type UserMenuProps = DropdownMenuProps &
  Pick<
    User.AsObject & GetPaymentInfoResponse.AsObject,
    'name' | 'email' | 'avatar' | 'customerUsageBytes' | 'customerQuotaBytes'
  > & {
    enableUpgradeButton: boolean
    downloadPageLink?: string
    userCanUpgrade?: boolean
    className?: string
  }
export const UserMenu = observer<UserMenuProps>(function UserMenu({
  name,
  email,
  avatar,
  defaultOpen = false,
  customerQuotaBytes,
  customerUsageBytes,
  enableUpgradeButton,
  downloadPageLink,
  userCanUpgrade,
  ...props
}) {
  const [open, setOpen] = useState(defaultOpen)
  const usage = `${filesize(customerUsageBytes)} ${
    customerQuotaBytes < 0 ? '' : ` / ${filesize(customerQuotaBytes)}`
  }`
  const navigate = useNavigate()
  const clickGuard = useRef(false)

  return (
    <Dropdown.Root open={open} onOpenChange={setOpen}>
      <Dropdown.Trigger
        sx={{
          position: 'relative',
          height: '100%',
          px: 2,
          zIndex: getBrandZIndex('appHeaderAvatar'),
          borderRadius: 2,
          '&:focus-visible': {
            boxShadow: 'inset 0 0 0 2px var(--color-focus)',
          },
        }}
      >
        <Avatar
          avatar={avatarFromProtobuf(avatar)}
          data-testid="UserMenuAvatar"
        />
      </Dropdown.Trigger>
      <AnimatePresence>
        {open && (
          <Dropdown.Portal forceMount>
            <Dropdown.Content
              side="top"
              sideOffset={-60}
              className={styles.dropdownContent}
              style={{
                zIndex: getBrandZIndex('appHeaderAvatar') - 1,
              }}
            >
              <button
                className={styles.avatarClone}
                onMouseDown={() => {
                  clickGuard.current = true
                }}
                onClick={e => {
                  if (clickGuard.current) {
                    setOpen(false)
                    clickGuard.current = false
                  }
                }}
              >
                <Avatar avatar={avatarFromProtobuf(avatar)} />
              </button>
              <motion.div
                initial={{ opacity: 0, y: -16 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -16 }}
                transition={{ duration: 0.2 }}
                className={styles.menu}
              >
                <Dropdown.Label className={styles.userinfo}>
                  <Headline4 mb="0">{name}</Headline4>
                  <p>{email}</p>
                </Dropdown.Label>
                <Dropdown.Label className={styles.usage}>
                  <p>
                    <Trans>Usage</Trans>: {usage}
                  </p>
                  <ProgressThemeUI
                    value={customerUsageBytes}
                    max={customerQuotaBytes}
                  />
                </Dropdown.Label>
                <Dropdown.Item
                  onSelect={() => navigate('/web/account')}
                  data-testid="UserMenuSettings"
                >
                  <PlainBrandIcon icon="SvgSettings" />
                  <Trans>Settings</Trans>
                </Dropdown.Item>

                {enableUpgradeButton && userCanUpgrade && (
                  <Dropdown.Item
                    onSelect={() => (location.href = '/web/upgrade')}
                    data-testid="UserMenuUpgrade"
                  >
                    <PlainBrandIcon icon="SvgStarOutline" />
                    <Trans>Upgrade</Trans>
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  onSelect={() =>
                    (location.href = downloadPageLink || '/download')
                  }
                  data-testid="UserMenuDownload"
                >
                  <PlainBrandIcon icon="SvgInstall" />
                  <Trans>Install</Trans>
                </Dropdown.Item>

                <Dropdown.Item
                  onSelect={() =>
                    redirectToLogout(`${window.location.origin}/web/sync`)
                  }
                  data-testid="UserMenuLogout"
                >
                  <PlainBrandIcon icon="SvgInstall" />
                  <Trans>Log out</Trans>
                </Dropdown.Item>
              </motion.div>
            </Dropdown.Content>
          </Dropdown.Portal>
        )}
      </AnimatePresence>
    </Dropdown.Root>
  )
})
