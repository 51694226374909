import { useThrottle } from '@jotta/hooks'
import type { Photos } from '@jotta/types/Photos'
import { bisectLeft } from '@jotta/utils/bisect'
import type { UseInfiniteQueryResult } from '@tanstack/react-query'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import throttle from 'lodash/throttle'
import type { RefObject } from 'react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { fetchTimeline } from '../../../api/photosApi'
import { HideScrollbar } from '../HideScrollbar'
import { PhotoRow } from '../PhotoRow'
import type { TimelineHeader } from '../useGroupByDayAndRow'
import { isTimelineHeader, useGroupByDayAndRow } from '../useGroupByDayAndRow'
import { ScrubberV2, fromEpoch, toEpoch } from './ScrubberV2'
import { LoadingOverlaySpinner } from '@jotta/ui/LoadingOverlay'
import { keepPreviousData } from '@tanstack/react-query'

const LIMIT_INITIAL_FETCH = 100
const LIMIT = 500
const FETCH_MORE_OFFSET = 2000 // Pixels

function useInfiniteTimeline(
  timestamp?: string,
  order: 'asc' | 'desc' = 'desc',
  enabled = true,
) {
  const fetchTimelinePage = ({ pageParam }: { pageParam?: string }) => {
    return fetchTimeline({
      from: pageParam,
      to: order === 'asc' ? toEpoch() : undefined,
      params: {
        limit: pageParam === timestamp ? LIMIT_INITIAL_FETCH : LIMIT,
        order,
      },
    }).then(res => res.result)
  }

  return useInfiniteQuery({
    queryKey: ['infinite_timeline', order, timestamp],
    queryFn: fetchTimelinePage,
    enabled,
    placeholderData: keepPreviousData,
    initialPageParam: timestamp,
    getNextPageParam: (lastPage, pages) =>
      lastPage.length ? lastPage[lastPage.length - 1].timestamp : undefined,
  })
}

function usePhotoAt(
  timestamp?: string | undefined,
  order: 'asc' | 'desc' = 'desc',
  enabled = true,
) {
  const from = timestamp
  let to = order === 'asc' ? toEpoch() : undefined
  if (to && from && to < from) {
    to = toEpoch(fromEpoch(from).startOf('day').add(1, 'day'))
  }
  return useQuery({
    queryKey: ['timeline', 'photo_at', order, timestamp],
    queryFn: () =>
      fetchTimeline({
        from,
        to,
        params: { limit: 1, order },
      }).then(res => (res.result.length ? res.result[0] : null)),
    refetchOnMount: false,
    enabled,
  })
}

function useFirstPhoto() {
  return usePhotoAt().data
}

function useLastPhoto() {
  return usePhotoAt(undefined, 'asc').data
}

function useFetchMore(
  query: UseInfiniteQueryResult,
  cmp: (offsetTop: number, offsetBottom: number) => number,
) {
  const { hasNextPage, isFetchingNextPage, fetchNextPage, isFetched } = query

  useEffect(() => {
    if (!hasNextPage || isFetchingNextPage || !isFetched) {
      return
    }

    const scrollHandler = throttle(() => {
      const scrollEl = document.scrollingElement

      if (
        scrollEl &&
        cmp(
          scrollEl.scrollTop,
          scrollEl.scrollHeight - scrollEl.clientHeight - scrollEl.scrollTop,
        ) >= 0
      ) {
        fetchNextPage()
      }
    }, 100)

    scrollHandler()
    scrollHandler.flush()
    document.addEventListener('scroll', scrollHandler)

    return () => {
      document.removeEventListener('scroll', scrollHandler)
      scrollHandler.cancel()
    }
  }, [hasNextPage, isFetchingNextPage, isFetched, cmp, fetchNextPage])
}

function usePreserveScroll(deps: unknown[]) {
  const scrollTop = useRef(0)
  const height = useRef(0)
  const el = document.scrollingElement

  if (el) {
    scrollTop.current = el.scrollTop
    height.current = el.scrollHeight + el.clientHeight
  }

  useEffect(() => {
    const el = document.scrollingElement

    if (!el) {
      return
    }

    const newHeight = el.scrollHeight + el.clientHeight
    if (newHeight > height.current) {
      const newScrollTop = newHeight - height.current + scrollTop.current
      el.scrollTop = newScrollTop
    }
  }, [...deps])
}

function useGetCurrentHeaderDate(
  container: RefObject<HTMLElement>,
  deps: unknown[],
) {
  const table = useRef<{ date: number; offset: number }[]>([])

  // Compute table
  useEffect(() => {
    if (!container.current) {
      return
    }

    table.current = []

    for (const el of container.current.querySelectorAll('h2')) {
      el.style.position = 'relative'

      table.current.push({
        date: Number(el.dataset.date),
        offset: el.offsetTop,
      })

      el.style.position = ''
    }
  }, [...deps])

  const tableLookup = useCallback(() => {
    const current = document.scrollingElement?.scrollTop || 0

    if (!table.current.length) {
      return null
    }

    const padding = 200

    let i =
      bisectLeft(table.current, ({ offset }) => offset - padding - current) - 1

    i = Math.min(Math.max(0, i), table.current.length - 1)

    return table.current[i]
  }, [])

  return tableLookup
}

function useIndicatorDate(
  container: RefObject<HTMLElement>,
  timestamp: string | undefined,
  // initialDate: string | undefined,
  enabled: boolean,
  deps: unknown[] = [],
) {
  const indicatorRef = useRef<{
    ts: string | undefined
    target: number
  } | null>(null)
  const [indicator, setIndicator] = useState<string | undefined>()
  const getCurrentHeaderDate = useGetCurrentHeaderDate(container, deps)

  useEffect(() => {
    if (!enabled) {
      indicatorRef.current = null
      return
    }

    const target = getCurrentHeaderDate()

    if (!target) {
      return
    }

    indicatorRef.current = {
      ts: timestamp,
      target: target.date,
    }
  }, [timestamp, enabled, getCurrentHeaderDate])

  useEffect(() => {
    const updateIndicator = throttle(() => {
      if (!indicatorRef.current) {
        return
      }

      const target = getCurrentHeaderDate()

      if (!target) {
        return
      }

      if (target.date !== indicatorRef.current.target) {
        setIndicator(toEpoch(dayjs(target.date)))
        indicatorRef.current.target = target.date
      }
    }, 100)

    document.addEventListener('scroll', updateIndicator)

    return () => {
      updateIndicator.cancel()
      document.removeEventListener('scroll', updateIndicator)
    }
  }, [getCurrentHeaderDate])

  return indicator
}

function TimelineH2({ row }: { row: TimelineHeader }) {
  return (
    <h2
      data-date={row.day}
      className="sticky top-[calc(var(--content-top)-36px)] z-[1] flex w-full items-end gap-4 bg-white pt-10 text-2xl font-medium"
      style={{
        backdropFilter: 'blur(3px)',
      }}
    >
      <span>{dayjs.utc(row.day).format('LL')}</span>
    </h2>
  )
}

function LoadingSection({ show = true }: { show?: boolean }) {
  if (!show) {
    return null
  }
  return (
    <div className="flex h-[calc(var(--content-height)/2)] items-center justify-center">
      <LoadingOverlaySpinner />
    </div>
  )
}

export function TimelineV2({
  onPhotos = () => {},
}: {
  onPhotos?: (photos: Photos.Media[]) => void
}) {
  const [timestamp, setTimestamp] = useState<string | undefined>()
  const olderQuery = useInfiniteTimeline(timestamp, 'desc')
  const newerQuery = useInfiniteTimeline(
    timestamp,
    'asc',
    Boolean(timestamp) && Boolean(olderQuery.isFetched),
  )

  useFetchMore(
    newerQuery,
    useCallback(top => FETCH_MORE_OFFSET - top, []),
  )
  useFetchMore(
    olderQuery,
    useCallback((top, bottom) => FETCH_MORE_OFFSET - bottom, []),
  )

  const first = useFirstPhoto()
  const last = useLastPhoto()

  const newerPhotos = useMemo(
    () => newerQuery.data?.pages.flat().reverse() || [],
    [newerQuery.data],
  )
  const olderPhotos = useMemo(
    () => olderQuery.data?.pages.flat() || [],
    [olderQuery.data],
  )
  const photos = useMemo(
    () => [...newerPhotos, ...olderPhotos],
    [newerPhotos, olderPhotos],
  )

  useEffect(() => {
    onPhotos(photos)
  }, [photos, onPhotos])

  usePreserveScroll([newerPhotos])

  const container = useRef<HTMLDivElement>(null)
  const timelineEl = useRef<HTMLDivElement>(null)
  const [rowAspect, setRowAspect] = useState<[number, number]>([1, 1])
  const [preserveAspect, setPreserveAspect] = useState(true)

  const handleResize = useThrottle(
    useCallback((entries: ResizeObserverEntry[]) => {
      if (!entries.length) {
        return
      }

      const width = entries[0].contentRect.width

      if (width >= 600) {
        setRowAspect([width, 240])
        setPreserveAspect(true)
      } else {
        setPreserveAspect(false)
      }
      // if (width >= 1000) {
      //   setRowAspect('9/1')
      //   setPreserveAspect(true)
      // } else if (width < 1000 && width >= 600) {
      //   setRowAspect('7/1')
      //   setPreserveAspect(true)
      // } else if (width < 600 && width > 475) {
      //   setRowAspect('5/1')
      //   setPreserveAspect(false)
      // } else {
      //   setRowAspect('3/1')
      //   setPreserveAspect(false)
      // }
    }, []),
    500,
  )

  useEffect(() => {
    const el = timelineEl.current

    if (!el) {
      return
    }

    const observer = new ResizeObserver(handleResize)

    observer.observe(el)

    return () => {
      observer.unobserve(el)
    }
  }, [handleResize])

  const newerRows = useGroupByDayAndRow(rowAspect, newerPhotos, preserveAspect)
  const olderRows = useGroupByDayAndRow(rowAspect, olderPhotos, preserveAspect)

  const scrollRef = useRef<HTMLDivElement>(null)

  const { isFetched: newerIsFetched, hasNextPage: hasMoreNewer } = newerQuery
  const { isFetched: olderIsFetched, hasNextPage: hasMoreOlder } = olderQuery

  const scrollSet = useCallback(() => {
    const scrollEl = document.scrollingElement

    if (!scrollEl) {
      return
    }

    if (!scrollRef.current) {
      scrollEl.scrollTop = 0
      return
    }

    scrollEl.scrollTop = scrollRef.current.offsetTop
  }, [])

  const indicator = useIndicatorDate(
    container,
    timestamp,
    // initialDate,
    Boolean(olderIsFetched && (timestamp || newerIsFetched)),
    [photos],
  )

  const handleSelect = useCallback(
    (ts?: string) => {
      setTimestamp(old => {
        if (old === ts) {
          scrollSet()
        }
        return ts
      })
    },
    [scrollSet],
  )

  return (
    <>
      <div className="flex flex-nowrap" ref={container}>
        <HideScrollbar />

        <div
          ref={timelineEl}
          className="h-full flex-auto select-none pl-4"
          style={{ scrollbarWidth: 'none' }}
          id="timeline"
        >
          <div className="relative pb-40">
            <LoadingSection
              show={olderIsFetched && newerIsFetched && hasMoreNewer}
            />
            {newerRows.map((row, i) =>
              isTimelineHeader(row) ? (
                <TimelineH2 key={row.key} row={row} />
              ) : (
                <div key={row.key} className="relative h-[240px] w-full">
                  <PhotoRow row={row} />
                </div>
              ),
            )}

            <div ref={scrollRef} className="h-0 w-full" />

            {olderRows.map((row, i) =>
              isTimelineHeader(row) ? (
                <TimelineH2 key={row.key} row={row} />
              ) : (
                <div key={row.key} className="relative h-[240px] w-full">
                  <PhotoRow row={row} />
                </div>
              ),
            )}
            <LoadingSection show={olderIsFetched && hasMoreOlder} />
          </div>
        </div>
        <ScrubberV2
          first={first?.timestamp}
          last={last?.timestamp}
          indicator={indicator}
          scrollingElement={document}
          onSelect={handleSelect}
          autohide
        />
      </div>
    </>
  )
}
