import { CreateUser } from './CreateUser'
import { SignupContainer } from './SignupContainer'
import { useActivationCodeTerminology } from './signupForm'
import { t } from '@lingui/macro'

export function VoucherSignup({
  voucherCode,
  customerGroupOverride,
}: {
  voucherCode?: string
  customerGroupOverride?: string
}) {
  const activationCodeTerminology = useActivationCodeTerminology()
  return (
    <SignupContainer
      title={
        activationCodeTerminology
          ? t`Use the activation code to register a new account`
          : t`Use the voucher code to register a new account`
      }
    >
      <CreateUser
        type="voucher"
        customerGroupOverride={customerGroupOverride}
        prefill={{
          voucherCode,
        }}
      />
    </SignupContainer>
  )
}
