import { useFileDownloadLinkContent } from '@jotta/file-utils'
import type { PathItemObject } from '@jotta/types/Files'
import type { CodeViewLanguage } from '@jotta/ui/CodeView'
import { CodeView } from '@jotta/ui/CodeView'

export interface FileCodeViewProps {
  file: PathItemObject
  language: CodeViewLanguage
  token?: string
}

export function FileCodeView({ file, language, token }: FileCodeViewProps) {
  const { data = '' } = useFileDownloadLinkContent({
    variables: {
      path: file.path,
      includeDeleted: Boolean(file.deletedAtMillis),
      token: token || file.token?.accessToken,
    },
  })
  return <CodeView language={language}>{data}</CodeView>
}
