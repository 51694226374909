import { useBrandStore } from '@jotta/ui/useBrandTheme'
import type { CSSProperties } from 'react'
import { getResponsiveValue } from '@jotta/ui/getResponsiveValue'
import Debug from 'debug'
import { observable, runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { useEffect } from 'react'

import moize from 'moize'
import { list } from 'radash'
import styles from './VirtualGrid.module.scss'
const debug = Debug('jotta:virtualgrid')
export type VirtualGridOptions = {
  /** Number of items in list */
  size: number
  /**
   * A function to render and item from index
   * Index can be higher than the total size of the list
   * if the size does not divide evenly with the calculated
   * number of columns. This lets you render something
   * for the last empty cells if needed
   */
  renderItem: (index: number) => JSX.Element
  /**
   * Number of rows to render outside viewport
   * @default {undefined}
   */
  overscanRows?: number | null
  /**
   * Minimum number of grid columns at each breakpoint
   * @default {[2]}
   */
  minColumns?: number[]
  /**
   * Approximate column width at each breakpoint
   * It chooses the closest width you can get by dividing
   * the screen width by an even number of columns
   */
  columnWidth?: number[]
  /**
   * Aspect ratio for the thumb at each breakpoint
   * Row height is calculated from this unless a fixed size
   * is set
   */
  thumbAspect?: number[]
  /**
   * Fix row height at each breakpoint
   * Setting this value overrides thumb aspect ratio and the
   * calculated row height based on thumb aspect ratio
   */
  fixedRowHeight?: number[]
}
type VirtualGridConfig = Required<VirtualGridOptions>
const getVirtualGridOptions = moize.deep(
  (options: VirtualGridOptions): VirtualGridConfig => {
    return {
      overscanRows: null,
      minColumns: [2],
      thumbAspect: [1],
      columnWidth: [130, 200],
      fixedRowHeight: [],
      ...options,
    }
  },
)
const getVirtualGridItem = moize(
  (i: number, columns: number) => {
    const col = i % columns
    const row = i > 0 ? Math.floor(i / columns) : 0
    return {
      key: [i, col, row].join('-'),
      index: i,
      columns,
      col,
      row,
    }
  },
  {
    maxSize: 100000,
  },
)
const getVirtualGridItems = moize(
  (size: number, columns: number) =>
    list(0, size - 1, i => getVirtualGridItem(i, columns)),
  {
    maxSize: 1000,
  },
)

export const VirtualGrid = observer<{
  options: VirtualGridOptions
}>(function VirtualGrid({ options }) {
  const branding = useBrandStore()
  const config = getVirtualGridOptions(options)
  const store = useLocalObservable(
    () => ({
      config: config,
      get thumbAspect() {
        return getResponsiveValue(
          branding.currentBreakpointIndex,
          this.config.thumbAspect,
        )
      },
      get columnWidth() {
        return getResponsiveValue(
          branding.currentBreakpointIndex,
          this.config.columnWidth,
        )
      },
      get minColumns() {
        return getResponsiveValue(
          branding.currentBreakpointIndex,
          this.config.minColumns,
        )
      },
      get fixedRowHeight() {
        if (!this.config.fixedRowHeight.length) {
          return 0
        }
        return getResponsiveValue(
          branding.currentBreakpointIndex,
          this.config.fixedRowHeight,
        )
      },
      get columns() {
        return Math.max(
          this.minColumns,
          Math.round(branding.contentWidthRounded / this.columnWidth),
        )
      },
      get rowAspect() {
        return this.thumbAspect / this.columns
      },
      get rowHeight() {
        if (this.config.fixedRowHeight.length) {
          return this.fixedRowHeight
        }
        return Math.round(branding.contentWidthSafe * this.rowAspect)
      },
      get rows() {
        return this.config.size ? Math.ceil(this.config.size / this.columns) : 0
      },
      get viewportRows() {
        return Math.round(branding.viewportHeight / this.rowHeight)
      },
      get overscanRows() {
        return this.config.overscanRows || Math.round(this.viewportRows / 2)
      },
      get overscan() {
        return this.overscanRows * this.columns
      },
      get numberOfVisibleRows() {
        return this.viewportRows + this.overscanRows * 2
      },
      get firstIndex() {
        return branding.scrollTop
          ? Math.max(
              0,
              Math.floor((branding.scrollTop / this.rowHeight) * this.columns) -
                this.overscan,
            )
          : 0
      },
      get lastIndex() {
        return Math.min(
          this.config.size,
          this.firstIndex + this.numberOfVisibleRows * this.columns,
        )
      },

      get totalHeight() {
        return this.rows * this.rowHeight
      },
      get toJSON() {
        const {
          firstIndex,
          lastIndex,
          rowHeight,
          columns,
          numberOfVisibleRows,
        } = this
        return {
          firstIndex,
          lastIndex,
          rowHeight,
          columns,
          numberOfVisibleRows,
          numberOfVisibleItems: this.lastIndex - this.firstIndex,
        }
      },
    }),
    {
      config: observable.ref,
    },
  )
  useEffect(() => {
    runInAction(() => {
      if (store.config !== config) {
        store.config = config
      }
    })
  }, [store, config])

  return (
    <div
      className={styles.list}
      data-testid="AlbumPhotos"
      style={
        {
          '--columns': store.columns,
          '--rows': store.rows,
          '--row-height': `${store.rowHeight}px`,
        } as CSSProperties
      }
    >
      {getVirtualGridItems(config.size, store.columns)
        .slice(store.firstIndex, store.lastIndex)
        .map(p => {
          return (
            <div
              key={p.key}
              className={styles.row}
              data-col={p.col}
              data-row={p.row}
              style={{
                transform: `translate(${p.col * 100}%, ${p.row * 100}%)`,
              }}
            >
              {config.renderItem(p.index)}
            </div>
          )
        })}
    </div>
  )
})
