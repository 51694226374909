import { useEffect, useMemo, useRef } from 'react'
import throttle from 'lodash/throttle'

export function useResizeObserver(
  element: Element | null,
  cb: ResizeObserverCallback,
  wait = 100,
) {
  const cbRef = useRef(cb)

  useEffect(() => {
    cbRef.current = cb
  }, [cb])

  const observer = useMemo(() => {
    return new ResizeObserver(
      throttle((entries, observer) => {
        cbRef.current(entries, observer)
      }, wait),
    )
  }, [wait])

  useEffect(() => {
    if (!element) {
      return
    }

    observer.observe(element)

    return () => {
      observer.disconnect()
    }
  }, [observer, element])
}
