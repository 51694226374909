/* eslint-disable react/jsx-key */
import { Button } from '@jotta/ui/Button'
import { Stack } from '@jotta/ui/Stack'
import { TextThemeUI } from '@jotta/ui/themeui'
import {
  Currency,
  SubscriptionInterval,
  getBillingHistory,
} from '@jotta/grpc-js-client/subscriptionService'
import { Order } from '@jotta/grpc-web/no/jotta/openapi/subscription/payment.v2_pb'
import { Card } from '@jotta/ui/Card'
import { getOrRefreshAccessToken } from '@jotta/auth-client/useAuthStatus'
import { downloadBlob } from '@jotta/utils/download'
import { Trans, t } from '@lingui/macro'
import dayjs from 'dayjs'
import Debug from 'debug'
// import * as Sentry from '@sentry/react'

import { useEffect, useState } from 'react'
import { formatCurrency } from '../../utils/formatPrice'
import { ResponsiveTable } from '../ResponsiveTable/ResponsiveTable'
import { useLocale } from '@jotta/i18n'
const debug = Debug('jotta:settings:BillingHistory')

export const generateMockData = (size = 3) => {
  const data: Array<OrderItem> = []
  for (let i = 0; i < size; i++) {
    data.push({
      orderNumber: `${i}`,
      order_date_millis: new Date().getMilliseconds(),
      localized_description: 'Example Order',
      price_cents: 100000,
      currency: Currency.NOK,
      receipt_link: 'https://pdfapi.jotta.cloud/pdfapi/order/receipt/11133346',
    })
  }
  return data
}

export interface OrderItem {
  orderNumber: string
  order_date_millis: number
  localized_description: string
  price_cents: number
  currency: Currency
  receipt_link: string
}

async function downloadReceipt(orderNumber: string, url: string) {
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${await getOrRefreshAccessToken()}`,
    },
  })
    .then(res => {
      if (res.status === 200) {
        return res.arrayBuffer()
      } else {
        throw new Error(
          `error downloading url=${url}: ${res.status} ${res.statusText}`,
        )
      }
    })
    .then(blob => {
      return downloadBlob(new Blob([blob]), `${orderNumber}.pdf`)
    })
}

export const BillingHistory: React.FC<{ receipts: Array<OrderItem> }> = ({
  receipts = [],
}) => {
  const maxReceiptsShown = 9
  const locale = useLocale()
  const [showAll, setShowAll] = useState(false)
  const tooMany = receipts.length > maxReceiptsShown

  const sublist =
    tooMany && !showAll ? receipts.slice(0, maxReceiptsShown) : receipts

  const data = sublist.map(order => [
    dayjs(order.order_date_millis).format('L'),
    <TextThemeUI>{order.localized_description}</TextThemeUI>,
    <TextThemeUI>
      {formatCurrency(order.price_cents, order.currency, locale)}
    </TextThemeUI>,
    <Button
      variant="buttons.link"
      onClick={() => {
        downloadReceipt(order.orderNumber, order.receipt_link).catch(err => {
          // Sentry.captureException(err)
          debug('Error', err)
        })
      }}
    >
      <Trans>Download</Trans>
    </Button>,
  ])

  return (
    <Card variant="cards.compact" title={t`Billing history`}>
      <Stack gap={4}>
        <ResponsiveTable
          columns={[t`Date`, t`Order details`, t`Amount`, t`Receipt`]}
          widths={['140px', 'auto', '130px', '100px']}
          rowData={data}
        />
        {tooMany && !showAll && (
          <Button variant="buttons.link">
            <TextThemeUI onClick={() => setShowAll(true)}>
              <Trans>Button.showMore</Trans>
            </TextThemeUI>
          </Button>
        )}
      </Stack>
    </Card>
  )
}

function localizeDescription(order: Order): string {
  switch (order.getInterval()) {
    case SubscriptionInterval.MONTHLY:
      switch (order.getType()) {
        case Order.OrderType.RENEWAL:
          return t({ id: 'Monthly renewal' })
        case Order.OrderType.BUY:
          return t({ id: 'New monthly subscription' })
        default:
          return ''
      }
    case SubscriptionInterval.YEARLY:
      switch (order.getType()) {
        case Order.OrderType.RENEWAL:
          return t({ id: 'Yearly renewal' })
        case Order.OrderType.BUY:
          return t({ id: 'New yearly subscription' })
        default:
          return ''
      }
  }
  return ''
}

export const LoadingBillingHistory: React.FC = () => {
  const [receipts, setReceipts] = useState<Array<OrderItem>>([])

  useEffect(() => {
    let isCancelled = false
    getBillingHistory().then(res => {
      if (isCancelled) {
        return
      }
      const orders: Array<OrderItem> = (res.getOrdersList() || []).map(
        order => ({
          orderNumber: order.getOrderNumber(),
          localized_description: localizeDescription(order),
          price_cents: order.getPriceCents(),
          currency: order.getCurrency(),
          order_date_millis: order.getTimestampMillis(),
          receipt_link: order.getReceiptLink(),
        }),
      )
      setReceipts(orders)
    })
    return function LoadingBillingHistoryCancel() {
      isCancelled = true
    }
  }, [])

  return receipts.length ? <BillingHistory receipts={receipts} /> : null
}
