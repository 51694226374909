// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BrandLogo_logo__Q8AvL{align-items:center;display:flex}.BrandLogo_logo__Q8AvL svg{height:100%;width:100%}`, "",{"version":3,"sources":["webpack://./../../libraries/ui/src/Icons/BrandLogo/BrandLogo.module.scss"],"names":[],"mappings":"AACA,uBAEE,kBAAA,CADA,YACA,CACA,2BAEE,WAAA,CADA,UACA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": `BrandLogo_logo__Q8AvL`
};
export default ___CSS_LOADER_EXPORT___;
