/** External */
import { Trans, plural, t } from '@lingui/macro'
import type { RouteObject } from 'react-router-dom'
import { Link } from 'react-router-dom'

/** Project */
import { createLoaderRoute } from '@jotta/router'
import { ErrorElement } from '@jotta/ui/ErrorElement'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
/** Internal */
import Debug from 'debug'
import { AlbumsSectionHeader } from '../../../components/Album/AlbumsSection/AlbumsSectionHeader'
import { fetchInitialPeople, usePeople } from '../api/PeopleApi'
import {
  PeopleBreadCrumb,
  PeopleBreadCrumbHandle,
} from '../components/PeopleBreadCrumb/PeopleBreadCrumb'
import { PeopleLayout } from '../components/PeopleLayout/PeopleLayout'
import { PersonList } from '../components/PersonList/PersonList'
import { StickyToolbar } from '../components/StickyToolbar/StickyToolbar'
import styles from './PeopleRoute.module.scss'
import { personRoute } from './PersonRoute'
const debug = Debug('jotta:people:PeopleRoute')

export const { route: peopleRoute, usePeopleLoaderData } = createLoaderRoute({
  id: 'people',
  path: 'people',
  errorElement: (
    <ErrorElement captureContext={scope => scope.setTag('key', 'people')} />
  ),
  element: <PeopleLayout />,
  children: [
    {
      index: true,
      element: <PeopleRouteView />,
    },
    personRoute,
  ],
  loader: async () => {
    const res = await fetchInitialPeople()
    if (res.persons.length) {
      return {
        people: res,
      }
    }
    throw new Response('', {
      status: 404,
      statusText: 'Not Found',
    })
  },
  handle: {
    crumb: new PeopleBreadCrumbHandle(
      () => (
        <Link to="/photo">
          <Trans>photostream.allPhotos</Trans>
        </Link>
      ),
      () => (
        <Link to="/photo/album">
          <Trans>photostream.albums</Trans>
        </Link>
      ),
      () => (
        <Link to="/photo/album/people">
          <Trans>People</Trans>
        </Link>
      ),
    ),
  },
} satisfies RouteObject)

function PeopleRouteView() {
  const [{ people, count }, query] = usePeople()
  const countLabel = plural(count, {
    one: '# person',
    other: '# people',
  })
  return (
    <>
      <StickyToolbar>
        <PeopleBreadCrumb />
      </StickyToolbar>
      <div className={styles.container}>
        <AlbumsSectionHeader title={t`People`} countLabel={countLabel} />
      </div>
      <PersonList people={people}>
        <LoadingOverlay open={query.isLoading} position="absolute" />
      </PersonList>
    </>
  )
}
