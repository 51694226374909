import { useBrandStore } from '@jotta/ui/useBrandTheme'
import { AppLayout } from '@jotta/ui/AppLayout'
import { ErrorPage } from '@jotta/ui/ErrorPage'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import { ErrorBoundary } from '@sentry/react'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import type { PropsWithChildren } from 'react'
import { Suspense, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { PublicHeader } from './PublicHeader'

const debug = Debug('jotta:webapp:layout')
export const WebappLayoutPublic = observer<
  PropsWithChildren<{
    section: 'sharedphotos' | 'sharedfiles'
  }>
>(function WebappLayoutPublic({ children = <Outlet />, section }) {
  const brandStore = useBrandStore()
  useEffect(() => {
    brandStore.activateElements({
      leftNav: false,
      moduleNav: false,
      timelineScrubber: false,
    })
  }, [brandStore])
  return (
    <AppLayout header={<PublicHeader />}>
      <ErrorBoundary
        fallback={({ error, resetError }) => (
          <ErrorPage error={error} resetError={resetError} />
        )}
        beforeCapture={scope => {
          const translated =
            document.body.parentElement?.classList.contains('translated-ltr') ||
            false
          return scope
            .addBreadcrumb({
              level: 'info',
              category: 'WebappLayoutPublic',
              data: {
                section,
                translated,
              },
            })
            .setTag('section', section)
        }}
      >
        <Suspense fallback={<LoadingOverlay open />}>{children}</Suspense>
      </ErrorBoundary>
    </AppLayout>
  )
})
