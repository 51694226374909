import { useCheckPasswordReset } from '@jotta/grpc-connect-client/login'
import { AppLayoutSignup } from '@jotta/ui/AppLayoutSignup'
import { BaseErrorPage } from '@jotta/ui/BaseErrorPage'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import { exhaustiveGuard } from '@jotta/utils/exhaustive'
import { t, Trans } from '@lingui/macro'
import type { ReactNode } from 'react'
import { useParams } from 'react-router-dom'
import { SetPassword } from '../../auth/SetPassword'
import { useBrandName } from '@jotta/grpc-connect-client/config'

export function SetPasswordPage({
  title,
  resetKey,
}: {
  title: ReactNode
  resetKey: string
}) {
  return (
    <AppLayoutSignup>
      <h1>{title}</h1>
      <div className="mx-auto w-[455px]">
        <div className="mt-6 rounded bg-white p-6">
          <SetPassword resetKey={resetKey} />
        </div>
      </div>
    </AppLayoutSignup>
  )
}

export function SetPasswordError({ title }: { title: ReactNode }) {
  return (
    <AppLayoutSignup>
      <BaseErrorPage title={title}>
        <p>
          <Trans>The set password URL is invalid or already used.</Trans>
        </p>
        <p>
          <a href="/" className="!text-signup-link underline">
            <Trans>Click here to go back to the homepage.</Trans>
          </a>
        </p>
      </BaseErrorPage>
    </AppLayoutSignup>
  )
}

export function SetPasswordRoute({ type = 'set' }: { type?: 'set' | 'reset' }) {
  const { resetKey = '' } = useParams()
  const { data } = useCheckPasswordReset(resetKey)
  const brand = useBrandName()
  const title =
    type === 'set' ? (
      <Trans>Choose a password for your {brand} account</Trans>
    ) : (
      t`Set your new password`
    )

  if (!data) {
    return <LoadingOverlay open />
  }

  switch (data.result.case) {
    case 'invalid':
      return <SetPasswordError title={title} />

    case 'redirect':
      window.location.href = data.result.value.url
      return <LoadingOverlay open />

    case 'valid':
      return <SetPasswordPage title={title} resetKey={resetKey} />

    case undefined:
      throw new Error('Invalid response')

    default:
      return exhaustiveGuard(data.result)
  }
}
