import { Box } from '@jotta/ui/Box'
import { filesize } from '@jotta/utils/filesize'
import { isString } from '@jotta/utils/typeAssertions'
import { useLingui } from '@lingui/react'
import filesizeParser from 'filesize-parser'
import type { FC } from 'react'

export interface FileSizeProps {
  bytes: number | string
  /** Width of number (default 5em) */
  numWidth?: string
}
export const FileSize: FC<FileSizeProps> = ({
  bytes,
  numWidth = '5em',
  ...props
}) => {
  const { i18n } = useLingui()
  const num = isString(bytes) ? filesizeParser(bytes) : bytes
  const [size, sym] = filesize(num, {
    round: 0,
    locale: i18n.locale,
    output: 'array',
    fullform: true,
  })
  return (
    <Box
      as="span"
      sx={{
        width: numWidth,
        display: 'grid',
        gridTemplateColumns: '1fr 2em',
        alignItems: 'center',
        gap: '0.25em',
        '> :first-child': {
          textAlign: 'right',
        },
      }}
      {...props}
    >
      <span>{size}</span>
      <span>{sym}</span>
    </Box>
  )
}

/**
 * Unformatted fragment for use in grids
 */
export function useFileSize(bytes: number | string = 0) {
  const { i18n } = useLingui()
  const locale = i18n.locale
  const num = isString(bytes) ? filesizeParser(bytes) : bytes
  return filesize(num, {
    round: 0,
    output: 'array',
    locale,
  }) as unknown as [string, string]
}
/**
 * Unformatted fragment for use in grids
 */

export const FileSizeFragment: FC<FileSizeProps> = ({ bytes }) => {
  const [size, sym] = useFileSize(bytes)
  return <>{`${size} ${sym}`}</>
}

export const FileSizeSpan: FC<FileSizeProps> = ({ bytes }) => {
  const [size, sym] = useFileSize(bytes)
  return <span>{`${size} ${sym}`}</span>
}
