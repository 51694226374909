import { Label } from '@jotta/ui/Label'
import { Headline3 } from '@jotta/ui/Headline'
import type { ReactNode } from 'react'
import type React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { t, Trans } from '@lingui/macro'
import type { StripeValidationError } from '@jotta/ui/ChangePaymentMethodDialog'
import { StripeForm } from '@jotta/ui/ChangePaymentMethodDialog'
import { StripeElementsContainer } from '@jotta/settings/StripeContainer'
import type { Stripe, StripeCardNumberElement } from '@stripe/stripe-js'
import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { useMutation } from '@tanstack/react-query'
import { useMutation as useConnectMutation } from '@connectrpc/connect-query'
import type {
  GetSupportedPaymentMethodsResponse_PaymentMethod as PaymentMethod,
  PlaceOrderResponse_Vipps,
} from '@jotta/grpc-connect-openapi/esm/openapi/subscription/payment.v2_pb'
import { SubscriptionInterval } from '@jotta/grpc-web/no/jotta/openapi/subscription/payment.v2_pb'
import {
  checkAndConfirmStripe as checkAndConfirmStripeDescriptor,
  placeOrder as placeOrderDescriptor,
} from '@jotta/grpc-connect-openapi/esm/openapi/subscription/payment.v2-SubscriptionService_connectquery'
import type { Money } from '@jotta/settings/formatPrice'
import { formatPriceConnectWithInterval } from '@jotta/settings/formatPrice'
import { SelectCountry } from '@jotta/ui/Select'
import { exhaustiveGuard } from '@jotta/utils/exhaustive'
import clsx from 'clsx'
import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import type { BrandThemeIcon } from '@jotta/types/Brand'
import { CTAButton } from '../auth/CTAButton'
import { cn } from '@jotta/utils/css'
import { usePaymentInfo } from './usePaymentInformation'
import type { PlaceOrderRequest } from '@jotta/grpc-connect-openapi/payment'
import type { PartialMessage } from '@bufbuild/protobuf'
import type { OfferState } from './useOfferState'
import { useAndConsumeSearchParam } from '@jotta/router'
import { ErrorMessage } from '@jotta/ui/ErrorMessage'
import type { ButtonProps } from '@jotta/ui/Button'
import { AppError } from '@jotta/types/AppError'
import { Divider } from '@jotta/ui/Divider'
import { useLocale } from '@jotta/i18n'
import { redirectAndWait } from '@jotta/utils/redirect'

function mapMoney(
  money: Money | undefined,
  fn: (amount: bigint) => bigint,
): Money | undefined {
  return money
    ? {
        currency: money.currency,
        amount: fn(BigInt(money.amount)),
      }
    : undefined
}

type RecurringIntervals =
  | SubscriptionInterval.YEARLY
  | SubscriptionInterval.MONTHLY

const currentCardCase = 'currentCard'
const vippsErrorParam = 'vipps-error'

function placeOrderParams(
  offerState: OfferState,
): PartialMessage<PlaceOrderRequest> {
  const { productCode, interval, extraStorageTB } = offerState
  return {
    extraStorageTb: extraStorageTB,
    productCode,
    subscriptionInterval: interval,
  }
}

function usePlaceOrderStripe(successUrl: string, offer: OfferState) {
  const { mutateAsync: placeOrder } = useConnectMutation(placeOrderDescriptor, {
    throwOnError: false,
  })
  const checkAndConfirmStripe = useCheckAndConfirmStripe(successUrl)

  return async (stripe: Stripe, newPaymentMethodId?: string) => {
    const placeOrderResponse = await placeOrder({
      ...placeOrderParams(offer),
      type: {
        case: 'stripe',
        value: {
          newPaymentMethodId: newPaymentMethodId,
        },
      },
    })
    const placeOrderResponseType = placeOrderResponse.type
    switch (placeOrderResponseType.case) {
      case undefined:
      case 'vipps':
        throw new Error('invalid api response')
      case 'stripe':
        return await checkAndConfirmStripe(
          stripe,
          placeOrderResponseType.value.orderNumber,
        )
      case 'rejected':
        throw new AppError({
          view: placeOrderResponseType.value.message,
        })
      default:
        return exhaustiveGuard(placeOrderResponseType)
    }
  }
}

function useCheckAndConfirmStripe(successUrl: string) {
  const { mutateAsync: checkAndConfirmStripe } = useConnectMutation(
    checkAndConfirmStripeDescriptor,
    {
      throwOnError: false,
    },
  )

  return async (stripe: Stripe, orderNumber: string) => {
    for (let i = 0; i < 2; i++) {
      const checkAndConfirmStripeResponse = await checkAndConfirmStripe({
        orderNumber: orderNumber,
      })
      const status = checkAndConfirmStripeResponse.status
      switch (status.case) {
        case 'success':
          return await redirectAndWait(successUrl)
        case 'cardActionRequired':
          await stripe.handleCardAction(status.value.clientSecret)
          break
        case 'cardSetupRequired':
          await stripe.confirmCardSetup(status.value.clientSecret)
          break
        case 'rejected':
          throw new AppError({
            view: status.value.message,
          })
        case undefined:
          throw new Error('invalid server response')
        default:
          return exhaustiveGuard(status)
      }
    }

    // max 2 attempts before failing
    throw new Error('Stripe payment failed')
  }
}

const paymentMethodsIcons: {
  [K in NonNullable<PaymentMethod['type']['case']>]: BrandThemeIcon[]
} = { stripe: ['SvgVisa', 'SvgMastercard'], vipps: ['SvgVipps'] }

type CTA = React.FC<ButtonProps>

function StripePayment({
  cta: CTA,
  offer,
  successUrl,
}: {
  cta: CTA
  offer: OfferState
  successUrl: string
}) {
  const elements = useElements()
  const stripe = useStripe()
  const [errors, setErrors] = useState<StripeValidationError>({})

  const placeOrderStripe = usePlaceOrderStripe(successUrl, offer)

  const {
    mutate: onVerify,
    data: stripeError,
    isPending,
    error,
  } = useMutation({
    throwOnError: false,
    mutationFn: async (cardNumberElement: StripeCardNumberElement | null) => {
      if (cardNumberElement && stripe) {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardNumberElement,
        })
        if (error) {
          return error
        }

        return await placeOrderStripe(stripe, paymentMethod.id)
      }
    },
  })

  return (
    <>
      <StripeForm onVerify={onVerify} errors={errors} setErrors={setErrors} />
      <CTA
        loading={isPending}
        disabled={!elements}
        onClick={e => {
          e.preventDefault()
          elements && onVerify(elements.getElement(CardNumberElement))
        }}
      >
        <Trans>Confirm purchase</Trans>
      </CTA>

      <ErrorMessage marginTop>{stripeError?.message}</ErrorMessage>
      <ErrorMessage marginTop error={error} />
    </>
  )
}

function VippsPayment({
  cta: CTA,
  offer,
  successUrl,
}: {
  cta: CTA
  offer: OfferState
  successUrl: string
}) {
  const failureRedirectUrl = new URL(window.location.href)
  failureRedirectUrl.searchParams.set(vippsErrorParam, 'true')
  const {
    mutate: placeOrder,
    error,
    isPending,
  } = useConnectMutation(placeOrderDescriptor, {
    onSuccess: data => {
      const { confirmationUrl } = data.type.value as PlaceOrderResponse_Vipps
      return redirectAndWait(confirmationUrl)
    },
    throwOnError: false,
  })

  return (
    <>
      <p>
        <Trans>Pay subscription with Vipps</Trans>
      </p>
      <p>
        <Trans>Approve subscription in the Vipps app</Trans>
      </p>
      <CTA
        loading={isPending}
        onClick={e => {
          e.preventDefault()
          placeOrder({
            ...placeOrderParams(offer),
            type: {
              case: 'vipps',
              value: {
                redirectUrl: successUrl,
                failureRedirectUrl: failureRedirectUrl.href,
              },
            },
          })
        }}
      >
        <Trans>Pay with Vipps</Trans>
      </CTA>
      <ErrorMessage marginTop error={error} />
    </>
  )
}

function CurrentCardPayment({
  offerState,
  successUrl,
}: {
  offerState: OfferState
  successUrl: string
}) {
  const stripe = useStripe()
  const placeOrderStripe = usePlaceOrderStripe(successUrl, offerState)

  const {
    mutate: placeAndConfirmOrder,
    error,
    isPending,
  } = useMutation({
    throwOnError: false,
    mutationFn: async () =>
      stripe && (await placeOrderStripe(stripe, undefined)),
  })

  return (
    <>
      <CTAButton
        loading={isPending || !stripe}
        disabled={isPending}
        onClick={() => placeAndConfirmOrder()}
      >
        Pay
      </CTAButton>
      <ErrorMessage error={error} />
    </>
  )
}

function PaymentType({
  cta,
  type,
  offerState,
  successUrl,
}: {
  cta: CTA
  type: PaymentMethod
  offerState: OfferState
  successUrl: string
}) {
  const t = type.type
  switch (t.case) {
    case 'stripe':
      return (
        <StripeElementsContainer publicKey={t.value.publishableKey}>
          <StripePayment offer={offerState} successUrl={successUrl} cta={cta} />
        </StripeElementsContainer>
      )
    case 'vipps':
      return (
        <VippsPayment offer={offerState} successUrl={successUrl} cta={cta} />
      )
  }
}

function PaymentPlan({
  offerState,
  interval,
}: {
  offerState: OfferState
  interval: RecurringIntervals
}) {
  const locale = useLocale()
  const { offer, interval: offerInterval, setInterval } = offerState
  const checked = offerInterval == interval
  const yearlyBasePrice = offer?.yearly?.basePrice
  const monthlyBasePrice = offer?.monthly?.basePrice
  const y = yearlyBasePrice?.amount
  const m = monthlyBasePrice?.amount
  const yearlyDiscount = y && m && (100n * (m * 12n - y)) / y

  const { label, discountPercent, yearlyPrice, monthlyPrice } = (() => {
    switch (interval) {
      case SubscriptionInterval.YEARLY: {
        return {
          label: t`Billed yearly`,
          discountPercent: yearlyDiscount ? `${yearlyDiscount}%` : undefined,
          yearlyPrice: yearlyBasePrice,
          monthlyPrice: mapMoney(yearlyBasePrice, amount => amount / 12n),
        }
      }
      case SubscriptionInterval.MONTHLY:
        return {
          label: t`Billed monthly`,
          discountPercent: undefined,
          yearlyPrice: mapMoney(monthlyBasePrice, amount => amount * 12n),
          monthlyPrice: monthlyBasePrice,
        }
      default:
        return exhaustiveGuard(interval)
    }
  })()

  const yearlyPriceFormatted =
    yearlyPrice &&
    formatPriceConnectWithInterval(
      SubscriptionInterval.YEARLY,
      yearlyPrice,
      locale,
    )
  const monthlyPriceFormatted =
    monthlyPrice &&
    formatPriceConnectWithInterval(
      SubscriptionInterval.MONTHLY,
      monthlyPrice,
      locale,
    )

  return (
    <label
      className={cn(
        'relative mt-4 flex flex-col justify-between gap-2 rounded-lg border border-light bg-white p-4 text-grey4',
        {
          'drop-shadow-md hover:cursor-pointer hover:border-signup-radio-button-stroke-hover':
            !checked,
          'bg-signup-background-fill-radio text-primary': checked,
          'rounded-tr-none': discountPercent,
        },
      )}
    >
      <div className="mb-4 flex justify-between">
        <span className="body-sm-sh">{label}</span>
        <input
          name="interval"
          type="radio"
          className="radio"
          checked={checked}
          onChange={() => setInterval(interval)}
        />
      </div>

      <div className="flex flex-col gap-2">
        <span className="label-md">
          {interval === SubscriptionInterval.YEARLY
            ? yearlyPriceFormatted
            : monthlyPriceFormatted}
        </span>
        {Boolean(yearlyDiscount) && (
          <span className="body-sm-sh">
            {interval === SubscriptionInterval.YEARLY
              ? monthlyPriceFormatted
              : yearlyPriceFormatted}
          </span>
        )}
      </div>

      {discountPercent && (
        <div className="label-sm absolute right-0 top-[-1px] translate-y-[-100%] self-end rounded-t-xl bg-good px-2 py-1 text-good">
          <Trans>Save {discountPercent}</Trans>
        </div>
      )}
    </label>
  )
}

export function PaymentInformation({
  offerState,
  successUrl,
  className,
  summary,
}: {
  offerState: OfferState
  successUrl: string
  className?: string
  summary?: ReactNode
}) {
  const currentPaymentMethod = usePaymentInfo().data?.paymentInfo?.paymentMethod
  const currentCard =
    currentPaymentMethod?.case === 'card'
      ? currentPaymentMethod.value
      : undefined
  const {
    country,
    setCountry,
    paymentMethods,
    extraStorageTB,
    setExtraStorageTB,
    offer,
  } = offerState

  const [paymentMethodTypeCase, setPaymentMethodTypeCase] = useState<string>()
  const [vippsError, setVippsError] = useState<string>()
  useAndConsumeSearchParam(vippsErrorParam, () => {
    setVippsError(t`Payment failed`)
  })

  const selectedPaymentMethod =
    paymentMethods?.find(value => value.type.case === paymentMethodTypeCase) ||
    (!currentCard ? paymentMethods?.at(0) : undefined)

  const stripePublicKey = paymentMethods
    ?.map(pm => {
      const typeCase = pm.type
      if (typeCase.case === 'stripe') {
        return typeCase.value.publishableKey
      }
    })
    .find(value => value)

  useEffect(() => {
    if (currentCard) {
      setPaymentMethodTypeCase(currentCardCase)
    }
  }, [currentCard])

  const cta: CTA = useCallback(
    (props: ButtonProps) => {
      return (
        <>
          {summary}
          <CTAButton {...props} />
        </>
      )
    },
    [summary],
  )

  return (
    <section className={clsx('flex flex-col', className)}>
      <ErrorMessage>{vippsError}</ErrorMessage>

      <div className="grid gap-2 py-12 sm:grid-cols-2">
        <PaymentPlan
          interval={SubscriptionInterval.YEARLY}
          offerState={offerState}
        />
        <PaymentPlan
          interval={SubscriptionInterval.MONTHLY}
          offerState={offerState}
        />
      </div>

      {offer?.extraStorageAvailable && (
        <>
          <Divider />
          <Headline3 className="pb-2">
            <Trans>Extra storage</Trans>
          </Headline3>
          <div className="mb-8 flex items-center rounded-lg border border-primary">
            <button
              className="btn"
              disabled={extraStorageTB <= 0}
              onClick={() => setExtraStorageTB(Math.max(0, extraStorageTB - 1))}
            >
              -
            </button>
            <span className="grow text-center">{`${extraStorageTB} TB`}</span>
            <button
              className="btn"
              onClick={() => setExtraStorageTB(extraStorageTB + 1)}
            >
              +
            </button>
          </div>
        </>
      )}

      <Divider />

      <h3 className="label-lg pb-6 pt-14">
        <Trans>Payment</Trans>
      </h3>

      <Label label={t`Country`}>
        <SelectCountry country={country} setCountry={setCountry} />
      </Label>

      {paymentMethods && paymentMethods.length > 1 && (
        <div className="flex gap-4 py-6">
          {currentCard && (
            <label className="radio">
              <input
                type="radio"
                name="payment_type"
                checked={paymentMethodTypeCase === currentCardCase}
                onChange={() => setPaymentMethodTypeCase(currentCardCase)}
              />
              use current ({currentCard.cardMask})
            </label>
          )}

          {paymentMethods?.map(value => {
            const typeCase = value.type.case
            const icons = (typeCase && paymentMethodsIcons[typeCase]) || []
            return (
              <label key={typeCase} className="radio flex gap-2">
                <input
                  type="radio"
                  name="payment_type"
                  checked={selectedPaymentMethod?.type.case === typeCase}
                  onChange={() => setPaymentMethodTypeCase(typeCase)}
                />
                {icons.map((icon, index) => (
                  <PlainBrandIcon key={index} icon={icon} height={20} />
                ))}
              </label>
            )
          })}
        </div>
      )}

      {selectedPaymentMethod && (
        <PaymentType
          type={selectedPaymentMethod}
          offerState={offerState}
          successUrl={successUrl}
          cta={cta}
        />
      )}

      {stripePublicKey && paymentMethodTypeCase === currentCardCase && (
        <StripeElementsContainer publicKey={stripePublicKey}>
          <CurrentCardPayment offerState={offerState} successUrl={successUrl} />
        </StripeElementsContainer>
      )}
    </section>
  )
}
