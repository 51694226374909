import { PlainBrandIcon } from '@jotta/ui/BrandIcon'
import type { Person } from '@jotta/grpc-connect-openapi/people'
import { addUrlParams } from '@jotta/utils/url'
import { t } from '@lingui/macro'
import type { PhotoSearchStore } from './PhotoSearchStore'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'

export const PhotoSearchSelectedTag = observer<{
  person: Person
  store: PhotoSearchStore
  index: number
}>(function PhotoSearchSelectedTag({ person, store, index }) {
  const { removePerson } = store
  return (
    <button
      className={clsx(
        store.ids.activetag,
        'my-1 flex overflow-hidden rounded-xl bg-sx-primary bg-opacity-40 ring-sx-primary data-active:ring-1',
      )}
      data-tag={person.id}
      key={person.id}
      onClick={() => removePerson(person.id)}
      onMouseDown={e => e.preventDefault()}
      type="button"
    >
      <span className="size-8 overflow-hidden rounded-xl">
        {person && (
          <img
            className="size-full object-cover object-center"
            src={addUrlParams(person.thumbUrl, {
              size: 's',
            })}
            alt={person.name || t`Unnamed person`}
          />
        )}
      </span>
      <PlainBrandIcon icon="SvgClose" className="h-8 w-4" />
    </button>
  )
})
