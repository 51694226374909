import Debug from 'debug'
import { makeAutoObservable } from 'mobx'

const debug = Debug('jotta:webapp:store')

export class WebAppStore {
  context = {
    path: '/',
  }
  constructor() {
    makeAutoObservable(this)
  }
  get path() {
    return this.context.path
  }
  set path(path) {
    this.context.path = path
  }
}

let wepAppStore: WebAppStore

export function getWebAppStore() {
  if (!wepAppStore) {
    debug('Create webapp store')
    wepAppStore = new WebAppStore()
  }
  return wepAppStore
}
