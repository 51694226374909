import { Button } from '@jotta/ui/Button'
import { getBrandZIndex } from '@jotta/ui/zIndex'
import { queryClient } from '@jotta/query'
import { t } from '@lingui/macro'
import * as Popover from '@radix-ui/react-popover'

import { useEffect, useState } from 'react'
import { LoadingOverlay } from '../Layout/LoadingOverlay/LoadingOverlay'
import type { NotificationsApi } from './NotificationsApi'
import { notificationsApi, useNotifications } from './NotificationsApi'
import { NotificationsList } from './NotificationsList'

export const Notifications: React.FC<{
  api?: NotificationsApi
}> = ({ api = notificationsApi, ...props }) => {
  const {
    notifications,
    unreadCount,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useNotifications(api)
  const [open, setOpen] = useState(false)
  const unread = notifications.find(n => n.isNew)
  useEffect(() => {
    if (!unread) {
      return
    }
    if (open) {
      api.markAsRead(unread)
    } else {
      queryClient.invalidateQueries({ queryKey: ['notifications'] })
    }
  }, [api, open, unread])

  return (
    <Popover.Root
      data-onboarding-id="notificationsBell"
      modal
      open={open}
      onOpenChange={setOpen}
    >
      <LoadingOverlay open={isLoading} />
      <Popover.Trigger asChild>
        <Button
          type="button"
          id="notification-alert-button"
          aria-label={t`Open your notifications`}
          variant="buttons.bellIcon"
          icon={unread ? 'SvgBellAlert' : 'SvgBell'}
        >
          <span
            sx={{
              opacity: 1,
              transform: 'scale(1)',
              position: 'absolute',
              right: '70px',
              top: '10px',
              color: 'background',
              fontSize: 0,
              fontWeight: 500,
              textAlign: 'center',
              width: 3,
              height: 3,
              borderRadius: '50%',
              bg: 'red',
              display: unread ? 'block' : 'none',
              boxShadow: 'rgb(0 0 0 / 50%) 0px 1px 3px',
              transition: 'opacity 0.2s ease 0s, transform 0.2s ease 0s',
            }}
          >
            {unreadCount}
          </span>
        </Button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          sx={{
            bg: 'background',
            border: '1px solid',
            borderColor: 'lightGrey',
            borderRadius: '5px',
            boxShadow: 'rgb(0 0 0 / 12%) 0px 2px 7px',
            position: 'inherit',
            top: '15px',
            right: '-12px',
            width: ['260px', '400px'],
            zIndex: getBrandZIndex('dropdown'),
          }}
          aria-live="polite"
          aria-label={t`Your list of notifications`}
        >
          <NotificationsList
            notifications={notifications}
            onFetchNextPage={fetchNextPage}
            hasNextPage={Boolean(hasNextPage)}
            isFetchingNextPage={isFetchingNextPage}
          />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}
