import type { HeadLineProps } from '@jotta/ui/Headline'
import { Headline } from '@jotta/ui/Headline'
import clsx from 'clsx'
import styles from './AlbumsSectionHeader.module.scss'

export function AlbumsSectionHeader({
  title,
  countLabel,
  level = 2,
  className,
  ...props
}: {
  level?: HeadLineProps['level']
  title: string
  countLabel?: string
  className?: string
}) {
  return (
    <Headline
      level={level}
      className={clsx(styles.header, className)}
      {...props}
    >
      {title}
      {!!countLabel && <small className={styles.count}>{countLabel}</small>}
    </Headline>
  )
}
