import type { CSSProperties } from 'react'
import type { Photos } from '@jotta/types/Photos'
import { LoadingOverlay } from '@jotta/ui/LoadingOverlay'
import { plural } from '@lingui/macro'
import Debug from 'debug'
import { observer } from 'mobx-react-lite'
import type { PropsWithChildren } from 'react'
import { Children } from 'react'
import { range } from 'remeda'
import { BaseAlbumThumb } from '../AlbumThumb/BaseAlbumThumb'
import { AlbumsSectionHeader } from './AlbumsSectionHeader'
import styles from './BaseAlbumsSection.module.scss'
const debug = Debug('jotta:photos:BaseAlbumsSection')
debug.enabled = false
export type BaseAlbumsSectionProps = PropsWithChildren<{
  columns?: number
  albumCount?: number
  isLoading?: boolean
  showAll?: boolean
  title?: string
  albumType?: Photos.DisplayCollectionTypeKey
}>
function AlbumPlaceholders({ columns }: { columns: number }) {
  return (
    <>
      {range(0, columns).map(col => (
        <BaseAlbumThumb key={`placeholder${col}`}>
          <div className={styles.placeHolder} />
        </BaseAlbumThumb>
      ))}
    </>
  )
}
export const BaseAlbumsSection = observer<BaseAlbumsSectionProps>(
  function BaseAlbumsSection({
    showAll = false,
    title,
    columns = 2,
    albumCount,
    isLoading = false,
    children,
    albumType,
  }) {
    const items = showAll
      ? children
      : Children.toArray(children).slice(0, columns)
    const itemCount = Children.count(items)
    const showPlaceHolder = isLoading && !itemCount
    debug('Children count %s showPlaceHolder %s', itemCount, showPlaceHolder)
    const countLabel =
      albumType === 'LOCATION_ALBUM'
        ? plural(albumCount || 0, {
            one: '# location',
            other: '# locations',
          })
        : plural(albumCount || 0, {
            one: '# album',
            other: '# albums',
          })
    return (
      <div
        style={
          {
            '--columns': columns,
          } as CSSProperties
        }
        className={styles.albumSection}
      >
        <LoadingOverlay position="absolute" open={isLoading} />
        {title && <AlbumsSectionHeader title={title} countLabel={countLabel} />}
        <div
          sx={{
            gap: [1, 2, 3],
          }}
          className={styles.rows}
        >
          {showPlaceHolder ? <AlbumPlaceholders columns={columns} /> : items}
        </div>
      </div>
    )
  },
)
