import type { FileRouteViewMode } from '@jotta/types/schemas/FileRouteSchema'
import Debug from 'debug'
import moize from 'moize'
import { list } from 'radash'
import type { CSSProperties } from 'react'
import type { FileListRenderItem } from './FileList'
const debug = Debug('jotta:files:fileListUtils')

export type FileListRowObject =
  | FileListRowDataViewModeList
  | FileListRowDataViewModeThumbs
export interface FileListRowData {
  rowIndex: number
  rowHeight: number
  key: string
  columns: number
  renderItem: FileListRenderItem
  style: CSSProperties
}
export interface FileListRowDataViewModeList extends FileListRowData {
  view: 'list'
}
export interface FileListRowDataViewModeThumbs extends FileListRowData {
  view: 'thumbs'
  items: {
    index: number
    key: string
  }[]
}
export interface GetRowDataProps {
  rowCount: number
  rowHeight: number
  columns: number
  rows: number
  view: FileRouteViewMode
  renderItem: FileListRenderItem
}

export const getRowDataItem = moize.shallow(
  function getRowDataItem({
    columns,
    rowIndex,
    view,
    rowHeight,
    renderItem,
  }: {
    rowIndex: number
    rowHeight: number
    columns: number
    view: FileRouteViewMode
    renderItem: FileListRenderItem
  }): FileListRowObject {
    // debug('getRowDataItem', rowIndex, view)
    const style = {
      transform: `translateY(${rowIndex * rowHeight}px)`,
    } as CSSProperties
    if (view === 'list') {
      return {
        renderItem,
        view: 'list',
        rowIndex,
        columns,
        key: `row${rowIndex}`,
        style,
        rowHeight,
      }
    }
    const itemIndex1 = rowIndex * columns
    const itemIndex2 = itemIndex1 + columns - 1
    return {
      renderItem,
      view: 'thumbs',
      rowIndex,
      columns,
      key: `row-thumbs-${rowIndex}`,
      style,
      rowHeight,
      items: list(itemIndex1, itemIndex2, index => ({
        index,
        key: `row${index}`,
      })),
    }
  },
  {
    maxSize: 10000,
  },
)

export const getRowData = moize.shallow(
  function getRowData({
    rowCount,
    rowHeight,
    columns,
    rows,
    view,
    renderItem,
  }: GetRowDataProps): FileListRowObject[] {
    return list(0, rowCount - 1, rowIndex =>
      getRowDataItem({
        columns,
        renderItem,
        rowHeight,
        rowIndex,
        view,
      }),
    )
  },
  {
    maxSize: 1000,
  },
)
