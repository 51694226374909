import { Box } from '@jotta/ui/Box'
import type { PathItemObject } from '@jotta/types/Files'
import { isString } from '@jotta/utils/typeAssertions'
import Debug from 'debug'
import filesizeParser from 'filesize-parser'
import { extname } from 'path'
import Prism from 'prismjs'
import 'prismjs/components/prism-bash'
import 'prismjs/components/prism-json'
import 'prismjs/components/prism-jsx'
import 'prismjs/components/prism-tsx'
import 'prismjs/components/prism-typescript'
import 'prismjs/plugins/normalize-whitespace/prism-normalize-whitespace'
import 'prismjs/themes/prism-okaidia.css'
import { useEffect, useRef } from 'react'
import { LoadingOverlaySpinner } from '../../Layout/LoadingOverlay/LoadingOverlay'
import { useMutation } from '@tanstack/react-query'

const debug = Debug('jotta:ui:code')

const TEXT_VIEW_MAX_FILE_SIZE = filesizeParser('150kb')
const CODE_VIEW_MAX_FILE_SIZE = filesizeParser('35kb')
export const supportedCodeViewLanguages = [
  'css',
  'html',
  'js',
  'json',
  'jsx',
  'sh',
  'ts',
  'tsx',
] as const
export type CodeViewLanguage = (typeof supportedCodeViewLanguages)[number]
export const supportedTextViewLanguages = [
  'ini',
  'log',
  'm',
  'nfo',
  'sha1',
  'txt',
] as const
export type TextViewLanguage = (typeof supportedTextViewLanguages)[number]

export function isSupportedCodeViewLanguage(
  language: any,
): language is CodeViewLanguage {
  return (
    isString(language) &&
    supportedCodeViewLanguages.includes(language as CodeViewLanguage)
  )
}
export function isSupportedTextViewLanguage(
  language: any,
): language is TextViewLanguage {
  return (
    isString(language) &&
    supportedTextViewLanguages.includes(language as TextViewLanguage)
  )
}

export type FileCodeViewInfo =
  | {
      lang: CodeViewLanguage
      view: 'CODE'
    }
  | {
      lang: TextViewLanguage
      view: 'TEXT'
    }
  | {
      lang: undefined
      view: 'DEFAULT'
    }

export function selectCodeView(file: PathItemObject): FileCodeViewInfo {
  const lang = extname(file.name).substring(1)
  if (!lang || file.size > TEXT_VIEW_MAX_FILE_SIZE) {
    return {
      lang: undefined,
      view: 'DEFAULT',
    }
  }

  if (
    isSupportedCodeViewLanguage(lang) &&
    file.size <= CODE_VIEW_MAX_FILE_SIZE
  ) {
    return {
      lang: lang,
      view: 'CODE',
    }
  }
  if (
    isSupportedTextViewLanguage(lang) &&
    file.size <= TEXT_VIEW_MAX_FILE_SIZE
  ) {
    return {
      lang: 'txt',
      view: 'TEXT',
    }
  }
  return {
    lang: undefined,
    view: 'DEFAULT',
  }
}

export interface CodeViewProps {
  language?: CodeViewLanguage | 'txt'
  children: string
}

function useHighlightElement() {
  return useMutation({
    mutationFn: (el: HTMLElement) => {
      return new Promise(resolve => {
        Prism.highlightElement(el, undefined, el => resolve(el))
      })
    },
  })
}

export function CodeView({ children, language = 'txt' }: CodeViewProps) {
  const codeRef = useRef<HTMLElement>(null)
  const isText = language === 'txt'
  const className = isText ? '' : `language-${language}`
  const { mutate: highlightElement, isSuccess: isHighlighted } =
    useHighlightElement()

  useEffect(() => {
    if (children && codeRef.current) {
      highlightElement(codeRef.current)
    }
  }, [children, highlightElement])

  return (
    <>
      <Box
        as="pre"
        sx={{ margin: '0 !important', display: isHighlighted ? '' : 'none' }}
        className={className}
      >
        <code ref={codeRef}>{children}</code>
      </Box>
      {!isHighlighted && <LoadingOverlaySpinner />}
    </>
  )
}
