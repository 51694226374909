import { SearchServicePromiseClient } from '@jotta/grpc-web/no/jotta/openapi/search/search.v2_grpc_web_pb'
import { withGrpcClientMiddleware } from './grpcutils'
import {
  SearchRequest,
  PhotoSearchRequest,
  GetWaitlistStatusRequest,
  JoinWaitlistRequest,
  FindSimilarPhotosRequest,
  PhotoSearchQuerySuggestionsRequest,
} from '@jotta/grpc-web/no/jotta/openapi/search/search.v2_pb'
import type { PathItem } from '@jotta/grpc-web/no/jotta/openapi/file/file.v2_pb'
import { env } from '@jotta/utils/env'
//Used for testing banners
//import { WaitlistStatus } from '@jotta/grpc-web/no/jotta/openapi/search/search.v2_pb'

let searchServiceClient: SearchServicePromiseClient

function getSearchServiceClient() {
  if (!searchServiceClient) {
    searchServiceClient = withGrpcClientMiddleware(
      'searchv2',
      new SearchServicePromiseClient(env.grpcApi),
    )
  }
  return searchServiceClient
}

export interface SearchMatchV2 {
  query: string
  item: PathItem
}

export interface SearchParamsV2 {
  query: string
  paths?: string[]
}

export type CancelFunc = () => void

export function searchV2(
  params: SearchParamsV2,
  receiver: (match: SearchMatchV2) => void,
  onEnd: () => void,
  onError?: (err: Error) => void,
): CancelFunc {
  const query = params.query
  const searchRequest = new SearchRequest()
  searchRequest.setQuery(params.query)
  if (params.paths) {
    searchRequest.setPathList(params.paths)
  }

  const client = getSearchServiceClient()
  const stream = client.search(searchRequest)
  stream.on('data', function (r) {
    const pathItem = r.getMatch()
    if (pathItem) {
      receiver({ query, item: pathItem })
    }
  })
  stream.on('end', () => {
    if (onEnd) {
      onEnd()
    }
  })
  // TODO: do something with error...
  stream.on('error', grpcErr => {
    if (onError) {
      const error = new Error('search error')
      ;(error as any).details = grpcErr
      onError(error)
    }
  })

  return () => {
    stream.cancel()
  }
}

export async function photoSearch(
  query: string,
  debug = false,
  minimumAestheticScore?: number | undefined,
) {
  const request = new PhotoSearchRequest().setQuery(query).setDebug(debug)
  if (minimumAestheticScore) {
    request.setMinimumAestheticScore(minimumAestheticScore)
  }
  const client = getSearchServiceClient()
  const response = await client.photoSearch(request)

  return response.getMatchesList()
}

export async function getWaitListStatus() {
  const waitlistRequest = new GetWaitlistStatusRequest()
  const client = getSearchServiceClient()
  const response = await client.getWaitlistStatus(waitlistRequest)
  return response.getStatus()
  //Testing purposes for status
  //return new WaitlistStatus().setStatus(WaitlistStatus.Status.ON_WAITLIST)
  //return new WaitlistStatus().setStatus(WaitlistStatus.Status.INACTIVE)
}
export async function joinWaitlist() {
  const joinWaitlistRequest = new JoinWaitlistRequest()
  const client = getSearchServiceClient()
  const response = await client.joinWaitlist(joinWaitlistRequest)
  return response.getStatus()
  //Testing purposes for status
  //return new WaitlistStatus().setStatus(WaitlistStatus.Status.ON_WAITLIST)
}
export async function findSimilarPhotos(
  id: string,
  debug = false,
  minimumAestheticScore?: number | undefined,
) {
  const request = new FindSimilarPhotosRequest().setPhotoId(id).setDebug(debug)
  if (minimumAestheticScore) {
    request.setMinimumAestheticScore(minimumAestheticScore)
  }
  const client = getSearchServiceClient()
  const response = await client.findSimilarPhotos(request)
  return response.getMatchesList()
}

export async function searchQuerySuggestions() {
  const request = new PhotoSearchQuerySuggestionsRequest()
  const client = getSearchServiceClient()
  const response = await client.photoSearchQuerySuggestions(request)
  return response.getSuggestedQueriesList()
}
