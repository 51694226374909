import type { FileThumbSize, PathItemObject } from '@jotta/types/Files'
import { isString } from '@jotta/utils/typeAssertions'
import Debug from 'debug'
const debug = Debug('jotta:files:getFileThumb')

export function getThumbLink(
  item?: string | Partial<PathItemObject>,
  size: FileThumbSize = 'WS',
  fallback: string = '',
) {
  if (!item) {
    return fallback
  }
  if (isString(item)) {
    return item.trim()
  }
  const thumbLink = item.thumbLink
  if (thumbLink) {
    try {
      const url = new URL(thumbLink)
      url.searchParams.set('size', size)
      return url.href
    } catch (err) {
      debug('error prosessing thumblink for item', thumbLink, size)
    }
  }
  return fallback
}
