// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Folder_breadcrumb__mGzKR{padding-left:var(--s4);padding-right:var(--s4)}.Folder_breadcrumb__mGzKR>nav{border-bottom:1px solid var(--color-border);height:60px}.Folder_bottom__trTCA{height:80px;margin-bottom:50px;position:relative;width:100%;z-index:0}.Folder_emptyVirtualListItem__qaXUd{background-color:#fff}`, "",{"version":3,"sources":["webpack://./../../libraries/files/src/Folder/Folder.module.scss"],"names":[],"mappings":"AACA,0BACE,sBAAA,CACA,uBAAA,CACA,8BAEE,2CAAA,CADA,WACA,CAGJ,sBAEE,WAAA,CACA,kBAAA,CACA,iBAAA,CAHA,UAAA,CAIA,SAAA,CAEF,oCACE,qBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumb": `Folder_breadcrumb__mGzKR`,
	"bottom": `Folder_bottom__trTCA`,
	"emptyVirtualListItem": `Folder_emptyVirtualListItem__qaXUd`
};
export default ___CSS_LOADER_EXPORT___;
